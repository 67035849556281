import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Box, Flex, Grid, Heading } from 'core/components';
import { ReactComponent as NetworkingInfrastructureIcon } from 'app/assets/onboarding/networking-infrastructure.svg';
import { ReactComponent as PublicCloudsIcon } from 'app/assets/onboarding/public_clouds.svg';
import { ReactComponent as SyntheticPerformance } from 'app/assets/onboarding/synthetic_performance.svg';

import AwsLogo from 'app/components/AwsLogo';
import KentikLogo from 'app/components/KentikLogo';
import { CLOUD_PROVIDERS } from 'app/util/constants';
import Background from 'app/views/onboarding/components/Background';
import Subtitle from 'app/views/onboarding/components/Subtitle';
import OnboardingCheckbox from 'app/views/onboarding/components/OnboardingCheckbox';
import OnboardingNextButton from 'app/views/onboarding/components/OnboardingNextButton';
import OnboardingSubcheckbox from 'app/views/onboarding/components/OnboardingSubcheckbox';
import Blurb from 'app/views/onboarding/components/Blurb';
import GoogleLogo from 'app/components/GoogleLogo';
import storeLoader from 'app/stores/storeLoader';

@storeLoader('$sites')
@inject('$auth', '$setup', '$users')
class What extends Component {
  constructor(props) {
    super(props);

    const { $setup } = props;

    const { digital_experience, hybrid, troubleshooting, ddos, peering, sdwan, kmi } = $setup.getSettings('why', {});
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      cloud: !!hybrid,
      synth: !!digital_experience,
      flow: !!(troubleshooting || ddos || peering || sdwan),
      aws: false,
      gcp: false,
      azure: false,
      oci: false,
      kmi: !!kmi
    };
  }

  componentDidMount() {
    // Do this up front so that we ensure things get saved in case the user likes the default selections
    const { $setup } = this.props;
    const { cloud, synth, flow, aws, gcp, azure, oci, kmi } = this.state;
    $setup.updateSettings({ what: { cloud, synth, flow, aws, gcp, azure, oci, kmi } });
    $setup.writeSetupTasks({ cloud, synth, flow, aws, gcp, azure, oci, kmi });
  }

  handleChange = (key, checked) => {
    const { $setup } = this.props;
    const state = { [key]: checked };

    // Clear out all cloud providers if cloud is false
    if (key === 'cloud' && !checked) {
      state.aws = false;
      state.gcp = false;
      state.azure = false;
      state.oci = false;
    }

    this.setState(state, () => {
      const { cloud, synth, flow, aws, gcp, azure, oci, kmi } = this.state;
      $setup.updateSettings({ what: { cloud, synth, flow, aws, gcp, azure, oci, kmi } });
      $setup.writeSetupTasks({ cloud, synth, flow, aws, gcp, azure, oci, kmi });
    });
  };

  handleNext = () => {
    const { $auth, $users } = this.props;

    $auth.track('tour-onboarding-completed');

    if (this.synthOnly) {
      $users.updateUserProfile({ defaultLandingPageType: 'synthetics' }, { toast: false });
    }

    if (this.kmiOnly) {
      $users.updateUserProfile({ defaultLandingPageType: 'market-intel' }, { toast: false });
    }
  };

  get hasSelection() {
    const { synth, flow, aws, gcp, azure, oci, kmi } = this.state;
    return !!(synth || flow || aws || gcp || azure || oci || kmi);
  }

  get tooltipContent() {
    const { cloud, aws, gcp, azure, oci } = this.state;

    if (cloud && !(aws || gcp || azure || oci)) {
      return 'You must select a specific cloud provider';
    }

    if (!this.hasSelection) {
      return 'You must make at least one selection';
    }

    return null;
  }

  // TODO: at some point we'll want to consider 'why' settings too for this
  get synthOnly() {
    const { cloud, synth, flow, aws, gcp, azure, oci, kmi } = this.state;
    return synth && !cloud && !flow && !aws && !gcp && !azure && !oci && !kmi;
  }

  get kmiOnly() {
    const { cloud, synth, flow, aws, gcp, azure, oci, kmi } = this.state;
    return kmi && !cloud && !flow && !aws && !gcp && !azure && !oci && !synth;
  }

  render() {
    const { cloud, synth, flow, aws, gcp, azure, oci } = this.state;

    return (
      <Background>
        <Flex alignItems="flex-start" mb={4} maxWidth={750}>
          <Box mr="20px" minWidth={220}>
            <KentikLogo className="logo" />
            <Subtitle />
          </Box>
          <Blurb />
        </Flex>
        <Heading level={1}>On it! Which data should we collect?</Heading>
        <OnboardingCheckbox
          icon={PublicCloudsIcon}
          title="Public Cloud"
          description="Let Kentik tap into the metrics, metadata, and flow logs of your favorite cloud providers so you're always in the know."
          onChange={(checked) => this.handleChange('cloud', checked)}
          checked={cloud}
          showChildren={cloud}
          mb={2}
        >
          <Grid gridGap={2} gridTemplateColumns="max-content max-content" mt={2}>
            <OnboardingSubcheckbox
              icon={<AwsLogo forceDark />}
              title="Amazon Web Services"
              onChange={(checked) => this.handleChange('aws', checked)}
              checked={aws}
            />
            <OnboardingSubcheckbox
              icon={<GoogleLogo />}
              title="Google Cloud Platform"
              onChange={(checked) => this.handleChange('gcp', checked)}
              checked={gcp}
            />
            <OnboardingSubcheckbox
              icon={CLOUD_PROVIDERS.AZURE.logo}
              title="Microsoft Azure"
              onChange={(checked) => this.handleChange('azure', checked)}
              checked={azure}
            />
            <OnboardingSubcheckbox
              icon={CLOUD_PROVIDERS.OCI.logo}
              title="Oracle Cloud Infrastructure"
              onChange={(checked) => this.handleChange('oci', checked)}
              checked={oci}
            />
          </Grid>
        </OnboardingCheckbox>
        <OnboardingCheckbox
          icon={SyntheticPerformance}
          title="Synthetic Performance Tests"
          description="Immediately understand network performance with Kentik's global network of synthetic testing agents. Create cloud, network, server, and routing tests and view results in minutes."
          checked={synth}
          onChange={(checked) => this.handleChange('synth', checked)}
          mb={2}
        />
        <OnboardingCheckbox
          icon={NetworkingInfrastructureIcon}
          title="Networking Infrastructure"
          description="Send flow telemetry from your switches and routers into Kentik for the world's best network analytics. Add SNMP and BGP data to unlock powerful enrichments used in workflows and visualizations."
          onChange={(checked) => this.handleChange('flow', checked)}
          checked={flow}
          mb={3}
        />
        <OnboardingNextButton
          text="Get Started"
          to={this.synthOnly ? '/v4/synthetics' : '/v4/setup'}
          onClick={this.handleNext}
          disabled={!this.hasSelection}
          tooltipContent={this.tooltipContent}
        />
      </Background>
    );
  }
}

export default What;
