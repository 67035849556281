function isValidRegex(val) {
  let isValid = true;
  try {
    RegExp(val);
  } catch (e) {
    isValid = false;
  }
  return isValid;
}

module.exports = isValidRegex;
