import { OCI_ENTITY_TYPES } from 'shared/hybrid/constants';

import { curveLeftUp, curveUpLeft, curveDownLeft, curveLeftDown } from 'app/views/hybrid/utils/d3/curves';

/**
 * Will connect drg gateway to any attachment
 */
const connectToAnyAttachment = ({
  sourceNode,
  path,
  pathIndex,
  targetPoint,
  sourcePoint,
  xSpacing,
  ySpacing,
  directConnectWidth,
  directConnectHeight,
  linkSpacing
}) => {
  const targetY = targetPoint[1] + directConnectHeight;
  const sourceY = sourcePoint[1];
  const sourceX = sourcePoint[0] - xSpacing;
  const revertedSourceY = sourcePoint[1] - ySpacing;
  const middleX = sourcePoint[0] - directConnectWidth / 2 - linkSpacing;

  const alignedAlongX = Math.abs(sourcePoint[1] - targetPoint[1]) < ySpacing;
  const isDrgGatewayOnTop = sourcePoint[1] < targetPoint[1];

  /**
   * example:
   * Attachment             DRG Gateway
   *   ↑                         ↓
   *    ← ← ← ← ← ←  ← ← ← ← ← ←
   */
  if (alignedAlongX || isDrgGatewayOnTop) {
    const connectionPoints = [
      ...curveLeftDown(sourceX, sourceY, linkSpacing),
      ...curveDownLeft(sourceX, targetY, linkSpacing),
      ...curveLeftUp(targetPoint[0], targetY, linkSpacing)
    ];
    return { sourceAnchor: 'left', targetAnchor: 'bottom', connectionPoints };
  }

  // if path goes transit gateway, we need to make sure that anchors are set based on prev and next node positions
  const nextLink = path[pathIndex + 1];
  const sourceNodeId = sourceNode.value;
  const nextLinkTargetNodeId = nextLink?.target?.value ?? null;

  if (sourceNodeId === nextLinkTargetNodeId && revertedSourceY > targetY) {
    /**
     * example:
     * Attachment
     *    ↑
     *    ← ← ← ← ← ← ←
     *                  ↑
     *                    ← ← ← ←
     *                            ↑
     *         <WE ARE HERE>DRG Gateway</WE ARE HERE>
     *                            ↑
     *                    → → → →
     *                   ↑
     *                   ↑
     *                   ↑
     *  Attachment       ↑
     *      ↓            ↑
     *       → → → → → →
     */

    const connectionPoints = [
      ...curveUpLeft(sourceX, revertedSourceY, linkSpacing),
      ...curveLeftUp(middleX, revertedSourceY, linkSpacing),
      ...curveUpLeft(middleX, targetY, linkSpacing),
      ...curveLeftUp(targetPoint[0], targetY, linkSpacing)
    ];
    return { sourceAnchor: 'left', targetAnchor: 'bottom', connectionPoints };
  }

  /**
   * example:
   * Attachment
   *    ↑
   *    ← ← ← ← ← ← ←
   *                  ↑   Transit Gateway
   *                  ↑         ↓
   *                    ← ← ← ←
   */
  const connectionPoints = [
    ...curveDownLeft(sourceX, sourceY + ySpacing, linkSpacing),
    ...curveLeftUp(middleX, sourceY + ySpacing, linkSpacing),
    ...curveUpLeft(middleX, targetY, linkSpacing),
    ...curveLeftUp(targetPoint[0], targetY, linkSpacing)
  ];
  return { sourceAnchor: 'bottom', targetAnchor: 'bottom', connectionPoints };
};

const connectDrgGateways = ({ regionRight, sourcePoint, targetPoint }) => {
  const connectionPoints = [
    [regionRight, sourcePoint[1]],
    [regionRight, targetPoint[1]]
  ];

  return { sourceAnchor: 'right', targetAnchor: 'right', connectionPoints };
};

/**
 * pinLeft = false:
 * Target
 *   ↑
 *    ← ← ← ← ← ← ←
 *                  ↑
 *             → → →
 *            ↑
 *       DRG Gateway
 *
 * pinLeft = true:
 * Target
 *   ↑
 *   ↑
 *   ↑
 *   → → →→ → →
 *            ↑
 *       DRG Gateway
 */
const connectToAnyConnectionOnPrem = ({
  sourcePoint,
  targetPoint,
  regionLeft,
  regionRight,
  ySpacing,
  sourceNodeHeight,
  targetNodeHeight,
  linkSpacing,
  routeLeft = false
}) => {
  let sideX = regionRight + linkSpacing;

  if (routeLeft === true) {
    sideX = regionLeft - linkSpacing;
  }

  const topY = targetPoint[1] + targetNodeHeight / 2 + ySpacing / 2;
  const bottomY = sourcePoint[1] - sourceNodeHeight / 2 - ySpacing / 2;
  const connectionPoints = [
    [sourcePoint[0], bottomY],
    [sideX, bottomY],
    [sideX, topY],
    [targetPoint[0], topY]
  ];
  return { sourceAnchor: 'top', targetAnchor: 'bottom', connectionPoints };
};

const DrgConnector = ({ targetType, ...rest }) => {
  switch (targetType) {
    // drg <---> drg
    case OCI_ENTITY_TYPES.DYNAMIC_ROUTING_GATEWAY:
      return connectDrgGateways({ ...rest });
    case OCI_ENTITY_TYPES.VIRTUAL_CLOUD_NETWORK:
    case OCI_ENTITY_TYPES.LOCAL_PEERING_GATEWAY:
    case OCI_ENTITY_TYPES.DYNAMIC_ROUTING_GATEWAY_ATTACHMENT:
      return connectToAnyAttachment({ ...rest });
    case OCI_ENTITY_TYPES.IP_SEC_CONNECTION:
      return connectToAnyConnectionOnPrem({ ...rest });
    default:
      return null;
  }
};

export default DrgConnector;
