import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { GiRadarSweep } from 'react-icons/gi';

import { Card, Text, Heading, Flex, ProgressBar, Box, Icon, CalloutOutline } from 'core/components';
import { addCommas, zeroToText } from 'app/util/utils';

import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import LabelValue from './LabelValue';

@observer
class SyntheticsPlanCard extends Component {
  render() {
    const {
      id,
      name,
      description: planDescription,
      free_credits,
      paid_credits,
      cloud_provider,
      trialDaysRemaining,
      usedSyntheticCredits,
      full_retention,
      ...rest
    } = this.props;

    const showPlanDescription = !cloud_provider;
    const isFreeTrialPlan = name === 'Free Trial Plan';
    const icon = <Icon icon={GiRadarSweep} iconSize={40} color="primary" />;
    const max_credits = parseInt(free_credits) + parseInt(paid_credits);

    return (
      <Card p={2} {...rest}>
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Flex flex="1 1 auto" mr={3}>
            {icon && (
              <Box width={40} mr={2}>
                {icon}
              </Box>
            )}

            <Box maxWidth={600} minWidth={350}>
              <Text color="muted" as="div" mb="4px">
                Synthetics
              </Text>

              <Heading level={4} mb={1}>
                {name}{' '}
                <Text fontWeight={200} muted>
                  ({id})
                </Text>
              </Heading>

              {showPlanDescription && (
                <Text color="muted" as="div" mb={1}>
                  {planDescription}
                </Text>
              )}

              <Box mt="4px" width={420}>
                <Box mb={1}>
                  <ProgressBar
                    value={max_credits ? usedSyntheticCredits / max_credits : 0}
                    animate={false}
                    stripes={false}
                    intent={(max_credits ? usedSyntheticCredits / max_credits : 0) > 1 ? 'danger' : 'primary'}
                  />
                </Box>
                <Text fontWeight="bold">
                  {(max_credits ? usedSyntheticCredits / max_credits : 0) >= 1 ? (
                    '> 100%'
                  ) : (
                    <span>{zeroToText((max_credits ? usedSyntheticCredits / max_credits : 0) * 100, { fix: 1 })}%</span>
                  )}
                </Text>{' '}
                credits used
              </Box>

              {isFreeTrialPlan && (
                <CalloutOutline intent="warning" fontWeight="bold" maxWidth={330} mt={1}>
                  <Icon icon={BsFillExclamationTriangleFill} color="warning" mr="4px" />
                  You have {trialDaysRemaining} days remaining in your trial.
                </CalloutOutline>
              )}
            </Box>
          </Flex>
          <Box width={275}>
            <LabelValue label="Used Credits" value={addCommas(parseInt(usedSyntheticCredits))} />
            <LabelValue label="Max Credits" value={addCommas(parseInt(max_credits))} />
            <LabelValue label="Data Retention" value={`${full_retention} days`} mb={0} />
          </Box>
        </Flex>
      </Card>
    );
  }
}

export default SyntheticsPlanCard;
