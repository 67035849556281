import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { select } from 'd3-selection';
import { curveCatmullRom } from 'd3-shape';
import { get } from 'lodash';
import { FaEllipsisH } from 'react-icons/fa';
import { GiRadarSweep } from 'react-icons/gi';
import { FiCloudOff } from 'react-icons/fi';

import {
  AnchorButton,
  Box,
  Card,
  Callout,
  CalloutOutline,
  Flex,
  Grid,
  Heading,
  Spinner,
  Text,
  EmptyState,
  Link,
  Button,
  showInfoToast
} from 'core/components';
import AbstractMap from 'app/views/hybrid/maps/components/AbstractMap';
import withPopover from 'app/views/hybrid/maps/components/popovers/withPopover';
import {
  getPathCounts,
  getEntityName,
  baseTestConfig,
  getMissingTestPaths,
  getTestsFromPaths
} from 'app/views/cloudPerformance/cloudPerformanceUtil';

import HealthStrip from './HealthStrip';
import NodeGroup from './nodes/NodeGroup';
import Regions from './nodes/Regions';
import InterconnectLegend from './InterconnectLegend';
import AgentManagementDialog from './AgentManagementDialog';

@inject('$hybridMap', '$syn')
@observer
class CloudInterconnects extends AbstractMap {
  curveType = curveCatmullRom;

  columnGutterCenter = 35;

  rowGutterCenter = 8;

  constructor(props) {
    super(props);

    Object.assign(this.state, {
      interconnectsData: {}
    });
  }

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener('resize', this.handleDrawLinksRequest);

    this.loadTopologyData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { collection, isAgentManagementOpen, drawerIsOpen } = this.props;

    super.componentDidUpdate(prevProps, prevState);

    const { sidebarSettings } = this.props;
    const mapSearchChanged = prevProps.sidebarSettings.searchTerm !== sidebarSettings.searchTerm;
    const timeSettingsChanged =
      prevProps.sidebarSettings.timeRange.start !== sidebarSettings.timeRange.start ||
      prevProps.sidebarSettings.timeRange.end !== sidebarSettings.timeRange.end;
    const agentManagementClosed = prevProps.isAgentManagementOpen === true && isAgentManagementOpen === false;
    const drawerOpenChanged = prevProps.drawerIsOpen !== drawerIsOpen;

    if (mapSearchChanged) {
      collection.filter(sidebarSettings.searchTerm);
    }

    if (timeSettingsChanged || agentManagementClosed) {
      this.loadTopologyData();
    }

    if (mapSearchChanged || drawerOpenChanged) {
      this.drawLinks();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleDrawLinksRequest);
  }

  loadTopologyData = () => {
    const { collection, sidebarSettings } = this.props;

    this.setState({ loading: true }, () => {
      collection.fetch({ force: true, query: sidebarSettings.timeRange }).then(() => {
        const { interconnectsData } = collection;
        const { topology, links } = interconnectsData;

        collection.filter(sidebarSettings.searchTerm);

        this.setState({
          loading: false,
          interconnectsData,
          nodeLinks: this.parseSynthDataForLinks(links)
        });

        if (topology.request && topology.request.isTimeRangeOverridden) {
          showInfoToast("We've pulled the latest metadata instead.", {
            title: 'No Metadata Found for Given Time Range'
          });
        }
      });
    });
  };

  shouldSourceLinkRollUp() {
    return false;
  }

  shouldTargetLinkRollUp() {
    return false;
  }

  parseSynthDataForLinks(links) {
    return links.map((link) => {
      // check for an agent in any of the paths this link is a part of
      const hasAgentsInPath = link.paths.some((path) => path.some((pathSegment) => pathSegment.agents.length > 0));

      return {
        ...link,
        cssClassnames: { 'no-synthetics-tests': !hasAgentsInPath }
      };
    });
  }

  handleMapClick = (e) => {
    const { setSidebarDetails } = this.props;
    const selectableNode = e.target.closest('.hybrid-map-selectable-node');
    const selectableLink = e.target.closest('.hybrid-map-selectable-link');
    const selectable = selectableNode || selectableLink;

    if (!selectable) {
      this.setState({ hoveredLink: -1, selectedLink: -1, selectedNode: null }, () => setSidebarDetails(null));
    }
  };

  handleNodeLinkClick = (config) => {
    const { openPopover } = this.props;

    return openPopover({
      ...config,
      panel: this.linkPopoverPanel
    });
  };

  getVpcFromNode = (node) => {
    const { collection } = this.props;
    const { nodeLinks } = this.state;
    if (node.type === 'Vpc') {
      return { vpcs: [{ vpc_id: node.VpcId, agent: node.agent }] };
    }

    if (node.agents && node.agents.length) {
      return { vpcs: node.agents.map((agent) => ({ vpc_id: agent.metadata.cloud_vpc, agent })) };
    }

    const targetNodeLinks = nodeLinks.filter(
      (link) => link.target.type === `${node.pathType}-${node.type}` && link.target.value === node.id
    );

    const vpcs = targetNodeLinks.reduce((acc, nodeLink) => {
      const { VpcId } = nodeLink.paths[0][0];
      const pathType = node.pathType || 'directConnect';
      const regionWithVpc = Object.values(collection.nodes[pathType].regions).find((regionVpcs) => regionVpcs[VpcId]);
      if (regionWithVpc) {
        acc[VpcId] = regionWithVpc[VpcId];
      }

      return acc;
    }, {});

    return { vpcs: Object.values(vpcs).map((vpc) => ({ vpc_id: vpc.VpcId, agent: vpc.agent })) };
  };

  getTestFromVpcAgents = (vpcs, lastNode, targetIps = []) => {
    let agentId;
    let testId;
    let testTarget;
    let testTargetIps = targetIps;

    if (lastNode) {
      if (lastNode.type === 'CustomerGateway') {
        testTargetIps = [lastNode.IpAddress];
      }
      if (lastNode.type === 'Router') {
        testTargetIps = Object.values(lastNode.children).map((intf) => intf.interface_ip);
      }
    }

    vpcs.some((vpc) => {
      if (vpc.agent) {
        const matchingTest = vpc.agent.tests.find((test) => testTargetIps.includes(test.config.target.value));
        if (matchingTest) {
          [agentId] = matchingTest.config.agents;
          testId = matchingTest.id;
          testTarget = matchingTest.config.target.value;
        }

        return matchingTest;
      }
      return false;
    });

    return { testId, agentId, testTarget };
  };

  handleSelectNode(config) {
    const { setSidebarDetails } = this.props;
    const { interconnectsData } = this.state;
    const { nodeData } = config;
    const { vpcs } = this.getVpcFromNode(nodeData);

    super.handleSelectNode(config);

    setSidebarDetails({
      ...config,
      ...nodeData,
      type: 'cloudInterconnect',
      subType: nodeData.type,
      links: this.nodeLinks,
      vpcs,
      routeTableSummary: interconnectsData.routeTableSummary,
      topology: interconnectsData.topology,
      ...this.getTestFromVpcAgents(vpcs, null, nodeData.targetIps)
    });
  }

  get offsets() {
    const overlayRect = document.querySelector('.overlay').getBoundingClientRect();
    const gridRect = document.querySelector('.direct-connects-grid').getBoundingClientRect();

    return [gridRect.left, overlayRect.y];
  }

  getNextPoint = (sourcePoint, targetPoint) => {
    const [sourceX, sourceY] = sourcePoint;
    const [targetX] = targetPoint;
    const [xOffset, yOffset] = this.offsets;

    const nodes = Array.from(document.querySelectorAll('.hybrid-map-selectable-node').values()).filter((node) => {
      const { x, top, bottom } = node.getBoundingClientRect();

      // offset compensation
      const nodeX = x - xOffset;
      const nodeTop = top - yOffset;
      const nodeBottom = bottom - yOffset;

      const isBlocking =
        nodeX > sourceX && // the node is to the right of the source
        nodeX < targetX && // the node is to the left of the target
        sourceY >= nodeTop &&
        sourceY <= nodeBottom; // the node is within the line of sight of the source point

      return isBlocking;
    });

    if (nodes.length > 0) {
      const nodeRect = nodes[0].getBoundingClientRect();
      const connectingX = nodeRect.x - xOffset - this.columnGutterCenter;
      const connectingY = nodeRect.y - yOffset - this.rowGutterCenter;

      return [connectingX, connectingY];
    }

    return null;
  };

  // assembles a list of connecting points that route around nodes
  getControlPoints = (sourcePoint, targetPoint) => {
    let currentPoint = sourcePoint;
    const points = [];

    while (currentPoint) {
      currentPoint = this.getNextPoint(currentPoint, targetPoint);

      if (currentPoint) {
        points.push(currentPoint);
      }
    }

    if (points.length > 0) {
      const lastPoint = points[points.length - 1];
      const lastConnectingPoint = [
        targetPoint[0] - this.columnGutterCenter, // same x as the target
        lastPoint[1] // maintain the y
      ];

      points.push(lastConnectingPoint);
    }

    return points;
  };

  getNodeLinkPositions = ({ source, target }) => {
    const { points: sourcePoints, anchors: sourceAnchors } = source;
    const { points: targetPoints, anchors: targetAnchors } = target;

    if (sourcePoints.length === 0 || targetPoints.length === 0) {
      return { source: { points: [] }, target: { points: [] } };
    }

    const yDelta = Math.abs(sourcePoints[0][1] - targetPoints[0][1]);
    const controlPoints = this.getControlPoints(sourceAnchors.right, targetAnchors.left);

    // for now we'll assume connecting left to right
    sourcePoints.splice(0, 1, sourceAnchors.right);
    targetPoints.splice(0, 1, targetAnchors.left);

    if (yDelta >= 20) {
      // always start in the middle of a column gutter
      sourcePoints.push([
        sourcePoints[0][0] + 10, // padded by half the width of a column gutter
        sourcePoints[0][1]
      ]);

      // always end in the middle of a column gutter too
      targetPoints.push([
        targetPoints[0][0] - 10, // padded by half the width of a column gutter
        targetPoints[0][1]
      ]);
    }

    if (yDelta === 0) {
      // this is a hack to ensure a 'straight' line can be drawn between nodes
      sourcePoints[0][1] += 1;
    }

    return {
      source: { ...source, points: [...sourcePoints, ...controlPoints] },
      target: { ...target, points: targetPoints }
    };
  };

  getLinkPathIds = (paths) => paths.flatMap((path) => path.map((n) => n && n.linkPathId));

  highlightLinks() {
    const { collection } = this.props;

    super.highlightLinks();

    // overrides logic for node links to highlight by 'path' instead of link segment

    const { hoveredNode, hoveredLink, selectedLink, selectedNode, nodeLinks: links } = this.state;
    const { nodeIdsMatchingFilter, linkPathIdsMatchingFilter } = collection;
    const hasMatchingFilters = nodeIdsMatchingFilter.length > 0;
    const nodeLinks = select(this.svg.current).selectAll('.links-node .link');
    const nodes = select(document.body).selectAll('.hybrid-map-selectable-node');
    const link = links[hoveredLink] || links[selectedLink];
    const nodeIds = (link && this.getLinkPathIds(link.paths)) || [];
    const isHoveringLink = hoveredLink > -1 || selectedLink > -1;
    const isHoveringNode = !!hoveredNode;
    const nodeIdsInPath = Array.from(
      links.reduce((acc, l) => {
        const matchingSet = [hoveredNode].concat(linkPathIdsMatchingFilter);
        const ids = this.getLinkPathIds(l.paths.filter((path) => path.find((n) => matchingSet.includes(n.linkPathId))));

        if (ids.includes(hoveredNode) || hasMatchingFilters) {
          ids.forEach((id) => acc.add(id));
        }

        return acc;
      }, new Set())
    );
    let reFilterMatch = null;

    if (hasMatchingFilters) {
      reFilterMatch = new RegExp(nodeIdsMatchingFilter.join('|'));
    }

    nodeLinks.classed('link-hovered', (d) => {
      if (isHoveringNode) {
        const pathMatches = d.paths.filter((path) => path.find((p) => p.linkPathId === hoveredNode));
        return pathMatches.length > 0;
      }

      if (link) {
        // highlight the link because it shares a complete path with the currently hovered link
        const sharedPaths = d.pathIds.filter((currentPathId) =>
          link.pathIds.find((linkPathId) => currentPathId === linkPathId)
        );

        return sharedPaths.length > 0;
      }

      return false;
    });

    nodeLinks.classed('link-unhovered', (d) => {
      if (isHoveringNode) {
        const pathMatches = d.paths.filter((path) => path.find((p) => p.linkPathId === hoveredNode));
        return pathMatches.length === 0;
      }

      if (isHoveringLink && link) {
        // this link is unhovered because it does not share a path with the currently hovered link
        const sharedPaths = d.pathIds.filter((currentPathId) =>
          link.pathIds.find((linkPathId) => currentPathId === linkPathId)
        );

        return sharedPaths.length === 0;
      }

      return false;
    });

    nodeLinks.classed('matches-filter', (d) => {
      if (reFilterMatch) {
        return d.pathIds.some((pathId) => reFilterMatch.test(pathId));
      }

      return false;
    });

    nodeLinks.classed('does-not-match-filter', (d) => {
      if (isHoveringNode) {
        return false;
      }

      if (reFilterMatch) {
        return d.pathIds.every((pathId) => !reFilterMatch.test(pathId));
      }

      return false;
    });

    nodeLinks.classed('link-health-good', (d) => d.health === 'good' || d.pathHealth === 'good');
    nodeLinks.classed('link-health-warning', (d) => d.health === 'warning' || d.pathHealth === 'warning');
    nodeLinks.classed('link-health-critical', (d) => d.health === 'critical' || d.pathHealth === 'critical');

    nodes.classed('hovered', function hoveredCallback() {
      if (isHoveringNode || hasMatchingFilters) {
        return nodeIdsInPath.includes(this.id);
      }

      if (selectedNode) {
        return selectedNode.value === this.id;
      }

      return nodeIds.includes(this.id);
    });

    nodes.classed('unhovered', function unhoveredCallback() {
      if (isHoveringNode) {
        return !nodeIdsInPath.includes(this.id);
      }

      return isHoveringLink && !nodeIds.includes(this.id);
    });

    nodeLinks.filter('.link-hovered').raise();
  }

  onCreateTestClick = () => {
    const { $syn, onPopoverClose } = this.props;
    const { selectedLink } = this.state;
    const link = this.nodeLinks[selectedLink];

    const { paths } = link;

    onPopoverClose();

    return Promise.all(
      getMissingTestPaths(paths).map((path) => {
        const [firstNode] = path;
        const target = path[path.length - 1];
        const { agent } = firstNode;
        const newTest = {
          test_type: 'ip-address',
          config: {
            ...baseTestConfig,
            name: `${agent.metadata.cloud_vpc} to ${getEntityName(target)} Test (IP Address)`,
            agents: [agent.id],
            target: { value: target.type === 'CustomerGateway' ? target.IpAddress : target.targetIps.join(',') }
          }
        };
        return $syn.tests.forge(newTest).save();
      })
    ).then(() => {
      this.loadTopologyData();
    });
  };

  get linkPopoverPanel() {
    const { selectedLink } = this.state;
    const link = this.nodeLinks[selectedLink];
    let hasTest = false;
    let hasAgent = false;

    if (link) {
      const { agents, targetIps } = link;
      hasAgent = !!agents.length;
      // does at least one of the agents have a test with a target that is included in our list of target ips?
      hasTest = agents.some(
        (agent) =>
          agent.tests &&
          agent.tests.some((test) => targetIps && targetIps.toString() === get(test, 'config.target.value'))
      );
    }

    return (
      <Flex flexDirection="column" p={1} width={250}>
        {this.linkSummary}
        <AnchorButton icon={FaEllipsisH} text="Show Details" onClick={this.handleLinkClick} alignText="left" minimal />
        {hasAgent && !hasTest && (
          <AnchorButton
            icon={GiRadarSweep}
            text="Create Test"
            alignText="left"
            minimal
            onClick={this.onCreateTestClick}
          />
        )}
      </Flex>
    );
  }

  renderSyntheticsTestWarning = (nodes) => {
    const nodeRegionValues = Object.values(nodes.regions);
    const { hasAgent, hasTest } = nodeRegionValues.reduce(
      (acc, region) => {
        // get the list of vpcs
        const vpcs = region.nodeGroups.find((nodeGroup) => nodeGroup.length > 0 && nodeGroup[0].type === 'Vpc');

        if (vpcs) {
          if (!acc.hasAgent) {
            // keep trying if we haven't already found an agent
            acc.hasAgent = vpcs.some((vpc) => vpc.agent);
          }

          if (!acc.hasTest) {
            // keep trying if we haven't found a test
            acc.hasTest = vpcs.some((vpc) => vpc.agent && vpc.agent.tests?.length);
          }
        }

        return acc;
      },
      {
        hasAgent: false,
        hasTest: false
      }
    );
    if (!hasAgent) {
      return (
        <CalloutOutline intent="warning" p={2} my={2}>
          <Text as="div">
            <Text fontWeight="bold">Warning: </Text>
            No Synthetics agents deployed
          </Text>
          <Text small>
            Deploy Synthetic agents in order to create tests to see latency, packet loss and jitter across your Direct
            Connect and Site-to-Site VPNs. To get started, select a VPC to deploy our Synthetic agent and then create a
            test.
          </Text>
        </CalloutOutline>
      );
    }
    if (!hasTest) {
      return (
        <CalloutOutline intent="warning" p={2} my={2}>
          <Text as="div">
            <Text fontWeight="bold">Warning: </Text>
            No Synthetics tests configured
          </Text>
          <Text small>
            Configure Synthetic tests to see latency, packet loss and jitter across your Direct Connect and Site-to-Site
            VPNs. To get started, select a target router to create a synthetic test.
          </Text>
        </CalloutOutline>
      );
    }
    return null;
  };

  handleNodeClick = (node) => {
    if (node.type !== 'DirectConnection') {
      const { cloudProvider } = this.props;

      this.handleSelectNode({ nodeData: node, value: node.id, cloudProvider });
    }
  };

  handleLinkClick = () => {
    const { cloudProvider, setSidebarDetails, onPopoverClose } = this.props;
    const { selectedLink } = this.state;
    const link = this.nodeLinks[selectedLink];

    const path = link.paths[0]; // @TODO taking the first path for now
    const { vpcs } = this.getVpcFromNode(path[0]);
    const lastNode = path[path.length - 1];
    const test = this.getTestFromVpcAgents(vpcs, lastNode);

    onPopoverClose();

    setSidebarDetails({
      type: 'cloudInterconnect',
      subType: 'link',
      cloudProvider,
      links: [],
      ...link,
      vpcs,
      ...test
    });
  };

  handleMouseEnter = (e) => this.setState({ hoveredNode: e.target.id });

  handleMouseLeave = () => this.setState({ hoveredNode: null });

  handleModelChange = ({ type, model }) => {
    const { collection, onAgentModelChange } = this.props;

    if (type === 'challenge' || type === 'save') {
      collection.updateAgent(model);
    }

    if (onAgentModelChange) {
      onAgentModelChange({ type, model });
    }
  };

  get linkSummary() {
    const { nodeLinks, hoveredLink, selectedLink } = this.state;

    if (hoveredLink > -1 || selectedLink > -1) {
      const link = nodeLinks[hoveredLink] || nodeLinks[selectedLink];

      const { hasTestCount, totalCount } = getPathCounts(link.paths);

      return (
        <Callout border="thin" mb={1}>
          {`${hasTestCount} of ${totalCount} path${totalCount !== 1 ? 's' : ''} configured with tests`}
          <HealthStrip
            link={link}
            tests={getTestsFromPaths(link.paths)}
            hasAgents={link.paths.every((path) => path[0].agent)}
          />
        </Callout>
      );
    }

    return null;
  }

  get emptyCallout() {
    return (
      <Text muted as="div">
        Hover over a link to see the connection path. Click on nodes and links to view more details.
      </Text>
    );
  }

  renderNodeMap = (nodes, title, pathType) => {
    const { regions, nodeGroups, columnCount } = nodes;

    if (columnCount === 0) {
      return null;
    }

    return (
      <Box mb={2} position="relative">
        <Flex alignItems="center" justifyContent="space-between">
          <Heading level={3} mb={0}>
            {title}
          </Heading>
          <InterconnectLegend />
        </Flex>
        <Box my={2}>{this.renderSyntheticsTestWarning(nodes)}</Box>
        <Grid
          gridTemplateColumns={`repeat(${columnCount}, 1fr)`}
          gridColumnGap="70px"
          alignItems="center"
          className="direct-connects-grid"
          mb={2}
        >
          <Regions
            pathType={pathType}
            regions={regions}
            onNodeClick={this.handleNodeClick}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          />

          {(nodeGroups || []).map((nodeGroup) => {
            const key = nodeGroup.map((node) => node.id).join('-');
            return (
              <NodeGroup
                key={key}
                pathType={pathType}
                nodes={nodeGroup}
                onNodeClick={this.handleNodeClick}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
              />
            );
          })}
        </Grid>
      </Box>
    );
  };

  onDismissEmptyStateClick = (type) => {
    window.localStorage.setItem(`cloud-interconnects-dismiss-emptystate-${type}`, true);
  };

  renderEmptyState(type) {
    const isDirectConnect = type === 'directConnect';
    let title = isDirectConnect ? 'No Direct Connects Found' : 'No Site-to-Site VPNs Found';
    let description = isDirectConnect ? 'Direct Connects' : 'Site-to-Site VPNs';
    if (type === 'both') {
      title = 'No Interconnections Found';
      description = 'Direct Connections or Site-to-Site VPNs';
    }

    if (window.localStorage.getItem(`cloud-interconnects-dismiss-emptystate-${type}`)) {
      return null;
    }

    return (
      <Card p={2} mb={2}>
        <EmptyState
          icon={<FiCloudOff />}
          title={title}
          description={
            <Box>
              <Text as="div">No {description} detected. If you expected to see these entities here:</Text>
              <ol>
                <li>
                  Ensure that Kentik has an{' '}
                  <Link to="https://kb.kentik.com/v0/Bd06.htm#Bd06-Create_a_Cloud_in_Kentik" blank>
                    export configured for the account and region
                  </Link>{' '}
                  where the {description} are configured
                </li>
                <li>
                  Ensure that this account has a role configured with the{' '}
                  <Link to="https://kb.kentik.com/v0/Bd06.htm#Bd06-Create_an_AWS_Role" blank>
                    necessary permissions
                  </Link>
                </li>
              </ol>
            </Box>
          }
          action={
            type !== 'both' && <Button small text="Dismiss" onClick={() => this.onDismissEmptyStateClick(type)} />
          }
        />
      </Card>
    );
  }

  renderMap() {
    const { collection, isAgentManagementOpen, toggleAgentManagement, agentModel, onAgentFormDialogClose } = this.props;
    const { loading } = this.state;

    if (loading) {
      return <Spinner />;
    }

    const { siteToSite, directConnect } = collection.nodes;
    const hasDirectConnect = Object.keys(directConnect.regions || {}).length || (directConnect.pathways || []).length;
    const hasSiteToSite = Object.keys(siteToSite.regions || {}).length || (siteToSite.pathways || []).length;

    if (!hasDirectConnect && !hasSiteToSite) {
      return this.renderEmptyState('both');
    }
    return (
      <Box>
        <Box my={2}>{this.emptyCallout}</Box>
        {this.renderNodeMap(directConnect, 'Direct Connects', 'directConnect')}
        {this.renderNodeMap(siteToSite, 'Site-to-Site VPNs', 'siteToSite')}
        {!hasDirectConnect && this.renderEmptyState('directConnect')}
        {!hasSiteToSite && this.renderEmptyState('siteToSite')}
        <AgentManagementDialog
          agentIds={collection.agentIds}
          uninstalledVpcs={collection.uninstalledVpcs}
          model={agentModel}
          isAgentManagementOpen={isAgentManagementOpen}
          toggleAgentManagement={toggleAgentManagement}
          onModelChange={this.handleModelChange}
          onAgentFormDialogClose={onAgentFormDialogClose}
        />
      </Box>
    );
  }
}

export default withPopover(CloudInterconnects, { position: 'top', positionOffset: { left: -10 } });
