import React, { Component } from 'react';
import { DateTime, Field, formConsumer, Switch, Select, InputGroup } from 'core/form';
import { COMPANY_PLAN_MAP, COMPANY_PLATFORM_MAP, COMPANY_STATUS_MAP } from 'app/stores/sudo/util';
import { Box, Card, Flex } from 'core/components';
import { observer } from 'mobx-react';
import AccountTeamCard from 'app/views/licenses/AccountTeamCard';
import LargeLabeledValue from 'app/components/LargeLabeledValue';
import { fromNow } from 'core/util/dateUtils';
import { buildOptionsFromMap } from '../../../core/form/components/modalSelect/selectHelpers';

@formConsumer
@observer
export default class CompanyOverview extends Component {
  render() {
    const { form } = this.props;
    return (
      <Flex gutter={2} justifyContent="space-between">
        <Box>
          <Flex mb={2}>
            <Field name="company_plan_id" options={buildOptionsFromMap(COMPANY_PLAN_MAP)} mr={2}>
              <Select />
            </Field>
            {form.getValue('company_plan_id') === 'TRIAL' && (
              <Field name="trial_end_date" mr={2}>
                <DateTime fill format="YYYY-MM-DD" timePrecision={null} />
              </Field>
            )}
            <Field name="company_status" options={buildOptionsFromMap(COMPANY_STATUS_MAP)} mr={2}>
              <Select />
            </Field>
            <Field name="platform" options={buildOptionsFromMap(COMPANY_PLATFORM_MAP)}>
              <Select />
            </Field>
          </Flex>
          <Flex mb={2}>
            <Field name="company_max_user">
              <InputGroup type="numeric" />
            </Field>
          </Flex>
          <Flex mb={2}>
            <Field name="settings.excludeDataRights" mr={2}>
              <Switch />
            </Field>
            <Field name="settings.allowBGPSharing">
              <Switch />
            </Field>
          </Flex>
          <Flex flex={1}>
            <Field name="settings.tags" flex={1}>
              <Select multi fill />
            </Field>
          </Flex>
        </Box>

        <Box>
          <Card p={2} mb={2}>
            <LargeLabeledValue label="Company ID" value={form.model.id} shouldFormatValue={false} mb={1} />
            <LargeLabeledValue
              label="Created"
              value={fromNow(form.model.get('cdate'))}
              shouldFormatValue={false}
              mb={1}
            />
            <LargeLabeledValue
              label="Last Updated"
              value={fromNow(form.model.get('edate'))}
              shouldFormatValue={false}
              mb={1}
            />
            <LargeLabeledValue
              label="Last Login"
              value={form.model.lastLogin ? fromNow(form.model.lastLogin) : 'N/A'}
              shouldFormatValue={false}
            />
          </Card>
          {form.model.get('accountTeam')?.length > 0 && (
            <AccountTeamCard accountTeam={form.model.get('accountTeam')} hideContact />
          )}
        </Box>
      </Flex>
    );
  }
}
