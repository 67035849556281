import React from 'react';

import { isObject } from 'lodash';
import { inject } from 'mobx-react';
import { Flex } from 'core/components';

import SidebarItem from '../SidebarItem';
import { AzureVpnSiteLinkDetails } from './components';

const AzureVpnSiteLinkesPopover = ({ width, popoutTitle, $hybridMap, nodeData }) => {
  const { azureCloudMapCollection } = $hybridMap;

  let vpnSiteLinks = azureCloudMapCollection.getEntityProperty(nodeData, 'vpnSiteLinks') ?? [];

  if (!Array.isArray(vpnSiteLinks) && isObject(vpnSiteLinks)) {
    vpnSiteLinks = [vpnSiteLinks];
  }

  // current node doesnt have connections
  if (vpnSiteLinks.length === 0) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title="Vpn Site Links"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="backlink"
      popoutTitle={popoutTitle}
    >
      <Flex px="4px" gap={1} flexDirection="column">
        <Flex>
          <Flex flexDirection="column" gap={1}>
            {vpnSiteLinks.map((vpnSiteLink) => (
              <AzureVpnSiteLinkDetails
                key={vpnSiteLink.id}
                nodeData={vpnSiteLink}
                popoutTitle={popoutTitle}
                width={width}
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureVpnSiteLinkesPopover);
