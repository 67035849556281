import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { connectAlignedNodes, connectToNetwork } from './commonUtils';

const { NETWORK, CLOUD_ROUTER, INTERCONNECT_ATTACHMENT, VPN_GATEWAY, VPN_TUNNEL } = ENTITY_TYPES.get('gcp');
const { EXPRESS_ROUTE_CIRCUIT, VNET_GATEWAY_CONNECTION, VPN_LINK_CONNECTION } = ENTITY_TYPES.get('azure');

/**
 * example:
 * CustomerRouter
 *   ↓
 *   ↓
 *   ↓
 *   ↓
 *   ↓
 *   → → → → → → →
 *                 ↓
 *               Target
 */
const connectToDirectConnectGateway = ({ sourcePoint, targetPoint, ySpacing, regionLeft }) => {
  const bottomY = targetPoint[1] - ySpacing;
  const topY = sourcePoint[1] + ySpacing;

  const connectionPoints = [
    [sourcePoint[0], topY],
    [regionLeft, topY],
    [regionLeft, bottomY],
    [targetPoint[0], bottomY]
  ];
  return {
    sourceAnchor: 'bottom',
    targetAnchor: 'top',
    connectionPoints
  };
};

const connectToExpressRoute = ({ sourcePoint, targetPoint, ySpacing, xSpacing, xDirectionModifier }) => {
  const bottomY = targetPoint[1] - ySpacing;
  const topY = sourcePoint[1] + ySpacing;

  const connectionPoints = [
    [sourcePoint[0], topY],
    [sourcePoint[0] + xSpacing * xDirectionModifier, topY],
    [sourcePoint[0] + xSpacing * xDirectionModifier, bottomY],
    [targetPoint[0], bottomY]
  ];
  return {
    sourceAnchor: 'bottom',
    targetAnchor: 'top',
    connectionPoints
  };
};

/**
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const CustomerRouterConnector = ({ targetType, ...rest }) => {
  const alignedConnection = connectAlignedNodes({ ...rest });
  switch (targetType) {
    case 'Lags':
    case 'VpnConnections':
    case 'DirectConnections':
    case 'DirectConnectGateways':
      return alignedConnection !== null ? alignedConnection : connectToDirectConnectGateway({ ...rest });
    /** @TODO other target types */
    case VPN_LINK_CONNECTION:
    case EXPRESS_ROUTE_CIRCUIT:
    case VNET_GATEWAY_CONNECTION:
      return connectToExpressRoute({ ...rest });
    case NETWORK:
      return connectToNetwork({ ...rest });
    case CLOUD_ROUTER:
      return connectToNetwork({ ...rest, targetAnchor: 'bottom' });
    case INTERCONNECT_ATTACHMENT:
      return alignedConnection ?? connectToDirectConnectGateway({ ...rest });
    case VPN_GATEWAY:
      return alignedConnection ?? connectToDirectConnectGateway({ ...rest });
    case VPN_TUNNEL:
      return connectToNetwork({ ...rest });
    default:
      return null;
  }
};

export default CustomerRouterConnector;
