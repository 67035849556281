import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Icon, Link, Text, Tooltip } from 'core/components';

const StyledLink = styled(Link)`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 12px 16px;
  width: max-content;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-decoration: none;
  }

  &:focus {
    outline: 1px dotted #ffffff;
    outline-offset: 4px;
  }
`;

const OnboardingNextButton = withTheme((props) => {
  const { to, text, disabled, tooltipContent, ...rest } = props;

  const link = (
    <StyledLink to={disabled ? '#' : to} {...rest}>
      <Text fontSize={16} color="#ffffff" fontWeight="medium">
        Next: {text}
      </Text>
      <Icon iconSize={20} color="#60ACFF" icon="circle-arrow-right" ml={1} />
    </StyledLink>
  );

  if (disabled) {
    return (
      <Tooltip intent="danger" content={tooltipContent}>
        {link}
      </Tooltip>
    );
  }

  return link;
});

export default OnboardingNextButton;
