import React, { Component } from 'react';
import { observer } from 'mobx-react';

import AdminFilterSidebar from 'app/components/admin/AdminFilterSidebar';

@observer
class RbacRoleFilterSidebar extends Component {
  get filterTypeHash() {
    return {
      type: {
        label: 'Type',
        placeholder: '???',
        allowMultiSelect: true,
        showCheckboxes: true,
        filter: (model, values) => values.includes(model.get('group'))
      },
      enabled: {
        label: 'Status',
        placeholder: '???',
        allowMultiSelect: true,
        showCheckboxes: true,
        filter: (model, values) => values.includes(model.get('isEnabled'))
      }
    };
  }

  get typeOptions() {
    const { collection } = this.props;
    const optionsSet = new Set();

    collection.get().forEach((model) => {
      optionsSet.add(model.get('group'));
    });
    return [...optionsSet].map((value) => ({ value, label: value }));
  }

  get enabledOptions() {
    return [
      { value: true, label: 'Full Access' },
      { value: false, label: 'Restricted' }
    ];
  }

  render() {
    const { filterTypeHash, typeOptions, enabledOptions } = this;

    const filterFields = [
      {
        name: 'enabled',
        options: enabledOptions,
        ...filterTypeHash.enabled
      },
      {
        name: 'type',
        options: typeOptions,
        ...filterTypeHash.type
      }
    ];

    return <AdminFilterSidebar {...this.props} filterFields={filterFields} />;
  }
}

export default RbacRoleFilterSidebar;
