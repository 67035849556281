import React, { Component } from 'react';
import { defaultsDeep, sumBy } from 'lodash';
import { inject, observer } from 'mobx-react';
import { MdAssessment } from 'react-icons/md';
import { Box, CalloutOutline, Card, Flex, Heading, Icon, Link, ProgressBar, Text } from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import { addCommas } from 'app/util/utils';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { COMPANY_MPS_HISTORY_QUERY, COMPANY_MPS_QUERY } from 'app/views/metrics/queries/index.js';
import MetricsExplorerLink from 'app/views/metrics/MetricsExplorerLink.js';
import { getMaxMps } from 'app/util/plans';
import LabelValue from './LabelValue';

const COMPANY_MPS_QUERY_TS = defaultsDeep({ kmetrics: { includeTimeseries: 10 } }, COMPANY_MPS_QUERY);

@inject('$devices', '$metrics', '$setup')
@observer
class MetricsPlanCard extends Component {
  render() {
    const {
      id,
      name: planName,
      description: planDescription,
      device_count: planDeviceCount,
      max_devices,
      max_fps,
      free_credits,
      paid_credits,
      pak_fps,
      cloud_provider,
      fast_retention,
      full_retention,
      plan_type,
      trialDaysRemaining,
      usedSyntheticCredits,
      $devices,
      $metrics,
      $setup,
      ...rest
    } = this.props;

    const name = planName;
    const icon = <Icon icon={MdAssessment} iconSize={40} color="primary" />;
    const isFreeTrialPlan = planName === 'Free Trial Plan';
    const device_count = $setup.nmsDeviceCount;
    // metricsPerSec includes all metrics for the company, which may include devices in flow plans
    // that also send metrics, so use $metrics.deviceCollection instead of planDevices.
    // const planDevices = $devices.deviceSummaries.filter(device => device.plan_id === id);

    return (
      <Card p={2} {...rest}>
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Flex flex="1 1 auto" mr={3}>
            <Box width={40} mr={2}>
              {icon}
            </Box>
            <Box maxWidth={600} minWidth={350}>
              <Text color="muted" as="div" mb="4px">
                MetricsPak
              </Text>
              <Heading level={4} mb={1}>
                {name}{' '}
                <Text fontWeight={200} muted>
                  ({id})
                </Text>
              </Heading>
              <Text color="muted" as="div" mb={1}>
                {planDescription}
              </Text>

              {device_count === 0 && <Text color="muted">No devices have been added to this plan.</Text>}

              {device_count > 0 && (
                <LightweightDataViewWrapper query={COMPANY_MPS_QUERY}>
                  {({ loading, results }) => {
                    if (!loading && results?.models.length > 0) {
                      let progressIntent = 'none';
                      let progressPercent = 0;
                      const planMaxMps = getMaxMps({ plan_type, pak_fps, max_fps });
                      const avgMps = sumBy(results.models, (res) => res.get('avg_metricsPerSec'));
                      const progressValue = loading || !avgMps ? 1 : avgMps / planMaxMps;

                      if (avgMps) {
                        progressIntent = Math.ceil(avgMps) > planMaxMps ? 'danger' : 'primary';
                        progressPercent = planMaxMps === 0 ? 100 : progressValue * 100;
                      }

                      return (
                        <Box mt="4px" width={420}>
                          <Box mb={1}>
                            <ProgressBar
                              value={progressValue}
                              animate={loading}
                              stripes={loading}
                              intent={progressIntent}
                            />
                          </Box>
                          <Flex justifyContent="space-between" alignItems="center">
                            {loading ? (
                              <Text color="muted">Loading...</Text>
                            ) : (
                              <>
                                <Flex alignItems="center">
                                  <div>
                                    <Text fontWeight="bold">
                                      {avgMps ? addCommas(Math.ceil(avgMps)) : 0} metrics/second
                                    </Text>{' '}
                                    <MetricsExplorerLink query={COMPANY_MPS_QUERY_TS}>
                                      in the last hour
                                    </MetricsExplorerLink>
                                    {' | '}
                                    <MetricsExplorerLink query={COMPANY_MPS_HISTORY_QUERY}>
                                      30 day history
                                    </MetricsExplorerLink>
                                  </div>
                                </Flex>
                                <Text color="muted">{progressPercent.toFixed(2)}%</Text>
                              </>
                            )}
                          </Flex>
                        </Box>
                      );
                    }
                    return <></>;
                  }}
                </LightweightDataViewWrapper>
              )}

              {isFreeTrialPlan && (
                <CalloutOutline intent="warning" fontWeight="bold" maxWidth={330} mt={1}>
                  <Icon icon={BsFillExclamationTriangleFill} color="warning" mr="4px" />
                  You have {trialDaysRemaining} days remaining in your trial.
                </CalloutOutline>
              )}
            </Box>
          </Flex>

          <Box width={275}>
            <LabelValue label="Plan MPS" value={addCommas(max_fps)} />
            <LabelValue
              label="Devices"
              value={
                <>
                  <Box as="span" pr="4px">
                    {device_count} of {max_devices} {device_count > 0 && <Link to="/v4/nms/devices">(Show)</Link>}
                  </Box>
                </>
              }
            />
            <LabelValue
              label="ICMP-Only Devices"
              value={
                <>
                  <Box as="span">{$setup.nmsIcmpOnlyDeviceCount}</Box>
                </>
              }
            />
            <LabelValue label="Data Retention" value={`${full_retention} days`} mb={0} />
          </Box>
        </Flex>
      </Card>
    );
  }
}

export default MetricsPlanCard;
