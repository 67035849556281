import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import Box from 'core/components/Box';
import Button from 'core/components/Button';

const PresetFilterButtons = (props) => {
  const { collection, className, hideFilters = [], showLabel = true, ml = 2, ...otherProps } = props;
  const { activePresetFilter, presetFilters } = collection;

  if (!presetFilters || presetFilters.length === 0) {
    return null;
  }

  const buttonGroupClassName = classNames('bp4-button-group', className);
  const allButtonClassName = classNames({ 'bp4-active': !collection.isRequestActive() && !activePresetFilter });

  // handle "toggling"
  const handleFilterSelect = (filter) => {
    if (collection.activePresetFilter && collection.activePresetFilter.label === filter.label) {
      collection.setPresetFilter();
    } else {
      collection.setPresetFilter(filter);
    }
  };

  const loadingOrNotFetched = collection.isRequestActive() || (!collection.hasFetched && collection.size === 0);

  return (
    <Box ml={ml} className={buttonGroupClassName} {...otherProps}>
      <Button
        className={allButtonClassName}
        onClick={() => collection.setPresetFilter()}
        text="All"
        disabled={loadingOrNotFetched}
      />
      {presetFilters.map((filter) => {
        if (hideFilters.includes(filter.label)) {
          return null;
        }

        return (
          <Button
            active={!collection.isRequestActive() && activePresetFilter && activePresetFilter.label === filter.label}
            className={filter.className}
            disabled={filter.disabled || loadingOrNotFetched}
            icon={filter.iconName}
            intent={filter.intent}
            key={filter.label}
            onClick={() => handleFilterSelect(filter)}
          >
            {showLabel && filter.label}
          </Button>
        );
      })}
    </Box>
  );
};

export default observer(PresetFilterButtons);
