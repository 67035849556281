import VerifyTwoFactor from 'app/views/login/VerifyTwoFactor';
import ProtectedRoute from 'app/components/ProtectedRoute';
import { LoginWrapper } from 'app/views/login/LoginWrapper';
import { Route, Switch } from 'react-router-dom';
import React from 'react';
import { LoginEntry } from 'app/views/login/Login';
import SsoLookup from 'app/views/login/SsoLookup';
import Password from 'app/views/login/password/Password';
import { LOGIN } from '@kentik/ui-shared/paths/login';

export const LoginRoutes = () => (
  <LoginWrapper>
    <Switch>
      <Route path={LOGIN.SSO_COMPANY} component={LoginEntry} />
      <ProtectedRoute exact skip2fa path={LOGIN.TWO_FACTOR} component={VerifyTwoFactor} />
      <Route exact path={LOGIN.SSO_LOOKUP} component={SsoLookup} />
      <Route exact path={LOGIN.PASSWORD_RECOVERY} component={Password} />
      <Route
        exact
        path={LOGIN.ACCOUNT_ACTIVATION}
        render={(routeProps) => <Password {...routeProps} accountActivation />}
      />
      <Route path={LOGIN.ENTRY} component={LoginEntry} />
    </Switch>
  </LoginWrapper>
);
