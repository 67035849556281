import * as React from 'react';
import { Box } from 'core/components';

import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import CoreNetworkAttachmentDetails from './CoreNetworkAttachmentDetails';

const CoreNetworkSummary = ({ data, width, popoutTitle, region = '', ...rest }) => {
  if (!data) {
    return null;
  }

  const edgeAttachments = data.Attachments?.filter((attachment) => attachment.EdgeLocation === region) ?? [];

  return (
    <>
      <CloudMetadata
        node={{ ...data }}
        keys={['id', 'Name', 'OwnerAccountId', 'CoreNetworkId', 'GlobalNetworkId', 'State', 'Tags']}
      />
      {edgeAttachments.length > 0 && (
        <SidebarItem
          excludeFormProps
          title="Attachments"
          dialogProps={{ width }}
          fullscreenProps={{ headingOffset: -16 }}
          icon="lock"
          popoutTitle={popoutTitle}
        >
          <Box>
            {edgeAttachments.map((attachment) => (
              <CoreNetworkAttachmentDetails
                key={attachment.id}
                width={width}
                popoutTitle={popoutTitle}
                data={attachment}
                {...rest}
              />
            ))}
          </Box>
        </SidebarItem>
      )}
    </>
  );
};

export default CoreNetworkSummary;
