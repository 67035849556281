import React, { useState } from 'react';

import { inject } from 'mobx-react';
import { Box, Flex, Heading, Tag } from 'core/components';
import { FaCheck } from 'react-icons/fa';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { getCustomProperties } from 'shared/util/map';
import { GCP_ENTITY_TYPES } from 'shared/hybrid/constants';
import { greekDisplay } from '../cloudUtil';

import SidebarItem from '../SidebarItem';
import { FirewallRulesTable } from './FirewallRulesComponents';

const { NETWORK, FIREWALL_RULE } = GCP_ENTITY_TYPES;

const FirewallRulesPopover = (props) => {
  const [isPoppedOut, setIsPoppedOut] = useState(false);

  const handlePopOutChange = (prevPopped) => {
    setIsPoppedOut(!!prevPopped);
  };

  const { width, popoutTitle, cloudProvider, $hybridMap, ...rest } = props;
  const { nodeData, type } = rest;

  // Firewall Rules metadata has no subnet or vm instance info
  // so no real way to scope these fw rules to a subnet
  if (type !== NETWORK) {
    return null;
  }

  const { gcpCloudMapCollection } = $hybridMap;
  const customProps = getCustomProperties(nodeData);

  const allFirewallRules = gcpCloudMapCollection.getActiveEntities({ entityType: FIREWALL_RULE, allowUnlinked: true });

  if (!allFirewallRules.length) {
    return null;
  }

  const networkFirewalls = allFirewallRules.filter((fw) => {
    // firewallRules array is created per api response
    // so project and network metadata will be same here regardless of projectFwRules.length
    const { project, network } = fw;

    return project === customProps.project && network === customProps.network;
  });

  return (
    <SidebarItem
      excludeFormProps
      title={
        <Flex alignItems="center" gap={1} width="100%">
          <Heading level={5} mb={0}>
            Firewall Rules
          </Heading>
          {networkFirewalls.length > 0 && (
            <Tag
              intent="success"
              icon={FaCheck}
              title={`${networkFirewalls.length} active firewall rules`}
              minimal
              round
              flexGrow={1}
            >
              {greekDisplay(networkFirewalls.length)}
            </Tag>
          )}
        </Flex>
      }
      dialogProps={{ width: 'auto' }}
      fullscreenProps={{ headingOffset: -16 }}
      icon={<CloudIcon cloudProvider={cloudProvider} entity={FIREWALL_RULE} iconSize={48} theme={{ name: 'dark' }} />}
      popoutTitle={popoutTitle}
      onPopOutChange={handlePopOutChange}
    >
      <Box>
        <FirewallRulesTable
          firewallRules={networkFirewalls}
          isPoppedOut={isPoppedOut}
          queryTimeOptions={$hybridMap.settingsModel.queryTimeOptions}
        />
      </Box>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(FirewallRulesPopover);
