import * as React from 'react';
import { withTheme } from 'styled-components';
import { Box, Text } from 'core/components';
// AWS icons
import { ReactComponent as APIGatewayIcon } from 'app/assets/icons/aws/services/api-gateway.svg';
import { ReactComponent as AppFlowIcon } from 'app/assets/icons/aws/services/appflow.svg';
import { ReactComponent as AthenaIcon } from 'app/assets/icons/aws/services/athena.svg';
import { ReactComponent as APSIcon } from 'app/assets/icons/aws/services/managed-service-for-prometheus.svg';
import { ReactComponent as AuroraIcon } from 'app/assets/icons/aws/services/aurora.svg';
import { ReactComponent as AwsLogo } from 'app/assets/logos/aws-logo.svg';
import { ReactComponent as ChimeIcon } from 'app/assets/icons/aws/services/chime.svg';
import { ReactComponent as Cloud9Icon } from 'app/assets/icons/aws/services/cloud9.svg';
import { ReactComponent as CloudFrontIcon } from 'app/assets/icons/aws/services/cloudfront.svg';
import { ReactComponent as CloudWatchIcon } from 'app/assets/icons/aws/services/cloudwatch.svg';
import { ReactComponent as CodeBuildIcon } from 'app/assets/icons/aws/services/codebuild.svg';
import { ReactComponent as ConnectIcon } from 'app/assets/icons/aws/services/connect.svg';
import { ReactComponent as DataSyncIcon } from 'app/assets/icons/aws/services/data-sync.svg';
import { ReactComponent as DynamoDBIcon } from 'app/assets/icons/aws/services/dynamodb.svg';
import { ReactComponent as EBSIcon } from 'app/assets/icons/aws/services/ebs.svg';
import { ReactComponent as EC2Icon } from 'app/assets/icons/aws/services/ec2.svg';
import { ReactComponent as ECSIcon } from 'app/assets/icons/aws/services/elastic-container-service.svg';
import { ReactComponent as ECRIcon } from 'app/assets/icons/aws/services/elastic-container-registry.svg';
import { ReactComponent as GlobalAcceleratorIcon } from 'app/assets/icons/aws/services/global-accelerator.svg';
import { ReactComponent as IAMIcon } from 'app/assets/icons/aws/services/identity-and-access-management.svg';
import { ReactComponent as KeyManagementServiceIcon } from 'app/assets/icons/aws/services/key-management-service.svg';
import { ReactComponent as KinesisDataFirehoseIcon } from 'app/assets/icons/aws/services/kinesis-data-firehose.svg';
import { ReactComponent as KinesisDataStreamsIcon } from 'app/assets/icons/aws/services/kinesis-data-streams.svg';
import { ReactComponent as KinesisVideoStreamsIcon } from 'app/assets/icons/aws/services/kinesis-video-streams.svg';
import { ReactComponent as LambdaIcon } from 'app/assets/icons/aws/services/lambda.svg';
import { ReactComponent as OpsWorksIcon } from 'app/assets/icons/aws/services/ops-works.svg';
import { ReactComponent as RDSIcon } from 'app/assets/icons/aws/services/relational-database-service.svg';
import { ReactComponent as RedshiftIcon } from 'app/assets/icons/aws/services/redshift.svg';
import { ReactComponent as Route53Icon } from 'app/assets/icons/aws/services/route-53.svg';
import { ReactComponent as S3Icon } from 'app/assets/icons/aws/services/s3.svg';
import { ReactComponent as SecretsManagerIcon } from 'app/assets/icons/aws/services/secrets-manager.svg';
import { ReactComponent as SimpleQueueServiceIcon } from 'app/assets/icons/aws/services/simple-queue-service.svg';
import { ReactComponent as SimpleNotificationServiceIcon } from 'app/assets/icons/aws/services/simple-notification-service.svg';
import { ReactComponent as StepFunctionsIcon } from 'app/assets/icons/aws/services/step-functions.svg';
import { ReactComponent as StorageGatewayIcon } from 'app/assets/icons/aws/services/storage-gateway.svg';
import { ReactComponent as SystemsManagerIcon } from 'app/assets/icons/aws/services/systems-manager.svg';
import { ReactComponent as WorkspacesIcon } from 'app/assets/icons/aws/services/workspaces.svg';
// Azure icons
import { ReactComponent as AzureLogo } from 'app/assets/logos/azure-logo.svg';
import { ReactComponent as AzureActiveDirectoryIcon } from 'app/assets/icons/azure/active-directory.svg';
import { ReactComponent as AzureContainerRegistryIcon } from 'app/assets/icons/azure/container-registry.svg';
import { ReactComponent as AzureCosmosDBIcon } from 'app/assets/icons/azure/cosmos-db.svg';
import { ReactComponent as AzureDatabricksIcon } from 'app/assets/icons/azure/databricks.svg';
import { ReactComponent as AzureEventHubIcon } from 'app/assets/icons/azure/event-hub.svg';
import { ReactComponent as AzureFrontDoorIcon } from 'app/assets/icons/azure/front-door.svg';
import { ReactComponent as AzureMonitorIcon } from 'app/assets/icons/azure/monitor.svg';
import { ReactComponent as AzureServiceBusIcon } from 'app/assets/icons/azure/service-bus.svg';
import { ReactComponent as AzureSqlIcon } from 'app/assets/icons/azure/sql.svg';

export default function withServiceBrand(WrappedComponent, options = {}) {
  @withTheme
  class WrapperComponent extends React.Component {
    getServiceBrand = (key) => {
      const { theme } = this.props;
      let serviceMap = {
        // AWS services
        API_GATEWAY: {
          icon: APIGatewayIcon,
          label: 'API Gateway',
          color: theme.colors.aws.red
        },

        AMAZON: {
          icon: AwsLogo,
          label: 'Amazon',
          color: theme.colors.aws.orange
        },

        AMAZON_APPFLOW: {
          icon: AppFlowIcon,
          label: 'Amazon AppFlow',
          color: theme.colors.aws.red
        },

        AMAZON_CONNECT: {
          icon: ConnectIcon,
          label: 'Amazon Connect',
          color: theme.colors.aws.red
        },

        API: {
          icon: AwsLogo,
          label: 'Amazon',
          subLabel: 'API',
          color: theme.colors.aws.orange
        },

        'APS-WORKSPACES': {
          icon: APSIcon,
          label: 'Prometheus',
          subLabel: 'APS Workspaces',
          color: theme.colors.aws.red
        },

        ATHENA: {
          icon: AthenaIcon,
          label: 'Athena',
          color: theme.colors.aws.purple
        },

        AURORA: {
          icon: AuroraIcon,
          label: 'Aurora',
          color: theme.colors.aws.blue
        },

        AUTOSCALING: {
          icon: AwsLogo,
          label: 'Amazon',
          subLabel: 'Autoscaling',
          color: theme.colors.aws.orange
        },

        CHIME_MEETINGS: {
          icon: ChimeIcon,
          label: 'Chime',
          subLabel: 'Meetings',
          color: theme.colors.aws.red
        },

        CHIME_VOICECONNECTOR: {
          icon: ChimeIcon,
          label: 'Chime',
          subLabel: 'Voice Connector',
          color: theme.colors.aws.red
        },

        CLOUD9: {
          icon: Cloud9Icon,
          label: 'Cloud9',
          color: theme.colors.aws.blue
        },

        CLOUDFRONT: {
          icon: CloudFrontIcon,
          label: 'CloudFront',
          color: theme.colors.aws.purple
        },

        CLOUDFRONT_ORIGIN_FACING: {
          icon: CloudFrontIcon,
          label: 'CloudFront',
          subLabel: 'Origin Facing',
          color: theme.colors.aws.purple
        },

        CODEBUILD: {
          icon: CodeBuildIcon,
          label: 'CodeBuild',
          color: theme.colors.aws.blue
        },

        DATASYNC: {
          icon: DataSyncIcon,
          label: 'DataSync',
          color: theme.colors.aws.green
        },

        DKR: {
          icon: ECRIcon,
          label: 'ECR',
          subLabel: 'DKR',
          color: theme.colors.aws.orange
        },

        DYNAMODB: {
          icon: DynamoDBIcon,
          label: 'DynamoDB',
          color: theme.colors.aws.blue
        },

        EBS: {
          icon: EBSIcon,
          label: 'Elastic Block Store',
          color: theme.colors.aws.green
        },

        EC2: {
          icon: EC2Icon,
          label: 'EC2',
          color: theme.colors.aws.orange
        },

        EC2_INSTANCE_CONNECT: {
          icon: EC2Icon,
          label: 'EC2',
          subLabel: 'Instance Connect',
          color: theme.colors.aws.orange
        },

        // see https://docs.aws.amazon.com/systems-manager/latest/userguide/systems-manager-setting-up-messageAPIs.html
        EC2MESSAGES: {
          icon: SystemsManagerIcon,
          label: 'SSM',
          subLabel: 'ec2messages',
          color: theme.colors.aws.red
        },

        ECS: {
          icon: ECSIcon,
          label: 'ECS',
          color: theme.colors.aws.orange
        },

        EVENTS: {
          icon: AwsLogo,
          label: 'Amazon',
          subLabel: 'Events',
          color: theme.colors.aws.orange
        },

        'EXECUTE-API': {
          icon: AwsLogo,
          label: 'Amazon',
          subLabel: 'Execute API',
          color: theme.colors.aws.orange
        },

        GLOBALACCELERATOR: {
          icon: GlobalAcceleratorIcon,
          label: 'Global Accelerator',
          color: theme.colors.aws.purple
        },

        IMAGEBUILDER: {
          icon: EC2Icon,
          label: 'EC2',
          subLabel: 'Image Builder',
          color: theme.colors.aws.orange
        },

        'KINESIS-FIREHOSE': {
          icon: KinesisDataFirehoseIcon,
          label: 'Kinesis',
          subLabel: 'Data Firehose',
          color: theme.colors.aws.purple
        },

        'KINESIS-STREAMS': {
          icon: KinesisDataStreamsIcon,
          label: 'Kinesis',
          subLabel: 'Data Streams',
          color: theme.colors.aws.purple
        },

        KINESIS_VIDEO_STREAMS: {
          icon: KinesisVideoStreamsIcon,
          label: 'Kinesis',
          subLabel: 'Video Streams',
          color: theme.colors.aws.orange
        },

        KMS: {
          icon: KeyManagementServiceIcon,
          label: 'KMS',
          color: theme.colors.aws.red
        },

        LAMBDA: {
          icon: LambdaIcon,
          label: 'Lambda',
          color: theme.colors.aws.orange
        },

        LOGS: {
          icon: CloudWatchIcon,
          label: 'CloudWatch',
          subLabel: 'Logs',
          color: theme.colors.aws.red
        },

        MLDP: {
          icon: AwsLogo,
          label: 'Amazon',
          subLabel: 'MLDP',
          color: theme.colors.aws.orange
        },

        MONITORING: {
          icon: OpsWorksIcon,
          label: 'OpsWorks',
          subLabel: 'Monitoring',
          color: theme.colors.aws.red
        },

        RDS: {
          icon: RDSIcon,
          label: 'RDS',
          color: theme.colors.aws.blue
        },

        'REDSHIFT-DATA': {
          icon: RedshiftIcon,
          label: 'RedShift',
          subLabel: 'Data',
          color: theme.colors.aws.purple
        },

        ROLESANYWHERE: {
          icon: IAMIcon,
          label: 'IAM',
          subLabel: 'Roles Anywhere',
          color: theme.colors.aws.red
        },

        ROUTE53: {
          icon: Route53Icon,
          label: 'Route 53',
          color: theme.colors.aws.purple
        },

        ROUTE53_HEALTHCHECKS: {
          icon: Route53Icon,
          label: 'Route 53',
          subLabel: 'Health Checks',
          color: theme.colors.aws.purple
        },

        ROUTE53_HEALTHCHECKS_PUBLISHING: {
          icon: Route53Icon,
          label: 'Route 53',
          subLabel: 'Health Checks Publishing',
          color: theme.colors.aws.purple
        },

        ROUTE53_RESOLVER: {
          icon: Route53Icon,
          label: 'Route 53',
          subLabel: 'Resolver',
          color: theme.colors.aws.purple
        },

        RUNTIME: {
          icon: AwsLogo,
          label: 'Amazon',
          subLabel: 'Runtime',
          color: theme.colors.aws.orange
        },

        S3: {
          icon: S3Icon,
          label: 'S3',
          color: theme.colors.aws.green
        },

        SECRETSMANAGER: {
          icon: SecretsManagerIcon,
          label: 'Secrets Manager',
          color: theme.colors.aws.red
        },

        SNS: {
          icon: SimpleNotificationServiceIcon,
          label: 'SNS',
          color: theme.colors.aws.red
        },

        SQS: {
          icon: SimpleQueueServiceIcon,
          label: 'SQS',
          color: theme.colors.aws.red
        },

        SSM: {
          icon: SystemsManagerIcon,
          label: 'SSM',
          color: theme.colors.aws.red
        },

        // see https://docs.aws.amazon.com/systems-manager/latest/userguide/systems-manager-setting-up-messageAPIs.html
        SSMMESSAGES: {
          icon: SystemsManagerIcon,
          label: 'SSM',
          subLabel: 'ssmmessages',
          color: theme.colors.aws.red
        },

        STATES: {
          icon: StepFunctionsIcon,
          label: 'Step Functions',
          subLabel: 'States',
          color: theme.colors.aws.red
        },

        STORAGEGATEWAY: {
          icon: StorageGatewayIcon,
          label: 'Storage Gateway',
          color: theme.colors.aws.green
        },

        STS: {
          icon: IAMIcon,
          label: 'IAM',
          subLabel: 'Security Token Service',
          color: theme.colors.aws.red
        },

        'SYNC-STATES': {
          icon: StepFunctionsIcon,
          label: 'Step Functions',
          subLabel: 'SyncStates',
          color: theme.colors.aws.red
        },

        WEBHOOKS: {
          icon: AwsLogo,
          label: 'Amazon',
          subLabel: 'Webhooks',
          color: theme.colors.aws.orange
        },

        WORKSPACES_GATEWAYS: {
          icon: WorkspacesIcon,
          label: 'WorkSpaces',
          subLabel: 'Gateways',
          color: theme.colors.aws.darkGreen
        },

        // Azure services
        AzureActiveDirectory: {
          icon: AzureActiveDirectoryIcon,
          label: 'Azure',
          subLabel: 'Active Directory',
          color: theme.colors.azure.blue
        },

        AzureCloud: {
          icon: AzureLogo,
          label: 'Azure Cloud',
          color: theme.colors.azure.blue
        },

        AzureContainerRegistry: {
          icon: AzureContainerRegistryIcon,
          label: 'Azure',
          subLabel: 'Container Registry',
          color: theme.colors.azure.blue
        },

        AzureCosmosDB: {
          icon: AzureCosmosDBIcon,
          label: 'Azure',
          subLabel: 'Cosmos DB',
          color: theme.colors.azure.blue
        },

        AzureDatabricks: {
          icon: AzureDatabricksIcon,
          label: 'Azure Databricks',
          color: theme.colors.azure.orange
        },

        AzureFrontDoor: {
          icon: AzureFrontDoorIcon,
          label: 'Azure Front Door',
          color: theme.colors.azure.blue
        },

        AzureMonitor: {
          icon: AzureMonitorIcon,
          label: 'Azure Monitor',
          color: theme.colors.azure.blue
        },

        AzureResourceManager: {
          icon: AzureLogo,
          label: 'Azure',
          subLabel: 'Resource Manager',
          color: theme.colors.azure.blue
        },

        AzureUpdateDelivery: {
          icon: AzureLogo,
          label: 'Azure',
          subLabel: 'Update Delivery',
          color: theme.colors.azure.blue
        },

        EventHub: {
          icon: AzureEventHubIcon,
          label: 'Azure',
          subLabel: 'Event Hub',
          color: theme.colors.azure.blue
        },

        OneDsCollector: {
          icon: AzureLogo,
          label: 'Azure',
          subLabel: 'OneDsCollector',
          color: theme.colors.azure.blue
        },

        GuestAndHybridManagement: {
          icon: AzureLogo,
          label: 'Azure',
          subLabel: 'Guest & Hybrid Mgmt',
          color: theme.colors.azure.blue
        },

        ServiceBus: {
          icon: AzureServiceBusIcon,
          label: 'Azure',
          subLabel: 'Service Bus',
          color: theme.colors.azure.blue
        },

        Sql: {
          icon: AzureSqlIcon,
          label: 'Azure SQL',
          color: theme.colors.azure.blue
        },

        Storage: {
          icon: AzureLogo,
          label: 'Azure Storage',
          color: theme.colors.azure.blue
        }
      };

      serviceMap = Object.keys(serviceMap).reduce((acc, serviceKey) => {
        const serviceBrand = serviceMap[serviceKey];
        const { label, subLabel = '' } = serviceBrand;

        serviceBrand.fullLabel = `${label} ${subLabel}`;

        serviceBrand.labelComponent = (
          <Box>
            <Text as="div" ellipsis>
              {label}
            </Text>
            {subLabel && <Text large>{subLabel}</Text>}
          </Box>
        );

        return {
          ...acc,
          [serviceKey]: serviceBrand
        };
      }, {});

      return (
        serviceMap[key] || {
          icon: 'cloud',
          label: key,
          fullLabel: key,
          labelComponent: <Text>{key}</Text>,
          color: 'muted'
        }
      );
    };

    render() {
      // eslint-disable-next-line react/destructuring-assignment
      const lookupKey = this.props[options.lookupKey || 'serviceKey'];

      return (
        <WrappedComponent
          {...this.props}
          serviceBrand={this.getServiceBrand(lookupKey)}
          getServiceBrand={this.getServiceBrand}
        />
      );
    }
  }

  return WrapperComponent;
}
