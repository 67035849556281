import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';

import { FlexColumn } from 'core/components';
import Topology from './Topology';
import EmbeddedTopo from './EmbeddedTopo';

@inject('$topo', '$sites')
@observer
class SiteTopology extends Component {
  static propTypes = {
    embedded: PropTypes.bool,
    siteId: PropTypes.string,
    siteLevel: PropTypes.bool
  };

  static defaultProps = {
    embedded: false,
    siteId: undefined,
    siteLevel: true
  };

  @computed
  get siteId() {
    const { siteId, match = {} } = this.props;
    const { params = {} } = match;

    if (siteId || params.siteId) {
      const siteIdInt = parseInt(siteId || match.params.siteId, 10);

      if (!Number.isNaN(siteIdInt)) {
        return siteIdInt;
      }
    }

    return undefined;
  }

  get site() {
    const { $sites } = this.props;

    if (this.siteId) {
      return $sites.collection.get(this.siteId);
    }

    return null;
  }

  get title() {
    return this.site ? this.site.get('title') : '';
  }

  componentDidMount() {
    this.getTopologyData();
  }

  componentDidUpdate = (prevProps) => {
    const { siteId, match = {} } = this.props;
    const { params = {} } = match;
    const prevParams = prevProps.match ? prevProps.match.params : {};

    if (siteId !== prevProps.siteId || params.siteId !== prevParams.siteId) {
      this.getTopologyData();
    }
  };

  getTopologyData = () => {
    const { $topo, siteLevel } = this.props;
    const { siteId } = this;

    $topo.getTopologyData({
      selectedSites: siteId ? [siteId] : [],
      type: siteId ? 'site' : 'main',
      level: siteLevel ? 'site' : 'device',
      showProviders: siteLevel ? 'connectivity' : 'name',
      showUnlinked: true
    });
  };

  handleOpenClick = () => {
    const { $topo } = this.props;
    $topo.navigateToSiteTopo(this.siteId);
  };

  render() {
    const { embedded, expandable } = this.props;

    return (
      <FlexColumn height="100%">
        {embedded && <EmbeddedTopo onOpen={this.handleOpenClick} expandable={expandable} />}
        {!embedded && <Topology title={this.title} siteId={this.siteId} />}
      </FlexColumn>
    );
  }
}

export default SiteTopology;
