import { isIpV4Valid, isIpV6Valid } from 'core/util/ip';

function fn(value, req) {
  const [maxV4Subnet, maxV6Subnet] = req.split(',');

  if (value) {
    const v4Valid = isIpV4Valid(value, { returnAddress: true });

    if (v4Valid) {
      return v4Valid.subnetMask >= maxV4Subnet;
    }

    const v6Valid = isIpV6Valid(value, { returnAddress: true });

    if (v6Valid) {
      return v6Valid.subnetMask >= maxV6Subnet;
    }
  }

  return true;
}

export default {
  message: 'Subnet mask is too large.',
  fn
};
