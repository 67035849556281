import * as React from 'react';
import { Box, EmptyState, Flex, Icon, Text } from 'core/components';
import { Field, Checkbox } from 'core/form';
import StackedArea from 'app/components/dataviews/views/StackedArea';
import { DateRange } from 'app/components/dataviews/DataViewHeader';
import ViewInExplorerButton from 'app/components/dataviews/tools/ViewInExplorerButton';

export default class TrafficChart extends React.Component {
  static defaultProps = {
    canSyncScales: false
  };

  state = {
    showExplorerButton: false
  };

  get explorerButton() {
    const { type, query } = this.props;
    const { showExplorerButton } = this.state;

    if (showExplorerButton) {
      return (
        <ViewInExplorerButton
          query={query}
          position="absolute"
          bottom={type === 'outbound' ? '48px' : '16px'}
          right="4px"
          openInNewWindow
        />
      );
    }

    return null;
  }

  get dateRange() {
    const { type, dataview } = this.props;

    if (type === 'outbound') {
      return (
        <Box mt="-8px" textAlign="center">
          <DateRange dataview={dataview} />
        </Box>
      );
    }

    return null;
  }

  get emptyState() {
    const { source, target, query } = this.props;

    return (
      <Box bg="subnavBackground" px={1} pb={1}>
        {this.getChartTitle({ source, target })}
        <EmptyState
          title="No Results"
          icon="inbox"
          description={<ViewInExplorerButton query={query} openInNewWindow />}
        />
      </Box>
    );
  }

  getChartTitle = ({ source, target, value }) => (
    <Flex alignItems="center" justifyContent="center" mt={1}>
      <Text ellipsis muted small>
        {source}
      </Text>
      {target && (
        <>
          <Icon icon="arrow-right" mx={1} color="muted" iconSize="14px" />
          <Text ellipsis muted small>
            {target}
          </Text>
        </>
      )}
      <Text ml={1} muted small>
        {value}
      </Text>
    </Flex>
  );

  render() {
    const {
      type,
      dataview,
      colors,
      loading,
      noResultsConfig,
      showResultsTable,
      source,
      target,
      lastDataPoint,
      units,
      resultsTable,
      canSyncScales
    } = this.props;

    if (loading) {
      return <Flex showSkeleton={loading} gridColumn="1/3" height={showResultsTable ? 350 : 150} />;
    }

    if (!noResultsConfig.hasData) {
      // handles a situation where both queries have no idea
      // inbound will render a rolled up version of empty state offering view in DE buttons for debuggability
      // outbound will render null as inbound takes care of describing state
      const { inbound, outbound } = noResultsConfig;

      if (type === 'inbound') {
        return (
          <Box bg="subnavBackground" pl={1} pr={1} pb={1}>
            <EmptyState title="No Results" icon="inbox" />
            <Flex justifyContent="center">
              <Box overflow="hidden" textAlign="center">
                {this.getChartTitle({ ...inbound.renderOptions })}
                <ViewInExplorerButton mt={1} query={inbound.query} openInNewWindow />
              </Box>
              <Box ml={3} overflow="hidden" textAlign="center">
                {this.getChartTitle({ ...outbound.renderOptions })}
                <ViewInExplorerButton mt={1} query={outbound.query} openInNewWindow />
              </Box>
            </Flex>
          </Box>
        );
      }

      if (type === 'outbound') {
        return null;
      }
    }

    return (
      <>
        {!noResultsConfig[type].hasData && this.emptyState}
        {noResultsConfig[type].hasData && (
          <>
            {this.getChartTitle({ source, target, value: `${lastDataPoint} ${units}` })}
            <Box
              position="relative"
              onMouseEnter={() => this.setState({ showExplorerButton: true })}
              onMouseLeave={() => this.setState({ showExplorerButton: false })}
              ml="-8px"
            >
              <StackedArea
                dataview={dataview}
                viewProps={{
                  height: 125,
                  style: { gridColumn: 2 },
                  chartConfig: {
                    xAxis: { className: 'no-title', visible: type === 'outbound', title: { reserveSpace: false } },
                    yAxis: [{ className: 'no-title', title: { reserveSpace: false } }]
                  },
                  colors
                }}
              />
              {this.explorerButton}
              {this.dateRange}
            </Box>
          </>
        )}

        {canSyncScales && (
          <Field name="syncChartScales" showLabel={false} mt={1}>
            <Checkbox />
          </Field>
        )}

        {resultsTable && (
          <Flex maxHeight={200} mt={1} flex={1} minHeight={0}>
            {resultsTable}
          </Flex>
        )}
      </>
    );
  }
}
