import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { fields, options } from 'app/forms/config/savedView';
import { FormDialog, Field, InputGroup, TextArea } from 'core/form';
import { Box, Callout, Heading } from 'core/components';
import { AnchorButton } from '@blueprintjs/core';
import ShareLevelSelector from 'app/views/core/dashboards/ShareLevelSelector';
import LabelSelector from 'app/components/labels/LabelSelector';
import { persistHash } from 'app/stores/query/urlHash';

@withRouter
export default class SavedViewFormDialog extends Component {
  static defaultProps = {
    showDescriptionCallout: true,
    navigateOnSave: true
  };

  handleSave = (form, values) => {
    const { model, history, onSave, navigateOnSave } = this.props;
    return model
      .save(values)
      .then(() => (model.has('saved_query_id') ? persistHash(model.get('saved_query_id')) : null))
      .then(() => {
        if (model.isNew && navigateOnSave) {
          history.push(model.navigatePath);
        } else if (onSave) {
          onSave(model);
        }
      });
  };

  render() {
    const { model, onClose, showDescriptionCallout } = this.props;
    const isClone = model && model.isNew && model.name.includes('[Copy');
    return (
      <FormDialog
        entityName="Saved View"
        model={model}
        fields={fields}
        options={options}
        onClose={onClose}
        title={isClone && 'Clone Saved View'}
        maxWidth={550}
        isOpen
        formActionsProps={{
          onSubmit: this.handleSave,
          onCancel: onClose
        }}
      >
        {({ form }) => (
          <Box>
            {model.isNew && showDescriptionCallout && (
              <Callout intent="primary" mb={2}>
                <Heading level={5}>Saved Views</Heading>
                <p>
                  A saved view is a collection of Data Explorer settings, including the settings of the Query, Time,
                  Filter, Devices, and Display panes in the sidebar.
                </p>
                <Box mb={0}>
                  <AnchorButton
                    minimal
                    icon="share"
                    rel="noopener noreferrer"
                    intent="primary"
                    target="_blank"
                    href="https://kb.kentik.com/?Db03.htm#Db03-Saved_Views"
                  >
                    <strong>Learn More About Saved Views</strong>
                  </AnchorButton>
                </Box>
              </Callout>
            )}

            <Field name="view_name" autoFocus large>
              <InputGroup />
            </Field>

            <Field name="view_description" large>
              <TextArea fill />
            </Field>

            <Box my={1} width={230}>
              <LabelSelector fieldName="labelIds" useDialog />
            </Box>

            <Box mb={2}>
              <ShareLevelSelector label="Visibility" field={form.getField('share_level')} />
            </Box>
          </Box>
        )}
      </FormDialog>
    );
  }
}
