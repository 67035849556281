import * as React from 'react';
import { observer } from 'mobx-react';
import { FiCopy } from 'react-icons/fi';
import { Box, Button, Flex, Text } from 'core/components';
import { ReactComponent as DirectConnectionIcon } from 'app/assets/icons/direct-connection.svg';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';

function getTitle(config) {
  return config.nodeData?.connectionName || config.metadata?.name || config.value;
}

const DirectConnectionSummary = (config) => {
  const { logo, nodeData } = config;
  const title = getTitle(config);

  return (
    <Box>
      <Flex p={2} borderBottom="thin" alignItems="center" gridColumn="1/3">
        {logo ? React.cloneElement(logo, { width: 32, height: 32 }) : <DirectConnectionIcon width={32} height={32} />}
        <Box flex={1} ml={1}>
          <Text as="div" muted>
            Direct Connection Details
          </Text>
          <Text muted small>
            {title}
          </Text>
          <CopyToClipboardButton text={title} intent="none" ml="4px" minimal small>
            <Button icon={FiCopy} />
          </CopyToClipboardButton>
        </Box>
      </Flex>
      {nodeData && (
        <SidebarItem title="Details" icon="more" popoutTitle={DirectConnectionSummary.PopoutTitle(config)}>
          <CloudMetadata
            node={{
              ...nodeData,
              State: nodeData.ConnectionState
            }}
            keys={['id', 'ConnectionName', 'OwnerAccount', 'OwnerId', 'State', 'Tags']}
          />
        </SidebarItem>
      )}
    </Box>
  );
};

DirectConnectionSummary.PopoutTitle = (config) => {
  const { logo } = config;
  return (
    <Flex alignItems="center">
      {logo || <DirectConnectionIcon />}
      <Box ml={1}>{getTitle(config)}</Box>
    </Flex>
  );
};

export default observer(DirectConnectionSummary);
