import { curveLeftUp, curveUpRight, curveUpLeft, curveRightUp } from 'app/views/hybrid/utils/d3/curves';

/**
 *      Subnet
 *      (dest)
 *         ↓
 *           → → → → → →
 *                       ↓
 *                  ← ← ←
 *                ↓
 *         VPC Endpoint
 *           (source)
 */
const connectToSubnet = ({ vpcRight, sourcePoint, targetPoint, ySpacing, targetNodeHeight, linkSpacing }) => {
  const bottomY = sourcePoint[1] - ySpacing;
  const topY = targetPoint[1] + targetNodeHeight / 2 + ySpacing / 2;
  const rightX = vpcRight;

  const connectionPoints = [
    ...curveUpRight(sourcePoint[0], bottomY, linkSpacing),
    ...curveRightUp(rightX, bottomY, linkSpacing),
    ...curveUpLeft(rightX, topY, linkSpacing),
    ...curveLeftUp(targetPoint[0], topY, linkSpacing)
  ];
  return { sourceAnchor: 'top', targetAnchor: 'bottom', connectionPoints };
};

/**
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const VpcEndpointConnector = ({ targetType, ...rest }) => {
  switch (targetType) {
    case 'subnet':
      return connectToSubnet({ ...rest });
    // /** @TODO other target types */
    default:
      return null;
  }
};

export default VpcEndpointConnector;
