import React from 'react';
import { Card, Text, Flex, Box } from 'core/components';
import AwsLogo from 'app/components/AwsLogo';
import { ReactComponent as AzureLogo } from 'app/assets/logos/azure-logo.svg';
import { ReactComponent as GoogleLogo } from 'app/assets/logos/google.svg';

import styled, { css } from 'styled-components';
import { observer } from 'mobx-react';

const CloudProviderWrapper = styled(Card)`
  background: ${(props) => props.theme.colors.subnavBackground};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  margin-right: 12px;
  margin-bottom: 8px;
  flex: 1;

  ${(props) =>
    !props.active &&
    css`
      svg {
        filter: grayscale(1);
        opacity: 0.65;
      }
    `}
`;

const providerLogoStyle = { width: 24, height: 24, marginRight: 8 };

function CloudProviderStrip({ numAzureDevices, numGcpDevices, numAwsDevices }) {
  return (
    <Flex>
      <CloudProviderWrapper active={numAwsDevices > 0}>
        <AwsLogo style={providerLogoStyle} />
        <Box>
          <Text as="div" fontSize="small" fontWeight="bold">
            Amazon Web Services
          </Text>
          <Text color="muted" fontSize="small">
            {numAwsDevices} devices
          </Text>
        </Box>
      </CloudProviderWrapper>
      <CloudProviderWrapper active={numGcpDevices > 0}>
        <GoogleLogo style={providerLogoStyle} />
        <Box>
          <Text as="div" fontSize="small" fontWeight="bold">
            Google Cloud Platform
          </Text>
          <Text color="muted" fontSize="small">
            {numGcpDevices} devices
          </Text>
        </Box>
      </CloudProviderWrapper>
      <CloudProviderWrapper active={numAzureDevices > 0}>
        <AzureLogo style={providerLogoStyle} />
        <Box>
          <Text as="div" fontSize="small" fontWeight="bold">
            Microsoft Azure
          </Text>
          <Text color="muted" fontSize="small">
            {numAzureDevices} devices
          </Text>
        </Box>
      </CloudProviderWrapper>
    </Flex>
  );
}

export default observer(CloudProviderStrip);
