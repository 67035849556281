import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FiServer, FiSettings } from 'react-icons/fi';
import { MdSettingsEthernet } from 'react-icons/md';

import { Button, Flex, Icon, Spinner, Tag, Text } from 'core/components';
import { CELL_TYPES } from 'core/components/table';
import AdminTable from 'app/components/admin/AdminTable';
import DeviceLink from 'app/components/links/DeviceLink';
import InterfaceLink from 'app/components/links/InterfaceLink';
import SiteLink from 'app/components/links/SiteLink';
import LabelList from 'app/components/labels/LabelList';
import DeviceFormDialog from 'app/views/settings/devices/DeviceFormDialog';
import InterfaceFormDialog from 'app/views/settings/interfaces/InterfaceFormDialog';

import HealthIssuesSettings from './HealthIssuesSettings';

const EntityLabel = observer(({ model }) => {
  const device_name = model.get('device_name');
  const level = model.get('level');

  if (level === 'interface') {
    const snmp_id = model.get('snmp_id');
    const interface_description = model.get('interface_description') || '';

    return (
      <Flex gap={1} alignItems="center">
        <Icon icon={MdSettingsEthernet} />
        <Text ellipsis>
          <InterfaceLink device_name={device_name} snmp_id={snmp_id} interface_description={interface_description} />
          {' on '}
          <DeviceLink name={device_name} />
        </Text>
      </Flex>
    );
  }

  return (
    <Flex gap={1} alignItems="center">
      <Icon icon={FiServer} />
      <DeviceLink name={device_name} />
    </Flex>
  );
});

const SettingsButton = observer(({ model, onClick }) => (
  <Button
    icon={model.entityModelIsLoading ? <Spinner /> : FiSettings}
    disabled={model.entityModelIsLoading}
    title={`View ${model.get('level')} settings`}
    onClick={() => onClick(model)}
    minimal
    small
  />
));

@withRouter
@inject('$auth', '$hybridMap')
export default class HealthIssuesTable extends Component {
  state = {
    editDeviceModel: null,
    editInterfaceModel: null
  };

  get columns() {
    const { $auth } = this.props;

    return [
      {
        name: 'check.state',
        width: 40,
        renderer: ({ value }) => {
          if (value === 'CRITICAL') {
            return <Icon icon="error" color="danger" />;
          }

          if (value === 'WARNING') {
            return <Icon icon="warning-sign" color="warning" />;
          }

          return null;
        }
      },
      {
        name: 'displayName',
        computed: true,
        label: 'Alarm Type'
      },
      {
        name: 'device_name',
        label: 'Entity Name',
        flexBasis: 150,
        renderer: ({ model }) => <EntityLabel model={model} />
      },
      {
        name: 'site_name',
        label: 'Site',
        flexBasis: 50,
        renderer: ({ model }) => <SiteLink siteId={model.get('site_id')}>{model.get('site_name')}</SiteLink>
      },
      {
        name: 'check.value',
        label: 'Current Value',
        flexBasis: 50,
        renderer: ({ model }) => model.displayValue
      },
      {
        type: CELL_TYPES.ACTION,
        align: 'right',
        actions: [
          (model) => (
            <Button
              key="map"
              icon="send-to-map"
              title={`View ${model.get('level')} in Kentik Map`}
              onClick={() => this.handleOpenInMap(model)}
              minimal
              small
            />
          ),
          (model) =>
            $auth.isAdministrator && <SettingsButton key="settings" model={model} onClick={this.handleOpenSettings} />
        ]
      }
    ];
  }

  groupSummaryLookup = ({ groupBy, groupKey, group }) => {
    if (groupBy === 'displayDeviceLabels') {
      const labels = group[0].get('device_labels')?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];

      return (
        <Flex gap={1} alignItems="center">
          {labels.length > 0 ? <LabelList labels={labels} /> : 'None'}
          <Tag minimal round mt="4px">
            {group.length}
          </Tag>
        </Flex>
      );
    }

    return (
      <>
        <Text fontWeight="bold" mx={1}>
          {groupKey}
        </Text>
        <Tag minimal round>
          {group.length}
        </Tag>
      </>
    );
  };

  handleOpenInMap = (model) => {
    const { history, $hybridMap } = this.props;
    const { baseRoute } = $hybridMap;
    const level = model.get('level');

    if (level === 'interface') {
      history.push(`${baseRoute}/device/${model.get('device_id')}`, {
        selectedNode: { type: 'interface', value: model.get('snmp_id') }
      });
    } else {
      history.push(`${baseRoute}/site/${model.get('site_id')}`, {
        selectedNode: { type: 'device', value: model.get('device_id') }
      });
    }
  };

  handleOpenSettings = (model) => {
    const level = model.get('level');

    if (level === 'interface') {
      model
        .fetchInterfaceModel()
        .then((editInterfaceModel) => this.setState({ editInterfaceModel, editDeviceModel: null }));
    } else {
      model.fetchDeviceModel().then((editDeviceModel) => this.setState({ editDeviceModel, editInterfaceModel: null }));
    }
  };

  render() {
    const { healthIssues, $auth } = this.props;
    const { editDeviceModel, editInterfaceModel } = this.state;

    return (
      <>
        <AdminTable
          collection={healthIssues}
          columns={this.columns}
          groupSummaryLookup={this.groupSummaryLookup}
          tableHeaderControls={<HealthIssuesSettings collection={healthIssues} />}
          fetchCollectionOnMount={false}
          searchBar={false}
        />
        {$auth.isAdministrator && editDeviceModel && (
          <DeviceFormDialog model={editDeviceModel} onClose={() => this.setState({ editDeviceModel: null })} />
        )}
        {$auth.isAdministrator && editInterfaceModel && (
          <InterfaceFormDialog model={editInterfaceModel} onClose={() => this.setState({ editInterfaceModel: null })} />
        )}
      </>
    );
  }
}
