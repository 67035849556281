import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Card, Grid, Link, Text } from 'core/components';
import NodeGroup from './NodeGroup';

@inject('$hybridMap')
@observer
export default class Regions extends React.Component {
  render() {
    const { $hybridMap, regions, onNodeClick, onMouseEnter, onMouseLeave, pathType } = this.props;

    if (regions) {
      const regionNames = Object.keys(regions);

      return (
        <Box>
          {regionNames.map((region) => {
            const { nodeGroups, columnCount } = regions[region];

            return (
              <Box key={region} mb={2}>
                <Text as="div" fontWeight="bold" mb="4px">
                  {region}
                  <Link to={`${$hybridMap.baseRoute}/cloud/aws`} ml={1} small>
                    (View in Map)
                  </Link>
                </Text>
                <Card p={2} elevation={2}>
                  <Grid gridTemplateColumns={`repeat(${columnCount}, 1fr)`} gridColumnGap="70px" alignItems="center">
                    {nodeGroups.map((nodeGroup) => {
                      const key = nodeGroup.map((node) => node.id).join('-');

                      return (
                        <NodeGroup
                          key={key}
                          nodes={nodeGroup}
                          pathType={pathType}
                          onNodeClick={onNodeClick}
                          onMouseEnter={onMouseEnter}
                          onMouseLeave={onMouseLeave}
                          gridProps={{ gridTemplateColumns: 'auto' }}
                        />
                      );
                    })}
                  </Grid>
                </Card>
              </Box>
            );
          })}
        </Box>
      );
    }

    return null;
  }
}
