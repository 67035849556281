import * as React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { PopoverInteractionKind, PopoverPosition } from '@blueprintjs/core';
import { formatBytesGreek, toDecimal } from 'core/util';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import { getDateRangeDisplay } from 'core/util/dateUtils';
import { GCP_ENTITY_TYPES } from 'shared/hybrid/constants';
import { Box, Button, Card, Heading, Icon, Flex, Popover, Sparkline, Text, EmptyState } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import MetricsExplorerButton from 'app/views/metrics/MetricsExplorerButton';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import SearchableTable from 'app/views/hybrid/maps/components/SearchableTable';
import { getCustomProperties } from 'shared/util/map';

const { CLOUD_ROUTER, SUBNET, VPN_GATEWAY, VPN_TUNNEL } = GCP_ENTITY_TYPES;

@inject('$hybridMap', '$dictionary', '$metrics')
@observer
export default class MetricsPanel extends React.Component {
  state = {
    loading: true,
    isPoppedOut: false,
    data: null
  };

  componentDidMount() {
    const { $hybridMap } = this.props;

    this.timeRangeDisposer = reaction(
      () => $hybridMap.sidebarSettings.timeRange,
      () => this.fetchMetrics()
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { nodeData } = this.props;
    const { data, isPoppedOut } = this.state;
    const popOutChanged = prevState.isPoppedOut !== isPoppedOut;

    if (prevProps.nodeData?.id !== nodeData?.id || (data === null && popOutChanged)) {
      // if we have the sidebar open and switch to a new load balancer or change the time range, fetch for new data
      this.fetchMetrics();
    }
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.cancel();
    }

    if (this.timeRangeDisposer) {
      this.timeRangeDisposer();
    }
  }

  fetchMetrics = () => {
    const { $hybridMap, entityType, nodeData } = this.props;
    let id = [CLOUD_ROUTER, SUBNET].includes(entityType) ? nodeData.selfLink : nodeData.id;

    if ([CLOUD_ROUTER, VPN_GATEWAY, VPN_TUNNEL].includes(entityType)) {
      // sometimes we need the entity's id in addition to the selflink, in that case append w/ useID
      let useID;
      const custPropObj = getCustomProperties(nodeData);
      useID = custPropObj?.id;

      if (!useID) {
        useID = nodeData.id;
      }

      id = id.concat(`/useID/${useID}`);
    }

    this.setState({ loading: true }, () => {
      this.request = makeCancelable($hybridMap.getGcpMetrics({ entityType, id }));

      this.request.promise
        .then((response) => {
          this.setState({ loading: false, data: response });
        })
        .catch((e) => {
          if (e instanceof CanceledError === false) {
            this.setState({ loading: false }, () => console.error(e));
          }
        });
    });
  };

  handleExpandChange = (isOpenOrPoppedOut) => {
    const { data } = this.state;

    if (isOpenOrPoppedOut && data === null) {
      // fetch for data when expanding for the first time
      this.fetchMetrics();
    }
  };

  handlePopOutChange = (isPoppedOut) => {
    this.setState({ isPoppedOut }, this.handleExpandChange);
  };

  handlePanelRefresh = (e) => {
    e.stopPropagation();
    this.fetchMetrics();
  };

  get title() {
    const { $hybridMap, $dictionary } = this.props;
    const { isPoppedOut } = this.state;
    // under the hood we support children being able to report their metrics as part of their parent
    // this would be shown as a group summary where we can display the unique time intervals for each metric there
    // since we're not using this feature just yet, just take the first interval and use that as it will be what was used for all the metrics returned

    const lookBackSeconds = $hybridMap.sidebarSettings?.sidebarQueryOverrides?.lookback_seconds ?? '';

    return (
      <Flex alignItems="end">
        <Heading level={5} mb={0} mr={1}>
          Metrics
        </Heading>
        {!isPoppedOut && (
          <Text color="muted" small>
            ({$dictionary.get(`showLast.${lookBackSeconds}`, 'UTC')})
          </Text>
        )}
      </Flex>
    );
  }

  get popOutHeader() {
    const { $hybridMap } = this.props;
    const { data, isPoppedOut } = this.state;
    const displayInterval = data?.metrics?.[0]?.interval?.display;
    const { start, end } = $hybridMap.sidebarSettings.timeRange;

    if (isPoppedOut) {
      return (
        <Text as="div" fontWeight="bold" mb={1}>{`${getDateRangeDisplay(start, end)} ${
          displayInterval ? `(${displayInterval})` : ''
        }`}</Text>
      );
    }

    return null;
  }

  get emptyState() {
    const { cloudProvider } = this.props;

    return (
      <EmptyState
        title="No Metrics Found"
        icon={<CloudIcon cloudProvider={cloudProvider} entity="metrics" iconSize={48} />}
      />
    );
  }

  isBracketUnit = (unit) => typeof unit === 'string' && (unit.startsWith('{') || unit.endsWith('}'));

  getBracketUnitValue = (unit) => unit.substring(unit.indexOf('{') + 1, unit.indexOf('}'));

  renderMetric = ({ value, model, column }) => {
    let displayUnit;

    if (!model.get('supportsTotalAggregation') && column.name === 'sum') {
      return '--';
    }

    const unit = model.get('unit');

    const basicUnits = {
      bit: 'b',
      By: 'B',
      s: 'second',
      min: 'minute',
      h: 'hour',
      d: 'day'
    };

    const sp = unit.split('/');
    const basicUnit = this.isBracketUnit(sp[0]) ? this.getBracketUnitValue(sp[0]) : sp[0];
    if (sp.length === 1) {
      displayUnit = basicUnits[basicUnit] ?? basicUnit;
    } else {
      displayUnit = basicUnits[basicUnit] ?? basicUnit;
      displayUnit = `${displayUnit}/${sp[1]}`;
    }

    // if unit is one it's dimensionless, (supportsTotalAggregation === false)
    if (displayUnit === '1') {
      displayUnit = '';
    }

    // make sure we display values less than 1
    const fixedValue = toDecimal(value);

    return `${formatBytesGreek(fixedValue, '')}${this.isBracketUnit(sp[0]) ? ' ' : ''}${displayUnit}`;
  };

  getMetricsExplorerQuery = ({ model }) => {
    let includeRegionDimension;
    let includeProjectIdDimension;
    let entityId;
    const { $metrics, $hybridMap, nodeData } = this.props;
    const { region, project: project_id, ...restNodeData } = getCustomProperties(nodeData);
    const { getCloudTopologyMetricExplorerLinkQuery, measurementModelByName, decorateFilterObj } = $metrics;

    const type = model.get('type');

    // ex type:  "router.googleapis.com/bgp/received_routes_count";
    if (typeof type !== 'string' || !type.includes('googleapis.com')) {
      // something's wrong with this type property, we won't be able to create a query
      return {};
    }

    const [gcpHostStr] = type.split('/');

    // ex entityType: router
    const [entityType] = gcpHostStr.split('.');

    // ex metricName: last_best_received_routes_count or bgp/received_routes_count (could be 0 or more /'s in string)
    const metricName = type.substring(`${gcpHostStr}/`.length);
    const measurement = `/cloud/google/${entityType}`;
    // get the model used for metrics NMS metrics explorer
    const measurementModel = measurementModelByName(measurement);

    if (!measurementModel) {
      // we can't query without a valid model
      return {};
    }

    const storage = measurementModel.get('storage');

    if (storage) {
      const { Metrics, Dimensions } = storage;

      if (!Metrics?.[metricName]) {
        // Metrics is array of metric explorer supported metrics for this measurement/entity-type
        // if metricName ain't there, we can't query on it
        // so just return empty object
        return {};
      }
      includeRegionDimension = !!Dimensions?.region;
      includeProjectIdDimension = !!Dimensions?.project_id;
    } else {
      // likewise if we don't have measurementModel storage, the query won't work
      return {};
    }

    const entityDimentionNameMap = {
      interconnect: 'attachment',
      vpn: 'gateway_id'
    };

    if (restNodeData?.entityType) {
      let nodeDataEntityType = restNodeData.entityType;
      entityId = nodeDataEntityType ? restNodeData[nodeDataEntityType] : undefined;

      if (!entityId && nodeDataEntityType.endsWith('s')) {
        // sometimes the gcp entity type is actually plural, like "interconnectAttachments"
        // but the key in our kentik custom object is singular like "interconnectAttachment"
        // so if we have an entity type in node data but can't find a corresponding key, try without the 's'
        nodeDataEntityType = nodeDataEntityType.substring(0, nodeDataEntityType.length - 1);

        entityId = restNodeData[nodeDataEntityType];
      }
    }

    if (!entityId && nodeData.id) {
      // fall back to using whatever id is in nodeData
      // will be the case for cloudRouters
      entityId = nodeData.id;
    }

    const entityDimensionName = entityDimentionNameMap[entityType] ?? `${entityType}_id`;

    // TODO this hard-coded bullshit sucks
    // need to coordinate with metrics explorer team to standardize GCP measurements & metrics
    if (entityDimensionName === 'gateway_id') {
      entityId = restNodeData.id;
    }

    const selectedFilters = [decorateFilterObj({ filterField: entityDimensionName, filterValue: entityId })];
    const selectedDimensions = [entityDimensionName];

    if (includeRegionDimension) {
      selectedFilters.push(decorateFilterObj({ filterField: 'region', filterValue: region }));
      selectedDimensions.push('region');
    }
    if (includeProjectIdDimension) {
      selectedFilters.push(decorateFilterObj({ filterField: 'project_id', filterValue: project_id }));
      selectedDimensions.push('project_id');
    }

    const queryParams = {
      selectedMeasurement: measurementModel?.get('id'),
      metricName,
      selectedDimensions,
      selectedFilters,
      starting_time: $hybridMap?.sidebarSettings?.timeRange?.start,
      ending_time: $hybridMap?.sidebarSettings?.timeRange?.end
    };
    return getCloudTopologyMetricExplorerLinkQuery(queryParams);
  };

  get columns() {
    const { isPoppedOut } = this.state;
    const { topology } = this.props;

    const hasExporterWithEnabledMetrics = topology?.KentikExportersSummary?.EnableMetricsCollection ?? false;

    return [
      {
        name: 'displayName',
        label: 'Metric',
        renderer: ({ value, model }) => {
          const sparklineData = model.get('sparklineData', []);
          const hasData = sparklineData.length > 0;

          const metricsExplorerQuery = hasExporterWithEnabledMetrics ? this.getMetricsExplorerQuery({ model }) : {};

          return (
            <Popover
              usePortal
              disabled={!hasData || isPoppedOut}
              minimal={false}
              interactionKind={PopoverInteractionKind.HOVER}
              position={PopoverPosition.LEFT}
              modifiers={{
                flip: { enabled: false },
                preventOverflow: { enabled: true, boundariesElement: 'window' }
              }}
              content={
                <Card p={1}>
                  <Box width={150} height={50}>
                    <Sparkline data={sparklineData} width={150} height={50} strokeWidth={0.5} color="primary" />
                  </Box>
                </Card>
              }
            >
              <Flex alignItems="end">
                {
                  // if query is legit, it'll have a kmetrics prop
                  // if we're missing data necessary for the query, metricsExplorerQuery will be empty object
                  // so don't show the link
                  hasExporterWithEnabledMetrics && metricsExplorerQuery?.kmetrics ? (
                    <MetricsExplorerButton
                      openInNewWindow
                      text={value}
                      query={metricsExplorerQuery}
                      iconSize={20}
                      ml={-1}
                      mr={1}
                    />
                  ) : (
                    <>
                      <Icon icon="timeline-line-chart" color={hasData ? 'primary' : 'muted'} mr={1} />
                      <Text>{value}</Text>
                    </>
                  )
                }
              </Flex>
            </Popover>
          );
        }
      },

      ...(isPoppedOut
        ? [
            {
              name: 'sparklineData',
              label: 'Trend',
              flexBasis: 125,
              renderer: ({ value }) => (
                <Box width={250} height={20}>
                  <Sparkline data={value} width={250} height={20} strokeWidth={0.5} color="primary" />
                </Box>
              )
            }
          ]
        : []),

      {
        name: 'average',
        label: 'Avg',
        width: 85,
        renderer: this.renderMetric
      },

      {
        name: 'sum',
        label: 'Sum',
        width: 85,
        renderer: this.renderMetric
      }
    ];
  }

  render() {
    const { cloudProvider, width, popoutTitle } = this.props;
    const { loading, data } = this.state;

    return (
      <SidebarItem
        excludeFormProps
        title={this.title}
        dialogProps={{ width }}
        popoutTitle={popoutTitle}
        filledIconTag={false}
        icon={<CloudIcon cloudProvider={cloudProvider} entity="metrics" style={{ marginTop: '3px' }} />}
        navigationButtons={
          <Flex gap={1}>
            <Button icon="refresh" color="success" onClick={this.handlePanelRefresh} small minimal />
          </Flex>
        }
        onExpandChange={this.handleExpandChange}
        onPopOutChange={this.handlePopOutChange}
      >
        <Box>
          {this.popOutHeader}
          <SearchableTable
            collectionOptions={{ sortState: { field: 'name', direction: 'asc' } }}
            height={240}
            loading={loading}
            columns={this.columns}
            filterKeys={['name']}
            data={data?.metrics || []}
            emptyState={this.emptyState}
            stickyHeader
            stickyGroups
          />
        </Box>
      </SidebarItem>
    );
  }
}
