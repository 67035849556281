import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import AdminTable from 'app/components/admin/AdminTable';
import { formatDateTime } from 'core/util/dateUtils';
import { CELL_TYPES, Search } from 'core/components/table';
import { Box, Button, Checkbox, DropdownMenu, Flex, Menu, MenuItem, Text } from 'core/components';
import KentikLogo from 'app/components/KentikLogo';
import RbacRoleCollection from 'app/stores/rbac/RbacRoleCollection';
import RemoveButton from '../../../core/form/components/RemoveButton';

@inject('$auth', '$rbac')
@observer
class RoleSetRoles extends Component {
  state = {
    dropdownRoleCollection: new RbacRoleCollection()
  };

  componentDidMount() {
    const { roleSetRoleCollection } = this.props;
    const { dropdownRoleCollection } = this.state;

    const roleIds = roleSetRoleCollection.map((role) => role.id);
    dropdownRoleCollection.setPresetFilter({
      fn: (role) => !roleIds.includes(role.id)
    });

    dropdownRoleCollection.fetch();
  }

  handleSearch = (e) => {
    const { dropdownRoleCollection } = this.state;
    return dropdownRoleCollection.filter(e.target.value);
  };

  updateCollections = (roleIds) => {
    const { $rbac, roleSetRoleCollection } = this.props;
    const { dropdownRoleCollection } = this.state;

    // surface-level add; not saved immediately like with users
    roleSetRoleCollection.reset();
    roleSetRoleCollection.add(
      $rbac.collection
        .filter((role) => roleIds.includes(role.id), { immutable: true })
        .map((roleModel) => ({ ...roleModel.get() }))
    );

    // update dropdown menu collection filter & clear previous selections
    dropdownRoleCollection.clearFilters();
    dropdownRoleCollection.setPresetFilter({
      fn: (role) => !roleIds.includes(role.id)
    });
  };

  removeRoleFromRoleSet = (roleModel) => {
    const { roleSetRoleCollection } = this.props;

    const roleIds = roleSetRoleCollection.map((role) => role.id);
    const removeIdx = roleIds.indexOf(roleModel.id);
    roleIds.splice(removeIdx, 1);

    this.updateCollections(roleIds);
  };

  get renderAddRoleDropdownMenu() {
    const { roleSetRoleCollection } = this.props;
    const { dropdownRoleCollection } = this.state;
    return (
      <DropdownMenu
        onClose={() => dropdownRoleCollection.clearSelection()}
        content={
          <Box p={1}>
            <Search collection={dropdownRoleCollection} onChange={this.handleSearch} rounded />
            <Menu py={1} height={310} width={300} overflow="auto" mb={1}>
              {dropdownRoleCollection.map((role) => {
                const label =
                  role.get('company_id') === null ? (
                    <Flex display="inline-flex" alignItems="center">
                      <KentikLogo onlyMark style={{ width: 16 }} />
                      <Text>{role.get('role_name')}</Text>
                    </Flex>
                  ) : (
                    role.get('role_name')
                  );
                return (
                  <MenuItem
                    as={Checkbox}
                    py="4px"
                    key={role.id}
                    labelElement={label}
                    selected={role.isSelected}
                    onClick={(e) => {
                      e.stopPropagation();
                      role.select({ multi: true, shiftKey: e.shiftKey });
                    }}
                  />
                );
              })}
            </Menu>
            <Flex p={1} justifyContent="space-between">
              <Button
                intent="primary"
                text="Apply"
                disabled={!dropdownRoleCollection.selected?.length}
                className="bp4-popover-dismiss"
                onClick={() => {
                  const existingRoleIds = roleSetRoleCollection.map((role) => role.id) || [];
                  const newRoleIds = existingRoleIds.concat(dropdownRoleCollection.selected.map((role) => role.id));
                  this.updateCollections(newRoleIds);
                }}
              />
              <Button
                className="bp4-popover-dismiss"
                text="Cancel"
                mr={2}
                onClick={() => dropdownRoleCollection.clearSelection()}
              />
            </Flex>
          </Box>
        }
      >
        <Button ml={2} icon="plus" text="Add Role(s)" intent="primary" rightIcon="caret-down" />
      </DropdownMenu>
    );
  }

  get columns() {
    const { $auth } = this.props;

    return [
      {
        name: 'role_name',
        label: 'Role Name',
        flexBasis: 30
      },
      {
        name: 'role_description',
        label: 'Description',
        flexBasis: 40
      },
      {
        name: 'cdate',
        label: 'Created',
        flexBasis: 10,
        renderer: ({ value }) => formatDateTime(value)
      },
      {
        name: 'edate',
        label: 'Last Modified',
        flexBasis: 10,
        renderer: ({ value }) => formatDateTime(value)
      },
      {
        type: CELL_TYPES.ACTION,
        width: 72,
        actions: [
          (model) => {
            if (!$auth.hasRbacPermissions(['rbac.role::delete'])) {
              return <div key="remove" />;
            }
            return (
              <RemoveButton
                key="remove"
                popoverProps={{
                  confirmBodyContent: `Are you sure you would like to remove the ${model.get(
                    'role_name'
                  )} role from the role set?`
                }}
                model={model}
                onRemove={() => this.removeRoleFromRoleSet(model)}
                showIcon
                hideText
                small
              />
            );
          }
        ]
      }
    ];
  }

  groupSummaryLookupFn = ({ groupKey }) => (
    <>
      <Flex fontWeight="bold" mx={1} style={{ alignItems: 'stretch' }}>
        {groupKey === 'null' ? (
          <>
            <KentikLogo onlyMark style={{ width: 16 }} />
            <Text mr={2}>Kentik-Managed Roles</Text>
          </>
        ) : (
          <>
            <Text mr={2}>Custom Roles</Text>
          </>
        )}
      </Flex>
    </>
  );

  render() {
    const { $auth, roleSetRoleCollection } = this.props;
    return (
      <Flex flexDirection="column" flex={1} px={2}>
        <Flex mt={1} flexDirection="row" flex={1} style={{ overflow: 'auto' }}>
          <AdminTable
            columns={this.columns}
            groupSummaryLookup={this.groupSummaryLookupFn}
            collection={roleSetRoleCollection}
            tableHeaderControlsPosition="afterSearch"
            tableHeaderControls={
              $auth.hasRbacPermissions(['rbac.role.user::assign_to_role']) && this.renderAddRoleDropdownMenu
            }
          />
        </Flex>
      </Flex>
    );
  }
}

export default RoleSetRoles;
