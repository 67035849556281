import * as React from 'react';
import { FiTarget } from 'react-icons/fi';
import { Box, Flex, Grid, Heading, Icon, Link, Text } from 'core/components';

const InternetSummary = ({ label, subType, id, value, asNumber }) => (
  <Grid gridTemplateColumns="auto 1fr" p={2} borderBottom="thin">
    <Flex alignItems="center" gridColumn="1/3">
      <Icon icon={FiTarget} iconSize={32} color="violet3" mr={1} />
      <Box>
        <Text as="div" muted>
          {label} Details
        </Text>
        <Heading level={3} mb={0}>
          <Link to={`/v4/core/quick-views/${subType}/${id}`} blank>
            {value}
          </Link>
        </Heading>
      </Box>
    </Flex>

    {asNumber !== null && (
      <Box mt={1} ml="40px">
        <Link to={`/v4/edge/peering/${asNumber}`} blank>
          View Peering Analysis
        </Link>
      </Box>
    )}
  </Grid>
);

InternetSummary.PopoutTitle = ({ value }) => (
  <Flex alignItems="center">
    <Icon icon={FiTarget} color="violet3" mr={1} />
    {value}
  </Flex>
);

export default InternetSummary;
