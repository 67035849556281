import { Grid } from 'core/components';
import React, { Component } from 'react';
import ExampleQueriesCard from './ExampleQueriesCard';

const LOOKBACK = 'PT60M';

const DEVICE_CPU_QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/system/cpus',
    dimensions: ['device_name'],
    metrics: [{ name: 'total/instant', type: 'gauge' }],
    merge: {
      aggregate: 'avg',
      dimensions: ['cpu_index']
    },
    range: {
      lookback: LOOKBACK
    },
    rollups: {
      avg_cpu_util: { aggregate: 'avg', metric: 'total/instant' }
    },
    window: {
      size: 0,
      fn: {
        'total/instant': 'avg'
      }
    },
    sort: [{ name: 'avg_cpu_util', direction: 'desc' }],
    limit: 10,
    includeTimeseries: 10
  }
};

const INTERFACE_IN_UTIL_QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/interfaces/counters',
    dimensions: ['device_name', 'name'],
    metrics: [
      {
        name: 'in-utilization',
        type: 'gauge'
      }
    ],
    range: {
      lookback: LOOKBACK
    },
    rollups: {
      IfInUtilization: {
        metric: 'in-utilization',
        aggregate: 'avg'
      }
    },
    window: {
      size: 0,
      fn: {
        'in-utilization': 'avg'
      }
    },
    sort: [
      {
        name: 'IfInUtilization',
        direction: 'desc'
      }
    ],
    limit: 10,
    includeTimeseries: 10
  }
};

const ROUTING_QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/protocols/bgp/neighbors',
    dimensions: ['device_name'],
    metrics: [
      {
        name: 'messages/received/TOTAL',
        type: 'gauge'
      },
      {
        name: 'messages/sent/TOTAL',
        type: 'gauge'
      }
    ],
    range: {
      lookback: LOOKBACK
    },
    window: {
      size: 0,
      fn: {
        'messages/received/TOTAL': 'last',
        'messages/sent/TOTAL': 'last'
      }
    },
    rollups: {
      'messages-received-total': {
        metric: 'messages/received/TOTAL',
        aggregate: 'last'
      },
      'messages-sent-total': {
        metric: 'messages/sent/TOTAL',
        aggregate: 'last'
      }
    },
    sort: [
      {
        name: 'messages-received-total',
        direction: 'desc'
      }
    ],
    limit: 10,
    includeTimeseries: 10
  }
};

const COMPONENT_QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/components/memory',
    dimensions: ['device_name', 'name'],
    metrics: [
      {
        name: 'utilization',
        type: 'gauge'
      }
    ],
    range: {
      lookback: LOOKBACK
    },
    window: {
      size: 0,
      fn: {
        utilization: 'avg'
      }
    },
    rollups: {
      avg_utilization: {
        metric: 'utilization',
        aggregate: 'avg'
      }
    },
    sort: [
      {
        name: 'avg_utilization',
        direction: 'desc'
      }
    ],
    limit: 10,
    includeTimeseries: 10
  }
};

const EXAMPLES = [
  {
    title: 'Devices',
    chart_title: 'CPU usage for my devices',
    chart_query: DEVICE_CPU_QUERY,
    examples: [
      'CPU usage by device for the last hour',
      'Memory utilization by device',
      'Device uptime and available status with OS name, version and IP'
    ]
  },
  {
    title: 'Interfaces',
    chart_title: 'Interfaces with high utilization',
    chart_query: INTERFACE_IN_UTIL_QUERY,
    examples: [
      'Interfaces with high utilization',
      'Interfaces with Admin Status up and Operational Status Down',
      'Propono interfaces summa utendo',
      'Interfaces with errors and discards',
      'Show me average packet rate '
    ]
  },
  {
    title: 'Routing',
    chart_title: 'BGP prefixes sent and received',
    chart_query: ROUTING_QUERY,
    examples: [
      'BGP prefixes sent and received',
      'BGP messages sent and received',
      'Neighbor count for my devices',
      'Total received and rejected prefixes'
    ]
  },
  {
    title: 'Hardware Components',
    chart_title: 'Component memory utilization',
    chart_query: COMPONENT_QUERY,
    examples: [
      'Component memory utilization',
      'Operational status of components by device and name',
      'Component temp by device and name',
      'Instant component cpu usage by device and name'
    ]
  }
];

export default class QueryGrid extends Component {
  render() {
    const { setNlQuery } = this.props;
    return (
      <Grid
        gridTemplateColumns="repeat(auto-fit,minmax(500px,1fr))"
        gridTemplateRows="300px 300px"
        gridGap={3}
        gridRowGap={3}
      >
        {EXAMPLES.map((example) => {
          const { title } = example;
          return <ExampleQueriesCard {...example} onSelectQuery={setNlQuery} key={title} />;
        })}
      </Grid>
    );
  }
}
