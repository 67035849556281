export const QUERY_NAMESPACE = 'AWS/NATGateway';
// The most useful statistics are is Max and Sum. See https://docs.aws.amazon.com/vpc/latest/userguide/metrics-dimensions-nat-gateway.html
export const MEANINGFUL_STATISTICS = ['Sum', 'Maximum'];

// taken from https://docs.aws.amazon.com/vpc/latest/userguide/vpc-nat-gateway-cloudwatch.html
export const METRICS = [
  'ActiveConnectionCount',
  'BytesInFromDestination',
  'BytesInFromSource',
  'BytesOutToDestination',
  'BytesOutToSource',
  'ConnectionAttemptCount',
  'ConnectionEstablishedCount',
  'ErrorPortAllocation',
  'IdleTimeoutCount',
  'PacketsDropCount',
  'PacketsInFromDestination',
  'PacketsInFromSource',
  'PacketsOutToDestination',
  'PacketsOutToSource',
  'PeakBytesPerSecond',
  'PeakPacketsPerSecond'
];

export const generateDimensionsByEntity = (nodeData) => [
  {
    Name: 'NatGatewayId',
    Value: nodeData.id
  }
];
