import moment from 'moment';
import { isString, isNumber } from 'lodash';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DEFAULT_DATETIME_FORMAT_WITH_SECONDS = 'YYYY-MM-DD HH:mm:ss';
export const DEFAULT_SQL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS';

export class Timezone {
  constructor(value = 'UTC') {
    this.value = value;
  }

  get momentFn() {
    return this.value === 'Local' ? moment : moment.utc;
  }
}
export const timezone = new Timezone();

export function formatDate(date, format = DEFAULT_DATE_FORMAT, tz = timezone) {
  return tz.momentFn(date).format(format);
}

export function formatDateTime(date, format = DEFAULT_DATETIME_FORMAT, customTimezone) {
  const tz = customTimezone instanceof Timezone ? customTimezone : timezone;
  return formatDate(date, format, tz);
}

export function formatDuration(timeInSeconds) {
  const duration = parseInt(timeInSeconds, 10);
  const days = Math.floor(duration / (3600 * 24));
  const hours = Math.floor((duration % (3600 * 24)) / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;

  const showDays = days;
  const showHours = days || hours;
  const showMinutes = hours || minutes;

  const formattedParts = [
    showDays ? `${days}d` : '',
    showHours ? `${hours}h` : '',
    showMinutes ? `${minutes}m` : '',
    `${seconds}s`
  ];

  return formattedParts.filter(Boolean).join(' ');
}

export function fromNow(date) {
  return timezone.momentFn(date).fromNow();
}

export function formatCalendar(date) {
  return timezone.momentFn(date).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: DEFAULT_DATE_FORMAT
  });
}

export function dateFormatter({ value }) {
  return value ? formatDate(value) : '';
}

export function dateTimeFormatter({ value }) {
  return value ? formatDateTime(value) : '';
}

export function getQueryTimeInterval({ lookback_seconds, starting_time, ending_time }) {
  if (lookback_seconds === 0 && starting_time && ending_time) {
    return moment.utc(ending_time).diff(moment.utc(starting_time), 'second');
  }
  if (lookback_seconds > 0 && lookback_seconds < 60) {
    const { momentFn } = timezone;
    if (lookback_seconds === 1) {
      return momentFn().diff(momentFn().date(1).hour(0).minute(0).second(0).millisecond(0), 'second');
    }
    if (lookback_seconds === 2) {
      const lastMonthStart = momentFn().date(1).hour(0).minute(0).second(0).millisecond(0).subtract(1, 'month');
      const lastMonthEnd = momentFn(lastMonthStart).date(31);
      return lastMonthEnd.diff(lastMonthStart, 'second');
    }
  }
  return lookback_seconds;
}

export function secondsIntervalToText(interval) {
  const textInterval = [];
  let remainingInterval = interval;

  if (remainingInterval >= 604800) {
    const weeks = Math.floor(remainingInterval / 604800);
    textInterval.push(`${weeks} week${weeks > 1 ? 's' : ''}`);
    remainingInterval %= 604800;
  }

  if (remainingInterval >= 86400) {
    const days = Math.floor(remainingInterval / 86400);
    textInterval.push(`${days} day${days > 1 ? 's' : ''}`);
    remainingInterval %= 86400;
  }

  if (remainingInterval >= 3600) {
    const hours = Math.floor(remainingInterval / 3600);
    textInterval.push(`${hours} hour${hours > 1 ? 's' : ''}`);
    remainingInterval %= 3600;
  }

  if (remainingInterval >= 60) {
    const minutes = Math.floor(remainingInterval / 60);
    textInterval.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
  }

  let text;
  if (textInterval.length === 0) {
    text = `${interval} seconds`; // Technically don't need this but doesn't hurt
  } else if (textInterval.length === 1) {
    [text] = textInterval;
  } else if (textInterval.length === 2) {
    text = textInterval.join(' and ');
  } else {
    text = `${textInterval.slice(0, -1).join(', ')} and ${textInterval.slice(-1)}`;
  }

  return text;
}

export function getGreeting() {
  const currentTime = moment();
  if (!currentTime || !currentTime.isValid()) {
    return 'Hello';
  }

  const splitAfternoon = 12;
  const splitEvening = 17;
  const currentHour = parseFloat(currentTime.format('HH'));

  if (currentHour >= splitAfternoon && currentHour < splitEvening) {
    return 'Good afternoon';
  }

  if (currentHour >= splitEvening) {
    return 'Good evening';
  }

  return 'Good morning';
}

// @stan will fix any resultant bugs.
export function getDateRangeDisplay(startDate, endDate) {
  const now = moment();
  const startMoment = typeof startDate === 'number' ? moment.unix(startDate) : moment(startDate);
  const endMoment = typeof endDate === 'number' ? moment.unix(endDate) : moment(endDate);

  const utc = timezone.value === 'UTC';

  if (utc) {
    startMoment.utc();
    endMoment.utc();
  }

  const sameYear = startMoment.isSame(endMoment, 'year');
  const showYear = !sameYear || !startMoment.isSame(now, 'year');
  const showEndMonth = sameYear && !startMoment.isSame(endMoment, 'month');
  const showEndDate = !startMoment.isSame(endMoment, 'day');
  const hasStartTime = startMoment.hour() !== 0 || startMoment.minutes() !== 0;
  const hasEndTime =
    endMoment.hour() * 60 + endMoment.minutes() > 0 && endMoment.hour() * 60 + endMoment.minutes() < 1439;
  const showTimes = hasStartTime || hasEndTime;

  let startFormat = '';
  let endFormat = '';

  if (showYear) {
    startFormat = 'YYYY-MM-DD';
  } else {
    startFormat = 'MMM D';
  }

  if (showTimes) {
    startFormat += ' HH:mm';
  } else if (!showYear) {
    startFormat += 'o';
  }

  if (showEndDate) {
    if (showYear) {
      endFormat = 'YYYY-MM-DD';
    } else if (showEndMonth || showTimes) {
      endFormat = 'MMM D';
    } else {
      endFormat = 'D';
    }
  }

  if (showTimes) {
    endFormat += ' HH:mm';
  } else if (showEndDate && !showYear) {
    endFormat += 'o';
  }

  return `${startMoment.format(startFormat)} to ${endMoment.format(endFormat)}`;
}

export function getStartAndEndFromLookback(lookbackSeconds, offset) {
  const end = moment.utc().startOf('minute').subtract(offset, 'seconds');
  const start = moment(end).subtract(lookbackSeconds, 'seconds');

  return { start, end };
}

export function getFetchTestResultsTimeRangeParams(lookbackSeconds, startDate, endDate) {
  if (lookbackSeconds) {
    const { start, end } = getStartAndEndFromLookback(lookbackSeconds, 120);
    return { start_time: start.unix(), end_time: end.unix() };
  }
  if (startDate && endDate) {
    return { start_time: startDate, end_time: endDate };
  }
  // Default 15m lookback until syn-back service supports windows
  const end_time = moment.utc().subtract(2, 'minute').startOf('minute').unix();
  return { start_time: end_time - 15 * 60, end_time };
}

// Slightly different than the one in QueryModel.js, because the timezone is set differently
export function dateRangeDisplay({ lookback_seconds, starting_time, ending_time, format = DEFAULT_DATE_FORMAT }) {
  let start;
  let end;

  const getMomentFn = timezone.value === 'UTC' ? moment.utc : moment;

  if (lookback_seconds) {
    start = getMomentFn().subtract(lookback_seconds, 'second');
    end = getMomentFn();
  } else {
    start = getMomentFn(starting_time);
    end = getMomentFn(ending_time);
  }

  const utcOffset = timezone.value === 'UTC' ? 0 : moment().utcOffset() / 60;

  const startOut = start.format(format);
  const endOut = ending_time ? end.format(format) : null;

  return `${startOut}${endOut && startOut !== endOut ? ` to ${endOut}` : ''} UTC${
    timezone.value !== 'UTC' ? getMomentFn().utcOffset(utcOffset).format('ZZ') : ''
  }`;
}

export function getStartAndEnd({ startDate, endDate, lookbackSeconds }) {
  if ((!lookbackSeconds && !startDate && !endDate) || lookbackSeconds) {
    const { start, end } = getStartAndEndFromLookback(lookbackSeconds || 60, 120);

    return [start.unix(), end.unix()];
  }

  return [startDate, endDate];
}

/*
    Idea: also return the moment object most appopriate based on user settings
*/
export function getMoment(stringDate, userTimeZone = 'UTC', subtractHours) {
  let theMomentToReturn = moment();
  if (stringDate) {
    theMomentToReturn = moment(stringDate);
  }

  if (isNumber(subtractHours)) {
    theMomentToReturn.subtract(subtractHours, 'hours');
  }

  if (userTimeZone === 'Local') {
    return theMomentToReturn.local();
  }
  // assume userTimezone === 'UTC'?
  return theMomentToReturn.utc();
}

export function translateFromUTCForUser({ timestamp, userTimezone }) {
  let theMomentToReturn;

  if (timestamp instanceof moment) {
    theMomentToReturn = timestamp;
  } else if (isString(timestamp)) {
    theMomentToReturn = moment(timestamp);
  }

  if (userTimezone === 'Local') {
    return theMomentToReturn.local().format(DEFAULT_DATETIME_FORMAT);
  }
  return theMomentToReturn.utc().format(DEFAULT_DATETIME_FORMAT);
}
