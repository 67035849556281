import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { FocusStyleManager } from '@blueprintjs/core';

import MessagesDialog from 'app/components/MessagesDialog';
import NotFound from 'app/views/NotFound';
import RecentExports from 'app/views/exports/RecentExports';
import SpoofAutoRedirect from 'app/views/SpoofAutoRedirect';
import ProtectedRoute from 'app/components/ProtectedRoute';
import MKPLanding from 'app/views/mkp/MKPLanding';
import AlertingDetails from 'app/views/alerting/AlertingDetails';
import Notifications from 'app/views/mkp/Notifications';
import DashboardView from 'app/views/core/dashboards/DashboardView';
import SavedView from 'app/views/core/SavedView';
import MetricsExplorer from 'app/views/metrics/MetricsExplorer';
import MKPDefaultView from 'app/views/MKPDefaultView';
import lazyLoad from '../../core/util/lazyLoad';

const Alerting = lazyLoad(() => import('./alerting/Alerting'));
const Mitigations = lazyLoad(() => import('./protect/mitigations/Mitigations'));
const Profile = lazyLoad(() => import('./profile/Profile'));

@inject('$app', '$devices', '$auth')
@withRouter
@observer
class MKPApp extends Component {
  state = {};

  componentDidMount() {
    const { $app } = this.props;
    $app.setIsSubtenant(true);
    FocusStyleManager.onlyShowFocusOnTabs();
  }

  render() {
    const { $auth } = this.props;
    return (
      <>
        <Switch>
          <Route exact path="/v4" component={MKPDefaultView} />
          <Route exact path="/v4/core" render={() => <Redirect to="/v4/landing" />} />

          <Route path="/v4/landing" component={MKPLanding} />

          <Route exact path="/v4/library/dashboards/:dashboardId" component={DashboardView} />
          <Route exact path="/v4/library/dashboards/:dashboardId/urlParams/:params" component={DashboardView} />
          <Route exact path="/v4/library/saved-views/:viewId" component={SavedView} />
          <Route exact path="/v4/library/saved-views/:viewId/hash/:hash" component={SavedView} />
          <Route exact path="/v4/library/saved-views/:viewId/urlParams/:params" component={SavedView} />
          <Route exact path="/v4/library/saved-views/hash/:hash" component={SavedView} />

          <Route exact path="/v4/nms/explorer/saved-view/:viewId" component={MetricsExplorer} />
          <Route exact path="/v4/nms/explorer/saved-view/:viewId/:queryHash" component={MetricsExplorer} />

          <Route exact path="/v4/alerting/:id" component={AlertingDetails} />
          <Route path="/v4/alerting" component={Alerting} />
          <Route path="/v4/mitigations" component={Mitigations} />
          {$auth.isTenantNotificationChannelCreationAllowed && (
            <Route path="/v4/notifications" component={Notifications} />
          )}
          <Route path="/v4/recent-exports" component={RecentExports} />
          <Route path="/v4/profile" component={Profile} />
          <ProtectedRoute
            path="/v4/spoof/:companyId/:userId"
            component={SpoofAutoRedirect}
            force2fa={$auth.isTotpExpired}
          />
          <ProtectedRoute path="/v4/unspoof" component={SpoofAutoRedirect} />
          <Route path="*" component={NotFound} />
        </Switch>

        <MessagesDialog />
      </>
    );
  }
}

export default MKPApp;
