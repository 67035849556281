import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Flex, Tag, Text } from 'core/components';

@observer
export default class SearchSummary extends React.Component {
  getGroupTitle = (key) => {
    const titleMap = {
      ids: 'IDs',
      tags: 'Tags',
      cidrs: 'CIDRs',
      accountIds: 'Account IDs',
      tenancyIds: 'Tenancy IDs',
      compartmentIds: 'Compartment IDs'
    };

    return titleMap[key] || key;
  };

  removeSearchTerm = (term) => {
    const { collection, onSearchTermChange } = this.props;
    const { filterStateGroups } = collection;
    const searchTerms = Object.values(filterStateGroups).flat();
    const searchTermList = searchTerms.filter((item) => item.trim() !== term).join(', ');

    onSearchTermChange(searchTermList);
  };

  clearGroup = (key) => {
    const { collection, onSearchTermChange } = this.props;
    const { filterStateGroups } = collection;
    const searchTermList = Object.keys(filterStateGroups)
      .filter((group) => group !== key)
      .flatMap((group) => filterStateGroups[group])
      .join(', ');

    onSearchTermChange(searchTermList);
  };

  get terms() {
    const { collection } = this.props;
    const { filterStateGroups } = collection;

    return (
      <Box>
        {Object.keys(filterStateGroups).map((key, i, all) => {
          const title = this.getGroupTitle(key);
          const terms = filterStateGroups[key];
          const termCount = terms.length;
          const lastTag = i === all.length - 1;

          return (
            <Box key={key} mb={lastTag ? 0 : 1}>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                position="sticky"
                top={0}
                bg="cardBackground"
                zIndex={1}
                lineHeight="24px"
                pt="4px"
              >
                <Text fontWeight="bold" small>
                  {title} ({termCount})
                </Text>

                {termCount > 0 && <Button text="Clear" onClick={() => this.clearGroup(key)} small minimal />}
              </Flex>

              <Box py="4px">
                {termCount > 0 &&
                  terms.map((term) => (
                    <Tag
                      key={term}
                      mr="4px"
                      onRemove={() => this.removeSearchTerm(term)}
                      intent="primary"
                      round
                      minimal
                      small
                    >
                      {term}
                    </Tag>
                  ))}

                {termCount === 0 && (
                  <Text color="muted" small>
                    No results
                  </Text>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  }

  render() {
    const { collection, onSearchTermChange, ...boxProps } = this.props;

    return (
      <Flex flexDirection="column" overflow="auto" {...boxProps}>
        {this.terms}
      </Flex>
    );
  }
}
