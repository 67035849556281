import * as React from 'react';
import { observer } from 'mobx-react';
import { FiCopy } from 'react-icons/fi';
import { Box, Button, Flex, Text } from 'core/components';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';

const DirectConnectGatewayAssociationSummary = ({ logo, value, metadata }) => {
  const nodeName = (metadata && metadata.name) || value;

  return (
    <Flex p={2} borderBottom="thin" alignItems="center">
      {React.cloneElement(logo, { width: 32, height: 32 })}
      <Box flex={1} ml={1}>
        <Text as="div" muted>
          Gateway Association Details
        </Text>
        <Text muted small>
          {nodeName}
        </Text>
        <CopyToClipboardButton text={value} intent="none" ml="4px" minimal small>
          <Button icon={FiCopy} />
        </CopyToClipboardButton>
      </Box>
    </Flex>
  );
};

DirectConnectGatewayAssociationSummary.PopoutTitle = ({ logo, value }) => (
  <Flex alignItems="center">
    {React.cloneElement(logo)}
    <Box ml={1}>{value}</Box>
  </Flex>
);

export default observer(DirectConnectGatewayAssociationSummary);
