import styled from 'styled-components';
import circleNetworkBg from 'app/assets/circle-network.png';

const Background = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  margin-top: -100px;
  background-image: linear-gradient(145.76deg, rgba(9, 34, 75, 0.9) 9.7%, rgba(30, 76, 116, 0.9) 59.09%),
    url('${circleNetworkBg}');
  background-position:
    0 0,
    bottom -200px right -200px;
  background-size:
    auto,
    1000px auto;
  background-repeat: no-repeat;
  padding: 100px;
  overflow-x: hidden;

  .logo {
    width: 150px;
    height: 30px;
  }

  h1 {
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
    color: #ffffff;
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;

export default Background;
