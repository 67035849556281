import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { isEqual } from 'lodash';
import { Collection } from 'core/model';
import { ReactComponent as PrivateAgentIcon } from 'app/assets/agents/private_agent_icon.svg';
import { BorderedTabs, BorderedTab, Box, Button, Card, Dialog, Flex, Icon, Tag, Text } from 'core/components';
import { Table, CELL_TYPES } from 'core/components/table';
import AgentRenderer from 'app/views/synthetics/components/AgentRenderer';

@inject('$syn')
@observer
export default class ManageAgentsDialog extends React.Component {
  static defaultProps = {
    agentIds: [],
    uninstalledVpcs: []
  };

  state = {
    selectedTabId: 'uninstalled'
  };

  componentDidMount() {
    this.updateAgentFilters();
  }

  componentDidUpdate(prevProps) {
    const { agentIds } = this.props;
    const agentIdsChanged = !isEqual(prevProps.agentIds, agentIds);

    if (agentIdsChanged) {
      this.updateAgentFilters();
    }
  }

  updateAgentFilters = () => {
    const { $syn, agentIds } = this.props;

    $syn.agents.removeDiscreteFilter('agents');

    if (agentIds.length > 0) {
      $syn.agents.setDiscreteFilters([
        ...$syn.agents.discreteFilters,
        {
          type: 'agents',
          fn: (model) => agentIds.includes(model.get('id'))
        }
      ]);
    }

    $syn.agents.filter();
  };

  handleTabChange = (tabId) => this.setState({ selectedTabId: tabId });

  get collection() {
    const { $syn, uninstalledVpcs } = this.props;
    const { selectedTabId } = this.state;

    if (selectedTabId === 'uninstalled') {
      return new Collection(uninstalledVpcs);
    }

    return new Collection($syn.agents[`${selectedTabId}AgentsFiltered`]);
  }

  get columns() {
    const { onModelChange } = this.props;
    const { selectedTabId } = this.state;
    const isUninstalled = selectedTabId === 'uninstalled';
    const columns = [];

    if (isUninstalled) {
      columns.push({
        name: 'vpcName',
        label: 'Virtual Network Name/ID',
        renderer: ({ model }) => {
          const vpcName = model.get('vpcName');
          const vpcId = model.get('vpcId');

          return (
            <Box>
              <Text as="div">{vpcName}</Text>
              {vpcName !== vpcId && <Text>{vpcId}</Text>}
            </Box>
          );
        }
      });
    } else {
      columns.push({
        name: 'label',
        label: 'Agent',
        computed: true,
        renderer: ({ model, updateGrid }) => <AgentRenderer agent={model} showLabels updateGrid={updateGrid} />
      });
    }

    columns.push({
      type: CELL_TYPES.ACTION,
      name: 'actions',
      align: 'right',
      width: 200,
      actions: [
        (model) => (
          <Button
            key="action-button"
            intent="primary"
            // eslint-disable-next-line no-nested-ternary
            text={isUninstalled ? 'Deploy Agent' : selectedTabId === 'pending' ? 'Activate' : 'Configure Agent'}
            onClick={() => onModelChange({ type: 'select', model })}
            small
          />
        )
      ]
    });

    return columns;
  }

  render() {
    const { $syn, uninstalledVpcs, ...dialogProps } = this.props;
    const { selectedTabId } = this.state;

    return (
      <Dialog title="Manage Agents" height={400} width={700} {...dialogProps}>
        <Dialog.Body display="flex" flexDirection="column" overflow="hidden">
          <Text pb={2}>
            Use this feature to see which virtual networks are accessing cloud services. Unmonitored virtual networks do
            not have an agent installed and/or associated. Click <Text fontWeight="bold">Deploy Agent</Text> to view
            setup instructions.
          </Text>
          <BorderedTabs onChange={this.handleTabChange} selectedTabId={selectedTabId}>
            <BorderedTab
              id="uninstalled"
              title={
                <Flex alignItems="center">
                  <Box mr={1}>Unmonitored</Box>
                  <Tag round small minimal textAlign="center">
                    {uninstalledVpcs.length}
                  </Tag>
                </Flex>
              }
            />
            <BorderedTab
              id="pending"
              title={
                <Flex alignItems="center">
                  <Box mr={1}>Pending Agents</Box>
                  <Tag round small minimal textAlign="center">
                    {$syn.agents.pendingAgentsFiltered.length}
                  </Tag>
                </Flex>
              }
            />
            <BorderedTab
              id="private"
              title={
                <Flex alignItems="center">
                  <Icon icon={PrivateAgentIcon} mr="4px" iconSize={20} />
                  <Box mr={1}>Private Agents</Box>
                  <Tag round small minimal textAlign="center">
                    {$syn.agents.privateAgentsFiltered.length}
                  </Tag>
                </Flex>
              }
            />
          </BorderedTabs>
          <Card mt="-16px" flex={1} overflow="auto">
            <Table collection={this.collection} columns={this.columns} selectOnRowClick={false} />
          </Card>
        </Dialog.Body>
      </Dialog>
    );
  }
}
