import { asnOptions, asnFields } from './fields/asnFields';
import { cdnOptions, cdnFields } from './fields/cdnFields';
import { countryOptions, countryFields } from './fields/countryFields';
import { regionOptions, regionFields } from './fields/regionFields';
import { cityOptions, cityFields } from './fields/cityFields';

const flowTestConfigs = {
  asn: { fields: asnFields, options: asnOptions },
  cdn: { fields: cdnFields, options: cdnOptions },
  country: { fields: countryFields, options: countryOptions },
  region: { fields: regionFields, options: regionOptions },
  city: { fields: cityFields, options: cityOptions }
};

export { flowTestConfigs };
