import React from 'react';
import { Box, Text } from 'core/components';

export default function LabelValue({ label, value, valueTextProps, ...rest }) {
  return (
    <Box mb={1} {...rest}>
      <Text fontWeight="bold">{label}</Text>: {value && <Text {...valueTextProps}>{value}</Text>}
    </Box>
  );
}
