import $dictionary from 'app/stores/$dictionary';
import { getAggregates } from 'app/stores/query/QueryModel';

// Note: this function, as written, will not work with alerting
function noConflictingUniques(value) {
  const { aggregateTypes } = this.validator.input;
  if (!aggregateTypes) {
    return true;
  }
  const units = Array.from(new Set(getAggregates(aggregateTypes).map((agg) => agg.unit)));

  // If we want to enable this to work with alerting, use this instead of the code above
  // const { aggregateTypes, metrics } = this.validator.input;
  // let aggregates = [];

  // if (aggregateTypes && Array.isArray(aggregateTypes)) {
  //   aggregates = [...aggregateTypes];
  // } else if (metrics && Array.isArray(metrics)) {
  //   // uniques in alerting don't begin with a prefix (max, avg, etc.), so add one
  //   aggregates = metrics.map(metric => (metric.startsWith('unique_') ? `agg_total_${metric}` : metric));
  // }

  // if (aggregates.length === 0) {
  //   return true;
  // }

  // const units = Array.from(new Set(getAggregates(aggregates).map(agg => agg.unit)));

  const uniqueMetricsMap = $dictionary.get('uniqueMetricsMap');

  let metricsArray = value;
  if (!Array.isArray(value)) {
    metricsArray = [value];
  }

  return units.every(
    (unit) => !uniqueMetricsMap[unit] || !uniqueMetricsMap[unit].some((col) => metricsArray.includes(col))
  );
}

export default {
  fn: noConflictingUniques,
  message: 'You cannot use a unique count metric for a selected dimension.'
};
