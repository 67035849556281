import * as React from 'react';
import { Box, Flex, Grid, Heading, Icon, Text } from 'core/components';
import { BiNetworkChart } from 'react-icons/bi';

const ServiceSummary = ({ value }) => (
  <Grid gridTemplateColumns="auto 1fr" p={2} borderBottom="thin">
    <Flex alignItems="center" gridColumn="1/3">
      <Icon icon={BiNetworkChart} iconSize={32} color="warning" mr={1} />
      <Box>
        <Text as="div" muted>
          Service Details
        </Text>
        <Heading level={3} mb={0}>
          {value}
        </Heading>
      </Box>
    </Flex>
  </Grid>
);

ServiceSummary.PopoutTitle = ({ value }) => (
  <Flex alignItems="center">
    <Icon icon={BiNetworkChart} color="warning" mr={1} />
    {value}
  </Flex>
);

export default ServiceSummary;
