import React from 'react';

import { Flex } from 'core/components';

import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';

const AzureLoadBalancingRuleDetails = ({ width, popoutTitle, nodeData }) => (
  <SidebarItem
    excludeFormProps
    title={nodeData?.name}
    dialogProps={{ width }}
    fullscreenProps={{ headingOffset: -16 }}
    icon="shield"
    popoutTitle={popoutTitle}
  >
    <Flex flexDirection="column" gap={1} width="100%">
      <CloudMetadata
        node={{
          ...nodeData,
          ...nodeData.properties
        }}
        keys={['provisioningState', 'frontendPort', 'backendPort', 'protocol', 'PrivateIpAdress']}
      />
    </Flex>
  </SidebarItem>
);

export default AzureLoadBalancingRuleDetails;
