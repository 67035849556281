import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { VirtualizedTable, Search } from 'core/components/table';
import { Box, Button, Card, Flex, Icon, Tag, Text, Heading } from 'core/components';
import Collection from 'core/model/Collection';

import { FiCopy } from 'react-icons/fi';
import { FaCheck } from 'react-icons/fa';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';

class RulesCollection extends Collection {
  groupBy = 'securityListName';
}

@observer
class SecurityListTable extends React.Component {
  static defaultProps = {
    isIngressRules: false
  };

  @observable
  securityListCollection;

  constructor(props) {
    super(props);
    const { securityRules } = props;

    this.securityListCollection = new RulesCollection(securityRules);
  }

  get columns() {
    const { isIngressRules } = this.props;
    const columns = [
      {
        label: isIngressRules ? 'Source' : 'Destination',
        name: isIngressRules ? 'source' : 'destination',
        width: 125,
        renderer: ({ value }) => (
          <Flex alignItems="center" gap={1}>
            <Box>
              <Text as="div">{value}</Text>
            </Box>
            <CopyToClipboardButton text={value} copyConfirmationText={null} intent="none" minimal small>
              <Button icon={FiCopy} />
            </CopyToClipboardButton>
          </Flex>
        )
      },

      {
        label: `${isIngressRules ? 'Source' : 'Destination'} Type`,
        name: isIngressRules ? 'sourceType' : 'destinationType',
        width: 75
      },
      {
        label: 'Protocol',
        name: 'protocol',
        width: 75
      },
      {
        label: 'Description',
        name: 'description'
      }
    ];

    return columns;
  }

  handleSearch = (e) => this.securityListCollection.filter(e.target.value);

  groupSummaryLookup = ({ groupKey, group }) => {
    const lifecycleState = group[0].get('lifecycleState');
    return (
      <>
        {lifecycleState === 'AVAILABLE' && <Icon icon={FaCheck} color="success" />}
        <Text fontWeight="bold" mx={1}>
          {groupKey}
        </Text>
        <Tag minimal round>
          {group.length}
        </Tag>
      </>
    );
  };

  render() {
    const { isIngressRules } = this.props;

    return (
      <Flex flexDirection="column" flex={1}>
        <Card height="100%">
          <Heading m={1} level={5} fontWeight="heavy">
            {isIngressRules ? 'Ingress Security Rules' : 'Egress Security Rules'}
          </Heading>
          <Search
            p="4px"
            collection={this.securityListCollection}
            onChange={this.handleSearch}
            placeholder="Search Security Rules..."
            inputProps={{ value: this.securityListCollection.filterState }}
            autoFocus
          />
          <VirtualizedTable
            style={{ height: 150 }}
            collection={this.securityListCollection}
            columns={this.columns}
            selectOnRowClick={false}
            groupSummaryLookup={this.groupSummaryLookup}
            isCollapsed
            flexed
          />
        </Card>
      </Flex>
    );
  }
}

export default SecurityListTable;
