/*
  a memoized factory for cloud sidebar summaries
*/
import * as React from 'react';
import { ENTITY_TYPES } from 'shared/hybrid/constants';

import CloudSummary from './CloudSummary';
import CustomerGatewaySummary from './CustomerGatewaySummary';
import DefaultSummary from './DefaultSummary';
import DeviceSummary from './DeviceSummary';
import DirectConnectionSummary from './DirectConnectionSummary';
import DirectConnectGatewaySummary from './DirectConnectGatewaySummary';
import DirectConnectGatewayAssociationSummary from './DirectConnectGatewayAssociationSummary';
import GatewaySummary from './GatewaySummary';
import InterfaceSummary from './InterfaceSummary';
import InternetSummary from './InternetSummary';
import K8NodeSummary from './K8NodeSummary';
import K8NodetoNodeLinkSummary from './K8NodetoNodeLinkSummary';
import K8PodSummary from './K8PodSummary';
import K8PodtoPodLinkSummary from './K8PodtoPodLinkSummary';
import LagSummary from './LagSummary';
import LinkSummary from './LinkSummary';
import RegionSummary from './RegionSummary';
import RouterSummary from './RouterSummary';
import ServiceSummary from './ServiceSummary';
import SiteSummary from './SiteSummary';
import SiteToSiteLinkSummary from './SiteToSiteLinkSummary';
import SubnetSummary from './SubnetSummary';
import CoreNetworkEdgeSummary from './aws/CoreNetworkEdgeSummary';
import CoreNetworkAttachmentSummary from './aws/CoreNetworkAttachmentSummary';
import TransitGatewaySummary from './TransitGatewaySummary';
import TransitGatewayVPCAttachmentSummary from './TransitGatewayVPCAttachmentSummary';
import VirtualInterfaceSummary from './VirtualInterfaceSummary';
import VirtualNetworkSummary from './VirtualNetworkSummary';
import VPNConnectionSummary from './VPNConnectionSummary';
import VPNGatewaySummary from './VPNGatewaySummary';
import VpcEndpointSummary from './VpcEndpointSummary';
import ClusterSummary from './ClusterSummary';
import KubeResourceSummary from './KubeResourceSummary';

import VcnSummary from './oci/VcnSummary';
import InternetGatewaySummary from './oci/InternetGatewaySummary';
import LocalPeeringGatewaySummary from './oci/LocalPeeringGatewaySummary';
import DinamicRoutingGatewayAttachmentSummary from './oci/DinamicRoutingGatewayAttachmentSummary';

const { CORE_NETWORK_EDGE, CORE_NETWORK_ATTACHMENT } = ENTITY_TYPES.get('aws');

const { INTERNET_GATEWAY, LOCAL_PEERING_GATEWAY, VIRTUAL_CLOUD_NETWORK, DYNAMIC_ROUTING_GATEWAY_ATTACHMENT } =
  ENTITY_TYPES.get('oci');

const summaries = {
  cloud: CloudSummary,
  CustomerGateway: CustomerGatewaySummary,
  device: DeviceSummary,
  DirectConnection: DirectConnectionSummary,
  DirectConnectGateway: DirectConnectGatewaySummary,
  directConnectGatewayAssociation: DirectConnectGatewayAssociationSummary,
  downstream: DeviceSummary,
  gateway: GatewaySummary,
  interface: InterfaceSummary,
  internet: InternetSummary,
  k8node: K8NodeSummary,
  k8pod: K8PodSummary,
  Lag: LagSummary,
  link: LinkSummary,
  parallel: DeviceSummary,
  region: RegionSummary,
  location: RegionSummary,
  Router: RouterSummary,
  infrastructure: ServiceSummary,
  site: SiteSummary,
  subnet: SubnetSummary,
  TransitGateway: TransitGatewaySummary,
  TransitGatewayVpcAttachment: TransitGatewayVPCAttachmentSummary,
  upstream: DeviceSummary,
  VirtualInterface: VirtualInterfaceSummary,
  cluster: ClusterSummary,
  namespace: KubeResourceSummary,
  resource: KubeResourceSummary,
  vpc: VirtualNetworkSummary,
  Vpc: VirtualNetworkSummary, // cloud interconnects vpc type
  VpnConnection: VPNConnectionSummary,
  VpnGateway: VPNGatewaySummary,
  VpcEndpoint: VpcEndpointSummary,

  [CORE_NETWORK_EDGE]: CoreNetworkEdgeSummary,
  [CORE_NETWORK_ATTACHMENT]: CoreNetworkAttachmentSummary,

  // azure specific summaries
  router: RouterSummary, // gcp too
  vnet: VirtualNetworkSummary,

  // gcp summaries
  network: VirtualNetworkSummary,

  // oci
  [VIRTUAL_CLOUD_NETWORK]: VcnSummary,
  [INTERNET_GATEWAY]: InternetGatewaySummary,
  [LOCAL_PEERING_GATEWAY]: LocalPeeringGatewaySummary,
  [DYNAMIC_ROUTING_GATEWAY_ATTACHMENT]: DinamicRoutingGatewayAttachmentSummary
};

class SidebarSummary extends React.Component {
  render() {
    const { type, subType, source, target, popout, details } = this.props;
    const componentType = type === 'cloudInterconnect' ? subType : type;

    let Component = summaries[componentType] || summaries[componentType.replace(/s$/, '')] || null;

    if (componentType === 'link') {
      // special case here where we test for target as well
      // the rest of the summaries are chosen by source type
      if (source.type === 'site' && target.type === 'site') {
        Component = SiteToSiteLinkSummary;
      } else if (source.type === 'k8node' && target.type === 'k8node') {
        Component = K8NodetoNodeLinkSummary;
      } else if (source.type === 'k8pod' && target.type === 'k8pod') {
        Component = K8PodtoPodLinkSummary;
      } else {
        Component = LinkSummary;
      }
    }

    if (!Component) {
      // check if there's a new 'default' style config
      if (details) {
        Component = DefaultSummary;
      } else {
        console.warn(`The type '${componentType}' does not have a summary component in the factory`);
        return null;
      }
    }

    if (popout) {
      if (!Component.PopoutTitle) {
        console.warn(`The type '${componentType}' does not have a summary popout title component in the factory`);
        return null;
      }

      return <Component.PopoutTitle {...this.props} />;
    }

    return <Component {...this.props} />;
  }
}

export default React.memo(SidebarSummary);
