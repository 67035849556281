import React from 'react';
import { observer } from 'mobx-react';
import { VirtualizedTable, Search } from 'core/components/table';
import { Box, Button, Card, Flex, Icon, Tag, Text } from 'core/components';
import { AzureRouteTableSummaryCollection } from 'app/stores/hybrid/azure';

import { FiCopy } from 'react-icons/fi';
import { FaCheck } from 'react-icons/fa';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';

@observer
class VHubRouteTable extends React.Component {
  constructor(props) {
    super(props);
    const { routes } = props;

    this.routesCollection = new AzureRouteTableSummaryCollection(routes, { groupBy: 'routeTable' });
  }

  get columns() {
    const columns = [
      {
        label: 'State',
        name: 'provisioningState',
        width: 50,
        renderer: ({ value }) => {
          if (value !== 'Succeeded') {
            return <Icon icon="error" color="danger" />;
          }

          return <Icon icon={FaCheck} intent="success" />;
        }
      },

      {
        label: 'Prefix',
        name: 'addressPrefix',
        width: 145,
        renderer: ({ value }) => (
          <Flex alignItems="center" gap={1}>
            <Box>
              <Text as="div">{value}</Text>
            </Box>
            <CopyToClipboardButton text={value} copyConfirmationText={null} intent="none" minimal small>
              <Button icon={FiCopy} />
            </CopyToClipboardButton>
          </Flex>
        )
      },
      {
        label: 'Next Hop',
        name: 'nextHop',
        width: 350,
        renderer: ({ value, model }) => {
          const copyValue = model.get('nextHopId');
          const nextHopType = model.get('nextHopType');
          return (
            <Flex alignItems="flex-start" flexDirection="column">
              <Box>
                <Text as="div">{nextHopType}</Text>
              </Box>
              <Flex alignItems="center" gap={1}>
                <Box>
                  <Text as="b">{value}</Text>
                </Box>
                <CopyToClipboardButton text={copyValue} copyConfirmationText={null} intent="none" minimal small>
                  <Button icon={FiCopy} />
                </CopyToClipboardButton>
              </Flex>
            </Flex>
          );
        }
      }
    ];

    return columns;
  }

  handleSearch = (e) => this.routesCollection.filter(e.target.value);

  groupSummary = ({ group }) => {
    const { isPoppedOut } = this.props;
    const maxWidth = isPoppedOut ? 'auto' : 345;
    const routeTable = group[0].get('routeTable');
    const labelCopyValue = group[0].get('routeTableId');
    const { totalActiveRoutes, totalBlackholeRoutes } = group.reduce(
      (acc, model) => {
        const routeState = model.get('provisioningState');

        if (routeState === 'Succeeded') {
          acc.totalActiveRoutes += 1;
        } else {
          // TODO - not sure if routes that arent provisioningState: Succeeded should be categorized as blackholeRoutes?
          acc.totalBlackholeRoutes += 1;
        }

        return acc;
      },
      {
        totalActiveRoutes: 0,
        totalBlackholeRoutes: 0
      }
    );
    return (
      <Flex alignItems="center">
        <Tag mr={1} intent="success" icon={FaCheck} title={`${totalActiveRoutes} active`} minimal round>
          {totalActiveRoutes}
        </Tag>
        {totalBlackholeRoutes > 0 && (
          <Tag mr={1} intent="danger" icon="error" title={`${totalBlackholeRoutes} blackhole`} minimal round>
            {totalBlackholeRoutes}
          </Tag>
        )}
        <Box maxWidth={maxWidth}>
          <Flex alignItems="center">
            <Text fontWeight="bold" mr={1} ellipsis>
              Table: {routeTable}
            </Text>
            {labelCopyValue && (
              <CopyToClipboardButton text={labelCopyValue} copyConfirmationText={null} intent="none" minimal small>
                <Button icon={FiCopy} />
              </CopyToClipboardButton>
            )}
          </Flex>
        </Box>
      </Flex>
    );
  };

  render() {
    return (
      <Flex flexDirection="column" flex={1}>
        <Card height="100%">
          <Search
            p="4px"
            collection={this.routesCollection}
            onChange={this.handleSearch}
            placeholder="Search Route Table..."
            inputProps={{ value: this.routesCollection.filterState }}
            autoFocus
          />
          <VirtualizedTable
            style={{ height: 300 }}
            collection={this.routesCollection}
            columns={this.columns}
            groupSummaryLookup={this.groupSummary}
            selectOnRowClick={false}
            isCollapsed
            flexed
          />
        </Card>
      </Flex>
    );
  }
}

export default VHubRouteTable;
