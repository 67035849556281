import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { orderBy } from 'lodash';
import { Spinner } from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import TopKeysLayout from './TopKeysLayout';

@inject('$hybridMap')
@observer
export default class TopKeysListing extends React.Component {
  static defaultProps = {
    layout: TopKeysLayout,
    aggregateNames: ['Inbound', 'Outbound']
  };

  state = {
    results: [],
    totalBytes: 0
  };

  get topKeyType() {
    const { query } = this.props;
    const typeMap = {
      'AS Number': 'asn',
      Provider: 'provider',
      'Next Hop AS Number': 'nextHopASN'
    };

    return typeMap[query.aggregateFiltersDimensionLabel];
  }

  getData = (results) => {
    if (results) {
      const asnRegex = /(.+)(\(\d+\))$/;
      const data = orderBy(results.toJS(), ['Total'], ['desc']);

      return data
        .map(({ key, Total }) => ({
          key,
          label: key.replace(asnRegex, '$2 $1'),
          bytes: Total
        }))
        .filter((item) => item.bytes > 0);
    }

    return null;
  };

  getResultsWithTotal = (results) => {
    const { aggregateNames } = this.props;

    // rollling up manually since asn queries do not support total overlays at the moment
    results.each((model) => {
      const total = aggregateNames.reduce((acc, item) => acc + (model.get(item) || 0), 0);

      model.set('Total', total);
    });

    return results;
  };

  handleQueryComplete = ({ results: queryResults, fullyLoaded }) => {
    if (fullyLoaded) {
      const resultsWithTotal = this.getResultsWithTotal(queryResults);
      const totalBytes = resultsWithTotal.reduce((acc, model) => acc + (model.get('Total') || 0), 0);

      const results = this.getData(resultsWithTotal);

      this.setState({ totalBytes, results });
    }
  };

  handleQueryError = (error) => {
    console.warn(`Unable to execute query, error - ${error}`);
    this.setState({ results: [] });
  };

  render() {
    const { classPrefix, emptyState, onSelect, selected, highlighted, query, layout: Layout } = this.props;
    const { totalBytes, results } = this.state;

    return (
      <LightweightDataViewWrapper
        query={query}
        onQueryError={this.handleQueryError}
        onQueryComplete={this.handleQueryComplete}
      >
        {({ fullyLoaded }) =>
          !fullyLoaded ? (
            <Spinner mx="auto" />
          ) : (
            <Layout
              classPrefix={classPrefix}
              emptyState={emptyState}
              totalBytes={totalBytes}
              onSelect={onSelect}
              selected={selected}
              highlighted={highlighted}
              topKeyType={this.topKeyType}
              results={results}
            />
          )
        }
      </LightweightDataViewWrapper>
    );
  }
}
