/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Box, Button, Text, Flex } from 'core/components';
import { FormComponent } from 'core/form';
import ApplyParametricOptions from 'app/views/core/dashboards/ApplyParametricOptions';
import { inject } from 'mobx-react';

const parametricFields = {
  parametric_fields: {
    isComplexArray: true
  },
  'parametric_fields[].type': {},
  'parametric_fields[].label': {},
  'parametric_fields[].question': {},
  'parametric_fields[].value': {
    rules: 'required'
  }
};

@inject('$dashboards')
export default class GuidedViewForm extends Component {
  handleParametricSubmit = (dashbboard, parametricFieldValues) => {
    const { $dashboards } = this.props;
    dashbboard.set(parametricFieldValues);
    $dashboards.navigateToDashboard(dashbboard, true, null, null);
  };

  getFormValuesFromDashboard = (model) => {
    const values = model.get();
    const [parametric_field] = values.parametric_fields;
    parametric_field.value = undefined;

    return values;
  };

  render() {
    const { view, textStyle } = this.props;
    return (
      <FormComponent
        fields={parametricFields}
        options={{ name: `${view.id} Parametric`, showPristineErrors: false }}
        values={this.getFormValuesFromDashboard(view)}
        fontSize={12}
      >
        <ApplyParametricOptions dashboard={view} handleSubmit={this.handleParametricSubmit}>
          {({ buttonProps, inputField, question }) => (
            <Flex flexDirection="column" gap="4px">
              <Text flex={1} fontSize={12} muted fontWeight="medium" style={{ ...textStyle }}>
                {question}
              </Text>
              <Flex alignItems="center">
                <Box flex={2}>{React.cloneElement(inputField, { width: '100%', mb: 0 })}</Box>
                <Button small {...buttonProps} text="Go" ml={1} />
              </Flex>
            </Flex>
          )}
        </ApplyParametricOptions>
      </FormComponent>
    );
  }
}
