/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { withTheme } from 'styled-components';
import { isEqual, memoize, merge, uniq, uniqBy } from 'lodash';
import { darken, opacify } from 'polished';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import { getASNValues } from 'app/util/queryResults';
import { Box, EmptyState, Flex, MenuItem, showInfoToast, Spinner } from 'core/components';
import { showErrorToast } from 'core/components/toast';
import { getConsoleUrl } from 'app/views/hybrid/utils/azure';
import { getCustomProperties, uriToObject } from 'shared/util/map';
import { ENTITY_TYPES, MAP_TYPES } from 'shared/hybrid/constants';
import {
  generateLinksForHighlightedNode,
  getEntityType,
  getGatewayByName,
  getMapClassname,
  rewriteFallbackLinks
} from 'app/views/hybrid/utils/map';
import { AZURE, getDimensionFilter } from 'app/views/hybrid/maps/cloudDimensionConstants';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import ItemGrid from 'app/views/hybrid/maps/components/ItemGrid';
import TrafficLinkGenerator from 'app/views/hybrid/maps/components/TrafficLinkGenerator';
import {
  getInternetDimension,
  getInternetFilterField
} from 'app/views/hybrid/maps/components/popovers/queryOptionsHelper';
import CloudMapConnector from 'app/views/hybrid/utils/cloud/connector';
import {
  getTotalWidth,
  GRID_ROWS_GAP,
  ICON_SIZE,
  INTERNET_WIDTH,
  LINK_SPACING,
  LOCATION_PADDING,
  ON_PREM_FLEX_GAP,
  REGION_MARGIN
} from 'app/views/hybrid/utils/cloud/constants';
import Location from './components/Location/Location';
import AbstractMap from '../components/AbstractMap';
import withPopover from '../components/popovers/withPopover';
import CloudMapBox from '../components/CloudMapBox';
import TopKeys from '../components/TopKeys/TopKeys';
import { AzureSubnetDestinations } from './components';

const {
  VNET,
  ROUTER,
  SUBNET,
  LOCATION,
  VPN_SITE,
  VIRTUAL_WAN,
  VIRTUAL_HUB,
  VNET_PEERING,
  VPN_LINK_CONNECTION,
  LOCAL_NETWORK_GATEWAY,
  EXPRESS_ROUTE_CIRCUIT,
  VNET_GATEWAY_CONNECTION,
  EXPRESS_ROUTE_CONNECTION,
  HUB_VIRTUAL_NETWORK_CONNECTION,
  LOAD_BALANCER,
  NAT_GATEWAY,
  VNET_GATEWAY,
  FIREWALL,
  APPLICATION_GATEWAY
} = ENTITY_TYPES.get('azure');
@withPopover
@withTheme
@inject('$hybridMap')
@observer
export default class AzureMap extends AbstractMap {
  constructor(props) {
    super(props);

    Object.assign(this.state, {
      boxExpanded: {
        internet: true,
        onprem: true
      },
      loading: true,
      topology: {
        Hierarchy: {}
      },
      selectedVNets: []
    });
  }

  componentDidMount() {
    const { $hybridMap } = this.props;
    const subscriptionId = this.initialSidebarNode?.subscriptionId;

    if (subscriptionId) {
      // override the subscription ids in the settings model
      // this will allow for the UI to display the correct number of subscriptions (1) that are selected
      // this will not save the settings and the user will be able to add more subscriptions to the filter
      // also, the subscription panel will offer a way to detect a filtered state and a method for completely clearing it and returning to the last known set of subscriptions in the settings
      $hybridMap.settingsModel.set({
        azureSubscriptionIds: [subscriptionId]
      });
    }

    this.fetchTopology();
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarSettings, drawerIsOpen } = this.props;
    const { activeNode } = this.state;
    const mapSearchChanged = prevProps.sidebarSettings.searchTerm !== sidebarSettings.searchTerm;

    // will enforce format 12 PM 11/3/2023, this is to prevent refetching topology when hour doesn't change
    const compareDateOption = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', hour12: true };

    const prevTimeRangeStart = new Date(prevProps.sidebarSettings.timeRange.start).toLocaleDateString(
      'en-us',
      compareDateOption
    );
    const prevTimeRangeEnd = new Date(prevProps.sidebarSettings.timeRange.end).toLocaleDateString(
      'en-us',
      compareDateOption
    );

    const newTimeRangeStart = new Date(sidebarSettings.timeRange.start).toLocaleDateString('en-us', compareDateOption);
    const newTimeRangeEnd = new Date(sidebarSettings.timeRange.end).toLocaleDateString('en-us', compareDateOption);

    const timeSettingsChanged = prevTimeRangeStart !== newTimeRangeStart || prevTimeRangeEnd !== newTimeRangeEnd;
    const selectedSubscriptionsChanged = !isEqual(
      prevProps.sidebarSettings.azureSubscriptionIds,
      sidebarSettings.azureSubscriptionIds
    );

    const drawerChanged = prevProps.drawerIsOpen !== drawerIsOpen;
    const activeNodeChanged = prevProps.activeNode !== activeNode;

    if (mapSearchChanged) {
      this.topologyCollection.filter(sidebarSettings.searchTerm);
    }

    if (selectedSubscriptionsChanged) {
      this.fetchTopology();
      return;
    }

    if (timeSettingsChanged) {
      this.fetchTopology();
      return;
    }

    if (drawerChanged || selectedSubscriptionsChanged) {
      this.getRegionBoxPositions.cache.clear();
      this.getVNetBoxPositions.cache.clear();
    }

    if (activeNodeChanged || selectedSubscriptionsChanged) {
      if (!activeNode) {
        this.topologyCollection.resetHighlightedNodes();
      }

      this.calculateStaticLinks.cache.clear();
    }

    super.componentDidUpdate(prevProps, prevState);
  }

  setInitialState(state) {
    const { $hybridMap } = this.props;
    const { topology } = $hybridMap.azureCloudMapCollection;
    const { initialSidebarNode } = this;

    if (initialSidebarNode) {
      // we have a url indicating we want to automatically open a sidebar detail for an entity on the map
      const { type, value } = initialSidebarNode;
      const entities = Object.values(topology.Entities?.[type] || {});
      const nodeData = entities.find((entity) => {
        if (type === SUBNET) {
          // subnets are found by complete id to prevent collisions with multiple subnets named 'default'
          return entity.id === value;
        }

        return entity.name === value;
      });

      if (type === VNET || type === VIRTUAL_HUB) {
        state.selectedVNets = [nodeData?.id];
      }

      if (
        type === NAT_GATEWAY ||
        type === VNET_GATEWAY ||
        type === FIREWALL ||
        type === LOAD_BALANCER ||
        type === APPLICATION_GATEWAY
      ) {
        let subnets = nodeData?.properties?.subnets || [];

        if (type === VNET_GATEWAY || type === FIREWALL) {
          subnets = (nodeData?.properties?.ipConfigurations || []).map(
            (ipConfiguration) => ipConfiguration.properties?.subnet || {}
          );
        }

        if (type === LOAD_BALANCER || type === APPLICATION_GATEWAY) {
          subnets = getCustomProperties(nodeData).relatedEntities?.subnets || [];
        }

        state.selectedVNets = subnets.map((subnet) => {
          const subnetName = uriToObject(subnet.id).subnet;

          // chop off the subnet identifier to end up with a complete vnet id
          return subnet.id.replace(`/subnets/${subnetName}`, '');
        });
      }

      if (type === SUBNET) {
        const { vnetId } = getCustomProperties(nodeData);

        if (vnetId) {
          state.selectedVNets = [vnetId];
        }
      }

      if (nodeData) {
        return this.setState(state, () => this.handleShowDetails({ type, value, nodeData, forceDetails: true }));
      }
    }

    return this.setState(state);
  }

  /*
    @override
    Helper used in componentDidUpdate
  */
  shouldLinksRedraw(prevProps, prevState) {
    const { persistantNodeLinks, selectedVNets, selectedNode, hoveredNode, activeNode, nodeLinks } = this.state;

    if (super.shouldLinksRedraw(prevProps, prevState)) {
      return true;
    }

    if (hoveredNode?.value !== prevState.hoveredNode?.value && (nodeLinks?.length ?? 0) === 0) {
      return true;
    }

    if (!isEqual(activeNode, prevState.activeNode)) {
      return true;
    }

    return (
      !isEqual(prevState.persistantNodeLinks, persistantNodeLinks) ||
      !isEqual(prevState.selectedVNets, selectedVNets) ||
      selectedNode?.value !== prevState.selectedNode?.value
    );
  }

  /*
    @override
    Determine if the given type should be rolled up to the 'box' level when getting node links
  */
  shouldTargetLinkRollUp(type) {
    return ['onprem', 'internet'].includes(type);
  }

  /*
    @borrowed from CloudAwsMap
  */
  styleLink = (link) => {
    const { theme } = this.props;
    const { source, target } = link;
    let connectionType = 'square';
    const color = theme.name === 'light' ? theme.colors.primary : darken(0.35, opacify(1, theme.colors.primary));

    // express route connection links should be square
    if ([EXPRESS_ROUTE_CONNECTION, VPN_LINK_CONNECTION].includes(target.type)) {
      return { connectionType, color, ...link };
    }

    if (
      (source.type === VNET && target.type === LOCATION) ||
      source.type === HUB_VIRTUAL_NETWORK_CONNECTION ||
      target.type === VNET ||
      source.type === SUBNET ||
      target.type === SUBNET ||
      source.type === VIRTUAL_HUB ||
      source.type === VNET_PEERING ||
      target.type === VNET_PEERING
    ) {
      connectionType = 'curved';
    }

    return { connectionType, color, ...link };
  };

  /*
    @borrowed from CloudAwsMap
  */
  getRegionOffset({ type, value }) {
    const { topology } = this.topologyCollection;
    const { Entities, Hierarchy } = topology;
    const { locations } = Hierarchy;
    const entity = Entities[type]?.[value];

    if (['box', 'internet', 'onprem'].includes(type)) {
      return -locations.length;
    }

    let regionName = entity?.location;

    if (type === LOCATION) {
      regionName = value;
    } else if (type === SUBNET) {
      const subnet = Object.values(Entities.subnets).find((s) => s.name === value);

      if (subnet) {
        regionName = getCustomProperties(subnet).location;
      }
    } else if (type === 'gateway') {
      const gateway = getGatewayByName({ Entities, name: value });

      if (gateway) {
        regionName = gateway.location;
      }
    } else if (!regionName) {
      // give a heads up so we can update as conditions arise
      console.warn(`Unhandled type ${type} detected in getRegionOffset calculation`);
    }

    return regionName ? locations.findIndex((region) => region.name === regionName) + 1 : 0;
  }

  /*
    @borrowed from CloudAwsMap
  */
  getRegionBoxPositions = memoize(
    ({ source }) => {
      const regionRect = this.map.current.querySelector('.box-region')?.getBoundingClientRect();
      const regionLeft = regionRect?.left - source.svg.rect.left;
      const regionRight = regionRect?.right - source.svg.rect.left;
      const regionTop = regionRect?.top - source.svg.rect.top;
      const regionBottom = regionRect?.bottom - source.svg.rect.top;

      const regionCx = (regionRect?.right - regionRect?.left) / 2;
      const regionCy = (regionRect?.regionTop - regionRect?.regionBottom) / 2;

      return { regionLeft, regionRight, regionCx, regionCy, regionTop, regionBottom };
    },
    // region box left and right position wont change
    () => this.map.current.querySelector('.box-region')?.className
  );

  /*
    @borrowed from CloudAwsMap
  */
  getPath(link) {
    const { topology } = this.topologyCollection;
    const { Entities } = topology;

    const nodes = link.flatMap((segment, idx) => {
      const { source, target } = segment;
      return idx === 0 ? [source, target] : target;
    });

    return nodes.map((node) => {
      const { type, id } = node;
      const entity = Entities[type]?.[id] || {};
      return { ...entity, type: String(type).replace(/s$/, '') };
    });
  }

  /*
    @borrowed from CloudAwsMap
  */
  calculateStaticLinks = memoize(
    () => {
      const { topology = {} } = this.topologyCollection;
      const { Links = [], Entities } = topology;
      const staticLinks = uniqBy(
        Links.flatMap((link) => {
          let processedLink = link.map((segment) => {
            const { source, target } = segment;
            const sourceSegment = { type: source.type, value: source.id };
            const targetSegment = { type: target.type, value: target.id };

            if (source.fallbackNodes) {
              sourceSegment.fallbackNodes = source.fallbackNodes;
            }

            if (target.fallbackNodes) {
              targetSegment.fallbackNodes = target.fallbackNodes;
            }

            return {
              source: sourceSegment,
              target: targetSegment
            };
          });

          const firstSegment = processedLink[0];
          const lastSegment = processedLink[processedLink.length - 1];

          if (firstSegment.source.type === VNET && lastSegment.target.type === VNET) {
            const startVNet = Entities.vnets[firstSegment.source.value];
            const endVNet = Entities.vnets[lastSegment.target.value];

            if (startVNet && endVNet) {
              const isPeering = firstSegment.target.type === VNET_PEERING && lastSegment.source.type === VNET_PEERING;

              const startLocation = startVNet.location;
              const endLocation = endVNet.location;

              if (isPeering && startLocation !== endLocation) {
                const startVNetOpen = this.vnetIsOpen(startVNet.id);
                const endVNetOpen = this.vnetIsOpen(endVNet.id);

                const startNode = startVNetOpen
                  ? { ...firstSegment.target, regionName: startLocation }
                  : { type: LOCATION, value: startLocation };
                const endNode = endVNetOpen
                  ? { ...lastSegment.source, regionName: endLocation }
                  : { type: LOCATION, value: endLocation };

                processedLink = [
                  { source: firstSegment.source, target: startNode },
                  { source: startNode, target: endNode },
                  { source: endNode, target: lastSegment.target }
                ];
              }
            }
          }

          const path = this.getPath(link);

          return processedLink.map((segment) => Object.assign(segment, { paths: [path] }));
        }),
        ({ source, target }) =>
          [source, target]
            .sort((a, b) => String(a.value).localeCompare(b.value))
            .map(({ type, value }) => `${type}-${value}`)
            .join('-')
      );

      return rewriteFallbackLinks({ links: staticLinks, mapDocument: this.map.current });
    },
    () => {
      const { selectedVNets } = this.state;
      return selectedVNets.join();
    }
  );

  /*
    @borrowed from CloudAwsMap.getVpcBoxPositions
  */
  getVNetBoxPositions = memoize(
    ({ source }) => {
      const vnetRect = this.map.current.querySelector('.vnet-mini-map')?.getBoundingClientRect();
      const vnetLeft = vnetRect?.left - source.svg.rect.left;
      const vnetRight = vnetRect?.right - source.svg.rect.left;
      const vnetTop = vnetRect?.top - source.svg.rect.top;
      const vnetBottom = vnetRect?.bottom - source.svg.rect.top;

      const vnetCx = (vnetRect?.right - vnetRect?.left) / 2;
      const vnetCy = (vnetRect?.vnetTop - vnetRect?.vnetBottom) / 2;

      return { vnetLeft, vnetRight, vnetCx, vnetCy, vnetTop, vnetBottom };
    },
    // vnet box left and right position wont change
    () => this.map.current.querySelector('.vnet-mini-map')?.className
  );

  /*
    @override
    @borrowed from CloudAwsMap
  */
  getNodeLinkPositions(data) {
    const { topology } = this.topologyCollection;
    const { Hierarchy } = topology;
    const { locations } = Hierarchy;
    const { linkData, source, target, path, pathIndex } = data;
    const { points: sourcePoints } = source;
    const { points: targetPoints } = target;

    if (sourcePoints.length === 0 || targetPoints.length === 0) {
      return { source, target };
    }

    const maxOffset = Math.floor((REGION_MARGIN - 2) / LINK_SPACING);
    const sourceRegionOffset = this.getRegionOffset(linkData.source);
    const targetRegionOffset = this.getRegionOffset(linkData.target);
    const regionOffset = Math.min(Math.abs(sourceRegionOffset - targetRegionOffset), maxOffset);
    const gatewayRegionOffset = Math.min(regionOffset + locations.length, maxOffset);
    const midRegionOffset = locations.length / 2 - regionOffset;
    const { regionCx, regionLeft, regionRight, regionBottom, regionTop } = this.getRegionBoxPositions({ source });
    const { vnetCx, vnetLeft, vnetRight, vnetTop, vnetBottom } = this.getVNetBoxPositions({ source });

    /**
     * currently moved some of:
     *  - links to/from VpnConnections
     *  - links to/from CustomerGateways
     *  - links to/from DirectConnections
     *  - some links from Regions
     *  - links from TransitGateways to On Prem
     *  - links from VirtualGateways to On Prem
     */
    const connector = CloudMapConnector({
      sourceNode: linkData.source,
      targetNode: linkData.target,
      path,
      vpcCx: vnetCx,
      source,
      target,
      vpcTop: vnetTop,
      vpcLeft: vnetLeft,
      regionCx,
      vpcRight: vnetRight,
      pathIndex,
      vpcBottom: vnetBottom,
      regionTop,
      regionLeft,
      regionRight,
      regionOffset,
      regionBottom,
      midRegionOffset,
      targetRegionOffset,
      sourceRegionOffset,
      gatewayRegionOffset,
      connectionType: linkData.connectionType
    });

    if (connector !== null) {
      const { sourceAnchor, targetAnchor, connectionPoints } = connector;
      this.setLinkAnchor(source, sourceAnchor);
      this.setLinkAnchor(target, targetAnchor);

      return super.getNodeLinkPositions({
        ...data,
        source: { ...source, points: sourcePoints.concat(connectionPoints) },
        target
      });
    }

    // eslint-disable-next-line no-console
    console.error('NO CONNECTOR FOUND FOR', linkData);

    return super.getNodeLinkPositions({
      ...data,
      source: { ...source, points: sourcePoints },
      target
    });
  }

  /*
    @borrowed from CloudAwsMap.vpcIsOpen
  */
  vnetIsOpen(vnetId) {
    const { selectedVNets } = this.state;
    return selectedVNets.includes(vnetId);
  }

  /*
    @override
    @borrowed from CloudAwsMap
  */
  get nodeLinks() {
    const { persistantNodeLinks = [] } = this.state;
    const { topology = {} } = this.topologyCollection;
    const { Entities } = topology;

    if (!this.shouldRenderLinksBasedOnState) {
      return [];
    }

    const staticLinks = this.calculateStaticLinks();
    const statePathLinks = super.nodeLinks;

    const nodeLinks = statePathLinks.concat(persistantNodeLinks).flatMap((link) => {
      const { source, target, ...rest } = link;

      if (source.type === 'internet' && target.type === SUBNET) {
        const subnetId = link.target.value;
        const subnet = Object.values(Entities.subnets).find((s) => s.name === subnetId);

        if (subnet) {
          const { location, vnetId } = getCustomProperties(subnet);
          const regionNode = { type: LOCATION, value: location };

          return [
            { source, target: regionNode, ...rest },
            { source: regionNode, target: { type: VNET, value: vnetId }, ...rest }
          ];
        }
        return [];
      }
      return link;
    });

    const allLinks = nodeLinks.concat(staticLinks).map(this.styleLink);
    const linksMap = {};

    allLinks.forEach((link) => {
      const { source, target } = link;

      const key = [source, target]
        .map(({ type, value }) => `${type}-${value}`)
        .sort()
        .join('-');

      const hadOutboundData = Object.prototype.hasOwnProperty.call(link, 'outbound');

      link.inbound = link.inbound || 0;
      link.outbound = link.outbound || 0;
      link.total = link.total || 0;
      link.paths = link.paths || [];

      link.isArrowLink = true;

      // always draw when outbound is not defined
      link.isForwardPath = !hadOutboundData || link.outbound > 0;
      link.isReversePath = link.inbound > 0;

      if (linksMap[key]) {
        const existingLink = linksMap[key];

        if (existingLink.source.value === source.value) {
          existingLink.inbound += link.inbound;
          existingLink.outbound += link.outbound;
        } else {
          existingLink.inbound += link.outbound;
          existingLink.outbound += link.inbound;
        }

        if (link.source.value === existingLink.target.value) {
          existingLink.isReversePath = true;
        }

        existingLink.total += link.total;
        existingLink.paths = existingLink.paths.concat(link.paths);
      } else {
        linksMap[key] = link;
      }
    });

    let resultedLinks = Object.values(linksMap);

    const { hoveredNode, activeNode } = this.state;
    if (activeNode) {
      resultedLinks = generateLinksForHighlightedNode(resultedLinks, activeNode);
    } else if (hoveredNode) {
      resultedLinks = generateLinksForHighlightedNode(resultedLinks, hoveredNode);
    }

    // if rendering path -> only include path link
    if (statePathLinks.length > 0) {
      resultedLinks = resultedLinks.filter((link) =>
        statePathLinks.some(
          (pathLink) => isEqual(pathLink.source, link.source) && isEqual(pathLink.target, link.target)
        )
      );
    }

    return resultedLinks
      .reverse() // reverse links to ensure path is drawn last
      .map(this.styleLink);
  }

  /*
    @override
    @borrowed from CloudAwsMap
  */
  handleNodeLinksUpdate(links) {
    const { selectedNode } = this.state;
    const processedLinks = links.flatMap((l) => l.links);

    if (processedLinks.length === 0 && selectedNode) {
      showInfoToast('No connections were found.');
    }

    const nodeLinks = [...this.getNodeLinks(), ...processedLinks];

    this.setState({
      nodeLinks,
      nodeLinksLoading: false
    });
  }

  /*
    @borrowed from CloudAwsMap
  */
  makeLinkQuery(overrides) {
    const { cloudProvider, $hybridMap } = this.props;

    const baseQuery = {
      aggregateTypes: ['agg_total_bytes'],
      outsort: 'agg_total_bytes',
      viz_type: 'sankey',
      all_devices: false,
      device_types: [`${cloudProvider}_subnet`],
      show_overlay: false,
      show_total_overlay: false,
      lookback_seconds: 3600,
      topx: 40,
      metric: [],
      filters: {
        connector: 'All',
        filterGroups: []
      }
    };

    return $hybridMap.getQuery(merge(baseQuery, overrides));
  }

  /**
   * @override
   * See maps/README.md file for explanation of how nodeLinkQueries need to be structured to interact with
   * TrafficLinkGenerator component.
   */
  getNodeLinkQueries({ selectedNode } = this.state) {
    const queryDefs = [];

    if (selectedNode) {
      const selectedType = MAP_TYPES.get('azure.gateways').includes(selectedNode.type) ? 'gateway' : selectedNode.type;

      if (selectedType === 'internet') {
        const { subType, value } = selectedNode;

        queryDefs.push({
          type: 'internet',
          keyTypes: ['internet', SUBNET, SUBNET, 'internet'],
          allowPairs: [
            ['internet', SUBNET],
            [SUBNET, 'internet']
          ],
          selectedNode,
          queries: [
            this.makeLinkQuery({
              metric: [
                getInternetDimension({ subType, direction: 'src' }),
                AZURE.SRC.SUBNET_NAME,
                AZURE.DST.SUBNET_NAME,
                getInternetDimension({ subType, direction: 'dst' }),
                AZURE.SRC.VNET_ID,
                AZURE.DST.VNET_ID
              ],
              filters: {
                connector: 'All',
                filterGroups: [
                  {
                    connector: 'All',
                    filters: [
                      {
                        filterField: getInternetFilterField({ subType }),
                        operator: '=',
                        filterValue: getASNValues(value).asnList
                      }
                    ]
                  },
                  {
                    connector: 'Any',
                    filters: [
                      { filterField: 'i_trf_profile', operator: '=', filterValue: 'from cloud to outside' },
                      { filterField: 'i_trf_profile', operator: '=', filterValue: 'from outside to cloud' }
                    ]
                  }
                ]
              }
            })
          ]
        });

        queryDefs.push({
          type: 'internetLoadBalancer',
          keyTypes: ['internet', LOAD_BALANCER, LOAD_BALANCER, 'internet'],
          allowPairs: [
            ['internet', LOAD_BALANCER],
            [LOAD_BALANCER, 'internet']
          ],
          selectedNode,
          queries: [
            this.makeLinkQuery({
              metric: [
                getInternetDimension({ subType, direction: 'src' }),
                AZURE.SRC.LOAD_BALANCER,
                AZURE.DST.LOAD_BALANCER,
                getInternetDimension({ subType, direction: 'dst' })
              ],
              filters: {
                connector: 'All',
                filterGroups: [
                  {
                    connector: 'All',
                    filters: [
                      {
                        filterField: getInternetFilterField({ subType }),
                        operator: '=',
                        filterValue: getASNValues(value).asnList
                      }
                    ]
                  },
                  {
                    connector: 'Any',
                    filters: [
                      { filterField: 'i_trf_profile', operator: '=', filterValue: 'from cloud to outside' },
                      { filterField: 'i_trf_profile', operator: '=', filterValue: 'from outside to cloud' }
                    ]
                  }
                ]
              }
            })
          ]
        });
      } else if (['gateway', SUBNET].includes(selectedType)) {
        const filters = [];
        let filterField;
        const filterValue = selectedNode.value;

        if (selectedType === 'gateway') {
          filterField = AZURE.DST.GATEWAY_NAME;
        } else if (selectedType === SUBNET) {
          filterField = AZURE.BI.SUBNET_NAME;
        }

        const filterGroup = {
          connector: 'All',
          filterGroups: [
            {
              connector: 'All',
              filters: [...filters, { filterField, operator: '=', filterValue }]
            }
          ]
        };

        queryDefs.push(
          {
            type: 'vnetInternal',
            keyTypes: [SUBNET, 'gateway', SUBNET],
            selectedNode: { ...selectedNode, value: filterValue },
            queries: [
              this.makeLinkQuery({
                metric: [
                  AZURE.SRC.SUBNET_NAME,
                  AZURE.DST.GATEWAY_NAME,
                  AZURE.DST.SUBNET_NAME,
                  AZURE.SRC.VNET_ID,
                  AZURE.DST.VNET_ID
                ],
                filters: filterGroup
              })
            ]
          },
          {
            type: 'vnetInternetTraffic',
            keyTypes: ['src-internet', 'gateway', 'dst-internet'],
            selectedNode: { type: 'gateway' },
            queries: [
              this.makeLinkQuery({
                metric: ['AS_src', AZURE.DST.GATEWAY_NAME, 'AS_dst'],
                filters: {
                  connector: 'All',
                  filterGroups: [
                    {
                      connector: 'All',
                      filters: [{ filterField, operator: '=', filterValue }]
                    },
                    {
                      connector: 'Any',
                      filters: [
                        {
                          filterField: AZURE.DST.GATEWAY_TYPE,
                          operator: '=',
                          filterValue: 'Internet'
                        },
                        {
                          filterField: AZURE.DST.GATEWAY_TYPE,
                          operator: '=',
                          filterValue: 'NAT Gateway'
                        }
                      ]
                    }
                  ]
                }
              })
            ]
          }
        );
      } else if (selectedType === LOAD_BALANCER) {
        queryDefs.push({
          type: 'loadBalancerInternetTraffic',
          keyTypes: [LOAD_BALANCER, 'dst-internet', 'src-internet', LOAD_BALANCER],
          allowPairs: [
            [LOAD_BALANCER, 'dst-internet'],
            ['src-internet', LOAD_BALANCER]
          ],
          selectedNode: { type: LOAD_BALANCER },
          queries: [
            this.makeLinkQuery({
              metric: [AZURE.SRC.LOAD_BALANCER, 'AS_dst', 'AS_src', AZURE.DST.LOAD_BALANCER],
              filters: {
                connector: 'All',
                filterGroups: [
                  {
                    connector: 'All',
                    filters: [
                      getDimensionFilter({
                        cloudProvider: 'azure',
                        type: 'load_balancer',
                        filterValue: selectedNode.value?.toLowerCase()
                      })
                    ]
                  },
                  {
                    connector: 'Any',
                    filters: [
                      { filterField: 'i_trf_profile', operator: '=', filterValue: 'from cloud to outside' },
                      { filterField: 'i_trf_profile', operator: '=', filterValue: 'from outside to cloud' }
                    ]
                  }
                ]
              }
            })
          ]
        });
      }
    }

    queryDefs.push({
      type: 'igw',
      keyTypes: [VNET, VNET],
      queries: [
        this.makeLinkQuery({
          metric: [AZURE.SRC.VNET_ID, AZURE.DST.VNET_ID],
          filters: {
            connector: 'All',
            filterGroups: [
              {
                connector: 'All',
                filters: [
                  { filterField: AZURE.DST.GATEWAY_TYPE, operator: '=', filterValue: 'Internet' },
                  { filterField: 'i_trf_profile', operator: '=', filterValue: 'cloud internal' }
                ]
              }
            ]
          }
        })
      ]
    });

    return queryDefs;
  }

  /*
    @borrowed from CloudAwsMap

    When forceDetails=true, just show the sidebar details directly
  */
  handleShowDetails = ({ type, value, nodeData, forceDetails = false }) => {
    const { activeInternetTabId } = this.state;
    const { topology } = this.topologyCollection;
    const isSelectable = MAP_TYPES.get('azure.selectable_entities').includes(type);
    let nodeValue = nodeData?.name || value;

    if (type === SUBNET && nodeData?.id && forceDetails) {
      // if it's a subnet, remove the possibility of multiple 'default' subnet names of colliding
      nodeValue = nodeData.id;
    }

    const node = { type, subType: activeInternetTabId, value: nodeValue, nodeData };

    if (isSelectable && !forceDetails) {
      this.handleSelectNode(node);
    } else {
      const { cloudProvider, setSidebarDetails } = this.props;

      if (setSidebarDetails) {
        this.setState({ selectedNode: node }, () => {
          setSidebarDetails({
            ...node,
            cloudProvider,
            // static links are memoized
            links: this.calculateStaticLinks(),
            topology
          });

          // reset node links
          // this prevents scenarios where we request the same node link queries and the traffic generator doesn't see any change,
          // resulting in a death spinner
          this.setNodeLinks(null);

          if (!forceDetails) {
            // when we "Open in Map" we don't want to activate nodes because that can interfere with "Show Connections" link drawing
            this.activateNode({ type: node.type, value: nodeData.id });
          }
        });
      }
    }
  };

  /** will draw lines and expand entities along path provided */
  handleShowPath(path) {
    const { $hybridMap, onSettingsUpdate } = this.props;

    // save new subscription selection if necessary
    const vnetsToExpand = [];
    const nodeLinks = path.flatMap((currentNode, index) => {
      if (index === 0) {
        return [];
      }

      const prevNode = path[index - 1];

      if (currentNode.vHubId) {
        vnetsToExpand.push(currentNode.vHubId);
      }

      if (currentNode.vnetId) {
        vnetsToExpand.push(currentNode.vnetId);
      }

      // do not expand last node
      if (index !== path.length - 1 && [VIRTUAL_HUB, VNET].includes(currentNode.type)) {
        vnetsToExpand.push(currentNode.id);
      }

      return [
        {
          source: {
            type: prevNode.type,
            value: prevNode.id
          },
          target: {
            type: currentNode.type,
            value: currentNode.id
          }
        }
      ];
    });

    // reset all filter to ensure full path is displayed
    this.topologyCollection.clearFilters();

    this.setState(({ selectedVNets }) => ({ nodeLinks, selectedVNets: [...selectedVNets, ...vnetsToExpand] }));

    const requiredSubscriptions = uniq(
      path.map((pathNode) => pathNode.subscriptionId ?? null).filter((subscription) => subscription)
    );

    const allSubscriptionsLoaded = requiredSubscriptions.every((subscriptionId) =>
      this.topologyCollection.selectedSubscriptions.includes(subscriptionId)
    );

    if (!allSubscriptionsLoaded) {
      $hybridMap.settingsModel.set({
        azureSubscriptionIds: requiredSubscriptions
      });

      onSettingsUpdate($hybridMap.settingsModel.sidebarSettingsWithTimeRange);
    }
  }

  /*
    @override
  */
  handleSelectNode({ type, value, nodeData = {}, force = false }) {
    const { openPopover, cloudProvider } = this.props;
    const { selectedNode } = this.state;
    const isCloudNode = MAP_TYPES.get('azure.cloud').includes(type);
    const { topology } = this.topologyCollection;

    super.handleSelectNode({ type, value, force, nodeData });

    this.setState((prevState) => {
      if (prevState.selectedNode) {
        const { node } = this.getNodePosition(prevState.selectedNode);

        if (node) {
          const isSameNode = isEqual(selectedNode, prevState.selectedNode);
          const { x, y, width, height } = node;
          const { id } = nodeData;
          const url = getConsoleUrl({ id, type });

          const customItems = [];

          if (type === SUBNET) {
            customItems.push(
              <MenuItem
                key="showPathTo"
                text={<>Show Path To&hellip;</>}
                icon="route"
                popoverProps={{ hoverCloseDelay: 600 }}
              >
                <li>
                  <AzureSubnetDestinations
                    subnet={prevState.selectedNode}
                    onSelect={(path) => this.handleShowPath(path)}
                  />
                </li>
              </MenuItem>
            );
          }

          if (url) {
            customItems.push({
              text: 'Show in Azure Console',
              icon: 'panel-stats',
              action: () => {
                window.open(url, '_blank');
              }
            });
          }

          openPopover({
            ...prevState.selectedNode,
            topology,
            cloudProvider,
            position: { left: x, top: y, width, height },
            placement: isCloudNode ? 'bottom' : 'left',
            detail: {
              type: 'cloud',
              cloudProvider
            },
            shortcutMenu: {
              customItems,
              selectedNode: prevState.selectedNode,
              showConnectionsCallback: this.setNodeLinks,
              isShowingConnections: prevState.nodeLinks.length > 0 && isSameNode
            }
          });
        }
      }

      return null;
    });
  }

  /*
    @borrowed from CloudAwsMap
  */
  isRegionHighlighted(regionName) {
    const { nodeLinks, selectedNode } = this.state;
    const { topology } = this.topologyCollection;
    const { Entities } = topology;

    return nodeLinks.some(({ source, target }) =>
      [source, target].some(({ type, value, subnetKey }) => {
        if (type === SUBNET) {
          return Object.values(Entities.subnets).find((s) => {
            const customProperties = getCustomProperties(s);
            return (
              customProperties.key === subnetKey &&
              customProperties.location === regionName &&
              value !== selectedNode?.value
            );
          });
        }

        return false;
      })
    );
  }

  /*
    @override
  */
  getHighlighted(type) {
    const { nodeLinks, selectedNode } = this.state;

    if (type === LOCATION) {
      if (selectedNode) {
        const highlighted = {};

        nodeLinks.forEach(({ source, target }) => {
          highlighted[source.value] = true;
          highlighted[target.value] = true;
        });

        return Object.keys(highlighted).filter((value) => value && value !== selectedNode?.value);
      }

      return [];
    }

    return super.getHighlighted(type);
  }

  /*
    @borrowed from CloudAwsMap.getHighlightedVpcs
  */
  getHighlightedVNets(regionName) {
    const { nodeLinks, selectedNode } = this.state;
    const { topology } = this.topologyCollection;
    const { Entities } = topology;

    return nodeLinks
      .flatMap(({ source, target }) =>
        [source, target].flatMap(({ type, subnetKey }) => {
          if (type === SUBNET) {
            return Object.values(Entities.subnets).map((s) => {
              const customProperties = getCustomProperties(s);

              if (customProperties.key === subnetKey && customProperties.location === regionName) {
                return customProperties.vnetId;
              }

              return null;
            });
          }

          return null;
        })
      )
      .filter((value) => value && value !== selectedNode?.value);
  }

  /*
    @borrowed from CloudAwsMap.handleToggleVpc
  */
  handleToggleVNet = (vnetId) =>
    this.setState((state) => ({
      selectedVNets: state.selectedVNets.includes(vnetId) ? state.selectedVNets.filter((id) => id !== vnetId) : [vnetId]
    }));

  /*
    @borrowed from CloudAwsMap
  */
  handleGridItemHoverToggle = ({ type, key, item }) => {
    if (type === 'enter') {
      this.handleHoverNode(key, item.id);
    } else {
      this.handleUnhoverNode(key, item.id);
    }
  };

  highlightVirtualHubs = (virtualWanEntity) => {
    this.topologyCollection.highLightedNodes =
      this.topologyCollection.getEntityProperty(virtualWanEntity, VIRTUAL_HUB)?.map((virtualHub) => ({
        type: VIRTUAL_HUB,
        value: virtualHub.id
      })) ?? [];
  };

  /*
    @borrowed from CloudAwsMap
  */
  handleGridItemClick = ({ key, item }) => {
    if (key === VIRTUAL_WAN) {
      this.highlightVirtualHubs(item);
    }

    this.activateNode({ type: key, value: item.id });
    this.handleShowDetails({ type: getEntityType(item), value: item.name, nodeData: item });
  };

  async fetchTopology() {
    const { selectedNode } = this.state;
    const { $hybridMap, sidebarSettings } = this.props;
    this.setState({ loading: true });

    if (this.pendingPromise) {
      this.pendingPromise.cancel();
    }

    this.pendingPromise = makeCancelable(
      $hybridMap.azureCloudMapCollection.fetch({ force: true, query: sidebarSettings.timeRange })
    );

    return this.pendingPromise.promise
      .then(() => {
        this.setInitialState({
          loading: false
        });
      })
      .catch((err) => {
        this.setState({ loading: false }, () => {
          if (!(err instanceof CanceledError)) {
            console.error('Error loading topology', err);
            showErrorToast('Error occurred attempting to load topology');
          } else {
            console.warn('Promise was canceled.');
          }
        });
      })
      .finally(() => {
        this.pendingPromise = null;
        this.setState({ loading: false }, () => {
          // once topology loaded, scroll to selected node if found
          if (selectedNode) {
            const selectedNodePosition = this.getNodePosition({ type: selectedNode.type, value: selectedNode.value });
            if (selectedNodePosition?.node?.element) {
              setTimeout(() => {
                selectedNodePosition.node.element.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }, 1000);
            }
          }
        });
      });
  }

  get topologyCollection() {
    const { $hybridMap } = this.props;
    return $hybridMap.azureCloudMapCollection;
  }

  get highlightedNodes() {
    return this.nodeLinks.flatMap((l) => [l.source.value, l.target.value]);
  }

  get onPremRoutersToRender() {
    const { cloudProvider } = this.props;
    const { topology } = this.topologyCollection;
    const { width: rectWidth } = this.svg.current.getBoundingClientRect();
    const items = [
      {
        key: 'on-prem-entities',
        getItemType: (item) => getEntityType(item),
        getItemIcon: (item) => (
          <CloudIcon cloudProvider={cloudProvider} entity={getEntityType(item)} width={ICON_SIZE} height={ICON_SIZE} />
        ),
        group: [...topology[ROUTER], ...topology[LOCAL_NETWORK_GATEWAY]],
        getTitle: (item) => {
          const entityType = getEntityType(item);

          if (entityType === LOCAL_NETWORK_GATEWAY) {
            return 'Local Network Gateway';
          }

          return 'Router';
        },
        getSubtitle: (entity) => entity?.name ?? entity?.device_name ?? entity?.id ?? 'Unknown Device'
      }
    ];
    const willFit = items.every(
      ({ group }) =>
        getTotalWidth({ itemCount: group.length, gap: ON_PREM_FLEX_GAP }) < rectWidth - INTERNET_WIDTH.toPoints()
    );

    return items.map((item) => ({ ...item, willFit })).filter((tempGroup) => tempGroup.group.length > 0);
  }

  get vpnSitesToRender() {
    const { cloudProvider } = this.props;
    const { topology } = this.topologyCollection;
    const { width: rectWidth } = this.svg.current.getBoundingClientRect();
    const items = [
      {
        key: VPN_SITE,
        name: 'VPN Site',
        group: topology[VPN_SITE],
        getTitle: (vpnSite) => vpnSite.name,
        getSubtitle: (vpnSite) => vpnSite.location,
        icon: <CloudIcon cloudProvider={cloudProvider} entity={VPN_SITE} width={ICON_SIZE} height={ICON_SIZE} />
      }
    ];
    const willFit = items.every(
      ({ group }) =>
        getTotalWidth({ itemCount: group.length, gap: ON_PREM_FLEX_GAP }) < rectWidth - INTERNET_WIDTH.toPoints()
    );

    return items.map((item) => ({ ...item, willFit })).filter((tempGroup) => tempGroup.group.length > 0);
  }

  get onPremTopologyToRender() {
    const { cloudProvider } = this.props;
    const { topology } = this.topologyCollection;
    const { width: rectWidth } = this.svg.current.getBoundingClientRect();

    const items = [
      {
        key: EXPRESS_ROUTE_CIRCUIT,
        group: topology[EXPRESS_ROUTE_CIRCUIT],
        name: 'Express Route Circuit',
        getSubtitle: (expressRouteCircuit) => expressRouteCircuit.name,
        icon: (
          <CloudIcon
            cloudProvider={cloudProvider}
            entity={EXPRESS_ROUTE_CIRCUIT}
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        )
      },
      {
        key: 'connections',
        name: 'Connections',
        getItemType: (item) => getEntityType(item),
        getItemIcon: (item) => (
          <CloudIcon cloudProvider={cloudProvider} entity={getEntityType(item)} width={ICON_SIZE} height={ICON_SIZE} />
        ),
        group: [
          ...topology[EXPRESS_ROUTE_CONNECTION],
          ...topology[VNET_GATEWAY_CONNECTION],
          ...topology[VPN_LINK_CONNECTION]
        ],
        getTitle: (item) => {
          const entityType = getEntityType(item);

          if (entityType === EXPRESS_ROUTE_CONNECTION) {
            return 'Express Route Connection';
          }

          if (entityType === VNET_GATEWAY_CONNECTION) {
            return 'VNet Gateway Connection';
          }

          if (entityType === VPN_LINK_CONNECTION) {
            return 'VPN Link Connection';
          }

          return entityType;
        },
        getSubtitle: (connection) => connection.name
      },
      {
        key: VIRTUAL_WAN,
        group: topology[VIRTUAL_WAN],
        name: 'Virtual WAN',
        getSubtitle: (virtualWan) => virtualWan.name,
        icon: <CloudIcon cloudProvider={cloudProvider} entity={VIRTUAL_WAN} width={ICON_SIZE} height={ICON_SIZE} />
      }
    ];
    const willFit = items.every(({ group }) => getTotalWidth({ itemCount: group.length, width: 200 }) < rectWidth);

    return items.map((item) => ({ ...item, willFit })).filter((tempGroup) => tempGroup.group.length > 0);
  }

  renderMap() {
    const { cloudProvider, width, $hybridMap } = this.props;
    const { boxExpanded, loading, selectedVNets, nodeLinkQueries, nodeLinksLoading, selectedNode } = this.state;
    const { topology } = this.topologyCollection;

    if (loading) {
      return <Spinner mt={100} />;
    }

    if ($hybridMap.selectedAzureSubscriptions.length === 0) {
      return <EmptyState mt={4} icon="cloud" title="No Subscriptions Selected" />;
    }

    if (this.topologyCollection.isEmpty()) {
      return <EmptyState mt={4} icon="cloud" title="No Topology Found" />;
    }

    return (
      <Box>
        {nodeLinksLoading && (
          <Flex alignItems="center" justifyContent="center" position="fixed" zIndex="999" top="300px" left="50%">
            <Spinner intent="primary" />
          </Flex>
        )}
        {/* ON PREM DEVICES */}
        <Flex justifyContent="space-between" gap={`${GRID_ROWS_GAP}px`}>
          <Box mr={1}>
            <CloudMapBox
              title="On Prem"
              className={classNames('box-onprem', {
                highlighted: this.isBoxHighlighted('onprem')
              })}
              minWidth="20vw"
              minHeight={150}
              flex="1 1 auto"
              flexWrap="wrap"
              onExpandToggle={(isExpanded) => this.setBoxExpanded('onprem', isExpanded)}
              isExpanded={boxExpanded.onprem}
            >
              <ItemGrid
                items={this.onPremRoutersToRender}
                itemStroke="primary"
                highlightedNodes={this.highlightedNodes}
                onHoverToggle={this.handleGridItemHoverToggle}
                onClick={this.handleGridItemClick}
                emptyState={<EmptyState icon="folder-close" description="No On Prem Resources" />}
                showTitle={false}
                selectedNode={selectedNode}
              />
            </CloudMapBox>
          </Box>

          {/* VPN SITES */}
          <Box mr={1}>
            <CloudMapBox
              title="VPN Sites"
              className={classNames('box-onprem', {
                highlighted: this.isBoxHighlighted('onprem')
              })}
              minWidth="20vw"
              minHeight={150}
              flex="1 1 auto"
              flexWrap="wrap"
              onExpandToggle={(isExpanded) => this.setBoxExpanded('vpnSites', isExpanded)}
              isExpanded={boxExpanded.vpnSites}
            >
              <ItemGrid
                items={this.vpnSitesToRender}
                itemStroke="primary"
                highlightedNodes={this.highlightedNodes}
                onHoverToggle={this.handleGridItemHoverToggle}
                onClick={this.handleGridItemClick}
                emptyState={<EmptyState icon="folder-close" description="No VPN Sites" />}
                showTitle={false}
                selectedNode={selectedNode}
              />
            </CloudMapBox>
          </Box>

          {/* INTERNET BOX */}
          <Box ml={1}>
            <CloudMapBox
              title="Internet"
              className={classNames('box-internet', 'link-bottomcenter', {
                highlighted: this.isBoxHighlighted('internet')
              })}
              width={INTERNET_WIDTH}
              height={233}
              onExpandToggle={(isExpanded) => this.setBoxExpanded('internet', isExpanded)}
              isExpanded={boxExpanded.internet}
            >
              <TopKeys
                classPrefix="internet"
                selected={this.getSelected('internet')}
                hovered={this.getHovered('internet')}
                highlighted={this.getHighlighted('internet')}
                onSelect={(value) => this.handleShowDetails({ type: 'internet', value })}
                onTabChange={this.handleInternetTabChange}
                cloud={cloudProvider}
              />
            </CloudMapBox>
          </Box>
        </Flex>

        {/* EXPRESS ROUTE CUICUITS / CONNECTIONS / VIRTUAL WANS SECTION */}
        <ItemGrid
          items={this.onPremTopologyToRender}
          itemWidth={200}
          itemStroke="primary"
          highlightedNodes={this.highlightedNodes}
          onHoverToggle={this.handleGridItemHoverToggle}
          onClick={this.handleGridItemClick}
          selectedNode={selectedNode}
        />

        {/* LOCATIONS BOXES */}
        <Flex flexDirection="column" gap={`${GRID_ROWS_GAP}px`}>
          {topology.Hierarchy?.locations?.map((location) => (
            <Box key={location.id} className={`box-region ${getMapClassname({ type: LOCATION, value: location.id })}`}>
              <CloudMapBox
                p={0}
                minWidth={200}
                minHeight={100}
                className={classNames({
                  highlighted: this.isRegionHighlighted(location.name)
                })}
                isExpanded
              >
                <Location
                  location={location}
                  width={width - LOCATION_PADDING}
                  selectedVNets={selectedVNets.filter(
                    (vnetId) =>
                      topology.Entities[VNET][vnetId]?.location === location.name ||
                      topology.Entities[VIRTUAL_HUB][vnetId]?.location === location.name
                  )}
                  highlighted={this.getHighlighted(LOCATION)}
                  highlightedVNets={this.getHighlightedVNets(location.name)}
                  onToggleVNet={this.handleToggleVNet}
                  onShowDetails={this.handleShowDetails}
                />
              </CloudMapBox>
            </Box>
          ))}
        </Flex>

        <TrafficLinkGenerator
          cloudProvider={cloudProvider}
          inputs={nodeLinkQueries}
          onLinksUpdate={this.handleNodeLinksUpdate}
        />
      </Box>
    );
  }
}
