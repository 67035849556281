import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { AiOutlineMenuFold } from 'react-icons/ai';
import { SizeMe } from 'react-sizeme';
import { Box, Button, LinkButton } from 'core/components';
import Page from 'app/components/page/Page';
import withHybridTopoSettings from 'app/views/hybrid/maps/components/settingsToolbar/withHybridTopoSettings';
import MultiCloudOverviewMap from './maps/MultiCloudOverviewMap';
import HybridTopoSidebar from './maps/components/HybridTopoSidebar';
import HybridTopoSettingsToolbar from './maps/components/settingsToolbar/HybridTopoSettingsToolbar';
import withSidebarDetails from './maps/components/popovers/withSidebarDetails';

@withHybridTopoSettings
@withSidebarDetails
@observer
class MultiCloudHybridOverview extends Component {
  static getDerivedStateFromProps(props) {
    if (!props.sidebarDetailsPanel) {
      return { drawerIsOpen: false };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      drawerIsOpen: true
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarDetailsPanel } = this.props;

    // if we're setting new details and it's not open, open it
    if (!prevState.drawerIsOpen && sidebarDetailsPanel) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ drawerIsOpen: true });
    }
  }

  handleDrawerToggle = () => this.setState(({ drawerIsOpen }) => ({ drawerIsOpen: !drawerIsOpen }));

  render() {
    const { settingsModel, sidebarSettings, saveSettings, setSidebarDetails, sidebarDetailsPanel } = this.props;
    const { drawerIsOpen } = this.state;

    return (
      <Page
        docTitle="Kentik Map"
        subnavTools={
          <>
            <LinkButton text="View Logical Map" ml="2px" to="/v4/kentik-map/logical" minimal />
            <Button
              text="Details"
              icon={AiOutlineMenuFold}
              ml="2px"
              active={drawerIsOpen}
              onClick={this.handleDrawerToggle}
              disabled={!sidebarDetailsPanel}
              minimal
            />
          </>
        }
        drawerContents={<HybridTopoSidebar sidebarDetailsPanel={sidebarDetailsPanel} />}
        toolbar={<HybridTopoSettingsToolbar onSettingsUpdate={saveSettings} model={settingsModel} />}
        drawerIsOpen={drawerIsOpen}
        drawerOnClose={(e) => {
          if (e && e.key === 'Escape' && drawerIsOpen) {
            this.handleDrawerToggle();
          }
        }}
        drawerProps={{ extraOffset: 43 }}
        scrolls
        canFullScreen
        minHeight="auto"
        pb={0}
      >
        <SizeMe monitorWidth noPlaceholder>
          {({ size }) => (
            <Box flex={1} mb={1}>
              <MultiCloudOverviewMap
                width={size.width}
                sidebarSettings={sidebarSettings}
                setSidebarDetails={setSidebarDetails}
              />
            </Box>
          )}
        </SizeMe>
      </Page>
    );
  }
}

export default MultiCloudHybridOverview;
