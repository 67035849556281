import React from 'react';
import { inject } from 'mobx-react';
import styled from 'styled-components';
import { Heading, Text } from 'core/components';

const BlurbContainer = styled.div`
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px 6px 6px 6px;
  padding: 16px;
  color: #ffffff;

  &::after {
    content: '';
    position: absolute;
    border: 6px solid transparent;
    border-top-color: rgba(255, 255, 255, 0.1);
    border-right-color: rgba(255, 255, 255, 0.1);
    right: 100%;
    top: 12px;
    transform: skewY(-30deg);
    transform-origin: 100% 100%;
  }
`;

const Blurb = (props) => {
  const { $auth } = props;

  if (!$auth.isActiveTrial) {
    return null;
  }

  return (
    <BlurbContainer>
      <Heading level={4} color="#ffffff" mb="4px" fontWeight="heavy">
        Hey {$auth.getActiveUserProperty('user_full_name')}!
      </Heading>
      <Text color="white.7">
        Thank you for giving Kentik a spin over at {$auth.getActiveUserProperty('company.company_name_full')}.
        Let&apos;s work together on getting the most out of your trial experience!
      </Text>
    </BlurbContainer>
  );
};

export default inject('$auth')(Blurb);
