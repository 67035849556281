import React from 'react';
import { difference, memoize, merge } from 'lodash';
import classNames from 'classnames';
import { getASNValues } from 'app/util/queryResults';
import { Box, EmptyState, Flex, showInfoToast, Spinner } from 'core/components';
import { showErrorToast } from 'core/components/toast';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import { OCI } from 'app/views/hybrid/maps/cloudDimensionConstants';
import TrafficLinkGenerator from 'app/views/hybrid/maps/components/TrafficLinkGenerator';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import CloudMapConnector from 'app/views/hybrid/utils/cloud/connector';

import ItemGrid from 'app/views/hybrid/maps/components/ItemGrid';
import {
  getTotalWidth,
  GRID_ROWS_GAP,
  ICON_SIZE,
  INTERNET_WIDTH,
  LOCATION_PADDING,
  ON_PREM_FLEX_GAP
} from 'app/views/hybrid/utils/cloud/constants';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { generateLinksForHighlightedNode, getMapClassname, rewriteFallbackLinks } from 'app/views/hybrid/utils/map';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import {
  getCloudSubnetFilterField,
  getInternetDimension,
  getInternetFilterField
} from 'app/views/hybrid/maps/components/popovers/queryOptionsHelper';

import { OciRegion } from './components';
import AbstractMap from '../components/AbstractMap';
import CloudMapBox from '../components/CloudMapBox';
import TopKeys from '../components/TopKeys/TopKeys';
import withPopover from '../components/popovers/withPopover';

const {
  ROUTER,
  REGION,
  SUBNET,
  NAT_GATEWAY,
  SERVICE_GATEWAY,
  INTERNET_GATEWAY,
  IP_SEC_CONNECTION,
  VIRTUAL_CLOUD_NETWORK,
  LOCAL_PEERING_GATEWAY,
  DYNAMIC_ROUTING_GATEWAY,
  DYNAMIC_ROUTING_GATEWAY_ATTACHMENT
} = ENTITY_TYPES.get('oci');

@withPopover
@withTheme
@inject('$hybridMap')
@observer
export default class OciMap extends AbstractMap {
  constructor(props) {
    super(props);

    Object.assign(this.state, {
      expandedRegionItems: [],
      activeNode: null,
      boxExpanded: {
        internet: true,
        onprem: true
      },
      loading: true
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarSettings } = this.props;
    const mapSearchChanged = prevProps.sidebarSettings.searchTerm !== sidebarSettings.searchTerm;

    if (mapSearchChanged) {
      this.topologyCollection.filter(sidebarSettings.searchTerm);
    }

    super.componentDidUpdate(prevProps, prevState);
  }

  componentDidMount() {
    this.fetchTopology();
  }

  shouldLinksRedraw(prevProps, prevState) {
    const { nodeLinksLoading, expandedRegionItems, activeNode, hoveredNode, isLoading } = this.state;

    if (isLoading) {
      return false;
    }

    if (super.shouldLinksRedraw(prevProps, prevState)) {
      return true;
    }

    if (expandedRegionItems.length !== prevState.expandedRegionItems.length) {
      return true;
    }

    if (prevState.activeNode?.value !== activeNode?.value) {
      return true;
    }

    if (prevState.hoveredNode?.value !== hoveredNode?.value) {
      return true;
    }

    if (prevState.nodeLinksLoading !== nodeLinksLoading) {
      return true;
    }

    const expandedRegionItemValues = expandedRegionItems.map((item) => item.value);
    const prevExpandedRegionItemValues = prevState.expandedRegionItems.map((item) => item.value);

    return difference(expandedRegionItemValues, prevExpandedRegionItemValues).length > 0;
  }

  shouldTargetLinkRollUp(type) {
    return ['cloud', 'internet'].includes(type);
  }

  async fetchTopology() {
    const { $hybridMap, sidebarSettings } = this.props;
    this.setState({ loading: true });

    if (this.pendingPromise) {
      this.pendingPromise.cancel();
    }

    this.pendingPromise = makeCancelable(
      $hybridMap.ociCloudMapCollection.fetch({ force: true, query: sidebarSettings.timeRange })
    );

    return this.pendingPromise.promise
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false }, () => {
          if (!(err instanceof CanceledError)) {
            console.error('Error loading topology', err);
            showErrorToast('Error occurred attempting to load topology');
          } else {
            console.warn('Promise was canceled.');
          }
        });
      })
      .finally(() => {
        this.pendingPromise = null;
        this.setState({ loading: false });
      });
  }

  /*
    @borrowed from CloudAwsMap
  */
  makeLinkQuery(overrides) {
    const { cloudProvider, $hybridMap } = this.props;

    const baseQuery = {
      aggregateTypes: ['agg_total_bytes'],
      outsort: 'agg_total_bytes',
      viz_type: 'sankey',
      all_devices: false,
      device_types: [`${cloudProvider}_subnet`],
      show_overlay: false,
      show_total_overlay: false,
      lookback_seconds: 3600,
      topx: 40,
      metric: [],
      filters: {
        connector: 'All',
        filterGroups: []
      }
    };

    return $hybridMap.getQuery(merge(baseQuery, overrides));
  }

  /*
    @override
    @borrowed from CloudAwsMap
  */
  handleNodeLinksUpdate(links) {
    const processedLinks = links.flatMap((l) => l.links);

    if (processedLinks.length === 0) {
      showInfoToast('No connections were found.');
    }

    const convertedLinks = processedLinks.map((link) => this.convertQueryResultLink(link)).flat();

    const nodeLinks = [...this.getNodeLinks(), ...convertedLinks];

    this.setState({
      nodeLinks,
      nodeLinksLoading: false
    });
  }

  /**
   * @override
   * See maps/README.md file for explanation of how nodeLinkQueries need to be structured to interact with
   * TrafficLinkGenerator component.
   */
  getNodeLinkQueries({ selectedNode, activeInternetTabId } = this.state) {
    const { cloudProvider } = this.props;
    const queryDefs = [];

    const { type, value } = selectedNode;

    // query to/from internet
    if (type === 'internet' || type === SUBNET) {
      let additionalFilter;
      const subType = activeInternetTabId;

      if (type === 'internet') {
        additionalFilter = {
          connector: 'All',
          filters: [
            {
              filterField: getInternetFilterField({ subType }),
              operator: '=',
              filterValue: getASNValues(value).asnList
            }
          ]
        };
      }

      if (type === SUBNET) {
        const { vcnId } = selectedNode;

        const vcnEntity = this.topologyCollection.findEntityById(vcnId);

        additionalFilter = {
          connector: 'All',
          filters: [
            {
              filterField: getCloudSubnetFilterField({ cloudProvider }),
              operator: '=',
              filterValue: selectedNode.displayName
            },
            {
              filterField: OCI.BI.VCN,
              operator: '=',
              filterValue: vcnEntity.displayName
            }
          ]
        };
      }

      queryDefs.push({
        type: 'subnetInternet',
        keyTypes: ['internet', SUBNET, SUBNET, 'internet', INTERNET_GATEWAY, NAT_GATEWAY, SERVICE_GATEWAY],
        allowPairs: [
          [SUBNET, 'internet'],
          ['internet', SUBNET]
        ],
        extraInfo: [INTERNET_GATEWAY, NAT_GATEWAY, SERVICE_GATEWAY],
        selectedNode,
        queries: [
          this.makeLinkQuery({
            metric: [
              getInternetDimension({ subType, direction: 'src' }),
              getCloudSubnetFilterField({ cloudProvider, direction: 'src' }),
              getCloudSubnetFilterField({ cloudProvider, direction: 'dst' }),
              getInternetDimension({ subType, direction: 'dst' }),
              OCI.DST[INTERNET_GATEWAY],
              OCI.DST[NAT_GATEWAY],
              OCI.DST[SERVICE_GATEWAY]
            ],
            filters: {
              connector: 'All',
              filterGroups: [
                additionalFilter,
                {
                  connector: 'Any',
                  filters: [
                    { filterField: 'i_trf_profile', operator: '=', filterValue: 'from cloud to outside' },
                    { filterField: 'i_trf_profile', operator: '=', filterValue: 'from outside to cloud' }
                  ]
                }
              ]
            }
          })
        ]
      });
    }

    if (type === SUBNET) {
      const { vcnId } = selectedNode;

      const vcnEntity = this.topologyCollection.findEntityById(vcnId);
      const subnetFilter = [
        {
          filterField: getCloudSubnetFilterField({ cloudProvider }),
          operator: '=',
          filterValue: selectedNode.displayName
        },
        {
          filterField: OCI.BI.VCN,
          operator: '=',
          filterValue: vcnEntity.displayName
        }
      ];

      queryDefs.push({
        type: 'vcnPeering',
        keyTypes: [`src-${SUBNET}`, `src-${LOCAL_PEERING_GATEWAY}`, `dst-${LOCAL_PEERING_GATEWAY}`, `dst-${SUBNET}`],
        allowPairs: [
          [`src-${SUBNET}`, `src-${LOCAL_PEERING_GATEWAY}`],
          [`dst-${LOCAL_PEERING_GATEWAY}`, `dst-${SUBNET}`]
        ],
        selectedNode: { type: selectedNode.type },
        queries: [
          this.makeLinkQuery({
            metric: [OCI.SRC.SUBNET, OCI.SRC[LOCAL_PEERING_GATEWAY], OCI.DST[LOCAL_PEERING_GATEWAY], OCI.DST.SUBNET],
            filters: {
              connector: 'All',
              filterGroups: [
                {
                  connector: 'All',
                  filters: subnetFilter
                }
              ]
            }
          })
        ]
      });

      // subnet to subnet within same vnet
      queryDefs.push({
        type: 'vcnInternal',
        keyTypes: [`src-${SUBNET}`, `dst-${SUBNET}`],
        allowPairs: [
          [`src-${SUBNET}`, `dst-${SUBNET}`],
          [`dst-${SUBNET}`, `src-${SUBNET}`]
        ],
        selectedNode: { type: selectedNode.type },
        queries: [
          this.makeLinkQuery({
            metric: [OCI.SRC.SUBNET, OCI.DST.SUBNET],
            filters: {
              connector: 'All',
              filterGroups: [
                {
                  connector: 'All',
                  filters: [
                    ...subnetFilter,
                    {
                      filterField: OCI.SRC.VCN,
                      operator: '=',
                      filterValue: vcnEntity.displayName
                    },
                    {
                      filterField: OCI.DST.VCN,
                      operator: '=',
                      filterValue: vcnEntity.displayName
                    }
                  ]
                }
              ]
            }
          })
        ]
      });
    }

    // show connections when clicking vcn gateways
    const gatewayConnectorsTypes = [INTERNET_GATEWAY, NAT_GATEWAY, SERVICE_GATEWAY];
    const gatewayConnectorType = gatewayConnectorsTypes.find((connectorType) => connectorType === type);
    if (gatewayConnectorType && OCI.DST[gatewayConnectorType]) {
      const filterField = OCI.DST[gatewayConnectorType];
      const filterValue = selectedNode.displayName;

      queryDefs.push({
        type: 'vcnGatewayInternetTraffic',
        keyTypes: ['src-internet', `src-${SUBNET}`, selectedNode.type, 'dst-internet', `dst-${SUBNET}`],
        allowPairs: [
          ['src-internet', selectedNode.type],
          [selectedNode.type, 'dst-internet'],
          [`src-${SUBNET}`, selectedNode.type],
          [selectedNode.type, `dst-${SUBNET}`]
        ],
        selectedNode: { type: selectedNode.type },
        queries: [
          this.makeLinkQuery({
            metric: ['AS_src', OCI.SRC.SUBNET, filterField, 'AS_dst', OCI.DST.SUBNET],
            filters: {
              connector: 'All',
              filterGroups: [
                {
                  connector: 'All',
                  filters: [{ filterField, operator: '=', filterValue }]
                }
              ]
            }
          })
        ]
      });
    }

    if (type === LOCAL_PEERING_GATEWAY) {
      queryDefs.push({
        type: 'localPeeringGateway',
        keyTypes: [`src-${SUBNET}`, `src-${LOCAL_PEERING_GATEWAY}`, `dst-${LOCAL_PEERING_GATEWAY}`, `dst-${SUBNET}`],
        allowPairs: [
          [`src-${SUBNET}`, `src-${LOCAL_PEERING_GATEWAY}`],
          [`src-${LOCAL_PEERING_GATEWAY}`, `dst-${LOCAL_PEERING_GATEWAY}`],
          [`dst-${LOCAL_PEERING_GATEWAY}`, `dst-${SUBNET}`]
        ],
        selectedNode: { type: selectedNode.type },
        queries: [
          this.makeLinkQuery({
            metric: [OCI.SRC.SUBNET, OCI.SRC[LOCAL_PEERING_GATEWAY], OCI.DST[LOCAL_PEERING_GATEWAY], OCI.DST.SUBNET],
            filters: {
              connector: 'All',
              filterGroups: [
                {
                  connector: 'All',
                  filters: [
                    {
                      filterField: OCI.BI[LOCAL_PEERING_GATEWAY],
                      operator: '=',
                      filterValue: selectedNode.displayName
                    }
                  ]
                }
              ]
            }
          })
        ]
      });
    }

    if (type === DYNAMIC_ROUTING_GATEWAY || type === DYNAMIC_ROUTING_GATEWAY_ATTACHMENT) {
      let filterValue = selectedNode.displayName;

      if (type === DYNAMIC_ROUTING_GATEWAY_ATTACHMENT) {
        const { drgId } = selectedNode;

        const drg = this.topologyCollection.findEntityById(drgId);
        filterValue = drg.displayName;
      }

      queryDefs.push({
        type: 'drgTraffic',
        keyTypes: [
          `src-${SUBNET}`,
          `src-${DYNAMIC_ROUTING_GATEWAY}`,
          `dst-${DYNAMIC_ROUTING_GATEWAY}`,
          `dst-${SUBNET}`,
          `dst-${IP_SEC_CONNECTION}`
        ],
        allowPairs: [
          [`src-${SUBNET}`, `src-${DYNAMIC_ROUTING_GATEWAY}`],
          [`src-${DYNAMIC_ROUTING_GATEWAY}`, `dst-${DYNAMIC_ROUTING_GATEWAY}`],
          [`dst-${DYNAMIC_ROUTING_GATEWAY}`, `dst-${SUBNET}`],
          [`dst-${DYNAMIC_ROUTING_GATEWAY}`, `dst-${IP_SEC_CONNECTION}`]
        ],
        selectedNode: { type: DYNAMIC_ROUTING_GATEWAY },
        queries: [
          this.makeLinkQuery({
            metric: [
              OCI.SRC.SUBNET,
              OCI.SRC[DYNAMIC_ROUTING_GATEWAY],
              OCI.DST[DYNAMIC_ROUTING_GATEWAY],
              OCI.DST.SUBNET,
              OCI.DST[IP_SEC_CONNECTION]
            ],
            filters: {
              connector: 'All',
              filterGroups: [
                {
                  connector: 'All',
                  filters: [
                    {
                      filterField: OCI.BI[DYNAMIC_ROUTING_GATEWAY],
                      operator: '=',
                      filterValue
                    }
                  ]
                }
              ]
            }
          })
        ]
      });
    }

    return queryDefs;
  }

  calculateStaticLinks = memoize(
    () => {
      const { Links = [] } = this.topologyCollection;

      const staticLinks = Links.flatMap((link) =>
        link.map((segment) => {
          const { source, target } = segment;
          const sourceSegment = { type: source.type, value: source.id };
          const targetSegment = { type: target.type, value: target.id };

          if (source.fallbackNodes) {
            sourceSegment.fallbackNodes = source.fallbackNodes;
          }

          if (target.fallbackNodes) {
            targetSegment.fallbackNodes = target.fallbackNodes;
          }

          return {
            isArrowLink: true,
            isForwardPath: true,
            source: sourceSegment,
            target: targetSegment
          };
        })
      );

      return staticLinks.map(this.styleLink);
    },
    () => this.topologyCollection.Links.flat().length
  );

  styleLink = (link) => {
    const { source, target } = link;
    let connectionType = 'curved';

    const squareConnections = [ROUTER, IP_SEC_CONNECTION, 'internet', 'box'];
    if (squareConnections.includes(source.type) || squareConnections.includes(target.type)) {
      connectionType = 'square';
    }

    if (source.type === LOCAL_PEERING_GATEWAY && target.type === LOCAL_PEERING_GATEWAY) {
      connectionType = 'square';
    }

    return { connectionType, ...link };
  };

  isVcnExpanded(vncId) {
    const { expandedRegionItems } = this.state;

    return expandedRegionItems.some((item) => item.value === vncId);
  }

  /**
   * will convert value as display name that comes from DE to id for lines drawing
   * will remove src-, dst-
   */
  convertQueryResultLink(link) {
    let resultedLink = { ...link };

    ['source', 'target'].forEach((direction) => {
      if (link[direction].type === SUBNET) {
        const subnetName = link[direction].value;

        const subnet = this.topologyCollection.findEntityByName(subnetName, SUBNET);
        if (!subnet) {
          return;
        }

        const { vcnId } = subnet;

        // add fallback to vcn if not expanded, and replace display name with id
        resultedLink = {
          ...resultedLink,
          [direction]: {
            ...resultedLink[direction],
            vcnId,
            fallbackNodes: [{ type: VIRTUAL_CLOUD_NETWORK, value: vcnId }],
            value: subnet.id,
            displayName: subnet.displayName
          }
        };
      }

      if (link[direction].type.includes('src-') || link[direction].type.includes('dst-')) {
        resultedLink[direction].type = resultedLink[direction].type.replace('src-', '').replace('dst-', '');
      }

      // fall back peering to vcn
      if (link[direction].type === LOCAL_PEERING_GATEWAY) {
        const peeringName = link[direction].value;

        const peering = this.topologyCollection.findEntityByName(peeringName, LOCAL_PEERING_GATEWAY);
        if (!peering) {
          return;
        }

        const { vcnId } = peering;
        resultedLink[direction].fallbackNodes = [{ type: VIRTUAL_CLOUD_NETWORK, value: vcnId }];
      }

      if (link[direction].type === DYNAMIC_ROUTING_GATEWAY) {
        const drgName = link[direction].value;

        const drg = this.topologyCollection.findEntityByName(drgName, DYNAMIC_ROUTING_GATEWAY);
        if (!drg) {
          return;
        }

        // replace name with id
        resultedLink[direction].value = drg.id;
      }

      if (link[direction].type === IP_SEC_CONNECTION) {
        const ipSecConName = link[direction].value;

        const ipSecConnection = this.topologyCollection.findEntityByName(ipSecConName, IP_SEC_CONNECTION);
        if (!ipSecConnection) {
          return;
        }

        // replace name with id
        resultedLink[direction].value = ipSecConnection.id;
      }
    });

    const { source, target } = resultedLink;
    if (target.type === SUBNET && source.type === DYNAMIC_ROUTING_GATEWAY) {
      const subnet = this.topologyCollection.findEntityById(target.value);

      const { vcnId } = subnet;

      const drgAttachment = Object.values(this.topologyCollection.Entities[DYNAMIC_ROUTING_GATEWAY_ATTACHMENT]).find(
        (attachment) => attachment.vcnId === vcnId
      );

      return [
        {
          ...resultedLink,
          source,
          target: {
            type: DYNAMIC_ROUTING_GATEWAY_ATTACHMENT,
            value: drgAttachment.id,
            vcnId,
            fallbackNodes: [{ type: VIRTUAL_CLOUD_NETWORK, value: vcnId }],
            displayName: drgAttachment.displayName
          }
        },
        {
          ...resultedLink,
          source: {
            type: DYNAMIC_ROUTING_GATEWAY_ATTACHMENT,
            value: drgAttachment.id,
            vcnId,
            fallbackNodes: [{ type: VIRTUAL_CLOUD_NETWORK, value: vcnId }],
            displayName: drgAttachment.displayName
          },
          target
        }
      ];
    }

    return resultedLink;
  }

  get nodeLinks() {
    if (!this.shouldRenderLinksBasedOnState) {
      return [];
    }

    const { nodeLinks, activeNode, hoveredNode } = this.state;

    return memoize(
      () =>
        rewriteFallbackLinks({ links: this.nodeLinksToDraw, mapDocument: this.map.current }).filter(
          ({ source, target }) => source.value !== target.value
        ),
      () => `${nodeLinks.length}-${activeNode?.value ?? ''}-${hoveredNode?.value ?? ''}`
    )();
  }

  get nodeLinksToDraw() {
    const statePathLinks = super.nodeLinks;

    const staticLinks = this.calculateStaticLinks();

    const allLinks = statePathLinks.concat(staticLinks);

    const { activeNode, hoveredNode } = this.state;
    if (hoveredNode || activeNode) {
      const links = generateLinksForHighlightedNode(allLinks, activeNode || hoveredNode);
      return links;
    }

    const totalStatePathLinks = statePathLinks.flatMap((statePathLink) => {
      const { source, target } = statePathLink;

      if (
        (['box', 'internet'].includes(source.type) && target.type === SUBNET) ||
        (source.type === SUBNET && ['box', 'internet'].includes(target.type))
      ) {
        const isSubnetSource = source.type === SUBNET;
        const subnetDirectionString = isSubnetSource ? 'source' : 'target';

        // example subnet -> IGV -> Internet
        const connectionGateways = [INTERNET_GATEWAY, NAT_GATEWAY, SERVICE_GATEWAY];
        const connectionGatewayType = connectionGateways.find((gatewayType) => statePathLink?.[gatewayType]);

        if (!connectionGatewayType) {
          return [];
        }

        const gatewayName = statePathLink?.[connectionGatewayType];
        const gatewayEntity = this.topologyCollection.findEntityByName(gatewayName, connectionGatewayType);

        // do not draw links to entities that not in topology. (or no enrichment)
        if (!gatewayEntity) {
          return [];
        }

        const subnetVcnId = statePathLink[subnetDirectionString].vcnId;

        return [
          {
            ...statePathLink,
            [subnetDirectionString]: {
              type: connectionGatewayType,
              value: gatewayEntity.id,
              fallbackNodes: [
                {
                  type: VIRTUAL_CLOUD_NETWORK,
                  value: subnetVcnId
                }
              ]
            }
          },
          {
            ...statePathLink,
            [isSubnetSource ? 'target' : 'source']: {
              type: connectionGatewayType,
              value: gatewayEntity.id,
              fallbackNodes: [
                {
                  type: VIRTUAL_CLOUD_NETWORK,
                  value: subnetVcnId
                }
              ]
            }
          }
        ];
      }

      return statePathLink;
    });

    const linksMap = {};

    // filter out duplicate links and sum their traffic
    totalStatePathLinks.forEach((link) => {
      const { source, target } = link;

      const key = [source, target]
        .map(({ type, value }) => `${type}-${value}`)
        .sort()
        .join('-');

      const hadOutboundData = Object.prototype.hasOwnProperty.call(link, 'outbound');

      link.inbound = link.inbound || 0;
      link.outbound = link.outbound || 0;
      link.total = link.total || 0;
      link.paths = link.paths || [];

      link.isArrowLink = true;

      // always draw when outbound is not defined
      link.isForwardPath = !hadOutboundData || link.outbound > 0;
      link.isReversePath = link.inbound > 0;

      if (linksMap[key]) {
        const existingLink = linksMap[key];

        if (existingLink.source.value === source.value) {
          existingLink.inbound += link.inbound;
          existingLink.outbound += link.outbound;
        } else {
          existingLink.inbound += link.outbound;
          existingLink.outbound += link.inbound;
        }

        if (link.source.value === existingLink.target.value) {
          existingLink.isReversePath = true;
        }

        existingLink.total += link.total;
        existingLink.paths = existingLink.paths.concat(link.paths);
      } else {
        linksMap[key] = link;
      }
    });

    const resultedLinks = Object.values(linksMap);

    return resultedLinks
      .reverse() // reverse links to ensure path is drawn last
      .map(this.styleLink);
  }

  handleNodeLinkClick = (config) => {
    const { cloudProvider, isEmbedded, setSidebarDetails } = this.props;
    const { topology } = this.state;

    if (config.pathLink) {
      // if this is a 'show path to' link, use the subnet/destination cidr as source/target
      config.source = config.pathLink.source;
      config.target = config.pathLink.target;
    }

    if (!isEmbedded) {
      setSidebarDetails({
        type: 'link',
        nodeData: {},
        initialLinkQuery: config.query ?? null,
        source: config.source,
        target: config.target,
        links: this.nodeLinks,
        cloudProvider,
        topology
      });
    }
  };

  get topologyCollection() {
    const { $hybridMap } = this.props;
    return $hybridMap.ociCloudMapCollection;
  }

  /*
    @borrowed from CloudAwsMap.getVpcBoxPositions
  */
  getRegionMiniBoxPositions = memoize(
    ({ source }) => {
      const rect = this.map.current.querySelector('.region-mini-map')?.getBoundingClientRect();
      const left = (rect?.left ?? 0) - source.svg.rect.left;
      const right = (rect?.right ?? 0) - source.svg.rect.left;
      const top = (rect?.top ?? 0) - source.svg.rect.top;
      const bottom = (rect?.bottom ?? 0) - source.svg.rect.top;

      const Cx = ((rect?.right ?? 0) - (rect?.left ?? 0)) / 2;
      const Cy = ((rect?.Top ?? 0) - (rect?.Bottom ?? 0)) / 2;

      return { left, right, Cx, Cy, top, bottom };
    },
    // vnet box left and right position wont change
    () => this.map.current.querySelector('.region-mini-map')?.className
  );

  getNodeLinkPositions(data) {
    const { linkData, source, target, path, pathIndex } = data;
    const { points: sourcePoints } = source;
    const { points: targetPoints } = target;

    if (sourcePoints.length === 0 || targetPoints.length === 0) {
      return { source, target };
    }

    const { regionCx, regionLeft, regionRight, regionTop, regionBottom } = this.getRegionBoxPositions({ source });
    /* box for expanded VCN */
    const regionMiniBoxPositions = this.getRegionMiniBoxPositions({ source });

    const connector = CloudMapConnector({
      sourceNode: linkData.source,
      targetNode: linkData.target,
      path,
      source,
      target,
      regionCx,
      pathIndex,
      regionTop,
      regionLeft,
      regionRight,
      regionBottom,
      regionMiniBoxPositions,
      connectionType: linkData.connectionType
    });

    if (connector !== null) {
      const { sourceAnchor, targetAnchor, connectionPoints } = connector;
      this.setLinkAnchor(source, sourceAnchor);
      this.setLinkAnchor(target, targetAnchor);

      return super.getNodeLinkPositions({
        ...data,
        source: { ...source, points: sourcePoints.concat(connectionPoints) },
        target
      });
    }

    // eslint-disable-next-line no-console
    console.error('NO CONNECTOR FOUND FOR', source, target, linkData);

    return super.getNodeLinkPositions({
      ...data,
      source: { ...source, points: sourcePoints },
      target
    });
  }

  handleGridItemHoverToggle = ({ type, item }) => {
    if (type === 'enter') {
      this.setState({ hoveredNode: { type: item.type, value: item.id } });
    } else {
      this.setState({ hoveredNode: null });
    }
  };

  handleGridItemClick = ({ key, item }) => {
    const { topology } = this.topologyCollection;
    const { setSidebarDetails } = this.props;

    this.setState({ activeNode: { value: item.id, type: key ?? item.type } }, () => {
      setSidebarDetails({
        topology,
        type: key,
        nodeData: { ...item },
        cloudProvider: 'oci',
        value: item?.displayName ?? item.id
      });
    });
  };

  handleShowDetails = ({ type, value, nodeData }) => {
    const { topology } = this.topologyCollection;
    const { openPopover } = this.props;

    const { node } = this.getNodePosition({ type, value: value ?? nodeData.id });
    const { x, y, width, height } = node;

    if (type === VIRTUAL_CLOUD_NETWORK || type === REGION) {
      return this.handleGridItemClick({ key: type, item: nodeData });
    }

    return openPopover({
      type,
      topology,
      value: value ?? nodeData.displayName,
      nodeData: { ...nodeData },
      cloudProvider: 'oci',
      position: { left: x, top: y, width, height },
      placement: 'bottom',
      detail: {
        type: 'cloud',
        cloudProvider: 'oci'
      },
      shortcutMenu: {
        selectedNode: nodeData ?? { type, value, nodeData },
        isShowingConnections: false,
        showConnectionsCallback: this.setNodeLinks
      }
    });
  };

  get onPremTopologyToRender() {
    const { topology } = this.topologyCollection;
    const { width: rectWidth } = this.svg.current.getBoundingClientRect();

    const items = [
      {
        key: IP_SEC_CONNECTION,
        group: Object.values(topology.Entities[IP_SEC_CONNECTION]),
        name: 'IPSec Connection',
        getSubtitle: (ipSec) => ipSec.displayName,
        icon: <CloudIcon cloudProvider="oci" entity={IP_SEC_CONNECTION} width={ICON_SIZE} height={ICON_SIZE} />
      }
    ];

    const willFit = items.every(({ group }) => getTotalWidth({ itemCount: group.length, width: 200 }) < rectWidth);

    return items.map((item) => ({ ...item, willFit })).filter((tempGroup) => tempGroup.group.length > 0);
  }

  get onPremRoutersToRender() {
    const { topology } = this.topologyCollection;
    const { width: rectWidth } = this.svg.current.getBoundingClientRect();
    const items = [
      {
        key: ROUTER,
        group: Object.values(topology.Entities[ROUTER]),
        name: 'Router',
        getSubtitle: (entity) => entity?.name ?? entity?.device_name ?? entity?.id ?? 'Unknown Device',
        icon: <CloudIcon cloudProvider="oci" entity={ROUTER} width={ICON_SIZE} height={ICON_SIZE} />
      }
    ];
    const willFit = items.every(
      ({ group }) =>
        getTotalWidth({ itemCount: group.length, gap: ON_PREM_FLEX_GAP }) < rectWidth - INTERNET_WIDTH.toPoints()
    );

    return items.map((item) => ({ ...item, willFit })).filter((tempGroup) => tempGroup.group.length > 0);
  }

  handleToggleRegionItem = ({ type, value }) => {
    this.setState(({ expandedRegionItems }) => {
      const isExpanded = expandedRegionItems.some((item) => item.type === type && item.value === value);
      // remove if expanded
      if (isExpanded) {
        return {
          expandedRegionItems: [...expandedRegionItems].filter((item) => item.type !== type && item.value !== value)
        };
      }

      return {
        expandedRegionItems: [...expandedRegionItems, { type, value }]
      };
    });
  };

  renderMap() {
    const { cloudProvider, width } = this.props;
    const { boxExpanded, loading, activeNode, nodeLinkQueries, nodeLinksLoading } = this.state;

    const { topology } = this.topologyCollection;

    if (loading) {
      return <Spinner mt={100} />;
    }

    if (this.topologyCollection.isEmpty()) {
      return <EmptyState mt={4} icon="cloud" title="No Topology Found" />;
    }

    return (
      <Box>
        {nodeLinksLoading && (
          <Flex alignItems="center" justifyContent="center" position="fixed" zIndex="999" top="300px" left="50%">
            <Spinner intent="primary" />
          </Flex>
        )}
        {/* ON PREM DEVICES */}
        <Flex justifyContent="space-between" gap={`${GRID_ROWS_GAP}px`}>
          <Box mr={1}>
            <CloudMapBox
              title="On Prem"
              className={classNames('box-onprem', {
                highlighted: this.isBoxHighlighted('onprem')
              })}
              minWidth="20vw"
              minHeight={150}
              flex="1 1 auto"
              flexWrap="wrap"
              onExpandToggle={(isExpanded) => this.setBoxExpanded('onprem', isExpanded)}
              isExpanded={boxExpanded.onprem}
            >
              <ItemGrid
                items={this.onPremRoutersToRender}
                itemStroke="primary"
                highlightedNodes={this.highlightedNodes}
                onHoverToggle={this.handleGridItemHoverToggle}
                onClick={this.handleGridItemClick}
                emptyState={<EmptyState icon="folder-close" description="No On Prem Resources" />}
                showTitle={false}
                selectedNode={activeNode}
              />
            </CloudMapBox>
          </Box>

          {/* INTERNET BOX */}
          <Box ml={1}>
            <CloudMapBox
              title="Internet"
              className={classNames('box-internet', 'link-bottomcenter', {
                highlighted: this.isBoxHighlighted('internet')
              })}
              width={INTERNET_WIDTH}
              height={233}
              onExpandToggle={(isExpanded) => this.setBoxExpanded('internet', isExpanded)}
              isExpanded={boxExpanded.internet}
            >
              <TopKeys
                classPrefix="internet"
                selected={this.getSelected('internet')}
                hovered={this.getHovered('internet')}
                highlighted={this.getHighlighted('internet')}
                onSelect={(value) => this.handleShowDetails({ type: 'internet', value })}
                onTabChange={this.handleInternetTabChange}
                cloud={cloudProvider}
              />
            </CloudMapBox>
          </Box>
        </Flex>

        {/* IPSec Connections */}
        <ItemGrid
          items={this.onPremTopologyToRender}
          itemWidth={200}
          itemStroke="primary"
          highlightedNodes={this.highlightedNodes}
          onHoverToggle={this.handleGridItemHoverToggle}
          onClick={this.handleGridItemClick}
          selectedNode={activeNode}
        />

        {/* REGION BOXES */}
        <Flex flexDirection="column" gap={`${GRID_ROWS_GAP}px`}>
          {topology.Hierarchy?.Regions?.map((region) => (
            <Box key={region.id} className={`box-region ${getMapClassname({ type: REGION, value: region.id })}`}>
              <CloudMapBox p={0} minWidth={200} minHeight={100} isExpanded>
                <OciRegion
                  region={region}
                  width={width - LOCATION_PADDING}
                  expandRegionItem={this.expandRegionItem}
                  onToggleRegionItem={this.handleToggleRegionItem}
                  onShowDetails={this.handleShowDetails}
                />
              </CloudMapBox>
            </Box>
          ))}
        </Flex>

        <TrafficLinkGenerator inputs={nodeLinkQueries} onLinksUpdate={this.handleNodeLinksUpdate} />
      </Box>
    );
  }
}
