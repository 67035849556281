import React from 'react';

import { inject } from 'mobx-react';
import { Flex, Text } from 'core/components';

import SidebarItem from '../SidebarItem';
import CloudMetadata from '../CloudMetadata';
import { AzureVirtualHubDetails, AzureVpnSiteDetails } from './components';

const AzureVirtualWanPopover = ({ cloudProvider, width, popoutTitle, $hybridMap, ...rest }) => {
  const { nodeData } = rest;

  const { azureCloudMapCollection } = $hybridMap;

  const virtualWan = azureCloudMapCollection.getEntityProperty(nodeData, 'virtualWan');

  if (!virtualWan) {
    return null;
  }

  const virtualHubs = azureCloudMapCollection.getEntityProperty(virtualWan, 'virtualHubs') ?? [];
  const vpnSites = azureCloudMapCollection.getEntityProperty(virtualWan, 'vpnSites') ?? [];

  return (
    <SidebarItem
      excludeFormProps
      title="Virtual Wan"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="chat"
      popoutTitle={popoutTitle}
    >
      <Flex px="4px" gap={1} flexDirection="column">
        <Flex flexDirection="column" gap={1}>
          <CloudMetadata
            node={{
              ...virtualWan,
              ...virtualWan.properties
            }}
            keys={['name', 'location', 'type', 'provisioningState']}
          />
          {virtualHubs?.length && (
            <Flex flexDirection="column" gap={1}>
              <Text as="div">Virtual Hubs:</Text>
              {virtualHubs.map((virtualHub) => (
                <AzureVirtualHubDetails
                  key={virtualHub.id}
                  nodeData={virtualHub}
                  width={width}
                  popoutTitle={popoutTitle}
                />
              ))}
            </Flex>
          )}

          {vpnSites?.length && (
            <Flex flexDirection="column" gap={1}>
              <Text as="div">VPN Sites:</Text>
              {vpnSites.map((vpnSite) => (
                <AzureVpnSiteDetails key={vpnSite.id} nodeData={vpnSite} width={width} popoutTitle={popoutTitle} />
              ))}
            </Flex>
          )}
        </Flex>
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureVirtualWanPopover);
