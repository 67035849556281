import React from 'react';
import { observable, reaction } from 'mobx';
import { AWS_ACCOUNT_SUMMARY_ENTITIES, AWS_ENTITY_TYPES } from 'shared/hybrid/constants';
import { memoize, orderBy, get } from 'lodash';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import {
  Card,
  Button,
  Flex,
  Popover,
  Icon,
  Tag,
  Text,
  Spinner,
  Box,
  Heading,
  Checkbox,
  Suspense,
  Tooltip
} from 'core/components';
import { Filter, VirtualizedTable } from 'core/components/table';
import AwsLogo from 'app/components/AwsLogo';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import TimedCallout from 'app/views/hybrid/maps/components/TimedCallout';
import { Field, Select, RemoveButton, Form } from 'core/form';
import AccountsFilterCollection from 'app/stores/clouds/aws/AccountsFilterCollection';
import MapSearchGroupDialog from './components/MapSearchGroupDialog';

const AWS_ACCOUNT_GROUPS_MODULE_GROUP = 'awsaccountgroups';

const StyledBox = styled(Box)`
  .flex-grow-1 {
    flex-grow: 1;
  }
`;

const initialFields = {
  subscriptions: {},
  name: {
    label: 'Name / ID'
  },
  view: {
    label: 'View',
    options: [
      { value: 'all', label: 'All' },
      { value: 'selected', label: 'Selected' }
    ],
    defaultValue: 'all'
  },
  group: {
    label: 'Group',
    options: [{ value: '', label: '---' }],
    defaultValue: ''
  }
};
@Form({ fields: initialFields, options: { name: 'aws-accounts-filter' } })
@inject('$clouds', '$hybridMap', '$auth', '$moduleConfig')
@observer
export default class MapSearchAwsSubscriptionsPanel extends React.Component {
  awsCloudMapCollectionDisposer = () => {};

  @observable
  accountsCollection = new AccountsFilterCollection([]);

  tempSelectedGroup = '';

  state = {
    popoverOpen: false,
    calloutMessageArr: [],
    displayGroupDialog: false,
    isManagingAccountGroup: false
  };

  initializeAccountsCollectionFromTopology() {
    const { $hybridMap, form } = this.props;
    const { awsCloudMapCollection } = $hybridMap;

    const accountModels = awsCloudMapCollection.awsAccountEntities;

    const accountsSummary = awsCloudMapCollection.Summary?.Accounts;

    const defaultSummary = AWS_ACCOUNT_SUMMARY_ENTITIES.reduce((carry, entityType) => {
      carry[entityType] = 0;
      return carry;
    }, {});

    // set summary for each account model
    accountModels.forEach((accountModel) => {
      accountModel.summary = accountsSummary[accountModel.id] ?? defaultSummary;
    });

    const sortedModels = orderBy(accountModels, (model) => +get(model, `summary.${AWS_ENTITY_TYPES.VPC}`, 0), 'desc');
    this.accountsCollection.set(sortedModels);

    const selectedAwsAccountsInUserOptions = $hybridMap.selectedAwsAccounts;
    if (selectedAwsAccountsInUserOptions.length > 0) {
      this.accountsCollection.models.forEach((awsAccountModel) => {
        if (selectedAwsAccountsInUserOptions.includes(awsAccountModel.get('id'))) {
          awsAccountModel.select({ multi: true });
        }
      });
    } else {
      this.accountsCollection.selectAll({ multi: true });
    }

    this.accountsCollection.applyPagination();

    // autoselect group and filter to show only accounts that apply to this group
    if ($hybridMap.selectedAwsAccountGroup) {
      form.setValue('group', $hybridMap.selectedAwsAccountGroup);
      this.onGroupDropdownChange({ value: $hybridMap.selectedAwsAccountGroup });
    }
  }

  fetchModuleConfig() {
    const { $moduleConfig } = this.props;

    return $moduleConfig.fetchModuleConfig(AWS_ACCOUNT_GROUPS_MODULE_GROUP);
  }

  componentDidMount() {
    const { $hybridMap } = this.props;

    this.fetchModuleConfig();

    this.initializeAccountsCollectionFromTopology();

    this.awsCloudMapCollectionDisposer = reaction(
      () => $hybridMap.awsCloudMapCollection.awsAccountEntities.length,
      () => this.initializeAccountsCollectionFromTopology()
    );
  }

  componentWillUnmount() {
    // execute to avoid memory leak
    this.awsCloudMapCollectionDisposer();
  }

  onPopoverClose = () => {
    const { onChange, $auth, form } = this.props;

    // do nothing when topology is loading
    if (this.isLoading) {
      return null;
    }

    this.setState({ popoverOpen: false }, () => {
      // do not persist in demo account
      onChange({
        persist: !$auth.isDemoUser,
        awsAccountIds: this.accountsCollection.selectedAccountIds,
        selectedGroup: form.getValue('group')
      });
    });

    return null;
  };

  onGroupDropdownChange = ({ value }) => {
    this.tempSelectedGroup = value;

    if (value !== '' && this.companyAwsAccountGroups[value]) {
      this.accountsCollection.addGroupFilter(this.companyAwsAccountGroups[value]);
    }

    if (value === '') {
      // reset selections back to initial one
      this.accountsCollection.removeGroupFilter();
      this.accountsCollection.applyPagination();
    }
  };

  changePage = (page) => {
    this.accountsCollection.setPage(page);
    this.accountsCollection.applyPageChangeFilter();
  };

  onViewDropdownChange = ({ value }) => {
    if (value === 'selected') {
      this.accountsCollection.showSelectedAccounts();
      return;
    }

    if (value === 'all') {
      this.accountsCollection.showAllAccounts();
      return;
    }

    console.warn('Unhandled inViewDropdownChange', value);
  };

  get columns() {
    const columns = [
      {
        key: 'id',
        label: 'Selected',
        width: 50,
        renderer: ({ model }) => (
          <Checkbox mt="2px" checked={model.isSelected} onChange={() => model.select({ multi: true })} />
        )
      },
      {
        key: 'name',
        label: 'Name',
        name: 'name',
        className: 'flex-grow-1',
        flexGrow: 1,
        width: 'auto',
        renderer: ({ model }) => (
          <Flex flexDirection="column" gap={1}>
            <Box>
              <Text as="b">{model.get('Name')}</Text>
            </Box>
            {model.get('Name') !== model.get('id') && (
              <Box>
                <Text muted small>
                  {model.get('id')}
                </Text>
              </Box>
            )}
          </Flex>
        )
      },
      {
        key: 'summary',
        label: 'Summary',
        name: 'summary',
        renderer: ({ model }) => {
          const summary = model.get('summary');

          if (!summary) {
            return null;
          }

          return (
            <Flex gap={1} flexWrap="wrap" justifyContent="space-evenly">
              {AWS_ACCOUNT_SUMMARY_ENTITIES.map((entityType) => (
                <Tooltip key={entityType} content={entityType}>
                  <Flex gap={1} flexDirection="column" alignItems="center">
                    <CloudIcon cloudProvider="aws" entity={entityType} iconSize={20} />
                    <Text>{summary[entityType] ?? 0}</Text>
                  </Flex>
                </Tooltip>
              ))}
            </Flex>
          );
        }
      }
    ];

    return columns;
  }

  get companyAwsAccountGroups() {
    const { $moduleConfig } = this.props;
    return $moduleConfig.get(AWS_ACCOUNT_GROUPS_MODULE_GROUP, '', {});
  }

  cancelManageGroup = () => {
    this.accountsCollection.addGroupFilter(this.companyAwsAccountGroups[this.tempSelectedGroup]);
    this.tempSelectedGroup = null;

    this.setState({ isManagingAccountGroup: false });
  };

  saveGroup = async () => {
    const { $moduleConfig } = this.props;

    return $moduleConfig
      .saveModuleConfig({
        module: AWS_ACCOUNT_GROUPS_MODULE_GROUP,
        settings: {
          ...this.companyAwsAccountGroups,
          [this.tempSelectedGroup]: this.accountsCollection.selectedAccountIds
        }
      })
      .finally(() => {
        this.setState({
          displayGroupDialog: false,
          isManagingAccountGroup: false,
          calloutMessageArr: [`${this.tempSelectedGroup} group has been saved.`]
        });

        this.tempSelectedGroup = null;
      });
  };

  createGroup = async (groupName) => {
    const { $moduleConfig } = this.props;

    const message = this.tempSelectedGroup
      ? `${this.tempSelectedGroup} group has been renamed into ${groupName}.`
      : `Group ${groupName} was successfully created.`;

    return $moduleConfig
      .saveModuleConfig({
        module: AWS_ACCOUNT_GROUPS_MODULE_GROUP,
        settings: {
          ...this.companyAwsAccountGroups,
          [groupName]: this.tempSelectedGroup
            ? this.companyAwsAccountGroups[this.tempSelectedGroup]
            : this.accountsCollection.selectedAccountIds
        }
      })
      .finally(() => {
        this.setState({
          displayGroupDialog: false,
          calloutMessageArr: [message]
        });

        // if group was renamed
        if (this.tempSelectedGroup) {
          this.removeGroup(this.tempSelectedGroup, true);
          this.onGroupDropdownChange({ value: groupName });
          this.tempSelectedGroup = null;
        }
      });
  };

  removeGroup = async (groupNameToRemove = '', ignoreCalloutMessage = false) => {
    const { $moduleConfig, form } = this.props;

    const field = form.getField('group');
    const groupName = groupNameToRemove || field.getValue('group');

    field.setValue('');

    const companyAccountGroups = { ...this.companyAwsAccountGroups };
    delete companyAccountGroups[groupName];

    this.onGroupDropdownChange({ value: '' });

    const newState = {
      displayGroupDialog: false
    };
    if (!ignoreCalloutMessage) {
      Object.assign(newState, { calloutMessageArr: [`Group ${groupName} was successfully removed.`] });
    }

    return $moduleConfig
      .saveModuleConfig({
        module: AWS_ACCOUNT_GROUPS_MODULE_GROUP,
        settings: { ...companyAccountGroups }
      })
      .finally(() => {
        this.setState(newState);
      });
  };

  getAwsAccountGroupOptions = memoize(
    () => {
      // append stored groups into form dropdown
      const { group } = initialFields;

      const awsAccountGroups = Object.keys(this.companyAwsAccountGroups).map((awsGroup) => ({
        value: awsGroup,
        label: awsGroup
      }));

      return [...group.options, ...awsAccountGroups];
    },
    () => this.companyAwsAccountGroups
  );

  onManageGroupClicked = () => {
    const { form } = this.props;

    const selectedGroup = form.getValue('group');
    this.tempSelectedGroup = selectedGroup;

    this.accountsCollection.resetDiscreteFilters();

    this.setState({
      isManagingAccountGroup: true
    });
  };

  render() {
    const { $hybridMap, form } = this.props;
    const { popoverOpen, displayGroupDialog, isManagingAccountGroup, calloutMessageArr } = this.state;
    const { pageCount, currentPage } = this.accountsCollection?.pageState || {};

    return (
      <Popover
        autoFocus={false}
        enforceFocus={false}
        isOpen={popoverOpen}
        onClose={this.onPopoverClose}
        placement="bottom-start"
        content={
          <Suspense
            loading={$hybridMap.awsCloudMapCollection.loading}
            fallback={
              <Flex p={2} alignItems="Center" justifyContent="center" width="100%" gap={1}>
                <Spinner size={24} />
                <Text>Loading AWS Accounts.</Text>
              </Flex>
            }
          >
            <Card display="flex" flexDirection="column" width="750px">
              <Heading p={2} bg="subnavBackground" level={5} mb={0}>
                Account Filter
              </Heading>
              <Flex p={1} gap={1} alignItems="center">
                <Field name="name" width="100%" placeholder="Search Accounts...">
                  <Filter collection={this.accountsCollection} />
                </Field>

                <Field
                  name="view"
                  onQuery={null}
                  onChange={this.onViewDropdownChange}
                  disabled={isManagingAccountGroup}
                >
                  <Select small menuWidth={100} />
                </Field>
                <Field
                  name="group"
                  disabled={isManagingAccountGroup}
                  options={this.getAwsAccountGroupOptions()}
                  onChange={this.onGroupDropdownChange}
                >
                  <Select small menuWidth={175} />
                </Field>
              </Flex>
              <Flex px={1} paddingTop="8px" alignItems="center" justifyContent="center" gap={1}>
                <Flex
                  flexGrow="1"
                  width="100%"
                  px={1}
                  justifyContent="space-between"
                  alignItems="center"
                  bg="subnavBackground"
                >
                  {isManagingAccountGroup && (
                    <Flex gap="1">
                      <Button small intent="primary" text="Save" onClick={this.saveGroup} />
                      <Button small intent="danger" text="Cancel" onClick={this.cancelManageGroup} />
                    </Flex>
                  )}
                  <Flex justifyContent="center" width="50px" style={{ paddingRight: '6px' }}>
                    <Checkbox
                      checked={this.accountsCollection.isAllSelected}
                      onChange={() => {
                        this.accountsCollection.selectAll({
                          multi: true
                        });
                      }}
                    />
                  </Flex>
                  <Flex flex="1" py="8px" px={1} justifyContent="space-between" alignItems="center">
                    <Text>{`${this.accountsCollection.selectedSize} / ${this.accountsCollection.unfilteredSize} selected`}</Text>
                  </Flex>
                  <Flex flex="1">
                    <Flex gap={2} ml="auto">
                      {form.getValue('group') === '' && (
                        <Button
                          small
                          intent="primary"
                          text="Create Group"
                          onClick={() => {
                            this.setState({ displayGroupDialog: true });
                          }}
                        />
                      )}

                      {form.getValue('group') !== '' && !isManagingAccountGroup && (
                        <>
                          <Button small text="Manage Group" onClick={this.onManageGroupClicked} />
                          <Button
                            small
                            text="Rename Group"
                            onClick={() => {
                              const selectedGroup = form.getValue('group');
                              this.tempSelectedGroup = selectedGroup;
                              return this.setState({ displayGroupDialog: true });
                            }}
                          />
                          <RemoveButton
                            buttonProps={{ small: true, intent: 'danger', text: 'Remove Group' }}
                            onRemove={this.removeGroup}
                          />
                        </>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <StyledBox p={1} className="flex-grow-1">
                <VirtualizedTable
                  hideHeader
                  isCollapsed
                  multiSelect
                  onRowClick={this.handleRowClick}
                  style={{ height: 300 }}
                  collection={this.accountsCollection}
                  columns={this.columns}
                />
              </StyledBox>
              {/* Pagination */}
              {pageCount > 1 && (
                <Flex justifyContent="space-between" p={1} alignItems="center">
                  <Button onClick={() => this.changePage(currentPage - 1)} disabled={currentPage <= 1}>
                    Previous
                  </Button>
                  <Box flexGrow="1" textAlign="center">
                    <Text>
                      Page {currentPage} of {pageCount}
                    </Text>
                  </Box>
                  <Button onClick={() => this.changePage(currentPage + 1)} disabled={currentPage >= pageCount}>
                    Next
                  </Button>
                </Flex>
              )}

              {calloutMessageArr.length > 0 && (
                <TimedCallout timeout={5000} intent="success" key={calloutMessageArr.join('-')}>
                  {calloutMessageArr.map((message) => (
                    <Text key={message}>{message}</Text>
                  ))}
                </TimedCallout>
              )}

              <MapSearchGroupDialog
                isOpen={displayGroupDialog}
                selectedCount={this.accountsCollection.selectedSize}
                groupName={this.tempSelectedGroup}
                onSubmit={(groupName) => {
                  this.createGroup(groupName).then(() => {
                    // select this group in a form
                    form.setValue('group', groupName);
                    this.onGroupDropdownChange({ value: groupName });
                  });
                }}
                onClose={() => this.setState({ displayGroupDialog: false })}
              />
            </Card>
          </Suspense>
        }
      >
        <Flex alignItems="center">
          <Button
            rightIcon="caret-down"
            height="35px"
            active={popoverOpen}
            onClick={() => this.setState((prevState) => ({ popoverOpen: !prevState.popoverOpen }))}
            small
          >
            <Flex alignItems="center" gap={1} px={1}>
              <Icon color="aws.orange" icon={AwsLogo} />
              Accounts
              {this.accountsCollection.selectedSize > 0 && (
                <Tag bg="aws.orange" color="white.10" round small>
                  {this.accountsCollection.selectedSize}
                </Tag>
              )}
            </Flex>
          </Button>
        </Flex>
      </Popover>
    );
  }
}
