import React from 'react';
import { isEqual, memoize } from 'lodash';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import {
  Card,
  Button,
  Flex,
  Popover,
  Icon,
  Tag,
  Callout,
  Text,
  Spinner,
  Box,
  Heading,
  Checkbox,
  Suspense,
  Tooltip
} from 'core/components';

import { Filter, VirtualizedTable } from 'core/components/table';
import SubscriptionFilterCollection from 'app/stores/clouds/azure/SubscriptionFilterCollection';
import { MAX_ALLOWED_SELECTED_SUBSCRIPTIONS, ENTITY_TYPES } from 'shared/hybrid/constants';
import { FormComponent, Field, Select, RemoveButton } from 'core/form';
import { parseQueryString } from 'app/util/utils';
import AzureLogo from 'app/components/AzureLogo';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import TimedCallout from 'app/views/hybrid/maps/components/TimedCallout';
import azureSubscriptionsListQuery from 'shared/hybrid/resourceGraphQueries/azureSubscriptionsList';
import MapSearchGroupDialog from './components/MapSearchGroupDialog';

const initialFields = {
  subscriptions: {},
  name: {
    label: 'Name / ID / CIDR'
  },
  view: {
    label: 'View',
    options: [
      { value: 'all', label: 'All' },
      { value: 'selected', label: 'Selected' }
    ],
    defaultValue: 'all'
  },
  group: {
    label: 'Group',
    options: [{ value: '', label: '---' }],
    defaultValue: ''
  }
};

const AZURE_SUBSCRIPTIONS_GROUPS_MODULE_GROUP = 'azuresubscriptiongroups';
const { LOCATION, VNET, SUBNET, FIREWALL, VIRTUAL_WAN, VIRTUAL_HUB } = ENTITY_TYPES.get('azure');

const StyledBox = styled(Box)`
  .flex-grow-1 {
    flex-grow: 1;
  }
`;

@inject('$clouds', '$hybridMap', '$auth', '$moduleConfig')
@observer
export default class MapSearchAzureSubscriptionsPanel extends React.Component {
  prevSelectedSubscriptionIds = [];

  prevHybridMapSelectedSubscriptionIds = [];

  tempSelectedGroup = null;

  state = {
    calloutMessageArr: [],
    displayGroupDialog: false,
    subscriptionsPopoverIsOpen: false,
    tempSubscriptionsToAddToGroup: [],
    isFetchingSubscriptionNames: true,
    isAddingSubscriptionsToGroup: false,
    tempSubscriptionsToRemoveFromGroup: [],
    subscriptionsCollection: new SubscriptionFilterCollection([])
  };

  /** will return array of selected subscription ids */
  get selectedSubscriptions() {
    const { subscriptionsCollection } = this.state;

    return subscriptionsCollection.selected?.map((model) => model.get('id')) ?? [];
  }

  get isMaxSubscriptionsSelected() {
    const { subscriptionsCollection } = this.state;
    return subscriptionsCollection.selectedSize >= MAX_ALLOWED_SELECTED_SUBSCRIPTIONS;
  }

  get companyAzureSubscriptionGroups() {
    const { $moduleConfig } = this.props;
    return $moduleConfig.get(AZURE_SUBSCRIPTIONS_GROUPS_MODULE_GROUP, '', {});
  }

  getGroupAzureSubscriptions(group) {
    return this.companyAzureSubscriptionGroups?.[group] ?? [];
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  get isAddingSubscriptionsToGroup() {
    const { tempSubscriptionsToAddToGroup } = this.state;

    return tempSubscriptionsToAddToGroup.length > this.getGroupAzureSubscriptions(this.tempSelectedGroup);
  }

  fetchModuleConfigForAzureSubscriptionGroups() {
    const { $moduleConfig } = this.props;

    return $moduleConfig.fetchModuleConfig(AZURE_SUBSCRIPTIONS_GROUPS_MODULE_GROUP);
  }

  get isLoading() {
    const { $hybridMap } = this.props;
    const { isFetchingSubscriptionNames } = this.state;
    const { isLoading: isLoadingTopology } = $hybridMap.azureCloudMapCollection;

    return isLoadingTopology || isFetchingSubscriptionNames;
  }

  componentDidMount() {
    const { $clouds, $hybridMap } = this.props;
    this.fetchModuleConfigForAzureSubscriptionGroups().then(() => {
      Promise.all([
        $hybridMap.azureCloudMapCollection.fetch(),
        $clouds.fetchAzureResourceGraphQueryData({
          query: azureSubscriptionsListQuery
        })
      ]).then(([, resourceGraphSubscriptions]) => {
        const azureSummary = $hybridMap.azureCloudMapCollection?.topology?.Summary;
        const models = $clouds.azureSubscriptionIds.map((subscriptionId, index) => {
          const subscriptionName =
            resourceGraphSubscriptions.find(
              (subscription) => subscription.subscriptionId?.toLowerCase() === subscriptionId?.toLowerCase()
            )?.name ?? `Unknown subscription ${index}`;
          return {
            id: subscriptionId,
            name: subscriptionName,
            ips: azureSummary?.[subscriptionId]?.ipAddresses ?? [],
            initialSelected: $hybridMap.selectedAzureSubscriptions.includes(subscriptionId),
            summary: azureSummary?.[subscriptionId] ?? null
          };
        });

        const subscriptionsCollection = new SubscriptionFilterCollection(models, {
          sortState: { field: 'initialSelected', direction: 'desc' }
        });

        $hybridMap.selectedAzureSubscriptions.forEach((id) => {
          subscriptionsCollection.get(id).select({ multi: true });
        });

        subscriptionsCollection.applyPageChangeFilter();

        this.prevHybridMapSelectedSubscriptionIds = [...$hybridMap.selectedAzureSubscriptions];
        this.setState({ subscriptionsCollection, isFetchingSubscriptionNames: false });
      });
    });
  }

  componentDidUpdate(_prevProps, prevState) {
    const { $hybridMap } = this.props;
    const { subscriptionsPopoverIsOpen, subscriptionsCollection } = this.state;

    if (!prevState.subscriptionsPopoverIsOpen && subscriptionsPopoverIsOpen) {
      this.onGroupDropdownChange({ value: '' });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ calloutMessageArr: [] });
    }

    if (!this.isLoading && !isEqual(this.prevHybridMapSelectedSubscriptionIds, $hybridMap.selectedAzureSubscriptions)) {
      // force select models in collection based on current hybridMap selection, this needs to be done in case selected subscriptions are changed directly in $hybridMap
      $hybridMap.selectedAzureSubscriptions.forEach((id) => {
        const model = subscriptionsCollection.get(id);
        if (!model.isSelected) {
          model.select({ multi: true });
        }
      });
      this.prevHybridMapSelectedSubscriptionIds = [...$hybridMap.selectedAzureSubscriptions];
    }
  }

  onPopoverClose = () => {
    const { onChange, $auth } = this.props;

    // do nothing when topology is loading
    if (this.isLoading) {
      return null;
    }

    this.setState({ subscriptionsPopoverIsOpen: false }, () => {
      // do not persist in demo account
      onChange({ persist: !$auth.isDemoUser, azureSubscriptionIds: this.selectedSubscriptions });
    });

    return null;
  };

  get columns() {
    const { isAddingSubscriptionsToGroup, tempSubscriptionsToAddToGroup, tempSubscriptionsToRemoveFromGroup } =
      this.state;

    const columns = [
      {
        key: 'id',
        label: 'Selected',
        width: 50,
        renderer: ({ model }) => (
          <Checkbox
            checked={model.isSelected}
            disabled={(this.isMaxSubscriptionsSelected && !model.isSelected) || isAddingSubscriptionsToGroup}
            onChange={() => model.select({ multi: true })}
          />
        )
      },
      {
        key: 'name',
        label: 'Name',
        name: 'name',
        className: 'flex-grow-1',
        flexGrow: 1,
        width: 'auto',
        renderer: ({ model }) => (
          <Flex flexDirection="column" gap={1}>
            <Box>
              <Text as="b">{model.get('name')}</Text>
            </Box>
            <Box>
              <Text>{model.get('id')}</Text>
            </Box>

            {/* <AzureSubscriptionAdditionalDetails subscriptionId={model.get('id')} /> */}
          </Flex>
        )
      },
      {
        key: 'summary',
        label: 'Summary',
        name: 'summary',
        renderer: ({ model }) => {
          const summary = model.get('summary');

          if (!summary) {
            return null;
          }

          return (
            <Flex gap={1} flexWrap="wrap" justifyContent="space-evenly">
              {Object.values(summary[LOCATION]).length > 0 && (
                <Tooltip content="Locations">
                  <Flex gap={1} flexDirection="column" alignItems="center">
                    <CloudIcon cloudProvider="azure" entity={LOCATION} iconSize={20} />
                    <Text>{Object.values(summary[LOCATION]).length}</Text>
                  </Flex>
                </Tooltip>
              )}

              {Object.values(summary[VNET]).length > 0 && (
                <Tooltip content="VNETs">
                  <Flex gap={1} flexDirection="column" alignItems="center">
                    <CloudIcon cloudProvider="azure" entity={VNET} iconSize={20} />
                    <Text>{Object.values(summary[VNET]).length}</Text>
                  </Flex>
                </Tooltip>
              )}

              {Object.values(summary[SUBNET]).length > 0 && (
                <Tooltip content="Subnets">
                  <Flex gap={1} flexDirection="column" alignItems="center">
                    <CloudIcon cloudProvider="azure" entity={SUBNET} iconSize={20} />
                    <Text>{Object.values(summary[SUBNET]).length}</Text>
                  </Flex>
                </Tooltip>
              )}

              {Object.values(summary[FIREWALL]).length > 0 && (
                <Tooltip content="Firewalls">
                  <Flex gap={1} flexDirection="column" alignItems="center">
                    <CloudIcon cloudProvider="azure" entity={FIREWALL} iconSize={20} />
                    <Text>{Object.values(summary[FIREWALL]).length}</Text>
                  </Flex>
                </Tooltip>
              )}

              {Object.values(summary[VIRTUAL_HUB]).length > 0 && (
                <Tooltip content="Virtual Hubs">
                  <Flex gap={1} flexDirection="column" alignItems="center" title="">
                    <CloudIcon cloudProvider="azure" entity={VIRTUAL_HUB} iconSize={20} />
                    <Text>{Object.values(summary[VIRTUAL_HUB]).length}</Text>
                  </Flex>
                </Tooltip>
              )}

              {Object.values(summary[VIRTUAL_WAN]).length > 0 && (
                <Tooltip content="Virtual Wans">
                  <Flex gap={1} flexDirection="column" alignItems="center" title="">
                    <CloudIcon cloudProvider="azure" entity={VIRTUAL_WAN} iconSize={20} />
                    <Text>{Object.values(summary[VIRTUAL_WAN]).length}</Text>
                  </Flex>
                </Tooltip>
              )}
            </Flex>
          );
        }
      }
    ];

    if (!isAddingSubscriptionsToGroup) {
      return columns;
    }

    columns.push({
      key: 'id',
      label: '',
      name: '',
      width: 50,
      renderer: ({ model }) => {
        let isModelAdded = null;

        if (tempSubscriptionsToAddToGroup.length === 0 && tempSubscriptionsToRemoveFromGroup.length === 0) {
          isModelAdded = this.getGroupAzureSubscriptions(this.tempSelectedGroup).includes(model.get('id'));
        } else {
          if (tempSubscriptionsToAddToGroup.length > 0 && tempSubscriptionsToAddToGroup.includes(model.get('id'))) {
            isModelAdded = true;
          }

          if (
            isModelAdded !== true &&
            tempSubscriptionsToRemoveFromGroup.length > 0 &&
            tempSubscriptionsToRemoveFromGroup.includes(model.get('id'))
          ) {
            isModelAdded = false;
          }

          // if was never set
          if (isModelAdded === null) {
            isModelAdded = this.getGroupAzureSubscriptions(this.tempSelectedGroup).includes(model.get('id'));
          }
        }

        return (
          <Flex flexDirection="column" gap={1}>
            {!isModelAdded && (
              <Button
                rightIcon="add"
                onClick={() =>
                  this.setState((prevState) =>
                    // add to tempSubscriptionsToAddToGroup and remove from tempSubscriptionsToRemoveFromGroup
                    ({
                      ...prevState,
                      // we can add subscription only when its not added to initial group list
                      tempSubscriptionsToAddToGroup: this.getGroupAzureSubscriptions(this.tempSelectedGroup).includes(
                        model.get('id')
                      )
                        ? [...prevState.tempSubscriptionsToAddToGroup]
                        : [...prevState.tempSubscriptionsToAddToGroup, model.get('id')],
                      tempSubscriptionsToRemoveFromGroup: [
                        ...prevState.tempSubscriptionsToRemoveFromGroup.filter((subId) => subId !== model.get('id'))
                      ]
                    })
                  )
                }
                className="bp4-minimal"
              />
            )}
            {isModelAdded && (
              <Button
                rightIcon="trash"
                onClick={() =>
                  this.setState((prevState) =>
                    // add to tempSubscriptionsToRemoveFromGroup and remove from tempSubscriptionsToAddToGroup
                    ({
                      ...prevState,
                      tempSubscriptionsToAddToGroup: [
                        ...prevState.tempSubscriptionsToAddToGroup.filter((subId) => subId !== model.get('id'))
                      ],
                      // we cam remove subscription only when its added to initial group list
                      tempSubscriptionsToRemoveFromGroup: this.getGroupAzureSubscriptions(
                        this.tempSelectedGroup
                      ).includes(model.get('id'))
                        ? [...prevState.tempSubscriptionsToRemoveFromGroup, model.get('id')]
                        : [...prevState.tempSubscriptionsToRemoveFromGroup]
                    })
                  )
                }
                className="bp4-minimal"
              />
            )}
          </Flex>
        );
      }
    });

    return columns;
  }

  onGroupDropdownChange = ({ value }) => {
    const { subscriptionsCollection } = this.state;

    subscriptionsCollection.resetDiscreteFilters();

    if (value !== '') {
      const groupSubscriptionIds = this.getGroupAzureSubscriptions(value);

      this.prevSelectedSubscriptionIds = subscriptionsCollection.selected?.map((model) => model.get('id')) ?? [];

      subscriptionsCollection.unselectAll();
      // select every model in this subscription group
      groupSubscriptionIds.forEach((subscriptionId) => {
        const model = subscriptionsCollection.get(subscriptionId);

        if (model && !model.isSelected) {
          model.select({ multi: true });
        }
      });

      subscriptionsCollection.addDiscreteFilter({
        type: 'group-dropdown',
        fn: (subscriptionModel) => groupSubscriptionIds.includes(subscriptionModel.get('id'))
      });
    }

    if (value === '') {
      // reset selections back to initial onces
      this.prevSelectedSubscriptionIds.forEach((subscriptionId) => {
        const model = subscriptionsCollection.get(subscriptionId);

        if (model && !model.isSelected) {
          model.select({ multi: true });
        }
      });
    }

    subscriptionsCollection.filter();
    subscriptionsCollection.calculatePageCount();
    subscriptionsCollection.applyPageChangeFilter();
  };

  onViewDropdownChange = ({ value }) => {
    const { subscriptionsCollection } = this.state;

    subscriptionsCollection.resetDiscreteFilters();

    if (value === 'selected') {
      subscriptionsCollection.addDiscreteFilter({
        type: 'view-dropdown',
        fn: (subscriptionModel) => subscriptionModel.isSelected
      });
    }

    // have to filter because addDiscreteFilter doesnt do it
    subscriptionsCollection.filter();
    subscriptionsCollection.calculatePageCount();
    subscriptionsCollection.applyPageChangeFilter();
  };

  onManageGroupSubscriptionClick(form) {
    const selectedGroup = form.getValue('group');
    this.onGroupDropdownChange({ value: '' });
    this.tempSelectedGroup = selectedGroup;
    this.setState({
      isAddingSubscriptionsToGroup: true,
      tempSubscriptionsToAddToGroup: [],
      tempSubscriptionsToRemoveFromGroup: []
    });
  }

  onManageGroupSubscriptionComplete() {
    const { subscriptionsCollection } = this.state;
    this.onGroupDropdownChange({ value: this.tempSelectedGroup });

    const groupSubscriptionIds = this.getGroupAzureSubscriptions(this.tempSelectedGroup);
    this.tempSelectedGroup = null;

    // select every new subscription in this subscription group
    groupSubscriptionIds.forEach((subscriptionId) => {
      const model = subscriptionsCollection.get(subscriptionId);
      if (!model.isSelected) {
        model.select({ multi: true });
      }
    });

    this.setState({
      isAddingSubscriptionsToGroup: false,
      tempSubscriptionsToAddToGroup: [],
      tempSubscriptionsToRemoveFromGroup: []
    });
  }

  changePage(page) {
    const { subscriptionsCollection } = this.state;
    subscriptionsCollection.setPage(page);
    subscriptionsCollection.applyPageChangeFilter();
  }

  async saveGroup(groupName) {
    const { $moduleConfig } = this.props;
    const { tempSubscriptionsToAddToGroup, tempSubscriptionsToRemoveFromGroup } = this.state;

    // add subscription from 'toAddToGroup' and remove 'toRemoveFromGroup' list
    const newSelectedSubscriptions = [...this.selectedSubscriptions, ...tempSubscriptionsToAddToGroup].filter(
      (subscriptionId) => tempSubscriptionsToRemoveFromGroup.includes(subscriptionId) === false
    );

    const messageArr = [];
    if (tempSubscriptionsToAddToGroup.length > 0) {
      messageArr.push(`${tempSubscriptionsToAddToGroup.length} subscription has been added to the group ${groupName}.`);
    }

    if (tempSubscriptionsToRemoveFromGroup.length > 0) {
      messageArr.push(
        `${tempSubscriptionsToRemoveFromGroup.length} subscription has been removed from the group ${groupName}.`
      );
    }

    return $moduleConfig
      .saveModuleConfig({
        module: AZURE_SUBSCRIPTIONS_GROUPS_MODULE_GROUP,
        settings: { ...this.companyAzureSubscriptionGroups, [groupName]: newSelectedSubscriptions }
      })
      .finally(() => {
        this.setState({ displayGroupDialog: false, calloutMessageArr: messageArr });
        this.onManageGroupSubscriptionComplete();
      });
  }

  async createGroup(groupName) {
    const { $moduleConfig } = this.props;
    const { subscriptionsCollection } = this.state;

    const message = this.tempSelectedGroup
      ? `${this.tempSelectedGroup} group has been renamed into ${groupName}.`
      : `Group ${groupName} was successfully created.`;

    return $moduleConfig
      .saveModuleConfig({
        module: AZURE_SUBSCRIPTIONS_GROUPS_MODULE_GROUP,
        settings: {
          ...this.companyAzureSubscriptionGroups,
          [groupName]: this.tempSelectedGroup ? this.getGroupAzureSubscriptions(this.tempSelectedGroup) : []
        }
      })
      .finally(() => {
        this.setState({
          displayGroupDialog: false,
          calloutMessageArr: [message]
        });

        if (this.tempSelectedGroup) {
          this.removeGroup(this.tempSelectedGroup, true);
          this.onGroupDropdownChange({ value: groupName });
          this.tempSelectedGroup = null;
          return;
        }

        // unselect everything only when creating new group
        subscriptionsCollection.unselectAll();
      });
  }

  async removeGroup(groupName, ignoreCalloutMessage = false) {
    const { $moduleConfig } = this.props;

    const companySubscriptionGroups = { ...this.companyAzureSubscriptionGroups };
    delete companySubscriptionGroups[groupName];
    this.onGroupDropdownChange({ value: '' });

    const newState = {
      displayGroupDialog: false
    };
    if (!ignoreCalloutMessage) {
      Object.assign(newState, { calloutMessageArr: [`Group ${groupName} was successfully removed.`] });
    }

    return $moduleConfig
      .saveModuleConfig({
        module: AZURE_SUBSCRIPTIONS_GROUPS_MODULE_GROUP,
        settings: { ...companySubscriptionGroups }
      })
      .finally(() => {
        this.setState(newState);
      });
  }

  getSubscriptionGroupOptions = memoize(
    () => {
      // append stored groups into form dropdown
      const savedSubscriptionGroups = this.companyAzureSubscriptionGroups;
      const { group } = initialFields;

      const subscriptionGroupOptions = Object.keys(savedSubscriptionGroups).map((subscriptionGroup) => ({
        value: subscriptionGroup,
        label: subscriptionGroup
      }));

      return [...group.options, ...subscriptionGroupOptions];
    },
    () => this.companyAzureSubscriptionGroups
  );

  handleRowClick = (model, event) => {
    event.stopPropagation();

    const { isAddingSubscriptionsToGroup, subscriptionsCollection } = this.state;

    if (isAddingSubscriptionsToGroup) {
      return;
    }

    subscriptionsCollection.select(model, { multi: true });
  };

  renderClearMapFilterButton = () => {
    const { sidebar } = parseQueryString(window.location.search);

    if (sidebar) {
      // we're currently filtering the map by "Open in Map" functionality
      // display messaging and a method for clearing the filter
      return (
        <Tooltip
          content={
            <Box width={200}>
              <Text as="div" mb={1}>
                This map is filtered to show only the subscription id associated with the selected entity
              </Text>
              Click here to clear the filter
            </Box>
          }
        >
          <Button
            ml={1}
            intent="primary"
            icon="info-sign"
            onClick={() => (window.location = window.location.pathname)}
            minimal
          />
        </Tooltip>
      );
    }

    return null;
  };

  render() {
    const {
      calloutMessageArr,
      displayGroupDialog,
      subscriptionsCollection,
      subscriptionsPopoverIsOpen,
      isFetchingSubscriptionNames,
      isAddingSubscriptionsToGroup,
      tempSubscriptionsToAddToGroup,
      tempSubscriptionsToRemoveFromGroup
    } = this.state;
    const { pageCount, currentPage } = subscriptionsCollection?.pageState || {};

    return (
      <Popover
        autoFocus={false}
        enforceFocus={false}
        isOpen={subscriptionsPopoverIsOpen}
        onClose={this.onPopoverClose}
        placement="bottom-start"
        content={
          <Suspense
            loading={isFetchingSubscriptionNames}
            fallback={
              <Flex p={2} alignItems="Center" justifyContent="center" width="100%" gap={1}>
                <Spinner size={24} />
                <Text>Fetching Subscriptions from Azure.</Text>
              </Flex>
            }
          >
            <FormComponent
              fields={initialFields}
              options={{ name: 'azure-subscriptions-filter' }}
              model={subscriptionsCollection}
            >
              {({ form }) => (
                <Card display="flex" flexDirection="column" width="750px">
                  <Heading p={2} bg="subnavBackground" level={5} mb={0}>
                    Subscription Filter
                  </Heading>
                  {this.isMaxSubscriptionsSelected && (
                    <Callout textAlign="center" intent="danger" p={1}>
                      <Text as="b">{`Max ${MAX_ALLOWED_SELECTED_SUBSCRIPTIONS} subscriptions are allowed.`}</Text>
                    </Callout>
                  )}
                  <Flex p={1} gap={1} alignItems="center">
                    <Field name="name" width="100%" placeholder="Search Subscriptions...">
                      <Filter
                        collection={subscriptionsCollection}
                        filterCallback={() => {
                          subscriptionsCollection.resetDiscreteFilters();
                          // need to run filter without pagination
                          subscriptionsCollection.filter();

                          // enable pagination again
                          subscriptionsCollection.calculatePageCount();
                          subscriptionsCollection.applyPageChangeFilter();
                        }}
                      />
                    </Field>

                    <Field name="view" onQuery={null} onChange={this.onViewDropdownChange}>
                      <Select small menuWidth={100} />
                    </Field>
                    <Field
                      name="group"
                      disabled={isAddingSubscriptionsToGroup}
                      options={this.getSubscriptionGroupOptions()}
                      onChange={this.onGroupDropdownChange}
                    >
                      <Select small menuWidth={175} />
                    </Field>
                  </Flex>
                  <Flex
                    bg="subnavBackground"
                    px={1}
                    paddingTop="8px"
                    alignItems="center"
                    justifyContent="center"
                    gap={1}
                  >
                    {isAddingSubscriptionsToGroup && (
                      <Flex flexGrow="1" width="100%" px={1} justifyContent="space-between" alignItems="center">
                        <Flex flexDirection="column" gap={1}>
                          <Box>
                            <Text as="b">Manage subscription</Text>
                          </Box>
                          <Box>
                            {tempSubscriptionsToAddToGroup.length === 0 &&
                              tempSubscriptionsToRemoveFromGroup.length === 0 && <Text>{this.tempSelectedGroup}</Text>}
                            {tempSubscriptionsToAddToGroup.length > 0 && (
                              <Text as="p">{`${tempSubscriptionsToAddToGroup.length} subscription id will be added to ${this.tempSelectedGroup}`}</Text>
                            )}
                            {tempSubscriptionsToRemoveFromGroup.length > 0 && (
                              <Text as="p">{`${tempSubscriptionsToRemoveFromGroup.length} subscription id will be removed to ${this.tempSelectedGroup}`}</Text>
                            )}
                          </Box>
                        </Flex>
                        <Flex gap={1}>
                          <Button
                            small
                            intent="primary"
                            text="Save"
                            onClick={() => this.saveGroup(this.tempSelectedGroup)}
                            disabled={
                              tempSubscriptionsToAddToGroup.length === 0 &&
                              tempSubscriptionsToRemoveFromGroup.length === 0
                            }
                          />
                          <Button
                            small
                            intent="info"
                            text="Cancel"
                            onClick={() => this.onManageGroupSubscriptionComplete()}
                          />
                        </Flex>
                      </Flex>
                    )}
                    {!isAddingSubscriptionsToGroup && (
                      <>
                        <Flex justifyContent="center" width="50px" style={{ paddingRight: '6px' }}>
                          <Checkbox
                            checked={subscriptionsCollection.isAllSelected}
                            onChange={() =>
                              subscriptionsCollection.selectAll({
                                multi: true,
                                selectedLimit: MAX_ALLOWED_SELECTED_SUBSCRIPTIONS
                              })
                            }
                          />
                        </Flex>
                        <Flex
                          flexGrow="1"
                          width="100%"
                          py="8px"
                          px={1}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text>{`${subscriptionsCollection.selectedSize} / ${subscriptionsCollection.unfilteredSize} selected`}</Text>
                          {/* Create Group Button */}
                          {form.getValue('group') === '' && (
                            <Flex gap={2}>
                              <Button
                                small
                                intent="primary"
                                text="Create Group"
                                onClick={() => {
                                  this.setState({ displayGroupDialog: true });
                                }}
                              />
                            </Flex>
                          )}
                        </Flex>
                        <Flex px={2}>
                          <Button
                            small
                            onClick={() => {
                              subscriptionsCollection.clearSelection();
                              subscriptionsCollection.filter();
                            }}
                          >
                            Clear
                          </Button>
                        </Flex>
                      </>
                    )}
                  </Flex>
                  {form.getValue('group') !== '' && !isAddingSubscriptionsToGroup && (
                    <Flex px={1} py="8px" alignItems="center" justifyContent="space-between" gap={1}>
                      <Box>
                        <Text as="b">{form.getValue('group')}</Text>
                      </Box>
                      <Flex gap="1">
                        <Button
                          small
                          text="Manage Group Subscriptions"
                          disabled={subscriptionsCollection.selectedSize === 0 && form.getValue('group') === ''}
                          onClick={() => this.onManageGroupSubscriptionClick(form)}
                        />
                        <Button
                          small
                          text="Rename Group"
                          disabled={subscriptionsCollection.selectedSize === 0 && form.getValue('group') === ''}
                          onClick={() => {
                            const selectedGroup = form.getValue('group');
                            this.tempSelectedGroup = selectedGroup;
                            return this.setState({ displayGroupDialog: true });
                          }}
                        />
                        <RemoveButton
                          buttonProps={{ small: true, intent: 'danger', text: 'Remove Group' }}
                          onRemove={() => {
                            const field = form.getField('group');
                            const currentSelectedGroup = form.getValue('group');
                            field.setValue('');
                            this.removeGroup(currentSelectedGroup);
                          }}
                        />
                      </Flex>
                    </Flex>
                  )}
                  <StyledBox p={1}>
                    <VirtualizedTable
                      hideHeader
                      isCollapsed
                      multiSelect
                      onRowClick={this.handleRowClick}
                      style={{ height: 300 }}
                      rowHeight={60}
                      collection={subscriptionsCollection}
                      columns={this.columns}
                    />
                  </StyledBox>
                  {/* Pagination */}
                  {pageCount > 1 && (
                    <Flex justifyContent="space-between" p={1} alignItems="center">
                      <Button onClick={() => this.changePage(currentPage - 1)} disabled={currentPage <= 1}>
                        Previous
                      </Button>
                      <Box flexGrow="1" textAlign="center">
                        <Text>
                          Page {currentPage} of {pageCount}
                        </Text>
                      </Box>
                      <Button onClick={() => this.changePage(currentPage + 1)} disabled={currentPage >= pageCount}>
                        Next
                      </Button>
                    </Flex>
                  )}
                  {calloutMessageArr.length > 0 && (
                    <TimedCallout timeout={5000} intent="success" key={calloutMessageArr.join('-')}>
                      {calloutMessageArr.map((message) => (
                        <Text key={message}>{message}</Text>
                      ))}
                    </TimedCallout>
                  )}

                  <MapSearchGroupDialog
                    isOpen={displayGroupDialog}
                    selectedCount={subscriptionsCollection.selectedSize}
                    groupName={this.tempSelectedGroup}
                    onSubmit={(groupName) => {
                      this.createGroup(groupName).then(() => {
                        // select this group in a form
                        form.setValue('group', groupName);
                        this.onGroupDropdownChange({ value: groupName });
                      });
                    }}
                    onClose={() => this.setState({ displayGroupDialog: false })}
                  />
                </Card>
              )}
            </FormComponent>
          </Suspense>
        }
      >
        <Flex alignItems="center">
          <Button
            rightIcon="caret-down"
            height="35px"
            active={subscriptionsPopoverIsOpen}
            onClick={() =>
              this.setState((prevState) => ({ subscriptionsPopoverIsOpen: !prevState.subscriptionsPopoverIsOpen }))
            }
            small
          >
            <Flex alignItems="center" gap={1} px={1}>
              <Icon color="azure.blue" icon={this.isLoading ? <Spinner size={18} /> : AzureLogo} />
              Subscriptions
              <Tag
                bg={this.selectedSubscriptions?.length === 0 ? 'azure.orange' : 'azure.blue'}
                color="white.10"
                round
                small
              >
                {this.selectedSubscriptions?.length}
              </Tag>
            </Flex>
          </Button>
          {this.renderClearMapFilterButton()}
        </Flex>
      </Popover>
    );
  }
}
