import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, Redirect } from 'react-router-dom';

import { Box, Card, Suspense } from 'core/components';

import KentikLogo from 'app/components/KentikLogo';

import lazyLoad from 'core/util/lazyLoad';
import PasswordSetRequestForm from 'app/views/login/password/PasswordSetRequestForm';
import { LOGIN } from '@kentik/ui-shared/paths/login';

const PasswordSetForm = lazyLoad(() => import('app/views/login/password/PasswordSetForm'));

@inject('$auth', '$app')
@observer
class Password extends Component {
  state = {
    loading: true,
    passwordSetToken: undefined
  };

  constructor(props) {
    super(props);
    const passwordSetToken = this.getSearchParam(props.location, 'token');
    this.state.passwordSetToken = passwordSetToken;
    if (passwordSetToken || !props.$auth.isExpiredPassword) {
      this.state.loading = false;
    }
  }

  componentDidMount() {
    const { $auth, location } = this.props;
    const passwordSetToken = this.getSearchParam(location, 'token');

    if ($auth.isExpiredPassword && !passwordSetToken) {
      $auth
        .requestPasswordReset({ user_email: $auth.getUnderlyingUserProperty('user_email'), skipEmail: true })
        .then(({ passwordResetToken }) => {
          this.setState({ passwordSetToken: passwordResetToken, loading: false });
        });
    }
  }

  getSearchParam(location, name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(location.search);

    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  render() {
    const { passwordSetToken, loading } = this.state;
    const { $app, accountActivation, $auth } = this.props;
    const { subtenancy } = $auth.openConfig;

    // Check for invalid state, somebody is manually messing with urls, send them to login and be done with it.
    if (accountActivation && !loading && !passwordSetToken) {
      return <Redirect to={LOGIN.ENTRY} />;
    }

    return (
      <Suspense loading={loading}>
        <>
          {subtenancy && subtenancy.config && subtenancy.config.icon && (
            <Box textAlign="center" p={3}>
              <img
                alt={subtenancy.subdomain}
                src={`/api/ui/icons/${subtenancy.company_id}`}
                style={{ width: 180, height: 'auto' }}
              />
            </Box>
          )}
          <Card maxWidth={375} minHeight={200} mx="auto" p={3} elevation={4}>
            {(!subtenancy || !subtenancy.config || !subtenancy.config.icon) && (
              <Box textAlign="center" mb={4}>
                <Link to="/">
                  <KentikLogo blackText={!$app.darkThemeEnabled} style={{ width: 200, height: 30 }} />
                </Link>
              </Box>
            )}
            <Box minWidth={300}>
              {!passwordSetToken && <PasswordSetRequestForm />}
              {passwordSetToken && (
                <PasswordSetForm passwordSetToken={passwordSetToken} accountActivation={accountActivation} />
              )}
            </Box>
          </Card>
        </>
      </Suspense>
    );
  }
}

export default Password;
