import * as React from 'react';
import { inject } from 'mobx-react';
import { Box, Flex, Icon, Spinner, Suspense, Text } from 'core/components';
import { toDecimal } from 'core/util';

@inject('$syn')
export default class HealthStrip extends React.Component {
  static defaultProps = {
    tests: []
  };

  state = {
    loading: true,
    healthSummary: undefined
  };

  componentDidMount() {
    const { $syn, tests } = this.props;
    if (tests && tests.length > 0 && !this.isLinkDown) {
      $syn.requests.fetchTestResults({ ids: tests.map((test) => test.id) }, { preset: false }).then((testResults) => {
        const { status } = testResults;
        if (status.ok) {
          const healthSummaries = Object.values(testResults.tests_health).flatMap((testResult) => {
            // grab a timestamp
            const ts = testResult.overall_health.time;

            // get the first task
            const [firstTask] = Object.values(testResult.health_ts[ts].tasks);

            // return all the agent(s) health
            return Object.values(firstTask.agents);
          });

          this.setState({
            loading: false,
            healthSummary: this.getHealthSummary(healthSummaries)
          });
        }
      });
    } else {
      this.setState({ loading: false });
    }
  }

  get isLinkDown() {
    const { link } = this.props;

    return link && link.status && link.status === 'DOWN';
  }

  getHealthWeight = (health) => {
    const healthMap = {
      critical: 1,
      warning: 2,
      healthy: 3
    };

    return healthMap[health] || 0;
  };

  getHealthLabel = (healthWeight) => ['', 'critical', 'warning', 'healthy'][healthWeight || 0];

  getHealthColor = (health) => {
    const colorMap = {
      critical: 'danger',
      warning: 'warning',
      healthy: 'success'
    };

    return colorMap[health] || 'muted';
  };

  getHealthSummary = (data) => {
    const healthData = data || [];

    return healthData.reduce(
      (acc, item) => {
        // roll up the totals
        acc.latency.totalValue += item.avg_latency || 0;
        acc.packetLoss.totalValue += item.packet_loss || 0;
        acc.jitter.totalValue += item.avg_jitter || 0;

        acc.latency.totalHealth += this.getHealthWeight(item.latency_health || 0);
        acc.packetLoss.totalHealth += this.getHealthWeight(item.packet_loss_health || 0);
        acc.jitter.totalHealth += this.getHealthWeight(item.jitter_health || 0);

        // calculate averages
        acc.latency.value = toDecimal(acc.latency.totalValue / data.length / 1000);
        acc.packetLoss.value = toDecimal(acc.packetLoss.totalValue / data.length) * 100;
        acc.jitter.value = toDecimal(acc.jitter.totalValue / data.length / 1000);

        acc.latency.health = this.getHealthLabel(Math.floor(acc.latency.totalHealth / data.length));
        acc.packetLoss.health = this.getHealthLabel(Math.floor(acc.packetLoss.totalHealth / data.length));
        acc.jitter.health = this.getHealthLabel(Math.floor(acc.jitter.totalHealth / data.length));

        return acc;
      },
      {
        latency: {
          totalHealth: 0,
          health: '',
          totalValue: 0,
          value: 0
        },

        packetLoss: {
          totalHealth: 0,
          health: '',
          totalValue: 0,
          value: 0
        },

        jitter: {
          totalHealth: 0,
          health: '',
          totalValue: 0,
          value: 0
        }
      }
    );
  };

  render() {
    const { tests, hasAgents } = this.props;
    const { healthSummary, loading } = this.state;

    if (this.isLinkDown) {
      return (
        <Flex alignItems="center">
          <Icon icon="full-circle" color="danger" iconSize={10} mr={1} />
          <Text small>This link is reporting a down status</Text>
        </Flex>
      );
    }

    if (!hasAgents) {
      return (
        <Text as="div" small>
          Please deploy an agent for the VPC(s) in this path.
        </Text>
      );
    }
    if (tests.length === 0) {
      return (
        <Text as="div" small>
          Please create a test to monitor the health of this path.
        </Text>
      );
    }

    return (
      <Suspense
        loading={loading}
        fallback={
          <Flex alignItems="center" {...this.props}>
            <Spinner size={12} mr={1} />
            <Text small>Loading health</Text>
          </Flex>
        }
      >
        {healthSummary && (
          <Box>
            <Flex alignItems="center">
              <Icon icon="full-circle" color={this.getHealthColor(healthSummary.latency.health)} iconSize={10} mr={1} />
              <Text small>Latency: {healthSummary.latency.value}ms</Text>
            </Flex>

            <Flex alignItems="center">
              <Icon
                icon="full-circle"
                color={this.getHealthColor(healthSummary.packetLoss.health)}
                iconSize={10}
                mr={1}
              />
              <Text small>Packet Loss: {healthSummary.packetLoss.value}%</Text>
            </Flex>

            <Flex alignItems="center">
              <Icon icon="full-circle" color={this.getHealthColor(healthSummary.jitter.health)} iconSize={10} mr={1} />
              <Text small>Jitter: {healthSummary.jitter.value}ms</Text>
            </Flex>
          </Box>
        )}
      </Suspense>
    );
  }
}
