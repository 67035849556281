import * as React from 'react';
import Page from 'app/components/page/Page';
import { Header } from 'app/views/NotPermittedView';

export default function Splash({ children, toolbar, ...rest }) {
  let toolbarComponent = null;

  if (toolbar) {
    toolbarComponent = (
      <Header py={4} pl={3}>
        {toolbar}
      </Header>
    );
  }

  return (
    <Page toolbar={toolbarComponent} {...rest}>
      {children}
    </Page>
  );
}
