import React from 'react';
import { cloneDeep, uniq } from 'lodash';
import { isGoogleCloud, getCustomProperties } from 'shared/util/map';
import $hybridMap from 'app/stores/hybrid/$hybridMap';
import { buildFilterGroup, addFilterGroup } from 'core/util/filters';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { getASNValues } from 'app/util/queryResults';
import {
  ALL_GROUPS_EXCLUDING_CLOUD,
  ALL_GROUPS_CLOUD,
  ALL_KAPPA
} from 'app/views/hybrid/maps/components/trafficCharts/dimensionOptionsHelper';
import {
  getTopLevelQuery,
  getSiteDeviceNodeLinkQuery,
  getDeviceSiteNodeLinkQuery
} from 'app/views/hybrid/maps/components/interSiteTraffic/interSiteTrafficQueryHelper';
import {
  getGatewayIdsAndIpsFromPaths,
  getRelatedGatewayIdsAndIps
} from 'app/views/hybrid/maps/components/popovers/cloudUtil';
import { getTransitGatewayFilterGroup } from 'app/views/hybrid/utils/aws';

import { ReactComponent as KubePodIcon } from 'app/assets/icons/kubernetes/pod.svg';
import { Flex, Icon, Text } from 'core/components';

import {
  AWS,
  GCP,
  OCI,
  AZURE,
  getDimensionFieldName,
  getDimensionFilter
} from 'app/views/hybrid/maps/cloudDimensionConstants';

const { CORE_NETWORK_EDGE, CORE_NETWORK_ATTACHMENT, TRANSIT_GATEWAY_ATTACHMENT, TRANSIT_GATEWAY } =
  ENTITY_TYPES.get('aws');

const internetTypeLabels = {
  asn: 'ASN',
  provider: 'Provider',
  nextHopASN: 'Next-Hop Network'
};

const privateIPAddresses = ['10.0.0.0/8', '172.16.0.0/12', '192.168.0.0/16', '169.254.0.0/16', '127.0.0.0/8'];

function restrictTo({ options, overrides }) {
  return options.map((option) => {
    const { query } = option;

    if (query.inboundQuery && query.outboundQuery) {
      return {
        ...option,
        query: {
          inboundQuery: {
            ...query.inboundQuery,
            ...overrides
          },
          outboundQuery: {
            ...query.outboundQuery,
            ...overrides
          }
        }
      };
    }

    return {
      ...option,
      query: {
        ...query,
        ...overrides
      }
    };
  });
}

export function getInternetDimension({ subType, direction = 'src|dst' }) {
  const fieldMap = {
    asn: `AS_${direction}`,
    provider: `i_${direction}_provider_classification`,
    nextHopASN: `${direction}_nexthop_asn`
  };

  return fieldMap[subType];
}

export function getInternetFilterField({ subType, direction = 'src|dst' }) {
  const fieldMap = {
    asn: `${direction}_as`,
    provider: `i_${direction}_provider_classification`,
    nextHopASN: `${direction}_nexthop_as`
  };

  return fieldMap[subType];
}

function getInternetFilterGroup({ subType, id, direction, boundary = true }) {
  return buildFilterGroup({
    filterGroups: [
      buildFilterGroup({
        filters: [
          {
            filterField: getInternetFilterField({ subType, direction }),
            operator: '=',
            filterValue: id
          },

          boundary && {
            filterField: `i_${direction}_network_bndry_name`,
            operator: '=',
            filterValue: 'external'
          }
        ].filter(Boolean)
      })
    ]
  });
}

function getInternetTargetsAnyMatchingFilterGroup({ targets, direction }) {
  return buildFilterGroup({
    connector: 'Any',
    filters: targets.map((target) => ({
      filterField: getInternetFilterField({ subType: target.subType, direction }),
      operator: '=',
      filterValue: target.id
    }))
  });
}

function getInternetTargetsFilterGroup({ targets, direction }) {
  return buildFilterGroup({
    filterGroups: [
      getInternetTargetsAnyMatchingFilterGroup({ targets, direction }),
      buildFilterGroup({
        filters: [
          {
            filterField: `i_${direction}_network_bndry_name`,
            operator: '=',
            filterValue: 'external'
          }
        ]
      })
    ]
  });
}

export function getInfrastructureDimension({ subType, direction = 'src|dst' }) {
  const fieldMap = {
    services: `${direction}_proto_port`,
    ips: `IP_${direction}`
  };

  return fieldMap[subType];
}

function getServicePort(value) {
  return (value || '').toString().match(/\(([0-9]+)\/.+\)/)?.[1] || '';
}

function getServiceProto(value) {
  return (value || '').toString().match(/\([0-9]+\/(\S*)\)/)?.[1] || '';
}

function getIPAddress(value) {
  return (value || '').toString().match(/(\S+)(?:\s+\()?/)?.[1] || '';
}

export function getInfrastructureFilterGroup({ subType, value, direction = 'src|dst' }) {
  return buildFilterGroup({
    filterGroups: [
      buildFilterGroup({
        filters:
          subType === 'services'
            ? [
                { filterField: `l4_${direction}_port`, operator: '=', filterValue: getServicePort(value) },
                { filterField: 'i_protocol_name', operator: '=', filterValue: getServiceProto(value) }
              ]
            : [{ filterField: `inet_${direction}_addr`, operator: '=', filterValue: getIPAddress(value) }]
      })
    ]
  });
}

function getExternalTrafficFilterGroup({ direction }) {
  return buildFilterGroup({
    filterGroups: [
      buildFilterGroup({
        filters: [
          {
            filterField: `i_${direction}_network_bndry_name`,
            operator: '=',
            filterValue: 'external'
          }
        ]
      })
    ]
  });
}

function getPodToOtherPodsFilters(podId) {
  return buildFilterGroup({
    filterGroups: [
      buildFilterGroup({
        filters: [
          {
            filterField: 'ktsubtype__kappa__STR16',
            operator: '=',
            filterValue: 'pod'
          },
          {
            filterField: 'ktsubtype__kappa__STR10',
            operator: '=',
            filterValue: 'pod'
          }
        ]
      }),
      buildFilterGroup({
        filters: [
          {
            filterField: 'ktsubtype__kappa__STR08',
            operator: '=',
            filterValue: podId
          }
        ]
      })
    ]
  });
}

function getOtherPodsToPodFilters(destPodId) {
  return buildFilterGroup({
    filterGroups: [
      buildFilterGroup({
        filters: [
          {
            filterField: 'ktsubtype__kappa__STR10',
            operator: '=',
            filterValue: 'pod'
          }
        ]
      }),
      buildFilterGroup({
        filters: [
          {
            filterField: 'ktsubtype__kappa__STR14',
            operator: '=',
            filterValue: destPodId
          }
        ]
      })
    ]
  });
}

export function getKubeInternetFilterGroup({ direction }) {
  return {
    connector: 'Any',
    not: true,
    filters: [{ filterField: `inet_${direction}_addr`, operator: 'ILIKE', filterValue: privateIPAddresses.join(',') }]
  };
}

export function getKubeInfrastructureFilterGroup({ direction }) {
  const filterField = {
    src: 'i_trf_origination',
    dst: 'i_trf_termination',
    'src|dst': 'i_trf_origination|termination'
  };
  const filterGroup = getKubeInternetFilterGroup({ direction });
  filterGroup.filters.push({ filterField: filterField[direction], operator: '=', filterValue: 'inside' });
  return filterGroup;
}

/**
 * @param {{ entity?: Object, direction?: string, internet?: boolean, infrastructure?: boolean }} options
 */
export function getKubeFilterGroup({ entity, direction = 'src|dst', internet = false, infrastructure = false }) {
  const filterFields = {
    objectType: { src: 'ktsubtype__kappa__STR10', dst: 'ktsubtype__kappa__STR16' },
    namespaces: { src: 'ktsubtype__kappa__STR09', dst: 'ktsubtype__kappa__STR15' },
    services: { src: 'ktsubtype__kappa__STR08', dst: 'ktsubtype__kappa__STR14' },
    ingresses: { src: '', dst: '' }, // TODO
    deployments: { src: 'ktsubtype__kappa__STR12', dst: 'ktsubtype__kappa__STR18' },
    pods: { src: 'ktsubtype__kappa__STR08', dst: 'ktsubtype__kappa__STR14' },
    nodes: { src: 'ktsubtype__kappa__STR06', dst: 'ktsubtype__kappa__STR07' }
  };

  return {
    connector: 'Any',
    filterGroups: direction.split('|').map((dir) => {
      const otherDir = dir === 'src' ? 'dst' : 'src';
      const filters = [];
      const filterGroups = [];

      if (entity?.ips) {
        filters.push({ filterField: `inet_${dir}_addr`, operator: 'ILIKE', filterValue: entity.ips.join(',') });
      }

      if (entity?.namespace) {
        filters.push({
          filterField: filterFields.namespaces[dir],
          operator: '=',
          filterValue: entity.namespace
        });
      }

      if (entity?.type === 'deployments' && entity.cloudProvider === 'aws') {
        // temporary workaround for missing workload
        filters.push({ filterField: filterFields.pods[dir], operator: 'ILIKE', filterValue: entity.name });
      } else if (['namespaces', 'services', 'ingresses', 'deployments', 'pods', 'nodes'].includes(entity?.type)) {
        filters.push({
          filterField: filterFields[entity.type][dir],
          operator: '=',
          filterValue: entity.name
        });
      }

      // TODO ingresses
      if (entity?.type === 'deployments' || entity?.type === 'pods') {
        filters.push({ filterField: filterFields.objectType[dir], operator: '=', filterValue: 'pod' });
      } else if (entity?.type === 'services') {
        filters.push({ filterField: filterFields.objectType[dir], operator: '=', filterValue: 'service' });
      }

      if (internet) {
        filterGroups.push(getKubeInternetFilterGroup({ direction: otherDir }));
      }

      if (infrastructure) {
        filterGroups.push(getKubeInfrastructureFilterGroup({ direction: otherDir }));
      }

      return { connector: 'All', filterGroups, filters };
    })
  };
}

function getInterfaceFilterGroup({ inf, direction }) {
  return buildFilterGroup({
    filterGroups: [
      buildFilterGroup({
        filters: [
          {
            filterField: 'i_device_name',
            operator: '=',
            filterValue: inf.device_name
          },

          {
            filterField: `${direction}_port`,
            operator: '=',
            filterValue: (inf.snmp_id || 0).toString()
          }
        ]
      })
    ]
  });
}

export function getInterfacesFilterGroup({ interfaces, direction }) {
  return buildFilterGroup({
    connector: 'Any',
    filterGroups: interfaces.map((inf) =>
      buildFilterGroup({
        filters: [
          {
            filterField: 'i_device_name',
            operator: '=',
            filterValue: inf.device_name
          },

          {
            filterField: `${direction}_port`,
            operator: '=',
            filterValue: (inf.snmp_id || 0).toString()
          }
        ]
      })
    )
  });
}

function getCloudRegionFilterField({ cloudProvider, direction = 'src|dst' }) {
  return getDimensionFieldName({ cloudProvider, direction, type: 'region' });
}

export function getCloudVPCVNetFilterField({ cloudProvider, direction = 'src|dst' }) {
  const fieldMap = {
    aws: getDimensionFieldName({ cloudProvider: 'aws', direction, type: 'vpc_id' }),
    azure: getDimensionFieldName({ cloudProvider: 'azure', direction, type: 'vnet_id' }),
    gcp: getDimensionFieldName({ cloudProvider: 'gcp', direction, type: 'vpc_name' }),
    oci: getDimensionFieldName({ cloudProvider: 'oci', direction, type: 'vcn' })
  };

  return fieldMap[cloudProvider] || '';
}

export function getCloudSubnetFilterField({ cloudProvider, direction = 'src|dst' }) {
  const fieldMap = {
    aws: getDimensionFieldName({ cloudProvider: 'aws', direction, type: 'subnet_id' }),
    azure: getDimensionFieldName({ cloudProvider: 'azure', direction, type: 'subnet_name' }),
    gcp: getDimensionFieldName({ cloudProvider: 'gcp', direction, type: 'subnet_name' }),
    oci: getDimensionFieldName({ cloudProvider: 'oci', direction, type: 'subnet' })
  };

  return fieldMap[cloudProvider] || '';
}

function getCloudSubnetFilters({ cloudProvider, direction = 'src|dst', operator = '=', subnetValue, subnetEntity }) {
  const filters = [
    {
      filterField: getCloudSubnetFilterField({ cloudProvider, direction }),
      operator,
      filterValue: subnetValue
    }
  ];

  if (cloudProvider === 'gcp') {
    // gcp subnet filters require more specific filters (subnet, network, and region)
    const { network, region } = getCustomProperties(subnetEntity);

    if (network) {
      filters.push({
        filterField: getDimensionFieldName({ cloudProvider, direction, type: 'vpc_name' }),
        operator,
        filterValue: network
      });
    }

    if (region) {
      filters.push({
        filterField: getDimensionFieldName({ cloudProvider, direction, type: 'region' }),
        operator,
        filterValue: region
      });
    }
  }

  return filters;
}

function getCloudRegionFilters({ cloudProvider, direction = 'src|dst', operator = '=', regionValue, network }) {
  const filters = [
    {
      filterField: getCloudRegionFilterField({ cloudProvider, direction }),
      operator,
      filterValue: regionValue
    }
  ];

  if (cloudProvider === 'gcp' && network) {
    // gcp region filters require more specific filters (region and network)
    filters.push({
      filterField: getDimensionFieldName({ cloudProvider, direction, type: 'vpc_name' }),
      operator,
      filterValue: network
    });
  }

  return filters;
}

export function getCloudGatewayFilterField({ cloudProvider }) {
  const strFieldMap = {
    aws: { dst: AWS.DST.GATEWAY_ID }
  };

  if (cloudProvider in strFieldMap) {
    return strFieldMap[cloudProvider].dst;
  }

  return null;
}

function getCloudRegionInternalTrafficQuery({ cloudProvider, name, value, direction = 'src|dst', network }) {
  return {
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...getCloudRegionFilters({ cloudProvider, direction, regionValue: value, network }),
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'cloud internal'
            }
          ]
        })
      ]
    },
    query_title: `Internal Traffic With ${name} ${value}`,
    renderOptions: { source: 'Internal Traffic' }
  };
}

function getCloudRegionOtherCloudTrafficQuery({ cloudProvider, name, value, direction = 'src|dst', network }) {
  return {
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...getCloudRegionFilters({ cloudProvider, direction, regionValue: value, network }),
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'multi-cloud'
            }
          ]
        })
      ]
    },
    query_title: `${name} ${value} Traffic With Other Clouds`,
    renderOptions: { source: 'Traffic With Other Clouds' }
  };
}

function getCloudVPCVNetInternalTrafficQuery({ cloudProvider, name, value, direction = 'src|dst' }) {
  return {
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            {
              filterField: getCloudVPCVNetFilterField({ cloudProvider, direction }),
              operator: '=',
              filterValue: value
            },
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'cloud internal'
            }
          ]
        })
      ]
    },
    query_title: `Internal Traffic With ${name} ${value}`,
    renderOptions: { source: 'Internal Traffic' }
  };
}

function getCloudVPCVNetOtherCloudTrafficQuery({ cloudProvider, name, value, direction = 'src|dst' }) {
  return {
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            {
              filterField: getCloudVPCVNetFilterField({ cloudProvider, direction }),
              operator: '=',
              filterValue: value
            },

            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'multi-cloud'
            }
          ]
        })
      ]
    },
    query_title: `${name} ${value} Traffic With Other Clouds`,
    renderOptions: { source: 'Traffic With Other Clouds' }
  };
}

function getCloudSubnetInternalTrafficQuery({ cloudProvider, name, value, direction = 'src|dst', subnetEntity }) {
  return {
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...getCloudSubnetFilters({ cloudProvider, direction, subnetValue: value, subnetEntity }),
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'cloud internal'
            }
          ]
        })
      ]
    },
    query_title: `Internal Traffic With ${name} ${value}`,
    renderOptions: { source: 'Internal Traffic' }
  };
}

function getCloudSubnetOtherCloudTrafficQuery({ cloudProvider, name, value, direction = 'src|dst', subnetEntity }) {
  return {
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...getCloudSubnetFilters({ cloudProvider, direction, subnetValue: value, subnetEntity }),

            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'multi-cloud'
            }
          ]
        })
      ]
    },
    query_title: `${name} ${value} Traffic With Other Clouds`,
    renderOptions: { source: 'Traffic With Other Clouds' }
  };
}

export function getInternetPopoverQueryOptions({ subType, id, value, detail }) {
  const inboundFilter = getInternetFilterGroup({ subType, id, direction: 'src' });
  const outboundFilter = getInternetFilterGroup({ subType, id, direction: 'dst' });
  const isSiteDetail = detail && detail.type === 'site';
  const isDeviceDetail = detail && detail.type === 'device';
  const isCloudDetail = detail && detail.type === 'cloud';

  let dimensions = ALL_GROUPS_EXCLUDING_CLOUD;

  if (detail && detail.type === 'cloud') {
    dimensions = { ...ALL_GROUPS_CLOUD, cloudProvider: detail.cloudProvider };
  }

  let options = [
    {
      label: 'All Traffic',
      value: 'all',
      query: {
        filters: buildFilterGroup({
          filterGroups: [
            buildFilterGroup({
              filters: [
                {
                  filterField: getInternetFilterField({ subType, direction: 'src|dst' }),
                  operator: '=',
                  filterValue: id
                }
              ]
            })
          ]
        }),
        query_title: `All ${value} Traffic`,
        renderOptions: { dimensions }
      }
    },
    {
      label: 'Inbound/Outbound Traffic',
      value: 'total',
      query: {
        inboundQuery: {
          query_title: `Traffic To ${value}`,
          filters: inboundFilter,
          renderOptions: { source: 'Inbound', dimensions: { ...dimensions, direction: 'src' } }
        },

        outboundQuery: {
          query_title: `Traffic From ${value}`,
          filters: outboundFilter,
          renderOptions: { source: 'Outbound', dimensions: { ...dimensions, direction: 'dst' } }
        }
      }
    }
  ];

  if (isSiteDetail) {
    options = restrictTo({
      options,
      overrides: {
        all_devices: false,
        device_sites: [detail.model.get('id')]
      }
    });
  }

  if (isDeviceDetail) {
    options = restrictTo({
      options,
      overrides: {
        all_devices: false,
        device_name: [detail.model.get('device_name')]
      }
    });
  }

  if (isCloudDetail) {
    options = restrictTo({
      options,
      overrides: {
        all_devices: false,
        device_types: [`${detail.cloudProvider}_subnet`]
      }
    });
  }

  return $hybridMap.mergeSidebarQueryOptions(options);
}

function getConnectedSitesQueries({ source, target, links }) {
  // get all links between our target site and others
  const siteLinks = links.filter((link) => {
    const hasSiteLink = !!link.site1_id && !!link.site2_id;
    const hasSourceSite = link.site1_id === source || link.site2_id === source;
    const isMatch = hasSiteLink && hasSourceSite;

    if (target) {
      return isMatch && (link.site1_id === target || link.site2_id === target);
    }

    return isMatch;
  });

  const devicesAndFilters = siteLinks.reduce(
    (acc, link) => {
      const connections = link.connections || [];

      connections.forEach((connection) => {
        // assuming first interface is the source
        let sourceInterface = connection.interface1;
        let targetInterface = connection.interface2;

        if (connection.interface2.site_id === source) {
          // flip if necessary
          sourceInterface = connection.interface2;
          targetInterface = connection.interface1;
        }

        // add to the inbound filters
        acc.inboundFilter.filterGroups.push(
          buildFilterGroup({
            filters: [
              {
                filterField: 'input_port',
                operator: '=',
                filterValue: (sourceInterface.snmp_id || 0).toString()
              },

              {
                filterField: 'i_device_name',
                operator: '=',
                filterValue: sourceInterface.device_name
              }
            ]
          })
        );

        // add the filter for the target interface
        acc.outboundFilter.filterGroups.push(
          buildFilterGroup({
            filters: [
              {
                filterField: 'input_port',
                operator: '=',
                filterValue: (targetInterface.snmp_id || 0).toString()
              },

              {
                filterField: 'i_device_name',
                operator: '=',
                filterValue: targetInterface.device_name
              }
            ]
          })
        );
      });

      return acc;
    },
    {
      inboundFilter: { connector: 'Any', filterGroups: [] },
      outboundFilter: { connector: 'Any', filterGroups: [] }
    }
  );

  if (
    devicesAndFilters.inboundFilter.filterGroups.length > 0 &&
    devicesAndFilters.outboundFilter.filterGroups.length > 0
  ) {
    return {
      inboundQuery: {
        all_devices: true,
        show_overlay: true,
        show_total_overlay: true,
        filters: devicesAndFilters.inboundFilter
      },

      outboundQuery: {
        all_devices: true,
        show_overlay: true,
        show_total_overlay: true,
        filters: devicesAndFilters.outboundFilter
      }
    };
  }

  return null;
}

export function getSitePopoverQueryOptions({ model }) {
  const siteName = model.get('title');

  const externalTrafficInboundQuery = {
    all_devices: false,
    device_sites: [model.get('id')],
    filters: getExternalTrafficFilterGroup({ direction: 'src' })
  };

  const externalTrafficOutboundQuery = {
    all_devices: false,
    device_sites: [model.get('id')],
    filters: getExternalTrafficFilterGroup({ direction: 'dst' })
  };

  const dimensions = ALL_GROUPS_EXCLUDING_CLOUD;
  const connectedSitesQueries = getConnectedSitesQueries({
    source: model.get('id'),
    links: $hybridMap.normalizedSiteLinks
  });

  let options = [
    {
      label: 'All Traffic',
      value: 'all',
      query: {
        all_devices: false,
        device_sites: [model.get('id')],
        query_title: `All ${siteName} Traffic`,
        renderOptions: { dimensions }
      }
    },
    {
      label: 'External Traffic',
      value: 'total',
      query: {
        inboundQuery: {
          ...externalTrafficInboundQuery,
          query_title: `From Outside To ${siteName}`,
          renderOptions: { source: 'Outside', target: siteName, dimensions: { ...dimensions, direction: 'src' } }
        },
        outboundQuery: {
          ...externalTrafficOutboundQuery,
          query_title: `From ${siteName} To Outside`,
          renderOptions: { source: siteName, target: 'Outside', dimensions: { ...dimensions, direction: 'dst' } }
        }
      }
    }
  ];

  if (connectedSitesQueries) {
    const { inboundQuery, outboundQuery } = connectedSitesQueries;

    options = options.concat([
      {
        label: 'Connected Sites',
        value: 'connectedSites',
        query: {
          inboundQuery: {
            ...inboundQuery,
            metric: ['i_device_site_name'],
            query_title: `Traffic From Sites Connected To ${siteName}`,
            renderOptions: {
              source: 'Connected Sites',
              target: siteName,
              dimensions: { ...dimensions, direction: 'dst' }
            }
          },
          outboundQuery: {
            ...outboundQuery,
            metric: ['i_device_site_name'],
            query_title: `Traffic From ${siteName} To Connected Sites`,
            renderOptions: {
              source: siteName,
              target: 'Connected Sites',
              dimensions: { ...dimensions, direction: 'src' }
            }
          }
        }
      }
    ]);
  }

  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getSiteDetailPopoverQueryOptions({ model, links, detail }) {
  const siteName = model.get('title');
  const siteId = model.get('id');
  const siteDetailName = detail.model.get('title');
  const dimensions = ALL_GROUPS_EXCLUDING_CLOUD;
  const allTrafficInterfaces = links.reduce((acc, link) => {
    if (link.site1_id === siteId || link.site2_id === siteId) {
      const allInterfaces = link.connections.map((connection) => [connection.interface1, connection.interface2]).flat();
      return acc.concat(allInterfaces);
    }

    return acc;
  }, []);
  let options = [];

  if (links && links.length > 0) {
    const connectedSitesQueries = getConnectedSitesQueries({
      source: model.get('id'),
      target: detail.model.get('id'),
      links
    });

    if (connectedSitesQueries) {
      const { inboundQuery, outboundQuery } = connectedSitesQueries;

      options = options.concat([
        {
          label: 'Bidirectional Traffic',
          value: 'total',
          query: {
            inboundQuery: {
              ...inboundQuery,
              query_title: `Traffic To ${siteName}`,
              renderOptions: { source: siteDetailName, target: siteName, dimensions }
            },
            outboundQuery: {
              ...outboundQuery,
              query_title: `Traffic From ${siteName}`,
              renderOptions: { source: siteName, target: siteDetailName, dimensions }
            }
          }
        }
      ]);
    }
  }

  options = options.concat({
    label: 'All Traffic',
    value: 'all',
    query: {
      filters: getInterfacesFilterGroup({ interfaces: allTrafficInterfaces, direction: 'input|output' }),
      query_title: `All ${siteName} Traffic`,
      renderOptions: { dimensions }
    }
  });

  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getKubeToKubeLinkPopoverQueryOptions({ source, target, deviceName }) {
  const inboundFilterGroups = [];
  const outboundFilterGroups = [];
  let sourceName;
  let targetName;

  if (source.nodeData?.id) {
    sourceName = source.nodeData.name;
    inboundFilterGroups.push(
      getKubeFilterGroup({
        entity: source.nodeData,
        direction: 'src',
        internet: target.type === 'internet',
        infrastructure: target.type === 'infrastructure'
      })
    );
    outboundFilterGroups.push(
      getKubeFilterGroup({
        entity: source.nodeData,
        direction: 'dst',
        internet: target.type === 'internet',
        infrastructure: target.type === 'infrastructure'
      })
    );
  } else if (source.type === 'internet') {
    const { asnList } = getASNValues(source.value);
    sourceName = source.value;
    inboundFilterGroups.push(
      getInternetFilterGroup({ subType: source.subType, direction: 'src', id: asnList, boundary: false })
    );
    outboundFilterGroups.push(
      getInternetFilterGroup({ subType: source.subType, direction: 'dst', id: asnList, boundary: false })
    );
  } else if (source.type === 'infrastructure') {
    sourceName = source.value;
    inboundFilterGroups.push(
      getInfrastructureFilterGroup({ subType: source.subType, direction: 'src', value: source.value })
    );
    outboundFilterGroups.push(
      getInfrastructureFilterGroup({ subType: source.subType, direction: 'dst', value: source.value })
    );
  } else if (source.value === 'infrastructure') {
    sourceName = 'Other Infrastructure';
    inboundFilterGroups.push(getKubeInfrastructureFilterGroup({ direction: 'src' }));
    outboundFilterGroups.push(getKubeInfrastructureFilterGroup({ direction: 'dst' }));
  } else {
    sourceName = 'Internet';
    inboundFilterGroups.push(getKubeInternetFilterGroup({ direction: 'src' }));
    outboundFilterGroups.push(getKubeInternetFilterGroup({ direction: 'dst' }));
  }

  if (target.nodeData?.id) {
    targetName = target.nodeData.name;
    inboundFilterGroups.push(
      getKubeFilterGroup({
        entity: target.nodeData,
        direction: 'dst',
        internet: source.type === 'internet',
        infrastructure: source.type === 'infrastructure'
      })
    );
    outboundFilterGroups.push(
      getKubeFilterGroup({
        entity: target.nodeData,
        direction: 'src',
        internet: source.type === 'internet',
        infrastructure: source.type === 'infrastructure'
      })
    );
  } else if (target.type === 'internet') {
    const { asnList } = getASNValues(target.value);
    targetName = target.value;
    inboundFilterGroups.push(
      getInternetFilterGroup({ subType: target.subType, direction: 'dst', id: asnList, boundary: false })
    );
    outboundFilterGroups.push(
      getInternetFilterGroup({ subType: target.subType, direction: 'src', id: asnList, boundary: false })
    );
  } else if (target.type === 'infrastructure') {
    targetName = target.value;
    inboundFilterGroups.push(
      getInfrastructureFilterGroup({ subType: target.subType, direction: 'dst', value: target.value })
    );
    outboundFilterGroups.push(
      getInfrastructureFilterGroup({ subType: target.subType, direction: 'src', value: target.value })
    );
  } else if (target.value === 'infrastructure') {
    targetName = 'Other Infrastructure';
    inboundFilterGroups.push(getKubeInfrastructureFilterGroup({ direction: 'dst' }));
    outboundFilterGroups.push(getKubeInfrastructureFilterGroup({ direction: 'src' }));
  } else {
    targetName = 'Internet';
    inboundFilterGroups.push(getKubeInternetFilterGroup({ direction: 'dst' }));
    outboundFilterGroups.push(getKubeInternetFilterGroup({ direction: 'src' }));
  }

  const baseQuery = {
    all_devices: false,
    device_name: [deviceName || source.nodeData?.device_name || target.nodeData?.device_name]
  };

  const options = [
    {
      label: `${sourceName} to ${targetName}`,
      value: 'traffic',
      query: {
        inboundQuery: {
          ...baseQuery,
          query_title: `From ${sourceName} to ${targetName}`,
          renderOptions: { source: sourceName, target: targetName, dimensions: ALL_KAPPA },
          filters: {
            connector: 'All',
            filterGroups: inboundFilterGroups
          }
        },
        outboundQuery: {
          ...baseQuery,
          query_title: `From ${targetName} to ${sourceName}`,
          renderOptions: { source: targetName, target: sourceName, dimensions: ALL_KAPPA },
          filters: {
            connector: 'All',
            filterGroups: outboundFilterGroups
          }
        }
      }
    }
  ];

  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getSiteToSiteLinkPopoverQueryOptions({ source, target, links }) {
  const sourceSiteName = source.model?.get('title') || source.value;
  const targetSiteName = target.model?.get('title') || target.value;
  const queries = getConnectedSitesQueries({
    source: source.id,
    target: target.id,
    links
  });
  const dimensions = ALL_GROUPS_EXCLUDING_CLOUD;

  if ($hybridMap.interSiteTrafficEnabled) {
    const trafficType = $hybridMap.settingsModel.get('interSiteTraffic');

    return $hybridMap.mergeSidebarQueryOptions([
      {
        label: 'Inter-Site Traffic',
        value: 'total',
        query: {
          inboundQuery: {
            ...getTopLevelQuery(trafficType, { source: sourceSiteName, target: targetSiteName, isPopoverQuery: true }),
            renderOptions: { source: sourceSiteName, target: targetSiteName, dimensions }
          },
          outboundQuery: {
            ...getTopLevelQuery(trafficType, { source: targetSiteName, target: sourceSiteName, isPopoverQuery: true }),
            renderOptions: { source: targetSiteName, target: sourceSiteName, dimensions }
          }
        }
      }
    ]);
  }

  if (queries) {
    return $hybridMap.mergeSidebarQueryOptions([
      {
        label: `${sourceSiteName} <-> ${targetSiteName}`,
        value: 'total',
        query: {
          inboundQuery: {
            ...queries.inboundQuery,
            query_title: `Traffic From ${targetSiteName} To ${sourceSiteName}`,
            renderOptions: { source: targetSiteName, target: sourceSiteName, dimensions }
          },
          outboundQuery: {
            ...queries.outboundQuery,
            query_title: `Traffic From ${sourceSiteName} To ${targetSiteName}`,
            renderOptions: { source: sourceSiteName, target: targetSiteName, dimensions }
          }
        }
      }
    ]);
  }

  return null;
}

export function getCloudToCloudLinkPopoverQueryOptions({ source, target }) {
  const sourceCloudProvider = source.value;
  const targetCloudProvider = target.value;

  const dimensions = { ...ALL_GROUPS_CLOUD };

  const queries = {
    inboundQuery: {
      all_devices: true,
      show_overlay: true,
      show_total_overlay: true,
      filters: {
        connector: 'All',
        filterGroups: [
          buildFilterGroup({
            filters: [
              {
                filterField: 'i_trf_origination',
                operator: '=',
                filterValue: targetCloudProvider
              },
              {
                filterField: 'i_trf_termination',
                operator: '=',
                filterValue: sourceCloudProvider
              }
            ]
          })
        ]
      }
    },

    outboundQuery: {
      all_devices: true,
      show_overlay: true,
      show_total_overlay: true,
      filters: {
        connector: 'All',
        filterGroups: [
          buildFilterGroup({
            filters: [
              {
                filterField: 'i_trf_origination',
                operator: '=',
                filterValue: sourceCloudProvider
              },
              {
                filterField: 'i_trf_termination',
                operator: '=',
                filterValue: targetCloudProvider
              }
            ]
          })
        ]
      }
    }
  };

  if (queries) {
    return $hybridMap.mergeSidebarQueryOptions([
      {
        label: `${sourceCloudProvider} <-> ${targetCloudProvider}`,
        value: 'total',
        query: {
          inboundQuery: {
            ...queries.inboundQuery,
            query_title: `Traffic From ${sourceCloudProvider} To ${targetCloudProvider}`,
            renderOptions: { source: targetCloudProvider, target: sourceCloudProvider, dimensions }
          },
          outboundQuery: {
            ...queries.outboundQuery,
            query_title: `Traffic From ${sourceCloudProvider} To ${targetCloudProvider}`,
            renderOptions: { source: sourceCloudProvider, target: targetCloudProvider, dimensions }
          }
        }
      }
    ]);
  }

  return null;
}
export function getCloudPopoverQueryOptions({ code, name }) {
  const cloudFilterValue = code?.toLowerCase();
  const dimensions = { ...ALL_GROUPS_CLOUD, cloudProvider: cloudFilterValue };

  const options = [
    {
      label: 'All Traffic',
      value: 'all',
      query: {
        all_devices: false,
        device_types: [`${cloudFilterValue}_subnet`],
        query_title: `All ${name} Traffic`,
        renderOptions: { dimensions }
      }
    },
    {
      label: 'Inbound/Outbound Traffic',
      value: 'total',
      query: {
        inboundQuery: {
          filters: {
            connector: 'All',
            filterGroups: [
              buildFilterGroup({
                filters: [
                  {
                    filterField: 'i_trf_origination',
                    operator: '=',
                    filterValue: cloudFilterValue
                  }
                ]
              })
            ]
          },
          query_title: `Total Traffic From ${name}`,
          renderOptions: { source: 'Inbound', showResultsTable: false, dimensions: { ...dimensions, direction: 'src' } }
        },
        outboundQuery: {
          filters: {
            connector: 'All',
            filterGroups: [
              buildFilterGroup({
                filters: [
                  {
                    filterField: 'i_trf_termination',
                    operator: '=',
                    filterValue: cloudFilterValue
                  }
                ]
              })
            ]
          },
          query_title: `Total Traffic To ${name}`,
          renderOptions: { source: 'Outbound', dimensions: { ...dimensions, direction: 'dst' } }
        }
      }
    },

    {
      label: 'Traffic With My Infrastructure',
      value: 'myInfrastructure',
      query: {
        inboundQuery: {
          query_title: `Traffic From ${code} To My Infrastructure`,
          filters: {
            connector: 'All',
            filterGroups: [
              buildFilterGroup({
                filters: [
                  {
                    filterField: 'i_trf_origination',
                    operator: '=',
                    filterValue: cloudFilterValue
                  }
                ]
              })
            ]
          },
          renderOptions: {
            source: code,
            target: 'On Prem',
            showResultsTable: false,
            dimensions: { ...dimensions, direction: 'src' }
          }
        },
        outboundQuery: {
          query_title: `Traffic From My Infrastructure To ${code}`,
          filters: {
            connector: 'All',
            filterGroups: [
              buildFilterGroup({
                filters: [
                  {
                    filterField: 'i_trf_termination',
                    operator: '=',
                    filterValue: cloudFilterValue
                  }
                ]
              })
            ]
          },
          renderOptions: { source: 'On Prem', target: code, dimensions: { ...dimensions, direction: 'dst' } }
        }
      }
    },

    {
      label: 'Traffic With The Internet',
      value: 'internet',
      query: {
        inboundQuery: {
          query_title: `Traffic From The Internet To ${code}`,
          filters: {
            connector: 'All',
            filterGroups: [
              buildFilterGroup({
                filters: [
                  {
                    filterField: 'i_trf_termination',
                    operator: '=',
                    filterValue: cloudFilterValue
                  }
                ]
              })
            ]
          },
          renderOptions: { source: 'The Internet', target: code, dimensions: { ...dimensions, direction: 'dst' } }
        },
        outboundQuery: {
          query_title: `Traffic From ${code} To The Internet`,
          filters: {
            connector: 'All',
            filterGroups: [
              buildFilterGroup({
                filters: [
                  {
                    filterField: 'i_trf_origination',
                    operator: '=',
                    filterValue: cloudFilterValue
                  }
                ]
              })
            ]
          },
          renderOptions: { source: code, target: 'The Internet', dimensions: { ...dimensions, direction: 'src' } }
        }
      }
    },

    {
      label: 'Internal Traffic',
      value: 'internalTraffic',
      query: {
        filters: {
          connector: 'All',
          filterGroups: [
            buildFilterGroup({
              filters: [
                {
                  filterField: 'kt_cloud_provider',
                  operator: '=',
                  filterValue: code
                }
              ]
            })
          ]
        },
        query_title: `${name} Internal Traffic`,
        renderOptions: { source: 'Internal Traffic', dimensions }
      }
    }
  ];

  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getCloudRegionPopoverQueryOptions({ cloudProvider, name, value, nodeData }) {
  const deviceType = `${cloudProvider}_subnet`;
  const baseQuery = {
    all_devices: false,
    device_types: [deviceType]
  };
  const dimensions = { ...ALL_GROUPS_CLOUD, cloudProvider };
  // find the network name in the region's selectId (for gcp)
  const [, network] = nodeData?.selectId?.match(/\|network:(.*)\|/) || [];

  const internetTrafficInboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...getCloudRegionFilters({ cloudProvider, direction: 'src|dst', regionValue: value, network }),
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'from outside to cloud'
            }
          ]
        })
      ]
    },
    query_title: `Traffic From Outside To ${name} ${value}`,
    renderOptions: { source: 'Inbound', dimensions: { ...dimensions, direction: 'src' } }
  };

  const internetTrafficOutboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...getCloudRegionFilters({ cloudProvider, direction: 'src|dst', regionValue: value, network }),
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'from cloud to outside'
            }
          ]
        })
      ]
    },
    query_title: `Traffic From ${name} ${value} To Outside`,
    renderOptions: { source: 'Outbound', dimensions: { ...dimensions, direction: 'dst' } }
  };

  const internalTrafficQuery = {
    ...baseQuery,
    ...getCloudRegionInternalTrafficQuery({ cloudProvider, name, value, network })
  };

  const internalTrafficInboundQuery = {
    ...baseQuery,
    ...getCloudRegionInternalTrafficQuery({ cloudProvider, name, value, direction: 'dst', network }),
    query_title: `Internal Traffic To ${name} ${value}`,
    renderOptions: { source: 'Inbound' }
  };

  const internalTrafficOutboundQuery = {
    ...baseQuery,
    ...getCloudRegionInternalTrafficQuery({ cloudProvider, name, value, direction: 'src', network }),
    query_title: `Internal Traffic From ${name} ${value}`,
    renderOptions: { source: 'Outbound' }
  };

  const interRegionalTrafficInboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: getCloudRegionFilters({
            cloudProvider,
            direction: 'dst',
            operator: '=',
            regionValue: value,
            network
          })
        }),

        buildFilterGroup({
          filters: getCloudRegionFilters({
            cloudProvider,
            direction: 'src',
            operator: '<>',
            regionValue: value,
            network
          })
        }),

        buildFilterGroup({
          filters: [
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'cloud internal'
            }
          ]
        })
      ]
    },
    query_title: `From Other Regions To ${name} ${value}`,
    renderOptions: { source: 'Inbound' }
  };

  const interRegionalTrafficOutboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: getCloudRegionFilters({
            cloudProvider,
            direction: 'src',
            operator: '=',
            regionValue: value,
            network
          })
        }),

        buildFilterGroup({
          filters: getCloudRegionFilters({
            cloudProvider,
            direction: 'dst',
            operator: '<>',
            regionValue: value,
            network
          })
        }),

        buildFilterGroup({
          filters: [
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'cloud internal'
            }
          ]
        })
      ]
    },
    query_title: `From ${name} ${value} To Other Regions`,
    renderOptions: { source: 'Outbound' }
  };

  const infrastructureTrafficInboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...getCloudRegionFilters({ cloudProvider, direction: 'src|dst', regionValue: value, network }),
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'from inside to cloud'
            }
          ]
        })
      ]
    },
    query_title: `From My Infrastructure To ${name} ${value}`,
    renderOptions: { source: 'Inbound', dimensions }
  };

  const infrastructureTrafficOutboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...getCloudRegionFilters({ cloudProvider, direction: 'src|dst', regionValue: value, network }),
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'from cloud to inside'
            }
          ]
        })
      ]
    },
    query_title: `From ${name} ${value} To My Infrastructure`,
    renderOptions: { source: 'Outbound', dimensions }
  };

  const otherCloudsTrafficQuery = {
    ...baseQuery,
    ...getCloudRegionOtherCloudTrafficQuery({ cloudProvider, name, value, network })
  };

  const otherCloudsTrafficInboundQuery = {
    ...baseQuery,
    ...getCloudRegionOtherCloudTrafficQuery({ cloudProvider, name, value, direction: 'dst', network }),
    query_title: `Other Cloud Traffic To ${name} ${value}`,
    renderOptions: { source: 'Inbound' }
  };

  const otherCloudsTrafficOutboundQuery = {
    ...baseQuery,
    ...getCloudRegionOtherCloudTrafficQuery({ cloudProvider, name, value, direction: 'src', network }),
    query_title: `Other Cloud Traffic From ${name} ${value}`,
    renderOptions: { source: 'Outbound' }
  };

  const options = [
    {
      label: 'All Traffic',
      value: 'all',
      query: {
        ...baseQuery,
        filters: buildFilterGroup({
          filterGroups: [
            buildFilterGroup({
              filters: getCloudRegionFilters({ cloudProvider, direction: 'src|dst', regionValue: value, network })
            })
          ]
        }),
        query_title: `All ${name} ${value} Region Traffic`,
        renderOptions: { dimensions }
      }
    },
    {
      label: 'Internet Traffic',
      value: 'internetTraffic',
      query: {
        inboundQuery: internetTrafficInboundQuery,
        outboundQuery: internetTrafficOutboundQuery
      }
    },

    {
      label: 'Internal Traffic',
      value: 'internalTraffic',
      query: {
        ...internalTrafficQuery,
        renderOptions: { ...internalTrafficQuery.renderOptions, dimensions }
      }
    },

    {
      label: 'Inbound/Outbound Internal Traffic',
      value: 'internalTrafficDirectional',
      query: {
        inboundQuery: {
          ...internalTrafficInboundQuery,
          renderOptions: {
            ...internalTrafficQuery.renderOptions,
            dimensions: { ...dimensions, direction: 'src' }
          }
        },

        outboundQuery: {
          ...internalTrafficOutboundQuery,
          renderOptions: {
            ...internalTrafficQuery.renderOptions,
            dimensions: { ...dimensions, direction: 'dst' }
          }
        }
      }
    },

    {
      label: 'Inter-Region Traffic',
      value: 'interRegionalTraffic',
      query: {
        inboundQuery: {
          ...interRegionalTrafficInboundQuery,
          renderOptions: {
            ...interRegionalTrafficInboundQuery.renderOptions,
            dimensions: { ...dimensions, direction: 'src' }
          }
        },
        outboundQuery: {
          ...interRegionalTrafficOutboundQuery,
          renderOptions: {
            ...interRegionalTrafficOutboundQuery.renderOptions,
            dimensions: { ...dimensions, direction: 'dst' }
          }
        }
      }
    },

    {
      label: 'Traffic With My Infrastructure',
      value: 'trafficWithMyInfrastructure',
      disabled: !$hybridMap.hasSites,
      query: {
        inboundQuery: infrastructureTrafficInboundQuery,
        outboundQuery: infrastructureTrafficOutboundQuery
      }
    },

    {
      label: 'Traffic With Other Clouds',
      value: 'trafficWithOtherClouds',
      query: {
        ...otherCloudsTrafficQuery,
        renderOptions: { ...otherCloudsTrafficQuery.renderOptions, dimensions }
      }
    },

    {
      label: 'Inbound/Outbound Traffic With Other Clouds',
      value: 'trafficWithOtherCloudsDirectional',
      query: {
        inboundQuery: {
          ...otherCloudsTrafficInboundQuery,
          renderOptions: {
            ...otherCloudsTrafficInboundQuery.renderOptions,
            dimensions: { ...dimensions, direction: 'src' }
          }
        },

        outboundQuery: {
          ...otherCloudsTrafficOutboundQuery,
          renderOptions: {
            ...otherCloudsTrafficOutboundQuery.renderOptions,
            dimensions: { ...dimensions, direction: 'dst' }
          }
        }
      }
    }
  ];

  return $hybridMap.mergeSidebarQueryOptions(options.filter((o) => !o.disabled));
}

export function getCloudVpcPopoverQueryOptions({ cloudProvider, name, value, subType }) {
  const providerVtypeMap = {
    aws: 'VPC',
    oci: 'VCN',
    azure: 'VNet',
    gcp: 'Network'
  };

  if (cloudProvider === 'aws' || cloudProvider === 'azure' || cloudProvider === 'gcp' || cloudProvider === 'oci') {
    const vType = providerVtypeMap[cloudProvider];
    const deviceType = `${cloudProvider}_subnet`;
    const baseQuery = {
      all_devices: false,
      device_types: [deviceType]
    };
    const dimensions = { ...ALL_GROUPS_CLOUD, cloudProvider };

    const internetTrafficInboundQuery = {
      ...baseQuery,
      filters: {
        connector: 'All',
        filterGroups: [
          buildFilterGroup({
            filters: [
              {
                filterField: getCloudVPCVNetFilterField({ cloudProvider, direction: 'src|dst' }),
                operator: '=',
                filterValue: value
              },
              {
                filterField: 'i_trf_profile',
                operator: '=',
                filterValue: 'from outside to cloud'
              }
            ]
          })
        ]
      },
      query_title: `Traffic From Outside To ${name} ${value}`,
      renderOptions: { source: 'Inbound' }
    };

    const internetTrafficOutboundQuery = {
      ...baseQuery,
      filters: {
        connector: 'All',
        filterGroups: [
          buildFilterGroup({
            filters: [
              {
                filterField: getCloudVPCVNetFilterField({ cloudProvider, direction: 'src|dst' }),
                operator: '=',
                filterValue: value
              },
              {
                filterField: 'i_trf_profile',
                operator: '=',
                filterValue: 'from cloud to outside'
              }
            ]
          })
        ]
      },
      query_title: `Traffic From ${name} ${value} To Outside`,
      renderOptions: { source: 'Outbound' }
    };

    const internalTrafficQuery = {
      ...baseQuery,
      ...getCloudVPCVNetInternalTrafficQuery({ cloudProvider, name, value })
    };

    const internalTrafficInboundQuery = {
      ...baseQuery,
      ...getCloudVPCVNetInternalTrafficQuery({ cloudProvider, name, value, direction: 'dst' }),
      query_title: `Internal Traffic To ${name} ${value}`,
      renderOptions: { source: 'Inbound' }
    };

    const internalTrafficOutboundQuery = {
      ...baseQuery,
      ...getCloudVPCVNetInternalTrafficQuery({ cloudProvider, name, value, direction: 'src' }),
      query_title: `Internal Traffic From ${name} ${value}`,
      renderOptions: { source: 'Outbound' }
    };

    const interVPCTrafficInboundQuery = {
      ...baseQuery,
      filters: {
        connector: 'All',
        filterGroups: [
          buildFilterGroup({
            filters: [
              {
                filterField: getCloudVPCVNetFilterField({ cloudProvider, direction: 'dst' }),
                operator: '=',
                filterValue: value
              },

              {
                filterField: getCloudVPCVNetFilterField({ cloudProvider, direction: 'src' }),
                operator: '<>',
                filterValue: value
              },

              {
                filterField: 'i_trf_profile',
                operator: '=',
                filterValue: 'cloud internal'
              }
            ]
          })
        ]
      },
      query_title: `From Other ${subType}s To ${name} ${value}`,
      renderOptions: { source: 'Inbound' }
    };

    const interVPCTrafficOutboundQuery = {
      ...baseQuery,
      filters: {
        connector: 'All',
        filterGroups: [
          buildFilterGroup({
            filters: [
              {
                filterField: getCloudVPCVNetFilterField({ cloudProvider, direction: 'src' }),
                operator: '=',
                filterValue: value
              },

              {
                filterField: getCloudVPCVNetFilterField({ cloudProvider, direction: 'dst' }),
                operator: '<>',
                filterValue: value
              },

              {
                filterField: 'i_trf_profile',
                operator: '=',
                filterValue: 'cloud internal'
              }
            ]
          })
        ]
      },
      query_title: `From ${name} ${value} To Other ${subType}s`,
      renderOptions: { source: 'Outbound' }
    };

    const infrastructureTrafficInboundQuery = {
      ...baseQuery,
      filters: {
        connector: 'All',
        filterGroups: [
          buildFilterGroup({
            filters: [
              {
                filterField: getCloudVPCVNetFilterField({ cloudProvider, direction: 'src|dst' }),
                operator: '=',
                filterValue: value
              },

              {
                filterField: 'i_trf_profile',
                operator: '=',
                filterValue: 'from inside to cloud'
              }
            ]
          })
        ]
      },
      query_title: `From My Infrastructure To ${name} ${value}`,
      renderOptions: { source: 'Inbound' }
    };

    const infrastructureTrafficOutboundQuery = {
      ...baseQuery,
      filters: {
        connector: 'All',
        filterGroups: [
          buildFilterGroup({
            filters: [
              {
                filterField: getCloudVPCVNetFilterField({ cloudProvider, direction: 'src|dst' }),
                operator: '=',
                filterValue: value
              },

              {
                filterField: 'i_trf_profile',
                operator: '=',
                filterValue: 'from cloud to inside'
              }
            ]
          })
        ]
      },
      query_title: `From ${name} ${value} To My Infrastructure`,
      renderOptions: { source: 'Outbound' }
    };

    const otherCloudsTrafficQuery = {
      ...baseQuery,
      ...getCloudVPCVNetOtherCloudTrafficQuery({ cloudProvider, name, value })
    };

    const otherCloudsTrafficInboundQuery = {
      ...baseQuery,
      ...getCloudVPCVNetOtherCloudTrafficQuery({ cloudProvider, name, value, direction: 'dst' }),
      query_title: `Other Cloud Traffic To ${name} ${value}`,
      renderOptions: { source: 'Inbound' }
    };

    const otherCloudsTrafficOutboundQuery = {
      ...baseQuery,
      ...getCloudVPCVNetOtherCloudTrafficQuery({ cloudProvider, name, value, direction: 'src' }),
      query_title: `Other Cloud Traffic From ${name} ${value}`,
      renderOptions: { source: 'Outbound' }
    };

    const options = [
      {
        label: 'All Traffic',
        value: 'all',
        query: {
          ...baseQuery,
          filters: buildFilterGroup({
            filterGroups: [
              buildFilterGroup({
                filters: [
                  {
                    filterField: getCloudVPCVNetFilterField({ cloudProvider, direction: 'src|dst' }),
                    operator: '=',
                    filterValue: value
                  }
                ]
              })
            ]
          }),
          query_title: `All ${name} ${value} ${vType} Traffic`,
          renderOptions: { dimensions }
        }
      },
      {
        label: 'Internet Traffic',
        value: 'internetTraffic',
        query: {
          inboundQuery: {
            ...internetTrafficInboundQuery,
            renderOptions: {
              ...internetTrafficInboundQuery.renderOptions,
              dimensions: { ...dimensions, direction: 'src' }
            }
          },
          outboundQuery: {
            ...internetTrafficOutboundQuery,
            renderOptions: {
              ...internetTrafficOutboundQuery.renderOptions,
              dimensions: { ...dimensions, direction: 'dst' }
            }
          }
        }
      },

      {
        label: 'Internal Traffic',
        value: 'internalTraffic',
        query: {
          ...internalTrafficQuery,
          renderOptions: {
            ...internalTrafficQuery.renderOptions,
            dimensions
          }
        }
      },

      {
        label: 'Inbound/Outbound Internal Traffic',
        value: 'internalTrafficDirectional',
        query: {
          inboundQuery: {
            ...internalTrafficInboundQuery,
            renderOptions: {
              ...internalTrafficInboundQuery.renderOptions,
              dimensions: { ...dimensions, direction: 'src' }
            }
          },

          outboundQuery: {
            ...internalTrafficOutboundQuery,
            renderOptions: {
              ...internalTrafficOutboundQuery.renderOptions,
              dimensions: { ...dimensions, direction: 'dst' }
            }
          }
        }
      },

      {
        label: `Inter-${vType} Traffic`,
        value: `inter${vType}Traffic`,
        query: {
          inboundQuery: {
            ...interVPCTrafficInboundQuery,
            renderOptions: {
              ...interVPCTrafficInboundQuery.renderOptions,
              dimensions: { ...dimensions, direction: 'src' }
            }
          },
          outboundQuery: {
            ...interVPCTrafficOutboundQuery,
            renderOptions: {
              ...interVPCTrafficOutboundQuery.renderOptions,
              dimensions: { ...dimensions, direction: 'dst' }
            }
          }
        }
      },

      {
        label: 'Traffic With My Infrastructure',
        value: 'trafficWithMyInfrastructure',
        disabled: !$hybridMap.hasSites,
        query: {
          inboundQuery: {
            ...infrastructureTrafficInboundQuery,
            renderOptions: {
              ...infrastructureTrafficInboundQuery.renderOptions,
              dimensions
            }
          },
          outboundQuery: {
            ...infrastructureTrafficOutboundQuery,
            renderOptions: {
              ...infrastructureTrafficOutboundQuery.renderOptions,
              dimensions
            }
          }
        }
      },

      {
        label: 'Traffic With Other Clouds',
        value: 'trafficWithOtherClouds',
        query: {
          ...otherCloudsTrafficQuery,
          renderOptions: {
            ...otherCloudsTrafficQuery.renderOptions,
            dimensions
          }
        }
      },

      {
        label: 'Inbound/Outbound Traffic With Other Clouds',
        value: 'trafficWithOtherCloudsDirectional',
        query: {
          inboundQuery: {
            ...otherCloudsTrafficInboundQuery,
            renderOptions: {
              ...otherCloudsTrafficInboundQuery.renderOptions,
              dimensions: { ...dimensions, direction: 'src' }
            }
          },

          outboundQuery: {
            ...otherCloudsTrafficOutboundQuery,
            renderOptions: {
              ...otherCloudsTrafficOutboundQuery.renderOptions,
              dimensions: { ...dimensions, direction: 'dst' }
            }
          }
        }
      }
    ];

    return $hybridMap.mergeSidebarQueryOptions(options.filter((o) => !o.disabled));
  }

  return null;
}

export function getCloudSubnetPopoverQueryOptions({ cloudProvider, name, value, nodeData }) {
  const deviceType = `${cloudProvider}_subnet`;
  const baseQuery = {
    all_devices: false,
    device_types: [deviceType]
  };
  const dimensions = { ...ALL_GROUPS_CLOUD, cloudProvider };

  if (isGoogleCloud(cloudProvider) && nodeData?.name) {
    value = nodeData.name;
  }

  const internetTrafficInboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...getCloudSubnetFilters({ cloudProvider, subnetValue: value, subnetEntity: nodeData }),
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'from outside to cloud'
            }
          ]
        })
      ]
    },
    query_title: `Traffic From Outside To ${name} ${value}`,
    renderOptions: { source: 'Inbound', dimensions: { ...dimensions, direction: 'src' } }
  };

  const internetTrafficOutboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...getCloudSubnetFilters({ cloudProvider, subnetValue: value, subnetEntity: nodeData }),
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'from cloud to outside'
            }
          ]
        })
      ]
    },
    query_title: `Traffic From ${name} ${value} To Outside`,
    renderOptions: { source: 'Outbound', dimensions: { ...dimensions, direction: 'dst' } }
  };

  const internalTrafficQuery = {
    ...baseQuery,
    ...getCloudSubnetInternalTrafficQuery({ cloudProvider, name, value, subnetEntity: nodeData })
  };

  const internalTrafficInboundQuery = {
    ...baseQuery,
    ...getCloudSubnetInternalTrafficQuery({ cloudProvider, name, value, direction: 'dst', subnetEntity: nodeData }),
    query_title: `Internal Traffic To ${name} ${value}`,
    renderOptions: { source: 'Inbound' }
  };

  const internalTrafficOutboundQuery = {
    ...baseQuery,
    ...getCloudSubnetInternalTrafficQuery({ cloudProvider, name, value, direction: 'src', subnetEntity: nodeData }),
    query_title: `Internal Traffic From ${name} ${value}`,
    renderOptions: { source: 'Outbound' }
  };

  const interSubnetTrafficInboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: getCloudSubnetFilters({
            cloudProvider,
            direction: 'dst',
            operator: '=',
            subnetValue: value,
            subnetEntity: nodeData
          })
        }),
        buildFilterGroup({
          filters: getCloudSubnetFilters({
            cloudProvider,
            direction: 'src',
            operator: '<>',
            subnetValue: value,
            subnetEntity: nodeData
          })
        }),
        buildFilterGroup({
          filters: [
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'cloud internal'
            }
          ]
        })
      ]
    },
    query_title: `From Other Subnets To ${name} ${value}`,
    renderOptions: { source: 'Inbound' }
  };

  const interSubnetTrafficOutboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: getCloudSubnetFilters({
            cloudProvider,
            direction: 'src',
            operator: '=',
            subnetValue: value,
            subnetEntity: nodeData
          })
        }),
        buildFilterGroup({
          filters: getCloudSubnetFilters({
            cloudProvider,
            direction: 'dst',
            operator: '<>',
            subnetValue: value,
            subnetEntity: nodeData
          })
        }),
        buildFilterGroup({
          filters: [
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'cloud internal'
            }
          ]
        })
      ]
    },
    query_title: `From ${name} ${value} To Other Subnets`,
    renderOptions: { source: 'Outbound' }
  };

  const infrastructureTrafficInboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...getCloudSubnetFilters({ cloudProvider, subnetValue: value, subnetEntity: nodeData }),

            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'from inside to cloud'
            }
          ]
        })
      ]
    },
    query_title: `From My Infrastructure To ${name} ${value}`,
    renderOptions: { source: 'Inbound' }
  };

  const infrastructureTrafficOutboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...getCloudSubnetFilters({ cloudProvider, subnetValue: value, subnetEntity: nodeData }),

            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'from cloud to inside'
            }
          ]
        })
      ]
    },
    query_title: `From ${name} ${value} To My Infrastructure`,
    renderOptions: { source: 'Outbound' }
  };

  const otherCloudsTrafficQuery = {
    ...baseQuery,
    ...getCloudSubnetOtherCloudTrafficQuery({ cloudProvider, name, value, subnetEntity: nodeData })
  };

  const otherCloudsTrafficInboundQuery = {
    ...baseQuery,
    ...getCloudSubnetOtherCloudTrafficQuery({ cloudProvider, name, value, direction: 'dst', subnetEntity: nodeData }),
    query_title: `Other Cloud Traffic To ${name} ${value}`,
    renderOptions: { source: 'Inbound' }
  };

  const otherCloudsTrafficOutboundQuery = {
    ...baseQuery,
    ...getCloudSubnetOtherCloudTrafficQuery({ cloudProvider, name, value, direction: 'src', subnetEntity: nodeData }),
    query_title: `Other Cloud Traffic From ${name} ${value}`,
    renderOptions: { source: 'Outbound' }
  };

  const options = [
    {
      label: 'All Traffic',
      value: 'all',
      query: {
        ...baseQuery,
        filters: buildFilterGroup({
          filterGroups: [
            buildFilterGroup({
              filters: getCloudSubnetFilters({ cloudProvider, subnetValue: value, subnetEntity: nodeData })
            })
          ]
        }),
        query_title: `All ${name} ${value} Subnet Traffic`,
        renderOptions: { dimensions }
      }
    },
    {
      label: 'Internet Traffic',
      value: 'internetTraffic',
      query: {
        inboundQuery: internetTrafficInboundQuery,
        outboundQuery: internetTrafficOutboundQuery
      }
    },

    {
      label: 'Internal Traffic',
      value: 'internalTraffic',
      query: {
        ...internalTrafficQuery,
        renderOptions: { ...internalTrafficQuery.renderOptions, dimensions }
      }
    },

    {
      label: 'Inbound/Outbound Internal Traffic',
      value: 'internalTrafficDirectional',
      query: {
        inboundQuery: {
          ...internalTrafficInboundQuery,
          renderOptions: {
            ...internalTrafficInboundQuery.renderOptions,
            dimensions: { ...dimensions, direction: 'src' }
          }
        },

        outboundQuery: {
          ...internalTrafficOutboundQuery,
          renderOptions: {
            ...internalTrafficOutboundQuery.renderOptions,
            dimensions: { ...dimensions, direction: 'dst' }
          }
        }
      }
    },

    {
      label: 'Inter-Subnet Traffic',
      value: 'interSubnetTraffic',
      query: {
        inboundQuery: {
          ...interSubnetTrafficInboundQuery,
          renderOptions: {
            ...interSubnetTrafficInboundQuery.renderOptions,
            dimensions: { ...dimensions, direction: 'src' }
          }
        },
        outboundQuery: {
          ...interSubnetTrafficOutboundQuery,
          renderOptions: {
            ...interSubnetTrafficOutboundQuery.renderOptions,
            dimensions: { ...dimensions, direction: 'dst' }
          }
        }
      }
    },

    {
      label: 'Traffic With My Infrastructure',
      value: 'trafficWithMyInfrastructure',
      disabled: !$hybridMap.hasSites,
      query: {
        inboundQuery: {
          ...infrastructureTrafficInboundQuery,
          renderOptions: {
            ...infrastructureTrafficInboundQuery.renderOptions,
            dimensions
          }
        },
        outboundQuery: {
          ...infrastructureTrafficOutboundQuery,
          renderOptions: {
            ...infrastructureTrafficOutboundQuery.renderOptions,
            dimensions
          }
        }
      }
    },

    {
      label: 'Traffic With Other Clouds',
      value: 'trafficWithOtherClouds',
      query: {
        ...otherCloudsTrafficQuery,
        renderOptions: {
          ...otherCloudsTrafficQuery.renderOptions,
          dimensions
        }
      }
    },

    {
      label: 'Inbound/Outbound Traffic With Other Clouds',
      value: 'trafficWithOtherCloudsDirectional',
      query: {
        inboundQuery: {
          ...otherCloudsTrafficInboundQuery,
          renderOptions: {
            ...otherCloudsTrafficInboundQuery.renderOptions,
            dimensions: { ...dimensions, direction: 'src' }
          }
        },

        outboundQuery: {
          ...otherCloudsTrafficOutboundQuery,
          renderOptions: {
            ...otherCloudsTrafficOutboundQuery.renderOptions,
            dimensions: { ...dimensions, direction: 'dst' }
          }
        }
      }
    }
  ];

  return $hybridMap.mergeSidebarQueryOptions(options.filter((o) => !o.disabled));
}

export function getCloudTransitGatewayQueryOptions({ cloudProvider, name, value, topology, baseQuery = {} }) {
  const filters = getTransitGatewayFilterGroup(topology, value);
  const dimensions = { ...ALL_GROUPS_CLOUD, cloudProvider };

  if (filters) {
    return [
      {
        label: 'All Traffic',
        value: 'all',
        query: {
          ...baseQuery,
          filters,
          query_title: `All ${name} ${value} Gateway Traffic`,
          renderOptions: { dimensions }
        }
      }
    ];
  }

  return null;
}

export function getCoreNetworkQueryOptions({ cloudProvider, nodeData, baseQuery = {} }) {
  const coreNetworkId = nodeData?.CoreNetworkId;
  const edgeLocation = nodeData?.EdgeLocation;

  const groupFilters = [
    {
      filterField: AWS.NON_DIRECTIONAL.CORE_NETWORK_ID,
      operator: '=',
      filterValue: coreNetworkId
    },
    {
      filterField: AWS.BI.CORE_NETWORK_EDGE_LOCATION,
      operator: '=',
      filterValue: edgeLocation
    }
  ];

  if (nodeData?.type === CORE_NETWORK_ATTACHMENT) {
    groupFilters.push({
      filterField: AWS.SRC.CORE_NETWORK_ATTACHMENT,
      operator: '=',
      filterValue: nodeData.id
    });
  }

  const filters = {
    connector: 'All',
    filterGroups: [
      buildFilterGroup({
        connector: 'All',
        filters: groupFilters
      })
    ]
  };

  const dimensions = { ...ALL_GROUPS_CLOUD, cloudProvider };

  if (filters) {
    return [
      {
        label: 'All Traffic',
        value: 'all',
        query: {
          ...baseQuery,
          filters,
          connector: 'All',
          query_title: `All ${coreNetworkId} Core Network Traffic in ${edgeLocation}.`,
          renderOptions: { dimensions }
        }
      }
    ];
  }

  return null;
}

export function getCloudGatewayPopoverQueryOptions({ cloudProvider, name, value, nodeData, topology }) {
  const deviceType = `${cloudProvider}_subnet`;
  const baseQuery = {
    all_devices: false,
    device_types: [deviceType]
  };
  const dimensions = { ...ALL_GROUPS_CLOUD, cloudProvider };
  const gatewayFilters = [];

  if (nodeData?.type === TRANSIT_GATEWAY) {
    return getCloudTransitGatewayQueryOptions({ cloudProvider, name, value, nodeData, topology, baseQuery });
  }

  if (nodeData?.type === CORE_NETWORK_EDGE || nodeData?.type === CORE_NETWORK_ATTACHMENT) {
    return getCoreNetworkQueryOptions({ cloudProvider, name, value, nodeData, topology, baseQuery });
  }

  if (nodeData?.type === TRANSIT_GATEWAY_ATTACHMENT) {
    gatewayFilters.push({
      filterField: getCloudGatewayFilterField({ cloudProvider }),
      operator: '=',
      filterValue: nodeData.TransitGatewayId
    });

    if (nodeData.ResourceType === 'vpc') {
      gatewayFilters.push({
        filterField: getCloudVPCVNetFilterField({ cloudProvider, direction: 'src|dst' }),
        operator: '=',
        filterValue: nodeData.ResourceId
      });
    }
  } else {
    gatewayFilters.push({
      filterField: getCloudGatewayFilterField({ cloudProvider }),
      operator: '=',
      filterValue: value
    });
  }

  const internetTrafficInboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...gatewayFilters,
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'from outside to cloud'
            }
          ]
        })
      ]
    },
    query_title: `Traffic From Outside To ${name} ${value}`,
    renderOptions: { source: 'Inbound', dimensions: { ...dimensions, direction: 'src' } }
  };

  const internetTrafficOutboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...gatewayFilters,
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'from cloud to outside'
            }
          ]
        })
      ]
    },
    query_title: `Traffic From ${name} ${value} To Outside`,
    renderOptions: { source: 'Outbound', dimensions: { ...dimensions, direction: 'dst' } }
  };

  const internalTrafficQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...gatewayFilters,
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'cloud internal'
            }
          ]
        })
      ]
    },
    query_title: `Internal Traffic With ${name} ${value}`,
    renderOptions: { source: 'Internal Traffic' }
  };

  const infrastructureTrafficInboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...gatewayFilters,
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'from inside to cloud'
            }
          ]
        })
      ]
    },
    query_title: `From My Infrastructure To ${name} ${value}`,
    renderOptions: { source: 'Inbound' }
  };

  const infrastructureTrafficOutboundQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...gatewayFilters,
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'from cloud to inside'
            }
          ]
        })
      ]
    },
    query_title: `From ${name} ${value} To My Infrastructure`,
    renderOptions: { source: 'Outbound' }
  };

  const otherCloudsTrafficQuery = {
    ...baseQuery,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            ...gatewayFilters,
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'multi-cloud'
            }
          ]
        })
      ]
    },
    query_title: `${name} ${value} Traffic With Other Clouds`,
    renderOptions: { source: 'Traffic With Other Clouds' }
  };

  const options = [
    {
      label: 'All Traffic',
      value: 'all',
      query: {
        ...baseQuery,
        filters: buildFilterGroup({
          filterGroups: [buildFilterGroup({ filters: gatewayFilters })]
        }),
        query_title: `All ${name} ${value} Gateway Traffic`,
        renderOptions: { dimensions }
      }
    },
    {
      label: 'Internet Traffic',
      value: 'internetTraffic',
      query: {
        inboundQuery: internetTrafficInboundQuery,
        outboundQuery: internetTrafficOutboundQuery
      }
    },

    {
      label: 'Internal Traffic',
      value: 'internalTraffic',
      query: {
        ...internalTrafficQuery,
        renderOptions: { ...internalTrafficQuery.renderOptions, dimensions }
      }
    },

    {
      label: 'Traffic With My Infrastructure',
      value: 'trafficWithMyInfrastructure',
      disabled: !$hybridMap.hasSites,
      query: {
        inboundQuery: {
          ...infrastructureTrafficInboundQuery,
          renderOptions: {
            ...infrastructureTrafficInboundQuery.renderOptions,
            dimensions
          }
        },
        outboundQuery: {
          ...infrastructureTrafficOutboundQuery,
          renderOptions: {
            ...infrastructureTrafficOutboundQuery.renderOptions,
            dimensions
          }
        }
      }
    },

    {
      label: 'Traffic With Other Clouds',
      value: 'trafficWithOtherClouds',
      query: {
        ...otherCloudsTrafficQuery,
        renderOptions: {
          ...otherCloudsTrafficQuery.renderOptions,
          dimensions
        }
      }
    }
  ];

  return $hybridMap.mergeSidebarQueryOptions(options.filter((o) => !o.disabled));
}

function getDeviceConnectedQueryOptions({ value, links }) {
  const deviceId = value;

  if (links) {
    const connections = links
      .filter((link) => link.device1_id && link.device2_id)
      .reduce(
        (acc, link) => {
          let { sourceDevice } = acc;
          const devices = {};
          const targetInterfaces = [];

          link.connections.forEach((connection) => {
            const interfaces = Object.values(connection);
            const targetInterface = interfaces.find((inf) => inf.device_id !== deviceId);

            if (!sourceDevice) {
              sourceDevice = interfaces.find((inf) => inf.device_id === deviceId);
            }

            if (targetInterface) {
              devices[targetInterface.device_name] = null;
              targetInterfaces.push(targetInterface);
            }
          });

          return {
            sourceDevice,
            devices: { ...acc.devices, ...devices },
            interfaces: [...acc.interfaces, ...targetInterfaces]
          };
        },
        {
          sourceDevice: null,
          devices: {},
          interfaces: []
        }
      );

    const baseQuery = {
      all_devices: false,
      device_name: Object.keys(connections.devices)
    };

    if (connections.sourceDevice && connections.interfaces.length > 0) {
      const inboundFilter = getInterfacesFilterGroup({ interfaces: connections.interfaces, direction: 'input' });
      const outboundFilter = getInterfacesFilterGroup({ interfaces: connections.interfaces, direction: 'output' });
      const dimensions = ALL_GROUPS_EXCLUDING_CLOUD;

      return [
        {
          label: 'Connected Devices',
          value: 'connectedDevices',
          query: {
            inboundQuery: {
              ...baseQuery,
              filters: inboundFilter,
              query_title: `Traffic From Connected Devices To ${connections.sourceDevice.device_name}`,
              renderOptions: {
                source: 'Connected Devices',
                target: connections.sourceDevice.device_name,
                columnLabel: 'Ingress',
                dimensions
              }
            },
            outboundQuery: {
              ...baseQuery,
              filters: outboundFilter,
              query_title: `Traffic From ${connections.sourceDevice.device_name} To Connected Devices`,
              renderOptions: {
                source: connections.sourceDevice.device_name,
                target: 'Connected Devices',
                columnLabel: 'Egress',
                dimensions
              }
            }
          }
        }
      ];
    }
  }

  return [];
}

export function getDevicePopoverQueryOptions({ model, value, links }) {
  const device = model.get('device_name');
  const inboundFilter = getExternalTrafficFilterGroup({ direction: 'src' });
  const outboundFilter = getExternalTrafficFilterGroup({ direction: 'dst' });
  const baseQuery = {
    all_devices: false,
    device_name: [model.get('device_name')]
  };
  const dimensions = ALL_GROUPS_EXCLUDING_CLOUD;

  let options = [
    {
      label: 'All Traffic',
      value: 'all',
      query: {
        ...baseQuery,
        query_title: `All ${device} Traffic`,
        renderOptions: { dimensions }
      }
    },
    {
      label: 'External Traffic',
      value: 'total',
      query: {
        inboundQuery: {
          ...baseQuery,
          query_title: `From Outside To ${device}`,
          filters: inboundFilter,
          renderOptions: { source: 'Outside', target: device, dimensions: { ...dimensions, direction: 'src' } }
        },
        outboundQuery: {
          ...baseQuery,
          query_title: `From ${device} To Outside`,
          filters: outboundFilter,
          renderOptions: { source: device, target: 'Outside', dimensions: { ...dimensions, direction: 'dst' } }
        }
      }
    }
  ];

  const connectedQueries = getDeviceConnectedQueryOptions({ value, links });

  if (connectedQueries.length > 0) {
    options = options.concat(connectedQueries);
  }

  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getK8OriginNetworksQueryOptions({ value, asNumber, device, namespace }) {
  const baseQuery = {
    all_devices: false,
    device_name: device || [],
    device_types: device ? [] : ['kappa']
  };

  const options = [
    {
      label: 'Origin Network',
      value: 'all',
      query: {
        ...baseQuery,
        query_title: `${value} Traffic`,
        renderOptions: { dimensions: ALL_KAPPA },
        filters: {
          connector: 'All',
          filterGroups: [
            {
              connector: 'All',
              not: false,
              filters: [
                {
                  filterField: 'ktsubtype__kappa__STR06',
                  operator: '<>',
                  filterValue: ''
                },
                {
                  filterField: 'src_as',
                  operator: '=',
                  filterValue: asNumber
                },
                namespace && {
                  filterField: 'ktsubtype__kappa__STR09src|dstktsubtype__kappa__STR15',
                  operator: '=',
                  filterValue: namespace
                }
              ].filter(Boolean)
            }
          ]
        }
      }
    }
  ];

  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getServicePopoverQueryOptions({ subType, value, device, namespace }) {
  let filters = [];
  const isServiceDetail = subType === 'services';

  if (isServiceDetail) {
    const inBetweenParenthesis = /\(([^)]+)\)/;
    const [port, protocol] = inBetweenParenthesis.exec(value)?.[1]?.split('/') || ['', ''];

    filters = [
      {
        filterField: 'l4_src_port',
        operator: '=',
        filterValue: port
      },
      {
        filterField: 'i_protocol_name',
        operator: '=',
        filterValue: protocol
      }
    ];
  } else {
    // is IP details
    filters = [
      {
        filterField: 'inet_src_addr',
        operator: 'ILIKE',
        filterValue: value.split(' ')[0]
      }
    ];
  }

  if (namespace) {
    filters.push({
      filterField: 'ktsubtype__kappa__STR09src|dstktsubtype__kappa__STR15',
      operator: '=',
      filterValue: namespace
    });
  }

  const baseQuery = {
    all_devices: false,
    device_name: device || [],
    device_types: device ? [] : ['kappa']
  };

  const options = [
    {
      label: 'All Traffic',
      value: 'all',
      query: {
        ...baseQuery,
        query_title: 'Service Traffic',
        renderOptions: { dimensions: ALL_KAPPA },
        filters: {
          connector: 'All',
          filterGroups: [
            {
              name: '',
              named: false,
              connector: 'Any',
              not: false,
              autoAdded: true,
              filters
            }
          ]
        }
      }
    }
  ];

  return $hybridMap.mergeSidebarQueryOptions(options);
}

// eslint-disable-next-line no-unused-vars
export function getK8NodePopoverQueryOptions({ value: k8NodeId }) {
  // const device = model.get('device_name');
  const inboundFilter = getExternalTrafficFilterGroup({ direction: 'src' });
  const outboundFilter = getExternalTrafficFilterGroup({ direction: 'dst' });

  const baseQuery = {
    all_devices: false,
    device_types: ['kappa']
  };

  const options = [
    {
      label: 'Pods',
      value: 'pods-in-node',
      query: {
        ...baseQuery,
        query_title: 'Pods Inside this Node',
        renderOptions: {
          dimensions: ALL_KAPPA
        },
        filters: {
          connector: 'All',
          filterGroups: [
            {
              name: '',
              named: false,
              connector: 'Any',
              not: false,
              autoAdded: true,
              filters: [
                {
                  filterField: 'ktsubtype__kappa__STR10',
                  operator: '=',
                  filterValue: 'pod'
                }
              ],
              saved_filters: [],
              filterGroups: []
            },
            {
              name: '',
              named: false,
              connector: 'Any',
              not: false,
              autoAdded: true,
              filters: [
                {
                  filterField: 'ktsubtype__kappa__STR06',
                  operator: '=',
                  filterValue: k8NodeId
                }
              ],
              saved_filters: [],
              filterGroups: []
            }
          ]
        }
      }
    },
    {
      label: 'External Traffic',
      value: 'total',
      query: {
        inboundQuery: {
          ...baseQuery,
          query_title: 'From Outside To Node',
          filters: inboundFilter,
          renderOptions: { source: 'Outside', target: k8NodeId, dimensions: { direction: 'src' } }
        },
        outboundQuery: {
          ...baseQuery,
          query_title: 'From Node To Outside',
          filters: outboundFilter,
          renderOptions: { source: k8NodeId, target: 'Outside', dimensions: { direction: 'dst' } }
        }
      }
    }
  ];

  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getKubePopoverTrafficQueryOptions({ nodeData, deviceName, namespace }) {
  const { typeLabel, type } = nodeData;
  const data = { ...nodeData, namespace };

  const baseQuery = {
    all_devices: false,
    device_name: [deviceName]
  };

  const options = [
    {
      label: typeLabel,
      value: `in-${type}`,
      query: {
        ...baseQuery,
        renderOptions: {
          source: `${typeLabel}: ${nodeData.name}`,
          dimensions: { ...ALL_KAPPA }
        },
        filters: {
          connector: 'All',
          filterGroups: [getKubeFilterGroup({ entity: data })]
        }
      }
    },
    {
      label: 'External Traffic',
      value: 'total',
      query: {
        inboundQuery: {
          ...baseQuery,
          query_title: `${typeLabel} traffic from the Internet`,
          filters: {
            connector: 'All',
            filterGroups: [getKubeFilterGroup({ entity: data, direction: 'dst', internet: true })]
          },
          renderOptions: {
            source: 'Internet',
            target: nodeData.name,
            dimensions: { ...ALL_KAPPA, direction: 'dst' }
          }
        },
        outboundQuery: {
          ...baseQuery,
          query_title: `${typeLabel} traffic to the Internet`,
          filters: {
            connector: 'All',
            filterGroups: [getKubeFilterGroup({ entity: data, direction: 'src', internet: true })]
          },
          renderOptions: {
            source: nodeData.name,
            target: 'Internet',
            dimensions: { ...ALL_KAPPA, direction: 'src' }
          }
        }
      }
    }
  ];

  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getKubePopoverPerformanceQueryOptions({ nodeData, deviceName, namespace }) {
  const { typeLabel } = nodeData;
  const data = { ...nodeData, namespace };

  const baseQuery = {
    all_devices: false,
    device_name: [deviceName]
  };

  const options = [
    {
      label: 'Performance',
      value: 'total',
      query: {
        ...baseQuery,
        query_title: `${typeLabel} performance`,
        filters: {
          connector: 'All',
          filterGroups: [getKubeFilterGroup({ entity: data })]
        },
        renderOptions: {
          dimensions: ALL_KAPPA
        }
      }
    }
  ];

  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getClusterPopoverTrafficQueryOptions({ nodeData }) {
  const { name: clusterName, device_name: deviceName } = nodeData;

  const baseQuery = {
    all_devices: false,
    device_name: [deviceName]
  };

  const options = [
    {
      label: 'Cluster',
      value: 'cluster',
      query: {
        ...baseQuery,
        renderOptions: {
          source: nodeData.name,
          dimensions: { ...ALL_KAPPA }
        },
        filters: {
          connector: 'All',
          filterGroups: [getKubeFilterGroup({ entity: nodeData })]
        }
      }
    },
    {
      label: 'External Traffic',
      value: 'total',
      query: {
        inboundQuery: {
          ...baseQuery,
          query_title: 'Cluster traffic from the Internet',
          filters: {
            connector: 'All',
            filterGroups: [getKubeInternetFilterGroup({ direction: 'src' })]
          },
          renderOptions: {
            source: 'Internet',
            target: clusterName,
            dimensions: { ...ALL_KAPPA, direction: 'dst' }
          }
        },
        outboundQuery: {
          ...baseQuery,
          query_title: 'Cluster traffic to the Internet',
          filters: {
            connector: 'All',
            filterGroups: [getKubeInternetFilterGroup({ direction: 'dst' })]
          },
          renderOptions: {
            source: clusterName,
            target: 'Internet',
            dimensions: { ...ALL_KAPPA, direction: 'src' }
          }
        }
      }
    }
  ];

  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getClusterPopoverPerformanceQueryOptions({ nodeData }) {
  const { device_name: deviceName } = nodeData;

  const baseQuery = {
    all_devices: false,
    device_name: [deviceName]
  };

  const options = [
    {
      label: 'Performance',
      value: 'total',
      query: {
        ...baseQuery,
        query_title: 'Cluster performance',
        renderOptions: {
          dimensions: ALL_KAPPA
        }
      }
    }
  ];

  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getK8PodPopoverQueryOptions({ value: k8PodId, nodeData }) {
  const toOtherPodsFilter = getPodToOtherPodsFilters(k8PodId);
  const otherPodsToPodFilter = getOtherPodsToPodFilters(k8PodId);

  // get the selected namespace for the pod, filter by it and stuff
  const namespace = nodeData?.selectedNamespace;

  const filters = [
    {
      filterField: 'ktsubtype__kappa__STR08src|dstktsubtype__kappa__STR14',
      operator: '=',
      filterValue: k8PodId
    }
  ];

  if (namespace) {
    filters.push({
      filterField: 'ktsubtype__kappa__STR09',
      operator: '=',
      filterValue: namespace
    });
  }

  const baseQuery = {
    all_devices: false,
    device_types: ['kappa']
  };

  const options = [
    {
      label: (
        <>
          <span>
            Traffic in <Text fontWeight="bold">{namespace}</Text>
          </span>
        </>
      ),

      value: 'in-namespace',
      query: {
        ...baseQuery,
        renderOptions: {
          dimensions: ALL_KAPPA
        },
        // query_title: `Originating from ${k8PodId}`,
        filters: {
          connector: 'All',
          filterGroups: [
            {
              name: '',
              named: false,
              connector: 'All',
              not: false,
              autoAdded: true,
              filters
            }
          ]
        }
      }
    },

    {
      label: (
        <>
          <span>
            Traffic to <Text fontWeight="bold">other Namespaces</Text>
          </span>
        </>
      ),
      value: 'other-namespaces',
      query: {
        ...baseQuery,
        renderOptions: {
          dimensions: ALL_KAPPA
        },
        filters: {
          connector: 'All',
          filterGroups: [
            {
              name: '',
              named: false,
              connector: 'All',
              not: false,
              autoAdded: true,
              filters: [
                {
                  filterField: 'ktsubtype__kappa__STR08',
                  operator: '=',
                  filterValue: k8PodId
                },
                {
                  filterField: 'ktsubtype__kappa__STR16',
                  operator: '=',
                  filterValue: 'pod'
                },

                // where source namespace isn't itself
                {
                  filterField: 'ktsubtype__kappa__STR09',
                  operator: '<>',
                  filterValue: namespace
                }
              ]
            }
          ]
        }
      }
    },

    {
      label: 'Pod to Pod',
      value: 'total',
      query: {
        renderOptions: {
          dimensions: ALL_KAPPA
        },
        inboundQuery: {
          ...baseQuery,
          query_title: `From ${k8PodId} to Other Pods`,
          filters: toOtherPodsFilter,
          renderOptions: {
            source: 'Other Pods',
            target: (
              <Flex alignItems="center">
                <Icon icon={KubePodIcon} iconSize={14} />
                {k8PodId}
              </Flex>
            ),
            dimensions: ALL_KAPPA
          }
        },
        outboundQuery: {
          ...baseQuery,
          metric: ['ktsubtype__kappa__STR08'],
          query_title: `From Other Pods to ${k8PodId}`,
          filters: otherPodsToPodFilter,
          renderOptions: {
            source: (
              <Flex alignItems="center">
                <Icon icon={KubePodIcon} iconSize={14} />
                {k8PodId}
              </Flex>
            ),
            target: 'Other Pods',
            dimensions: ALL_KAPPA
          }
        }
      }
    }
  ];

  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getInterfacePopoverQueryOptions(connections, selectedInterface) {
  const dimensions = ALL_GROUPS_EXCLUDING_CLOUD;
  const inboundInterfaces = connections.map((c) => c.interface1).filter((inf) => !!inf);
  const outboundInterfaces = connections.map((c) => c.interface2).filter((inf) => !!inf);
  let inboundFilterGroup = buildFilterGroup();
  let outboundFilterGroup = buildFilterGroup();
  let inboundDevice = null;
  let outboundDevice = null;
  let options = [];

  if (selectedInterface) {
    inboundFilterGroup = getInterfaceFilterGroup({ inf: selectedInterface, direction: 'input' });
  } else if (inboundInterfaces.length > 0) {
    inboundFilterGroup = getInterfacesFilterGroup({ interfaces: inboundInterfaces, direction: 'input' });
    inboundDevice = inboundInterfaces[0].device_name;
  }

  if (outboundInterfaces.length > 0) {
    outboundFilterGroup = getInterfacesFilterGroup({ interfaces: outboundInterfaces, direction: 'input' });
    outboundDevice = outboundInterfaces[0].device_name;
  }

  if (inboundFilterGroup.filterGroups.length > 0 && outboundFilterGroup.filterGroups.length > 0) {
    options = options.concat([
      {
        label: 'Bidirectional Link Traffic',
        value: 'total',
        query: {
          inboundQuery: {
            filters: inboundFilterGroup,
            query_title: selectedInterface
              ? `Traffic to ${selectedInterface.device_name} (${selectedInterface.interface_description})`
              : `Traffic to ${inboundDevice}`,
            renderOptions: {
              source: selectedInterface ? 'Ingress' : outboundDevice,
              target: selectedInterface ? null : inboundDevice,
              dimensions
            }
          },
          outboundQuery: {
            filters: outboundFilterGroup,
            query_title: selectedInterface
              ? `Traffic from ${selectedInterface.device_name} (${selectedInterface.interface_description})`
              : `Traffic from ${inboundDevice}`,
            renderOptions: {
              source: selectedInterface ? 'Egress' : inboundDevice,
              target: selectedInterface ? null : outboundDevice,
              dimensions
            }
          }
        }
      }
    ]);
  }

  options = options.concat({
    label: 'All Traffic',
    value: 'all',
    query: {
      filters: buildFilterGroup({
        connector: 'Any',
        filterGroups: [...inboundFilterGroup.filterGroups, ...outboundFilterGroup.filterGroups]
      }),
      query_title: selectedInterface
        ? `All ${selectedInterface.device_name} (${
            selectedInterface.interface_description || 'No Description'
          }) Traffic`
        : `All Traffic Between ${inboundDevice} and ${outboundDevice}`,
      renderOptions: { dimensions }
    }
  });

  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getInterfaceCountersConfig(connections) {
  return connections.reduce(
    (acc, connection) => {
      const { interface1, interface2 } = connection;

      // unique device names we'll use in the 'device_name' part of our query
      acc.devices[interface1.device_name] = null;

      if (interface2) {
        acc.devices[interface2.device_name] = null;
      }

      const sourceFilterGroup = buildFilterGroup({
        filters: [
          {
            filterField: 'ktappprotocol__snmp__i_device_name',
            operator: '=',
            filterValue: interface1.device_name
          },

          {
            filterField: 'ktappprotocol__snmp__output_port',
            operator: '=',
            filterValue: (interface1.snmp_id || 0).toString()
          }
        ]
      });

      if (interface2) {
        const targetFilterGroup = buildFilterGroup({
          filters: [
            {
              filterField: 'ktappprotocol__snmp__i_device_name',
              operator: '=',
              filterValue: interface2.device_name
            },

            {
              filterField: 'ktappprotocol__snmp__output_port',
              operator: '=',
              filterValue: (interface2.snmp_id || 0).toString()
            }
          ]
        });

        acc.filters.filterGroups = acc.filters.filterGroups.concat(targetFilterGroup);
      }

      // build up the filter group to match any pairs of device/interface groups
      acc.filters.filterGroups = acc.filters.filterGroups.concat(sourceFilterGroup);

      // preserve the connection as we'll use that to cross-check against the resultset
      acc.connections.push(connection);

      return acc;
    },
    {
      devices: {},
      connections: [],
      filters: { connector: 'Any', filterGroups: [] }
    }
  );
}

export function getLinkPopoverQueryOptions({ source, target, internetSubType, detail, topology }) {
  const { connectedTargets } = target;
  const targetType = target.value;
  let options = [];

  if (source.type === 'internet') {
    const sourceLabel = source.subType === 'provider' ? source.value : `AS ${source.asNumber}`;
    const inboundFilter = getInternetFilterGroup({ ...source, direction: 'dst' });
    const outboundFilter = getInternetFilterGroup({ ...source, direction: 'src' });
    const siteDimensions = ALL_GROUPS_EXCLUDING_CLOUD;
    const deviceDimensions = ALL_GROUPS_EXCLUDING_CLOUD;

    if (targetType === 'site') {
      const baseQuery = {
        all_devices: false,
        device_sites: uniq(connectedTargets.map((t) => t.id)),
        metric: ['i_device_site_name']
      };

      // internet <-> sites
      options = [
        {
          label: 'Traffic With Connected Sites',
          value: 'trafficWithConnectedSites',
          query: {
            inboundQuery: {
              ...baseQuery,
              query_title: `Traffic From ${source.value} To Connected Sites`,
              filters: outboundFilter,
              renderOptions: { source: sourceLabel, target: 'Connected Sites', dimensions: siteDimensions }
            },
            outboundQuery: {
              ...baseQuery,
              query_title: `Traffic From Connected Sites To ${source.value}`,
              filters: inboundFilter,
              renderOptions: { source: 'Connected Sites', target: sourceLabel, dimensions: siteDimensions }
            }
          }
        }
      ];
    }

    if (targetType === 'device') {
      const baseQuery = {
        all_devices: false,
        device_name: connectedTargets.map((t) => t.model.get('device_name')),
        metric: ['i_device_id']
      };

      // internet <-> devices
      options = [
        {
          label: 'Traffic With Connected Devices',
          value: 'trafficWithConnectedDevices',
          query: {
            inboundQuery: {
              ...baseQuery,
              query_title: `Traffic From ${source.value} To Connected Devices`,
              filters: outboundFilter,
              renderOptions: { source: sourceLabel, target: 'Connected Devices', dimensions: deviceDimensions }
            },
            outboundQuery: {
              ...baseQuery,
              query_title: `Traffic From Connected Devices To ${source.value}`,
              filters: inboundFilter,
              renderOptions: { source: 'Connected Devices', target: sourceLabel, dimensions: deviceDimensions }
            }
          }
        }
      ];
    }
  }

  if (source.type === 'site') {
    const internetDimensions = ALL_GROUPS_EXCLUDING_CLOUD;
    const deviceDimensions = ALL_GROUPS_EXCLUDING_CLOUD;

    if (targetType === 'internet') {
      const targetLabel = `${internetTypeLabels[internetSubType]}s`;
      const baseQuery = {
        all_devices: false,
        device_sites: [source.id]
      };

      options = [
        {
          label: `Traffic With Top ${targetLabel}`,
          value: 'trafficWithTopInternet',
          query: {
            inboundQuery: {
              ...baseQuery,
              metric: [getInternetDimension({ subType: internetSubType, direction: 'src' })],
              filters: getInternetTargetsFilterGroup({ targets: target.connectedTargets, direction: 'src' }),
              query_title: `Traffic From Top ${targetLabel} To ${source.value}`,
              renderOptions: {
                source: `Top ${targetLabel}`,
                target: source.value,
                dimensions: { ...internetDimensions, direction: 'src' }
              }
            },
            outboundQuery: {
              ...baseQuery,
              metric: [getInternetDimension({ subType: internetSubType, direction: 'dst' })],
              filters: getInternetTargetsFilterGroup({ targets: target.connectedTargets, direction: 'dst' }),
              query_title: `Traffic From ${source.value} To Top ${targetLabel}`,
              renderOptions: {
                source: source.value,
                target: `Top ${targetLabel}`,
                dimensions: { ...internetDimensions, direction: 'dst' }
              }
            }
          }
        }
      ];
    }

    if (targetType === 'device') {
      if ($hybridMap.interSiteTrafficEnabled) {
        const trafficType = $hybridMap.settingsModel.get('interSiteTraffic');
        const selectedSiteModel = source.model;
        const detailSiteModel = detail.model;
        const sourceSiteName = selectedSiteModel.get('title');
        const targetSiteName = detailSiteModel.get('title');

        return $hybridMap.mergeSidebarQueryOptions([
          {
            label: 'Inter-Site Traffic',
            value: 'total',
            query: {
              inboundQuery: {
                ...getSiteDeviceNodeLinkQuery(selectedSiteModel, detailSiteModel, {
                  direction: 'inbound',
                  type: trafficType,
                  isPopoverQuery: true
                }),
                renderOptions: { source: sourceSiteName, target: targetSiteName, dimensions: deviceDimensions }
              },
              outboundQuery: {
                ...getSiteDeviceNodeLinkQuery(selectedSiteModel, detailSiteModel, {
                  direction: 'outbound',
                  type: trafficType,
                  isPopoverQuery: true
                }),
                renderOptions: { source: targetSiteName, target: sourceSiteName, dimensions: deviceDimensions }
              }
            }
          }
        ]);
      }

      const connectedSitesQueries = getConnectedSitesQueries({
        source: source.model.get('id'),
        target: detail.model.get('id'),
        links: topology.siteLinks
      });

      if (connectedSitesQueries) {
        const { inboundQuery, outboundQuery } = connectedSitesQueries;
        const siteName = source.model.get('title');
        const detailSiteName = detail.model.get('title');

        options = [
          {
            label: 'Traffic with Connected Devices',
            value: 'targetTraffic',
            query: {
              inboundQuery: {
                ...inboundQuery,
                metric: ['i_device_id'],
                query_title: `Traffic From ${detailSiteName} To ${siteName}`,
                renderOptions: { source: detailSiteName, target: siteName, dimensions: deviceDimensions }
              },
              outboundQuery: {
                ...outboundQuery,
                metric: ['i_device_id'],
                query_title: `Traffic From ${siteName} To ${detailSiteName}`,
                renderOptions: { source: siteName, target: detailSiteName, dimensions: deviceDimensions }
              }
            }
          }
        ];
      }
    }
  }

  if (source.type === 'device') {
    const deviceName = source.model.get('device_name');
    const baseQuery = {
      all_devices: false,
      device_name: [deviceName]
    };
    const internetDimensions = ALL_GROUPS_EXCLUDING_CLOUD;
    const siteDimensions = ALL_GROUPS_EXCLUDING_CLOUD;

    if (targetType === 'internet') {
      const targetLabel = `${internetTypeLabels[internetSubType]}s`;

      options = [
        {
          label: `Traffic With Top ${targetLabel}`,
          value: 'trafficWithTopInternet',
          query: {
            inboundQuery: {
              ...baseQuery,
              metric: [getInternetDimension({ subType: internetSubType, direction: 'src' })],
              filters: getInternetTargetsFilterGroup({ targets: target.connectedTargets, direction: 'src' }),
              query_title: `Traffic From Top ${targetLabel} To ${deviceName}`,
              renderOptions: {
                source: `Top ${targetLabel}`,
                target: deviceName,
                columnLabel: 'Ingress',
                dimensions: { ...internetDimensions, direction: 'src' }
              }
            },
            outboundQuery: {
              ...baseQuery,
              metric: [getInternetDimension({ subType: internetSubType, direction: 'dst' })],
              filters: getInternetTargetsFilterGroup({ targets: target.connectedTargets, direction: 'dst' }),
              query_title: `Traffic From ${deviceName} To Top ${targetLabel}`,
              renderOptions: {
                source: deviceName,
                target: `Top ${targetLabel}`,
                columnLabel: 'Egress',
                dimensions: { ...internetDimensions, direction: 'dst' }
              }
            }
          }
        }
      ];
    }

    if (targetType === 'site') {
      if ($hybridMap.interSiteTrafficEnabled) {
        const trafficType = $hybridMap.settingsModel.get('interSiteTraffic');
        const deviceModel = source.model;
        const siteName = detail.model.get('title');

        return $hybridMap.mergeSidebarQueryOptions([
          {
            label: 'Inter-Site Traffic',
            value: 'total',
            query: {
              inboundQuery: {
                ...getDeviceSiteNodeLinkQuery(deviceModel, siteName, {
                  direction: 'inbound',
                  type: trafficType,
                  isPopoverQuery: true
                }),
                renderOptions: {
                  source: 'Other Sites',
                  target: deviceModel.get('device_name'),
                  dimensions: siteDimensions
                }
              },
              outboundQuery: {
                ...getDeviceSiteNodeLinkQuery(deviceModel, siteName, {
                  direction: 'outbound',
                  type: trafficType,
                  isPopoverQuery: true
                }),
                renderOptions: {
                  source: deviceModel.get('device_name'),
                  target: 'Other Sites',
                  dimensions: siteDimensions
                }
              }
            }
          }
        ]);
      }

      baseQuery.device_sites = connectedTargets.map((t) => t.id);

      // whittle down the site links to those that match with our source device
      const links = topology.siteLinks.filter((l) => l.device1_id === source.value || l.device2_id === source.value);

      const connections = links.reduce(
        (acc, link) => {
          let sourceInterface = null;
          const targetInterfaces = [];

          link.connections.forEach((connection) => {
            sourceInterface = connection.interface1;
            let targetInterface = connection.interface2;

            if (connection.interface2.device_id === source.value) {
              sourceInterface = connection.interface2;
              targetInterface = connection.interface1;
            }

            targetInterfaces.push(targetInterface);
          });

          return {
            sourceInterface,
            targetInterfaces: [...acc.targetInterfaces, ...targetInterfaces]
          };
        },
        {
          sourceInterface: null,
          targetInterfaces: []
        }
      );

      const inboundFilter = getInterfacesFilterGroup({ interfaces: connections.targetInterfaces, direction: 'input' });
      const outboundFilter = getInterfacesFilterGroup({
        interfaces: connections.targetInterfaces,
        direction: 'output'
      });

      options = [
        {
          label: 'Traffic with Connected Sites',
          value: 'targetTraffic',
          query: {
            inboundQuery: {
              ...baseQuery,
              metric: ['i_device_site_name'],
              filters: inboundFilter,
              query_title: `Traffic From Sites To ${deviceName}`,
              renderOptions: {
                source: 'Connected Sites',
                target: deviceName,
                columnLabel: 'Ingress',
                dimensions: siteDimensions
              }
            },
            outboundQuery: {
              ...baseQuery,
              metric: ['i_device_site_name'],
              filters: outboundFilter,
              query_title: `Traffic From ${deviceName} To Sites`,
              renderOptions: {
                source: deviceName,
                target: 'Connected Sites',
                columnLabel: 'Egress',
                dimensions: siteDimensions
              }
            }
          }
        }
      ];
    }
  }

  if (source.type === 'cloud') {
    const { code } = source;
    const targetLabel = `${internetTypeLabels[internetSubType]}s`;
    const cloudFilterValue = code?.toLowerCase();
    const internetDimensions = ALL_GROUPS_EXCLUDING_CLOUD;
    const siteDimensions = ALL_GROUPS_EXCLUDING_CLOUD;

    if (targetType === 'internet') {
      options = [
        {
          label: `Traffic With Top ${targetLabel}`,
          value: 'trafficWithTopInternet',
          query: {
            inboundQuery: {
              query_title: `Traffic From Top ${targetLabel} To ${code}`,
              metric: [getInternetDimension({ subType: internetSubType, direction: 'src' })],
              filters: {
                connector: 'All',
                filterGroups: [
                  buildFilterGroup({
                    filters: [
                      {
                        filterField: 'i_trf_termination',
                        operator: '=',
                        filterValue: cloudFilterValue
                      },

                      {
                        filterField: 'i_trf_profile',
                        operator: '=',
                        filterValue: 'from outside to cloud'
                      }
                    ]
                  }),
                  getInternetTargetsAnyMatchingFilterGroup({ targets: target.connectedTargets, direction: 'src' })
                ]
              },
              renderOptions: {
                source: `Top ${targetLabel}`,
                target: code,
                dimensions: { ...internetDimensions, direction: 'src' }
              }
            },
            outboundQuery: {
              query_title: `Traffic From ${code} To Top ${targetLabel}`,
              metric: [getInternetDimension({ subType: internetSubType, direction: 'dst' })],
              filters: {
                connector: 'All',
                filterGroups: [
                  buildFilterGroup({
                    filters: [
                      {
                        filterField: 'i_trf_origination',
                        operator: '=',
                        filterValue: cloudFilterValue
                      },

                      {
                        filterField: 'i_trf_profile',
                        operator: '=',
                        filterValue: 'from cloud to outside'
                      }
                    ]
                  }),
                  getInternetTargetsAnyMatchingFilterGroup({ targets: target.connectedTargets, direction: 'dst' })
                ]
              },
              renderOptions: {
                source: code,
                target: `Top ${targetLabel}`,
                dimensions: { ...internetDimensions, direction: 'dst' }
              }
            }
          }
        }
      ];
    }

    if (targetType === 'site') {
      const baseQuery = {
        all_devices: false,
        device_types: [`${cloudFilterValue}_subnet`],
        metric: ['kt_dst_site_title']
      };

      options = [
        {
          label: 'Traffic With Connected Sites',
          value: 'trafficWithConnectedSites',
          query: {
            inboundQuery: {
              ...baseQuery,
              query_title: `Traffic From ${code} To Connected Sites`,
              filters: {
                connector: 'All',
                filterGroups: [
                  buildFilterGroup({
                    filters: [
                      {
                        filterField: 'i_trf_origination',
                        operator: '=',
                        filterValue: cloudFilterValue
                      },

                      {
                        filterField: 'i_trf_profile',
                        operator: '=',
                        filterValue: 'from cloud to inside'
                      }
                    ]
                  })
                ]
              },
              renderOptions: { source: code, target: 'On Prem', dimensions: siteDimensions }
            },

            outboundQuery: {
              ...baseQuery,
              query_title: `Traffic From Connected Sites To ${code}`,
              filters: {
                connector: 'All',
                filterGroups: [
                  buildFilterGroup({
                    filters: [
                      {
                        filterField: 'i_trf_termination',
                        operator: '=',
                        filterValue: cloudFilterValue
                      },

                      {
                        filterField: 'i_trf_profile',
                        operator: '=',
                        filterValue: 'from inside to cloud'
                      }
                    ]
                  })
                ]
              },
              renderOptions: { source: 'On Prem', target: code, dimensions: siteDimensions }
            }
          }
        }
      ];
    }
  }

  if (options.length > 0) {
    return $hybridMap.mergeSidebarQueryOptions(options);
  }

  return null;
}

function getCloudInterconnectFilterGroup({ sources, key, cloudProvider, paths }) {
  // currently only supports aws
  if (cloudProvider !== 'aws') {
    return null;
  }

  const filterGroup = buildFilterGroup();

  if (sources && sources.length > 0) {
    filterGroup.filterGroups.push(
      buildFilterGroup({
        connector: 'Any',
        filters: sources.map((source) => ({
          filterField: AWS.SRC.VPC_ID,
          operator: '=',
          filterValue: source
        }))
      })
    );
  }

  if (key && key.type !== 'Vpc' && key.type !== 'VirtualInterface' && key.type !== 'link' && key.type !== 'Router') {
    filterGroup.filterGroups.push(
      buildFilterGroup({
        filters: [
          {
            filterField: AWS.DST.GATEWAY_ID,
            operator: '=',
            filterValue: key.value
          }
        ]
      })
    );
  }

  if (paths && paths.length) {
    const { gatewayIds } = getGatewayIdsAndIpsFromPaths(paths);

    if (gatewayIds && gatewayIds.length) {
      filterGroup.filterGroups.push(
        buildFilterGroup({
          connector: 'Any',
          filters: gatewayIds.map((id) => ({
            filterField: AWS.DST.GATEWAY_ID,
            operator: '=',
            filterValue: id
          }))
        })
      );
    }
  }

  return filterGroup;
}

function getCustomerGatewayFilterGroup({ gatewayIds, ips, vpcIds, direction = 'outbound' } = {}) {
  const filterGroup = buildFilterGroup();

  if (ips && ips.length) {
    filterGroup.filterGroups.push(
      buildFilterGroup({
        connector: 'Any',
        filters: ips.map((ip) => ({
          filterField: `inet_${direction === 'outbound' ? 'dst' : 'src'}_addr`,
          operator: 'ILIKE',
          filterValue: ip
        }))
      })
    );
  }

  if (direction === 'outbound' && gatewayIds && gatewayIds.length) {
    filterGroup.filterGroups.push(
      buildFilterGroup({
        connector: 'Any',
        filters: gatewayIds.map((id) => ({
          filterField: AWS.DST.GATEWAY_ID,
          operator: '=',
          filterValue: id
        }))
      })
    );
  }

  if (direction === 'inbound' && vpcIds && vpcIds.length) {
    filterGroup.filterGroups.push(
      buildFilterGroup({
        connector: 'Any',
        filters: vpcIds.map((vpcId) => ({
          filterField: AWS.DST.VPC_ID,
          operator: '=',
          filterValue: vpcId
        }))
      })
    );
  }

  return filterGroup;
}

export function getCustomerGatewayQueryOptions({ value, links, cloudProvider, topology }) {
  const dimensions = { ...ALL_GROUPS_CLOUD, cloudProvider };
  const { gatewayIds, ips, vpcIds } = getRelatedGatewayIdsAndIps({ links, topology, target: value });

  return [
    {
      label: 'Outbound from Cloud',
      value: 'outboundFromCloud',
      query: {
        all_devices: cloudProvider !== 'aws',
        device_types: cloudProvider === 'aws' ? ['aws_subnet'] : [],
        filters: getCustomerGatewayFilterGroup({ gatewayIds, ips, direction: 'outbound' }),
        query_title: `Outbound from Cloud to ${value}`,
        renderOptions: {
          source: 'Cloud',
          target: value,
          dimensions
        }
      }
    },

    {
      label: 'Inbound from On-Prem',
      value: 'inboundFromOnPrem',
      query: {
        all_devices: cloudProvider !== 'aws',
        device_types: cloudProvider === 'aws' ? ['aws_subnet'] : [],
        filters: getCustomerGatewayFilterGroup({ ips, vpcIds, direction: 'inbound' }),
        query_title: `Inbound from ${value} to Cloud`,
        renderOptions: {
          source: value,
          target: 'Cloud',
          dimensions
        }
      }
    }
  ];
}

export function getCloudInterconnectQueryOptions({
  subType,
  value,
  links,
  cloudProvider,
  metadata,
  paths,
  name,
  nodeData,
  topology
}) {
  const key = { type: subType, value };
  let sources = [];
  let destinations = [];

  if (subType === 'TransitGateway') {
    return getCloudTransitGatewayQueryOptions({ cloudProvider, name, value, nodeData, topology });
  }

  if (subType === 'link') {
    const sourcesAndDestinations = paths.reduce(
      (acc, path) => {
        acc.sources.add(path[0].id);
        acc.destinations.add(path[path.length - 1].id);

        return acc;
      },
      {
        sources: new Set(),
        destinations: new Set()
      }
    );

    sources = Array.from(sourcesAndDestinations.sources);
    destinations = Array.from(sourcesAndDestinations.destinations);
  } else {
    // inspect all the paths where this key particpates and break out into ultimate sources and destinations
    const sourcesAndDestinations = links.reduce(
      (acc, link) => {
        const foundPaths = link.paths.filter((p) =>
          p.find((node) => node && key.type === node.type && key.value === node.id)
        );

        if (foundPaths.length > 0) {
          foundPaths.forEach((p) => {
            acc.sources.add(p[0].id);
            acc.destinations.add(p[p.length - 1].id);
          });
        }

        return acc;
      },
      {
        sources: new Set(),
        destinations: new Set()
      }
    );

    sources = Array.from(sourcesAndDestinations.sources);
    destinations = Array.from(sourcesAndDestinations.destinations);
  }

  // get the filter group based on sources, destinations, and key
  const filterGroup = getCloudInterconnectFilterGroup({ sources, destinations, key, cloudProvider, paths });

  if (filterGroup) {
    const dimensions = { ...ALL_GROUPS_CLOUD, cloudProvider };
    const keyName = (metadata && metadata.name) || '';
    let linkFrom = '';
    let linkTo = '';

    // assume a middle node
    let queryTitle = `Traffic through ${keyName}`;

    if (key.type === 'Vpc') {
      queryTitle = `Traffic from ${keyName}`;
    }

    if (key.type === 'VirtualInterface') {
      queryTitle = `Traffic to ${keyName}`;
    }

    if (key.type === 'link') {
      // default to the first path's entities
      const firstPath = paths[0];
      linkFrom = firstPath[0].metadata.name;
      linkTo = firstPath[firstPath.length - 1].metadata.name;

      if (sources.length > 1) {
        linkFrom = `${sources.length} sources`;
      }

      if (destinations.length > 1) {
        linkTo = `${destinations.length} targets`;
      }

      queryTitle = `Traffic between ${linkFrom} and ${linkTo}`;
    }

    let options = [
      {
        label: 'Total Traffic',
        value: 'cloudInterconnectTraffic',
        query: {
          all_devices: cloudProvider !== 'aws',
          device_types: cloudProvider === 'aws' ? ['aws_subnet'] : [],
          filters: filterGroup,
          query_title: queryTitle,
          renderOptions: {
            source: key.type === 'link' ? linkFrom : queryTitle,
            target: key.type === 'link' ? linkTo : null,
            dimensions
          }
        }
      }
    ];

    if (key.type === 'Vpc') {
      // get all the gateway ids in this vpc's path
      const gatewayIds = Array.from(
        links.reduce((acc, link) => {
          if (link.paths) {
            // each link has an array of paths that it participates in
            link.paths.forEach((path) => {
              if (path.find((item) => item.type === 'Vpc' && item.id === key.value)) {
                // the selected vpc participates in this path, get all the gateway ids
                const gatewayIdsInPath = path.reduce((pathAcc, item) => {
                  if (item.type === 'TransitGateway' || item.type === 'VpnGateway' || item.type === 'CustomerGateway') {
                    pathAcc.push(item.id);
                  }

                  return pathAcc;
                }, []);

                gatewayIdsInPath.forEach((id) => acc.add(id));
              }
            });
          }

          return acc;
        }, new Set())
      );

      if (gatewayIds.length > 0) {
        options = options.concat({
          label: 'From VPC to Gateway',
          value: 'fromVPCToGateway',
          query: {
            all_devices: cloudProvider !== 'aws',
            device_types: cloudProvider === 'aws' ? ['aws_subnet'] : [],
            filters: addFilterGroup(
              cloneDeep(filterGroup),
              'Any',
              false,
              gatewayIds.map((gatewayId) => ({
                filterField: AWS.DST.GATEWAY_ID,
                operator: '=',
                filterValue: gatewayId
              }))
            ),
            query_title: `From ${key.value} to gateways`,
            renderOptions: {
              source: key.value,
              target: 'Gateways',
              dimensions
            }
          }
        });
      }
    }

    return options;
  }

  return null;
}

export function getGCPInterconnectAttachmentQueryOptions(config) {
  const { value } = config;
  const dimensions = [GCP.INTERCONNECT_NAME, GCP.INTERCONNECT_TYPE];

  const interconnectAttachmentMatchFilter = buildFilterGroup({
    name: 'Interconnect Attachment Match',
    filters: [
      {
        filterField: GCP.INTERCONNECT_NAME,
        operator: '=',
        filterValue: value
      }
    ]
  });

  // inbound is when source gateway name is unknown
  const inboundFilter = buildFilterGroup({
    filterGroups: [interconnectAttachmentMatchFilter]
  });

  // outbount is when destination gateway name is unknown
  const outboundFilter = buildFilterGroup({
    filterGroups: [interconnectAttachmentMatchFilter]
  });

  const options = [
    {
      label: 'All Traffic',
      value: 'all',
      query: {
        all_devices: false,
        device_types: [GCP.DATASET],
        filters: buildFilterGroup({
          filterGroups: [interconnectAttachmentMatchFilter]
        }),
        query_title: `All ${value} Traffic`,
        renderOptions: { dimensions }
      }
    },
    {
      label: 'Inbound/Outbound Traffic',
      value: 'total',
      query: {
        all_devices: false,
        device_types: [GCP.DATASET],
        inboundQuery: {
          all_devices: false,
          device_types: [GCP.DATASET],
          query_title: `Traffic To ${value}`,
          filters: inboundFilter,
          renderOptions: { source: 'Inbound', dimensions }
        },

        outboundQuery: {
          all_devices: false,
          device_types: [GCP.DATASET],
          query_title: `Traffic From ${value}`,
          filters: outboundFilter,
          renderOptions: { source: 'Outbound', dimensions }
        }
      }
    }
  ];
  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getAzureExpressRouteCircuitQueryOptions(config) {
  const { value } = config;
  const dimensions = [
    AZURE.SRC.GATEWAY_NAME,
    AZURE.DST.GATEWAY_NAME,
    AZURE.EXPRESS_ROUTE_CIRCUIT,
    AZURE.EXPRESS_ROUTE_PEERING_TYPE
  ];

  const expressRouteCircuitMatchFilter = buildFilterGroup({
    name: 'Express Route Circuit Match',
    filters: [
      {
        filterField: AZURE.EXPRESS_ROUTE_CIRCUIT,
        operator: '=',
        filterValue: value
      }
    ]
  });

  // inbound is when source gateway name is unknown
  const inboundFilter = buildFilterGroup({
    filterGroups: [
      expressRouteCircuitMatchFilter,
      buildFilterGroup({
        name: 'Source Gateway Name Unknown',
        filters: [
          {
            filterField: AZURE.SRC.GATEWAY_NAME,
            operator: '!=',
            filterValue: ''
          },
          {
            filterField: AZURE.SRC.GATEWAY_NAME,
            operator: '!=',
            filterValue: '0'
          }
        ]
      })
    ]
  });

  // outbount is when destination gateway name is unknown
  const outboundFilter = buildFilterGroup({
    filterGroups: [
      expressRouteCircuitMatchFilter,
      buildFilterGroup({
        name: 'Destination Gateway Name Unknown',
        filters: [
          {
            filterField: AZURE.DST.GATEWAY_NAME,
            operator: '!=',
            filterValue: ''
          },
          {
            filterField: AZURE.DST.GATEWAY_NAME,
            operator: '!=',
            filterValue: '0'
          }
        ]
      })
    ]
  });

  const options = [
    {
      label: 'All Traffic',
      value: 'all',
      query: {
        all_devices: false,
        device_types: [AZURE.DATASET],
        filters: buildFilterGroup({
          filterGroups: [expressRouteCircuitMatchFilter]
        }),
        query_title: `All ${value} Traffic`,
        renderOptions: { dimensions }
      }
    },
    {
      label: 'Inbound/Outbound Traffic',
      value: 'total',
      query: {
        all_devices: false,
        device_types: [AZURE.DATASET],
        inboundQuery: {
          all_devices: false,
          device_types: [AZURE.DATASET],
          query_title: `Traffic To ${value}`,
          filters: inboundFilter,
          renderOptions: { source: 'Inbound', dimensions }
        },

        outboundQuery: {
          all_devices: false,
          device_types: [AZURE.DATASET],
          query_title: `Traffic From ${value}`,
          filters: outboundFilter,
          renderOptions: { source: 'Outbound', dimensions }
        }
      }
    }
  ];
  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getOCILinkQueryOptions(config) {
  const { initialLinkQuery } = config;

  if (config?.type !== 'link') {
    return null;
  }

  if (!initialLinkQuery) {
    return null;
  }

  const { filters } = initialLinkQuery;

  return $hybridMap.mergeSidebarQueryOptions([
    {
      label: 'All Traffic',
      value: 'allTraffic',
      query: {
        all_devices: false,
        device_types: [OCI.DATASET],
        filters,
        renderOptions: { dimensions: ALL_GROUPS_CLOUD },
        query_title: `Traffic through ${config.source.value} --- ${config.target.value}`
      }
    }
  ]);
}

export function getAWSLinkQueryOptions(config) {
  const { links, topology } = config;

  if (config && config.type === 'link') {
    const gatewayTypes = ['gateway', 'InternetGateways', 'TransitGateways', 'NatGateways', 'TransitGatewayAttachments'];

    const sourceTargetFilters = [config.source, config.target].reduce(
      (acc, entity) => {
        if (entity.type === 'subnet') {
          acc.filters.push({
            filterField: AWS.BI.SUBNET_ID,
            operator: '=',
            filterValue: entity.value
          });
        }

        if (gatewayTypes.includes(entity.type)) {
          if (topology.SubnetGatewayNetworkInterfaceMap) {
            // attempt to get all the network interface ids associated with this gateway id
            const networkInterfaceIds = Object.keys(topology.SubnetGatewayNetworkInterfaceMap).reduce(
              (mapAcc, networkInterfaceId) => {
                const item = topology.SubnetGatewayNetworkInterfaceMap[networkInterfaceId];

                if (item.parsedId === entity.value || item.gatewayId === entity.value) {
                  return mapAcc.concat(networkInterfaceId);
                }

                return mapAcc;
              },
              []
            );

            if (networkInterfaceIds.length > 0) {
              // if we have network interface ids associated with this gateway, use those as we can specify both directions
              acc.filterGroups.push(
                buildFilterGroup({
                  connector: 'Any',
                  filters: networkInterfaceIds.map((networkInterfaceId) => ({
                    filterField: AWS.BI.INTERFACE_ID,
                    operator: '=',
                    filterValue: networkInterfaceId
                  }))
                })
              );
            } else {
              // when we can't find network interface ids for this gateway id, fallback is to exclude all the others
              acc.filterGroups.push(
                buildFilterGroup({
                  connector: 'Any',
                  not: true,
                  filters: Object.keys(topology.SubnetGatewayNetworkInterfaceMap).map((networkInterfaceId) => ({
                    filterField: AWS.BI.INTERFACE_ID,
                    operator: '=',
                    filterValue: networkInterfaceId
                  }))
                })
              );
            }
          } else {
            // when we don't even have a network interface map to work with, define a filter with the destination gateway id as a final fallback
            acc.filters.push({
              filterField: AWS.DST.GATEWAY_ID,
              operator: '=',
              filterValue: entity.value
            });
          }
        }

        if (entity.type === 'CustomerGateways') {
          const { gatewayIds, ips, vpcIds } = getRelatedGatewayIdsAndIps({ links, topology, target: entity.value });
          const inboundFilterGroup = getCustomerGatewayFilterGroup({ ips, vpcIds, direction: 'inbound' });
          const outboundFilterGroup = getCustomerGatewayFilterGroup({ gatewayIds, ips, direction: 'outbound' });

          acc.filterGroups.push({
            connector: 'Any',
            filterGroups: [inboundFilterGroup, outboundFilterGroup]
          });
        }

        if (entity.type === 'cidr') {
          acc.filters.push({
            filterField: 'inet_src|dst_addr',
            operator: 'ILIKE',
            filterValue: entity.value
          });
        }

        if (entity.type === 'Routers' && entity.value > -1) {
          acc.filters.push({
            filterField: 'i_device_id',
            operator: '=',
            filterValue: entity.value.toString()
          });
        }

        if (entity.type === 'region') {
          acc.filters.push({
            filterField: AWS.BI.REGION,
            operator: '=',
            filterValue: entity.value
          });
        }

        if (entity.type === 'box' && entity.value === 'internet') {
          acc.filterGroups.push(
            buildFilterGroup({
              connector: 'Any',
              filters: [
                {
                  filterField: 'i_trf_profile',
                  operator: '=',
                  filterValue: 'from outside to cloud'
                },

                {
                  filterField: 'i_trf_profile',
                  operator: '=',
                  filterValue: 'from cloud to outside'
                }
              ]
            })
          );
        }

        return acc;
      },
      {
        filters: [],
        filterGroups: []
      }
    );

    return $hybridMap.mergeSidebarQueryOptions([
      {
        label: 'All Traffic',
        value: 'allTraffic',
        query: {
          all_devices: false,
          device_types: [AWS.DATASET],
          query_title: `Traffic through ${config.source.value} --- ${config.target.value}`,
          filters: buildFilterGroup({
            filterGroups: [buildFilterGroup({ ...sourceTargetFilters })]
          }),
          renderOptions: { dimensions: ALL_GROUPS_CLOUD }
        }
      }
    ]);
  }

  return null;
}

export function getVpcEndpointQueryOptions(config) {
  let filterField;
  let filterValue;
  if (config.nodeData?.VpcEndpointType === 'Interface') {
    filterField = AWS.BI.INTERFACE_ID;
    filterValue = config.nodeData?.NetworkInterfaceIds?.[0];
  } else {
    filterField = AWS.DST.GATEWAY_ID;
    filterValue = config.value;
  }
  const vpcEndpointQuery = {
    all_devices: false,
    device_types: ['aws_subnet'],
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [{ filterField, operator: '=', filterValue }]
        })
      ]
    },
    query_title: `Vpc Endpoint traffic from ${config.value}`,
    renderOptions: { source: config.value }
  };

  return $hybridMap.mergeSidebarQueryOptions([
    {
      label: 'Vpc Endpoint Traffic',
      value: 'total',
      query: vpcEndpointQuery
    }
  ]);
}

export function getAzureLoadBalancerQueryOptions({ cloudProvider, value }) {
  if (value) {
    const baseQuery = {
      all_devices: false,
      device_types: [`${cloudProvider}_subnet`]
    };
    const filterValue = value.toLowerCase();
    const dimensions = { ...ALL_GROUPS_CLOUD, cloudProvider };
    const srcFilter = getDimensionFilter({ cloudProvider, direction: 'src', type: 'load_balancer', filterValue });
    const dstFilter = getDimensionFilter({ cloudProvider, direction: 'dst', type: 'load_balancer', filterValue });
    const biDirectionalFilter = getDimensionFilter({ cloudProvider, type: 'load_balancer', filterValue });

    const options = [
      {
        label: 'All Traffic',
        value: 'all',
        query: {
          ...baseQuery,
          filters: buildFilterGroup({
            filterGroups: [
              buildFilterGroup({
                filters: [biDirectionalFilter]
              })
            ]
          }),
          query_title: `All ${value} Traffic`,
          renderOptions: { dimensions }
        }
      },

      {
        label: 'Internet Traffic',
        value: 'internetTraffic',
        query: {
          inboundQuery: {
            ...baseQuery,
            filters: {
              connector: 'All',
              filterGroups: [
                buildFilterGroup({
                  filters: [
                    biDirectionalFilter,
                    {
                      filterField: 'i_trf_profile',
                      operator: '=',
                      filterValue: 'from outside to cloud'
                    }
                  ]
                })
              ]
            },
            query_title: `Traffic From Outside To ${value}`,
            renderOptions: {
              source: 'Inbound',
              dimensions: { ...dimensions, direction: 'src' }
            }
          },
          outboundQuery: {
            ...baseQuery,
            filters: {
              connector: 'All',
              filterGroups: [
                buildFilterGroup({
                  filters: [
                    biDirectionalFilter,
                    {
                      filterField: 'i_trf_profile',
                      operator: '=',
                      filterValue: 'from cloud to outside'
                    }
                  ]
                })
              ]
            },
            query_title: `Traffic From ${value} To Outside`,
            renderOptions: {
              source: 'Outbound',
              dimensions: { ...dimensions, direction: 'dst' }
            }
          }
        }
      },

      {
        label: 'Internal Traffic',
        value: 'internalTraffic',
        query: {
          ...baseQuery,
          filters: {
            connector: 'All',
            filterGroups: [
              buildFilterGroup({
                filters: [
                  biDirectionalFilter,
                  {
                    filterField: 'i_trf_profile',
                    operator: '=',
                    filterValue: 'cloud internal'
                  }
                ]
              })
            ]
          },
          query_title: `Internal Traffic With ${value}`,
          renderOptions: { source: 'Internal Traffic' }
        }
      },

      {
        label: 'Inbound/Outbound Internal Traffic',
        value: 'internalTrafficDirectional',
        query: {
          inboundQuery: {
            ...baseQuery,
            filters: {
              connector: 'All',
              filterGroups: [
                buildFilterGroup({
                  filters: [
                    dstFilter,
                    {
                      filterField: 'i_trf_profile',
                      operator: '=',
                      filterValue: 'cloud internal'
                    }
                  ]
                })
              ]
            },
            query_title: `Internal Traffic To ${value}`,
            renderOptions: {
              source: 'Inbound',
              dimensions: { ...dimensions, direction: 'src' }
            }
          },

          outboundQuery: {
            ...baseQuery,
            filters: {
              connector: 'All',
              filterGroups: [
                buildFilterGroup({
                  filters: [
                    srcFilter,
                    {
                      filterField: 'i_trf_profile',
                      operator: '=',
                      filterValue: 'cloud internal'
                    }
                  ]
                })
              ]
            },
            query_title: `Internal Traffic From ${value}`,
            renderOptions: {
              source: 'Outbound',
              dimensions: { ...dimensions, direction: 'dst' }
            }
          }
        }
      },

      {
        label: 'Inter-Load Balancer Traffic',
        value: 'interLoadBalancerTraffic',
        query: {
          inboundQuery: {
            ...baseQuery,
            filters: {
              connector: 'All',
              filterGroups: [
                buildFilterGroup({
                  filters: [
                    dstFilter,

                    {
                      filterField: getDimensionFieldName({ cloudProvider, direction: 'src', type: 'load_balancer' }),
                      operator: '<>',
                      filterValue: value
                    },

                    {
                      filterField: getDimensionFieldName({ cloudProvider, direction: 'src', type: 'load_balancer' }),
                      operator: '<>',
                      filterValue: ''
                    },

                    {
                      filterField: 'i_trf_profile',
                      operator: '=',
                      filterValue: 'cloud internal'
                    }
                  ]
                })
              ]
            },
            query_title: `From Other Load Balancers To ${value}`,
            renderOptions: {
              source: 'Inbound',
              dimensions: { ...dimensions, direction: 'src' }
            }
          },
          outboundQuery: {
            ...baseQuery,
            filters: {
              connector: 'All',
              filterGroups: [
                buildFilterGroup({
                  filters: [
                    srcFilter,

                    {
                      filterField: getDimensionFieldName({ cloudProvider, direction: 'dst', type: 'load_balancer' }),
                      operator: '<>',
                      filterValue: value
                    },

                    {
                      filterField: getDimensionFieldName({ cloudProvider, direction: 'dst', type: 'load_balancer' }),
                      operator: '<>',
                      filterValue: ''
                    },

                    {
                      filterField: 'i_trf_profile',
                      operator: '=',
                      filterValue: 'cloud internal'
                    }
                  ]
                })
              ]
            },
            query_title: `From ${value} To Other Load Balancers`,
            renderOptions: {
              source: 'Outbound',
              dimensions: { ...dimensions, direction: 'dst' }
            }
          }
        }
      },

      {
        label: 'Traffic With My Infrastructure',
        value: 'trafficWithMyInfrastructure',
        disabled: !$hybridMap.hasSites,
        query: {
          inboundQuery: {
            ...baseQuery,
            filters: {
              connector: 'All',
              filterGroups: [
                buildFilterGroup({
                  filters: [
                    biDirectionalFilter,

                    {
                      filterField: 'i_trf_profile',
                      operator: '=',
                      filterValue: 'from inside to cloud'
                    }
                  ]
                })
              ]
            },
            query_title: `From My Infrastructure To ${value}`,
            renderOptions: {
              source: 'Inbound',
              dimensions
            }
          },
          outboundQuery: {
            ...baseQuery,
            filters: {
              connector: 'All',
              filterGroups: [
                buildFilterGroup({
                  filters: [
                    biDirectionalFilter,

                    {
                      filterField: 'i_trf_profile',
                      operator: '=',
                      filterValue: 'from cloud to inside'
                    }
                  ]
                })
              ]
            },
            query_title: `From ${value} To My Infrastructure`,
            renderOptions: {
              source: 'Outbound',
              dimensions
            }
          }
        }
      },

      {
        label: 'Traffic With Other Clouds',
        value: 'trafficWithOtherClouds',
        query: {
          filters: {
            connector: 'All',
            filterGroups: [
              buildFilterGroup({
                filters: [
                  biDirectionalFilter,

                  {
                    filterField: 'i_trf_profile',
                    operator: '=',
                    filterValue: 'multi-cloud'
                  }
                ]
              })
            ]
          },
          query_title: `${value} Traffic With Other Clouds`,
          renderOptions: {
            source: 'Traffic With Other Clouds',
            dimensions
          }
        }
      },

      {
        label: 'Inbound/Outbound Traffic With Other Clouds',
        value: 'trafficWithOtherCloudsDirectional',
        query: {
          inboundQuery: {
            filters: {
              connector: 'All',
              filterGroups: [
                buildFilterGroup({
                  filters: [
                    dstFilter,

                    {
                      filterField: 'i_trf_profile',
                      operator: '=',
                      filterValue: 'multi-cloud'
                    }
                  ]
                })
              ]
            },
            query_title: `Other Cloud Traffic To ${value}`,
            renderOptions: {
              source: 'Inbound',
              dimensions: { ...dimensions, direction: 'src' }
            }
          },

          outboundQuery: {
            filters: {
              connector: 'All',
              filterGroups: [
                buildFilterGroup({
                  filters: [
                    srcFilter,

                    {
                      filterField: 'i_trf_profile',
                      operator: '=',
                      filterValue: 'multi-cloud'
                    }
                  ]
                })
              ]
            },
            query_title: `Other Cloud Traffic From ${value}`,
            renderOptions: {
              source: 'Outbound',
              dimensions: { ...dimensions, direction: 'dst' }
            }
          }
        }
      }
    ];

    return $hybridMap.mergeSidebarQueryOptions(options.filter((o) => !o.disabled));
  }

  return null;
}

export function getAzureFirewallQueryOptions({ cloudProvider, value }) {
  if (value) {
    const baseQuery = {
      all_devices: false,
      device_types: [`${cloudProvider}_subnet`]
    };
    const dimensions = { ...ALL_GROUPS_CLOUD, cloudProvider };
    const firewallFilterGroups = [
      // determines whether logging resource was a firewall
      buildFilterGroup({
        connector: 'Any',
        filters: [
          getDimensionFilter({
            cloudProvider,
            type: 'logging_resource_category',
            filterValue: 'AZFWNetworkRule'
          }),
          getDimensionFilter({
            cloudProvider,
            type: 'logging_resource_category',
            filterValue: 'AzureFirewallNetworkRule'
          }),
          getDimensionFilter({
            cloudProvider,
            type: 'logging_resource_category',
            filterValue: 'AzureFirewallApplicationRule'
          }),
          getDimensionFilter({
            cloudProvider,
            type: 'logging_resource_category',
            filterValue: 'AZFWApplicationRule'
          })
        ]
      }),

      buildFilterGroup({
        connector: 'Any',
        filters: [
          // the logging resource name currently wants the value to be all uppercase
          // there may be changes however that rely on the value being what the user-defined
          getDimensionFilter({
            cloudProvider,
            type: 'logging_resource_name',
            filterValue: value.toUpperCase()
          }),

          getDimensionFilter({
            cloudProvider,
            type: 'logging_resource_name',
            filterValue: value
          })
        ]
      })
    ];

    const options = [
      {
        label: 'All Traffic',
        value: 'all',
        query: {
          ...baseQuery,
          filters: buildFilterGroup({
            filterGroups: firewallFilterGroups
          }),
          query_title: `All ${value} Traffic`,
          renderOptions: { dimensions }
        }
      },

      {
        label: 'Internet Traffic',
        value: 'internetTraffic',
        query: {
          inboundQuery: {
            ...baseQuery,
            filters: {
              connector: 'All',
              filterGroups: [
                ...firewallFilterGroups,
                buildFilterGroup({
                  filters: [
                    {
                      filterField: 'i_trf_profile',
                      operator: '=',
                      filterValue: 'from outside to cloud'
                    }
                  ]
                })
              ]
            },
            query_title: `Traffic From Outside To ${value}`,
            renderOptions: {
              source: 'Inbound',
              dimensions: { ...dimensions, direction: 'src' }
            }
          },
          outboundQuery: {
            ...baseQuery,
            filters: {
              connector: 'All',
              filterGroups: [
                ...firewallFilterGroups,
                buildFilterGroup({
                  filters: [
                    {
                      filterField: 'i_trf_profile',
                      operator: '=',
                      filterValue: 'from cloud to outside'
                    }
                  ]
                })
              ]
            },
            query_title: `Traffic From ${value} To Outside`,
            renderOptions: {
              source: 'Outbound',
              dimensions: { ...dimensions, direction: 'dst' }
            }
          }
        }
      },

      {
        label: 'Internal Traffic',
        value: 'internalTraffic',
        query: {
          ...baseQuery,
          filters: {
            connector: 'All',
            filterGroups: [
              ...firewallFilterGroups,
              buildFilterGroup({
                filters: [
                  {
                    filterField: 'i_trf_profile',
                    operator: '=',
                    filterValue: 'cloud internal'
                  }
                ]
              })
            ]
          },
          query_title: `Internal Traffic With ${value}`,
          renderOptions: { source: 'Internal Traffic' }
        }
      },

      {
        label: 'Traffic With My Infrastructure',
        value: 'trafficWithMyInfrastructure',
        disabled: !$hybridMap.hasSites,
        query: {
          inboundQuery: {
            ...baseQuery,
            filters: {
              connector: 'All',
              filterGroups: [
                ...firewallFilterGroups,
                buildFilterGroup({
                  filters: [
                    {
                      filterField: 'i_trf_profile',
                      operator: '=',
                      filterValue: 'from inside to cloud'
                    }
                  ]
                })
              ]
            },
            query_title: `From My Infrastructure To ${value}`,
            renderOptions: {
              source: 'Inbound',
              dimensions
            }
          },
          outboundQuery: {
            ...baseQuery,
            filters: {
              connector: 'All',
              filterGroups: [
                ...firewallFilterGroups,
                buildFilterGroup({
                  filters: [
                    {
                      filterField: 'i_trf_profile',
                      operator: '=',
                      filterValue: 'from cloud to inside'
                    }
                  ]
                })
              ]
            },
            query_title: `From ${value} To My Infrastructure`,
            renderOptions: {
              source: 'Outbound',
              dimensions
            }
          }
        }
      },

      {
        label: 'Traffic With Other Clouds',
        value: 'trafficWithOtherClouds',
        query: {
          filters: {
            connector: 'All',
            filterGroups: [
              ...firewallFilterGroups,
              buildFilterGroup({
                filters: [
                  {
                    filterField: 'i_trf_profile',
                    operator: '=',
                    filterValue: 'multi-cloud'
                  }
                ]
              })
            ]
          },
          query_title: `${value} Traffic With Other Clouds`,
          renderOptions: {
            source: 'Traffic With Other Clouds',
            dimensions
          }
        }
      }
    ];

    return $hybridMap.mergeSidebarQueryOptions(options.filter((o) => !o.disabled));
  }

  return null;
}

export function getGCPVpnGatewayQueryOptions(config) {
  const { value } = config;
  const dimensions = [GCP.SRC.GATEWAY_NAME, GCP.SRC.GATEWAY_TYPE, GCP.DST.GATEWAY_NAME, GCP.DST.GATEWAY_TYPE];
  const aggregateTypes = ['avg_bits_per_sec', 'p95th_bits_per_sec', 'max_bits_per_sec'];
  const aggregates = [
    {
      value: 'avg_bits_per_sec',
      column: 'f_sum_both_bytes',
      fn: 'average',
      label: 'Bits/s Sampled at Ingress + Egress Average',
      unit: 'bytes',
      group: 'Bits/s Sampled at Ingress + Egress',
      origLabel: 'Average',
      sample_rate: 1,
      raw: true,
      name: 'avg_bits_per_sec'
    },
    {
      value: 'p95th_bits_per_sec',
      column: 'f_sum_both_bytes',
      fn: 'percentile',
      label: 'Bits/s Sampled at Ingress + Egress 95th Percentile',
      rank: 95,
      unit: 'bytes',
      group: 'Bits/s Sampled at Ingress + Egress',
      origLabel: '95th Percentile',
      sample_rate: 1,
      name: 'p95th_bits_per_sec'
    },
    {
      value: 'max_bits_per_sec',
      column: 'f_sum_both_bytes',
      fn: 'max',
      label: 'Bits/s Sampled at Ingress + Egress Max',
      unit: 'bytes',
      group: 'Bits/s Sampled at Ingress + Egress',
      origLabel: 'Max',
      sample_rate: 1,
      name: 'max_bits_per_sec'
    }
  ];

  const defaults = {
    aggregateTypes,
    aggregates,
    all_devices: false,
    device_types: [GCP.DATASET],
    depth: 75,
    topx: 8,
    bracketOptions: '',
    period_over_period: false,
    period_over_period_lookback: 1,
    period_over_period_lookback_unit: 'week',
    use_alt_timestamp_field: false,
    viz_type: 'stackedArea'
  };

  const vpnGatewayMatchFilter = buildFilterGroup({
    name: 'VPN Gateway Match',
    filters: [
      {
        filterField: GCP.BI.GATEWAY_NAME,
        operator: '=',
        filterValue: value
      }
    ]
  });

  // inbound is when source gateway name is unknown
  const inboundFilter = buildFilterGroup({
    filterGroups: [
      vpnGatewayMatchFilter,
      buildFilterGroup({
        name: 'Source Gateway Name Unknown',
        filters: [
          {
            filterField: GCP.SRC.GATEWAY_NAME,
            operator: '!=',
            filterValue: ''
          },
          {
            filterField: GCP.SRC.GATEWAY_NAME,
            operator: '!=',
            filterValue: ''
          }
        ]
      })
    ]
  });

  // outbound is when destination gateway name is unknown
  const outboundFilter = buildFilterGroup({
    filterGroups: [
      vpnGatewayMatchFilter,
      buildFilterGroup({
        name: 'Destination Gateway Name Unknown',
        filters: [
          {
            filterField: GCP.DST.GATEWAY_NAME,
            operator: '!=',
            filterValue: ''
          },
          {
            filterField: GCP.DST.GATEWAY_NAME,
            operator: '!=',
            filterValue: ''
          }
        ]
      })
    ]
  });

  const options = [
    {
      label: 'All Traffic',
      value: 'all',
      query: {
        all_devices: false,
        device_types: [GCP.DATASET],
        filters: buildFilterGroup({
          filterGroups: [vpnGatewayMatchFilter]
        }),
        query_title: `All ${value} Traffic`,
        renderOptions: { dimensions },
        metric: [GCP.SRC.GATEWAY_NAME, GCP.SRC.GATEWAY_TYPE, GCP.DST.GATEWAY_NAME, GCP.DST.GATEWAY_TYPE],
        ...defaults
      }
    },
    {
      label: 'Inbound/Outbound Traffic',
      value: 'total',
      query: {
        all_devices: false,
        device_types: [GCP.DATASET],
        inboundQuery: {
          all_devices: false,
          device_types: [GCP.DATASET],
          query_title: `Traffic To ${value}`,
          filters: inboundFilter,
          renderOptions: { source: 'Inbound', dimensions },
          metric: [GCP.SRC.GATEWAY_NAME, GCP.SRC.GATEWAY_TYPE, GCP.DST.GATEWAY_NAME, GCP.DST.GATEWAY_TYPE],
          ...defaults
        },
        outboundQuery: {
          all_devices: false,
          device_types: [GCP.DATASET],
          query_title: `Traffic From ${value}`,
          filters: outboundFilter,
          renderOptions: { source: 'Outbound', dimensions },
          metric: [GCP.SRC.GATEWAY_NAME, GCP.SRC.GATEWAY_TYPE, GCP.DST.GATEWAY_NAME, GCP.DST.GATEWAY_TYPE],
          ...defaults
        }
      }
    }
  ];

  return $hybridMap.mergeSidebarQueryOptions(options);
}

export function getGCPNatGatewayQueryOptions(config) {
  const { value } = config;
  const dimensions = [GCP.SRC.GATEWAY_NAME, GCP.SRC.GATEWAY_TYPE, GCP.DST.GATEWAY_NAME, GCP.DST.GATEWAY_TYPE];
  const aggregateTypes = ['avg_bits_per_sec', 'p95th_bits_per_sec', 'max_bits_per_sec'];
  const aggregates = [
    {
      value: 'avg_bits_per_sec',
      column: 'f_sum_both_bytes',
      fn: 'average',
      label: 'Bits/s Sampled at Ingress + Egress Average',
      unit: 'bytes',
      group: 'Bits/s Sampled at Ingress + Egress',
      origLabel: 'Average',
      sample_rate: 1,
      raw: true,
      name: 'avg_bits_per_sec'
    },
    {
      value: 'p95th_bits_per_sec',
      column: 'f_sum_both_bytes',
      fn: 'percentile',
      label: 'Bits/s Sampled at Ingress + Egress 95th Percentile',
      rank: 95,
      unit: 'bytes',
      group: 'Bits/s Sampled at Ingress + Egress',
      origLabel: '95th Percentile',
      sample_rate: 1,
      name: 'p95th_bits_per_sec'
    },
    {
      value: 'max_bits_per_sec',
      column: 'f_sum_both_bytes',
      fn: 'max',
      label: 'Bits/s Sampled at Ingress + Egress Max',
      unit: 'bytes',
      group: 'Bits/s Sampled at Ingress + Egress',
      origLabel: 'Max',
      sample_rate: 1,
      name: 'max_bits_per_sec'
    }
  ];

  const defaults = {
    aggregateTypes,
    aggregates,
    all_devices: false,
    device_types: [GCP.DATASET],
    depth: 75,
    topx: 8,
    bracketOptions: '',
    period_over_period: false,
    period_over_period_lookback: 1,
    period_over_period_lookback_unit: 'week',
    use_alt_timestamp_field: false,
    viz_type: 'stackedArea'
  };

  const natGatewayMatchFilter = buildFilterGroup({
    name: 'NAT Gateway Match',
    filters: [
      {
        filterField: GCP.BI.GATEWAY_NAME,
        operator: '=',
        filterValue: value
      }
    ]
  });

  // inbound is when source gateway name is unknown
  const inboundFilter = buildFilterGroup({
    filterGroups: [
      natGatewayMatchFilter,
      buildFilterGroup({
        name: 'Source Gateway Name Unknown',
        filters: [
          {
            filterField: GCP.SRC.GATEWAY_NAME,
            operator: '!=',
            filterValue: ''
          },
          {
            filterField: GCP.SRC.GATEWAY_NAME,
            operator: '!=',
            filterValue: ''
          }
        ]
      })
    ]
  });

  // outbound is when destination gateway name is unknown
  const outboundFilter = buildFilterGroup({
    filterGroups: [
      natGatewayMatchFilter,
      buildFilterGroup({
        name: 'Destination Gateway Name Unknown',
        filters: [
          {
            filterField: GCP.DST.GATEWAY_NAME,
            operator: '!=',
            filterValue: ''
          },
          {
            filterField: GCP.DST.GATEWAY_NAME,
            operator: '!=',
            filterValue: ''
          }
        ]
      })
    ]
  });

  const options = [
    {
      label: 'All Traffic',
      value: 'all',
      query: {
        all_devices: false,
        device_types: [GCP.DATASET],
        filters: buildFilterGroup({
          filterGroups: [natGatewayMatchFilter]
        }),
        query_title: `All ${value} Traffic`,
        renderOptions: { dimensions },
        metric: [GCP.SRC.GATEWAY_NAME, GCP.SRC.GATEWAY_TYPE, GCP.DST.GATEWAY_NAME, GCP.DST.GATEWAY_TYPE],
        ...defaults
      }
    },
    {
      label: 'Inbound/Outbound Traffic',
      value: 'total',
      query: {
        all_devices: false,
        device_types: [GCP.DATASET],
        inboundQuery: {
          all_devices: false,
          device_types: [GCP.DATASET],
          query_title: `Traffic To ${value}`,
          filters: inboundFilter,
          renderOptions: { source: 'Inbound', dimensions },
          metric: [GCP.SRC.GATEWAY_NAME, GCP.SRC.GATEWAY_TYPE, GCP.DST.GATEWAY_NAME, GCP.DST.GATEWAY_TYPE],
          ...defaults
        },
        outboundQuery: {
          all_devices: false,
          device_types: [GCP.DATASET],
          query_title: `Traffic From ${value}`,
          filters: outboundFilter,
          renderOptions: { source: 'Outbound', dimensions },
          metric: [GCP.SRC.GATEWAY_NAME, GCP.SRC.GATEWAY_TYPE, GCP.DST.GATEWAY_NAME, GCP.DST.GATEWAY_TYPE],
          ...defaults
        }
      }
    }
  ];

  return $hybridMap.mergeSidebarQueryOptions(options);
}
