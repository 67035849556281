import * as React from 'react';
import { inject } from 'mobx-react';
import { ReactComponent as DeployAgentIcon } from 'app/assets/agents/deploy_agent_icon.svg';
import { Box, Button, Flex, Text } from 'core/components';
import AgentInstallModal from 'app/components/agent/AgentInstallModal';

@inject('$dictionary')
export default class DeployAgentInstructionsDialog extends React.Component {
  handleAgentChallengeCodeClick = () => {
    const { onEnterAgentChallengeCodeClick } = this.props;

    if (onEnterAgentChallengeCodeClick) {
      onEnterAgentChallengeCodeClick();
    }
  };

  render() {
    return (
      <AgentInstallModal title="Agent Deployment Instructions" height={650} {...this.props}>
        <Flex mb={3}>
          <DeployAgentIcon width={128} height={88} />
          <Box ml={2} mb={2}>
            <Text as="div" small>
              To get the most from Kentik, we recommend installing our agent throughout your infrastructure. Click below
              to see information on getting started with Kentik Private Agents.
            </Text>

            <Button onClick={this.handleAgentChallengeCodeClick} mt={2} small>
              Enter an Agent challenge code
            </Button>
          </Box>
        </Flex>
      </AgentInstallModal>
    );
  }
}
