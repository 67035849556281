import * as React from 'react';
import { Box, Text } from 'core/components';

export default function HealthDetailsItemContainer({ children, title }) {
  return (
    <Box m="2px" mb={2}>
      <Text as="div" fontWeight="bold" mb={1}>
        {title}
      </Text>
      {children}
    </Box>
  );
}
