import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Tag, Text } from 'core/components';
import LinkConnectionSelector from 'app/views/hybrid/maps/components/popovers/LinkConnectionSelector';

@observer
class InterfaceSummary extends React.Component {
  render() {
    const { groupedConnections, linkLabel, onStateChange } = this.props;
    let selectWidth = '100%';

    if (!linkLabel) {
      selectWidth = 'calc(100% - 60px)';
    }

    return (
      <Box p={2} borderBottom="thin">
        <Text as="div" mb={1} muted>
          Interface Details
        </Text>
        {linkLabel && (
          <Tag mt="4px" mb={1} textTransform="uppercase" bg="violet3" color="white.9" alignSelf="flex-start" small>
            {linkLabel}
          </Tag>
        )}
        <Box width={selectWidth}>
          <LinkConnectionSelector groupedConnections={groupedConnections} onStateChange={onStateChange} />
        </Box>
      </Box>
    );
  }
}

InterfaceSummary.PopoutTitle = ({ value }) =>
  `${value.device_name} (${value.device_id}) ${value.interface_description}`;

export default InterfaceSummary;
