import { get } from 'lodash';
import { adjustByGreekPrefix, getPrefixMagnitude, greekPrefix } from 'core/util/greekPrefixing';
import { adjustByNumberSuffix, numberSuffix } from 'core/util/numberSuffixing';

import $dictionary from 'app/stores/$dictionary';
import { CLOUD_PROVIDERS } from 'app/util/constants';

// TODO: figure out what belongs in core, what we don't need at all, etc.

export const getToFixed = (units = 'bytes') => {
  if (units.includes('bytes') || units.includes('packets') || units === 'fps') {
    return 2;
  }
  if (units.startsWith('perc_')) {
    return 3;
  }
  return 0;
};

export const kflowTypeLabelMap = {
  router: 'Router',
  host: 'Legacy Host',
  'host-nprobe-basic': 'nProbe Basic',
  'host-nprobe-dns-www': 'kProbe'
};

export function deviceTypeByKflow(...kflowTypes) {
  const results = [];
  return kflowTypes.reduce((accumulator, kflowType) => {
    const matchingDeviceType = $dictionary
      .get('deviceTypes', [])
      .find((deviceType) => deviceType.kflow_type === kflowType);
    if (matchingDeviceType) {
      const { id, kflow_type } = matchingDeviceType;
      accumulator.push({ id, kflow_type, label: kflowTypeLabelMap[kflowType] || kflowType });
    } else {
      throw new Error(`Unrecognized kflow type: ${kflowType}`);
    }
    return accumulator;
  }, results);
}

export function addCommas(nStr) {
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1,$2');
  }
  return x1 + x2;
}

export const removeCommas = (str) => str.replace(/,/g, '');

export function numberWithCommas(x) {
  return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// an undefined locale will use the browser's default locale
export function intlCurrency(value, currency = 'USD', options = {}) {
  const { locale, minimumFractionDigits = 0 } = options;
  let { maximumFractionDigits } = options;
  if (Number.isFinite(maximumFractionDigits)) {
    // prevent maximumFractionDigits from being less than minimumFractionDigits (error thrown by Intl.NumberFormat)
    maximumFractionDigits = Math.max(minimumFractionDigits, maximumFractionDigits);
  }
  try {
    const regFormat = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits,
      maximumFractionDigits
    });
    const decFormat = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits
    });
    return value % 1 === 0 || minimumFractionDigits || maximumFractionDigits
      ? regFormat.format(value)
      : decFormat.format(value);
  } catch (err) {
    return value.toFixed(2);
  }
}

export const countNumberDecimals = (value) =>
  !value || `${Math.floor(value)}` === `${value}` ? 0 : value.toString().split('.')[1].length || 0;

// where possible, use intlCurrency() instead
export function formatExpense(value, { fixed = 0, millionFixed = 1, prefix = '$', showPrefix = true } = {}) {
  const millions = +value / 10 ** 6;
  const pfx = showPrefix ? prefix : '';
  return millions >= 1
    ? `${pfx}${addCommas(millions.toFixed(millionFixed))}M`
    : `${pfx}${addCommas(value.toFixed(fixed))}`;
}

export function isNumber(val) {
  const testNum = parseFloat(val);
  return !Number.isNaN(testNum) && Number.isFinite(testNum);
}

export function zeroToText(val, options = {}) {
  const { fix = 2, limit = 10 ** -fix, useAbsVal = true, useAbsParens = true, useCommas = true } = options;

  const absVal = Math.abs(val);
  const numberVal = Number(val);
  const numToFormat = useAbsVal ? absVal : numberVal;
  if (!isNumber(numToFormat)) {
    return val;
  }

  let returnVal = numToFormat.toFixed(fix);

  if (absVal !== 0 && absVal < limit) {
    returnVal = `<${limit}`;
  }

  returnVal = useCommas ? addCommas(returnVal) : returnVal;
  if (useAbsVal === false && useAbsParens === true && val < 0) {
    returnVal = `(${returnVal})`;
  }
  return returnVal;
}

export function percentToText(value, options = {}) {
  const { fix = 0, showNines = true } = options;

  if (!isNumber(value)) {
    // Don't try to perform numerical operations on invalid input
    return value;
  }

  if (showNines && value > 99 && value < 100) {
    const decimals = `${value}`.split('.')[1];

    // Show all the 9s!
    if (decimals.length > 1 && decimals.startsWith('9')) {
      const index = decimals.split('').findIndex((d) => d !== '9');
      return `99.${decimals.slice(0, Math.max(fix, index))}%`;
    }

    // Otherwise, only show `fix` decimals
    return `99.${decimals.slice(0, Math.max(fix, 1))}%`;
  }

  return `${zeroToText(value, {
    fix: value < 1 && value !== 0 ? Math.max(fix, 1) : fix,
    useAbsVal: false,
    useAbsParens: false
  })}%`;
}

export function escapeHtml(str) {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}

export function escapeForRegExp(str) {
  return str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
}

export function getOrdinalSuffix(num) {
  const ones = num % 10;
  const tens = num % 100;
  if (ones === 1 && tens !== 11) {
    return 'st';
  }
  if (ones === 2 && tens !== 12) {
    return 'nd';
  }
  if (ones === 3 && tens !== 13) {
    return 'rd';
  }
  return 'th';
}

export function truncate(str, len, suffix = '…') {
  if (str && str.length > len + suffix.length) {
    return str.substr(0, len) + suffix;
  }

  return str;
}

export function parseQueryString(queryString) {
  const params = {};
  let temp;
  if (queryString && typeof queryString === 'string') {
    const queries = queryString.split('&');
    for (let i = 0; i < queries.length; i += 1) {
      temp = queries[i].split('=');
      // Turn comma-separated values into arrays
      const value = (temp[1] && decodeURIComponent(temp[1]))?.split(',');
      params[temp[0].replace('?', '')] = value?.length === 1 ? value[0] : value;
    }
  }
  return params;
}

export function createQueryStringFromObject(object) {
  if (typeof object === 'object' && Object.keys(object).length) {
    return Object.keys(object).reduce((queryString, key) => {
      if (queryString.length) {
        queryString += '&';
      }
      return `${queryString}${key}=${object[key]}`;
    }, '');
  }
  return '';
}

// removing duplicate code, just exporting for backwards compat.
export { adjustByGreekPrefix };

export function greekIt(value, { scaleMax = 1, fix = 3, suffix = '', forcePrefix, trailingZeros = true } = {}) {
  const prefix = forcePrefix || greekPrefix([Number(value)], scaleMax);
  const adjusted = adjustByGreekPrefix(value, prefix);
  const unitsLabel = `${prefix}${suffix}`;
  const display = trailingZeros ? zeroToText(adjusted, { fix }) : parseFloat(adjusted.toFixed(fix));
  const displayFull = `${display}${unitsLabel ? ` ${unitsLabel}` : unitsLabel}`;
  const magnitude = getPrefixMagnitude(prefix);

  return {
    value,
    prefix,
    adjusted,
    display,
    displayFull,
    unitsLabel,
    magnitude
  };
}

export function formatNumber(value, { scaleMax = 1, fix = 3, suffix = '', useAbsVal, trailingZeros = true } = {}) {
  const valueSuffix = numberSuffix([Math.abs(Number(value))], scaleMax);
  const adjusted = adjustByNumberSuffix(value, valueSuffix);
  const unitsLabel = suffix;
  const display =
    (trailingZeros ? zeroToText(adjusted, { fix, useAbsVal }) : addCommas(parseFloat(adjusted.toFixed(fix)))) +
    valueSuffix;
  const displayFull = `${display}${unitsLabel ? ` ${unitsLabel}` : unitsLabel}`;

  return {
    value,
    valueSuffix,
    adjusted,
    display,
    displayFull,
    unitsLabel
  };
}

export const stopBubbling = (e) => e.stopPropagation();

function findGcd(a, b) {
  return b ? findGcd(b, a % b) : a;
}

export function reduceFraction(numerator, denominator, reduceToOne = false) {
  let gcd = 1;
  let values = [numerator, denominator];

  if (reduceToOne) {
    gcd = numerator < denominator ? numerator : denominator;
    gcd = gcd || 1;
    const reducedNum = numerator / gcd;
    const reducedDen = denominator / gcd;
    const precision = Math.max(reducedNum, reducedDen) < 10 ? 1 : 0;
    values = [+reducedNum.toFixed(precision), +reducedDen.toFixed(precision)];
  } else {
    gcd = findGcd(numerator, denominator);
    values = [numerator / gcd, denominator / gcd];
  }

  return values;
}
export function cloudProviderData(cloudProviderId, path) {
  // normalize for google.
  const provider = cloudProviderId === 'gcp' ? 'gce' : cloudProviderId;
  const record = Object.values(CLOUD_PROVIDERS).find((p) => p.id === provider);
  if (path) {
    return get(record || {}, path);
  }
  return record;
}

export function arrayToLineSeparatedString(value) {
  if (Array.isArray(value)) {
    return value.join('\n');
  }
  return value.toString();
}

export function lineSeparatedStringToArray(value) {
  if (typeof value === 'string') {
    value = value.trim();
    if (!value) {
      return [];
    }
    if (value.endsWith('\n')) {
      value = value.slice(0, -2);
    }
    return value.split('\n').map((item) => item.trim());
  }
  return [];
}

export function arrayToCommaSeparatedString(value, withSpace = true) {
  if (Array.isArray(value)) {
    return value.join(`,${withSpace ? ' ' : ''}`);
  }
  return value.toString();
}

export function commaSeparatedStringToArray(value) {
  if (typeof value === 'string') {
    value = value.trim();
    if (!value) {
      return [];
    }
    if (value.endsWith(',')) {
      value = value.slice(0, -1);
    }
    return value.split(',').map((item) => item.trim());
  }
  return [];
}

// Create cross-company spoof links
export function generateCustomSpoofUrl(companyId, userId, url) {
  const sanitizedUri = new URL(url);
  const { search, pathname, origin } = sanitizedUri;
  const updatedSearch = search ? `&${search.substring(1)}` : '';

  return `${origin}/v4/spoof/${companyId}/${userId}?redirectUrl=${encodeURIComponent(pathname)}${updatedSearch}`;
}

export const capitalizeFirstLetter = (str) => (str ? str.charAt(0).toUpperCase() + str.slice(1) : str);
