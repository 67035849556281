import * as React from 'react';

const CircleIconWrapper = ({ size, children, circleProps, ...svgProps }) => {
  const circleSize = size / 2;

  return (
    <svg width={size} height={size} {...svgProps}>
      <circle cx={circleSize} cy={circleSize} r={circleSize} fill="white" {...circleProps} />
      {children}
    </svg>
  );
};

export default CircleIconWrapper;
