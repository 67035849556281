import React from 'react';

import { Text, Flex, FlexColumn } from 'core/components';

import { exportContentMap, getContentLabel } from 'app/util/export';

const delivery_period_options = {
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly',
  lastdaymonth: 'Last day of the month'
};

const export_type_options = {
  pdf: 'Visual Report (PDF)',
  csv: 'Data Table (CSV)',
  'csv-chart': 'Chart Table (CSV)'
};

const days = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
};

const contentMap = Object.fromEntries(Object.entries(exportContentMap).filter((keyVals) => keyVals[1].hasSubscription));

const contentTypes = Object.entries(contentMap).map(([value, { name }]) => ({
  value,
  label: getContentLabel(name)
}));

const contentFilterTypes = Object.entries(contentMap).map(([value, { name }]) => ({
  value,
  label: name
}));

function getFormattedDeliveryPeriod(model) {
  const delivery_period = model.get('delivery_period');
  const recurrence_ordinal = model.get('recurrence_ordinal');
  let formatted = delivery_period_options[delivery_period];
  if (delivery_period === 'week') {
    formatted += ` on ${days[recurrence_ordinal]}`;
  } else if (delivery_period === 'month') {
    formatted += ` on ${recurrence_ordinal}`;
  }
  return formatted;
}

function subscriptionSelectOptionFormatter(option) {
  if (option) {
    if (option.value === 'CREATE_NEW') {
      return <Text fontWeight="bold">Create New</Text>;
    }
    if (option.value === 'EDIT_EXISTING') {
      return (
        <Flex borderBottom="thin" width="100%" cursor="default">
          <Text fontWeight="bold" color="body" fontSize={14} pb="3px">
            Edit Existing Subscription
          </Text>
        </Flex>
      );
    }
    const { label, model } = option;
    return (
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <Flex flexDirection="column">
          <Text fontWeight="bold">{label}</Text>
          <Text>{contentMap[model.get('content_type')].name}</Text>
        </Flex>
        <Flex>{getFormattedDeliveryPeriod(model)}</Flex>
        <Flex>{export_type_options[model.get('export_type')]}</Flex>
        <Flex>
          {model.allRecipients.length} subscriber{model.allRecipients.length !== 1 ? 's' : ''}
        </Flex>
      </Flex>
    );
  }
  return null;
}

function shortSummarySubscriptionOptionRenderer(option) {
  if (option) {
    if (option.value === 'CREATE_NEW') {
      return <Text fontWeight="bold">Create New</Text>;
    }
    if (option.value === 'EDIT_EXISTING') {
      return (
        <Flex borderBottom="thin" width="100%" cursor="default">
          <Text fontWeight="bold" color="body" fontSize={14} pb="3px">
            Edit Existing Subscription
          </Text>
        </Flex>
      );
    }
    const { label, model } = option;
    return (
      <FlexColumn width="100%">
        <Text fontWeight="bold">{label}</Text>
        <Flex>
          <Text muted>{getFormattedDeliveryPeriod(model)}&nbsp;-&nbsp;</Text>
          <Text muted>{export_type_options[model.get('export_type')]}&nbsp;-&nbsp;</Text>
          <Text muted>
            {model.allRecipients.length} subscriber{model.allRecipients.length !== 1 ? 's' : ''}
          </Text>
        </Flex>
      </FlexColumn>
    );
  }
  return null;
}

export {
  contentMap,
  contentTypes,
  contentFilterTypes,
  delivery_period_options,
  days,
  subscriptionSelectOptionFormatter,
  shortSummarySubscriptionOptionRenderer
};
