import React from 'react';

import { Heading, Text, Box } from 'core/components';
import SharedPage from 'app/components/page/SharedPage';

const SharedNotFound = () => (
  <SharedPage title="Not Found">
    <Box style={{ textAlign: 'center', paddingTop: '100px' }}>
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M32.0002 58.6667C46.7278 58.6667 58.6668 46.7276 58.6668 32C58.6668 17.2724 46.7278 5.33334 32.0002 5.33334C17.2726 5.33334 5.3335 17.2724 5.3335 32C5.3335 46.7276 17.2726 58.6667 32.0002 58.6667Z"
          stroke="#EE7E0F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.6668 42.6667C42.6668 42.6667 38.6668 37.3333 32.0002 37.3333C25.3335 37.3333 21.3335 42.6667 21.3335 42.6667"
          stroke="#EE7E0F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M24 24H24.0267" stroke="#EE7E0F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M40 24H40.0267" stroke="#EE7E0F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Box>

    <Heading level={2} style={{ textAlign: 'center' }}>
      Oops, we&apos;re sorry
    </Heading>

    <Text muted monospace style={{ textAlign: 'center' }}>
      The link you are trying to access is not available.
    </Text>
  </SharedPage>
);

export default SharedNotFound;
