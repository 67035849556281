import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import withHybridTopoSettings from 'app/views/hybrid/maps/components/settingsToolbar/withHybridTopoSettings';
import Page from 'app/components/page/Page';
import { SizeMe } from 'react-sizeme';
import { Box, Button } from 'core/components';
import { AiOutlineMenuFold } from 'react-icons/ai';
import HybridTopoSidebar from '../maps/components/HybridTopoSidebar';
import HybridTopoSettingsToolbar from '../maps/components/settingsToolbar/HybridTopoSettingsToolbar';
import withSidebarDetails from '../maps/components/popovers/withSidebarDetails';
import ClusterDetailsMap from '../maps/kube/ClusterDetailsMap';

@withHybridTopoSettings
@withSidebarDetails
@inject('$explorer', '$hybridMap')
@observer
class ClusterDetails extends Component {
  static getDerivedStateFromProps(props) {
    if (!props.sidebarDetailsPanel) {
      return { drawerIsOpen: false };
    }

    return null;
  }

  state = {
    drawerIsOpen: true
  };

  handleDrawerToggle = () => this.setState(({ drawerIsOpen }) => ({ drawerIsOpen: !drawerIsOpen }));

  componentDidUpdate(prevProps, prevState) {
    const { sidebarDetailsPanel } = this.props;

    // if we're setting new details and it's not open, open it
    if (!prevState.drawerIsOpen && sidebarDetailsPanel) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ drawerIsOpen: true });
    }
  }

  render() {
    const { match, setSidebarDetails, sidebarSettings, settingsModel, saveSettings, $hybridMap, sidebarDetailsPanel } =
      this.props;
    const { drawerIsOpen } = this.state;
    const { clusterId, clusterName: paramClusterName, cloud = '' } = match.params;

    const clusterName = paramClusterName || clusterId.replace(/^\w+-/, '');

    return (
      <Page
        title={`Cluster: ${clusterName}`}
        parentLinks={[{ link: '/v4/cloud/kube/', label: 'Kentik Kube' }]}
        drawerContents={<HybridTopoSidebar sidebarDetailsPanel={sidebarDetailsPanel} />}
        drawerIsOpen={drawerIsOpen}
        drawerOnClose={(e) => {
          if ((e && e.key === 'Escape' && drawerIsOpen) || (e && e.type === 'click')) {
            this.handleDrawerToggle();
          }
        }}
        drawerProps={{ extraOffset: 43, showCloseButton: true }}
        subnavTools={
          <Button
            text="Details"
            icon={AiOutlineMenuFold}
            ml="2px"
            active={drawerIsOpen}
            onClick={this.handleDrawerToggle}
            disabled={!sidebarDetailsPanel}
            minimal
          />
        }
        toolbar={
          <HybridTopoSettingsToolbar
            onSettingsUpdate={saveSettings}
            model={settingsModel}
            // todo: change collection?
            collection={$hybridMap.kubeCloudMapCollection}
          />
        }
        scrolls
        minHeight="auto"
      >
        <SizeMe monitorWidth noPlaceholder>
          {({ size }) => (
            <Box flex={1} mb={1}>
              <ClusterDetailsMap
                cloud={cloud}
                width={size.width}
                clusterId={clusterId}
                clusterName={clusterName}
                sidebarSettings={sidebarSettings}
                setSidebarDetails={setSidebarDetails}
                drawerIsOpen={drawerIsOpen}
              />
            </Box>
          )}
        </SizeMe>
      </Page>
    );
  }
}

export default ClusterDetails;
