import { isNaN } from 'lodash';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { curveLeftUp, curveUpRight, curveUpLeft, curveRightDown } from 'app/views/hybrid/utils/d3/curves';

import { connectThroughMiddleX } from '../commonUtils';

const { VIRTUAL_HUB, VNET_GATEWAY, VNET_PEERING } = ENTITY_TYPES.get('azure');

const connectToPeering = ({ sourcePoint, targetPoint, sourceNodeHeight, ySpacing, linkSpacing, regionLeft }) => {
  const topY = targetPoint[1] + sourceNodeHeight / 2 + linkSpacing;
  const bottomY = sourcePoint[1] - sourceNodeHeight / 2 - ySpacing;

  const tooFarApart = Math.abs(targetPoint[1] - sourcePoint[1]) > sourceNodeHeight * 2;

  let connectionPoints = [
    [sourcePoint[0], bottomY],
    [targetPoint[0], topY]
  ];

  if (tooFarApart) {
    /**
     *    VNET Peering
     *        ↑
     *  → → →
     * ↑
     *  ← ← ←
     *        ↑
     *      VNET
     */
    const leftX = regionLeft - linkSpacing / 2;

    connectionPoints = [
      ...curveUpLeft(sourcePoint[0], bottomY, linkSpacing),
      ...curveLeftUp(leftX, bottomY, linkSpacing),
      ...curveUpRight(leftX, topY, linkSpacing),
      ...curveRightDown(targetPoint[0], topY, linkSpacing)
    ];
  }

  return {
    sourceAnchor: 'top',
    targetAnchor: 'bottom',
    connectionPoints
  };
};

const connectToAnyGateway = (props) => {
  const { sourcePoint, targetPoint, ySpacing, xSpacing, sourceNodeHeight } = props;
  const isPositionedVertically = Math.abs(sourcePoint[0] - targetPoint[0]) < xSpacing / 2;

  if (isPositionedVertically) {
    return {
      sourceAnchor: 'top',
      targetAnchor: 'bottom',
      connectionPoints: []
    };
  }

  return connectThroughMiddleX({ ...props, sourceYSpacing: sourceNodeHeight / 2 + ySpacing / 2 });
};

/**
 * example:
 *                → → →
 *               ↑      ↓
 *               ↑    VHUB
 *               ↑
 *    VNET       ↑
 *      ↓        ↑
 *       → → → →
 */
const connectToVirtualHubs = ({
  sourcePoint,
  targetPoint,
  xSpacing,
  ySpacing,
  sourceNodeHeight,
  vpcRight,
  nodeWidth
}) => {
  // if horizontally alligned
  if (Math.abs(sourcePoint[1] - targetPoint[1]) < sourceNodeHeight) {
    /**
     * example:

      *    VNET        VHUB
      *      ↓          ↑
      *       → → → → →
    */

    const bottomY = sourcePoint[1] + ySpacing;

    return {
      sourceAnchor: 'bottom',
      targetAnchor: 'bottom',
      connectionPoints: [
        [sourcePoint[0], bottomY],
        [targetPoint[0], bottomY]
      ]
    };
  }

  // adding extra hapf spacing to prevent overlapping with VHUB -> to -> VLAN
  const rightX = isNaN(vpcRight)
    ? targetPoint[0] - xSpacing - xSpacing / 2
    : vpcRight + (targetPoint[0] - nodeWidth / 2 - vpcRight) / 2;

  const xRandomizer = (targetPoint[0] - sourcePoint[0]) / targetPoint[0];
  const totalYSpacing = (xRandomizer * sourceNodeHeight) / 2 + sourceNodeHeight / 2;

  const bottomY = sourcePoint[1] + totalYSpacing;
  const topY = targetPoint[1] - ySpacing;

  const connectionPoints = [
    [sourcePoint[0], bottomY],
    [rightX, bottomY],
    [rightX, topY],
    [targetPoint[0], topY]
  ];
  return {
    sourceAnchor: 'bottom',
    targetAnchor: 'top',
    connectionPoints
  };
};

/**
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const VNETConnector = ({ targetType, ...rest }) => {
  switch (targetType) {
    case VIRTUAL_HUB:
      return connectToVirtualHubs({ ...rest });
    case VNET_GATEWAY:
      return connectToAnyGateway({ ...rest });
    case VNET_PEERING:
      return connectToPeering({ ...rest });
    /** @TODO other target types */
    default:
      return null;
  }
};

export default VNETConnector;
