import React from 'react';

import { Box, Text, Flex, Icon } from 'core/components';

const KubeInventoryBlock = ({ icon, count, label, iconSize = 20 }) => (
  <Box>
    <Text as="div" fontWeight="medium" muted>
      {label}
    </Text>
    <Flex alignItems="center">
      <Icon icon={icon} iconSize={iconSize} color="primary" mr="4px" />
      <Text fontWeight="medium">{count}</Text>
    </Flex>
  </Box>
);

export default KubeInventoryBlock;
