import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { SizeMe } from 'react-sizeme';
import { AiOutlineMenuFold } from 'react-icons/ai';
import { Box, Button, LinkButton } from 'core/components';
import Page from 'app/components/page/Page';
import withHybridTopoSettings from 'app/views/hybrid/maps/components/settingsToolbar/withHybridTopoSettings';
import DeviceDetailsMap from './maps/DeviceDetailsMap';
import HybridTopoSidebar from './maps/components/HybridTopoSidebar';
import HybridTopoSettingsToolbar from './maps/components/settingsToolbar/HybridTopoSettingsToolbar';
import withSidebarDetails from './maps/components/popovers/withSidebarDetails';

@withHybridTopoSettings
@withSidebarDetails
@inject('$devices', '$hybridMap')
@observer
class DeviceDetails extends Component {
  static getDerivedStateFromProps(props) {
    if (!props.sidebarDetailsPanel) {
      return { drawerIsOpen: false };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      drawerIsOpen: true
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarDetailsPanel } = this.props;

    // if we're setting new details and it's not open, open it
    if (!prevState.drawerIsOpen && sidebarDetailsPanel) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ drawerIsOpen: true });
    }
  }

  handleDrawerToggle = () => this.setState(({ drawerIsOpen }) => ({ drawerIsOpen: !drawerIsOpen }));

  render() {
    const {
      match,
      $devices,
      $hybridMap,
      settingsModel,
      sidebarSettings,
      saveSettings,
      setSidebarDetails,
      sidebarDetailsPanel
    } = this.props;
    const { drawerIsOpen } = this.state;
    const id = Number(match.params.id);
    const device = $devices.deviceSummariesById[id];

    return (
      <Page
        title={device ? device.device_name : ''}
        docTitle={device ? `${device.device_name} Map` : ''}
        parentLinks={[
          { link: device && `${$hybridMap.baseRoute}/site/${device.site_id}`, label: device ? device.title : '' }
        ]}
        drawerContents={<HybridTopoSidebar sidebarDetailsPanel={sidebarDetailsPanel} />}
        drawerIsOpen={drawerIsOpen}
        drawerOnClose={(e) => {
          if (e && e.key === 'Escape' && drawerIsOpen) {
            this.handleDrawerToggle();
          }
        }}
        drawerProps={{ extraOffset: 123 }}
        toolbar={<HybridTopoSettingsToolbar onSettingsUpdate={saveSettings} model={settingsModel} />}
        subnavTools={
          <>
            <LinkButton
              text="View Logical Map"
              ml="2px"
              to={`/v4/kentik-map/logical${device ? `/devices/${device.device_name}` : ''}`}
              minimal
            />
            <Button
              text="Details"
              icon={AiOutlineMenuFold}
              ml="2px"
              active={drawerIsOpen}
              onClick={this.handleDrawerToggle}
              disabled={!sidebarDetailsPanel}
              minimal
            />
          </>
        }
        scrolls
        minHeight="auto"
        canFullScreen
      >
        <SizeMe monitorWidth noPlaceholder>
          {({ size }) => (
            <Box flex={1} mb={1}>
              <DeviceDetailsMap
                id={id}
                width={size.width}
                sidebarSettings={sidebarSettings}
                setSidebarDetails={setSidebarDetails}
              />
            </Box>
          )}
        </SizeMe>
      </Page>
    );
  }
}

export default DeviceDetails;
