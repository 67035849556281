import * as React from 'react';
import classNames from 'classnames';
import { FiCopy } from 'react-icons/fi';
import { Box, Button, Card, Flex, Icon, Text } from 'core/components';
import { ReactComponent as CPUIcon } from 'app/assets/icons/cpu_icon.svg';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';

export default class Node extends React.Component {
  static defaultProps = {
    icon: null,
    primaryContent: null,
    secondaryContent: null,
    syntheticAgentInstalled: false
  };

  state = {
    showCopyButton: false
  };

  handleNodeClick = () => {
    const { node, onNodeClick } = this.props;

    if (onNodeClick) {
      onNodeClick(node);
    }
  };

  render() {
    const {
      node,
      icon,
      primaryContent,
      secondaryContent,
      syntheticAgentInstalled,
      onNodeClick,
      pathType,
      ...boxProps
    } = this.props;
    const { showCopyButton } = this.state;

    return (
      <Card
        key={node.id}
        id={`${pathType}-${node.id}`}
        display="flex"
        alignItems="center"
        bg="cloudPerformance.node.background"
        p="4px"
        borderRadius="4px"
        elevation={2}
        className={classNames('hybrid-map-selectable-node', `${pathType}-${node.cssId}`, {
          'matches-filter': node.matchesFilter === true,
          'does-not-match-filter': node.matchesFilter === false
        })}
        minWidth={0}
        style={{ cursor: 'pointer' }}
        onClick={this.handleNodeClick}
        position="relative"
        onMouseOver={() => this.setState({ showCopyButton: true })}
        onMouseOut={() => this.setState({ showCopyButton: false })}
        {...boxProps}
      >
        <CopyToClipboardButton
          text={node.id}
          copyConfirmationText={null}
          intent="none"
          ml="4px"
          minimal
          small
          position="absolute"
          top="-4px"
          right="-4px"
          display={showCopyButton ? 'flex' : 'none'}
        >
          <Button icon={FiCopy} color="cloudPerformance.node.primaryForeground" onClick={(e) => e.stopPropagation()} />
        </CopyToClipboardButton>
        {icon}
        <Box ml="4px" overflow="hidden" flex={1}>
          {primaryContent && (
            <Text as="div" fontWeight="bold" color="cloudPerformance.node.primaryForeground" small>
              {primaryContent}
            </Text>
          )}

          {secondaryContent && (
            <Text as="div" color="cloudPerformance.node.secondaryForeground" ellipsis small>
              {secondaryContent}
            </Text>
          )}

          {syntheticAgentInstalled && (
            <Flex bg="black.10" mt="4px" px="4px" py="2px" borderRadius="4px" width="fit-content" alignItems="center">
              <Icon icon={CPUIcon} mr="4px" />
              <Text color="white.10" fontSize={10}>
                Synthetic Agent Installed
              </Text>
            </Flex>
          )}
        </Box>
      </Card>
    );
  }
}
