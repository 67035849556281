import React from 'react';
import styled, { css } from 'styled-components';
import { Grid } from 'core/components';
import { lighten, darken } from 'polished';

const ResultItem = styled(Grid)`
  grid-template-columns: min-content 1fr max-content;
  grid-template-rows: 1fr minmax(1fr, 3fr);
  grid-template-areas:
    'icon title rightIcon'
    'icon description rightIcon';
  border-radius: 4px;

  .bp4-icon {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${(props) =>
    props.selected &&
    css`
      background: ${({ theme }) => `${darken(0.2, theme.colors.primary)}`};

      .title,
      .description {
        color: #ffffff;
      }

      .bp4-icon {
        color: ${({ theme }) => `${lighten(0.4, theme.colors.primary)}`};
      }
    `}
`;

function ResultMenuItem(props) {
  return <ResultItem gridTemplateColumns="" gridGap={1} gridRowGap={0} p="4px" {...props} />;
}

export default ResultMenuItem;
