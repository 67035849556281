import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import CELL_TYPES from 'core/components/table/CELL_TYPES';
import Checkbox from 'core/components/Checkbox';
import { Box, Flex, Heading, Switch, Text, Tooltip } from 'core/components';
import AdminTable from 'app/components/admin/AdminTable';
import Collection from '../../../core/model/Collection';

@inject('$auth', '$rbac')
@observer
class RoleSetPermissions extends Component {
  collection = new Collection([], { groupBy: 'group' });

  state = {
    showEnabledOnly: true
  };

  componentDidMount() {
    this.generateCollection();
    this.handleSwitchChange(true);
  }

  componentDidUpdate() {
    this.generateCollection();
  }

  get columns() {
    return [
      {
        key: 'select',
        value: 'select',
        id: 'select',
        type: CELL_TYPES.ACTION,
        actions: [
          (model) => (
            <Checkbox
              key="select"
              checked={model.get('isSelected')}
              disabled
              onClick={(e) => {
                e.stopPropagation();
                model.select({ multi: true, shiftKey: e.shiftKey });
              }}
              mb={0}
            />
          )
        ],
        padding: '6px 0 6px 16px !important',
        headerPadding: '4px 0 0 16px !important',
        justifyContent: 'center',
        width: 40,
        minWidth: 40,
        verticalAlign: 'center',
        dynamicClassName: (model) => (model.get('highlighted') ? 'pt-intent-primary-cell' : '')
      },
      {
        name: 'display',
        dynamicClassName: (model) => (model.get('highlighted') ? 'pt-intent-primary-cell' : ''),
        renderer: ({ value, model }) => {
          const { $rbac } = this.props;
          const { isSelected, highlighted } = model.attributes;

          return (
            <Box
              flex={1}
              m={1}
              onMouseEnter={() => $rbac.setHoveredRbacPermission(model.attributes.permission)}
              onMouseLeave={() => $rbac.setHoveredRbacPermission()}
              style={{
                backgroundColor: highlighted ? '' : '',
                lineHeight: '30px'
              }}
            >
              <Tooltip content={value}>
                <Text pl={2} muted={!isSelected}>
                  {value}
                </Text>
              </Tooltip>
            </Box>
          );
        }
      }
    ];
  }

  handleSwitchChange = (showEnabledOnly) => {
    this.setState({ showEnabledOnly });
    if (showEnabledOnly) {
      this.collection.filter((m) => m.get('isSelected'));
    } else {
      this.collection.clearFilters();
    }
  };

  generateCollection = () => {
    const { $rbac, roleSetRoleCollection } = this.props;

    const roleIds = roleSetRoleCollection.map((role) => role.id) || [];
    const collectionData = [];

    for (let i = 0; i < $rbac.globalRbacPermissions?.groups.length; i++) {
      const group = $rbac.globalRbacPermissions?.groups[i];
      if (group) {
        const { name, permissions } = group;
        for (let p = 0; p < permissions.length; p++) {
          const { permission } = permissions[p];

          const selected = $rbac.collection.get().filter((role) => roleIds.includes(role.id));

          const isSelected = selected.find((role) => role.attributes.permissions.includes(permission)) || false;

          const highlighted = $rbac.hoveredRbacRole?.attributes.permissions.includes(permission) || false;

          collectionData.push(
            Object.assign({}, permissions[p], { group: name, isSelected: !!isSelected, highlighted })
          );
        }
      }
    }

    this.collection.processData(collectionData);
  };

  groupSummaryLookupFn = ({ groupKey }) => (
    <>
      <Flex fontWeight="bold" mx={1} style={{ alignItems: 'stretch' }}>
        <Text mr={2}>{groupKey}</Text>
      </Flex>
    </>
  );

  render() {
    const { showEnabledOnly } = this.state;
    return (
      <Flex flex={2} flexDirection="column" mt={2}>
        <Flex justifyContent="space-between">
          <Heading level={6}>Resulting Effective Permissions</Heading>
          <Switch
            label="Enabled Only"
            checked={showEnabledOnly}
            onChange={(val) => this.handleSwitchChange(val)}
            small
          />
        </Flex>
        <AdminTable
          hideHeader
          collection={this.collection}
          columns={this.columns}
          groupSummaryLookup={this.groupSummaryLookupFn}
          searchBar={false}
          tableContainerProps={{ bg: 'cardBackground' }}
        />
      </Flex>
    );
  }
}

export default RoleSetPermissions;
