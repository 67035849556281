import * as React from 'react';
import { compact as _compact } from 'lodash';
import { Box, Flex, Heading, EmptyState, Tag } from 'core/components';
import { Table } from 'core/components/table';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import Collection from 'core/model/Collection';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { GCP_ENTITY_TYPES } from 'shared/hybrid/constants';
import { getVpnGatewayMetadata } from './utils';

const { VPN_GATEWAY } = GCP_ENTITY_TYPES;
export default class VpnGatewayPopover extends React.Component {
  get title() {
    const key = 'vpnGwEntitiesCount';
    const color = this.vpnGwEntities.length ? 'gcp.blue' : '';
    return (
      <Flex gap={1}>
        <Heading level={5} m={0}>
          VPN Gateways
        </Heading>
        <Tag key={key} bg={color} round>
          {this.vpnGwEntities.length}
        </Tag>
      </Flex>
    );
  }

  get vpnGwEntities() {
    const { nodeData, data } = this.props;
    return _compact(
      Object.values(data).map((vpnGW) => {
        if (nodeData.selfLink === vpnGW.router) {
          return vpnGW;
        }
        return null;
      })
    );
  }

  get accountColumns() {
    return [
      {
        name: 'id',
        label: 'ID'
      },
      {
        name: 'ipAddress',
        label: 'IP Address'
      }
    ];
  }

  get vpnInterfaces() {
    const vpnInterfaceArray = this.vpnGwEntities.map((i) => i.vpnInterfaces);
    const vpnInterfacesCollection = new Collection(vpnInterfaceArray.flat());
    return <Table collection={vpnInterfacesCollection} columns={this.accountColumns} />;
  }

  get vpnGatewayMetadata() {
    const configs = this.vpnGwEntities.map((vpnGW) => getVpnGatewayMetadata(vpnGW));
    return configs.map((config) => (
      <React.Fragment key={`vpnGatewayMetadata-${config.node.id}`}>
        <CloudMetadata {...config} />
        {this.vpnInterfaces}
      </React.Fragment>
    ));
  }

  render() {
    const { width, popoutTitle, cloudProvider } = this.props;
    return (
      <SidebarItem
        excludeFormProps
        title={this.title}
        dialogProps={{ width }}
        icon={<CloudIcon cloudProvider="gcp" entity={VPN_GATEWAY} theme={{ name: 'dark' }} />}
        popoutTitle={popoutTitle}
      >
        {this.vpnGwEntities.length ? (
          <Box>{this.vpnGatewayMetadata}</Box>
        ) : (
          <>
            <EmptyState
              title="No Results"
              icon={<CloudIcon cloudProvider={cloudProvider} entity={VPN_GATEWAY} iconSize={48} />}
            />
          </>
        )}
      </SidebarItem>
    );
  }
}
