import React from 'react';

import { inject } from 'mobx-react';
import { Box, FlexColumn } from 'core/components';

import SidebarItem from '../../SidebarItem';
import CloudMetadata from '../../CloudMetadata';

const AzurePeeringDetails = ({ width, popoutTitle, $hybridMap, nodeData }) => {
  const { azureCloudMapCollection } = $hybridMap;

  const expressRouteCircuit = azureCloudMapCollection.findParentEntityByIdUri(nodeData.id);

  if (!expressRouteCircuit) {
    return null;
  }
  const connectedInterfaces = [].concat(expressRouteCircuit?.kentik?.connectedInterfaces ?? []);
  const capacityInMbps = azureCloudMapCollection.getEntityProperty(
    expressRouteCircuit,
    'serviceProviderProperties'
  )?.bandwidthInMbps;

  const {
    properties: { primaryPeerAddressPrefix, secondaryPeerAddressPrefix }
  } = nodeData;
  const interfaces = [];
  if (primaryPeerAddressPrefix) {
    interfaces.push(connectedInterfaces.shift());
  }
  if (secondaryPeerAddressPrefix) {
    interfaces.push(connectedInterfaces.shift());
  }

  const { properties } = nodeData;
  const node = { ...properties, capacityInMbps };

  const primaryKeys = ['primaryAzurePort', 'primaryPeerAddressPrefix'];
  const secondaryKeys = ['secondaryAzurePort', 'secondaryPeerAddressPrefix'];
  if (interfaces[0]) {
    Object.keys(interfaces[0]).forEach((key) => {
      if (key !== 'expressRouteCircuit') {
        const newKey = `primary ${key.replace(/_/g, ' ')}`;
        node[newKey] = interfaces[0][key];
        primaryKeys.push(newKey);
      }
    });
  }
  if (interfaces[1]) {
    Object.keys(interfaces[1]).forEach((key) => {
      if (key !== 'expressRouteCircuit') {
        const newKey = `secondary ${key.replace(/_/g, ' ')}`;
        node[newKey] = interfaces[1][key];
        secondaryKeys.push(newKey);
      }
    });
  }

  return (
    <SidebarItem
      key={nodeData.id}
      excludeFormProps
      title={nodeData.name}
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="chat"
      popoutTitle={popoutTitle}
    >
      <Box key={nodeData.id}>
        <FlexColumn>
          <CloudMetadata
            node={node}
            keys={['peeringType', 'state', 'vlanId', ...primaryKeys, ...secondaryKeys, 'capacityInMbps']}
          />
        </FlexColumn>
      </Box>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzurePeeringDetails);
