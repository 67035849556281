import React from 'react';
import { Flex } from 'core/components';

import { OCI_ENTITY_TYPES } from 'shared/hybrid/constants';

import SidebarItem from '../SidebarItem';
import SecurityListTable from './components/SecurityListTable';

const { SECURITY_LIST } = OCI_ENTITY_TYPES;

const OciSecurityListsPopover = ({ width, popoutTitle, ...rest }) => {
  const { nodeData, topology } = rest;

  const securiyListIds =
    nodeData?.securityListIds ?? (nodeData?.defaultSecurityListId ? [nodeData?.defaultSecurityListId] : []);

  const securityLists =
    nodeData?.securityLists ?? securiyListIds.map((securityListId) => topology.Entities[SECURITY_LIST][securityListId]);

  if (securityLists.length === 0) {
    return null;
  }

  const [ingressRules, egressRules] = ['ingressSecurityRules', 'egressSecurityRules'].map((ruleType) =>
    securityLists.reduce((carry, securityList) => {
      const { displayName, lifecycleState } = securityList;
      carry = [
        ...carry,
        ...securityList[ruleType].map((rule) => ({ ...rule, securityListName: displayName, lifecycleState }))
      ];

      return carry;
    }, [])
  );

  return (
    <SidebarItem
      key={nodeData.id}
      excludeFormProps
      title="Security Lists"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="shield"
      popoutTitle={popoutTitle}
    >
      <Flex px="4px" gap={1} flexDirection="column">
        <SecurityListTable securityRules={ingressRules} isIngressRules />

        <SecurityListTable securityRules={egressRules} />
      </Flex>
    </SidebarItem>
  );
};

export default OciSecurityListsPopover;
