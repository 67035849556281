import * as React from 'react';
import { greekPrefix, adjustByGreekPrefix, toDecimal } from 'core/util';
import { Box, Heading, Sparkline, Text } from 'core/components';

export default class TrafficSummary extends React.Component {
  render() {
    const { collection, serviceKey, serviceName, color } = this.props;
    const rollup = collection.rollup[serviceKey];

    return ['from', 'to'].map((direction) => {
      const label = `${direction === 'from' ? 'From' : 'To'} ${serviceName}`;
      const value = rollup[`${direction}Traffic`];
      const prefix = greekPrefix([value], 1);
      const sparklineData = rollup.sparklineData[direction];

      return (
        <Box key={direction} position="relative">
          <Box>
            <Text as="div" title={label} muted small ellipsis>
              {label}
            </Text>
            <Heading mb={0} level={3}>
              {toDecimal(adjustByGreekPrefix(value, prefix))}
            </Heading>
            <Text muted small>{`${prefix}bits/s`}</Text>
          </Box>
          <Box position="absolute" width="calc(100% - 50px)" pb="1px" bottom={0} right={0}>
            <Sparkline data={sparklineData} height={30} strokeWidth={1.5} color={color} />
          </Box>
        </Box>
      );
    });
  }
}
