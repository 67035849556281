import React, { memo } from 'react';
import { inject } from 'mobx-react';
import { withTheme } from 'styled-components';
import { Box, Text, Flex, Link, Heading, Image, CalloutOutline, FlexColumn, Icon, Button } from 'core/components';
import { FiCopy } from 'react-icons/fi';
import { FaGithub } from 'react-icons/fa';

import kubeNoDevicesConfigured from 'app/assets/kube/kube-no-devices-configured.svg';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';

const ConnectWithSales = memo(
  inject('$app')(({ $app }) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link onClick={() => $app.setReportIssuePopoverPropsAndOpen({ type: 'question' })}>
      Get in touch with our team!
    </Link>
  ))
);

@withTheme
@inject('$auth', '$users', '$setup')
class KubeMapNoDevicesFound extends React.Component {
  componentDidMount() {
    const { $setup, $users } = this.props;

    $users.fetchUserProfile();
    $setup.getPlans();
  }

  render() {
    const { $auth, theme, $setup } = this.props;

    const email = $auth.getActiveUserProperty('user_email');
    const token = '<your_api_token>';
    const defaultCloudPlanId = $setup.defaultCloudPlanId() ?? '<billing_plan>';

    return (
      <FlexColumn gap={2}>
        <Box>
          <Heading level={2} fontWeight="black" fontSize={22} mb={0} mr={4}>
            Oh No! You haven&apos;t deployed Kube to your K8S cluster yet!
          </Heading>
          <Text>Let&apos;s get you started, it&apos;s easy as 1-2-3.</Text>
        </Box>
        <FlexColumn flex={3}>
          <Link to="https://kb.kentik.com/v4/Na03.htm" small blank>
            How does Kentik Kube work?
          </Link>
          <Flex gap={1}>
            <Box mx="auto" flex={1}>
              <Image src={kubeNoDevicesConfigured} alt="Kentik Kube No Devices Configured" />
            </Box>
            <CalloutOutline intent="default" flex={1} border="none">
              <FlexColumn gap={2} p={1} justifyContent="space-evenly" height="100%">
                <FlexColumn gap={1}>
                  <Text fontWeight="bold">1. Clone the repository.</Text>
                  <Flex gap={1}>
                    <Flex alignItems="center">
                      <Link to="https://github.com/kentik/kentik-kube-deploy.git" blank>
                        <Icon icon={FaGithub} iconSize={30} color={theme.colors.blackText} />
                      </Link>
                    </Flex>
                    <CalloutOutline>
                      <Flex justifyContent="space-between" alignItems="center">
                        <code>git clone https://github.com/kentik/kentik-kube-deploy.git</code>
                        <CopyToClipboardButton text="git clone https://github.com/kentik/kentik-kube-deploy.git">
                          <Button icon={FiCopy} minimal small />
                        </CopyToClipboardButton>
                      </Flex>
                    </CalloutOutline>
                  </Flex>
                </FlexColumn>

                <FlexColumn gap={2}>
                  <Text fontWeight="bold">2. Configure the deploy script.</Text>
                  <CalloutOutline>
                    <Flex justifyContent="space-between" alignItems="center">
                      <FlexColumn>
                        <code>cd kentik-kube-deploy</code>
                        <code>nano deploy-kube.sh</code>
                      </FlexColumn>

                      <CopyToClipboardButton text="cd kentik-kube-deploy \ nano deploy-kube.sh">
                        <Button icon={FiCopy} minimal small />
                      </CopyToClipboardButton>
                    </Flex>
                  </CalloutOutline>
                  <Text>
                    Edit the <em>USER CONFIGURATION</em> section of <em>deploy-kube.sh</em>
                  </Text>
                  <CalloutOutline>
                    <FlexColumn>
                      <code>PLAN={defaultCloudPlanId}</code>
                      <code>EMAIL={email}</code>
                      <code>TOKEN={token}</code>
                      <code>
                        CLUSTER=<b>{'<your_cluster_name>'}</b>
                      </code>
                      <code>
                        CLOUDPROVIDER=<b>{'<aws_or_gcp_or_azure_or_prem>'}</b>
                      </code>
                    </FlexColumn>
                  </CalloutOutline>
                </FlexColumn>

                <FlexColumn gap={1}>
                  <Text fontWeight="bold">3. Run the deploy script.</Text>
                  <CalloutOutline>
                    <Flex justifyContent="space-between" alignItems="center">
                      <code>./deploy-kube.sh</code>
                      <CopyToClipboardButton text="./deploy-kube.sh">
                        <Button icon={FiCopy} minimal small />
                      </CopyToClipboardButton>
                    </Flex>
                  </CalloutOutline>
                </FlexColumn>
              </FlexColumn>
            </CalloutOutline>
          </Flex>
          <Text fontWeight="bold">Lost or new to Kubernetes?</Text>
          <ConnectWithSales />
        </FlexColumn>
        {/* </Flex> */}
      </FlexColumn>
    );
  }
}

export default KubeMapNoDevicesFound;
