export function getLayerIndex(layers, deviceId) {
  for (let layerIndex = 0; layerIndex < layers.length; layerIndex += 1) {
    const { subLayers } = layers[layerIndex];

    for (let subLayerIndex = 0; subLayerIndex < subLayers.length; subLayerIndex += 1) {
      const { devices } = subLayers[subLayerIndex];
      const deviceIndex = devices.findIndex((id) => Number(id) === Number(deviceId));

      if (deviceIndex > -1) {
        return { layerIndex, subLayerIndex, deviceIndex };
      }
    }
  }

  return { layerIndex: -1 };
}
