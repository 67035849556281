import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { AiOutlineLineChart } from 'react-icons/ai';
import { FiGrid, FiDownload } from 'react-icons/fi';
import PageHeading from 'app/components/page/PageHeading';
import { Text, Flex, Icon, Card, AnchorButton } from 'core/components';
import { Table, CELL_TYPES } from 'core/components/table';
import { timezone } from 'core/util/dateUtils';
import Page from 'app/components/page/Page';

function getMetadataFromPath(recentExport) {
  const type = recentExport.get('viewType');
  if (type === 'explorer') {
    return { icon: 'series-search', label: 'Explorer Query' };
  }
  if (type === 'dashboard') {
    return { icon: FiGrid, label: recentExport.get('viewTitle') };
  }
  if (type === 'savedView') {
    return { icon: AiOutlineLineChart, label: recentExport.get('viewTitle') };
  }
  return {
    label: recentExport.get('viewTitle') || recentExport.get('fileName')
  };
}

function getHrefForExport(recentExport) {
  const payload = recentExport.get('payload');

  let href;

  if (recentExport.get('type') === 'csv') {
    const blob = new Blob([payload], { type: 'text/csv;charset=utf-8;' });
    href = URL.createObjectURL(blob);
  } else {
    href = window.URL.createObjectURL(payload);
  }

  return href;
}

const tableColumns = [
  {
    name: 'path',
    label: 'Export',
    renderer: ({ model }) => {
      const { icon, label } = getMetadataFromPath(model);
      return (
        <>
          <Icon icon={icon} mr={2} />
          <Text>{label}</Text>
        </>
      );
    }
  },
  {
    name: 'type',
    label: 'Type',
    width: 60,
    renderer: ({ value }) => (value ? value.toUpperCase() : value)
  },
  {
    name: 'generated',
    label: 'Generated',
    width: 150,
    renderer: ({ value }) => timezone.momentFn(value).fromNow()
  },
  {
    type: CELL_TYPES.ACTION,
    width: 110,
    actions: [
      (model) => (
        <AnchorButton
          key="download"
          download={`${model.get('fileName')}.${model.get('type')}`}
          href={getHrefForExport(model)}
          icon={FiDownload}
          small
          text="Download"
        />
      )
    ]
  }
];

@inject('$exports')
@observer
export default class RecentExports extends Component {
  render() {
    const { $exports } = this.props;

    return (
      <Page title="Recent Exports" hideSubnav>
        <Flex alignItems="center">
          <PageHeading title="Recent Exports" />
        </Flex>
        <Card overflow="auto">
          <Table columns={tableColumns} collection={$exports.recentExports} selectOnRowClick={false} />
        </Card>
      </Page>
    );
  }
}
