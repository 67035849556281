import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Checkbox, Flex, Switch, Text } from 'core/components';
import AdminTable from 'app/components/admin/AdminTable';
import RbacPermCollection from 'app/stores/rbac/RbacPermCollection';
import LabelList from 'app/components/labels/LabelList';
import UserRolesFilterSidebar from 'app/views/settings/users/UserRolesFilterSidebar';
import { formConsumer } from 'core/form';
import { unionBy } from 'lodash';

@formConsumer
@inject('$rbac', '$labels')
@observer
export default class UserRoles extends Component {
  state = {
    showPermissionOrigin: false
  };

  permissionCollection = new RbacPermCollection([], {
    groupBy: 'group'
  });

  componentDidMount() {
    this.permissionCollection.fetch().then(() => {
      this.updatePermissions();
    });
  }

  updatePermissions = () => {
    const { roleCollection, roleSetRoleCollection } = this.props;
    this.permissionCollection.get().forEach((permModel) => {
      const permissionsFromRole = roleCollection.permissionsWithLabels[permModel.get('permission')];
      const permissionsFromRoleSet = roleSetRoleCollection.permissionsWithLabels[permModel.get('permission')];
      const matchingPermissions = unionBy(permissionsFromRole, permissionsFromRoleSet, 'role_id');

      if (matchingPermissions.length) {
        const rolesWithLabels = matchingPermissions.filter((role) => role.label_metadata);

        permModel.set({
          isEnabled: true,
          label_metadata: matchingPermissions,
          labelBasedAccess: rolesWithLabels.length === matchingPermissions.length
        });
      } else {
        permModel.set({ isEnabled: false, label_metadata: null, labelBasedAccess: false });
      }
    });
  };

  groupSummaryLookupFn = ({ groupKey }) => (
    <Box>
      <Text>{groupKey}</Text>
    </Box>
  );

  permissionRenderer = ({ model }) => {
    const { $labels } = this.props;

    const { showPermissionOrigin } = this.state;

    return (
      <Flex flexDirection="column" data-id="permDetails">
        <Flex>{model.get('display')}</Flex>
        {showPermissionOrigin &&
          (model.get('label_metadata') || []).map((role) => (
            <Box key={role.role_id}>
              {role.label_metadata?.length > 0 && (
                <Flex alignItems="center" ml={3}>
                  <Box mr={1}>with</Box>
                  <Box flex={1} style={{ overflowY: 'auto', paddingBottom: '4px' }}>
                    <LabelList labels={$labels.getLabelOptionsFromIds(role.label_metadata || [])} />
                  </Box>
                </Flex>
              )}
              <Box>
                <Text small muted>
                  from role: {role.role_name}
                </Text>
              </Box>
            </Box>
          ))}
        {!showPermissionOrigin && (
          <Box>
            {(model.get('label_metadata') || []).some((r) => r.label_metadata) && (
              <Flex alignItems="center" ml={3}>
                <Box mr={1}>with</Box>
                <Box flex={1} style={{ overflowY: 'auto', paddingBottom: '4px' }}>
                  <LabelList
                    labels={$labels.getLabelOptionsFromIds(
                      model
                        .get('label_metadata')
                        .map((role) => role.label_metadata || [])
                        .flat()
                    )}
                  />
                </Box>
              </Flex>
            )}
          </Box>
        )}
      </Flex>
    );
  };

  onShowPermissionOriginCheck = (checked) => {
    this.setState({ showPermissionOrigin: checked });
  };

  get columns() {
    const columns = [
      {
        name: 'isEnabled',
        renderer: ({ model }) => {
          const switchId = `permIsEnabled${model.id}`;
          return (
            <Box p={1}>
              <Switch key="select" disabled checked={model.get('isEnabled') === true} data-testid={switchId} />
            </Box>
          );
        },

        minWidth: 60,
        width: 60
      },
      {
        flexBasis: 100,
        ellipsis: false,
        name: 'label_metadata',
        computed: true,
        renderer: this.permissionRenderer
      },
      {
        name: 'isLabelBasedAccess',
        width: 160,
        computed: true,
        referencedFields: ['isLabelBasedAccess', 'labelBasedAccess', 'isEnabled'],
        renderer: ({ value }) => (value ? 'Label Based Access' : 'Full Access')
      }
    ];
    return columns;
  }

  render() {
    const { roleCollection, roleSetCollection, roleSetRoleCollection, form } = this.props;
    return (
      <Flex flexDirection="column" flex={1} overflow="auto">
        <AdminTable
          columns={this.columns}
          collection={this.permissionCollection}
          containerProps={{ overflow: 'auto' }}
          tableContainerProps={{ overflow: 'auto' }}
          noVirtualizedTable
          groupSummaryLookup={this.groupSummaryLookupFn}
          hideHeader
          deepCompareOnUpdate
          multiSelect={false}
          fetchCollectionOnMount={false}
          rowAlign="center"
          flexed
          selectOnRowClick={false}
          tableHeaderControls={
            <Checkbox
              label="Show permission origin"
              onChange={(checked) => this.onShowPermissionOriginCheck(checked)}
            />
          }
          tableHeaderControlsPosition="afterSearch"
          filterSidebar={
            <UserRolesFilterSidebar
              permissionCollection={this.permissionCollection}
              onRoleChange={this.updatePermissions}
              roleCollection={roleCollection}
              roleSetCollection={roleSetCollection}
              roleSetRoleCollection={roleSetRoleCollection}
              selectedUser={form.model}
            />
          }
        />
      </Flex>
    );
  }
}
