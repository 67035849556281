import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';

import { Select } from 'core/components';
import LabelOptionRenderer from 'app/components/labels/LabelOptionRenderer';
import LabelList from 'app/components/labels/LabelList';
import LabelValueTagRenderer from 'app/components/labels/LabelValueTagRenderer';

@inject('$auth', '$labels')
@withRouter
class LabelSelectorList extends Component {
  /*
    This is a simpler form of LabelSelector that does NOT depend on forms, etc.
    It also puts the add label capability in a renderAsButton form of select
    That button can be configured with normal Select props via restSelectProps

  */

  render() {
    const { $labels, handleLabelSelect, onRemove = () => {}, selectedList, ...restSelectProps } = this.props;

    const addButtonElement = (
      <span>
        <span key="endElement" style={{ maxWidth: '110px', display: 'inline-block', height: '16px' }}>
          <div style={{ width: '280px', maxWidth: '240px' }}>
            <Select
              optionRenderer={LabelOptionRenderer}
              valueTagRenderer={LabelValueTagRenderer}
              options={$labels.options}
              fill
              multi
              toggle
              renderAsButton
              hideSelected
              buttonStyle={{
                style: {
                  height: '16px',
                  verticalAlign: 'middle',
                  width: '106px',
                  float: 'left',
                  maxWidth: '106px',
                  icon: 'plus'
                }
              }}
              label="Add Label"
              inlineLabel
              small
              showFilter
              values={selectedList}
              onChange={handleLabelSelect}
              {...restSelectProps} // This should represent attributes like showFilter, keepOpen, etc
            />
          </div>
        </span>
      </span>
    );

    return (
      <>
        <LabelList
          p={1}
          display="inline-block"
          labels={$labels.getLabelOptionsFromIds(selectedList)}
          onRemove={onRemove}
          renderEmpty
          endElement={addButtonElement}
          data-testid="labelList"
        />
      </>
    );
  }
}

export default LabelSelectorList;
