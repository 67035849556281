import * as React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { toDecimal } from 'core/util';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import { Box, Button, EmptyState, Flex, Tag, Text } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import { AZURE_ENTITY_TYPES } from 'shared/hybrid/constants';
import SearchableTable from 'app/views/hybrid/maps/components/SearchableTable';

@inject('$hybridMap')
@observer
export default class BackendPoolsPanel extends React.Component {
  state = {
    loading: true,
    isPoppedOut: false,
    data: null
  };

  componentDidMount() {
    const { $hybridMap } = this.props;

    this.timeRangeDisposer = reaction(
      () => $hybridMap.sidebarSettings.timeRange,
      () => this.fetchPools()
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { nodeData } = this.props;
    const { data, isPoppedOut } = this.state;
    const popOutChange = prevState.isPoppedOut !== isPoppedOut;

    if (prevProps.nodeData?.id !== nodeData?.id || (data === null && popOutChange)) {
      // if we have the sidebar open and switch to a new load balancer, fetch for new data
      this.fetchPools();
    }
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.cancel();
    }

    if (this.timeRangeDisposer) {
      this.timeRangeDisposer();
    }
  }

  fetchPools = () => {
    const { $hybridMap, nodeData } = this.props;

    this.setState({ loading: true }, () => {
      this.request = makeCancelable($hybridMap.getAzureBackendPools({ id: nodeData.id }));

      this.request.promise
        .then((response) => {
          this.setState({ loading: false, data: response });
        })
        .catch((e) => {
          if (e instanceof CanceledError === false) {
            this.setState({ loading: false }, () => console.error(e));
          }
        });
    });
  };

  handleExpandChange = (isOpen) => {
    const { data } = this.state;

    if (isOpen && data === null) {
      // fetch for data when expanding for the first time
      this.fetchPools();
    }
  };

  handlePopOutChange = (isPoppedOut) => {
    this.setState({ isPoppedOut }, this.handleExpandChange);
  };

  handlePanelRefresh = (e) => {
    e.stopPropagation();
    this.fetchPools();
  };

  get columns() {
    return [
      {
        name: 'resourceName',
        label: 'Resource'
      },

      {
        name: 'networkInTotal',
        label: 'Inbound Dist%',
        width: 75,
        renderer: this.renderLoadDistribution
      },

      {
        name: 'networkOutTotal',
        label: 'Outbound Dist%',
        width: 75,
        renderer: this.renderLoadDistribution
      },

      {
        name: 'dipAvailability',
        label: 'Health Probe',
        width: 75
      },

      {
        name: 'ipAddress',
        label: 'IP Address',
        width: 110
      }
    ];
  }

  renderLoadDistribution = ({ value, collection, column, model }) => {
    if (model.get('vmId')) {
      // only backend pools of type virtual machine will get stats in the table cells
      // vm scale sets are reported in the group summary
      const total = collection.models.reduce((acc, m) => acc + m.get(column.name, 0), 0);

      if (total > 0) {
        return `${toDecimal(value / total) * 100}%`;
      }
    }

    return '--';
  };

  groupSummary = ({ groupKey, group, collection }) => {
    const label = groupKey === 'undefined' ? 'Ungrouped' : groupKey;
    const allStats = collection.models.reduce(
      (acc, model) => ({
        in: acc.in + model.get('networkInTotal', 0),
        out: acc.out + model.get('networkOutTotal', 0)
      }),
      { in: 0, out: 0 }
    );
    const groupStats = group.reduce(
      (acc, model) => ({
        in: acc.in + model.get('networkInTotal', 0),
        out: acc.out + model.get('networkOutTotal', 0)
      }),
      { in: 0, out: 0 }
    );

    return (
      <Flex alignItems="center">
        <Tag mr={1} minimal>
          {group.length}
        </Tag>
        <Box>
          <Text as="div">{label}</Text>
          {allStats.in > 0 && allStats.out > 0 && (
            <Text>
              {`${toDecimal(groupStats.in / allStats.in) * 100}% Inbound,`}{' '}
              {`${toDecimal(groupStats.out / allStats.out) * 100}% Outbound`}
            </Text>
          )}
        </Box>
      </Flex>
    );
  };

  render() {
    const { cloudProvider, width, popoutTitle } = this.props;
    const { loading, data } = this.state;

    return (
      <SidebarItem
        excludeFormProps
        title="Backend Pools"
        dialogProps={{ width }}
        popoutTitle={popoutTitle}
        navigationButtons={<Button icon="refresh" color="success" onClick={this.handlePanelRefresh} small minimal />}
        onExpandChange={this.handleExpandChange}
        onPopOutChange={this.handlePopOutChange}
        filledIconTag={false}
        icon={
          <CloudIcon
            cloudProvider={cloudProvider}
            entity={AZURE_ENTITY_TYPES.VIRTUAL_MACHINE}
            style={{ marginTop: '3px' }}
          />
        }
      >
        <SearchableTable
          height={240}
          loading={loading}
          collectionOptions={{ groupBy: 'backendPoolName' }}
          columns={this.columns}
          data={data || []}
          stickyHeader
          stickyGroups
          groupSummary={this.groupSummary}
          emptyState={
            <EmptyState
              title="No Results"
              icon={
                <CloudIcon cloudProvider={cloudProvider} entity={AZURE_ENTITY_TYPES.VIRTUAL_MACHINE} iconSize={48} />
              }
            />
          }
        />
      </SidebarItem>
    );
  }
}
