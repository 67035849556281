import React, { Component } from 'react';
import { Flex, Box } from 'core/components';

class SharedFooter extends Component {
  render() {
    const styles = {
      textAlign: 'center',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#5C7080'
    };

    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Box flex={1} alignItems="center" style={styles}>
          <p>Kentik is the network observability company.</p>
          <p>
            &copy;&nbsp;2014-{new Date().getFullYear()}&nbsp;
            <a href="https://www.kentik.com" target="_blank" rel="noreferrer">
              Kentik Technologies, Inc.
            </a>
          </p>
        </Box>
      </Flex>
    );
  }
}

export default SharedFooter;
