import React from 'react';
import Page from 'app/components/page/Page';
import { Box, Button, Flex, FlexColumn, Heading, Image, Text } from 'core/components';
import kubeMagicDoorPng from 'app/assets/kube/kentik-kube-2023-shopping-no-header.png';
import { inject } from 'mobx-react';
import { Header } from 'app/views/NotPermittedView';

const KubeMagicDoor = ({ $app }) => {
  const openFeedbackModal = () => {
    $app.setReportIssuePopoverPropsAndOpen({
      type: 'kube request',
      summary: 'I would like to join Kentik Kube beta.',
      description: ''
    });
  };

  return (
    <Page
      title="Kentik Kube"
      scrolls
      toolbar={
        <Header py={4}>
          <Box px={3}>
            <FlexColumn width="100%">
              <Heading level={1}>Network-layer Kubernetes Visibility at Your Fingertips.</Heading>
            </FlexColumn>
          </Box>
        </Header>
      }
    >
      <FlexColumn width="100%" pb={30} gap={2}>
        <Flex>
          <Heading level={4} fontWeight="normal">
            Unleash network observability for Kubernetes! Click the button below to let Kentik’s product team know
            you’re interested, and we’ll reach out to discuss availability and next steps.
          </Heading>
        </Flex>
        <Flex gap={3} pt={20}>
          <FlexColumn gap={5} flex={2}>
            <FlexColumn gap={1}>
              <Text as="b" large>
                Ensure Kubernetes Performance
              </Text>
              <Text>
                Discover which services and pods are experiencing network delays in order to troubleshoot and fix
                problems faster. Configure alert policies to proactively find high latency nodes, pods, workloads, or
                services.
              </Text>
            </FlexColumn>

            <FlexColumn gap={1}>
              <Text as="b" large>
                Optimize Costs
              </Text>
              <Text>
                Quickly detect traffic changes tied to new deployments or misconfigurations before egress, inter-region
                transfer, and gateway charges get out of control.
              </Text>
            </FlexColumn>

            <FlexColumn gap={1}>
              <Text as="b" large>
                Total Infrastructure Visibility
              </Text>
              <Text>
                Know which pods were deployed on which nodes — even historically. See which pods and services are
                communicating with other clusters, non-Kubernetes infrastructure, or the Internet. Quickly detect top
                talkers. Identify Kubernetes clusters sending or receiving traffic from embargoed countries or
                unapproved external destinations.
              </Text>
            </FlexColumn>

            <Box>
              <Button large intent="primary" onClick={openFeedbackModal}>
                I want in!
              </Button>
            </Box>
          </FlexColumn>

          <FlexColumn flex={2} border="3px solid #345583">
            <Image src={kubeMagicDoorPng} alt="Kentik Kube demo" p={1} />
          </FlexColumn>
        </Flex>
        <Box borderTop="1px solid lightgray" mt={20} />
        <FlexColumn gap={2}>
          <Heading level={3}>Product Literature</Heading>
          <Flex gap={2} width="50%">
            <Box flex={1}>
              <Text
                as="a"
                large
                href="https://www.kentik.com/resources/kentik-kube-kubernetes-monitoring/"
                target="_blank"
                rel="noreferrer"
              >
                Kentik Kube Solution Brief
              </Text>
            </Box>
            <Box flex={1}>
              <Text
                as="a"
                large
                href="https://www.kentik.com/blog/introducing-kentik-kube/"
                target="_blank"
                rel="noreferrer"
              >
                Press Release
              </Text>
            </Box>
          </Flex>
        </FlexColumn>
      </FlexColumn>
    </Page>
  );
};

export default inject('$app')(KubeMagicDoor);
