import React from 'react';
import { get } from 'lodash';

import { idToType } from 'app/views/hybrid/utils/aws';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';

import { Flex } from 'core/components';

import SidebarItem from '../SidebarItem';
import CloudMetadata from '../CloudMetadata';

const { DIRECT_CONNECTION_GATEWAY } = ENTITY_TYPES.get('aws');

const GatewayAssociation = ({ nodeData, width, popoutTitle }) => {
  const { gatewayType } = nodeData;
  const { VpcAttachments = [] } = nodeData;
  const attachedVpcIds = VpcAttachments.map(({ VpcId }) => VpcId).join(',');

  return (
    <SidebarItem
      excludeFormProps
      title={nodeData?.VirtualInterfaceName ?? nodeData?.id}
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon={<CloudIcon cloudProvider="aws" entity={gatewayType} width={16} height={16} />}
      popoutTitle={popoutTitle}
    >
      <CloudMetadata
        node={{
          ...nodeData,
          VpcAttachments: attachedVpcIds
        }}
        keys={['id', 'state', 'OwnerId', 'Tags', 'VpcAttachments']}
      />
    </SidebarItem>
  );
};

const AwsGatewayAssociationsPopover = ({ width, popoutTitle, type, nodeData, topology }) => {
  if (type !== DIRECT_CONNECTION_GATEWAY) {
    return null;
  }

  const { Associations = [] } = nodeData;

  if (Associations.length === 0) {
    return null;
  }

  const associatedGateways = Associations.map(({ AssociatedGateway }) => {
    const gatewayType = idToType(AssociatedGateway?.Id);

    const gatewayEntity = get(topology.Entities, `${gatewayType}.${AssociatedGateway?.Id}`);

    if (!gatewayEntity) {
      return null;
    }

    return {
      ...AssociatedGateway,
      ...gatewayEntity,
      gatewayType: AssociatedGateway.Type
    };
  }).filter((gateway) => gateway);

  if (associatedGateways.length === 0) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title="Gateway Associations"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="lock"
      popoutTitle={popoutTitle}
    >
      <Flex gap={1} flexDirection="column">
        {associatedGateways.map((associatedGateway) => (
          <GatewayAssociation
            key={associatedGateway.id}
            width={width}
            nodeData={associatedGateway}
            popoutTitle={popoutTitle}
          />
        ))}
      </Flex>
    </SidebarItem>
  );
};

export default AwsGatewayAssociationsPopover;
