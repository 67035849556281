import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, CalloutOutline, Flex, Icon, Heading, Text } from 'core/components';
import { ReactComponent as KubePodIcon } from 'app/assets/icons/kubernetes/pod.svg';
import { ReactComponent as KubePodIconProblem } from 'app/assets/icons/kubernetes/pod_problem.svg';
import { ReactComponent as NamespaceIcon } from 'app/assets/icons/kubernetes/ns.svg';
import { ReactComponent as WorkloadIcon } from 'app/assets/icons/kubernetes/deploy.svg';

@observer
class K8PodSummary extends React.Component {
  render() {
    const { id } = this.props;
    const { nodeData } = this.props;
    const { health, selectedNamespace, clusterName, clusterId, latency } = nodeData;
    const hasProblem = health?.data.state === 'CRITICAL' || health?.data.state === 'WARNING';

    return (
      <>
        <Flex alignItems="center" borderBottom="thin" p={2} mb={1}>
          <Icon icon={hasProblem ? KubePodIconProblem : KubePodIcon} iconSize={40} mr={1} />
          <Box>
            <Text as="div" muted>
              Pod Details
            </Text>
            <Heading level={3} mb={0}>
              {id}
            </Heading>
          </Box>
        </Flex>

        {hasProblem && (
          <Box px={2}>
            <CalloutOutline intent="danger">This pod is experiencing some high latency</CalloutOutline>
          </Box>
        )}

        <Box p={2} py={1} borderBottom="thin">
          <Flex mb="4px" alignItems="center">
            <Text muted as="div" mr="4px">
              Name:
            </Text>
            <Text fontWeight="medium">{id}</Text>
          </Flex>
          {/* <Flex mb="4px" alignItems="center">
            <Text muted as="div" mr="4px">
              IP Address:
            </Text>
            <Text fontWeight="medium">---</Text>
          </Flex> */}
          {clusterId > 0 && (
            <Flex mb="4px" alignItems="center">
              <Text muted as="div" mr="4px">
                Cluster ID:
              </Text>
              <Flex alignItems="center">
                <Text fontWeight="medium">{clusterId}</Text>
              </Flex>
            </Flex>
          )}
          <Flex mb="4px" alignItems="center">
            <Text muted as="div" mr="4px">
              Cluster Name:
            </Text>
            <Flex alignItems="center">
              <Text fontWeight="medium">{clusterName}</Text>
            </Flex>
          </Flex>
          <Flex mb="4px" alignItems="center">
            <Text muted as="div" mr="4px">
              Namespace:
            </Text>
            <Flex alignItems="center">
              <Icon icon={NamespaceIcon} iconSize={16} mr="4px" color="primary" />
              <Text fontWeight="medium">{selectedNamespace}</Text>
            </Flex>
          </Flex>
          <Flex mb="4px" alignItems="center">
            <Text muted as="div" mr="4px">
              Workload:
            </Text>
            <Flex alignItems="center">
              <Icon icon={WorkloadIcon} iconSize={16} mr="4px" color="primary" />
              <Text fontWeight="medium">Not set</Text>
            </Flex>
          </Flex>
          {latency && (
            <Flex mb="4px" alignItems="center">
              <Text muted as="div" mr="4px">
                Latency:
              </Text>
              <Flex alignItems="center">
                <Text fontWeight="medium">{Math.round(latency)}ms</Text>
              </Flex>
            </Flex>
          )}
        </Box>
      </>
    );
  }
}

export default K8PodSummary;
