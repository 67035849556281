import React from 'react';

import { inject } from 'mobx-react';
import { Flex, Text } from 'core/components';

import SidebarItem from '../../SidebarItem';
import CloudMetadata from '../../CloudMetadata';
import AzureVpnSiteLinkDetails from './VpnSiteLinkDetails';

const AzureVpnSiteDetails = ({ width, popoutTitle, $hybridMap, nodeData }) => {
  const { azureCloudMapCollection } = $hybridMap;

  const vpnSiteLinks = azureCloudMapCollection.getEntityProperty(nodeData, 'vpnSiteLinks');

  return (
    <SidebarItem
      excludeFormProps
      title={nodeData?.name}
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="route"
      popoutTitle={popoutTitle}
    >
      <Flex flexDirection="column" gap={1}>
        <CloudMetadata
          node={{
            ...nodeData,
            ...nodeData.properties
          }}
          keys={['addressSpace.addressPrefixes']}
        />

        {vpnSiteLinks?.length && (
          <Flex flexDirection="column" gap={1}>
            <Text as="div">VPN Site Links:</Text>
            {vpnSiteLinks.map((siteLink) => (
              <AzureVpnSiteLinkDetails key={siteLink.id} nodeData={siteLink} />
            ))}
          </Flex>
        )}
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureVpnSiteDetails);
