import React from 'react';
import { render } from 'react-dom';
import 'core-js/features/array/flat-map';
import 'core-js/features/array/flat';
import 'core-js/features/string/trim-end';

import $store from 'app/$store';

import Root from './Root';
import AppWrapper from './AppWrapper';

const root = document.createElement('div');
root.id = 'app-root';
document.body.appendChild(root);

render(<Root $store={$store} component={AppWrapper} />, root);
