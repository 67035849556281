import React from 'react';

import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { Box, Flex, Tag, Text } from 'core/components';
import { ReactComponent as ClusterIcon } from 'app/assets/icons/kubernetes/cluster.svg';
import classNames from 'classnames';
import { getMapClassname } from '../../utils/map';

const { CLUSTER } = ENTITY_TYPES.get('kube');

export default function RelatedClusters({ clusters, handleOnClick, highlighted = [] }) {
  if (clusters.length === 0) {
    return null;
  }

  return (
    <Box bg="appBackground" border="thinLighter" borderRadius={8} p={2}>
      <Flex gap={1} alignItems="center" mb={1}>
        <Text fontWeight={700}>Related Cluster{clusters.length === 1 ? '' : 's'}</Text>
        <Tag round minimal>
          {clusters.length}
        </Tag>
      </Flex>
      <Flex gap="1px" flexDirection="column">
        {clusters.map((cluster) => {
          const isHighlighted = highlighted.includes(cluster.id);

          return (
            <Flex key={cluster.id} gap={3} alignItems="center" justifyContent="space-between">
              <Flex gap={1} alignItems="center">
                <ClusterIcon width={25} height={25} style={{ display: 'block', flex: '0 0 25px' }} />
                <Text ellipsis>{cluster.name}</Text>
              </Flex>

              <Box
                onClick={() =>
                  handleOnClick({
                    type: CLUSTER,
                    value: cluster.id,
                    nodeData: cluster
                  })
                }
                flex="0 0 14px"
                border={isHighlighted ? 'active' : 'thick'}
                borderWidth={2}
                borderRadius="50%"
                width={14}
                height={14}
                style={{ cursor: 'pointer' }}
                className={
                  ('hybrid-map-selectable-node',
                  'node',
                  classNames(getMapClassname({ type: CLUSTER, value: cluster.id })))
                }
              />
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
}
