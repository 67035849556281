import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Form, Field, SubmitButton } from 'core/form';
import { Dialog, Button, Flex, Box, Text } from 'core/components';
import InputGroup from 'core/form/components/InputGroup';

const fields = {
  groupName: {
    label: 'Group Name',
    rules: 'required'
  }
};

@Form({ fields })
@observer
class MapSearchGroupDialog extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    groupName: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    selectedCount: PropTypes.number
  };

  static defaultProps = {
    isOpen: false,
    groupName: '',
    selectedCount: 0
  };

  state = {
    isLoading: false
  };

  componentDidUpdate(prevProps) {
    const { groupName, form, isOpen } = this.props;
    if (prevProps.groupName !== groupName) {
      form.setValue('groupName', groupName);
    }

    if (prevProps.isOpen === false && isOpen === true) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isLoading: false });
    }
  }

  handleFormSubmit(values) {
    const { onSubmit } = this.props;
    const { groupName } = values;

    this.setState({ isLoading: true });
    onSubmit(groupName);
  }

  handleFormCancel() {
    const { form, onClose } = this.props;
    form.reset();
    onClose();
  }

  render() {
    const { isOpen, selectedCount, form } = this.props;
    const { isLoading } = this.state;

    return (
      <Dialog isOpen={isOpen} canEscapeKeyClose={false} canClickOutside={false} top={100}>
        <Dialog.Body p={2} overflow="hidden" display="flex" flexDirection="column" gap={1}>
          <Flex justifyContent="space-between">
            <Text>Group Name</Text>
            <Text>{selectedCount} selected</Text>
          </Flex>
          <Box w="100%">
            <Field name="groupName" showLabel={false}>
              <InputGroup autoFocus />
            </Field>
          </Box>
        </Dialog.Body>

        <Dialog.Footer>
          <Flex gap={1}>
            <Button onClick={() => this.handleFormCancel()} text="Cancel" />
            <SubmitButton
              text="Confirm"
              intent="primary"
              loading={isLoading}
              disabled={!form.dirty || !form.valid}
              onSubmit={(_form, values) => this.handleFormSubmit(values)}
            />
          </Flex>
        </Dialog.Footer>
      </Dialog>
    );
  }
}

export default MapSearchGroupDialog;
