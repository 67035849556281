import * as React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT, timezone } from 'core/util/dateUtils';
import { Box } from 'core/components';
import { formConsumer } from 'core/form';
import LookbackDateRange from 'core/form/components/LookbackDateRange';

@formConsumer
@observer
export default class TimeOptions extends React.Component {
  formatDateIn = (date) => {
    if (!date) {
      return null;
    }

    return moment.utc(date).unix();
  };

  formatDateOut = (unixTimestamp) => {
    if (!unixTimestamp) {
      return '';
    }

    return moment.unix(unixTimestamp).utc().format(DEFAULT_DATETIME_FORMAT);
  };

  handleChange = ({ lookbackSeconds, startDate, endDate }) => {
    const { form, onChange } = this.props;
    const lookback_seconds = lookbackSeconds || 0;
    const starting_time = this.formatDateOut(startDate);
    const ending_time = this.formatDateOut(endDate);

    form.getField('sidebarQueryOverrides.time_format').setValue(timezone.value);
    form.getField('sidebarQueryOverrides.lookback_seconds').setValue(lookback_seconds);
    form.getField('sidebarQueryOverrides.starting_time').setValue(starting_time);
    form.getField('sidebarQueryOverrides.ending_time').setValue(ending_time);

    if (onChange) {
      onChange(form.getValues());
    }
  };

  render() {
    const { form, model, onChange, ...boxProps } = this.props;
    const startDate = this.formatDateIn(form.getValue('sidebarQueryOverrides.starting_time'));
    const endDate = this.formatDateIn(form.getValue('sidebarQueryOverrides.ending_time'));
    const lookbackSeconds = form.getValue('sidebarQueryOverrides.lookback_seconds');

    return (
      <Box {...boxProps}>
        <LookbackDateRange
          lookbackSeconds={lookbackSeconds}
          startDate={startDate}
          endDate={endDate}
          onChange={this.handleChange}
          dropdown
        />
      </Box>
    );
  }
}
