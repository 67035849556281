import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { QUICK_VIEW_ROUTE } from './core';

@inject('$auth', '$devices', '$syn')
@observer
export default class DefaultView extends Component {
  render() {
    const { $auth, $devices, $syn } = this.props;

    // New users get pageType: observationDeck, Old users may not have anything set
    // We default them to networkExplorer and let them opt-in to observationDeck
    const { defaultLandingPageType = 'observationDeck', defaultLandingPageValue } = $auth.activeUser;
    const lastVistedPage = localStorage.getItem('kentik.lastVisitedPage');
    const ssoInitialReferer = sessionStorage.getItem('kentik.ssoInitialReferer');

    // TODO: We'll plan to remove these when synthetics is sufficiently supported within Observation Deck
    const legacyDefaultPath =
      $devices.deviceSummaries.length === 0 && $syn.tests.size > 0 ? '/v4/synthetics' : '/v4/core';

    const redirects = {
      observationDeck: () => '/v4/home',
      networkExplorer: () => legacyDefaultPath,
      nmsDashboard: () => '/v4/nms',
      library: () => '/v4/library',
      alerting: () => '/v4/alerting',
      insights: () => '/v4/core/insights',
      explorer: () => '/v4/core/explorer',
      dashboard: (value) => `/v4/library/dashboards/${value}`,
      'saved-view': (value) => `/v4/library/saved-views/${value}`,
      savedView: (value) => `/v4/library/saved-views/${value}`,
      synthetics: () => '/v4/synthetics',
      'market-intel': () => '/v4/service-provider/market-intel',
      'quick-view': (value) => `${QUICK_VIEW_ROUTE}/${value}`
    };

    if (ssoInitialReferer) {
      sessionStorage.removeItem('kentik.ssoInitialReferer');
      return <Redirect to={new URL(ssoInitialReferer).pathname} />;
    }

    if (lastVistedPage) {
      localStorage.removeItem('kentik.lastVisitedPage');
      return <Redirect to={lastVistedPage} />;
    }

    if (defaultLandingPageType && redirects[defaultLandingPageType]) {
      return <Redirect to={redirects[defaultLandingPageType](defaultLandingPageValue)} />;
    }

    return <Redirect to="/v4/home" />;
  }
}
