import React from 'react';

import { inject } from 'mobx-react';
import { Flex, Box } from 'core/components';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { isSameLinkNode } from 'app/views/hybrid/utils/map';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { getMetadataValues } from 'app/views/hybrid/maps/components/popovers/summaries/VirtualNetworkSummary';

import SidebarItem from '../SidebarItem';
import CloudMetadata from '../CloudMetadata';

const { VNET } = ENTITY_TYPES.get('azure');

const AzureAssociatedVNetsPopover = ({ cloudProvider, width, popoutTitle, $hybridMap, nodeData, type, links }) => {
  const { azureCloudMapCollection } = $hybridMap;

  const linkNode = { type, value: nodeData.id };
  // get links that only connected to current node
  const nodeLinks = links.filter(
    ({ source, target }) => isSameLinkNode(linkNode, source) || isSameLinkNode(linkNode, target)
  );

  const connectedVNetIds = [
    ...new Set(
      nodeLinks.reduce((carry, link) => {
        const { source, target } = link;

        if (source.type === VNET) {
          carry.push(source.value);
        }

        if (target.type === VNET) {
          carry.push(target.value);
        }

        return carry;
      }, [])
    )
  ];

  const vNets = connectedVNetIds
    .map((vnetId) => azureCloudMapCollection.topology.Entities[VNET][vnetId] ?? null)
    .filter((vnet) => vnet);

  if (vNets.length === 0) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title="Virtual Networks"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon={<CloudIcon cloudProvider={cloudProvider} entity={VNET} width={16} height={16} />}
      popoutTitle={popoutTitle}
    >
      <Flex px="4px" gap={1} flexDirection="column">
        <Box>
          {vNets.map((vnet) => {
            const { node, keys } = getMetadataValues({ cloudProvider, nodeData: vnet });
            return (
              <SidebarItem
                key={vnet.id}
                excludeFormProps
                title={vnet.name}
                dialogProps={{ width }}
                fullscreenProps={{ headingOffset: -16 }}
                icon={<CloudIcon cloudProvider={cloudProvider} entity={VNET} width={16} height={16} />}
                popoutTitle={popoutTitle}
              >
                <CloudMetadata node={node} keys={keys} />
              </SidebarItem>
            );
          })}
        </Box>
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureAssociatedVNetsPopover);
