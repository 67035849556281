import * as React from 'react';
import { inject, observer } from 'mobx-react';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import { getHealthClass } from '../../utils/health';

// source node, dest node
const srcField = 'ktsubtype__kappa__STR06';
const dstField = 'ktsubtype__kappa__STR07';

const clusterDeviceNameDimension = 'i_device_name';
const clusterIdField = 'ktsubtype__kappa__INT00';
const trafficAggregateField = 'avg_bits_per_sec';
const latencyAggregateField = 'avg_ktsubtype__kappa__APPL_LATENCY_MS';

@inject('$hybridMap')
@observer
export default class KubeNodeToNodeLinkGenerator extends React.Component {
  nodeResults;

  linkResults;

  handleQueryComplete = ({ results, fullyLoaded, type }) => {
    if (fullyLoaded) {
      this[`${type}Results`] = results;

      if (this.nodeResults && this.linkResults) {
        this.handleQueriesComplete({ nodeResults: this.nodeResults, linkResults: this.linkResults, fullyLoaded: true });
      }
    }
  };

  handleQueryError = () => {
    const { $hybridMap } = this.props;
    $hybridMap.setKubeState({ loading: false, kubeInitializationFailed: true });
  };

  handleQueriesComplete = ({ nodeResults, linkResults, fullyLoaded }) => {
    const { $hybridMap, onNodeLinksUpdate } = this.props;

    if (fullyLoaded) {
      const clusterMap = {};

      nodeResults.models.forEach((model) => {
        const nodeId = model.get(srcField);
        const device_name = model.get(clusterDeviceNameDimension);
        const cluster_id = model.get(clusterIdField);

        if (device_name) {
          clusterMap[device_name] = clusterMap[device_name] || {
            device_name,
            cluster_id,
            links: {},
            nodes: {}
          };
        }

        const cluster = clusterMap[device_name];

        if (nodeId) {
          cluster.nodes[nodeId] = cluster.nodes[nodeId] || { id: nodeId, device_name, cluster_id, latency: 0 };
        }
      });

      linkResults.models.forEach((model) => {
        const srcId = model.get(srcField);
        const dstId = model.get(dstField);
        const device_name = model.get(clusterDeviceNameDimension);
        const cluster_id = model.get(clusterIdField);
        const traffic = model.get(trafficAggregateField);
        const latency = model.get(latencyAggregateField);

        const key = [srcId, dstId].sort().join('|');

        if (device_name) {
          clusterMap[device_name] = clusterMap[device_name] || {
            device_name,
            cluster_id,
            links: {},
            nodes: {}
          };

          const cluster = clusterMap[device_name];

          if (srcId) {
            cluster.nodes[srcId] = cluster.nodes[srcId] || { id: srcId, device_name, cluster_id, latency: 0 };
            cluster.nodes[srcId].latency = Math.max(cluster.nodes[srcId].latency, latency);
          }

          if (dstId) {
            cluster.nodes[dstId] = cluster.nodes[dstId] || { id: dstId, device_name, cluster_id, latency: 0 };
            cluster.nodes[dstId].latency = Math.max(cluster.nodes[dstId].latency, latency);
          }

          if (srcId && dstId && srcId !== dstId) {
            if (cluster.links[key]) {
              const link = cluster.links[key];

              if (link.node1_id === srcId) {
                // outbound traffic
                link.traffic.outTraffic = traffic;
                link.latency.outLatency = latency;
              } else {
                // inbound traffic
                link.traffic.inTraffic = traffic;
                link.latency.inLatency = latency;
              }
            } else {
              cluster.links[key] = {
                key,
                node1_id: srcId,
                node2_id: dstId,
                device_name,
                latency: {
                  fromInterface: { node_id: srcId },
                  toInterface: { node_id: dstId },
                  inLatency: 0,
                  outLatency: latency
                },
                traffic: {
                  fromInterface: { node_id: srcId },
                  toInterface: { node_id: dstId },
                  inTraffic: 0,
                  outTraffic: traffic
                }
              };
            }
          }
        }
      });

      const clusters = Object.values(clusterMap).map((cluster) =>
        Object.assign(cluster, {
          links: Object.values(cluster.links),
          nodes: Object.values(cluster.nodes).map((node, index) => {
            const healthData = {};

            if (node.latency > $hybridMap.latencyThreshold) {
              healthData.state = 'CRITICAL';
            } else if (node.latency > 0) {
              healthData.state = 'GOOD';
            }

            return Object.assign(node, {
              index,
              health: healthData.state && { cssClassName: getHealthClass(healthData), data: healthData }
            });
          })
        })
      );

      $hybridMap.initializeKubeState(clusters);

      if (onNodeLinksUpdate) {
        onNodeLinksUpdate({ clusters });
      }
    }
  };

  render() {
    const { $hybridMap } = this.props;

    const nodesQuery = $hybridMap.getQuery({
      all_devices: false,
      show_overlay: false,
      show_total_overlay: false,
      device_types: ['kappa'],

      metric: [srcField, 'i_device_id', clusterIdField],
      aggregateTypes: ['agg_total_bytes'],
      viz_type: 'table',
      depth: 50000,
      topx: 50000
    });

    const linksQuery = $hybridMap.getQuery({
      all_devices: false,
      show_overlay: false,
      show_total_overlay: false,
      device_types: ['kappa'],

      // i_device_id will give us the Cluster
      metric: [srcField, dstField, 'i_device_id', clusterIdField],
      aggregateTypes: [trafficAggregateField, latencyAggregateField],
      viz_type: 'table',
      depth: 500,
      topx: 500
    });

    return (
      <>
        <LightweightDataViewWrapper
          query={nodesQuery}
          toastOnError={false}
          onQueryComplete={(data) => this.handleQueryComplete({ ...data, type: 'node' })}
          onQueryError={() => this.handleQueryError()}
        />
        <LightweightDataViewWrapper
          query={linksQuery}
          toastOnError={false}
          onQueryComplete={(data) => this.handleQueryComplete({ ...data, type: 'link' })}
          onQueryError={() => this.handleQueryError()}
        />
      </>
    );
  }
}
