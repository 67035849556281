import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { FocusStyleManager } from '@blueprintjs/core';
import lazyLoad from 'core/util/lazyLoad';
import GlobalSearch from 'app/components/search/GlobalSearch';
import MessagesDialog from 'app/components/MessagesDialog';
import AssetMigration from 'app/views/assetMigration/AssetMigration';
import ObservationDeck from 'app/views/ObservationDeck';
import Licenses from 'app/views/licenses/Licenses';
import NotFound from 'app/views/NotFound';
import RecentExports from 'app/views/exports/RecentExports';
import DefaultView from 'app/views/DefaultView';
import SpoofAutoRedirect from 'app/views/SpoofAutoRedirect';
import ProtectedRoute from 'app/components/ProtectedRoute';
import EnvironmentModal from 'app/components/demo/EnvironmentModal';
import What from 'app/views/onboarding/What';
import Why from 'app/views/onboarding/Why';
import Library from 'app/views/core/library/Library';
import DashboardView from 'app/views/core/dashboards/DashboardView';
import SavedView from 'app/views/core/SavedView';
import Topology from 'app/views/topology';
import Hybrid from 'app/views/hybrid';
import CloudDeck from 'app/views/cloud/CloudDeck';
import CloudPerformance from 'app/views/cloudPerformance/CloudPerformance';
import ConnectivityChecker from 'app/views/cloudConnectivity/ConnectivityChecker';
import CreateConnectivityReport from 'app/views/cloudConnectivity/CreateConnectivityReport';
import ConnectivityReport from 'app/views/cloudConnectivity/ConnectivityReport';
import Kube from 'app/views/hybrid/kube';
import KubeMagicDoor from 'app/views/hybrid/kube/KubeMagicDoor';
import SharedExplorer from 'app/views/core/explorer/SharedExplorer';
import SharedLinks from 'app/views/sharedLinks/SharedLinks';
import RbacManagement from 'app/views/rbac/RbacManagement';
import RbacRoleDetails from 'app/views/rbac/RbacRoleDetails';
import RoleSetDetails from 'app/views/rbac/RoleSetDetails';
import SharedTestResults from 'app/views/synthetics/SharedTestResults';
import DisabledFeature from 'app/views/DisabledFeature';
import SharedDashboards from 'app/views/core/dashboards/SharedDashboardView';
import ConnectivityCheckerSplash from 'app/views/splash/ConnectivityCheckerSplash';
import CloudPerformanceMonitorSplash from 'app/views/splash/CloudPerformanceMonitorSplash';
import Admin from 'app/views/admin/Admin';
import CompanyAdminDetails from 'app/views/admin/CompanyAdminDetails';
import CompanyUserDetails from 'app/views/admin/CompanyUserDetails';

const Setup = lazyLoad(() => import('./setup'));
const Settings = lazyLoad(() => import('app/views/settings'));
const Integrations = lazyLoad(() => import('./integrations'));
const Insights = lazyLoad(() => import('./insights'));
const Alerting = lazyLoad(() => import('./alerting'));
const MyKentikPortalConfig = lazyLoad(() => import('./mkpConfig'));
const Core = lazyLoad(() => import('./core'));
const Infrastructure = lazyLoad(() => import('./infrastructure'));
const Edge = lazyLoad(() => import('./edge'));
const Metrics = lazyLoad(() => import('./metrics'));
const Protect = lazyLoad(() => import('./protect'));
const ServiceProvider = lazyLoad(() => import('./serviceProvider'));
const Synthetics = lazyLoad(() => import('./synthetics'));
const Profile = lazyLoad(() => import('./profile/Profile'));

const DisabledComponent = () => <DisabledFeature title="Public Link Sharing Disabled" feature="Public Link Sharing" />;

@inject('$app', '$devices', '$setup', '$auth', '$hybridMap')
@withRouter
@observer
class App extends Component {
  state = {};

  componentDidMount() {
    FocusStyleManager.onlyShowFocusOnTabs();
  }

  handleNavigate = (path, options) => {
    const { $app, history } = this.props;
    history.push(path, options);
    $app.hideSearch();
  };

  render() {
    const { $app, $auth, $setup, $hybridMap } = this.props;

    return (
      <>
        <Switch>
          <Redirect exact from="/v4/onboarding" to="/v4/onboarding/why" />
          <Route exact path="/v4/onboarding/what" component={What} />
          <Route exact path="/v4/onboarding/why" component={Why} />
          {!$app.isSharedLink && $setup.redirectToOnboarding && <Redirect from="*" to="/v4/onboarding" />}

          <Route exact path="/v4/shared/data-explorer/:urlEntropy/:urlHash" component={SharedExplorer} />
          <Route exact path="/v4/shared/saved-view/:urlEntropy/:urlHash" component={SharedExplorer} />
          <Route exact path="/v4/shared/synthetics/:urlEntropy/:urlHash" component={SharedTestResults} />

          <ProtectedRoute
            path="/v4/settings/shared-links"
            component={SharedLinks}
            permission="linkSharing.disabled"
            isNegativePermission
            overrideForSudo={false}
            forbiddenComponent={DisabledComponent}
          />
          <Redirect from="/v4/shared-links" to="/v4/settings/shared-links" />

          <ProtectedRoute
            path="/v4/settings/rbac/roleDetails/:roleid"
            component={RbacRoleDetails}
            rbacPermissions={['rbac.role::read']}
          />
          <ProtectedRoute
            path="/v4/settings/rbac/roleSetDetails/:rolesetid"
            component={RoleSetDetails}
            rbacPermissions={['rbac.role::read']}
          />
          <ProtectedRoute
            path="/v4/settings/rbac/roleSetDetails"
            component={RoleSetDetails}
            rbacPermissions={['rbac.role::read']}
          />
          <ProtectedRoute
            path="/v4/settings/rbac/roleDetails"
            component={RbacRoleDetails}
            rbacPermissions={['rbac.role::read']}
          />
          <ProtectedRoute
            path="/v4/settings/rbac/:tab?"
            component={RbacManagement}
            rbacPermissions={['rbac.role::read']}
          />

          <Route exact path="/v4/shared/dashboards/:urlEntropy/:urlHash" component={SharedDashboards} />

          <Route exact path="/v4" component={DefaultView} />

          <Route path="/v4/setup" component={Setup} />

          <Route path="/v4/home" component={ObservationDeck} />
          <Route path="/v4/kentik-map/logical" component={Topology} />
          <Route path="/v4/kentik-map" component={Hybrid} />
          <Route path="/v4/mkp" component={MyKentikPortalConfig} />
          <Route path="/v4/settings" component={Settings} />
          <Route path="/v4/integrations" component={Integrations} />
          <Route path="/v4/licenses" component={Licenses} />

          {/* Legacy Operate Redirect Party */}
          <Redirect from="/v4/operate/saved-views/:viewId/hash/:hash" to="/v4/library/saved-views/:viewId/hash/:hash" />
          <Redirect
            from="/v4/operate/saved-views/:viewId/urlParams/:params"
            to="/v4/library/saved-views/:viewId/urlParams/:params"
          />
          <Redirect from="/v4/operate/saved-views/hash/:hash" to="/v4/library/saved-views/hash/:hash" />
          <Redirect from="/v4/operate/saved-views/:viewId" to="/v4/library/saved-views/:viewId" />
          <Redirect
            from="/v4/operate/custom-views/:dashboardId/urlParams/:params"
            to="/v4/library/dashboards/:dashboardId/urlParams/:params"
          />
          <Redirect from="/v4/operate/custom-views/:dashboardId" to="/v4/library/dashboards/:dashboardId" />
          <Redirect from="/v4/operate/explorer/preset" to="/v4/core/explorer/preset" />
          <Redirect from="/v4/operate/explorer/urlParams/:params" to="/v4/core/explorer/urlParams/:params" />
          <Redirect from="/v4/operate/explorer/:urlHash" to="/v4/core/explorer/:urlHash" />
          <Redirect from="/v4/operate/explorer" to="/v4/core/explorer" />
          <Redirect from="/v4/operate/insights/:id" to="/v4/core/insights/:id" />
          <Redirect from="/v4/operate/insights" to="/v4/core/insights" />
          <Redirect from="/v4/operate" to="/v4/core" />
          <Redirect from="/v4/core/library/alarm/:id" to="/v4/alerting/dashboard/0/:id" />
          <Redirect from="/v4/library/alarm/:id" to="/v4/alerting/dashboard/0/:id" />

          <Route exact path="/v4/library" component={Library} />
          <Route exact path="/v4/library/dashboards/:dashboardId" component={DashboardView} />
          <Route exact path="/v4/library/dashboards/:dashboardId/guided" component={DashboardView} />
          <Route exact path="/v4/library/dashboards/:dashboardId/urlParams/:params" component={DashboardView} />
          <Route exact path="/v4/library/saved-views/:viewId" component={SavedView} />
          <Route exact path="/v4/library/saved-views/:viewId/hash/:hash" component={SavedView} />
          <Route exact path="/v4/library/saved-views/:viewId/urlParams/:params" component={SavedView} />
          <Route exact path="/v4/library/saved-views/hash/:hash" component={SavedView} />

          <Route path="/v4/core/insights" component={Insights} />
          <Redirect from="/v4/protect/alerting/:id" to="/v4/alerting/:id" />
          <Redirect from="/v4/protect/alerting" to="/v4/alerting" />
          <Route path="/v4/alerting" component={Alerting} />
          <Route path="/v4/core" component={Core} />
          <Route path="/v4/infrastructure" component={Infrastructure} />

          <ProtectedRoute permission="recon.enabled" overrideForSudo={false} path="/v4/nms" component={Metrics} />
          <Route path="/v4/edge" component={Edge} />
          <Route path="/v4/protect" component={Protect} />
          <Route path="/v4/service-provider" component={ServiceProvider} />
          <Route path="/v4/synthetics" component={Synthetics} />

          <ProtectedRoute
            exact
            path="/v4/cloud/pathfinder/:cloud(aws|azure)/create"
            component={CreateConnectivityReport}
            fallback={!$hybridMap.hasCloud('aws') && !$hybridMap.hasCloud('azure')}
            fallbackComponent={ConnectivityCheckerSplash}
          />

          <ProtectedRoute
            exact
            path="/v4/cloud/pathfinder/:cloud(aws|azure)/:reportIdOrHash"
            component={ConnectivityReport}
            fallback={!$hybridMap.hasCloud('aws') && !$hybridMap.hasCloud('azure')}
            fallbackComponent={ConnectivityCheckerSplash}
          />

          <ProtectedRoute
            path="/v4/cloud/pathfinder/:cloud(aws|azure)"
            exact
            component={ConnectivityChecker}
            fallback={!$hybridMap.hasCloud('aws') && !$hybridMap.hasCloud('azure')}
            fallbackComponent={ConnectivityCheckerSplash}
          />

          <ProtectedRoute exact path="/v4/cloud/pathfinder" component={ConnectivityCheckerSplash} />

          <ProtectedRoute
            path="/v4/cloud/performance/:tabId?"
            component={CloudPerformance}
            fallback={!$hybridMap.hasAWSExportOrDevice}
            fallbackComponent={CloudPerformanceMonitorSplash}
          />

          <Route exact path="/v4/cloud/views/:dashboardSlug" component={DashboardView} />
          <ProtectedRoute
            permission="kubeMap.enabled"
            overrideForSudo={false}
            path="/v4/cloud/kube"
            component={Kube}
            forbiddenComponent={KubeMagicDoor}
          />
          <Route path="/v4/cloud" component={CloudDeck} />

          <Route path="/v4/asset-migration" component={AssetMigration} />
          <Route path="/v4/recent-exports" component={RecentExports} />
          <Route path="/v4/profile/:tab?" component={Profile} />
          <ProtectedRoute
            path="/v4/spoof/:companyId/:userId"
            component={SpoofAutoRedirect}
            force2fa={$auth.isTotpExpired}
          />
          <ProtectedRoute path="/v4/unspoof" component={SpoofAutoRedirect} />
          {$auth.isSudoer && <ProtectedRoute path="/v4/admin/:companyId/user/:id" component={CompanyUserDetails} />}

          {$auth.isSudoer && <ProtectedRoute path="/v4/admin/:id/:tab?" component={CompanyAdminDetails} />}

          {$auth.isSudoer && <ProtectedRoute path="/v4/admin" component={Admin} />}
          <Route path="*" component={NotFound} />
        </Switch>

        <GlobalSearch isOpen={$app.searchIsOpen} onClose={$app.hideSearch} onNavigate={this.handleNavigate} />
        <MessagesDialog />
        <EnvironmentModal />
      </>
    );
  }
}

export default App;
