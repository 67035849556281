import React from 'react';
import styled from 'styled-components';
import { noop as _noop } from 'lodash';
import { inject, observer } from 'mobx-react';

import EmbeddedSiteDetailsMap from 'app/views/hybrid/maps/EmbeddedSiteDetailsMap.js';

const Wrapper = styled.div`
  margin: -20px;
`;

@inject('$hybridMap')
@observer
export default class SiteDetails extends React.Component {
  state = {
    width: 0
  };

  static defaultProps = {
    containerRef: React.createRef()
  };

  componentDidMount() {
    this.calculateSiteDetailsMapWidth();
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    const { isOpen: prevIsOpen } = prevProps;

    if (isOpen !== prevIsOpen) {
      this.calculateSiteDetailsMapWidth();
    }
  }

  calculateSiteDetailsMapWidth = () => {
    const { containerRef } = this.props;
    const containerRect = containerRef?.current?.getBoundingClientRect();

    const { width } = this.state;
    if (width !== (containerRect?.width ?? 0)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ width: containerRect?.width ?? 0 });
    }
  };

  render() {
    const { site, containerRef } = this.props;
    const { width } = this.state;

    return (
      <Wrapper width={width} ref={containerRef}>
        {width > 0 && (
          <EmbeddedSiteDetailsMap
            site={site}
            width={width}
            onShowSiteDialog={_noop}
            isEmbedded
            paddingX={30}
            paddingY={50}
          />
        )}
      </Wrapper>
    );
  }
}
