import React from 'react';
import { Text } from 'core/components';

const Subtitle = (...props) => (
  <Text as="div" fontWeight="medium" fontSize={14} color="white.6" {...props}>
    Welcome to Network Observability
  </Text>
);

export default Subtitle;
