import Validator from 'validatorjs';

import validOptions from 'core/form/util/validOptionsValidation';

import isCommaSeparatedHostnames from 'shared/validators/commaSeparatedHostnames';
import customConditionMax from './alerting/customConditionMax';
import customConditionValue from './alerting/customConditionValue';
import customMaxActivationTimeWindow from './alerting/customMaxActivationTimeWindow';
import customMinActivationTimeWindow from './alerting/customMinActivationTimeWindow';
import after from './after';
import afterDate from './afterDate';
import atLeastOneIfNotAllDevices from './atLeastOneIfNotAllDevices';
import before from './before';
import beforeNow from './beforeNow';
import commaSeparatedASNs from './commaSeparatedASNs';
import commaSeparatedCIDR from './commaSeparatedCIDR';
import commaSeparatedEmails from './commaSeparatedEmails';
import commaSeparatedIPsCIDR from './commaSeparatedIPsCIDR';
import commaSeparatedIPsCIDRWithExcludes from './commaSeparatedIPsCIDRWithExcludes';
import commaSeparatedIPsNoCIDR from './commaSeparatedIPsNoCIDR';
import commaSeparatedIntegers from './commaSeparatedIntegers';
import commaSeparatedMax from './commaSeparatedMax';
import commaSeparatedv4IPsNoCIDR from './commaSeparatedv4IPsNoCIDR';
import commaSeparatedv6IPs from './commaSeparatedv6IPs';
import date from './date';
import dateRangeRequired from './dateRangeRequired';
import email from './email';
import hex from './hex';
import hostname from './hostname';
import htmlSanitary from './htmlSanitary';
import httpHeader from './httpHeader';
import imageFileType from './imageFileType';
import ip from './ip';
import ipMaxSubnet from './ipMaxSubnet';
import ipNoCidr from './ipNoCidr';
import ipPort from './ipPort';
import ipPublic from './ipPublic';
import ipv4 from './ipv4';
import ipv6 from './ipv6';
import maxDateRange from './maxDateRange';
import maxGenericAggregates from './maxGenericAggregates';
import min_if from './min_if';
import mirrorOrSecondaryMirrorOnly from './mirrorOrSecondaryMirrorOnly';
import newViewCategory from './newViewCategory';
import noConflictingAggregateTypes from './noConflictingAggregateTypes';
import noConflictingUniques from './noConflictingUniques';
import bgpPassword from './bgpPassword';
import onlyAvailableDimensions from './onlyAvailableDimensions';
import oid from './oid';
import port from './port';
import regexSanitary from './regexSanitary';
import requiredIfIncludes from './requiredIfIncludes';
import requiredIfAny from './requiredIfAny';
import requiredIfMultiple from './requiredIfMultiple';
import uint32 from './uint32';
import url, { urlSingleDomain } from './url';
import validMirrorAndSecondaryOutsort from './validMirrorAndSecondaryOutsort';
import { startsWith, endsWith, sameAs } from './strings';

import {
  portValidator,
  protocolValidator,
  asnValidator,
  nextHopAsnValidator,
  tcpFlagValidator,
  countryValidator,
  macValidator,
  bgpCommunityValidator,
  bgpAspathValidator,
  interfaceNameValidator,
  vlanValidator
} from './tagValidations';

import {
  portArrayValidator,
  protocolArrayValidator,
  asnArrayValidator,
  nextHopAsnArrayValidator,
  tcpFlagsValidator,
  countryArrayValidator,
  macArrayValidator,
  bgpCommunityArrayValidator,
  bgpAspathArrayValidator,
  interfaceNameArrayValidator,
  vlanArrayValidator,
  atLeastOneTagPopulatorCriteria
} from './tagDetailValidations';

const implicitValidators = {
  requiredIfIncludes,
  requiredIfAny,
  requiredIfMultiple
};

Object.keys(implicitValidators).forEach((name) => {
  const { message, fn } = implicitValidators[name];
  Validator.registerImplicit(name, fn, message);
});

const validators = {
  after,
  afterDate,
  atLeastOneIfNotAllDevices,
  before,
  beforeNow,
  date,
  dateRangeRequired,
  email,
  hostname,
  htmlSanitary,
  httpHeader,
  imageFileType,
  ip,
  ipMaxSubnet,
  ipNoCidr,
  ipPort,
  ipPublic,
  ipv4,
  ipv6,
  hex,
  maxDateRange,
  maxGenericAggregates,
  min_if,
  mirrorOrSecondaryMirrorOnly,
  newViewCategory,
  noConflictingAggregateTypes,
  noConflictingUniques,
  onlyAvailableDimensions,
  oid,
  port,
  regexSanitary,
  uint32,
  url,
  urlSingleDomain,
  validMirrorAndSecondaryOutsort,
  startsWith,
  endsWith,
  sameAs,
  commaSeparatedEmails,
  commaSeparatedIPsCIDR,
  commaSeparatedIPsCIDRWithExcludes,
  commaSeparatedIPsNoCIDR,
  commaSeparatedCIDR,
  commaSeparatedv4IPsNoCIDR,
  commaSeparatedv6IPs,
  commaSeparatedMax,
  commaSeparatedIntegers,
  commaSeparatedASNs,
  isCommaSeparatedHostnames,
  // alerting specific validations
  customConditionMax,
  customConditionValue,
  customMaxActivationTimeWindow,
  customMinActivationTimeWindow,
  // begin legacy tagValidators, can be removed once migrated fully hippo
  portValidator,
  protocolValidator,
  asnValidator,
  nextHopAsnValidator,
  tcpFlagValidator,
  countryValidator,
  macValidator,
  bgpCommunityValidator,
  bgpAspathValidator,
  interfaceNameValidator,
  vlanValidator,
  // end legacy tagValidators
  // begin new tagDetailValidators
  portArrayValidator,
  protocolArrayValidator,
  asnArrayValidator,
  nextHopAsnArrayValidator,
  tcpFlagsValidator,
  countryArrayValidator,
  macArrayValidator,
  bgpCommunityArrayValidator,
  bgpAspathArrayValidator,
  interfaceNameArrayValidator,
  vlanArrayValidator,
  atLeastOneTagPopulatorCriteria,
  // end new tagDetailValidators
  validOptions,
  bgpPassword
};

export default function initValidators() {
  Object.keys(validators).forEach((name) => {
    const { message, fn } = validators[name];
    Validator.register(name, fn, message);
  });
}
