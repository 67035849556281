import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';
import { Box, Flex, Heading, Text } from 'core/components';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';

import { getGatewayProps } from 'app/views/hybrid/maps/components/popovers/cloudUtil';

function getTitle(config) {
  return config.nodeData?.Name || config.value;
}

const GatewaySummary = (config) => {
  const { logo, nodeData, topology } = config;
  const { displayName, type = 'Gateway', icon = logo } = nodeData ? getGatewayProps(nodeData) : {};
  const vpcId = nodeData.VpcId ?? 'N/A';
  const vpcData = get(topology, `Entities.Vpcs.${vpcId}`, {});

  return (
    <Box>
      <Flex borderBottom="thin" p={2} alignItems="center">
        {React.cloneElement(icon, { width: 32, height: 32 })}
        <Box flex={1} ml={1}>
          <Text as="div" muted>
            {displayName ?? type} Details
          </Text>
          <Heading mb={0} level={4}>
            {getTitle(config)}
          </Heading>
        </Box>
      </Flex>
      {nodeData && (
        <SidebarItem title="Details" icon="more" popoutTitle={GatewaySummary.PopoutTitle(config)}>
          <CloudMetadata
            node={{
              ...nodeData,
              'Subnet CIDR': nodeData.CidrBlock,
              'VPC CIDR': vpcData?.CidrBlock ?? 'N/A'
            }}
            keys={[
              'id',
              'OwnerId',
              'State',
              'VpcId',
              'VPC CIDR',
              'SubnetId',
              'Subnet CIDR',
              'Tags',
              'NatGatewayAddresses.0.NetworkInterfaceId',
              'NatGatewayAddresses.0.PrivateIp',
              'NatGatewayAddresses.0.PublicIp'
            ]}
          />
        </SidebarItem>
      )}
    </Box>
  );
};

GatewaySummary.PopoutTitle = (config) => {
  const { logo, nodeData } = config;
  const { displayName, type = 'Gateway', icon = logo } = nodeData ? getGatewayProps(nodeData) : {};

  return (
    <Flex alignItems="center">
      {React.cloneElement(icon)}
      <Box ml={1}>
        {getTitle(config)} {displayName ?? type}
      </Box>
    </Flex>
  );
};

export default inject('$hybridMap')(observer(GatewaySummary));
