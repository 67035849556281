import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import NotFound from 'app/views/NotFound';
import DeviceTopology from './DeviceTopology';
import ProviderTopology from './ProviderTopology';
import SiteTopology from './SiteTopology';
import CustomerTopology from './CustomerToplogy';

const BASE_ROUTE = '/v4/kentik-map/logical';

@observer
class TopologyRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={`${BASE_ROUTE}/sites/:siteId`} component={SiteTopology} />
        <Route exact path={`${BASE_ROUTE}/customer/:customerId`} component={CustomerTopology} />
        <Route exact path={`${BASE_ROUTE}/provider/:provider`} component={ProviderTopology} />
        <Route exact path={`${BASE_ROUTE}/devices/:deviceName`} component={DeviceTopology} />
        <Route path={BASE_ROUTE} render={(props) => <SiteTopology {...props} siteId={undefined} siteLevel={false} />} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default TopologyRoutes;
