import * as React from 'react';
import { Box, Heading } from 'core/components';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';

export default class CloudRouterBGP extends React.Component {
  get bgpMetadata() {
    const {
      nodeData: { bgp }
    } = this.props;

    const config = {
      node: {
        ASN: bgp?.asn,
        'Advertise Mode': bgp?.advertiseMode,
        'Advertised Groups': bgp?.advertisedGroups || [],
        'Advertised CIDRs': (bgp?.advertisedIpRanges || []).map(({ range }) => range),
        'Keep-Alive Interval (seconds)': bgp?.keepaliveInterval
      },
      keys: ['ASN', 'Advertise Mode', 'Advertised Groups', 'Advertised CIDRs', 'Keep-Alive Interval']
    };

    return <CloudMetadata {...config} />;
  }

  get bgpPeerMetadata() {
    const {
      nodeData: { bgpPeers = [] }
    } = this.props;

    if (bgpPeers.length > 0) {
      return (
        <Box mt={1}>
          <Heading level={5}>Peers</Heading>
          {bgpPeers.map((peer) => this.renderPeer(peer))}
        </Box>
      );
    }

    return null;
  }

  renderPeer(peer) {
    return (
      <CloudMetadata
        key={peer.name}
        border="thin"
        mb={1}
        p={1}
        node={{
          Name: peer.name,
          'Advertise Mode': peer.advertiseMode,
          'Advertised Groups': peer.advertisedGroups || [],
          'Advertised CIDRs': (peer.advertisedIpRanges || []).map(({ range }) => range),
          Enabled: peer.enable,
          IP: peer.ipAddress,
          'Peer IP': peer.peerIpAddress,
          'Peer ASN': peer.peerAsn,
          'Interface Name': peer.interfaceName
        }}
        keys={['Name', 'Interface Name', 'Enabled', 'IP', 'Peer IP', 'Peer ASN', 'Advertise Mode', 'BFD']}
      />
    );
  }

  render() {
    const { width, popoutTitle } = this.props;
    return (
      <SidebarItem excludeFormProps title="BGP" dialogProps={{ width }} icon="chat" popoutTitle={popoutTitle}>
        <Box>
          {this.bgpMetadata}
          {this.bgpPeerMetadata}
        </Box>
      </SidebarItem>
    );
  }
}
