import React from 'react';
import { inject, observer } from 'mobx-react';
import { FaCheck } from 'react-icons/fa';
import { FiLink2 } from 'react-icons/fi';
import { BiShapeSquare } from 'react-icons/bi';
import { GiRadarSweep } from 'react-icons/gi';
import { BsFilterLeft } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { MdCompareArrows } from 'react-icons/md';
import { get, groupBy, upperFirst } from 'lodash';

import { ENTITY_TYPES, GCP_ENTITY_TYPES, MAP_TYPES } from 'shared/hybrid/constants';
import { Box, Flex, Heading, Icon, Tag, Tabs, Tab, CalloutOutline, Tooltip } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { getGroupedConnectionKey } from 'app/views/hybrid/utils/links';
import { idToType, getNameFromEntity } from 'app/views/hybrid/utils/aws';
import { ReactComponent as DockerLogo } from 'app/assets/logos/docker.svg';
import { ReactComponent as KubePodIcon } from 'app/assets/icons/kubernetes/pod.svg';
import { makeConfig } from 'app/views/hybrid/maps/components/popovers/configHelper';
import CoreNetworkSummary from 'app/views/hybrid/maps/components/popovers/summaries/aws/CoreNetworkSummary';
import { getHealthState, getHealthStateFromHealthData, healthClasses } from 'app/views/hybrid/utils/health';
import { isGoogleCloud, getCustomProperties } from '@kentik/ui-shared/util/map';

import {
  AwsRouteTablePopover,
  AwsSecurityGroupNACLPopover,
  AwsVirtualInterfacesPopover,
  AwsCustomerGatewaysPopover,
  AwsGatewayAssociationsPopover,
  AwsMetricsPanel
} from './aws';
import {
  AzureFirewallPopover,
  AzureVpnSitesPopover,
  AzureVirtualWanPopover,
  AzureRouteTablePopover,
  AzureVirtualHubsPopover,
  AzureConnectionsPopover,
  AzureSecurityGroupPopover,
  AzureVpnSiteLinkesPopover,
  AzureAssociatedVNetsPopover,
  AzureAssociatedSubnetsPopover,
  AzureExpressRoutePeeringPopover,
  AzureExpressRouteGatewaysPopover,
  AzureLoadBalancerExtendedDetailsPopover,
  AzureMetricsPanel,
  AzureVNetGatewayBGPPeerStatusPanel,
  AzureVNetGatewayLearnedRoutesPanel
} from './azure';

import { OciSecurityListsPopover, OciRouteTablePopover, DrgRouteTablePopover, OciMetricsPanel } from './oci';

import {
  GcpCloudRouterBGP,
  GcpCloudRouterNats,
  GcpVpnTunnels,
  GcpRouteTablePopover,
  FirewallRulesPopover,
  FirewallPoliciesPopover,
  GcpLoadBalancers,
  GcpInterconnectAttachment,
  GcpVpnGateway,
  GcpNatGatewayPopover,
  GcpMetricsPanel
} from './gcp';

import CloudMetadata from './CloudMetadata';
import SidebarItem from './SidebarItem';
import SidebarSummary from './summaries/SidebarSummary';
import Performance from './Performance';
import KubePerformance from './KubePerformance';
import InterfaceCounters from './InterfaceCounters';
import InterfaceMetadata from './interfaceMetadata/InterfaceMetadata';
import HealthDetails from './healthDetails/HealthDetails';
import TrafficCharts from '../trafficCharts/TrafficCharts';
import ContainersSummary from './ContainersSummary';
import PodsSummary from './PodsSummary';
import SiteDetails from './siteDetails';
import SiteDevices from './siteDevices';
import MemoryChart from './healthDetails/MemoryChart';
import CPUChart from './healthDetails/CPUChart';
import OnpremConnectedInterfaces from './OnpremConnectedInterfaces';
import DeniedTrafficTable from './DeniedTrafficTable';

const {
  VPC,
  CORE_NETWORK,
  VPN_CONNECTION,
  TRANSIT_GATEWAY,
  CORE_NETWORK_EDGE,
  TRANSIT_GATEWAY_ATTACHMENT,
  TRANSIT_GATEWAY_ROUTE_TABLE,
  TRANSIT_GATEWAY_CONNECT_PEER,
  TRANSIT_GATEWAY_CONNECT
} = ENTITY_TYPES.get('aws');

const { VIRTUAL_HUB, LOAD_BALANCER, APPLICATION_GATEWAY, VNET_GATEWAY, FIREWALL } = ENTITY_TYPES.get('azure');

export const SIDEBAR_ITEM_WIDTH = 800;

@withTheme
@inject('$hybridMap', '$auth')
@observer
export default class SidebarDetails extends React.Component {
  state = {
    selectedConnection: null
  };

  // ensures certain sections get remounted so we can reinitialize state when switching between detail types
  get key() {
    const { type, subType, id, source, target, value, groupedConnections, nodeData } = this.props;

    if (isGoogleCloud(id)) {
      const { project, network } = getCustomProperties(nodeData);
      return `${type}-${project}-${network}-${value}`;
    }

    if (type === 'link') {
      return `${source.type}-${source.value}-${target.type}-${target.value}`;
    }

    if (type === 'interface') {
      if (value && value.device_id && value.snmp_id) {
        return `${type}-${value.device_id}-${value.snmp_id}`;
      }

      if (groupedConnections) {
        return `${type}-${getGroupedConnectionKey(groupedConnections)}`;
      }
    }

    if (type === 'gateway') {
      return `${type}-${value}-${id}`;
    }

    return `${type}-${subType || ''}-${id}`;
  }

  get popoutTitle() {
    return <SidebarSummary {...this.props} popout />;
  }

  get summary() {
    return <SidebarSummary key={this.key} {...this.props} onStateChange={this.handleStateChange} />;
  }

  get coreNetworkTab() {
    const { type, nodeData, $hybridMap } = this.props;

    if (type !== CORE_NETWORK_EDGE) {
      return null;
    }

    const { awsCloudMapCollection } = $hybridMap;

    const coreNetworkEntity = awsCloudMapCollection.getEntity({
      entityType: CORE_NETWORK,
      entityId: nodeData.CoreNetworkId
    });

    return (
      <SidebarItem
        key={`sidebar-core-network-${this.key}`}
        title="Core Network"
        popoutTitle={this.popoutTitle}
        icon={BiShapeSquare}
        overflow="hidden"
        padding="-20px"
      >
        <CoreNetworkSummary
          width={SIDEBAR_ITEM_WIDTH}
          popoutTitle={this.popoutTitle}
          data={coreNetworkEntity}
          region={nodeData.EdgeLocation ?? ''}
          {...this.props}
        />
      </SidebarItem>
    );
  }

  get topologyTab() {
    const { type, model } = this.props;

    if (type !== 'site') {
      return null;
    }

    return (
      <SidebarItem
        key={`sidebar-topology-${this.key}`}
        title="Internal Map Details"
        popoutTitle={this.popoutTitle}
        icon={BiShapeSquare}
        overflow="hidden"
        padding="-20px"
      >
        <SiteDetails site={model} />
      </SidebarItem>
    );
  }

  get devicesTab() {
    const { type, nodeData } = this.props;

    if (type !== 'site') {
      return null;
    }

    const devices = (nodeData?.devices || []).sort(
      (a, b) => healthClasses.indexOf(b.health?.cssClassName) - healthClasses.indexOf(a.health?.cssClassName)
    );

    if (devices.length === 0) {
      return null;
    }

    const badgeCounts = devices.reduce(
      (counts, device) => {
        const healthClass = device.health?.cssClassName;

        if (healthClass in counts) {
          counts[healthClass] += 1;
        } else {
          counts.unknown += 1;
        }

        return counts;
      },
      { critical: 0, warning: 0, healthy: 0, unknown: 0 }
    );

    return (
      <SidebarItem
        key={`sidebar-devices-${this.key}`}
        title={
          <Flex gap={2}>
            <Heading level={5} mb={0}>
              Devices
            </Heading>
            {Object.entries(badgeCounts)
              .filter(([, count]) => count > 0)
              .map(([healthClass, count]) => {
                const { intent, text } = getHealthStateFromHealthData({ cssClassName: healthClass });
                return (
                  <Flex key={healthClass} gap="4px">
                    <Tag intent={intent} round small>
                      {count}
                    </Tag>
                    <Box>{text}</Box>
                  </Flex>
                );
              })}
          </Flex>
        }
        popoutTitle={this.popoutTitle}
        icon={BsFilterLeft}
        overflow="hidden"
      >
        <SiteDevices devices={devices} healthIssues={nodeData.healthIssues} />
      </SidebarItem>
    );
  }

  get trafficTab() {
    const { queryOptions, isBoxLink } = this.props;
    const { selectedConnection } = this.state;
    let trafficQueryOptions = queryOptions;

    if (selectedConnection && selectedConnection.value !== 'combined') {
      // interface, device link, and site-to-site popovers can select new connections
      // in this case if we have a selected connection, make a new config of type 'interface'
      // and override the query options
      const interfaceConfig = makeConfig({
        ...this.props,
        links: [{ connections: [selectedConnection.connection], ...selectedConnection.connection.linkMetadata }]
      });

      trafficQueryOptions = interfaceConfig.queryOptions;
    }

    if (trafficQueryOptions) {
      return (
        <SidebarItem
          key={`sidebar-${this.key}`}
          title={
            <Flex alignItems="center">
              <Heading level={5} mb={0}>
                Traffic
              </Heading>
              {isBoxLink && (
                <Tooltip
                  maxWidth={100}
                  content={
                    <div style={{ maxWidth: 200 }}>
                      The link traffic data on the map is calculated using total bytes and deriving a bits/s rate using
                      the query time interval which may differ from the selections in this panel
                    </div>
                  }
                >
                  <Icon ml={1} icon="info-sign" color="primary" />
                </Tooltip>
              )}
            </Flex>
          }
          popoutTitle={this.popoutTitle}
          icon={MdCompareArrows}
          openOnMount
        >
          <TrafficCharts key={this.key} queryOptions={trafficQueryOptions} />
        </SidebarItem>
      );
    }

    return null;
  }

  get performanceTab() {
    // eslint-disable-next-line no-unused-vars
    const { performance, isKubeDetail, theme, testId, agentId, ...rest } = this.props;

    if (performance && isKubeDetail) {
      return (
        <SidebarItem title="Performance" popoutTitle={this.popoutTitle} icon={GiRadarSweep}>
          <KubePerformance {...this.props} />
        </SidebarItem>
      );
    }

    if (performance) {
      return (
        <SidebarItem
          title="Performance"
          popoutTitle={this.popoutTitle}
          icon={GiRadarSweep}
          dialogProps={{ width: 'calc(100vw - 100px)', style: { background: theme.name === 'light' && 'white' } }}
        >
          <Performance
            key={`sidebar-performance-${this.key}-${agentId}-${testId}`}
            testId={testId}
            agentId={agentId}
            {...rest}
          />
        </SidebarItem>
      );
    }

    return null;
  }

  get routeTableTab() {
    const { nodeData, cloudProvider, type } = this.props;

    if (!nodeData || !cloudProvider) {
      return null;
    }

    if (cloudProvider === 'aws') {
      return (
        <AwsRouteTablePopover
          width={SIDEBAR_ITEM_WIDTH}
          popoutTitle={this.popoutTitle}
          key={`sidebar-routetable-${this.key}`}
          {...this.props}
        />
      );
    }

    if (cloudProvider === 'azure') {
      return (
        <AzureRouteTablePopover
          width={SIDEBAR_ITEM_WIDTH}
          popoutTitle={this.popoutTitle}
          key={`sidebar-routetable-${this.key}`}
          {...this.props}
        />
      );
    }

    if (cloudProvider === 'oci') {
      return (
        <OciRouteTablePopover
          width={SIDEBAR_ITEM_WIDTH}
          popoutTitle={this.popoutTitle}
          key={`sidebar-routetable-${this.key}`}
          {...this.props}
        />
      );
    }

    if (isGoogleCloud(cloudProvider)) {
      return type === GCP_ENTITY_TYPES.SUBNET || type === GCP_ENTITY_TYPES.NETWORK ? (
        <GcpRouteTablePopover
          width={SIDEBAR_ITEM_WIDTH}
          popoutTitle={this.popoutTitle}
          key={`sidebar-routetable-${this.key}`}
          {...this.props}
        />
      ) : null;
    }

    console.error(`Route Table Tab - Unknown cloud provider: '${cloudProvider}'`);

    return null;
  }

  get interfaceCountersTab() {
    const { interfaceCountersConfig } = this.props;

    if (interfaceCountersConfig) {
      const { selectedConnection } = this.state;
      const selectedKey = selectedConnection && selectedConnection.value;

      return (
        <SidebarItem
          title="Metrics"
          dialogProps={{ width: SIDEBAR_ITEM_WIDTH }}
          icon="database"
          popoutTitle={this.popoutTitle}
        >
          <InterfaceCounters queryConfig={interfaceCountersConfig} selectedKey={selectedKey} />
        </SidebarItem>
      );
    }

    return null;
  }

  get containersTab() {
    const { containers } = this.props;

    if (containers) {
      return (
        <SidebarItem title="Containers" dialogProps={{ width: SIDEBAR_ITEM_WIDTH }} icon={DockerLogo}>
          <ContainersSummary {...this.props} />
        </SidebarItem>
      );
    }

    return null;
  }

  get podsTab() {
    const { pods } = this.props;

    if (pods) {
      return (
        <SidebarItem title="Pods" dialogProps={{ width: SIDEBAR_ITEM_WIDTH }} icon={KubePodIcon}>
          <PodsSummary {...this.props} />
        </SidebarItem>
      );
    }

    return null;
  }

  get healthTab() {
    const { type, nodeData } = this.props;

    if (nodeData && nodeData.healthIssues) {
      const badgeCounts = nodeData.healthIssues.byState;

      return (
        <SidebarItem
          title={
            <Flex gap={2}>
              <Heading level={5} mb={0}>
                Health
              </Heading>
              {Object.entries(badgeCounts)
                .filter(([, count]) => count > 0)
                .map(([state, count]) => {
                  const { intent, name } = getHealthState(state);
                  return (
                    <Flex key={state} gap="4px">
                      <Tag intent={intent} round small>
                        {count}
                      </Tag>
                      <Box>{name}</Box>
                    </Flex>
                  );
                })}
            </Flex>
          }
          popoutTitle={this.popoutTitle}
          dialogProps={{ width: 600 }}
          icon="heart"
          openOnMount={nodeData.healthIssues.size > 0}
        >
          <HealthDetails type={type} health={nodeData.health} healthIssues={nodeData.healthIssues} />
        </SidebarItem>
      );
    }

    return null;
  }

  get deviceMetricsTab() {
    const { type, model } = this.props;

    if (type === 'device' && model) {
      const deviceName = model.get('device_name');

      return (
        <SidebarItem
          title="Metrics"
          dialogProps={{ width: SIDEBAR_ITEM_WIDTH }}
          icon="database"
          popoutTitle={this.popoutTitle}
        >
          <Flex flexDirection="column" gap={4}>
            <CPUChart deviceName={deviceName} />
            <MemoryChart deviceName={deviceName} />
          </Flex>
        </SidebarItem>
      );
    }

    return null;
  }

  get interfaceMetadataTab() {
    const { groupedConnections } = this.props;

    if (groupedConnections) {
      const { selectedConnection } = this.state;
      const selectedKey = selectedConnection && selectedConnection.value;

      return (
        <SidebarItem
          title="Interface Metadata"
          dialogProps={{ width: SIDEBAR_ITEM_WIDTH }}
          fullscreenProps={{ headingOffset: -16 }}
          icon={FiLink2}
          popoutTitle={this.popoutTitle}
        >
          <InterfaceMetadata connections={groupedConnections} selectedKey={selectedKey} headingOffset={0} />
        </SidebarItem>
      );
    }

    return null;
  }

  get directGatewayVirtualInterfacesTab() {
    const { cloudProvider } = this.props;

    if (cloudProvider === 'aws') {
      return <AwsVirtualInterfacesPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
    }

    return null;
  }

  get directGatewayAssociationsTab() {
    const { cloudProvider } = this.props;

    if (cloudProvider === 'aws') {
      return (
        <AwsGatewayAssociationsPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />
      );
    }

    return null;
  }

  get customerGatewaysTab() {
    const { cloudProvider } = this.props;

    if (cloudProvider === 'aws') {
      return <AwsCustomerGatewaysPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
    }

    return null;
  }

  get natGatewaysTab() {
    const { type, nodeData, topology, cloudProvider } = this.props;
    if (isGoogleCloud(cloudProvider) && type === 'natGateways') {
      return (
        <GcpNatGatewayPopover
          width={SIDEBAR_ITEM_WIDTH}
          popoutTitle={this.popoutTitle}
          key={`sidebar-nat-gw-${this.key}`}
          {...this.props}
        />
      );
    }

    if (cloudProvider === 'aws' && type === 'subnet' && Object.values(nodeData?.NatGateways ?? {}).length > 0) {
      const vpcId = nodeData.VpcId ?? 'N/A';
      const vpcData = get(topology, `Entities.Vpcs.${vpcId}`, {});

      return (
        <SidebarItem
          excludeFormProps
          title="NAT Gateways"
          dialogProps={{ width: SIDEBAR_ITEM_WIDTH }}
          icon={<CloudIcon cloudProvider="aws" entity="natGateway" style={{ marginTop: '3px' }} />}
        >
          <Box style={{ marginTop: '-15px' }}>
            {Object.keys(nodeData?.NatGateways).map((natGatewayKey) => (
              <SidebarItem
                excludeFormProps
                key={natGatewayKey}
                title={natGatewayKey}
                icon="lock"
                dialogProps={{ width: SIDEBAR_ITEM_WIDTH, marginTop: '-10px' }}
              >
                <CloudMetadata
                  node={{
                    ...nodeData.NatGateways[natGatewayKey],
                    'Subnet CIDR': nodeData.CidrBlock,
                    'VPC CIDR': vpcData?.CidrBlock ?? 'N/A'
                  }}
                  keys={[
                    'id',
                    'OwnerId',
                    'State',
                    'VpcId',
                    'VPC CIDR',
                    'SubnetId',
                    'Subnet CIDR',
                    'Tags',
                    'NatGatewayAddresses.0.NetworkInterfaceId',
                    'NatGatewayAddresses.0.PrivateIp',
                    'NatGatewayAddresses.0.PublicIp'
                  ]}
                />
              </SidebarItem>
            ))}
          </Box>
        </SidebarItem>
      );
    }
    return null;
  }

  /**
   * transit gateway attachments separated by type for tgw details node
   */
  get transitGatewayAttachmentsByTypeTab() {
    const { value, topology } = this.props;

    if (idToType(value) === TRANSIT_GATEWAY) {
      // get all tgws associated to current tgw
      const tgwAttachments = Object.values(topology.Entities[TRANSIT_GATEWAY_ATTACHMENT]).filter(
        (attachment) => attachment.TransitGatewayId === value
      );

      if (tgwAttachments.length === 0) {
        return null;
      }

      /**
       * enrich tgw attachment with vpc and subnet data and TGW route table data
       */
      tgwAttachments.forEach((attachment) => {
        const TgwRouteTable = get(
          topology.Entities[TRANSIT_GATEWAY_ROUTE_TABLE],
          `${attachment?.Association?.TransitGatewayRouteTableId}`
        );

        // do not show Name same is ID
        if (TgwRouteTable && TgwRouteTable?.Name === TgwRouteTable?.id) {
          delete TgwRouteTable.Name;
        }

        // attach tgw route table, as we will need it for summary
        Object.assign(attachment, { TgwRouteTable });

        if (attachment.ResourceType === 'connect') {
          const transitGatewayConnectPeer = Object.values(topology.Entities[TRANSIT_GATEWAY_CONNECT_PEER]).find(
            (peer) => peer.TransitGatewayAttachmentId === attachment.id
          );

          Object.assign(attachment, { TransitGatewayConnectPeer: transitGatewayConnectPeer });
        }

        if (attachment.ResourceType === 'peering') {
          if (!attachment.id) {
            const id = attachment.TransitGatewayAttachmentId.split('-tgw-')[0];
            Object.assign(attachment, { id });
          }
          // neightboor tgw is a tgw that start with same id but has different TGW ID
          const neighborTGW = get(
            topology.Entities[TRANSIT_GATEWAY_ATTACHMENT],
            `${attachment.id}-${attachment.ResourceId}`
          );

          Object.assign(attachment, { Neighbor: neighborTGW });
        }

        Object.keys(attachment?.NetworkInterfaces ?? []).forEach((eniId) => {
          const { SubnetId, VpcId } = attachment.NetworkInterfaces[eniId];

          const Subnet = get(topology.Entities.Subnets, SubnetId);
          const vpc = get(topology.Entities.Vpcs, VpcId);

          Object.assign(attachment.NetworkInterfaces[eniId], { Subnet, VPC: vpc });
        });
      });

      const tgwAttachmentByType = groupBy(tgwAttachments, 'ResourceType');

      return Object.keys(tgwAttachmentByType).map((type) => (
        <SidebarItem
          key={`${value}-${type}`}
          excludeFormProps
          title={
            <Flex alignItems="center">
              <Heading level={5} mb={0}>
                {`TGW Attachment - ${['vpc', 'vpn'].includes(type) ? type.toUpperCase() : upperFirst(type)}`}
              </Heading>
              <Tag
                mx={1}
                intent="primary"
                icon={FaCheck}
                title={`${tgwAttachmentByType[type].length} total`}
                minimal
                round
              >
                {tgwAttachmentByType[type].length}
              </Tag>
            </Flex>
          }
          dialogProps={{ width: SIDEBAR_ITEM_WIDTH }}
          icon={<CloudIcon cloudProvider="aws" entity="transitGateway" style={{ marginTop: '3px' }} />}
        >
          <Box>
            {tgwAttachmentByType[type].map((attachment) => (
              <SidebarItem
                key={attachment.id ?? attachment.ResourceId}
                excludeFormProps
                title={attachment.id ?? attachment.ResourceId}
                dialogProps={{ width: SIDEBAR_ITEM_WIDTH }}
                icon="lock"
              >
                <Box>
                  {type === 'peering' && (
                    <CloudMetadata
                      node={{
                        ...attachment,
                        'This TGW Router': attachment.TransitGatewayId,
                        'Neighbor TGW Router': attachment.Neighbor.TransitGatewayId
                      }}
                      keys={['id', 'Name', 'This TGW Router', 'Neighbor TGW Router']}
                    />
                  )}
                  {type === 'vpc' && (
                    <CloudMetadata
                      node={{
                        ...attachment,
                        'VPC ID': attachment.ResourceId,
                        'VPC CIDR': get(topology.Entities[VPC], `${attachment.ResourceId}.CidrBlock`),
                        'TGW Route Table ID': attachment?.TgwRouteTable?.id,
                        'TGW Route Table Name': attachment?.TgwRouteTable?.Name
                      }}
                      keys={['id', 'Name', 'VPC ID', 'VPC CIDR', 'TGW Route Table ID', 'TGW Route Table Name']}
                    />
                  )}
                  {type === 'connect' && (
                    <CloudMetadata
                      node={{
                        ...attachment,
                        'TGW Route Table ID': attachment?.TgwRouteTable?.id,
                        'TGW Route Table Name': attachment?.TgwRouteTable?.Name,
                        'Transport Attachment ID': attachment.ResourceId,
                        'Transport Attachment Name': get(
                          topology.Entities[TRANSIT_GATEWAY_ATTACHMENT],
                          attachment.ResourceId
                        )?.Name,
                        'Connect Protocol': get(
                          topology.Entities[TRANSIT_GATEWAY_CONNECT],
                          `${attachment.id}.Options.Protocol`
                        ),
                        'Connect Name': attachment?.TransitGatewayConnectPeer?.Name,
                        'Connect Peer ID': attachment?.TransitGatewayConnectPeer?.id,
                        'Connect Attachment ID': attachment?.TransitGatewayConnectPeer?.TransitGatewayAttachmentId,
                        'Transit Gateway GRE address':
                          attachment?.TransitGatewayConnectPeer?.ConnectPeerConfiguration?.TransitGatewayAddress,
                        'Peer GRE address': attachment?.TransitGatewayConnectPeer?.ConnectPeerConfiguration?.PeerAddress
                      }}
                      keys={[
                        'id',
                        'Name',
                        'Connect Protocol',
                        'Connect Name',
                        'Connect Peer ID',
                        'Connect Attachment ID',
                        'Transit Gateway GRE address',
                        'Peer GRE address',
                        'Transport Attachment ID',
                        'Transport Attachment Name',
                        'TGW Route Table ID',
                        'TGW Route Table Name'
                      ]}
                    />
                  )}

                  {type === 'vpn' && (
                    <CloudMetadata
                      node={{
                        ...attachment,
                        'Resource ID': attachment.ResourceId,
                        'Resource Name': get(topology.Entities[VPN_CONNECTION], attachment.ResourceId)?.Name,
                        'TGW Route Table ID': attachment?.TgwRouteTable?.id,
                        'TGW Route Table Name': attachment?.TgwRouteTable?.Name
                      }}
                      keys={[
                        'id',
                        'Name',
                        'Resource ID',
                        'Resource Name',
                        'TGW Route Table ID',
                        'TGW Route Table Name'
                      ]}
                    />
                  )}
                </Box>
              </SidebarItem>
            ))}
          </Box>
        </SidebarItem>
      ));
    }

    return null;
  }

  get drgTab() {
    const { cloudProvider, value, type } = this.props;

    if (cloudProvider !== 'oci') {
      return null;
    }

    return (
      <DrgRouteTablePopover
        key={`${value}-${type}`}
        width={SIDEBAR_ITEM_WIDTH}
        popoutTitle={this.popoutTitle}
        {...this.props}
      />
    );
  }

  /**
   * transit gateway attachment data for subnets
   */
  get transitGatewayAttachmentTab() {
    const { type, nodeData, topology } = this.props;

    if (type === 'subnet' && Object.values(nodeData?.TransitGatewayAttachments ?? {}).length > 0) {
      const vpcId = nodeData.VpcId ?? 'N/A';
      const vpcData = get(topology, `Entities.Vpcs.${vpcId}`, {});

      return (
        <SidebarItem
          excludeFormProps
          title="TGW ENI"
          dialogProps={{ width: SIDEBAR_ITEM_WIDTH }}
          icon={<CloudIcon cloudProvider="aws" entity="transitGateway" style={{ marginTop: '3px' }} />}
        >
          <Box style={{ marginTop: '-15px' }}>
            {Object.keys(nodeData?.TransitGatewayAttachments).map((TransitGatewayAttachmentId) => (
              <Box key={TransitGatewayAttachmentId} style={{ marginTop: '-15px' }}>
                {Object.keys(
                  nodeData.TransitGatewayAttachments[TransitGatewayAttachmentId]?.NetworkInterfaces ?? {}
                ).map((eniId) => (
                  <Box key={eniId}>
                    <CloudMetadata
                      node={{
                        ...nodeData.TransitGatewayAttachments[TransitGatewayAttachmentId].NetworkInterfaces[eniId],
                        'Network Interface ID': eniId,
                        Tags: nodeData.TransitGatewayAttachments[TransitGatewayAttachmentId].NetworkInterfaces[eniId]
                          .TagSet,
                        'Subnet CIDR': nodeData.CidrBlock,
                        'VPC CIDR': vpcData?.CidrBlock ?? 'N/A',
                        'Attachment Status':
                          nodeData.TransitGatewayAttachments[TransitGatewayAttachmentId].NetworkInterfaces[eniId]
                            .Attachment.Status
                      }}
                      keys={[
                        'VpcId',
                        'VPC CIDR',
                        'SubnetId',
                        'Subnet CIDR',
                        'Description',
                        'Tags',
                        'Network Interface ID',
                        'PrivateIpAddress',
                        'Attachment.AttachmentId',
                        'Attachment Status'
                      ]}
                    />
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </SidebarItem>
      );
    }
    return null;
  }

  get expressRoutesPeeringTab() {
    const { cloudProvider } = this.props;

    if (cloudProvider === 'azure') {
      return (
        <AzureExpressRoutePeeringPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />
      );
    }

    return null;
  }

  get virtualHubsTab() {
    const { cloudProvider } = this.props;

    if (cloudProvider === 'azure') {
      return <AzureVirtualHubsPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
    }

    return null;
  }

  get virtualVpnSitesTab() {
    const { cloudProvider } = this.props;

    if (cloudProvider === 'azure') {
      return <AzureVpnSitesPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
    }

    return null;
  }

  get virtualWanTab() {
    const { cloudProvider } = this.props;

    if (cloudProvider === 'azure') {
      return <AzureVirtualWanPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
    }

    return null;
  }

  get firewallTab() {
    const { cloudProvider, type } = this.props;

    if (cloudProvider === 'azure') {
      return <AzureFirewallPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
    }

    if (isGoogleCloud(cloudProvider) && (type === GCP_ENTITY_TYPES.REGION || type === GCP_ENTITY_TYPES.NETWORK)) {
      return (
        <FirewallPoliciesPopover
          width={SIDEBAR_ITEM_WIDTH}
          popoutTitle={this.popoutTitle}
          key={`sidebar-firewall-policy-table-${this.key}`}
          {...this.props}
        />
      );
    }

    return null;
  }

  get associatedVNetsTab() {
    const { cloudProvider, type } = this.props;

    if (cloudProvider === 'azure' && type === VIRTUAL_HUB) {
      // associated vnets currently generated based on topology links
      return <AzureAssociatedVNetsPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
    }

    return null;
  }

  get associatedSubnetsTab() {
    const { cloudProvider } = this.props;

    if (cloudProvider === 'azure') {
      // associated vnets currently generated based on topology links
      return (
        <AzureAssociatedSubnetsPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />
      );
    }

    return null;
  }

  get connectionsTab() {
    const { cloudProvider } = this.props;

    if (cloudProvider === 'azure') {
      // associated vnets currently generated based on topology links
      return <AzureConnectionsPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
    }

    return null;
  }

  get vpnSiteLinksTab() {
    const { cloudProvider } = this.props;

    if (cloudProvider === 'azure') {
      // associated vnets currently generated based on topology links
      return <AzureVpnSiteLinkesPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
    }

    return null;
  }

  get expressRouteGatewaysTab() {
    const { cloudProvider } = this.props;

    if (cloudProvider === 'azure') {
      return (
        <AzureExpressRouteGatewaysPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />
      );
    }

    return null;
  }

  get loadBalancerExtendedDetailsTab() {
    const { cloudProvider, type } = this.props;
    const gcpLbDisplayTypes = [GCP_ENTITY_TYPES.NETWORK, GCP_ENTITY_TYPES.REGION];

    if (cloudProvider === 'azure' && (type === LOAD_BALANCER || type === APPLICATION_GATEWAY)) {
      return (
        <AzureLoadBalancerExtendedDetailsPopover
          width={SIDEBAR_ITEM_WIDTH}
          popoutTitle={this.popoutTitle}
          {...this.props}
        />
      );
    }

    if (isGoogleCloud(cloudProvider) && gcpLbDisplayTypes.includes(type)) {
      return (
        <GcpLoadBalancers
          width={SIDEBAR_ITEM_WIDTH}
          popoutTitle={this.popoutTitle}
          key={`sidebar-load-balancing-rules-table-${this.key}`}
          {...this.props}
        />
      );
    }

    return null;
  }

  get securityGroupNACLSidebarTab() {
    const { cloudProvider } = this.props;

    if (cloudProvider === 'azure') {
      return <AzureSecurityGroupPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
    }

    if (cloudProvider === 'aws') {
      return <AwsSecurityGroupNACLPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
    }

    if (cloudProvider === 'oci') {
      return <OciSecurityListsPopover width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
    }

    if (isGoogleCloud(cloudProvider)) {
      return (
        <FirewallRulesPopover
          width={SIDEBAR_ITEM_WIDTH}
          popoutTitle={this.popoutTitle}
          key={`sidebar-firewall-rules-table-${this.key}`}
          {...this.props}
        />
      );
    }

    return null;
  }

  get gatewaySubnetsTab() {
    const { type, value, theme, nodeData, topology } = this.props;

    if (type === 'gateway' && idToType(value) === 'TransitGatewayAttachments') {
      // find all subnets that have attached transit gateway eni

      const subnets = Object.values(nodeData?.NetworkInterfaces ?? {})
        .map((eni) => ({
          ...get(topology, `Entities.Subnets.${eni.SubnetId}`),
          NetworkInterface: eni
        }))
        .sort((subnet1, subnet2) => {
          // try to pull the name out of Tags,because its how we show it in the UI
          const sortingKey1 = getNameFromEntity(subnet1);
          const sortingKey2 = getNameFromEntity(subnet2);

          return sortingKey1.localeCompare(sortingKey2);
        });

      if (subnets.length === 0) {
        return null;
      }

      return (
        <SidebarItem
          excludeFormProps
          title="TGW Subnets"
          dialogProps={{ width: SIDEBAR_ITEM_WIDTH }}
          icon={
            <CloudIcon
              cloudProvider="aws"
              entity="subnet"
              width={17}
              height={17}
              style={{
                marginTop: '3px',
                stroke: theme.colors.appBackground,
                strokeWidth: '0.7px'
              }}
            />
          }
        >
          <Box style={{ marginTop: '-15px' }}>
            {subnets.map((subnetEntity) => (
              <SidebarItem
                excludeFormProps
                key={subnetEntity.Name}
                title={getNameFromEntity(subnetEntity)}
                icon="lock"
                dialogProps={{ width: SIDEBAR_ITEM_WIDTH, marginTop: '-10px' }}
              >
                <CloudMetadata
                  node={{
                    ...subnetEntity,
                    'Network Interface ID': subnetEntity.NetworkInterface.id
                  }}
                  keys={[
                    'id',
                    'CidrBlock',
                    'OwnerId',
                    'State',
                    'Network Interface ID',
                    'NetworkInterface.PrivateIpAddress',
                    'NetworkInterface.Attachment.AttachmentId'
                  ]}
                />
              </SidebarItem>
            ))}
          </Box>
        </SidebarItem>
      );
    }
    return null;
  }

  get connectedInterfacesTab() {
    const { cloudProvider } = this.props;

    if (['azure', 'gcp'].includes(cloudProvider)) {
      return <OnpremConnectedInterfaces width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
    }

    return null;
  }

  get cloudRouterBGP() {
    const { type, nodeData } = this.props;
    if (type === GCP_ENTITY_TYPES.CLOUD_ROUTER) {
      if (nodeData?.bgp) {
        return <GcpCloudRouterBGP width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
      }
    }
    return null;
  }

  get cloudRouterNAT() {
    const { type } = this.props;
    if (type === GCP_ENTITY_TYPES.CLOUD_ROUTER) {
      return <GcpCloudRouterNats width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
    }
    return null;
  }

  get interconnectAttachments() {
    const { type, topology } = this.props;
    const data = get(topology, 'Entities.interconnectAttachments', {});

    if (type === GCP_ENTITY_TYPES.CLOUD_ROUTER) {
      return (
        <GcpInterconnectAttachment
          width={SIDEBAR_ITEM_WIDTH}
          popoutTitle={this.popoutTitle}
          data={data}
          {...this.props}
        />
      );
    }

    return null;
  }

  get vpnTunnels() {
    const { type, topology } = this.props;
    const vpnTunnels = get(topology, 'Entities.vpnTunnels', {});
    if (type === GCP_ENTITY_TYPES.CLOUD_ROUTER) {
      return (
        <GcpVpnTunnels width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} data={vpnTunnels} {...this.props} />
      );
    }

    return null;
  }

  get vpnGateways() {
    const { type, topology } = this.props;
    const vpnGateways = get(topology, 'Entities.vpnGateways', {});
    if (type === GCP_ENTITY_TYPES.CLOUD_ROUTER) {
      return (
        <GcpVpnGateway width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} data={vpnGateways} {...this.props} />
      );
    }

    return null;
  }

  get metrics() {
    const { cloudProvider, nodeData, type } = this.props;
    const { entityType } = getCustomProperties(nodeData);

    if (cloudProvider === 'azure' && MAP_TYPES.get('azure.supports_metrics').includes(type)) {
      return <AzureMetricsPanel width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />;
    }

    // aws metrics are currently a poc only in production kentikdemonew
    if (cloudProvider === 'aws') {
      const awsType = nodeData?.type || entityType || type;

      if (MAP_TYPES.get('aws.supports_metrics').includes(awsType)) {
        return (
          <AwsMetricsPanel
            width={SIDEBAR_ITEM_WIDTH}
            popoutTitle={this.popoutTitle}
            {...this.props}
            entityType={awsType}
          />
        );
      }
    }

    if (isGoogleCloud(cloudProvider)) {
      const gcpEntityType = entityType || type;
      if (MAP_TYPES.get('gcp.supports_metrics').includes(gcpEntityType)) {
        return (
          <GcpMetricsPanel
            width={SIDEBAR_ITEM_WIDTH}
            popoutTitle={this.popoutTitle}
            entityType={gcpEntityType}
            {...this.props}
          />
        );
      }
    }

    if (cloudProvider === 'oci') {
      const ociEntityType = entityType || type;
      if (MAP_TYPES.get('oci.supports_metrics').includes(ociEntityType)) {
        return (
          <OciMetricsPanel
            width={SIDEBAR_ITEM_WIDTH}
            popoutTitle={this.popoutTitle}
            {...this.props}
            entityType={ociEntityType}
          />
        );
      }
    }

    return null;
  }

  get vnetGatewayBGPPeerStatus() {
    const { cloudProvider, type } = this.props;

    if (cloudProvider === 'azure' && type === VNET_GATEWAY) {
      return (
        <AzureVNetGatewayBGPPeerStatusPanel width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />
      );
    }

    return null;
  }

  get vnetGatewayLearnedRoutes() {
    const { cloudProvider, type } = this.props;

    if (cloudProvider === 'azure' && type === VNET_GATEWAY) {
      return (
        <AzureVNetGatewayLearnedRoutesPanel width={SIDEBAR_ITEM_WIDTH} popoutTitle={this.popoutTitle} {...this.props} />
      );
    }

    return null;
  }

  get deniedTraffic() {
    const { $hybridMap, cloudProvider, type, nodeData } = this.props;

    if (cloudProvider === 'azure' && type === FIREWALL) {
      return (
        <SidebarItem
          excludeFormProps
          title="Denied Traffic"
          icon="shield"
          dialogProps={{ width: SIDEBAR_ITEM_WIDTH }}
          popoutTitle={this.popoutTitle}
        >
          <DeniedTrafficTable
            cloudProvider={cloudProvider}
            entity={nodeData}
            entityType={type}
            queryTimeOptions={$hybridMap.settingsModel.queryTimeOptions}
          />
        </SidebarItem>
      );
    }

    return null;
  }

  handleStateChange = (config) => this.setState(config);

  get healthCallout() {
    const { nodeData, subType, type, showHealthCallout } = this.props;
    if (nodeData) {
      const { health } = nodeData;
      const hasProblem = health?.data?.state === 'CRITICAL' || health?.data?.state === 'WARNING';
      if (showHealthCallout && hasProblem) {
        return (
          <Box px={2} mt={2}>
            <CalloutOutline color="danger" intent="danger">
              <Icon icon="warning-sign" mr={1} color="danger" />
              This {(nodeData.typeLabel || '').toLowerCase() || (subType || type)?.replace(/s$/, '')} is experiencing
              high latency
            </CalloutOutline>
          </Box>
        );
      }
    }

    return null;
  }

  get renderMainTabs() {
    const { content } = this.props;
    return (
      <>
        {this.healthCallout}
        {this.topologyTab}
        {this.coreNetworkTab}
        {this.trafficTab}
        {this.metrics}
        {this.devicesTab}
        {this.podsTab}
        {this.containersTab}
        {this.performanceTab}
        {this.routeTableTab}
        {this.healthTab}
        {this.deviceMetricsTab}
        {this.interfaceCountersTab}
        {this.interfaceMetadataTab}
        {this.securityGroupNACLSidebarTab}
        {this.gatewaySubnetsTab}
        {this.natGatewaysTab}
        {/* direct connect associations */}
        {this.directGatewayAssociationsTab}
        {/* direct connect virtual interfaces */}
        {this.directGatewayVirtualInterfacesTab}
        {/* vpn connections customer gateways */}
        {this.customerGatewaysTab}
        {this.connectedInterfacesTab}
        {/* transit gateway attachment data for subnets */}
        {this.transitGatewayAttachmentTab}
        {/* transit gateway attachments separated by type for tgw details node */}
        {this.transitGatewayAttachmentsByTypeTab}
        {/* azure express routes peerings */}
        {this.expressRoutesPeeringTab}
        {/* azure virtual hubs */}
        {this.virtualHubsTab}
        {/* azure vpn sites */}
        {this.virtualVpnSitesTab}
        {/* azure virtual WAN */}
        {this.virtualWanTab}
        {/* azure firewall */}
        {this.firewallTab}
        {this.deniedTraffic}
        {/* azure vnets where this entity attached to */}
        {this.associatedVNetsTab}
        {/* azure gateway connections */}
        {this.connectionsTab}
        {/* azure VPN Site Links */}
        {this.vpnSiteLinksTab}
        {/* azure Express Route Gateways Tab */}
        {this.expressRouteGatewaysTab}

        {/* azure load balancer extended properties tab */}
        {this.loadBalancerExtendedDetailsTab}

        {/* azure vnet gateway bgp peer status */}
        {this.vnetGatewayBGPPeerStatus}
        {this.vnetGatewayLearnedRoutes}

        {/* azure subnets where this entity attached to */}
        {this.associatedSubnetsTab}

        {/* gcp cloud router bgp details and peers */}
        {this.cloudRouterBGP}
        {/* gcp cloud router NAT gateway details */}
        {this.cloudRouterNAT}

        {/* gcp interconnect attachment details */}
        {this.interconnectAttachments}

        {/* gcp vpn gateway details */}
        {this.vpnGateways}

        {/* gcp target vpn gateway vpn tunnel details */}
        {this.vpnTunnels}

        {/* OCI */}
        {this.drgTab}

        {content}
      </>
    );
  }

  render() {
    const { tabs } = this.props;
    const hasHorizontalTabs = tabs && tabs.length > 0;

    if (hasHorizontalTabs) {
      return (
        <>
          {this.summary}
          <Tabs defaultSelectedId="charts" bordered>
            <Tab id="charts" title="Charts" panel={this.renderMainTabs} style={{ marginLeft: 16 }} />
            {tabs.map(({ title, panel }) => (
              <Tab key={title} id={title} title={title} panel={panel} />
            ))}
          </Tabs>
        </>
      );
    }

    return (
      <>
        {this.summary}
        {this.renderMainTabs}
      </>
    );
  }
}
