import * as React from 'react';
import { observer } from 'mobx-react';
import { ReactComponent as CPUIcon } from 'app/assets/icons/cpu_icon.svg';
import { ReactComponent as MemoryIcon } from 'app/assets/icons/memory_icon.svg';
import { Box, Card, Flex, Grid, Heading, Icon, Popover, Sparkline, Spinner, Text } from 'core/components';
import { formatBytesGreek } from 'core/util';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import { getHealthStateFromValue, getMaxFromQueryResults } from 'app/views/hybrid/utils/health';
import DeviceMetricsDetailCharts from './DeviceMetricsDetailCharts';

@observer
export default class DeviceMetrics extends React.Component {
  state = {
    viewDetailsOpen: false,
    cpuQueryLoading: true,
    memoryQueryLoading: true,
    cpuDetailData: [],
    memoryDetailData: []
  };

  get baseQuery() {
    const { device } = this.props;

    return {
      all_devices: false,
      device_name: [device.get('device_name')],
      fastData: 'Full',
      metric: ['ktappprotocol__snmp_device_metrics__STR01'], // component
      lookback_seconds: 86400,
      show_overlay: false,
      show_total_overlay: false
    };
  }

  get cpuQuery() {
    return {
      ...this.baseQuery,
      aggregateTypes: ['avg_ktappprotocol__snmp_device_metrics__INT64_00'] // utilization
    };
  }

  get memoryQuery() {
    return {
      ...this.baseQuery,
      aggregateTypes: [
        'avg_ktappprotocol__snmp_device_metrics__INT64_04', // utilization
        'avg_ktappprotocol__snmp_device_metrics__INT64_02', // used
        'avg_ktappprotocol__snmp_device_metrics__INT64_01' // total
      ]
    };
  }

  get detailsEnabled() {
    const { cpuQueryLoading, memoryQueryLoading, cpuDetailData, memoryDetailData } = this.state;

    return !cpuQueryLoading && !memoryQueryLoading && (cpuDetailData.length > 0 || memoryDetailData.length > 0);
  }

  get details() {
    const { cpuDetailData, memoryDetailData } = this.state;

    return (
      <Card p={2} maxHeight={650} overflow="auto">
        <Grid gridTemplateColumns="350px 350px" gridGap={4} gridRowGap={2}>
          <DeviceMetricsDetailCharts data={cpuDetailData} title="Average CPU Utilization" icon={CPUIcon} />
          <DeviceMetricsDetailCharts
            data={memoryDetailData}
            title="Average Memory Utilization"
            icon={MemoryIcon}
            renderFooter={({ usedValue, totalValue }) => (
              <Flex justifyContent="flex-end" mt={1}>
                <Text fontWeight="bold" mr={2}>
                  Used:{' '}
                  <Text fontWeight="normal" muted>
                    {formatBytesGreek(usedValue)}
                  </Text>
                </Text>
                <Text fontWeight="bold">
                  Total:{' '}
                  <Text fontWeight="normal" muted>
                    {formatBytesGreek(totalValue)}
                  </Text>
                </Text>
              </Flex>
            )}
          />
        </Grid>
      </Card>
    );
  }

  handleCPUQueryComplete = ({ results, fullyLoaded }) => {
    const cpuDataByComponent = results.getRawDataRows().map((model) => {
      const value = model.get('avg_ktappprotocol__snmp_device_metrics__INT64_00');
      const healthState = getHealthStateFromValue({ type: 'cpu', value });
      let color = 'primary';
      let headingProps = {};

      if (healthState.state !== 'GOOD') {
        color = healthState.color;
        headingProps = { color: healthState.color, fontWeight: 'bold' };
      }

      return {
        key: model.get('key'),
        value,
        lastDatapoint: model.get('f_avg_int64_00__k_last'),
        rawData: model.get('rawData').f_avg_int64_00.flow,
        color,
        headingProps
      };
    });

    this.setState({ cpuQueryLoading: !fullyLoaded, cpuDetailData: cpuDataByComponent });
  };

  handleMemoryQueryComplete = ({ results, fullyLoaded }) => {
    const memoryDataByComponent = results.getRawDataRows().map((model) => {
      const value = model.get('avg_ktappprotocol__snmp_device_metrics__INT64_04');
      const healthState = getHealthStateFromValue({ type: 'memory', value });
      let color = 'indigo3';
      let headingProps = {};

      if (healthState.state !== 'GOOD') {
        color = healthState.color;
        headingProps = { color: healthState.color, fontWeight: 'bold' };
      }

      return {
        key: model.get('key'),
        usedValue: model.get('avg_ktappprotocol__snmp_device_metrics__INT64_02'),
        totalValue: model.get('avg_ktappprotocol__snmp_device_metrics__INT64_01'),
        value,
        usedLastDatapoint: model.get('f_avg_int64_02__k_last'),
        totalLastDatapoint: model.get('f_avg_int64_01__k_last'),
        valueLastDatapoint: model.get('f_avg_int64_04__k_last'),
        rawData: model.get('rawData').f_avg_int64_04.flow,
        color,
        headingProps
      };
    });

    this.setState({ memoryQueryLoading: !fullyLoaded, memoryDetailData: memoryDataByComponent });
  };

  renderSummary = ({ type, queryResults, valueKey, rawDataKey, loadingIndicator, icon, label }) => {
    const { cpuQueryLoading, memoryQueryLoading } = this.state;
    const isLoading = cpuQueryLoading || memoryQueryLoading;

    if (isLoading) {
      return loadingIndicator;
    }

    if (queryResults.size === 0) {
      return (
        <Flex mt={2} flexDirection="column" alignItems="center">
          <Icon icon={icon} iconSize={30} mb={1} />
          <Text muted>{`No ${label} Data Reported`}</Text>
        </Flex>
      );
    }

    const data = getMaxFromQueryResults({ queryResults, valueKey, rawDataKey });

    const healthState = getHealthStateFromValue({ type, value: data.value });
    let color = 'primary';
    let headingProps = {};

    if (healthState.state !== 'GOOD') {
      color = healthState.color;
      headingProps = { color, fontWeight: 'bold' };
    }

    return (
      <Box mt={2}>
        <Flex justifyContent="space-between">
          <Text>
            <Icon icon={icon} mr="4px" />
            {`Highest ${label} Utilization:`}
          </Text>
          <Text {...headingProps} muted>
            {Math.round(data.value)}%
          </Text>
        </Flex>
        <Card mt="4px">
          <Sparkline data={data.rawData} color={color} height={60} />
        </Card>
      </Box>
    );
  };

  render() {
    const { viewDetailsOpen } = this.state;

    return (
      <Box>
        <Flex justifyContent="space-between">
          <Heading level={5} mb={0}>
            Metrics{' '}
            <Text fontSize="normal" muted>
              (Last 24h)
            </Text>
          </Heading>
          <Popover
            isOpen={viewDetailsOpen}
            content={this.details}
            onClose={() => this.setState({ viewDetailsOpen: false })}
            popoverClassName="no-transform"
            minimal={false}
          >
            <Text
              as="a"
              color="primary"
              onClick={() => this.detailsEnabled && this.setState({ viewDetailsOpen: true })}
              disabled={!this.detailsEnabled}
            >
              View Details
            </Text>
          </Popover>
        </Flex>
        <Flex flexDirection="column" maxWidth="350px">
          <LightweightDataViewWrapper query={this.cpuQuery} onQueryComplete={this.handleCPUQueryComplete}>
            {({ results }) =>
              this.renderSummary({
                type: 'cpu',
                queryResults: results,
                valueKey: 'avg_ktappprotocol__snmp_device_metrics__INT64_00',
                rawDataKey: 'f_avg_int64_00',
                loadingIndicator: <Spinner mt={2} size={16} />,
                icon: CPUIcon,
                label: 'CPU'
              })
            }
          </LightweightDataViewWrapper>
          <LightweightDataViewWrapper query={this.memoryQuery} onQueryComplete={this.handleMemoryQueryComplete}>
            {({ results }) =>
              this.renderSummary({
                type: 'memory',
                queryResults: results,
                valueKey: 'avg_ktappprotocol__snmp_device_metrics__INT64_04',
                rawDataKey: 'f_avg_int64_04',
                loadingIndicator: null,
                icon: MemoryIcon,
                label: 'Memory'
              })
            }
          </LightweightDataViewWrapper>
        </Flex>
      </Box>
    );
  }
}
