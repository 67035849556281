import React from 'react';

import { inject } from 'mobx-react';
import { Flex, Box } from 'core/components';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { AzureSubnetDetails } from './components';
import SidebarItem from '../SidebarItem';

const { SUBNET } = ENTITY_TYPES.get('azure');

const AzureAssociatedSubnetsPopover = ({ cloudProvider, width, popoutTitle, $hybridMap, nodeData }) => {
  const { azureCloudMapCollection } = $hybridMap;

  const associatedSubnets = azureCloudMapCollection.getEntityProperty(nodeData, SUBNET) ?? [];
  const relatedSubnets = azureCloudMapCollection.getEntityRelatedEntities(nodeData, SUBNET) ?? [];

  if (relatedSubnets.length === 0 && associatedSubnets.length === 0) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title="Subnets"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon={<CloudIcon cloudProvider={cloudProvider} entity={SUBNET} width={16} height={16} />}
      popoutTitle={popoutTitle}
    >
      <Flex px="4px" gap={1} flexDirection="column">
        <Box>
          {[...relatedSubnets, ...associatedSubnets].map((relatedSubnet) => (
            <AzureSubnetDetails
              key={relatedSubnet.id}
              nodeData={relatedSubnet}
              width={width}
              popoutTitle={popoutTitle}
            />
          ))}
        </Box>
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureAssociatedSubnetsPopover);
