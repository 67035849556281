import * as React from 'react';
import { Text } from 'core/components';
import { getHealthStateFromHealthData } from 'app/views/hybrid/utils/health';

export default function HealthTag({ health }) {
  if (health) {
    const { color, text } = getHealthStateFromHealthData(health);

    return (
      <Text color={color}>
        <Text small>●</Text> {text}
      </Text>
    );
  }

  return <Text muted>Unavailable</Text>;
}
