import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import { uniqBy, intersection } from 'lodash';

import { Box, Flex, Icon, Text, Button } from 'core/components';
import { VirtualizedTable, Search } from 'core/components/table';
import RouteTableSummaryCollection from 'app/stores/hybrid/RouteTableSummaryCollection';

import { ReactComponent as VPCIcon } from 'app/assets/icons/vpc.svg';
import { ReactComponent as RouterIcon } from 'app/assets/icons/router_icon.svg';
import { ReactComponent as TransitGatewayIcon } from 'app/assets/icons/transit-gateway.svg';
import { ReactComponent as InternetGatewayIcon } from 'app/assets/icons/internet-gateway.svg';
import CloudIcon from '../CloudIcon';

@inject('$hybridMap')
@observer
class CloudSubnetDestinations extends Component {
  collection = new RouteTableSummaryCollection([], {
    sortState: { field: 'destination', direction: 'desc' }
  });

  computeRouteTableSummary = async () => {
    const { subnet, $hybridMap } = this.props;
    const { awsCloudMapCollection, sidebarSettings } = $hybridMap;

    const vpc = awsCloudMapCollection.getEntity({ entityType: 'Vpcs', entityId: subnet.VpcId });
    const vpcTgwAttachmentIds = vpc?.TransitGatewayAttachments?.map((tgwAttachment) => tgwAttachment.id) ?? [];

    const subnetRouteTableSummary = await awsCloudMapCollection.computeSubnetRouteTableSummary(
      subnet.id,
      sidebarSettings.timeRange
    );

    const routes = subnetRouteTableSummary.routes
      .flatMap((route) => [route, ...(route.moreSpecificRoutes || []).map((r) => ({ ...r, parent: route }))])
      .filter((route) => {
        // always include not specific routes
        if (!route?.routeTgwAttachmentIds) {
          return true;
        }
        // if it a more specific route, we need to make sure that route was generated by tgw in this vpc
        return intersection(route.routeTgwAttachmentIds, vpcTgwAttachmentIds).length > 0;
      });

    const uniqRoutes = uniqBy(
      routes,
      (route) => `${route.destination}-${route.routeTarget?.nextHopResource?.id || route.routeTarget}`
    );

    this.collection.set(uniqRoutes);
    this.collection.filter();
    this.collection.calculatePageCount();
    this.collection.applyPageChangeFilter();
  };

  componentDidMount() {
    const computingPromise = async () => {
      this.collection.setRequestStatus('fetching');
      await this.computeRouteTableSummary();
      this.collection.setRequestStatus(null);
      this.collection.hasFetched = true;
    };

    computingPromise();
  }

  get columns() {
    return [
      {
        label: 'Type',
        name: 'type',
        width: 50,
        renderer: ({ model }) => {
          if (model.get('SegmentName')) {
            return (
              <Box title="Core Network Policy">
                <CloudIcon cloudProvider="aws" entity="coreNetworkPolicy" width={24} height={24} />
              </Box>
            );
          }
          return (
            <Box title="Route">
              <CloudIcon cloudProvider="aws" entity="route" width={24} height={24} />
            </Box>
          );
        }
      },
      {
        label: 'Destination',
        name: 'destination',
        width: 160,
        renderer: ({ value }) => (
          <Box>
            <Box>{value}</Box>
          </Box>
        )
      },

      {
        label: 'Target',
        name: 'routeTarget',
        width: 400,
        renderer: ({ value, model }) => {
          if (model.isTransitGatewayRouteTable) {
            const { attachment, nextHopResource, resourceType } = value;

            return (
              <>
                {model.has('parent') && <Box mb={1}>{model.get('parent.routeTarget')} →</Box>}
                <Box mb={1}>
                  <Text fontWeight="bold">Attachment:</Text>
                  <Flex alignItems="center">
                    <Text as="div" title={attachment.id} ellipsis>
                      {attachment.name}
                    </Text>
                  </Flex>
                </Box>

                <Box>
                  <Text fontWeight="bold">Next Hop Resource:</Text>
                  <Flex alignItems="center">
                    {this.getIcon(resourceType)}
                    {(nextHopResource && (
                      <Text as="div" title={nextHopResource.id} ellipsis>
                        {nextHopResource.name}
                      </Text>
                    )) || (
                      <Text fontStyle="italic" muted>
                        n/a
                      </Text>
                    )}
                  </Flex>
                </Box>
              </>
            );
          }

          if (model.isAwsCoreNetworkRoute) {
            return (
              <Flex alignItems="center">
                <Text>{value}</Text>
              </Flex>
            );
          }
          return (
            <Flex alignItems="center">
              <Text>{value}</Text>
            </Flex>
          );
        }
      }
    ];
  }

  getIcon = (resourceType) => {
    const iconMap = {
      vpc: <Icon icon={VPCIcon} mr="4px" />,
      vpn: <Icon icon={InternetGatewayIcon} mr="4px" />,
      'direct-connect-gateway': <Icon icon={InternetGatewayIcon} mr="4px" />,
      connect: <Icon icon={RouterIcon} mr="4px" />,
      peering: <Icon icon={TransitGatewayIcon} mr="4px" />,
      'tgw-peering': <Icon icon={TransitGatewayIcon} mr="4px" />
    };

    return iconMap[resourceType] || null;
  };

  handleRowClick = (model) => {
    const { onSelect } = this.props;
    onSelect(model.get('destination'));
  };

  handleSearch = (e) => {
    this.collection.filter(e.target.value);

    if (!e.target.value) {
      this.collection.clearFilters();
      this.collection.resetDiscreteFilters();
    }

    this.collection.calculatePageCount();
    this.changePage(1);

    return this.collection;
  };

  changePage(page) {
    this.collection.setPage(page);
    this.collection.applyPageChangeFilter();
  }

  render() {
    const { pageCount, currentPage } = this.collection.pageState || {};

    return (
      <>
        <Search
          p="4px"
          collection={this.collection}
          onChange={this.handleSearch}
          placeholder="Search..."
          inputProps={{ value: this.collection?.filterState }}
          autoFocus
        />
        <VirtualizedTable
          style={{ height: 320 }}
          collection={this.collection ?? []}
          columns={this.columns}
          rowHeight={40}
          rowClass={Classes.POPOVER_DISMISS}
          onRowClick={this.handleRowClick}
          flexed
        />
        {pageCount > 1 && (
          <Flex justifyContent="space-between" p={1} alignItems="center">
            <Button onClick={() => this.changePage(currentPage - 1)} disabled={currentPage <= 1}>
              Previous
            </Button>
            <Box flexGrow="1" textAlign="center">
              <Text>
                Page {currentPage} of {pageCount}
              </Text>
            </Box>
            <Button onClick={() => this.changePage(currentPage + 1)} disabled={currentPage >= pageCount}>
              Next
            </Button>
          </Flex>
        )}
      </>
    );
  }
}

export default CloudSubnetDestinations;
