import { get } from 'lodash';

/**
 * requiredIfIncludes:anotherfield,value
 * The field under validation must be present and not empty if the `anotherfield` field includes `value`
 */
function requiredIfIncludes(val) {
  const req = this.getParameters();
  const otherVal = get(this.validator.input, req[0]);

  if (otherVal?.includes?.(req[1])) {
    return this.validator.getRule('required').validate(val);
  }

  return true;
}

export default {
  message: ':attribute is required',
  fn: requiredIfIncludes
};
