import { cloneDeep } from 'lodash';
import { FormState } from 'core/form';
import { urlFields } from 'app/forms/config/syntheticTests';

// provides overrides to service names used in test urls
function getServiceNameForUrl(service) {
  switch (service) {
    case 'AMAZON_CONNECT':
      return 'connect';
    case 'AMAZON_APPFLOW':
      return 'appflow';
    case 'API_GATEWAY':
      return 'apigateway';
    case 'AURORA':
      return 'rds';
    case 'KINESIS-FIREHOSE':
      return 'firehose';
    case 'KINESIS-STREAMS':
      return 'kinesis';
    case 'KINESIS_VIDEO_STREAMS':
      return 'kinesisvideo';
    case 'MONITORING':
      return 'opsworks';
    case 'ROUTE53_RESOLVER':
      return 'route53resolver';
    case 'WORKSPACES_GATEWAYS':
      return 'workspaces';
    default:
      return service;
  }
}

// returns a complete test model fragment for the given aws service
export default function getServiceTest({ service, region, testCollection }) {
  const urlFieldsClone = cloneDeep(urlFields);

  const periodInSeconds = 300;
  Object.assign(urlFieldsClone['config.period'], { value: periodInSeconds });
  Object.assign(urlFieldsClone['config.tasks'], { value: ['http'] });

  // satisfy the 'alertsDurationGreaterThanPeriod' custom validator by calculating the minimumally valid time window
  const defaultActivationTImes = parseInt(urlFieldsClone['config.activate.times'].defaultValue, 10);
  Object.assign(urlFieldsClone['config.activate.timeWindow'], {
    value: `${(periodInSeconds * (defaultActivationTImes + 1)) / 60}`
  });

  let configTargetValue;
  let configName;

  switch (service) {
    // services that support regional endpoints
    case 'AMAZON_CONNECT':
    case 'AMAZON_APPFLOW':
    case 'API_GATEWAY':
    case 'APS-WORKSPACES':
    case 'ATHENA':
    case 'AURORA':
    case 'AUTOSCALING':
    case 'CLOUD9':
    case 'CODEBUILD':
    case 'DATASYNC':
    case 'DYNAMODB':
    case 'EBS':
    case 'EC2':
    case 'EC2MESSAGES':
    case 'ECS':
    case 'EVENTS':
    case 'EXECUTE-API':
    case 'IMAGEBUILDER':
    case 'KINESIS-FIREHOSE':
    case 'KINESIS-STREAMS':
    case 'KINESIS_VIDEO_STREAMS':
    case 'KMS':
    case 'LAMBDA':
    case 'LOGS':
    case 'MONITORING':
    case 'ROUTE53_RESOLVER':
    case 'RDS':
    case 'REDSHIFT-DATA':
    case 'ROLESANYWHERE':
    case 'S3':
    case 'SECRETSMANAGER':
    case 'SNS':
    case 'SQS':
    case 'SSM':
    case 'SSMMESSAGES':
    case 'STATES':
    case 'STORAGEGATEWAY':
    case 'STS':
    case 'SYNC-STATES':
    case 'WORKSPACES_GATEWAYS':
      configTargetValue = `https://${getServiceNameForUrl(service)}.${region}.amazonaws.com/ping`.toLowerCase();
      configName = `${service}-${region}`.toLowerCase();
      break;
    // services that have static endpoints
    case 'CHIME_MEETINGS':
    case 'CHIME_VOICECONNECTOR':
      configTargetValue = 'https://service.chime.aws.amazon.com/ping';
      configName = 'chime';
      break;
    case 'CLOUDFRONT':
    case 'CLOUDFRONT_ORIGIN_FACING':
      configTargetValue = 'https://cloudfront.amazonaws.com/ping';
      configName = 'cloudfront';
      break;
    case 'GLOBALACCELERATOR':
      configTargetValue = 'https://globalaccelerator.amazonaws.com/ping';
      configName = 'globalaccelerator';
      break;
    case 'ROUTE53':
    case 'ROUTE53_HEALTHCHECKS':
    case 'ROUTE53_HEALTHCHECKS_PUBLISHING':
      configTargetValue = 'https://route53.amazonaws.com/ping';
      configName = 'route53';
      break;
    // special services
    case 'EC2_INSTANCE_CONNECT':
      configTargetValue = `https://ec2.${region}.amazonaws.com/ping`;
      configName = `ec2-${region}`.toLowerCase();
      break;
    default:
      return null;
  }

  if (configTargetValue && configName) {
    Object.assign(urlFieldsClone['config.target.value'], {
      value: configTargetValue
    });
    Object.assign(urlFieldsClone['config.name'], {
      value: configName
    });
  }

  const form = new FormState({ fields: urlFieldsClone });

  const test = (form && { ...form.getValues(), healthErrors: [], labels: [], test_type: 'url' }) || null;
  const matchingTest = testCollection?.models.find((t) => t.get('config.name') === test?.config.name);

  if (test) {
    // we have to change these from their form defaults of empty arrays to empty objects so the createTest will succeed
    test.config.http.css_selectors = {};
    test.config.http.headers = {};

    if (matchingTest) {
      test.company_id = matchingTest.get('company_id');
      test.device_id = matchingTest.get('device_id');
      test.user_id = matchingTest.get('user_id');
    }
  }

  return test;
}
