import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Box, Card, Grid, Icon, Tag, Text } from 'core/components';
import DeviceLink from 'app/components/links/DeviceLink';
import InterfaceLink from 'app/components/links/InterfaceLink';
import { getHealthState } from 'app/views/hybrid/utils/health';
import HealthDetailsItemContainer from './HealthDetailsItemContainer';
import CPUChart from './CPUChart';
import MemoryChart from './MemoryChart';
import InterfaceHealthCard from './InterfaceHealthCard';

function CPUPerformanceRenderer({ healthIssue }) {
  const deviceName = healthIssue.get('device_name');

  return (
    <HealthDetailsItemContainer title="CPU Usage">
      <Card p={1} mb={1}>
        <CPUChart deviceName={deviceName} title={<DeviceLink name={deviceName} />} />
      </Card>
    </HealthDetailsItemContainer>
  );
}

function memoryPerformanceRenderer({ healthIssue }) {
  const deviceName = healthIssue.get('device_name');

  return (
    <HealthDetailsItemContainer title="Memory Usage">
      <Card p={1} mb={1}>
        <MemoryChart deviceName={deviceName} title={<DeviceLink name={deviceName} />} />
      </Card>
    </HealthDetailsItemContainer>
  );
}

function deviceAvailabilitySNMPRenderer({ healthIssue }) {
  const { healthState } = healthIssue;
  const value = healthIssue.get('check.value');
  const icon = value === 1 ? 'small-tick' : 'small-cross';
  const description = `This device is${!value ? ' not' : ' '} available based on reachability for SNMP counter polling`;

  return (
    <HealthDetailsItemContainer title="SNMP Interface Availability">
      <Card p={1} mb={1}>
        <Grid gridTemplateColumns="45px 1fr">
          <Tag intent={healthState.intent} textAlign="center" height="32px">
            <Icon icon={icon} />
          </Tag>
          <Text>{description}</Text>
        </Grid>
      </Card>
    </HealthDetailsItemContainer>
  );
}

function interfaceAvailabilityRenderer({ healthIssue }) {
  const { healthState } = healthIssue;
  const value = healthIssue.get('check.value');
  const icon = value === 1 ? 'small-tick' : 'small-cross';
  const description = `This interface is${!value ? ' not' : ' '} available`;

  return (
    <HealthDetailsItemContainer title="Interface Availability">
      <Card p={1} mb={1}>
        <Grid gridTemplateColumns="45px 1fr" alignItems="center">
          <Tag intent={healthState.intent} textAlign="center" height="32px">
            <Icon icon={icon} />
          </Tag>
          <Text>{description}</Text>
        </Grid>
      </Card>
    </HealthDetailsItemContainer>
  );
}

function interfacePerformanceInOctetsRenderer({ healthIssue }) {
  return (
    <HealthDetailsItemContainer title="Ingress Interface Capacity">
      <InterfaceHealthCard healthIssue={healthIssue} direction="in" />
    </HealthDetailsItemContainer>
  );
}

function interfacePerformanceOutOctetsRenderer({ healthIssue }) {
  return (
    <HealthDetailsItemContainer title="Egress Interface Capacity">
      <InterfaceHealthCard healthIssue={healthIssue} direction="out" on />
    </HealthDetailsItemContainer>
  );
}

function getOperStatusDescription(healthIssue) {
  const status = healthIssue.displayValue?.toLowerCase() || 'unknown';
  const deviceName = healthIssue.get('device_name');
  const snmpId = healthIssue.get('snmp_id');
  const interfaceDescription = healthIssue.get('interface_description');

  return (
    <>
      Interface {status}: <DeviceLink name={deviceName} />:{' '}
      <InterfaceLink device_name={deviceName} snmp_id={snmpId} interface_description={interfaceDescription} />
    </>
  );
}

function interfaceOperStatusRenderer({ healthIssue, group }) {
  let state;
  let healthState;
  let description;

  if (group) {
    state = group.collection.overallState;
    healthState = getHealthState(state);
    description = (
      <>
        <Box fontWeight="medium" mb={1}>
          {group.description}
        </Box>
        {group.models.map((model) => (
          <Box key={model.id} my="4px">
            {getOperStatusDescription(model)}
          </Box>
        ))}
      </>
    );
  } else {
    state = healthIssue.get('check.state');
    healthState = healthIssue.healthState;
    description = getOperStatusDescription(healthIssue);
  }

  const icon = state === 'GOOD' ? 'small-tick' : 'small-cross';

  return (
    <HealthDetailsItemContainer title="Interface OperStatus">
      <Card p={1} mb={1}>
        <Grid gridTemplateColumns="auto 1fr" alignItems="baseline">
          <Tag intent={healthState.intent} textAlign="center" height="24px" small>
            <Icon icon={icon} />
          </Tag>
          <Box>{description}</Box>
        </Grid>
      </Card>
    </HealthDetailsItemContainer>
  );
}

const renderers = {
  device_performance_cpu_busy: CPUPerformanceRenderer,
  device_performance_mem_in_use: memoryPerformanceRenderer,
  device_availability_snmp: deviceAvailabilitySNMPRenderer,
  interface_availability: interfaceAvailabilityRenderer,
  interface_performance_inoctets: interfacePerformanceInOctetsRenderer,
  interface_performance_outoctets: interfacePerformanceOutOctetsRenderer,
  interface_availability_down: interfaceOperStatusRenderer,
  interface_availability_operstatus: interfaceOperStatusRenderer
};

@observer
export default class HealthItem extends Component {
  render() {
    const { healthIssue, group } = this.props;
    const type = group?.type || healthIssue.get('check.check_name');
    const renderer = renderers[type];

    if (renderer) {
      return renderer(this.props);
    }

    console.warn('unhandled device check', type);

    return null;
  }
}
