import { inject } from 'mobx-react';
import { isObject } from 'lodash';
import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import { rgba } from 'polished';
import classNames from 'classnames';
import { FLEX_GAP } from 'app/views/hybrid/utils/cloud/constants';

import { ENTITY_TYPES, MAP_TYPES } from 'shared/hybrid/constants';
import { addCustomPropertiesToEntity, getCustomProperties } from 'shared/util/map';
import { Box, ButtonLink, Flex, Tooltip } from 'core/components';
import { Grid, Path, Rect, Text as SVGText, TextMeasure } from 'app/components/svg';

import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { getMapClassname, getEntityType } from 'app/views/hybrid/utils/map';

import CloudItem from 'app/views/hybrid/maps/components/CloudItem';
import { getGatewayProps } from 'app/views/hybrid/maps/components/popovers/cloudUtil';

const { SUBNET, NETWORK_PEERING, CLOUD_ROUTER, VPN_GATEWAY, INTERCONNECT_ATTACHMENT } = ENTITY_TYPES.get('gcp');

const zonePaddingX = 16;
const zonePaddingY = 32;
const zoneMargin = 30;

const itemMarginX = FLEX_GAP;
const itemMarginY = 32;
const itemWidth = 160;
const itemHeight = 38;

const connectionItemWidth = 160;
const connectionItemHeight = 38;

const miniItemSize = connectionItemHeight;
const miniItemMargin = 5;
const groupMargin = 60;

const RegionBox = styled(Box)`
  background-color: ${(props) => rgba(props.theme.colors.gcp.green, 0.15)};
  border: 1px solid ${(props) => props.theme.colors.gcp.green};
  border-radius: 4px;
`;

function getConnectionProps(item) {
  const { type, icon, stroke } = getGatewayProps({ cloudProvider: 'gcp', ...item });

  return {
    title: type,
    subtitle: item.name || item.id,
    icon,
    stroke
  };
}

@withTheme
@inject('$hybridMap')
export default class RegionMap extends Component {
  static defaultProps = {
    padding: 8,
    highlighted: []
  };

  state = {
    tooltipOpen: false,
    tooltipItem: null,
    tooltipRect: {}
  };

  get svgWidth() {
    const { width } = this.props;
    return width - 2;
  }

  get availableWidth() {
    const { padding } = this.props;
    return this.svgWidth - padding * 2;
  }

  get zonePositions() {
    const { region } = this.props;
    const { availableWidth } = this;
    // @TODO take from relatedEntities
    const networkSubnets = region?.subnets || [];

    const maxCols = Math.floor((availableWidth - zonePaddingX * 2 + itemMarginX) / (itemWidth + itemMarginX));

    const uniqueAZs = ['Subnets'];

    const zonePositions = uniqueAZs
      .map((zoneName) => {
        const rows = Math.ceil(networkSubnets.length / maxCols);
        const cols = Math.ceil(networkSubnets.length / rows);

        const width = cols * (itemWidth + itemMarginX) - itemMarginX + 2 * zonePaddingX;
        const height = rows * (itemHeight + itemMarginY) - itemMarginY + 2 * zonePaddingY;

        return { zoneName, subnets: networkSubnets, rows, cols, width, height };
      })
      .filter(({ subnets }) => subnets.length > 0);

    let xOffset = 0;
    let yOffset = 0;
    let rowHeight = 0;

    for (let i = 0; i < zonePositions.length; i += 1) {
      const zonePos = zonePositions[i];

      if (xOffset + zonePos.width > availableWidth) {
        xOffset = 0;
        yOffset += rowHeight + zoneMargin;
        rowHeight = 0;
      }

      zonePos.x = xOffset;
      zonePos.y = yOffset;

      xOffset += zonePos.width + zoneMargin;
      rowHeight = Math.max(rowHeight, zonePos.height);
    }

    return zonePositions;
  }

  get groupedNetworkConnections() {
    const { region } = this.props;
    const groups = {};

    MAP_TYPES.get('gcp.regional_entities').forEach((type) => {
      let connections = region[type] || [];

      /** example: vhub.hubVirtualNetworkConnections */
      if (region[type] && isObject(connections)) {
        connections = Object.values(connections);
      }

      if (!Array.isArray(connections)) {
        connections = [connections];
      }

      groups[type] = connections.map((item) => {
        let peeringId;

        if (type === NETWORK_PEERING) {
          peeringId = `${item.id}-${region.id}`;
        }

        return { ...item, type, peeringId };
      });
    });

    return groups;
  }

  get networkConnections() {
    return Object.values(this.groupedNetworkConnections)
      .flat()
      .map((connection) =>
        addCustomPropertiesToEntity({
          entity: connection,
          customProperties: { iconsToDisplay: this.getChildIcons(connection) }
        })
      );
  }

  collapseConnections(networkConnections = this.networkConnections) {
    const { availableWidth } = this;
    const num = networkConnections.length;
    return num * (connectionItemWidth + itemMarginX) - itemMarginX > availableWidth;
  }

  // grouped positions when vpc connections are collapsed
  getConnectionPositions(groupedNetworkConnections = this.groupedNetworkConnections) {
    const groups = Object.values(groupedNetworkConnections).filter((group) => group.length > 0);
    const availableWidth = this.availableWidth - (groups.length - 1) * groupMargin;

    const widths = groups.map((connections) => {
      const num = connections.length;
      const width = Math.max(miniItemSize, num * (miniItemSize + miniItemMargin) - miniItemMargin);
      return { connections, num, width };
    });

    const sumWidths = () => widths.reduce((sum, { width }) => sum + width, 0);

    if (availableWidth > groups.length * miniItemSize) {
      while (sumWidths() > availableWidth) {
        const maxWidth = Math.max(...widths.map(({ width }) => width)) - (miniItemSize + miniItemMargin);

        widths.forEach((item) => {
          item.width = Math.min(item.width, maxWidth);
        });
      }
    }

    const extraMargin = Math.floor((availableWidth - sumWidths()) / groups.length);
    let xOffset = 0;

    return widths.map(({ connections, num, width }) => {
      const cols = Math.floor((width + miniItemMargin) / (miniItemSize + miniItemMargin));
      const rows = Math.ceil(num / cols);
      const height = rows * (miniItemSize + miniItemMargin) - miniItemMargin;
      const x = xOffset;

      xOffset += width + groupMargin + extraMargin;

      return { connections, width, height, rows, cols, x };
    });
  }

  getItemClasses(item) {
    const { selected, highlighted } = this.props;
    const isSelected = selected === item.id;
    const isHighlighted = highlighted.includes(item.name);

    return {
      selected: isSelected,
      highlighted: isHighlighted,
      unselected: highlighted.length > 0 && !isSelected && !isHighlighted
    };
  }

  // TODO: make this work (subnet not Subnets...etc)
  getSubnetsForZone(zoneName) {
    const { region } = this.props;
    const { Subnets = [] } = region;
    return Subnets.filter((subnet) => subnet.AvailabilityZone === zoneName);
  }

  handleShowDetails = (evt) => {
    const { region } = this.props;
    evt.stopPropagation();
    this.handleItemClick(region);
  };

  handleItemHover = (tooltipItem, tooltipRect) => {
    if (tooltipItem) {
      tooltipRect.top += 64;
      this.setState({ tooltipOpen: true, tooltipItem, tooltipRect });
    } else {
      this.setState({ tooltipOpen: false });
    }
  };

  handleItemClick = (item) => {
    const { onShowDetails } = this.props;
    const type = getEntityType(item);

    if (type) {
      onShowDetails({ type, nodeData: item });
    }
  };

  /*
    Entities such as child routers can display child items in the map such as vpn gateways and interconnect attachments
  */
  getChildIcons(entity) {
    const entityType = getEntityType(entity);
    const icons = [];

    if (entityType === CLOUD_ROUTER) {
      // get a count for each interface type (vpn tunnel, interconnect attachment, or vm instance)
      const interfaceTypes = entity.interfaces.reduce(
        (acc, inf) => {
          if (inf.linkedVpnTunnel) {
            acc.vpnTunnel.count += 1;
          } else if (inf.linkedInterconnectAttachment) {
            acc.interconnectAttachment.count += 1;
          } else {
            acc.vmInstance.count += 1;
          }

          return acc;
        },
        {
          vpnTunnel: {
            icon: { entity: VPN_GATEWAY, title: 'VPN-GW' },
            count: 0
          },

          interconnectAttachment: {
            icon: { entity: INTERCONNECT_ATTACHMENT, title: 'IC-ATT' },
            count: 0
          },

          vmInstance: {
            // using the SUBNET entity type because we just want the cloud icon
            // not sure if this is/should be an official type or just something more generic we display as a cloud router child
            // right now, we're taking the generic approach
            icon: { entity: SUBNET, title: 'VM-INF' },
            count: 0
          }
        }
      );

      Object.values(interfaceTypes).forEach((infType) => {
        if (infType.count > 0) {
          icons.push({
            ...infType.icon,
            cloudProvider: 'gcp',
            title: `${infType.icon.title} ${infType.count > 1 ? `(${infType.count})` : ''}`,
            count: infType.count
          });
        }
      });
    }

    return icons;
  }

  render() {
    const { width, padding, region, theme, onClose } = this.props;
    const { tooltipOpen, tooltipItem, tooltipRect } = this.state;
    const { zonePositions, groupedNetworkConnections, networkConnections } = this;
    const collapseConnections = this.collapseConnections(networkConnections);

    const lastZone = zonePositions[zonePositions.length - 1];
    const zonesHeight = zonePositions.length > 0 ? lastZone.y + lastZone.height + zoneMargin : 0;

    let svgHeight = padding + zonesHeight;
    let connectionPositions;

    if (networkConnections.length > 0) {
      const hasChildrenToDisplay = networkConnections.some(
        (connection) => getCustomProperties(connection)?.iconsToDisplay.length > 0
      );

      if (collapseConnections) {
        connectionPositions = this.getConnectionPositions(groupedNetworkConnections);
        svgHeight += Math.max(...connectionPositions.map(({ height }) => height)) + 32;
      } else {
        svgHeight += hasChildrenToDisplay ? connectionItemHeight * 2 : connectionItemHeight + itemMarginY;
      }
    }

    return (
      <Box position="relative">
        <Flex alignItems="center" justifyContent="space-between" width={width}>
          <RegionBox className="region-mini-map">
            <Flex alignItems="flex-start" justifyContent="space-between" m={`${padding}px`}>
              <Flex alignItems="center">
                <CloudIcon cloudProvider="gcp" entity="regions" />
                <Box ml={1}>
                  <Box fontSize={14} fontWeight={500}>
                    {region.name || region.selectId}
                  </Box>
                  {region.name && (
                    <Box fontSize={12} fontStyle="italic" color="muted">
                      {region.selfLink}
                    </Box>
                  )}
                </Box>
              </Flex>
              <Box whiteSpace="nowrap">
                <ButtonLink small ml={1} onClick={this.handleShowDetails}>
                  Show Details
                </ButtonLink>
                <ButtonLink color="muted" small ml={1} onClick={onClose}>
                  Close
                </ButtonLink>
              </Box>
            </Flex>
            <Box>
              <svg width={this.svgWidth} height={svgHeight}>
                <g transform={`translate(${padding} ${Math.round(padding / 2)})`}>
                  <g>
                    {zonePositions.map(({ zoneName, subnets, ...zonePos }) => (
                      <g key={zoneName} transform={`translate(${zonePos.x} ${zonePos.y})`}>
                        <Rect
                          x={-0.5}
                          y={-0.5}
                          width={zonePos.width + 1}
                          height={zonePos.height + 1}
                          fill="transparent"
                          stroke="body"
                          strokeWidth={1}
                          strokeDasharray="2"
                          rx={4}
                        />
                        <g fontSize={12} letterSpacing="-0.035em">
                          <TextMeasure text={zoneName}>
                            {({ text, width: textWidth }) => (
                              <>
                                <g transform="translate(-0.5 -0.5)">
                                  <Path
                                    d={`M0,20 v-16 q0,-4 4,-4 h${textWidth + 4} v16 q0,4 -4,4 z`}
                                    fill="transparent"
                                    stroke="body"
                                    strokeWidth={1}
                                  />
                                </g>
                                <SVGText x={6} y={5} dominantBaseline="hanging" fill="body">
                                  {text}
                                </SVGText>
                                <g transform={`translate(${zonePaddingX} ${zonePaddingY})`}>
                                  {subnets.map((subnet, subnetIndex) => {
                                    const row = Math.floor(subnetIndex / zonePos.cols);
                                    const col = subnetIndex % zonePos.cols;

                                    const x = col * (itemWidth + itemMarginX);
                                    const y = row * (itemHeight + itemMarginY);
                                    return (
                                      <g key={subnet.selfLink} transform={`translate(${x} ${y})`}>
                                        <CloudItem
                                          width={itemWidth}
                                          height={itemHeight}
                                          stroke={theme.colors.gcp.green}
                                          title={subnet.name || subnet.selfLink}
                                          subtitle={subnet.ipCidrRange}
                                          cloudProvider="gcp"
                                          icon={
                                            <CloudIcon cloudProvider="gcp" entity={SUBNET} width={17} height={17} />
                                          }
                                          className={classNames(
                                            getMapClassname({ type: SUBNET, value: subnet.selfLink }),
                                            getMapClassname({ type: SUBNET, value: getCustomProperties(subnet).key }),
                                            this.getItemClasses(subnet)
                                          )}
                                          onClick={(evt) => this.handleItemClick(subnet, evt.target)}
                                        />
                                      </g>
                                    );
                                  })}
                                </g>
                              </>
                            )}
                          </TextMeasure>
                        </g>
                      </g>
                    ))}
                  </g>
                  <g transform={`translate(0 ${zonesHeight})`}>
                    {collapseConnections &&
                      connectionPositions.map(({ connections, width: groupWidth, x }) => {
                        const num = connections.length;
                        const label =
                          getGatewayProps({ cloudProvider: 'gcp', ...connections[0] }).type +
                          (num > 1 ? `s (${num})` : '');
                        return (
                          <g key={label} transform={`translate(${x}, 16)`}>
                            <SVGText fontSize={11} fontWeight={500} color="muted">
                              {label}
                            </SVGText>
                            <Grid
                              items={connections}
                              width={groupWidth + 2}
                              paddingX={1}
                              paddingY={8}
                              itemWidth={miniItemSize}
                              itemHeight={miniItemSize}
                              itemMarginX={miniItemMargin}
                              itemMarginY={miniItemMargin}
                              renderItem={(connection, props) => {
                                const { icon, stroke } = getConnectionProps(connection);
                                return (
                                  <CloudItem
                                    {...props}
                                    icon={icon}
                                    stroke={stroke}
                                    fill="appBackground"
                                    className={classNames(
                                      getMapClassname({ type: 'gateway', value: connection.id }),
                                      getMapClassname({ type: 'gateway', value: connection.name }),
                                      getMapClassname({ type: getEntityType(connection), value: connection.name }),
                                      getMapClassname({ type: getEntityType(connection), value: connection.id }),
                                      getMapClassname({ type: connection.type, value: connection.id }),
                                      getMapClassname({ type: connection.type, value: connection.name }),
                                      connection.peeringId &&
                                        getMapClassname({ type: 'gateway', value: connection.peeringId }),
                                      connection.peeringId &&
                                        getMapClassname({ type: connection.type, value: connection.peeringId }),
                                      this.getItemClasses(connection)
                                    )}
                                    {...getConnectionProps(connection)}
                                  />
                                );
                              }}
                              onItemHover={this.handleItemHover}
                              onItemClick={this.handleItemClick}
                              embed
                            />
                          </g>
                        );
                      })}

                    {!collapseConnections &&
                      networkConnections.map((connection, index) => {
                        const { iconsToDisplay = [] } = getCustomProperties(connection);

                        return (
                          connection &&
                          connection.id && (
                            <g
                              key={connection.id}
                              transform={`translate(${(connectionItemWidth + itemMarginX) * index}, 0)`}
                            >
                              <CloudItem
                                width={connectionItemWidth}
                                height={iconsToDisplay.length > 0 ? connectionItemHeight * 2 : connectionItemHeight}
                                stroke={theme.colors.gcp.green}
                                className={classNames(
                                  getMapClassname({ type: 'gateway', value: connection.id }),
                                  getMapClassname({ type: 'gateway', value: connection.name }),
                                  getMapClassname({ type: getEntityType(connection), value: connection.name }),
                                  getMapClassname({ type: getEntityType(connection), value: connection.id }),
                                  getMapClassname({ type: connection.type, value: connection.id }),
                                  getMapClassname({ type: connection.type, value: connection.name }),
                                  connection.peeringId &&
                                    getMapClassname({ type: 'gateway', value: connection.peeringId }),
                                  connection.peeringId &&
                                    getMapClassname({ type: connection.type, value: connection.peeringId }),
                                  this.getItemClasses(connection)
                                )}
                                onClick={(evt) => this.handleItemClick(connection, evt.target)}
                                innerProps={{ height: connectionItemHeight }}
                                iconsToDisplay={iconsToDisplay}
                                {...getConnectionProps(connection)}
                              />
                            </g>
                          )
                        );
                      })}
                  </g>
                </g>
              </svg>
            </Box>
          </RegionBox>
        </Flex>
        <Tooltip
          key={tooltipItem && tooltipItem.id}
          isOpen={tooltipOpen}
          boundary="viewport"
          position="bottom"
          content={tooltipItem ? tooltipItem.Name || tooltipItem.id : null}
          target={<div />}
          targetProps={{ style: { pointerEvents: 'none', position: 'absolute', ...tooltipRect } }}
        />
      </Box>
    );
  }
}
