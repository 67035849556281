import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CELL_TYPES, Search } from 'core/components/table';
import RemoveButton from 'core/form/components/RemoveButton';
import { Box, Button, Checkbox, DropdownMenu, Flex, Menu, MenuItem } from 'core/components';

import AdminTable from 'app/components/admin/AdminTable';
import UserCollection from 'app/stores/user/UserCollection';

@inject('$auth', '$rbac', '$users')
@observer
class RbacRoleUsers extends Component {
  state = {
    users: new UserCollection()
  };

  componentDidMount() {
    const { model } = this.props;
    const { users } = this.state;

    // users seem to be the users to chose from NOT the ones assigned currently
    users.setPresetFilter({
      fn: (user) => !user.get('user_group_id') && !user.get('roles').includes(parseInt(model.get('id')))
    });

    users.fetch();
  }

  refetchRole() {
    const { $rbac } = this.props;
    $rbac.selectedRbacRoleUsersCollection.fetch({ force: true }).then(() => {
      $rbac.selectedRbacRoleUsersCollection.totalCount = $rbac.selectedRbacRoleUsersCollection.size;
    });
  }

  get columns() {
    const { $auth, $rbac, viewOnly } = this.props;
    return [
      {
        name: 'user_name',
        label: 'User Name',
        flexBasis: 150
      },
      {
        name: 'user_full_name',
        label: 'User Full Name',
        flexBasis: 150
      },
      {
        name: 'user_email',
        label: 'User Email',
        flexBasis: 150
      },
      {
        type: CELL_TYPES.ACTION,
        width: 72,
        actions: [
          (model) => {
            if (viewOnly || !$auth.hasRbacPermissions(['rbac.role.user::remove_from_role'])) {
              return <div key="remove" />;
            }

            return (
              <RemoveButton
                key="remove"
                model={model}
                showIcon
                hideText
                small
                onRemove={() => {
                  $rbac
                    .removeUserFromRole(model.id, $rbac.selectedRbacRoleUsersCollection.roleId)
                    .then(() => this.refetchRole());
                }}
              />
            );
          }
        ]
      }
    ];
  }

  handleSearch = (e) => {
    const { users } = this.state;

    return users.filter(e.target.value);
  };

  render() {
    const { users } = this.state;
    const { $rbac, model: rbacRole, $auth } = this.props;

    return (
      <Flex flexDirection="column" flex={1} px={2}>
        <Flex mt={1} flexDirection="row" flex={1} style={{ overflow: 'auto' }}>
          <AdminTable
            tableContainerProps={{ bg: 'cardBackground' }}
            fetchCollectionOnMount
            collection={$rbac.selectedRbacRoleUsersCollection}
            flexed
            columns={this.columns}
            tableHeaderControlsPosition="afterSearch"
            tableHeaderControls={
              $auth.hasRbacPermissions(['rbac.role.user::assign_to_role']) && (
                <DropdownMenu
                  content={
                    <Box>
                      <Search collection={users} onChange={this.handleSearch} />
                      <Menu py={1} height={310} width={300} overflow="auto" mb={1}>
                        {users.map((user) => (
                          <MenuItem
                            as={Checkbox}
                            py="4px"
                            key={user.id}
                            label={user.get('user_full_name')}
                            checked={user.isSelected}
                            onClick={(e) => {
                              e.stopPropagation();
                              user.select({ multi: true, shiftKey: e.shiftKey });
                            }}
                          />
                        ))}
                      </Menu>
                      <Flex p={1} justifyContent="space-between">
                        <Button
                          intent="primary"
                          text="Apply"
                          disabled={!users.selected?.length}
                          className="bp4-popover-dismiss"
                          onClick={() =>
                            $rbac
                              .bulkAddUsersToRole(
                                users.selected.map((user) => user.id),
                                rbacRole.get('id')
                              )
                              .then(() => {
                                users.clearSelection();
                                return this.refetchRole();
                              })
                          }
                        />
                        <Button
                          className="bp4-popover-dismiss"
                          text="Cancel"
                          mr={2}
                          onClick={() => users.clearSelection()}
                        />
                      </Flex>
                    </Box>
                  }
                >
                  <Button ml={2} icon="plus" text="Add User(s)" intent="primary" rightIcon="caret-down" />
                </DropdownMenu>
              )
            }
          />
        </Flex>
      </Flex>
    );
  }
}
export default RbacRoleUsers;
