import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Suspense, Spinner } from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import { ENTITY_TYPES } from 'shared/hybrid/constants';

const { CLUSTER, NODE, POD } = ENTITY_TYPES.get('kube');

@inject('$hybridMap')
@observer
export default class KubeHealth extends Component {
  state = {
    loading: true
  };

  resultsMap = { nodes: null, pods: null };

  nodesQuery = {
    all_devices: false,
    show_overlay: false,
    show_total_overlay: false,
    device_types: ['kappa'],
    aggregateTypes: ['avg_bits_per_sec', 'avg_ktsubtype__kappa__APPL_LATENCY_MS'],
    metric: ['ktsubtype__kappa__STR06', 'i_device_id'],
    viz_type: 'table',
    depth: 500,
    topx: 500
  };

  podsQuery = {
    all_devices: false,
    show_overlay: false,
    show_total_overlay: false,
    device_types: ['kappa'],
    aggregateTypes: ['avg_bits_per_sec', 'avg_ktsubtype__kappa__APPL_LATENCY_MS'],
    metric: ['kt_k8s_src_pod_name', 'kt_k8s_src_pod_ns', 'i_device_id'],
    filters: {
      connector: 'All',
      filterGroups: [
        { connector: 'All', filters: [{ filterField: 'ktsubtype__kappa__STR10', operator: '=', filterValue: 'pod' }] }
      ]
    },
    viz_type: 'table',
    depth: 500,
    topx: 500
  };

  onQueriesComplete = () => {
    const items = [];

    const { $hybridMap } = this.props;
    const { Entities } = $hybridMap.kubeCloudMapCollection;

    this.resultsMap.nodes.each((row) => {
      const latency = row.get('avg_ktsubtype__kappa__APPL_LATENCY_MS');

      if (latency > $hybridMap.latencyThreshold) {
        const nodeName = row.get('ktsubtype__kappa__STR06');
        const deviceName = row.get('i_device_name');
        const nodeNamespace = row.get('kt_k8s_src_pod_ns');

        // ensure cluster exist in topology
        const clusterId = Object.keys(Entities?.[CLUSTER] ?? {}).find(
          (tClusterId) =>
            $hybridMap?.kubeCloudMapCollection?.Entities?.[CLUSTER]?.[tClusterId]?.device_name === deviceName
        );

        if (!clusterId) {
          return;
        }

        if (nodeName !== '---') {
          items.push({
            name: nodeName,
            type: NODE,
            namespace: nodeNamespace,
            deviceName,
            latency,
            clusterId
          });
        }
      }
    });

    this.resultsMap.pods.each((row) => {
      const latency = row.get('avg_ktsubtype__kappa__APPL_LATENCY_MS');

      if (latency > $hybridMap.latencyThreshold) {
        const podName = row.get('kt_k8s_src_pod_name');
        const podNamespace = row.get('kt_k8s_src_pod_ns');
        const deviceName = row.get('i_device_name');

        // ensure cluster exist in topology
        const clusterId = Object.keys(Entities?.[CLUSTER] ?? {}).find(
          (tClusterId) =>
            $hybridMap?.kubeCloudMapCollection?.Entities?.[CLUSTER]?.[tClusterId]?.device_name === deviceName
        );

        if (!clusterId) {
          return;
        }

        if (podName !== '---') {
          items.push({ name: podName, type: POD, namespace: podNamespace, deviceName, latency, clusterId });
        }
      }
    });

    $hybridMap.setKubeState({ highLatencyItems: items.sort((a, b) => b.latency - a.latency) });
  };

  onQueryComplete = ({ results, type }) => {
    this.resultsMap[type] = results;

    if (this.resultsMap.nodes && this.resultsMap.pods) {
      this.onQueriesComplete();
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    const { children, $hybridMap } = this.props;

    return (
      <>
        <LightweightDataViewWrapper
          query={$hybridMap.getQuery(this.nodesQuery)}
          onQueryComplete={({ results }) => this.onQueryComplete({ results, type: 'nodes' })}
        />
        <LightweightDataViewWrapper
          query={$hybridMap.getQuery(this.podsQuery)}
          onQueryComplete={({ results }) => this.onQueryComplete({ results, type: 'pods' })}
        />
        <Suspense loading={loading} fallback={<Spinner size={16} />}>
          {children()}
        </Suspense>
      </>
    );
  }
}
