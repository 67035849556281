import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';

import { FlexColumn } from 'core/components';
import Topology from './Topology';
import EmbeddedTopo from './EmbeddedTopo';

@inject('$topo', '$devices')
@observer
class DeviceTopology extends Component {
  static propTypes = {
    deviceName: PropTypes.string,
    embedded: PropTypes.bool
  };

  static defaultProps = {
    deviceName: undefined,
    embedded: false
  };

  @computed
  get deviceName() {
    const { deviceName, match } = this.props;

    return deviceName || match.params.deviceName;
  }

  @computed
  get device() {
    const { $devices } = this.props;
    return $devices.deviceSummariesByName[this.deviceName];
  }

  componentDidMount() {
    this.getTopologyData();
  }

  componentDidUpdate = (prevProps) => {
    const { deviceName, match = {} } = this.props;
    const { params = {} } = match;
    const prevParams = prevProps.match ? prevProps.match.params : {};

    if (deviceName !== prevProps.deviceName || params.deviceName !== prevParams.deviceName) {
      this.getTopologyData();
    }
  };

  getTopologyData = () => {
    const { $topo } = this.props;

    $topo.getTopologyData({
      selectedDeviceName: this.deviceName,
      type: 'device',
      level: 'device',
      showProviders: 'name'
    });
  };

  handleOpenClick = () => {
    const { $topo } = this.props;
    $topo.navigateToDeviceTopo(this.deviceName);
  };

  render() {
    const { embedded, expandable } = this.props;

    return (
      <FlexColumn height="100%">
        {embedded && <EmbeddedTopo onOpen={this.handleOpenClick} expandable={expandable} />}
        {!embedded && <Topology title={this.deviceName} deviceId={this.device ? this.device.id : undefined} />}
      </FlexColumn>
    );
  }
}

export default DeviceTopology;
