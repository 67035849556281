import React from 'react';
import { uniqWith as _uniqWith, isEqual as _isEqual } from 'lodash';
import SidebarItem from '../../SidebarItem';
import CloudMetadata from '../../CloudMetadata';

const labelMap = {
  ruleMappings: 'Rule Mappings',
  interfaceNatMappings: 'Interface NAT Mappings',
  rules: 'Rules',
  ruleStatus: 'Rule Status',
  natIpInfoMappings: 'NAT IP Info Mappings',
  autoAllocatedNatIps: 'Auto-allocated NAT IPs'
};

const NatGatewayMappingDetails = ({ width, popoutTitle, data, label }) => {
  const title = labelMap[label] ?? 'Misc';
  let keys = [];
  const node = {};

  data.forEach(({ sublabel, values }) => {
    keys.push(sublabel);
    node[sublabel] = node[sublabel] ? [values, node[sublabel]].join() : values;
  });

  keys = _uniqWith(keys, _isEqual);

  return (
    <SidebarItem
      key={title}
      excludeFormProps
      title={title}
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      popoutTitle={popoutTitle}
    >
      <CloudMetadata node={node} keys={keys} />
    </SidebarItem>
  );
};

export default NatGatewayMappingDetails;
