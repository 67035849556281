import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { Flex } from 'core/components';

const BorderedBox = styled(Flex)`
  border-color: ${themeGet('borderColors.thick')};
  border-radius: 8px;
  border-style: ${({ borderStyle }) => borderStyle};
  border-width: 2px;

  &.highlighted {
    border-color: ${themeGet('colors.primary')};
  }
`;

BorderedBox.defaultProps = {
  position: 'relative',
  bg: 'subnavBackground',
  borderStyle: 'solid',
  p: 2
};

export default BorderedBox;
