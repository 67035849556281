import React from 'react';

import { inject } from 'mobx-react';
import { Flex, Text } from 'core/components';

import SidebarItem from '../../SidebarItem';
import CloudMetadata from '../../CloudMetadata';
import AzureVirtualHubDetails from './VirtualHubDetails';
import AzureExpressRouteConnectionDetails from './ExpressRouteConnectionDetails';

const AzureExpressRouteGatewayDetails = ({ width, popoutTitle, $hybridMap, nodeData }) => {
  const { azureCloudMapCollection } = $hybridMap;

  const virtualHub = azureCloudMapCollection.getEntityProperty(nodeData, 'virtualHub');
  const expressRouteConnections = azureCloudMapCollection.getEntityProperty(nodeData, 'expressRouteConnections');

  return (
    <SidebarItem
      excludeFormProps
      title={nodeData?.name}
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="asterisk"
      popoutTitle={popoutTitle}
    >
      <Flex flexDirection="column" gap={1}>
        <CloudMetadata
          node={{
            ...nodeData,
            ...nodeData.properties
          }}
          keys={['name, location, provisioningState']}
        />
        {expressRouteConnections.length > 0 && (
          <Flex flexDirection="column" gap={1}>
            <Text as="div">Express Route Connections</Text>
            {expressRouteConnections.map((connection) => (
              <AzureExpressRouteConnectionDetails
                key={connection.id}
                nodeData={connection}
                width={width}
                popoutTitle={popoutTitle}
              />
            ))}
          </Flex>
        )}

        {virtualHub && (
          <Flex flexDirection="column" gap={1}>
            <Text as="div">Virtual Hub</Text>

            <AzureVirtualHubDetails nodeData={virtualHub} width={width} popoutTitle={popoutTitle} />
          </Flex>
        )}
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureExpressRouteGatewayDetails);
