import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { formatBytesGreek } from 'core/util';
import { Box, Button, Flex, Grid, HTMLTable, Icon, Link, Spinner, Suspense } from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import DeviceLink from 'app/components/links/DeviceLink';
import InterfaceLink from 'app/components/links/InterfaceLink';
import InterfaceMetadataCard from 'app/views/hybrid/maps/components/popovers/interfaceMetadata/InterfaceMetadataCard';
import { getConnectionKey } from 'app/views/hybrid/utils/links';

@inject('$explorer', '$hybridMap')
@observer
export default class InterfaceCounters extends React.Component {
  get interfaceCountersQuery() {
    const { queryConfig, queryOverrides } = this.props;

    return {
      all_devices: false,
      device_name: Object.keys(queryConfig.devices),
      lookback_seconds: 86400,
      metric: ['ktappprotocol__snmp__output_port', 'ktappprotocol__snmp__i_device_name'],
      aggregateTypes: [
        'agg_total_ktappprotocol__snmp__INT64_00', // snmp bits/s in
        'agg_total_ktappprotocol__snmp__INT64_02', // snmp bits/s out
        'agg_total_ktappprotocol__snmp__INT00', // snmp input errors
        'agg_total_ktappprotocol__snmp__INT01', // snmp output errors
        'agg_total_ktappprotocol__snmp__INT64_04', // snmp input discards
        'agg_total_ktappprotocol__snmp__INT64_05' // snmp output discards
      ],
      show_overlay: false,
      show_total_overlay: false,
      units: [
        'ktappprotocol__snmp__INT64_00',
        'ktappprotocol__snmp__INT64_02',
        'ktappprotocol__snmp__INT00',
        'ktappprotocol__snmp__INT01',
        'ktappprotocol__snmp__INT64_04',
        'ktappprotocol__snmp__INT64_05'
      ],
      filters: queryConfig.filters,
      viz_type: 'table',
      ...queryOverrides
    };
  }

  modelMatch(model, value) {
    return (
      model.get('i_device_name') === value.device_name &&
      model.get('i_output_interface_description') === value.interface_description
    );
  }

  renderCounters(model, rawInterface, selected) {
    const data = {
      bitsIn: 0,
      bitsOut: 0,
      inputErrors: 0,
      outputErrors: 0,
      inputDiscards: 0,
      outputDiscards: 0
    };

    if (model) {
      data.bitsIn = (model.get('agg_total_ktappprotocol__snmp__INT64_00') * 8) / 86400;
      data.bitsOut = (model.get('agg_total_ktappprotocol__snmp__INT64_02') * 8) / 86400;
      data.inputErrors = model.get('agg_total_ktappprotocol__snmp__INT00');
      data.outputErrors = model.get('agg_total_ktappprotocol__snmp__INT01');
      data.inputDiscards = model.get('agg_total_ktappprotocol__snmp__INT64_04');
      data.outputDiscards = model.get('agg_total_ktappprotocol__snmp__INT64_05');
    }

    return (
      <InterfaceMetadataCard selected={selected}>
        <Box p={1}>
          <DeviceLink name={rawInterface.device_name} blank />
          {' - '}
          <InterfaceLink {...rawInterface} blank>
            {rawInterface.interface_description}
          </InterfaceLink>
        </Box>
        <HTMLTable condensed striped>
          <tbody>
            <tr>
              <td>SNMP Bits/s In</td>
              <td>{formatBytesGreek(data.bitsIn, 'bits/s')}</td>
            </tr>
            <tr>
              <td>SNMP Bits/s Out</td>
              <td>{formatBytesGreek(data.bitsOut, 'bits/s')}</td>
            </tr>
            <tr>
              <td>Input Errors</td>
              <td>{data.inputErrors}</td>
            </tr>
            <tr>
              <td>Output Errors</td>
              <td>{data.outputErrors}</td>
            </tr>
            <tr>
              <td>Input Discards</td>
              <td>{data.inputDiscards}</td>
            </tr>
            <tr>
              <td>Output Discards</td>
              <td>{data.outputDiscards}</td>
            </tr>
          </tbody>
        </HTMLTable>
      </InterfaceMetadataCard>
    );
  }

  renderResults = (results) => {
    const { $hybridMap, queryConfig, selectedKey } = this.props;

    return (
      <Grid alignItems="center" gridTemplateColumns="1fr auto 1fr">
        {queryConfig.site1 && queryConfig.site2 && (
          <>
            <Link to={`${$hybridMap.baseRoute}/site/${queryConfig.site1.id}`} textAlign="center">
              {queryConfig.site1.name}
            </Link>
            <span />
            <Link to={`${$hybridMap.baseRoute}/site/${queryConfig.site2.id}`} textAlign="center">
              {queryConfig.site2.name}
            </Link>
          </>
        )}
        {queryConfig.connections.reduce((acc, { interface1, interface2 }) => {
          const sourceModel = results.models.find((model) => this.modelMatch(model, interface1));
          const key = getConnectionKey({ interface1, interface2 });
          const selected = key === selectedKey;
          let targetModel = null;

          if (interface2) {
            targetModel = results.models.find((model) => this.modelMatch(model, interface2));
          }

          const cmp = (
            <React.Fragment key={key}>
              {this.renderCounters(sourceModel, interface1, selected)}
              {interface2 && (
                <>
                  <Icon icon="arrows-horizontal" color={selected ? 'primary' : 'body'} />
                  {this.renderCounters(targetModel, interface2, selected)}
                </>
              )}
            </React.Fragment>
          );

          if (selected) {
            return [].concat(cmp, acc);
          }

          return acc.concat(cmp);
        }, [])}
      </Grid>
    );
  };

  render() {
    const { $explorer } = this.props;

    return (
      <LightweightDataViewWrapper query={this.interfaceCountersQuery}>
        {({ loading, results }) => (
          <Suspense
            loading={loading}
            fallback={
              <Flex p={2} justifyContent="center">
                <Spinner />
              </Flex>
            }
          >
            <Box>
              {this.renderResults(results)}

              <Button
                mt={1}
                onClick={() => $explorer.navigateToExplorer(this.interfaceCountersQuery, true)}
                icon="series-search"
                text="View in Data Explorer"
                minimal
                small
              />
            </Box>
          </Suspense>
        )}
      </LightweightDataViewWrapper>
    );
  }
}
