import React from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Flex, Select, Switch, Text } from 'core/components';
import LabelSelectorList from 'app/components/labels/LabelSelectorList';

import AdminTable from 'app/components/admin/AdminTable';
import RbacRoleFilterSidebar from './RbacRoleFilterSidebar';

const RbacIsEnabled = observer(({ model, disabled }) => (
  <Box p={1} data-testid="switchOuter">
    <Switch
      key="select"
      disabled={disabled}
      checked={model.get('isEnabled') === true}
      onChange={(enabled) => {
        model.set('isEnabled', enabled);
        if (!enabled) {
          model.set('label_metadata', []);
          model.set('labelBasedAccess', false);
        }
      }}
      data-testid="permIsEnabled"
    />
  </Box>
));

const RbacLabelBasedAccessSwitch = observer(({ model }) => {
  const valueRenderer = (option) => option?.label || null;

  if (model.get('labelFilter')) {
    return (
      <Select
        options={[
          {
            label: 'Full Access',
            value: false
          },
          {
            label: 'Label Access',
            value: true
          }
        ]}
        width={128}
        menuWidth={110}
        disabled={!model.get('isEnabled')}
        values={model?.isLabelBasedAccess}
        onChange={(val) => model.setLabelBasedAccess(val)}
        minimal
        valueRenderer={valueRenderer}
        dataTestId="permIsLabelBasedAccess"
      />
    );
  }
  return null;
});

const RbacLabelMetadataRenderer = observer(({ model }) => {
  const labelBasedAccessMode = model.isLabelBasedAccess;

  // Only updates the collection as it is the source of truth PRIOR to SAVE
  const handleRemoveLabel = (permModel, removedId) => {
    let selectedList = permModel.get('label_metadata');

    selectedList = selectedList.filter((selectedId) => selectedId !== removedId);

    permModel.set('label_metadata', selectedList);
  };

  const handleLabelSelect = (values) => {
    model.set('label_metadata', values);
  };

  const labelSelectorElement = (
    <Flex alignItems="center" ml={3}>
      <Box mr={1}>with</Box>
      <Box flex={1}>
        <LabelSelectorList
          onRemove={(removeId) => {
            handleRemoveLabel(model, removeId);
          }}
          useDialog
          useAddButton
          handleLabelSelect={handleLabelSelect}
          selectedList={model.get('label_metadata') || []}
        />
      </Box>
    </Flex>
  );

  return (
    <Flex flexDirection="column" data-testid="permDetails">
      <Flex>{model.get('display')}</Flex>
      {labelBasedAccessMode === true && labelSelectorElement}
    </Flex>
  );
});

// row height without labels
const ROW_HEIGHT = 24;

const TAG_ROW_HEIGHT = 20;

const TAG_AREA_PADDING = 8;

// row height addition for labels
const EXAPANDED_ROW_HEIGHT = 4 + TAG_ROW_HEIGHT + 2 * TAG_AREA_PADDING;

@inject('$rbac')
@observer
class RbacRolePermissions extends React.Component {
  get columns() {
    const { viewOnly } = this.props;
    const columns = [
      {
        name: 'isEnabled',
        alignItems: 'flex-start',
        renderer: ({ model }) => <RbacIsEnabled model={model} disabled={viewOnly} />,
        minWidth: 60,
        width: 60,
        verticalAlign: 'center'
      },
      {
        flexBasis: 100,
        ellipsis: false,
        name: 'label_metadata',
        computed: true,
        renderer: ({ model }) => <RbacLabelMetadataRenderer model={model} />
      },
      {
        name: 'isLabelBasedAccess',
        width: 160,
        alignItems: 'flex-start',
        computed: true,
        referencedFields: ['isLabelBasedAccess', 'labelBasedAccess', 'isEnabled'],
        renderer: ({ model }) => <RbacLabelBasedAccessSwitch model={model} />
      }
    ];
    return columns;
  }

  groupSummaryLookupFn = ({ groupKey }) => (
    <Box>
      <Text>{groupKey}</Text>
    </Box>
  );

  rowHeight = (args) => {
    const { model: permModel } = args;
    if (permModel && permModel.id) {
      if (permModel.isLabelBasedAccess === true) {
        return EXAPANDED_ROW_HEIGHT + ROW_HEIGHT;
      }
      return ROW_HEIGHT;
    }
    // This only applies to group names so we could make it a different height
    return ROW_HEIGHT;
  };

  render() {
    const { collection } = this.props;

    return (
      <Flex flexDirection="column" flex={1} mt={1} px={2} overflow="auto" data-testid="AdminTableFlex">
        <AdminTable
          collection={collection}
          columns={this.columns}
          groupSummaryLookup={this.groupSummaryLookupFn}
          hideHeader
          deepCompareOnUpdate
          multiSelect={false}
          fetchCollectionOnMount={false}
          rowHeight={this.rowHeight}
          rowAlign="center"
          flexed
          selectOnRowClick={false}
          noVirtualizedTable
          containerProps={{ overflow: 'auto' }}
          tableContainerProps={{ overflow: 'auto' }}
          filterSidebar={<RbacRoleFilterSidebar collection={collection} />}
        />
      </Flex>
    );
  }
}
export default RbacRolePermissions;
