import { get } from 'lodash';

/**
 * requiredIfAny:anotherfield
 * The field under validation must be present and not empty if the `anotherfield` field is equal to any value.`
 * It is expected that the `anotherfield` field value is an array or string.
 */
function requiredIfAny(val) {
  const req = this.getParameters();
  const otherVal = get(this.validator.input, req[0]);

  if (otherVal?.length !== 0) {
    return this.validator.getRule('required').validate(val);
  }

  return true;
}

export default {
  message: ':attribute is required',
  fn: requiredIfAny
};
