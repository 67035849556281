import React from 'react';
import { Box, Flex, Link, Text } from 'core/components';
import { capitalize } from 'lodash';
import ResourceItem from './ResourceItem';
import { getMapClassname } from '../../utils/map';

function ParentItem({ item, type, to }) {
  return (
    <Flex
      gap={18}
      className={getMapClassname({ type, value: item.id })}
      border="thinLighter"
      borderRadius={4}
      p="8px 12px 8px 8px"
      minHeight={72}
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex gap={1} alignItems="center" overflow="hidden">
        <Box>
          <svg display="block" width={54} height={54} pointerEvents="none">
            <g transform="translate(27, 27)">
              <ResourceItem id={item.id} type={type} className={item.health?.cssClassName} />
            </g>
          </svg>
        </Box>
        <Box overflow="hidden">
          <Text muted>{capitalize(type.replace(/s$/, ''))}</Text>
          <Text ellipsis as="div">
            {item.name}
          </Text>
        </Box>
      </Flex>
      <Link to={to} small>
        Change
      </Link>
    </Flex>
  );
}

export default ParentItem;
