import { AZURE_ENTITY_TYPES, GCP_ENTITY_TYPES, OCI_ENTITY_TYPES, AWS_ENTITY_TYPES } from 'shared/hybrid/constants';
import { getMapClassname } from 'app/views/hybrid/utils/map';
import {
  FLEX_GAP,
  ICON_SIZE,
  LINK_SPACING,
  GRID_ROWS_GAP,
  ON_PREM_BOX_PADDING,
  DIRECT_CONNECT_WIDTH,
  DIRECT_CONNECT_HEIGHT
} from 'app/views/hybrid/utils/cloud/constants';

// OCI
import IpSecConnector from './oci/IpSecConnector';

import DrgConnector from './oci/DrgConnector';
import RegionConnector from './RegionConnector';
import SubnetConnector from './SubnetConnector';
import VNETConnector from './azure/VNETConnector';
import GatewayConnector from './GatewayConnector';
import VpnGatewayConnector from './VpnGatewayConnector';
import InternetBoxConnector from './InternetBoxConnector';
import VpcEndpointConnector from './VpcEndpointConnector';
import VirtualHubConnector from './azure/VirtualHubConnector';
import VPNConnectionConnector from './VPNConnectionConnector';
import OnPremGatewayConnector from './OnPremGatewayConnector';
import CustomerRouterConnector from './CustomerRouterConnector';
import VNETPeeringConnector from './azure/VNETPeeringConnector';
import DirectConnectionConnector from './DirectConnectionConnector';
import VpcPeeringConnectionConnector from './VpcPeeringConnectionConnector';
import TransitGatewayAttachmentConnector from './TransitGatewayAttachmentConnector';

const connectorFactory = ({ sourceNode, targetNode, iconSize, ...rest }) => {
  const { source, target, sameTypeGap, onPremBoxPadding, gridRowsGap } = rest;
  const { type: sourceType, value: sourceValue } = sourceNode;
  const { type: targetType, value: targetValue } = targetNode;
  const { points: sourcePoints } = source;
  const { points: targetPoints } = target;

  const sourceClassName = getMapClassname({ type: sourceType, value: sourceValue });
  const targetClassName = getMapClassname({ type: targetType, value: targetValue });

  const sourceNodeClientRect = document.querySelector(`.${sourceClassName}`)?.getBoundingClientRect();
  const targetNodeClientRect = document.querySelector(`.${targetClassName}`)?.getBoundingClientRect();

  const sourceNodeWidth = sourceNodeClientRect?.width || iconSize;
  const sourceNodeHeight = sourceNodeClientRect?.height || iconSize;

  const targetNodeWidth = targetNodeClientRect?.width || iconSize;
  const targetNodeHeight = targetNodeClientRect?.height || iconSize;

  const maxNodeHeight = Math.max(sourceNodeHeight, targetNodeHeight);

  // we calculate min, because we want to use minimal spacing - example lines from regions should have small spacing
  const nodeWidth = Math.min(sourceNodeWidth, targetNodeWidth);
  const maxNodeWidth = Math.max(sourceNodeWidth, targetNodeWidth);
  const nodeHeight = Math.min(sourceNodeHeight, targetNodeHeight);

  const sourcePoint = sourcePoints[sourcePoints.length - 1]; // center point
  const targetPoint = targetPoints[targetPoints.length - 1]; // center point

  const xDirectionModifier = sourcePoint[0] > targetPoint[0] ? -1 : 1;
  const yDirectionModifier = targetPoint[1] > sourcePoint[1] ? 1 : -1; // target below source
  const xSpacing = Math.floor(nodeWidth / 2 + sameTypeGap / 2) + 4;
  const ySpacing = Math.floor((gridRowsGap + onPremBoxPadding + nodeHeight) / 2);

  /** Extra precalculated props */
  const passThroughProps = {
    ...rest,
    nodeWidth,
    sourceType,
    nodeHeight,
    targetType,
    sourceNode,
    targetNode,
    sourcePoint,
    targetPoint,
    xDirectionModifier,
    yDirectionModifier,
    xSpacing,
    ySpacing,
    sourceNodeWidth,
    targetNodeWidth,
    sourceNodeHeight,
    targetNodeHeight,
    maxNodeHeight,
    maxNodeWidth
  };

  switch (sourceType) {
    case 'VpnGateways':
      return VpnGatewayConnector({ ...passThroughProps });
    case 'gateway':
    case 'VirtualGateways':
    case 'InternetGateways':
    case AWS_ENTITY_TYPES.TRANSIT_GATEWAY:
    case AWS_ENTITY_TYPES.CORE_NETWORK_EDGE:
      return GatewayConnector({ ...passThroughProps });

    case OCI_ENTITY_TYPES.DYNAMIC_ROUTING_GATEWAY:
      return DrgConnector({ ...passThroughProps });

    case 'subnet':
    case AWS_ENTITY_TYPES.SUBNET:
    case OCI_ENTITY_TYPES.SUBNET:
    case AZURE_ENTITY_TYPES.SUBNET:
      return SubnetConnector({ ...passThroughProps });

    case 'region':
    case AZURE_ENTITY_TYPES.LOCATION:
      return RegionConnector({ ...passThroughProps });

    case AWS_ENTITY_TYPES.VPC_ENDPOINT:
      return VpcEndpointConnector({ ...passThroughProps });

    case 'box':
    case 'internet':
      return InternetBoxConnector({ ...passThroughProps });

    case 'VpnConnections':
    case AZURE_ENTITY_TYPES.VPN_LINK_CONNECTION:
    case AZURE_ENTITY_TYPES.VNET_GATEWAY_CONNECTION:
    case AZURE_ENTITY_TYPES.EXPRESS_ROUTE_CONNECTION:
    case GCP_ENTITY_TYPES.INTERCONNECT:
      return VPNConnectionConnector({ ...passThroughProps });

    case 'Routers':
    case AZURE_ENTITY_TYPES.ROUTER:
    case AZURE_ENTITY_TYPES.VPN_SITE:
    case AZURE_ENTITY_TYPES.LOCAL_NETWORK_GATEWAY:
    case GCP_ENTITY_TYPES.EXTERNAL_VPN_GATEWAY:
    case GCP_ENTITY_TYPES.INTERCONNECT_ATTACHMENT:
    case GCP_ENTITY_TYPES.VPN_TUNNEL:
    case GCP_ENTITY_TYPES.VPN_GATEWAY:
      return CustomerRouterConnector({ ...passThroughProps });

    case 'CustomerGateways':
      return OnPremGatewayConnector({ ...passThroughProps });

    case AWS_ENTITY_TYPES.DIRECT_CONNECTION:
    case AWS_ENTITY_TYPES.DIRECT_CONNECTION_GATEWAY:
      return DirectConnectionConnector({ ...passThroughProps });

    case AWS_ENTITY_TYPES.VPC_PEERING_CONNECTION:
    case OCI_ENTITY_TYPES.LOCAL_PEERING_GATEWAY:
      return VpcPeeringConnectionConnector({ ...passThroughProps });

    case AWS_ENTITY_TYPES.TRANSIT_GATEWAY_ATTACHMENT:
      return TransitGatewayAttachmentConnector({ ...passThroughProps });

    case AZURE_ENTITY_TYPES.VIRTUAL_HUB:
    case AZURE_ENTITY_TYPES.VPN_GATEWAY:
    case AZURE_ENTITY_TYPES.EXPRESS_ROUTE_GATEWAY:
    case AZURE_ENTITY_TYPES.HUB_VIRTUAL_NETWORK_CONNECTION:
      return VirtualHubConnector({ ...passThroughProps });

    case AZURE_ENTITY_TYPES.VNET:
      return VNETConnector({ ...passThroughProps });

    case AZURE_ENTITY_TYPES.VNET_PEERING:
      return VNETPeeringConnector({ ...passThroughProps });

    // OCI
    case OCI_ENTITY_TYPES.IP_SEC_CONNECTION:
      return IpSecConnector({ ...passThroughProps });
    default:
      break;
  }

  return null;
};

const checkOppositeConnector = ({ source, target, sourceNode, targetNode, ...rest }) => {
  // if opposite connector exists, return it
  const factoryResult = connectorFactory({
    sourceNode: targetNode,
    targetNode: sourceNode,
    source: target,
    target: source,
    ...rest,
    isOpposite: true
  });

  if (factoryResult !== null) {
    const { sourceAnchor, targetAnchor, connectionPoints } = factoryResult;
    // reverse direction as its opposite
    return { sourceAnchor: targetAnchor, targetAnchor: sourceAnchor, connectionPoints: connectionPoints.reverse() };
  }

  return null;
};

/**
 * Factory to connect 2 nodes
 * @param {
 *   sourceNode: {
 *    value: string,
 *    type: string
 *   };
 *   targetNode: {
 *    value: string,
 *    type: string
 *   };
 *   iconSize: number;
 *   source: object;
 *   target: object;
 *   sameTypeGap: number,
 *   gridRowsGap: number,
 *   regionRight: number,
 *   linkSpacing: number,
 *   onPremBoxPadding: number
 *   isOpposite: ?boolean;
 *   connectionType: 'square' | 'curved';
 *  }
 * }
 * @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const CloudMapConnector = ({
  linkSpacing = LINK_SPACING,
  directConnectWidth = DIRECT_CONNECT_WIDTH,
  directConnectHeight = DIRECT_CONNECT_HEIGHT,
  iconSize = ICON_SIZE,
  gridRowsGap = GRID_ROWS_GAP.toPoints(),
  sameTypeGap = FLEX_GAP.toPoints(),
  onPremBoxPadding = ON_PREM_BOX_PADDING.toPoints(),
  ...rest
}) => {
  const connectorArgs = {
    directConnectWidth,
    directConnectHeight,
    gridRowsGap,
    iconSize,
    linkSpacing,
    onPremBoxPadding,
    sameTypeGap,
    ...rest
  };

  // check if source to target helper exists
  const connector = connectorFactory(connectorArgs);

  if (connector !== null) {
    return connector;
  }

  // check if target to source connector helper exists or return null
  return checkOppositeConnector(connectorArgs);
};

export default CloudMapConnector;
