import { toIp, isInSubnet } from 'core/util/ip';
import { get, uniqWith, isEqual } from 'lodash';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { idToType, getCoreNetworkAttachmentsForVpcId } from './aws';

function sortRoutes(a, b) {
  if (a.matchingDestination.subnetMask === b.matchingDestination.subnetMask) {
    return b.priority - a.priority;
  }

  return b.matchingDestination.subnetMask - a.matchingDestination.subnetMask;
}

const { SUBNET, CORE_NETWORK_EDGE, CORE_NETWORK_ATTACHMENT } = ENTITY_TYPES.get('aws');

function getRouteTablesForDestination(entities, type, destination) {
  const destIp = toIp(destination);

  return Object.values(entities[type] || [])
    .map((routeTable) => {
      const { Routes = [] } = routeTable;
      const matchingRoutes = Routes.map((route) => {
        let destinations = [];
        let priority = 0;

        if (route.DestinationPrefixListId) {
          const prefixList = entities.PrefixLists[route.DestinationPrefixListId];
          destinations.push(...(prefixList?.Cidrs || []));
          priority = 2;
        } else {
          destinations.push(route.DestinationIpv6CidrBlock || route.DestinationCidrBlock);
          priority = route.Origin?.includes('Propagation') || route.Type === 'propagated' ? 1 : 3;
        }

        destinations = destinations
          .map((dest) => toIp(dest))
          // toIp will return null when ip is not valid, see isIpV4Valid in src/core/util/ip.js
          .filter((dest) => dest && dest?.v4 === destIp?.v4)
          .sort((a, b) => b.subnetMask - a.subnetMask);

        const matchingDestination = destinations.find(
          (dest) => destIp.isInSubnet(dest) || destIp.addressMinusSuffix === dest.addressMinusSuffix
        );

        return { ...route, matchingDestination, priority };
      })
        .filter((route) => route.matchingDestination)
        .sort(sortRoutes);

      return { ...routeTable, matchingRoute: matchingRoutes[0] };
    })
    .filter((routeTable) => !!routeTable.matchingRoute);
}

function getRouteTableForGateway(routeTables, gatewayId) {
  return routeTables.find((routeTable) =>
    (routeTable.Associations || []).some((assoc) => assoc.GatewayId === gatewayId)
  );
}

function getMatchingSubnets(entities, vpcId, destination) {
  const destIp = toIp(destination);

  return Object.values(entities.Subnets || []).filter((subnet) => {
    if (subnet.VpcId === vpcId) {
      if (destIp.v4) {
        const ip = toIp(subnet.CidrBlock);
        return destIp.isInSubnet(ip) || ip.isInSubnet(destIp);
      }

      return (subnet.Ipv6CidrBlockAssociationSet || []).some((set) => {
        const ip = toIp(set.Ipv6CidrBlock);
        return destIp.isInSubnet(ip) || ip.isInSubnet(destIp);
      });
    }

    return false;
  });
}

function getDeviceByIp(entities, ipAddr) {
  const ip = toIp(ipAddr);

  for (const router of Object.values(entities.Routers || [])) {
    const { device_id, children } = router;

    for (const intf of Object.values(children)) {
      if (intf.interface_cidr && ip.isInSubnet(toIp(intf.interface_cidr))) {
        return device_id;
      }
    }
  }

  return -1;
}

function getRouteNode(route) {
  const keys = Object.keys(route);
  const idKey = keys.find((key) => key.endsWith('Id') && !key.includes('Destination'));
  const value = route[idKey];
  const type = idToType(value) || idKey.replace(/Id$/, 's');
  return { type, value };
}

function getRouteTablesAssociatedToSubnet(routeTables, subnet) {
  const vpcRouteTables = routeTables.filter((routeTable) => routeTable.VpcId === subnet.VpcId);
  return vpcRouteTables
    .filter((routeTable) => {
      const { Associations } = routeTable;
      return (Associations || []).some((assoc) => assoc.SubnetId === subnet.SubnetId || assoc.Main);
    })
    .sort((routeTable) =>
      // main route tables should not take priority
      routeTable.main === true ? 1 : -1
    );
}

/**
 * will calculate next hop for cloud path calculation from subnet
 *
 * if subnets are in the same VPC, we should find a route to dest subnet in that VPC
 *
 * if subnets source and destination subnets are in different VPCS:
 *  : can be connected through TGW
 *  : can be connected through VPC Peering
 */
function getNextPathForSubnet(entities, subnetId, destinationIp, routeTablesForDestination) {
  const sourceSubnet = entities.Subnets[subnetId];
  const sourceSubnetVpc = get(entities, `Vpcs[${sourceSubnet.VpcId}]`);

  if (!sourceSubnet) {
    // invalid subnet
    return null;
  }

  // if destination in subnet, we reached our destination
  if (isInSubnet(destinationIp, sourceSubnet.CidrBlock)) {
    return null;
  }

  if (destinationIp === sourceSubnetVpc.CidrBlock) {
    return [{ type: 'Vpcs', value: sourceSubnetVpc.VpcId }];
  }

  const subnetRouteTables = getRouteTablesAssociatedToSubnet(routeTablesForDestination, sourceSubnet);

  for (const subnetRouteTable of subnetRouteTables) {
    // traffic goes to internet, route it through subnet that has NAT attachment
    if (subnetRouteTable.matchingRoute.NatGatewayId) {
      const natGateway = entities.NatGateways[subnetRouteTable.matchingRoute.NatGatewayId];
      if (natGateway) {
        return [
          { type: 'NatGateways', value: natGateway.id },
          { type: 'Subnets', value: natGateway.SubnetId }
        ];
      }
    }

    // subnets are in different VPCs
    if (subnetRouteTable.matchingRoute.TransitGatewayId) {
      const attachment = Object.values(entities.TransitGatewayAttachments || []).find(
        (a) =>
          a.TransitGatewayId === subnetRouteTable.matchingRoute.TransitGatewayId && a.ResourceId === sourceSubnet.VpcId
      );

      if (!attachment) {
        return null;
      }

      // route through subnet that have TGW attachment interface attached to
      const attachmentSubnets = Object.values(attachment?.NetworkInterfaces ?? {})
        .filter(
          (eni) =>
            // ensure eni attached in subnet in the same vpc
            eni.VpcId === sourceSubnet.VpcId
        )
        .map((eni) => eni.SubnetId)
        // sort by AZ to get the closes
        .sort((subnetAId, subnetBId) => {
          const subnetA = get(entities, `Subnets.${subnetAId}`);
          const subnetB = get(entities, `Subnets.${subnetBId}`);

          if (subnetA.AvailabilityZoneId === sourceSubnet.AvailabilityZoneId) {
            return -1;
          }
          if (subnetB.AvailabilityZoneId === sourceSubnet.AvailabilityZoneId) {
            return 1;
          }

          return 0;
        });

      const subnetPath = [];
      // do not route through itself
      if (attachmentSubnets[0] !== subnetId) {
        subnetPath.push({ type: 'Subnets', value: attachmentSubnets[0] });
      }

      return [
        { type: 'TransitGateways', value: attachment.TransitGatewayId },
        { type: 'TransitGatewayAttachments', value: attachment.TransitGatewayAttachmentId },
        ...subnetPath
      ];
    }

    if (subnetRouteTable.matchingRoute.VpcPeeringConnectionId) {
      const peeringConnection = entities.VpcPeeringConnections[subnetRouteTable.matchingRoute.VpcPeeringConnectionId];

      if (!peeringConnection) {
        // blackhole peering connection
        return null;
      }

      const { VpcPeeringConnectionId, AccepterVpcInfo, RequesterVpcInfo } = peeringConnection;
      const vpcInfos =
        sourceSubnet.VpcId === AccepterVpcInfo.VpcId
          ? [AccepterVpcInfo, RequesterVpcInfo]
          : [RequesterVpcInfo, AccepterVpcInfo];

      return [
        { type: 'Vpcs', value: vpcInfos[1].VpcId, VpcPeeringConnectionId },
        { type: 'VpcPeeringConnections', value: VpcPeeringConnectionId, VpcId: vpcInfos[0].VpcId }
      ];
    }

    if (subnetRouteTable.matchingRoute.coreNetworkId) {
      const subnetEntity = get(entities, `${SUBNET}.${subnetId}`);
      // find subnet within same vpc that has core network attachments
      const vpcCoreNetworkAttachments = getCoreNetworkAttachmentsForVpcId(entities, subnetEntity.VpcId);

      if (!vpcCoreNetworkAttachments?.length) {
        return null;
      }

      const coreNetworkAttachmentId = vpcCoreNetworkAttachments[0].id;
      const coreNetworkAttachment = get(entities, `${CORE_NETWORK_ATTACHMENT}.${coreNetworkAttachmentId}`);

      const coreNetworkEdge = Object.values(entities?.[CORE_NETWORK_EDGE] ?? {}).find(
        (edge) =>
          edge.EdgeLocation === coreNetworkAttachment.EdgeLocation &&
          edge.CoreNetworkId === coreNetworkAttachment.CoreNetworkId
      );

      const subnetPath = [
        { type: CORE_NETWORK_EDGE, value: coreNetworkEdge?.id },
        { type: CORE_NETWORK_ATTACHMENT, value: coreNetworkAttachmentId },
        { type: SUBNET, value: coreNetworkAttachment.SubnetId }
      ];

      const matchingSpecificRoute = subnetRouteTable.matchingRoute.moreSpecificRoutes.find(
        (r) =>
          isInSubnet(destinationIp, r.DestinationCidrBlock) &&
          r.Destinations.some((d) => d?.peeringAttachmentId ?? d?.CoreNetworkAttachmentId)
      );

      if (matchingSpecificRoute) {
        const matchingSpecificDestination = matchingSpecificRoute?.Destinations?.[0];

        if (!matchingSpecificDestination) {
          return subnetPath;
        }

        const peeringCoreNetworkAttachmentId =
          matchingSpecificDestination?.peeringAttachmentId ?? matchingSpecificDestination.CoreNetworkAttachmentId;

        const peeringCoreNetworkAttachment = get(
          entities,
          `${CORE_NETWORK_ATTACHMENT}.${peeringCoreNetworkAttachmentId}`
        );
        const peeringCoreNetworkEdge = Object.values(entities?.[CORE_NETWORK_EDGE] ?? {}).find(
          (edge) =>
            edge.EdgeLocation === peeringCoreNetworkAttachment.EdgeLocation &&
            edge.CoreNetworkId === peeringCoreNetworkAttachment.CoreNetworkId
        );
        return [
          { type: SUBNET, value: matchingSpecificDestination.SubnetId },
          { type: CORE_NETWORK_ATTACHMENT, value: peeringCoreNetworkAttachment.id },
          { type: CORE_NETWORK_EDGE, value: peeringCoreNetworkEdge.id },
          ...subnetPath
        ];
      }

      return subnetPath;
    }

    if (subnetRouteTable.matchingRoute.GatewayId) {
      return [getRouteNode(subnetRouteTable.matchingRoute)];
    }
  }

  /**
   * Because we are looping for a route to TGW or VPC Peering (because dest subnet is on Other VPC)
   * we need to make sure there is local route, then we can find any other subnets
   * that has connection to TGW or VpcPeering
   */
  const originalSubnetRouteTables = getRouteTablesAssociatedToSubnet(Object.values(entities.RouteTables), sourceSubnet);

  // if has local route, it can connect to any subnet in the same vpc
  const hasLocalRoute = originalSubnetRouteTables.some((subnetRouteTable) =>
    subnetRouteTable.Routes.some((route) => route.GatewayId === 'local')
  );

  if (!hasLocalRoute) {
    return null;
  }

  const subnetsWithRouteToDestination = routeTablesForDestination
    .filter(
      (routeTable) =>
        routeTable.VpcId === sourceSubnet.VpcId &&
        idToType(routeTable.matchingRoute?.NatGatewayId ?? '') !== 'NatGateways' &&
        idToType(routeTable.matchingRoute?.GatewayId ?? '') !== 'InternetGateways'
    )
    // get all subnets that route table is associated to
    .map(({ Associations }) => Associations.map(({ SubnetId }) => SubnetId))
    // flatten because associations are an array
    .flat(2)
    // filter nulls and undefined
    .filter((value) => value);

  const uniqueSubnetsWithRouteToDestination = [...new Set(subnetsWithRouteToDestination)]
    // filter out current subnet
    .filter((uniqueSubnetId) => uniqueSubnetId !== sourceSubnet.id)
    // keep subnets in same AZ in the beginning
    .sort((subnetAId, subnetBId) => {
      const subnetA = get(entities, `Subnets.${subnetAId}`);
      const subnetB = get(entities, `Subnets.${subnetBId}`);

      if (subnetA.AvailabilityZoneId === sourceSubnet.AvailabilityZoneId) {
        return -1;
      }
      if (subnetB.AvailabilityZoneId === sourceSubnet.AvailabilityZoneId) {
        return 1;
      }

      return 0;
    });

  if (uniqueSubnetsWithRouteToDestination.length > 0) {
    return [{ type: 'Subnets', value: uniqueSubnetsWithRouteToDestination[0] }];
  }

  // to NAT gateway or IGW
  if (subnetRouteTables.length > 0) {
    return [getRouteNode(subnetRouteTables[0].matchingRoute)];
  }

  return null;
}

// will calculate next hop for cloud path calculation from transit gateway
function getNextPathForTGW(entities, prevPathNode, transitGatewayRouteTables, lastNodeValue) {
  let attachment = null;

  if (prevPathNode.type === 'TransitGatewayAttachments') {
    const prevPathAttachment = get(entities, `TransitGatewayAttachments[${prevPathNode.value}]`);
    const transitGatewayRouteTable = get(
      entities,
      `TransitGatewayRouteTables.${prevPathAttachment?.Association?.TransitGatewayRouteTableId}`
    );

    if (!transitGatewayRouteTable) {
      return null;
    }

    const tgwRouteTables = transitGatewayRouteTables
      .filter((table) => table.id === transitGatewayRouteTable.id)
      .sort((a, b) => sortRoutes(a.matchingRoute, b.matchingRoute));
    [attachment] = tgwRouteTables[0]?.matchingRoute?.TransitGatewayAttachments ?? [null];
  }

  // prev node can be transit gateway peering type
  if (prevPathNode.type === 'TransitGateways') {
    const tgwRouteTables = transitGatewayRouteTables
      .filter((table) => table.TransitGatewayId === lastNodeValue)
      .sort((a, b) => sortRoutes(a.matchingRoute, b.matchingRoute));
    [attachment] = tgwRouteTables[0]?.matchingRoute?.TransitGatewayAttachments ?? [null];
  }

  if (!attachment) {
    return null;
  }

  if (attachment.ResourceType === 'direct-connect-gateway') {
    return [{ type: 'DirectConnectGateways', value: attachment.ResourceId }];
  }

  if (attachment.ResourceType === 'peering') {
    return [{ type: 'TransitGateways', value: attachment.ResourceId }];
  }

  if (attachment.ResourceType === 'vpc') {
    const attachmentEnis = get(
      entities,
      `TransitGatewayAttachments.${attachment.TransitGatewayAttachmentId}.NetworkInterfaces`
    );

    const path = [
      { type: 'Vpcs', value: attachment.ResourceId },
      { type: 'TransitGatewayAttachments', value: attachment.TransitGatewayAttachmentId }
    ];

    if (attachmentEnis) {
      const attachedEni = Object.values(attachmentEnis)[0];
      return [{ type: 'Subnets', value: attachedEni.SubnetId }, ...path];
    }

    return path;
  }

  if (attachment.ResourceType === 'vpn') {
    return [{ type: 'VpnConnections', value: attachment.ResourceId.replace(/\(.+\)$/, '') }];
  }

  if (attachment.ResourceType === 'connect') {
    const connectPeerId = attachment.ResourceId.replace(/\(.+\)$/, '');
    const attachmentId = attachment.TransitGatewayAttachmentId;
    const transportId = entities.TransitGatewayConnects[attachmentId]?.TransportTransitGatewayAttachmentId;
    const peerAddress = entities.TransitGatewayConnectPeers[connectPeerId]?.ConnectPeerConfiguration.PeerAddress;
    const targetAttachment = entities.TransitGatewayAttachments[transportId];

    if (!targetAttachment) {
      console.warn(`TransitGatewayAttachment with ID '${transportId}' not found`);
      return null;
    }

    if (targetAttachment.ResourceType === 'vpc') {
      const subnets = getMatchingSubnets(entities, targetAttachment.ResourceId, peerAddress);

      const path = [
        { type: 'Vpcs', value: targetAttachment.ResourceId },
        { type: 'TransitGatewayAttachments', value: targetAttachment.TransitGatewayAttachmentId }
      ];

      if (subnets.length > 0) {
        return [{ type: 'Subnets', value: subnets.map((subnet) => subnet.SubnetId) }, ...path];
      }
      return path;
    }

    if (targetAttachment.ResourceType === 'direct-connect-gateway') {
      const { Attachments } = entities.DirectConnectGateways[targetAttachment.ResourceId];
      const virtualInterface = Attachments.map((dcgwa) => entities.VirtualInterfaces[dcgwa.virtualInterfaceId]).find(
        (vif) => vif && isInSubnet(peerAddress, vif.CustomerAddress)
      );

      const path = [{ type: 'DirectConnectGateways', value: targetAttachment.ResourceId }];

      if (virtualInterface) {
        return [
          { type: 'Routers', value: getDeviceByIp(entities, virtualInterface.CustomerAddress) },
          { type: idToType(virtualInterface.ConnectionId), value: virtualInterface.ConnectionId },
          ...path
        ];
      }

      return path;
    }

    console.warn(`Unexpected '${targetAttachment.ResourceType}' type`);
    return null;
  }

  return [
    {
      type: idToType(attachment.ResourceId) || `Unknown (${attachment.ResourceType})`,
      value: attachment.ResourceId
    }
  ];
}

// will calculate next hop for cloud path calculation from direct connect gateway
function getNextPathForDirectConnectGateway(entities, directGatewayId) {
  const { Attachments } = entities.DirectConnectGateways[directGatewayId];
  const interfaces = Attachments.map((attachment) => entities.VirtualInterfaces[attachment.VirtualInterfaceId]).filter(
    Boolean
  );

  if (interfaces.length > 0) {
    return [
      { type: 'Routers', value: interfaces.map((vif) => getDeviceByIp(entities, vif.CustomerAddress)) },
      { type: idToType(interfaces[0].ConnectionId), value: interfaces.map((vif) => vif.ConnectionId) }
    ];
  }

  return null;
}

// will calculate next hop for cloud path calculation from internet gateway
function getNextPathForInternetGateway(internetGatewayId, routeTables) {
  const gatewayRouteTable = getRouteTableForGateway(routeTables, internetGatewayId);

  if (gatewayRouteTable) {
    return [getRouteNode(gatewayRouteTable.matchingRoute)];
  }

  return null;
}

function getNextPathForVpc(entities, vpcId, destinationIp, prevPathNode) {
  const subnets = getMatchingSubnets(entities, vpcId, destinationIp);

  if (subnets.length > 0) {
    return [
      {
        type: 'Subnets',
        value: subnets.map((subnet) => subnet.SubnetId).filter((subnetId) => subnetId !== prevPathNode.value)
      }
    ];
  }

  return null;
}

function getNextPathForVpnConnection(entities, vpnConnectionId) {
  const vpn = entities.VpnConnections[vpnConnectionId];
  return [{ type: 'CustomerGateways', value: vpn.CustomerGatewayId }];
}

function getNextPathForVpnGateways(entities, vpnGatewayId, routeTables) {
  const gatewayRouteTable = getRouteTableForGateway(routeTables, vpnGatewayId);

  if (gatewayRouteTable) {
    return [getRouteNode(gatewayRouteTable.matchingRoute)];
  }

  const connections = Object.values(entities.VpnConnections || []).filter(
    (vpnc) => vpnc.VpnGatewayId === vpnGatewayId && vpnc.State === 'available'
  );

  if (connections.length > 0) {
    return [
      { type: 'CustomerGateways', value: connections.map((vpnc) => vpnc.CustomerGatewayId) },
      { type: 'VpnConnections', value: connections.map((vpnc) => vpnc.VpnConnectionId) }
    ];
  }

  const interfaces = Object.values(entities.VirtualInterfaces || []).filter(
    (vif) => vif.VirtualGatewayId === vpnGatewayId
  );

  if (interfaces.length > 0) {
    return [
      {
        type: 'Routers',
        value: interfaces.map((vif) => getDeviceByIp(entities, vif.CustomerAddress))
      },
      {
        type: idToType(interfaces[0].ConnectionId),
        value: interfaces.map((vif) => vif.ConnectionId)
      }
    ];
  }

  return null;
}

/** will calculate path between 2 subnet entities */
export function getCloudPath(entities, sourceSubnetId, destinationIp) {
  const routeTables = getRouteTablesForDestination(entities, 'RouteTables', destinationIp);
  const transitGatewayRouteTables = getRouteTablesForDestination(entities, 'TransitGatewayRouteTables', destinationIp);

  let path = [{ type: 'Subnets', value: sourceSubnetId }];
  let iterationCount = 0; // prevent infinite loops

  let shouldExit = false;
  while (iterationCount < 50) {
    const lastNode = path[0];

    // most likely our path ended in VPC
    if (Array.isArray(lastNode.value)) {
      break;
    }

    let nextPathNodes = null;
    switch (lastNode.type) {
      case 'Subnets':
        nextPathNodes = getNextPathForSubnet(entities, lastNode.value, destinationIp, routeTables);
        break;
      case 'Vpcs':
        nextPathNodes = getNextPathForVpc(entities, lastNode.value, destinationIp, path[1]);
        shouldExit = true;
        break;
      case 'TransitGateways':
        nextPathNodes = getNextPathForTGW(entities, path[1], transitGatewayRouteTables, lastNode.value);
        break;
      case 'DirectConnectGateways':
        nextPathNodes = getNextPathForDirectConnectGateway(entities, lastNode.value, destinationIp);
        break;
      case 'InternetGateways':
        nextPathNodes = getNextPathForInternetGateway(lastNode.value, routeTables);
        break;
      case 'VpnConnections':
        nextPathNodes = getNextPathForVpnConnection(entities, lastNode.value, destinationIp);
        break;
      case 'VpnGateways':
        nextPathNodes = getNextPathForVpnGateways(entities, lastNode.value, routeTables);
        break;
      case 'VpcEndpoints':
        shouldExit = true;
        break;
      default:
        console.warn(`Unexpected node type: ${lastNode.type}`);
    }

    if (nextPathNodes === null) {
      break;
    }

    path = [...nextPathNodes, ...path];

    if (shouldExit) {
      break;
    }

    iterationCount += 1;
  }

  return uniqWith(path, isEqual).reverse();
}
