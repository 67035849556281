import * as React from 'react';
import { themeGet } from 'styled-system';
import styled from 'styled-components';
import { lighten } from 'polished';
import { get } from 'lodash';
import { formatBytesGreek } from 'core/util';
import { Path, Rect, Text as SVGText } from 'app/components/svg';
import { CloudItemRect, CloudItemInner } from 'app/views/hybrid/maps/components/CloudItem';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { getEntityType } from 'app/views/hybrid/utils/map';

const MiniRegion = styled(Rect)`
  @keyframes glowing {
    0% {
      stroke: ${themeGet('colors.gcp.green')};
      opacity: 1;
    }
    50% {
      stroke: ${themeGet('colors.gcp.green')};
      opacity: 0.5;
    }
  }

  &.flashing {
    animation: glowing 750ms linear infinite;
  }

  cursor: pointer;
  fill: ${(props) => lighten(0.2, props.theme.colors.gcp.green)};

  &:hover,
  &.hover {
    stroke: ${(props) => props.theme.colors.gcp.green};
    stroke-width: 2;
  }

  &.highlighted {
    stroke: ${(props) => props.theme.colors.gcp.green};
    stroke-width: 2px;
    stroke-dasharray: 7 3;

    &:hover {
      stroke-dasharray: none;
    }
  }
`;

export default class Region extends React.Component {
  get subtitle() {
    const { region } = this.props;
    const type = getEntityType(region);

    if (region.name) {
      return [
        <tspan key={`${type}-${region.id}`} fontStyle="italic">
          {region.selfLink}
        </tspan>
      ];
    }

    return null;
  }

  render() {
    const { region, fill, mini, selected, expanded, innerHeight, traffic, ...rest } = this.props;
    const { width, height } = rest;
    const sideWidth = 6;

    if (mini) {
      return <MiniRegion fill={fill} {...rest} />;
    }

    return (
      <>
        <CloudItemRect
          {...rest}
          stroke={fill}
          strokeWidth={2}
          opacity={selected ? 0.5 : 1}
          pointerEvents={selected ? 'none' : 'auto'}
          backgroundHighlightColor="primaryBackground"
          cloudProvider="gcp"
        />
        {!expanded && <Rect x={1} y={1} width={sideWidth} height={height - 2} fill={fill} pointerEvents="none" />}
        <g transform={`translate(${sideWidth}, 0)`}>
          <CloudItemInner
            width={width - sideWidth}
            height={innerHeight || height}
            title={region.name || region.selectId}
            subtitle={this.subtitle}
            icon={<CloudIcon cloudProvider="gcp" entity="regions" />}
          />
        </g>
        {expanded && (
          <g transform={`translate(0 ${innerHeight})`} pointerEvents="none" fontSize={10} letterSpacing="-0.035em">
            <Path d={`M0,0 H${width}`} stroke="muted" strokeOpacity={0.2} strokeWidth={1} />
            <SVGText x={8} y={16} textAnchor="start" fill="muted">
              Inbound
            </SVGText>
            <SVGText x={8} y={36} textAnchor="start" fill="muted">
              Outbound
            </SVGText>
            <SVGText x={8} y={56} textAnchor="start" fill="muted">
              Internal
            </SVGText>
            <g transform="translate(49 0)" fontSize={11}>
              <g transform="translate(0 11)">
                <SVGText x={10} y={2} dominantBaseline="central" fill="muted">
                  {formatBytesGreek(get(traffic, 'inbound', 0), 'bps')}
                </SVGText>
              </g>
              <g transform="translate(0 30)">
                <SVGText x={10} y={2} dominantBaseline="central" fill="muted">
                  {formatBytesGreek(get(traffic, 'outbound', 0), 'bps')}
                </SVGText>
              </g>
              <g transform="translate(0 49)">
                <SVGText x={10} y={2} dominantBaseline="central" fill="muted">
                  {formatBytesGreek(get(traffic, 'internal', 0), 'bps')}
                </SVGText>
              </g>
            </g>
          </g>
        )}
      </>
    );
  }
}
