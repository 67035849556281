import * as React from 'react';
import classNames from 'classnames';
import { themeGet } from 'styled-system';
import styled from 'styled-components';
import { lighten } from 'polished';
import { Rect } from 'app/components/svg';
import { CloudItemRect, CloudItemInner } from 'app/views/hybrid/maps/components/CloudItem';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { getMapClassname, getEntityType } from 'app/views/hybrid/utils/map';

const MiniRegionItem = styled(Rect)`
  @keyframes glowing {
    0% {
      stroke: ${themeGet('colors.primary')};
      opacity: 1;
    }
    50% {
      stroke: ${themeGet('colors.primaryBackground')};
      opacity: 0.5;
    }
  }

  &.flashing {
    animation: glowing 750ms linear infinite;
  }

  cursor: pointer;
  fill: ${(props) => lighten(0.2, props.theme.colors.primary)};

  &:hover,
  &.hover {
    stroke: ${(props) => props.theme.colors.primary};
    stroke-width: 2;
  }

  &.highlighted {
    stroke: ${(props) => props.theme.colors.primary};
    stroke-width: 2px;
    stroke-dasharray: 7 3;

    &:hover {
      stroke-dasharray: none;
    }
  }
`;

export default class RegionEntity extends React.Component {
  get subtitle() {
    const { entity } = this.props;

    const subTitles = [];
    if (entity.displayName) {
      subTitles.push(
        <tspan key={`${getEntityType(entity)}-${entity.id}`} fontStyle="italic">
          {entity.id}
        </tspan>
      );
    }

    if (entity.cidrBlock) {
      subTitles.push(entity.cidrBlock);
    }

    if (subTitles.length > 0) {
      return subTitles;
    }

    return entity.id;
  }

  handleSvgClick = () => {
    const { onShowDetails, entity } = this.props;

    if (!onShowDetails) {
      return;
    }

    onShowDetails({ type: getEntityType(entity), nodeData: entity });
  };

  render() {
    const {
      entity,
      fill,
      mini,
      selected,
      expanded,
      innerHeight,
      onShowDetails,
      withArrow = false,
      ...rest
    } = this.props;
    const { width, height } = rest;
    const sideWidth = 6;

    if (mini) {
      return <MiniRegionItem fill={fill} {...rest} />;
    }

    return (
      <svg width={width} height={height} onClick={this.handleSvgClick}>
        <CloudItemRect
          className={classNames(
            'hybrid-map-selectable-node',
            getMapClassname({ type: getEntityType(entity), value: entity.id })
          )}
          strokeWidth={2}
          {...rest}
          stroke={fill}
          opacity={selected ? 0.5 : 1}
          pointerEvents={selected ? 'none' : 'auto'}
          backgroundHighlightColor="primaryBackground"
        />
        {!expanded && <Rect x={1} y={1} width={sideWidth} height={height - 2} fill={fill} pointerEvents="none" />}
        <g transform={`translate(${sideWidth}, 0)`}>
          <CloudItemInner
            withArrow={withArrow}
            width={width - sideWidth}
            height={innerHeight || height}
            title={entity.displayName || entity.id}
            subtitle={this.subtitle}
            icon={<CloudIcon cloudProvider="oci" entity={getEntityType(entity)} />}
          />
        </g>
      </svg>
    );
  }
}
