import React, { Component } from 'react';

import { formatBytesGreek } from 'core/util';
import { Card, Flex, Text } from 'core/components';
import DeviceLink from 'app/components/links/DeviceLink';
import { ViewInExplorerButton } from 'app/components/dataviews/tools';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import { addFilters } from 'app/stores/query/FilterUtils';

export default class InterfaceHealthCard extends Component {
  get query() {
    const { healthIssue, direction } = this.props;

    const deviceName = healthIssue.get('device_name');
    const snmpId = healthIssue.get('snmp_id');

    const query = {
      all_devices: false,
      device_name: [deviceName],
      fastData: 'Full',
      metric: ['Traffic'],
      aggregateTypes: [direction === 'in' ? 'avg_ktappprotocol__snmp__INT64_00' : 'avg_ktappprotocol__snmp__INT64_02'],
      lookback_seconds: 86400,
      show_overlay: false,
      show_total_overlay: false
    };

    const filters = [{ filterField: 'ktappprotocol__snmp__output_port', operator: '=', filterValue: String(snmpId) }];

    addFilters(query, filters);
    return query;
  }

  get viewProps() {
    const { healthIssue } = this.props;
    const capacity = healthIssue.get('check.capacity');
    const formatted = formatBytesGreek(capacity, 'bits/s', 1);

    return {
      height: 100,
      showAxisLabels: false,
      plotLines: [
        {
          id: 'capacity-line',
          className: 'capacity-level',
          label: {
            text: `Capacity (${formatted})`,
            align: 'left',
            x: -1,
            className: 'capacity-level'
          },
          value: capacity,
          width: 2,
          zIndex: 5
        }
      ]
    };
  }

  render() {
    const { healthIssue } = this.props;
    const { healthState } = healthIssue;

    const deviceName = healthIssue.get('device_name');
    const snmpId = healthIssue.get('snmp_id');
    const interfaceDescription = healthIssue.get('interface_description');

    return (
      <Card p={1} mb={1}>
        <Flex justifyContent="space-between" mb={1}>
          <Text ellipsis>
            <DeviceLink name={deviceName} />:{' '}
            <ViewInExplorerButton as="a" query={this.query} openInNewWindow>{`${interfaceDescription} ${
              snmpId ?? ''
            }`}</ViewInExplorerButton>
          </Text>
          <Text color={healthState.color} fontWeight="bold">
            {healthIssue.displayValue}
          </Text>
        </Flex>
        <Card p={1}>
          <DataViewWrapper allowCache query={this.query} viewProps={this.viewProps} />
        </Card>
      </Card>
    );
  }
}
