import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, Heading, Link, Text } from 'core/components';

const CloudSummary = ({ logo, name, value }) => (
  <Flex p={2} borderBottom="thin" alignItems="center">
    {React.cloneElement(logo, { width: 32, height: 32 })}
    <Box flex={1} ml={1}>
      <Text as="div" muted>
        Cloud Details
      </Text>
      <Heading level={3} mb={0}>
        <Link to={`/v4/core/quick-views/cloud/${value}`} blank>
          {name}
        </Link>
      </Heading>
    </Box>
  </Flex>
);

CloudSummary.PopoutTitle = ({ logo, name }) => (
  <Flex alignItems="center">
    {React.cloneElement(logo)}
    <Box ml={1}>{name}</Box>
  </Flex>
);

export default observer(CloudSummary);
