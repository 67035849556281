import { ENTITY_TYPES } from 'shared/hybrid/constants';

const { CORE_NETWORK_ATTACHMENT, CORE_NETWORK_EDGE } = ENTITY_TYPES.get('aws');

export const QUERY_NAMESPACE = 'AWS/Network Manager';

/*
  Based on aws portal api calls
*/
export const METRICS = [
  'BytesIn',
  'BytesOut',
  'BytesDropCountNoRoute',
  'BytesDropCountBlackhole',

  'PacketsIn',
  'PacketsOut',
  'PacketDropCountNoRoute',
  'PacketDropCountBlackhole'
];

export const generateDimensionsByEntity = (nodeData, entityType) => {
  if (entityType === CORE_NETWORK_ATTACHMENT) {
    return [
      {
        Name: 'CoreNetwork',
        Value: nodeData?.CoreNetworkId
      },
      {
        Name: 'Attachment',
        Value: nodeData.id
      }
    ];
  }

  if (entityType === CORE_NETWORK_EDGE) {
    return [
      {
        Name: 'CoreNetwork',
        Value: nodeData?.CoreNetworkId
      },
      {
        Name: 'EdgeLocation',
        Value: nodeData?.EdgeLocation
      }
    ];
  }

  return [];
};
