/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import { path } from 'd3-path';
import classNames from 'classnames';
import styled from 'styled-components';
import React, { Component } from 'react';
import { themeGet } from 'styled-system';
import { line, curveBasis } from 'd3-shape';
import { formatBytesGreek } from 'core/util';
import greatCircle from '@turf/great-circle';
import { Box, Tooltip } from 'core/components';
import safelyParseJson from 'core/util/safelyParseJson';
import { debounce, get, isEqual, memoize } from 'lodash';
import { getMapClassname } from 'app/views/hybrid/utils/map';
import { event, namespaces, select } from 'd3-selection';
import { linearTransitionAlongThePath } from 'app/views/hybrid/utils/d3/transitions';
import { ABSTRACT_MAP_TOO_CLOSE_MODIFIER } from 'app/views/hybrid/utils/cloud/constants';
import { parseQueryString } from 'app/util/utils';

import { getHealthClass, mergeConnectionHealthStates } from '../../utils/health';
import { getTrafficColor } from '../../utils/legend';
import {
  splitPath,
  splitPathIntoPieces,
  getTextPath,
  getTrafficLabel,
  addTrafficLabelArrow,
  getPathLength
} from '../../utils/links';

function normalizeAngle(angle, min = 0) {
  while (angle < min) {
    angle += 2 * Math.PI;
  }

  while (angle >= min + 2 * Math.PI) {
    angle -= 2 * Math.PI;
  }

  return angle;
}

const Wrapper = styled(Box)`
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 32px;

  svg.overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .link-target {
      cursor: pointer;
      pointer-events: stroke;
      fill: none;
      stroke-width: 10px;
    }

    .link-line {
      fill: none;
      stroke: ${themeGet('borderColors.thick')};
      stroke-width: 2px;
    }

    .link-head {
      fill: ${themeGet('colors.primary')};

      &.filtered {
        fill: ${themeGet('colors.hybrid.filtered')};
      }
    }

    .link-text {
      fill: ${themeGet('colors.muted')};
      font-size: 12px;
    }

    .links-node {
      .link-line {
        stroke: ${themeGet('colors.primary')};

        &.filtered {
          stroke: ${themeGet('colors.hybrid.filtered')};
        }
      }

      .link-text {
        opacity: 0;
      }
    }

    .link-hovered {
      .link-line {
        stroke-width: 3px;
        opacity: 1 !important;
      }

      .link-head {
        opacity: 1 !important;
      }

      .link-text {
        opacity: 1 !important;
      }
    }

    .link-unhovered {
      .link-line,
      .link-head {
        opacity: 0.2;
      }
    }

    .link-selected,
    .box-link-selected {
      .link-line {
        stroke-width: 3px;
      }
    }

    .box-link-selected {
      .link-line {
        stroke: ${themeGet('colors.primary')};
      }
    }
  }

  @keyframes link-spinner-animation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .spinner-track,
  .spinner-head {
    stroke: lightgray;
    stroke-width: 2px;
    fill: none;
  }

  .spinner-head {
    stroke: darkgray;
    animation: link-spinner-animation 500ms linear infinite;
  }

  .no-display {
    display: none !important;
  }

  .muted {
    opacity: 0.2;
  }

  ${({ css }) => css}
`;

export default class AbstractMap extends Component {
  static defaultProps = {
    width: 900,
    sidebarSettings: {},
    isEmbedded: false
  };

  state = {
    loading: true,
    isExpandedMode: false,
    boxExpanded: {},
    selectedNode: null,
    hoveredNode: null,
    activeNode: null,
    selectedLink: -1,
    selectedBoxLink: -1,
    hoveredLink: -1,
    hoveredBoxLink: -1,
    linkTooltip: { position: { top: 0, left: 0 }, content: '' },
    linkTooltipOpen: false,
    nodeLinks: [],
    nodeLinkQueries: [],
    nodeLinksLoading: false,
    linkFlowTrafficLoading: false,
    boxLinks: [],
    activeInternetTabId: 'asn'
  };

  curveType = curveBasis;

  map = React.createRef();

  svg = React.createRef();

  boxLinksGroup = React.createRef();

  nodeLinksGroup = React.createRef();

  chordLinksGroup = React.createRef();

  nodeLinksData = [];

  geoLineFeatures = {};

  constructor(props) {
    super(props);
    this.getLinkHealth = this.getLinkHealth.bind(this);
    this.getLinkTraffic = this.getLinkTraffic.bind(this);
    this.handleNodeLinksUpdate = this.handleNodeLinksUpdate.bind(this);
    this.handleSelectNode = this.handleSelectNode.bind(this);
    this.onTopologyReady = this.onTopologyReady.bind(this);
  }

  componentDidMount() {
    this.drawLinks();
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarSettings, drawerIsOpen } = this.props;
    const { sidebarQueryOverrides } = sidebarSettings;
    const drawerChanged = prevProps.drawerIsOpen !== drawerIsOpen;

    if (drawerChanged) {
      this.getRegionBoxPositions.cache.clear();
    }

    if (this.shouldLinksRedraw(prevProps, prevState)) {
      this.drawLinks();
    }

    if (!isEqual(prevProps.sidebarSettings.sidebarQueryOverrides, sidebarQueryOverrides)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(({ selectedNode }) => {
        if (selectedNode) {
          return { nodeLinksLoading: true, nodeLinkQueries: this.getNodeLinkQueries() };
        }

        return null;
      });
    }

    this.highlightLinks();
  }

  shouldLinksRedraw(prevProps, prevState) {
    const { width } = this.props;
    const { loading, boxExpanded, nodeLinks, boxLinks } = this.state;

    return (
      prevProps.width !== width ||
      prevState.loading !== loading ||
      !isEqual(prevState.boxExpanded, boxExpanded) ||
      !isEqual(prevState.nodeLinks, nodeLinks) ||
      !isEqual(prevState.boxLinks, boxLinks)
    );
  }

  onTopologyReady() {
    const { history, location, setSidebarDetails } = this.props;

    // set selectedNode from page state
    if (location?.state?.selectedNode) {
      const { selectedNode } = location.state;

      this.setState({ selectedNode });
      history.replace(location.pathname, { ...location.state, selectedNode: null });

      if (setSidebarDetails) {
        setSidebarDetails(this.getSidebarConfig({ selectedNode }));
      }
    }

    this.handleHoverLink = this.handleHoverLink.bind(this);
  }

  shouldSourceLinkRollUp(type) {
    const { boxExpanded } = this.state;
    return boxExpanded[type] === false;
  }

  shouldTargetLinkRollUp() {
    return true;
  }

  shouldCloseSidebarOnMapClick() {
    return true;
  }

  get shouldRenderLinksBasedOnState() {
    const { hoveredNode, nodeLinks, activeNode } = this.state;

    return !!(hoveredNode || nodeLinks.length > 0 || activeNode);
  }

  // returns the config of the predefined sidebar, used for showing on load
  // we have links like this from various widgets that want to 'jump' to a specific spot in the map
  // currently only aws uses this (see CloudAwsMap.setInitialState) but others are sure to follow
  get initialSidebarNode() {
    const { location } = this.props;
    const { sidebar } = parseQueryString(location.search);

    if (sidebar) {
      return safelyParseJson(sidebar);
    }

    return null;
  }

  get hasSidebarFilters() {
    const { sidebarSettings } = this.props;
    return get(sidebarSettings, 'sidebarQueryOverrides.filters.filterGroups', []).length > 0;
  }

  get chordType() {
    return null;
  }

  get geoTypes() {
    return [];
  }

  get nodeLinks() {
    const { nodeLinks } = this.state;

    return nodeLinks
      .map((link) => {
        const { source, target, ...rest } = link;

        return {
          source: this.shouldSourceLinkRollUp(source.type) ? { type: 'box', value: source.type } : source,
          target: this.shouldTargetLinkRollUp(target.type) ? { type: 'box', value: target.type } : target,
          ...rest
        };
      })
      .reduce((acc, link) => {
        const { source, target, inbound, outbound, total, ...rest } = link;
        const existing = acc.find(
          (l) =>
            l.source.type === source.type &&
            l.source.value === source.value &&
            l.target.type === target.type &&
            l.target.value === target.value
        );

        if (existing) {
          existing.inbound += inbound;
          existing.outbound += outbound;
          existing.total += total;

          Object.keys(rest).forEach((linkProp) => {
            existing[linkProp] ||= rest[linkProp];
          });
          return acc;
        }

        return acc.concat(link);
      }, []);
  }

  get autoAnchorLinks() {
    return false;
  }

  getGeoLineFeature(sourcePoint, targetPoint, nPoints = 500) {
    const sourceCoords = sourcePoint.geometry.coordinates;
    const targetCoords = targetPoint.geometry.coordinates;

    const key = [sourceCoords, targetCoords, nPoints].join('-');

    if (!this.geoLineFeatures[key]) {
      const posOffset = sourceCoords[0] > 180 || targetCoords[0] > 180;
      const negOffset = sourceCoords[0] < -180 || targetCoords[0] < -180;
      const minLon = Math.min(sourceCoords[0], targetCoords[0]);
      const maxLon = Math.max(sourceCoords[0], targetCoords[0]);
      const diffLon = maxLon - minLon;

      const lineFeature = greatCircle(sourcePoint, targetPoint, { npoints: nPoints });

      if (lineFeature.geometry.type === 'MultiLineString') {
        lineFeature.geometry.type = 'LineString';
        lineFeature.geometry.coordinates = lineFeature.geometry.coordinates.flat();
      }

      lineFeature.geometry.coordinates = lineFeature.geometry.coordinates.map((coord) => {
        // coords are normalized between -180 and 180, add back offset if necessary
        if (negOffset && coord[0] > maxLon) {
          coord[0] -= 360;
        } else if (posOffset && coord[0] < minLon) {
          coord[0] += 360;
        }

        // adjust lines to go in-between points, instead of other way around world
        if (coord[0] < minLon) {
          coord[0] = minLon - diffLon * ((coord[0] - minLon) / (360 - diffLon));
        } else if (coord[0] > maxLon) {
          coord[0] = maxLon - diffLon * ((coord[0] - maxLon) / (360 - diffLon));
        }

        return coord;
      });

      this.geoLineFeatures[key] = lineFeature;
    }

    return this.geoLineFeatures[key];
  }

  getGeoMarker() {
    return null;
  }

  getGeoPosition({ value, ...rest }) {
    const marker = this.getGeoMarker(value, rest);

    if (
      !this.geoMap ||
      !this.svg.current ||
      !marker ||
      typeof marker.lat !== 'number' ||
      typeof marker.lon !== 'number'
    ) {
      return { points: [] };
    }

    const svgRect = this.svg.current.getBoundingClientRect();
    const geoMapRect = this.geoMap.getContainer().getBoundingClientRect();

    const { lon, lat } = marker;
    const bounds = this.geoMap.getBounds();
    const center = bounds.getCenter();
    let minDist = Infinity;
    let offset = 0;

    // offset marker to the one closest to the center
    [0, 360, -360].forEach((o) => {
      const l = lon + o;
      const dist = Math.abs(center.lng - l);

      if (dist < minDist) {
        minDist = dist;
        offset = o;
      }
    });

    const sitePoint = this.geoMap.project([lon + offset, lat]);

    const geoMapLeft = geoMapRect.left - svgRect.left;
    const geoMapTop = geoMapRect.top - svgRect.top;

    const markerSize = marker.cluster ? 28 : (marker?.markerSize ?? 16);
    const cx = sitePoint.x + geoMapLeft;
    const cy = sitePoint.y + geoMapTop;
    const x = cx - markerSize / 2;
    const y = cy - markerSize / 2;

    return {
      marker,
      offset,
      points: [[cx, cy]],
      mask: null, // no mask, point will get moved to edge of marker
      clipPath: {
        type: 'rect',
        attrs: { x: geoMapLeft, y: geoMapTop, width: geoMapRect.width, height: geoMapRect.height }
      },
      geoMap: {
        left: geoMapLeft,
        right: geoMapLeft + geoMapRect.width,
        top: geoMapTop,
        bottom: geoMapTop + geoMapRect.height,
        width: geoMapRect.width,
        height: geoMapRect.height
      },
      svg: { rect: svgRect },
      node: { width: markerSize, height: markerSize, x, y, cx, cy }
    };
  }

  getBoxLinkPoints(from, to, orientation) {
    const fromNode = this.map.current.querySelector(`.box-${from}`);
    const toNode = this.map.current.querySelector(`.box-${to}`);

    if (!fromNode || !toNode) {
      return { points: [] };
    }

    const svgRect = this.svg.current.getBoundingClientRect();
    const fromRect = fromNode.getBoundingClientRect();
    const toRect = toNode.getBoundingClientRect();

    const points = [];

    if (orientation === 'horizontal') {
      const y = (Math.max(fromRect.top, toRect.top) + Math.min(fromRect.bottom, toRect.bottom)) / 2 - svgRect.top;
      points.push([fromRect.right - svgRect.left, y], [toRect.left - svgRect.left, y]);
    } else {
      const x = (Math.max(fromRect.left, toRect.left) + Math.min(fromRect.right, toRect.right)) / 2 - svgRect.left;
      const fromY = (fromRect.top < toRect.top ? fromRect.bottom : fromRect.top) - svgRect.top;
      const toY = (fromRect.top < toRect.top ? toRect.top : toRect.bottom) - svgRect.top;
      points.push([x, fromY], [x, toY]);
    }

    const center = [(points[0][0] + points[1][0]) / 2, (points[0][1] + points[1][1]) / 2];

    return { points, center };
  }

  isGeoType(type) {
    return this.geoTypes.includes(type);
  }

  getNodePosition({ type, value, ...rest }) {
    if (this.isGeoType(type)) {
      return this.getGeoPosition({ value, type, ...rest });
    }

    const selector = `.${getMapClassname({ type, value })}`;
    const node = this.map.current.querySelector(selector);

    if (!node) {
      return { points: [] };
    }

    const svgRect = this.svg.current.getBoundingClientRect();
    const nodeRect = node.getBoundingClientRect();
    const tag = node.tagName.toLowerCase();

    const { width, height } = nodeRect;
    const x = nodeRect.left - svgRect.left;
    const y = nodeRect.top - svgRect.top;
    const cx = x + width / 2;
    const cy = y + height / 2;

    const points = [[cx, cy]];

    let mask;

    if (node.classList.contains('topKeys')) {
      const box = this.map.current.querySelector(`.box-${type}`);

      points[0][0] = x;
      points.push([cx - 20, cy]);

      if (box) {
        const boxRect = box.getBoundingClientRect();
        points.push([cx - 20, boxRect.bottom - svgRect.top + 50]);
      }
    } else if (type === 'box') {
      if (value === this.chordType) {
        points.pop();
        points.push([cx, y - 60], [cx, y - 100]);
      } else if (node.classList.contains('link-topcenter')) {
        points.splice(0);
        points.push([cx, y]);
      } else if (node.classList.contains('link-bottomright')) {
        points.splice(0);
        points.push([x + width - 4, y + height - 4], [x + width, y + height]);
      } else if (node.classList.contains('link-bottomcenter')) {
        points.splice(0);
        points.push([cx, y + height]);
      } else if (node.classList.contains('link-bottomleft')) {
        points.splice(0);
        points.push([x + 4, y + height - 4], [x, y + height]);
      }
    } else if (type === this.chordType) {
      const box = this.map.current.querySelector(`.box-${this.chordType}`);

      if (box) {
        const boxRect = box.getBoundingClientRect();
        const boxCx = boxRect.left - svgRect.left + boxRect.width / 2;
        const boxY = boxRect.top - svgRect.top;
        points.push([boxCx, boxY - 60], [boxCx, boxY - 100]);
      }
    } else if (type === 'cloud') {
      points.push([cx, cy + 80]);
    }

    if (tag === 'path') {
      const { x: pathX, y: pathY } = node.getBBox();
      mask = { type: 'path', attrs: { d: node.getAttribute('d'), transform: `translate(${x - pathX} ${y - pathY})` } };
    } else if (tag === 'div') {
      const style = getComputedStyle(node);
      const borderRadius = parseInt(style.borderRadius || style.borderTopLeftRadius) || 0;
      mask = { type: 'rect', attrs: { x, y, width, height, rx: borderRadius, ry: borderRadius } };
    } else if (tag === 'circle') {
      mask = { type: 'circle', attrs: { cx, cy, r: width / 2 } };
    } else if (tag === 'rect') {
      const style = getComputedStyle(node);
      const rx = parseInt(style.rx) || 0;
      const ry = parseInt(style.ry) || 0;
      mask = { type: 'rect', attrs: { x, y, width, height, rx, ry } };
    }

    return {
      points,
      mask,
      svg: { rect: svgRect },
      node: { rect: nodeRect, width, height, x, y, cx, cy, element: node },
      anchors: {
        top: [cx, y],
        right: [x + width, cy],
        bottom: [cx, y + height],
        left: [x, cy]
      }
    };
  }

  drawLinks() {
    if (!this.svg.current || this.geoMap?.isFallback) {
      return;
    }

    this.drawBoxLinks(select(this.boxLinksGroup.current));
    this.drawNodeLinks(select(this.nodeLinksGroup.current), this.handleNodeLinkClick);
    this.drawChordTargetLinks(select(this.chordLinksGroup.current));
  }

  drawBoxLinks(group) {
    const { isEmbedded, setSidebarDetails } = this.props;
    const { boxLinks } = this.state;
    const drawBoxLink = line();

    const data = boxLinks
      .map((link, index) => {
        const { orientation, from, to, bytesIn, bytesOut } = link;
        const { points, center } = this.getBoxLinkPoints(from, to, orientation);

        if (points.length === 0) {
          return null;
        }

        return {
          ...link,
          points,
          center,
          bytesInFormatted: typeof bytesIn === 'number' ? formatBytesGreek(bytesIn, 'bps') : null,
          bytesOutFormatted: typeof bytesOut === 'number' ? formatBytesGreek(bytesOut, 'bps') : null,
          index
        };
      })
      .filter((link) => link !== null);

    group.selectAll('g').remove();

    const groups = group.selectAll('g').data(data).join('g').attr('class', 'link');

    groups
      .append('path')
      .attr('id', (d) => `link-line-box${d.index}`)
      .attr('class', 'link-line')
      .attr('d', (d) => drawBoxLink(d.points));

    groups
      .append('path')
      .attr('class', (d) =>
        classNames('hybrid-map-selectable-link', {
          'link-target': !isEmbedded && !!d.queryOptions
        })
      )
      .attr('d', (d) => drawBoxLink(d.points))
      .on('click', (d) => {
        this.setState({ selectedBoxLink: d.index }, () => {
          setSidebarDetails({
            type: 'link',
            links: [],
            source: { type: 'box', value: d.from },
            target: { type: 'box', value: d.to },
            queryOptions: d.queryOptions,
            isBoxLink: true
          });
        });
      })
      .on('mouseenter', (d) => this.setState({ hoveredBoxLink: d.index }))
      .on('mouseleave', () => this.setState({ hoveredBoxLink: -1 }));

    const spinnerBox = groups
      .append('g')
      .attr('class', 'spinner')
      .attr('visibility', (d) => (d.loading ? 'visible' : 'hidden'));

    spinnerBox
      .append('rect')
      .attr('x', (d) => d.center[0] - 15)
      .attr('y', (d) => d.center[1] - 15)
      .attr('width', 30)
      .attr('height', 30)
      .attr('class', 'spinner-box');

    spinnerBox
      .append('circle')
      .attr('r', 10)
      .attr('cx', (d) => d.center[0])
      .attr('cy', (d) => d.center[1])
      .attr('class', 'spinner-track');

    spinnerBox
      .append('circle')
      .attr('r', 10)
      .attr('cx', (d) => d.center[0])
      .attr('cy', (d) => d.center[1])
      .attr('pathLength', 280)
      .attr('stroke-dasharray', '280 280')
      .attr('stroke-dashoffset', 210)
      .attr('transform-origin', (d) => `${d.center[0]}px ${d.center[1]}px`)
      .attr('class', 'spinner-head');

    groups
      .append('text')
      .attr('class', 'link-text')
      .attr('dy', '-0.35em')
      .append('textPath')
      .attr('xlink:href', (d) => `#link-line-box${d.index}`)
      .attr('text-anchor', 'middle')
      .attr('startOffset', '50%')
      .attr('visibility', (d) => (d.loading ? 'hidden' : 'visible'))
      .text((d) => (d.bytesOutFormatted !== null ? `${d.bytesOutFormatted} ▶` : null));

    groups
      .append('text')
      .attr('class', 'link-text')
      .attr('dy', (d) => (d.orientation === 'vertical' ? '-0.35em' : '1.05em'))
      .style('transform-origin', (d) => `${d.center[0]}px ${d.center[1]}px`)
      .attr('transform', (d) => (d.orientation === 'vertical' ? 'rotate(180)' : null))
      .append('textPath')
      .attr('xlink:href', (d) => `#link-line-box${d.index}`)
      .attr('text-anchor', 'middle')
      .attr('startOffset', '50%')
      .attr('visibility', (d) => (d.loading ? 'hidden' : 'visible'))
      .text((d) => {
        if (d.bytesInFormatted === null) {
          return null;
        }

        return d.orientation === 'vertical' ? `${d.bytesInFormatted} ▶` : `◀ ${d.bytesInFormatted}`;
      });
  }

  findLinkAnchor(nodePosition, point) {
    const center = nodePosition.points[0];
    const angle = Math.atan2(point[1] - center[1], point[0] - center[0]) * (180 / Math.PI);
    let anchor;

    if (angle <= -135 || angle > 135) {
      anchor = 'left';
    } else if (angle <= -45) {
      anchor = 'top';
    } else if (angle <= 45) {
      anchor = 'right';
    } else {
      anchor = 'bottom';
    }

    this.setLinkAnchor(nodePosition, anchor);
  }

  setLinkAnchor(nodePosition, anchor, force = false) {
    if (
      force ||
      (nodePosition.points[0][0] === nodePosition.node.cx && nodePosition.points[0][1] === nodePosition.node.cy)
    ) {
      nodePosition.mask = null;
      // eslint-disable-next-line prefer-destructuring
      nodePosition.points[0][0] = nodePosition.anchors[anchor][0];
      // eslint-disable-next-line prefer-destructuring
      nodePosition.points[0][1] = nodePosition.anchors[anchor][1];
    }
  }

  // the default is to connect on each node's center point
  // this can be overridden to connect on a different anchor (top, right, bottom, left)
  getNodeLinkPositions({ linkData, source, target }) {
    const { width } = this.props;
    const { connectionType } = linkData;
    const { points: sourcePoints } = source;
    const { points: targetPoints } = target;
    const connectionPoints = [];

    if (sourcePoints.length === 0 || targetPoints.length === 0) {
      return { source, target };
    }

    const sourcePoint = sourcePoints[sourcePoints.length - 1];
    const targetPoint = targetPoints[targetPoints.length - 1];

    if (connectionType === 'square') {
      const leftPoints = [];
      const centerPoints = [];
      const rightPoints = [];

      [sourcePoint, targetPoint].forEach((point) => {
        if (point[0] < width / 3) {
          leftPoints.push(point);
        } else if (point[0] < width * (2 / 3)) {
          centerPoints.push(point);
        } else {
          rightPoints.push(point);
        }
      });

      if (leftPoints.length === 1 && centerPoints.length === 1) {
        connectionPoints.push([leftPoints[0][0], centerPoints[0][1]]);
      } else if (centerPoints.length === 1 && rightPoints.length === 1) {
        connectionPoints.push([rightPoints[0][0], centerPoints[0][1]]);
      } else {
        let y;

        if (sourcePoints.length > 1) {
          [, y] = sourcePoint;
        } else if (targetPoints.length > 1) {
          [, y] = targetPoint;
        } else {
          y = (sourcePoint[1] + targetPoint[1]) / 2;
        }

        connectionPoints.push([sourcePoint[0], y], [targetPoint[0], y]);
      }
    }

    if (this.autoAnchorLinks) {
      const points = [...sourcePoints, ...connectionPoints, ...targetPoints.slice().reverse()];

      this.findLinkAnchor(source, points[1]);
      this.findLinkAnchor(target, points[points.length - 2]);
    }

    return { source: { ...source, points: sourcePoints.concat(connectionPoints) }, target };
  }

  /**
   * Will generate [id: {targetFor: [], sourceFor: []}] helper tree structure
   */
  get nodeTree() {
    const nodeTree = [];
    this.nodeLinks.forEach(({ source, target }) => {
      // create if doesnt exist
      if (!nodeTree[target.value]) {
        nodeTree[target.value] = { targetFor: [], sourceFor: [] };
      }

      if (nodeTree[target.value].targetFor.includes(source) === false) {
        nodeTree[target.value].targetFor.push(source);
      }

      // create if doesnt exist
      if (!nodeTree[source.value]) {
        nodeTree[source.value] = { targetFor: [], sourceFor: [] };
      }

      if (nodeTree[source.value].sourceFor.includes(target) === false) {
        nodeTree[source.value].sourceFor.push(target);
      }
    });

    return nodeTree;
  }

  createArrowSvg(d3Selector, x, y) {
    const { theme } = this.props;

    return d3Selector
      .append('svg:path')
      .attr('class', 'right-arrow transition-marker')
      .attr('d', 'M0,-5L10,0L0,5')
      .attr('fill', 'none')
      .attr('stroke', theme?.colors.primary ?? 'black')
      .attr('stroke-width', '2px')
      .attr('transform', `translate(${x},${y})`);
  }

  drawNodeLinks(group, onNodeLinkClick) {
    const drawCurvedLink = line().curve(curveBasis);
    const drawSquareLink = line();

    const splittedLines = [];

    /** will keep unique points for lines here, to prevent lines overlapping */
    let uniquePoints = [];
    const allNodeLinks = this.nodeLinks;

    const nodeData = allNodeLinks
      .map((linkData, index) => {
        const { source, target, inbound, outbound, total, capacity, connectionType, separatedLinks, ...link } =
          linkData;

        const {
          source: sourcePosition,
          target: targetPosition,
          clipPath
        } = this.getNodeLinkPositions({
          linkData,
          pathIndex: index,
          path: allNodeLinks,
          source: this.getNodePosition({ ...source, connectionType }),
          target: this.getNodePosition({ ...target, connectionType })
        });

        allNodeLinks[index].positions = { sourcePosition, targetPosition, clipPath };

        const { points: sourcePoints, mask: sourceMask } = sourcePosition;
        const { points: targetPoints, mask: targetMask } = targetPosition;

        if (sourcePoints.length === 0 || targetPoints.length === 0) {
          return null;
        }

        const points = [...sourcePoints, ...targetPoints.reverse()];
        const pathData = connectionType === 'square' ? drawSquareLink(points) : drawCurvedLink(points);

        let info = '';

        if (separatedLinks) {
          if (separatedLinks.length === 1 && separatedLinks[0].aggregate) {
            const { aggregate } = separatedLinks[0];
            const linkCapacity = formatBytesGreek(aggregate.snmp_speed * 1000000, 'bits/s');
            info = `${aggregate.count} x ${linkCapacity}`;
          } else if (separatedLinks.length > 1) {
            info = `${separatedLinks.length} Links`;
          }
        }

        let trafficData = {};

        if (total) {
          const [sourcePath, targetPath] = splitPath(pathData, { at: outbound / total, connectionType });
          const trafficPath = getTextPath(points, { connectionType });

          trafficData = {
            sourcePath,
            targetPath,
            trafficPath,
            sourceTraffic: getTrafficLabel(outbound, capacity),
            targetTraffic: getTrafficLabel(inbound, capacity),
            canRenderDirection:
              sourcePath && sourcePath.canRenderDirection && targetPath && targetPath.canRenderDirection
          };
        }

        return {
          points,
          pathData,
          source,
          target,
          sourceMask,
          targetMask,
          clipPath,
          info,
          index,
          ...link,
          ...trafficData
        };
      })
      // keep the link if it's not null and has not been explicitly hidden
      .filter((data) => data !== null && data?.hidden !== true)
      .filter((data) => {
        if (data.isArrowLink) {
          /**
           * Animated
           * [src]----->--------->-----[target]
           * The idea is to split current link into smaller pieces, and filter out pieces that are already rendered on a view to prevenr overlap
           */
          const splittedPath = splitPathIntoPieces({ pathData: data.pathData });
          splittedPath.forEach((pathPiece) => {
            const { segs } = pathPiece;
            const uniqueSegs = segs.filter(
              ([x, y]) =>
                !uniquePoints.some(
                  ([uniqueX, uniqueY]) =>
                    Math.abs(uniqueX - x) <= ABSTRACT_MAP_TOO_CLOSE_MODIFIER &&
                    Math.abs(uniqueY - y) <= ABSTRACT_MAP_TOO_CLOSE_MODIFIER
                )
            );
            // keep in memory points we are rendering on a screen
            uniquePoints = [...uniquePoints, ...uniqueSegs];
            const pathData = data.connectionType === 'square' ? drawSquareLink(uniqueSegs) : drawCurvedLink(uniqueSegs);
            const pathLength = getPathLength(pathData);
            // we dont care about small pieces that are < 10 points
            if (pathData && pathLength > 10) {
              splittedLines.push({
                ...data,
                pathData,
                points: uniqueSegs,
                sourceMask: false,
                targetMask: false
              });
            }
          });
          // returning false to exclude current long path link from render as it will be replaced with smalled link pieces.
          return false;
        }

        return true;
      })
      // including splitted pieces for render
      .concat(splittedLines);

    // cache nodeData used in this draw cycle
    this.nodeLinksData = nodeData;

    group.selectAll('g').remove();

    const groups = group.selectAll('g').data(nodeData).join('g').attr('class', 'link');

    groups.each((d, idx, nodes) => {
      const g = select(nodes[idx]);
      const lines = g
        .append('g')
        .attr('mask', d.sourceMask || d.targetMask ? `url(#link-mask-${d.index})` : undefined)
        .attr('clip-path', d.clipPath ? `url(#link-clip-${d.index})` : undefined);

      if (d.sourcePath && d.targetPath && !d.isArrowLink) {
        lines
          .append('path')
          .style('stroke', d.outboundColor || d.color)
          .style('stroke-dasharray', d.outboundDashArray || d.dashArray)
          .attr(
            'class',
            classNames('link-line', {
              'animated-traffic': d.animated || this.hasSidebarFilters,
              [d.sourcePath.textAlign]: d.animated || this.hasSidebarFilters,
              filtered: this.hasSidebarFilters
            })
          )
          .attr('id', `link-line-source-node-${d.index}`)
          .attr('d', d.sourcePath.pathData);

        lines
          .append('path')
          .style('stroke', d.inboundColor || d.color)
          .style('stroke-dasharray', d.inboundDashArray || d.dashArray)
          .attr(
            'class',
            classNames('link-line', {
              'animated-traffic': d.animated || this.hasSidebarFilters,
              [d.targetPath.textAlign]: d.animated || this.hasSidebarFilters,
              filtered: this.hasSidebarFilters
            })
          )
          .attr('id', `link-line-target-node-${d.index}`)
          .attr('d', d.targetPath.pathData);

        lines
          .append('path')
          .style('fill', d.outboundColor || d.color)
          .attr('class', classNames('link-head', { filtered: this.hasSidebarFilters }))
          .attr('d', d.sourcePath.arrowHead);

        lines
          .append('path')
          .style('fill', d.inboundColor || d.color)
          .attr('class', classNames('link-head', { filtered: this.hasSidebarFilters }))
          .attr('d', d.targetPath.arrowHead);

        if (d.canRenderDirection) {
          g.append('text')
            .attr('class', 'link-text')
            .attr('dy', -5)
            .append('textPath')
            .attr('href', `#link-line-source-node-${d.index}`)
            .attr('startOffset', d.sourcePath.textAlign === 'left' ? '0%' : '100%')
            .style('text-anchor', d.sourcePath.textAlign === 'left' ? 'start' : 'end')
            .text(d.sourceTraffic + (d.info && ` - ${d.info}`));

          g.append('text')
            .attr('class', 'link-text')
            .attr('dy', -5)
            .append('textPath')
            .attr('href', `#link-line-target-node-${d.index}`)
            .attr('startOffset', d.targetPath.textAlign === 'left' ? '0%' : '100%')
            .style('text-anchor', d.targetPath.textAlign === 'left' ? 'start' : 'end')
            .text(d.targetTraffic + (d.info && ` - ${d.info}`));
        } else {
          g.append('path')
            .attr('fill', 'none')
            .attr('stroke', 'none')
            .attr('stroke-width', 2)
            .attr('d', d.trafficPath?.pathData)
            .attr('id', `link-line-traffic-${d.index}`);

          g.append('text')
            .attr('class', 'link-text')
            .attr('dy', -5)
            .append('textPath')
            .attr('href', `#link-line-traffic-${d.index}`)
            .attr('startOffset', '50%')
            .style('text-anchor', 'middle')
            .text(addTrafficLabelArrow(d.sourceTraffic + (d.info && ` - ${d.info}`), d.trafficPath?.sourceTextAlign));

          g.append('text')
            .attr('class', 'link-text')
            .attr('dy', 14)
            .append('textPath')
            .attr('href', `#link-line-traffic-${d.index}`)
            .attr('startOffset', '50%')
            .style('text-anchor', 'middle')
            .text(addTrafficLabelArrow(d.targetTraffic + (d.info && ` - ${d.info}`), d.trafficPath?.targetTextAlign));
        }
      } else {
        lines
          .append('path')
          .style('stroke', d.color)
          .style('stroke-dasharray', d.dashArray)
          .attr(
            'class',
            classNames('link-line', {
              'animated-traffic': d.animated,
              right: d.animated,
              filtered: this.hasSidebarFilters,
              ...d.cssClassnames
            })
          )
          .attr('id', `link-line-node-${d.index}`)
          .attr('d', d.pathData);

        const abstractMapInstance = this;

        lines
          .filter((pathLine) => pathLine.isArrowLink)
          .each(function createSvgTransition(pathLine) {
            const pathSvg = select(this.childNodes[0]).node();

            if (pathLine.isForwardPath) {
              const arrow = abstractMapInstance.createArrowSvg(
                select(this),
                pathLine.points[0][0],
                pathLine.points[0][1]
              );
              arrow.attr('data-line-id', d.index).each(function createArrowTransition() {
                linearTransitionAlongThePath({
                  pathSvg,
                  d3Arrow: select(this),
                  startPoint: pathLine.points[0]
                });
              });
            }

            if (pathLine.isReversePath) {
              const reverseArrow = abstractMapInstance.createArrowSvg(
                select(this),
                pathLine.points[pathLine.points.length - 1][0],
                pathLine.points[pathLine.points.length - 1][1]
              );
              reverseArrow.attr('data-line-id', d.index).each(function createArrowTransition() {
                linearTransitionAlongThePath({
                  pathSvg,
                  d3Arrow: select(this),
                  isReverse: true,
                  startPoint: pathLine.points[pathLine.points.length - 1]
                });
              });
            }
          });

        if (d.sourceTraffic && d.targetTraffic) {
          g.append('path')
            .attr('fill', 'none')
            .attr('stroke', 'none')
            .attr('stroke-width', 2)
            .attr('d', d.trafficPath?.pathData)
            .attr('id', `link-line-traffic-${d.index}`);

          g.append('text')
            .attr('class', 'link-text')
            .attr('dy', -5)
            .append('textPath')
            .attr('href', `#link-line-traffic-${d.index}`)
            .attr('startOffset', '50%')
            .style('text-anchor', 'middle')
            .text(addTrafficLabelArrow(d.sourceTraffic + (d.info && ` - ${d.info}`), d.trafficPath?.sourceTextAlign));

          g.append('text')
            .attr('class', 'link-text')
            .attr('dy', 14)
            .append('textPath')
            .attr('href', `#link-line-traffic-${d.index}`)
            .attr('startOffset', '50%')
            .style('text-anchor', 'middle')
            .text(addTrafficLabelArrow(d.targetTraffic + (d.info && ` - ${d.info}`), d.trafficPath?.targetTextAlign));
        }
      }
    });

    groups
      .append('path')
      .attr('class', classNames('link-target', 'hybrid-map-selectable-link'))
      .attr('d', (d) => d.pathData)
      .on('click', (d) =>
        this.setState(
          ({ selectedLink }) => ({
            selectedLink: selectedLink === d.index ? -1 : d.index
          }),
          () => {
            if (onNodeLinkClick) {
              onNodeLinkClick({
                ...d,
                type: 'link',
                source: d.source,
                target: d.target,
                position: {
                  left: event.offsetX,
                  top: event.offsetY
                }
              });
            }
          }
        )
      )
      .on('mouseenter', (d) => this.handleHoverLink(d))
      .on('mouseleave', () => this.handleHoverLink(null));

    const masks = groups
      .filter((d) => d.sourceMask || d.targetMask)
      .append('mask')
      .attr('id', (d) => `link-mask-${d.index}`);

    masks.append('rect').attr('fill', 'white').attr('width', '100%').attr('height', '100%');

    masks
      .filter((d) => d.sourceMask)
      .append((d) => document.createElementNS(namespaces.svg, d.sourceMask.type))
      .attr('fill', 'black')
      .each((d, idx, nodes) =>
        Object.entries(d.sourceMask.attrs).forEach(([name, value]) => nodes[idx].setAttribute(name, value))
      );

    masks
      .filter((d) => d.targetMask)
      .append((d) => document.createElementNS(namespaces.svg, d.targetMask.type))
      .attr('fill', 'black')
      .each((d, idx, nodes) =>
        Object.entries(d.targetMask.attrs).forEach(([name, value]) => nodes[idx].setAttribute(name, value))
      );

    groups
      .filter((d) => d.clipPath)
      .append('clipPath')
      .attr('id', (d) => `link-clip-${d.index}`)
      .append((d) => document.createElementNS(namespaces.svg, d.clipPath.type))
      .each((d, idx, nodes) =>
        Object.entries(d.clipPath.attrs).forEach(([name, value]) => nodes[idx].setAttribute(name, value))
      );
  }

  drawChordTargetLinks(group) {
    group.selectAll('g').remove();

    if (!this.chordType) {
      return;
    }

    const { nodeLinks } = this.state;
    const svgRect = this.svg.current.getBoundingClientRect();

    const box = this.map.current.querySelector(`.box-${this.chordType}`);

    if (!box) {
      return;
    }

    const boxRect = box.getBoundingClientRect();
    const boxY = boxRect.top - svgRect.top;
    const boxCx = boxRect.left - svgRect.left + boxRect.width / 2;
    const boxCy = boxY + boxRect.height / 2;
    const radius = Math.min(boxRect.width - 175, boxRect.height - 175) / 2 + 110;

    const linkCurve = Math.PI / 60; // 3 deg
    const arcEnd = Math.PI / 36; // 5 deg
    let minAngle = 0;
    let maxAngle = 0;

    const siteTargetData = nodeLinks
      .filter(({ target }) => target.type === this.chordType)
      .map(({ target: { value } }) => {
        const selector = `.${getMapClassname({ type: this.chordType, value })}`;
        const node = this.map.current.querySelector(selector);

        if (!node) {
          return null;
        }

        const nodeRect = node.getBoundingClientRect();
        const nodeData = select(node).datum();
        const labelRadius = get(nodeData, 'labelRadius', 0);
        const angle = normalizeAngle(get(nodeData, 'angle', 0), -Math.PI);

        minAngle = Math.min(minAngle, angle);
        maxAngle = Math.max(maxAngle, angle);

        return {
          cx: nodeRect.left - svgRect.left + nodeRect.width / 2,
          cy: nodeRect.top - svgRect.top + nodeRect.height / 2,
          paddingTop: nodeRect.height / 2,
          tag: node.tagName.toLowerCase(),
          labelRadius,
          angle
        };
      })
      .filter((data) => data !== null);

    const arcGroup = group.append('g');
    const linksGroup = group.append('g').selectAll('g').data(siteTargetData).join('g').attr('class', 'link');

    if (minAngle + linkCurve < -arcEnd) {
      const arcPath = path();
      const angle = minAngle + linkCurve;

      arcPath.arc(boxCx, boxCy, radius, angle - Math.PI / 2, -arcEnd - Math.PI / 2, false);
      arcPath.quadraticCurveTo(boxCx, boxY - 30, boxCx, boxY - 60);
      arcGroup
        .append('g')
        .attr('class', 'link')
        .datum({ angle })
        .append('path')
        .attr('class', classNames('link-line', { filtered: this.hasSidebarFilters }))
        .attr('d', arcPath.toString());
    }

    if (maxAngle - linkCurve > arcEnd) {
      const arcPath = path();
      const angle = maxAngle - linkCurve;

      arcPath.arc(boxCx, boxCy, radius, angle - Math.PI / 2, arcEnd - Math.PI / 2, true);
      arcPath.quadraticCurveTo(boxCx, boxY - 30, boxCx, boxY - 60);
      arcGroup
        .append('g')
        .attr('class', 'link')
        .datum({ angle })
        .append('path')
        .attr('class', classNames('link-line', { filtered: this.hasSidebarFilters }))
        .attr('d', arcPath.toString());
    }

    linksGroup
      .append('circle')
      .attr('class', classNames('link-head', { filtered: this.hasSidebarFilters }))
      .attr('cx', boxCx)
      .attr('cy', boxY - 60)
      .attr('r', 3);

    linksGroup
      .append('path')
      .attr('class', classNames('link-line', { filtered: this.hasSidebarFilters }))
      .attr('d', ({ cx, cy, tag, labelRadius, angle, paddingTop }) => {
        const linkPath = path();

        if (tag === 'circle' || tag === 'rect') {
          linkPath.moveTo(cx, cy - paddingTop);
          linkPath.bezierCurveTo(cx, boxY, boxCx, boxY, boxCx, boxY - 60);
        } else {
          linkPath.moveTo(boxCx + Math.sin(angle) * labelRadius, boxCy - Math.cos(angle) * labelRadius);

          if (angle + linkCurve < -arcEnd || angle - linkCurve > arcEnd) {
            linkPath.quadraticCurveTo(
              boxCx + Math.sin(angle) * radius,
              boxCy - Math.cos(angle) * radius,
              boxCx + Math.sin(angle - linkCurve * (angle < 0 ? -1 : 1)) * radius,
              boxCy - Math.cos(angle - linkCurve * (angle < 0 ? -1 : 1)) * radius
            );
          } else {
            linkPath.bezierCurveTo(
              boxCx + Math.sin(angle) * radius,
              boxCy - Math.cos(angle) * radius,
              boxCx,
              boxY - 20,
              boxCx,
              boxY - 60
            );
          }
        }

        return linkPath.toString();
      });
  }

  // will hover necessary links on node hover
  hoverLinksForNode(hoveredNodeValue, direction = null) {
    // root node
    if (hoveredNodeValue === -1) {
      return;
    }

    // find all links adjacent to current hoverNode
    select(this.svg.current)
      .selectAll('.links-node .link')
      // filter links that already hovered, just in case to prevent infinite loop
      .filter(function filterHoveredLink() {
        return this.classList.contains('link-hovered') === false;
      })
      .filter((d) => {
        // for initial node we need to go both ways
        if (direction === null) {
          return d.source.value === hoveredNodeValue || d.target.value === hoveredNodeValue;
        }

        // we only need links that go to other nodes, not to current one, thats why we check for reverseDirection node
        const reverseDirection = direction === 'target' ? 'source' : 'target';
        return d[reverseDirection]?.value === hoveredNodeValue;
      })
      // hover link
      .classed('link-hovered', true)
      // hover every other node this node connected too
      .each((d) => {
        // going right side of the tree
        if (d.target.value !== hoveredNodeValue) {
          this.hoverLinksForNode(d.target.value, 'target');
        }

        // going left side of the tree
        if (d.source.value !== hoveredNodeValue) {
          this.hoverLinksForNode(d.source.value, 'source');
        }
      });
  }

  getRegionBoxPositions = memoize(
    ({ source }) => {
      const regionRect = this.map.current.querySelector('.box-region')?.getBoundingClientRect();
      const regionLeft = (regionRect?.left ?? 0) - source.svg.rect.left;
      const regionRight = (regionRect?.right ?? 0) - source.svg.rect.left;
      const regionTop = (regionRect?.top ?? 0) - source.svg.rect.top;
      const regionBottom = (regionRect?.bottom ?? 0) - source.svg.rect.top;

      const regionCx = ((regionRect?.right ?? 0) - (regionRect?.left ?? 0)) / 2;
      const regionCy = ((regionRect?.regionTop ?? 0) - (regionRect?.regionBottom ?? 0)) / 2;

      return { regionLeft, regionRight, regionCx, regionCy, regionTop, regionBottom };
    },
    // region box left and right position wont change
    () => this.map.current.querySelector('.box-region')?.className
  );

  canLinkHighlight() {
    return true;
  }

  highlightLinks() {
    const { hoveredLink, selectedLink, hoveredBoxLink, selectedBoxLink } = this.state;
    const nodeLinks = select(this.svg.current)
      .selectAll('.links-node .link')
      .filter((d) => this.canLinkHighlight(d));
    const boxLinks = select(this.svg.current).selectAll('.links-box .link');

    nodeLinks.classed('link-hovered', (d) => d.index === hoveredLink);
    nodeLinks.classed('link-unhovered', (d) => hoveredLink > -1 && d.index !== hoveredLink);
    nodeLinks.classed('link-selected', (d) => d.index === selectedLink);

    boxLinks.classed('link-hovered', (d) => d.index === hoveredBoxLink);
    boxLinks.classed('box-link-selected', (d) => d.index === selectedBoxLink);

    nodeLinks.filter((d) => d.animated).raise();
    nodeLinks.filter('.link-hovered').raise();
    nodeLinks.filter('.link-selected').raise();
  }

  getSidebarConfig({ selectedNode } = this.state) {
    return { ...selectedNode };
  }

  getLinkHealth(link) {
    const { topology } = this.state;
    const healthData = get(topology, 'health.interfaces');

    if (link && link.connections) {
      // which layer connections to aggregate
      const layer = link.connections.some((connection) => connection.layer === 2) ? 2 : 3;

      return link.connections
        .filter((connection) => connection.layer === layer)
        .map(({ interface1, interface2 }) => {
          if (!interface1 || !interface2) {
            return null;
          }

          const health1 = get(healthData, `${interface1.device_id}.${interface1.snmp_id}`);
          const health2 = get(healthData, `${interface2.device_id}.${interface2.snmp_id}`);

          // pick the health object with the most checks
          if (health1 && (!health2 || health1.checks.length >= health2.checks.length)) {
            return {
              health: health1,
              fromHealth: health1,
              toHealth: health2,
              fromInterface: interface1,
              toInterface: interface2
            };
          }

          if (health2) {
            return {
              health: health2,
              fromHealth: health2,
              toHealth: health1,
              fromInterface: interface2,
              toInterface: interface1
            };
          }

          return null;
        })
        .filter((health) => health !== null);
    }

    return [];
  }

  getLinkTraffic(link) {
    const interfaceTraffic =
      link.traffic ||
      this.getLinkHealth(link).map(({ health, fromHealth, toHealth, fromInterface, toInterface }) => {
        const inTraffic = get(
          health && health.checks.find(({ check_name }) => check_name === 'interface_performance_inoctets'),
          'usage',
          0
        );
        const outTraffic = get(
          health && health.checks.find(({ check_name }) => check_name === 'interface_performance_outoctets'),
          'usage',
          0
        );

        return {
          fromInterface,
          toInterface,
          inTraffic,
          outTraffic,
          inHealthClass: getHealthClass(toHealth, true),
          outHealthClass: getHealthClass(fromHealth, true)
        };
      });

    if (interfaceTraffic.length > 0) {
      return interfaceTraffic.reduce(
        (acc, curr) => {
          acc.fromInterfaces.push(curr.fromInterface);
          acc.toInterfaces.push(curr.toInterface);
          acc.inTraffic += Math.round(curr.inTraffic);
          acc.outTraffic += Math.round(curr.outTraffic);
          acc.inHealthClass = acc.inHealthClass
            ? mergeConnectionHealthStates(acc.inHealthClass, curr.inHealthClass)
            : curr.inHealthClass;
          acc.outHealthClass = acc.outHealthClass
            ? mergeConnectionHealthStates(acc.outHealthClass, curr.outHealthClass)
            : curr.outHealthClass;
          return acc;
        },
        { fromInterfaces: [], toInterfaces: [], inTraffic: 0, outTraffic: 0, inHealthClass: '', outHealthClass: '' }
      );
    }

    return null;
  }

  getTrafficColor(bps) {
    const { $hybridMap } = this.props;
    const { maxTraffic } = $hybridMap;
    return getTrafficColor(bps, maxTraffic);
  }

  getSelected(type) {
    const { selectedNode } = this.state;
    return selectedNode && selectedNode.type === type ? selectedNode.value : null;
  }

  getSelectedItem(type, items, prop = 'id') {
    const selected = this.getSelected(type);
    return selected ? items.find((item) => item[prop] === selected) : null;
  }

  getHovered(type) {
    const { hoveredNode } = this.state;
    return hoveredNode && hoveredNode.type === type ? hoveredNode.value : null;
  }

  getNodeLinks() {
    return [];
  }

  getNodeLinkQueries() {
    return [];
  }

  getHighlighted(type) {
    const { nodeLinks } = this.state;
    const selected = this.getSelected(type);
    return nodeLinks
      .map(({ source, target }) => {
        if (source.type === type && source.value !== selected) {
          return source.value;
        }

        if (target.type === type && target.value !== selected) {
          return target.value;
        }

        return null;
      })
      .filter(Boolean);
  }

  getHighlightedItems(type, items, prop = 'id') {
    return this.getHighlighted(type).map((id) => items.find((item) => item[prop] === id) || { [prop]: id });
  }

  isBoxHighlighted(type) {
    return this.nodeLinks.some(
      ({ source, target }) =>
        (source.type === 'box' && source.value === type) || (target.type === 'box' && target.value === type)
    );
  }

  setBoxExpanded(type, expanded) {
    this.setState(({ boxExpanded }) => ({ boxExpanded: { ...boxExpanded, [type]: expanded } }));
  }

  handleSelectNode({ type, subType, value, force = false, nodeData }) {
    this.setState(({ selectedNode, activeInternetTabId }) => {
      if (selectedNode && selectedNode.type === type && selectedNode.value === value && !force) {
        return null;
      }

      subType = subType || (type === 'internet' && activeInternetTabId) || null;
      const newSelectedNode = value !== null ? { type, subType, value, nodeData } : null;

      return {
        selectedNode: newSelectedNode,
        selectedLink: -1,
        hoveredLink: -1,
        activeNode: null // activeNodes can interfere with drawing node link connections
      };
    });
  }

  activateNode = (activeNode) => {
    this.setState({ activeNode, nodeLinks: [] });
    this.drawLinks();
  };

  handleHoverLink = debounce((link) => {
    if (link) {
      const { index, title } = link;
      const newState = { hoveredLink: index };

      if (title && event) {
        newState.linkTooltipOpen = true;
        newState.linkTooltip = { position: { left: event.offsetX, top: event.offsetY }, content: title };
      } else {
        newState.linkTooltipOpen = false;
      }

      this.setState(newState);
    } else {
      this.setState({ hoveredLink: -1, linkTooltipOpen: false });
    }
  }, 50);

  setNodeLinks = (node) => {
    this.setState((state) => {
      const nodeLinks = node ? this.getNodeLinks(node) : [];
      const nodeLinkQueries = node ? this.getNodeLinkQueries({ ...state, selectedNode: node }) : [];

      return {
        nodeLinks,
        nodeLinkQueries,
        nodeLinksLoading: nodeLinkQueries.length > 0
      };
    });
  };

  handleHoverNode(type, value) {
    const { hoveredNode, activeNode, nodeLinks } = this.state;
    if (!activeNode && nodeLinks.length === 0 && !isEqual(hoveredNode, { type, value })) {
      this.setState({ hoveredNode: value !== null ? { type, value } : null });
    }
  }

  handleUnhoverNode(type, value) {
    const { hoveredNode } = this.state;

    if (hoveredNode && hoveredNode.type === type && hoveredNode.value === value) {
      this.setState({ hoveredNode: null });
    }
  }

  handleInternetTabChange = (tabId) => {
    const { selectedNode } = this.state;

    this.setState({ activeInternetTabId: tabId });

    if (selectedNode) {
      if (selectedNode.type === 'internet') {
        this.handleSelectNode({ type: 'internet', value: null });
      } else {
        this.handleSelectNode({ ...selectedNode, force: true });
      }
    }
  };

  // show nothing when traffic is '0'
  cleanBoxLinkTraffic = (boxLinks) =>
    boxLinks.map((link) => ({
      ...link,
      bytesIn: link.bytesIn > 0 ? link.bytesIn : null,
      bytesOut: link.bytesOut > 0 ? link.bytesOut : null
    }));

  handleBoxLinksUpdate = (boxLinks) => {
    const links = this.cleanBoxLinkTraffic(boxLinks);

    this.setState({ boxLinks: links });
  };

  handleNodeLinksUpdate(nodeLinks) {
    this.setState({ nodeLinks: this.getNodeLinks().concat(nodeLinks), nodeLinksLoading: false });
  }

  handleDrawLinksRequest = debounce(() => this.drawLinks(), 100);

  renderMap() {
    return null;
  }

  handleMapClick = (e) => {
    const { setSidebarDetails } = this.props;
    const { nodeLinksLoading, selectedNode } = this.state;
    const selectableNode = e.target.closest('.hybrid-map-selectable-node');
    const selectableLink = e.target.closest('.hybrid-map-selectable-link');
    const selectable = selectableNode || selectableLink;

    if (!selectableLink && !nodeLinksLoading) {
      this.setState((prevState) => {
        const isSameNode = isEqual(selectedNode, prevState.selectedNode);
        const nextState = { selectedNode: selectableNode ? prevState.selectedNode : null };

        if (!isSameNode && setSidebarDetails) {
          setSidebarDetails(null);
        }

        if (!isSameNode || !selectable) {
          nextState.nodeLinks = [];
          nextState.nodeLinkQueries = [];
          nextState.nodeLinksLoading = false;
          nextState.selectedBoxLink = -1;
          nextState.activeNode = null;
        }

        return nextState;
      });
    }

    if (!selectable && setSidebarDetails && this.shouldCloseSidebarOnMapClick()) {
      // clear the sidebar if nothing is selected
      setSidebarDetails(null);
      this.setState({ selectedLink: -1 });
    }
  };

  render() {
    const { wrapperProps } = this.props;
    const { linkTooltip, linkTooltipOpen } = this.state;

    return (
      <Wrapper ref={this.map} onClick={this.handleMapClick} {...wrapperProps}>
        {this.renderMap()}
        <svg ref={this.svg} className="overlay">
          <g ref={this.boxLinksGroup} className="links-box" />
          <g ref={this.nodeLinksGroup} className="links-node" />
          <g ref={this.chordLinksGroup} className="links-node" />
        </svg>
        <Tooltip
          isOpen={linkTooltipOpen}
          boundary="viewport"
          position="bottom"
          content={linkTooltip.content}
          target={<div />}
          targetProps={{ style: { pointerEvents: 'none', position: 'absolute', ...linkTooltip.position } }}
        />
      </Wrapper>
    );
  }
}
