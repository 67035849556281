import React from 'react';
import { inject } from 'mobx-react';
import { compact as _compact } from 'lodash';
import { FaCheck } from 'react-icons/fa';
import { uriToObject } from '@kentik/ui-shared/util/map';
import { Flex, Heading, Tag } from 'core/components';
import { GcpRouteTable } from 'app/views/hybrid/maps/gcp/components';
import { GCP_ENTITY_TYPES } from '@kentik/ui-shared/hybrid/constants';
import SidebarItem from '../SidebarItem';
import { greekDisplay } from '../cloudUtil';

const { NETWORK, REGION, SUBNET } = GCP_ENTITY_TYPES;

const getFormattedRoutes = ({ routes, cidrs }) => {
  if (!routes) {
    return [];
  }

  const routesArray = routes.map((route) => {
    let nextHopType;
    let nextHop;

    const {
      name,
      tags,
      warnings,
      nextHopGateway,
      nextHopNetwork,
      nextHopInstance,
      nextHopIp,
      nextHopPeering,
      nextHopIlb,
      nextHopVpnTunnel,
      destRange,
      description,
      id,
      priority
    } = route;

    if (cidrs && !cidrs.includes(destRange)) {
      return null;
    }
    const nextHopUri =
      nextHopGateway ||
      nextHopNetwork ||
      nextHopInstance ||
      nextHopPeering ||
      nextHopIlb ||
      nextHopVpnTunnel ||
      nextHopIp;

    const { gateway, network } = uriToObject(nextHopUri, false, true);

    nextHop = gateway ?? network;
    nextHopType = gateway ? 'Gateway' : 'Network';

    if (!nextHop) {
      if (nextHopPeering) {
        // network peerings
        nextHopType = 'Peering';
        nextHop = nextHopPeering;
      } else if (nextHopVpnTunnel) {
        // vpn tunnels
        nextHopType = 'VpnTunnel';
        nextHop = nextHopVpnTunnel;
      } else if (nextHopIlb) {
        // forwardingRules
        nextHopType = 'ForwardingRule';
        const { forwardingRule } = uriToObject(nextHopIlb, false, true);
        nextHop = forwardingRule;
      } else if (nextHopIp) {
        nextHopType = 'Ip';
        // fallback to nextHopIp if we have it
        nextHop = nextHopIp;
      }
    }

    return {
      target: { nextHop, description, id },
      destination: destRange,
      route,
      nextHopType,
      priority,
      name,
      tags,
      warnings
    };
  });
  return _compact(routesArray);
};

const GcpRouteTablePopover = ({ width, popoutTitle, $hybridMap, ...rest }) => {
  const { nodeData, type } = rest;

  let routes;
  const { gcpCloudMapCollection } = $hybridMap;
  const {
    topology: {
      Entities: { routeTables }
    }
  } = gcpCloudMapCollection;

  if (!routeTables) {
    console.error('Missing routeTables!');
    return null;
  }

  switch (type) {
    case NETWORK: {
      routes = getFormattedRoutes({ routes: routeTables[nodeData.selfLink]?.routes });
      break;
    }
    case SUBNET: {
      routes = getFormattedRoutes({ routes: routeTables[nodeData.network]?.routes, cidrs: [nodeData.ipCidrRange] });
      break;
    }
    // we don't show route table for GCP Region sidebar
    case REGION:
    default: {
      routes = [];
    }
  }

  return (
    <SidebarItem
      excludeFormProps
      title={
        <Flex alignItems="center" gap={1} width="100%">
          <Heading level={5} mb={0}>
            Route Table
          </Heading>
          {routes.length > 0 && (
            <Tag intent="success" icon={FaCheck} title={`${routes.length} active routes`} minimal round flexGrow={1}>
              {greekDisplay(routes.length)}
            </Tag>
          )}
        </Flex>
      }
      popoutTitle={popoutTitle}
      icon="route"
      dialogProps={{ width: 'auto' }}
    >
      <GcpRouteTable routes={routes} />
    </SidebarItem>
  );
};

export default inject('$hybridMap')(GcpRouteTablePopover);
