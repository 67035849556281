import { range, commaSeparatedNumbers, commaSeparatedNumberRanges } from './validationHelpers';

const portDetails = {
  delimiters: {
    maxNumOfItems: 100,
    minVal: 0,
    maxVal: 65535
  },
  message: 'Up to 100 comma separated list of port numbers between 0 to 65535'
};

const vlanDetails = {
  delimiters: {
    maxNumOfItems: 100,
    minVal: 0,
    maxVal: 4095
  },
  message: 'Up to 100 comma separated list of integers and ranges of integers between 0 to 4095'
};

const protocolDetails = {
  delimiters: {
    maxNumOfItems: 100,
    minVal: 0,
    maxVal: 255
  },
  message: 'Up to 100 comma separated list of protocol numbers between 0 to 255'
};

const asnDetails = {
  delimiters: {
    maxNumOfItems: 100,
    minVal: 0,
    maxVal: 4294967295
  },
  message: 'Up to 100 comma separated list of ASN numbers between 1 and 2^32-1=4294967295'
};

export const portValidator = { message: portDetails.message, fn: commaSeparatedNumberRanges(portDetails.delimiters) };
export const vlanValidator = { message: vlanDetails.message, fn: commaSeparatedNumberRanges(vlanDetails.delimiters) };
export const protocolValidator = {
  message: protocolDetails.message,
  fn: commaSeparatedNumbers(protocolDetails.delimiters)
};
export const asnValidator = { message: asnDetails.message, fn: commaSeparatedNumberRanges(asnDetails.delimiters) };

export const nextHopAsnValidator = Object.assign({}, asnValidator, {
  message: 'Up to 100 comma separated list of Nexthop ASN numbers between 1 and 2^32-1=4294967295'
}); // same delimiters as asnDetails

export const tcpFlagValidator = { message: 'TCP flag mask between 0 to 255', fn: range(0, 255) };

export const countryValidator = {
  message: 'Up to 100 comma separated list of 2 digit Country codes',
  fn: (value) => value && value.trim().split(',').length <= 500 && /^([A-Za-z]{2}\s*,?\s*)+$/gi.test(value)
};

export const macValidator = {
  message: 'Up to 100 comma separated list of MAC addresses',
  fn: (value) =>
    value &&
    /^([0-9A-Fa-f]{2}[:-]){5,7}([0-9A-Fa-f]{2})((\s*,\s*)([0-9A-Fa-f]{2}[:-]){5,7}([0-9A-Fa-f]{2}))*$/gi.test(value)
};

export const bgpCommunityValidator = {
  message: `Comma separated list of numbers or regular expression representing bgp community
  (i.e. 2096:2212) Permitted characters []*:_^$.0123456789()+?,space-`,
  fn: (value) =>
    value &&
    value.trim().split(',').length <= 500 &&
    /^\s*[-[\]*:_^$.0-9()+?\s]+\s*(,\s*[-[\]*:_^$.0-9()+?\s]+\s*){0,99}$/i.test(value)
};

export const bgpAspathValidator = {
  message: `Comma separated list of numbers or regular expression representing bgp aspath.
   Example "^3737 1212, _7801_, 2906$" would look for any of those 3 combinations in the AS path.
   Permitted characters []*:_^$.0123456789()+?,space-`,
  fn: (value) =>
    value &&
    value.trim().split(',').length <= 500 &&
    /^\s*[-[\]*:_^$.0-9()+?\s]+\s*(,\s*[-[\]*:_^$.0-9()+?\s]+\s*){0,99}$/i.test(value)
};

export const interfaceNameValidator = {
  message: 'Comma separated list of interface names (no pipe or double quote allow)',
  fn: (value) => value && value.trim().split(',').length <= 5000 // && /^[ -!#-{}-~]+(\s*,\s*[ -!#-{}-~]*)*$/i.test(value)
  // match all printable ascii characters but double quote and pipe "|
};
