import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { EmptyState, Spinner, Tab, Tabs } from 'core/components';
import TopKeysListing from './TopKeysListing';

@inject('$hybridMap', '$networkClass')
@observer
class TopKeys extends Component {
  static defaultProps = {
    defaultTab: 'asn',
    showOriginNetworksTab: true,
    showProvidersTab: true,
    showNextHopNetworksTab: true,
    excludeInternalASNs: false,
    isKube: false,
    aggregateNames: ['Inbound', 'Outbound']
  };

  state = {
    isLoading: true
  };

  componentDidMount() {
    const { $networkClass } = this.props;
    // contains internal asns we want loaded so we can have them participate in query filters
    $networkClass.model.fetch().finally(() => this.setState({ isLoading: false }));
  }

  render() {
    const {
      $hybridMap,
      excludeInternalASNs,
      defaultTab,
      classPrefix,
      selected,
      highlighted,
      layout,
      aggregateNames,
      site,
      device,
      cloud,
      cloudRegion,
      cloudVpc,
      onSelect,
      onTabChange,

      showOriginNetworksTab,
      showProvidersTab,
      showNextHopNetworksTab,

      isKube,
      namespace
    } = this.props;
    const { isLoading } = this.state;

    if (isLoading) {
      return <Spinner m="0 auto" />;
    }

    return (
      <Tabs defaultSelectedTabId={defaultTab} onChange={onTabChange} flexed mt={-1} renderActiveTabPanelOnly>
        {showOriginNetworksTab && (
          <Tab
            id="asn"
            title="Origin Networks"
            className="legend-tab"
            panel={
              <TopKeysListing
                name="Origin Networks"
                classPrefix={classPrefix}
                selected={selected}
                highlighted={highlighted}
                onSelect={onSelect}
                layout={layout}
                aggregateNames={aggregateNames}
                query={$hybridMap.getASNQuery({
                  type: 'ASNS',
                  excludeInternalASNs,
                  site,
                  device,
                  cloud,
                  cloudRegion,
                  cloudVpc,
                  isKube,
                  namespace
                })}
                emptyState={<EmptyState icon="folder-close" description="No origin networks" />}
              />
            }
          />
        )}
        {showProvidersTab && (
          <Tab
            id="provider"
            title="Providers"
            className="legend-tab"
            panel={
              <TopKeysListing
                classPrefix={classPrefix}
                selected={selected}
                highlighted={highlighted}
                onSelect={onSelect}
                layout={layout}
                aggregateNames={aggregateNames}
                query={$hybridMap.getASNQuery({ type: 'PROVIDERS', site, device, cloud, cloudRegion, cloudVpc })}
                emptyState={<EmptyState icon="folder-close" description="No providers" />}
              />
            }
          />
        )}
        {showNextHopNetworksTab && (
          <Tab
            id="nextHopASN"
            title="Next-Hop Networks"
            className="legend-tab"
            panel={
              <TopKeysListing
                classPrefix={classPrefix}
                selected={selected}
                highlighted={highlighted}
                onSelect={onSelect}
                layout={layout}
                aggregateNames={aggregateNames}
                query={$hybridMap.getASNQuery({ type: 'NEXTHOP_ASNS', site, device, cloud, cloudRegion, cloudVpc })}
                emptyState={<EmptyState icon="folder-close" description="No next-hop networks" />}
              />
            }
          />
        )}
      </Tabs>
    );
  }
}

export default TopKeys;
