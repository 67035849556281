import styled from 'styled-components';
import { space, width } from 'styled-system';
import { Spinner as BPSpinner } from '@blueprintjs/core';
import position from './utils/position';

const Spinner = styled(BPSpinner)`
  ${space};
  ${width};
  ${position};
`;

export default Spinner;
