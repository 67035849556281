import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from 'core/components';
import { getConsoleUrl } from 'app/views/hybrid/utils/aws';

const AWSConsoleLink = ({ hideLabel, ...rest }) => {
  const awsConsoleUrl = getConsoleUrl({ ...rest });

  if (!awsConsoleUrl) {
    return null;
  }

  return (
    <LinkButton
      key="clear"
      icon="panel-stats"
      to={awsConsoleUrl}
      title="Open in AWS Console"
      ml="auto"
      blank
      minimal
      small
      {...rest}
    >
      {!hideLabel && 'AWS Console'}
    </LinkButton>
  );
};

AWSConsoleLink.propTypes = {
  hideLabel: PropTypes.bool,
  nodeData: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

AWSConsoleLink.defaultProps = {
  hideLabel: false
};

export default React.memo(AWSConsoleLink);
