import classNames from 'classnames';
import { memoize } from 'lodash';
import { mix } from 'polished';
import React from 'react';
import { useTheme } from 'styled-components';
import { ReactComponent as ClusterIcon } from 'app/assets/icons/kubernetes/cluster.svg';
import { ReactComponent as DeploymentIcon } from 'app/assets/icons/kubernetes/deploy.svg';
import { ReactComponent as IngressIcon } from 'app/assets/icons/kubernetes/ing.svg';
import { ReactComponent as NamespaceIcon } from 'app/assets/icons/kubernetes/ns.svg';
import { ReactComponent as NodeIcon } from 'app/assets/icons/kubernetes/node.svg';
import { ReactComponent as PodIcon } from 'app/assets/icons/kubernetes/pod.svg';
import { ReactComponent as ServiceIcon } from 'app/assets/icons/kubernetes/svc.svg';
import { Text, TextMeasure } from 'app/components/svg';
import { getMapClassname } from '../../utils/map';

export const icons = {
  clusters: ClusterIcon,
  deployments: DeploymentIcon,
  ingresses: IngressIcon,
  namespaces: NamespaceIcon,
  nodes: NodeIcon,
  pods: PodIcon,
  services: ServiceIcon
};

const resourceCss = memoize(
  (theme) => {
    const warningCss = {
      '.node-bg': {
        fill: 'transparent'
      }
    };

    const criticalCss = {
      '.node-bg': {
        fill: 'transparent'
      }
    };

    const healthyCss = {
      '.node-bg': {
        fill: 'transparent'
      }
    };

    return {
      '.node-bg': {
        fill: 'transparent'
      },

      '.health-indicator': {
        fill: 'transparent',
        '&.healthy': { fill: theme.colors.success },
        '&.empty': { fill: theme.colors.gray1 }
      },
      '&.warning': {
        ...warningCss,
        '[class$="_svg__icon-bg"]': { fill: theme.colors.warning }
      },
      '&.critical': {
        ...criticalCss,
        '[class$="_svg__icon-bg"]': { fill: theme.colors.danger }
      },
      // '&.healthy': { ...healthyCss, '[class$="_svg__icon-bg"]': { fill: theme.colors.success } },
      '&:hover': {
        cursor: 'pointer'
        // '.node-bg': {
        //   stroke: theme.name === 'light' ? theme.colors.gray1 : theme.colors.lightGray5,
        //   strokeWidth: 2
        // },
        // '&.warning': warningCss,
        // '&.critical': criticalCss,
        // '&.healthy': healthyCss
      },
      '&.node-selected': {
        // '.node-bg': {
        //   stroke: theme.colors.primary,
        //   strokeWidth: 2
        // },
        '&.warning': warningCss,
        '&.critical': criticalCss,
        '&.healthy': healthyCss
      },
      '&.node-highlighted': {
        // '.node-bg': {
        //   stroke: theme.colors.primary,
        //   strokeWidth: 2,
        //   strokeDasharray: '7 3'
        // },
        '&.warning': warningCss,
        '&.critical': criticalCss,
        '&.healthy': healthyCss
      },
      '&.node-unselected:not(:hover)': {
        '.node-bg': {
          fill: mix(0.85, theme.colors.subnavBackground, theme.colors.appBackground)
          // strokeOpacity: 0.3
        },
        'text, svg': {
          opacity: 0.3
        }
        // '&.warning': {
        //   '.node-bg': {
        //     fill: mix(0.85, theme.colors.subnavBackground, theme.colors.warning)
        //   }
        // },
        // '&.critical': {
        //   '.node-bg': {
        //     fill: mix(0.85, theme.colors.subnavBackground, theme.colors.danger)
        //   }
        // },
        // '&.healthy': {
        //   '.node-bg': {
        //     fill: mix(0.85, theme.colors.subnavBackground, theme.colors.success)
        //   }
        // }
      }
    };
  },
  (theme) => theme.name
);

export default function ResourceItem({
  id,
  label = '',
  type,
  empty = false,
  subType = '',
  className = '',
  titleStyles = {},
  onClick
}) {
  const theme = useTheme();
  const iconSize = 40;
  const padding = 12;
  const height = iconSize + padding;
  const width = iconSize + padding;

  const Icon = icons[subType || type];
  const emptyClassName = empty && 'empty';

  return (
    <g className={className} onClick={onClick} css={resourceCss(theme)}>
      <rect
        className={classNames('node-bg', getMapClassname({ type, value: id }))}
        x={-width / 2}
        y={-height / 2}
        width={width}
        height={height}
        rx={4}
      />
      {Icon && (
        <g transform={`translate(${-iconSize / 2}, ${-iconSize / 2})`}>
          <Icon width={iconSize} height={iconSize} />
        </g>
      )}
      <g transform={`translate(${iconSize / 2 - iconSize / 4}, ${-iconSize / 2 + iconSize / 10})`}>
        <circle className={classNames('health-indicator', className, emptyClassName)} cx="0" cy="0" r="5" />
      </g>

      {label && (
        <TextMeasure text={label}>
          {({ text, width: textWidth }) => (
            <>
              <rect
                width={textWidth + 4}
                height={20}
                x={-textWidth / 2 - 2}
                y={-((height + 20) / 2 + 15)}
                fill={theme.colors.subnavBackground}
              />
              <Text x={-textWidth / 2} y={-(height / 2 + 15)} dominantBaseline="central" {...titleStyles}>
                {text}
              </Text>
            </>
          )}
        </TextMeasure>
      )}
    </g>
  );
}
