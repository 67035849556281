import React, { Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';

import { Box, Button, ConfirmDialog, Flex, Heading } from 'core/components';
import { showSuccessToast } from 'core/components/toast';
import headerBg from 'app/assets/interstitials/interstitial-bg.svg';

import Page from 'app/components/page/Page';

export const Header = styled(Box)`
  background: #345583 url('${headerBg}');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: auto 100%;
  width: 100vw;

  div.heading-wrapper {
    margin: 0 auto;
    max-width: 1250px;

    @media (min-width: 1600px) {
      max-width: 1600px;
    }
  }
  .bp4-heading {
    font-weight: 700;
    line-height: 34px;
    color: #ffffff;

    overflow-wrap: break-word;
  }
`;

@inject('$app', '$auth')
@observer
export default class NotPermittedView extends Component {
  state = {
    isDialogOpen: false
  };

  showDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  hideDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  onConfirm = () => {
    const { $app, $auth, requestFeature } = this.props;
    const { platform, userAgent } = window.navigator;
    const { pathname } = window.location;
    const { clientWidth, clientHeight } = document.querySelector('body');

    const payload = {
      clientHeight,
      clientWidth,
      info: {
        type: 'enable feature',
        summary: `Enable Feature: ${requestFeature}`,
        description: `Enable feature request for ${requestFeature} for ${$auth.companyFullName}`
      },
      pathname,
      platform,
      userAgent
    };

    $app.submitReportIssue(payload, true).then(() => {
      showSuccessToast('Feedback was submitted successfully. Thanks!');
      this.hideDialog();
    });
  };

  render() {
    const {
      title,
      heading,
      mainContent,
      mainSubContent,
      screenshot,
      footerTitle,
      footerLeft,
      footerMid,
      footerRight,
      confirmDialogProps,
      requestHelpButtonText
    } = this.props;

    const { isDialogOpen } = this.state;

    const confirmButton = (
      <Button mb={2} large intent="danger" onClick={this.showDialog}>
        {requestHelpButtonText}
      </Button>
    );
    return (
      <Page
        title={title}
        pt={0}
        scrolls
        toolbar={
          <Header py={4} pl={2} mb={2}>
            <div className="heading-wrapper">
              <Heading px={2} maxWidth={700} level={1} fontSize={28}>
                {heading}
              </Heading>
            </div>
          </Header>
        }
      >
        <Flex alignItems="center" justifyContent="center" mb={2}>
          <Box flex={1} mr={4}>
            {mainContent}
            {confirmButton}
            {mainSubContent}
          </Box>
          <Box flex={1}>{screenshot}</Box>
        </Flex>
        <hr />
        <Box>
          <Heading mb={2} level={2}>
            {footerTitle}
          </Heading>
          <Flex justifyContent="center" mb={2}>
            <Box flex={1}>{footerLeft}</Box>
            <Box flex={1} mx={2}>
              {footerMid}
            </Box>
            <Box flex={1}>{footerRight}</Box>
          </Flex>
          {confirmButton}
        </Box>
        <ConfirmDialog
          onConfirm={this.onConfirm}
          onCancel={this.hideDialog}
          {...confirmDialogProps}
          isOpen={isDialogOpen}
        />
      </Page>
    );
  }
}
