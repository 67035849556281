import { inject } from 'mobx-react';
import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import classNames from 'classnames';
import { isEqual } from 'lodash';

import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { Box, ButtonLink, Flex, Text, Heading } from 'core/components';
import { getMapClassname, getEntityType } from 'app/views/hybrid/utils/map';
import getScrollbarWidth from 'core/util/getScrollbarWidth';
import { Grid } from 'app/components/svg';

import VNet from './VNet';
import VNetMap from './VNetMap';

const { LOCATION, VIRTUAL_HUB } = ENTITY_TYPES.get('azure');

const miniItemMargin = 3;
const miniItemSize = 20;

const normalItemMarginX = 12;
const normalItemMarginY = 20;
const normalItemWidth = 170;
const normalItemHeight = 52;

const expandedItemMargin = normalItemMarginX;
const expandedItemWidth = normalItemWidth;
const expandedItemHeight = normalItemHeight + 76;

const connectionItemWidth = 160;

@withTheme
@inject('$hybridMap')
export default class Location extends Component {
  static defaultProps = {
    width: 500,
    padding: 16,
    selectedVNets: [],
    selected: false,
    highlighted: false,
    colorBy: 'inbound_bits_per_second',
    highlightedVNets: []
  };

  static getDerivedStateFromProps(props, state) {
    const { colorBy, location } = props;
    const { vnets = [] } = location;

    if (colorBy !== state.colorBy) {
      const maxBps = Math.max(1_000_000_000, ...vnets.map((vnet) => vnet.traffic?.[colorBy] || 0));
      return { colorBy, maxBps };
    }

    return null;
  }

  state = {
    expanded: false
  };

  shouldComponentUpdate(prevProps, prevState) {
    const { width, selectedVNets, selected, highlighted, highlightedVNets, location } = this.props;
    return (
      prevState !== this.state ||
      prevProps.width !== width ||
      prevProps.selectedVNets !== selectedVNets ||
      !isEqual(prevProps.selected, selected) ||
      !isEqual(prevProps.highlighted, highlighted) ||
      !isEqual(prevProps.highlightedVNets, highlightedVNets) ||
      !isEqual(prevProps.location, location)
    );
  }

  get vnets() {
    const { location } = this.props;
    const { vnets = [] } = location;
    return vnets;
  }

  get virtualHubs() {
    const { location, $hybridMap } = this.props;
    const { azureCloudMapCollection } = $hybridMap;
    return azureCloudMapCollection.topology[VIRTUAL_HUB].filter((virtualHub) => virtualHub.location === location.name);
  }

  get combinedVNetsAndHubs() {
    const { vnets, virtualHubs } = this;
    return [...vnets, ...virtualHubs];
  }

  get canExpand() {
    const { selectedVNets } = this.props;
    return selectedVNets?.length < 1 && this.vnets.length > this.getColumns(false) * 9;
  }

  get mini() {
    const { selectedVNets } = this.props;
    const { expanded } = this.state;
    return !expanded && selectedVNets?.length < 1 && this.canExpand;
  }

  get svgWidth() {
    const { width } = this.props;
    const { locationConnections } = this;
    return width - (locationConnections.length > 0 ? connectionItemWidth + 30 : 0) - connectionItemWidth / 2;
  }

  get availableWidth() {
    const { padding } = this.props;
    return this.svgWidth - getScrollbarWidth() - padding * 2;
  }

  get selectedVNets() {
    const { selectedVNets } = this.props;
    return this.combinedVNetsAndHubs.filter((vnet) => selectedVNets.includes(vnet.id));
  }

  get locationConnections() {
    const { location } = this.props;
    const { TransitGateways = [] } = location;
    return TransitGateways.map((item) => ({ type: 'TransitGateways', ...item }));
  }

  getColumns(mini = this.mini) {
    const { availableWidth } = this;
    return mini
      ? Math.floor((availableWidth + miniItemMargin) / (miniItemSize + miniItemMargin))
      : Math.floor((availableWidth + normalItemMarginX) / (normalItemWidth + normalItemMarginX));
  }

  getVNetColor() {
    const { theme } = this.props;
    return theme.colors.primary;
    // @TODO more logic to follow when color-by traffic functionality is done
  }

  get isExpanded() {
    return this.selectedVNets.length > 0;
  }

  get VNetItemProps() {
    const { padding } = this.props;
    const { mini, isExpanded } = this;

    // assume normal sizing
    const props = {
      maxHeight: 9 * (normalItemHeight + normalItemMarginY) + padding,
      itemWidth: normalItemWidth,
      itemHeight: normalItemHeight,
      itemMarginX: normalItemMarginX,
      itemMarginY: normalItemMarginY
    };

    if (mini) {
      // mini mode has condensed boxes with tooltips
      props.itemWidth = miniItemSize;
      props.itemHeight = miniItemSize;
      props.itemMarginX = miniItemMargin;
      props.itemMarginY = miniItemMargin;
      props.renderTooltipContent = (vnet) => (
        <Box>
          <Box fontWeight="bold">{vnet.name || vnet.id}</Box>
          {vnet.name && (
            <Box fontSize="small" fontStyle="italic">
              {vnet.id}
            </Box>
          )}
          {/* @TODO render address(es) */}
          <Box fontSize="small">{vnet.CidrBlock}</Box>
        </Box>
      );

      return props;
    }

    if (isExpanded) {
      props.itemWidth = expandedItemWidth;
      props.itemHeight = expandedItemHeight;
      props.itemMarginX = expandedItemMargin;
      props.itemMarginY = expandedItemMargin;
    }

    return props;
  }

  getItemClasses(item) {
    const { selected, highlighted } = this.props;
    const isSelected = selected === item.id;
    const isHighlighted = highlighted.includes(item.id);

    return {
      selected: isSelected,
      highlighted: isHighlighted,
      unselected: highlighted.length > 0 && !isSelected && !isHighlighted
    };
  }

  handleToggleExpanded = (evt) => {
    const { onResize } = this.props;
    evt.stopPropagation();
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
    onResize();
  };

  handleShowDetails = (evt) => {
    const { location, onShowDetails } = this.props;
    evt.stopPropagation();
    onShowDetails({ type: LOCATION, nodeData: location });
  };

  handleToggleVNet = (vnet) => {
    const { onToggleVNet } = this.props;
    onToggleVNet(vnet.id);
  };

  render() {
    const { location, $hybridMap, padding, selected, highlighted, highlightedVNets, onShowDetails } = this.props;
    const { vnets, virtualHubs, svgWidth, canExpand, mini, selectedVNets, isExpanded, availableWidth } = this;
    const { azureCloudMapCollection } = $hybridMap;

    return (
      <Box width="100%">
        <Flex alignItems="baseline" justifyContent="space-between" pt={`${padding}px`} px={`${padding}px`}>
          <Flex>
            <Heading level={6} mb={0}>
              {location.displayName}
            </Heading>
            {canExpand && (
              <ButtonLink ml={2} color="muted" small onClick={this.handleToggleExpanded}>
                {mini ? 'Expand' : 'Collapse'}
              </ButtonLink>
            )}
          </Flex>
          <Box>
            <ButtonLink small ml={1} onClick={this.handleShowDetails}>
              Show Details
            </ButtonLink>
            <Text small muted ml={1}>
              {vnets.length} VNet{vnets.length === 1 ? '' : 's'}
            </Text>
          </Box>
        </Flex>
        <Flex justifyContent="space-between" gap={1} mt={`${padding / 2}px`}>
          <Box>
            {selectedVNets.map((selectedVNet) => (
              <Box key={selectedVNet.id} mx={padding} mb={4}>
                <VNetMap
                  width={availableWidth}
                  padding={padding / 2}
                  vnet={selectedVNet}
                  location={location}
                  selected={selected}
                  highlighted={highlighted}
                  onClose={() => this.handleToggleVNet(selectedVNet)}
                  onShowDetails={onShowDetails}
                />
              </Box>
            ))}
            <Box py={`${padding / 2}px`}>
              <Grid
                items={[...vnets, ...virtualHubs]}
                width={svgWidth}
                paddingX={padding}
                paddingY={padding / 2}
                getItemFill={(vnet) => this.getVNetColor(vnet)}
                getItemProps={() => ({
                  innerHeight: isExpanded ? normalItemHeight : null
                })}
                renderItem={(vnetOrHub, props) => {
                  const vnetSelected = selectedVNets.some((selectedVNet) => selectedVNet.id === vnetOrHub.id);
                  const entityType = getEntityType(vnetOrHub);

                  return (
                    <VNet
                      vnet={vnetOrHub}
                      mini={mini}
                      expanded={isExpanded}
                      selected={vnetSelected}
                      className={classNames(
                        'hybrid-map-selectable-node',
                        getMapClassname({ type: entityType, value: vnetOrHub.id }),
                        {
                          highlighted: !vnetSelected && highlightedVNets.includes(vnetOrHub.id),
                          flashing:
                            entityType === VIRTUAL_HUB &&
                            azureCloudMapCollection.isNodeHighlighted({ type: VIRTUAL_HUB, value: vnetOrHub.id }),
                          selected: vnetSelected
                        }
                      )}
                      {...props}
                    />
                  );
                }}
                onItemClick={this.handleToggleVNet}
                {...this.VNetItemProps}
              />
            </Box>
          </Box>
        </Flex>
      </Box>
    );
  }
}
