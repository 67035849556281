import * as React from 'react';
import { observer } from 'mobx-react';
import { isObject as _isObject, isString as _isString } from 'lodash';
import { Box, Flex, Grid, Heading, Link, Text } from 'core/components';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import { uriToObject } from 'shared/util/map';

const RegionSummary = ({ logo, value, cloudProvider, nodeData }) => {
  const resizedLogo = logo ? React.cloneElement(logo, { width: 32, height: 32 }) : <></>; // cannot be null!
  const uriMetadata = uriToObject(nodeData?.id);

  return (
    <Box>
      <Flex borderBottom="thin" p={2} alignItems="center">
        {resizedLogo}
        <Box flex={1} ml={1}>
          <Text as="div" muted>
            Cloud Region Details
          </Text>
          <Heading mb={0} level={4}>
            <Link to={`/v4/core/quick-views/cloud/${cloudProvider}/region/${uriMetadata.region || value}`} blank>
              {uriMetadata.region || nodeData?.displayName || value}
            </Link>
          </Heading>
        </Box>
      </Flex>
      {!['azure', 'gcp'].includes(cloudProvider) && nodeData && (
        <SidebarItem
          icon="more"
          title="Details"
          popoutTitle={RegionSummary.PopoutTitle({ logo: resizedLogo, value })}
          excludeFormProps
        >
          <RegionSummary.DetailsDataSection node={nodeData} cloudProvider={cloudProvider} />
        </SidebarItem>
      )}
    </Box>
  );
};

const awsLabelMap = {
  TransitGateways: 'Transit Gateways',
  InternetGateways: 'Internet Gateways',
  VpnGateways: 'VPN Gateways',
  AvailabilityZones: 'Availability Zones',
  Vpcs: 'VPCs'
};

const ociLabelMap = {
  regionName: 'Region Name',
  regionKey: 'Region Key',
  isHomeRegion: 'Home Region'
};

RegionSummary.DetailsDataSection = ({ node, cloudProvider, ...gridProps }) => {
  const renderItem = (key) => {
    let label = awsLabelMap[key] || key;

    let nodeData;
    let value;
    if (cloudProvider === 'aws') {
      nodeData = _isObject(node[key]) ? Object.values(node[key]) : node[key];
      value = nodeData?.length ?? 0;
    }
    if (cloudProvider === 'gcp') {
      nodeData = _isObject(node[key]) ? Object.values(node[key]) : node[key];
      value = _isString(nodeData) ? nodeData : (nodeData?.length ?? 0);
    }

    if (cloudProvider === 'oci') {
      label = ociLabelMap[key] || key;
      nodeData = _isObject(node[key]) ? Object.values(node[key]) : `${node[key]}`;
      value = _isString(nodeData) ? nodeData : (nodeData?.length ?? 0);
    }

    return (
      <Flex key={key} justifyContent="space-between" alignItems="center">
        <Text muted>{label}</Text>
        <Text small>{value}</Text>
      </Flex>
    );
  };

  return (
    <Grid gridTemplateColumns="1fr 1fr" gridRowGap="4px" gridColumnGap={3} {...gridProps}>
      {cloudProvider === 'aws' && Object.keys(awsLabelMap).map((key) => renderItem(key, cloudProvider))}
      {cloudProvider === 'oci' && Object.keys(ociLabelMap).map((key) => renderItem(key, cloudProvider))}
    </Grid>
  );
};

RegionSummary.PopoutTitle = ({ logo, value }) => (
  <Flex alignItems="center">
    {logo}
    <Box ml={1}>{value} Region</Box>
  </Flex>
);

export default observer(RegionSummary);
