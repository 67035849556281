import { isIpV4Valid } from 'core/util/ip';

export default {
  message: 'This field requires one or more comma-separated IPv4 addresses',
  fn: (value) => {
    let ips = value;
    if (!Array.isArray(value)) {
      ips = value.split(',');
    }

    return ips.length > 0 && ips.every((ip) => ip.indexOf('/') === -1 && isIpV4Valid(ip));
  }
};
