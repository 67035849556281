import { chunk, maxBy } from 'lodash';
import React, { Component } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import classNames from 'classnames';

import { getMapClassname } from 'app/views/hybrid/utils/map';
import { Box, ButtonLink, Flex, Tooltip } from 'core/components';
import { Grid, Path, Rect, Text as SVGText, TextMeasure } from 'app/components/svg';

import { ReactComponent as SubnetIcon } from 'app/assets/icons/subnet.svg';
import { MAP_TYPES, ENTITY_TYPES } from 'shared/hybrid/constants';

import CloudIcon from './CloudIcon';

import CloudItem from './CloudItem';
import { getGatewayProps, getVpcEndpointProps } from './popovers/cloudUtil';

const { SUBNET, NAT_GATEWAY, TRANSIT_GATEWAY, VPC_PEERING_CONNECTION, CORE_NETWORK_ATTACHMENT } =
  ENTITY_TYPES.get('aws');

const zonePaddingX = 16;
const zonePaddingY = 32;
const zoneMargin = 30;

const itemMarginX = 12;
const itemMarginY = 8;
const itemWidth = 160;
const itemHeight = 38;
const itemHeightWithIcons = itemHeight * 2;

const connectionItemWidth = 160;
const connectionItemHeight = 38;

const miniItemSize = connectionItemHeight;
const miniItemMargin = 5;
const groupMargin = 60;

const VpcBox = styled(Box)`
  background-color: ${(props) => rgba(props.theme.colors.calloutOutlineBackgrounds.warning, 0.5)};
  border: 1px solid ${(props) => props.theme.colors.warning};
  border-radius: 4px;
`;

function getConnectionProps(item) {
  const isVpcEndpoint = item.VpcEndpointId;
  const { displayName, type, icon, stroke } = isVpcEndpoint ? getVpcEndpointProps(item) : getGatewayProps(item);

  return {
    title: displayName ?? type,
    subtitle: isVpcEndpoint ? item.ServiceName : item.Name || item.id,
    icon,
    stroke
  };
}

export default class CloudVpcMiniMap extends Component {
  static defaultProps = {
    padding: 8
  };

  state = {
    tooltipOpen: false,
    tooltipItem: null,
    tooltipRect: {}
  };

  get svgWidth() {
    const { width } = this.props;
    return width - 2;
  }

  get availableWidth() {
    const { padding } = this.props;
    return this.svgWidth - padding * 2;
  }

  get zonePositions() {
    const { vpc } = this.props;
    const { availableWidth } = this;
    const { Subnets = [] } = vpc;

    const maxCols = Math.floor((availableWidth - zonePaddingX * 2 + itemMarginX) / (itemWidth + itemMarginX));

    const uniqueAvailZoneIds = [...new Set(Subnets.map((subnet) => subnet.AvailabilityZoneId))];

    const zonePositions = uniqueAvailZoneIds
      .map((zoneId) => {
        const subnets = this.getSubnetsForZone(zoneId);
        const { AvailabilityZone: AvailabilityZoneName } = subnets[0];
        const zoneName = `${AvailabilityZoneName} (AZ ID: ${zoneId}) `;
        const rows = Math.ceil(subnets.length / maxCols);
        const cols = Math.ceil(subnets.length / rows);
        const chunkedSubnets = chunk(subnets, cols);

        const width = cols * (itemWidth + itemMarginX) - itemMarginX + 2 * zonePaddingX;

        let totalRowHeight = 0;
        chunkedSubnets.forEach((rowSubnets) => {
          const hasNatGateways = rowSubnets.some((subnet) => Object.values(subnet?.NatGateways ?? {}).length > 0);
          const hasTgwAttachments = rowSubnets.some(
            (subnet) => Object.values(subnet?.TransitGatewayAttachments ?? {}).length > 0
          );
          const hasClusters = rowSubnets.some((subnet) => !!subnet.hasEksNode);

          const hasExtraIconsSection = hasNatGateways || hasTgwAttachments || hasClusters;

          // we will need to increase row height if there will be new icons section
          totalRowHeight += (hasExtraIconsSection ? itemHeightWithIcons : itemHeight) + itemMarginY + zonePaddingY;
        });
        const height = totalRowHeight + zonePaddingY;

        return { zoneId, zoneName, subnets, rows, cols, width, height };
      })
      .filter(({ subnets }) => subnets.length > 0)
      .sort((a, b) => a.zoneId.localeCompare(b.zoneId));

    let xOffset = 0;
    let yOffset = 0;
    let rowHeight = 0;

    for (let i = 0; i < zonePositions.length; i += 1) {
      const zonePos = zonePositions[i];

      if (xOffset + zonePos.width > availableWidth) {
        xOffset = 0;
        yOffset += rowHeight + zoneMargin;
        rowHeight = 0;
      }

      zonePos.x = xOffset;
      zonePos.y = yOffset;

      xOffset += zonePos.width + zoneMargin;
      rowHeight = Math.max(rowHeight, zonePos.height);
    }

    return zonePositions;
  }

  get groupedVpcConnections() {
    const { vpc } = this.props;
    const groups = {};

    MAP_TYPES.get('aws.vpc_connections').forEach((type) => {
      const connections = vpc[type] || [];

      if (connections.length > 0) {
        groups[type] = connections.map((item) => {
          let peeringId;

          if (type === VPC_PEERING_CONNECTION) {
            peeringId = `${item.id}-${vpc.id}`;
          }

          return { ...item, type, peeringId };
        });
      }
    });

    return groups;
  }

  get vpcConnections() {
    return Object.values(this.groupedVpcConnections).flat();
  }

  collapseConnections(vpcConnections = this.vpcConnections) {
    const { availableWidth } = this;
    const num = vpcConnections.length;
    return num * (connectionItemWidth + itemMarginX) - itemMarginX > availableWidth;
  }

  // grouped positions when vpc connections are collapsed
  getConnectionPositions(groupedVpcConnections = this.groupedVpcConnections) {
    const groups = Object.values(groupedVpcConnections);
    const availableWidth = this.availableWidth - (groups.length - 1) * groupMargin;

    const widths = groups.map((connections) => {
      const num = connections.length;
      const width = Math.max(miniItemSize, num * (miniItemSize + miniItemMargin) - miniItemMargin);
      return { connections, num, width };
    });

    const sumWidths = () => widths.reduce((sum, { width }) => sum + width, 0);

    if (availableWidth > groups.length * miniItemSize) {
      while (sumWidths() > availableWidth) {
        const maxWidth = Math.max(...widths.map(({ width }) => width)) - (miniItemSize + miniItemMargin);

        widths.forEach((item) => {
          item.width = Math.min(item.width, maxWidth);
        });
      }
    }

    const extraMargin = Math.floor((availableWidth - sumWidths()) / groups.length);
    let xOffset = 0;

    return widths.map(({ connections, num, width }) => {
      const cols = Math.floor((width + miniItemMargin) / (miniItemSize + miniItemMargin));
      const rows = Math.ceil(num / cols);
      const height = rows * (miniItemSize + miniItemMargin) - miniItemMargin;
      const x = xOffset;

      xOffset += width + groupMargin + extraMargin;

      return { connections, width, height, rows, cols, x };
    });
  }

  getItemClasses(item) {
    const { selected, highlighted } = this.props;
    const isSelected = selected === item.id || (item.peeringId && selected === item.peeringId);
    const isHighlighted = highlighted.includes(item.id) || (item.peeringId && highlighted.includes(item.peeringId));

    return {
      selected: isSelected,
      highlighted: isHighlighted,
      unselected: highlighted.length > 0 && !isSelected && !isHighlighted
    };
  }

  getSubnetsForZone(zoneId) {
    const { vpc } = this.props;
    const { Subnets = [] } = vpc;
    return Subnets.filter((subnet) => subnet.AvailabilityZoneId === zoneId);
  }

  handleShowDetails = (evt) => {
    const { vpc, onShowVpcDetails } = this.props;
    evt.stopPropagation();
    onShowVpcDetails(vpc);
  };

  handleItemHover = (tooltipItem, tooltipRect) => {
    if (tooltipItem) {
      tooltipRect.top += 64;
      this.setState({ tooltipOpen: true, tooltipItem, tooltipRect });
    } else {
      this.setState({ tooltipOpen: false });
    }
  };

  // eslint-disable-next-line no-unused-vars
  handleItemClick = (item, _target) => {
    const { onSelectNode } = this.props;
    let type = 'subnet';
    const value = item.id;

    if (item.type) {
      if (item.type.includes('Gateway') || item.type.includes('Connection')) {
        type = 'gateway';
      } else {
        type = item.type;
      }
    }

    onSelectNode({ type, value, nodeData: item });

    this.setState((prevState) => {
      if (prevState.tooltipOpen) {
        return { tooltipOpen: false };
      }

      return null;
    });
  };

  render() {
    const { width, padding, vpc, onClose } = this.props;
    const { tooltipOpen, tooltipItem, tooltipRect } = this.state;
    const { zonePositions, groupedVpcConnections } = this;
    const vpcConnections = Object.values(groupedVpcConnections).flat();
    const collapseConnections = this.collapseConnections(vpcConnections);

    // row have different height -> so we need to get last row with max height
    const lastMaxedZone = maxBy(zonePositions, (zone) => zone.y + zone.height);
    const zonesHeight = zonePositions.length > 0 ? lastMaxedZone.y + lastMaxedZone.height + zoneMargin : 0;

    let svgHeight = padding + zonesHeight;
    let connectionPositions;

    if (vpcConnections.length > 0) {
      if (collapseConnections) {
        connectionPositions = this.getConnectionPositions(groupedVpcConnections);
        svgHeight += Math.max(...connectionPositions.map(({ height }) => height)) + 16;
      } else {
        svgHeight += connectionItemHeight + itemMarginY;
      }
    }

    let maxHeightPerRow = {};
    let prevZone = null;

    return (
      <Box position="relative">
        <Flex alignItems="center" justifyContent="space-between" width={width}>
          <VpcBox className="vpc-mini-map">
            <Flex alignItems="flex-start" justifyContent="space-between" m={`${padding}px`}>
              <Flex alignItems="center">
                <CloudIcon cloudProvider="aws" entity="vpc" width={20} height={23} />
                <Box ml={1}>
                  <Box fontSize={14} fontWeight={500}>
                    {vpc.Name || vpc.id}
                  </Box>
                  {vpc.Name && (
                    <Box fontSize={12} fontStyle="italic" color="muted">
                      {vpc.id}
                    </Box>
                  )}
                  <Box fontSize={12} color="muted">
                    {vpc.CidrBlock}
                  </Box>
                </Box>
              </Flex>
              <Box>
                <ButtonLink small ml={1} onClick={this.handleShowDetails}>
                  Show Details
                </ButtonLink>
                <ButtonLink color="muted" small ml={1} onClick={onClose}>
                  Close
                </ButtonLink>
              </Box>
            </Flex>
            <Box>
              <svg width={this.svgWidth} height={svgHeight}>
                <g transform={`translate(${padding} ${Math.round(padding / 2)})`}>
                  <g>
                    {zonePositions.map(({ zoneName, subnets, ...zonePos }) => (
                      <g key={zoneName} transform={`translate(${zonePos.x} ${zonePos.y})`}>
                        <Rect
                          x={-0.5}
                          y={-0.5}
                          width={zonePos.width + 1}
                          height={zonePos.height + 1}
                          fill="transparent"
                          stroke="body"
                          strokeWidth={1}
                          strokeDasharray="2"
                          rx={4}
                        />
                        <g fontSize={12} letterSpacing="-0.035em">
                          <TextMeasure text={zoneName}>
                            {({ text, width: textWidth }) => (
                              <>
                                <g transform="translate(-0.5 -0.5)">
                                  <Path
                                    d={`M0,20 v-16 q0,-4 4,-4 h${textWidth + 4} v16 q0,4 -4,4 z`}
                                    fill="transparent"
                                    stroke="body"
                                    strokeWidth={1}
                                  />
                                </g>
                                <SVGText x={6} y={5} dominantBaseline="hanging" fill="body">
                                  {text}
                                </SVGText>
                                <g transform={`translate(${zonePaddingX} ${zonePaddingY})`}>
                                  {subnets.map((subnet, subnetIndex) => {
                                    // reset maxHeightPerRow calculation for each zone
                                    if (prevZone !== zoneName) {
                                      prevZone = zoneName;
                                      maxHeightPerRow = {};
                                    }
                                    const hasNatGateways = Object.values(subnet?.NatGateways ?? {}).length > 0;

                                    const hasTgwAttachments =
                                      Object.values(subnet?.TransitGatewayAttachments ?? {}).filter((attachment) =>
                                        Object.values(attachment.NetworkInterfaces).some(
                                          (eni) => !eni?.Description?.includes('Core Network Attachment')
                                        )
                                      ).length > 0;

                                    const hasCoreAttachments =
                                      Object.values(subnet?.TransitGatewayAttachments ?? {}).filter((attachment) =>
                                        Object.values(attachment.NetworkInterfaces).some((eni) =>
                                          eni?.Description?.includes('Core Network Attachment')
                                        )
                                      ).length > 0;

                                    const hasExtraIconsSection =
                                      hasNatGateways || hasTgwAttachments || hasCoreAttachments || subnet.hasEksNode;

                                    const curItemHeight = hasExtraIconsSection ? itemHeightWithIcons : itemHeight;
                                    const row = Math.floor(subnetIndex / zonePos.cols);
                                    const col = subnetIndex % zonePos.cols;

                                    if (!maxHeightPerRow[row]) {
                                      maxHeightPerRow[row] = 0;
                                    }

                                    // keep prev row height so we can allign items properly
                                    if (curItemHeight > maxHeightPerRow[row]) {
                                      maxHeightPerRow[row] = curItemHeight;
                                    }

                                    const x = col * (itemWidth + itemMarginX);

                                    const totalHeight = Object.values(maxHeightPerRow).reduce((acc, cur, index) => {
                                      if (index === row) {
                                        return acc;
                                      }

                                      acc += cur + itemMarginY + zonePaddingY;
                                      return acc;
                                    }, 0);
                                    const y = row > 0 ? totalHeight : 0;

                                    const iconsToDisplay = [];
                                    if (subnet.hasEksNode) {
                                      iconsToDisplay.push({
                                        cloudProvider: 'aws',
                                        entity: 'cluster',
                                        title: 'EKS'
                                      });
                                    }

                                    if (hasNatGateways) {
                                      iconsToDisplay.push({
                                        cloudProvider: 'aws',
                                        entity: NAT_GATEWAY,
                                        title: 'NAT'
                                      });
                                    }

                                    if (hasTgwAttachments) {
                                      iconsToDisplay.push({
                                        cloudProvider: 'aws',
                                        entity: TRANSIT_GATEWAY,
                                        title: 'TGW-ENI'
                                      });
                                    }

                                    if (hasCoreAttachments) {
                                      iconsToDisplay.push({
                                        cloudProvider: 'aws',
                                        entity: CORE_NETWORK_ATTACHMENT,
                                        title: 'CORE-ENI'
                                      });
                                    }
                                    return (
                                      <g key={subnet.id} transform={`translate(${x} ${y})`}>
                                        <CloudItem
                                          width={itemWidth}
                                          height={hasExtraIconsSection ? itemHeightWithIcons : itemHeight}
                                          stroke="#3d7fb2"
                                          title={subnet.Name || subnet.id}
                                          subtitle={subnet.CidrBlock}
                                          icon={<SubnetIcon width={17} height={17} />}
                                          className={classNames(
                                            `${SUBNET}-${subnet.id.replace(/\W+/g, '')}`,
                                            `subnet-${subnet.id.replace(/\W+/g, '')}`,
                                            this.getItemClasses(subnet),
                                            getMapClassname({ type: SUBNET, value: subnet.id })
                                          )}
                                          onClick={(evt) => this.handleItemClick(subnet, evt.target)}
                                          withArrow
                                          innerProps={{ height: itemHeight }}
                                          iconsToDisplay={iconsToDisplay}
                                        />
                                      </g>
                                    );
                                  })}
                                </g>
                              </>
                            )}
                          </TextMeasure>
                        </g>
                      </g>
                    ))}
                  </g>
                  <g transform={`translate(0 ${zonesHeight})`}>
                    {collapseConnections &&
                      connectionPositions.map(({ connections, width: groupWidth, x }) => {
                        const num = connections.length;
                        const label = getGatewayProps(connections[0]).type + (num > 1 ? `s (${num})` : '');
                        return (
                          <g key={label} transform={`translate(${x}, 0)`}>
                            <SVGText fontSize={11} fontWeight={500} color="muted">
                              {label}
                            </SVGText>
                            <Grid
                              items={connections}
                              width={groupWidth + 2}
                              paddingX={1}
                              paddingY={8}
                              itemWidth={miniItemSize}
                              itemHeight={miniItemSize}
                              itemMarginX={miniItemMargin}
                              itemMarginY={miniItemMargin}
                              renderItem={(connection, props) => (
                                <CloudItem
                                  {...props}
                                  fill="appBackground"
                                  className={classNames(
                                    `gateway-${connection.id.replace(/\W+/g, '')}`,
                                    `${connection.type}-${connection.id.replace(/\W+/g, '')}`,
                                    connection.peeringId && `gateway-${connection.peeringId.replace(/\W+/g, '')}`,
                                    connection.peeringId &&
                                      `${connection.type}-${connection.peeringId.replace(/\W+/g, '')}`,
                                    this.getItemClasses(connection)
                                  )}
                                  {...getConnectionProps(connection)}
                                />
                              )}
                              onItemHover={this.handleItemHover}
                              onItemClick={this.handleItemClick}
                              embed
                            />
                          </g>
                        );
                      })}

                    {!collapseConnections &&
                      vpcConnections.map(
                        (connection, index) =>
                          connection &&
                          connection.id && (
                            <g
                              key={connection.id}
                              transform={`translate(${(connectionItemWidth + itemMarginX) * index}, 0)`}
                            >
                              <CloudItem
                                width={connectionItemWidth}
                                height={connectionItemHeight}
                                stroke="#4d27aa"
                                className={classNames(
                                  `gateway-${connection.id.replace(/\W+/g, '')}`,
                                  `${connection.type}-${connection.id.replace(/\W+/g, '')}`,
                                  connection.peeringId && `gateway-${connection.peeringId.replace(/\W+/g, '')}`,
                                  connection.peeringId &&
                                    `${connection.type}-${connection.peeringId.replace(/\W+/g, '')}`,
                                  this.getItemClasses(connection)
                                )}
                                onClick={(evt) => this.handleItemClick(connection, evt.target)}
                                withArrow
                                {...getConnectionProps(connection)}
                              />
                            </g>
                          )
                      )}
                  </g>
                </g>
              </svg>
            </Box>
          </VpcBox>
        </Flex>
        <Tooltip
          key={tooltipItem && tooltipItem.id}
          isOpen={tooltipOpen}
          boundary="viewport"
          position="bottom"
          content={tooltipItem ? tooltipItem.Name || tooltipItem.id : null}
          target={<div />}
          targetProps={{ style: { pointerEvents: 'none', position: 'absolute', ...tooltipRect } }}
        />
      </Box>
    );
  }
}
