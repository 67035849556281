import React from 'react';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { VirtualizedTable } from 'core/components/table';
import { Box, Button, Card, Label, Tag, Flex, Text } from 'core/components';
import { FiCopy } from 'react-icons/fi';
import { Form, Field, InputGroup, Select } from 'core/form';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';
import ViewInExplorerButton from 'app/components/dataviews/tools/ViewInExplorerButton';
import Collection from 'core/model/Collection';

@Form({ fields: { groupBy: { label: 'Group By' }, search: {} }, options: { name: 'Firewal Rules Search Form' } })
@withTheme
@observer
class FirewallRulesTable extends React.Component {
  static defaultProps = {
    label: null
  };

  constructor(props) {
    super(props);

    const { firewallRules } = props;
    this.firewalls_collection = new Collection(firewallRules);
  }

  getDetailQuery = (model) => {
    const { queryTimeOptions } = this.props;
    const network = model.get('network');
    const direction = model.get('direction');
    const networkSTRnum = direction === 'INGRESS' ? 12 : 11;
    const resource = model.get('ip');
    const port = model.get('port');
    const filters = [];
    const directionCode = direction === 'INGRESS' ? 'dst' : 'src';
    const query_title = `${direction === 'INGRESS' ? 'Inbound' : 'Outbound'} traffic ${
      direction === 'INGRESS' ? 'to' : 'from'
    } ${network}`;

    if (resource) {
      filters.push({
        filterField: `inet_${directionCode}_addr`,
        operator: 'ILIKE',
        filterValue: resource
      });
    }

    if (port) {
      filters.push({
        filterField: `l4_${directionCode}_port`,
        operator: '=',
        filterValue: port.toString()
      });
    }

    if (network) {
      filters.push({
        filterField: `ktsubtype__gcp_subnet__STR${networkSTRnum}`,
        operator: '=',
        filterValue: network
      });
    }

    const query = {
      all_devices: false,
      aggregateTypes: ['agg_total_fps', 'agg_total_bytes'],
      device_types: ['gcp_subnet'],
      query_title,
      show_overlay: false,
      show_total_overlay: false,
      filters: {
        connector: 'All',
        filterGroups: [
          {
            name: '',
            named: false,
            connector: 'All',
            not: false,
            autoAdded: '',
            filters,
            saved_filters: [],
            filterGroups: []
          }
        ]
      },
      metric: ['IP_src', 'Port_src', 'IP_dst', 'Port_dst'],
      viz_type: 'table',
      ...queryTimeOptions
    };

    return query;
  };

  renderMultiValueRow = (value) => {
    if (!Array.isArray(value)) {
      return value;
    }
    if (!value.length) {
      return (
        <Text as="p" ellipsis>
          All
        </Text>
      );
    }
    const maxLinesToDisplay = 2;
    const visibleValues = value.slice(0, maxLinesToDisplay);
    const hasHiddenValues = value.length > maxLinesToDisplay;

    return (
      <Flex alignItems="left" flexDirection="row">
        <CopyToClipboardButton text={value.join('\n')} copyConfirmationText={null} intent="none" minimal small>
          <Button icon={FiCopy} />
        </CopyToClipboardButton>

        <Flex flexDirection="column">
          {visibleValues.map((v) => (
            <Text as="p" key={v} muted ellipsis>
              {v}
            </Text>
          ))}
          {hasHiddenValues && (
            <Text as="div" muted small>
              +{value.length - maxLinesToDisplay} more
            </Text>
          )}
        </Flex>
      </Flex>
    );
  };

  get columns() {
    const { isPoppedOut } = this.props;
    const ruleNameColumn = {
      label: 'Rule Name',
      name: 'ruleName',
      width: 250,
      ellipsis: true,
      justifyContent: 'center',
      renderer: ({ value }) => (
        <Flex alignItems="flex-start" flexDirection="column">
          <Flex alignItems="left" gap={1}>
            <CopyToClipboardButton text={value} copyConfirmationText={null} intent="none" minimal small>
              <Button icon={FiCopy} />
            </CopyToClipboardButton>
            <Text as="div" width={200} ellipsis>
              {value}
            </Text>
          </Flex>
        </Flex>
      )
    };

    // direction is Ingress or Egress
    const directionColumn = {
      label: 'Direction',
      name: 'direction',
      width: 70,
      renderer: ({ value }) => {
        const icon = value?.toLowerCase() === 'ingress' ? 'arrow-left' : null;
        const rightIcon = value?.toLowerCase() === 'egress' ? 'arrow-right' : null;
        const label = value?.toLowerCase() === 'ingress' ? 'in' : 'out';
        return (
          <Tag icon={icon} rightIcon={rightIcon} small minimal>
            {label}
          </Tag>
        );
      }
    };

    // rule action is allow or deny
    const ruleActionColumn = {
      label: 'Rule Action',
      name: 'ruleAction',
      width: 60,
      renderer: ({ value }) => {
        const intent = value === 'allow' ? 'success' : 'danger';
        return (
          <Tag intent={intent} small minimal>
            {value}
          </Tag>
        );
      }
    };

    // target can be tag, service account or ip destination range
    // or combo of tag/ip range OR service acct/ip range
    // but never tag AND service acct
    const targetColumn = {
      label: 'Target',
      name: 'targets',
      width: 100,
      ellipsis: true,
      justifyContent: 'center',
      renderer: ({ value }) => this.renderMultiValueRow(value)
    };

    const destinationRangesColumn = {
      label: 'DST Ranges',
      name: 'destinationRanges',
      width: 150,
      ellipsis: true,
      justifyContent: 'center',
      renderer: ({ value }) => this.renderMultiValueRow(value)
    };

    const sourceColumn = {
      label: 'Source',
      name: 'sources',
      width: 100,
      ellipsis: true,
      justifyContent: 'center',
      renderer: ({ value }) => this.renderMultiValueRow(value)
    };

    const sourceRangesColumn = {
      label: 'SRC Ranges',
      name: 'sourceRanges',
      width: 150,
      ellipsis: true,
      justifyContent: 'center',
      renderer: ({ value }) => this.renderMultiValueRow(value)
    };

    const protocolAndPortColumn = {
      label: 'Protocol:Port',
      name: 'portsAndProtocols',
      width: 100,
      ellipsis: true,
      justifyContent: 'center',
      renderer: ({ value }) => this.renderMultiValueRow(value)
    };

    const priorityColumn = {
      label: 'Priority',
      name: 'priority',
      width: 60
    };

    const logsColumn = {
      label: 'Logs',
      name: 'logs',
      width: 50
    };

    const descriptionColumn = {
      label: 'Description',
      name: 'description',
      width: 200,
      ellipsis: true,
      justifyContent: 'center',

      renderer: ({ value }) => {
        if (!value) {
          return '--';
        }
        return (
          <Flex alignItems="flex-start" flexDirection="column">
            <Flex alignItems="center" gap={1}>
              <CopyToClipboardButton
                text={value}
                copyConfirmationText="copied to clipboard"
                intent="none"
                minimal
                small
              >
                <Button icon={FiCopy} />
              </CopyToClipboardButton>
              <Text as="div" ellipsis>
                {value}
              </Text>
            </Flex>
          </Flex>
        );
      }
    };

    const ruleIdColumn = {
      label: 'ID',
      name: 'ruleId',
      width: 150,
      ellipsis: true,
      justifyContent: 'center',
      renderer: ({ value }) => (
        <Flex alignItems="flex-start" flexDirection="column">
          <Flex alignItems="center" gap={1}>
            <CopyToClipboardButton text={value} copyConfirmationText={null} intent="none" minimal small>
              <Button icon={FiCopy} />
            </CopyToClipboardButton>
            <Text as="div" width={200} ellipsis>
              {value}
            </Text>
          </Flex>
        </Flex>
      )
    };

    const disabledColumn = {
      label: 'Disabled',
      name: 'disabled',
      width: 70,
      style: { color: 'red' },
      renderer: ({ value }) => value?.toString() || 'false'
    };

    const ruleCreatedColumn = {
      label: 'Rule Created',
      name: 'creationTimestamp',
      width: 200
    };

    const viewInExplorerButtonColumn = {
      label: null,
      name: 'viewInExplorer',
      width: 50,
      renderer: ({ model }) => (
        // keep the model selected when using the data explorer button
        <div
          onClick={(e) => {
            model.select();
            e.stopPropagation();
          }}
        >
          <ViewInExplorerButton text={null} query={this.getDetailQuery(model)} openInNewWindow />
        </div>
      )
    };

    const notPoppedOutColumns = [ruleActionColumn, priorityColumn, targetColumn, protocolAndPortColumn, sourceColumn];
    const poppedOutColumns = [
      directionColumn,
      ruleActionColumn,
      ruleNameColumn,
      targetColumn,
      destinationRangesColumn,
      sourceColumn,
      sourceRangesColumn,
      protocolAndPortColumn,
      priorityColumn,
      ruleIdColumn,
      descriptionColumn,
      logsColumn,
      disabledColumn,
      ruleCreatedColumn,
      viewInExplorerButtonColumn
    ];

    return isPoppedOut ? poppedOutColumns : notPoppedOutColumns;
  }

  get groupByOptions() {
    const options = [
      { label: 'None', value: null },
      { label: 'Target', value: 'targets' },
      { label: 'Source', value: 'sources' },
      { label: 'Direction', value: 'direction' },
      { label: 'Port', value: 'ports' },
      { label: 'Protocol', value: 'protocols' },
      { label: 'Rule Action', value: 'ruleAction' }
    ];

    return options;
  }

  groupSummary = ({ groupKey, group }) => {
    const groupKeyLabels = {
      INGRESS: 'Inbound',
      EGRESS: 'Outbound',
      '-1': 'all protocols',
      allow: 'Allow',
      deny: 'Deny',
      undefined: 'N/A'
    };

    return `${groupKeyLabels[groupKey] || groupKey} (${group.length})`;
  };

  handleGroupByChange = (field) => this.firewalls_collection.group(field.getValue());

  handleSearchChange = (field) => this.firewalls_collection.filter(field.getValue());

  handleSearchClear = () => {
    const { form } = this.props;

    form.setValue('search', '');
    this.firewalls_collection.clearFilters();
  };

  render() {
    const { form, label } = this.props;
    const rowHeight = 75;
    return (
      <Box>
        {label && <Label as="h4">{label}</Label>}
        <Card display="flex" flexDirection="column">
          <Flex alignItems="center" borderBottom="thin" p={1} bg="subnavBackground">
            <Label mr={1} small>
              Group By:
            </Label>
            <Field
              options={this.groupByOptions}
              name="groupBy"
              showLabel={false}
              className="no-margin"
              placeholder="None"
              onChange={this.handleGroupByChange}
            >
              <Select menuWidth={100} />
            </Field>

            <Field name="search" className="no-margin" flex={1} onChange={this.handleSearchChange}>
              <InputGroup
                ml={1}
                leftIcon="search"
                rightElement={
                  form.getValue('search') !== '' && (
                    <Button icon="cross" onClick={this.handleSearchClear} minimal small />
                  )
                }
              />
            </Field>
          </Flex>
          <Box height="30vh">
            <VirtualizedTable
              collection={this.firewalls_collection}
              columns={this.columns}
              groupSummaryLookup={this.groupSummary}
              selectOnRowClick={false}
              style={{ height: '100%' }}
              rowHeight={rowHeight}
            />
          </Box>
        </Card>
      </Box>
    );
  }
}

export default FirewallRulesTable;
