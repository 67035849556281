import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

// import { Grid, Box, Heading, Flex, Card, LinkButton, ButtonGroup, Divider, Link } from 'core/components';
import Page from 'app/components/page/Page';
import { Card, Heading, Flex, Box, Text, CalloutOutline } from 'core/components';
import { addCommas } from 'app/util/utils';
import SyntheticsPlanCard from 'app/views/licenses/SyntheticsPlanCard';
import MetricsPlanCard from 'app/views/licenses/MetricsPlanCard';
import MkpPlanCard from 'app/views/licenses/MkpPlanCard';
import AccountTeamCard from 'app/views/licenses/AccountTeamCard';
import PageHeading from 'app/components/page/PageHeading';
import { getEditionText } from 'app/stores/sudo/util';
import PlanCard from './PlanCard';
import ServiceProviderPlanCard from './ServiceProviderPlanCard';
import LabelValue from './LabelValue';

function getCapacityColor(capacity) {
  if (!Number.isFinite(capacity)) {
    return undefined;
  }

  if (capacity >= 0.85) {
    return 'danger';
  }
  if (capacity >= 0.7) {
    return 'warning';
  }
  return undefined;
}

@inject('$setup', '$syn', '$companySettings', '$auth')
@observer
class Licenses extends Component {
  state = {
    loadingPlanFps: false
  };

  componentDidMount() {
    const { $setup, $syn, $companySettings, $auth } = this.props;
    $setup.getPlans();

    // make sure we have up-to-date counts
    $setup.fetchSettings();

    if ($auth.hasSudo || $auth.isSpoofed) {
      this.setState({ loadingPlanFps: true });
      $setup.fetchPlanFps().then(() => this.setState({ loadingPlanFps: false }));
    }

    $companySettings.loadAssetCounts();
    $syn.plan.loadUsageData({ force: true });
  }

  render() {
    const { $setup, $companySettings, $auth, $syn } = this.props;
    const { loadingPlanFps } = this.state;
    const cloudPlans = $setup.plans.filter(({ plan_type, active }) => plan_type === 'cloud' && active);
    const legacyPlans = $setup.plans.filter(({ plan_type, active }) => plan_type === 'legacy' && active);
    const edgePlans = $setup.plans.filter(({ plan_type, active }) => plan_type === 'edge' && active);
    const corePlans = $setup.plans.filter(({ plan_type, active }) => plan_type === 'core' && active);
    const flowPakPlans = $setup.plans.filter(({ plan_type, active }) => plan_type === 'flowpak' && active);
    const syntheticPlans = $setup.plans.filter(({ plan_type, active }) => plan_type === 'ksynth' && active);
    const metricsPlans = $setup.plans.filter(({ plan_type, active }) => plan_type === 'metrics' && active);
    const universalPakPlans = $setup.plans.filter(({ plan_type, active }) => plan_type === 'universalpak' && active);

    const usedSyntheticCredits = $syn.plan.currentMonthCreditUsage;

    const maxDashboards = $auth.getPermission('dashboards.max') || 'Unlimited';
    const maxSubscriptions = $auth.getPermission('reportSubscriptions.max') || 'Unlimited';

    return (
      <Page title="Licenses" hideSubnav scrolls>
        <PageHeading title="Licenses" />
        <Flex>
          <Box flex={2}>
            <Heading level={4}>Edition</Heading>
            <Card p={2} mb={3}>
              <Flex justifyContent="space-between">
                <Box>
                  {$auth.isActiveTrial && (
                    <Box>
                      <Heading level={1} m={0}>
                        Trial
                      </Heading>
                    </Box>
                  )}

                  {!$auth.isActiveTrial && (
                    <Box>
                      <Heading level={1} m={0}>
                        Kentik <Text color="warning">{getEditionText($companySettings.edition)}</Text>
                      </Heading>
                    </Box>
                  )}

                  <Text as="div" muted small mb={2}>
                    Account #{$auth.getActiveUserProperty('company_id')}
                  </Text>
                </Box>
                {$companySettings.edition && (
                  <>
                    <Box>
                      <Flex flexWrap="wrap">
                        <Box mr={5}>
                          <LabelValue
                            label="Dashboards"
                            value={`${$companySettings.assetCounts?.dashboards ?? 0} of ${maxDashboards ?? 0}`}
                            valueTextProps={{
                              // disabling eslint error and others like it that use getCapacityColor because the util does a check for a finite number
                              // eslint-disable-next-line no-unsafe-optional-chaining
                              color: getCapacityColor($companySettings.assetCounts?.dashboards / maxDashboards)
                            }}
                          />
                          <LabelValue
                            label="Scheduled Reports"
                            value={`${$companySettings.assetCounts?.reportSubscriptions ?? 0} of ${
                              maxSubscriptions ?? 0
                            }`}
                            valueTextProps={{
                              color: getCapacityColor(
                                // eslint-disable-next-line no-unsafe-optional-chaining
                                $companySettings.assetCounts?.reportSubscriptions / maxSubscriptions
                              )
                            }}
                          />
                          <LabelValue label="Users" mb={0} value={$companySettings.assetCounts?.users} />
                        </Box>
                      </Flex>
                    </Box>
                    <Box>
                      <LabelValue
                        label="Custom Dimensions"
                        value={
                          $companySettings.assetCounts
                            ? `${addCommas($companySettings.assetCounts?.custom_dimension_count)} of ${addCommas(
                                $companySettings.assetCounts?.custom_dimension_limit
                              )}`
                            : undefined
                        }
                        valueTextProps={{
                          color: getCapacityColor(
                            // eslint-disable-next-line no-unsafe-optional-chaining
                            $companySettings.assetCounts?.custom_dimension_count /
                              // eslint-disable-next-line no-unsafe-optional-chaining
                              $companySettings.assetCounts?.custom_dimension_limit
                          )
                        }}
                      />
                      <LabelValue
                        label="Custom Populators"
                        value={
                          $companySettings.assetCounts
                            ? `${addCommas($companySettings.assetCounts?.populator_count)} of ${addCommas(
                                $companySettings.assetCounts?.populator_limit
                              )}`
                            : undefined
                        }
                        valueTextProps={{
                          color: getCapacityColor(
                            // eslint-disable-next-line no-unsafe-optional-chaining
                            $companySettings.assetCounts?.populator_count /
                              // eslint-disable-next-line no-unsafe-optional-chaining
                              $companySettings.assetCounts?.populator_limit
                          )
                        }}
                      />
                    </Box>
                  </>
                )}
              </Flex>
              {$auth.isActiveTrial && (
                <Box maxWidth={350}>
                  <CalloutOutline intent="primary" p={2}>
                    <Text fontWeight="bold">Need higher asset limits or plan capacity?</Text> Contact sales to upgrade
                    your company to Kentik Pro/Premier.
                  </CalloutOutline>
                </Box>
              )}
            </Card>

            <Heading level={4}>Plans</Heading>
            {legacyPlans.map((plan) => (
              <PlanCard key={plan.id} {...plan} mb={2} trialDaysRemaining={$auth.trialDaysRemaining} />
            ))}

            {corePlans.map((plan) => (
              <PlanCard key={plan.id} {...plan} mb={2} />
            ))}

            {edgePlans.map((plan) => (
              <PlanCard key={plan.id} {...plan} mb={2} />
            ))}

            {cloudPlans.map((plan) => (
              <PlanCard key={plan.id} {...plan} mb={2} loadingPlanFps={loadingPlanFps} />
            ))}

            {flowPakPlans.map((plan) => (
              <PlanCard key={plan.id} {...plan} mb={2} loadingPlanFps={loadingPlanFps} />
            ))}

            {universalPakPlans.map((plan) => (
              <PlanCard key={plan.id} {...plan} mb={2} loadingPlanFps={loadingPlanFps} />
            ))}

            {syntheticPlans.map((plan) => (
              <SyntheticsPlanCard key={plan.id} usedSyntheticCredits={usedSyntheticCredits} {...plan} mb={2} />
            ))}

            {metricsPlans.map((plan) => (
              <MetricsPlanCard key={plan.id} {...plan} mb={2} />
            ))}

            <MkpPlanCard mb={2} />
            <ServiceProviderPlanCard />
          </Box>
          <Box width={325} ml={3}>
            <AccountTeamCard />
          </Box>
        </Flex>
      </Page>
    );
  }
}

export default Licenses;
