import * as React from 'react';
import { Box, Flex, Heading, Text } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import CloudDetailLink from 'app/components/links/CloudDetailLink';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';

const { LOCAL_PEERING_GATEWAY } = ENTITY_TYPES.get('oci');

const LocalPeeringGatewaySummary = (config) => {
  const { value, cloudProvider, nodeData } = config;

  const cloudMetadataDetails = {
    node: {
      ...nodeData,
      Name: nodeData.displayName,
      Status: nodeData.peeringStatusDetails,
      'Created At': new Date(nodeData.timeCreated).toLocaleString()
    },
    keys: [
      'id',
      'Name',
      'description',
      'peerAdvertisedCidr',
      'peerAdvertisedCidrDetails',
      'region',
      'Status',
      'Created At',
      'compartmentId',
      'tenancyId'
    ]
  };

  return (
    <Box>
      <Flex borderBottom="thin" p={2} alignItems="center">
        <CloudIcon cloudProvider={cloudProvider} entity={LOCAL_PEERING_GATEWAY} width={32} height={32} />
        <Box flex={1} ml={1}>
          <Text as="div" muted>
            Cloud Local Peering Gateway Details
          </Text>
          <Heading mb={0} level={4}>
            <CloudDetailLink cloud={cloudProvider} detail="vcn" value={value}>
              {nodeData.displayName}
            </CloudDetailLink>
          </Heading>
        </Box>
      </Flex>
      {nodeData && (
        <SidebarItem title="Details" icon="more" popoutTitle={LocalPeeringGatewaySummary.PopoutTitle(config)}>
          <CloudMetadata {...cloudMetadataDetails} />
        </SidebarItem>
      )}
    </Box>
  );
};

LocalPeeringGatewaySummary.PopoutTitle = (config) => {
  const { cloudProvider, nodeData } = config;

  return (
    <Flex alignItems="center">
      <CloudIcon cloudProvider={cloudProvider} entity={LOCAL_PEERING_GATEWAY} />
      <Box ml={1}>{`${nodeData.displayName} Internet Gateway`}</Box>
    </Flex>
  );
};

export default LocalPeeringGatewaySummary;
