import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import Flex from 'core/components/Flex';
import getErrorBoundary from 'core/util/getErrorBoundary';
import Navbar, { NAVBAR_HEIGHT } from 'app/components/navbar/Navbar';
import NavbarMenu from 'app/components/navbar/NavbarMenu';
import Subnav, { SUBNAV_HEIGHT } from 'app/components/navbar/Subnav';
import Drawer from 'app/components/page/Drawer';
import PageContentContainer from 'app/components/page/PageContentContainer';

const PageContentsErrorBoundaryCmp = getErrorBoundary('PageContents');
const DrawerErrorBoundaryCmp = getErrorBoundary('Drawer');

export const NAV_HEIGHT = NAVBAR_HEIGHT + SUBNAV_HEIGHT;
export const NAV_HEIGHT_WITHOUT_SUBNAV = NAVBAR_HEIGHT;

@inject('$app', '$setup', '$sharedLinks')
@observer
class Page extends Component {
  static defaultProps = {
    parentLinks: [],
    scrolls: false,
    hideSubnav: false,
    forceShowExport: false,
    showExport: null,
    exportMenuItems: null,
    additionalExportMenuItems: null,
    exportButtonProps: null,
    shareButtonProps: null,
    locked: false,
    flexDirection: 'column',
    toolbar: null,
    subnavToolsPosition: 'start'
  };

  state = {
    menuOpen: false
  };

  pageRef = React.createRef();

  pageContainerRef = React.createRef();

  componentDidMount() {
    const { onScroll } = this.props;

    this.updateDocTitle();

    if (onScroll) {
      this.pageRef.current.onscroll = this.handleScroll;
      this.pageContainerRef.current.onscroll = this.handleScroll;
    }
  }

  componentDidUpdate(prevProps) {
    const { title, docTitle, parentLinkLabel } = this.props;

    if (title !== prevProps.title || docTitle !== prevProps.docTitle || parentLinkLabel !== prevProps.parentLinkLabel) {
      this.updateDocTitle();
    }
  }

  handleScroll = (e) => {
    const { onScroll } = this.props;

    if (onScroll) {
      onScroll(e);
    }
  };

  updateDocTitle() {
    const { $app, title, docTitle } = this.props;
    if (title || docTitle) {
      $app.setPageTitle(docTitle || title);
    }
  }

  handleCloseMenu = () => {
    this.setState({ menuOpen: false });
  };

  handleToggleMenu = (force) => {
    const { $app } = this.props;
    this.setState(
      (prevState) => ({ menuOpen: force !== undefined ? force : !prevState.menuOpen }),
      () => {
        if ($app.searchIsOpen) {
          $app.hideSearch();
        }
      }
    );
  };

  renderDrawer() {
    const { $app, $setup, canDockDrawer, drawerContents, drawerIsOpen, drawerOnClose, drawerProps, hideSubnav } =
      this.props;
    const alwaysDockDrawers = canDockDrawer && $setup.getSettings('layout.alwaysDockDrawers');
    const leftDrawer = $setup.getSettings('layout.drawerLocation') === 'left';

    if (!$app.isExport && drawerContents) {
      return (
        <DrawerErrorBoundaryCmp>
          <Drawer
            isOpen={drawerIsOpen}
            onClose={drawerOnClose}
            contents={drawerContents}
            topOffset={hideSubnav ? NAV_HEIGHT_WITHOUT_SUBNAV : NAV_HEIGHT}
            showDockedInLargeFullScreen
            forceDocked={alwaysDockDrawers}
            left={leftDrawer}
            {...drawerProps}
          />
        </DrawerErrorBoundaryCmp>
      );
    }

    return null;
  }

  renderPageContent() {
    const {
      $app,
      $setup,
      bottomElement,
      children,
      drawerContents,
      drawerIsOpen,
      drawerOnClose,
      title,
      parentLinks,
      scrolls,
      locked,
      hideSubnav,
      insightsMenu,
      showJumpMenu,
      forceShowExport,
      showExport,
      exportButtonProps,
      shareButtonProps,
      subnavTools,
      flexDirection,
      isExportReady,
      pageProps,
      onScroll,
      rightElement,
      ...rest
    } = this.props;

    const leftDrawer = $setup.getSettings('layout.drawerLocation') === 'left';
    const defaultFlexDirection = leftDrawer ? 'row-reverse' : 'row';
    let contentOffset = this.subnavExtraHeight;

    if (hideSubnav) {
      contentOffset = `-${SUBNAV_HEIGHT}px`;
    }

    return (
      <Flex
        flex={1}
        mt={contentOffset}
        width="100%"
        overflow="hidden"
        className="page-main-content" /* not for styling, for event handling */
        flexDirection={drawerContents ? defaultFlexDirection : flexDirection}
        bg="appBackground"
        ref={this.pageRef}
        {...pageProps}
      >
        <PageContentsErrorBoundaryCmp>
          <PageContentContainer
            className={classNames({ scrolls, isExportReady })}
            pt={2}
            px={3}
            pb={3}
            flexDirection={flexDirection}
            overflow={scrolls ? 'hidden scroll' : 'visible'}
            height={scrolls ? 'fit-content' : undefined}
            maxHeight={scrolls ? '100%' : undefined}
            ref={this.pageContainerRef}
            {...rest}
          >
            {children}
          </PageContentContainer>
        </PageContentsErrorBoundaryCmp>

        {this.renderDrawer()}
      </Flex>
    );
  }

  render() {
    const {
      $app,
      title,
      showQuickViewSelector,
      parentLinks,
      locked,
      hideSubnav,
      insightsMenu,
      showJumpMenu,
      forceShowExport,
      showExport,
      exportButtonProps,
      shareButtonProps,
      exportMenuItems,
      additionalExportMenuItems,
      subnavTools,
      subnavToolsPosition,
      toolbar,
      centerElement,
      rightElement,
      bottomElement
    } = this.props;

    const { menuOpen } = this.state;

    if ($app.isExport) {
      return this.renderPageContent();
    }

    let showExportValue = showExport;
    if (forceShowExport) {
      showExportValue = typeof showExport === 'object' && showExport !== null ? showExport : true;
    }

    return (
      <>
        <Navbar
          menuOpen={menuOpen}
          onMenuToggle={this.handleToggleMenu}
          onMenuClose={this.handleCloseMenu}
          locked={locked}
        />

        {!hideSubnav && (
          <Subnav
            title={title}
            showQuickViewSelector={showQuickViewSelector}
            parentLinks={parentLinks}
            insightsMenu={insightsMenu}
            showJumpMenu={showJumpMenu}
            tools={subnavTools}
            toolsPosition={subnavToolsPosition}
            showExport={showExportValue}
            exportMenuItems={exportMenuItems}
            additionalExportMenuItems={additionalExportMenuItems}
            exportButtonProps={exportButtonProps}
            shareButtonProps={shareButtonProps}
            centerElement={centerElement}
            rightElement={rightElement}
            bottomElement={bottomElement}
          />
        )}

        <NavbarMenu
          topOffset={NAVBAR_HEIGHT}
          isOpen={menuOpen}
          onMenuToggle={this.handleToggleMenu}
          onClose={this.handleCloseMenu}
        />

        {toolbar}

        {this.renderPageContent()}
      </>
    );
  }
}

export default Page;
