import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Box, Button, Flex, Grid, Text, Spinner, ButtonGroup } from 'core/components';
import { getMapClassname } from 'app/views/hybrid/utils/map';
import { Circle } from 'app/components/svg';
import CircleGroup from '../CircleGroup';
import Pager from '../Pager';
import ArcLayoutIcon from '../../layouts/arc/ArcLayoutIcon';

@observer
export default class TopKeysLayout extends Component {
  static defaultProps = {
    classPrefix: 'key',
    loading: false,
    highlighted: [],
    itemType: undefined,
    pageSize: 8,
    results: [],
    totalBytes: 0
  };

  state = {
    currentPage: 1
  };

  get results() {
    const { results, selected, highlighted } = this.props;
    const highlightedResults = [];
    const otherResults = [];

    results.forEach((item) => {
      const { key, label } = item;
      const isHighlighted = !selected && highlighted.some((value) => value === key || value === label);

      if (isHighlighted) {
        highlightedResults.push(item);
      } else {
        otherResults.push(item);
      }
    });

    return highlightedResults.concat(otherResults);
  }

  page = (event, step) => {
    const { selected, onSelect } = this.props;
    event.stopPropagation();
    this.setState(({ currentPage }) => ({ currentPage: currentPage + step }));

    if (selected && onSelect) {
      onSelect(null, { event });
    }
  };

  getPercentOfTotal = (data) => {
    const { totalBytes } = this.props;

    if (totalBytes > 0) {
      const pageTotalBytes = data.reduce((acc, item) => acc + (item.bytes || 0), 0);
      let percentOfTotal = Math.round((pageTotalBytes / totalBytes) * 100);

      if (pageTotalBytes > 0 && percentOfTotal === 0) {
        percentOfTotal = '< 1';
      }

      return ` (${percentOfTotal}% of total)`;
    }

    return null;
  };

  renderItem = (item) => {
    const { classPrefix: defaultClassPrefix, selected, highlighted, onSelect, itemType: defaultType } = this.props;
    const { key, type, classPrefix, label, subLabel } = item;
    const itemType = type || defaultType;
    const itemClassPrefix = classPrefix || defaultClassPrefix;
    const keyClass = getMapClassname({ type: itemClassPrefix, value: key });
    const labelClass = getMapClassname({ type: itemClassPrefix, value: label });

    return (
      <CircleGroup
        key={key}
        onClick={(event) => onSelect(key, { event, health: item.health })}
        className={classNames('circle-item-group', 'hybrid-map-selectable-node', {
          selected: selected === key,
          highlighted: !selected && highlighted.some((value) => value === key || value === label),
          deselected: selected && selected !== key
        })}
      >
        <Flex alignItems="center">
          <svg width={24} height={24} viewBox="-1 -1 26 26">
            <Circle cx={12} cy={12} r={12} className={`topKeys ${keyClass} ${labelClass}`} />
            {itemType && <ArcLayoutIcon point={{ x: 12, y: 12 }} type={itemType} iconSize={12} />}
            {item.health && (
              <circle className={classNames('health-indicator', item.health.cssClassName)} cx={20} cy={3} />
            )}
          </svg>
          <Box ml="6px">
            <Text as="div" width={180} lineHeight="12px" ellipsis small>
              {label}
            </Text>
            {subLabel && (
              <Text as="div" small muted>
                {subLabel}
              </Text>
            )}
          </Box>
        </Flex>
      </CircleGroup>
    );
  };

  render() {
    const { emptyState, loading, pageSize } = this.props;
    const { currentPage } = this.state;
    const { results } = this;
    const hasResults = results.length > 0;

    if (loading) {
      return (
        <Flex justifyContent="center" flex={1}>
          <Spinner />
        </Flex>
      );
    }

    if (!hasResults) {
      return emptyState;
    }

    return (
      <Pager data={results} pageSize={pageSize} currentPage={currentPage}>
        {({ pageCount, pageData }) => {
          const percentOfTotal = this.getPercentOfTotal(pageData);

          return (
            <Flex flexDirection="column" justifyContent="space-between" flex={1}>
              <Grid
                gridAutoFlow="column"
                gridTemplateRows={`repeat(${Math.ceil(pageData.length / 2)}, 1fr)`}
                gridTemplateColumns="1fr 1fr"
              >
                {pageData.map((item) => this.renderItem(item))}
              </Grid>

              {pageCount > 1 && (
                <Flex alignItems="center" justifyContent="flex-end" pt="12px">
                  <Text as="div" mr={1} small>
                    Page {currentPage} of {pageCount}
                    <Text fontSize="small" muted as="div">
                      {percentOfTotal}
                    </Text>
                  </Text>
                  <ButtonGroup>
                    <Button
                      icon="chevron-left"
                      disabled={currentPage === 1}
                      onClick={(evt) => this.page(evt, -1)}
                      small
                    />
                    <Button
                      icon="chevron-right"
                      disabled={currentPage === pageCount}
                      onClick={(evt) => this.page(evt, 1)}
                      small
                    />
                  </ButtonGroup>
                </Flex>
              )}
            </Flex>
          );
        }}
      </Pager>
    );
  }
}
