import * as React from 'react';
import classNames from 'classnames';
import { Flex, Grid, Heading } from 'core/components';
import { getMapClassname } from 'app/views/hybrid/utils/map';
import {
  DIRECT_CONNECT_HEIGHT,
  DIRECT_CONNECT_WIDTH,
  FLEX_GAP,
  GRID_ROWS_GAP,
  ICON_SIZE
} from 'app/views/hybrid/utils/cloud/constants';
import MinifiedCloudItem from 'app/views/hybrid/maps/components/MinifiedCloudItem';
import CloudItem from 'app/views/hybrid/maps/components/CloudItem';

const itemHeightWithIcons = DIRECT_CONNECT_HEIGHT * 2;

export default class ItemGrid extends React.Component {
  static defaultProps = {
    itemWidth: DIRECT_CONNECT_WIDTH,
    itemHeight: DIRECT_CONNECT_HEIGHT,
    itemStroke: 'aws.purple',
    itemFill: 'appBackground',
    itemHoverFill: null,
    highlightedNodes: [],
    showTitle: true,
    selectedNode: null,
    onHoverToggle: () => {}
  };

  get emptyState() {
    const { emptyState, items } = this.props;

    if (emptyState) {
      const isEmpty = items.every((item) => item.group?.length === 0);

      if (isEmpty) {
        return emptyState;
      }
    }

    return null;
  }

  render() {
    const {
      className,
      items,
      itemWidth,
      itemHeight,
      itemStroke,
      itemFill,
      itemHoverFill,
      highlightedNodes,
      onHoverToggle,
      onClick,
      showTitle,
      selectedNode
    } = this.props;

    if (this.emptyState) {
      return this.emptyState;
    }

    const labelColumn = showTitle ? `${FLEX_GAP * 2 + ICON_SIZE * 2 + 2}px` : '';

    return (
      <Grid
        // add 2px because on prev border width is 2px and we need to align all items vertically
        gridTemplateColumns={`${labelColumn} auto`}
        alignItems="center"
        gridRowGap={`${GRID_ROWS_GAP}px`}
        my={`${GRID_ROWS_GAP}px`}
      >
        {items.map(({ key, icon, group, name, getSubtitle, willFit, getTitle, getItemType, getItemIcon }) => (
          <React.Fragment key={key}>
            {showTitle && <Heading level={6}>{name}</Heading>}
            <Flex gap={`${FLEX_GAP}px`} flexWrap="wrap" alignItems="center">
              {group.map((item) => {
                const itemName = getTitle ? getTitle(item) : name;
                const subtitle = getSubtitle(item);
                const isMinified = !willFit && !highlightedNodes.includes(item.id);
                const selected = selectedNode && selectedNode.type === key && selectedNode.value === item.id;

                const type = getItemType ? getItemType(item) : key;
                const itemIcon = getItemIcon ? getItemIcon(item) : icon;
                const iconsToDisplay = [];

                const hasExtraIconsSection = iconsToDisplay.length > 0;
                const height = hasExtraIconsSection ? itemHeightWithIcons : itemHeight;

                return (
                  <MinifiedCloudItem
                    key={item.id}
                    className={classNames(getMapClassname({ type, value: item.id }), className)}
                    icon={itemIcon}
                    title={subtitle}
                    onClickCapture={() => onClick({ key: type, item, isMinified })} // enable capture phase because CloudItem swallows click events
                    onMouseEnter={() => onHoverToggle({ type: 'enter', key: type, item })}
                    onMouseLeave={() => onHoverToggle({ type: 'leave', key: type, item })}
                    isMinified={isMinified}
                  >
                    <svg width={itemWidth + 2} height={height + 2} viewBox={`-1 -1 ${itemWidth + 2} ${height + 2}`}>
                      <CloudItem
                        className={classNames({ selected })}
                        width={itemWidth}
                        height={height}
                        innerProps={{ height: itemHeight }}
                        fill={itemFill}
                        hoverFill={itemHoverFill}
                        stroke={itemStroke}
                        icon={itemIcon}
                        title={itemName}
                        subtitle={subtitle}
                        iconsToDisplay={iconsToDisplay}
                      />
                    </svg>
                  </MinifiedCloudItem>
                );
              })}
            </Flex>
          </React.Fragment>
        ))}
      </Grid>
    );
  }
}
