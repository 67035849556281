import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CELL_TYPES, Search } from 'core/components/table';
import RemoveButton from 'core/form/components/RemoveButton';
import { Box, Button, Checkbox, DropdownMenu, Flex, Menu, MenuItem, Text } from 'core/components';

import AdminTable from 'app/components/admin/AdminTable';
import UserCollection from 'app/stores/user/UserCollection';

@inject('$auth', '$rbac')
@observer
class RbacRoleUsers extends Component {
  state = {
    users: new UserCollection()
  };

  componentDidMount() {
    const { model } = this.props;
    const { users } = this.state;
    const roleSetUserIds = model.users.map((user) => user.id);
    // users to chose from, NOT the ones assigned currently
    users.setPresetFilter({
      fn: (user) => !user.get('user_group_id') && !roleSetUserIds.includes(user.id)
    });

    users.fetch();
  }

  refetchRoleSetUsers() {
    const { model } = this.props;
    const { users } = this.state;
    model.fetch().then((updatedModel) => {
      const roleSetUserIds = updatedModel.users.map((user) => user.id);
      users.setPresetFilter({
        fn: (user) => !user.get('user_group_id') && !roleSetUserIds.includes(user.id)
      });
    });
  }

  handleSearch = (e) => {
    const { users } = this.state;
    return users.filter(e.target.value);
  };

  get renderAddUserDropdownMenu() {
    const { model: roleSet, $rbac } = this.props;
    const { users } = this.state;

    return (
      <DropdownMenu
        content={
          <Box>
            <Search collection={users} onChange={this.handleSearch} />
            <Menu py={1} height={310} width={300} overflow="auto" mb={1}>
              {users.map((user) => (
                <MenuItem
                  as={Checkbox}
                  py="4px"
                  key={user.id}
                  label={user.get('user_full_name')}
                  selected={user.isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    user.select({ multi: true, shiftKey: e.shiftKey });
                  }}
                />
              ))}
            </Menu>
            <Flex p={1} justifyContent="space-between">
              <Button
                intent="primary"
                text="Apply"
                disabled={!users.selected?.length}
                className="bp4-popover-dismiss"
                onClick={() =>
                  $rbac
                    .bulkAddUsersToRoleSet(
                      users.selected.map((user) => user.id),
                      roleSet.id
                    )
                    .then(() => {
                      users.clearSelection();
                      return this.refetchRoleSetUsers();
                    })
                }
              />
              <Button className="bp4-popover-dismiss" text="Cancel" mr={2} onClick={() => users.clearSelection()} />
            </Flex>
          </Box>
        }
      >
        <Button ml={2} icon="plus" text="Add User(s)" intent="primary" rightIcon="caret-down" />
      </DropdownMenu>
    );
  }

  get columns() {
    const { $auth, viewOnly, $rbac, model: roleSet } = this.props;
    return [
      {
        name: 'user_name',
        label: 'User Name',
        flexBasis: 150
      },
      {
        name: 'user_full_name',
        label: 'User Full Name',
        flexBasis: 150
      },
      {
        name: 'user_email',
        label: 'User Email',
        flexBasis: 150
      },
      {
        type: CELL_TYPES.ACTION,
        width: 72,
        actions: [
          (model) => {
            if (viewOnly || !$auth.hasRbacPermissions(['rbac.role.user::remove_from_role'])) {
              return <div key="remove" />;
            }

            return (
              <RemoveButton
                key="remove"
                model={model}
                showIcon
                hideText
                small
                popoverProps={{
                  confirmBodyContent: 'Are you sure you want to remove this user?'
                }}
                onRemove={() => {
                  $rbac.bulkRemoveUsersFromRoleSet([model.id], roleSet.id).then(() => this.refetchRoleSetUsers());
                }}
              />
            );
          }
        ]
      }
    ];
  }

  render() {
    const { model, $auth } = this.props;

    return (
      <Flex flexDirection="column" flex={1} px={2}>
        <Box mt={2} ml={1}>
          <Text as="div">
            <b>Note:</b> User assignment changes are applied immediately, regardless of save.
          </Text>
        </Box>

        <Flex mt={1} flexDirection="row" flex={1} style={{ overflow: 'auto' }}>
          <AdminTable
            tableContainerProps={{ bg: 'cardBackground' }}
            collection={model.users}
            flexed
            columns={this.columns}
            tableHeaderControlsPosition="afterSearch"
            tableHeaderControls={
              $auth.hasRbacPermissions(['rbac.role.user::assign_to_role']) && this.renderAddUserDropdownMenu
            }
          />
        </Flex>
      </Flex>
    );
  }
}

export default RbacRoleUsers;
