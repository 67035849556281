import React from 'react';

import { inject } from 'mobx-react';
import { Flex } from 'core/components';

import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import LoadBalancingRule from './LoadBalancingRule';

const AzureLoadBalancingRulesPopover = ({ width, popoutTitle, $hybridMap, nodeData }) => {
  const { azureCloudMapCollection } = $hybridMap;

  const loadBalancingRules = azureCloudMapCollection.getEntityProperty(nodeData, 'loadBalancingRules') ?? [];

  if (loadBalancingRules.length === 0) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title="Load Balancing Rules"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="backlink"
      popoutTitle={popoutTitle}
    >
      <Flex px="4px" gap={1} flexDirection="column">
        <Flex flexDirection="column" gap={1}>
          {loadBalancingRules.map((loadBalancingRule) => (
            <LoadBalancingRule
              key={loadBalancingRule.id}
              nodeData={loadBalancingRule}
              width={width}
              popoutTitle={popoutTitle}
            />
          ))}
        </Flex>
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureLoadBalancingRulesPopover);
