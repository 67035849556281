import React from 'react';
import { FiGrid } from 'react-icons/fi';
import { MenuItem, LinkButton, Link } from 'core/components';
import { withRouter } from 'react-router-dom';
import { pick } from 'lodash';

const queryProps = [
  'all_devices',
  'device_labels',
  'device_name',
  'device_sites',
  'device_types',
  'filters',
  'lookback_seconds',
  'starting_time',
  'ending_time'
];

export const encodeDashboardParams = (query) => encodeURIComponent(JSON.stringify(pick(query, queryProps)));

export const generateDashboardLink = (dashboardId, urlParams) =>
  `/v4/library/dashboards/${dashboardId}${urlParams ? `/urlParams/${urlParams}` : ''}`;

const DashboardLink = ({ as, children = 'Open Dashboard', id, query, urlParams, withIcon, history, ...rest }) => {
  if (query && !urlParams) {
    urlParams = encodeDashboardParams(query);
  }

  const isMenuItem = as === 'MenuItem';
  const isButton = as === 'Button';
  const to = generateDashboardLink(id, urlParams);

  if (isMenuItem) {
    return <MenuItem icon={withIcon ? FiGrid : null} onClick={() => history.push(to)} text={children} {...rest} />;
  }

  if (isButton) {
    return (
      <LinkButton to={to} icon={withIcon ? FiGrid : null} {...rest}>
        {children}
      </LinkButton>
    );
  }

  return (
    <Link to={to} as={as} {...rest}>
      {children}
    </Link>
  );
};

export default withRouter(DashboardLink);
