import React from 'react';
import $devices from 'app/stores/device/$devices';
import $sites from 'app/stores/site/$sites';
import $auth from 'app/stores/$auth';
import $labels from 'app/stores/label/$labels';
import $dictionary from 'app/stores/$dictionary';
import DeviceSubtypeIcon from 'app/components/device/DeviceSubtypeIcon';
import { getDisabledAppProtocolDimensions } from 'app/util/dimensions';
import { kflowTypeLabelMap } from 'app/util/utils';
import { isGoogleCloud } from '@kentik/ui-shared/util/map';

const deviceTypeOrder = ['router', 'aws_subnet', 'azure_subnet', 'gcp_subnet', 'kappa', 'kprobe', 'nprobe'];

const labelOverrides = {
  router: 'All Routers'
};

function getSortValue(subtype, label) {
  const typeOrderIdx = deviceTypeOrder.indexOf(subtype);
  if (typeOrderIdx !== -1) {
    return typeOrderIdx;
  }

  return label.charCodeAt(0) + deviceTypeOrder.length;
}

export function getDeviceTypeOptions(uniqueSubtypes) {
  const device_subtypes = $dictionary.get('device_subtypes', []);
  return (uniqueSubtypes || $devices.uniqueSubtypes)
    .filter((subtype) => subtype !== 'kmetrics')
    .map((subtype) => {
      const deviceSubtype = device_subtypes[subtype];
      if (deviceSubtype) {
        return {
          value: subtype,
          label: labelOverrides[subtype] || deviceSubtype.display_name || subtype,
          iconName: deviceSubtype.icon ? <DeviceSubtypeIcon type={subtype} style={{ width: 14 }} /> : 'help',
          numDevices: $devices.flowDeviceTypeCounts[subtype] || 0,
          devices: $devices.deviceSummariesByType[subtype] || [],
          sortValue: getSortValue(subtype, deviceSubtype.display_name || subtype)
        };
      }

      return {
        value: subtype,
        label: labelOverrides[subtype] || subtype,
        iconName: 'help',
        numDevices: $devices.flowDeviceTypeCounts[subtype] || 0,
        devices: $devices.deviceSummariesByType[subtype] || [],
        sortValue: getSortValue(subtype, subtype)
      };
    })
    .sort((a, b) => a.sortValue - b.sortValue)
    .map(({ sortValue, ...rest }) => rest);
}

export function getDeviceSubtypeOptions() {
  const device_subtypes = Object.values($dictionary.get('device_subtypes', {}));
  return device_subtypes
    .map((subtype) => {
      const { device_subtype, display_name } = subtype;
      return {
        value: device_subtype,
        label: display_name
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function getBaseDeviceTypeOptions() {
  return Object.entries(kflowTypeLabelMap).map(([value, label]) => ({ value, label }));
}

export const getDeviceSubtypeColumns = (subtype) =>
  $devices.getDeviceSubtypeColumns(subtype).then((res) => res.filter(({ metadata }) => !metadata.isMetric));

export const getLabelOptionsForDeviceSelector = (allowOnlyActiveDeviceAttributes = true) =>
  allowOnlyActiveDeviceAttributes
    ? $devices.activeDeviceLabelOptions
    : $labels.options.map(({ bg, ...rest }) => ({ ...rest, color: bg }));

export function getDeviceValues({
  all_devices,
  device_types,
  device_labels,
  device_sites,
  device_name,
  allowOnlyActiveDeviceAttributes = true,
  simpleSiteLabelsOnly = false
}) {
  const values = new Map();

  values.set('all', []);
  values.set('labels', []);
  values.set('sites', []);
  values.set('types', []);
  values.set('devices', []);

  if (all_devices) {
    values.set('all', $devices.activeDeviceSummaries);
    return values;
  }

  const deviceTypeOptions = getDeviceTypeOptions();

  const types = device_types.map((type) => deviceTypeOptions.find((t) => t.value === type));

  const labelOptions = getLabelOptionsForDeviceSelector(allowOnlyActiveDeviceAttributes);
  const labels = labelOptions.length
    ? device_labels.map((labelId) => labelOptions.find((d) => `${d.value}` === `${labelId}`)) // compare string to string
    : [];

  const sites = device_sites.map((siteId) =>
    // Some customers have a lot of devices. Using hash maps instead of arrays will be faster here
    simpleSiteLabelsOnly ? $sites.siteLabelsMap[siteId] : $sites.siteOptionsMap[siteId]
  );

  const devices = device_name.map((name) =>
    $devices.getDeviceSummaryOptionByName({
      deviceName: name,
      activeDevicesOnly: allowOnlyActiveDeviceAttributes
    })
  );

  values.set('all', false);
  values.set('labels', labels.filter(Boolean));
  values.set('sites', sites.filter(Boolean));
  values.set('types', types.filter(Boolean));
  values.set('devices', devices.filter(Boolean));

  return values;
}

export function queryHasDevices(query) {
  const { all_devices, device_types, device_labels, device_sites, device_name } = query;

  return (
    $devices.activeDeviceSummaries.length > 0 &&
    (all_devices ||
      $devices.activeDeviceSummaries.some(
        ({ device_type, device_subtype, labels, site_id, device_name: currDeviceName }) =>
          device_name.includes(currDeviceName) ||
          device_types.includes(device_subtype) ||
          device_types.includes(device_type) ||
          labels.some((label) => device_labels.includes(label.id)) ||
          device_sites.includes(site_id)
      ))
  );
}

export const getDisabledCloudDimensions = ({ device_name, device_labels, device_sites, device_types, all_devices }) => {
  if ($auth.isPresetCompany) {
    return [];
  }

  const gceFilter = (device) => isGoogleCloud(device.cloudExportTask?.cloud_provider ?? device?.cloud_provider);
  const awsFilter = (device) => (device.cloudExportTask?.cloud_provider ?? device?.cloud_provider) === 'aws';
  const ociFilter = (device) => (device.cloudExportTask?.cloud_provider ?? device?.cloud_provider) === 'oci';
  const azureFilter = (device) => (device.cloudExportTask?.cloud_provider ?? device?.cloud_provider) === 'azure';

  let hasGCEDevice;
  let hasAWSDevice;
  let hasAzureDevice;
  let hasOciCloudDevice;

  if (all_devices) {
    hasGCEDevice = $devices.hasGCEDevice;
    hasAWSDevice = $devices.hasAWSDevice;
    hasAzureDevice = $devices.hasAzureDevice;
    hasOciCloudDevice = $devices.hasOCIDevice;
  } else {
    const devices = $devices.getUniqueSelectedDevices({
      device_name,
      device_labels,
      device_sites,
      device_types
    });

    hasGCEDevice = devices.some(gceFilter);
    hasAWSDevice = devices.some(awsFilter);
    hasAzureDevice = devices.some(azureFilter);
    hasOciCloudDevice = devices.some(ociFilter);
  }

  if (!hasGCEDevice && !hasAWSDevice && !hasAzureDevice && !hasOciCloudDevice) {
    return ['Cloud'];
  }

  const disabledGroups = [];

  if (!hasGCEDevice) {
    disabledGroups.push('Google Cloud Platform');
  }

  if (!hasAWSDevice) {
    disabledGroups.push('Amazon Web Services');
  }

  if (!hasAzureDevice) {
    disabledGroups.push('Microsoft Azure');
  }

  if (!hasOciCloudDevice) {
    disabledGroups.push('OCI Cloud Platform');
  }

  return disabledGroups;
};

export const getDisabledSubtypeDimensions = ({
  all_devices,
  device_types,
  device_labels,
  device_sites,
  device_name
}) => {
  if ($auth.isPresetCompany) {
    return [];
  }
  const subtypes = { ...$dictionary.dictionary.device_subtypes };
  const devices = all_devices
    ? $devices.activeDeviceSummaries
    : $devices.getUniqueSelectedDevices({
        device_name,
        device_labels,
        device_sites,
        device_types
      });

  // create list of cloud provider display names to check.
  const cloudProviders = [
    subtypes.aws_subnet.display_name,
    subtypes.gcp_subnet.display_name,
    subtypes.gcp_cloud_run.display_name,
    subtypes.azure_subnet.display_name,
    subtypes.oci_subnet.display_name
  ];

  // remove all device subtypes that are present.
  devices.forEach((device) => delete subtypes[device.device_subtype]);
  const results = Object.values(subtypes).map((subtype) => subtype.display_name);

  // Remaining subtypes are ones that AREN'T present. If all cloud providers are still in results,
  // then there are no cloud providers, so disable "Cloud" dimension group.
  const noCloud = cloudProviders.every((cloudProvider) => results.includes(cloudProvider));

  return noCloud ? [...results, 'Cloud'] : results;
};

export const getDisabledSegmentRoutingDimensions = ({
  all_devices,
  device_types,
  device_labels,
  device_sites,
  device_name
}) => {
  if ($auth.isPresetCompany) {
    return [];
  }

  if (all_devices) {
    return ['dst_sr_sid', 'dst_sr_sidpath'];
  }
  const devices = $devices.getUniqueSelectedDevices({
    device_name,
    device_labels,
    device_sites,
    device_types
  });

  if (devices.some((device) => !device.device_bgp_label_unicast)) {
    return ['dst_sr_sid', 'dst_sr_sidpath'];
  }

  return [];
};

export const getAllDisabledDimensions = ({
  all_devices,
  device_name,
  device_labels,
  device_sites,
  device_types,
  flow_fields,
  filter_groups,
  additionalDisabled = [],
  includeAppProtocolDimensions = true,
  includeKProbeDimensions = true,
  includeSegmentRoutingDimensions = false
}) => {
  const disabledCloudDimensions = getDisabledCloudDimensions({
    all_devices,
    device_name,
    device_labels,
    device_sites,
    device_types
  });

  const disabledSubtypeDimensions = getDisabledSubtypeDimensions({
    all_devices,
    device_name,
    device_labels,
    device_sites,
    device_types
  });

  const disabledKubernetesDimensions = $dictionary.get('kubernetes.supported') ? [] : ['Kubernetes'];

  let disabledAppProtocolDimensions = [];
  let disabledSegmentRoutingDimensions = [];
  let disabledKProbeDimensions = [];

  const hasKProbeSelected = all_devices
    ? $devices.hasDnsProbe
    : $devices.containsDnsProbe({ device_name, device_labels, device_sites, device_types });

  if (includeAppProtocolDimensions) {
    disabledAppProtocolDimensions = getDisabledAppProtocolDimensions(flow_fields, filter_groups);
  }

  if (includeSegmentRoutingDimensions) {
    disabledSegmentRoutingDimensions = getDisabledSegmentRoutingDimensions({
      all_devices,
      device_name,
      device_labels,
      device_sites,
      device_types
    });
  }

  if (includeKProbeDimensions && !hasKProbeSelected) {
    disabledKProbeDimensions = $dictionary.dictionary.kprobeDimensions;
  }

  return [
    ...disabledCloudDimensions,
    ...disabledSubtypeDimensions,
    ...disabledKubernetesDimensions,
    ...disabledKProbeDimensions,
    ...disabledAppProtocolDimensions,
    ...disabledSegmentRoutingDimensions,
    ...additionalDisabled
  ];
};

export function stoppedSendingFlow(flowStatus, deviceId) {
  return (
    !$devices.incompleteDevices.get(deviceId) &&
    flowStatus[deviceId].has_had_flow === true &&
    flowStatus[deviceId].has_recent_flow === false &&
    flowStatus[deviceId].device_status === 'V'
  );
}

export function neverSentFlow(flowStatus, deviceId) {
  return (
    !$devices.incompleteDevices.get(deviceId) &&
    flowStatus[deviceId].has_had_flow === false &&
    flowStatus[deviceId].device_status === 'V' &&
    ($devices.isRouter(deviceId) || $devices.isCloudDevice(deviceId)) &&
    !$devices.isMetricsDevice(deviceId)
  );
}

export function noInterfacesDefined(flowStatus, deviceId) {
  return (
    !$devices.incompleteDevices.get(deviceId) &&
    !$devices.isCloudDevice(deviceId) &&
    flowStatus[deviceId].has_had_flow === true &&
    flowStatus[deviceId].has_recent_flow === true &&
    flowStatus[deviceId].has_interfaces === false &&
    flowStatus[deviceId].device_status === 'V'
  );
}

export function noExternalInterfacesDefined(flowStatus, deviceId) {
  return (
    !$devices.incompleteDevices.get(deviceId) &&
    flowStatus[deviceId].has_had_flow === true &&
    flowStatus[deviceId].has_recent_flow === true &&
    flowStatus[deviceId].has_interfaces === true &&
    flowStatus[deviceId].has_external_interfaces === false &&
    flowStatus[deviceId].device_status === 'V'
  );
}

export function lowInterfaceClassification(flowStatus, deviceId) {
  return (
    !$devices.incompleteDevices.get(deviceId) &&
    flowStatus[deviceId].has_had_flow === true &&
    flowStatus[deviceId].has_recent_flow === true &&
    flowStatus[deviceId].has_interfaces === true &&
    flowStatus[deviceId].percentClassified <= 50 &&
    flowStatus[deviceId].device_status === 'V'
  );
}
