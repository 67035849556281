import React from 'react';

import { inject } from 'mobx-react';
import { Flex } from 'core/components';

import SidebarItem from '../SidebarItem';
import { AzureVirtualHubDetails } from './components';

const AzureVirtualHubsPopover = ({ cloudProvider, width, popoutTitle, $hybridMap, ...rest }) => {
  const { nodeData } = rest;

  const { azureCloudMapCollection } = $hybridMap;

  const virtualHubs = azureCloudMapCollection.getEntityProperty(nodeData, 'virtualHubs');

  if (!virtualHubs || virtualHubs.length === 0) {
    return null;
  }

  const virtualHubsArr = Array.isArray(virtualHubs) ? virtualHubs : [virtualHubs];

  return (
    <SidebarItem
      excludeFormProps
      title="Virtual Hubs"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="chat"
      popoutTitle={popoutTitle}
    >
      <Flex px="4px" gap={1} flexDirection="column">
        {virtualHubsArr.map((virtualHub) => (
          <AzureVirtualHubDetails key={virtualHub.id} nodeData={virtualHub} width={width} popoutTitle={popoutTitle} />
        ))}
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureVirtualHubsPopover);
