import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import SplitPane from 'react-split-pane';

import { FormComponent } from 'core/form';
import { fields, options } from 'app/forms/config/explorerQuery';

import { Box, Card, Flex, FlexColumn } from 'core/components';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import Legend from 'app/components/dataviews/views/legend/Legend';
import Page from 'app/components/page/Page';
import ExplorerQueryModel from 'app/stores/query/ExplorerQueryModel';
import SharedPage from 'app/components/page/SharedPage';
import SharedLinkHeader from 'app/views/sharedLinks/SharedLinkHeader';

const splitPaneProps = {
  split: 'horizontal',
  pane2Style: { display: 'flex' },
  defaultSize: 'calc(50vh - 75px)'
};

@inject('$dataviews', '$explorer', '$setup', '$sharedLinks')
@withRouter
@observer
class Explorer extends Component {
  debouncedResize;

  state = {
    sourceLink: null
  };

  constructor(props) {
    const { $explorer } = props;

    super(props);

    $explorer.clear();
    this.load('load', props);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props;

    if (location.pathname !== nextProps.location.pathname) {
      this.load('reload', nextProps);
    }
  }

  componentWillUnmount() {
    const { $explorer } = this.props;
    $explorer.destroy(true);
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    const { $explorer } = this.props;

    if (this.debouncedResize) {
      clearTimeout(this.debouncedResize);
    }

    this.debouncedResize = setTimeout(() => {
      $explorer.dataview.reflow();
    }, 500);
  };

  load(fn, props) {
    const { match, $explorer, $sharedLinks } = props;
    const { params } = match.params;
    const urlHash = $sharedLinks.data.metadata.hash;

    if (props.location.pathname.includes('/preset')) {
      $explorer.loadPreset();
    } else if (params && !urlHash) {
      $explorer.loadUrlParams(params);
    } else {
      this.updateRecentQueries(urlHash);
      $explorer[`${fn}Hash`](urlHash, undefined, params ? JSON.parse(decodeURIComponent(params)) : params);
    }
  }

  updateRecentQueries(hash) {
    const { $setup } = this.props;
    const recentQueries = $setup.getSettings('recentQueries', []);
    const idx = recentQueries.findIndex((q) => q === hash);
    if (idx >= 0) {
      recentQueries.splice(idx, 1);
    }
    recentQueries.unshift(hash);
    if (recentQueries.length > 10) {
      recentQueries.splice(9);
    }
    $setup.updateSettings({ recentQueries });
  }

  render() {
    const { $explorer, $sharedLinks } = this.props;
    const { dataview } = $explorer;
    const { selectedQuery } = dataview.queryBuckets;

    if (!selectedQuery) {
      return <Page title="Data Explorer" pt={0} pb={0} canFullScreen />;
    }

    const { sourceLink } = this.state;

    const sidebarQuery = ExplorerQueryModel.createFromQueryModel(selectedQuery);

    let explorerChartHeight = 'calc(50vh - 25px)';
    const previousExplorerPaneRatio = localStorage.getItem('kentik.explorerPaneRatio');
    if (previousExplorerPaneRatio) {
      explorerChartHeight = Math.max(window.innerHeight - previousExplorerPaneRatio, 56);
    }

    const dataviewWrapperStyles = {};
    if (dataview.queryBuckets.selectedQuery.get('generatorMode')) {
      dataviewWrapperStyles.borderRadius = 4;
      dataviewWrapperStyles.border = 'thin';
      dataviewWrapperStyles.bg = 'subnavBackground';
    }

    if (dataview.viewType === 'table') {
      dataviewWrapperStyles.borderRadius = 4;
      dataviewWrapperStyles.border = 'thin';
    }

    const dataViewWrapper = (
      <DataViewWrapper
        dataview={dataview}
        sourceLink={sourceLink}
        fitToHeight
        viewProps={{
          showNativeLegend: false,
          hasFooter: true,
          height: '100%'
        }}
        headerProps={{
          showTitleLink: false,
          showViewTypeTag: true,
          shouldArrangeVertically: true,
          showLastUpdated: false,
          showDateRange: true,
          showAppliedFilters: true,
          showDataSources: true
        }}
        ref={this.wrapperRef}
        loadingText="Your query is running; if you have additional adjustments to make, please feel free to do so!"
      >
        {({ component, footer }) => (
          <>
            <Flex flex={1} overflow="auto" {...dataviewWrapperStyles}>
              {component}
            </Flex>
            <Box pt={1}>{footer}</Box>
          </>
        )}
      </DataViewWrapper>
    );

    return (
      <FormComponent fields={fields} options={options} model={sidebarQuery}>
        <SharedPage title="Data Explorer">
          <SharedLinkHeader
            isDataExplorer={$sharedLinks.data.metadata.link_type === $sharedLinks.types.dataExplorer}
            isSavedView={$sharedLinks.data.metadata.link_type === $sharedLinks.types.savedView}
          />
          <Flex width="100%" height="100%" flex={1} pt={2} overflow="hidden">
            <FlexColumn flex={1} position="relative" overflow="hidden">
              <Flex flexDirection="column" flex={1} position="relative" overflow="hidden">
                <SplitPane
                  onDragFinished={this.handlePaneResize}
                  maxSize={-120}
                  minSize={56}
                  {...splitPaneProps}
                  defaultSize={explorerChartHeight}
                  ref={this.splitPaneRef}
                >
                  {dataViewWrapper}
                  <Card display="flex" flex={1} m="1px" style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                    <Legend dataview={dataview} onChange={$explorer.onFormChange} />
                  </Card>
                </SplitPane>
              </Flex>
            </FlexColumn>
          </Flex>
        </SharedPage>
      </FormComponent>
    );
  }
}

export default Explorer;
