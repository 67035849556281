import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { connectThroughMiddleX } from '../commonUtils';

const { ROUTER } = ENTITY_TYPES.get('oci');

const connectToRouter = (props) => connectThroughMiddleX(props);

/**
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const IpSecConnector = ({ targetType, ...rest }) => {
  switch (targetType) {
    case ROUTER:
      return connectToRouter(rest);
    default:
      return null;
  }
};

export default IpSecConnector;
