import React from 'react';

import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import SidebarItem from '../../SidebarItem';
import CloudMetadata from '../../CloudMetadata';

const { SUBNET } = ENTITY_TYPES.get('azure');

const AzureSubnetDetails = ({ width, popoutTitle, nodeData }) => (
  <SidebarItem
    key={nodeData.id}
    excludeFormProps
    title={nodeData.name}
    dialogProps={{ width }}
    fullscreenProps={{ headingOffset: -16 }}
    icon={<CloudIcon cloudProvider="azure" entity={SUBNET} width={16} height={16} />}
    popoutTitle={popoutTitle}
  >
    <CloudMetadata
      node={{
        ...nodeData,
        ...nodeData.properties
      }}
      keys={['name', 'provisioningState', 'addressPrefix']}
    />
  </SidebarItem>
);

export default AzureSubnetDetails;
