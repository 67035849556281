import React, { Component } from 'react';
import { inject } from 'mobx-react';

import Page from 'app/components/page/Page';
import { CalloutOutline, Suspense } from 'core/components';

import HealthIssuesTable from './maps/components/healthIssues/HealthIssuesTable';

@inject('$hybridMap')
export default class HealthIssues extends Component {
  state = {
    loading: true,
    healthIssues: null
  };

  componentDidMount() {
    this.fetchTopology();
  }

  fetchTopology() {
    const { $hybridMap } = this.props;

    $hybridMap
      .getHealthCollection()
      .then((healthIssues) => {
        healthIssues.lookupInterfaceDescriptions();
        this.setState({ loading: false, healthIssues });
      })
      .catch((error) => {
        console.error('Error loading health', error);
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading, healthIssues } = this.state;

    return (
      <Page title="Health Problems" minHeight="auto">
        <Suspense loading={loading}>
          {!healthIssues && <CalloutOutline intent="error">Error loading health information</CalloutOutline>}
          {healthIssues && <HealthIssuesTable healthIssues={healthIssues} />}
        </Suspense>
      </Page>
    );
  }
}
