import { isIpValid } from 'core/util/ip';

export default {
  message:
    'This field requires a comma-separated list of IP addresses and/or subnets.' +
    ' Use a leading - to exclude an IP or subnet',
  fn: (value) => {
    let ips = value;
    if (!Array.isArray(value)) {
      ips = value.split(',');
    }
    ips = ips.map((ip) => (ip.charAt(0) === '-' ? ip.substring(1) : ip));
    return ips.every((ip) => isIpValid(ip));
  }
};
