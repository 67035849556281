import React from 'react';

import { Flex } from 'core/components';

import SidebarItem from '../../SidebarItem';
import CloudMetadata from '../../CloudMetadata';

const AzureP2SConnectionConfigurationDetails = ({ width, popoutTitle, nodeData }) => (
  <SidebarItem
    excludeFormProps
    title={nodeData?.name}
    dialogProps={{ width }}
    fullscreenProps={{ headingOffset: -16 }}
    icon="asterisk"
    popoutTitle={popoutTitle}
  >
    <Flex flexDirection="column" gap={1}>
      <CloudMetadata
        node={{
          ...nodeData,
          ...nodeData.properties
        }}
        keys={['provisioningState', 'vpnClientAddressPool.addressPrefixes']}
      />
    </Flex>
  </SidebarItem>
);

export default AzureP2SConnectionConfigurationDetails;
