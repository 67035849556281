import React from 'react';
import { observable } from 'mobx';
import { FiCopy } from 'react-icons/fi';
import { FaCheck } from 'react-icons/fa';
import { inject, observer } from 'mobx-react';
import Collection from 'core/model/Collection';
import { getEntityType } from 'app/views/hybrid/utils/map';
import { VirtualizedTable, Search } from 'core/components/table';
import { Box, Button, Card, Flex, Icon, Tag, Text } from 'core/components';

import CopyToClipboardButton from 'app/components/CopyToClipboardButton';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';

class RouteTableRulesCollection extends Collection {
  groupBy = 'routeTable';
}

@inject('$hybridMap')
@observer
class RouteTable extends React.Component {
  @observable
  routeTableRule;

  constructor(props) {
    super(props);
    const { routeTableRules } = props;

    this.routeTableRule = new RouteTableRulesCollection(routeTableRules);
  }

  get columns() {
    const { $hybridMap } = this.props;
    const { ociCloudMapCollection } = $hybridMap;

    const columns = [
      {
        label: 'Destination',
        name: 'destination',
        width: 150,
        renderer: ({ value }) => (
          <Flex alignItems="center" gap={1}>
            <Box>
              <Text as="div">{value}</Text>
            </Box>
            <CopyToClipboardButton text={value} copyConfirmationText={null} intent="none" minimal small>
              <Button icon={FiCopy} />
            </CopyToClipboardButton>
          </Flex>
        )
      },
      {
        label: 'Network Entity',
        name: 'networkEntityId',
        renderer: ({ value }) => {
          const entity = ociCloudMapCollection.findEntityById(value);
          return (
            <Flex alignItems="center" gap={1}>
              <Box>
                <CloudIcon cloudProvider="oci" entity={getEntityType(entity)} width={16} height={16} />
              </Box>
              <Box>
                <Text>{entity?.displayName ?? 'Unknown'}</Text>
              </Box>
            </Flex>
          );
        }
      },

      {
        label: 'Description',
        name: 'description'
      }
    ];

    return columns;
  }

  handleSearch = (e) => this.routeTableRule.filter(e.target.value);

  groupSummaryLookup = ({ groupKey, group }) => {
    const lifecycleState = group[0].get('lifecycleState');
    return (
      <>
        {lifecycleState === 'AVAILABLE' && <Icon icon={FaCheck} color="success" />}
        <Text fontWeight="bold" mx={1}>
          {groupKey}
        </Text>
        <Tag minimal round>
          {group.length}
        </Tag>
      </>
    );
  };

  render() {
    return (
      <Flex flexDirection="column" flex={1}>
        <Card height="100%">
          <Search
            p="4px"
            collection={this.routeTableRule}
            onChange={this.handleSearch}
            placeholder="Search route table rule..."
            inputProps={{ value: this.routeTableRule.filterState }}
            autoFocus
          />
          <VirtualizedTable
            style={{ height: 300 }}
            collection={this.routeTableRule}
            columns={this.columns}
            selectOnRowClick={false}
            groupSummaryLookup={this.groupSummaryLookup}
            isCollapsed
            flexed
          />
        </Card>
      </Flex>
    );
  }
}

export default RouteTable;
