import * as React from 'react';
import { observer } from 'mobx-react';
import { Flex, Icon, Text } from 'core/components';
import { Form, Field, Select } from 'core/form';
import { getOption } from 'core/form/components/modalSelect/selectHelpers';
import { getConnectionKey, flattenGroupConnections } from 'app/views/hybrid/utils/links';

const fields = {
  connection: {
    label: 'Connection',
    defaultValue: 'combined'
  }
};

const formOptions = { name: 'Link Connection Selector Form' };

@Form({ fields, options: formOptions })
@observer
export default class LinkConnectionSelector extends React.Component {
  componentDidMount() {
    const { form } = this.props;
    const isCombinedSelected = getOption(this.options, 'combined');

    if (!isCombinedSelected && this.options.length > 0) {
      // get the first selectable option
      const firstOption = this.options.find((o) => !o.groupHeaderProps);

      if (firstOption) {
        form.setValue('connection', firstOption.value);
      }
    }
  }

  getInterfaceLabel = (intf) => {
    if (intf) {
      let label = intf.device_name;

      if (intf.interface_description) {
        label = `${label} (${intf.interface_description})`;
      }

      return label;
    }

    return null;
  };

  getLayerOptions = (connections, header) => {
    if (connections && connections.length > 0) {
      const layerOptions = connections.map((connection) => ({
        value: getConnectionKey(connection),
        connection
      }));

      if (layerOptions.length > 0) {
        return [].concat(
          { label: header, value: header, groupHeaderProps: { top: -4, fontWeight: 'bold', mt: '4px' } },
          layerOptions
        );
      }
    }

    return [];
  };

  get options() {
    const { groupedConnections } = this.props;
    const allConnections = flattenGroupConnections(groupedConnections);
    const connectionOptions = [].concat(
      this.getLayerOptions(groupedConnections.layer3, 'Layer 3'),
      this.getLayerOptions(groupedConnections.layer2, 'Layer 2'),
      this.getLayerOptions(groupedConnections.unknown, 'Connections')
    );

    if (allConnections.length > 1) {
      return [{ label: 'Combined Interfaces', value: 'combined' }, ...connectionOptions];
    }

    return connectionOptions;
  }

  handleConnectionChange = (field) => {
    const { onStateChange } = this.props;
    const selectedConnection = getOption(this.options, field.getValue());

    if (onStateChange) {
      onStateChange({ selectedConnection });
    }
  };

  optionRenderer = (option) => {
    if (option) {
      if (option.label) {
        return option.label;
      }

      return (
        <Flex width="100%">
          <Text ellipsis>{this.getInterfaceLabel(option.connection.interface1)}</Text>
          <Icon icon="arrows-horizontal" mx={2} />
          <Text ellipsis>{this.getInterfaceLabel(option.connection.interface2) || 'Unknown Device'}</Text>
        </Flex>
      );
    }

    return null;
  };

  render() {
    if (this.options.length > 0) {
      return (
        <Field name="connection" options={this.options} showLabel={false} onChange={this.handleConnectionChange}>
          <Select optionFormatter={this.optionRenderer} valueRenderer={this.optionRenderer} inlineLabel fill />
        </Field>
      );
    }

    return null;
  }
}
