import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, Heading, Text } from 'core/components';
import ResourceItem from 'app/views/hybrid/maps/kube/ResourceItem';
import { ENTITY_TYPES } from 'shared/hybrid/constants';

const { CLUSTER } = ENTITY_TYPES.get('kube');

@observer
class ClusterSummary extends React.Component {
  render() {
    const { nodeData } = this.props;
    const { name, region } = nodeData;
    const hasProblem = nodeData?.health?.data.state === 'CRITICAL' || nodeData?.health?.data.state === 'WARNING';
    const className = hasProblem ? nodeData?.health?.cssClassName : '';
    return (
      <>
        <Flex alignItems="center" pt={2} px={2}>
          <Box flex={`0 0 ${54}px`}>
            <svg display="block" width={54} height={54} style={{ margin: '0 auto' }}>
              <g transform="translate(27, 27)">
                <ResourceItem id={name} type="resources" subType={CLUSTER} className={`${className} node`} />
              </g>
            </svg>
          </Box>
          {/* <Icon icon={ClusterIcon} iconSize={40} mr={1} /> */}
          <Box>
            <Text as="div" muted>
              Cluster
            </Text>
            <Heading level={3} mb={0}>
              {name}
            </Heading>
          </Box>
        </Flex>

        <Box p={2} mb={1} borderBottom="thin">
          <Flex mb="4px" alignItems="center">
            <Text muted as="div" mr="4px">
              Region:
            </Text>
            <Text fontWeight="medium">{region}</Text>
          </Flex>
        </Box>
      </>
    );
  }
}

ClusterSummary.PopoutTitle = ({ value }) => `Kubernetes Cluster - ${value}`;

export default ClusterSummary;
