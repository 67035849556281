import { get } from 'lodash';
import uuid from 'uuid';
import { isInSubnet, isIpV6Valid } from 'core/util/ip';
import { buildFilterGroup } from 'core/util/filters';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { getRouteTableSummary, getCoreNetworkAttachmentsForSubnetId } from 'shared/util/aws/utils';

import { ReactComponent as VpcIcon } from 'app/assets/icons/vpc.svg';
import { ReactComponent as SubnetIcon } from 'app/assets/icons/subnet.svg';
import { ReactComponent as InstanceIcon } from 'app/assets/icons/cloud-instance.svg';
import { ReactComponent as DirectConnectIcon } from 'app/assets/icons/direct-connection.svg';
import { ReactComponent as VpnConnectionIcon } from 'app/assets/icons/vpn-connection.svg';
import { ReactComponent as VpcPeeringIcon } from 'app/assets/icons/aws/vpc-peering.svg';
import { ReactComponent as TransitGatewayIcon } from 'app/assets/icons/transit-gateway.svg';
import { ReactComponent as NatGatewayIcon } from 'app/assets/icons/nat-gateway.svg';
import { ReactComponent as VirtualGatewayIcon } from 'app/assets/icons/vpn-gateway.svg';
import { ReactComponent as InternetGatewayIcon } from 'app/assets/icons/internet-gateway.svg';

export const CLOUD_TYPE_DEVICES = ['aws_subnet', 'gcp_subnet', 'azure_subnet', 'oci_subnet'];
export const CLOUD_DASHBOARD_LABELS = {
  azure: 'Microsoft Azure',
  aws: 'Amazon Web Services',
  gcp: 'Google Cloud Platform'
};

export const typeToIconMap = {
  Vpcs: VpcIcon,
  Subnets: SubnetIcon,
  Instances: InstanceIcon,
  NatGateways: NatGatewayIcon,
  VpnGateways: VirtualGatewayIcon,
  VpnConnections: VpnConnectionIcon,
  TransitGateways: TransitGatewayIcon,
  DirectConnections: DirectConnectIcon,
  InternetGateways: InternetGatewayIcon,
  VpcPeeringConnections: VpcPeeringIcon,
  TransitGatewayAttachments: TransitGatewayIcon
};

const {
  LAG,
  VPC,
  SUBNET,
  NETWORK_ACL,
  NAT_GATEWAY,
  VPN_GATEWAY,
  VPC_ENDPOINT,
  VPN_CONNECTIONS,
  TRANSIT_GATEWAY,
  INTERNET_GATEWAY,
  DIRECT_CONNECTION,
  TRANSIT_GATEWAY_ATTACHMENT
} = ENTITY_TYPES.get('aws');

export function getEntities(topology, entityType, options = {}) {
  const entityMap = get(topology, `Entities.${entityType}`, {});

  if (options.asList === true) {
    return Object.values(entityMap);
  }

  return entityMap;
}

export function findEntity(entities, id) {
  const entityMaps = Object.values(entities);

  for (const map of entityMaps) {
    if (map) {
      const entity = map[id];

      if (entity) {
        return entity;
      }
    }
  }

  return null;
}

/*
  Given a topology and prefix list id, expands the cidrs in the prefix list to return an array with prefix list properties attached to each ip
*/
export function expandPrefixListId(topology, prefixListId, options = {}) {
  if (prefixListId) {
    const prefixListEntities = getEntities(topology, 'PrefixLists');
    const prefixList = prefixListEntities[prefixListId];

    if (prefixList) {
      const { Cidrs, ...restPrefixList } = prefixList;

      return Cidrs.map((ip) => {
        const item = { ...restPrefixList, ip };

        if (options.withIpVersion === true) {
          const isIpv6 = isIpV6Valid(ip);

          item.ipVersion = `IPv${isIpv6 ? 6 : 4}`;
        }

        return item;
      });
    }
  }

  return [];
}

export function getSecurityGroupRules(topology, securityGroup) {
  let rules = [];

  if (topology && securityGroup) {
    const permissionReducer = (acc, permission) => {
      // template rule we use as a base
      const rule = {
        direction: permission.direction,
        protocol: permission.IpProtocol,
        fromPort: permission.FromPort,
        toPort: permission.ToPort,
        userIdGroupPairs: permission.UserIdGroupPairs,
        // we expect these to be overwritten by IpRanges, Ipv6Ranges, and PrefixListIds
        ipVersion: 'IPv4',
        resource: '--',
        description: null
      };

      // process the ipv4 ranges
      const ipv4Rules = (permission.IpRanges || []).map((ip) => ({
        ...rule,
        resource: ip.CidrIp,
        description: ip.Description
      }));

      // process the ipv6 ranges
      const ipv6Rules = (permission.Ipv6Ranges || []).map((ip) => ({
        ...rule,
        ipVersion: 'IPv6',
        resource: ip.CidrIpv6,
        description: ip.Description
      }));

      // process the prefix list ids
      const prefixListRules = (permission.PrefixListIds || []).reduce(
        (prefixListAcc, prefixListConfig) =>
          prefixListAcc.concat(
            expandPrefixListId(topology, prefixListConfig.PrefixListId, { withIpVersion: true }).map((prefixList) => ({
              ...rule,
              ipVersion: prefixList.ipVersion,
              resource: prefixList.ip,
              description: prefixListConfig.Description
            }))
          ),
        []
      );

      return acc.concat(ipv4Rules, ipv6Rules, prefixListRules);
    };

    if (securityGroup.IpPermissions) {
      rules = rules.concat(
        securityGroup.IpPermissions.map((permission) => ({ ...permission, direction: 'inbound' })).reduce(
          permissionReducer,
          []
        )
      );
    }

    if (securityGroup.IpPermissionsEgress) {
      rules = rules.concat(
        securityGroup.IpPermissionsEgress.map((permission) => ({ ...permission, direction: 'outbound' })).reduce(
          permissionReducer,
          []
        )
      );
    }
  }

  return rules.map((rule) => ({ ...rule, id: uuid.v4() }));
}

function sortNACLRules(rules) {
  return rules.sort((a, b) => {
    if (a.ruleNumber < b.ruleNumber) {
      return -1;
    }

    if (a.ruleNumber > b.ruleNumber) {
      return 1;
    }

    return 0;
  });
}

export function getNACLRules(nacl) {
  if (nacl && nacl.Entries) {
    return nacl.Entries.map((entry) => {
      // template rule we use as a base
      const rule = {
        direction: 'inbound',
        protocol: entry.Protocol,
        fromPort: null,
        toPort: null,
        ipVersion: 'IPv4',
        resource: '--',
        ruleAction: entry.RuleAction,
        ruleNumber: entry.RuleNumber
      };

      if (entry.Egress === true) {
        rule.direction = 'outbound';
      }

      if (entry.CidrBlock) {
        rule.resource = entry.CidrBlock;
      }

      if (entry.Ipv6CidrBlock) {
        rule.resource = entry.Ipv6CidrBlock;
        rule.ipVersion = 'IPv6';
      }

      if (entry.PortRange) {
        if (entry.PortRange.From) {
          rule.fromPort = entry.PortRange.From;
        }

        if (entry.PortRange.To) {
          rule.toPort = entry.PortRange.To;
        }
      }

      return rule;
    });
  }

  return [];
}

/*
  checkRule has:
    - direction (inbound|outbound)
    - sourceIp
    - sourcePort
    - sourceProtocol
    - destinationIp
    - destinationPort
    - destinationProtocol
*/
function doesSecurityGroupRuleAllowTraffic(securityGroupRule, checkRule) {
  if (checkRule && securityGroupRule.direction === checkRule.direction) {
    const { fromPort, toPort, protocol } = securityGroupRule;

    if (protocol === '-1' || checkRule.sourceProtocol === protocol) {
      const hasFromPort = fromPort && typeof fromPort === 'number' && fromPort !== -1;
      const hasToPort = toPort && typeof toPort === 'number' && toPort !== -1;
      let checkRuleResource = checkRule.destinationIp;
      let checkRulePort = Number(checkRule.destinationPort);
      let ipAllowed = true;
      let portAllowed = true;

      if (securityGroupRule.direction === 'outbound') {
        checkRuleResource = checkRule.sourceIp;
        checkRulePort = Number(checkRule.sourcePort);
      }

      ipAllowed = isInSubnet(checkRuleResource, securityGroupRule.resource);

      if (hasFromPort) {
        if (hasToPort) {
          portAllowed = checkRulePort >= fromPort && checkRulePort <= toPort;
        } else {
          portAllowed = checkRulePort === fromPort;
        }
      }

      return ipAllowed && portAllowed;
    }
  }

  return true;
}

/*
  checkRule has:
    - direction (inbound|outbound)
    - sourceIp
    - sourcePort
    - destinationIp
    - destinationPort
*/
export function checkSecurityGroupRules(topology, securityGroup, checkRule) {
  // expand all the rules in the group, accounting for all ips and expanded prefix lists
  const rules = (securityGroup.rules || getSecurityGroupRules(topology, securityGroup)).reduce(
    (acc, rule) => {
      const allowed = doesSecurityGroupRuleAllowTraffic(rule, checkRule);

      acc.all.push(rule);

      if (allowed) {
        acc.allowed.push(rule);
      } else {
        acc.denied.push(rule);
      }

      return acc;
    },
    {
      all: [],
      allowed: [],
      denied: []
    }
  );

  return {
    securityGroup,
    checkRule,
    rules,
    allowed:
      // a security group rule is allowed if all rules do not match the direction
      // or there is at least one matching rule to allow the traffic
      rules.all.every((rule) => rule.direction !== checkRule?.direction) ||
      rules.allowed.find((allowRule) => allowRule.direction === checkRule?.direction)
  };
}

/*
  checkRule has:
    - direction (inbound|outbound)
    - sourceIp
    - sourcePort
    - sourceProtocol
    - destinationIp
    - destinationPort
    - destinationProtocol
*/
function doesNACLMatchTraffic(naclRule, checkRule) {
  if (checkRule && naclRule.direction === checkRule.direction) {
    const { fromPort, toPort, protocol } = naclRule;

    if (protocol === '-1' || checkRule.sourceProtocol === protocol) {
      const hasFromPort = fromPort && typeof fromPort === 'number' && fromPort !== -1;
      const hasToPort = toPort && typeof toPort === 'number' && toPort !== -1;
      let checkRuleResource = checkRule.destinationIp;
      let checkRulePort = Number(checkRule.destinationPort);
      let ipMatched = true;
      let portMatched = true;

      if (naclRule.direction === 'outbound') {
        checkRuleResource = checkRule.sourceIp;
        checkRulePort = Number(checkRule.sourcePort);
      }

      ipMatched = isInSubnet(checkRuleResource, naclRule.resource);

      if (hasFromPort) {
        if (hasToPort) {
          portMatched = checkRulePort >= fromPort && checkRulePort <= toPort;
        } else {
          portMatched = checkRulePort === fromPort;
        }
      }

      return ipMatched && portMatched;
    }
  }

  return false;
}

/*
  checkRule has:
    - direction (inbound|outbound)
    - sourceIp
    - sourcePort
    - destinationIp
    - destinationPort
*/
export function checkNACLRules(nacl, checkRule) {
  const sortedRules = sortNACLRules(nacl.rules || getNACLRules(nacl));

  // expand all the rules in the group, accounting for all ips and expanded prefix lists
  const rules = sortedRules.reduce(
    (acc, rule) => {
      const matched = doesNACLMatchTraffic(rule, checkRule);

      acc.all.push(rule);

      if (!acc.hasMatch && matched && rule.ruleAction === 'deny') {
        acc.denied.push(rule);
      } else {
        acc.allowed.push(rule);
      }

      if (matched) {
        acc.hasMatch = true;
      }

      return acc;
    },
    {
      all: [],
      allowed: [],
      denied: [],
      hasMatch: false
    }
  );

  return {
    nacl,
    checkRule,
    rules,
    allowed: rules.denied.length === 0
  };
}

export function securityEntitiesDenyTraffic(securityEntities) {
  return securityEntities.some((securityEntity) => securityEntity.deniesTraffic);
}

export function getTransitGatewayRouteTableSummary(entities, routeTableIds) {
  return getRouteTableSummary(entities, routeTableIds, 'TransitGatewayRouteTables');
}

export function getCoreNetworkAttachmentsForVpcId(entities, vpcId) {
  return Object.values(entities[SUBNET] ?? {})
    .filter((subnet) => subnet.VpcId === vpcId)
    .map((subnet) => getCoreNetworkAttachmentsForSubnetId(entities, subnet.id))
    .flat()
    .filter((t) => t);
}

function getTransitGatewayFilterSubGroup({ vpcs, gatewayId, cidrs }) {
  if (vpcs && vpcs.length > 0 && gatewayId && cidrs) {
    // this filter group as it stands now can represent a group of vpcs directly connected to a gateway
    const filterGroup = buildFilterGroup({
      filterGroups: [
        buildFilterGroup({
          connector: 'Any',
          filters: vpcs.map((vpcFilter) => ({
            filterField: 'kt_aws_src_vpc_id',
            operator: '=',
            filterValue: vpcFilter
          }))
        }),

        buildFilterGroup({
          filters: [
            {
              filterField: 'ktsubtype__aws_subnet__STR17',
              operator: '=',
              filterValue: gatewayId
            }
          ]
        })
      ]
    });

    if (cidrs.length > 0) {
      // we're processing an adjacent transit gateway, add in the dest ip filter group
      filterGroup.filterGroups.push(
        buildFilterGroup({
          connector: 'Any',
          filters: cidrs.map((cidr) => ({
            filterField: 'inet_dst_addr',
            operator: 'ILIKE',
            filterValue: cidr
          }))
        })
      );
    }

    return filterGroup;
  }

  return null;
}

export function getTransitGatewayFilterGroup(topology, gatewayId) {
  if (topology && topology.Entities && topology.Entities.TransitGatewayAttachments) {
    const attachmentList = Object.values(topology.Entities.TransitGatewayAttachments);

    // group the attachments by transit gateway id, then subgroup that list by resource type
    const attachmentGroups = attachmentList.reduce((acc, attachment) => {
      const { TransitGatewayId, ResourceType } = attachment;

      return {
        ...acc,
        [TransitGatewayId]: {
          ...acc[TransitGatewayId],
          [ResourceType]: [...((acc[TransitGatewayId] && acc[TransitGatewayId][ResourceType]) || []), attachment]
        }
      };
    }, {});

    // creates a map of filter groups keyed by transit gateway id
    const gatewayFilterGroups = Object.keys(attachmentGroups).reduce((acc, transitGatewayId) => {
      const item = attachmentGroups[transitGatewayId];
      const filterGroup = buildFilterGroup({ connector: 'Any' });

      acc[transitGatewayId] = filterGroup;

      if (item.vpc) {
        // this filter matches the vpcs directly connected to the specified transit gateway
        filterGroup.filterGroups.push(
          getTransitGatewayFilterSubGroup({
            vpcs: item.vpc.map((vpcAttachment) => vpcAttachment.ResourceId),
            gatewayId: transitGatewayId,
            cidrs: []
          })
        );
      }

      if (item.peering) {
        // start looking for traffic forwarded from transit gateways adjacent to the specified transit gateway
        item.peering.forEach((adjacentAttachment) => {
          const adjacentAttachmentGroup = attachmentGroups[adjacentAttachment.ResourceId];

          if (adjacentAttachmentGroup && adjacentAttachmentGroup.vpc) {
            adjacentAttachmentGroup.vpc.forEach((vpcAttachment) => {
              const sourceVpc = vpcAttachment.ResourceId;
              const routeTableId = vpcAttachment.Association && vpcAttachment.Association.TransitGatewayRouteTableId;
              const routeTable = findEntity(topology.Entities, routeTableId);
              let cidrs = [];

              if (routeTable) {
                // get the cidrs using route records that forward to the specified transit gateway
                cidrs = routeTable.Routes.reduce((cidrAcc, route) => {
                  if (
                    route.TransitGatewayAttachments &&
                    route.TransitGatewayAttachments.find((attachment) => attachment.ResourceId === transitGatewayId)
                  ) {
                    cidrAcc.push(route.DestinationCidrBlock);
                  }

                  return cidrAcc;
                }, []);
              }

              if (cidrs.length > 0) {
                filterGroup.filterGroups.push(
                  getTransitGatewayFilterSubGroup({
                    vpcs: [sourceVpc],
                    gatewayId: adjacentAttachment.ResourceId,
                    cidrs
                  })
                );
              }
            });
          }
        });
      }

      return acc;
    }, {});

    return gatewayFilterGroups[gatewayId] || null;
  }

  return null;
}

export const typeToLabelMap = {
  Routers: 'Router',
  Vpcs: 'VPC',
  Subnets: 'Subnet',
  Instances: 'Instance',
  CustomerGateways: 'Customer Gateway',
  InternetGateways: 'Internet Gateway',
  NatGateways: 'NAT Gateway',
  TransitGateways: 'Transit Gateway',
  VpcPeeringConnections: 'Peering Connection',
  VpnGateways: 'VPN Gateway',
  DirectConnections: 'Direct Connection',
  DirectConnectGateways: 'Direct Connect Gateway',
  VirtualInterfaces: 'Virtual Interface'
};

export function idToType(id) {
  if (id && id?.startsWith) {
    if (id.startsWith('dxcon-')) {
      return DIRECT_CONNECTION;
    }

    if (id.startsWith('dxlag-')) {
      return LAG;
    }

    if (id.startsWith('igw-')) {
      return INTERNET_GATEWAY;
    }

    if (id.startsWith('nat-')) {
      return NAT_GATEWAY;
    }

    if (id.startsWith('tgw-attach')) {
      return TRANSIT_GATEWAY_ATTACHMENT;
    }

    if (id.startsWith('tgw-')) {
      return TRANSIT_GATEWAY;
    }

    if (id.startsWith('vgw-')) {
      return VPN_GATEWAY;
    }

    if (id.startsWith('vpc-')) {
      return VPC;
    }

    if (id.startsWith('vpce-')) {
      return VPC_ENDPOINT;
    }

    if (id.startsWith('vpn-')) {
      return VPN_CONNECTIONS;
    }

    if (id.startsWith('acl-')) {
      return NETWORK_ACL;
    }

    if (id.startsWith('subnet-')) {
      return SUBNET;
    }
  }

  return null;
}

export function getIconById(id) {
  const type = idToType(id);

  return typeToIconMap[type];
}

export function getNameFromEntity(entity) {
  return entity?.Name ?? entity?.Tags?.find((tag) => tag.Key === 'Name')?.Value ?? entity.id;
}

export function getConsoleUrl({ type, value, nodeData }) {
  const regionName = nodeData?.RegionName;

  if (regionName) {
    if (type === 'subnet' || value.startsWith('subnet-')) {
      return `https://console.aws.amazon.com/vpc/home?region=${regionName}#SubnetDetails:subnetId=${value}`;
    }

    if (type === 'vpc' || value.startsWith('vpc-')) {
      return `https://console.aws.amazon.com/vpc/home?region=${regionName}#VpcDetails:VpcId=${value}`;
    }

    if (value.startsWith('igw-')) {
      return `https://console.aws.amazon.com/vpc/home?region=${regionName}#InternetGateway:internetGatewayId=${value}`;
    }

    if (value.startsWith('nat-')) {
      return `https://console.aws.amazon.com/vpc/home?region=${regionName}#NatGatewayDetails:natGatewayId=${value}`;
    }

    if (value.startsWith('tgw-')) {
      return `https://console.aws.amazon.com/vpc/home?region=${regionName}#TransitGatewayDetails:transitGatewayId=${value}`;
    }

    if (value.startsWith('vgw-')) {
      return `https://console.aws.amazon.com/vpc/home?region=${regionName}#VpnGateways:sort=VpnGatewayId`;
    }

    if (value.startsWith('pcx-')) {
      return `https://console.aws.amazon.com/vpc/home?region=${regionName}#PeeringConnections`;
    }

    if (value.startsWith('acl-')) {
      return `https://console.aws.amazon.com/vpc/home?region=${regionName}#NetworkAclDetails:networkAclId=${value}`;
    }

    if (value.startsWith('sg-')) {
      return `https://console.aws.amazon.com/vpc/home?region=${regionName}#SecurityGroup:groupId=${value}`;
    }

    if (value.startsWith('i-')) {
      return `https://console.aws.amazon.com/ec2/home?region=${regionName}#InstanceDetails:instanceId=${value}`;
    }

    return `https://console.aws.amazon.com/vpc/home?region=${regionName}#subnets:`;
  }

  return 'https://console.aws.amazon.com/vpc/home';
}
