import { get } from 'lodash';
import { getAllDisabledDimensions } from 'app/util/devices';
import $dictionary from 'app/stores/$dictionary';

/**
 * Checks that user has no disabled dimensions selected, based on selected devices.
 * Other dimension settings (ex: includeAppProtocolDimensions) should come in as booleans.
 */

function onlyAvailableDimensions(value) {
  const deviceSelectionKeys = ['all_devices', 'device_types', 'device_labels', 'device_sites', 'device_name'];

  // if no dimensions, we're done
  if (!value || value.length === 0) {
    return true;
  }

  const deviceData = deviceSelectionKeys.reduce((deviceDataObj, selectionKey) => {
    deviceDataObj[selectionKey] = get(this.validator.input.selected_devices, selectionKey, []);
    return deviceDataObj;
  }, {});

  const disabledDimensions = getAllDisabledDimensions({
    ...deviceData,
    includeAppProtocolDimensions: false
  });

  // Check if any selected values are directly disabled
  let hasDisabledSelection = value.find((dimension) => {
    // Disabled dimensions are labels, while selected dimensions are values.
    const labelMatch = $dictionary.flatDimensionLabels.find((dim) => dim.value === dimension);
    return disabledDimensions.includes(labelMatch);
  });

  // Check if any selected values are dimensions in disabled categories
  if (!hasDisabledSelection) {
    const flatDisabledDimensionValues = disabledDimensions.flatMap((disabledDimension) =>
      ($dictionary.getFlatDimensionOptionsForCategory(disabledDimension) || []).map(
        (flatDimension) => flatDimension.value
      )
    );
    hasDisabledSelection = value.some((dimension) => flatDisabledDimensionValues.includes(dimension));
  }

  return !hasDisabledSelection;
}

export default {
  fn: onlyAvailableDimensions,
  message: 'Device selection does not allow for current dimension selection.'
};
