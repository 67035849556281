import React from 'react';
import { observer } from 'mobx-react';
import { VirtualizedTable, Search } from 'core/components/table';
import { Box, Button, Card, Flex, Icon, Text } from 'core/components';
import { AzureRouteTableSummaryCollection } from 'app/stores/hybrid/azure';

import { FiCopy } from 'react-icons/fi';
import { FaCheck } from 'react-icons/fa';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';

@observer
class RouteTable extends React.Component {
  constructor(props) {
    super(props);
    const { routes } = props;

    this.routesCollection = new AzureRouteTableSummaryCollection(routes);
  }

  get columns() {
    const columns = [
      {
        label: 'State',
        name: 'properties.provisioningState',
        width: 50,
        renderer: ({ value }) => {
          if (value !== 'Succeeded') {
            return <Icon icon="error" color="danger" />;
          }

          return <Icon icon={FaCheck} intent="success" />;
        }
      },

      {
        label: 'Destination',
        name: 'properties.addressPrefix',
        width: 145,
        renderer: ({ value }) => (
          <Flex alignItems="center" gap={1}>
            <Box>
              <Text as="div">{value}</Text>
            </Box>
            <CopyToClipboardButton text={value} copyConfirmationText={null} intent="none" minimal small>
              <Button icon={FiCopy} />
            </CopyToClipboardButton>
          </Flex>
        )
      },
      {
        label: 'Route Target',
        name: 'properties',
        width: 145,
        renderer: ({ value }) => {
          const { nextHopType, nextHopIpAddress } = value;
          return (
            <Flex alignItems="flex-start" flexDirection="column">
              <Box>
                <Text as="div">{nextHopType}</Text>
              </Box>
              <Flex alignItems="center" gap={1}>
                <Box>
                  <Text as="b">{nextHopIpAddress}</Text>
                </Box>
                <CopyToClipboardButton text={nextHopIpAddress} copyConfirmationText={null} intent="none" minimal small>
                  <Button icon={FiCopy} />
                </CopyToClipboardButton>
              </Flex>
            </Flex>
          );
        }
      }
    ];

    return columns;
  }

  handleSearch = (e) => this.routesCollection.filter(e.target.value);

  render() {
    return (
      <Flex flexDirection="column" flex={1}>
        <Card height="100%">
          <Search
            p="4px"
            collection={this.routesCollection}
            onChange={this.handleSearch}
            placeholder="Search Route Table..."
            inputProps={{ value: this.routesCollection.filterState }}
            autoFocus
          />
          <VirtualizedTable
            style={{ height: 300 }}
            collection={this.routesCollection}
            columns={this.columns}
            selectOnRowClick={false}
            isCollapsed
            flexed
          />
        </Card>
      </Flex>
    );
  }
}

export default RouteTable;
