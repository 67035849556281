import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { capitalize } from 'lodash';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { ButtonLink, Flex, Spinner, Suspense, Text, Tag } from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import ResultsTable from 'app/components/resultsTable/ResultsTable';
import { getHealthClass } from '../../../utils/health';

const KUBE_ENTITY_TYPES = ENTITY_TYPES.get('kube');
const { RESOURCE, POD, NODE } = KUBE_ENTITY_TYPES;

function getPodFilterGroup({ nodeData, entities }) {
  if (nodeData.type === 'deployments') {
    const pods = Object.values(entities.pods).filter((pod) => pod.deploymentId === nodeData.id);

    if (pods.length > 0) {
      return {
        connector: 'Any',
        filterGroups: pods.map((pod) => ({
          connector: 'All',
          filters: [
            { filterField: 'ktsubtype__kappa__STR08', operator: '=', filterValue: pod.name },
            pod.spec?.nodeName && {
              filterField: 'ktsubtype__kappa__STR06',
              operator: '=',
              filterValue: pod.spec.nodeName
            }
          ].filter(Boolean)
        }))
      };
    }
  }

  if (nodeData.type === 'services') {
    const labels = Object.entries(nodeData.spec.selector || {}).map(([key, value]) => `${key}=${value}`);

    if (labels.length > 0) {
      return {
        connector: 'All',
        filters: [{ filterField: 'ktsubtype__kappa__STR20', operator: 'ILIKE', filterValue: labels.join(',') }]
      };
    }
  }

  if (nodeData.type === 'nodes') {
    const pods = Object.values(entities.pods).filter((pod) => pod.spec?.nodeName === nodeData.name);

    return {
      connector: 'All',
      filters: [
        { filterField: 'ktsubtype__kappa__STR08', operator: '=', filterValue: pods.map((pod) => pod.name).join(',') },
        { filterField: 'ktsubtype__kappa__STR06', operator: '=', filterValue: nodeData.name }
      ]
    };
  }

  // fallback
  return {
    connector: 'All',
    filters: [{ filterField: 'ktsubtype__kappa__STR08', operator: 'ILIKE', filterValue: nodeData.name }]
  };
}

@inject('$hybridMap')
@observer
export default class PodsSummary extends React.Component {
  get podsQuery() {
    const { deviceName, queryOverrides, entities, nodeData, $hybridMap } = this.props;
    const { namespace } = nodeData;
    const metric = ['ktsubtype__kappa__STR08'];

    if (nodeData.type === 'nodes') {
      metric.push('ktsubtype__kappa__STR09');
    } else {
      metric.push('ktsubtype__kappa__STR06');
    }

    return $hybridMap.getQuery({
      all_devices: false,
      device_name: [deviceName],
      show_overlay: false,
      show_total_overlay: false,
      depth: 100,
      topx: 100,
      viz_type: 'table',
      aggregateTypes: ['p98th_ktsubtype__kappa__APPL_LATENCY_MS', 'avg_bits_per_sec'],
      outsort: 'avg_bits_per_sec',
      filters: {
        connector: 'All',
        filterGroups: [
          getPodFilterGroup({ nodeData, entities }),
          {
            connector: 'All',
            not: false,
            filters: [
              { filterField: 'ktsubtype__kappa__STR10', operator: '=', filterValue: 'pod' },
              namespace && { filterField: 'ktsubtype__kappa__STR09', operator: '=', filterValue: namespace }
            ].filter(Boolean)
          }
        ]
      },
      metric,
      ...queryOverrides
    });
  }

  get resultsFilter() {
    const { entities, nodeData } = this.props;

    if (nodeData.type === 'nodes') {
      return null;
    }

    const pods = Object.values(entities.pods);

    return {
      fn: (result) => {
        const podName = result.get('kt_k8s_src_pod_name');
        const nodeName = result.get('ktsubtype__kappa__STR06');
        return pods.find((pod) => pod.name === podName)?.spec?.nodeName === nodeName;
      }
    };
  }

  handleClickResult = ({ result, type, value }) => {
    const { entities, nodeData, deviceName, cloudProvider, namespace, onPushConfig, $hybridMap } = this.props;
    const latency = result.get('p98th_ktsubtype__kappa__APPL_LATENCY_MS');
    const healthData = { state: latency > $hybridMap.latencyThreshold ? 'CRITICAL' : 'GOOD' };

    onPushConfig({
      type: RESOURCE,
      subType: type,
      value,
      result,
      deviceName,
      cloudProvider,
      isKubeDetail: true,
      namespace: type === POD ? namespace : null,
      pods: type === NODE,
      entities,
      center: true,
      showDetails: true,
      parentNodeData: type === NODE ? {} : nodeData,
      nodeData: {
        name: value,
        type,
        typeLabel: capitalize(type.replace(/s$/, '')),
        health: { cssClassName: getHealthClass(healthData), data: healthData },
        ...entities[type]?.[value]
      }
    });
  };

  render() {
    const { nodeData, $hybridMap } = this.props;
    const overrides = [];

    overrides.push({
      key: 'kt_k8s_src_pod_name',
      label: 'Pod Name',
      renderer: ({ model, value }) => {
        const latency = model.get('p98th_ktsubtype__kappa__APPL_LATENCY_MS');
        const hasProblem = latency > $hybridMap.latencyThreshold;

        return (
          <>
            <ButtonLink
              ellipsis
              title={value}
              onClick={() => this.handleClickResult({ type: 'pods', value, result: model })}
            >
              {value}
            </ButtonLink>
            {hasProblem && (
              <>
                <br />
                <Tag intent="danger" minimal round>
                  High Latency
                </Tag>
              </>
            )}
          </>
        );
      }
    });

    if (nodeData.type === 'nodes') {
      overrides.push({
        key: 'kt_k8s_src_pod_ns',
        label: 'Namespace'
      });
    } else {
      overrides.push({
        key: 'ktsubtype__kappa__STR06',
        label: 'Node',
        renderer: ({ model, value }) => {
          const latency = model.get('p98th_ktsubtype__kappa__APPL_LATENCY_MS');
          const hasProblem = latency > $hybridMap.latencyThreshold;

          return (
            <>
              <ButtonLink
                ellipsis
                title={value}
                onClick={() => this.handleClickResult({ type: 'nodes', value, result: model })}
              >
                {value}
              </ButtonLink>
              {hasProblem && (
                <>
                  <br />
                  <Tag intent="danger" minimal round>
                    High Latency
                  </Tag>
                </>
              )}
            </>
          );
        }
      });
    }

    return (
      <LightweightDataViewWrapper query={this.podsQuery} resultsPresetFilter={this.resultsFilter}>
        {({ loading, dataview, queryModel, results, bucket }) => (
          <Suspense loading={loading} fallback={<Spinner pt={1} size={24} />}>
            <Flex overflow="auto">
              <ResultsTable
                query={this.podsQuery}
                loading={loading}
                dataview={dataview}
                queryModel={queryModel}
                queryResultsCollection={results}
                bucket={bucket}
                showSparklines={false}
                stickyHeader
                mergeOverlayCells
                dimensionOverrides={overrides}
                valueOverrides={[
                  {
                    key: 'p98th_ktsubtype__kappa__APPL_LATENCY_MS',
                    width: 77,
                    label: (
                      <>
                        <Text as="div">Latency</Text>
                        <Text muted fontWeight="normal">
                          ms
                        </Text>
                      </>
                    )
                  },
                  {
                    key: 'avg_bits_per_sec',
                    width: 78
                  }
                ]}
              />
            </Flex>
          </Suspense>
        )}
      </LightweightDataViewWrapper>
    );
  }
}
