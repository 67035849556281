import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import { Box, Button, Flex, Text } from 'core/components';
import KentikLogo from 'app/components/KentikLogo';
import AdminTable from 'app/components/admin/AdminTable';
import { CELL_TYPES } from 'core/components/table';
import { formatDateTime } from 'core/util/dateUtils';
import RemoveButton from 'core/form/components/RemoveButton';

@inject('$auth', '$rbac')
@withRouter
@observer
class RbacRoles extends Component {
  componentWillUnmount() {
    const { $rbac } = this.props;
    $rbac.collection.resetState();
  }

  getColumns = () => {
    const { $auth } = this.props;
    const { history } = this.props;

    const columns = [
      {
        name: 'role_name',
        label: 'Role Name',
        flexBasis: 30
      },
      {
        name: 'role_description',
        label: 'Description',
        flexBasis: 40
      },
      {
        name: 'count',
        label: '# Users',
        flexBasis: 10
      },
      {
        name: 'cdate',
        label: 'Created',
        flexBasis: 10,
        renderer: ({ value }) => formatDateTime(value)
      },
      {
        name: 'edate',
        label: 'Last Modified',
        flexBasis: 10,
        renderer: ({ value }) => formatDateTime(value)
      },
      {
        type: CELL_TYPES.ACTION,
        width: 72,
        actions: [
          (model) => {
            const viewOnly = !$auth.hasRbacPermissions(['rbac.role::update']) || model.attributes.company_id === null;

            return (
              <Button
                key="edit"
                icon={viewOnly ? 'eye-open' : 'edit'}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/v4/settings/rbac/roleDetails/${model.id}`);
                }}
                text=""
                title="Edit"
                minimal
                small
              />
            );
          },
          (model) => {
            if (!$auth.hasRbacPermissions(['rbac.role::delete']) || model.attributes.company_id === null) {
              return <div key="remove" />;
            }

            return (
              <RemoveButton
                key="remove"
                popoverProps={{
                  confirmBodyContent: `Deleting this role will impact ${model.attributes.count} users. Are you sure?`
                }}
                model={model}
                showIcon
                hideText
                small
              />
            );
          }
        ]
      }
    ];

    return columns;
  };

  groupSummaryLookupFn = ({ groupKey }) => (
    <>
      <Flex fontWeight="bold" mx={1} style={{ alignItems: 'stretch' }}>
        {groupKey === 'null' ? (
          <>
            <KentikLogo onlyMark style={{ width: 16 }} />
            <Text mr={2}>Kentik-Managed Roles</Text>
          </>
        ) : (
          <>
            <Text mr={2}>Custom Roles</Text>
          </>
        )}
      </Flex>
    </>
  );

  onCreate = () => {
    const { history } = this.props;
    history.push('/v4/settings/rbac/roleDetails/');
  };

  render() {
    const { $rbac, $auth } = this.props;

    return (
      <Flex flexDirection="column" flex={1}>
        <AdminTable
          fetchCollectionOnMount
          collection={$rbac.collection}
          columns={this.getColumns()}
          groupSummaryLookup={this.groupSummaryLookupFn}
          tableHeaderControlsPosition="afterSearch"
          tableHeaderControls={
            $auth.hasRbacPermissions(['rbac.role::create']) && (
              <Box ml={1}>
                <Button
                  key="create"
                  intent="primary"
                  icon={FiPlus}
                  onClick={this.onCreate}
                  text="Create a Role"
                  title="Create a Role"
                />
              </Box>
            )
          }
        />
      </Flex>
    );
  }
}

export default RbacRoles;
