import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { isEqual, uniqueId } from 'lodash';
import { interfaceKeyRegex } from 'app/components/dataviews/views/legend/legendUtils';
import ViewInExplorerButton from 'app/components/dataviews/tools/ViewInExplorerButton';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';

const emptyResults = ['', '---', 'Unassigned'];

@observer
export default class NodeLinkGenerator extends Component {
  static defaultProps = {
    queries: [],
    debug: false,
    onLinksUpdate: () => {}
  };

  static getDerivedStateFromProps(props, state) {
    const { queries } = props;

    if (!isEqual(queries, state.queries)) {
      return { queriesId: uniqueId(), queries, results: [] };
    }

    return null;
  }

  state = {
    queriesId: 0,
    queries: [],
    results: []
  };

  processResults(queryResults) {
    const links = [];

    queryResults.forEach(({ source, sourceValue, target, direction, results }) => {
      results.each((result) => {
        let value = result.get('key');

        if (emptyResults.includes(value)) {
          return;
        }

        let link = links.find((l) => l.target.type === target && l.target.value === value);

        if (target === 'interface') {
          const interfaceParts = interfaceKeyRegex.exec(value);

          if (interfaceParts) {
            value = Number(interfaceParts.groups.snmp_id);
          }
        }

        if (!link) {
          link = {
            source: { type: source, value: sourceValue },
            target: { type: target, value },
            inbound: 0,
            outbound: 0
          };
          links.push(link);
        }

        link[direction] = result.get('avg_bits_per_sec');
      });
    });

    links.forEach((link) => {
      link.total = link.inbound + link.outbound;
    });

    // do not show links with 0 traffic on both sides
    return links.filter((link) => Math.round(link.inbound) > 0 || Math.round(link.outbound) > 0);
  }

  handleQueryComplete = (data, index) => {
    const { onLinksUpdate } = this.props;

    this.setState(({ queries, results }) => {
      results[index] = data;

      if (results.filter((d) => d).length === queries.length) {
        const links = this.processResults(results);
        onLinksUpdate(links);
      }

      return { results };
    });
  };

  render() {
    const { debug } = this.props;
    const { queriesId, queries } = this.state;

    return queries.map(({ query, ...rest }, index) => {
      const key = `${rest.source}-${rest.sourceValue}-${rest.target}-${rest.direction}-${queriesId}`;
      return (
        <React.Fragment key={key}>
          <LightweightDataViewWrapper
            query={query}
            onQueryComplete={({ results }) => this.handleQueryComplete({ ...rest, results }, index)}
          />
          {debug && <ViewInExplorerButton key={key} text={key} query={query} openInNewWindow />}
        </React.Fragment>
      );
    });
  }
}
