import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, Redirect } from 'react-router-dom';
import { Intent } from '@blueprintjs/core';
import Recaptcha from 'react-grecaptcha';

import { Box, Button, Callout, Card, Flex, Heading, Icon, showClientErrorToast } from 'core/components';
import { Field, Form, InputGroup } from 'core/form';
import { fields, options } from 'app/forms/config/ssoLookup';

const labelStyle = {
  fontSize: 16,
  fontWeight: 500
};

@Form({ fields, options })
@inject('$auth')
@observer
class SsoLookup extends Component {
  state = {};

  componentDidMount() {
    const { $auth, form } = this.props;
    $auth
      .getOpenConfig()
      .then(() => {
        form.getField('recaptcha').setRules($auth.openConfig.recaptchaEnabled ? 'required' : []);
      })
      .catch(() => {
        showClientErrorToast('Error fetching initial configuration');
      });

    sessionStorage.setItem('kentik.ssoInitialReferer', $auth.openConfig.initialReferer);
  }

  recaptchaCallback = (response) => {
    const { form } = this.props;
    form.getField('recaptcha').setValue(response);
  };

  recaptchaExpire = () => {
    const { form } = this.props;
    form.getField('recaptcha').setValue(null);
  };

  handleSubmit = (form, formData) => {
    const { $auth } = this.props;
    const { email, recaptcha } = formData;
    $auth
      .ssoLookup({
        email,
        recaptcha
      })
      .then((result) => {
        const { companies, errorStatus } = result;

        if ($auth.openConfig.recaptchaEnabled) {
          window.grecaptcha.reset();
          form.getField('recaptcha').setValue(null);
        }

        if (errorStatus || !companies.length) {
          form.invalidate(
            "This could mean either your company doesn't have SSO enabled or that we were unable to " +
              'locate a SSO enabled company via email domain within allowed security parameters. If ' +
              'your company has SSO enabled, please contact your Kentik system administrator for Single Sign-on details.'
          );
        } else if (companies.length > 1) {
          this.setState({ reqComplete: true, companies });
        } else {
          this.setState({ ssoRedirectPath: `/login/sso/${companies[0].company_name}` });
        }
      });
  };

  render() {
    const { form, $auth } = this.props;
    const { reqComplete, ssoRedirectPath, companies } = this.state;
    const showRecaptcha = $auth.openConfig && $auth.openConfig.recaptchaEnabled;

    if (!$auth.openConfig) {
      return null;
    }

    // If ssoRedirect path is set, redirect immediately.
    if (ssoRedirectPath) {
      return <Redirect to={{ pathname: ssoRedirectPath }} />;
    }

    return (
      <Card maxWidth={600} minHeight={340} mx="auto" p={3} elevation={4}>
        <Flex flexDirection="column" alignItems="center">
          <Heading level={2}>Locate your company&apos;s Single Sign-on page.</Heading>
        </Flex>
        <Callout p={2} my={2} title="Don't have Single-Sign on?">
          <span className="pt-text-muted">
            If your company doesn&apos;t have Kentik Single Sign-on and you&apos;d like to see this feature enabled,
            please contact your Kentik system administrator to request it.
            <p>
              <Link to="/">Return to Kentik</Link>
            </p>
          </span>
        </Callout>
        {!reqComplete && (
          <Box>
            <Field
              name="email"
              autoFocus
              labelStyle={labelStyle}
              large
              placeholder="email@yourCompany.com"
              helpText="Enter email associated with your Kentik account. NOTE: For security purposes, only the email domain will be used for lookup"
            >
              <InputGroup />
            </Field>
            {showRecaptcha && (
              <Box mb={2}>
                <Recaptcha
                  sitekey={$auth.openConfig.recaptchaSiteKey}
                  callback={this.recaptchaCallback}
                  expiredCallback={this.recaptchaExpire}
                />
              </Box>
            )}
            {form.error && (
              <Callout intent="danger" className="pt-icon-error" my={2}>
                <h5>SSO results not found</h5>
                {form.error}
              </Callout>
            )}
            <Button
              large
              fill
              intent={Intent.PRIMARY}
              text="Submit"
              disabled={!$auth.openConfig || !form.dirty || !form.valid}
              loading={$auth.ssoLookupInProgress}
              onClick={() => form.submit(this.handleSubmit)}
            />
          </Box>
        )}
        {reqComplete && companies && (
          <Callout as={Flex} intent="success" flexDirection="column" alignItems="center" pb={2}>
            <h5>Mulitple Results Found</h5> Multiple results have been found for the provided email domain. Select to be
            redirected to the appropriate Kentik login page.
            <ul className="pt-list pt-list-unstyled">
              {companies.map((company) => (
                <li key={company.company_name}>
                  <Icon name="dot" />
                  <Link to={`/login/sso/${company.company_name}`}>{company.company_name_full}</Link>
                </li>
              ))}
            </ul>
          </Callout>
        )}
      </Card>
    );
  }
}

export default SsoLookup;
