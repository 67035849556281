import * as React from 'react';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { Box, Flex, Text, Heading } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';

import CoreNetworkAttachmentDetails from './CoreNetworkAttachmentDetails';

const { CORE_NETWORK_ATTACHMENT } = ENTITY_TYPES.get('aws');

const CoreNetworkAttachmentSummary = (config) => {
  const { nodeData } = config;

  return (
    <>
      <Box>
        <Flex borderBottom="thin" p={2} alignItems="center">
          <CloudIcon cloudProvider="aws" entity={CORE_NETWORK_ATTACHMENT} />
          <Box flex={1} ml={1}>
            <Text as="div" muted>
              Core Network Attachment
            </Text>
            <Heading mb={0} level={4}>
              {nodeData.Name}
            </Heading>
          </Box>
        </Flex>
        {nodeData && (
          <CoreNetworkAttachmentDetails title="Details" icon="more" data={nodeData} openOnMount {...config} />
        )}
      </Box>
    </>
  );
};

CoreNetworkAttachmentSummary.PopoutTitle = () => (
  <Flex alignItems="center">
    <CloudIcon cloudProvider="aws" entity={CORE_NETWORK_ATTACHMENT} />
    <Box ml={1}>Core Network Attachment</Box>
  </Flex>
);

export default CoreNetworkAttachmentSummary;
