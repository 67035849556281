import React, { useState, useEffect } from 'react';
import { inject } from 'mobx-react';
import { FaCheck } from 'react-icons/fa';
import { AwsRouteTable } from 'app/views/hybrid/maps/aws/components';
import { idToType } from 'app/views/hybrid/utils/aws';
import { ENTITY_TYPES } from 'shared/hybrid/constants';

import { Flex, Heading, Tag, Spinner, Suspense } from 'core/components';

import SidebarItem from '../SidebarItem';
import { greekDisplay } from '../cloudUtil';

const { TRANSIT_GATEWAY, ROUTE_TABLE, SUBNET, VPC } = ENTITY_TYPES.get('aws');

const filterRouteTableSummary = (routeTableSummary, { vpcId, gatewayId, subnetId, entities } = {}) => {
  if (routeTableSummary) {
    const filteredRoutes = routeTableSummary.routes
      .map((route) => {
        const { routeTableId } = route;

        let { routeTable } = route;
        if (routeTableId) {
          routeTable = entities[ROUTE_TABLE][routeTableId];
        }
        let associationLabel = null;

        if (routeTable) {
          if (routeTable.main) {
            // demonstrate an association with main
            associationLabel = '(Associated with Main)';

            if (vpcId && routeTable.vpcId === vpcId) {
              // a vpcId match along with the main property gives a direct relationship
              associationLabel = '(Main)';
            }
          }

          if (routeTable.subnetIds && routeTable.subnetIds.includes(subnetId)) {
            // matching subnet id relationship
            associationLabel = '(Subnet Route Table)';
          }
        }

        return { ...route, routeTable: { ...routeTable, associationLabel } };
      })
      .filter((route) => {
        const matches = [];

        if (vpcId) {
          matches.push(route.routeTable.vpcId === vpcId);
        }

        if (gatewayId) {
          matches.push(route.routeTable.gatewayIds.includes(gatewayId));
        }

        if (subnetId) {
          matches.push(route.routeTable.subnetIds.includes(subnetId));
        }

        return matches.every((match) => match === true);
      });

    if (filteredRoutes.length > 0) {
      const routeCounts = filteredRoutes.reduce(
        (acc, route) => {
          if (route && route.state === 'blackhole') {
            acc.totalBlackholeRoutes += 1;
          } else {
            acc.totalActiveRoutes += 1;
          }

          return acc;
        },
        {
          totalActiveRoutes: 0,
          totalBlackholeRoutes: 0
        }
      );

      return {
        ...routeTableSummary,
        ...routeCounts,
        routes: filteredRoutes
      };
    }
  }

  return routeTableSummary;
};

const AwsRouteTablePopover = ({
  width,
  popoutTitle,
  nodeData,
  type,
  subType,
  value,
  onShowPath,
  topology,
  $hybridMap
}) => {
  const { tgwRouteTableSummary } = nodeData;
  let { routeTableSummary } = nodeData;
  const [loading, setLoading] = useState(true);
  const [filteredRouteTableSummary, setFilteredRouteTableSummary] = useState({});

  // we don't want to have to compute the route table summary for all entities because it's slow and unnecessary
  // so only render if we have a route table summary already, or if one of these entities, we'll call computeRouteTableSummary() to get it
  const isSupported =
    tgwRouteTableSummary || routeTableSummary || [TRANSIT_GATEWAY, SUBNET, VPC].includes(idToType(value));

  const title = tgwRouteTableSummary || idToType(value) === TRANSIT_GATEWAY ? 'TGW Route Table' : 'Route Table';

  const computeRouteTableSummary = async () => {
    const { awsCloudMapCollection, sidebarSettings } = $hybridMap;

    if (idToType(value) === SUBNET) {
      routeTableSummary = await awsCloudMapCollection.computeSubnetRouteTableSummary(value, sidebarSettings.timeRange);
    }

    if (idToType(value) === VPC) {
      routeTableSummary = await awsCloudMapCollection.computeVpcRouteTableSummary(value, sidebarSettings.timeRange);
    }

    const routeTableSummaryData = routeTableSummary || tgwRouteTableSummary;

    if (!routeTableSummaryData) {
      return {};
    }

    const vpcId = (type === 'vpc' || subType === 'Vpc') && value;
    const gatewayId = ['VpnGateway', 'CustomerGateway', 'DirectConnectGateway'].includes(subType) && value;
    const subnetId = type === 'subnet' && value;

    const summary = filterRouteTableSummary(routeTableSummaryData, {
      vpcId,
      gatewayId,
      subnetId,
      entities: topology.Entities
    });

    return summary;
  };

  useEffect(() => {
    const runComputation = async () => {
      setLoading(true);
      const summary = await computeRouteTableSummary();

      setFilteredRouteTableSummary(summary);
      setLoading(false);
    };
    runComputation();
  }, [nodeData]);

  if (!isSupported) {
    return null;
  }

  const {
    totalActiveRoutes = 0,
    totalBlackholeRoutes = 0,
    routes = [],
    routeTables: filteredRouteTables
  } = filteredRouteTableSummary;
  // get unique route tables if we don't have anything in filtered results
  const routeTableSet = [...new Set(routes.map((route) => route.routeTableId))];
  const routeTables =
    Array.isArray(filteredRouteTables) && filteredRouteTables.length ? filteredRouteTables : routeTableSet;

  return (
    <SidebarItem
      title={
        <Flex alignItems="center">
          <Heading level={5} mb={0}>
            {title}
          </Heading>
          {!loading && (
            <>
              <Tag mx={1} intent="primary" icon={FaCheck} title={`${routeTables.length} tables`} minimal round>
                {routeTables.length}
              </Tag>
              <Tag mx={1} intent="success" icon={FaCheck} title={`${totalActiveRoutes} active routes`} minimal round>
                {greekDisplay(totalActiveRoutes)}
              </Tag>
              {totalBlackholeRoutes > 0 && (
                <Tag
                  mr={1}
                  intent="danger"
                  icon="error"
                  title={`${totalBlackholeRoutes} blackhole routes`}
                  minimal
                  round
                >
                  {greekDisplay(totalBlackholeRoutes)}
                </Tag>
              )}
            </>
          )}
        </Flex>
      }
      popoutTitle={popoutTitle}
      icon="route"
      dialogProps={{ width: `${width}px` }}
    >
      <Suspense
        loading={loading}
        fallback={
          <Flex mt={2} alignItems="flex-start" justifyContent="center" width="100%">
            <Spinner />
          </Flex>
        }
      >
        <AwsRouteTable routeTableSummary={filteredRouteTableSummary} onShowPath={onShowPath} />
      </Suspense>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AwsRouteTablePopover);
