import { inject } from 'mobx-react';
import React from 'react';
import { Box, Flex, Icon, Dialog, Text } from 'core/components';
import { AzureConsoleLink } from 'app/views/hybrid/maps/azure/components';

import CloudMetadata from '../../CloudMetadata';
import SecurityGroupRulesTable from './RulesTable';

@inject('$hybridMap')
class SecurityGroupDialog extends React.Component {
  state = {
    isDialogOpen: false
  };

  handleOpenDialog = () => this.setState({ isDialogOpen: true });

  handleCloseDialog = () => this.setState({ isDialogOpen: false });

  render() {
    const { isDialogOpen } = this.state;
    const { dialogProps, nodeData, $hybridMap, children } = this.props;
    const { azureCloudMapCollection } = $hybridMap;

    const securityGroupData = azureCloudMapCollection.getEntityProperty(nodeData, 'networkSecurityGroup');
    if (!securityGroupData) {
      return null;
    }

    const securityGroupAttachedSubnets = azureCloudMapCollection.getEntityProperty(securityGroupData, 'subnets');
    const targets = securityGroupAttachedSubnets?.map(({ name }) => name) ?? [];

    const childrenWithUnClick = React.cloneElement(children, { onClick: this.handleOpenDialog });

    const securityRules = azureCloudMapCollection.getEntityProperty(securityGroupData, 'securityRules');
    const defaultSecurityRules = azureCloudMapCollection.getEntityProperty(securityGroupData, 'defaultSecurityRules');
    const associatedNetworkInterfaces = azureCloudMapCollection.getEntityProperty(
      securityGroupData,
      'networkInterfaces'
    );

    const securityGroupMetadata = {
      ...securityGroupData,
      'Subnet Association': nodeData.name,
      'Network Interfaces Association':
        associatedNetworkInterfaces?.length > 0 ? associatedNetworkInterfaces?.map(({ name }) => name) : 'N/A'
    };

    return (
      <>
        {childrenWithUnClick}
        <Dialog
          isOpen={isDialogOpen}
          onClose={this.handleCloseDialog}
          height="fit-content"
          width="35%"
          title={
            <Flex my={1} justifyContent="space-between" alignItems="center">
              <Box>
                <Icon icon="shield" mr="4px" />
                <Text>Security Group {securityGroupData.name}</Text>
                <Text as="div" color="muted" small>
                  {`applied to ${targets.join(', ')}`}
                </Text>
              </Box>
              <AzureConsoleLink entity={securityGroupData} />
            </Flex>
          }
          {...dialogProps}
        >
          <Dialog.Body>
            <Flex flexDirection="column" gap={1}>
              <CloudMetadata
                node={{
                  ...securityGroupMetadata
                }}
                keys={[
                  'name',
                  'kentik.uri.resourceGroup',
                  'Subnet Association',
                  'Network Interfaces Association',
                  'location',
                  'kentik.subscriptionId',
                  'kentik.tenantId'
                ]}
                mb={2}
                maxHeight={200}
                overflow="auto"
                small
              />

              <SecurityGroupRulesTable securityRules={securityRules} label="Security Rules" />

              <SecurityGroupRulesTable securityRules={defaultSecurityRules} label="Default Security Rules" />
            </Flex>
          </Dialog.Body>
        </Dialog>
      </>
    );
  }
}

export default SecurityGroupDialog;
