import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import NotFound from 'app/views/NotFound';
import Kube from './Kube';
import ClusterDetails from './ClusterDetails';
import NamespaceDetails from './NamespaceDetails';

export const BASE_ROUTE = '/v4/cloud/kube';

@observer
class KubeRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={BASE_ROUTE} component={Kube} />
        <Route exact path={`${BASE_ROUTE}/cluster/:clusterId`} component={ClusterDetails} />
        <Route exact path={`${BASE_ROUTE}/cluster/:cloud/:clusterName`} component={ClusterDetails} />
        <Route exact path={`${BASE_ROUTE}/cluster/:clusterId/namespace/:namespaceName`} component={NamespaceDetails} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default KubeRoutes;
