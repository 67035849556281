import { get } from 'lodash';
import { MAX_WINDOW_LENGTH_MIN } from 'shared/alerting/constants';

function customMaxActivationTimeWindow() {
  const timeWindowUnit = get(this, 'validator.input.activate.timeUnit');
  const timeWindow = parseInt(get(this, 'validator.input.activate.timeWindow'), 10) || 0;
  const inputValue = timeWindowUnit === 'hour' ? timeWindow * 60 : timeWindow;

  return inputValue <= MAX_WINDOW_LENGTH_MIN;
}

export default {
  message: `Threshold activation frequency cannot exceed ${MAX_WINDOW_LENGTH_MIN} minutes (${
    MAX_WINDOW_LENGTH_MIN / 60
  } hours)`,
  fn: customMaxActivationTimeWindow
};
