/**
 * Will draw the curve down and left
 * Example:
 *       ↓
 *       ↓ l
 *       ↓
 * ← ← ← * x,y
 *   l
 * @param x point for center of the curve
 * @param y point for initial y point
 * @param l is used to calculate angel of the curve
 * @returns array of points
 */
export const curveDownLeft = (x, y, l) => [
  [x, y - l],
  [x - l, y]
];

/**
 * Will draw the curve up and right
 * Example:
 *         l
 * x,y * → → →
 *     ↑
 *     ↑ l
 *     ↑
 * @param x point for center of the curve
 * @param y point for initial y point
 * @param l is used to calculate angel of the curve
 * @returns array of points
 */
export const curveUpRight = (x, y, l) => curveDownLeft(x, y, l * -1);

/**
 * Will draw the curve left and up
 * Example:
 *     ↑
 *     ↑ l
 *     ↑
 * x,y * ← ← ←
 *         l
 * @param x point for center of the curve
 * @param y point for initial y point
 * @param l is used to calculate angel of the curve
 * @returns array of points
 */
export const curveLeftUp = (x, y, l) => [
  [x + l, y],
  [x, y - l]
];

/**
 * Will draw the curve left and up
 * Example:
 *   l
 * → → → * x,y
 *       ↓
 *       ↓ l
 *       ↓
 * @param x point for center of the curve
 * @param y point for initial y point
 * @param l is used to calculate angel of the curve
 * @returns array of points
 */
export const curveRightDown = (x, y, l) => curveLeftUp(x, y, l * -1);

/**
 * Will draw the curve up and left
 * Example:
 *   l
 * ← ← ← * x,y
 *       ↑
 *       ↑ l
 *       ↑
 * @param x point for center of the curve
 * @param y point for initial y point
 * @param l is used to calculate angel of the curve
 * @returns array of points
 */
export const curveUpLeft = (x, y, l) => [
  [x, y + l],
  [x - l, y]
];

/**
 * Will draw the curve up and left
 * Example:
 *     ↓
 *   l ↓
 *     ↓
 * x,y * → → →
 *         l
 * @param x point for center of the curve
 * @param y point for initial y point
 * @param l is used to calculate angel of the curve
 * @returns array of points
 */
export const curveDownRight = (x, y, l) => curveUpLeft(x, y, l * -1);

/**
 * Will draw the curve left and down
 * Example:
 *         l
 * x,y * ← ← ←
 *     ↓
 *   l ↓
 *     ↓
 * @param x point for center of the curve
 * @param y point for initial y point
 * @param l is used to calculate angel of the curve
 * @returns array of points
 */
export const curveLeftDown = (x, y, l) => [
  [x + l, y],
  [x, y + l]
];

/**
 * Will draw the curve left and down
 * Example:
 *       ↑
 *       ↑ l
 *       ↑
 * → → → * x,y
 *   l
 * @param x point for center of the curve
 * @param y point for initial y point
 * @param l is used to calculate angel of the curve
 * @returns array of points
 */
export const curveRightUp = (x, y, l) => curveLeftUp(x, y, l * -1);
