import * as React from 'react';
import { getTagValue } from 'shared/util/map';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';

const CoreNetworkAttachmentDetails = ({
  data,
  width,
  popoutTitle,
  title = null,
  icon = 'asterisk',
  openOnMount = false
}) => {
  if (!data) {
    return null;
  }

  const name = getTagValue(data, 'Name');

  return (
    <SidebarItem
      excludeFormProps
      title={title ?? name}
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon={icon}
      popoutTitle={popoutTitle}
      openOnMount={openOnMount}
    >
      <CloudMetadata
        node={{ ...data }}
        keys={['id', 'SegmentName', 'EdgeLocation', 'AttachmentType', 'State', 'Tags']}
      />
    </SidebarItem>
  );
};

export default CoreNetworkAttachmentDetails;
