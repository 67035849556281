import React from 'react';
import { inject } from 'mobx-react';
import { Text, Icon, Box, Flex } from 'core/components';
import KentikLogo from 'app/components/KentikLogo';
import FavoriteButton from 'app/views/core/FavoriteButton';
import ResultMenuItem from './ResultMenuItem';

function Dashboard({ item, selected, query, $dictionary, IconCmp, onItemClick, showFavorite, daysAgo, ...rest }) {
  const { id, dash_title, name, description, path } = item;
  const isPreset =
    item.share_level === 'org' &&
    item.company_id.toString() === $dictionary.dictionary.templateDashboardsCID.toString();

  return (
    <ResultMenuItem
      {...rest}
      selected={selected}
      onClick={() => onItemClick({ type: 'dashboard', id, path }, 'Search Results')}
    >
      <Flex gridArea="icon" alignItems="center" justifyContent={showFavorite ? 'space-between' : 'center'}>
        {isPreset ? <KentikLogo onlyMark style={{ width: 22 }} /> : <IconCmp iconSize={22} />}
        {showFavorite && <FavoriteButton type="dashboard" id={id} name={dash_title || name} ml={1} />}
      </Flex>

      <Box gridArea="title">
        <Text className="title">{dash_title || name}</Text>
      </Box>

      <Flex gridArea="rightIcon" alignItems="center" fontSize={12}>
        <Icon icon={selected ? 'key-enter' : undefined} color="#ffffff" />
        {daysAgo && <Text pl={1}>{daysAgo}</Text>}
      </Flex>

      <Text
        className="description"
        gridArea="description"
        muted
        small
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        style={{ overflow: 'hidden' }}
      >
        {description || 'No description'}
      </Text>
    </ResultMenuItem>
  );
}

export default inject('$dictionary')(Dashboard);
