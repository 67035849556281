import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { deepClone } from 'core/util';
import CopyToClipboardDialog from 'app/components/CopyToClipboardDialog';

@inject('$dictionary')
@observer
export default class MetricsApiCallDialog extends Component {
  get json() {
    const { query, showDataApiCurlDialogOpen, showChartApiCurlDialogOpen, showReportApiCurlDialogOpen } = this.props;
    const q = deepClone(query.kmetrics);
    const response = { version: 4 };

    delete q.streamingUpdate;

    if (showDataApiCurlDialogOpen) {
      delete q.viz;
    }

    if (showChartApiCurlDialogOpen) {
      response.imageType = 'png';
    }

    if (showReportApiCurlDialogOpen) {
      response.imageType = 'pdf';
    }

    response.query = q;
    return response;
  }

  getCurlCommand(type) {
    const { $dictionary } = this.props;
    const apiToken = '<YOUR_API_TOKEN_HERE>';
    const apiEmail = '<YOUR_EMAIL_HERE>';
    const apiUrl = $dictionary.get('baseAPIUrl').replace('/v5', '/next/v5');

    return `curl -v -H 'X-CH-Auth-Email:${apiEmail}' -H 'X-CH-Auth-API-Token:${apiToken}' -H 'Content-Type: application/json' -d '${JSON.stringify(
      this.json
    )}' ${apiUrl}/query/${type}`;
  }

  render() {
    const {
      showChartApiCurlDialogOpen,
      showDataApiCurlDialogOpen,
      showApiJsonDialogOpen,
      showReportApiCurlDialogOpen,
      onClose
    } = this.props;

    if (showChartApiCurlDialogOpen) {
      return (
        <CopyToClipboardDialog
          title="Chart API Call via cURL"
          isOpen={showChartApiCurlDialogOpen}
          onClose={onClose}
          value={this.getCurlCommand('nmschart')}
          dialogHeight={500}
        />
      );
    }

    if (showReportApiCurlDialogOpen) {
      return (
        <CopyToClipboardDialog
          title="Report API Call via cURL"
          isOpen={showReportApiCurlDialogOpen}
          onClose={onClose}
          value={this.getCurlCommand('nmschart')}
          dialogHeight={500}
        />
      );
    }

    if (showDataApiCurlDialogOpen) {
      return (
        <CopyToClipboardDialog
          title="Data API Call via cURL"
          isOpen={showDataApiCurlDialogOpen}
          onClose={onClose}
          value={this.getCurlCommand('nms')}
          dialogHeight={500}
        />
      );
    }

    if (showApiJsonDialogOpen) {
      return (
        <CopyToClipboardDialog
          title="Data API JSON Input"
          isOpen={showApiJsonDialogOpen}
          onClose={onClose}
          value={JSON.stringify(this.json, null, 2)}
          dialogHeight={600}
        />
      );
    }

    return null;
  }
}
