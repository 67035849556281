import * as React from 'react';
import { isEqual as _isEqual } from 'lodash';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import storeLoader from 'app/stores/storeLoader';
import { Suspense } from 'core/components';
import SidebarDetails from './SidebarDetails';
import { makeConfig } from './configHelper';

export default function withSidebarDetails(WrappedComponent) {
  @storeLoader('$devices', '$sites')
  @inject('$hybridMap')
  @withRouter
  @observer
  class WrapperComponent extends React.Component {
    state = {
      config: null,
      initialConfig: null,
      configStack: []
    };

    static getDerivedStateFromProps(_props, state) {
      const { config, initialConfig } = state;

      if (initialConfig) {
        /**
         * When $hybridmap.settingsModel changes, we need make sure the config is still valid,
         * otherwise we should regenerate it to enfore all data/charts to follow new settings/filters
         * */
        const newConfig = makeConfig(initialConfig);
        if (!_isEqual(newConfig, config)) {
          return { config: newConfig, configStack: [] };
        }
      }

      return null;
    }

    handlePopSidebarConfig = () => {
      this.setState((prevState) => ({ configStack: prevState.configStack.slice(0, -1) }));
    };

    handlePushSidebarConfig = (initialConfig) => {
      this.setState((prevState) => {
        const config = makeConfig(initialConfig);
        return { configStack: prevState.configStack.concat(config) };
      });
    };

    handleSetSidebarDetails = (initialConfig) => {
      let config = null;

      if (initialConfig) {
        // at this point we have a raw config and will probably want to decorate it
        // with additional details that haven't been submitted via the setSidebarDetails call
        config = makeConfig(initialConfig);
      }

      this.setState({ config, initialConfig, configStack: [] });
    };

    get sidebarDetailsPanel() {
      const { config, configStack } = this.state;
      const hasConfig = config && Object.keys(config).length > 0;
      const hasMultiConfig = hasConfig && configStack.length > 0;

      if (hasMultiConfig) {
        const configs = [config].concat(configStack);
        return configs.map((singleConfig) => {
          const SidebarComponent = singleConfig.sidebarComponent || SidebarDetails;
          return (
            <SidebarComponent
              key={config.id || config.value}
              {...singleConfig}
              onPushConfig={this.handlePushSidebarConfig}
            />
          );
        });
      }

      if (hasConfig) {
        const SidebarComponent = config.sidebarComponent || SidebarDetails;
        return (
          <SidebarComponent key={config.id || config.value} {...config} onPushConfig={this.handlePushSidebarConfig} />
        );
      }

      return null;
    }

    render() {
      const { $hybridMap, loading, ...restProps } = this.props;

      return (
        <Suspense loading={loading}>
          <WrappedComponent
            {...restProps}
            onPopSidebarConfig={this.handlePopSidebarConfig}
            setSidebarDetails={this.handleSetSidebarDetails}
            sidebarDetailsPanel={this.sidebarDetailsPanel}
          />
        </Suspense>
      );
    }
  }

  return WrapperComponent;
}
