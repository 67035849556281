import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Flex } from 'core/components';
import { formatBytesGreek } from 'core/util/greekPrefixing';
import { renderLegend } from '../../../utils/legend';

@inject('$hybridMap')
@observer
export default class TrafficLegend extends Component {
  canvas = React.createRef();

  componentDidMount() {
    renderLegend(this.canvas.current);
  }

  get maxTraffic() {
    const { $hybridMap } = this.props;
    return $hybridMap.maxTraffic || 1_000_000_000;
  }

  render() {
    return (
      <Box width={220}>
        <Flex justifyContent="space-between" color="muted" fontSize={12} mb="2px">
          <Box>0</Box>
          <Box>&gt; {formatBytesGreek(this.maxTraffic, 'bits/s', 1)}</Box>
        </Flex>
        <Box height={14} border="thin" borderRadius={4} overflow="hidden">
          <canvas ref={this.canvas} />
        </Box>
      </Box>
    );
  }
}
