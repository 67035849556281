import React, { Component } from 'react';
import { observer } from 'mobx-react';

import AnchorButton from 'core/components/AnchorButton';
import ConfirmPopover from 'core/components/ConfirmPopover';
import formConsumer from '../formConsumer';
import FormErrorsButton from './FormErrorsButton';

@formConsumer
@observer
class SubmitButton extends Component {
  static defaultProps = {
    confirmText: 'Submit',
    text: 'Save',
    minWidth: 110
  };

  render() {
    const {
      model,
      form,
      confirmBodyContent,
      confirmSubmit,
      confirmText,
      entityName,
      onSubmit,
      disabled,
      errorRenderer,
      ...rest
    } = this.props;

    const { valid } = form;
    const isCreating = model && model.isRequestActive('creating');
    const isSaving = model && model.isRequestActive('updating');

    const buttonProps = {
      intent: 'primary',
      disabled: disabled || isSaving || isCreating || !valid,
      loading: isSaving || isCreating,
      ...rest
    };

    if (confirmSubmit && !buttonProps.disabled) {
      return (
        <ConfirmPopover
          confirmBodyContent={confirmBodyContent}
          confirmText={confirmText}
          entityName={entityName}
          onConfirm={() => form.submit(onSubmit)}
        >
          <AnchorButton {...buttonProps} />
        </ConfirmPopover>
      );
    }

    const button = <AnchorButton {...buttonProps} onClick={() => form.submit(onSubmit)} />;

    return <FormErrorsButton button={button} form={form} errorRenderer={errorRenderer} />;
  }
}

export default SubmitButton;
