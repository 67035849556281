import * as React from 'react';
import { Button, Flex, Heading } from 'core/components';
import BorderedBox from './BorderedBox';

export default class TopLevelBox extends React.Component {
  static defaultProps = {
    keepChildrenMounted: false
  };

  get boxProps() {
    const { isExpanded, boxProps } = this.props;
    const defaultBoxProps = {
      borderStyle: 'dashed',
      ...boxProps
    };

    if (isExpanded) {
      return {
        ...defaultBoxProps,
        borderStyle: 'solid'
      };
    }

    return {
      ...defaultBoxProps,
      // in collapsed state enforce a consistent, smaller size
      minWidth: 'auto',
      minHeight: 'auto',
      width: 150,
      height: 75
    };
  }

  get expandButton() {
    const { isExpanded, onExpandToggle } = this.props;
    const icon = isExpanded ? 'minimize' : 'maximize';

    if (onExpandToggle) {
      return (
        <Button position="absolute" right={4} top={4} icon={icon} onClick={this.handleExpandToggle} minimal small />
      );
    }

    return null;
  }

  get titleHeading() {
    const { isExpanded, title } = this.props;

    if (isExpanded) {
      return title;
    }

    return <span>&nbsp;</span>;
  }

  get collapsedContent() {
    const { isExpanded, collapsedContent, title } = this.props;

    if (!isExpanded) {
      if (collapsedContent) {
        return collapsedContent;
      }

      return (
        <Flex alignItems="center" justifyContent="center" flex={1}>
          <Heading level={6}>{title}</Heading>
        </Flex>
      );
    }

    return null;
  }

  get expandedContent() {
    const { isExpanded, expandedContent, keepChildrenMounted } = this.props;

    if (keepChildrenMounted) {
      return (
        <Flex display={isExpanded ? 'flex' : 'none'} flex={1}>
          {expandedContent}
        </Flex>
      );
    }

    if (isExpanded) {
      return expandedContent;
    }

    return null;
  }

  handleExpandToggle = (evt) => {
    const { isExpanded, onExpandToggle } = this.props;

    evt.stopPropagation();
    onExpandToggle(!isExpanded);
  };

  render() {
    const { boxProps, collapsedContent, expandedContent, isExpanded, onExpandToggle, title, ...restProps } = this.props;

    return (
      <Flex flexDirection="column" {...restProps}>
        {title && <Heading level={5}>{this.titleHeading}</Heading>}
        <BorderedBox {...this.boxProps}>
          {this.expandButton}
          {this.collapsedContent}
          {this.expandedContent}
        </BorderedBox>
      </Flex>
    );
  }
}
