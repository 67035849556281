import * as React from 'react';
import { adjustByGreekPrefix, toDecimal } from 'core/util';
import { buildFilterGroup } from 'core/util/filters';
import { Flex, Spinner, Suspense, Text } from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';

export default class PathTraffic extends React.Component {
  get query() {
    const { subnetId, destinationIp } = this.props;

    return {
      all_devices: false,
      aggregateTypes: ['avg_bits_per_sec'],
      device_types: ['aws_subnet'],
      topx: 0,
      metric: ['kt_aws_src_subnet_id', 'IP_dst'],
      filters: buildFilterGroup({
        filterGroups: [
          buildFilterGroup({
            filters: [
              {
                filterField: 'kt_aws_src|dst_subnet_id',
                operator: '=',
                filterValue: subnetId
              },

              {
                filterField: 'inet_src|dst_addr',
                operator: 'ILIKE',
                filterValue: destinationIp
              }
            ]
          })
        ]
      }),
      show_overlay: false,
      show_total_overlay: false,
      viz_type: 'table'
    };
  }

  formatValue = (value, prefix) => `${toDecimal(adjustByGreekPrefix(value, prefix))} ${prefix || ''}bits/s`;

  render() {
    const { subnetId } = this.props;

    return (
      <LightweightDataViewWrapper query={this.query}>
        {({ loading, results }) => {
          const trafficData = results.reduce(
            (acc, model) => {
              acc[model.get('kt_aws_src_subnet_id') === subnetId ? 'outbound' : 'inbound'] +=
                model.get('avg_bits_per_sec');
              return acc;
            },
            { inbound: 0, outbound: 0 }
          );

          return (
            <Suspense
              loading={loading}
              fallback={
                <Flex>
                  <Spinner size={14} />
                  <Text ml={1}>Loading traffic</Text>
                </Flex>
              }
            >
              <div>
                <div>Inbound: {this.formatValue(trafficData.inbound, results.prefix.bytes)}</div>
                <div>Outbound: {this.formatValue(trafficData.outbound, results.prefix.bytes)}</div>
              </div>
            </Suspense>
          );
        }}
      </LightweightDataViewWrapper>
    );
  }
}
