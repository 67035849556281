import React from 'react';
import { Text, Icon, Box, Flex } from 'core/components';
import ResultMenuItem from './ResultMenuItem';

function Filter({ item, selected, query, IconCmp, onItemClick, ...rest }) {
  const { id, filter_name, filter_description, path } = item;
  return (
    <ResultMenuItem
      {...rest}
      selected={selected}
      onClick={() => onItemClick({ type: 'saved_filter', id, path }, 'Search Results')}
    >
      <Flex gridArea="icon" alignItems="center" justifyContent="center">
        <IconCmp iconSize={22} />
      </Flex>

      <Box gridArea="title">
        <Text className="title">{filter_name}</Text>
      </Box>

      <Flex gridArea="rightIcon" alignItems="center" justifyContent="center">
        <Icon icon={selected ? 'key-enter' : undefined} color="#ffffff" />
      </Flex>

      <Text
        className="description"
        gridArea="description"
        muted
        small
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        style={{ overflow: 'hidden' }}
      >
        {filter_description || 'No description'}
      </Text>
    </ResultMenuItem>
  );
}

export default Filter;
