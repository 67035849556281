import * as React from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { Classes } from '@blueprintjs/core';
import { FiActivity, FiCloud, FiCopy, FiMoreVertical } from 'react-icons/fi';
import { GiRadarSweep } from 'react-icons/gi';
import { adjustByGreekPrefix, toDecimal, greekPrefix } from 'core/util';
import { Collection } from 'core/model';
import {
  Box,
  Button,
  Card,
  Collapse,
  DropdownMenu,
  Flex,
  Grid,
  Icon,
  LinkButton,
  Menu,
  MenuItem,
  Sparkline,
  Tag,
  Text
} from 'core/components';
import { Table, CELL_TYPES } from 'core/components/table';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';
import ViewInExplorerButton from 'app/components/dataviews/tools/ViewInExplorerButton';
import getCloudServicesQuery from 'app/views/cloudPerformance/utils/getCloudServicesQuery';
import { getSparklineDataRollup } from 'app/views/cloudPerformance/utils/getSparklineData';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { healthToIntent, getMsValue } from 'app/views/synthetics/utils/syntheticsUtils';
import withServiceBrand from './withServiceBrand';
import CloudServiceTableSummary from './cloudServiceTableSummary/CloudServiceTableSummary';

// wrapper used to style a service as 'critical' if the health is anything other than healthy-ish
const CardHealth = styled(Card)`
  @media (max-width: 1300px) {
    overflow-x: scroll;
    .tr {
      overflow: inherit;
    }
  }
  ${({ health, theme }) => {
    const intent = health !== 'none' && health !== 'healthy' && 'danger';
    return (
      intent &&
      css`
        border: 1px solid ${theme.colors[intent]};
      `
    );
  }}
`;

// wraps the table(s) to help with their magical positioning to make sure they line up their columns when nested
const TableWrapper = styled.div`
  .group-summary {
    background: ${(props) => props.theme.colors.appBackground};
  }

  .group-summary-wrapper {
    flex: 1;

    .${Classes.HTML_TABLE} {
      margin-left: -12px;
    }
  }
`;

// renders special statuses such as 'failing' and 'pending' for service test result values
const columnTagRenderer = ({ model, value, intent = 'none', isPending, regionRollup }) => {
  const isFailing = model.get('serviceTestResults')?.overall_health.health === 'failing';

  if (regionRollup) {
    const statusCodes = Object.keys(regionRollup);

    if (statusCodes.length === 1) {
      // if we have just one type of status code, show that
      return (
        <Tag
          intent={healthToIntent({
            health: regionRollup[statusCodes[0]].health,
            isAggregated: model.get('serviceTestResults.isAggregated')
          })}
          fontSize="small"
          fontWeight="bold"
          minimal
        >
          {regionRollup[statusCodes[0]].value}
        </Tag>
      );
    }

    if (statusCodes.length > 1) {
      // if there are multiple status code types, show a special tag indicating multiple codes reported
      return (
        <Tag fontSize="small" fontWeight="bold" minimal>
          Multiple
        </Tag>
      );
    }
  }

  if (isPending) {
    return (
      <Tag fontSize="small" fontWeight="bold" minimal>
        Pending
      </Tag>
    );
  }

  if (isFailing) {
    return (
      <Tag intent="danger" fontSize="small" fontWeight="bold" minimal>
        Failing
      </Tag>
    );
  }

  if (!value) {
    return <Text muted>---</Text>;
  }

  return (
    <Tag fontSize="small" fontWeight="bold" minimal intent={intent}>
      {value}
    </Tag>
  );
};

@withServiceBrand
@observer
export default class CloudServiceTable extends React.Component {
  state = {
    isOpen: false
  };

  static getDerivedStateFromProps(props, state) {
    const { collection, serviceKey } = props;

    if (serviceKey !== state.serviceKey) {
      const { cloudProvider, data } = collection.rollup[serviceKey];
      const dataCollection = new Collection(data, { groupBy: 'region' });
      const trafficData = data.reduce((acc, model) => acc.concat(model.get('fromTraffic'), model.get('toTraffic')), []);

      return {
        cloudProvider,
        dataCollection,
        serviceKey,
        prefix: greekPrefix(trafficData, 1)
      };
    }

    return null;
  }

  // renders the synthetics menu options
  // when in the context of a region that has a test, it will offer options to edit the test and view results
  // when in the context of a virtual network that has a test, it will offer options to view test results
  getSyntheticsMenu = ({ model, isVirtualNetwork }) => {
    const testId = model.get('serviceTestModel')?.id;

    if (!testId) {
      return null;
    }

    return (
      <MenuItem icon={GiRadarSweep} text="Synthetics" small>
        {!isVirtualNetwork && testId && (
          <LinkButton alignText="left" text="Configure Test" to={`/v4/synthetics/tests/${testId}`} fill minimal small />
        )}

        {testId && (
          <LinkButton
            alignText="left"
            text="View Test Results"
            to={`/v4/synthetics/tests/${testId}/results`}
            fill
            minimal
            small
          />
        )}
      </MenuItem>
    );
  };

  getColumns({ forGroupSummary = false } = {}) {
    const { collection, networkInterfaceMap, timeOptions } = this.props;

    return [
      {
        name: forGroupSummary ? 'region' : 'virtualNetworkId',
        label: 'Region',
        width: forGroupSummary ? 250 : 286,
        minWidth: forGroupSummary ? 250 : 286,
        renderer: ({ value, model }) => {
          const cloudProvider = model.get('cloudProvider');
          const virtualNetworkCount = model.get('virtualNetworkCount');
          const isVirtualNetwork = !forGroupSummary;
          const label = isVirtualNetwork ? model.get('virtualNetworkName') : value;
          let subLabel = null;

          if (forGroupSummary === false && label !== value) {
            // only show the virtual network id when there's a name tag defined
            subLabel = value;
          }

          return (
            <Flex ml={isVirtualNetwork ? '36px' : 0}>
              {isVirtualNetwork ? (
                <CloudIcon
                  cloudProvider={cloudProvider}
                  entity="virtualNetwork"
                  iconSize={16}
                  style={{ marginRight: 8, minWidth: 16 }}
                />
              ) : (
                <Icon mr={1} icon={FiCloud} />
              )}
              <Box>
                <Text ellipsis>
                  {label} {virtualNetworkCount}
                </Text>
                {subLabel && <Box>{subLabel}</Box>}
              </Box>
            </Flex>
          );
        }
      },
      {
        name: 'connectionType',
        label: 'Connection Type',
        width: 142,
        minWidth: 142,
        renderer: ({ value, model }) => {
          if (forGroupSummary) {
            const cloudProvider = model.get('cloudProvider');
            return ['public', 'private'].map((connectionType) => {
              const connectionTypeCount = value[connectionType];

              if (connectionTypeCount > 0) {
                return (
                  <Flex key={connectionType} alignItems="center">
                    <Text>
                      {connectionType} ({connectionTypeCount}{' '}
                      {`${cloudProvider === 'azure' ? 'VNET' : 'VPC'}${connectionTypeCount === 1 ? '' : 's'}`})
                    </Text>
                  </Flex>
                );
              }

              return null;
            });
          }

          return (
            <Flex alignItems="center">
              <Text>{value}</Text>
            </Flex>
          );
        }
      },
      {
        name: 'fromTraffic',
        width: 235,
        minWidth: 235,
        ellipsis: false,
        label: (
          <Box>
            <Text as="div" muted>
              From
            </Text>
            <Text>{this.metric}</Text>
          </Box>
        ),
        renderer: this.getTrafficRenderer('from')
      },
      {
        name: 'toTraffic',
        width: 235,
        minWidth: 235,
        ellipsis: false,
        label: (
          <Box>
            <Text as="div" muted>
              To
            </Text>
            <Text>{this.metric}</Text>
          </Box>
        ),
        renderer: this.getTrafficRenderer('to')
      },
      {
        name: 'serviceTestResults.http_status',
        label: 'Status Code',
        ellipsis: false,
        width: 120,
        minWidth: 120,
        renderer: ({ model, value }) => {
          const isPending = forGroupSummary && model.get('serviceTestModel')?.get('results_first_valid') === null;

          return columnTagRenderer({
            model,
            value: value?.value,
            intent: healthToIntent({
              health: value?.health,
              isAggregated: model.get('serviceTestResults.isAggregated')
            }),
            isPending,
            regionRollup: forGroupSummary && value
          });
        }
      },
      {
        name: 'serviceTestResults.http_latency',
        label: 'Avg HTTP Latency',
        ellipsis: false,
        width: 130,
        minWidth: 130,
        renderer: ({ model, value }) =>
          columnTagRenderer({
            model,
            value: getMsValue(value?.value),
            intent: healthToIntent({
              health: value?.health,
              isAggregated: model.get('serviceTestResults.isAggregated')
            })
          })
      },
      {
        name: 'spacer',
        sortable: false,
        renderer: () => null
      },
      {
        type: CELL_TYPES.ACTION,
        name: 'actions',
        align: 'right',
        actions: [
          (model) => {
            const cloudProvider = model.get('cloudProvider');
            const service = model.get('service');
            let value = model.get('virtualNetworkId');
            let copyText = 'Copy Virtual Network ID to Clipboard';
            const params = {
              cloudProvider,
              services: [service],
              filterableServices: collection.getServiceNameFilterValues([service]),
              networkInterfaceMap,
              timeOptions
            };
            let quickViewUrl = null;
            let mapConfig = null;

            if (forGroupSummary) {
              value = model.get('region');
              copyText = 'Copy Region Name to Clipboard';
              params.region = model.get('region');
              params.matchBy = ['region'];
              quickViewUrl = `/v4/core/quick-views/cloud/${cloudProvider}/region/${params.region}`;
            } else {
              params.region = model.get('region');
              params.virtualNetworkId = model.get('virtualNetworkId');
              params.matchBy = ['region', 'virtualNetworkId'];
              quickViewUrl = `/v4/core/quick-views/cloud/${cloudProvider}/${
                cloudProvider === 'azure' ? 'vnet_id' : 'vpc_id'
              }/${params.virtualNetworkId}`;
              mapConfig = { type: cloudProvider === 'azure' ? 'vnets' : 'Vpcs', value: params.virtualNetworkId };
            }

            return (
              <Box key="actions-menu" mr={forGroupSummary ? '-20px' : 2}>
                <DropdownMenu
                  autoFocus={false}
                  className={Classes.POPOVER_DISMISS}
                  content={
                    <Menu>
                      {this.getSyntheticsMenu({ model, isVirtualNetwork: forGroupSummary === false })}
                      {quickViewUrl && (
                        <Box>
                          <LinkButton
                            alignText="left"
                            icon={FiActivity}
                            text="Open Quick-View"
                            to={quickViewUrl}
                            blank
                            fill
                            minimal
                            small
                          />
                        </Box>
                      )}
                      {mapConfig && (
                        <Box>
                          <LinkButton
                            alignText="left"
                            icon="layout-auto"
                            text="View in Kentik Map"
                            to={`/v4/kentik-map/cloud/${cloudProvider}?sidebar=${encodeURIComponent(
                              JSON.stringify(mapConfig)
                            )}`}
                            blank
                            fill
                            minimal
                            small
                          />
                        </Box>
                      )}
                      <ViewInExplorerButton
                        query={getCloudServicesQuery(params)}
                        textAlign="left"
                        fill
                        openInNewWindow
                      />
                      <CopyToClipboardButton
                        key="copy-button"
                        text={value}
                        copyConfirmationText={null}
                        intent="none"
                        fill
                        minimal
                        small
                      >
                        <Button icon={FiCopy} text={copyText} />
                      </CopyToClipboardButton>
                    </Menu>
                  }
                >
                  <Button icon={FiMoreVertical} color="muted" minimal />
                </DropdownMenu>
              </Box>
            );
          }
        ]
      }
    ];
  }

  fromTrafficRenderer = ({ value, model }) => {
    const { serviceBrand } = this.props;
    const { prefix } = this.state;

    const formattedValue = toDecimal(adjustByGreekPrefix(value, prefix));
    const sparklineData = model.get('sparklineData');
    const hasSparklineData = sparklineData && sparklineData.from;
    const gridTemplateColumns = `minmax(47px, auto) ${hasSparklineData ? '1fr' : ''}`;

    return (
      <Grid width={220} gridTemplateColumns={gridTemplateColumns} gridGap={0}>
        <Box mr="4px">{formattedValue}</Box>
        {hasSparklineData && (
          <Box pb="1px">
            <Sparkline data={sparklineData.from} height={30} strokeWidth={1.5} color={serviceBrand.color} />
          </Box>
        )}
      </Grid>
    );
  };

  toTrafficRenderer = ({ value, model }) => {
    const { serviceBrand } = this.props;
    const { prefix } = this.state;

    const formattedValue = toDecimal(adjustByGreekPrefix(value, prefix));
    const sparklineData = model.get('sparklineData');
    const hasSparklineData = sparklineData && sparklineData.to;
    const gridTemplateColumns = `minmax(47px, auto) ${hasSparklineData ? '1fr' : ''}`;

    return (
      <Grid width={220} gridTemplateColumns={gridTemplateColumns} gridGap={0}>
        <Box mr="4px">{formattedValue}</Box>
        {hasSparklineData && (
          <Box pb="1px">
            <Sparkline data={sparklineData.to} height={30} strokeWidth={1.5} color={serviceBrand.color} />
          </Box>
        )}
      </Grid>
    );
  };

  getTrafficRenderer = (direction) => (direction === 'from' ? this.fromTrafficRenderer : this.toTrafficRenderer);

  // the rolled up region data
  // this is represented in a separate table that calculates special values in the columns config to make sure everything lines up
  groupSummary = ({ group, groupKey }) => {
    const firstModel = group.length > 0 && group[0];
    const cloudProvider = firstModel?.get('cloudProvider');

    const regionRollup = group.reduce(
      (acc, model) => {
        // see if we can get the service test model and results
        // so we can grab test id and health rolled up by region
        const serviceTestModel = model.get('serviceTestModel');
        const serviceTestResults = model.get('serviceTestResults');

        // tack on to the traffic stats
        acc.fromTraffic += model.get('fromTraffic');
        acc.toTraffic += model.get('toTraffic');

        // add to the connection type count
        acc.connectionType[model.get('connectionType')] += 1;

        if (serviceTestModel && !acc.serviceTestModel) {
          // we use this to render the 'Synthetics' detail menu in the region rollup row to offer the option of editing the test
          acc.serviceTestModel = serviceTestModel;
        }

        if (serviceTestResults) {
          // if we find test results, add the overall health, http latency, and a place to store status codes from the region
          // overall health is used in the column renderer, latency is value rolled up by region
          if (!acc.serviceTestResults) {
            acc.serviceTestResults = {
              overall_health: serviceTestResults?.overall_health,
              http_latency: serviceTestResults?.region.http_latency,
              http_status: {},
              isAggregated: serviceTestResults?.isAggregated
            };
          }

          if (serviceTestResults.http_status) {
            // create a map of various status codes reported
            const { value, health } = serviceTestResults.http_status;
            acc.serviceTestResults.http_status[value] = { value, health };
          }
        }

        return acc;
      },
      {
        cloudProvider,
        service: firstModel?.get('service'),
        region: groupKey,
        virtualNetworkCount: `(${group.length} ${cloudProvider === 'azure' ? 'vnet' : 'vpc'}${
          group.length === 1 ? '' : 's'
        })`,
        fromTraffic: 0,
        toTraffic: 0,
        sparklineData: getSparklineDataRollup(group),
        connectionType: { private: 0, public: 0 },
        serviceTestModel: null,
        serviceTestResults: null
      }
    );

    return (
      <Table
        collection={new Collection([regionRollup])}
        columns={this.getColumns({ forGroupSummary: true })}
        selectOnRowClick={false}
        hideHeader
      />
    );
  };

  get metric() {
    const { prefix } = this.state;
    return `${prefix}bits/s`;
  }

  render() {
    const { collection, serviceKey, networkInterfaceMap, serviceBrand, timeOptions, ...rest } = this.props;
    const { isOpen, cloudProvider, dataCollection } = this.state;

    return (
      <CardHealth key={serviceKey} health={collection.serviceHealth[serviceKey]} {...rest}>
        <CloudServiceTableSummary
          isOpen={isOpen}
          cloudProvider={cloudProvider}
          serviceKey={serviceKey}
          collection={collection}
          networkInterfaceMap={networkInterfaceMap}
          onViewToggle={(nextIsOpen) => this.setState({ isOpen: nextIsOpen })}
          timeOptions={timeOptions}
        />
        <Collapse isOpen={isOpen}>
          <TableWrapper>
            <Table
              collection={dataCollection}
              columns={this.getColumns()}
              groupSummaryLookup={this.groupSummary}
              selectOnRowClick={false}
              isCollapsed
            />
          </TableWrapper>
        </Collapse>
      </CardHealth>
    );
  }
}
