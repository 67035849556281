import * as React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { isEqual } from 'lodash';
import { Card, EmptyState, Flex, Spinner } from 'core/components';
import { showErrorToast } from 'core/components/toast';
import { deepClone } from 'core/util';
import PerfMonitorServiceCollection from 'app/stores/hybrid/PerfMonitorServiceCollection';
import QueryModel from 'app/stores/query/QueryModel';
import ViewInExplorerButton from 'app/components/dataviews/tools/ViewInExplorerButton';
import AgentManagementDialog from 'app/views/cloudPerformance/AgentManagementDialog';
import getCloudServicesQuery from 'app/views/cloudPerformance/utils/getCloudServicesQuery';
import CloudServiceToolbar from './CloudServiceToolbar';
import CloudServiceTable from './CloudServiceTable';

@inject('$clouds', '$hybridMap', '$syn')
@observer
export default class CloudServices extends React.Component {
  static getDerivedStateFromProps(props) {
    const { sidebarSettings } = props;
    const { filters, ...timeOptions } = sidebarSettings.sidebarQueryOverrides;

    return { timeOptions, ...sidebarSettings.timeRange };
  }

  state = {
    loadingNetworkInterfaces: true,
    updatingTests: false,
    networkInterfaceMap: {},
    selectedServices: [],
    collection: new PerfMonitorServiceCollection(),
    loadingTraffic: true,
    timeOptions: {},
    start: '',
    end: ''
  };

  get rollupKeys() {
    const { collection, selectedServices } = this.state;
    const { rollup } = collection;

    return selectedServices.filter((service) => rollup[service]);
  }

  componentDidMount() {
    const { $syn } = this.props;

    this.agentCountDisposer = reaction(
      () => {
        const { collection } = this.state;
        return collection.agentBreakdown;
      },
      ({ unmonitoredVpcs, pendingAgents, privateAgents }) => {
        const { onAgentCountUpdate } = this.props;

        if (onAgentCountUpdate) {
          onAgentCountUpdate({
            unmonitoredVpcs: unmonitoredVpcs.length,
            pendingAgents: pendingAgents.length,
            privateAgents: privateAgents.length
          });
        }
      }
    );

    $syn.agents
      .fetch({ force: true })
      .then(() => $syn.tests.fetch({ force: true }))
      .then(this.fetchServices);
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarSettings } = this.props;
    const { start, end } = this.state;

    if (
      !isEqual(prevProps.sidebarSettings.cloudProviders, sidebarSettings.cloudProviders) ||
      prevState.start !== start ||
      prevState.end !== end
    ) {
      this.fetchServices();
    }
  }

  componentWillUnmount() {
    if (this.agentCountDisposer) {
      this.agentCountDisposer();
    }
  }

  get cloudProviders() {
    const { sidebarSettings } = this.props;
    return sidebarSettings.cloudProviders;
  }

  getTrafficQueries = ({ networkInterfaceMap }) =>
    this.cloudProviders.map((cloudProvider) => {
      const { timeOptions } = this.state;

      return {
        cloudProvider,
        query: QueryModel.create(getCloudServicesQuery({ cloudProvider, networkInterfaceMap, timeOptions })).serialize()
      };
    });

  fetchServices = () => {
    const { $hybridMap, $syn, sidebarSettings } = this.props;
    const { start, end } = this.state;
    const { cloudProviders } = this;

    this.setState({ loadingNetworkInterfaces: true, loadingTraffic: true }, () =>
      $hybridMap
        .getCloudServices(cloudProviders, { start, end })
        .then((response) => {
          const queries = this.getTrafficQueries({ networkInterfaceMap: response });

          $hybridMap.getCloudServicesTraffic({ queries }).then((trafficResponse) => {
            const results = trafficResponse.flat();

            const collection = new PerfMonitorServiceCollection();

            // cache the network interface data on the collection
            // we use this when determining privately connected services
            collection.networkInterfaceMap = response;

            collection.processData(results);

            $syn.requests
              .fetchTestResults(
                { ids: collection.serviceTestIds, start_time: moment(start).unix(), end_time: moment(end).unix() },
                { preset: false }
              )
              .then((testResults) => {
                collection.mergeTestResults(testResults);

                this.setState({
                  collection,
                  loadingTraffic: false,
                  loadingNetworkInterfaces: false,
                  networkInterfaceMap: response,
                  selectedServices: sidebarSettings.cloudPerformance?.selectedServices || Object.keys(collection.rollup)
                });
              });
          });
        })
        .catch(() => {
          // if this fails, we won't have any network interface ids used to get privately linked services
          this.setState(
            { loadingNetworkInterfaces: false, networkInterfaceMap: { networkInterfaces: {}, virtualNetworks: {} } },
            () =>
              showErrorToast(
                <div>
                  <div>Unable to load topology and synthetics data</div>
                  <div>Only traffic data will be shown</div>
                </div>
              )
          );
        })
    );
  };

  handleServiceSelectionChange = (selectedServices) => {
    const { sidebarSettings, saveSettings } = this.props;
    const nextSettings = deepClone(sidebarSettings);
    nextSettings.cloudPerformance = nextSettings.cloudPerformance || {};

    nextSettings.cloudPerformance.selectedServices = selectedServices;

    saveSettings(nextSettings);

    this.setState({ selectedServices });
  };

  renderEmptyState = (isLoading) => {
    const { collection, updatingTests } = this.state;
    const hasTraffic = collection.size > 0;
    let icon = <Spinner />;
    let title = null;
    let description = null;

    if (isLoading) {
      if (updatingTests) {
        title = 'Updating Tests';
      } else {
        title = 'Loading Top Service Traffic';
      }
    } else if (!hasTraffic) {
      icon = 'cloud';
      title = 'No Service Traffic Detected';
      description = 'There were no matches for your search criteria';
    }

    return title ? (
      <Card p={2} mb={1} alignItems="center" justifyContent="center" height={300}>
        <EmptyState icon={icon} title={title} description={description} />
      </Card>
    ) : null;
  };

  handleModelChange = ({ type, model }) => {
    const { onAgentModelChange } = this.props;
    const { collection } = this.state;

    // other perf monitor tabs still use the 'vpcId' property so keep it around for agent dialogs
    model.set('vpcId', model.get('virtualNetworkId'));

    if (type === 'challenge' || type === 'save') {
      collection.updateAgent(model);
    }

    if (onAgentModelChange) {
      onAgentModelChange({ type, model });
    }
  };

  refreshSynthetics = () => {
    const { $syn } = this.props;
    const { collection, start, end } = this.state;

    return $syn.agents
      .fetch({ force: true })
      .then(() => $syn.tests.fetch({ force: true }))
      .then(() => {
        $syn
          .fetchTestResults(
            { ids: collection.serviceTestIds, start_time: moment(start).unix(), end_time: moment(end).unix() },
            { preset: false }
          )
          .then((testResults) => collection.mergeTestResults(testResults));
      });
  };

  handleTestsUpdate = (missingTests) => {
    const { $clouds } = this.props;

    // whether all the jobs complete or not, we'll want to refresh stats and traffic
    // if an error occurs for any items in the batch, we'll still display the api error in a toast
    // and update the view accordingly
    this.setState({ updatingTests: true }, () =>
      $clouds
        .bulkSaveTests(missingTests)
        .catch((e) => console.error(e))
        .finally(() =>
          this.refreshSynthetics()
            .then(this.fetchServices)
            .then(() => this.setState({ updatingTests: false }))
        )
    );
  };

  getTotalQuery = () => {
    const { collection, networkInterfaceMap, selectedServices, timeOptions } = this.state;
    const { cloudProviders } = this;
    const baseQuery = getCloudServicesQuery({
      services: selectedServices,
      filterableServices: collection.getServiceNameFilterValues(selectedServices),
      networkInterfaceMap,
      matchBy: ['service'],
      timeOptions
    });

    baseQuery.device_types = cloudProviders.map((cloudProvider) => `${cloudProvider}_subnet`);

    baseQuery.metric = baseQuery.metric.slice(0, 2);

    const cloudProvidersFilterGroup = baseQuery.filters.filterGroups[0];
    cloudProvidersFilterGroup.connector = 'Any';
    cloudProvidersFilterGroup.filters = cloudProviders.map((cloudProvider) => ({
      filterField: 'src|dst_cloud',
      operator: '=',
      filterValue: cloudProvider
    }));

    return baseQuery;
  };

  render() {
    const { isAgentManagementOpen, toggleAgentManagement, agentModel, onAgentFormDialogClose } = this.props;
    const {
      collection,
      loadingNetworkInterfaces,
      updatingTests,
      loadingTraffic,
      networkInterfaceMap,
      selectedServices,
      timeOptions
    } = this.state;
    const isLoading = loadingNetworkInterfaces || loadingTraffic || updatingTests;
    const hasTraffic = !loadingTraffic && collection.unfilteredSize > 0;

    return (
      <>
        <Flex flexDirection="column" mb={4}>
          <CloudServiceToolbar
            flex={1}
            pb={1}
            collection={collection}
            selectedServices={selectedServices}
            onServiceSelectionChange={this.handleServiceSelectionChange}
            onTestsUpdate={this.handleTestsUpdate}
            loading={isLoading}
            disabled={isLoading || !hasTraffic}
          />

          {this.renderEmptyState(isLoading)}

          {!isLoading && (
            <>
              {this.rollupKeys.map((key) => (
                <CloudServiceTable
                  mb={2}
                  p={1}
                  elevation={1}
                  key={key}
                  serviceKey={key}
                  collection={collection}
                  networkInterfaceMap={networkInterfaceMap}
                  timeOptions={timeOptions}
                />
              ))}
              <ViewInExplorerButton alignSelf="flex-end" query={this.getTotalQuery()} openInNewWindow />
            </>
          )}
        </Flex>

        <AgentManagementDialog
          agentIds={collection.agentIds}
          uninstalledVpcs={collection.uninstalledVirtualNetworks}
          model={agentModel}
          isAgentManagementOpen={isAgentManagementOpen}
          toggleAgentManagement={toggleAgentManagement}
          onModelChange={this.handleModelChange}
          onAgentFormDialogClose={onAgentFormDialogClose}
        />
      </>
    );
  }
}
