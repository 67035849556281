import * as React from 'react';
import { inject, observer } from 'mobx-react';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';

@inject('$hybridMap')
@observer
export default class InterCloudLinkGenerator extends React.Component {
  handleQueryComplete = ({ results, fullyLoaded }) => {
    const { onFlowTrafficUpdate } = this.props;
    if (fullyLoaded) {
      const linkMap = results.reduce((acc, row) => {
        const srcProvider = row.get('i_trf_origination');
        const dstProvider = row.get('i_trf_termination');

        const traffic = row.get('avg_bits_per_sec');
        const key = [srcProvider, dstProvider].sort().join('|');
        const linkEntry = acc[key] || { cloud1_provider: srcProvider, cloud2_provider: dstProvider };
        if (linkEntry.cloud1_provider === srcProvider && linkEntry.cloud2_provider === dstProvider) {
          linkEntry.outTraffic = traffic;
        } else if (linkEntry.cloud1_provider === dstProvider && linkEntry.cloud2_provider === srcProvider) {
          linkEntry.inTraffic = traffic;
        }

        acc[key] = linkEntry;
        return acc;
      }, {});

      onFlowTrafficUpdate(Object.values(linkMap));
    }
  };

  render() {
    const { $hybridMap } = this.props;

    const query = $hybridMap.getQuery({
      show_overlay: false,
      show_total_overlay: false,
      device_types: ['aws_subnet', 'azure_subnet', 'gcp_subnet'],
      metric: ['i_trf_origination', 'i_trf_termination'],
      filters: {
        connector: 'All',
        filterGroups: [
          {
            name: '',
            named: false,
            connector: 'All',
            not: false,
            autoAdded: '',
            filters: [
              {
                filterField: 'i_trf_profile',
                operator: '=',
                filterValue: 'multi-cloud'
              }
            ],
            saved_filters: [],
            filterGroups: []
          }
        ]
      }
    });

    return <LightweightDataViewWrapper query={query} onQueryComplete={this.handleQueryComplete} />;
  }
}
