import PixelValue from 'app/views/hybrid/utils/PixelValue';

export const FLEX_GAP = new PixelValue(40);
export const GRID_ROWS_GAP = new PixelValue(40);
export const INTERNET_WIDTH = new PixelValue(500);
export const ON_PREM_BOX_PADDING = new PixelValue(16);
export const ABSTRACT_MAP_WRAPPER_PADDING = new PixelValue(20);
export const ABSTRACT_MAP_TOO_CLOSE_MODIFIER = 5;

export const DIRECT_CONNECT_HEIGHT = 38;
export const DIRECT_CONNECT_WIDTH = 200;
export const ICON_SIZE = 25;
export const LINK_SPACING = 7;
export const REGION_MARGIN = 90;
export const LOCATION_PADDING = 2 * REGION_MARGIN - 4;
export const MAX_HIGHLIGHT_DEPTH = 5;
export const ON_PREM_FLEX_GAP = 30;

export function getTotalWidth({ itemCount = 0, width = DIRECT_CONNECT_WIDTH, gap = FLEX_GAP }) {
  return itemCount * width + gap * itemCount;
}
