import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Flex, Menu, MenuItem, Popover, Text } from 'core/components';
import { getOption } from 'core/form/components/modalSelect/selectHelpers';
import { formConsumer } from 'core/form';

@formConsumer
@observer
export default class ColorByMenu extends React.Component {
  handleChange = (field, value) => {
    const { form, onChange } = this.props;

    field.setValue(value);

    if (onChange) {
      onChange(form.getValues());
    }
  };

  get menuOptions() {
    const { form } = this.props;
    const colorByField = form.getField('colorBy');

    return (
      <Menu>
        {colorByField.options.map(({ label, value }) => (
          <MenuItem
            key={value}
            text={label}
            onClick={() => this.handleChange(colorByField, value)}
            active={colorByField.getValue() === value}
            width={this.menuWidth - 10}
            popoverProps={{ isOpen: true }}
            small
          />
        ))}
      </Menu>
    );
  }

  get buttonLabel() {
    const { form } = this.props;
    const colorByField = form.getField('colorBy');
    const colorByOption = getOption(colorByField.options, colorByField.getValue());
    return colorByOption.label;
  }

  get menuWidth() {
    return 150;
  }

  render() {
    const { interSiteMenuEnabled, ...rest } = this.props;

    return (
      <Popover content={this.menuOptions} position="bottom-left">
        <Button rightIcon="caret-down" width={this.menuWidth} height="35px" textAlign="left" small {...rest}>
          <Flex alignItems="center">
            <Flex mr="4px">
              {['#613E8B', '#8F70B0', '#D5C1E9'].map((color) => (
                <Box key={color} bg={color} width={5} height={5} mr="1px" borderRadius="50%" />
              ))}
            </Flex>
            <Box>
              <Text as="div" color="muted" mb="1px">
                Color by
              </Text>

              <Text as="div" fontWeight="bold" small>
                {this.buttonLabel}
              </Text>
            </Box>
          </Flex>
        </Button>
      </Popover>
    );
  }
}
