import * as React from 'react';
import { Box, Flex, Icon, Dialog, Tabs, Tab, Text } from 'core/components';
import { securityEntitiesDenyTraffic } from 'app/views/hybrid/utils/aws';
import SecurityGroupsPanel from './SecurityGroupsPanel';
import NetworkACLsPanel from './NetworkACLsPanel';

export default class SecurityGroupsAndNACLsDialog extends React.Component {
  state = {
    selectedTabId: 'securityGroups'
  };

  componentDidUpdate(prevProps) {
    const { initialTab } = this.props;

    if (initialTab && prevProps.initialTab !== initialTab) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedTabId: initialTab });
    }
  }

  handleTabChange = (tabId) => this.setState({ selectedTabId: tabId });

  renderDeniesTrafficMarker = (securityEntities) =>
    securityEntitiesDenyTraffic(securityEntities) && <Icon icon="dot" color="danger" mt="-8px" />;

  render() {
    const {
      topology,
      securityGroups,
      nacls,
      initialTab,
      vpcId,
      subnetId,
      selectedRule,
      queryTimeOptions,
      ...dialogProps
    } = this.props;
    const { selectedTabId } = this.state;
    const targets = [vpcId, subnetId].filter((target) => !!target);

    return (
      <Dialog
        title={
          <Box my={1}>
            <Text>Security Groups &amp; Network ACLs</Text>
            <Text as="div" color="muted" small>
              {`applied to ${targets.join(', ')}`}
            </Text>
          </Box>
        }
        {...dialogProps}
      >
        <Dialog.Body>
          <Tabs selectedTabId={selectedTabId} onChange={this.handleTabChange}>
            <Tab
              id="securityGroups"
              panel={
                <SecurityGroupsPanel
                  topology={topology}
                  securityGroups={securityGroups}
                  vpcId={vpcId}
                  subnetId={subnetId}
                  selectedRule={selectedRule}
                  queryTimeOptions={queryTimeOptions}
                />
              }
              title={
                <Flex alignItems="center">
                  <Icon icon="shield" mr="4px" />
                  <Text>Security Groups</Text>
                  {this.renderDeniesTrafficMarker(securityGroups)}
                </Flex>
              }
            />
            <Tab
              id="networkACLs"
              panel={
                <NetworkACLsPanel
                  topology={topology}
                  nacls={nacls}
                  vpcId={vpcId}
                  subnetId={subnetId}
                  selectedRule={selectedRule}
                  queryTimeOptions={queryTimeOptions}
                />
              }
              title={
                <Flex alignItems="center">
                  <Icon icon="lock" mr="4px" />
                  <Text>Network ACLs</Text>
                  {this.renderDeniesTrafficMarker(nacls)}
                </Flex>
              }
            />
          </Tabs>
        </Dialog.Body>
      </Dialog>
    );
  }
}
