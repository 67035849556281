import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Checkbox, Flex, FlexColumn, Icon, Menu, MenuItem, Popover, Text } from 'core/components';

import { Search } from 'core/components/table';
import storeLoader from 'app/stores/storeLoader';
import { Intent } from '@blueprintjs/core';
import styled from 'styled-components';
import KentikLogo from 'app/components/KentikLogo';
import { HiUserGroup } from 'react-icons/hi';

const MultiSelectWrapper = styled(FlexColumn)`
  .bp4-control {
    display: flex;
    align-items: center;

    &.bp4-checkbox .bp4-control-indicator {
      margin-top: 0px;
    }
  }
`;

@storeLoader('$users')
@observer
export default class UserCloneRoleMenu extends Component {
  state = {
    selectedItems: [],
    isOpen: false
  };

  componentWillUnmount() {
    const { $users } = this.props;

    $users.collection.resetState();
  }

  onSearchChange = (e) => {
    const { $users } = this.props;
    $users.collection.filter(e.target.value);
  };

  handleInteraction = (nextOpenState) => {
    this.setState({ isOpen: nextOpenState });
  };

  handleCancel = () => {
    const { $users } = this.props;
    $users.collection.resetState();
    this.setState({ isOpen: false, selectedItems: [] });
  };

  handleRoleChecked = (role, userId, checked) => {
    this.setState(({ selectedItems: prevSelectedItems }) => {
      const selectedItems = [...prevSelectedItems];
      if (checked) {
        selectedItems.push(role);
      } else {
        selectedItems.splice(selectedItems.indexOf(role), 1);
      }
      return { selectedItems };
    });
  };

  handleCopyItems = ({ roles = [], roleSets = [], selectedItems }) => {
    const { onApply, collection } = this.props;
    if (onApply) {
      onApply(
        collection
          .get()
          .filter((item) =>
            item.isRoleSet
              ? roleSets.includes(parseInt(item.id.split('|')[1]))
              : roles.includes(parseInt(item.id.split('|')[1]))
          )
      );
    }

    if (selectedItems && onApply) {
      onApply(collection.get().filter((item) => selectedItems.includes(item.id)));
    }

    this.handleCancel();
  };

  renderMenuItem = (userModel) => {
    const { selectedItems } = this.state;
    const { selectedUser, collection } = this.props;
    if (userModel.id === selectedUser.id) {
      return null;
    }

    return (
      <MenuItem
        key={userModel.id}
        text={
          <Box>
            <Text as="div">{userModel.get('user_full_name')}</Text>
            <Text as="div" small muted>
              {userModel.get('user_email')}
            </Text>
          </Box>
        }
        popoverProps={{ onOpened: () => this.setState({ selectedItems: [] }) }}
      >
        <Box p={2} minWidth={300}>
          {userModel.get('roles').map((roleId) => {
            const roleModel = collection.find((role) => `role|${roleId}` === role.id);

            const label = roleModel?.get('company_id') ? (
              roleModel.get('role_name')
            ) : (
              <Flex alignItems="center">
                <KentikLogo style={{ width: 16 }} onlyMark mr="2px" />

                <Text>{roleModel?.get('role_name')}</Text>
              </Flex>
            );
            return (
              <Checkbox
                key={`role|${roleId}`}
                label={label}
                onChange={(checked) => this.handleRoleChecked(`role|${roleId}`, userModel.id, checked)}
              />
            );
          })}
          {userModel.get('role_sets').map((roleSetId) => {
            const roleSetModel = collection.find((roleSet) => `roleSet|${roleSetId}` === roleSet.id);
            const label = (
              <Flex alignItems="center">
                <Icon icon={HiUserGroup} color="muted" mr={1} />
                <Text>{roleSetModel?.get('name')}</Text>
              </Flex>
            );

            return (
              <Checkbox
                key={`roleSet|${roleSetId}`}
                label={label}
                onChange={(checked) => this.handleRoleChecked(`roleSet|${roleSetId}`, userModel.id, checked)}
              />
            );
          })}
          <Flex justifyContent="flex-end" mt={2}>
            <Box>
              <Button
                text="Copy All"
                mr={1}
                onClick={() =>
                  this.handleCopyItems({ roles: userModel.get('roles'), roleSets: userModel.get('role_sets') })
                }
              />
            </Box>
            <Box>
              <Button
                text="Copy Selected"
                intent={Intent.PRIMARY}
                disabled={Object.keys(selectedItems).length === 0}
                onClick={() => this.handleCopyItems({ selectedItems })}
              />
            </Box>
          </Flex>
        </Box>
      </MenuItem>
    );
  };

  render() {
    const { $users, loading } = this.props;
    const { isOpen } = this.state;

    if (loading) {
      return false;
    }
    return (
      <Popover
        isOpen={isOpen}
        modifiers={{
          flip: { enabled: true },
          preventOverflow: { enabled: true, boundariesElement: 'window' }
        }}
        onInteraction={this.handleInteraction}
        content={
          <MultiSelectWrapper justifyContent="space-between" p={2} maxHeight={450} width={300} overflow="hidden">
            <FlexColumn flex={1} overflow="hidden">
              <Search collection={$users.collection} onChange={this.onSearchChange} mb={1} p={1} />
              <Box flex={1} overflow="auto">
                <Menu>{$users.collection.map((userModel) => this.renderMenuItem(userModel))}</Menu>
              </Box>
            </FlexColumn>
            <Flex justifyContent="space-between" mt={2}>
              <Box>
                <Button text="Cancel" onClick={this.handleCancel} />
              </Box>
            </Flex>
          </MultiSelectWrapper>
        }
      >
        <Button text="Copy" icon="inherited-group" />
      </Popover>
    );
  }
}
