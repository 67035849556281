import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { formatBytesGreek, toDecimal } from 'core/util';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import SearchableTable from 'app/views/hybrid/maps/components/SearchableTable';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import { PopoverInteractionKind, PopoverPosition } from '@blueprintjs/core';
import { Flex, Button, EmptyState, Heading, Popover, Sparkline, Card, Box, Icon, Text } from 'core/components';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import { getDateRangeDisplay } from 'core/util/dateUtils';
import MetricsExplorerButton from 'app/views/metrics/MetricsExplorerButton';
import { reaction } from 'mobx';

import {
  METRICS as TRANSIT_GATEWAY_METRICS,
  QUERY_NAMESPACE as TRANSIT_GATEWAY_QUERY_NAMESPACE,
  generateDimensionsByEntity as generateDimensionsFromTransitGateway,
  MEANINGFUL_STATISTICS as TRANSIT_GATEWAY_STATISTICS
} from './metrics/TransitGateway';

import {
  METRICS as NAT_GATEWAY_METRICS,
  QUERY_NAMESPACE as NAT_GATEWAY_QUERY_NAMESPACE,
  generateDimensionsByEntity as generateDimensionsFromNatGateway,
  MEANINGFUL_STATISTICS as NAT_GATEWAY_STATISTICS
} from './metrics/NatGateway';

import {
  METRICS as VPC_ENDPOINT_METRICS,
  QUERY_NAMESPACE as VPC_ENDPOINT_QUERY_NAMESPACE,
  generateDimensionsByEntity as generateDimensionsFromVpcEndpoint,
  MEANINGFUL_STATISTICS as VPC_ENDPOINT_STATISTICS
} from './metrics/VpcEndpoint';

import {
  METRICS as CORE_NETWORK_METRICS,
  QUERY_NAMESPACE as CORE_NETWORK_QUERY_NAMESPACE,
  generateDimensionsByEntity as generateDimensionsFromCoreNetwork
} from './metrics/CoreNetwork';

import {
  METRICS as DIRECT_CONNECT_NETWORK_METRICS,
  QUERY_NAMESPACE as DIRECT_CONNECT_NETWORK_QUERY_NAMESPACE,
  generateDimensionsByEntity as generateDimensionsFromDirectConnectNetwork
} from './metrics/DirectConnect';

const {
  NAT_GATEWAY,
  VPC_ENDPOINT,
  TRANSIT_GATEWAY,
  VIRTUAL_INTERFACE,
  CORE_NETWORK_EDGE,
  DIRECT_CONNECTION,
  CORE_NETWORK_ATTACHMENT,
  TRANSIT_GATEWAY_ATTACHMENT
} = ENTITY_TYPES.get('aws');

const TOTAL_BREAKPOINTS = 20;

const createCloudWatchQuery = ({ computedPeriod, nodeData, entityType }) => {
  let metrics = [];
  let namespace;
  let dimensions;
  let statistics;

  const DEFAULT_STATISTICS = ['Average'];

  if (entityType === TRANSIT_GATEWAY_ATTACHMENT || entityType === TRANSIT_GATEWAY) {
    metrics = TRANSIT_GATEWAY_METRICS;
    namespace = TRANSIT_GATEWAY_QUERY_NAMESPACE;
    dimensions = generateDimensionsFromTransitGateway(nodeData, entityType);
    statistics = TRANSIT_GATEWAY_STATISTICS;
  }

  if (entityType === NAT_GATEWAY) {
    metrics = NAT_GATEWAY_METRICS;
    namespace = NAT_GATEWAY_QUERY_NAMESPACE;
    dimensions = generateDimensionsFromNatGateway(nodeData, entityType);
    statistics = NAT_GATEWAY_STATISTICS;
  }

  if (entityType === VPC_ENDPOINT) {
    metrics = VPC_ENDPOINT_METRICS;
    namespace = VPC_ENDPOINT_QUERY_NAMESPACE;
    dimensions = generateDimensionsFromVpcEndpoint(nodeData, entityType);
    statistics = VPC_ENDPOINT_STATISTICS;
  }

  if (entityType === CORE_NETWORK_ATTACHMENT || entityType === CORE_NETWORK_EDGE) {
    metrics = CORE_NETWORK_METRICS;
    namespace = CORE_NETWORK_QUERY_NAMESPACE;
    dimensions = generateDimensionsFromCoreNetwork(nodeData, entityType);
    statistics = DEFAULT_STATISTICS;
  }

  if (entityType === DIRECT_CONNECTION || entityType === VIRTUAL_INTERFACE) {
    metrics = DIRECT_CONNECT_NETWORK_METRICS;
    namespace = DIRECT_CONNECT_NETWORK_QUERY_NAMESPACE;
    dimensions = generateDimensionsFromDirectConnectNetwork(nodeData, entityType);
    statistics = DEFAULT_STATISTICS;
  }

  return {
    MetricsArr: metrics,
    Namespace: namespace,
    Dimensions: dimensions,
    Period: computedPeriod,
    Statistics: statistics
  };
};

const awsNullFiltersByEntityType = {
  TransitGateway: ['AvailabilityZone', 'TransitGatewayAttachment'],
  TransitGatewayAttachment: ['AvailabilityZone']
};

// sets 5 minute floor for times used in queries to better match AWS cloudwatch
const setTimeFloor = ({ startTime, endTime }) => {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const fiveMinutes = 1000 * 60 * 5;

  const flooredStart = new Date(Math.floor(start.getTime() / fiveMinutes) * fiveMinutes);
  const flooredEnd = new Date(Math.floor(end.getTime() / fiveMinutes) * fiveMinutes);
  return { start: flooredStart.toISOString(), end: flooredEnd.toISOString() };
};
@inject('$hybridMap', '$dictionary', '$metrics')
@observer
export default class MetricsPanel extends React.Component {
  state = {
    loading: true,
    isPoppedOut: false,
    data: null
  };

  componentDidMount() {
    const { $hybridMap } = this.props;

    this.timeRangeDisposer = reaction(
      () => $hybridMap.sidebarSettings.timeRange,
      () => this.fetchMetrics()
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { nodeData } = this.props;
    const { data, isPoppedOut } = this.state;
    const popOutChanged = prevState.isPoppedOut !== isPoppedOut;

    if (prevProps.nodeData?.id !== nodeData?.id || (data === null && popOutChanged)) {
      // if we have the sidebar open and switch to a new load balancer or change the time range, fetch for new data
      this.fetchMetrics();
    }
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.cancel();
    }

    if (this.timeRangeDisposer) {
      this.timeRangeDisposer();
    }
  }

  getComputedPeriod = () => {
    const { $hybridMap } = this.props;

    const startTime = $hybridMap.sidebarSettings.timeRange.start;
    const endTime = $hybridMap.sidebarSettings.timeRange.end;
    const { start, end } = setTimeFloor({ startTime, endTime });

    // min value is 30
    return Math.max(Math.floor((end - start) / 1000 / TOTAL_BREAKPOINTS / 60) * 60, 30);
  };

  fetchMetrics = () => {
    const { $hybridMap, nodeData, entityType } = this.props;

    this.setState({ loading: true }, () => {
      const computedPeriod = this.getComputedPeriod();
      const postData = {
        metricDataQuery: createCloudWatchQuery({ computedPeriod, nodeData, entityType })
      };

      this.request = makeCancelable(
        $hybridMap.getAwsMetrics({ entityType, id: nodeData.id, postData, region: nodeData.RegionName })
      );

      this.request.promise
        .then((response) => {
          this.setState({ loading: false, data: response });
        })
        .catch((e) => {
          if (e instanceof CanceledError === false) {
            this.setState({ loading: false }, () => console.error(e));
          }
        });
    });
  };

  handleExpandChange = (isOpenOrPoppedOut) => {
    const { data } = this.state;

    if (isOpenOrPoppedOut && data === null) {
      // fetch for data when expanding for the first time
      this.fetchMetrics();
    }
  };

  handlePopOutChange = (isPoppedOut) => {
    this.setState({ isPoppedOut }, this.handleExpandChange);
  };

  handlePanelRefresh = (e) => {
    e.stopPropagation();
    this.fetchMetrics();
  };

  get title() {
    const { $hybridMap, $dictionary } = this.props;
    const { isPoppedOut } = this.state;
    // under the hood we support children being able to report their metrics as part of their parent
    // this would be shown as a group summary where we can display the unique time intervals for each metric there
    // since we're not using this feature just yet, just take the first interval and use that as it will be what was used for all the metrics returned

    const lookBackSeconds = $hybridMap.sidebarSettings?.sidebarQueryOverrides?.lookback_seconds ?? '';

    return (
      <Flex alignItems="end">
        <Heading level={5} mb={0} mr={1}>
          Metrics
        </Heading>
        {!isPoppedOut && (
          <Text color="muted" small>
            ({$dictionary.get(`showLast.${lookBackSeconds}`, 'UTC')})
          </Text>
        )}
      </Flex>
    );
  }

  get popOutHeader() {
    const { $hybridMap } = this.props;
    const { data, isPoppedOut } = this.state;
    const displayInterval = data?.metrics?.[0]?.interval?.display;
    const { start, end } = $hybridMap.sidebarSettings.timeRange;

    if (isPoppedOut) {
      return (
        <Text as="div" fontWeight="bold" mb={1}>{`${getDateRangeDisplay(start, end)} ${
          displayInterval ? `(${displayInterval})` : ''
        }`}</Text>
      );
    }

    return null;
  }

  get emptyState() {
    const { cloudProvider } = this.props;

    return (
      <EmptyState
        title="No Metrics Found"
        icon={<CloudIcon cloudProvider={cloudProvider} entity="cloudWatch" iconSize={48} />}
      />
    );
  }

  renderMetric = ({ value, model }) => {
    const metricsName = model.get('name')?.toLowerCase();

    // make sure we display values less than 1
    const fixedValue = toDecimal(value);

    if (metricsName.includes('bytes')) {
      return formatBytesGreek(fixedValue, 'B');
    }

    return formatBytesGreek(fixedValue, '');
  };

  getEntityContextForQuery = () => {
    const { $metrics, nodeData, entityType } = this.props;
    const { measurementModelByName, decorateFilterObj } = $metrics;
    const computedPeriod = this.getComputedPeriod();
    const { Namespace, Dimensions } = createCloudWatchQuery({ computedPeriod, nodeData, entityType });
    // Dimensions.length should always be 1 here
    if (Dimensions?.length > 1) {
      console.warn(
        `Multiple Metric Dimensions returned for entityType ${entityType}.  Using only first one found.  Full Dimensions object: `,
        Dimensions
      );
    }

    const [{ Name: entityDimensionName, Value: entityId }] = Dimensions;
    const measurement = `/cloud/amazon/${Namespace}`;

    // get the model used for metrics NMS metrics explorer
    const measurementModel = measurementModelByName(measurement);

    if (!measurementModel) {
      // we can't query without a valid model
      return {};
    }

    // get all the dimensions for this entity/measurment to include in the query
    const selectedDimensions = Object.values(measurementModel.dimensionColumnMap).map(({ name }) => name);

    // add the entity type and it's id to the query filters
    const selectedFilters = [decorateFilterObj({ filterField: entityDimensionName, filterValue: entityId })];

    // some dimensions in metrics explorer (e.g. TransitGateways) will include more results than just the entity id,
    //  so we need to filter them out with null values
    if (awsNullFiltersByEntityType[entityDimensionName]?.length) {
      awsNullFiltersByEntityType[entityDimensionName].forEach((nullFilterName) =>
        selectedFilters.push(decorateFilterObj({ filterField: nullFilterName, filterValue: '' }))
      );
    }

    return { measurementModel, selectedDimensions, selectedFilters };
  };

  getMetricsExplorerQuery = ({ metricName, measurementModel, selectedDimensions, selectedFilters }) => {
    const { $metrics, $hybridMap } = this.props;
    const { getCloudTopologyMetricExplorerLinkQuery } = $metrics;
    const startTime = $hybridMap.sidebarSettings.timeRange.start;
    const endTime = $hybridMap.sidebarSettings.timeRange.end;
    const { start, end } = setTimeFloor({ startTime, endTime });

    const queryParams = {
      selectedMeasurement: measurementModel?.get('id'),
      metricName,
      selectedDimensions,
      selectedFilters,
      starting_time: start,
      ending_time: end
    };
    return getCloudTopologyMetricExplorerLinkQuery(queryParams);
  };

  get columns() {
    const { topology } = this.props;
    const { isPoppedOut } = this.state;
    const { measurementModel, selectedDimensions = [], selectedFilters = [] } = this.getEntityContextForQuery();
    let hasExporterWithEnabledMetrics = topology?.KentikExportersSummary?.EnableMetricsCollection ?? false;
    hasExporterWithEnabledMetrics = true;
    if (!measurementModel) {
      hasExporterWithEnabledMetrics = false;
    }

    return [
      {
        name: 'name',
        label: 'Metric',
        renderer: ({ value, model }) => {
          const sparklineData = model.get('sparklineData', []);
          const hasData = sparklineData?.length > 0;

          const metricsExplorerQuery = hasExporterWithEnabledMetrics
            ? this.getMetricsExplorerQuery({ metricName: value, measurementModel, selectedDimensions, selectedFilters })
            : {};

          return (
            <Popover
              usePortal
              disabled={!hasData || isPoppedOut}
              minimal={false}
              interactionKind={PopoverInteractionKind.HOVER}
              position={PopoverPosition.LEFT}
              modifiers={{
                flip: { enabled: false },
                preventOverflow: { enabled: true, boundariesElement: 'window' }
              }}
              content={
                <Card p={1}>
                  <Box width={150} height={50}>
                    <Sparkline data={sparklineData} width={150} height={50} strokeWidth={0.5} color="primary" />
                  </Box>
                </Card>
              }
            >
              <Flex alignItems="end">
                {
                  // if query is legit, it'll have a kmetrics prop
                  // if we're missing data necessary for the query, metricsExplorerQuery will be empty object
                  // so don't show the link
                  hasExporterWithEnabledMetrics && metricsExplorerQuery?.kmetrics ? (
                    <MetricsExplorerButton
                      openInNewWindow
                      text={value}
                      query={metricsExplorerQuery}
                      iconSize={20}
                      ml={-1}
                      mr={1}
                    />
                  ) : (
                    <>
                      <Icon icon="timeline-line-chart" color={hasData ? 'primary' : 'muted'} mr={1} />
                      <Text>{value}</Text>
                    </>
                  )
                }
              </Flex>
            </Popover>
          );
        }
      },

      ...(isPoppedOut
        ? [
            {
              name: 'sparklineData',
              label: 'Trend',
              flexBasis: 125,
              renderer: ({ value }) => (
                <Box width={250} height={20}>
                  <Sparkline data={value} width={250} height={20} strokeWidth={0.5} color="primary" />
                </Box>
              )
            }
          ]
        : []),

      {
        name: 'average',
        label: 'Avg',
        width: 85,
        renderer: this.renderMetric
      },

      {
        name: 'sum',
        label: 'Sum',
        width: 85,
        renderer: this.renderMetric
      }
    ];
  }

  render() {
    const { cloudProvider, width, popoutTitle } = this.props;
    const { loading, data } = this.state;

    return (
      <SidebarItem
        excludeFormProps
        title={this.title}
        dialogProps={{ width }}
        popoutTitle={popoutTitle}
        useIconTag={false}
        icon={<CloudIcon cloudProvider={cloudProvider} entity="cloudWatch" iconSize={24} style={{ marginRight: 8 }} />}
        navigationButtons={
          <Flex gap={1}>
            <Button icon="refresh" color="success" onClick={this.handlePanelRefresh} small minimal />
          </Flex>
        }
        onExpandChange={this.handleExpandChange}
        onPopOutChange={this.handlePopOutChange}
      >
        <Box>
          {this.popOutHeader}
          <SearchableTable
            collectionOptions={{ sortState: { field: 'name', direction: 'asc' } }}
            height={240}
            loading={loading}
            columns={this.columns}
            filterKeys={['name']}
            data={data?.data || []}
            emptyState={this.emptyState}
            stickyHeader
            stickyGroups
          />
        </Box>
      </SidebarItem>
    );
  }
}
