import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Position } from '@blueprintjs/core';
import { TimePrecision } from '@blueprintjs/datetime';
import moment from 'moment';
import get from 'lodash/get';

import { Flex, Box, Callout, Button, Text } from 'core/components';
import { timezone, DEFAULT_DATETIME_FORMAT, secondsIntervalToText, getDateRangeDisplay } from 'core/util/dateUtils';
import DateRangeInputShortcuts from './DateRangeInputShortcuts';

const normalizeDate = (momentObj) => {
  const utc = timezone.value === 'UTC';
  const utcOffset = momentObj.utcOffset();

  return momentObj.subtract(utc ? utcOffset : 0, 'minute');
};

const fixDate = (date) => {
  const momentObj = moment(date);
  const utc = timezone.value === 'UTC';
  const utcOffset = momentObj.utcOffset();

  return momentObj.add(utc ? utcOffset : 0, 'minute');
};

const createDateRange = (lookbackSeconds) => {
  const now = moment();
  const start = normalizeDate(moment(now).subtract(lookbackSeconds, 'seconds'));
  const end = normalizeDate(moment(now));

  if (lookbackSeconds >= 1209600) {
    start.startOf('day');
    end.endOf('day');
  }

  return [start.toDate(), end.toDate()];
};

const setShortcutDateRanges = (shortcuts) =>
  shortcuts.map((shortcut) => {
    if (shortcut.dateRange) {
      return shortcut;
    }

    return {
      ...shortcut,
      dateRange: createDateRange(shortcut.lookbackSeconds)
    };
  });

// timezone is not set yet
export const DEFAULT_SHORTCUTS = [
  { label: 'Last 5 Minutes', lookbackSeconds: 300, includeTime: true },
  { label: 'Last 15 Minutes', lookbackSeconds: 900, includeTime: true },
  { label: 'Last Hour', lookbackSeconds: 3600, includeTime: true },
  { label: 'Last 3 Hours', lookbackSeconds: 10800, includeTime: true },
  { label: 'Last 6 Hours', lookbackSeconds: 21600, includeTime: true },
  { label: 'Last 1 Day', lookbackSeconds: 86400, includeTime: true },
  { label: 'Last 1 Week', lookbackSeconds: 604800, includeTime: true },
  { label: 'Last 2 Weeks', lookbackSeconds: 1209600, includeTime: true },
  { label: 'Last 30 Days', lookbackSeconds: 2592000, includeTime: true }
];

const DEFAULT_MIN_DATE = moment('2014-01-01').toDate();

class LookbackDateRange extends Component {
  state = {
    isEditing: false,
    startDate: null,
    endDate: null,
    selectedShortcutIndex: -1,
    shortcuts: [],
    dirty: false,
    startDateInputFocused: false,
    endDateInputFocused: false
  };

  componentDidMount() {
    const { lookbackSeconds, minLookbackSeconds, maxLookbackSeconds, startDate, endDate } = this.props;

    // wait to create defaults until after timezone is set
    DEFAULT_SHORTCUTS.forEach((shortcut, i) => {
      DEFAULT_SHORTCUTS[i].dateRange = createDateRange(shortcut.lookbackSeconds);
    });

    const shortcuts = setShortcutDateRanges(this.getShortcuts(minLookbackSeconds, maxLookbackSeconds));
    const selectedShortcutIndex = this.findShortcut(shortcuts, lookbackSeconds);

    this.originalValues = { selectedShortcutIndex, startDate, endDate };
    this.setState({ selectedShortcutIndex, shortcuts });
  }

  componentDidUpdate(prevProps, prevState) {
    const { lookbackSeconds, minLookbackSeconds, maxLookbackSeconds, onEditModeChange } = this.props;
    const { isEditing } = this.state;
    if (
      prevProps.lookbackSeconds !== lookbackSeconds ||
      prevProps.minLookbackSeconds !== minLookbackSeconds ||
      prevProps.maxLookbackSeconds !== maxLookbackSeconds
    ) {
      const shortcuts = setShortcutDateRanges(this.getShortcuts(minLookbackSeconds, maxLookbackSeconds));
      const selectedShortcutIndex = this.findShortcut(shortcuts, lookbackSeconds);
      Object.assign(this.originalValues, { selectedShortcutIndex });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedShortcutIndex, shortcuts });
    }

    if (prevState.isEditing !== isEditing && onEditModeChange) {
      onEditModeChange(isEditing);
    }
  }

  recalculateShortcuts = () => {
    const { minLookbackSeconds, maxLookbackSeconds } = this.props;
    const shortcuts = setShortcutDateRanges(this.getShortcuts(minLookbackSeconds, maxLookbackSeconds));

    this.setState({ shortcuts });
  };

  getShortcuts = (minLookbackSeconds, maxLookbackSeconds) => {
    const { overrideShortcuts } = this.props;

    let shortcuts = overrideShortcuts || DEFAULT_SHORTCUTS;

    if (minLookbackSeconds || maxLookbackSeconds) {
      shortcuts = shortcuts.filter(
        (shortcut) =>
          (!minLookbackSeconds || shortcut.lookbackSeconds >= minLookbackSeconds) &&
          (!maxLookbackSeconds || shortcut.lookbackSeconds <= maxLookbackSeconds)
      );
    }

    return shortcuts;
  };

  findShortcut = (shortcuts, lookbackSeconds) =>
    shortcuts.findIndex((shortcut) => shortcut.lookbackSeconds === lookbackSeconds);

  showDateRangeSelector = () => {
    this.setState({ isEditing: true, dirty: false });
  };

  hideDateRangeSelector = () => {
    this.setState({ isEditing: false, dirty: false });
  };

  cancelEditing = () => {
    this.setState({ isEditing: false, dirty: false, ...this.originalValues });
  };

  onApplyClick = () => {
    const { onChange } = this.props;
    this.hideDateRangeSelector();

    const { startDate, endDate, shortcuts, selectedShortcutIndex } = this.state;
    if (onChange) {
      onChange({
        startDate,
        endDate,
        lookbackSeconds: selectedShortcutIndex === -1 ? null : shortcuts[selectedShortcutIndex].lookbackSeconds
      });
    }
  };

  /**
   * Called when the user selects a day. If no days are selected, it will pass [null, null].
   * If a start date is selected but not an end date, it will pass [selectedDate, null].
   * If both a start and end date are selected, it will pass [startDate, endDate].
   */
  handleDateChange = (dateRange) => {
    const { adjustEndTime } = this.props;
    const [start, end] = dateRange;
    const startDate = fixDate(start).unix();
    let endDate = fixDate(end).unix();

    // adjust end time to be 23:59 instead of 00:00 and increment seconds by 59 in order to include the full time range for that day
    if (adjustEndTime && end) {
      const endMoment = moment(end);

      if (endMoment.hours() === 0 && endMoment.minutes() === 0) {
        endMoment.hours(23);
        endMoment.minutes(59);
      }

      endMoment.seconds(59);
      endDate = fixDate(endMoment).unix();
    }

    this.setState({
      startDate,
      endDate,
      selectedShortcutIndex: -1,
      dirty: true
    });
  };

  handleShortcutChange = (_shortcut, index) => {
    this.setState({ startDate: null, endDate: null, selectedShortcutIndex: index, dirty: true });
  };

  calloutRef = (ref) => {
    this.callout = ref;
  };

  onInputGroupKeyUp = (e) => {
    if (e && (e.key === 'Esc' || e.key === 'Escape')) {
      this.cancelEditing();
    }
  };

  onInputFocus = (type) => {
    this.setState({ [`${type}InputFocused`]: true });
  };

  onInputBlur = (type) => {
    this.setState({ [`${type}InputFocused`]: false });
  };

  getDateInputPlaceholders = () => {
    const { format = DEFAULT_DATETIME_FORMAT } = this.props;
    const { shortcuts, selectedShortcutIndex, startDateInputFocused, endDateInputFocused } = this.state;

    if (startDateInputFocused || endDateInputFocused) {
      const [startDate, endDate] = get(shortcuts[selectedShortcutIndex], 'dateRange') || [];

      if (startDateInputFocused && startDate) {
        return [moment(startDate).format(format), null];
      }

      if (endDateInputFocused && endDate) {
        return [null, moment(endDate).format(format)];
      }
    }

    return [null, null];
  };

  render() {
    const {
      stacked = false,
      disabled = false,
      format = DEFAULT_DATETIME_FORMAT,
      minDate,
      maxDate,
      startDate,
      endDate,
      dropdown,
      showShortcuts = true,
      showLabel = true,
      showShortcutLabelAsInterval,
      /*
        when minimal is true:

        - the 'Time Range' label will not show
        - start/end date inputs are shortened to their absolute minimum
        - the 'Apply' and 'Cancel' buttons are shortened to their absolute minimum
      */
      minimal = false
    } = this.props;
    const {
      isEditing,
      selectedShortcutIndex,
      shortcuts,
      dirty,
      startDate: stateStartDate,
      endDate: stateEndDate
    } = this.state;

    const defaultValue = [
      startDate ? normalizeDate(moment.unix(startDate)).toDate() : startDate,
      endDate ? normalizeDate(moment.unix(endDate)).toDate() : endDate
    ];
    const selectedShortcut = shortcuts[selectedShortcutIndex];
    const dropdownOffset = dropdown ? '197, 5' : '194, 6';

    const [startDatePlaceholder, endDatePlaceholder] = this.getDateInputPlaceholders();
    const now = moment();

    const textProps = {
      as: 'div',
      small: !stacked,
      fontWeight: stacked ? 'normal' : 'bold',
      whiteSpace: 'nowrap'
    };

    const selectButton = (
      <Box>
        <Button
          disabled={disabled}
          onClick={this.showDateRangeSelector}
          textAlign="left"
          rightIcon="caret-down"
          height={stacked ? 30 : 35}
          width={stacked ? '100%' : 'auto'}
        >
          {!stacked && (
            <Text as="div" small color="muted" mb="1px">
              Time Range ({timezone.value})
            </Text>
          )}

          {selectedShortcutIndex !== -1 && (
            <Text {...textProps}>
              {showShortcutLabelAsInterval
                ? selectedShortcut.label
                : `Last ${secondsIntervalToText(shortcuts[selectedShortcutIndex].lookbackSeconds)}`}
            </Text>
          )}

          {selectedShortcutIndex === -1 && <Text {...textProps}>{getDateRangeDisplay(startDate, endDate)}</Text>}
        </Button>
      </Box>
    );

    const editorInputStyle = {};
    let editorButtonWidth = stacked ? '50%' : '90px';

    if (minimal) {
      editorInputStyle.width = 120;
      editorButtonWidth = 50;
    }

    const editor = (
      <Callout
        ml={dropdown || stacked || minimal ? 0 : 1}
        bg={dropdown ? 'unset' : 'auto'}
        width={stacked || minimal ? 'auto' : 567}
        minWidth={stacked || minimal ? 150 : 567}
        height={stacked ? 120 : 35}
        p={stacked ? 1 : '6px'}
        whiteSpace="nowrap"
        position="relative"
        zIndex="101" // 1 higher than highcharts tooltip
      >
        <Flex alignItems="center">
          {showLabel && !minimal && (
            <Text as="div" small color="muted" mr={1} textAlign="left">
              Time Range
            </Text>
          )}
          <Flex flexDirection={stacked ? 'column' : 'row'}>
            <Flex flexWrap="wrap">
              <DateRangeInputShortcuts
                stacked={stacked}
                closeOnSelection={stacked}
                formatDate={(date) => moment(date).format(format)}
                onChange={this.handleDateChange}
                onShortcutChange={this.handleShortcutChange}
                parseDate={(str) => moment(str, format).toDate()}
                selectedShortcutIndex={selectedShortcutIndex}
                highlightCurrentDay
                flexWrap="wrap"
                startInputProps={{
                  style: { flex: '0 0 100% !important', flexWrap: 'wrap', ...editorInputStyle },
                  autoFocus: isEditing,
                  small: !stacked,
                  onKeyUp: this.onInputGroupKeyUp,
                  onFocus: this.onInputFocus.bind(null, 'startDate'),
                  onBlur: this.onInputBlur.bind(null, 'startDate'),
                  placeholder: startDatePlaceholder
                }}
                endInputProps={{
                  style: editorInputStyle,
                  small: !stacked,
                  onKeyUp: this.onInputGroupKeyUp,
                  onFocus: this.onInputFocus.bind(null, 'endDate'),
                  onBlur: this.onInputBlur.bind(null, 'endDate'),
                  placeholder: endDatePlaceholder
                }}
                shortcuts={showShortcuts && shortcuts}
                allowSingleDayRange
                defaultValue={defaultValue}
                minDate={minDate || DEFAULT_MIN_DATE}
                maxDate={maxDate || normalizeDate(moment(now).subtract(-60, 'minute')).toDate()}
                popoverProps={{
                  minimal: true,
                  modifiers: {
                    flip: { enabled: true, boundariesElement: 'viewport' },
                    preventOverflow: { enabled: true, boundariesElement: 'window' },
                    offset: { enabled: true, offset: showShortcuts ? dropdownOffset : '0, 5' }
                  },
                  position: Position.BOTTOM_RIGHT,
                  targetTagName: 'div',
                  wrapperTagName: 'div',
                  onClosing: () => {
                    if (!dirty) {
                      this.setState((prevState) => ({ isEditing: !prevState.isEditing }));
                    }
                  },
                  onOpening: () => {
                    this.recalculateShortcuts();
                  }
                }}
              />
            </Flex>
            <Box>
              <Flex justifyContent="space-between" gap={stacked ? 1 : '4px'} px={stacked ? 0 : 1} py={stacked ? 1 : 0}>
                <Button
                  small={!stacked}
                  onClick={this.onApplyClick}
                  text="Apply"
                  width={editorButtonWidth}
                  disabled={selectedShortcutIndex < 0 && (!stateStartDate || !stateEndDate)}
                  title="Apply"
                />
                <Button
                  small={!stacked}
                  onClick={this.cancelEditing}
                  text="Cancel"
                  width={editorButtonWidth}
                  title="Cancel"
                />
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Callout>
    );

    if (dropdown) {
      return (
        <Box position="relative">
          {selectButton}
          {isEditing && (
            <Box
              position="absolute"
              top={35}
              left={1}
              zIndex={2147483647}
              width={dropdown ? 591 : 596}
              height={36}
              border="thin"
              bg="subnavBackground"
              pl={dropdown ? '20px' : '29px'}
              borderRadius="4px"
            >
              {editor}
            </Box>
          )}
        </Box>
      );
    }

    if (!isEditing) {
      return selectButton;
    }

    return editor;
  }
}

LookbackDateRange.defaultProps = {
  format: 'YYYY-MM-DD HH:mm',
  timePrecision: TimePrecision.MINUTE
};

export default observer(LookbackDateRange);
