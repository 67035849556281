import { namespaces } from 'd3-selection';
import { line, curveBasis } from 'd3-shape';
import { formatBytesGreek } from 'core/util';
import { memoize as _memoize } from 'lodash';
import $dictionary from 'app/stores/$dictionary';

const LAG_TYPE = 161;

const snmpTypeOverrides = {
  6: 'Ethernet',
  53: 'Virtual',
  135: 'VLAN',
  161: 'Link Aggregation (802.3ad)'
};

export function onlyTopTargetLinksFor(targetType, links, percentage = 0.98) {
  const data = links
    .filter(({ target }) => target.type === targetType)
    .map(({ total }) => total)
    .sort((a, b) => b - a);

  const sum = data.reduce((s, value) => s + value, 0);
  const minAcc = sum * percentage;
  let cutoff = Infinity;
  let acc = 0;

  // add up top values until we get at least 98%
  for (let i = 0; i < data.length; i += 1) {
    cutoff = data[i];
    acc += data[i];

    if (acc > minAcc) {
      break;
    }
  }

  // only take values that are at least 1% of total
  cutoff = Math.max(cutoff, sum * 0.01);

  return links.filter(({ total, target }) => target.type !== targetType || total >= cutoff);
}

/**
 * @param {string} pathData
 * @param {"source"|"target"} type
 * @param {number} size
 */
export function getArrowHead(pathData, type, size = 6) {
  const path = document.createElementNS(namespaces.svg, 'path');
  path.setAttribute('d', pathData);

  let point;
  let anchor;

  if (type === 'target') {
    point = path.getPointAtLength(0);
    anchor = path.getPointAtLength(1);
  } else if (type === 'source') {
    const length = path.getTotalLength();
    point = path.getPointAtLength(length);
    anchor = path.getPointAtLength(length - 1);
  }

  const angle = Math.atan2(point.y - anchor.y, point.x - anchor.x) - Math.PI / 2;
  const top = [point.x - Math.sin(angle) * size, point.y + Math.cos(angle) * size];
  const left = [point.x + Math.cos(angle) * (size / 2), point.y + Math.sin(angle) * (size / 2)];
  const right = [point.x - Math.cos(angle) * (size / 2), point.y - Math.sin(angle) * (size / 2)];

  const points = [top, left, right];

  return `M${points.join('L')}Z`;
}

/*
  Options:

  at: where to split the path at. Default is 0.5 which is a clean cut into equal segments
  margin: spacing between segments
  minimumPiece: the minimum length of a segment
  orientationCheckDirection (source|target): which segment we want to check to ensure left-to-right path creation (helps with determining text direction)
*/
export function splitPath(
  pathData,
  { at = 0.5, margin = 16, minimumPiece = 40, orientationCheckDirection = 'source', connectionType } = {}
) {
  const drawPiece = connectionType === 'square' ? line() : line().curve(curveBasis);
  const path = document.createElementNS(namespaces.svg, 'path');
  path.setAttribute('d', pathData);

  const sampleLength = connectionType === 'square' ? 4 : 20;
  const length = path.getTotalLength();
  const marginSize = margin / length / 2;

  if (Number.isNaN(at)) {
    at = 0;
  }

  // adjust smaller piece to be at least the minimum
  if (length > minimumPiece * 2) {
    if (at < 0.5 && at * length < minimumPiece) {
      at = minimumPiece / length;
    } else if (at > 0.5 && (1 - at) * length < minimumPiece) {
      at = (length - minimumPiece) / length;
    }
  }

  return [
    [0, at - marginSize],
    [at + marginSize, 1]
  ].map(([start, end], idx) => {
    const pieceSize = Math.abs(end - start);
    const pieceLength = pieceSize * length;
    const numSamples = Math.ceil(pieceLength / sampleLength);
    const samples = [];

    if (numSamples === 0) {
      return null;
    }

    for (let s = 0; s <= numSamples; s += 1) {
      const { x, y } = path.getPointAtLength(length * (start + pieceSize * (s / numSamples)));
      samples.push([x, y]);
    }

    const direction = idx === 0 ? 'source' : 'target';
    const arrowHead = getArrowHead(drawPiece(samples), direction);
    let textAlign = direction === 'source' ? 'right' : 'left';

    const deltaX =
      direction === orientationCheckDirection
        ? samples[samples.length - 1][0] - samples[samples.length - 2][0]
        : samples[1][0] - samples[0][0];

    // draw line from left to right to make textPath render on top
    if (deltaX < 0) {
      samples.reverse();
      textAlign = direction === 'source' ? 'left' : 'right';
    }

    return {
      pathData: drawPiece(samples),
      arrowHead,
      textAlign,
      canRenderDirection: pieceLength >= 130 // the threshold that will determine whether labels are rendered side-by-side or stacked
    };
  });
}

/*
 * When `canRenderDirection` is false, get a text path that will render text in the middle correctly
 */
export function getTextPath(rawPoints, { connectionType = 'curved' }) {
  const points = rawPoints.slice();
  const drawPath = connectionType === 'square' ? line() : line().curve(curveBasis);
  let pathData = drawPath(points);
  let textAlign = 'right';

  const path = document.createElementNS(namespaces.svg, 'path');
  path.setAttribute('d', pathData);

  const length = path.getTotalLength();

  const point1 = path.getPointAtLength(length / 2 - 1);
  const point2 = path.getPointAtLength(length / 2 + 1);

  const deltaX = point2.x - point1.x;

  // draw line from left to right to make textPath render on top
  if (deltaX < 0) {
    points.reverse();
    pathData = drawPath(points);
    textAlign = 'left';
  }

  return { pathData, sourceTextAlign: textAlign, targetTextAlign: textAlign === 'left' ? 'right' : 'left' };
}

export function getLinkType(if_type) {
  return snmpTypeOverrides[if_type] || $dictionary.get('snmpMetadata.snmpType')[if_type];
}

export function getLinkLabel(groupedConnectionConfig) {
  const { layer3, layer2 } = groupedConnectionConfig;
  const hasLayer3Connections = layer3.length > 0;
  const hasLayer2Connections = layer2.length > 0;

  if (layer2.length > 1 && !hasLayer3Connections) {
    return 'VLAN Trunk';
  }

  /** @type {Set<number>} */
  const types = new Set();
  let connections = [];

  if (hasLayer3Connections) {
    connections = layer3;
  } else if (hasLayer2Connections) {
    connections = layer2;
  }

  connections.forEach((connection) => {
    types.add(connection.interface1.if_type);
  });

  return Array.from(types)
    .map((type) => getLinkType(type))
    .filter(Boolean)
    .join(', ');
}

export function getLinkCapacityUtilization(traffic, capacity) {
  if (
    typeof traffic === 'number' &&
    !Number.isNaN(traffic) &&
    typeof capacity === 'number' &&
    !Number.isNaN(capacity) &&
    capacity > 0
  ) {
    const percent = (traffic / capacity) * 100;

    if (percent > 0 && percent < 1) {
      return '<1';
    }

    return Math.round(percent);
  }

  return null;
}

export function getTrafficLabel(traffic, capacity) {
  let label = formatBytesGreek(traffic, 'bps');
  const utilization = getLinkCapacityUtilization(traffic, capacity);

  if (traffic === 0) {
    // when we have no traffic, do not display capacity and direction
    return label;
  }

  if (utilization) {
    // tack on capacity if we have it
    label = `${label} (${utilization}%)`;
  }

  return label;
}

export function getLatencyLabel(latency) {
  if (!latency || latency === 0) {
    return '';
  }

  return `${latency.toFixed(2)}ms`;
}

export function addTrafficLabelArrow(label, arrowAlign) {
  // skip arrows for no traffic
  if (label.startsWith('0')) {
    return label;
  }

  return arrowAlign === 'left' ? `◀ ${label}` : `${label} ▶`;
}

export function getConnectionsForInterface(connections, interfaceConfig) {
  const { device_name, snmp_id } = interfaceConfig;

  return connections.reduce((acc, connection) => {
    const { interface1, interface2, ...rest } = connection;

    // if the interface matches the first interface in the connection, add it
    if (interface1 && interface1.device_name === device_name && interface1.snmp_id === snmp_id) {
      return acc.concat(connection);
    }

    // if the interface matches the second interface in the connection, add it to the list but swap places
    if (interface2 && interface2.device_name === device_name && interface2.snmp_id === snmp_id) {
      return acc.concat({
        interface1: interface2,
        interface2: interface1,
        ...rest
      });
    }

    return acc;
  }, []);
}

export function groupConnections(links, { forInterface } = {}) {
  const groupedConnections = links.reduce(
    (acc, link) => {
      // things like site-to-site connections want to remember the site 1 and 2 ids on the link
      const linkMetadata = { site1_id: link.site1_id, site2_id: link.site2_id };

      // add the unbundled layer3 and layer2 connections
      let layer3 = acc.layer3.concat(link.layer3_connections || []);
      let layer2 = acc.layer2.concat(link.layer2_connections || []);

      if (link.bundled_connections && link.bundled_connections.length > 0) {
        acc.isBundled = true;

        for (let i = 0; i < link.bundled_connections.length; i += 1) {
          const bundle = link.bundled_connections[i];

          layer3 = layer3.concat(bundle.layer3_connections || []);
          layer2 = layer2.concat(bundle.layer2_connections || []);
        }
      }

      // add layer info the connections for easy reference later on
      layer3 = layer3.map((l) => ({ ...l, layer: 3, linkMetadata }));
      layer2 = layer2.map((l) => ({ ...l, layer: 2, linkMetadata }));

      return { ...acc, layer3, layer2 };
    },
    {
      isBundled: false,
      layer3: [],
      layer2: []
    }
  );

  if (forInterface) {
    groupedConnections.layer3 = getConnectionsForInterface(groupedConnections.layer3, forInterface);
    groupedConnections.layer2 = getConnectionsForInterface(groupedConnections.layer2, forInterface);
  }

  return groupedConnections;
}

/**
 * breaks bundled links into separate links and adds additional metadata
 * @param link
 * @returns array of separated links
 */
export function separateLink(link) {
  const layer2 = link.connections.filter((c) => c.layer === 2);
  const layer3 = link.connections.filter((c) => c.layer === 3 && c.interface1.if_type !== LAG_TYPE);
  const aggregates = link.connections.filter((c) => c.layer === 3 && c.interface1.if_type === LAG_TYPE);
  const attachedDeviceIds = [];
  const links = [];

  // make a link from each aggregate found, along with its corresponding layer2 connections
  aggregates.forEach((c) => {
    const aggMatch = layer2.filter((l) => l.interface1.device_id === c.interface1.device_id);
    const count = aggMatch.length;

    const linkLabel = getLinkType(c.interface1.if_type);
    const { snmp_speed } = c.interface1;
    const layer3_connections = [c];
    const layer2_connections = aggMatch;

    attachedDeviceIds.push(c.interface1.device_id);

    links.push({
      ...link,
      linkLabel: `${linkLabel}${count > 0 ? ` - ${count} Member${count === 1 ? '' : 's'}` : ''}`,
      snmp_speed,
      connections: [c, ...aggMatch],
      layer3_connections: count === 0 ? layer3_connections : null,
      layer2_connections: null,
      bundled_connections: count > 0 ? [{ layer3_connections, layer2_connections }] : null,
      aggregate: { count: Math.max(count, 1), snmp_speed: aggMatch[0]?.interface1.snmp_speed || snmp_speed }
    });
  });

  // make a link for each layer3 connection found, along with its corresponding layer2 connection(s)
  layer3.forEach((c) => {
    const layer2Match = layer2.filter((l) => l.interface1.device_id === c.interface1.device_id);
    const count = layer2Match.length;

    const layer3_connections = [c];
    const layer2_connections = layer2Match;

    attachedDeviceIds.push(c.interface1.device_id);

    links.push({
      ...link,
      linkLabel: getLinkType(c.interface1.if_type),
      snmp_speed: c.interface1.snmp_speed,
      connections: [c, ...layer2Match],
      layer3_connections: count === 0 ? layer3_connections : null,
      layer2_connections: null,
      bundled_connections: count > 0 ? [{ layer3_connections, layer2_connections }] : null
    });
  });

  // make a link for each layer2 connection that hasn't been already attached
  layer2
    .filter((c) => !attachedDeviceIds.includes(c.interface1.device_id))
    .forEach((c) => {
      links.push({
        ...link,
        linkLabel: getLinkType(c.interface1.if_type),
        snmp_speed: c.interface1.snmp_speed,
        connections: [c],
        bundled_connections: null,
        layer2_connections: [c]
      });
    });

  return links;
}

export function getConnectionKey(connection) {
  if (connection) {
    const { interface1, interface2 } = connection;
    let key = `${interface1.device_id}-${interface1.snmp_id}`;

    if (interface2) {
      key = `${key}-${interface2.device_id}-${interface2.snmp_id}`;
    }

    return key;
  }

  return null;
}

export function flattenGroupConnections(groupedConnections) {
  const layer3 = groupedConnections.layer3 || [];
  const layer2 = groupedConnections.layer2 || [];
  const unknown = groupedConnections.unknown || [];

  return [].concat(layer3, layer2, unknown);
}

export function getGroupedConnectionKey(groupedConnections) {
  const connections = flattenGroupConnections(groupedConnections);
  const keys = connections.map((connection) => getConnectionKey(connection));

  return keys.join('-');
}

export const getPathLength = _memoize((pathData) => {
  if (!pathData) {
    return 0;
  }

  const path = document.createElementNS(namespaces.svg, 'path');
  path.setAttribute('d', pathData);
  return path.getTotalLength();
});

/** based on http://bl.ocks.org/bycoffe/18441cddeb8fe147b719fab5e30b5d45 with small refactor */
export const splitPathIntoPieces = _memoize(
  ({ pathData, numPieces = 5, sampleInterval = 1, cumu = 0, calculateOptimal = true, minLength = 70, margin = 0 }) => {
    const path = document.createElementNS(namespaces.svg, 'path');
    path.setAttribute('d', pathData);
    const length = path.getTotalLength();

    let optimalNumOfPieces = numPieces;
    // calculate optiman num of pieces to make sure every segment is not less than minLength
    if (calculateOptimal) {
      optimalNumOfPieces = Math.min(Math.floor(length / minLength), numPieces);

      // there should be at least 1 peace
      if (optimalNumOfPieces === 0) {
        optimalNumOfPieces = 1;
      }
    }

    const pieceSizes = Array(optimalNumOfPieces)
      .fill('')
      .map((_value, i) => ({ i, size: Math.floor(length / optimalNumOfPieces) }));
    const pieces = [];

    const size = pieceSizes.reduce((a, b) => a + b.size, 0);

    const pieceSize = length / size;

    pieceSizes.forEach((x, index) => {
      const segs = [];
      let lastIndex = x.size + sampleInterval;
      // add margin back to last piece
      if (index < pieceSizes.length - 1) {
        lastIndex -= margin;
      }
      for (let i = 0; i <= lastIndex; i += sampleInterval) {
        const pt = path.getPointAtLength(i * pieceSize + cumu * pieceSize);
        segs.push([pt.x, pt.y]);
      }
      const angle = (Math.atan2(segs[1][1] - segs[0][1], segs[1][0] - segs[0][0]) * 180) / Math.PI;
      pieces.push({ segs, angle });
      cumu += x.size;
    });

    return pieces;
  },
  (args) => JSON.stringify(args)
);
