import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';

import { Overlay } from '@blueprintjs/core';
import { Button, Flex } from 'core/components';
import EnvironmentCard from './EnvironmentCard';

const width = 370;

const StyledOverlay = styled(Overlay)`
  position: absolute;
  top: 200px;
  left: ${() => `calc(50% - ${width / 2}px)`};
`;

@inject('$auth')
@observer
class EnvironmentModal extends Component {
  static defaultProps = {
    showLearnMore: true,
    asModal: false
  };

  render() {
    const { $auth } = this.props;

    return (
      <StyledOverlay hasBackdrop isOpen={$auth.isEnvironmentModalVisible} onClose={$auth.hideEnvironmentModal}>
        <EnvironmentCard width={width} borderRadius="4px" showLearnMore={false} position="relative" zIndex={30}>
          <Flex justifyContent="flex-end">
            <Button small text="Continue" fontWeight="bold" onClick={$auth.hideEnvironmentModal} />
          </Flex>
        </EnvironmentCard>
      </StyledOverlay>
    );
  }
}

export default EnvironmentModal;
