import styled, { css } from 'styled-components';
import { Card } from 'core/components';

const InterfaceMetadataCard = styled(Card)`
  ${({ selected, theme }) =>
    selected &&
    css`
      border: 1px solid ${theme.colors.primary};
      background-color: ${theme.colors.primaryBackground};
      box-shadow: none;

      path {
        fill: ${theme.colors.primary};
      }
    `}
`;

export default InterfaceMetadataCard;
