import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { isEqual } from 'lodash';
import { bytesToBitrate } from 'core/util';
import { buildFilterGroup } from 'core/util/filters';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import {
  ALL_GROUPS_CLOUD,
  ALL_GROUPS_EXCLUDING_CLOUD
} from 'app/views/hybrid/maps/components/trafficCharts/dimensionOptionsHelper';

@inject('$hybridMap', '$tabs')
@observer
export default class BoxLinkGenerator extends React.Component {
  static defaultProps = {
    boxes: {
      cloudNetworkLink: {
        orientation: 'vertical',
        from: 'cloud',
        to: 'site',
        loading: true
      },
      cloudInternetLink: {
        orientation: 'horizontal',
        from: 'cloud',
        to: 'internet',
        loading: true
      },
      internetNetworkLink: {
        orientation: 'vertical',
        from: 'internet',
        to: 'site',
        loading: true
      }
    }
  };

  constructor(props) {
    super(props);
    const { boxes } = props;

    this.state = boxes;
  }

  componentDidMount() {
    this.updateBoxLinks();
  }

  componentDidUpdate(prevProps, prevState) {
    const { cloudNetworkLink, cloudInternetLink, internetNetworkLink } = this.state;

    if (
      !isEqual(prevState.cloudNetworkLink, cloudNetworkLink) ||
      !isEqual(prevState.cloudInternetLink, cloudInternetLink) ||
      !isEqual(prevState.internetNetworkLink, internetNetworkLink)
    ) {
      this.updateBoxLinks();
    }
  }

  get hasClouds() {
    const { $hybridMap } = this.props;

    return $hybridMap.clouds.length > 0;
  }

  updateBoxLinks() {
    const { onBoxLinksUpdate } = this.props;
    const { cloudNetworkLink, cloudInternetLink, internetNetworkLink, interSiteLink } = this.state;
    let boxLinks = [internetNetworkLink, interSiteLink];

    if (this.hasClouds) {
      boxLinks = boxLinks.concat([cloudNetworkLink, cloudInternetLink]);
    }

    // filter out unsupported links
    boxLinks = boxLinks.filter((l) => !!l);

    onBoxLinksUpdate(boxLinks);
  }

  generateBitrateBreakdown(query, results) {
    let onpremToInternet = 0;
    let internetToOnprem = 0;
    let onpremToCloud = 0;
    let cloudToOnprem = 0;
    let cloudToInternet = 0;
    let internetToCloud = 0;

    for (let i = 0; i < results.size; i += 1) {
      const {
        i_src_connect_type_name,
        i_src_network_bndry_name,
        i_dst_connect_type_name,
        i_dst_network_bndry_name,
        i_trf_profile,
        agg_total_bytes
      } = results.at(i).get();

      if (
        i_dst_connect_type_name !== 'cloud_interconnect' &&
        i_dst_network_bndry_name === 'external' &&
        i_trf_profile !== 'from inside to cloud'
      ) {
        onpremToInternet += agg_total_bytes;
      }

      if (
        i_src_connect_type_name !== 'cloud_interconnect' &&
        i_src_network_bndry_name === 'external' &&
        i_trf_profile !== 'from cloud to inside'
      ) {
        internetToOnprem += agg_total_bytes;
      }

      if (i_src_connect_type_name === 'cloud_interconnect' || i_trf_profile === 'from cloud to inside') {
        cloudToOnprem += agg_total_bytes;
      }

      if (i_dst_connect_type_name === 'cloud_interconnect' || i_trf_profile === 'from inside to cloud') {
        onpremToCloud += agg_total_bytes;
      }

      if (i_trf_profile === 'from cloud to outside') {
        cloudToInternet += agg_total_bytes;
      }

      if (i_trf_profile === 'from outside to cloud') {
        internetToCloud += agg_total_bytes;
      }
    }

    return {
      onpremToInternet: bytesToBitrate(onpremToInternet, query),
      internetToOnprem: bytesToBitrate(internetToOnprem, query),
      onpremToCloud: bytesToBitrate(onpremToCloud, query),
      cloudToOnprem: bytesToBitrate(cloudToOnprem, query),
      cloudToInternet: bytesToBitrate(cloudToInternet, query),
      internetToCloud: bytesToBitrate(internetToCloud, query)
    };
  }

  handleQueryComplete = ({ query, results, dataview }) => {
    const { $hybridMap } = this.props;
    const cloudProvider = $hybridMap.clouds.map((cloud) => cloud.provider);
    const { filters, ...optionQuery } = this.optionQuery;
    const [bucket] = dataview.queryBuckets.activeBuckets;

    if (!bucket.loading) {
      const { onpremToInternet, internetToOnprem, onpremToCloud, cloudToOnprem, cloudToInternet, internetToCloud } =
        this.generateBitrateBreakdown(query, results);

      this.setState(({ cloudNetworkLink, cloudInternetLink, internetNetworkLink }) => ({
        cloudNetworkLink: {
          ...cloudNetworkLink,
          bytesIn: onpremToCloud,
          bytesOut: cloudToOnprem,
          loading: false,
          queryOptions: [
            {
              label: 'Traffic Between Cloud and On Prem',
              value: 'boxLinkTraffic',
              query: {
                inboundQuery: {
                  ...optionQuery,
                  filters: buildFilterGroup({
                    connector: 'All',
                    filterGroups: [
                      ...filters.filterGroups,
                      buildFilterGroup({
                        connector: 'Any',
                        filters: [
                          {
                            filterField: 'i_dst_connect_type_name',
                            operator: '=',
                            filterValue: 'cloud_interconnect'
                          },

                          {
                            filterField: 'i_trf_profile',
                            operator: '=',
                            filterValue: 'from inside to cloud'
                          }
                        ]
                      })
                    ]
                  }),
                  query_title: 'Traffic from On Prem to Cloud',
                  renderOptions: {
                    source: 'On Prem',
                    target: 'Cloud',
                    dimensions: { ...ALL_GROUPS_CLOUD, cloudProvider }
                  }
                },
                outboundQuery: {
                  ...optionQuery,
                  filters: buildFilterGroup({
                    connector: 'All',
                    filterGroups: [
                      ...filters.filterGroups,
                      buildFilterGroup({
                        connector: 'Any',
                        filters: [
                          {
                            filterField: 'i_src_connect_type_name',
                            operator: '=',
                            filterValue: 'cloud_interconnect'
                          },

                          {
                            filterField: 'i_trf_profile',
                            operator: '=',
                            filterValue: 'from cloud to inside'
                          }
                        ]
                      })
                    ]
                  }),
                  query_title: 'Traffic from Cloud to On Prem',
                  renderOptions: {
                    source: 'Cloud',
                    target: 'On Prem',
                    dimensions: { ...ALL_GROUPS_CLOUD, cloudProvider }
                  }
                }
              }
            }
          ]
        },
        cloudInternetLink: {
          ...cloudInternetLink,
          bytesIn: internetToCloud,
          bytesOut: cloudToInternet,
          loading: false,
          queryOptions: [
            {
              label: 'Traffic Between Cloud and Internet',
              value: 'boxLinkTraffic',
              query: {
                inboundQuery: {
                  ...optionQuery,
                  filters: buildFilterGroup({
                    connector: 'All',
                    filterGroups: [
                      ...filters.filterGroups,
                      buildFilterGroup({
                        filters: [
                          {
                            filterField: 'i_trf_profile',
                            operator: '=',
                            filterValue: 'from outside to cloud'
                          }
                        ]
                      })
                    ]
                  }),
                  query_title: 'Traffic from Internet to Cloud',
                  renderOptions: {
                    source: 'Internet',
                    target: 'Cloud',
                    dimensions: { ...ALL_GROUPS_CLOUD, cloudProvider }
                  }
                },
                outboundQuery: {
                  ...optionQuery,
                  filters: buildFilterGroup({
                    connector: 'All',
                    filterGroups: [
                      ...filters.filterGroups,
                      buildFilterGroup({
                        filters: [
                          {
                            filterField: 'i_trf_profile',
                            operator: '=',
                            filterValue: 'from cloud to outside'
                          }
                        ]
                      })
                    ]
                  }),
                  query_title: 'Traffic from Cloud to Internet',
                  renderOptions: {
                    source: 'Cloud',
                    target: 'Internet',
                    dimensions: { ...ALL_GROUPS_CLOUD, cloudProvider }
                  }
                }
              }
            }
          ]
        },
        internetNetworkLink: {
          ...internetNetworkLink,
          bytesIn: onpremToInternet,
          bytesOut: internetToOnprem,
          loading: false,
          queryOptions: [
            {
              label: 'Traffic Between Internet and On Prem',
              value: 'boxLinkTraffic',
              query: {
                inboundQuery: {
                  ...optionQuery,
                  filters: buildFilterGroup({
                    connector: 'All',
                    filterGroups: [
                      ...filters.filterGroups,
                      buildFilterGroup({
                        connector: 'All',
                        filters: [
                          {
                            filterField: 'i_dst_connect_type_name',
                            operator: '<>',
                            filterValue: 'cloud_interconnect'
                          },

                          {
                            filterField: 'i_dst_network_bndry_name',
                            operator: '=',
                            filterValue: 'external'
                          }
                        ]
                      }),

                      buildFilterGroup({
                        not: true,
                        filters: [
                          {
                            filterField: 'i_trf_profile',
                            operator: '=',
                            filterValue: 'from inside to cloud'
                          }
                        ]
                      })
                    ]
                  }),
                  query_title: 'Traffic from On Prem to Internet',
                  renderOptions: { source: 'On Prem', target: 'Internet', dimensions: ALL_GROUPS_EXCLUDING_CLOUD }
                },
                outboundQuery: {
                  ...optionQuery,
                  filters: buildFilterGroup({
                    connector: 'All',
                    filterGroups: [
                      ...filters.filterGroups,
                      buildFilterGroup({
                        connector: 'All',
                        filters: [
                          {
                            filterField: 'i_src_connect_type_name',
                            operator: '<>',
                            filterValue: 'cloud_interconnect'
                          },

                          {
                            filterField: 'i_src_network_bndry_name',
                            operator: '=',
                            filterValue: 'external'
                          }
                        ]
                      }),

                      buildFilterGroup({
                        not: true,
                        filters: [
                          {
                            filterField: 'i_trf_profile',
                            operator: '=',
                            filterValue: 'from cloud to inside'
                          }
                        ]
                      })
                    ]
                  }),
                  query_title: 'Traffic from Internet to On Prem',
                  renderOptions: { source: 'Internet', target: 'On Prem', dimensions: ALL_GROUPS_EXCLUDING_CLOUD }
                }
              }
            }
          ]
        }
      }));
    }
  };

  get query() {
    const { $hybridMap, site, device, cloudProvider } = this.props;
    return $hybridMap.getInternetMyNetworkLinkQuery({ site, device, cloudProvider });
  }

  get optionQuery() {
    const { aggregateTypes, viz_type, show_overlay, show_total_overlay, ...query } = this.query;
    return query;
  }

  render() {
    return <LightweightDataViewWrapper query={this.query} onQueryComplete={this.handleQueryComplete} />;
  }
}
