import { get, isEqual } from 'lodash';
import { getCustomProperties } from 'shared/util/map';
import { MAX_HIGHLIGHT_DEPTH } from 'app/views/hybrid/utils/cloud/constants';
import { AZURE_SUBNET_FIREWALL_NAME, AZURE_ENTITY_TYPES } from 'shared/hybrid/constants';

const { NAT_GATEWAY, LOCAL_NETWORK_GATEWAY, VNET_GATEWAY } = AZURE_ENTITY_TYPES;

/*
  Makes a consistent classname for nodes in a hybrid map

  ex: getMapClassname({ type: 'region', value: 'central-us' }) -> 'region-centralus'
*/
export function getMapClassname({ type, value }) {
  return `${type || ''}-${String(value).replace(/\W+/g, '').toLowerCase()}`;
}

/*
  Attempts to get the type of entity by first looking for 'entityType' under custom properties
  then falling back to a plain old 'type' property
*/
export function getEntityType(entity = {}) {
  // try the entityType custom property first
  const entityType = getCustomProperties(entity)?.entityType;

  if (entityType) {
    return entityType;
  }

  // if no custom kentik properties set, extract it from type
  return entity?.type?.split('/')?.pop() ?? null;
}

/* will extract type from id url string */
export function getEntityTypeById(id) {
  return id.split('/').slice(0, -1).pop();
}

/*
  Assembles a list of all cidrs tied to a vnet
*/
export function getAzureVNetCIDRs(vnet = {}) {
  return vnet.properties?.addressSpace?.addressPrefixes ?? vnet.properties?.virtualRouterIps ?? [];
}

/**
 * according to azure docs, name must be AzureFirewallSubnet
 * should also have azure firewalls stored as related entity, as we pre-populate it in node
 */
export function isAzureSubnetAFirewall(subnetEntity = {}) {
  const { name } = subnetEntity;
  const customProps = getCustomProperties(subnetEntity);

  return name === AZURE_SUBNET_FIREWALL_NAME && get(customProps, 'relatedEntities.azureFirewalls', []).length > 0;
}

/**
 * alternative to isEqual, because sometimes values can be uppercased
 */
export function isSameLinkNode(nodeA, nodeB) {
  if (nodeA.type === nodeB.type) {
    const valueA = nodeA.value === -1 ? nodeA.value : nodeA.value?.toLowerCase();
    const valueB = nodeB.value === -1 ? nodeB.value : nodeB.value?.toLowerCase();

    return valueA === valueB;
  }

  return false;
}

function getConnectedLinksToNode(searchableLinks, node) {
  return searchableLinks.filter((link) => isSameLinkNode(link.source, node) || isSameLinkNode(link.target, node));
}

/**
 * Go through every node connected and combine its links
 */
export function generateLinksForHighlightedNode(links, highlightedNode) {
  const visitedNodesIds = [];
  const resultingLinks = [];

  /** prevent overcalculations with depth check */
  const getConnectedLinksToNodeRecursive = (searchableLinks, node, direction = null, depth = 0) => {
    if ((node.value === -1 && direction !== null) || depth > MAX_HIGHLIGHT_DEPTH) {
      return;
    }

    const connectedLinks = getConnectedLinksToNode(searchableLinks, node);
    visitedNodesIds.push(node.value);

    connectedLinks.forEach((link) => {
      const { source, target } = link;

      if (resultingLinks.some((visitedLink) => isEqual(visitedLink, link))) {
        return;
      }

      if (direction !== null) {
        const reverseDirection = direction === 'source' ? 'target' : 'source';

        // our node should be a reverseDirection of the link
        if (link[reverseDirection].value !== node.value) {
          return;
        }

        // link direction should be X ---> Vpcs ---> Vpc Connection ---> Subnet
        if (direction === 'source' && node.type === 'Vpcs' && link[direction].type === 'TransitGateways') {
          return;
        }
      }

      resultingLinks.push(link);

      if (!visitedNodesIds.includes(source.value)) {
        getConnectedLinksToNodeRecursive(searchableLinks, source, 'source', depth + 1);
      }

      if (!visitedNodesIds.includes(target.value)) {
        getConnectedLinksToNodeRecursive(searchableLinks, target, 'target', depth + 1);
      }
    });
  };

  getConnectedLinksToNodeRecursive(links, highlightedNode);

  return resultingLinks;
}

export function getGatewayByName({ Entities, name }) {
  const gatewayTypes = [NAT_GATEWAY, LOCAL_NETWORK_GATEWAY, VNET_GATEWAY];

  return gatewayTypes
    .reduce((gateways, gateway) => gateways.concat(Object.values(Entities?.[gateway] || [])), [])
    .find((gatewayEntity) => gatewayEntity.name === name);
}

/*
  Checks a link for fallback nodes it can use in case the preferred link is not rendered yet
*/
export function rewriteFallbackLinkSegment({ linkSegment, mapDocument }) {
  // if we don't have any fallbacks, the deal's off
  if (!linkSegment.fallbackNodes) {
    return linkSegment;
  }

  // assemble a list of nodes we can use, ordered in preference
  const nodes = [].concat(linkSegment, linkSegment.fallbackNodes);

  // get the first rendered node we see
  const renderedNode = nodes.find((node) => mapDocument.querySelector(`.${getMapClassname(node)}`));

  // even if nothing's rendered, return the original link segment. the segment will simply not be rendered
  return renderedNode || linkSegment;
}

/*
  Checks links for a list of nodes we can use as fallbacks.
  This will be necessary when we have a node on a link that isn't rendered yet, usually because it's collapsed under a parent node in the map.
  If we don't have that preferred node rendered yet, we can check the list of fallbacks and render connectors to the first one we see.
*/
export function rewriteFallbackLinks({ links, mapDocument }) {
  // if we don't have a document to search, the deal's off
  if (!mapDocument) {
    return links;
  }

  return links.map(({ source, target, ...rest }) => ({
    source: rewriteFallbackLinkSegment({ linkSegment: source, mapDocument }),
    target: rewriteFallbackLinkSegment({ linkSegment: target, mapDocument }),
    ...rest
  }));
}
