import Model from 'core/model/Model';

export default class LoginModel extends Model {
  get defaults() {
    return {
      user_email: '',
      password: ''
    };
  }
}
