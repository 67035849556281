const urlRegex = require('url-regex-safe');
const tlds = require('tlds');
const { URL_REGEX, REGEX_HTTP_PROTOCOL } = require('@kentik/ui-shared/util/regex');

/*
  Options:

  extraTopLevelDomains: Array<string> - Additional top level domains to validate against
*/
function isUrlSingleDomain(value, options = {}) {
  try {
    const { extraTopLevelDomains = [] } = options;
    const { hostname } = new URL(value);
    const isIPv6 = hostname.startsWith('[') && hostname.endsWith(']');
    const isValidProtocolRegEx = REGEX_HTTP_PROTOCOL.test(value);
    const isValidUrlRegex = urlRegex({
      exact: !isIPv6,
      trailingPeriod: true,
      localhost: false,
      tlds: tlds.concat(extraTopLevelDomains)
    }).test(value);
    return isValidProtocolRegEx && isValidUrlRegex;
  } catch (err) {
    return false;
  }
}

function isUrl(value) {
  return value && value.match(URL_REGEX);
}

module.exports = {
  isUrlSingleDomain,
  isUrl
};
