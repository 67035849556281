import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FiUsers } from 'react-icons/fi';

import { Card, Text, Heading, Flex, ProgressBar, Box, Icon } from 'core/components';
import { zeroToText } from 'app/util/utils';

import LabelValue from './LabelValue';

@inject('$auth', '$companySettings')
@observer
class SyntheticsPlanCard extends Component {
  render() {
    const { $auth, $companySettings, ...rest } = this.props;

    const hasAdvancedMode = $auth.hasPermission('subtenancy.advancedMode');
    const numTenants = $companySettings.assetCounts?.mkpTenants;
    const maxTenants = $auth.getPermission('subtenancy.maxTenants');

    return (
      <Card p={2} {...rest}>
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Flex flex="1 1 auto" mr={3}>
            <Box width={40} mr={2}>
              <Icon icon={FiUsers} iconSize={40} color="warning" />
            </Box>

            <Box maxWidth={600} minWidth={350}>
              <Heading level={4} mb={1}>
                My Kentik Portal
              </Heading>

              <Box mt="4px" width={420}>
                {!hasAdvancedMode && (
                  <Box mb={1}>
                    <ProgressBar
                      value={numTenants / maxTenants}
                      animate={false}
                      stripes={false}
                      intent={numTenants / maxTenants > 0.9 ? 'warning' : 'primary'}
                    />
                  </Box>
                )}
                <Text fontWeight="bold">
                  {hasAdvancedMode && `${numTenants} tenants in use, unlimited tenants`}
                  {!hasAdvancedMode && (
                    <span>
                      {numTenants} of {maxTenants} tenants in use (
                      {zeroToText((numTenants / maxTenants) * 100, { fix: 1 })}%)
                    </span>
                  )}
                </Text>
              </Box>
            </Box>
          </Flex>
          <Box width={275}>
            <LabelValue label="Advanced MKP" value={hasAdvancedMode ? 'Enabled' : 'Disabled'} />
          </Box>
        </Flex>
      </Card>
    );
  }
}

export default SyntheticsPlanCard;
