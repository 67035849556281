/**
 * example:
 *    Direct Connect Gateway
 *          ↑
 *   → → → →
 * ↑
 * ↑
 * ↑   VPN Gateway
 * ↑         ↓
 *   ← ← ← ←
 */
const connectToAnyConnectionOnPrem = ({
  sourcePoint,
  targetPoint,
  regionLeft,
  ySpacing,
  targetNodeHeight,
  linkSpacing
}) => {
  const leftX = regionLeft - linkSpacing;

  const bottomY = Math.max(sourcePoint[1], targetPoint[1]) + ySpacing;
  const topY = Math.min(sourcePoint[1], targetPoint[1]) + targetNodeHeight / 2 + ySpacing;

  const connectionPoints = [
    [sourcePoint[0], bottomY],
    [leftX, bottomY],
    [leftX, topY],
    [targetPoint[0], topY]
  ];
  return {
    sourceAnchor: 'bottom',
    targetAnchor: 'bottom',
    connectionPoints
  };
};
/*
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const VpnGatewayConnector = ({ targetType, ...rest }) => {
  switch (targetType) {
    case 'Lags':
    case 'VpnConnections':
    case 'DirectConnections':
    case 'DirectConnectGateways':
      return connectToAnyConnectionOnPrem({ ...rest });
    // /** @TODO other target types */
    default:
      return null;
  }
};

export default VpnGatewayConnector;
