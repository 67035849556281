import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { observer, Provider } from 'mobx-react';
import { IconContext } from 'react-icons';

import { isDev } from 'core/util';

import 'normalize.css/normalize.css';
import 'core/components/styles/index.scss';

@observer
class Root extends Component {
  constructor(props) {
    super(props);
    console.info(`Kentik UI running in ${isDev ? 'dev' : 'prod'} mode.`);
  }

  render() {
    const { $store, component } = this.props;

    const providerProps = { $store, ...$store };
    return (
      <Provider {...providerProps}>
        <IconContext.Provider value={{ className: 'global-class-name' }}>
          <BrowserRouter>
            <Switch>
              <Route path="/" component={component} />
            </Switch>
          </BrowserRouter>
        </IconContext.Provider>
      </Provider>
    );
  }
}

export default Root;
