import { isIpValid } from 'core/util/ip';

export default {
  message: 'This field requires a comma-separated list of prefixes',
  fn: (value) => {
    let ips = value;
    if (!Array.isArray(value)) {
      ips = value.split(',');
    }

    return ips.every((ip) => {
      const isValid = isIpValid(ip);

      const hasSubnet = ip.includes(':') || ip.includes('/');

      return isValid && hasSubnet;
    });
  }
};
