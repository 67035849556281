import React, { Component } from 'react';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import { MdCloudCircle } from 'react-icons/md';

import { Card, Text, Heading, Flex, ProgressBar, Box, Icon, CalloutOutline, Popover } from 'core/components';
import { ReactComponent as FlowPakIcon } from 'app/assets/icons/flowpak-icon.svg';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import DeviceLink from 'app/components/links/DeviceLink';
import { ViewInExplorerButton } from 'app/components/dataviews/tools';
import { CLOUD_PROVIDERS } from 'app/util/constants';
import { addCommas } from 'app/util/utils';

import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import RawFpsChart from 'app/views/licenses/RawFpsChart';
import CloudProviderStrip from './CloudProviderStrip';
import LabelValue from './LabelValue';

// device_name is an array of device names
function getPlanQuery(device_name) {
  const yesterday = moment.utc().subtract(1, 'day');
  return {
    all_devices: false,
    device_name,
    show_overlay: false,
    lookback_seconds: 0,
    fastData: 'Full',
    aggregateTypes: ['max_flows_per_sec', 'max_bits_per_sec'],
    forceMinsPolling: true,
    minsPolling: 1,
    reAggInterval: 300,
    reAggFn: 'max',
    starting_time: yesterday.format('YYYY-MM-DD 00:00:00'),
    ending_time: yesterday.format('YYYY-MM-DD 23:59:59'),
    time_format: 'UTC'
  };
}

function PlanDevicesPopover({ devices }) {
  if (!devices.length) {
    return null;
  }

  return (
    <Popover minimal={false} targetTagName="span" position="left-top">
      <Text color="primary">(Show devices)</Text>
      <Box p={1} maxHeight={500} overflow="auto" minWidth={180}>
        <Text as="div" mb={1} fontWeight="bold">
          Devices in this plan:
        </Text>
        {devices.map((device) => (
          <Box key={device.id} mb="4px">
            <DeviceLink name={device.device_name} />
          </Box>
        ))}
      </Box>
    </Popover>
  );
}

@inject('$devices', '$setup', '$auth')
@observer
class PlanCard extends Component {
  renderRawPlanFps = (sampledFps) => {
    const { $setup, id, loadingPlanFps, pak_fps } = this.props;
    const rawPlanFps = $setup.planFps[id];
    if (loadingPlanFps) {
      return <Text color="muted">Loading...</Text>;
    }
    if (rawPlanFps) {
      const progressPercent = pak_fps === 0 ? 100 : (Math.ceil(rawPlanFps.max_flows_in) / pak_fps) * 100;

      return (
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <div>
              <Text fontWeight="bold">Raw: </Text>
              <Text fontWeight="bold">
                {rawPlanFps ? addCommas(Math.ceil(rawPlanFps.max_flows_in)) : 0} max flows/s
              </Text>
              {', '}
              <Text fontWeight="bold">
                {rawPlanFps ? addCommas(Math.ceil(rawPlanFps.avg_flows_in)) : 0} avg flows/s
              </Text>{' '}
              on {moment.utc().subtract(1, 'day').format('YYYY-MM-DD')}
            </div>
            {sampledFps.size > 0 && (
              <RawFpsChart
                sampledFpsTimeSeries={sampledFps?.at(0)?.get('rawData')?.flows_per_sec.flow?.slice(1)}
                rawFpsTimeSeries={rawPlanFps.timeseries}
                maxFps={pak_fps}
              />
            )}
          </Flex>

          <Text color="muted">{progressPercent.toFixed(2)}%</Text>
        </Flex>
      );
    }

    return null;
  };

  render() {
    const {
      id,
      name: planName,
      description: planDescription,
      bgp,
      device_count,
      max_devices,
      max_fps,
      free_credits,
      paid_credits,
      pak_fps,
      cloud_provider,
      fast_retention,
      full_retention,
      plan_type,
      trialDaysRemaining,
      usedSyntheticCredits,
      $devices,
      $setup,
      $auth,
      ...rest
    } = this.props;

    let name = planName;
    let bgpText = `${bgp ? 'Enabled (Full)' : 'Disabled'}`;
    let pak;
    let icon;

    const showPlanDescription = !cloud_provider;
    const isFreeTrialPlan = planName === 'Free Trial Plan';
    const isUniversalCloudPlan = plan_type === 'cloud' && cloud_provider === 'universal';
    const isCorePlan = plan_type === 'core';
    const isEdgePlan = plan_type === 'edge';
    const isCloudPlan = plan_type === 'cloud' && cloud_provider !== 'universal';
    const isFlowPak = plan_type === 'flowpak';
    const isUniversalPlan = plan_type === 'universalpak';

    // "legacy" includes more than just plan_type legacy
    const isLegacyPlan = ['core', 'legacy', 'edge'].includes(plan_type);

    const planDevices = $devices.deviceSummaries.filter((device) => device.plan_id === id);
    const planMaxFps24hQuery = getPlanQuery(planDevices.map((device) => device.device_name));

    if (isCloudPlan) {
      const { name: cloudName, logo: cloudLogo } = CLOUD_PROVIDERS.byId(cloud_provider);
      name = cloudName;
      icon = React.cloneElement(cloudLogo, { width: 40, height: 40 });
      pak = 'CloudPak';
    }

    if (isUniversalCloudPlan) {
      icon = <Icon icon={MdCloudCircle} iconSize={40} color="primary" />;
      pak = 'CloudPak';
      name = 'Universal';
    }

    if (isFlowPak) {
      icon = <FlowPakIcon style={{ width: 40, height: 40 }} />;
      pak = 'FlowPak';
      bgpText = `${bgp ? 'Enabled (Partial)' : 'Disabled'}`;
    }

    if (plan_type === 'legacy') {
      icon = <Icon icon="cube" iconSize={40} color="warning" />;
    }

    if (isCorePlan) {
      icon = <Icon icon="cog" iconSize={40} color="warning" />;
      pak = 'Core';
      bgpText = `${bgp ? 'Enabled (Partial)' : 'Disabled'}`;
    }

    if (isEdgePlan) {
      icon = <Icon icon="cube" iconSize={40} color="primary" />;
      pak = 'Edge';
    }

    if (isUniversalPlan) {
      icon = <Icon icon="globe-network" iconSize={40} color="primary" />;
      pak = 'UniversalPak';
    }

    return (
      <Card p={2} {...rest}>
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Flex flex="1 1 auto" mr={3}>
            {icon && (
              <Box width={40} mr={2}>
                {icon}
              </Box>
            )}

            <Box maxWidth={600} minWidth={350}>
              {pak && (
                <Text color="muted" as="div" mb="4px">
                  {pak}
                </Text>
              )}

              <Heading level={4} mb={1}>
                {name}{' '}
                <Text fontWeight={200} muted>
                  ({id})
                </Text>
              </Heading>

              {(isUniversalCloudPlan || isUniversalPlan) && (
                <CloudProviderStrip
                  numAzureDevices={$devices.getCloudDeviceCount('azure', id)}
                  numGcpDevices={$devices.getCloudDeviceCount('gce', id)}
                  numAwsDevices={$devices.getCloudDeviceCount('aws', id)}
                />
              )}

              {showPlanDescription && (
                <Text color="muted" as="div" mb={1}>
                  {planDescription}
                </Text>
              )}

              {/* hide no devices count if Universal, because there's already a display that indicates this per cloud provider */}
              {device_count === 0 && !isUniversalCloudPlan && (
                <Text color="muted">No devices have been added to this plan.</Text>
              )}

              {device_count > 0 && !isLegacyPlan && (
                <>
                  <LightweightDataViewWrapper query={planMaxFps24hQuery}>
                    {({ loading, results }) => {
                      let progressIntent = 'none';
                      let progressPercent = 0;

                      const maxFps =
                        isFlowPak || isCloudPlan || isUniversalCloudPlan || isUniversalPlan ? pak_fps : max_fps;

                      const total = results.models.find((item) => item.get('name') === 'Total');
                      const progressValue = loading || !total ? 1 : Math.ceil(total.get('max_flows_per_sec')) / maxFps;

                      if (total) {
                        progressIntent = Math.ceil(total.get('max_flows_per_sec')) > maxFps ? 'danger' : 'primary';
                        progressPercent =
                          maxFps === 0 ? 100 : (Math.ceil(total.get('max_flows_per_sec')) / maxFps) * 100;
                      }

                      return (
                        <Box mt="4px" width={520}>
                          <Box mb={1}>
                            <ProgressBar
                              value={progressValue}
                              animate={loading}
                              stripes={loading}
                              intent={progressIntent}
                            />
                          </Box>
                          <Flex justifyContent="space-between" alignItems="center">
                            {loading ? (
                              <Text color="muted">Loading...</Text>
                            ) : (
                              <>
                                <Flex alignItems="center">
                                  <div>
                                    <Text fontWeight="bold">Sampled: </Text>
                                    <Text fontWeight="bold">
                                      {total ? addCommas(Math.ceil(total.get('max_flows_per_sec'))) : 0} max flows/s
                                    </Text>{' '}
                                    on {moment.utc().subtract(1, 'day').format('YYYY-MM-DD')}
                                  </div>
                                  <ViewInExplorerButton
                                    query={planMaxFps24hQuery}
                                    minimal
                                    small
                                    openInNewWindow
                                    text={null}
                                    ml="4px"
                                  />
                                </Flex>
                                <Text color="muted">{progressPercent.toFixed(2)}%</Text>
                              </>
                            )}
                          </Flex>
                          {($auth.hasSudo || $auth.isSpoofed) && this.renderRawPlanFps(results)}
                        </Box>
                      );
                    }}
                  </LightweightDataViewWrapper>
                </>
              )}

              {isLegacyPlan && (
                <Box mt="4px" width={420}>
                  <Box mb={1}>
                    <ProgressBar animate={false} stripes={false} value={device_count / max_devices} intent="primary" />
                  </Box>
                  <Text fontWeight="bold">{device_count}</Text> of{' '}
                  <Text color="muted" textAlign="right">
                    {max_devices} devices
                  </Text>{' '}
                  <PlanDevicesPopover devices={planDevices} />
                </Box>
              )}

              {isFreeTrialPlan && (
                <CalloutOutline intent="warning" fontWeight="bold" maxWidth={330} mt={1}>
                  <Icon icon={BsFillExclamationTriangleFill} color="warning" mr="4px" />
                  You have {trialDaysRemaining} days remaining in your trial.
                </CalloutOutline>
              )}
            </Box>
          </Flex>
          <Box width={275}>
            {/* CloudPaks use a new `pak_fps` to indicate "sold" FPS */}
            <LabelValue
              label="Max FPS"
              value={
                isFlowPak || isCloudPlan || isUniversalCloudPlan || isUniversalPlan
                  ? addCommas(parseInt(pak_fps))
                  : addCommas(max_fps)
              }
            />
            {!isLegacyPlan && (
              <LabelValue
                label={isCloudPlan || isUniversalCloudPlan ? 'Data Sources' : 'Devices'}
                value={
                  <>
                    <Box as="span" pr="4px">
                      {device_count}
                      {/* Cloud (universal or paks) have no device limit */}
                      {(!isCloudPlan || !isUniversalCloudPlan) && <> of {max_devices}</>}
                    </Box>
                    {device_count > 0 && (
                      <Popover minimal={false} targetTagName="span" position="left-top">
                        <Text color="primary">(Show)</Text>
                        <Box p={1} maxHeight={500} overflow="auto" minWidth={180}>
                          <Text as="div" mb={1} fontWeight="bold">
                            Devices in this plan:
                          </Text>
                          {planDevices.map((device) => (
                            <Box key={device.id} mb="4px">
                              <DeviceLink name={device.device_name} />
                            </Box>
                          ))}
                        </Box>
                      </Popover>
                    )}
                  </>
                }
              />
            )}
            <LabelValue label="BGP" value={bgpText} />
            <LabelValue label="Fast Retention" value={`${fast_retention} days`} />
            <LabelValue label="Full Retention" value={`${full_retention} days`} mb={0} />
          </Box>
        </Flex>
      </Card>
    );
  }
}

export default PlanCard;
