import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { GiRadarSweep } from 'react-icons/gi';

import { Box, EmptyState, Button, Text, Flex, Heading, Icon, LinkButton, Callout } from 'core/components';
import LargeLabeledValue from 'app/components/LargeLabeledValue';

import { getEntityName, baseTestConfig, getTestsFromPaths } from 'app/views/cloudPerformance/cloudPerformanceUtil';
import NodeGroup from 'app/views/cloudPerformance/nodes/NodeGroup';

@inject('$hybridMap', '$syn', '$dataviews')
@observer
export default class Performance extends React.Component {
  static defaultProps = {
    agentId: null,
    testId: null,
    target: null,
    showMesh: false
  };

  state = {
    testId: undefined,
    agentId: undefined,
    selectedPath: undefined,
    test: undefined
  };

  componentDidMount() {
    const { paths, testId, agentId, $syn } = this.props;

    if (paths && Array.isArray(paths) && paths.length) {
      this.onPathClick(paths[0]);
    } else {
      const testModel = $syn.tests.get(testId);
      if (testModel) {
        this.setState({
          test: testModel,
          testId,
          agentId
        });
      }
    }
  }

  handleMouseOut = () => {
    const { $dataviews } = this.props;
    $dataviews.renderedDataviews.forEach(({ chart }) => {
      if (chart.tooltip) {
        chart.tooltip.hide();
      }
    });
  };

  onCreateVpcMeshTestClick = () => {
    const { $syn, vpcs } = this.props;
    const [vpc1, vpc2] = vpcs;
    const newTest = {
      test_type: 'application-mesh',
      config: {
        ...baseTestConfig,
        name: `${vpc1.vpc_id} to ${vpc2.vpc_id} Test (Network Mesh)`,
        agents: [vpc1.agent.id, vpc2.agent.id],
        useLocalIp: true
      }
    };

    const testModel = $syn.tests.forge(newTest);
    testModel.save().then(() => {
      this.setState({ test: testModel, testId: testModel.id, agentId: vpc1.agent.id });
    });
  };

  onCreateVpcIpTestClick = () => {
    const { $syn } = this.props;
    const { selectedPath } = this.state;

    const [firstNode] = selectedPath;
    const target = selectedPath[selectedPath.length - 1];
    const { agent } = firstNode;

    const newTest = {
      test_type: 'ip-address',
      config: {
        ...baseTestConfig,
        name: `${agent.metadata.cloud_vpc} to ${getEntityName(target)} Test (IP Address)`,
        agents: [agent.id],
        target: { value: target.type === 'CustomerGateway' ? target.IpAddress : target.targetIps.join(',') }
      }
    };
    const testModel = $syn.tests.forge(newTest);
    testModel.save().then(() => {
      this.setState({ test: testModel, testId: testModel.id, agentId: agent.id });
    });
  };

  renderDeployAgent = () => {
    const { vpcs, paths, showMesh } = this.props;
    const { selectedPath } = this.state;

    const missingAgents = vpcs.filter((vpc) => !vpc.agent).map((vpc) => vpc.vpc_id);

    let description = (
      <Text>
        Deploy a ksynth agent to <Text fontWeight="heavy">{missingAgents[0]}</Text>
        {missingAgents.length > 1 &&
          missingAgents.slice(1).map((missingAgent) => (
            <Text key={missingAgent}>
              , <Text fontWeight="heavy">{missingAgent}</Text>
            </Text>
          ))}{' '}
        in order to monitor your cloud performance.
      </Text>
    );

    if (selectedPath) {
      description = (
        <Text>
          Deploy a ksynth agent to <Text fontWeight="heavy">{selectedPath[0].VpcId}</Text> in order to monitor your
          cloud performance.
        </Text>
      );
    }
    if ((paths && selectedPath) || !paths || showMesh) {
      return <EmptyState description={description} />;
    }

    return null;
  };

  renderCreateTest = () => {
    const { vpcs, showMesh } = this.props;
    const { selectedPath, agentId } = this.state;
    if (showMesh) {
      const description = (
        <Text>
          A Network Mesh test needs to exist between <Text fontWeight="heavy">{vpcs[0].vpc_id}</Text> and{' '}
          <Text fontWeight="heavy">{vpcs[1].vpc_id}</Text>
        </Text>
      );

      return (
        <EmptyState
          description={description}
          action={
            <Button intent="primary" onClick={this.onCreateVpcMeshTestClick}>
              Create a Network Mesh Test
            </Button>
          }
        />
      );
    }

    if (
      (selectedPath && selectedPath[selectedPath.length - 1].type === 'CustomerGateway') ||
      (selectedPath && selectedPath[selectedPath.length - 1].type === 'Router') ||
      (!selectedPath && agentId)
    ) {
      const description = (
        <Text>
          An IP Address test needs to exist between <Text fontWeight="heavy">{vpcs[0].vpc_id}</Text> and{' '}
          <Text fontWeight="heavy">{getEntityName(selectedPath[selectedPath.length - 1])}</Text>
        </Text>
      );

      return (
        <EmptyState
          description={description}
          action={
            <Button intent="primary" onClick={this.onCreateVpcIpTestClick}>
              Create an IP Address Test
            </Button>
          }
        />
      );
    }

    return null;
  };

  renderPathSummaries = (paths) => {
    if (paths && paths.length > 0) {
      const pathMap = paths.reduce((acc, path) => {
        const key = `${path[0].id}|${path[path.length - 1].id}`;
        acc[key] = path;
        return acc;
      }, {});

      return (
        <Callout mb={2}>
          <Text as="div" small mb={1} muted>
            Select a path across this node/link
          </Text>
          {Object.values(pathMap).map((path) => {
            const id = path.reduce((acc, segment) => `${acc}-${segment.id}`, '');

            return (
              <Button
                key={id}
                small
                minimal
                text={<NodeGroup nodes={[path[0], path[path.length - 1]]} mini />}
                onClick={() => this.onPathClick(path)}
              />
            );
          })}
        </Callout>
      );
    }

    return null;
  };

  onPathClick = (path) => {
    const { $syn } = this.props;
    const tests = getTestsFromPaths([path]);
    if (tests && tests.length) {
      const [test] = tests;

      if (test?.id) {
        const testModel = $syn.tests.get(test.id);
        this.setState({
          test: testModel,
          testId: test.id,
          agentId: test.config.agents[0],
          selectedPath: path
        });
      }
    } else {
      this.setState({ test: undefined, testId: undefined, agentId: undefined, selectedPath: path });
    }
  };

  render() {
    const { testId, test, selectedPath } = this.state;
    const { vpcs, showMesh, paths } = this.props;
    let title = null;

    if (selectedPath && selectedPath.length >= 2) {
      title = (
        <Flex alignItems="center" mb={2}>
          <Heading level={6} mb={0}>
            {getEntityName(selectedPath[0])}
          </Heading>
          <Icon icon="arrow-right" color="muted" mx={1} />
          <Heading level={6} mb={0}>
            {getEntityName(selectedPath[selectedPath.length - 1])}
          </Heading>
        </Flex>
      );
    }

    const srcVpcNode = selectedPath ? selectedPath[0] : vpcs[0];

    return (
      <Box p={1} onMouseOut={this.handleMouseOut}>
        {paths && paths.length > 1 && this.renderPathSummaries(paths)}
        {title}
        {vpcs[showMesh ? 'some' : 'every']((vpc) => !vpc.agent) && !testId && this.renderDeployAgent()}
        {vpcs[showMesh ? 'every' : 'some']((vpc) => vpc.agent) && !testId && this.renderCreateTest()}
        {testId && (
          <Box>
            <Flex mb={2}>
              <LargeLabeledValue label="Agent" valueElement={srcVpcNode.agent.agent_alias} />
              <LargeLabeledValue label="Test" valueElement={test?.get('config.name')} />
            </Flex>

            <Flex mt={2}>
              <LinkButton to={`/v4/synthetics/tests/${testId}/results`} icon={GiRadarSweep} mr={2}>
                View Test Results
              </LinkButton>
              <LinkButton to={`/v4/synthetics/tests/${testId}`} icon="cog">
                Configure Test
              </LinkButton>
            </Flex>
          </Box>
        )}
      </Box>
    );
  }
}
