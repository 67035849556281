import * as React from 'react';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { Box, Flex, Text, Heading } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';

const { CORE_NETWORK_EDGE } = ENTITY_TYPES.get('aws');

const CoreNetworkEdgeSummary = (config) => {
  const { nodeData } = config;

  return (
    <>
      <Box>
        <Flex borderBottom="thin" p={2} alignItems="center">
          <CloudIcon cloudProvider="aws" entity={CORE_NETWORK_EDGE} width={32} height={32} />
          <Box flex={1} ml={1}>
            <Text as="div" muted>
              Core Network Edge
            </Text>
            <Heading mb={0} level={4}>
              {nodeData.EdgeLocation}
            </Heading>
          </Box>
        </Flex>
        {nodeData && (
          <SidebarItem title="Details" icon="more" popoutTitle={CoreNetworkEdgeSummary.PopoutTitle(config)} openOnMount>
            <CloudMetadata node={{ ...nodeData }} keys={['Asn', 'EdgeLocation', 'InsideCidrBlocks', 'CoreNetworkId']} />
          </SidebarItem>
        )}
      </Box>
    </>
  );
};

CoreNetworkEdgeSummary.PopoutTitle = () => (
  <Flex alignItems="center">
    <CloudIcon cloudProvider="aws" entity={CORE_NETWORK_EDGE} width={17} height={17} />
    <Box ml={1}>Core Network Edge</Box>
  </Flex>
);

export default CoreNetworkEdgeSummary;
