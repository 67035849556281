import React from 'react';
import { createUltimatePagination, ITEM_TYPES } from 'react-ultimate-pagination';
import Button from './Button';
import ButtonGroup from './ButtonGroup';

const Page = ({ value, isActive, isDisabled, onClick }) => (
  <Button active={isActive} onClick={onClick} disabled={isDisabled}>
    {value}
  </Button>
);

const Ellipsis = ({ isDisabled, onClick }) => (
  <Button onClick={onClick} disabled={isDisabled}>
    ...
  </Button>
);

const FirstPageLink = ({ isDisabled, onClick }) => (
  <Button onClick={onClick} disabled={isDisabled} icon="chevron-backward" />
);

const PreviousPageLink = ({ isDisabled, onClick }) => (
  <Button onClick={onClick} disabled={isDisabled} icon="chevron-left" />
);

const NextPageLink = ({ isDisabled, onClick }) => (
  <Button onClick={onClick} disabled={isDisabled} icon="chevron-right" />
);

const LastPageLink = ({ isDisabled, onClick }) => (
  <Button onClick={onClick} disabled={isDisabled} icon="chevron-forward" />
);

const WrapperComponent = ({ children }) => <ButtonGroup className="pagination">{children}</ButtonGroup>;

const Paginator = createUltimatePagination({
  itemTypeToComponent: {
    [ITEM_TYPES.PAGE]: Page,
    [ITEM_TYPES.ELLIPSIS]: Ellipsis,
    [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPageLink,
    [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
    [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
    [ITEM_TYPES.LAST_PAGE_LINK]: LastPageLink
  },
  WrapperComponent
});

export default Paginator;
