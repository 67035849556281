const { HOSTNAME_REGEX } = require('@kentik/ui-shared/util/regex');

const isCommaSeparatedHostnames = (value) => {
  if (Array.isArray(value)) {
    value = value.join(',');
  }

  return typeof value === 'string' && (value === '' || value.split(',').every((v) => v.trim().match(HOSTNAME_REGEX)));
};

module.exports = {
  fn: isCommaSeparatedHostnames,
  HOSTNAME_REGEX,
  message: 'This field requires one or more valid hostnames',
  isCommaSeparatedHostnames
};
