import * as React from 'react';
import { observer } from 'mobx-react';
import { escapeRegExp, orderBy } from 'lodash';
import { Classes } from '@blueprintjs/core';
import { Collection } from 'core/model';
import { Box, Button, Callout, Card, Dialog, Flex, Grid, Text } from 'core/components';
import { InputGroup } from 'core/form';
import ServiceList from './ServiceList';

@observer
export default class SelectServices extends React.Component {
  static defaultProps = {
    selectedServices: []
  };

  state = {
    allServicesSelected: false,
    dialogOpen: false,
    searchTerm: ''
  };

  collection = new Collection();

  componentDidUpdate(prevProps, prevState) {
    const { collection, selectedServices } = this.props;
    const { dialogOpen } = this.state;

    if (prevProps.collection !== collection) {
      const data = collection.serviceSelections.map(({ value, traffic }) => ({
        selected: selectedServices.includes(value),
        matchesFilter: true,
        value,
        traffic
      }));

      this.collection.processData(data);
    }

    if (prevState.dialogOpen !== dialogOpen && dialogOpen) {
      this.initSelectedServices();
    }
  }

  initSelectedServices = () => {
    const { selectedServices } = this.props;

    this.setState({ allServicesSelected: false }, () => {
      this.collection.each((model) => model.set('selected', selectedServices.includes(model.get('value'))));

      if (this.availableServices.length === 0) {
        this.toggleAllServices(true);
      }
    });
  };

  toggleDialog = () => this.setState(({ dialogOpen }) => ({ dialogOpen: !dialogOpen }));

  get availableServices() {
    const { searchTerm } = this.state;
    const regex = new RegExp(escapeRegExp(searchTerm), 'i');

    return orderBy(
      this.collection.models
        .filter((model) => !model.get('selected'))
        .map((model) => model.set('matchesFilter', !searchTerm || regex.test(model.get('value')))),
      [(model) => (model.get('matchesFilter') ? 0 : 1), (model) => model.get('traffic')],
      ['asc', 'desc']
    );
  }

  get selectedServices() {
    return this.collection.models.filter((model) => model.get('selected'));
  }

  handleItemClick = (model) => {
    model.set('selected', !model.get('selected'));
  };

  handleSearch = (e) => this.setState({ searchTerm: e.target.value });

  handleSave = () => {
    const { onServiceSelectionChange } = this.props;
    const { allServicesSelected } = this.state;

    if (onServiceSelectionChange) {
      onServiceSelectionChange(
        this[allServicesSelected ? 'availableServices' : 'selectedServices'].map((model) => model.get('value'))
      );
      this.toggleDialog();
    }
  };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    const sortedServices = [...this.selectedServices];
    // reorder the selected services
    sortedServices.splice(newIndex, 0, sortedServices.splice(oldIndex, 1)[0]);

    // set the collection with the newly sorted selected services and the remaining available services tacked on the end
    this.collection.set(sortedServices.concat(this.availableServices));
  };

  toggleAllServices = (selected) =>
    this.setState({ allServicesSelected: selected }, () => {
      const { allServicesSelected } = this.state;

      if (allServicesSelected) {
        this.collection.each((model) => model.set('selected', false));
      }
    });

  renderSelectedServices = () => {
    const { selectedServices } = this;
    const { allServicesSelected } = this.state;

    if (allServicesSelected) {
      return (
        <Card display="flex" p="4px" pl={1} alignItems="center" justifyContent="space-between">
          <Text>All services</Text>
          <Button icon="small-cross" onClick={() => this.toggleAllServices(false)} minimal />
        </Card>
      );
    }

    if (selectedServices.length > 0) {
      return (
        <ServiceList
          serviceModels={this.selectedServices}
          allServicesSelected={allServicesSelected}
          onItemClick={this.handleItemClick}
          lockAxis="y"
          useDragHandle
          onSortEnd={this.handleSortEnd}
          sortable
        />
      );
    }

    return (
      <Callout intent="danger" title="No services selected">
        Select at least one item from the left pane.
      </Callout>
    );
  };

  render() {
    const { collection, selectedServices, onServiceSelectionChange, disabled, ...boxProps } = this.props;
    const { allServicesSelected, dialogOpen, searchTerm } = this.state;
    const hasSelection = allServicesSelected || this.selectedServices.length > 0;

    return (
      <Box {...boxProps}>
        <Button icon="cog" onClick={this.toggleDialog} disabled={disabled} minimal />
        {dialogOpen && (
          <Dialog
            pb={0}
            isOpen={dialogOpen}
            onClose={this.toggleDialog}
            width={700}
            height={400}
            title="Configure AWS Service Monitoring"
          >
            <Dialog.Body p={0} display="flex">
              <Grid gridTemplateColumns="1fr 1fr" gridColumnGap={0} flex={1}>
                <Box p={1} pt={0} borderRight="thin" overflow="auto">
                  <Box position="sticky" top={0} bg="dialogBackground" pt={1}>
                    <Text as="div" muted>
                      Available Services
                    </Text>

                    <Box pt="4px" pb={1}>
                      <InputGroup
                        leftIcon="search"
                        placeholder="Search"
                        onChange={this.handleSearch}
                        className={Classes.ROUND}
                        value={searchTerm}
                        rightElement={
                          searchTerm ? (
                            <Button icon="cross" onClick={() => this.setState({ searchTerm: '' })} minimal />
                          ) : null
                        }
                        small
                      />
                    </Box>

                    <Box>
                      <Button
                        textAlign="left"
                        icon={allServicesSelected ? 'tick' : 'multi-select'}
                        text="All Services"
                        onClick={() => this.toggleAllServices(!allServicesSelected)}
                        minimal
                        fill
                      />
                    </Box>
                  </Box>

                  <ServiceList
                    serviceModels={this.availableServices}
                    allServicesSelected={allServicesSelected}
                    onItemClick={this.handleItemClick}
                  />
                </Box>

                <Flex p={1} flexDirection="column" justifyContent="space-between" overflow="hidden">
                  <Flex flexDirection="column" flex={1} overflow="auto">
                    <Box position="sticky" top={0} pb={1} bg="dialogBackground">
                      <Text muted>Selected Services</Text>
                    </Box>
                    <Box flex={1}>{this.renderSelectedServices()}</Box>
                  </Flex>
                  <Box p={1} alignSelf="flex-end">
                    <Button mr={1} width={75} text="Cancel" onClick={this.toggleDialog} />
                    <Button
                      width={75}
                      onClick={this.handleSave}
                      text="Save"
                      intent="primary"
                      disabled={!hasSelection}
                    />
                  </Box>
                </Flex>
              </Grid>
            </Dialog.Body>
          </Dialog>
        )}
      </Box>
    );
  }
}
