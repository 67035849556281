import React from 'react';
import { Flex, Tag, Text, Grid } from 'core/components';
import { RESULT_TYPE_MAP } from './results/ResultTypeMap';

const Category = ({ category, minimal, title, description }) => {
  if (minimal) {
    return (
      <Flex gap={1} alignItems="center">
        <Tag minimal intent="primary">
          {title}
        </Tag>
        <Text color="#ffffff" small>
          {description}
        </Text>
      </Flex>
    );
  }

  const { Icon } = RESULT_TYPE_MAP[category];
  return (
    <>
      <Tag fontWeight="bold" bg="#343638" color="#ffffff">
        <Icon size={16} mr={1} />
        {title}
      </Tag>
      <Text color="#ffffff" pl={2}>
        {description}
      </Text>
    </>
  );
};

export function SearchSuggestions({ minimal, ...rest }) {
  return (
    <Flex mt={minimal ? 1 : 5} flexDirection="column" alignItems="center" {...rest}>
      <Flex mb={minimal ? 0 : 2}>
        <Text fontSize={minimal ? 16 : 24} mb={2} lineHeight="26px">
          Try searching for...
        </Text>
      </Flex>

      <Grid gridTemplateColumns={minimal ? 'auto' : '26% 100%'} gridGap={0}>
        <Category category="asn" minimal={minimal} title="ASNs" description="Jump directly to any ASN's details" />
        <Category
          category="capacityPlan"
          minimal={minimal}
          title="Capacity Plans"
          description="Search by Name or Description"
        />
        <Category category="cdn" minimal={minimal} title="CDNs" description="Search from our 50+ library of CDNs" />
        <Category
          category="costGroup"
          minimal={minimal}
          title="Cost Groups"
          description="Search by Cost Group (Connectivity Costs workflow)"
        />
        <Category
          category="provider"
          minimal={minimal}
          title="Cost Providers"
          description="Search by Cost Provider (Connectivity Costs workflow)"
        />
        <Category
          category="dashboard"
          minimal={minimal}
          title="Dashboards"
          description="Search by Name or Description"
        />
        <Category
          category="device"
          minimal={minimal}
          title="Devices"
          description="Search by Name, Site, IP Address, or SNMP details"
        />
        <Category
          category="interface"
          minimal={minimal}
          title="Interfaces"
          description="Search by Alias, Description, or IP Address"
        />
        <Category
          category="ip"
          minimal={minimal}
          title="IP Addresses"
          description="IPv4 and IPv6 formats are supported"
        />
        <Category category="journey" minimal={minimal} title="Journeys" description="Search to start a new Journey" />
        <Category
          category="ottService"
          minimal={minimal}
          title="OTT Services"
          description="Search OTT services by Service or Provider Name"
        />
        <Category
          category="savedView"
          minimal={minimal}
          title="Saved Views"
          description="Search by Name or Description"
        />
        <Category category="site" minimal={minimal} title="Sites" description="Search by Name or Geographic Location" />
        <Category
          category="syntheticTest"
          minimal={minimal}
          title="Synthetic Tests"
          description="Search by Test Name or Test Target"
        />
      </Grid>
    </Flex>
  );
}
