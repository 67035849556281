import React, { useState } from 'react';
import { uniqWith as _uniqWith, isEqual as _isEqual, groupBy as _groupBy } from 'lodash';
import { inject } from 'mobx-react';
import { Box, Flex, Heading, Tag, EmptyState } from 'core/components';
import { FaCheck } from 'react-icons/fa';
import { GCP_ENTITY_TYPES } from 'shared/hybrid/constants';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { greekDisplay } from '../cloudUtil';
import SidebarItem from '../SidebarItem';
import NatGatewayMappingDetails from './NatGatewayComponents/NatGatewayMappingDetails';

const { NAT_GATEWAY } = GCP_ENTITY_TYPES;

const isArrayOfStringValues = (arr) => Array.isArray(arr) && arr.length && arr.every((i) => typeof i === 'string');
const isArrayOfObjects = (arr) => Array.isArray(arr) && arr.length && arr.every((i) => typeof i === 'object');

function getObjectData(obj, label, rowData, prevKey) {
  for (const [key, value] of Object.entries(obj)) {
    if (Object.keys(value).length || typeof value === 'number') {
      const sublabel = key;
      if (typeof value === 'string' || typeof value === 'number') {
        rowData.push({ label, sublabel, values: value });
      } else if (isArrayOfStringValues(value)) {
        rowData.push({ label, sublabel, values: value.join() });
      } else if (isArrayOfObjects(value)) {
        getObjectData(value, label, rowData, sublabel);
      } else if (typeof value === 'object') {
        getObjectData(value, prevKey, rowData);
      }
    }
  }
}

function collectData({ nodeData }) {
  const rowData = [];

  const { drainNatIps, natInfo, natIps, rules, status } = nodeData;
  const { natIpInfoMappings, interfaceNatMappings } = natInfo;

  const {
    autoAllocatedNatIps,
    drainAutoAllocatedNatIps,
    drainUserAllocatedNatIps,
    ruleStatus,
    userAllocatedNatIpResources,
    userAllocatedNatIps
  } = status;
  const arrayData = {
    natIpInfoMappings,
    interfaceNatMappings,
    drainNatIps,
    natIps,
    rules,
    autoAllocatedNatIps,
    drainAutoAllocatedNatIps,
    drainUserAllocatedNatIps,
    ruleStatus,
    userAllocatedNatIpResources,
    userAllocatedNatIps
  };
  const mappedData = new Map(Object.entries(arrayData));

  mappedData.forEach((mappedValue, mappedKey) => {
    if (Array.isArray(mappedValue) && mappedValue.length) {
      const label = mappedKey;
      let sublabel;
      let innerValues;
      for (const singleValue of mappedValue) {
        if (typeof singleValue === 'string' || typeof singleValue === 'number') {
          innerValues = singleValue;
          sublabel = '-';
          rowData.push({ label, sublabel, values: innerValues });
        }
        if (isArrayOfStringValues(singleValue)) {
          innerValues = singleValue.join();
          sublabel = '-';
        }
        if (typeof singleValue === 'object') {
          // rowData array is still in scope when getObjectData is called
          // so even though we don't do anything with return value, rowData still gets populated
          getObjectData(singleValue, label, rowData);
        }
      }
    }
  });
  return _uniqWith(rowData, _isEqual);
}

const NatGatewayMappings = ({ data, isPoppedOut }) => {
  const grouped = _groupBy(data, 'label');
  const components = [];

  for (const [key, value] of Object.entries(grouped)) {
    const label = key ?? 'Misc';
    components.push(
      <Box key={key}>
        <NatGatewayMappingDetails data={value} label={label} isPoppedOut={isPoppedOut} />
      </Box>
    );
  }

  if (components.length) {
    return components;
  }

  return null;
};

const NatGatewaysPopover = (props) => {
  const [isPoppedOut, setIsPoppedOut] = useState(false);

  const handlePopOutChange = (prevPopped) => {
    setIsPoppedOut(!!prevPopped);
  };

  const { width, popoutTitle, $hybridMap, $auth, ...rest } = props;
  const { nodeData } = rest;
  const data = collectData({ nodeData });

  return (
    <SidebarItem
      excludeFormProps
      title={
        <Flex alignItems="center" gap={1} width="100%">
          <Heading level={5} mb={0}>
            NAT Gateway Mappings
          </Heading>
          {data.length > 0 && (
            <Tag
              intent="success"
              icon={FaCheck}
              title={`${data.length} Nat Gateway Mappings`}
              minimal
              round
              flexGrow={1}
            >
              {greekDisplay(data.length)}
            </Tag>
          )}
        </Flex>
      }
      dialogProps={{ width: width * 1.5 }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="shield"
      popoutTitle={popoutTitle}
      onPopOutChange={handlePopOutChange}
    >
      {data.length > 0 ? (
        <NatGatewayMappings data={data} isPoppedOut={isPoppedOut} />
      ) : (
        <Box>
          <EmptyState title="No Results" icon={<CloudIcon cloudProvider="gcp" entity={NAT_GATEWAY} iconSize={48} />} />
        </Box>
      )}
    </SidebarItem>
  );
};

export default inject('$hybridMap')(NatGatewaysPopover);
