import React from 'react';
import { inject } from 'mobx-react';
import { FaCheck } from 'react-icons/fa';

import { Flex, Heading, Tag } from 'core/components';

import SidebarItem from '../SidebarItem';
import { greekDisplay } from '../cloudUtil';
import RouteTable from './components/RouteTable';

const OciRouteTablePopover = ({ width, popoutTitle, $hybridMap, ...rest }) => {
  const { nodeData } = rest;
  const { ociCloudMapCollection } = $hybridMap;

  const routeTables = nodeData?.route_tables ?? (nodeData?.routeTableId ? [{ id: nodeData.routeTableId }] : null) ?? [];
  if (routeTables.length === 0) {
    return null;
  }

  const grouppedRouteRules = routeTables
    .map(({ id }) => {
      const routeTable = ociCloudMapCollection.findEntityById(id);
      const { routeRules = [], displayName } = routeTable;

      return routeRules.map((rule) => ({
        ...rule,
        routeTable: displayName
      }));
    })
    .flat();

  return (
    <SidebarItem
      excludeFormProps
      title={
        <Flex alignItems="center" gap={1} width="100%">
          <Heading level={5} mb={0}>
            Route Table
          </Heading>
          <Tag
            intent="success"
            icon={FaCheck}
            title={`${routeTables.length} active route tables`}
            minimal
            round
            flexGrow={1}
          >
            {greekDisplay(routeTables.length)}
          </Tag>
        </Flex>
      }
      popoutTitle={popoutTitle}
      icon="route"
      dialogProps={{ width: `${width}px` }}
    >
      <RouteTable routeTableRules={grouppedRouteRules} />
    </SidebarItem>
  );
};

export default inject('$hybridMap')(OciRouteTablePopover);
