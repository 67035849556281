import React from 'react';

import { inject } from 'mobx-react';
import { Flex, Text } from 'core/components';

import SidebarItem from '../SidebarItem';
import {
  AzureVpnConnectionDetails,
  AzureExpressRouteConnectionDetails,
  AzureP2SConnectionConfigurationDetails
} from './components';

const AzureConnectionsPopover = ({ width, popoutTitle, $hybridMap, nodeData }) => {
  const { azureCloudMapCollection } = $hybridMap;

  const connections = azureCloudMapCollection.getEntityProperty(nodeData, 'connections') ?? [];
  const expressRouteConnections = azureCloudMapCollection.getEntityProperty(nodeData, 'expressRouteConnections') ?? [];
  const p2SConnections = azureCloudMapCollection.getEntityProperty(nodeData, 'p2SConnectionConfigurations') ?? [];

  // current node doesnt have connections
  if (connections.length === 0 && expressRouteConnections.length === 0 && p2SConnections.length === 0) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title="Connections"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="data-connection"
      popoutTitle={popoutTitle}
    >
      <Flex px="4px" gap={1} flexDirection="column">
        <Flex>
          {connections.length > 0 && (
            <Flex flexDirection="column" gap={1}>
              <Text as="div">VPN Connections</Text>
              {connections.map((connection) => {
                const connectionType = azureCloudMapCollection.getEntityType(connection);
                if (connectionType === 'vpnConnections') {
                  return (
                    <AzureVpnConnectionDetails
                      key={connection.id}
                      type={connectionType}
                      nodeData={connection}
                      popoutTitle={popoutTitle}
                      width={width}
                    />
                  );
                }

                console.error('unknown connection type: ', connectionType, connection);
                return null;
              })}
            </Flex>
          )}

          {expressRouteConnections.length > 0 && (
            <Flex flexDirection="column" gap={1}>
              <Text as="div">Express Route Connections</Text>
              {expressRouteConnections.map((connection) => (
                <AzureExpressRouteConnectionDetails
                  key={connection.id}
                  nodeData={connection}
                  popoutTitle={popoutTitle}
                  width={width}
                />
              ))}
            </Flex>
          )}

          {p2SConnections.length > 0 && (
            <Flex flexDirection="column" gap={1}>
              <Text as="div">P2S Connection Configurations:</Text>
              {p2SConnections.map((connection) => (
                <AzureP2SConnectionConfigurationDetails
                  key={connection.id}
                  nodeData={connection}
                  popoutTitle={popoutTitle}
                  width={width}
                />
              ))}
            </Flex>
          )}
        </Flex>
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureConnectionsPopover);
