import React from 'react';
import { Flex, Button, DropdownMenu, Menu, MenuItem, MenuDivider } from 'core/components';
import { RemoveButton } from 'core/form';
import { FiCopy } from 'react-icons/fi';

const DashboardItemControls = ({ loading, item, onEdit, onClone }) => (
  <Flex mr="4px">
    <Button mr="4px" icon="edit" text="Edit" onClick={onEdit} disabled={loading} minimal small />
    <DropdownMenu
      tetherOptions={{ offset: '0px 0px' }}
      content={
        <Menu>
          <MenuItem icon="grid-view" text="This Dashboard" intent="primary" onClick={() => onClone('this')} />
          <MenuDivider />
          <MenuItem icon="new-grid-item" text="Existing Dashboard" onClick={() => onClone('existing')} />
          <MenuItem icon="plus" text="New Dashboard" onClick={() => onClone('new')} />
        </Menu>
      }
    >
      <Button icon={FiCopy} text="Clone to..." disabled={loading} mr="4px" minimal small />
    </DropdownMenu>
    <RemoveButton model={item} intent="none" showIcon small />
  </Flex>
);

export default DashboardItemControls;
