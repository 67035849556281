import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Box, Flex, Grid, Heading, Text } from 'core/components';
import { ReactComponent as PeeringIcon } from 'app/assets/onboarding/peering_interconnection.svg';
import { ReactComponent as DdosIcon } from 'app/assets/onboarding/ddos.svg';
import { ReactComponent as DigitalExperienceIcon } from 'app/assets/onboarding/digital_experience.svg';
import { ReactComponent as SdwanIcon } from 'app/assets/onboarding/sdwan.svg';
import { ReactComponent as TroubleshootIcon } from 'app/assets/onboarding/troubleshoot.svg';
import { ReactComponent as HybridIcon } from 'app/assets/onboarding/hybrid_icon.svg';
import { ReactComponent as KmiIcon } from 'app/assets/onboarding/market-intel.svg';
import KentikLogo from 'app/components/KentikLogo';
import Background from 'app/views/onboarding/components/Background';
import Subtitle from 'app/views/onboarding/components/Subtitle';
import OnboardingCheckbox from 'app/views/onboarding/components/OnboardingCheckbox';
import OnboardingNextButton from 'app/views/onboarding/components/OnboardingNextButton';
import Blurb from 'app/views/onboarding/components/Blurb';
import storeLoader from 'app/stores/storeLoader';

const checkboxProps = {
  width: '100%'
};

@storeLoader('$sites')
@inject('$auth', '$setup', '$users')
class Why extends Component {
  constructor(props) {
    super(props);

    const { $setup } = props;

    const { digital_experience, hybrid, troubleshooting, ddos, peering, sdwan, kmi } = $setup.getSettings('why', {});
    this.state = {
      digital_experience: digital_experience || false,
      hybrid: hybrid || false,
      troubleshooting: troubleshooting || false,
      ddos: ddos || false,
      peering: peering || false,
      sdwan: sdwan || false,
      kmi: kmi || false
    };
  }

  componentDidMount() {
    // Do this up front so that we ensure things get saved in case the user likes the default selections
    const { $setup } = this.props;
    const { digital_experience, hybrid, troubleshooting, ddos, peering, sdwan, kmi } = this.state;
    $setup.updateSettings({ why: { digital_experience, hybrid, troubleshooting, ddos, peering, sdwan, kmi } });
  }

  handleChange = (key, checked) => {
    const { $setup } = this.props;

    this.setState({ [key]: checked }, () => {
      const { digital_experience, hybrid, troubleshooting, ddos, peering, sdwan, kmi } = this.state;

      $setup.updateSettings({
        why: {
          digital_experience,
          hybrid,
          troubleshooting,
          ddos,
          peering,
          sdwan,
          kmi
        }
      });
    });
  };

  get kmiOnly() {
    const { digital_experience, hybrid, troubleshooting, ddos, peering, sdwan, kmi } = this.state;
    return kmi && !digital_experience && !hybrid && !troubleshooting && !ddos && !peering && !sdwan;
  }

  handleNext = async () => {
    const { $setup, $auth, $users } = this.props;

    if (this.kmiOnly) {
      $auth.track('tour-onboarding-completed');
      return Promise.all([
        $setup.updateSettings({ what: { kmi: true } }),
        $setup.writeSetupTasks({ kmi: true }),
        $users.updateUserProfile({ defaultLandingPageType: 'market-intel' }, { toast: false })
      ]);
    }

    return true;
  };

  get nextStep() {
    const { $setup } = this.props;

    if (this.kmiOnly) {
      return { text: 'Start Monitoring', to: '/v4' };
    }

    if ($setup.getSettings('what')) {
      return { text: 'Start Monitoring', to: '/v4/home' };
    }

    return {
      text: 'Which data shall we collect?',
      to: '/v4/onboarding/what'
    };
  }

  render() {
    const { digital_experience, hybrid, troubleshooting, ddos, peering, sdwan, kmi } = this.state;
    const { $auth } = this.props;

    return (
      <Background>
        <Flex alignItems="flex-start" mb={4} maxWidth={750}>
          <Box mr="20px" minWidth={220}>
            <KentikLogo className="logo" />
            <Subtitle />
          </Box>
          <Blurb />
        </Flex>
        <Heading level={1}>First, how can we help?</Heading>
        <Text as="div" fontWeight="medium" fontSize={14} color="white.6" mt="-20px" mb={4}>
          Here&apos;s how we often help customers. Pick the use cases that you think are relevant to you.
        </Text>
        <Grid gridTemplateColumns="repeat(auto-fit,minmax(320px,1fr))" gridRowGap={16} mb={2} maxWidth={1250}>
          <OnboardingCheckbox
            icon={DigitalExperienceIcon}
            title="Improve Users' Digital Experience"
            description="Setup synthetic testing and monitor SaaS performance"
            onChange={(checked) => this.handleChange('digital_experience', checked)}
            checked={digital_experience}
            {...checkboxProps}
          />
          <OnboardingCheckbox
            icon={HybridIcon}
            title="Manage Public &amp; Hybrid Cloud Networking"
            description="Visualize and solve connectivity problems and proactively map and monitor performance"
            onChange={(checked) => this.handleChange('hybrid', checked)}
            checked={hybrid}
            {...checkboxProps}
          />
          <OnboardingCheckbox
            icon={TroubleshootIcon}
            title="Troubleshoot Network Problems"
            description="Get alerts, insights, and helpful context and query traffic and metrics data to solve problems"
            onChange={(checked) => this.handleChange('troubleshooting', checked)}
            checked={troubleshooting}
            {...checkboxProps}
          />
          <OnboardingCheckbox
            icon={DdosIcon}
            title="Stop DDoS Attacks"
            description="Accurately detect attacks and automate mitigations"
            onChange={(checked) => this.handleChange('ddos', checked)}
            checked={ddos}
            {...checkboxProps}
          />
          <OnboardingCheckbox
            icon={PeeringIcon}
            title="Optimize Peering &amp; Interconnection"
            description="Find peers, understand your traffic flows, plan capacity, and optimize costs"
            onChange={(checked) => this.handleChange('peering', checked)}
            checked={peering}
            {...checkboxProps}
          />
          <OnboardingCheckbox
            icon={SdwanIcon}
            title="Manage my WAN, SD-WAN"
            description="Observe traffic levels to clouds and services and proactively monitor performance between locations"
            onChange={(checked) => this.handleChange('sdwan', checked)}
            checked={sdwan}
            {...checkboxProps}
          />
          {$auth.hasPermission('marketIntel.enabled') && (
            <OnboardingCheckbox
              icon={KmiIcon}
              title="Leverage Market Intelligence"
              description="Consume BGP-based data to understand & benchmark how networks are connected, providers, customers."
              onChange={(checked) => this.handleChange('kmi', checked)}
              checked={kmi}
              {...checkboxProps}
            />
          )}
        </Grid>
        <OnboardingNextButton text={this.nextStep.text} to={this.nextStep.to} onClick={this.handleNext} />
      </Background>
    );
  }
}

export default Why;
