import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Heading, Icon } from 'core/components';
import { ReactComponent as CheckboxIcon } from 'app/assets/onboarding/onboarding-checkbox.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'app/assets/onboarding/onboarding-checkbox-checked.svg';

const Checkbox = styled(Box)`
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.05);
  max-width: 600px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.7);
  }

  &:focus {
    outline: 1px dotted #ffffff;
    outline-offset: 4px;
  }
`;

class OnboardingSubcheckbox extends Component {
  state = {
    checked: undefined,
    checkedInitial: undefined
  };

  static getDerivedStateFromProps(props, state) {
    const { checked } = props;

    if (checked !== state.checkedInitial) {
      return { checked, checkedInitial: checked };
    }

    return null;
  }

  handleCheck = (e) => {
    e.stopPropagation();

    const { onChange } = this.props;
    const { checked } = this.state;

    this.setState({ checked: !checked }, () => onChange(!checked));
  };

  render() {
    const { title, icon, children, showChildren, checked: checkedProp, onChange, ...rest } = this.props;
    const { checked } = this.state;

    return (
      <Checkbox onClick={this.handleCheck} tabIndex={0} {...rest}>
        <Icon icon={icon} iconSize={20} />
        <Box flex={1} ml={1} mr={2}>
          <Heading level={6} color="#ffffff" mb={0}>
            {title}
          </Heading>
        </Box>
        <Icon icon={checked ? CheckboxCheckedIcon : CheckboxIcon} iconSize={16} />
      </Checkbox>
    );
  }
}

export default OnboardingSubcheckbox;
