import { isUrl, isUrlSingleDomain } from '@kentik/ui-shared/validators/url';

const message = 'Enter a valid URL';

export const urlSingleDomain = {
  message,
  fn: (value, options) => isUrlSingleDomain(value, options)
};

export default {
  message: 'Enter a valid URL',
  fn: (value) => isUrl(value)
};
