import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { get, isEqual } from 'lodash';
import { Box, EmptyState, Flex, Spinner, Link } from 'core/components';
import { cloudProviderData } from 'app/util/utils';
import { Circle, Text } from 'app/components/svg';
import classNames from 'classnames';
import ArcLayoutItemGroup from 'app/views/hybrid/maps/layouts/arc/ArcLayoutItemGroup';
import ArcLayoutIcon from 'app/views/hybrid/maps/layouts/arc/ArcLayoutIcon';
import ChordLayoutWithFallback from '../layouts/ChordLayoutWithFallback';
import { initMatrix } from '../../utils/matrix';

function getMatrix(keys, links) {
  const matrix = initMatrix(keys.length);

  (links || []).forEach((link) => {
    const { cloud1_provider, cloud2_provider } = link;
    const s1Idx = keys.findIndex(({ provider }) => provider === cloud1_provider);
    const s2Idx = keys.findIndex(({ provider }) => provider === cloud2_provider);

    if (s1Idx > -1 && s2Idx > -1) {
      matrix[s1Idx][s2Idx] = link;
      matrix[s2Idx][s1Idx] = link;
    }
  });

  return matrix;
}

@inject('$hybridMap')
@observer
class CloudsWithLinks extends Component {
  static defaultProps = {
    clouds: [],
    getLinkTraffic: () => null
  };

  static getDerivedStateFromProps(props, state) {
    const { clouds, links } = props;

    if (clouds.length !== state.linkMatrix.length) {
      return { linkMatrix: getMatrix(clouds, links) };
    }

    return null;
  }

  state = {
    linkMatrix: []
  };

  componentDidUpdate(prevProps) {
    const { clouds, links } = this.props;

    if (!isEqual(prevProps.links, links)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ linkMatrix: getMatrix(clouds, links) });
    }
  }

  get height() {
    const { clouds } = this.props;
    return ChordLayoutWithFallback.getHeight({ ...this.props, items: clouds });
  }

  get selected() {
    const { selected, clouds } = this.props;
    return clouds.find((cloud) => cloud.provider === selected);
  }

  getLink(source, target, options) {
    const { linkMatrix } = this.state;
    const { checkExistence } = options || {};
    const link = get(linkMatrix, `${source.index}.${target.index}`);
    if (link) {
      if (checkExistence) {
        return true;
      }

      const trafficData = {};

      if (link.inTraffic || link.outTraffic) {
        trafficData.inTraffic = link.inTraffic || 0;
        trafficData.outTraffic = link.outTraffic || 0;

        trafficData.totalTraffic = trafficData.inTraffic + trafficData.outTraffic;
      }

      if (link.isFiltered) {
        trafficData.isFiltered = link.isFiltered;
      }

      if (link.snmp_speed) {
        trafficData.capacity = link.snmp_speed * 1000000;
      }

      return trafficData;
    }

    return null;
  }

  handleLinkClick = (data) => {
    const { onLinkClick } = this.props;

    onLinkClick({
      type: 'link',
      source: {
        type: 'cloud',
        value: data.source
      },
      target: {
        type: 'cloud',
        value: data.target
      },
      offset: data.offset
    });
  };

  renderLogo(provider) {
    const { large } = this.props;
    const logo = cloudProviderData(provider, 'logo');
    const width = large ? 40 : 20;
    const height = large ? 40 : 20;

    if (logo) {
      return React.cloneElement(logo, { x: width / 2, y: height / 2, width, height });
    }

    return null;
  }

  itemRenderer = (item, props, index, items) => {
    const { selected, highlighted } = props;
    return (
      <ArcLayoutItemGroup {...props} className="no-icon-fill" shapeSize={60} key={item.provider}>
        <Circle
          className={classNames(
            'circle-item-group',
            'no-icon-fill',
            'hybrid-map-selectable-node',
            `cloud-${item.provider}`,
            {
              selected: selected === item.provider,
              highlighted: !selected && (highlighted || []).includes(item.provider),
              deselected: selected && selected !== item.provider
            }
          )}
        />
        <Text
          className={classNames('arclayout-item-group', {
            topleft: index < (items.length - 1) / 2,
            topright: index >= (items.length - 1) / 2
          })}
          fill="body"
        >
          {item.name}
        </Text>
        <ArcLayoutIcon
          point={item.point}
          className="color-icon"
          icon={cloudProviderData(item.provider, 'logoCmp')}
          iconSize={40}
        />
      </ArcLayoutItemGroup>
    );
  };

  render() {
    const { width, highlighted, onSelect, setPopoverPosition, isPopoverOpen, clouds, loading } = this.props;
    const { linkMatrix } = this.state;
    const { height, selected } = this;

    if (loading) {
      return (
        <Flex justifyContent="center" width={width} height={height}>
          <Spinner />
        </Flex>
      );
    }

    if (clouds.length === 0) {
      return (
        <Box width={width} height={height}>
          <EmptyState
            icon="cloud"
            description={
              <>
                No {this.isFiltered ? 'other' : ''} clouds configured. Visit{' '}
                <Link to="/v4/settings/clouds">cloud settings</Link> to get started.
              </>
            }
          />
        </Box>
      );
    }

    return (
      <svg width={width} height={height}>
        <ChordLayoutWithFallback
          width={width}
          height={height}
          classPrefix="cloud"
          keyProp="provider"
          itemType="cloud"
          strokeWidth={2}
          items={clouds}
          selected={selected}
          itemRenderer={this.itemRenderer}
          highlighted={highlighted.map((provider) => clouds.find((cloud) => cloud.provider === provider))}
          onSelect={(item) => onSelect(item || null)}
          getLink={(a, b, options) => this.getLink(a, b, options)}
          onLinkClick={this.handleLinkClick}
          setPopoverPosition={setPopoverPosition}
          isPopoverOpen={isPopoverOpen}
          linkMatrix={linkMatrix}
        />
      </svg>
    );
  }
}

export default CloudsWithLinks;
