import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FiBarChart, FiTarget, FiCopy, RiDashboardLine, RiBarChartBoxLine } from 'react-icons/fi';
import { Flex, FlexColumn, Text, Icon, Button, Tooltip, Link } from 'core/components';
import { formatDateTime } from 'core/util/dateUtils';
import Page from 'app/components/page/Page';
import AdminTable from 'app/components/admin/AdminTable';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';
import { CELL_TYPES } from 'core/components/table';
import RemoveButton from 'core/form/components/RemoveButton';
import ShareViewDialog from 'app/views/core/ShareViewDialog';
import PageHeading from 'app/components/page/PageHeading';
import storeLoader from 'app/stores/storeLoader';

@storeLoader('$subscriptions')
@inject('$auth', '$setup', '$sharedLinks')
@withRouter
@observer
class SharedLinks extends Component {
  state = {
    editing: null
  };

  getColumns = () => {
    const { $auth, $sharedLinks } = this.props;

    const columns = [
      {
        name: 'name',
        label: 'Title',
        flexBasis: 150
      },
      {
        name: 'metadata',
        label: 'Type',
        renderer: ({ value }) => {
          const { link_type } = value;

          switch (link_type) {
            case $sharedLinks.types.synthetics:
              return (
                <Text title="Synthetics Test">
                  <Icon icon={FiTarget} iconSize={12} color="inherit" pl="2px" pb="2px" pr="6px" />
                  Synthetics Test
                </Text>
              );

            case $sharedLinks.types.savedView:
              return (
                <Text title="Saved View">
                  <Icon icon={RiBarChartBoxLine} iconSize={12} color="inherit" pl="2px" pb="2px" pr="6px" />
                  Saved View
                </Text>
              );

            case $sharedLinks.types.dashboards:
              return (
                <Text title="Dashboard">
                  <Icon icon={RiDashboardLine} iconSize={12} color="inherit" pl="2px" pb="2px" pr="6px" />
                  Dashboard
                </Text>
              );

            default:
              return (
                <Text title="Data Explorer View">
                  <Icon icon={FiBarChart} iconSize={12} color="inherit" pl="2px" pb="2px" pr="6px" />
                  Data Explorer View
                </Text>
              );
          }
        }
      },
      {
        name: 'public_hash',
        label: 'URL',
        flexBasis: 200,
        renderer: ({ value, model }) => {
          const { metadata, entropy_url } = model.get();
          const link = `${window.location.origin}/v4/shared/${metadata.link_type}/${entropy_url}/${value}`;

          return (
            <Text>
              <Tooltip content="Copy public URL">
                <CopyToClipboardButton text={link} intent="">
                  <Icon icon={FiCopy} iconSize={12} color="inherit" pl="2px" pb="2px" pr="10px" />
                </CopyToClipboardButton>
              </Tooltip>

              <Tooltip content="Open public share page">
                <Link blank to={`/${value}`} href={link} />
              </Tooltip>
            </Text>
          );
        }
      },
      // {
      //   name: 'edate',
      //   label: 'Status',
      //   renderer: () => <Text>Active</Text>
      // },
      {
        name: 'cdate',
        label: 'Created At',
        minWidth: 95,
        flexBasis: 75,
        renderer: ({ value }) => formatDateTime(value)
      },
      // {
      //   name: 'expire_date',
      //   label: 'Expires',
      //   renderer: ({ value }) => <Text>{value ? formatDate(value) : 'Never'}</Text>
      // },
      {
        name: 'user.user_full_name',
        label: 'Shared by User',
        minWidth: 95,
        flexBasis: 75
      },
      {
        type: CELL_TYPES.ACTION,
        width: 72,
        actions: [
          (model) => {
            if ($auth.activeUser.id !== model.get('user_id') && !$auth.isAdministrator) {
              return <div key="edit" />;
            }

            return (
              <Button
                key="edit"
                icon="edit"
                onClick={(e) => {
                  e.stopPropagation();

                  const { metadata, entropy_url, public_hash } = model.get();
                  const link = `/v4/shared/${metadata.link_type}/${entropy_url}/${public_hash}`;
                  this.setState({ editing: { ...model.toJS(), privateShareUrl: link } });
                }}
                text=""
                title="Edit"
                minimal
                small
              />
            );
          },
          (model) => {
            if ($auth.activeUser.id !== model.get('user_id') && !$auth.isAdministrator) {
              return <div key="remove" />;
            }

            return <RemoveButton key="remove" model={model} showIcon hideText small />;
          }
        ]
      }
    ];

    return columns;
  };

  onClose = (values) => {
    if (!values) {
      this.setState({ editing: null });
      return;
    }

    const { id, description, name, public_hash } = values;
    const { $sharedLinks } = this.props;

    this.setState({ editing: null }, () => {
      const model = $sharedLinks.collection.get(id);
      if (model && model.set) {
        model.set('description', description);
        model.set('name', name);
        model.set('public_hash', public_hash);
      }
    });
  };

  render() {
    const { $sharedLinks } = this.props;
    const { editing } = this.state;

    return (
      <Page title="Public Shares">
        <PageHeading title="Public Shares" icon="share" />
        <Flex width="100%" height="100%" flex={1} pt={1} pb={3} overflow="hidden">
          <FlexColumn flex={1} position="relative" overflow="hidden">
            <Flex flexDirection="column" flex={1} position="relative" overflow="hidden">
              <Flex flex="1 1 auto" overflow="hidden">
                <AdminTable fetchCollectionOnMount collection={$sharedLinks.collection} columns={this.getColumns()} />
              </Flex>
            </Flex>
          </FlexColumn>
        </Flex>
        {editing && (
          <ShareViewDialog
            isOpen={editing}
            editingLink={editing}
            link_type={editing.metadata.link_type}
            privateShareUrl={editing.privateShareUrl}
            isPublicLink
            onClose={this.onClose}
            showManagePublicShares={false}
            hideSubscription
            hideEmail
          />
        )}
      </Page>
    );
  }
}

export default SharedLinks;
