import React from 'react';

import { inject } from 'mobx-react';
import { Flex } from 'core/components';

import SidebarItem from '../SidebarItem';
import { AzureExpressRouteGatewayDetails } from './components';

const AzureExpressRouteGatewaysPopover = ({ width, popoutTitle, $hybridMap, nodeData }) => {
  const { azureCloudMapCollection } = $hybridMap;

  const expressRouteGateways = azureCloudMapCollection.getEntityRelatedEntities(nodeData, 'expressRouteGateways') ?? [];

  if (expressRouteGateways.length === 0) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title="Express Route Gateways"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="backlink"
      popoutTitle={popoutTitle}
    >
      <Flex px="4px" gap={1} flexDirection="column">
        <Flex>
          <Flex flexDirection="column" gap={1}>
            {expressRouteGateways.map((expressRouteGateway) => (
              <AzureExpressRouteGatewayDetails
                key={expressRouteGateway.id}
                nodeData={expressRouteGateway}
                width={width}
                popoutTitle={popoutTitle}
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureExpressRouteGatewaysPopover);
