import React from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { get, uniq, uniqBy, isEqual } from 'lodash';
import classNames from 'classnames';
import { Box, EmptyState, Flex, Icon, Spinner, Button } from 'core/components';
import SiteLink from 'app/components/links/SiteLink';
import { sortArcItems } from 'app/views/hybrid/maps/layouts/arc/ArcLayout';
import SiteFormDialog from 'app/views/settings/sites/SiteFormDialog';
import { ReactComponent as SiteIcon } from 'app/assets/icons/map_pin.svg';
import AbstractMap from './components/AbstractMap';
import Clouds from './components/Clouds';
import SiteDevices from './components/SiteDevices';
import TopKeys from './components/TopKeys/TopKeys';
import TopKeysLayout from './components/TopKeys/TopKeysLayout';
import TopLevelBox from './components/TopLevelBox';
import NodeLinkGenerator from './components/NodeLinkGenerator';
import LinkFlowTrafficGenerator from './components/LinkFlowTrafficGenerator';
import BoxLinkGenerator from './components/BoxLinkGenerator';
import withPopover from './components/popovers/withPopover';
import OtherSites from './components/interSiteTraffic/OtherSites';

export function setupLayers(site, topology) {
  const { deviceLinks, devices, kprobeDeviceIds } = topology;

  const linksMap = {};
  deviceLinks.forEach((link) => {
    const { device1_id, device2_id } = link;

    if (device1_id) {
      linksMap[device1_id] = linksMap[device1_id] || {};
    }
    if (device2_id) {
      linksMap[device2_id] = linksMap[device2_id] || {};
    }
    if (device1_id && device2_id) {
      linksMap[device1_id][device2_id] = link;
      linksMap[device2_id][device1_id] = link;
    }
  });

  const layers = (site.get('metadata.architecture') || []).map((layer) => ({
    ...layer,
    subLayers: [
      layer.subLayers.reduce(
        (acc, subLayer) => {
          const subLayerDevices = (subLayer && subLayer.devices) || [];
          // filter out any kprobe devices that may have made their way into the architecture editor
          const devicesWithoutKprobeTypes = subLayerDevices.filter((device) => kprobeDeviceIds.indexOf(device) === -1);

          acc.names.push(subLayer.name);
          acc.devices = acc.devices.concat(
            sortArcItems(devicesWithoutKprobeTypes, (a, b) => get(linksMap, `${a}.${b}`, false))
          );

          return acc;
        },
        { names: [], devices: [] }
      )
    ]
  }));

  const unassignedDevices = Object.keys(devices).filter(
    (id) =>
      devices[id].site_id === site.id &&
      !layers.some((layer) => layer.subLayers.some((subLayer) => (subLayer.devices || []).includes(parseInt(id, 10))))
  );

  const hasUnassignedDevices = unassignedDevices.length > 0;

  if (hasUnassignedDevices) {
    layers.push({ subLayers: [{ names: ['Unassigned'], devices: unassignedDevices, unassigned: true }] });
  }

  return { layers, linksMap, hasUnassignedDevices };
}

@withPopover
@withRouter
@inject('$auth', '$hybridMap', '$sites')
export default class SiteDetailsMap extends AbstractMap {
  constructor(props) {
    super(props);

    Object.assign(this.state, {
      loading: true,
      boxExpanded: {
        site: false,
        cloud: false,
        internet: false
      },
      boxLinks: [
        {
          orientation: 'vertical',
          from: 'site',
          to: 'device'
        },
        {
          orientation: 'vertical',
          from: 'cloud',
          to: 'device'
        },
        {
          orientation: 'vertical',
          from: 'internet',
          to: 'device'
        }
      ],
      activeInternetTabId: 'asn',
      isSiteDialogOpen: false,
      siteKeys: [],
      layers: [],
      linksMap: {},
      hasUnassignedDevices: false
    });
  }

  componentDidMount() {
    this.fetchTopology();
  }

  componentDidUpdate(prevProps, prevState) {
    const { id, sidebarSettings } = this.props;
    const showInterSiteTrafficChanged =
      prevProps.sidebarSettings.showInterSiteTraffic !== sidebarSettings.showInterSiteTraffic;
    const interSiteTrafficChanged = prevProps.sidebarSettings.interSiteTraffic !== sidebarSettings.interSiteTraffic;
    const { selectedNode } = this.state;

    if (prevProps.id !== id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ loading: true }, () => {
        this.fetchTopology();

        if (selectedNode) {
          // deselect the currently selected node to clean up drawn links etc.
          this.handleSelectNode({ type: selectedNode.type, value: selectedNode.value });
        }
      });
    } else if (prevProps.sidebarSettings.connectionType !== sidebarSettings.connectionType) {
      this.fetchTopology();
    } else {
      if (
        !isEqual(
          get(prevProps.sidebarSettings, 'sidebarQueryOverrides.filters'),
          get(sidebarSettings, 'sidebarQueryOverrides.filters')
        ) ||
        showInterSiteTrafficChanged
      ) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ linkFlowTrafficLoading: this.hasSidebarFilters || sidebarSettings.showInterSiteTraffic });
      }

      if (showInterSiteTrafficChanged || interSiteTrafficChanged) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          nodeLinksLoading: selectedNode !== null,
          nodeLinkQueries: this.getNodeLinkQueries()
        });
      }

      super.componentDidUpdate(prevProps, prevState);
    }
  }

  fetchTopology() {
    const { id, $hybridMap, $sites } = this.props;

    Promise.all([
      $hybridMap.getTopologyDataForSite(id),
      $hybridMap.getHealthCollection('site', id),
      $sites.collection.fetch()
    ])
      .then(([topology, healthCollection]) => {
        const siteKeys = uniqBy(
          topology.siteLinks
            .map(({ site1_id, site2_id }) => {
              const key = (site1_id === id ? site2_id : null) || (site2_id === id ? site1_id : null);
              const site = key && $sites.collection.get(key);
              const siteWithHealth = $hybridMap.sites.find((s) => s.id === key);
              const health = (siteWithHealth && siteWithHealth.health) || null;

              if (!key || !site) {
                return null;
              }

              return { key, label: site.get('title'), health };
            })
            .filter((keyObj) => keyObj !== null)
            .sort((a, b) => a.label.localeCompare(b.label)),
          ({ key }) => key
        );

        const boxExpanded = {
          site: siteKeys.length > 0,
          cloud: $hybridMap.clouds.length > 0,
          internet: true
        };

        const { layers, linksMap, hasUnassignedDevices } = setupLayers(this.site, topology);
        this.setState({
          topology,
          healthCollection,
          siteKeys,
          boxExpanded,
          loading: false,
          layers,
          linksMap,
          hasUnassignedDevices
        });
        this.onTopologyReady(topology);
      })
      .catch((e) => {
        console.error('Error loading topology', e);
        this.setState({ loading: false });
      });
  }

  get site() {
    const { id, $sites } = this.props;
    return $sites.collection.get(id);
  }

  getNodePosition({ type, value }) {
    const { points, mask, svg, node } = super.getNodePosition({ type, value });

    if (points.length === 0) {
      return { points };
    }

    if (type === 'device') {
      const boxRect = this.map.current.querySelector('.box-device').getBoundingClientRect();
      const boxTop = boxRect.top - svg.rect.top;
      const boxLeft = boxRect.left - svg.rect.left;

      points.push([node.cx, node.cy - 100]);

      if (!node.element.classList.contains('layer-0')) {
        if (node.cx < boxLeft + boxRect.width / 2) {
          const x = Math.min(boxLeft + boxRect.width / 3, node.cx);
          points.push([x, node.cy - 100], [x, boxTop]);
        } else if (node.cx > boxLeft + boxRect.width / 2) {
          const x = Math.max(boxLeft + (2 * boxRect.width) / 3, node.cx);
          points.push([x, node.cy - 100], [x, boxTop]);
        }
      }
    }

    return { points, mask };
  }

  getLinksFor(type) {
    const { topology } = this.state;

    if (type === 'site') {
      return topology.siteLinks;
    }

    if (type === 'cloud') {
      return topology.cloudLinks;
    }

    if (type === 'provider') {
      return topology.providerLinks;
    }

    if (type === 'device') {
      return topology.deviceLinks;
    }

    return [];
  }

  getSelectedLinksFor(type, selectedNode = this.state.selectedNode) {
    if (selectedNode) {
      const { type: selectedType, value } = selectedNode;
      const links = this.getLinksFor(type);

      if (selectedType === 'device') {
        return links.filter(({ device1_id, device2_id }) => device1_id === value || device2_id === value);
      }

      if (selectedType === 'site') {
        return links.filter(({ site1_id, site2_id }) => site1_id === value || site2_id === value);
      }
    }

    return [];
  }

  getSelectedInterfacesFor(type, selectedNode = this.state.selectedNode) {
    const selectedLinks = this.getSelectedLinksFor(type, selectedNode);

    return uniq(
      selectedLinks
        .flatMap(({ connections }) => connections.flatMap(({ interface1, interface2 }) => [interface1, interface2]))
        .filter(
          (intf) =>
            intf &&
            intf.snmp_id !== undefined &&
            ((selectedNode.type === 'site' && intf.site_id !== selectedNode.value) ||
              (selectedNode.type === 'device' && intf.device_id !== selectedNode.value))
        )
    );
  }

  getNodeLinkQueries({ selectedNode, activeInternetTabId } = this.state) {
    const { $hybridMap } = this.props;
    const { site } = this;

    const sourceValueKey = { interface: 'snmp_id' };

    if (selectedNode) {
      const { type: source } = selectedNode;
      const sourceValue = sourceValueKey[source] ? selectedNode.value[sourceValueKey[source]] : selectedNode.value;
      let types = [];

      if (source === 'site' || source === 'cloud' || source === 'internet') {
        types = ['device'];
      } else if (source === 'device') {
        types = ['site', 'cloud', 'internet'];
      }

      let inboundSiteInterfaces = [];
      const outboundSiteInterfaces = this.getSelectedInterfacesFor('site', selectedNode);

      if (source === 'site') {
        // get all site interfaces for the other side
        inboundSiteInterfaces = this.getSelectedInterfacesFor('site', { type: 'site', value: site.id }).filter(
          (i) => i.site_id === selectedNode.value
        );
      } else if (source === 'device') {
        // start by getting all connecting site ids
        const connectingSiteIds = uniq(outboundSiteInterfaces.map((i) => i.site_id));

        // use the connecting site ids to obtain interfaces, filtering by the selected device
        inboundSiteInterfaces = connectingSiteIds
          .flatMap((siteId) => this.getSelectedInterfacesFor('site', { type: 'site', value: siteId }))
          .filter((i) => i.device_id === selectedNode.value);
      }

      return types
        .flatMap((target) => {
          const type = target === 'internet' ? activeInternetTabId : target;

          if (
            (source === 'site' || target === 'site') &&
            (inboundSiteInterfaces.length === 0 || outboundSiteInterfaces.length === 0) &&
            !$hybridMap.interSiteTrafficEnabled
          ) {
            return [];
          }

          const [inboundQuery, outboundQuery] = $hybridMap.getNodeLinkQuery(
            { selectedNode: { ...selectedNode, value: sourceValue }, type },
            { site: site.get('title'), inboundSiteInterfaces, outboundSiteInterfaces }
          );

          return [
            inboundQuery ? { direction: 'inbound', source, sourceValue, target, query: inboundQuery } : null,
            outboundQuery ? { direction: 'outbound', source, sourceValue, target, query: outboundQuery } : null
          ];
        })
        .filter((query) => query !== null);
    }

    return [];
  }

  getNodeData({ type, value }) {
    const { topology, healthCollection } = this.state;

    if (type === 'site') {
      return {
        devices: Object.values(topology.devices).filter((device) => device.site_id === value),
        health: topology.sites[value]?.health,
        healthIssues: healthCollection.getIssues({ site_id: value }, { doLookups: true })
      };
    }

    if (type === 'device') {
      return {
        health: topology.devices[value]?.health,
        healthIssues: healthCollection.getIssues({ device_id: value }, { doLookups: true })
      };
    }

    return null;
  }

  getSidebarConfig({ selectedNode } = this.state) {
    const linkType = selectedNode.type === 'site' ? 'site' : 'device';

    return {
      ...selectedNode,
      nodeData: this.getNodeData(selectedNode),
      links: this.getSelectedLinksFor(linkType, selectedNode),
      detail: {
        type: 'site',
        model: this.site
      }
    };
  }

  handleSelectNode({ type, value, force = false, nodeData }) {
    const { openPopover } = this.props;
    const { selectedNode } = this.state;

    super.handleSelectNode({ type, value, force, nodeData });

    this.setState((prevState) => {
      if (prevState.selectedNode) {
        const { points } = this.getNodePosition(prevState.selectedNode);
        const isSameNode = isEqual(selectedNode, prevState.selectedNode);

        if (points.length > 0) {
          const [x, y] = points[0];

          openPopover({
            ...this.getSidebarConfig(prevState),
            position: { left: x, top: y },
            shortcutMenu: {
              selectedNode: prevState.selectedNode,
              showConnectionsCallback: this.setNodeLinks,
              isShowingConnections: prevState.nodeLinks.length > 0 && isSameNode
            }
          });
        }
      }

      return null;
    });
  }

  handleNodeLinkClick = (config) => {
    const { setSidebarDetails } = this.props;
    const { nodeLinks, activeInternetTabId, topology } = this.state;
    const { source, target } = config;

    // @TODO cloud link popovers are currently not supported
    if (source.type !== 'cloud' && target.value !== 'cloud') {
      if (source.type === 'internet') {
        // the sub type isn't shipped here, we need to manually add it
        source.subType = activeInternetTabId;
      }

      setSidebarDetails({
        ...config,
        source,
        links: nodeLinks,
        internetSubType: activeInternetTabId,
        detail: {
          type: 'site',
          model: this.site
        },
        topology
      });
    }
  };

  handleSiteDevicesLinkClick = ({ connections, link, linkLabel, offset }) => {
    if (connections.length > 0) {
      const { setSidebarDetails } = this.props;

      setSidebarDetails({
        type: 'link',
        source: { type: 'device', value: link.device1_id },
        target: { type: 'device', value: link.device2_id },
        links: [link],
        linkLabel,
        offset
      });
    }
  };

  handleShowSiteDialog = () => {
    this.setState({ isSiteDialogOpen: true });
  };

  handleCloseSiteDialog = () => {
    this.setState({ isSiteDialogOpen: false });
  };

  handleSiteSave = () => {
    const { layers, linksMap, hasUnassignedDevices } = setupLayers(this.site, this.state.topology);
    this.setState({ isSiteDialogOpen: false, layers, linksMap, hasUnassignedDevices });
  };

  handleFlowTrafficUpdate = (deviceLinks) => {
    this.setState(({ topology }) => {
      const updatedTopology = { ...topology, deviceLinks };
      const { layers, linksMap, hasUnassignedDevices } = setupLayers(this.site, updatedTopology);

      return { topology: updatedTopology, layers, linksMap, hasUnassignedDevices, linkFlowTrafficLoading: false };
    });
  };

  handleSiteKeysUpdate = (siteKeys) => {
    this.setState({ siteKeys, linkFlowTrafficLoading: false });
  };

  renderMap() {
    const { $auth, width, isPopoverOpen } = this.props;
    const {
      loading,
      topology,
      boxExpanded,
      nodeLinkQueries,
      nodeLinksLoading,
      linkFlowTrafficLoading,
      siteKeys,
      layers,
      linksMap,
      hasUnassignedDevices,
      isSiteDialogOpen
    } = this.state;
    const { site } = this;

    if (loading) {
      return <Spinner mt={100} />;
    }

    if (!site || !topology) {
      return <EmptyState icon="map-marker" title="Site not found" />;
    }

    return (
      <Box>
        {(nodeLinksLoading || linkFlowTrafficLoading) && (
          <Box key="linksLoading" position="absolute" top="300px" width="100%">
            <Spinner intent="primary" />
          </Box>
        )}

        <Flex justifyContent="space-between">
          <Box>
            <TopLevelBox
              title="Other Sites"
              boxProps={{
                className: classNames('box-site', 'link-bottomright', { highlighted: this.isBoxHighlighted('site') }),
                minWidth: 200,
                minHeight: 150
              }}
              onExpandToggle={(isExpanded) => this.setBoxExpanded('site', isExpanded)}
              isExpanded={boxExpanded.site}
              expandedContent={
                <TopKeysLayout
                  classPrefix="site"
                  selected={this.getSelected('site')}
                  hovered={this.getHovered('site')}
                  itemType="site"
                  highlighted={this.getHighlighted('site')}
                  onSelect={(value, data) => this.handleSelectNode({ type: 'site', value, nodeData: data })}
                  results={siteKeys}
                  emptyState={<EmptyState icon="folder-close" description="No connected sites" />}
                />
              }
            />
          </Box>
          <Box>
            <TopLevelBox
              title="Clouds"
              boxProps={{
                className: classNames('box-cloud', 'link-bottomcenter', {
                  highlighted: this.isBoxHighlighted('cloud')
                }),
                minWidth: 200,
                maxWidth: 400,
                minHeight: 150
              }}
              onExpandToggle={(isExpanded) => this.setBoxExpanded('cloud', isExpanded)}
              isExpanded={boxExpanded.cloud}
              expandedContent={
                <Clouds
                  selected={this.getSelected('cloud')}
                  hovered={this.getHovered('cloud')}
                  highlighted={this.getHighlighted('cloud')}
                  onSelect={(value) => this.handleSelectNode({ type: 'cloud', value })}
                  site={site.get('id')}
                />
              }
            />
          </Box>
          <Box>
            <TopLevelBox
              title="Internet"
              boxProps={{
                className: classNames('box-internet', 'link-bottomleft', {
                  highlighted: this.isBoxHighlighted('internet')
                }),
                width: 500,
                height: 233
              }}
              onExpandToggle={(isExpanded) => this.setBoxExpanded('internet', isExpanded)}
              isExpanded={boxExpanded.internet}
              expandedContent={
                <TopKeys
                  classPrefix="internet"
                  selected={this.getSelected('internet')}
                  hovered={this.getHovered('internet')}
                  highlighted={this.getHighlighted('internet')}
                  onSelect={(value) => this.handleSelectNode({ type: 'internet', value })}
                  onTabChange={this.handleInternetTabChange}
                  site={site.get('id')}
                />
              }
            />
          </Box>
        </Flex>

        <Box mt={100}>
          <TopLevelBox
            title={
              <>
                <Icon icon={SiteIcon} color="muted" /> <SiteLink siteId={site.id}>{site.get('title')}</SiteLink>
                {!hasUnassignedDevices && $auth.isAdministrator && (
                  <Button
                    text="Configure Site"
                    onClick={this.handleShowSiteDialog}
                    intent="primary"
                    minimal
                    small
                    ml={1}
                  />
                )}
              </>
            }
            boxProps={{
              className: classNames('box-device', 'link-topcenter', { highlighted: this.isBoxHighlighted('device') }),
              p: 0,
              minWidth: 200,
              minHeight: 150
            }}
            isExpanded
            expandedContent={
              <SiteDevices
                site={site}
                devices={topology.devices}
                links={topology.deviceLinks}
                layers={layers}
                linksMap={linksMap}
                selected={this.getSelected('device')}
                hovered={this.getHovered('device')}
                highlighted={this.getHighlighted('device')}
                getLinkTraffic={this.getLinkTraffic}
                onSelect={(value, data) => this.handleSelectNode({ type: 'device', value, nodeData: data })}
                onShowSiteDialog={this.handleShowSiteDialog}
                onHover={(value) => this.handleHoverNode('device', value)}
                width={width - 4}
                onLinkClick={this.handleSiteDevicesLinkClick}
                isPopoverOpen={isPopoverOpen}
              />
            }
          />
          {isSiteDialogOpen && (
            <SiteFormDialog model={site} onSave={this.handleSiteSave} onClose={this.handleCloseSiteDialog} />
          )}
        </Box>

        <NodeLinkGenerator queries={nodeLinkQueries} onLinksUpdate={this.handleNodeLinksUpdate} />

        <BoxLinkGenerator
          boxes={{
            cloudNetworkLink: {
              orientation: 'vertical',
              from: 'cloud',
              to: 'device',
              loading: true
            },
            internetNetworkLink: {
              orientation: 'vertical',
              from: 'internet',
              to: 'device',
              loading: true
            },
            interSiteLink: {
              orientation: 'vertical',
              from: 'site',
              to: 'device'
            }
          }}
          site={site.get('title')}
          onBoxLinksUpdate={this.handleBoxLinksUpdate}
        />

        <LinkFlowTrafficGenerator links={topology.deviceLinks} onFlowTrafficUpdate={this.handleFlowTrafficUpdate} />

        <OtherSites
          siteKeys={siteKeys}
          sites={Object.keys(topology.sites)}
          detailSite={site}
          onSiteKeysUpdate={this.handleSiteKeysUpdate}
        />
      </Box>
    );
  }
}
