import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { css } from 'styled-components';
import { themeGet } from 'styled-system';
import { AiOutlineMenuFold } from 'react-icons/ai';
import { Tabs, Tab, Button, Flex, Box, Text } from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import Page from 'app/components/page/Page';
import PageHeading from 'app/components/page/PageHeading';
import PerfMonitorNodeCollection from 'app/stores/hybrid/PerfMonitorNodeCollection';
import HybridTopoSidebar from 'app/views/hybrid/maps/components/HybridTopoSidebar';
import withSidebarDetails from 'app/views/hybrid/maps/components/popovers/withSidebarDetails';
import HybridTopoSettingsToolbar, {
  ToolbarWrapper
} from 'app/views/hybrid/maps/components/settingsToolbar/HybridTopoSettingsToolbar';
import withHybridTopoSettings from 'app/views/hybrid/maps/components/settingsToolbar/withHybridTopoSettings';
import CloudInterconnects from './CloudInterconnects';
import CloudServices from './cloudServices/CloudServices';
import AgentSummary from './AgentSummary';

const wrapperCSS = css`
  margin: 0;
  padding: 0;

  svg.overlay {
    .links-node {
      .link-line {
        stroke: ${themeGet('colors.cloudPerformance.link.default')};

        &.no-synthetics-tests {
          stroke-dasharray: 2 3;
          stroke: ${themeGet('colors.cloudPerformance.link.noTests')};
        }
      }
    }

    .link {
      &.link-health-good .link-line {
        stroke: ${themeGet('colors.success')};
      }

      &.link-health-warning .link-line {
        stroke: ${themeGet('colors.warning')};
      }

      &.link-health-critical .link-line {
        stroke: ${themeGet('colors.danger')};
      }

      &.does-not-match-filter {
        .link-line {
          opacity: 0.2;
        }

        .hybrid-map-selectable-link {
          pointer-events: none;
        }
      }
    }
  }

  .hybrid-map-selectable-node {
    border: 4px solid transparent;

    &.unhovered,
    &.does-not-match-filter {
      opacity: 0.2;
      pointer-events: none;
    }

    &.hovered,
    &.selected,
    &.matches-filter {
      border-color: ${themeGet('colors.primary')};
      opacity: 1;
      pointer-events: all;
    }
  }
`;

@withSidebarDetails
@storeLoader('$syn.agents')
@inject('$syn')
@observer
class CloudPerformance extends React.Component {
  static getDerivedStateFromProps(props) {
    if (!props.sidebarDetailsPanel) {
      return { drawerIsOpen: false };
    }

    return null;
  }

  nodeCollection = new PerfMonitorNodeCollection();

  constructor(props) {
    super(props);

    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      drawerIsOpen: true,
      isAgentManagementOpen: false,
      unmonitoredVpcs: 0,
      pendingAgents: 0,
      privateAgents: 0,
      selectedAgentModel: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarDetailsPanel } = this.props;

    // if we're setting new details and it's not open, open it
    if (!prevState.drawerIsOpen && sidebarDetailsPanel) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ drawerIsOpen: true });
    }
  }

  componentWillUnmount() {
    if (this.agentCountDisposer) {
      this.agentCountDisposer();
    }
  }

  handleDrawerToggle = () => this.setState(({ drawerIsOpen }) => ({ drawerIsOpen: !drawerIsOpen }));

  handleTabChange = (selectedTabId) => {
    const { history, setSidebarDetails } = this.props;

    // close the sidebar when changing tabs
    setSidebarDetails(null);

    // reset the agent counts as we expect updated counts when the next tab loads
    this.setState({ unmonitoredVpcs: 0, pendingAgents: 0, privateAgents: 0 }, () =>
      history.push(`/v4/cloud/performance/${selectedTabId}`)
    );
  };

  // manages visibility of the tabbed table dialog that displays all uninstalled/pending/private agents
  toggleAgentManagement = () =>
    this.setState(({ isAgentManagementOpen }) => ({ isAgentManagementOpen: !isAgentManagementOpen }));

  handleAgentCountUpdate = ({ unmonitoredVpcs, pendingAgents, privateAgents }) =>
    this.setState({ unmonitoredVpcs, pendingAgents, privateAgents });

  handleAgentModelChange = ({ type, model }) => {
    const { $syn } = this.props;

    if (type === 'select') {
      this.setState({ selectedAgentModel: model });
    }

    if (type === 'challenge' || type === 'save') {
      $syn.agents.fetch({ force: true });
    }

    if (type === 'save') {
      this.handleAgentDialogClose();
    }
  };

  // this will close the agent form dialog used to save an agent's config
  handleAgentDialogClose = () => this.handleAgentModelChange({ type: 'select', model: null });

  get selectedTabId() {
    const { match } = this.props;
    const validTabIds = ['interconnects', 'services'];

    if (validTabIds.includes(match.params.tabId)) {
      return match.params.tabId;
    }

    return 'interconnects';
  }

  get agentBreakdown() {
    const { unmonitoredVpcs, pendingAgents, privateAgents } = this.state;

    if (this.selectedTabId === 'interconnects') {
      const { agentBreakdown } = this.nodeCollection;

      return {
        unmonitoredVpcs: agentBreakdown.unmonitoredVpcs.length,
        pendingAgents: agentBreakdown.pendingAgents.length,
        privateAgents: agentBreakdown.privateAgents.length
      };
    }

    return { unmonitoredVpcs, pendingAgents, privateAgents };
  }

  render() {
    const { settingsModel, saveSettings, sidebarSettings, setSidebarDetails, sidebarDetailsPanel } = this.props;
    const { drawerIsOpen, isAgentManagementOpen, selectedAgentModel } = this.state;
    const { unmonitoredVpcs, pendingAgents, privateAgents } = this.agentBreakdown;
    const agentManagementProps = {
      toggleAgentManagement: this.toggleAgentManagement,
      agentModel: selectedAgentModel,
      onAgentModelChange: this.handleAgentModelChange,
      onAgentFormDialogClose: this.handleAgentDialogClose,
      isAgentManagementOpen
    };

    return (
      <Page
        title="Performance Monitor"
        drawerContents={<HybridTopoSidebar sidebarDetailsPanel={sidebarDetailsPanel} />}
        drawerProps={{ extraOffset: 43 }}
        subnavTools={
          <Button
            text="Details"
            icon={AiOutlineMenuFold}
            active={drawerIsOpen}
            onClick={this.handleDrawerToggle}
            disabled={!sidebarDetailsPanel}
            minimal
          />
        }
        drawerIsOpen={drawerIsOpen}
        drawerOnClose={(e) => {
          if (e && e.key === 'Escape' && drawerIsOpen) {
            this.handleDrawerToggle();
          }
        }}
        toolbar={
          <HybridTopoSettingsToolbar
            moduleName="cloudperformance"
            onSettingsUpdate={saveSettings}
            model={settingsModel}
            collection={this.nodeCollection}
          >
            {({ mapSearch, timeSelector, cloudProvidersFilter }) => (
              <ToolbarWrapper bg="subnavBackground">
                <Flex px={3} py="4px" justifyContent="space-between">
                  <Flex>
                    <Box width={220} pr={1}>
                      {timeSelector}
                    </Box>
                    {this.selectedTabId === 'interconnects' && mapSearch}
                  </Flex>
                  {this.selectedTabId === 'services' && cloudProvidersFilter}
                </Flex>
              </ToolbarWrapper>
            )}
          </HybridTopoSettingsToolbar>
        }
        scrolls
      >
        <Flex mb={2} alignItems="flex-start" justifyContent="space-between">
          <PageHeading title="Performance Monitor" />

          <AgentSummary
            flex="none"
            toggleAgentManagement={this.toggleAgentManagement}
            unmonitoredVpcs={unmonitoredVpcs}
            pendingAgents={pendingAgents}
            privateAgents={privateAgents}
          />
        </Flex>

        <Text mb={1} muted>
          The Cloud Performance Monitor keeps track of cloud service network performance. In-use services are detected
          in flow log records and API metadata. Click <Text fontWeight="bold">Manage Agents</Text> to see where
          Synthetic agents should be deployed for full coverage. Once agents are deployed, select{' '}
          <Text fontWeight="bold">Update Tests</Text> to automatically configure appropriate tests to the discovered
          services. Click on the settings icon to add or remove services from being tested.
        </Text>

        <Tabs onChange={this.handleTabChange} selectedTabId={this.selectedTabId} renderActiveTabPanelOnly minimal>
          <Tab
            id="interconnects"
            title="Interconnects"
            panel={
              <CloudInterconnects
                wrapperProps={{ flex: 1, css: wrapperCSS }}
                setSidebarDetails={setSidebarDetails}
                cloudProvider="aws"
                sidebarSettings={sidebarSettings}
                collection={this.nodeCollection}
                drawerIsOpen={drawerIsOpen}
                {...agentManagementProps}
              />
            }
          />
          <Tab
            id="services"
            title="Services"
            panel={
              <CloudServices
                sidebarSettings={sidebarSettings}
                saveSettings={saveSettings}
                onAgentCountUpdate={this.handleAgentCountUpdate}
                {...agentManagementProps}
              />
            }
          />
        </Tabs>
      </Page>
    );
  }
}

export default withHybridTopoSettings(CloudPerformance, { module: 'cloudperformance' });
