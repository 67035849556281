import React, { Component } from 'react';
import { formConsumer, TextArea } from 'core/form';
import { dateTimeFormatter } from 'core/util/dateUtils';
import AdminTable from 'app/components/admin/AdminTable';
import { Box, Button, DropdownMenu, Flex } from 'core/components';
import { observer } from 'mobx-react';
import { CELL_ACTIONS, CELL_TYPES } from 'core/components/table';

@formConsumer
@observer
export default class CompanyNotes extends Component {
  state = {
    note: undefined
  };

  getColumns = () => [
    {
      label: 'Note',
      name: 'note_content',
      flexBasis: 200
    },
    {
      label: 'Author',
      name: 'user.user_full_name'
    },
    {
      label: 'Created',
      name: 'note_date',
      renderer: dateTimeFormatter
    },
    {
      type: CELL_TYPES.ACTION,
      actions: [CELL_ACTIONS.REMOVE]
    }
  ];

  onChange = e => this.setState({ note: e.target.value });

  onAddNoteClick = () => {
    const { note } = this.state;
    const { form } = this.props;
    const company = form.model;
    const newNote = form.model.get('notes').forge({ note, company_id: company.id });
    return newNote.save().then(() => company.fetch());
  };

  onCancelNoteClick = () => {
    this.setState({ note: null });
  };

  render() {
    const { form } = this.props;
    return (
      <Flex flexDirection="column" flex={1} overflow="auto">
        <AdminTable
          collection={form.model.get('notes')}
          columns={this.getColumns()}
          containerProps={{ overflow: 'auto' }}
          tableContainerProps={{ overflow: 'auto' }}
          fetchCollectionOnMount={false}
          tableHeaderControlsPosition="afterSearch"
          tableHeaderControls={
            <DropdownMenu
              content={
                <Box p={2}>
                  <TextArea onChange={this.onChange} placeholder="Note..." />
                  <Flex mt={1} flexAuto justifyContent="space-between">
                    <Button intent="primary" text="Add" className="bp4-popover-dismiss" onClick={this.onAddNoteClick} />
                    <Button className="bp4-popover-dismiss" text="Cancel" onClick={this.onCancelNoteClick} />
                  </Flex>
                </Box>
              }
            >
              <Button ml={2} icon="plus" text="Add Note" intent="primary" rightIcon="caret-down" />
            </DropdownMenu>
          }
          flexed
        />
      </Flex>
    );
  }
}
