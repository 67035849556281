import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { formatDateTime } from 'core/util/dateUtils';
import { CELL_TYPES } from 'core/components/table';
import { Box, Button, Flex, Tag, Text, Tooltip } from 'core/components';
import AdminTable from 'app/components/admin/AdminTable';
import { FiPlus } from 'react-icons/fi';
import RemoveButton from 'core/form/components/RemoveButton';
import RoleSetFilterSidebar from 'app/views/rbac/RoleSetFilterSidebar';
import AuthProfile from 'app/stores/auth/AuthProfile';

@inject('$auth', '$rbac')
@withRouter
@observer
class RoleSets extends Component {
  state = {
    defaultRoleSet: undefined
  };

  componentDidMount() {
    const { $rbac, $auth } = this.props;
    $rbac.roleSetCollection.fetch({ force: true });

    const ssoProfile = new AuthProfile({
      id: $auth.getActiveUserProperty('company_id'),
      companyName: $auth.getActiveUserProperty('company.company_name')
    });

    ssoProfile.fetch({ force: true }).then(() => {
      this.setState({ defaultRoleSet: ssoProfile.get('meta_defaultSsoRoleSet') });
    });
  }

  componentWillUnmount() {
    const { $rbac } = this.props;
    $rbac.roleSetCollection.resetState();
  }

  renderRoleNames = (roleNames, max = 2) => {
    if (!roleNames?.length > 0) {
      return null;
    }
    const count = roleNames.length;
    const overCount = count > max ? count - max : 0;
    const topItems = roleNames.slice(0, max);

    return (
      <Box overflow="hidden">
        <Flex alignItems="center">
          {topItems.map((roleName, idx) => (
            <Flex key={roleName} alignItems="center">
              <Tag minimal round mr={1}>
                {roleName}
              </Tag>
              {idx === 1 && overCount > 0 && (
                <Tooltip
                  content={roleNames.slice(idx + 1).map((overItem) => (
                    <Box key={overItem}>{overItem}</Box>
                  ))}
                >
                  <Text color="primary" style={{ cursor: 'pointer' }} fontSize={10} whiteSpace="nowrap">
                    +{overCount} more
                  </Text>
                </Tooltip>
              )}
            </Flex>
          ))}
        </Flex>
      </Box>
    );
  };

  removeConfirmBodyContent = (model) => {
    const { defaultRoleSet } = this.state;
    const numMappings = model.get('ssoAttributes')?.length || 0;

    const confirmBodyContent = `${
      numMappings > 0 ? `This role set has ${numMappings} mapping${numMappings > 1 ? 's' : ''}. ` : ''
    }${
      defaultRoleSet === model.id.toString()
        ? `This role set is the default role set${numMappings > 0 ? 'as well' : ''}. `
        : ''
    }Are you sure you would like to delete this role set?`;
    return <Text>{confirmBodyContent}</Text>;
  };

  get columns() {
    const { $auth, $rbac } = this.props;
    const { history } = this.props;
    return [
      {
        name: 'name',
        label: 'Role Set Name',
        flexBasis: 20
      },
      {
        name: 'roles',
        label: 'Roles',
        ellipsis: false,
        flexBasis: 30,
        renderer: ({ model }) => {
          const roles = model.get('roles');
          const roleModels = $rbac.collection.filter((roleModel) => roles.includes(roleModel.id), {
            immutable: true
          });
          const roleNames = roleModels.map((roleModel) => roleModel.get('role_name')).sort();
          return this.renderRoleNames(roleNames);
        }
      },
      {
        name: 'ssoAttributes.length',
        label: '# Mappings',
        align: 'center',
        flexBasis: 8
      },
      {
        name: 'users.length',
        label: '# Users',
        align: 'center',
        flexBasis: 8
      },
      {
        name: 'cdate',
        label: 'Created',
        flexBasis: 10,
        renderer: ({ value }) => formatDateTime(value)
      },
      {
        name: 'edate',
        label: 'Last Modified',
        flexBasis: 10,
        renderer: ({ value }) => formatDateTime(value)
      },
      {
        type: CELL_TYPES.ACTION,
        width: 72,
        actions: [
          (model) => {
            const viewOnly = !$auth.hasRbacPermissions(['rbac.role::update']) || model.attributes.company_id === null;

            return (
              <Button
                key="edit"
                icon={viewOnly ? 'eye-open' : 'edit'}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/v4/settings/rbac/roleSetDetails/${model.id}`);
                }}
                text=""
                title="Edit"
                minimal
                small
              />
            );
          },
          (model) => {
            if (!$auth.hasRbacPermissions(['rbac.role::delete']) || model.attributes.company_id === null) {
              return <div key="remove" />;
            }

            return (
              <RemoveButton
                key="remove"
                popoverProps={{
                  confirmBodyContent: this.removeConfirmBodyContent(model)
                }}
                model={model}
                showIcon
                hideText
                small
              />
            );
          }
        ]
      }
    ];
  }

  onCreate = () => {
    const { history } = this.props;
    history.push('/v4/settings/rbac/roleSetDetails/');
  };

  render() {
    const { $rbac, $auth } = this.props;
    return (
      <Flex flexDirection="column" flex={1}>
        <AdminTable
          collection={$rbac.roleSetCollection}
          columns={this.columns}
          tableHeaderControlsPosition="afterSearch"
          tableHeaderControls={
            $auth.hasRbacPermissions(['rbac.role::create']) && (
              <Box ml={1}>
                <Button
                  key="create"
                  intent="primary"
                  icon={FiPlus}
                  onClick={this.onCreate}
                  text="Create a Role Set"
                  title="Create a Role Set"
                />
              </Box>
            )
          }
          filterSidebar={<RoleSetFilterSidebar collection={$rbac.roleSetCollection} />}
        />
      </Flex>
    );
  }
}

export default RoleSets;
