import React, { Component, Fragment } from 'react';
import { Card, Box, Heading, Flex, Text, LinkButton, MenuDivider } from 'core/components';
import styled from 'styled-components';
import MetricsExplorerResult from '../MetricsExplorerResult';

const Wrap = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  h5 {
    background: ${({ theme }) => theme.backgrounds.tableHeader};
    border-radius: 4px 4px 0 0;
    margin: 0;
    font-size: 14px;
    padding: 8px 16px;
    width: 100%;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  height: 100%;
`;

export default class ExampleQueriesCard extends Component {
  // prevents unnecessary re-renders
  shouldComponentUpdate(nextProps) {
    const { chart_query } = this.props;
    return chart_query !== nextProps.chart_query;
  }

  render() {
    const { title, chart_title, chart_query, examples, onSelectQuery } = this.props;
    return (
      <Wrap>
        <Heading level={5}>{title}</Heading>
        <Inner>
          <Flex flexDirection="column" gap={1} flex={1}>
            <Text small muted as="div" p={1} pb={0} textAlign="center">
              {chart_title}
            </Text>
            <Flex flex={1}>
              <MetricsExplorerResult query={chart_query} isWidget noLegend />
            </Flex>
          </Flex>
          <Box p={2} pl={0} flex={1}>
            <Flex flexDirection="column" gap={0}>
              <Text muted small fontWeight="medium" pb={1}>
                Example queries for {title}
              </Text>
              {examples.map((query, index) => (
                <Fragment key={query}>
                  <LinkButton
                    onClick={() => onSelectQuery(query)}
                    minimal
                    intent="primary"
                    style={{ justifyContent: 'flex-start', textAlign: 'left' }}
                  >
                    {query}
                  </LinkButton>
                  {index !== examples.length - 1 && <MenuDivider />}
                </Fragment>
              ))}
            </Flex>
          </Box>
        </Inner>
      </Wrap>
    );
  }
}
