import React from 'react';
import { MdEmail } from 'react-icons/md';
import { GoTerminal } from 'react-icons/go';
import OttIcon from '../serviceProvider/ott/OttIcon';
import PeeringDbMapDialog from './PeeringDbMapDialog';

const DDOS = 'DDoS Mitigation Platforms';
const NOTIFICATIONS = 'Notification Channels';
const OPERATIONS = 'Operations';
const SSO = 'SSO Platforms';
const NET = 'Network Platforms';

const ssoHref = 'https://kb.kentik.com/v3/Cb11.htm#Cb11-SSO_Identity_Providers';
const notificationsPath = '/v4/settings/notifications';
const ddosPath = '/v4/settings/mitigations';

const availableIntegrations = {
  a10: {
    name: 'A10',
    group: DDOS,
    logo: <OttIcon type="integration" value="a10" width={75} />,
    href: ddosPath
  },

  adfs: {
    name: 'ADFS',
    group: SSO,
    logo: <OttIcon type="integration" value="adfs" width={130} />,
    href: ssoHref
  },

  aws: {
    name: 'AWS',
    group: SSO,
    logo: <OttIcon type="integration" value="aws" useAlternateDarkLogo width={50} />,
    href: ssoHref
  },

  bitium: {
    name: 'Bitium',
    group: SSO,
    logo: <OttIcon type="integration" value="bitium" />,
    href: ssoHref
  },

  cloudflare: {
    name: 'Cloudflare',
    group: DDOS,
    logo: <OttIcon type="integration" value="cloudflare" useAlternateDarkLogo />,
    to: ddosPath
  },

  'custom-webhook': {
    name: 'Webhook',
    group: NOTIFICATIONS,
    logo: <OttIcon type="integration" value="webhooks" height={50} />,
    showName: true,
    height: 50,
    to: notificationsPath
  },

  duo: {
    name: 'Duo',
    group: SSO,
    logo: <OttIcon type="integration" value="duo" width={80} />,
    href: ssoHref
  },

  email: {
    name: 'Email',
    group: NOTIFICATIONS,
    icon: MdEmail,
    iconSize: 54,
    showName: true,
    to: notificationsPath
  },

  google: {
    name: 'Google',
    group: SSO,
    logo: <OttIcon type="integration" value="gsuite" width={45} />,
    href: ssoHref
  },

  grafana: {
    name: 'Grafana',
    group: OPERATIONS,
    logo: <OttIcon type="integration" value="grafana" useAlternateDarkLogo height={80} width={110} />,
    href: 'https://github.com/kentik/kentik-grafana-app'
  },

  msteams: {
    name: 'Microsoft Teams',
    group: NOTIFICATIONS,
    logo: <OttIcon type="integration" value="msteams" height={45} />,
    showName: true,
    height: 45,
    to: notificationsPath
  },

  okta: {
    name: 'Okta',
    group: SSO,
    logo: <OttIcon type="integration" value="okta" width={75} />,
    href: ssoHref
  },

  onelogin: {
    name: 'Onelogin',
    group: SSO,
    logo: <OttIcon type="integration" value="onelogin" useAlternateDarkLogo width={100} />,
    href: ssoHref
  },

  opsgenie: {
    name: 'OpsGenie',
    group: NOTIFICATIONS,
    logo: <OttIcon type="integration" value="opsgenie" width={130} />,
    to: notificationsPath
  },

  pagerduty: {
    name: 'PagerDuty',
    group: NOTIFICATIONS,
    logo: <OttIcon type="integration" value="pagerduty" />,
    to: notificationsPath
  },

  peeringdb: {
    name: 'PeeringDB',
    group: NET,
    logo: <OttIcon type="integration" value="peeringdb" useAlternateDarkLogo />,
    DialogCmp: PeeringDbMapDialog
  },

  pingid: {
    name: 'PingID',
    group: SSO,
    logo: <OttIcon type="integration" value="ping" />,
    href: ssoHref
  },

  radware: {
    name: 'Radware',
    group: DDOS,
    logo: <OttIcon type="integration" value="radware" useAlternateDarkLogo width={110} />,
    to: ddosPath
  },

  secureauth: {
    name: 'Secureauth',
    group: SSO,
    logo: <OttIcon type="integration" value="secureauth" useAlternateDarkLogo width={140} />,
    href: ssoHref
  },

  servicenow: {
    name: 'ServiceNow',
    group: NOTIFICATIONS,
    logo: <OttIcon type="integration" value="servicenow" useAlternateDarkLogo width={120} />,
    to: notificationsPath
  },

  shibboleth: {
    name: 'Shibboleth',
    group: SSO,
    logo: <OttIcon type="integration" value="shibboleth" width={60} height={60} />,
    showName: true,
    href: ssoHref
  },

  slack: {
    name: 'Slack',
    group: NOTIFICATIONS,
    logo: <OttIcon type="integration" value="slack" useAlternateDarkLogo width={100} />,
    to: notificationsPath
  },

  splunk: {
    name: 'Splunk',
    group: NOTIFICATIONS,
    logo: <OttIcon type="integration" value="splunk" height={100} />,
    height: 100,
    to: notificationsPath
  },

  syslog: {
    name: 'Syslog',
    group: NOTIFICATIONS,
    icon: GoTerminal,
    iconSize: 54,
    showName: true,
    to: notificationsPath
  },

  victorops: {
    name: 'VictorOps',
    group: NOTIFICATIONS,
    logo: <OttIcon type="integration" value="victorops" height={65} useAlternateDarkLogo />,
    height: 65,
    to: notificationsPath
  },

  xmatters: {
    name: 'xMatters',
    group: NOTIFICATIONS,
    logo: <OttIcon type="integration" value="xmatters" useAlternateDarkLogo />,
    to: notificationsPath
  }
};

export { availableIntegrations };

export default Object.values(availableIntegrations);
