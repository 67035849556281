import React from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Card, Flex, Grid, Heading, Tag } from 'core/components';
import DeviceSubtypeIcon from 'app/components/device/DeviceSubtypeIcon';
import SiteLink from 'app/components/links/SiteLink';
import { getHealthState } from 'app/views/hybrid/utils/health';
import SidebarItem from '../SidebarItem';
import CPUChart from '../healthDetails/CPUChart';
import MemoryChart from '../healthDetails/MemoryChart';
import HealthItem from '../healthDetails/HealthItem';

@inject('$devices')
@observer
export default class SiteDevices extends React.Component {
  state = {
    devices: []
  };

  constructor(props) {
    super(props);
    const { devices, healthIssues, $devices } = this.props;

    this.state.devices = devices.map(({ id }) => {
      const device = $devices.collection.get(id);
      return { device, healthIssues: healthIssues.getIssues({ device_id: id }) };
    });
  }

  render() {
    const { devices } = this.state;

    return devices.map(({ device, healthIssues }, idx) => {
      const badgeCounts = healthIssues.byState;
      const deviceName = device.get('device_name');

      const deviceMetricCheckNames = ['device_performance_cpu_busy', 'device_performance_mem_in_use'];
      const remainingHealthModels = healthIssues.models.filter(
        (healthIssue) => !deviceMetricCheckNames.includes(healthIssue.get('check.check_name'))
      );

      return (
        <SidebarItem
          key={device.id}
          title={
            <Flex gap={2}>
              <Heading level={5} mb={0}>
                {deviceName}
              </Heading>
              {Object.entries(badgeCounts)
                .filter(([, count]) => count > 0)
                .map(([state, count]) => {
                  const { intent, name } = getHealthState(state);
                  return (
                    <Flex key={state} gap="4px">
                      <Tag intent={intent} round small>
                        {count}
                      </Tag>
                      <Box>{name}</Box>
                    </Flex>
                  );
                })}
            </Flex>
          }
          dialogProps={{ width: 800 }}
          icon={<DeviceSubtypeIcon type={device.get('device_subtype')} />}
          isLast={idx === devices.length - 1}
        >
          <Card p={1}>
            <Grid gridTemplateColumns="auto auto">
              <Box color="muted">IP Address</Box>
              <Box>{device.sendingIpsDisplay}</Box>

              <Box color="muted">Site</Box>
              <Box>
                <SiteLink siteId={device.get('site_id')}>{device.get('site_name')}</SiteLink>
              </Box>

              {device.hasVendor && (
                <>
                  <Box color="muted">Model</Box>
                  <Box>
                    {device.get('device_vendor_type')} {device.get('device_model_type')}
                  </Box>
                </>
              )}

              <Box color="muted">Sample Rate</Box>
              <Box>1:{device.get('device_sample_rate')}</Box>

              <Box color="muted">Machine Type</Box>
              <Box>{device.deviceType}</Box>

              <Box color="muted">Device ID</Box>
              <Box>{device.id}</Box>
            </Grid>

            <Box borderTop="thin" pt={2} mt={2}>
              <Flex flexDirection="column" gap={4}>
                <CPUChart deviceName={deviceName} />
                <MemoryChart deviceName={deviceName} />
              </Flex>
            </Box>

            {remainingHealthModels.length > 0 && (
              <Box borderTop="thin" pt={2} mt={2}>
                {remainingHealthModels.map((healthIssue) => (
                  <HealthItem key={healthIssue.id} healthIssue={healthIssue} />
                ))}
              </Box>
            )}
          </Card>
        </SidebarItem>
      );
    });
  }
}
