import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Dialog, showSuccessToast } from 'core/components';
import { Form, Field, TextArea } from 'core/form';

const fields = {
  type: {
    label: 'Type',
    rules: 'required',
    defaultValue: 'report issue'
  },
  summary: {
    label: 'Summary',
    placeholder: 'What can we help you with?',
    rules: 'required'
  },
  description: {
    label: 'Additional Details',
    placeholder: "Let us know if you have any feedback or questions. We'd love to hear from you!"
  }
};

const options = {
  name: 'Contact Account Team',
  suppressFormValues: true
};

@Form({ fields, options })
@inject('$companySettings')
@observer
export default class ContactAccountTeamDialog extends Component {
  state = {
    sendingEmail: false
  };

  handleSubmit = () => {
    this.setState({
      sendingEmail: true
    });
    const { $companySettings, form, onClose, contactMember } = this.props;

    $companySettings.contactAccountTeam(form.getValues(), contactMember).then(() => {
      this.setState({ sendingEmail: false });
      showSuccessToast('Feedback was submitted successfully. Thanks!');

      form.reset();
      onClose();
    });
  };

  handleClose = () => {
    const { form, onClose } = this.props;
    form.reset();
    onClose();
  };

  render() {
    const { sendingEmail } = this.state;
    const { form, isOpen, contactMember } = this.props;

    const title = `Contact ${contactMember ? contactMember.user.user_full_name : 'Account Team'}`;

    return (
      <Dialog
        title={title}
        isOpen={isOpen}
        onClose={this.handleClose}
        position="absolute"
        top={100}
        width={460}
        minHeight={408}
      >
        <Dialog.Body>
          <Field name="summary" disabled={sendingEmail}>
            <TextArea rows={2} fill />
          </Field>
          <Field name="description" disabled={sendingEmail}>
            <TextArea rows={6} fill />
          </Field>
        </Dialog.Body>
        <Dialog.Footer justifyContent="space-between">
          <Button text="Cancel" onClick={this.handleClose} minWidth={110} mr={1} />
          <Button
            disabled={!form.valid || !form.dirty}
            intent="primary"
            loading={sendingEmail}
            text="Submit"
            onClick={this.handleSubmit}
            minWidth={110}
          />
        </Dialog.Footer>
      </Dialog>
    );
  }
}
