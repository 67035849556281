import React from 'react';
import { observer } from 'mobx-react';
import { VirtualizedTable, Search } from 'core/components/table';
import { Box, Button, Card, Flex, Icon, Text, Tooltip } from 'core/components';
import Collection from 'core/model/Collection';

import { FiCopy } from 'react-icons/fi';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';

@observer
class RouteTable extends React.Component {
  constructor(props) {
    super(props);
    const { routes } = props;

    this.routesCollection = new Collection(routes);
  }

  get columns() {
    const { isPoppedOut } = this.props;
    const columns = [
      {
        label: 'Destination',
        name: 'destination',
        width: 150,
        renderer: ({ value }) => (
          <Flex alignItems="center" gap={1}>
            <CopyToClipboardButton text={value} copyConfirmationText={null} intent="none" minimal small>
              <Button icon={FiCopy} />
            </CopyToClipboardButton>
            <Box>
              <Text as="div" width={150} ellipsis>
                {value}
              </Text>
            </Box>
          </Flex>
        )
      },
      {
        label: 'Route Target',
        name: 'target',
        width: 200,
        renderer: ({ value }) => {
          const { nextHop } = value;
          return (
            <Flex alignItems="flex-start" flexDirection="column">
              <Flex alignItems="center" gap={1}>
                <CopyToClipboardButton text={nextHop} copyConfirmationText={null} intent="none" minimal small>
                  <Button icon={FiCopy} />
                </CopyToClipboardButton>

                <Tooltip content={<div style={{ maxWidth: 200 }}>{nextHop}</div>} className="widget-tooltip">
                  <Text as="div" width={200} ellipsis>
                    {nextHop}
                  </Text>
                </Tooltip>
              </Flex>
            </Flex>
          );
        }
      },
      {
        label: 'Priority',
        name: 'priority',
        width: 60,
        renderer: ({ value }) => (
          <Flex alignItems="center" gap={1}>
            <Box>
              <Text as="div" width={60} ellipsis>
                {value}
              </Text>
            </Box>
          </Flex>
        )
      }
    ];
    const nextHopType = {
      label: 'Target Type',
      name: 'nextHopType',
      width: 145,
      renderer: ({ value }) => (
        <Flex alignItems="center" gap={1}>
          <Box>
            <Text as="div" width={145} ellipsis>
              {value}
            </Text>
          </Box>
        </Flex>
      )
    };

    const name = {
      label: 'Name',
      name: 'name',
      width: 250,
      renderer: ({ value }) => (
        <Flex alignItems="center" gap={1}>
          <CopyToClipboardButton text={value} copyConfirmationText={null} intent="none" minimal small>
            <Button icon={FiCopy} />
          </CopyToClipboardButton>
          <Box>
            <Text as="div" width={250} ellipsis>
              {value}
            </Text>
          </Box>
        </Flex>
      )
    };
    const tags = {
      label: 'Tags',
      name: 'tags',
      width: 150,
      renderer: ({ value }) => {
        const hasTags = Array.isArray(value) && value?.length;
        const tagString = value.join();
        return (
          <Flex alignItems="center" gap={1}>
            {hasTags ? (
              <CopyToClipboardButton text={tagString} copyConfirmationText={null} intent="none" minimal small>
                <Button icon={FiCopy} />
              </CopyToClipboardButton>
            ) : (
              '-'
            )}
            <Box>
              <Text as="div" width={150} ellipsis>
                {tagString}
              </Text>
            </Box>
          </Flex>
        );
      }
    };

    const warnings = {
      label: 'Warnings',
      name: 'warnings',
      width: 200,
      renderer: ({ value }) => {
        if (value?.length) {
          const { code, message } = value[0];
          return (
            <Flex alignItems="center" gap={1}>
              <Icon icon="error" color="danger" />
              <CopyToClipboardButton
                text={`${code} : ${message}`}
                copyConfirmationText={null}
                intent="none"
                minimal
                small
              >
                <Button icon={FiCopy} />
              </CopyToClipboardButton>
              <Box>
                <Tooltip
                  content={<div style={{ maxWidth: 200 }}>{`${code} : ${message}`}</div>}
                  className="widget-tooltip"
                >
                  <Text as="div" width={200} ellipsis>
                    {code}
                  </Text>
                </Tooltip>
              </Box>
            </Flex>
          );
        }
        return <Text as="div">-</Text>;
      }
    };

    if (isPoppedOut) {
      columns.push(nextHopType, name, tags, warnings);
    }
    return columns;
  }

  handleSearch = (e) => this.routesCollection.filter(e.target.value);

  render() {
    return (
      <Card height="100%">
        <Search
          p="4px"
          collection={this.routesCollection}
          onChange={this.handleSearch}
          placeholder="Search Route Table..."
          inputProps={{ value: this.routesCollection.filterState }}
          autoFocus
        />
        <VirtualizedTable
          style={{ height: 400 }}
          collection={this.routesCollection}
          columns={this.columns}
          selectOnRowClick={false}
          isCollapsed
          flexed
        />
      </Card>
    );
  }
}

export default RouteTable;
