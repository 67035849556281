import React from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { get, isEqual } from 'lodash';
import { Box, Grid, Spinner, EmptyState } from 'core/components';
import CloudsWithLinks from 'app/views/hybrid/maps/components/CloudsWithLinks';
import InterCloudLinkGenerator from 'app/views/hybrid/maps/components/InterCloudLinkGenerator';
import AbstractMap from './components/AbstractMap';
import TopLevelBox from './components/TopLevelBox';
import BoxLinkGenerator from './components/BoxLinkGenerator';
import NodeLinkGenerator from './components/NodeLinkGenerator';
import TopKeys from './components/TopKeys/TopKeys';
import withPopover from './components/popovers/withPopover';
import { onlyTopTargetLinksFor } from '../utils/links';

@withPopover
@inject('$explorer', '$hybridMap')
@observer
class MultiCloudOverviewMap extends AbstractMap {
  constructor(props) {
    super(props);
    const { $hybridMap } = this.props;

    Object.assign(this.state, {
      boxExpanded: {
        cloud: $hybridMap.clouds.length > 0,
        internet: true
      }
    });
  }

  componentDidMount() {
    this.fetchTopology({ force: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarSettings } = this.props;
    const showInterSiteTrafficChanged =
      prevProps.sidebarSettings.showInterSiteTraffic !== sidebarSettings.showInterSiteTraffic;
    const interSiteTrafficChanged = prevProps.sidebarSettings.interSiteTraffic !== sidebarSettings.interSiteTraffic;
    const filtersChanged = !isEqual(
      get(prevProps.sidebarSettings, 'sidebarQueryOverrides.filters'),
      get(sidebarSettings, 'sidebarQueryOverrides.filters')
    );

    if (prevProps.sidebarSettings.connectionType !== sidebarSettings.connectionType) {
      this.fetchTopology({ fromCache: true });
    }

    if (filtersChanged || showInterSiteTrafficChanged || interSiteTrafficChanged) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ linkFlowTrafficLoading: this.hasSidebarFilters || sidebarSettings.showInterSiteTraffic });
    }

    super.componentDidUpdate(prevProps, prevState);
  }

  fetchTopology(options) {
    const { $hybridMap } = this.props;

    $hybridMap
      .getSiteTopologyData(options)
      .then((topology) => {
        this.setState({ topology, loading: false });
      })
      .catch((e) => {
        console.error('Error loading topology', e);
        this.setState({ loading: false });
      });
  }

  get chordType() {
    return 'site';
  }

  handleSelectNode({ type, value, force = false, nodeData }) {
    const { isEmbedded, openPopover } = this.props;
    const { selectedNode } = this.state;

    super.handleSelectNode({ type, value, force, nodeData });

    this.setState((prevState) => {
      if (prevState.selectedNode) {
        const isSameNode = isEqual(selectedNode, prevState.selectedNode);
        const { points } = this.getNodePosition(prevState.selectedNode);
        if (points.length > 0) {
          const [x, y] = points[0];

          openPopover({
            ...prevState.selectedNode,
            position: { left: x, top: y },
            shortcutMenu: {
              selectedNode: prevState.selectedNode,
              showConnectionsCallback: this.setNodeLinks,
              isShowingConnections: prevState.nodeLinks.length > 0 && isSameNode
            },
            isEmbedded
          });
        }
      }

      return null;
    });
  }

  getNodeLinkQueries({ selectedNode, activeInternetTabId } = this.state) {
    const { $hybridMap } = this.props;

    if (selectedNode) {
      const { type: source, value: sourceValue } = selectedNode;
      const types = ['cloud', 'internet', 'site'].filter((type) => type !== source);

      return types.flatMap((target) => {
        const type = target === 'internet' ? activeInternetTabId : target;
        let sites = null;

        if (source === 'site' || target === 'site') {
          if ($hybridMap.sites === 0) {
            return [];
          }

          sites = $hybridMap.sites.map(({ id }) => id);
        }

        const [inboundQuery, outboundQuery] = $hybridMap.getNodeLinkQuery(
          {
            selectedNode: { ...selectedNode, value: sourceValue },
            type
          },
          { sites }
        );

        if (inboundQuery && outboundQuery) {
          return [
            { direction: 'inbound', source, sourceValue, target, query: inboundQuery },
            { direction: 'outbound', source, sourceValue, target, query: outboundQuery }
          ];
        }

        return [];
      });
    }

    return [];
  }

  handleNodeLinksUpdate(nodeLinks) {
    const { $hybridMap } = this.props;
    const { selectedNode, activeInternetTabId } = this.state;

    nodeLinks = nodeLinks.filter(({ source, target }) => {
      const site = (source.type === 'site' && source.value) || (target.type === 'site' && target.value);
      return !site || $hybridMap.sites.find(({ name }) => name === site);
    });

    if (selectedNode && selectedNode.type === 'internet' && activeInternetTabId === 'asn') {
      nodeLinks = onlyTopTargetLinksFor('site', nodeLinks);
    }

    super.handleNodeLinksUpdate(nodeLinks);
  }

  handleNodeLinkClick = (config) => {
    const { setSidebarDetails, isEmbedded } = this.props;
    const { nodeLinks, activeInternetTabId } = this.state;
    const { source, target } = config;

    // @TODO link popovers with cloud targets are currently not supported
    if (!isEmbedded && target.value !== 'cloud') {
      if (source.type === 'internet') {
        // the sub type isn't shipped here, we need to manually add it
        source.subType = activeInternetTabId;
      }

      setSidebarDetails({
        ...config,
        source,
        links: nodeLinks,
        internetSubType: activeInternetTabId
      });
    }
  };

  handleSiteLinkClick = (config) => {
    const { $hybridMap, setSidebarDetails, isEmbedded } = this.props;

    if (!isEmbedded) {
      setSidebarDetails({ ...config, links: $hybridMap.normalizedSiteLinks });
    }
  };

  handleFlowTrafficUpdate = (links) => {
    this.setState(({ topology }) => ({
      linkFlowTrafficLoading: false,
      topology: {
        ...topology,
        links
      }
    }));
  };

  handleBoxLinksUpdate = (boxLinks) => {
    const { $hybridMap } = this.props;
    let links = this.cleanBoxLinkTraffic(boxLinks);

    if (!$hybridMap.hasSites) {
      // if there are no sites, null out the traffic
      links = boxLinks.map((link) => {
        if (link.to === 'site') {
          link.bytesIn = null;
          link.bytesOut = null;
        }

        return link;
      });
    }

    this.setState({ boxLinks: links });
  };

  renderMap() {
    const { $hybridMap, width, isPopoverOpen, setPopoverPosition } = this.props;
    const { boxExpanded, nodeLinkQueries, nodeLinksLoading, linkFlowTrafficLoading, topology, loading } = this.state;
    const links = topology && topology.links;

    return (
      <Box>
        {(nodeLinksLoading || linkFlowTrafficLoading) && (
          <Box key="linksLoading" position="absolute" top="300px" width="100%">
            <Spinner intent="primary" />
          </Box>
        )}

        <Box>
          <Grid gridTemplateAreas="'prem internet' 'clouds clouds'" gridGap={5} gridRowGap={100}>
            <Box gridArea="prem">
              <TopLevelBox
                title="On Prem"
                boxProps={{ p: 0, width: 500, height: 233, className: 'box-site' }}
                expandedContent={<EmptyState icon="social-media" description="No on-prem sites found" />}
                isExpanded
              />
            </Box>

            <Box gridArea="internet" justifySelf="flex-end">
              <TopLevelBox
                title="Internet"
                boxProps={{
                  className: classNames('box-internet', 'link-bottomleft', {
                    highlighted: this.isBoxHighlighted('internet')
                  }),
                  width: 500,
                  height: 233
                }}
                onExpandToggle={(isExpanded) => this.setBoxExpanded('internet', isExpanded)}
                expandedContent={
                  <TopKeys
                    classPrefix="internet"
                    selected={boxExpanded.internet && this.getSelected('internet')}
                    highlighted={this.getHighlighted('internet')}
                    onSelect={(internet) => this.handleSelectNode({ type: 'internet', value: internet })}
                    onTabChange={this.handleInternetTabChange}
                  />
                }
                isExpanded={boxExpanded.internet}
                keepChildrenMounted
              />
            </Box>

            <Box gridArea="clouds">
              <TopLevelBox
                title="Clouds"
                boxProps={{
                  className: classNames('box-cloud', 'link-bottomright', {
                    highlighted: this.isBoxHighlighted('cloud')
                  }),
                  minHeight: 200
                }}
                expandedContent={
                  <CloudsWithLinks
                    height={200}
                    width={width - 4}
                    loading={loading}
                    clouds={$hybridMap.cloudsWithIndex}
                    links={links}
                    selected={this.getSelected('cloud')}
                    highlighted={this.getHighlighted('cloud')}
                    setPopoverPosition={setPopoverPosition}
                    onSelect={(cloud) => this.handleSelectNode({ type: 'cloud', value: cloud.provider })}
                    onLinkClick={this.handleSiteLinkClick}
                    isPopoverOpen={isPopoverOpen}
                  />
                }
                isExpanded
              />
            </Box>
          </Grid>
        </Box>

        {!loading && (
          <BoxLinkGenerator
            boxes={{
              cloudInternetLink: {
                orientation: 'vertical',
                from: 'internet',
                to: 'cloud',
                loading: true
              }
            }}
            onBoxLinksUpdate={this.handleBoxLinksUpdate}
          />
        )}

        <NodeLinkGenerator queries={nodeLinkQueries} onLinksUpdate={this.handleNodeLinksUpdate} />

        {!loading && <InterCloudLinkGenerator onFlowTrafficUpdate={this.handleFlowTrafficUpdate} />}
      </Box>
    );
  }
}

export default MultiCloudOverviewMap;
