import * as React from 'react';
import { Box, Flex, Text } from 'core/components';
import NodeGroup from './NodeGroup';
import { getPathCounts } from '../cloudPerformanceUtil';

export default class PathSummary extends React.Component {
  get pathSummaries() {
    const { paths } = this.props;

    return (
      <Box>
        {paths.map((path) => {
          const id = path.reduce((acc, segment) => `${acc}-${segment.id}`, '');

          return <NodeGroup key={id} nodes={path} mini />;
        })}
      </Box>
    );
  }

  render() {
    const { paths, ...rest } = this.props;
    const { hasTestCount, totalCount } = getPathCounts(paths);

    return (
      <Flex alignItems="center" {...rest}>
        <Text>{`${hasTestCount} of ${totalCount} path${totalCount !== 1 ? 's' : ''} configured with tests`}</Text>
      </Flex>
    );
  }
}
