import { getQueryTimeInterval } from './dateUtils';

export default function bytesToBitrate(bytes, query) {
  if (!bytes) {
    return 0;
  }

  const timeInterval = getQueryTimeInterval(query);

  return (bytes * 8) / timeInterval;
}
