import React from 'react';

import { inject } from 'mobx-react';
import { Flex, Text } from 'core/components';

import SidebarItem from '../../SidebarItem';
import CloudMetadata from '../../CloudMetadata';
import { AzureVpnSiteLinkDetails } from '.';

const AzureVpnLinkConnectionDetails = ({ width, popoutTitle, $hybridMap, nodeData }) => {
  const { azureCloudMapCollection } = $hybridMap;

  const vpnSiteLink = azureCloudMapCollection.getEntityProperty(nodeData, 'vpnSiteLink');

  return (
    <SidebarItem
      excludeFormProps
      title={nodeData?.name}
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="antenna"
      popoutTitle={popoutTitle}
    >
      <Flex flexDirection="column" gap={1}>
        <CloudMetadata
          node={{
            ...nodeData,
            ...nodeData.properties
          }}
          keys={[
            'provisioningState',
            'connectionBandwidth',
            'vpnConnectionProtocolType',
            'ingressBytesTransferred',
            'egressBytesTransferred',
            'connectionStatus',
            'vpnGatewayCustomBgpAddresses'
          ]}
        />
        {vpnSiteLink && (
          <Flex flexDirection="column" gap={1}>
            <Text as="div">VPN Site Link:</Text>
            <AzureVpnSiteLinkDetails nodeData={vpnSiteLink} width={width} popoutTitle={popoutTitle} />
          </Flex>
        )}
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureVpnLinkConnectionDetails);
