import { generateFilterFields } from 'app/forms/config/helpers';

const fields = {
  user_full_name: {
    label: 'Full Name',
    rules: 'max:50'
  },

  user_email: {
    label: 'Email Address',
    rules: 'required|email',
    validateDebounceDurationMs: 500
  },

  role: {
    label: 'User Level (legacy)',
    type: 'select',
    placeholder: 'Select User Level'
  },

  user_api_token: {
    label: 'API Token'
  },

  settings: { label: 'User Permissions' },

  'settings.canCancelRawFlow': {
    defaultValue: false
  },

  ...generateFilterFields()
};

export { fields };
