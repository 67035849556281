import * as React from 'react';
import { observer } from 'mobx-react';
import { SortableHandle } from 'react-sortable-hoc';
import { greekPrefix, adjustByGreekPrefix, toDecimal } from 'core/util';
import { Box, Button, Flex, Icon, Text } from 'core/components';
import withServiceBrand from 'app/views/cloudPerformance/cloudServices/withServiceBrand';

const DragHandle = SortableHandle(() => (
  <Box ml={1} cursor="move" small>
    <Icon icon="drag-handle-vertical" color="muted" />
  </Box>
));

@withServiceBrand
@observer
export default class ServiceListItem extends React.Component {
  render() {
    const { model, serviceKey, allServicesSelected, onItemClick, serviceBrand, sortable, ...boxProps } = this.props;
    const traffic = model.get('traffic');
    const prefix = greekPrefix([traffic]);
    const { icon, fullLabel } = serviceBrand;

    return (
      <Flex alignItems="center" justifyContent="space-between" {...boxProps}>
        <Button
          textAlign="left"
          icon={icon}
          onClick={() => onItemClick(model)}
          opacity={model.get('selected') || model.get('matchesFilter') ? 1 : 0.2}
          disabled={allServicesSelected}
          minimal
          fill
        >
          <Flex justifyContent="space-between">
            <Text>{fullLabel}</Text>
            <Text>{`${toDecimal(adjustByGreekPrefix(traffic, prefix))} ${prefix}bs`}</Text>
          </Flex>
        </Button>

        {sortable && <DragHandle />}
      </Flex>
    );
  }
}
