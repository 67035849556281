import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FiMoreVertical } from 'react-icons/fi';
import { Box, Button, FlexColumn, Icon, Menu, MenuDivider, MenuItem, Popover, Text } from 'core/components';
import { formConsumer, RemoveMenuItem } from 'core/form';
import DashboardDetailsDialog from 'app/views/core/dashboards/DashboardDetailsDialog';
import DashboardItemModel from 'app/stores/dashboard/DashboardItemModel';
import DashboardPanelFormDialog from 'app/views/core/dashboards/dashboardItem/DashboardPanelFormDialog';
import SavedViewFormDialog from 'app/views/core/savedViews/SavedViewFormDialog';
import MetricsApiCallDialog from './MetricsApiCallDialog';

function normalizeWidgetQuery(query) {
  // make sure includeTimeseries is set so sparklines work
  if (!query.kmetrics.includeTimeseries) {
    query = { ...query, kmetrics: { ...query.kmetrics, includeTimeseries: query.kmetrics.limit } };
  }

  return query;
}

@inject('$app', '$dashboards', '$decks', '$exports', '$metrics', '$mkp', '$savedViews')
@withRouter
@formConsumer
@observer
export default class MetricsExplorerOptionsMenu extends Component {
  state = {
    isOpen: false,
    addToDashboardDialogOpen: false,
    showDataApiCurlDialogOpen: false,
    showChartApiCurlDialogOpen: false,
    showReportApiCurlDialogOpen: false,
    showApiJsonDialogOpen: false
  };

  componentDidMount() {
    const { $app, $mkp } = this.props;
    if (!$app.isSubtenant) {
      $mkp.tenants.fetch();
    }
  }

  handleInteraction = (isOpen) => {
    this.setState({ isOpen });
  };

  createDashboardItem = () => {
    const { queryTitle, hash } = this.props;

    // create a new DashboardItemModel model that we'll manipulate in the Dialog. We default the
    // `title` to whatever the current DataView title is.
    const dashboardItem = new DashboardItemModel();

    dashboardItem.set({
      panel_type: 'metrics',
      panel_title: queryTitle || 'My View Panel',
      saved_query_id: hash
    });

    return dashboardItem;
  };

  handleAddToDashboard = () => {
    const { $dashboards } = this.props;
    const dashboardItem = this.createDashboardItem();

    const newDash = $dashboards.collection.forge({ share_level: 'self' });
    $dashboards.collection.select(newDash);

    this.setState({ addToNewDashboardDialogOpen: true, dashboardItem });
  };

  handleAddToObservationDeck = () => {
    const { $decks, history, query, queryTitle } = this.props;

    $decks.getDefaultDeck({ type: 'home' }).then((decks) => {
      const deck = decks.find((d) => d.get('share_level') === 'self');

      deck.addWidget('reconQueryWidget', {
        query: normalizeWidgetQuery(query),
        title: queryTitle
      });

      history.push('/v4/home');
    });
  };

  handleAddToNmsLandingPage = () => {
    const { $decks, history, query, queryTitle } = this.props;

    $decks.collection.fetch().then(() => {
      $decks.getDefaultDeck({ type: 'recon', canCustomize: true }).then((decks) => {
        const deck = decks.find((d) => d.get('share_level') === 'self');

        deck.addWidget('reconQueryWidget', {
          query: normalizeWidgetQuery(query),
          title: queryTitle
        });

        history.push('/v4/nms');
      });
    });
  };

  handleAddToDashboardItem = () => {
    const dashboardItem = this.createDashboardItem();

    this.setState({ addToDashboardDialogOpen: true, dashboardItem });
  };

  handleShowSavedViewDialog = () => {
    const { $savedViews, hash, queryTitle } = this.props;

    this.setState({
      savedViewDialogOpen: true,
      savedViewModel: $savedViews.collection.forge({
        view_name: queryTitle,
        view_type: 'nms',
        saved_query_id: hash
      })
    });
  };

  handleCreateSavedView = (model) => {
    const { history } = this.props;

    history.push(model.navigatePath);
  };

  onAddNewDashboardSubmit = (dashboard) => {
    const { $dashboards } = this.props;

    $dashboards.navigateToDashboard(dashboard);
  };

  handleShowDataApiCurl = () => {
    this.setState({ showDataApiCurlDialogOpen: true });
  };

  handleShowApiJson = () => {
    this.setState({ showApiJsonDialogOpen: true });
  };

  handleShowChartApiCurl = () => {
    this.setState({ showChartApiCurlDialogOpen: true });
  };

  handleShowReportApiCurl = () => {
    this.setState({ showReportApiCurlDialogOpen: true });
  };

  onClose = () => {
    this.setState({
      addToDashboardDialogOpen: false,
      addToNewDashboardDialogOpen: false,
      savedViewDialogOpen: false,
      showDataApiCurlDialogOpen: false,
      showChartApiCurlDialogOpen: false,
      showApiJsonDialogOpen: false,
      showReportApiCurlDialogOpen: false
    });
  };

  handleRemove = () => {
    const { history, savedViewModel } = this.props;

    return savedViewModel.destroy().then(() => {
      history.push('/v4/library');
    });
  };

  @computed
  get removeProps() {
    const { savedViewModel } = this.props;

    if (!savedViewModel) {
      return null;
    }

    const confirmText = (
      <FlexColumn>
        <Text> Are you sure you want to remove &ldquo;{savedViewModel.get('view_name')}&rdquo;?</Text>
      </FlexColumn>
    );

    return {
      show: savedViewModel.canEdit,
      entityName: 'Saved View',
      model: savedViewModel,
      onRemove: this.handleRemove,
      confirmText
    };
  }

  exportCsv = () => {
    const { $metrics, hash, query, results } = this.props;
    $metrics.exportQueryResultsCollectionToCsv(results, query, hash);
  };

  exportCsvTimeSeries = () => {
    const { $metrics, hash, query, results } = this.props;
    $metrics.exportQueryResultsCollectionTimeseriesToCsv(results, query, hash);
  };

  renderOptionsMenu() {
    const { $app, $exports, $mkp, hash, onReset, savedViewId, query, savedViewModel, onPreviewTenant } = this.props;
    const path = `/v4/export/nms/explorer/${hash}`;
    return (
      <Menu>
        <MenuItem icon="export" text="Export" popoverProps={{ openOnTargetFocus: false }}>
          <MenuDivider title="Visual Report" />
          <MenuItem
            icon="document"
            text="Chart + Data Table"
            label=".pdf"
            onClick={() => {
              $exports.fetchExport({
                path: '/api/ui/export/exportPage',
                type: 'pdf',
                fileName: `nms-report-${hash}-${Date.now()}`,
                exportOptions: {
                  location: path,
                  selectorOptions: { hidden: false },
                  selector: '.nms-result'
                }
              });
            }}
          />
          <MenuItem
            icon="media"
            text="Chart Image"
            label=".png"
            disabled={query?.kmetrics?.viz?.type === 'table'}
            onClick={() => {
              $exports.fetchExport({
                path: '/api/ui/export/exportPage',
                type: 'png',
                fileName: `nms-${hash}-${Date.now()}`,
                exportOptions: {
                  location: path,
                  selectorOptions: { hidden: false },
                  selector: '.nms-result'
                }
              });
            }}
          />
          <MenuDivider title="Data" />
          <MenuItem key="timeCsv" text="Chart Data" icon="th" label=".csv" onClick={() => this.exportCsvTimeSeries()} />
          <MenuItem key="tableCsv" text="Data Table" icon="th" label=".csv" onClick={() => this.exportCsv()} />
        </MenuItem>
        <MenuDivider />
        {!$app.isSubtenant && (
          <>
            <MenuItem
              icon="new-grid-item"
              text="Add to Observation Deck"
              disabled={!query}
              onClick={this.handleAddToObservationDeck}
            />
            <MenuItem
              text="Add to NMS Dashboard"
              icon="new-grid-item"
              disabled={!query}
              onClick={this.handleAddToNmsLandingPage}
            />
          </>
        )}
        {!savedViewId && !$app.isSubtenant && (
          <>
            <MenuDivider />
            <MenuItem
              icon="floppy-disk"
              text="Create Saved View"
              disabled={!!savedViewModel}
              onClick={this.handleShowSavedViewDialog}
            />
          </>
        )}

        {!$app.isSubtenant && (
          <>
            <MenuDivider />
            <MenuItem icon="plus" text="Add To New Dashboard" onClick={this.handleAddToDashboard} />
            <MenuItem icon="new-grid-item" text="Add To Existing Dashboard" onClick={this.handleAddToDashboardItem} />
          </>
        )}
        {!$app.isSubtenant && $mkp.tenants.size > 0 && (
          <>
            <MenuDivider />
            <MenuItem
              icon="user"
              text="Preview as Tenant"
              popoverProps={{ openOnTargetFocus: false, style: { maxHeight: 300 } }}
            >
              <Box maxHeight={300} overflow="auto">
                {$mkp.tenants.map((tenant) => (
                  <MenuItem
                    key={tenant.id}
                    className="preview-as-tenant"
                    text={tenant.get('name')}
                    onClick={() => onPreviewTenant(tenant)}
                  />
                ))}
              </Box>
            </MenuItem>
          </>
        )}
        {!$app.isSubtenant && (
          <>
            <MenuDivider />
            <MenuItem icon="code" text="Show API Call" popoverProps={{ openOnTargetFocus: false }}>
              <MenuItem text="For Report" icon="document" tagName="button" onClick={this.handleShowReportApiCurl} />
              <MenuItem text="For Chart" icon="media" tagName="button" onClick={this.handleShowChartApiCurl} />
              <MenuItem text="For Data" icon="th" tagName="button" onClick={this.handleShowDataApiCurl} />
              <MenuItem text="JSON input" icon="code" tagName="button" onClick={this.handleShowApiJson} />
            </MenuItem>
            <MenuDivider />
          </>
        )}

        <MenuItem
          icon="reset"
          text={savedViewModel ? 'Reset Saved View' : 'Reset to Default Query'}
          onClick={onReset}
        />

        {this.removeProps && this.removeProps.show && (
          <>
            <MenuDivider />
            <RemoveMenuItem
              text="Remove"
              entityName={this.removeProps.entityName}
              model={this.removeProps.model}
              onRemove={this.removeProps.onRemove}
              confirmText={this.removeProps.confirmText}
            />
          </>
        )}
      </Menu>
    );
  }

  render() {
    const { $dashboards, fullyLoaded, query, buttonProps, showText } = this.props;
    const {
      isOpen,
      addToDashboardDialogOpen,
      addToNewDashboardDialogOpen,
      dashboardItem,
      savedViewDialogOpen,
      savedViewModel,
      showDataApiCurlDialogOpen,
      showChartApiCurlDialogOpen,
      showApiJsonDialogOpen,
      showReportApiCurlDialogOpen
    } = this.state;

    if (!query) {
      return null;
    }

    return (
      <>
        <Popover
          isOpen={isOpen}
          content={this.renderOptionsMenu()}
          onInteraction={this.handleInteraction}
          position="bottom-right"
        >
          <Button
            disabled={!fullyLoaded}
            icon={showText ? undefined : FiMoreVertical}
            text={showText ? 'Actions' : undefined}
            rightIcon={<Icon icon="caret-down" iconSize={16} mx="-2px" />}
            active={isOpen}
            {...buttonProps}
          />
        </Popover>
        {addToDashboardDialogOpen && (
          <DashboardPanelFormDialog isOpen={addToDashboardDialogOpen} onCancel={this.onClose} model={dashboardItem} />
        )}
        {addToNewDashboardDialogOpen && (
          <DashboardDetailsDialog
            isOpen={addToNewDashboardDialogOpen}
            onSubmit={this.onAddNewDashboardSubmit}
            onClose={this.onClose}
            model={$dashboards.collection.selected}
            collection={$dashboards.collection}
            title="New Dashboard"
            dashboardItem={dashboardItem}
          />
        )}
        {savedViewDialogOpen && (
          <SavedViewFormDialog
            model={savedViewModel}
            onClose={this.onClose}
            onSave={this.handleCreateSavedView}
            showDescriptionCallout={false}
          />
        )}
        <MetricsApiCallDialog
          query={query}
          showDataApiCurlDialogOpen={showDataApiCurlDialogOpen}
          showChartApiCurlDialogOpen={showChartApiCurlDialogOpen}
          showApiJsonDialogOpen={showApiJsonDialogOpen}
          showReportApiCurlDialogOpen={showReportApiCurlDialogOpen}
          onClose={this.onClose}
        />
      </>
    );
  }
}
