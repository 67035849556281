import * as React from 'react';
import { Box, Flex, Heading, Tag, EmptyState } from 'core/components';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import { GCP_ENTITY_TYPES } from 'shared/hybrid/constants';

const { NAT_GATEWAY } = GCP_ENTITY_TYPES;
export default class CloudRouterNats extends React.Component {
  get title() {
    const {
      nodeData: { nats = [] }
    } = this.props;
    const key = 'NatGwEntitiesCount';
    const color = nats.length ? 'gcp.blue' : '';
    return (
      <Flex gap={1}>
        <Heading level={5} m={0}>
          Nat Gateways
        </Heading>
        <Tag key={key} bg={color} round>
          {nats.length}
        </Tag>
      </Flex>
    );
  }

  get natGatewayMetadata() {
    const {
      nodeData: { nats = [] }
    } = this.props;

    if (nats.length > 0) {
      return <Box mt={1}>{nats.map((nat) => this.renderNat(nat))}</Box>;
    }

    return null;
  }

  renderNat(nat) {
    return (
      <CloudMetadata
        key={nat.name}
        border="thin"
        mb={1}
        p={1}
        node={{
          Name: nat.name,
          Type: nat.natGatewayType,
          'Enable Dynamic Port Allocation': `${nat.enableDynamicPortAllocation}`,
          'Enable Endpoint Independent Mapping': `${nat.enableEndpointIndependentMapping}`,
          'Min Ports Per VM': `${nat.minPortsPerVm}`,
          'Source Subnet IP Ranges To NAT': `${nat.sourceSubnetworkIpRangesToNat}`
        }}
        keys={[
          'Name',
          'Type',
          'Rules',
          'Enable Dynamic Port Allocation',
          'Enable Endpoint Independent Mapping',
          'Min Ports Per VM',
          'Source Subnet IP Ranges To NAT'
        ]}
      />
    );
  }

  render() {
    const {
      width,
      popoutTitle,
      cloudProvider,
      nodeData: { nats = [] }
    } = this.props;
    return (
      <SidebarItem
        excludeFormProps
        title={this.title}
        dialogProps={{ width }}
        icon={<CloudIcon cloudProvider={cloudProvider} entity={NAT_GATEWAY} iconSize={48} theme={{ name: 'dark' }} />}
        popoutTitle={popoutTitle}
      >
        {nats.length ? (
          <Box>{this.natGatewayMetadata}</Box>
        ) : (
          <>
            <EmptyState
              title="No Results"
              icon={<CloudIcon cloudProvider={cloudProvider} entity={NAT_GATEWAY} iconSize={48} />}
            />
          </>
        )}
      </SidebarItem>
    );
  }
}
