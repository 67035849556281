import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { FiDatabase } from 'react-icons/fi';
import { Button, ButtonGroup, Flex, Heading, Text } from 'core/components';
import { Form } from 'core/form';
import Page from 'app/components/page/Page';
import storeLoader from 'app/stores/storeLoader';
import MetricsExplorerOptionsMenu from 'app/views/metrics/components/MetricsExplorerOptionsMenu';
import getTenantPreviewFilters from 'app/util/mkp/getTenantPreviewFilters';
import { mapDataExplorerFilters } from '@kentik/ui-shared/nms/filters';
import { getQueryDuration } from '@kentik/ui-shared/nms/query';
import { DataExplorerDrawer } from 'app/views/metrics/dataExplorerDrawer';
import StandaloneDimensionSelector from 'app/components/dimensions/StandaloneDimensionSelector';
import { DataExplorerQuery } from 'app/views/metrics/result/dataExplorerQuery';
import MetricsExplorerResult from './MetricsExplorerResult';
import MetricsExplorerSidebar from './MetricsExplorerSidebar';
import { metricsExplorerFormFields, metricsExplorerFormOptions } from './utils/metricsExplorerFormConfig';
import MetricsExplorerNLQueryPrompt from './nlq/MetricsExplorerNLQueryPrompt';

@Form({ fields: metricsExplorerFormFields, options: metricsExplorerFormOptions })
@storeLoader('$metrics.availableMetricsCollection', '$savedViews')
@inject('$app', '$auth', '$metrics', '$decks', '$savedViews', '$companySettings')
@withRouter
@observer
export default class MetricsExplorer extends Component {
  static defaultProps = {
    initialValues: undefined,
    onChange: undefined,
    // when running in integrated mode, we're not doing a page / sidebar format and layout but rather
    // just using the innards of the explorer as a query builder / form, basically
    integratedMode: false,

    // Sidebar things to propagate...
    // array of rollup functions to allow, overrides defaults
    rollupAggFuncsOptionsOverride: undefined,
    // some integratedMode situations only care about specific parts of the form
    showVizOptions: true,
    showTimeOptions: true,
    showMetricFilterOptions: true,
    showMergeDimensions: true,
    showDimensionGrouping: true,
    showRollupOptions: true,
    showMergeSeriesOptions: true,
    // toggle allowing multiple aggregate types over the same metrics / dimensions
    allowMultiAggregate: true,
    isSingleMetric: false
  };

  state = {
    isLoading: true,
    fullyLoaded: false,
    isSidebarOpen: true,
    results: undefined, // QueryResultsCollection
    savedViewModel: undefined,
    urlHash: undefined // keep the url hash in state
  };

  static getDerivedStateFromProps(props, state) {
    // loading comes from storeLoader
    const { $savedViews, match } = props;
    const { savedViewModel } = state;
    const { queryHash, viewId } = match.params;

    if (viewId && !savedViewModel && $savedViews.collection.hasFetched) {
      const savedViewId = parseInt(viewId);
      const model = $savedViews.collection.get(savedViewId);

      return { savedViewModel: model };
    }

    if (queryHash) {
      return { urlHash: queryHash, savedViewId: viewId };
    }

    return { savedViewId: viewId };
  }

  componentDidMount() {
    const { $auth, form, onChange, initialValues, rollupAggFuncsOptionsOverride } = this.props;

    if (onChange) {
      form.onChange = onChange;
    }

    if (initialValues) {
      form.setValuesDeep(initialValues);
    }

    if (rollupAggFuncsOptionsOverride && rollupAggFuncsOptionsOverride.length === 1) {
      form.setValue('selectedRollupsAggFunc', [rollupAggFuncsOptionsOverride[0].value]);
    }

    form.setValue('time_format', $auth.userTimezone);

    if (!initialValues) {
      this.loadCurrentHash();
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const { savedViewModel, urlHash } = this.state;
    const { savedViewModel: prevSavedViewModel, urlHash: prevUrlHash } = prevState;
    const previousHash = prevUrlHash || prevSavedViewModel?.get('saved_query_id');
    const currentHash = urlHash || savedViewModel?.get('saved_query_id');

    if (previousHash !== currentHash || prevSavedViewModel !== savedViewModel) {
      this.loadCurrentHash();
    }

    if (location !== prevProps.location && location?.state?.isSidebarOpen && !prevState.isSidebarOpen) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isSidebarOpen: true });
    }
  }

  loadCurrentHash() {
    const { $metrics, form } = this.props;
    const { savedViewId, savedViewModel, urlHash } = this.state;
    const currentHash = urlHash || savedViewModel?.get('saved_query_id');

    // If we want a Saved View, but it's not loaded yet, do nothing
    if (savedViewId && !savedViewModel) {
      return;
    }

    $metrics.hashToQuery(currentHash).then(({ values, urlHash: newUrlHash }) => {
      if (savedViewModel) {
        if (savedViewModel.get('saved_query_id') !== newUrlHash) {
          console.info(`📄 Loading Saved View ${savedViewModel.id} with overrides...`, newUrlHash, values);
        } else {
          console.info(`📄 Loading Saved View ${savedViewModel.id}...`, values);
        }
      } else if (newUrlHash) {
        console.info(`#️⃣ Loading queryHash ${newUrlHash}...`, values);
      }

      this.setState({ isLoading: false, urlHash: newUrlHash, query: values.query }, () => {
        form.init(values);
      });
    });
  }

  handleForceRunQuery = () => {
    const { $metrics, form, history } = this.props;
    const { savedViewModel } = this.state;
    const { query } = this;

    this.setState({ query }, () => {
      if (form.valid && form.dirty) {
        $metrics.queryToHash(query).then((hash) => {
          console.info(`✅ Form is valid, hash: ${hash}`, query);

          this.setState({ urlHash: hash }, () => {
            if (savedViewModel) {
              history.push(`/v4/nms/explorer/saved-view/${savedViewModel.id}/${hash}`);
            } else {
              history.push(`/v4/nms/explorer/${hash}`);
            }
          });
        });
      }
    });
  };

  handlePreviewTenant = (tenant) => {
    const { form, $metrics } = this.props;
    const { query } = this.state;
    const measurement = $metrics.measurementModelByName(query.kmetrics.measurement).toJS();
    const filters = getTenantPreviewFilters(tenant, query.kmetrics.filters || {});
    const maxDateRange = tenant.get('config').max_date_range;
    let { range } = query.kmetrics;

    filters.filterGroups = filters.filterGroups.map((filterGroup) => {
      if (filterGroup.name.startsWith('mkp_preview')) {
        return mapDataExplorerFilters(filterGroup, measurement);
      }

      return filterGroup;
    });

    if (maxDateRange && getQueryDuration(query.kmetrics) > maxDateRange) {
      if (range.start && range.end) {
        const end = moment.utc(range.end);
        range = { ...range, start: end.subtract(maxDateRange, 'seconds').toISOString() };
      } else {
        range = { lookback: `PT${maxDateRange}S` };
      }
    }

    const formValues = $metrics.queryToFormValues({ ...query, kmetrics: { ...query.kmetrics, range, filters } });

    form.setValuesDeep(formValues);
    this.handleForceRunQuery();
  };

  dimensionSelector = {
    get: {
      isOpen: () => {
        const { isDimensionSelectorOpen } = this.state;
        return isDimensionSelectorOpen;
      }
    },
    action: {
      onClose: () => this.setState({ isDimensionSelectorOpen: false }),
      /**
       * Show dimension selector and track the relevant fields we are using to filter this query.
       * @param metricsExplorerQuery {object}
       */
      onShow: (metricsExplorerQuery) => this.setState({ isDimensionSelectorOpen: true, metricsExplorerQuery }),
      /**
       * Finalize dimension selector and open Data Explorer Drawer to show results
       * @param metric
       */
      onSave: (metric) => {
        // can't show anything if you haven't selected any dimensions
        if (!DataExplorerQuery.query.metric?.length) {
          return;
        }

        // set the new Group By Dimensions
        DataExplorerQuery.setQuery({
          ...DataExplorerQuery.query,
          metric
        });

        this.setState({ isDataExplorerDrawerOpen: true });
      }
    }
  };

  dataExplorerDrawer = {
    get: {
      isOpen: () => {
        const { isDataExplorerDrawerOpen } = this.state;
        return isDataExplorerDrawerOpen;
      },
      /**
       * Return the dimension selector filters associated with the Data Explorer Drawer, only if it is open.
       * @returns {object|null}
       */
      query: () => {
        const { metricsExplorerQuery, isDataExplorerDrawerOpen } = this.state;
        return isDataExplorerDrawerOpen ? metricsExplorerQuery : null;
      }
    },
    action: {
      onClose: () => this.setState({ isDataExplorerDrawerOpen: false })
    }
  };

  handleSubmitNlqPrompt = (query) => {
    const { form, $metrics } = this.props;
    const formValues = $metrics.queryToFormValues(query);

    form.reset();
    form.setValuesDeep(formValues);

    this.setState({ isSidebarOpen: true });

    console.info("Why isn't lookback_seconds set", form.getValue('lookback_seconds'));
    // This one isn't being set deep.
    form.setValue('lookback_seconds', formValues.lookback_seconds);

    this.handleForceRunQuery();
  };

  handleSidebarClose = () => {
    this.setState({ isSidebarOpen: false });
  };

  handleSidebarToggle = () => {
    const { isSidebarOpen } = this.state;
    this.setState({ isSidebarOpen: !isSidebarOpen });
  };

  handleReset = () => {
    const { form, history } = this.props;
    const { savedViewModel } = this.state;

    if (form.dirty) {
      form.reset();
    }

    this.setState({ urlHash: undefined });

    if (savedViewModel) {
      history.push(`/v4/nms/explorer/saved-view/${savedViewModel.id}`);
    } else {
      history.push('/v4/nms/explorer');
    }
  };

  @computed
  get query() {
    const { $metrics, form, allowMultiAggregate } = this.props;
    const queryOptions = form.getValues();

    return $metrics.formValuesToQuery(queryOptions, { allowMultiAggregate });
  }

  onQueryComplete = (response) => {
    const { fullyLoaded, results } = response;
    if (fullyLoaded) {
      this.setState({ fullyLoaded: true, results });
    }
  };

  render() {
    const {
      $app,
      $auth,
      $metrics,
      $companySettings,
      form,
      history,
      integratedMode,
      showVizOptions,
      showTimeOptions,
      showMetricFilterOptions,
      showMergeSeriesOptions,
      showRollupOptions,
      showMergeDimensions,
      showDimensionGrouping,
      allowMultiAggregate,
      rollupAggFuncsOptionsOverride,
      isSingleMetric
    } = this.props;
    const { fullyLoaded, isLoading, isSidebarOpen, urlHash, savedViewModel, query, results } = this.state;

    const sidebar = (
      <MetricsExplorerSidebar
        isLoading={isLoading}
        integratedMode={integratedMode}
        showVizOptions={showVizOptions}
        showTimeOptions={showTimeOptions}
        showMetricFilterOptions={showMetricFilterOptions}
        showDimensionGrouping={showDimensionGrouping}
        showMergeDimensions={showMergeDimensions}
        showRollupOptions={showRollupOptions}
        showMergeSeriesOptions={showMergeSeriesOptions}
        allowMultiAggregate={allowMultiAggregate}
        rollupAggFuncsOptionsOverride={rollupAggFuncsOptionsOverride}
        onForceRunQuery={this.handleForceRunQuery}
        savedViewModel={savedViewModel}
        isSingleMetric={isSingleMetric}
        query={query}
      />
    );

    if (integratedMode) {
      return sidebar;
    }

    const subnavTools = (
      <ButtonGroup minimal style={{ gap: 8 }}>
        {$auth.isSudoer && !$app.isSubtenant && (
          <Button
            minimal
            icon={FiDatabase}
            text="Schemas"
            onClick={() => {
              history.push('/v4/nms/schemas');
            }}
          />
        )}
        <MetricsExplorerOptionsMenu
          fullyLoaded={fullyLoaded}
          results={results}
          query={query}
          queryTitle={this.queryTitle}
          hash={urlHash}
          savedViewModel={savedViewModel}
          onReset={this.handleReset}
          onPreviewTenant={this.handlePreviewTenant}
          showText
        />
        <Button
          text="Query"
          icon={isSidebarOpen ? AiOutlineMenuFold : AiOutlineMenuUnfold}
          active={!!isSidebarOpen}
          onClick={this.handleSidebarToggle}
          minimal
        />
      </ButtonGroup>
    );

    const dimensionSelectorDimensions = DataExplorerQuery.query?.metric || [];

    return (
      <Page
        title="Metrics Explorer"
        subnavTools={subnavTools}
        drawerContents={sidebar}
        drawerIsOpen={isSidebarOpen}
        drawerOnClose={this.handleSidebarClose}
        overrideFullScreen
        overflow="hidden"
        px={0}
        py={0}
      >
        <Flex flexDirection="column" zIndex={0} overflow="hidden scroll" flex={1} align="stretch">
          <Flex flexDirection="column" flex={1} p={2}>
            {!integratedMode && !isLoading && !$app.isSubtenant && $companySettings.enableKentikAi && (
              <MetricsExplorerNLQueryPrompt slimMode={query} onSubmitPrompt={this.handleSubmitNlqPrompt} />
            )}
            {query && (
              <>
                <Heading level={4}>{savedViewModel?.get('view_name') || $metrics.queryTitle(query)}</Heading>
                {savedViewModel?.get('view_description') && <Text muted>{savedViewModel.get('view_description')}</Text>}
                <MetricsExplorerResult
                  query={query}
                  useUTC={form.getValue('time_format') === 'UTC'}
                  showLegend
                  enableSplitPane
                  onQueryComplete={this.onQueryComplete}
                  selectedItem={this.dataExplorerDrawer.get.query()}
                  onShowDimensionSelector={this.dimensionSelector.action.onShow}
                />
              </>
            )}
          </Flex>
          <DataExplorerDrawer
            isOpen={this.dataExplorerDrawer.get.isOpen()}
            onClose={this.dataExplorerDrawer.action.onClose}
          />
        </Flex>
        <StandaloneDimensionSelector
          title="Show By Dimensions"
          {...this.dimensionSelector.action}
          isOpen={this.dimensionSelector.get.isOpen()}
          saveButtonText="Show By Selected Dimensions"
          mustHaveDimensions={dimensionSelectorDimensions}
          multi
          values={{ dimension: dimensionSelectorDimensions }}
        />
      </Page>
    );
  }
}
