import * as React from 'react';
import { themeGet } from 'styled-system';
import styled from 'styled-components';
import { lighten } from 'polished';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { Path, Rect } from 'app/components/svg';
import { CloudItemRect, CloudItemInner } from 'app/views/hybrid/maps/components/CloudItem';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { getAzureVNetCIDRs, getEntityType } from 'app/views/hybrid/utils/map';

const { VNET } = ENTITY_TYPES.get('azure');

const MiniVNet = styled(Rect)`
  @keyframes glowing {
    0% {
      stroke: ${themeGet('colors.primary')};
      opacity: 1;
    }
    50% {
      stroke: ${themeGet('colors.primaryBackground')};
      opacity: 0.5;
    }
  }

  &.flashing {
    animation: glowing 750ms linear infinite;
  }

  cursor: pointer;
  fill: ${(props) => lighten(0.2, props.theme.colors.primary)};

  &:hover,
  &.hover {
    stroke: ${(props) => props.theme.colors.primary};
    stroke-width: 2;
  }

  &.highlighted {
    stroke: ${(props) => props.theme.colors.primary};
    stroke-width: 2px;
    stroke-dasharray: 7 3;

    &:hover {
      stroke-dasharray: none;
    }
  }
`;

export default class VNet extends React.Component {
  get subtitle() {
    const { vnet } = this.props;
    const type = getEntityType(vnet);
    const isVnet = type === VNET;
    const cidrs = isVnet ? getAzureVNetCIDRs(vnet).join(', ') : (vnet.properties?.addressPrefix ?? '');

    if (vnet.name) {
      return [
        <tspan key={`${type}-${vnet.id}`} fontStyle="italic">
          {vnet.id}
        </tspan>,
        cidrs
      ];
    }

    return cidrs;
  }

  render() {
    const { vnet, fill, mini, selected, expanded, innerHeight, ...rest } = this.props;
    const { width, height } = rest;
    const sideWidth = 6;

    if (mini) {
      return <MiniVNet fill={fill} {...rest} />;
    }

    return (
      <>
        <CloudItemRect
          {...rest}
          stroke={fill}
          strokeWidth={2}
          opacity={selected ? 0.5 : 1}
          pointerEvents={selected ? 'none' : 'auto'}
          backgroundHighlightColor="primaryBackground"
        />
        {!expanded && <Rect x={1} y={1} width={sideWidth} height={height - 2} fill={fill} pointerEvents="none" />}
        <g transform={`translate(${sideWidth}, 0)`}>
          <CloudItemInner
            width={width - sideWidth}
            height={innerHeight || height}
            title={vnet.name || vnet.id}
            subtitle={this.subtitle}
            icon={<CloudIcon cloudProvider="azure" entity={getEntityType(vnet)} />}
          />
        </g>
        {expanded && !selected && (
          <g transform={`translate(0 ${innerHeight})`} pointerEvents="none" fontSize={10} letterSpacing="-0.035em">
            <Path d={`M0,0 H${width}`} stroke="muted" strokeOpacity={0.2} strokeWidth={1} />
            {/* @TODO implement traffic stats */}
          </g>
        )}
      </>
    );
  }
}
