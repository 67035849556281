import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Box } from 'core/components';

import TenantLogo from 'app/views/mkpConfig/components/TenantLogo';
import headerBg from 'app/assets/interstitials/interstitial-bg.svg';

import KentikLogo from '../KentikLogo';

const NavWrapper = styled(Box)`
  background: #345583 url('${headerBg}');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: auto 100%;

  position: fixed;
  top: 0;
  height: 60px;
  width: 100vw;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 20px;
`;

@inject('$auth', '$app')
@withRouter
@observer
class Navbar extends Component {
  render() {
    const { $app, $auth } = this.props;

    let LogoCmp;

    if ($app.isSubtenant) {
      LogoCmp = <TenantLogo companyId={$auth.getActiveUserProperty('company_id')} height={28} />;
    } else {
      LogoCmp = <KentikLogo style={{ width: 110, height: 28 }} />;
    }

    return <NavWrapper>{LogoCmp}</NavWrapper>;
  }
}

export default Navbar;
