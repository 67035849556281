import React, { Component } from 'react';
import classNames from 'classnames';
import { truncate } from 'app/util/utils';
import { getMapClassname } from 'app/views/hybrid/utils/map';
import HoneycombItemGroup from './HoneycombItemGroup';
import ArcLayoutIcon from '../arc/ArcLayoutIcon';

const spacing = 12;

function getRowsAndColumns({ width, size, count }) {
  const total = size * count + spacing * (count - 1);
  const availableWidth = total > width ? width - size : width;
  const rows = Math.ceil(total / availableWidth);
  const columns = Math.ceil(count / rows);
  return { rows, columns };
}

function getSideLength(size) {
  return size / 2 / Math.sin(Math.PI / 3);
}

export function getHoneycombWidth({ width, size = 40, count }) {
  const { columns } = getRowsAndColumns({ width, size, count });
  return columns * (size + spacing);
}

export function getHoneycombHeight({ size = 40, width = size, count }) {
  const { rows } = getRowsAndColumns({ width, size, count });
  const side = getSideLength(size);
  return rows * ((1.5 * (size + spacing)) / (2 * Math.sin(Math.PI / 3))) + side * Math.cos(Math.PI / 3);
}

export function getHoneycombPoint({ width, size = 40, index, count }) {
  const { columns } = getRowsAndColumns({ width, size, count });
  const row = Math.floor(index / columns);
  const column = index % columns;
  const columnOffset = row % 2 === 1 ? size + spacing : (size + spacing) / 2;
  return [column * (size + spacing) + columnOffset, row * ((1.5 * (size + spacing)) / (2 * Math.sin(Math.PI / 3)))];
}

function drawHexagon({ size, point }) {
  const side = getSideLength(size);
  const firstPoint = [point[0] - size / 2, point[1] + side / 2];
  const points = [];

  for (let i = 0; i < 5; i += 1) {
    const angle = (Math.PI * i) / 3;
    const dx = Math.sin(angle) * side;
    const dy = -Math.cos(angle) * side;
    points.push([dx, dy]);
  }

  return `M${firstPoint}l${points.join('l')}z`;
}

export default class HoneycombLayout extends Component {
  static defaultProps = {
    classPrefix: 'device',
    className: '',
    keyProp: 'id',
    nameProp: 'name',
    truncateName: 12, // number of characters, 0 to disable
    items: [],
    itemType: 'leaf',
    getLink: () => null,
    highlighted: [],
    hovered: null,
    selected: null,
    width: 900,
    size: 40
  };

  state = {
    labelWidth: 100
  };

  labelRef = React.createRef();

  componentDidUpdate(prevProps) {
    const { hovered, selected } = this.props;

    if (this.labelRef.current) {
      const item = hovered || selected;
      const prevItem = prevProps.hovered || prevProps.selected;

      if (item && item !== prevItem) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ labelWidth: this.labelRef.current.getComputedTextLength() + 16 });
      }
    }
  }

  handleHoverItem = (item) => {
    const { onHover } = this.props;

    if (onHover) {
      onHover(item);
    }
  };

  handleSelectItem = (item) => {
    const { onSelect } = this.props;

    if (onSelect) {
      onSelect(item, { health: item.health });
    }
  };

  renderLabel() {
    const { items, nameProp, truncateName, width, size, hovered, selected } = this.props;
    const { labelWidth } = this.state;
    const item = hovered || selected;
    const index = items.indexOf(item);

    if (index === -1) {
      return null;
    }

    const point = getHoneycombPoint({ width, size, index, count: items.length });
    const labelHeight = 16;
    const honeycombHeight = getHoneycombHeight({ size, count: 1 });
    const x = point[0];
    const y = point[1] - honeycombHeight / 2 + 4;
    const name = truncateName > 0 ? truncate(item[nameProp], truncateName) : item[nameProp];

    return (
      <g pointerEvents="none" className="honeycomb-label">
        <rect x={x - labelWidth / 2} y={y} width={labelWidth} height={labelHeight} rx={4} ry={4} />
        <text ref={this.labelRef} x={x} y={y + 12} fontSize={12} fontWeight={500} textAnchor="middle">
          {name}
        </text>
      </g>
    );
  }

  render() {
    const {
      classPrefix,
      className,
      keyProp,
      items,
      itemType,
      width,
      size,
      noIconFill,
      noShape,
      getLink,
      hovered,
      selected,
      renderItem,
      highlighted
    } = this.props;

    return (
      <g>
        {items.map((item, index) => {
          if (!item) {
            return null;
          }

          const isSelected = selected === item || item?.id === selected;
          const isHighlighted =
            !isSelected &&
            (highlighted.includes(item) || (selected && getLink(selected, item, { checkExistence: true })));
          const point = getHoneycombPoint({ width, size, index, count: items.length });
          const [x, y] = point;
          const itemClassName = getMapClassname({ type: classPrefix, value: item[keyProp] });

          if (renderItem) {
            return (
              <g transform={`translate(${x}, ${y})`} key={item[keyProp]}>
                {renderItem({
                  item,
                  id: item[keyProp],
                  onClick: () => this.handleSelectItem(item),
                  onMouseEnter: () => this.handleHoverItem(item),
                  onMouseLeave: () => this.handleHoverItem(null),
                  className: classNames('node', 'hybrid-map-selectable-node', item.health?.cssClassName, {
                    'no-icon-fill': noIconFill,
                    'node-selected': isSelected,
                    'node-highlighted': isHighlighted,
                    'node-unselected': (selected || highlighted.length > 0) && !isSelected && !isHighlighted,
                    hovered: item === hovered,
                    selected: isSelected,
                    unselected: (selected || highlighted.length > 0) && !isSelected && !isHighlighted,
                    highlighted: isHighlighted
                  })
                })}
              </g>
            );
          }

          return (
            <HoneycombItemGroup
              key={item[keyProp]}
              className={classNames('hybrid-map-selectable-node', {
                'no-icon-fill': noIconFill,
                hovered: item === hovered,
                selected: isSelected,
                unselected: (selected || highlighted.length > 0) && !isSelected && !isHighlighted,
                highlighted: isHighlighted
              })}
              onClick={() => this.handleSelectItem(item)}
              onMouseEnter={() => this.handleHoverItem(item)}
              onMouseLeave={() => this.handleHoverItem(null)}
            >
              {!noShape && (
                <path
                  className={classNames(className, itemClassName, item.health?.cssClassName)}
                  d={drawHexagon({ size, point })}
                />
              )}
              <ArcLayoutIcon
                className={classNames({ 'color-icon': noIconFill, [itemClassName]: noShape })}
                type={itemType}
                x={x}
                y={y}
                hovered={isSelected || item === hovered}
                health={item.health?.cssClassName}
                iconSize={noShape ? size : 20}
              />
              {item.health && (
                <circle
                  className={classNames('health-indicator', item.health.cssClassName)}
                  cx={x + 10}
                  cy={y - 17}
                  width={10}
                  height={10}
                />
              )}
            </HoneycombItemGroup>
          );
        })}
        {this.renderLabel()}
      </g>
    );
  }
}
