import * as React from 'react';
import { Classes } from '@blueprintjs/core';
import { FiMoreVertical } from 'react-icons/fi';
import { Box, Button, DropdownMenu, Grid, Heading, Icon, Text } from 'core/components';
import ViewInExplorerButton from 'app/components/dataviews/tools/ViewInExplorerButton';
import getCloudServicesQuery from 'app/views/cloudPerformance/utils/getCloudServicesQuery';
import withServiceBrand from 'app/views/cloudPerformance/cloudServices/withServiceBrand';
import ConnectionTypeSummary from './ConnectionTypeSummary';
import TrafficSummary from './TrafficSummary';

@withServiceBrand
export default class CloudServiceTableSummary extends React.Component {
  render() {
    const {
      isOpen,
      cloudProvider,
      collection,
      serviceKey,
      networkInterfaceMap,
      serviceBrand,
      onViewToggle,
      timeOptions
    } = this.props;
    const { icon, fullLabel, labelComponent, color } = serviceBrand;

    return (
      <Box pt={2} pb={1} position="sticky" top={-16} bg="cardBackground" zIndex={1}>
        <Grid gridTemplateColumns="32px 32px 185px 125px 220px 220px auto" alignItems="center">
          <Button ml="10px" icon={`caret-${isOpen ? 'down' : 'right'}`} onClick={() => onViewToggle(!isOpen)} minimal />

          <Icon icon={icon} iconSize={32} />

          <Heading mb={0} level={3} title={fullLabel}>
            <Text as="div" ellipsis>
              {labelComponent}
            </Text>
          </Heading>

          <ConnectionTypeSummary collection={collection} serviceKey={serviceKey} />

          <TrafficSummary collection={collection} serviceKey={serviceKey} serviceName={fullLabel} color={color} />

          <Box justifySelf="flex-end" mr="28px">
            <DropdownMenu
              className={Classes.POPOVER_DISMISS}
              content={
                <ViewInExplorerButton
                  query={getCloudServicesQuery({
                    cloudProvider,
                    services: [serviceKey],
                    filterableServices: collection.getServiceNameFilterValues([serviceKey]),
                    networkInterfaceMap,
                    matchBy: ['service'],
                    timeOptions
                  })}
                  openInNewWindow
                />
              }
            >
              <Button icon={FiMoreVertical} color="muted" minimal />
            </DropdownMenu>
          </Box>
        </Grid>
      </Box>
    );
  }
}
