import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Page from 'app/components/page/Page';
import PageHeading from 'app/components/page/PageHeading';
import { HiUserGroup } from 'react-icons/hi';
import { RbacAdminCta } from 'app/components/cta/rbacAdmin';
import PageTabs, { PageTab } from 'app/components/page/PageTabs';
import RbacRoles from './RbacRoles';
import RoleSets from './RoleSets';

@inject('$auth', '$rbac')
@observer
class RbacManagement extends Component {
  render() {
    const { $rbac, $auth } = this.props;
    const isMissingSuperAdmin = $auth.isPreciselyAdministrator && !$rbac.hasSuperAdmin;

    return (
      <Page title="RBAC Management">
        {isMissingSuperAdmin && <RbacAdminCta />}
        <PageHeading title="RBAC Management" icon={HiUserGroup} hideFavorite />
        <PageTabs defaultSelectedTabId="roles" useUrlPathParam>
          <PageTab id="roles" title="Roles" icon="inherited-group" panel={<RbacRoles />} />
          <PageTab id="role-sets" title="Role Sets" icon={HiUserGroup} panel={<RoleSets />} />
        </PageTabs>
      </Page>
    );
  }
}

export default RbacManagement;
