import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, Heading, Link } from 'core/components';
import DeviceSubtypeLabel from 'app/components/device/DeviceSubtypeLabel';
import VendorLogo from 'app/components/VendorLogo';
import LabelList from 'app/components/labels/LabelList';

const DeviceSummary = ({ model }) => {
  const deviceId = model.get('id');
  const deviceName = model.get('device_name');
  const labels = model.get('labels') || [];

  return (
    <Box p={2} borderBottom="thin">
      <DeviceSubtypeLabel
        showIcon={false}
        type={model.get('device_subtype')}
        labelProps={{ textTransform: 'uppercase', muted: true, small: true }}
      />
      <Flex alignItems="center" mb={1}>
        <Heading level={4} mb={0}>
          <Link display="flex" to={`/v4/infrastructure/devices/${deviceName}`} blank>
            {deviceName} ({deviceId})
          </Link>
        </Heading>
        <LabelList ml={1} labels={labels} />
      </Flex>
      <Box width={75}>
        <VendorLogo name={model.get('device_vendor_type')} />
      </Box>
    </Box>
  );
};

DeviceSummary.PopoutTitle = ({ model }) => (
  <Flex alignItems="center">
    <VendorLogo name={model.get('device_vendor_type')} />
    {`${model.get('device_name')} (${model.get('id')})`}
  </Flex>
);

export default observer(DeviceSummary);
