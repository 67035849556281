import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { isEqual } from 'lodash';

import { Box, Button, Flex, Suspense, Text } from 'core/components';
import { Field, formConsumer, InputGroup } from 'core/form';

@inject('$auth', '$users')
@formConsumer
class UserInfo extends Component {
  handleResetApiToken = () => {
    const { model, $users, form } = this.props;
    $users.resetUserApiToken(model.id).then((user_api_token) => {
      form.setValue('user_api_token', user_api_token);
    });
  };

  handleDisableTotp = () => {
    const { model, $users } = this.props;
    $users.disableUserTotps(model.id);
  };

  // TODO: this should move and become reusable
  passwordSameValidator = () => {
    const { form } = this.props;

    return {
      message: '":attribute" must be the same',
      fn: (value, field) => {
        const otherField = form.getField(field);
        return isEqual(value, otherField.getValue());
      }
    };
  };

  render() {
    const { model, $users, loading } = this.props;
    const isNew = model && model.isNew;
    const { totpEnabled } = (model && model.get(['totpEnabled'])) || {};

    return (
      <Suspense loading={loading}>
        <Field
          name="user_email"
          helpText={
            <>
              Changes will send a verification message to the updated address.
              <br />
              The new address will only appear after user has completed verification.
            </>
          }
          autoFocus
          large
        >
          <InputGroup width={300} />
        </Field>
        <Field name="user_full_name" large>
          <InputGroup width={300} />
        </Field>

        {!isNew && (
          <Box borderTop="thin">
            <Flex alignItems="flex-end" pt={2}>
              <Field name="user_api_token" mr={1} mb={0} readOnly large>
                <InputGroup width={300} />
              </Field>
              <Button text="Reset API Token" loading={$users.resettingApiToken} onClick={this.handleResetApiToken} />
            </Flex>

            <Box borderTop="thin" my={2} />

            <Flex my={2}>
              {totpEnabled && (
                <Button
                  text="Disable 2-Factor Authentication"
                  loading={$users.disablingTotp}
                  onClick={this.handleDisableTotp}
                />
              )}
              {!totpEnabled && (
                <Text fontSize={14} muted>
                  2-Factor authentication is disabled for this user
                </Text>
              )}
            </Flex>
          </Box>
        )}
      </Suspense>
    );
  }
}

export default UserInfo;
