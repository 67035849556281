import React from 'react';
import Banner from 'app/components/Banner';

export const RbacAdminCta = () => (
  <Banner
    boxProps={{ my: 2 }}
    title="Warning: No Super Administrators"
    intent="warning"
    description="A Super Administrator Kentik-Managed Role needs be assigned to user(s) for them to be able to
    create/edit/delete RBAC roles - please contact your Customer Success representative for help"
  />
);
