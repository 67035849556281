import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, Grid, Heading, Text } from 'core/components';
import { greekIt } from 'app/util/utils';
import ResourceItem, { icons } from '../../../kube/ResourceItem';

function KubeIcon({ nodeData }) {
  const { type, id } = nodeData;
  const hasProblem = nodeData?.health?.data.state === 'CRITICAL' || nodeData?.health?.data.state === 'WARNING';
  const className = hasProblem ? nodeData?.health?.cssClassName : '';
  const KubeTypeIcon = icons[type];

  if (!KubeTypeIcon) {
    return null;
  }

  return (
    <Box>
      <svg display="block" width={54} height={54} style={{ margin: '0 auto' }}>
        <g transform="translate(27, 27)">
          <ResourceItem id={id} type="resources" subType={type} className={`${className} node`} />
        </g>
      </svg>
      {/* <KubeTypeIcon width={size} height={size} className={className} /> */}
    </Box>
  );
}

@observer
class KubeResourceSummary extends React.Component {
  render() {
    const { center, nodeData, parentNodeData, result, showDetails, latency } = this.props;

    const { name, typeLabel, spec } = nodeData;
    return (
      <Box p={2}>
        <Flex gap={1} alignItems="center" justifyContent={center ? 'center' : 'flex-start'}>
          <KubeIcon nodeData={nodeData} size={showDetails ? 26 : 40} />
          <Box>
            <Text as="div" muted>
              {typeLabel}
            </Text>
            {!showDetails && (
              <Heading level={3} mb={0}>
                {name}
              </Heading>
            )}
          </Box>
        </Flex>
        {showDetails && typeLabel && (
          <Grid gridTemplateColumns="auto minmax(0, 1fr)" gridRowGap={1} gridColumnGap={4} mt={3}>
            <Text muted>{typeLabel} name</Text>
            <Text ellipsis>{name}</Text>
            {spec?.nodeName && (
              <>
                <Text muted>Node name</Text>
                <Text ellipsis>{spec.nodeName}</Text>
              </>
            )}
            {parentNodeData && parentNodeData.typeLabel && parentNodeData.type !== 'nodes' && (
              <>
                <Text muted>{parentNodeData.typeLabel} name</Text>
                <Text ellipsis>{parentNodeData.name}</Text>
              </>
            )}
            {result && (
              <>
                <Text muted>Latency</Text>
                <Text ellipsis>{result.get('p98th_ktsubtype__kappa__APPL_LATENCY_MS')} ms</Text>
                <Text muted>Traffic</Text>
                <Text ellipsis>
                  {greekIt(result.get('avg_bits_per_sec'), { fix: 2, suffix: 'bits/s' }).displayFull}
                </Text>
              </>
            )}
            {latency && (
              <>
                <Text muted>Latency</Text>
                <Text ellipsis>{latency.toFixed(1)} ms</Text>
              </>
            )}
          </Grid>
        )}
      </Box>
    );
  }
}

KubeResourceSummary.PopoutTitle = ({ nodeData }) => `Kubernetes ${nodeData?.typeLabel} - ${nodeData?.name}`;

export default KubeResourceSummary;
