/*
  A collection of panels to be rendered in the map sidebar
  for cloud providers of type azure, and entities of type load balancer
*/
import * as React from 'react';
import FrontendIPConfigurationsPanel from './FrontendIPConfigurationsPanel';
import BackendPoolsPanel from './BackendPoolsPanel';
import LoadBalancingRules from './LoadBalancingRules';

export default function LoadBalancerExtendedDetails(props) {
  return (
    <>
      <FrontendIPConfigurationsPanel {...props} />
      <BackendPoolsPanel {...props} />
      <LoadBalancingRules {...props} />
    </>
  );
}
