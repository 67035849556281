import { inject } from 'mobx-react';
import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import classNames from 'classnames';
import { isEqual } from 'lodash';

import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { Box, ButtonLink, Flex, Text, Heading } from 'core/components';
import { getMapClassname, getEntityType } from 'app/views/hybrid/utils/map';
import getScrollbarWidth from 'core/util/getScrollbarWidth';
import { Grid } from 'app/components/svg';

import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';

import Region from './Region';
import RegionMap from './RegionMap';

const { NETWORK } = ENTITY_TYPES.get('gcp');

const miniItemMargin = 3;
const miniItemSize = 20;

const normalItemMarginX = 12;
const normalItemMarginY = 20;
const normalItemWidth = 170;
const normalItemHeight = 52;

const expandedItemMargin = normalItemMarginX;
const expandedItemWidth = normalItemWidth;
const expandedItemHeight = normalItemHeight + 76;

const connectionItemWidth = 160;

@withTheme
@inject('$hybridMap')
export default class Network extends Component {
  static defaultProps = {
    width: 500,
    padding: 16,
    selectedRegions: [],
    selected: false,
    highlighted: false,
    colorBy: 'inbound_bits_per_second',
    highlightedRegions: []
  };

  static getDerivedStateFromProps(props, state) {
    const { colorBy, network } = props;
    const { regions = [] } = network;

    if (colorBy !== state.colorBy) {
      const maxBps = Math.max(1_000_000_000, ...regions.map((region) => region.traffic?.[colorBy] || 0));
      return { colorBy, maxBps };
    }

    return null;
  }

  state = {
    expanded: false
  };

  shouldComponentUpdate(prevProps, prevState) {
    const { width, selectedRegions, selected, highlighted, highlightedRegions, network, regionTraffic } = this.props;

    return (
      prevState !== this.state ||
      prevProps.width !== width ||
      prevProps.selectedRegions !== selectedRegions ||
      !isEqual(prevProps.selected, selected) ||
      !isEqual(prevProps.highlighted, highlighted) ||
      !isEqual(prevProps.highlightedRegions, highlightedRegions) ||
      !isEqual(prevProps.network, network) ||
      !isEqual(prevProps.regionTraffic, regionTraffic)
    );
  }

  get regions() {
    const { network } = this.props;
    const { regions = [] } = network;
    return regions;
  }

  get canExpand() {
    const { selectedRegions } = this.props;
    return selectedRegions?.length < 1 && this.regions.length > this.getColumns(false) * 9;
  }

  get mini() {
    const { selectedRegions } = this.props;
    const { expanded } = this.state;
    return !expanded && selectedRegions?.length < 1 && this.canExpand;
  }

  get svgWidth() {
    const { width } = this.props;
    const { regionConnections } = this;
    return width - (regionConnections.length > 0 ? connectionItemWidth + 30 : 0) - connectionItemWidth / 2;
  }

  get availableWidth() {
    const { padding } = this.props;
    return this.svgWidth - getScrollbarWidth() - padding * 2;
  }

  get selectedRegions() {
    const { selectedRegions } = this.props;
    return this.regions.filter((region) => selectedRegions.includes(region.selectId));
  }

  get regionConnections() {
    return [];
  }

  getColumns(mini = this.mini) {
    const { availableWidth } = this;
    return mini
      ? Math.floor((availableWidth + miniItemMargin) / (miniItemSize + miniItemMargin))
      : Math.floor((availableWidth + normalItemMarginX) / (normalItemWidth + normalItemMarginX));
  }

  getRegionColor() {
    const { theme } = this.props;
    return theme.colors.gcp.green;
    // @TODO more logic to follow when color-by traffic functionality is done
  }

  get isExpanded() {
    return this.selectedRegions.length > 0;
  }

  get regionItemProps() {
    const { padding } = this.props;
    const { mini, isExpanded } = this;

    // assume normal sizing
    const props = {
      maxHeight: 9 * (normalItemHeight + normalItemMarginY) + padding,
      itemWidth: normalItemWidth,
      itemHeight: normalItemHeight,
      itemMarginX: normalItemMarginX,
      itemMarginY: normalItemMarginY
    };

    if (mini) {
      // mini mode has condensed boxes with tooltips
      props.itemWidth = miniItemSize;
      props.itemHeight = miniItemSize;
      props.itemMarginX = miniItemMargin;
      props.itemMarginY = miniItemMargin;
      props.renderTooltipContent = (region) => (
        <Box>
          <Box fontWeight="bold">{region.name || region.id}</Box>
          {region.name && (
            <Box fontSize="small" fontStyle="italic">
              {region.id}
            </Box>
          )}
          {/* @TODO render address(es) */}
          <Box fontSize="small">{region.CidrBlock}</Box>
        </Box>
      );

      return props;
    }

    if (isExpanded) {
      props.itemWidth = expandedItemWidth;
      props.itemHeight = expandedItemHeight;
      props.itemMarginX = expandedItemMargin;
      props.itemMarginY = expandedItemMargin;
    }

    return props;
  }

  getItemClasses(item) {
    const { selected, highlighted } = this.props;
    const isSelected = selected === item.id;
    const isHighlighted = highlighted.includes(item.id);

    return {
      selected: isSelected,
      highlighted: isHighlighted,
      unselected: highlighted.length > 0 && !isSelected && !isHighlighted
    };
  }

  handleToggleExpanded = (evt) => {
    const { onResize } = this.props;
    evt.stopPropagation();
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
    onResize();
  };

  handleShowDetails = (evt) => {
    const { network, onShowDetails } = this.props;
    evt.stopPropagation();
    onShowDetails({ type: NETWORK, nodeData: network });
  };

  handleToggleRegion = (region) => {
    const { onToggleRegion } = this.props;
    onToggleRegion(region.selectId);
  };

  render() {
    const { network, padding, selected, regionTraffic, highlighted, highlightedRegions, onShowDetails } = this.props;
    const { regions, svgWidth, canExpand, mini, selectedRegions, isExpanded, availableWidth } = this;

    return (
      <Box width="100%">
        <Flex alignItems="baseline" justifyContent="space-between" pt={`${padding}px`} px={`${padding}px`}>
          <Flex gap={1}>
            <CloudIcon cloudProvider="gcp" entity="networks" />
            <Heading level={6} mb={0} display="flex" alignItems="center">
              {network.project} {network.name}
            </Heading>
            {canExpand && (
              <ButtonLink ml={2} color="muted" small onClick={this.handleToggleExpanded}>
                {mini ? 'Expand' : 'Collapse'}
              </ButtonLink>
            )}
          </Flex>
          <Box>
            <ButtonLink small ml={1} onClick={this.handleShowDetails}>
              Show Details
            </ButtonLink>
            <Text small muted ml={1}>
              {regions?.length} Region{regions?.length === 1 ? '' : 's'}
            </Text>
          </Box>
        </Flex>
        <Flex justifyContent="space-between" gap={1} mt={`${padding / 2}px`}>
          <Box>
            {selectedRegions.map((selectedRegion) => (
              <Box key={selectedRegion.id} mx={padding} mb={4}>
                <RegionMap
                  width={availableWidth}
                  padding={padding / 2}
                  region={selectedRegion}
                  location={selectedRegion} // was region, s'ok?
                  selected={selected}
                  highlighted={highlighted}
                  onClose={() => this.handleToggleRegion(selectedRegion)}
                  onShowDetails={onShowDetails}
                />
              </Box>
            ))}
            <Box py={`${padding / 2}px`}>
              <Grid
                items={regions}
                width={svgWidth}
                paddingX={padding}
                paddingY={padding / 2}
                getItemFill={(region) => this.getRegionColor(region)}
                getItemProps={() => ({
                  innerHeight: isExpanded ? normalItemHeight : null
                })}
                renderItem={(region, props) => {
                  const regionSelected = selectedRegions.some(
                    (selectedRegion) => selectedRegion.selectId === region.selectId
                  );
                  const entityType = getEntityType(region);

                  return (
                    <Region
                      region={region}
                      mini={mini}
                      expanded={isExpanded}
                      traffic={regionTraffic?.[region.selectId]}
                      selected={regionSelected}
                      className={classNames(
                        'hybrid-map-selectable-node',
                        getMapClassname({ type: entityType, value: region.id }),
                        {
                          highlighted: !regionSelected && highlightedRegions.includes(region.selectId),
                          selected: regionSelected
                        }
                      )}
                      {...props}
                    />
                  );
                }}
                onItemClick={this.handleToggleRegion}
                {...this.regionItemProps}
              />
            </Box>
          </Box>
        </Flex>
      </Box>
    );
  }
}
