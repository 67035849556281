/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Box, Button, Dialog, Text, Flex } from 'core/components';
import { FormComponent } from 'core/form';
import ApplyParametricOptions from 'app/views/core/dashboards/ApplyParametricOptions';
import { inject } from 'mobx-react';

const parametricFields = {
  parametric_fields: {
    isComplexArray: true
  },
  'parametric_fields[].type': {},
  'parametric_fields[].label': {},
  'parametric_fields[].question': {},
  'parametric_fields[].value': {
    rules: 'required'
  }
};

@inject('$dashboard')
export default class GuidedViewForm extends Component {
  handleParametricSubmit = (dashboard, parametricFieldValues) => {
    const { $dashboard, onClose } = this.props;
    dashboard.set(parametricFieldValues);
    $dashboard.export({ dashboard });
    onClose();
  };

  getFormValuesFromDashboard = (model) => {
    const values = model.get();
    const [parametric_field] = values.parametric_fields;
    parametric_field.value = undefined;

    return values;
  };

  render() {
    const { view, isOpen, onClose } = this.props;
    return (
      <Dialog title="Export" isOpen={isOpen} onClose={onClose}>
        <Dialog.Body>
          <FormComponent
            fields={parametricFields}
            options={{ name: `${view.id} Parametric`, showPristineErrors: false }}
            values={this.getFormValuesFromDashboard(view)}
          >
            {({ form }) => (
              <ApplyParametricOptions dashboard={view} handleSubmit={this.handleParametricSubmit}>
                {({ buttonProps, inputField, question }) => (
                  <Box>
                    <Text flex={1} fontSize={12} as="div" muted fontWeight="bold" mb="4px">
                      {question}
                    </Text>
                    <Flex alignItems="center">
                      <Box flex={2}>{React.cloneElement(inputField, { width: '100%', mb: 0 })}</Box>
                    </Flex>
                    <Dialog.Footer mt="20px">
                      <Button text="Cancel" onClick={() => onClose()} mr={1} width={110} />
                      <Button
                        {...buttonProps}
                        intent="primary"
                        text="Export"
                        width={110}
                        ml={1}
                        disabled={form?.errors?.length > 0}
                      />
                    </Dialog.Footer>
                  </Box>
                )}
              </ApplyParametricOptions>
            )}
          </FormComponent>
        </Dialog.Body>
      </Dialog>
    );
  }
}
