import { getAggregates } from 'app/stores/query/QueryModel';

const uniqueRegex = /unique_/;
const ipRegex = /(src|dst)_ip$/;
const routePrefixRegex = /(src|dst)_route_prefix$/;

export default {
  fn: (value, maxMatches) => {
    const aggregateUnits = getAggregates(value).reduce(
      (aggUnits, agg) => (aggUnits.includes(agg.unit) ? aggUnits : aggUnits.concat(agg.unit)),
      []
    );

    const genericAggregateUnits = aggregateUnits.filter(
      (unit) => uniqueRegex.test(unit) && !ipRegex.test(unit) && !routePrefixRegex.test(unit)
    );

    return genericAggregateUnits.length <= maxMatches;
  },
  message: 'You cannot have more than :maxGenericAggregates Unique* metrics selected, excluding IP and Route Prefix'
};
