import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { path } from 'd3-path';
import { line, curveBasis } from 'd3-shape';
import classNames from 'classnames';
import uuid from 'uuid';
import { Circle, Path, Text } from 'app/components/svg';
import { getMapClassname } from 'app/views/hybrid/utils/map';
import ArcLayoutItemGroup from './ArcLayoutItemGroup';
import ArcLayoutIcon from './ArcLayoutIcon';
import { splitPath, getTrafficLabel, addTrafficLabelArrow, getLatencyLabel } from '../../../utils/links';

/**
 * sort items so that linked items are next to each other
 * @param {Object[]} items
 * @param {(a, b) => boolean} hasLink
 */
export function sortArcItems(items, hasLink) {
  if (items.length === 0) {
    return [];
  }

  items = items.slice();
  const sorted = [items.shift()];
  let broken = false;

  function findNextLink() {
    const prev = sorted[sorted.length - 1];
    const nextIndex = items.findIndex((item) => item !== prev && hasLink(item, prev));

    if (nextIndex > -1) {
      const next = items[nextIndex];
      items.splice(nextIndex, 1);
      return next;
    }

    broken = true;
    return null;
  }

  while (items.length > 0) {
    sorted.push(findNextLink() || items.shift());
  }

  if (broken) {
    while (hasLink(sorted[0], sorted[sorted.length - 1])) {
      sorted.unshift(sorted.pop());
    }
  }

  return sorted;
}

@withTheme
export default class ArcLayout extends Component {
  static defaultProps = {
    // the height at the arc's center point, by default this will render a flat line
    // a positive value will be a frown, but a negative value will turn it upside down
    // a value of '0' will render the items evenly spread out but with no connecting arc path
    arcHeight: 1,

    // prefix used for item class names
    classPrefix: 'arc',

    // prop used for getting id from item
    keyProp: 'id',

    // prop user for getting name from item
    nameProp: 'name',

    // prop user for getting optional label from item
    labelProp: 'label',

    // callback used to render an item in the arc
    // (item, props, index, items) => JSX
    itemRenderer: null,

    // get the link, if it exists, between two items
    getLink: () => ({ color: 'gray5' }),

    // selected item
    selected: null,

    // array of highlighted items
    highlighted: [],

    // color that links get on hover
    hoverColor: 'gray1',

    // color that links get on select
    selectColor: 'primary',

    // the thickness of the arc path
    strokeWidth: 2,

    // the width of the entire arc path
    width: 1000,

    // set to true to draw direct connections between items rather than relying on the built-in arc path
    drawDirectConnections: false
  };

  static getArcProps(props) {
    const { arcHeight, width } = { ...this.defaultProps, ...props };
    const height = Math.abs(arcHeight);
    const invert = arcHeight < 0;

    const innerAngle = Math.atan(width / 2 / height);
    const arcAngle = (Math.PI - 2 * innerAngle) * 2;

    const radius = width * (Math.sin((Math.PI - arcAngle) / 2) / Math.sin(arcAngle));

    const cx = width / 2;
    const cy = invert ? -Math.sqrt(radius ** 2 - (width / 2) ** 2) : radius;

    const middleAngle = invert ? Math.PI / 2 : (3 * Math.PI) / 2;
    const startAngle = invert ? middleAngle + arcAngle / 2 : middleAngle - arcAngle / 2;
    const endAngle = invert ? middleAngle - arcAngle / 2 : middleAngle + arcAngle / 2;

    return { radius, height, cx, cy, startAngle, endAngle };
  }

  static getPointOnArc(props, t) {
    const { cx, cy, radius, startAngle, endAngle } = this.getArcProps(props);
    const angle = startAngle + t * (endAngle - startAngle);
    return [cx + radius * Math.cos(angle), cy + radius * Math.sin(angle)];
  }

  state = {
    hoveredLink: null,
    selectedLink: null
  };

  constructor(props) {
    super(props);

    this.linkGradientId = `arc-layout-gradient-${uuid.v4()}`;
  }

  componentDidUpdate(prevProps) {
    const { isPopoverOpen } = this.props;

    if (prevProps.isPopoverOpen && !isPopoverOpen) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedLink: null });
    }
  }

  drawArc(from, to) {
    const { items } = this.props;
    const { cx, cy, radius, startAngle, endAngle } = ArcLayout.getArcProps(this.props);

    const fromT = items.length <= 1 ? 0.5 : from / (items.length - 1);
    const toT = items.length <= 1 ? 0.5 : to / (items.length - 1);

    const fromAngle = startAngle + fromT * (endAngle - startAngle);
    const toAngle = startAngle + toT * (endAngle - startAngle);

    const context = path();
    context.arc(cx, cy, radius, fromAngle, toAngle, fromAngle > toAngle);
    return context.toString();
  }

  /**
   * gets the center point of the item at the provided index
   */
  getPointAtIndex(index) {
    const { items } = this.props;
    const t = items.length <= 1 ? 0.5 : index / (items.length - 1);
    return ArcLayout.getPointOnArc(this.props, t);
  }

  hasLink(item1, item2) {
    const { getLink } = this.props;
    return getLink(item1, item2, { checkExistence: true });
  }

  getLink(item1, item2) {
    const { getLink, keyProp, selected, hovered, theme } = this.props;
    const { hoveredLink, selectedLink } = this.state;
    const link = getLink(item1, item2);

    if (link) {
      const { isFiltered } = link;
      const isSelectedLink =
        selectedLink && selectedLink.source === item1[keyProp] && selectedLink.target === item2[keyProp];
      const isHoveredLink =
        hoveredLink && hoveredLink.source === item1[keyProp] && hoveredLink.target === item2[keyProp];
      const isSelected = isSelectedLink || item1 === selected || item2 === selected;
      const isHovered = isHoveredLink || item1 === hovered || item2 === hovered;

      if (!link.color || isSelected || isHovered) {
        link.color = theme.name === 'dark' ? 'rgb(255,255,255,0.10)' : 'gray5';
        link.opacity = 1;

        if ((hovered || hoveredLink) && !isHovered) {
          link.opacity = 0.5;

          if (isSelected) {
            link.opacity = 0.2;
          }
        }

        if (isHovered) {
          link.color = theme.name === 'dark' ? '#ffffff' : 'gray1';
        }

        if (isSelected) {
          link.color = 'primary';
        }
      }

      if (isFiltered) {
        link.color = 'hybrid.filtered';

        if (link.totalTraffic === 0 && !isHovered) {
          link.opacity = 0.2;
        }
      }

      return { ...link, isHoveredLink, isSelectedLink, isHovered, isSelected };
    }

    return null;
  }

  handleItemHover = (item) => {
    const { onHover } = this.props;

    if (onHover) {
      onHover(item);
    }
  };

  handleItemSelect = (item) => {
    const { onSelect } = this.props;

    if (onSelect) {
      onSelect(item, { health: item.health });
    }
  };

  /** the length of each link between items, evenly divided */
  get linkLength() {
    const { items } = this.props;

    if (items.length <= 1) {
      return null;
    }

    const point1 = this.getPointAtIndex(0);
    const point2 = this.getPointAtIndex(1);

    return Math.sqrt((point2[0] - point1[0]) ** 2 + (point2[1] - point1[1]) ** 2);
  }

  get links() {
    const { classPrefix, keyProp, items, arcHeight, strokeWidth, drawDirectConnections, interSiteTrafficEnabled } =
      this.props;
    const lines = [];

    const drawLink = line().curve(curveBasis);

    for (let s = 0; s < items.length - 1; s += 1) {
      const source = items[s];

      for (let t = s + 1; t < items.length; t += 1) {
        const target = items[t];
        const link = this.getLink(source, target);

        if (link) {
          // console.log('link', link);
          const { color, inLatency, outLatency, inTraffic, outTraffic, totalTraffic, capacity, ...rest } = link;
          let pathData = '';
          let trafficData = {};
          let latencyData = {};

          if (!drawDirectConnections && t - s === 1) {
            pathData = this.drawArc(s, t);
          } else {
            const [sourceX, sourceY] = this.getPointAtIndex(s);
            const [targetX, targetY] = this.getPointAtIndex(t);
            pathData = drawLink([
              [sourceX, sourceY],
              [(sourceX + targetX) / 2, Math.min(arcHeight, Math.max(sourceY, targetY) + arcHeight / 2)],
              [targetX, targetY]
            ]);
          }

          if (totalTraffic) {
            const [sourcePath, targetPath] = splitPath(pathData, { at: outTraffic / totalTraffic });
            const [trafficPath] = splitPath(pathData, { at: 1, margin: 0, minimumPiece: 0 });

            trafficData = {
              sourcePath,
              targetPath,
              trafficPath,
              sourceTraffic: getTrafficLabel(outTraffic, capacity),
              targetTraffic: getTrafficLabel(inTraffic, capacity),
              canRenderDirection:
                sourcePath && sourcePath.canRenderDirection && targetPath && targetPath.canRenderDirection
            };
          }

          latencyData = {
            sourceLatency: getLatencyLabel(inLatency),
            targetLatency: getLatencyLabel(outLatency)
          };

          const lineToAdd = { ...rest, source, target, color, pathData, ...trafficData, ...latencyData };
          lines.push(lineToAdd);
        }
      }
    }

    const sortLink = ({ isHovered, isSelected }) => {
      if (isHovered) {
        return 2;
      }

      if (isSelected) {
        return 1;
      }

      return 0;
    };

    return lines
      .sort((a, b) => sortLink(a) - sortLink(b))
      .map((link) => {
        const {
          isHoveredLink,
          isSelectedLink,
          source,
          target,
          color,
          opacity,
          pathData,
          sourcePath,
          targetPath,
          sourceTraffic,
          targetTraffic,
          sourceLatency,
          targetLatency,
          isFiltered,
          canRenderDirection,
          trafficPath
        } = link;

        return (
          <g key={`${source[keyProp]}-${target[keyProp]}`}>
            {sourcePath && targetPath ? (
              <>
                <Path
                  fill="none"
                  stroke={color}
                  strokeWidth={strokeWidth}
                  opacity={opacity}
                  d={sourcePath.pathData}
                  className={classNames(sourcePath.textAlign, {
                    'animated-traffic': isFiltered
                  })}
                  id={`${classPrefix}-source-path-${source[keyProp]}-${target[keyProp]}`}
                />
                <Path
                  fill="none"
                  stroke={color}
                  strokeWidth={strokeWidth}
                  opacity={opacity}
                  d={targetPath.pathData}
                  className={classNames(targetPath.textAlign, {
                    'animated-traffic': isFiltered
                  })}
                  id={`${classPrefix}-target-path-${source[keyProp]}-${target[keyProp]}`}
                />
                <Path fill={color} opacity={opacity} d={sourcePath.arrowHead} />
                <Path fill={color} opacity={opacity} d={targetPath.arrowHead} />
                <g opacity={isHoveredLink || isSelectedLink ? 1 : 0} pointerEvents="none">
                  {canRenderDirection && (
                    <>
                      <Text
                        dy={-5}
                        fill={isSelectedLink ? 'primary' : 'muted'}
                        fontWeight={isSelectedLink ? 'bold' : 'normal'}
                        fontSize={12}
                      >
                        <textPath
                          href={`#${classPrefix}-source-path-${source[keyProp]}-${target[keyProp]}`}
                          startOffset={sourcePath.textAlign === 'left' ? '0%' : '100%'}
                          textAnchor={sourcePath.textAlign === 'left' ? 'start' : 'end'}
                        >
                          {sourceTraffic} {sourceLatency && `(${sourceLatency})`}
                        </textPath>
                      </Text>
                      <Text
                        dy={-5}
                        fill={isSelectedLink ? 'primary' : 'muted'}
                        fontWeight={isSelectedLink ? 'bold' : 'normal'}
                        fontSize={12}
                      >
                        <textPath
                          href={`#${classPrefix}-target-path-${source[keyProp]}-${target[keyProp]}`}
                          startOffset={targetPath.textAlign === 'left' ? '0%' : '100%'}
                          textAnchor={targetPath.textAlign === 'left' ? 'start' : 'end'}
                        >
                          {targetTraffic} {targetLatency && `(${targetLatency})`}
                        </textPath>
                      </Text>
                    </>
                  )}
                  {!canRenderDirection && (
                    <>
                      <Path
                        fill="none"
                        stroke="none"
                        strokeWidth={2}
                        d={trafficPath.pathData}
                        id={`device-traffic-path-${source[keyProp]}-${target[keyProp]}`}
                      />
                      <Text
                        dy={-5}
                        fill={isSelectedLink ? 'primary' : 'muted'}
                        fontWeight={isSelectedLink ? 'bold' : 'normal'}
                        fontSize={12}
                      >
                        <textPath
                          href={`#device-traffic-path-${source[keyProp]}-${target[keyProp]}`}
                          startOffset="50%"
                          textAnchor="middle"
                        >
                          {addTrafficLabelArrow(`${sourceTraffic} (${sourceLatency})`, sourcePath.textAlign)}
                        </textPath>
                      </Text>
                      <Text
                        dy={14}
                        fill={isSelectedLink ? 'primary' : 'muted'}
                        fontWeight={isSelectedLink ? 'bold' : 'normal'}
                        fontSize={12}
                      >
                        <textPath
                          href={`#device-traffic-path-${source[keyProp]}-${target[keyProp]}`}
                          startOffset="50%"
                          textAnchor="middle"
                        >
                          {addTrafficLabelArrow(`${targetTraffic} (${targetLatency})`, targetPath.textAlign)}
                        </textPath>
                      </Text>
                    </>
                  )}
                </g>
              </>
            ) : (
              <Path
                fill="none"
                stroke={color}
                strokeWidth={strokeWidth}
                opacity={opacity}
                d={pathData}
                className={classNames({ 'no-display': interSiteTrafficEnabled })}
              />
            )}
            <Path
              fill="none"
              stroke="transparent"
              strokeWidth={10}
              d={pathData}
              onMouseEnter={(e) => {
                e.persist();
                this.setState({ hoveredLink: { source: source[keyProp], target: target[keyProp] } });
              }}
              onMouseLeave={(e) => {
                e.persist();
                this.setState({ hoveredLink: null });
              }}
              onClick={(e) => {
                e.persist();
                const { onLinkClick } = this.props;

                if (onLinkClick) {
                  const rect = e.target.getBoundingClientRect();
                  const offsetX = e.clientX - rect.x;
                  const offsetY = e.clientY - rect.y;

                  this.setState({ selectedLink: { source: source[keyProp], target: target[keyProp] } });

                  onLinkClick({
                    source: source[keyProp],
                    target: target[keyProp],
                    offset: {
                      left: offsetX,
                      top: offsetY
                    },
                    rawLinkData: link
                  });
                }
              }}
              css={{ cursor: 'pointer' }}
              className={classNames('hybrid-map-selectable-link', {
                'no-display': !sourceTraffic && !targetTraffic && interSiteTrafficEnabled
              })}
            />
          </g>
        );
      });
  }

  get items() {
    const { items, hovered, selected, highlighted, noIconFill } = this.props;
    const { linkLength } = this;

    return items.map((item, index) => {
      if (!item) {
        return null;
      }

      const isSelected = selected === item;
      const isHovered = hovered === item;
      const isHighlighted = !isSelected && (highlighted.includes(item) || (selected && this.hasLink(selected, item)));
      const point = this.getPointAtIndex(index);

      return this.renderItem(
        item,
        {
          linkLength,
          point,
          isSelected,
          isHovered,
          className: classNames('arclayout-item-group', 'hybrid-map-selectable-node', {
            'no-icon-fill': noIconFill,
            hovered: isHovered,
            selected: isSelected,
            unselected: (selected || highlighted.length > 0) && !isSelected && !isHighlighted,
            highlighted: isHighlighted
          }),
          onClick: () => this.handleItemSelect(item, point),
          onMouseEnter: () => this.handleItemHover(item),
          onMouseLeave: () => this.handleItemHover(null)
        },
        index
      );
    });
  }

  renderItem(item, props, index) {
    const { classPrefix, keyProp, nameProp, labelProp, items, itemRenderer, itemType, noIconFill, noShape } =
      this.props;

    if (itemRenderer) {
      return itemRenderer(item, props, index, items);
    }

    const itemClassName = getMapClassname({ type: classPrefix, value: item[keyProp] });

    return (
      <ArcLayoutItemGroup {...props} key={item[keyProp]}>
        {!noShape && (
          <Circle className={classNames('arclayout-item-group', itemClassName, item.health?.cssClassName)} />
        )}
        <Text
          className={classNames('arclayout-item-group', {
            topleft: index < (items.length - 1) / 2,
            topright: index >= (items.length - 1) / 2
          })}
          fill="body"
        >
          {item[labelProp] || item[nameProp]}
        </Text>
        {itemType && (
          <ArcLayoutIcon
            className={classNames({ 'color-icon': noIconFill, [itemClassName]: noShape })}
            point={item.point}
            type={itemType}
            hovered={props.isSelected || props.isHovered}
            health={item.health?.cssClassName}
            iconSize={noShape ? 40 : 20}
          />
        )}
        {item.health && <circle className={classNames('health-indicator', item.health.cssClassName)} />}
      </ArcLayoutItemGroup>
    );
  }

  render() {
    return (
      <g>
        <g>{this.links}</g>
        <g>{this.items}</g>
      </g>
    );
  }
}
