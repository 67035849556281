import React, { Component } from 'react';
import { observer } from 'mobx-react';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import { MenuItem, ConfirmDialog, Box } from 'core/components';

@observer
class RemoveMenuItem extends Component {
  static defaultProps = {
    hideText: false,
    hideTitle: false,
    intent: 'danger',
    showIcon: true,
    text: 'Remove'
  };

  state = {
    isConfirmOpen: false
  };

  componentWillUnmount() {
    if (this.removePromise) {
      this.removePromise.cancel();
    }
  }

  handleRemove = async () => {
    const { model, onRemove } = this.props;

    this.removePromise = makeCancelable(onRemove ? Promise.resolve().then(() => onRemove()) : model.destroy());
    return this.removePromise.promise
      .then(() => this.handleCancel())
      .catch((err) => {
        if (!(err instanceof CanceledError)) {
          console.error('Error handling removal from menu item', err);
        }
      });
  };

  handleCancel = () => {
    this.setState({ isConfirmOpen: false });
  };

  onClick = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({ isConfirmOpen: !prevState.isConfirmOpen }));
  };

  render() {
    const { isConfirmOpen } = this.state;
    const {
      disabled,
      hideText,
      hideTitle,
      model,
      entityName,
      large,
      text,
      intent,
      showIcon,
      itemProps,
      small,
      confirmText,
      confirmButtonText
    } = this.props;

    const isCreating = model && model.isRequestActive('creating');
    const isSaving = model && model.isRequestActive('updating');

    return (
      <>
        <MenuItem
          disabled={disabled || isSaving || isCreating}
          text={hideText ? undefined : text}
          intent={intent}
          icon={showIcon ? 'trash' : null}
          large={large}
          small={small}
          onClick={this.onClick}
          {...itemProps}
        />
        {isConfirmOpen && (
          <ConfirmDialog
            title={hideTitle ? null : `${text} ${entityName}`}
            onConfirm={this.handleRemove}
            onCancel={this.handleCancel}
            confirmButtonIntent={intent}
            model={model}
            isOpen={isConfirmOpen}
            entityName={entityName}
            confirmButtonText={confirmButtonText || text}
          >
            <Box px={2} pt={2} pb={2}>
              {confirmText || `Are you sure you want to ${text.toLowerCase()} this ${entityName.toLowerCase()}?`}
            </Box>
          </ConfirmDialog>
        )}
      </>
    );
  }
}

export default RemoveMenuItem;
