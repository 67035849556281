import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Grid } from 'core/components';
import { Form, Field, InputGroup } from 'core/form';
import { ENTER } from 'core/util/keyCodes';

const fields = {
  savedSearchName: {
    placeholder: 'Give this search a name...',
    rules: 'required|max:75|min:3',
    messages: {
      required: 'A name is required',
      max: 'A maximum of 75 characters is allowed',
      min: 'Please enter at least 3 characters',
      not_in: 'The search name already exists'
    }
  }
};

const options = { name: 'Add Saved Search Form' };

@Form({ fields, options })
@observer
export default class AddSavedSearchForm extends React.Component {
  componentDidMount() {
    const { form, savedSearches } = this.props;
    const savedSearchNameField = form.getField('savedSearchName');
    const savedSearchNames = savedSearches.map(({ name }) => name).toString();
    const newRules = `${savedSearchNameField.rules}|not_in:${savedSearchNames}`;

    savedSearchNameField.setRules(newRules);
  }

  handleAddSavedSearch = () => {
    const { form, onAddSavedSearch } = this.props;

    onAddSavedSearch(form.getValue('savedSearchName'));
    form.reset();
  };

  handleInputKeyDown = (e) => {
    if (e.keyCode === ENTER) {
      this.handleAddSavedSearch();
    }
  };

  render() {
    const { form, savedSearches, searchTerm, onAddSavedSearch, ...containerProps } = this.props;

    return (
      <Grid alignItems="flex-start" gridTemplateColumns="auto min-content" gridColumnGap="8px" {...containerProps}>
        <Field name="savedSearchName" disabled={searchTerm === ''} className="no-margin">
          <InputGroup onKeyDown={this.handleInputKeyDown} fill small />
        </Field>
        <Button
          text="Save"
          intent="primary"
          disabled={!form.dirty || !form.valid}
          onClick={this.handleAddSavedSearch}
          small
        />
      </Grid>
    );
  }
}
