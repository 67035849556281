import React from 'react';
import { inject } from 'mobx-react';
import { FaCheck } from 'react-icons/fa';

import { Flex, Heading, Tag } from 'core/components';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { AzureRouteTable, AzureConsoleLink, VHubRouteTable } from 'app/views/hybrid/maps/azure/components';

import SidebarItem from '../SidebarItem';
import { greekDisplay } from '../cloudUtil';

const { ROUTE_TABLE, VIRTUAL_HUB, SUBNET } = ENTITY_TYPES.get('azure');

const AzureRouteTablePopover = ({ width, popoutTitle, $hybridMap, ...rest }) => {
  const { nodeData, type } = rest;
  const { azureCloudMapCollection } = $hybridMap;

  const relevantEntity =
    type === ROUTE_TABLE || type === VIRTUAL_HUB
      ? nodeData
      : azureCloudMapCollection.getEntityProperty(nodeData, ROUTE_TABLE);

  if (!relevantEntity) {
    return null;
  }

  let routes;
  if (type === ROUTE_TABLE || type === SUBNET) {
    routes = azureCloudMapCollection.getEntityProperty(relevantEntity, 'routes');
  }
  if (type === VIRTUAL_HUB) {
    routes = azureCloudMapCollection.getVHubRoutes(relevantEntity);
  }

  if (!routes) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title={
        <Flex alignItems="center" gap={1} width="100%">
          <Heading level={5} mb={0}>
            Route Table
          </Heading>
          <Tag intent="success" icon={FaCheck} title={`${routes.length} active routes`} minimal round flexGrow={1}>
            {greekDisplay(routes.length)}
          </Tag>
          <AzureConsoleLink entity={relevantEntity} />
        </Flex>
      }
      popoutTitle={popoutTitle}
      icon="route"
      dialogProps={{ width: `${width}px` }}
    >
      {type === ROUTE_TABLE || type === SUBNET ? (
        <AzureRouteTable routes={routes} />
      ) : (
        <VHubRouteTable routes={routes} />
      )}
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureRouteTablePopover);
