import { ENTITY_TYPES } from 'shared/hybrid/constants';

const { NETWORK_INTERFACE, SUBNET } = ENTITY_TYPES.get('aws');

const sourceTypeOptions = [
  {
    label: 'Network Interfaces',
    value: NETWORK_INTERFACE,
    labelKeys: ['id', 'Name', 'PrivateIpAddress', 'SubnetId', 'VpcId']
  },
  {
    label: 'Subnets',
    value: SUBNET,
    labelKeys: ['id', 'Name', 'CidrBlock', 'VpcId']
  },
  {
    label: 'Instance',
    value: 'Instance',
    labelKeys: [
      'id',
      'Name',
      'PrivateIpAddress',
      'PublicIpAddress',
      'PrivateDnsName',
      'PublicDnsName',
      'SubnetId',
      'VpcId'
    ]
  }
];

export const SourceFieldType = {
  label: 'Source Type',
  options: sourceTypeOptions,
  defaultValue: SUBNET
};

export const TargetFieldType = {
  label: 'Target Type',
  options: sourceTypeOptions,
  defaultValue: SUBNET
};
