import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { connectAlignedNodes, connectThroughLeft, connectToNetwork } from './commonUtils';

const { VNET_GATEWAY, EXPRESS_ROUTE_CIRCUIT } = ENTITY_TYPES.get('azure');
const { INTERCONNECT_ATTACHMENT, NETWORK } = ENTITY_TYPES.get('gcp');

/**
 * VPN Connection
 *   ↓
 *    → → → → → →
 *                ↓
 *                ↓
 *                ↓
 *                ↓
 *                ↓
 *                ↓
 *  VPN Gateway   ↓
 *     ↓          ↓
 *       ← ← ← ← ←
 *
 * routeLeft = true:
 *
 * VPN Connection
 *      ↓
 *  ← ← ←
 *  ↓
 *  ↓   VPN Gateway
 *  ↓       ↓
 *  ↓ → → → →
 */
const connectToVpnGateway = ({
  sourcePoint,
  targetPoint,
  regionLeft,
  regionRight,
  ySpacing,
  linkSpacing,
  routeLeft = false
}) => {
  let sideX = regionRight + linkSpacing;

  if (routeLeft === true) {
    sideX = regionLeft - linkSpacing;
  }

  const topY = sourcePoint[1] + ySpacing;
  const bottomY = targetPoint[1] + ySpacing;
  const connectionPoints = [
    [sourcePoint[0], topY],
    [sideX, topY],
    [sideX, bottomY],
    [targetPoint[0], bottomY]
  ];
  return { sourceAnchor: 'bottom', targetAnchor: 'bottom', connectionPoints };
};

/**
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const VPNConnectionConnector = ({ targetType, ...rest }) => {
  const alignedConnection = connectAlignedNodes({ ...rest });

  switch (targetType) {
    case 'VpnGateways':
    case VNET_GATEWAY:
    case EXPRESS_ROUTE_CIRCUIT:
      return alignedConnection !== null ? alignedConnection : connectToVpnGateway({ ...rest });
    case INTERCONNECT_ATTACHMENT:
      return alignedConnection !== null ? alignedConnection : connectToVpnGateway({ ...rest, routeLeft: true });
    case NETWORK:
      return connectToNetwork({ ...rest });
    // /** @TODO other target types */
    case 'CustomerGateways':
      return alignedConnection !== null ? alignedConnection : connectThroughLeft({ ...rest });
    default:
      return null;
  }
};

export default VPNConnectionConnector;
