import React from 'react';
import { inject, observer } from 'mobx-react';
import { getASNValues } from 'app/util/queryResults';
import { Box, EmptyState, Flex, Grid, MenuItem, Spinner } from 'core/components';
import { showInfoToast } from 'core/components/toast';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { ENTITY_TYPES } from 'shared/hybrid/constants';

import { IoIosGitNetwork } from 'react-icons/io';
import KubeNodeToNodeLinkGenerator from 'app/views/hybrid/maps/components/KubeNodeToNodeLinkGenerator';

import AbstractMap from '../components/AbstractMap';
import TopLevelBox from '../components/TopLevelBox';
import TopKeys from '../components/TopKeys/TopKeys';
import KubeBoxLinkGenerator from './KubeBoxLinkGenerator';
import KubeMapNoDevicesFound from './KubeMapNoDevicesFound';
import withPopover from '../components/popovers/withPopover';
import CircleLayout, { SPACING } from '../layouts/CircleLayout';
import TopServicesIPs from '../components/TopKeys/TopServicesIPs';
import TrafficLinkGenerator from '../components/TrafficLinkGenerator';
import {
  getInfrastructureDimension,
  getInfrastructureFilterGroup,
  getInternetDimension,
  getInternetFilterField,
  getKubeFilterGroup
} from '../components/popovers/queryOptionsHelper';
import { getHealthClass } from '../../utils/health';
// import ClusterItem from './ClusterItem';
import ResourceItem from './ResourceItem';

const { CLUSTER } = ENTITY_TYPES.get('kube');

@withPopover
@inject('$explorer', '$hybridMap', '$query')
@observer
class KubeMap extends AbstractMap {
  constructor(props) {
    super(props);

    if (process.env.NODE_ENV !== 'production') {
      window.KubeMap = this;
    }

    Object.assign(this.state, {
      loading: true,
      activeInfrastructureTabId: 'services',
      boxExpanded: {
        infrastructure: true,
        internet: true
      }
    });

    this.getQueryResultKey = this.getQueryResultKey.bind(this);
  }

  componentDidMount() {
    this.fetchTopology();
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarSettings } = this.props;
    const mapSearchChanged = prevProps.sidebarSettings.searchTerm !== sidebarSettings.searchTerm;
    const timeSettingsChanged =
      prevProps.sidebarSettings.timeRange.start !== sidebarSettings.timeRange.start ||
      prevProps.sidebarSettings.timeRange.end !== sidebarSettings.timeRange.end;

    if (mapSearchChanged) {
      this.topologyCollection.filter(sidebarSettings.searchTerm);
    }

    if (timeSettingsChanged) {
      this.fetchTopology();
      return;
    }

    super.componentDidUpdate(prevProps, prevState);
  }

  async fetchTopology() {
    const { $hybridMap, sidebarSettings } = this.props;
    this.setState({ loading: true });

    if (this.pendingPromise) {
      this.pendingPromise.cancel();
    }

    this.pendingPromise = makeCancelable(
      Promise.all([
        this.getLatency(),
        $hybridMap.kubeCloudMapCollection.fetch({ force: true, query: sidebarSettings.timeRange })
      ])
    );

    return this.pendingPromise.promise
      .then(([latencies]) => {
        this.topologyCollection.getEntities('clusters').forEach((cluster) => {
          const latency = latencies.get(cluster.device_name);
          const healthData = { state: latency > $hybridMap.latencyThreshold ? 'CRITICAL' : 'GOOD' };

          Object.assign(cluster, {
            latency,
            health: { cssClassName: getHealthClass(healthData), data: healthData }
          });
        });

        this.setState({ loading: false });
      })
      .catch((err) => {
        if (!(err instanceof CanceledError)) {
          console.error('Error loading topology', err);
          if (err.includes('No devices')) {
            $hybridMap.setKubeState({ loading: false, kubeInitializationFailed: true });
          }
        } else {
          console.warn('Promise was canceled.');
        }
      })
      .finally(() => {
        this.pendingPromise = null;
        this.setState({ loading: false });
      });
  }

  /**
   * Get latency for each cluster
   * @returns {Promise<Map<string, string>>}
   */
  async getLatency() {
    const { $query, $hybridMap } = this.props;

    const query = this.getQuery({
      metric: ['i_device_id'],
      aggregateTypes: ['p98th_ktsubtype__kappa__APPL_LATENCY_MS']
    });

    return $query
      .runQuery(query, true, true)
      .then(({ results }) => {
        const latencies = new Map();

        results.each((row) => {
          const deviceName = row.get('i_device_name');
          const latency = row.get('p98th_ktsubtype__kappa__APPL_LATENCY_MS');
          latencies.set(deviceName, latency);
        });

        $hybridMap.setKubeState({ loading: false });
        return latencies;
      })
      .catch((error) => {
        console.error('Unable to execute getLatency queries - ', error);
        if (error.includes('No devices')) {
          $hybridMap.setKubeState({ loading: false, kubeInitializationFailed: true });
        }

        throw error;
      });
  }

  shouldTargetLinkRollUp(type) {
    return type !== 'clusters';
  }

  getClusterFromIp(cidr) {
    const { topologyCollection } = this;
    const ip = cidr.replace(/\/\d+$/, '');
    return topologyCollection
      .get()
      .find((model) => model.get('entityType') === 'clusters' && model.searchableData.cidrs.includes(ip))?.id;
  }

  getQueryResultKey(result) {
    let key = result.get('lookup') || result.get('key');

    // replace ip -> cluster id
    const srcIp = result.get('inet_src_addr');
    const dstIp = result.get('inet_dst_addr');

    if (srcIp) {
      const srcCluster = this.getClusterFromIp(srcIp) || '---';
      key = key.replace(srcIp, srcCluster);
    }

    if (dstIp) {
      const dstCluster = this.getClusterFromIp(dstIp) || '---';
      key = key.replace(dstIp, dstCluster);
    }

    return key;
  }

  getNodeLinkQueries({ selectedNode, activeInternetTabId, activeInfrastructureTabId } = this.state) {
    const queries = [];

    if (selectedNode) {
      const { type: source, value } = selectedNode;
      const entity = this.topologyCollection.getEntity({ entityType: source, entityId: value });
      const deviceName = `${entity?.device_name || ''}`.toLowerCase();

      // clusters <-> clusters
      if (deviceName && source === 'clusters') {
        queries.push({
          type: 'clusters',
          getKey: this.getQueryResultKey,
          keyTypes: ['clusters', 'clusters'],
          selectedNode,
          queries: [this.getQuery({ metric: ['IP_src', 'IP_dst'] }, deviceName)]
        });
      }

      // clusters <-> internet
      if ((deviceName && source === 'clusters') || source === 'internet') {
        const subType = selectedNode.subType || activeInternetTabId;
        const aggregateTypes = ['avg_bits_per_sec'];
        const filterGroups = [];

        if (source === 'clusters') {
          aggregateTypes.push('avg_in_bits_per_sec', 'avg_out_bits_per_sec');
        }

        if (source === 'internet') {
          filterGroups.push({
            connectors: 'All',
            filters: [
              {
                filterField: getInternetFilterField({ subType }),
                operator: '=',
                filterValue: getASNValues(value).asnList
              }
            ]
          });
        }

        filterGroups.push(getKubeFilterGroup({ internet: true }));

        queries.push({
          type: 'clustersInternet',
          getKey: this.getQueryResultKey,
          keyTypes: ['src-internet', 'src-clusters', 'dst-clusters', 'dst-internet'],
          allowPairs: [
            ['src-internet', 'dst-clusters'],
            ['src-clusters', 'dst-internet']
          ],
          selectedNode,
          queries: [
            this.getQuery(
              {
                metric: [
                  getInternetDimension({ subType, direction: 'src' }),
                  'IP_src',
                  'IP_dst',
                  getInternetDimension({ subType, direction: 'dst' })
                ],
                aggregateTypes,
                filters: { connector: 'All', filterGroups }
              },
              deviceName
            )
          ]
        });
      }

      // clusters <-> infrastructure
      if ((deviceName && source === 'clusters') || source === 'infrastructure') {
        const subType = selectedNode.subType || activeInfrastructureTabId;
        const aggregateTypes = ['avg_bits_per_sec'];
        const filterGroups = [];

        if (source === 'clusters') {
          aggregateTypes.push('avg_in_bits_per_sec', 'avg_out_bits_per_sec');
        }

        if (source === 'infrastructure') {
          filterGroups.push(getInfrastructureFilterGroup({ subType, value }));
        }

        filterGroups.push(getKubeFilterGroup({ infrastructure: true }));

        queries.push({
          type: 'clustersInfrastructure',
          getKey: this.getQueryResultKey,
          keyTypes: ['src-infrastructure', 'src-clusters', 'dst-clusters', 'dst-infrastructure'],
          allowPairs: [
            ['src-infrastructure', 'dst-clusters'],
            ['src-clusters', 'dst-infrastructure']
          ],
          selectedNode,
          queries: [
            this.getQuery(
              {
                metric: [
                  getInfrastructureDimension({ subType, direction: 'src' }),
                  'IP_src',
                  'IP_dst',
                  getInfrastructureDimension({ subType, direction: 'dst' })
                ],
                aggregateTypes,
                filters: { connector: 'All', filterGroups }
              },
              deviceName
            )
          ]
        });
      }
    }

    return queries;
  }

  /**
   * @param {Object} query
   * @param {string?} device_name
   * @returns {Object}
   */
  getQuery(query, device_name = null) {
    const { $hybridMap } = this.props;

    return $hybridMap.getQuery({
      all_devices: false,
      device_types: device_name ? [] : ['kappa'],
      device_name: device_name ? [device_name] : [],
      hostname_lookup: false,
      show_overlay: false,
      show_total_overlay: false,
      aggregateTypes: ['avg_bits_per_sec', 'avg_in_bits_per_sec', 'avg_out_bits_per_sec'],
      viz_type: 'table',
      depth: 5000,
      topx: 5000,
      ...query
    });
  }

  /**
   * @returns {import('app/stores/hybrid/KubeCloudMapCollection').default}
   */
  get topologyCollection() {
    const { $hybridMap } = this.props;
    return $hybridMap.kubeCloudMapCollection;
  }

  handleClusterSelect = (cluster) => {
    if (cluster) {
      this.handleSelectNode({ type: 'clusters', value: cluster.id, nodeData: cluster });
    } else {
      this.handleSelectNode({ value: null });
    }
  };

  handleBoxLinksUpdate = (boxLinks) => {
    const links = this.cleanBoxLinkTraffic(boxLinks);
    this.setState({ boxLinks: links });
  };

  /**
   * @override
   * @borrowed from CloudAwsMap
   */
  handleNodeLinksUpdate(links) {
    const { selectedNode } = this.state;
    const processedLinks = links.flatMap((l) => l.links);

    if (processedLinks.length === 0 && selectedNode) {
      showInfoToast('No connections were found.');
    }

    const nodeLinks = [...this.getNodeLinks(), ...processedLinks];

    this.setState({
      nodeLinks,
      nodeLinksLoading: false
    });
  }

  handleInfrastructureTabChange = (tabId) => {
    const { selectedNode } = this.state;

    this.setState({ activeInfrastructureTabId: tabId });

    if (selectedNode?.type === 'infrastructure') {
      this.handleSelectNode({ value: null });
    }
  };

  handleSelectNode({ type, subType, value, nodeData, event }) {
    const { isEmbedded, openPopover } = this.props;
    const { selectedNode } = this.state;
    const cloudProvider = nodeData?.cloudProvider;

    if (event) {
      event.stopPropagation();
    }

    super.handleSelectNode({ type, subType, value, nodeData });

    this.setState((prevState) => {
      if (prevState.selectedNode) {
        const isSameNode = isEqual(selectedNode, prevState.selectedNode);
        const { node } = this.getNodePosition(prevState.selectedNode);

        if (node) {
          const { x, y, width, height } = node;

          const config = {
            ...prevState.selectedNode,
            isEmbedded,
            cloudProvider,
            isKubeDetail: true,
            showHealth: nodeData?.health,
            showTitle: nodeData?.name,
            position: { left: x, top: y, width, height },
            shortcutMenu: {
              selectedNode: prevState.selectedNode,
              showConnectionsCallback: this.setNodeLinks,
              isShowingConnections: prevState.nodeLinks.length > 0 && isSameNode,
              customItems: this.getCustomMenuItems(type, value)
            }
          };

          openPopover(config);
        }
      }

      return null;
    });
  }

  handleNodeLinkClick = (config) => {
    const { setSidebarDetails } = this.props;
    const { activeInternetTabId, activeInfrastructureTabId } = this.state;
    const { topologyCollection } = this;
    const { source, target } = config;

    if (source.type === 'internet') {
      source.subType = activeInternetTabId;
    } else if (source.type === 'infrastructure') {
      source.subType = activeInfrastructureTabId;
    }

    if (target.type === 'internet') {
      target.subType = activeInternetTabId;
    } else if (target.type === 'infrastructure') {
      target.subType = activeInfrastructureTabId;
    }

    setSidebarDetails({
      ...config,
      source: {
        ...source,
        nodeData: topologyCollection.getEntity({ entityType: source.type, entityId: source.value })
      },
      target: {
        ...target,
        nodeData: topologyCollection.getEntity({ entityType: target.type, entityId: target.value })
      }
    });
  };

  getCustomMenuItems(type, value) {
    const { history } = this.props;
    if (type === 'clusters') {
      const mapType = type.replace(/s$/, '');
      const id = value;

      const cluster = this.topologyCollection.topology.Entities?.[CLUSTER]?.[value];

      return [
        <MenuItem
          disabled={!cluster?.data}
          key="view-namespaces"
          text="View Namespaces"
          onClick={() => history.push(`/v4/cloud/kube/${mapType}/${id}`)}
          icon={IoIosGitNetwork}
        />
      ];
    }

    return null;
  }

  renderMap() {
    const { $hybridMap } = this.props;
    const { loading, boxExpanded, nodeLinkQueries, nodeLinksLoading, activeInternetTabId, activeInfrastructureTabId } =
      this.state;
    const { topologyCollection } = this;
    const topBoxHeight = 233;

    const { kubeInitializationFailed } = $hybridMap.kubeState;

    if (loading) {
      return <Spinner mt={100} />;
    }

    const regions = topologyCollection.topology.Hierarchy.regions || [];

    if (kubeInitializationFailed) {
      return <KubeMapNoDevicesFound />;
    }

    if (regions.length === 0) {
      return <EmptyState mt={4} icon="cloud" title="No Kubernetes Clusters Found" />;
    }

    return (
      <Box>
        <Grid gridTemplateAreas="'clouds internet' 'spinner spinner' 'kube kube'" gridGap={5} gridRowGap={3}>
          <Box gridArea="clouds">
            <TopLevelBox
              title="Other Infrastructure"
              boxProps={{
                className: classNames('box-infrastructure', 'link-bottomright', {
                  highlighted: this.isBoxHighlighted('infrastructure')
                }),
                height: topBoxHeight,
                overflow: 'hidden'
              }}
              onExpandToggle={(isExpanded) => this.setBoxExpanded('infrastructure', isExpanded)}
              expandedContent={
                <TopServicesIPs
                  classPrefix="infrastructure"
                  selected={this.getSelected('infrastructure')}
                  highlighted={this.getHighlighted('infrastructure')}
                  onSelect={(value, { event }) =>
                    this.handleSelectNode({ type: 'infrastructure', subType: activeInfrastructureTabId, value, event })
                  }
                  onTabChange={this.handleInfrastructureTabChange}
                />
              }
              isExpanded={boxExpanded.infrastructure}
              keepChildrenMounted
            />
          </Box>

          <Box gridArea="internet">
            <TopLevelBox
              title="Internet"
              boxProps={{
                className: classNames('box-internet', 'link-bottomleft', {
                  highlighted: this.isBoxHighlighted('internet')
                }),
                height: topBoxHeight,
                overflow: 'hidden'
              }}
              onExpandToggle={(isExpanded) => this.setBoxExpanded('internet', isExpanded)}
              expandedContent={
                <TopKeys
                  classPrefix="internet"
                  selected={this.getSelected('internet')}
                  hovered={this.getHovered('internet')}
                  highlighted={this.getHighlighted('internet')}
                  onSelect={(internet, { event }) =>
                    this.handleSelectNode({
                      type: 'internet',
                      subType: activeInternetTabId,
                      value: internet,
                      nodeData: { subType: 'internet-k8' },
                      event
                    })
                  }
                  onTabChange={this.handleInternetTabChange}
                  showProvidersTab={false}
                  showNextHopNetworksTab={false}
                  isKube
                />
              }
              isExpanded={boxExpanded.internet}
              keepChildrenMounted
            />
          </Box>

          <Box gridArea="spinner" justifySelf="center" width={50} height={50}>
            {nodeLinksLoading && <Spinner />}
          </Box>

          <Box gridArea="kube">
            <TopLevelBox
              isExpanded
              boxProps={{ p: 0, className: 'box-kube' }}
              expandedContent={
                <Flex gap={4} flexWrap="wrap" alignItems="center" justifyContent="center" width="100%" py={5} px={3}>
                  {regions.map(({ clusters, ...region }) => {
                    const radius = Math.max(385, (clusters.length / 2) * 50) / 2;
                    const width = (radius + 95) * 2;
                    const height = (radius + 50) * 2;
                    return (
                      <Box key={region.id} flex={`0 0 ${width}px`}>
                        <svg width={width} height={height} display="block">
                          <CircleLayout
                            titleStyles={{ fontSize: '13px', fontWeight: 500 }}
                            width={width}
                            height={height}
                            radius={radius}
                            title={region.name}
                            spacing={SPACING.Vertical}
                            items={clusters}
                            // renderItem={props => <ClusterItem {...props} />}
                            renderItem={(props) => (
                              <ResourceItem
                                type="clusters"
                                titleStyles={{ fontSize: '13px', fontWeight: 500 }}
                                empty={!props?.item?.data}
                                {...props}
                              />
                            )}
                            selected={this.getSelected('clusters')}
                            highlighted={this.getHighlighted('clusters')}
                            onSelect={this.handleClusterSelect}
                          />
                        </svg>
                      </Box>
                    );
                  })}
                </Flex>
              }
            />
          </Box>
        </Grid>

        <KubeBoxLinkGenerator onBoxLinksUpdate={this.handleBoxLinksUpdate} {...$hybridMap.kubeStateAlt} />
        <TrafficLinkGenerator inputs={nodeLinkQueries} onLinksUpdate={this.handleNodeLinksUpdate} />
        <KubeNodeToNodeLinkGenerator />
      </Box>
    );
  }
}

export default KubeMap;
