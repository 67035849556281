function customMinActivationTimeWindow(value) {
  const { input } = this.validator;
  const { evaluationPeriod } = input;
  const isHourly = input.activate.timeUnit === 'hour';

  // the activate.timeWindow must be greater than or equal to the evaluationPeriod (or Policy Window)
  // evaluationPeriod is stored in seconds, so convert to mins.
  if (evaluationPeriod) {
    return (isHourly && value >= 1) || (value >= 1 && value >= evaluationPeriod / 60);
  }

  // Fallback if no evaluation period - MKP policy forms don't always have one to evaluate
  return value >= 1;
}

export default {
  message:
    'Threshold activation frequency must be at least 1 minute and cannot be less than the Policy Evaluation Frequency',
  fn: customMinActivationTimeWindow
};
