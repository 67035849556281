function customConditionValue(value) {
  const { input } = this.validator;

  // We're using min:0 on this field too, so let negative numbers succeed to avoid duplicate error messages
  return input.value_type !== 'percent' || input.type === 'interface_capacity' || value < 0 || value >= 100;
}

export default {
  message: 'Value must be greater than 100%',
  fn: customConditionValue
};
