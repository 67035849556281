import * as React from 'react';
import { MdCompareArrows } from 'react-icons/md';
import { Box, Flex, Grid, Heading, Icon, Text } from 'core/components';
import PathSummary from 'app/views/cloudPerformance/nodes/PathSummary';

const boxLabels = {
  cloud: 'Clouds',
  internet: 'Internet',
  infrastructure: 'Other Infrastructure',
  site: 'On Prem',
  device: 'On Prem',
  kube: 'Kubernetes',
  k8node: 'Kubernetes Node',
  k8pod: 'Kubernetes Pod'
};

class LinkSummary extends React.Component {
  getLabel = (config) => {
    const { detail } = this.props;
    const { type, value, name, model, nodeData } = config;
    const isSiteDetail = detail && detail.type === 'site';

    if (nodeData?.name) {
      return nodeData.name;
    }

    if (type === 'box') {
      if (value === 'device') {
        if (isSiteDetail) {
          return detail.model.get('title');
        }
      }

      return boxLabels[value] || value;
    }

    if (type === 'cloud') {
      return name;
    }

    if (type === 'site' && model) {
      return model.get('title');
    }

    if (type === 'device' && model) {
      return model.get('device_name');
    }

    return value;
  };

  render() {
    const { source, target, linkLabel, popoutTitle, paths } = this.props;

    if (popoutTitle) {
      return (
        <Flex alignItems="center">
          <Icon icon={MdCompareArrows} color="violet3" mr={1} />
          <Text>{this.getLabel(source)}</Text>
          <Text mx={1}>--</Text>
          <Text>{this.getLabel(target)}</Text>
        </Flex>
      );
    }

    return (
      <Box p={2} borderBottom="thin">
        <Flex alignItems="center">
          <Icon icon={MdCompareArrows} iconSize={32} color="violet3" mr={1} />
          <Box>
            <Text as="div" muted>
              Traffic Details
            </Text>
            <Heading level={4}>
              <Text>Traffic between </Text>
              <Text>{this.getLabel(source)}</Text> and <Text>{this.getLabel(target)}</Text>
            </Heading>
            {paths && <PathSummary paths={paths} />}
          </Box>
        </Flex>
        {linkLabel && (
          <Grid gridTemplateColumns="auto 1fr" mt={2}>
            <Text muted>Type</Text>
            <Text>{linkLabel}</Text>
          </Grid>
        )}
      </Box>
    );
  }
}

LinkSummary.PopoutTitle = (props) => <LinkSummary {...props} popoutTitle />;

export default LinkSummary;
