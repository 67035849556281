import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, Text } from 'core/components';
import { ReactComponent as VPNConnectionIcon } from 'app/assets/icons/vpn-connection.svg';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';

function getTitle(config) {
  return config.nodeData?.Name || config.nodeData?.metadata?.name || config.value;
}

const VPNConnectionSummary = (config) => {
  const { logo, nodeData } = config;

  const { TransitGatewayId } = nodeData;
  const type = TransitGatewayId ? 'TGW' : 'VGW';

  return (
    <Box>
      <Flex p={2} borderBottom="thin" alignItems="center">
        {logo ? React.cloneElement(logo, { width: 32, height: 32 }) : <VPNConnectionIcon width={32} height={32} />}
        <Box flex={1} ml={1}>
          <Text as="div" muted>
            VPN Connection Details
          </Text>
          <Text muted small>
            {getTitle(config)}
          </Text>
        </Box>
      </Flex>
      {nodeData && (
        <SidebarItem title="Details" icon="more" popoutTitle={VPNConnectionSummary.PopoutTitle(config)}>
          <CloudMetadata
            node={{ ...nodeData, type, TransitGatewayId }}
            keys={['id', 'OwnerId', 'State', 'Tags', 'type', 'TransitGatewayId']}
          />
        </SidebarItem>
      )}
    </Box>
  );
};

VPNConnectionSummary.PopoutTitle = (config) => {
  const { logo } = config;
  return (
    <Flex alignItems="center">
      {logo || <VPNConnectionIcon />}
      <Box ml={1}>{getTitle(config)}</Box>
    </Flex>
  );
};

export default observer(VPNConnectionSummary);
