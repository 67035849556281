import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form } from 'core/form';
import { fields } from 'app/forms/config/UserDetails';
import { BorderedTab, BorderedTabs, Box, Button, Flex, Suspense } from 'core/components';
import UserInfo from 'app/views/settings/users/UserInfo';
import UserPermissions from 'app/views/settings/users/UserPermissions';
import UserFilters from 'app/views/settings/users/UserFilters';

import PageHeading from 'app/components/page/PageHeading';
import { ReactComponent as PersonIcon } from 'app/assets/icons/person.svg';
import RbacRoleCollection from 'app/stores/rbac/RbacRoleCollection';
import UserRoles from 'app/views/settings/users/UserRoles';
import { withRouter } from 'react-router-dom';
import RbacRoleSetCollection from 'app/stores/rbac/RbacRoleSetCollection';
import storeLoader from 'app/stores/storeLoader';

const options = {
  name: 'User Details'
};
@storeLoader('$rbac.roleSetCollection')
@inject('$auth', '$rbac')
@withRouter
@Form({ fields, options })
@observer
export default class UserDetailsForm extends Component {
  rbacRoles = new RbacRoleCollection();

  roleSets = new RbacRoleSetCollection();

  roleSetRoles = new RbacRoleCollection();

  componentDidMount() {
    const { $rbac, form, model } = this.props;
    const roles = model.get('roles');

    if (model.isNew) {
      this.setRbacRoleForLevel(form.getValue('role'));
    } else {
      this.rbacRoles.add(
        $rbac.collection
          .filter((role) => roles.includes(role.id), { immutable: true })
          .map((roleModel) => ({ ...roleModel.get() }))
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { loading, model, $rbac } = this.props;

    if (prevProps.loading && !loading) {
      const roleSets = model.get('role_sets');
      if (roleSets) {
        this.roleSets.add(
          $rbac.roleSetCollection
            .filter((role) => roleSets.includes(role.id), { immutable: true })
            .map((roleSetModel) => ({ ...roleSetModel.get() }))
        );
      }

      // using separate role collection so that it only shows up
      // under a role set & not as an independent role in sidebar
      if (this.roleSets.size > 0) {
        const roleSetRoleIds = Array.from(
          new Set(this.roleSets.map((roleSetModel) => roleSetModel.get('roles')).flat())
        );
        this.roleSetRoles.add(
          $rbac.collection
            .filter((role) => roleSetRoleIds.includes(role.id), { immutable: true })
            .map((roleModel) => ({ ...roleModel.get() }))
        );
      }
    }
  }

  setRbacRoleForLevel = (level) => {
    const { $rbac } = this.props;
    const levelRoleMapping = {
      Member: 'Members',
      Administrator: 'Administrators',
      'Super Administrator': 'Super Administrators'
    };

    const rbacRole = $rbac.collection.find({ company_id: null, role_name: levelRoleMapping[level] });

    if (rbacRole) {
      this.rbacRoles.set([{ ...rbacRole.get() }]);
    }
  };

  handleSave = () => {
    const { form, model } = this.props;
    const newValues = Object.assign({}, form.getValues(), {
      roles: this.rbacRoles.get().map((role) => role.id),
      role_sets: this.roleSets.get().map((roleSet) => roleSet.id)
    });
    model.set(newValues);

    return model.save();
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push('/v4/settings/users');
  };

  render() {
    const { $auth, form, title, loading } = this.props;
    const hasRolesOrSetsSelected = this.rbacRoles.size > 0 || this.roleSets.size > 0;

    const tabs = [
      <BorderedTab key="general" id="general" title="General Settings" panel={<UserInfo />} />,
      <BorderedTab
        id="permissions"
        key="permissions"
        title="User Level (legacy)"
        panel={<UserPermissions onUserLevelChange={this.setRbacRoleForLevel} />}
      />,
      <BorderedTab key="filters" id="filters" title="Filters" panel={<UserFilters />} />
    ];

    if ($auth.hasRbacPermissions(['rbac.role::read'])) {
      tabs.push(
        <BorderedTab
          id="rbac-roles"
          key="rbac-roles"
          title="RBAC Roles & Permissions"
          panel={
            <UserRoles
              roleCollection={this.rbacRoles}
              roleSetCollection={this.roleSets}
              roleSetRoleCollection={this.roleSetRoles}
            />
          }
        />
      );
    }

    return (
      <Suspense loading={loading}>
        <Flex justifyContent="space-between">
          <PageHeading title={title} icon={PersonIcon} mb={2} />
          <Flex justifyContent="flex-end">
            <Box>
              <Button
                onClick={this.handleSave}
                text="Save"
                mr={2}
                intent="primary"
                disabled={!hasRolesOrSetsSelected || !form.valid}
              />
            </Box>
            <Box>
              <Button onClick={this.handleCancel} text="Cancel" />
            </Box>
          </Flex>
        </Flex>
        <BorderedTabs minimal>{tabs}</BorderedTabs>
      </Suspense>
    );
  }
}
