import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { Table, Search } from 'core/components/table';
import { Box, Card, Label, Tag } from 'core/components';
import { AzureSecurityGroupRulesCollection } from 'app/stores/hybrid/azure';
import { AzureConsoleLink } from 'app/views/hybrid/maps/azure/components';

@withTheme
@observer
class SecurityGroupRulesTable extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    securityRules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ).isRequired
  };

  static defaultProps = {
    label: null
  };

  constructor(props) {
    super(props);

    const { securityRules } = props;
    this.collection = new AzureSecurityGroupRulesCollection(securityRules);
  }

  get columns() {
    return [
      {
        label: 'Direction',
        name: 'properties.direction',
        width: 90,
        renderer: ({ value }) => {
          const icon = value?.toLowerCase() === 'inbound' ? 'arrow-left' : null;
          const rightIcon = value?.toLowerCase() === 'outbound' ? 'arrow-right' : null;
          const label = value?.toLowerCase() === 'inbound' ? 'in' : 'out';

          return (
            <Tag icon={icon} rightIcon={rightIcon} small minimal>
              {label}
            </Tag>
          );
        }
      },
      {
        label: 'Resource',
        name: 'properties.sourceAddressPrefix',
        renderer: ({ value }) => (value === '*' ? 'Any' : value)
      },
      {
        label: 'Protocos',
        name: 'properties.protocol',
        renderer: ({ value }) => (value === '*' ? 'all protocols' : value)
      },
      {
        label: 'Port Range',
        name: 'properties.sourcePortRange',
        renderer: ({ model }) => {
          const direction = model.get('properties.direction');
          const portValue =
            direction?.toLowerCase() === 'inbound'
              ? model.get('properties.destinationPortRange')
              : model.get('properties.sourcePortRange');
          return portValue === '*' ? 'Any' : portValue;
        }
      },
      {
        label: 'Priority',
        name: 'properties.priority'
      },
      {
        label: 'Description',
        name: 'properties.description'
      },
      {
        label: null,
        name: 'viewInAzureConsole',
        width: 50,
        renderer: ({ model }) => {
          const data = model.toJS();
          return <AzureConsoleLink entity={data} hideLabel />;
        }
      }
    ];
  }

  handleSearch = (e) => this.collection.filter(e.target.value);

  render() {
    const { theme, label } = this.props;

    return (
      <Box>
        {label && <Label as="h4">{label}</Label>}
        <Card>
          <Box overflow="auto" height="30vh">
            <Search
              p="4px"
              collection={this.collection}
              onChange={this.handleSearch}
              placeholder={`Search ${label} Table...`}
              inputProps={{ value: this.collection.filterState }}
              autoFocus
            />
            <Table
              collection={this.collection}
              columns={this.columns}
              groupSummaryLookup={this.groupSummary}
              selectOnRowClick={false}
              isCollapsed
              flexed
              rowTags={({ model }) => {
                const tags = {};

                if (model.get('properties.access') === 'Deny') {
                  tags.style = { background: theme.colors.dangerBackground };
                }

                return tags;
              }}
            />
          </Box>
        </Card>
      </Box>
    );
  }
}

export default SecurityGroupRulesTable;
