import React from 'react';
import Page from 'app/components/page/Page';
import { EmptyState } from 'core/components';

const DisabledFeature = ({ title, feature }) => (
  <Page title={title || 'Feature Disabled'}>
    <EmptyState
      title={`${feature || 'This feature'} is disabled for your account. Please contact your administrator.`}
    />
  </Page>
);

export default DisabledFeature;
