import { ENTITY_TYPES } from 'shared/hybrid/constants';

const { TRANSIT_GATEWAY_ATTACHMENT, TRANSIT_GATEWAY } = ENTITY_TYPES.get('aws');

export const QUERY_NAMESPACE = 'AWS/TransitGateway';
// The only meaningful statistic is Sum.  See https://docs.aws.amazon.com/vpc/latest/tgw/transit-gateway-cloudwatch-metrics.html
export const MEANINGFUL_STATISTICS = ['Sum'];
/*
  Based on documentation https://docs.aws.amazon.com/vpc/latest/tgw/transit-gateway-cloudwatch-metrics.html

  BytesDropCountBlackhole
  The number of bytes dropped because they matched a blackhole route.

  BytesDropCountNoRoute
  The number of bytes dropped because they did not match a route.

  BytesIn
  The number of bytes received by the transit gateway.

  BytesOut
  The number of bytes sent from the transit gateway.

  PacketsIn
  The number of packets received by the transit gateway.

  PacketsOut
  The number of packets sent by the transit gateway.

  PacketDropCountBlackhole
  The number of packets dropped because they matched a blackhole route.

  PacketDropCountNoRoute
  The number of packets dropped because they did not match a route.
*/
export const METRICS = [
  'BytesIn',
  'BytesOut',
  'BytesDropCountNoRoute',
  'BytesDropCountBlackhole',
  'PacketsIn',
  'PacketsOut',
  'PacketDropCountNoRoute',
  'PacketDropCountBlackhole'
];

export const generateDimensionsByEntity = (nodeData, entityType) => {
  if (entityType === TRANSIT_GATEWAY) {
    return [
      {
        Name: 'TransitGateway',
        Value: nodeData.id
      }
    ];
  }
  if (entityType === TRANSIT_GATEWAY_ATTACHMENT) {
    return [
      {
        Name: 'TransitGatewayAttachment',
        Value: nodeData.id
      },
      {
        Name: 'TransitGateway',
        Value: nodeData.TransitGatewayId
      }
    ];
  }

  return [];
};
