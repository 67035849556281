import { ENTITY_TYPES } from 'shared/hybrid/constants';
import {
  curveDownLeft,
  curveLeftDown,
  curveRightDown,
  curveDownRight,
  curveRightUp,
  curveUpLeft
} from 'app/views/hybrid/utils/d3/curves';

const { FIREWALL } = ENTITY_TYPES.get('azure');

const connectToFirewall = ({
  ySpacing,
  vpcBottom,
  sourcePoint,
  targetPoint,
  regionRight,
  linkSpacing,
  sourceNodeHeight,
  targetNodeHeight
}) => {
  /**
   * horizontally aligned
   *
   *    Peering        Firewall
   *      ↓               ↑
   *       → → → → → → → →
   */
  if (Math.abs(sourcePoint[1] - targetPoint[1]) < sourceNodeHeight) {
    const bottomY = sourcePoint[1] + sourceNodeHeight / 2 + ySpacing / 2;
    return {
      sourceAnchor: 'bottom',
      targetAnchor: 'bottom',
      connectionPoints: [
        [sourcePoint[0], bottomY],
        [targetPoint[0], bottomY]
      ]
    };
  }

  const isPeeringOnTop = sourcePoint[1] < targetPoint[1];
  if (isPeeringOnTop) {
    /**
     * VNET Peering
     *     ↓
     *      → → →
     *           ↓
     *           ↓
     *      ← ← ←
     *     ↓
     *  Firewall
     */

    const topY = vpcBottom + linkSpacing / 2;
    const rightX = regionRight + linkSpacing;
    const bottomY = targetPoint[1] - targetNodeHeight / 2 - ySpacing / 2;

    const connectionPoints = [
      ...curveDownRight(sourcePoint[0], topY, linkSpacing),
      ...curveRightDown(rightX, topY, linkSpacing),
      ...curveDownLeft(rightX, bottomY, linkSpacing),
      ...curveLeftDown(targetPoint[0], bottomY, linkSpacing)
    ];

    return {
      sourceAnchor: 'bottom',
      targetAnchor: 'top',
      connectionPoints
    };
  }

  /**
   *       ← ← ← ← ←
   *      ↓         ↑
   *  Firewall      ↑
   *                ↑
   *                ↑
   *  VNET Peering  ↑
   *      ↓         ↑
   *      → → → → →
   */

  const bottomY = vpcBottom + linkSpacing / 2;
  const rightX = regionRight + linkSpacing;
  const topY = targetPoint[1] - targetNodeHeight / 2 - ySpacing / 2;

  const connectionPoints = [
    ...curveDownRight(sourcePoint[0], bottomY, linkSpacing),
    ...curveRightUp(rightX, bottomY, linkSpacing),
    ...curveUpLeft(rightX, topY, linkSpacing),
    ...curveLeftDown(targetPoint[0], topY, linkSpacing)
  ];

  return {
    sourceAnchor: 'bottom',
    targetAnchor: 'top',
    connectionPoints
  };
};

/**
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const VNETPeeringConnector = ({ targetType, ...rest }) => {
  switch (targetType) {
    case FIREWALL:
      return connectToFirewall({ ...rest });
    // case VNET_PEERING:
    //   return connectToPeering({ ...rest });
    default:
      return null;
  }
};

export default VNETPeeringConnector;
