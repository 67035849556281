import * as React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import storeLoader from 'app/stores/storeLoader';
import { getOtherSitesQuery } from './interSiteTrafficQueryHelper';

@storeLoader('$sites')
@inject('$hybridMap')
@observer
export default class OtherSites extends React.Component {
  static defaultProps = {
    sites: [],
    siteKeys: []
  };

  siteKeysDisposer;

  componentDidMount() {
    const { $hybridMap } = this.props;

    this.styleDisposer = reaction(
      () => $hybridMap.interSiteTrafficEnabled,
      (enabled) => {
        if (!enabled) {
          this.resetSiteKeys();
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.siteKeysDisposer) {
      this.siteKeysDisposer();
    }
  }

  get queryType() {
    const { $hybridMap } = this.props;
    return $hybridMap.settingsModel.get('interSiteTraffic');
  }

  get query() {
    const { $hybridMap, detailSite } = this.props;
    const query = getOtherSitesQuery(detailSite.get('title'));

    return $hybridMap.getQuery(query);
  }

  resetSiteKeys = () => {
    const { siteKeys, onSiteKeysUpdate } = this.props;
    const originalSiteKeys = siteKeys.filter((entry) => !entry.interSite);

    onSiteKeysUpdate(originalSiteKeys);
  };

  // a valid site id occurs when a title matches a model in the sites collection
  // and is contained in the list of viewable sites on the map
  getSiteId = (title) => {
    const { $sites, sites } = this.props;
    const model = $sites.collection.models.find((m) => m.get('title') === title);

    if (model) {
      if (sites.includes(model.id)) {
        return model.id;
      }
    }

    return null;
  };

  handleQueryComplete = ({ results, fullyLoaded }) => {
    if (fullyLoaded) {
      const { siteKeys, onSiteKeysUpdate } = this.props;
      const newSiteKeys = results.reduce((acc, model) => {
        const siteName = model.get('i_device_site_name');
        const siteId = this.getSiteId(siteName);

        if (siteId) {
          const existingEntry = acc.find((entry) => entry.key === siteId);

          // if we don't have this site in the keys bank, add it and mark it as an inter-site entry
          if (!existingEntry) {
            return [...acc, { key: siteId, label: siteName, interSite: true }];
          }
        }

        return acc;
      }, siteKeys);

      onSiteKeysUpdate(newSiteKeys);
    }
  };

  render() {
    const { $hybridMap, sites } = this.props;

    if ($hybridMap.interSiteTrafficEnabled && sites.length > 0) {
      return <LightweightDataViewWrapper query={this.query} onQueryComplete={this.handleQueryComplete} />;
    }

    return null;
  }
}
