import React from 'react';

import { inject } from 'mobx-react';
import { Flex, Box } from 'core/components';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import SidebarItem from '../SidebarItem';
import { AzureFirewallDetails } from './components';

const { FIREWALL } = ENTITY_TYPES.get('azure');

const AzureFirewallPopover = ({ width, popoutTitle, $hybridMap, nodeData }) => {
  const { azureCloudMapCollection } = $hybridMap;

  const firewall = azureCloudMapCollection.getEntityProperty(nodeData, FIREWALL);

  if (!firewall) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title="Firewall"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="shield"
      popoutTitle={popoutTitle}
    >
      <Flex px="4px" gap={1} flexDirection="column">
        <Box>
          <AzureFirewallDetails nodeData={firewall} width={width} popoutTitle={popoutTitle} />
        </Box>
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureFirewallPopover);
