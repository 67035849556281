import {
  curveUpLeft,
  curveLeftUp,
  curveUpRight,
  curveRightUp,
  curveLeftDown,
  curveDownLeft,
  curveDownRight,
  curveRightDown
} from 'app/views/hybrid/utils/d3/curves';

/**
 * example:
 *        Target
 *          ↑
 *  → → → →
 * ↑
 * ↑
 *  ← ← ← ←
 *          ↑
 *        Source
 */
export const connectThroughLeftCurved = ({ ...passThroughProps }) => {
  const { targetPoint, sourcePoint, regionLeft, linkSpacing, ySpacing } = passThroughProps;

  const leftX = regionLeft - linkSpacing * 1.5;

  const isTargetAbove = targetPoint[1] < sourcePoint[1];
  const bottomY = Math.max(sourcePoint[1], targetPoint[1]) - ySpacing;
  const topY = Math.min(sourcePoint[1], targetPoint[1]) + ySpacing;

  if (isTargetAbove) {
    return {
      sourceAnchor: 'top',
      targetAnchor: 'bottom',
      connectionPoints: [
        ...curveUpLeft(sourcePoint[0], bottomY, linkSpacing),
        ...curveLeftUp(leftX, bottomY, linkSpacing),
        ...curveUpRight(leftX, topY, linkSpacing),
        ...curveRightUp(targetPoint[0], topY, linkSpacing)
      ]
    };
  }

  return {
    sourceAnchor: 'bottom',
    targetAnchor: 'top',
    connectionPoints: [
      ...curveUpLeft(sourcePoint[0], topY, linkSpacing),
      ...curveLeftUp(leftX, topY, linkSpacing),
      ...curveUpRight(leftX, bottomY, linkSpacing),
      ...curveRightUp(targetPoint[0], bottomY, linkSpacing)
    ]
  };
};

/**
 * example:
 * Source                    Source
 *   ↓                         ↓
 *    → → → → → →  ← ← ← ← ← ←
 *                ↓
 *                ↓
 *                ↓
 *    ← ← ← ← ← ←   → → → → → →
 *   ↓                         ↓
 *  Target                   Target
 */
export const connectThroughMiddleXCurved = ({
  ySpacing,
  sourcePoint,
  linkSpacing,
  targetPoint,
  sourceNodeWidth,
  xDirectionModifier
}) => {
  const isSourceLeft = sourcePoint[0] < targetPoint[0];
  const sourceMiddleX = sourcePoint[0] + (sourceNodeWidth / 2 + 5) * xDirectionModifier;

  const bottomY = Math.max(sourcePoint[1], targetPoint[1]) - ySpacing;
  const topY = Math.min(sourcePoint[1], targetPoint[1]) + ySpacing;
  const isTargetAbove = targetPoint[1] < sourcePoint[1];

  if (isTargetAbove) {
    const sourceAnchor = 'top';
    const targetAnchor = 'bottom';
    if (isSourceLeft) {
      /**
       * example:
       *            Target
       *               ↑
       *           → →
       *          ↑
       *          ↑
       *          ↑
       *    → → →
       *   ↑
       *  Source
       */

      return {
        sourceAnchor,
        targetAnchor,
        connectionPoints: [
          ...curveUpRight(sourcePoint[0], bottomY, linkSpacing),
          ...curveRightUp(sourceMiddleX, bottomY, linkSpacing),
          ...curveUpRight(sourceMiddleX, topY, linkSpacing),
          ...curveRightUp(targetPoint[0], topY, linkSpacing)
        ]
      };
    }

    /**
     * example:
     *   Target
     *      ↑
     *       ← ←
     *          ↑
     *          ↑
     *          ↑
     *           ← ←
     *             ↑
     *           Source
     */

    return {
      sourceAnchor,
      targetAnchor,
      connectionPoints: [
        ...curveUpLeft(sourcePoint[0], bottomY, linkSpacing),
        ...curveLeftUp(sourceMiddleX, bottomY, linkSpacing),
        ...curveUpLeft(sourceMiddleX, topY, linkSpacing),
        ...curveLeftUp(targetPoint[0], topY, linkSpacing)
      ]
    };
  }

  // target is below
  const sourceAnchor = 'bottom';
  const targetAnchor = 'top';

  if (isSourceLeft) {
    /**
     * example:
     *  Source
     *     ↓
     *      → →
     *          ↓
     *          ↓
     *          ↓
     *           → →
     *              ↓
     *            Target
     */
    return {
      sourceAnchor,
      targetAnchor,
      connectionPoints: [
        ...curveDownRight(sourcePoint[0], topY, linkSpacing),
        ...curveRightDown(sourceMiddleX, topY, linkSpacing),
        ...curveDownRight(sourceMiddleX, bottomY, linkSpacing),
        ...curveRightDown(targetPoint[0], bottomY, linkSpacing)
      ]
    };
  }

  /**
   * example:
   *            Source
   *               ↓
   *           ← ←
   *          ↓
   *          ↓
   *          ↓
   *     ← ← ←
   *    ↓
   *  Target
   */
  return {
    sourceAnchor,
    targetAnchor,
    connectionPoints: [
      ...curveDownLeft(sourcePoint[0], topY, linkSpacing),
      ...curveLeftDown(sourceMiddleX, topY, linkSpacing),
      ...curveDownLeft(sourceMiddleX, bottomY, linkSpacing),
      ...curveLeftDown(targetPoint[0], bottomY, linkSpacing)
    ]
  };
};
