import React from 'react';

import { snakeCase, capitalize } from 'lodash';
import { greekIt } from 'app/util/utils';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { findEntity } from 'app/views/hybrid/utils/aws';
import { getEntityType } from 'app/views/hybrid/utils/map';

import CloudIcon from '../CloudIcon';

const {
  FIREWALL,
  ROUTE_TABLE,
  NAT_GATEWAY,
  VPN_GATEWAY,
  VNET_GATEWAY,
  VNET_PEERING,
  LOAD_BALANCER,
  P2S_VPN_GATEWAY,
  VNET_GATEWAY_CONNECTION,
  CORE_NETWORK_ATTACHMENT,
  HUB_VIRTUAL_NETWORK_CONNECTION
} = ENTITY_TYPES.get('azure');

const { CORE_NETWORK_EDGE } = ENTITY_TYPES.get('aws');
const { TARGET_VPN_GATEWAY } = ENTITY_TYPES.get('gcp');
const { DYNAMIC_ROUTING_GATEWAY_ATTACHMENT } = ENTITY_TYPES.get('oci');

export function getGatewayProps(item) {
  const cloudProvider = item?.cloudProvider ?? 'aws';
  const iconSize = { width: 21, height: 21 };

  if (item.VpnGatewayId) {
    return {
      type: 'Virtual Gateway',
      icon: <CloudIcon cloudProvider={cloudProvider} entity="virtualGateway" width={23} height={23} />
    };
  }

  if (item.InternetGatewayId) {
    return {
      type: 'Internet Gateway',
      icon: <CloudIcon cloudProvider={cloudProvider} entity="internetGateway" {...iconSize} />
    };
  }

  if (item.TransitGatewayAttachmentId) {
    return {
      type: 'TGW Attachment',
      displayName: 'TGW VPC Attachment',
      icon: <CloudIcon cloudProvider={cloudProvider} entity="transitGateway" {...iconSize} />
    };
  }

  if (item.NatGatewayId) {
    return {
      type: 'NAT Gateway',
      icon: <CloudIcon cloudProvider={cloudProvider} entity="natGateway" {...iconSize} />
    };
  }

  if (item.TransitGatewayId) {
    return {
      type: 'Transit Gateway',
      icon: <CloudIcon cloudProvider="aws" entity="transitGateway" width={21} height={20} />
    };
  }

  if (item.VpcPeeringConnectionId) {
    return {
      type: 'Peering Connection',
      icon: <CloudIcon cloudProvider={cloudProvider} entity="vpcPeering" width={20} height={19} />
    };
  }

  if (item.type === VNET_GATEWAY) {
    return {
      type: 'VNet Gateway',
      icon: <CloudIcon cloudProvider={cloudProvider} entity={VNET_GATEWAY} {...iconSize} />
    };
  }

  if (item.type === VNET_PEERING) {
    return {
      type: 'VNet Peering',
      icon: <CloudIcon cloudProvider={cloudProvider} entity={VNET_PEERING} width={25} height={25} />
    };
  }

  if (item.type === NAT_GATEWAY) {
    return {
      type: 'NAT Gateway',
      icon: <CloudIcon cloudProvider={cloudProvider} entity={NAT_GATEWAY} {...iconSize} />
    };
  }

  if (item.type === VNET_GATEWAY_CONNECTION) {
    return {
      type: 'VNet Gateway Connection',
      icon: <CloudIcon cloudProvider={cloudProvider} entity={VNET_GATEWAY_CONNECTION} {...iconSize} />
    };
  }

  const entityType = getEntityType(item) || '';

  if (entityType === VPN_GATEWAY) {
    return {
      // cap 1st character and removing ending 's' -> example azureFirewalls => AzureFirewall
      type: 'VPN Gateway',
      icon: <CloudIcon cloudProvider={cloudProvider} entity={VPN_GATEWAY} {...iconSize} />
    };
  }

  if (entityType === FIREWALL) {
    return {
      // cap 1st character and removing ending 's' -> example azureFirewalls => AzureFirewall
      type: 'Firewall',
      icon: <CloudIcon cloudProvider={cloudProvider} entity={FIREWALL} {...iconSize} />
    };
  }

  if (entityType === LOAD_BALANCER) {
    return {
      type: 'Load Ballancer',
      icon: <CloudIcon cloudProvider={cloudProvider} entity={LOAD_BALANCER} width={25} height={25} />
    };
  }

  if (entityType === HUB_VIRTUAL_NETWORK_CONNECTION) {
    return {
      // cap 1st character and removing ending 's' -> example azureFirewalls => AzureFirewall
      type: 'VNET Connection',
      icon: <CloudIcon cloudProvider={cloudProvider} entity={VNET_GATEWAY_CONNECTION} {...iconSize} />
    };
  }

  if (entityType === ROUTE_TABLE) {
    return {
      // cap 1st character and removing ending 's' -> example azureFirewalls => AzureFirewall
      type: 'VNET Router',
      icon: <CloudIcon cloudProvider={cloudProvider} entity={ROUTE_TABLE} {...iconSize} />
    };
  }

  if (entityType === P2S_VPN_GATEWAY) {
    return {
      type: 'P2S VPN Gateway',
      icon: <CloudIcon cloudProvider={cloudProvider} entity={P2S_VPN_GATEWAY} {...iconSize} />
    };
  }

  if (entityType === TARGET_VPN_GATEWAY) {
    return {
      type: 'Target VPN Gateway',
      icon: <CloudIcon cloudProvider={cloudProvider} entity={TARGET_VPN_GATEWAY} />
    };
  }

  if (entityType === DYNAMIC_ROUTING_GATEWAY_ATTACHMENT) {
    return {
      type: 'DRG Attachment',
      icon: <CloudIcon cloudProvider={cloudProvider} entity={DYNAMIC_ROUTING_GATEWAY_ATTACHMENT} />
    };
  }

  if (item.type === CORE_NETWORK_EDGE) {
    return {
      type: 'Core Network Edge',
      icon: <CloudIcon cloudProvider={cloudProvider} entity={CORE_NETWORK_EDGE} width={30} height={30} />
    };
  }

  if (item.type === CORE_NETWORK_ATTACHMENT) {
    return {
      type: 'Core Network Attachment',
      icon: <CloudIcon cloudProvider={cloudProvider} entity={CORE_NETWORK_ATTACHMENT} />
    };
  }

  return {
    // cap 1st character and removing ending 's' -> example azureFirewalls => Azure Firewall
    type: snakeCase(entityType.slice(0, -1)).split('_').map(capitalize).join(' '),
    icon: <CloudIcon cloudProvider={cloudProvider} entity={entityType} {...iconSize} />
  };
}

export function getVpcEndpointProps(item) {
  if (item.VpcEndpointType === 'Interface') {
    return {
      type: 'VPC Interface Endpoint',
      icon: <CloudIcon cloudProvider="aws" entity="internetGateway" />
    };
  }

  if (item.VpcEndpointType?.toLowerCase()?.includes('gateway')) {
    return {
      type: 'VPC Gateway Endpoint',
      icon: <CloudIcon cloudProvider="aws" entity="vpcEndpointGateway" />
    };
  }

  console.warn('Unknown VPC Endpoint type:', item.VpcEndpointType);
  return { type: 'VPC Endpoint' };
}

export function getGatewayIdsAndIpsFromPaths(paths, topology) {
  const gatewayIdsAndIps = paths.reduce(
    (acc, path) => {
      const vpnConnection = path.find((entity) => entity.type === 'VpnConnection');
      const gateway = path.find((entity) => entity.type === 'VpnGateway' || entity.type === 'TransitGateway');

      if (gateway) {
        acc.gatewayIds.add(gateway.id);
      }

      if (vpnConnection) {
        if (vpnConnection.VpnGatewayId && topology && topology.Entities) {
          const vpnGateway = findEntity(topology.Entities, vpnConnection.VpnGatewayId);
          ((vpnGateway && vpnGateway.VpcAttachments) || []).forEach((vpcAttachment) =>
            acc.vpcIds.add(vpcAttachment.VpcId)
          );
        }

        if (vpnConnection.Routes && vpnConnection.Routes.length > 0) {
          vpnConnection.Routes.forEach((route) => acc.ips.add(route.DestinationCidrBlock));
        }
      }

      return acc;
    },
    {
      gatewayIds: new Set(),
      ips: new Set(),
      vpcIds: new Set()
    }
  );

  return {
    gatewayIds: Array.from(gatewayIdsAndIps.gatewayIds),
    ips: Array.from(gatewayIdsAndIps.ips),
    vpcIds: Array.from(gatewayIdsAndIps.vpcIds)
  };
}

export function getRelatedGatewayIdsAndIps({ links, topology, source, target } = {}) {
  if (links) {
    return links.reduce(
      (acc, link) => {
        if (
          (source && link.source && link.source.value === source) ||
          (target && link.target && link.target.value === target)
        ) {
          const { gatewayIds, ips, vpcIds } = getGatewayIdsAndIpsFromPaths(link.paths, topology);

          acc.gatewayIds = acc.gatewayIds.concat(gatewayIds);
          acc.ips = acc.ips.concat(ips);
          acc.vpcIds = acc.vpcIds.concat(vpcIds);
        }

        return acc;
      },
      {
        gatewayIds: [],
        ips: [],
        vpcIds: []
      }
    );
  }

  return null;
}

export function greekDisplay(value) {
  const { display, unitsLabel } = greekIt(value, { fix: 1 });
  return unitsLabel ? `${display}${unitsLabel}` : value;
}
