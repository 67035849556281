import React from 'react';

import { inject } from 'mobx-react';
import { Flex, Text } from 'core/components';
import AzureVpnSiteDetails from './VpnSiteDetails';
import AzureVpnLinkConnectionDetails from './VpnLinkConnectionDetails';

import SidebarItem from '../../SidebarItem';
import CloudMetadata from '../../CloudMetadata';

const AzureVpnConnectionDetails = ({ width, popoutTitle, $hybridMap, nodeData }) => {
  const { azureCloudMapCollection } = $hybridMap;

  const vpnLinkConnections = azureCloudMapCollection.getEntityProperty(nodeData, 'vpnLinkConnections');
  const remoteVpnSite = azureCloudMapCollection.getEntityProperty(nodeData, 'remoteVpnSite');

  return (
    <SidebarItem
      excludeFormProps
      title={nodeData?.name}
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="data-connection"
      popoutTitle={popoutTitle}
    >
      <Flex flexDirection="column" gap={1}>
        <CloudMetadata
          node={{
            ...nodeData,
            ...nodeData.properties
          }}
          keys={['type', 'provisioningState', 'ingressBytesTransferred', 'egressBytesTransferred']}
        />
        {remoteVpnSite && (
          <Flex flexDirection="column" gap={1}>
            <Text as="div">VPN Sites:</Text>
            <AzureVpnSiteDetails nodeData={remoteVpnSite} width={width} popoutTitle={popoutTitle} />
          </Flex>
        )}
        {vpnLinkConnections.length && (
          <Flex flexDirection="column" gap={1}>
            <Text as="div">VPN Link Connections:</Text>
            {vpnLinkConnections.map((linkConnection) => (
              <AzureVpnLinkConnectionDetails
                key={linkConnection.id}
                nodeData={linkConnection}
                width={width}
                popoutTitle={popoutTitle}
              />
            ))}
          </Flex>
        )}
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureVpnConnectionDetails);
