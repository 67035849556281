/*
  AWS VPC and Azure VNet are logical equivalents
*/
import * as React from 'react';
import { observer } from 'mobx-react';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { getCustomProperties } from 'shared/util/map';
import { Box, Flex, Heading, Text } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import CloudDetailLink from 'app/components/links/CloudDetailLink';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import { getAzureVNetCIDRs } from 'app/views/hybrid/utils/map';

const VNET = ENTITY_TYPES.get('azure.vnet');
const NETWORK = ENTITY_TYPES.get('gcp.network');

function getTitle(config) {
  return (
    config.nodeData?.Name ||
    config.nodeData?.name ||
    config.nodeData?.metadata?.name ||
    config.metadata?.name ||
    config.value
  );
}

export function getMetadataValues({ cloudProvider, nodeData }) {
  const customProperties = getCustomProperties(nodeData);

  if (cloudProvider === 'azure') {
    return {
      node: {
        Name: nodeData.name,
        Location: nodeData.location,
        CIDRs: getAzureVNetCIDRs(nodeData),
        State: nodeData.properties.provisioningState || 'n/a',
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        resourceGroup: customProperties.uri?.resourceGroup,
        Tags: nodeData.tags
      }
    };
  }

  if (cloudProvider === 'gcp') {
    return {
      node: {
        ...nodeData,
        ...customProperties,
        'Routing Mode': nodeData.routingConfig?.routingMode,
        'Firewall Policy Enforcement': nodeData.networkFirewallPolicyEnforcementOrder
      },
      keys: ['selfLink', 'project', 'id', 'name', 'description', 'Routing Mode', 'Firewall Policy Enforcement']
    };
  }

  return { node: nodeData, keys: ['id', 'OwnerId', 'CidrBlock', 'State', 'Tags'] };
}

const VirtualNetworkSummary = (config) => {
  const { value, cloudProvider, nodeData } = config;
  const { node, keys } = getMetadataValues({ cloudProvider, nodeData });
  let layer = 'vpc';
  let layerName = 'VPC';
  let iconEntity = layer;

  if (cloudProvider === 'azure') {
    layer = 'vnet';
    layerName = 'VNet';
    iconEntity = VNET;
  }

  if (cloudProvider === 'gcp') {
    layerName = 'Network';
    iconEntity = NETWORK;
  }

  // gcp vpcs don't have a quick view
  const heading =
    cloudProvider === 'gcp' ? (
      getTitle(config)
    ) : (
      <CloudDetailLink cloud={cloudProvider} detail={`${layer}_id`} value={value}>
        {getTitle(config)}
      </CloudDetailLink>
    );

  return (
    <Box>
      <Flex borderBottom="thin" p={2} alignItems="center">
        <CloudIcon cloudProvider={cloudProvider} entity={iconEntity} width={32} height={32} />
        <Box flex={1} ml={1}>
          <Text as="div" muted>
            Cloud {layerName} Details
          </Text>
          <Heading mb={0} level={4}>
            {heading}
          </Heading>
        </Box>
      </Flex>
      {nodeData && (
        <SidebarItem title="Details" icon="more" popoutTitle={VirtualNetworkSummary.PopoutTitle(config)}>
          <CloudMetadata node={node} keys={keys} />
        </SidebarItem>
      )}
    </Box>
  );
};

VirtualNetworkSummary.PopoutTitle = (config) => {
  const { cloudProvider } = config;
  let layerName = 'VPC';
  let iconEntity = 'vpc';

  if (cloudProvider === 'azure') {
    layerName = 'VNet';
    iconEntity = VNET;
  }

  if (cloudProvider === 'gcp') {
    layerName = 'Network';
    iconEntity = NETWORK;
  }

  return (
    <Flex alignItems="center">
      <CloudIcon cloudProvider={cloudProvider} entity={iconEntity} />
      <Box ml={1}>{`${getTitle(config)} ${layerName}`}</Box>
    </Flex>
  );
};

export default observer(VirtualNetworkSummary);
