import * as React from 'react';
import { observer } from 'mobx-react';
import { Card, Flex } from 'core/components';
import { formConsumer } from 'core/form';
import SavedSearches from './SavedSearches';
import SearchSummary from './SearchSummary';
import AddSavedSearchForm from './AddSavedSearchForm';

@formConsumer
@observer
export default class MapSearchPanel extends React.Component {
  handleSearchTermChange = (searchTerm) => {
    const { form, onChange } = this.props;

    form.setValue('searchTerm', searchTerm);
    onChange();
  };

  handleAddSavedSearch = (searchName) => {
    const { form, onSavedSearchAdd } = this.props;

    onSavedSearchAdd({ name: searchName, value: form.getValue('searchTerm') });
  };

  render() {
    const { collection, form, onSavedSearchAdd, onSavedSearchEdit, onSavedSearchDelete, ...containerProps } =
      this.props;
    const savedSearches = form.getValue('savedSearches');

    return (
      <Card display="flex" flexDirection="column" {...containerProps}>
        <Flex flex={1} overflow="hidden">
          <Flex flexDirection="column" justifyContent="space-between" flex={1}>
            <SearchSummary collection={collection} onSearchTermChange={this.handleSearchTermChange} px={1} />

            <AddSavedSearchForm
              searchTerm={form.getValue('searchTerm')}
              savedSearches={savedSearches}
              onAddSavedSearch={this.handleAddSavedSearch}
              p={1}
              borderTop="thin"
              gridColumn="1/3"
            />
          </Flex>

          <SavedSearches
            flex={1}
            maxWidth={250}
            bg="subnavBackground"
            borderLeft="thin"
            savedSearches={savedSearches}
            onSavedSearchSelect={this.handleSearchTermChange}
            onSavedSearchEdit={onSavedSearchEdit}
            onSavedSearchDelete={onSavedSearchDelete}
          />
        </Flex>
      </Card>
    );
  }
}
