import classNames from 'classnames';
import { scaleLinear } from 'd3-scale';
import { memoize } from 'lodash';
import React, { Component } from 'react';
import { Circle, Text } from 'app/components/svg';

/** @enum {string} */
export const SPACING = {
  /** Space equally around the circle. Good for circles and square items */
  Equal: 'equal',
  /** Equalize vertical spacing between items. Good for rectangular items to prevent overlap */
  Vertical: 'vertical'
};

export default class CircleLayout extends Component {
  static defaultProps = {
    idProp: 'id',
    labelProp: 'name',
    spacing: SPACING.Equal,
    items: [],
    selected: '',
    highlighted: [],
    titleStyles: {}
  };

  nodeGroup = React.createRef();

  verticalScale = memoize(
    () => {
      const { items } = this.props;
      const { radius } = this;
      return scaleLinear([0, items.length / 2, items.length], [-radius, radius, -radius]);
    },
    () => {
      const { items } = this.props;
      return items.length;
    }
  );

  get width() {
    const { width } = this.props;
    return width || 500;
  }

  get height() {
    const { height } = this.props;
    return height || this.width;
  }

  get radius() {
    const { radius } = this.props;
    return radius || Math.min(this.width, this.height) / 2 - 100;
  }

  getPosition(index) {
    const { items, spacing } = this.props;
    const { radius } = this;

    if (spacing === SPACING.Equal) {
      const spacingAngle = (2 * Math.PI) / items.length;
      const x = radius * Math.sin(spacingAngle * index);
      const y = -radius * Math.cos(spacingAngle * index);
      return { x, y };
    }

    if (spacing === SPACING.Vertical) {
      const verticalScale = this.verticalScale();
      const y = verticalScale(index);
      const x = (index < items.length / 2 ? 1 : -1) * Math.sqrt(radius ** 2 - y ** 2);
      return { x, y };
    }

    return { x: 0, y: 0 };
  }

  handleSelect = (item) => {
    const { onSelect } = this.props;

    if (onSelect) {
      onSelect(item);
    }
  };

  renderItem(item) {
    const { idProp, labelProp, renderItem, selected, highlighted } = this.props;

    if (renderItem) {
      const isSelected = selected && item[idProp] === selected;
      const isHighlighted = !isSelected && highlighted.some((h) => item[idProp] === h);
      const isUnselected = (selected || highlighted.length > 0) && !isSelected && !isHighlighted;

      return renderItem({
        item,
        id: item[idProp],
        label: item[labelProp],
        className: classNames('node', 'hybrid-map-selectable-node', item.health?.cssClassName, {
          'node-selected': isSelected,
          'node-highlighted': isHighlighted,
          'node-unselected': isUnselected
        }),
        onClick: () => this.handleSelect(item)
      });
    }

    return <text>{item[labelProp]}</text>;
  }

  render() {
    const { title, items, idProp, titleStyles } = this.props;
    const { width, height } = this;
    return (
      <g transform={`translate(${width / 2}, ${height / 2})`}>
        <Circle r={this.radius} fill="none" stroke="muted" strokeWidth={2} strokeDasharray="4 2" />
        {title && (
          <Text textAnchor="middle" dominantBaseline="central" {...titleStyles}>
            {title}
          </Text>
        )}
        <g>
          {items.map((item, index) => {
            const { x, y } = this.getPosition(index);
            return (
              <g key={item[idProp]} transform={`translate(${x}, ${y})`}>
                {this.renderItem(item)}
              </g>
            );
          })}
        </g>
      </g>
    );
  }
}
