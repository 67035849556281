import * as React from 'react';
import { withTheme } from 'styled-components';
import { Box, Card, Flex, Heading, Highcharts, Icon, Text } from 'core/components';
import { timezone, DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';

@withTheme
export default class DeviceMetricsDetailCharts extends React.Component {
  getChartOptions({ color }) {
    const { theme } = this.props;

    return {
      chart: {
        type: 'area'
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%m/%e',
          minute: '%H:%M'
        }
      },
      yAxis: [
        {
          labels: {
            format: '{value}%'
          },
          floor: 0,
          max: 100,
          title: {
            text: null
          }
        }
      ],
      tooltip: {
        shared: true,
        crosshairs: true,
        formatter() {
          return `
            ${timezone.momentFn(this.points[0].x).format(DEFAULT_DATETIME_FORMAT)}<br />
            ${this.points.map(
              (point) =>
                `<span style="color: ${theme.colors[color]}">●</span> ${point.series.name}: <b>${point.y}%</b><br />`
            )}
          `;
        }
      },
      legend: { enabled: false }
    };
  }

  render() {
    const { data, title, icon, renderFooter, theme } = this.props;

    if (data.length > 0) {
      return (
        <>
          {title && (
            <Heading level={5} gridColumn="1/-1">
              <Icon icon={icon} iconSize={16} mr="4px" />
              {title}
            </Heading>
          )}
          {data.map(({ key, value, rawData, color, headingProps, ...rest }, _, all) => (
            <Box key={key} gridColumn={all.length === 1 ? '1/-1' : 'auto'}>
              <Flex mb={1} justifyContent="space-between">
                <Text ellipsis>{key}</Text>
                <Text {...headingProps}>{Math.round(value)}%</Text>
              </Flex>
              <Card>
                <Highcharts
                  height={100}
                  options={{
                    ...this.getChartOptions({ color }),
                    series: [{ name: key, data: rawData }]
                  }}
                  colors={[theme.colors[color]]}
                  containerProps={{ style: { width: '100%' } }}
                />
              </Card>
              {renderFooter && renderFooter({ ...rest })}
            </Box>
          ))}
        </>
      );
    }

    return null;
  }
}
