import React from 'react';
import { get } from 'lodash';
import { ENTITY_TYPES } from 'shared/hybrid/constants';

import SidebarItem from '../SidebarItem';
import CloudMetadata from '../CloudMetadata';

const { CUSTOMER_GATEWAY, VPN_CONNECTION } = ENTITY_TYPES.get('aws');

const AwsCustomerGatewaysPopover = ({ width, popoutTitle, type, nodeData, topology }) => {
  if (type !== VPN_CONNECTION) {
    return null;
  }

  const { CustomerGatewayId } = nodeData;

  const customerGateway = get(topology.Entities, `${[CUSTOMER_GATEWAY]}.${CustomerGatewayId}`);

  if (!customerGateway) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title="Customer Gateways"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="compass"
      popoutTitle={popoutTitle}
    >
      <CloudMetadata
        node={{
          ...customerGateway
        }}
        keys={['id', 'Name', 'State', 'IpAddress', 'Type']}
      />
    </SidebarItem>
  );
};

export default AwsCustomerGatewaysPopover;
