import { connectAlignedNodes, connectThroughMiddleX } from './commonUtils';

const connectToDirectConnectGateway = ({ ...rest }) => connectThroughMiddleX({ ...rest });

/**
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const DirectConnectionConnector = ({ targetType, ...rest }) => {
  const alignedConnection = connectAlignedNodes({ ...rest });
  switch (targetType) {
    case 'Lags':
    case 'VpnConnections':
    case 'DirectConnectGateways':
      return alignedConnection !== null ? alignedConnection : connectToDirectConnectGateway({ ...rest });
    /** @TODO other target types */
    default:
      return null;
  }
};

export default DirectConnectionConnector;
