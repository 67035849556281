import React, { Component } from 'react';

import { inject } from 'mobx-react';
import { Box, Flex, Card, Heading, Button, Text } from 'core/components';

import ContactAccountTeamDialog from 'app/views/licenses/ContactAccountTeamDialog';

@inject('$companySettings')
export default class AccountTeamCard extends Component {
  state = {
    accountTeam: [],
    contactMember: undefined,
    hoveredAccountMember: null,
    isContactDialogOpen: false
  };

  static defaultProps = {
    showContact: true
  };

  static getDerivedStateFromProps(props) {
    if (props.accountTeam) {
      return { accountTeam: props.accountTeam };
    }
    return null;
  }

  componentDidMount() {
    const { $companySettings } = this.props;
    const { accountTeam: stateAccountTeam } = this.state;
    if (!stateAccountTeam) {
      $companySettings.fetchAccountTeam().then((accountTeam) => {
        this.setState({ accountTeam });
      });
    }
  }

  onContactAccountTeamClick = ({ contactMember }) => {
    this.setState({ isContactDialogOpen: true, contactMember });
  };

  onContactAccountTeamClose = () => {
    this.setState({ isContactDialogOpen: false });
  };

  onAccountMemberMouseOver = (hoveredAccountMember) => {
    this.setState({ hoveredAccountMember });
  };

  getRoleLabel(role) {
    switch (role) {
      case 'AE':
        return 'Account Executive';
      case 'SE':
        return 'Solutions Engineer';
      case 'CSE':
        return 'Customer Success Engineer';
      case 'CSM':
        return 'Customer Success Manager';
      default:
        return role;
    }
  }

  render() {
    const { showContact } = this.props;
    const { isContactDialogOpen, accountTeam, hoveredAccountMember, contactMember } = this.state;

    if (accountTeam.length) {
      return (
        <Box mb={2}>
          <Heading level={4}>Account Team</Heading>
          <Card>
            {accountTeam.map((member) => {
              if (showContact) {
                return (
                  <Button
                    key={member.user_email}
                    textAlign="left"
                    minimal
                    fill
                    p={2}
                    onClick={() => this.onContactAccountTeamClick({ contactMember: member })}
                    onMouseOver={() => this.onAccountMemberMouseOver(member)}
                    onMouseOut={this.onAccountMemberMouseOver}
                    rightIcon={hoveredAccountMember === member && 'envelope'}
                  >
                    <Flex>
                      <Box>
                        <Text as="div">{this.getRoleLabel(member.user_role)}</Text>
                        <Text as="div" large fontWeight="bold">
                          {member.user.user_full_name}
                        </Text>
                      </Box>
                    </Flex>
                  </Button>
                );
              }

              return (
                <Box key={member.user_email}>
                  <Box>
                    <Text as="div">{this.getRoleLabel(member.user_role)}</Text>
                    <Text as="div" large fontWeight="bold">
                      {member.user.user_full_name}
                    </Text>
                  </Box>
                </Box>
              );
            })}

            {showContact && (
              <Flex alignItems="center" justifyContent="center" p={2}>
                <Button fill intent="primary" text="Contact Account Team" onClick={this.onContactAccountTeamClick} />
              </Flex>
            )}
          </Card>
          {isContactDialogOpen && (
            <ContactAccountTeamDialog
              isOpen={isContactDialogOpen}
              contactMember={contactMember}
              onClose={this.onContactAccountTeamClose}
            />
          )}
        </Box>
      );
    }
    return null;
  }
}
