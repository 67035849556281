import React from 'react';
import { Flex, Box, FlexColumn, Heading } from 'core/components';
import { MdSettingsEthernet } from 'react-icons/md';

import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import { formatBytesGreek } from 'core/util';
import { ViewInExplorerButton } from 'app/components/dataviews/tools';
import SidebarItem from './SidebarItem';
import CloudMetadata from './CloudMetadata';

/**
 * Show summary info and a chart for each connected interface
 */
const OnpremConnectedInterfaces = ({ width, popoutTitle, nodeData }) => {
  function getQuery(intf) {
    return {
      all_devices: false,
      aggregateTypes: ['p95th_ktappprotocol__snmp__INT64_00', 'p95th_ktappprotocol__snmp__INT64_02'],
      aggregateThresholds: {
        avg_bits_per_sec: 0,
        p95th_bits_per_sec: 0,
        max_bits_per_sec: 0,
        p95th_ktappprotocol__snmp__INT64_00: 0,
        p95th_ktappprotocol__snmp__INT64_02: 0
      },
      customAsGroups: false,
      device_name: [intf.device_name],
      fastData: 'Full',
      hostname_lookup: false,
      lookback_seconds: 86400,
      metric: [],
      units: ['ktappprotocol__snmp__INT64_00', 'ktappprotocol__snmp__INT64_02'],
      outsort: 'p95th_ktappprotocol__snmp__INT64_00',
      secondaryOutsort: 'p95th_ktappprotocol__snmp__INT64_02',
      secondaryTopxSeparate: false,
      secondaryTopxMirrored: true,
      show_overlay: false,
      show_total_overlay: false,
      starting_time: null,
      ending_time: null,
      viz_type: 'line',
      aggregates: [
        {
          value: 'p95th_ktappprotocol__snmp__INT64_00',
          column: 'f_sum_int64_00',
          fn: 'percentile',
          label: 'SNMP Interface Metrics Bits/s In 95th Percentile',
          rank: 95,
          unit: 'ktappprotocol__snmp__INT64_00',
          is_bytes: true,
          fix: 2,
          group: 'SNMP Interface Metrics Bits/s In',
          origLabel: '95th Percentile',
          sample_rate: 1,
          raw: true,
          name: 'p95th_ktappprotocol__snmp__INT64_00'
        },
        {
          value: 'p95th_ktappprotocol__snmp__INT64_02',
          column: 'f_sum_int64_02',
          fn: 'percentile',
          label: 'SNMP Interface Metrics Bits/s Out 95th Percentile',
          rank: 95,
          unit: 'ktappprotocol__snmp__INT64_02',
          is_bytes: true,
          fix: 2,
          group: 'SNMP Interface Metrics Bits/s Out',
          origLabel: '95th Percentile',
          sample_rate: -1,
          raw: true,
          name: 'p95th_ktappprotocol__snmp__INT64_02'
        }
      ],
      filters: {
        connector: 'All',
        filterGroups: [
          {
            name: '',
            named: false,
            connector: 'All',
            not: false,
            autoAdded: '',
            filters: [
              {
                filterField: 'ktappprotocol__snmp__output_port',
                operator: '=',
                filterValue: intf.snmp_id
              }
            ],
            saved_filters: [],
            filterGroups: []
          }
        ]
      }
    };
  }

  function getViewProps() {
    return {
      height: 200
      // COMMENTING FOR NOW until we find a better way to show this without washing out the chart?
      // ,plotLines: [
      //   {
      //     id: 'capacity-line',
      //     className: 'capacity-level',
      //     label: {
      //       text: `Capacity (${formatBytesGreek(intf.snmp_speed, '', 1)})`,
      //       align: 'left',
      //       x: -1,
      //       className: 'capacity-level'
      //     },
      //     value: intf.snmp_speed,
      //     width: 2,
      //     zIndex: 5
      //   }
      // ]
    };
  }

  if (!nodeData) {
    return null;
  }

  const { connectedInterfaces } = nodeData;

  if (!Array.isArray(connectedInterfaces) || connectedInterfaces.length === 0) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title="Connected Interfaces"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon={MdSettingsEthernet}
      popoutTitle={popoutTitle}
    >
      <Flex px="4px" gap={1} flexDirection="column">
        {connectedInterfaces.map((node) => (
          <SidebarItem
            key={node.snmp_id}
            excludeFormProps
            title={node.interface_description}
            dialogProps={{ width }}
            fullscreenProps={{ headingOffset: -16 }}
            icon={MdSettingsEthernet}
            popoutTitle={popoutTitle}
          >
            <FlexColumn>
              <Box key={node.snmp_id}>
                <CloudMetadata
                  node={{ ...node, Capacity: formatBytesGreek(node.snmp_speed, '', 1) }}
                  keys={[
                    'snmp_id',
                    'interface_description',
                    'snmp_alias',
                    'interface_ip',
                    'interface_ip_netmask',
                    'expressRouteCircuit.Circuit Name',
                    'expressRouteCircuit.peering.Peering Name',
                    'expressRouteCircuit.peering.Peering Role',
                    'expressRouteCircuit.peering.Peer Prefix',
                    'expressRouteCircuit.peering.Azure Port',
                    'Capacity'
                  ]}
                />
              </Box>
              <Box height={225}>
                <DataViewWrapper allowCache query={getQuery(node)} viewProps={getViewProps(node)}>
                  {({ component, footer }) => (
                    <FlexColumn>
                      <Heading level={5} my="10px">
                        In vs Out Utilization (bits/s)
                      </Heading>
                      <Flex flex={1} overflow="auto">
                        {component}
                        <ViewInExplorerButton
                          query={getQuery(node)}
                          position="absolute"
                          bottom="50px"
                          right="20px"
                          width="auto"
                          openInNewWindow
                        />
                      </Flex>
                      <Box pt={1}>{footer}</Box>
                    </FlexColumn>
                  )}
                </DataViewWrapper>
              </Box>
            </FlexColumn>
          </SidebarItem>
        ))}
      </Flex>
    </SidebarItem>
  );
};

export default OnpremConnectedInterfaces;
