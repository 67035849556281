import React from 'react';
import { inject } from 'mobx-react';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';

import SidebarItem from '../../SidebarItem';

const DrgRouteDistribution = ({ width, popoutTitle, $hybridMap, ...rest }) => {
  const { nodeData } = rest;

  if (!nodeData) {
    return null;
  }

  const cloudMetadataDetails = {
    node: {
      ...nodeData,
      Name: nodeData.displayName,
      Status: nodeData.lifecycleState,
      Type: nodeData.distributionType,
      'Created At': new Date(nodeData.timeCreated).toLocaleString()
    },
    keys: ['id', 'Name', 'Type', 'region', 'Status', 'Created At', 'compartmentId', 'tenancyId']
  };

  return (
    <SidebarItem title="DRG Route Distribution" icon="route" {...rest}>
      <CloudMetadata {...cloudMetadataDetails} />
    </SidebarItem>
  );
};

export default inject('$hybridMap')(DrgRouteDistribution);
