import React from 'react';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { VirtualizedTable } from 'core/components/table';
import { Box, Button, Card, Label, Tag, Flex } from 'core/components';
import { Form, Field, InputGroup, Select } from 'core/form';
import Collection from 'core/model/Collection';

@Form({ fields: { groupBy: { label: 'Group By' }, search: {} }, options: { name: 'Firewal Policy Rules Search Form' } })
@withTheme
@observer
class FirewallPolicyRulesTable extends React.Component {
  static defaultProps = {
    label: null
  };

  constructor(props) {
    super(props);

    const { firewallPolicyRules } = props;
    this.firewall_policy_rules_collection = new Collection(firewallPolicyRules);
  }

  get columns() {
    const { isPoppedOut } = this.props;

    const directionCol = {
      label: 'Direction',
      name: 'direction',
      width: 60,
      renderer: ({ value }) => {
        const icon = value?.toLowerCase() === 'ingress' ? 'arrow-left' : null;
        const rightIcon = value?.toLowerCase() === 'egress' ? 'arrow-right' : null;
        const label = value?.toLowerCase() === 'ingress' ? 'in' : 'out';

        return (
          <Tag icon={icon} rightIcon={rightIcon} small minimal>
            {label}
          </Tag>
        );
      }
    };

    const ruleActionCol = {
      label: 'Rule Action',
      name: 'action',
      renderer: ({ value }) => {
        const successTypes = ['allow', 'goto_next'];
        const intent = successTypes.includes(value) ? 'success' : 'danger';
        const actionLabelMap = {
          allow: 'Allow',
          goto_next: 'Go To Next'
        };
        return (
          <Tag intent={intent} small minimal>
            {actionLabelMap[value]}
          </Tag>
        );
      }
    };

    const poppedRuleActionCol = { width: 150, ...ruleActionCol };
    const unpoppedRuleActionCol = { width: 85, ...ruleActionCol };

    const ruleTupleCountCol = {
      label: 'Rule Tuple Count',
      name: 'ruleTupleCount',
      width: 130
    };

    const destCol = {
      label: 'Destination',
      name: 'destination',
      width: 90
    };

    const srcCol = {
      label: 'Source',
      name: 'source',
      width: 120
    };

    const poppedProtocolAndPortCol = {
      label: 'Protocol and Port',
      name: 'protocolAndPort',
      width: 150
    };

    const unpoppedProtocolAndPortCol = {
      label: 'Protocol and Port',
      name: 'protocolAndPort',
      width: 75
    };

    const priorityCol = {
      label: 'Priority',
      name: 'priority',
      width: 60
    };

    const targetsCol = {
      label: 'Targets',
      name: 'targets',
      width: 100,
      renderer: ({ value }) => (value && value.length ? value : 'Apply to all')
    };

    const descriptionCol = {
      label: 'Description',
      name: 'description',
      renderer: ({ value }) => value || '--'
    };

    const notPoppedOutColumns = [directionCol, unpoppedRuleActionCol, srcCol, destCol, unpoppedProtocolAndPortCol];
    const poppedOutColumns = [
      directionCol,
      poppedRuleActionCol,
      srcCol,
      destCol,
      poppedProtocolAndPortCol,
      priorityCol,
      targetsCol,
      ruleTupleCountCol,
      descriptionCol
    ];

    return isPoppedOut ? poppedOutColumns : notPoppedOutColumns;
  }

  get groupByOptions() {
    const { isPoppedOut } = this.props;

    const options = [
      { label: 'None', value: null },
      { label: 'Direction', value: 'direction' },
      { label: 'Action', value: 'action' },
      { label: 'Source', value: 'source' },
      { label: 'Destination', value: 'destination' },
      { label: 'Protocol and Port', value: 'protocolAndPort' }
    ];

    if (isPoppedOut) {
      options.push({ label: 'Rule Name', value: 'ruleName' });
    }

    return options;
  }

  groupSummary = ({ groupKey, group }) => {
    const groupKeyLabels = {
      INGRESS: 'Inbound',
      EGRESS: 'Outbound',
      '-1': 'all protocols',
      allow: 'Allow',
      deny: 'Deny',
      undefined: 'N/A'
    };

    return `${groupKeyLabels[groupKey] || groupKey} (${group.length})`;
  };

  handleGroupByChange = (field) => this.firewall_policy_rules_collection.group(field.getValue());

  handleSearchChange = (field) => this.firewall_policy_rules_collection.filter(field.getValue());

  handleSearchClear = () => {
    const { form } = this.props;

    form.setValue('search', '');
    this.firewall_policy_rules_collection.clearFilters();
  };

  render() {
    const { form, label } = this.props;

    return (
      <Box>
        {label && <Label as="h4">{label}</Label>}
        <Card display="flex" flexDirection="column">
          <Flex alignItems="center" borderBottom="thin" p={1} bg="subnavBackground">
            <Label mr={1} small>
              Group By:
            </Label>
            <Field
              options={this.groupByOptions}
              name="groupBy"
              showLabel={false}
              className="no-margin"
              placeholder="None"
              onChange={this.handleGroupByChange}
            >
              <Select menuWidth={130} />
            </Field>

            <Field name="search" className="no-margin" flex={1} onChange={this.handleSearchChange}>
              <InputGroup
                ml={1}
                leftIcon="search"
                rightElement={
                  form.getValue('search') !== '' && (
                    <Button icon="cross" onClick={this.handleSearchClear} minimal small />
                  )
                }
              />
            </Field>
          </Flex>
          <Box height="30vh">
            <VirtualizedTable
              collection={this.firewall_policy_rules_collection}
              columns={this.columns}
              groupSummaryLookup={this.groupSummary}
              selectOnRowClick={false}
              style={{ height: '100%' }}
            />
          </Box>
        </Card>
      </Box>
    );
  }
}

export default FirewallPolicyRulesTable;
