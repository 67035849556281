import * as React from 'react';
import { Box, Card, Flex, Text } from 'core/components';

function InterconnectLegend(props) {
  return (
    <Card display="flex" alignItems="center" p={1} {...props}>
      <Flex alignItems="center" mr={2}>
        <hr style={{ width: 12, borderStyle: 'dashed', marginLeft: 0, marginRight: 4 }} />
        <Text small>No Agent Installed</Text>
      </Flex>
      <Flex alignItems="center" mr={2}>
        <hr style={{ width: 12, borderStyle: 'solid', marginLeft: 0, marginRight: 4 }} />
        <Text small>Agent installed</Text>
      </Flex>
      <Flex alignItems="center" mr={2}>
        <Box width={12} height={12} bg="success" mr="4px" borderRadius={3} />
        <Text small>Healthy</Text>
      </Flex>
      <Flex alignItems="center" mr={2}>
        <Box width={12} height={12} bg="warning" mr="4px" borderRadius={3} />
        <Text small>Warning</Text>
      </Flex>
      <Flex alignItems="center">
        <Box width={12} height={12} bg="danger" mr="4px" borderRadius={3} />
        <Text small>Critical</Text>
      </Flex>
    </Card>
  );
}

export default InterconnectLegend;
