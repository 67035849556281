import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core';
import { FiKey, FiShare2 } from 'react-icons/fi';
import { RiDashboardLine } from 'react-icons/ri';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Icon,
  Link,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Suspense,
  Tab,
  Tabs,
  Tag,
  Text
} from 'core/components';
import { showSuccessToast } from 'core/components/toast';
import { RemoveMenuItem } from 'core/form';
import RemoveButton from 'core/form/components/RemoveButton';
import { formatDate } from 'core/util/dateUtils';
import LibraryCollection from 'app/stores/library/LibraryCollection';
import DashboardItemModel from 'app/stores/dashboard/DashboardItemModel';
import { ReactComponent as EnvelopeRemove } from 'app/assets/icons/envelope-remove.svg';
import Page from 'app/components/page/Page';
import KentikLogo from 'app/components/KentikLogo';
import AdminTable from 'app/components/admin/AdminTable';
import storeLoader from 'app/stores/storeLoader';
import DashboardDetailsDialog from 'app/views/core/dashboards/DashboardDetailsDialog';
import DashboardPanelFormDialog from 'app/views/core/dashboards/dashboardItem/DashboardPanelFormDialog';
import GuidedViewForm from 'app/views/core/GuidedViewForm';
import ExportMenuItem from 'app/components/dataviews/tools/options/ExportMenuItem';
import SavedViewFormDialog from 'app/views/core/savedViews/SavedViewFormDialog';
import FavoriteButton from 'app/views/core/FavoriteButton';
import SubscribeDialog from 'app/views/settings/subscriptions/SubscribeDialog';
import ShareViewDialog from 'app/views/core/ShareViewDialog';
import GuidedExportDialog from 'app/views/exports/GuidedExportDialog';
import GroupByControl from 'app/components/admin/GroupByControl';
import PageHeading from 'app/components/page/PageHeading';
import LabelList from 'app/components/labels/LabelList';
import CELL_TYPES from 'core/components/table/CELL_TYPES';
import moment from 'moment';
import LibraryFilterSidebar from './LibraryFilterSidebar';
import LibraryPanel from './LibraryPanel';

@storeLoader(
  '$dashboards.collection',
  '$labels.labels',
  '$mkp.packages',
  '$mkp.tenants',
  '$savedViews',
  '$subscriptions',
  '$users',
  '$recentlyViewed.trendingViewsCollection'
)
@inject('$auth', '$explorer', '$recentlyViewed', '$setup')
@withRouter
@HotkeysTarget
@observer
class Library extends Component {
  state = {
    showDashboardDialog: false,
    showSavedViewDialog: false,
    shareDialogOpen: false,
    subscribeDialogOpen: false,
    unsubscribeDialogOpen: false,
    guidedDialogOpen: false,
    allCollection: undefined,
    isFavoritesSectionOpen: false
  };

  constructor(props) {
    super(props);

    const { $setup } = props;
    this.state.isFavoritesSectionOpen = $setup.getSettings('libraryFavoritesPanelVisible') !== false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading } = this.props;

    if (!prevState.allCollection && prevProps.loading !== loading && !loading) {
      this.loadCollection();
    }
  }

  loadCollection() {
    const { $dashboards, $labels, $savedViews, $subscriptions, $auth, $recentlyViewed } = this.props;
    const { allCollection } = this.state;

    // Fine to serve somewhat stale data for these fields
    $recentlyViewed.companyWideCollection.fetch();
    $recentlyViewed.collection.fetch();
    $labels.labels.fetch();

    const promises = [$dashboards.collection.fetch(), $savedViews.collection.fetch()];

    return Promise.all(promises).then(() => {
      const allViews = new LibraryCollection(
        $dashboards.collection.unfiltered.concat($savedViews.collection.unfiltered)
      );
      $subscriptions.applySubscriptionsToContentCollection(allViews, $auth.getActiveUserProperty('user_email', ''));
      allViews.models.forEach((model) => {
        model.set('key', `${model.get('type')}||${model.get('id')}`);
      });

      if (allCollection) {
        allViews.setDiscreteFilters(allCollection.discreteFilters);
        allViews.filter();
      }

      this.setState({ allCollection: allViews });
      return allViews;
    });
  }

  toggleShareDialog = (model, dialogType) => {
    const { $subscriptions } = this.props;
    const content_type = model.get('type').replace(/-/g, '');
    const attributes = { content_type, content_id: model.id };

    if (model.isParametric) {
      Object.assign(attributes, { parametric_fields: model.get('parametric_fields') });
    }

    const dialogToOpen = {
      share: 'shareDialogOpen',
      subscribe: 'subscribeDialogOpen',
      unsubscribe: 'unsubscribeDialogOpen'
    }[dialogType];

    this.setState(() => ({
      [dialogToOpen]: true,
      selectedModel: $subscriptions.collection.forge(attributes, { select: false })
    }));
  };

  toggleGuidedDialog = (selectedModel) => {
    this.setState({ selectedModel, guidedDialogOpen: true });
  };

  createDashboardItem = () => {
    const { $explorer } = this.props;
    const { dataview } = $explorer;
    if (dataview.hasUpdateFrequency) {
      dataview.setUpdateFrequency();
    }
    // create a new DashboardItemModel model that we'll manipulate in the Dialog.
    //  We default the `title` to whatever the current DataView title is.
    const dashboardItem = new DashboardItemModel();
    dashboardItem.set({
      panel_title: dataview.title || 'My View Panel',
      saved_query_id: dataview.hash,
      queries: [dataview.queryBuckets.selectedQuery]
    });
    return dashboardItem;
  };

  handleCloneView = (model) => {
    const newModel = model.duplicate({ save: false });
    if (model.type === 'Dashboard') {
      newModel.isClone = true;
      newModel.origId = model.get('id');
      this.setState({
        showDashboardDialog: true,
        selectedModel: newModel,
        dashboardItem: undefined
      });
    }
    if (model.type === 'Saved View') {
      this.setState({
        showSavedViewDialog: true,
        selectedModel: newModel,
        dashboardItem: undefined
      });
    }
  };

  handleOpenEditMode = (model) => {
    const { history } = this.props;
    history.push(model.navigatePath, { isEditing: true });
  };

  handleEditView = (model) => {
    if (model.type === 'Dashboard') {
      this.setState({
        showDashboardDialog: true,
        selectedModel: model
      });
    }
    if (model.type === 'Saved View') {
      this.setState({
        showSavedViewDialog: true,
        selectedModel: model
      });
    }
  };

  handleAddToNewDashboard = () => {
    const { $dashboards } = this.props;
    const dashboardItem = this.createDashboardItem();
    const newDash = $dashboards.collection.forge({ share_level: 'self' });
    $dashboards.collection.select(newDash);
    this.setState({ showDashboardDialog: true, dashboardItem, selectedModel: newDash });
  };

  handleAddToExistingDashboard = () => {
    const dashboardItem = this.createDashboardItem();
    this.setState({ addToDashboardDialogOpen: true, dashboardItem });
  };

  handlePreviewTenant = (model, tenant) => {
    const { $explorer } = this.props;
    $explorer.applyTenantFilter(tenant);
    $explorer.navigateToExplorer(model.query);
  };

  handleAddToTenant = (model, tenant) => {
    tenant.addView(model).then(() => showSuccessToast(`Assigned "${model.name}" to tenant "${tenant.get('name')}"`));
  };

  handleAddToPackage = (model, pkg) => {
    pkg.addView(model).then(() => showSuccessToast(`Assigned "${model.name}" to package "${pkg.get('name')}"`));
  };

  setSelectedModel(selectedModel) {
    this.setState({ selectedModel });
  }

  loadView = (model) => {
    const { $explorer } = this.props;
    $explorer.loadView(model, false);
  };

  handleFavoritesSectionToggle = () => {
    const { $setup } = this.props;
    const { isFavoritesSectionOpen } = this.state;

    this.setState({ isFavoritesSectionOpen: !isFavoritesSectionOpen }, () => {
      $setup.updateSettings({ libraryFavoritesPanelVisible: !isFavoritesSectionOpen });
    });
  };

  getKebabActions = () => {
    const { $explorer, $mkp } = this.props;
    const kebabActions = [
      {
        id: 'openEdit',
        label: 'Open in Edit Mode',
        hidden: (model) => !model.canEdit || model.type !== 'Dashboard',
        handler: (model) => this.handleOpenEditMode(model),
        icon: RiDashboardLine
      },
      {
        id: 'edit',
        label: 'Edit Properties',
        hidden: (model) => !model.canEdit,
        handler: (model) => this.handleEditView(model),
        icon: 'edit'
      },
      {
        id: 'duplicate',
        label: (model) => `Clone ${model.type}`,
        handler: (model) => this.handleCloneView(model),
        icon: 'duplicate'
      },
      {
        hidden: (model) => model.isPreset || model.type === 'Dashboard',
        renderer: () => <MenuDivider key="divider-1" />
      },
      {
        id: 'addNew',
        label: 'Add To New Dashboard',
        hidden: (model) => model.type === 'Dashboard',
        handler: (model) => this.handleAddToNewDashboard(model),
        icon: 'plus'
      },
      {
        id: 'addExisting',
        label: 'Add To Existing Dashboard',
        hidden: (model) => model.type === 'Dashboard',
        handler: (model) => this.handleAddToExistingDashboard(model),
        icon: 'new-grid-item'
      },
      {
        renderer: () => <MenuDivider key="divider-2" />
      },
      {
        id: 'export',
        renderer: (model) => (
          <ExportMenuItem
            key="export"
            dataview={$explorer.dataview}
            dashboard={model.type === 'Dashboard' ? model : undefined}
            toggleGuidedDialog={() => this.toggleGuidedDialog(model)}
          />
        )
      },
      {
        id: 'subscribe',
        label: 'Subscribe',
        handler: (model) => this.toggleShareDialog(model, 'subscribe'),
        icon: 'envelope'
      },
      {
        id: 'unsubscribe',
        label: 'Unsubscribe',
        hidden: (model) => !model.get('isSubscribed'),
        handler: (model) => this.toggleShareDialog(model, 'unsubscribe'),
        icon: EnvelopeRemove
      },
      {
        id: 'share',
        label: 'Share',
        handler: (model) => this.toggleShareDialog(model, 'share'),
        icon: FiShare2
      },
      {
        id: 'mkp',
        hidden: $mkp.tenants.size === 0,
        renderer: (model) => (
          <Box key="mkp">
            <MenuDivider />
            <MenuItem icon={FiKey} text="My Kentik Portal" popoverProps={{ openOnTargetFocus: false }}>
              <MenuItem icon="user" text="Preview as Tenant" popoverProps={{ openOnTargetFocus: false }}>
                <Box maxHeight={300} overflow="auto">
                  {$mkp.tenants.map((tenant) => (
                    <MenuItem
                      key={tenant.id}
                      className="preview-as-tenant"
                      text={tenant.get('name')}
                      onClick={() => this.handlePreviewTenant(model, tenant)}
                    />
                  ))}
                </Box>
              </MenuItem>
              <MenuItem icon="following" text="Assign to Tenant" popoverProps={{ openOnTargetFocus: false }}>
                <Box maxHeight={300} overflow="auto">
                  {$mkp.tenants.map((tenant) => {
                    const viewType = model.type === 'Dashboard' ? 'dashboard' : 'savedView';
                    if (tenant.hasReport({ id: `${model.id}`, type: viewType })) {
                      return null;
                    }
                    return (
                      <MenuItem
                        key={tenant.id}
                        className="assign-to-tenant"
                        text={tenant.get('name')}
                        onClick={() => this.handleAddToTenant(model, tenant)}
                      />
                    );
                  })}
                </Box>
              </MenuItem>
              {$mkp.packages.size > 0 && (
                <MenuItem icon="cube" text="Add to Package" popoverProps={{ openOnTargetFocus: false }}>
                  <Box maxHeight={300} overflow="auto">
                    {$mkp.packages.map((pkg) => {
                      const viewType = model.type === 'Dashboard' ? 'dashboard' : 'savedView';
                      if (pkg.hasReport({ id: `${model.id}`, type: viewType })) {
                        return null;
                      }
                      return (
                        <MenuItem
                          key={pkg.id}
                          className="assign-to-package"
                          text={pkg.get('name')}
                          onClick={() => this.handleAddToPackage(model, pkg)}
                        />
                      );
                    })}
                  </Box>
                </MenuItem>
              )}
            </MenuItem>
          </Box>
        )
      },
      {
        hidden: (model) => !model.canEdit,
        renderer: () => <MenuDivider key="divider-3" />
      },
      {
        id: 'remove',
        hidden: (model) => !model.canEdit,
        renderer: (model) => (
          <RemoveMenuItem
            key="remove"
            model={model}
            entityName={model.type}
            confirmText={`Are you sure you want to remove "${model.name}"?`}
          />
        )
      }
    ];

    return kebabActions;
  };

  get tableHeaderControls() {
    const { allCollection } = this.state;
    return (
      <GroupByControl
        collection={allCollection}
        options={[
          { label: 'None', value: '' },
          { label: 'Owner', value: 'author' },
          { label: 'Sharing', value: 'shareLevel' },
          { label: 'View Type', value: 'type' }
        ]}
      />
    );
  }

  get actions() {
    return (
      <Popover position="bottom-right">
        <Button text="Add New" minWidth="100px" textAlign="left" rightIcon="caret-down" />
        <Menu>
          <MenuItem icon="new-grid-item" text="Dashboard" onClick={this.addNewDashboard} />
          <MenuItem icon="grouped-bar-chart" text="Saved View" onClick={this.addNewSavedView} />
        </Menu>
      </Popover>
    );
  }

  get columns() {
    return [
      {
        id: 'isFavorite',
        name: 'isFavorite',
        label: '',
        value: 'isFavorite',
        width: 42,
        ellipsis: false,
        renderer: ({ model }) => <FavoriteButton model={model} />,
        type: CELL_TYPES.ALWAYS_ACTIVE
      },
      {
        id: 'type',
        name: 'type',
        label: 'Type',
        value: 'type',
        width: 54,
        ellipsis: false,
        renderer: ({ model }) => {
          const icon = model.get('type') === 'dashboard' ? 'new-grid-item' : 'grouped-bar-chart';
          return (
            <Flex alignItems="center" justifyContent="center" width={30} height={30}>
              <Icon icon={icon} color="rgb(92, 112, 128)" />
            </Flex>
          );
        }
      },
      {
        id: 'name',
        name: 'name',
        label: 'Name',
        value: 'name',
        minWidth: 250,
        flexBasis: 400,
        ellipsis: false,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '4px',
        renderer: ({ model }) => {
          const { description, view_description, type, dash_title, view_name } = model.get();

          return (
            <>
              <Flex alignItems="center" width="100%" gap={2}>
                <Link
                  fontWeight="bold"
                  fontSize={16}
                  ellipsis
                  maxWidth="100%"
                  to={model.navigatePath}
                  style={{ minWidth: 'min-content' }}
                >
                  {type === 'saved-view' ? view_name : dash_title}
                </Link>

                {model.isTrending && (
                  <Flex>
                    <Tag minimal icon={<Icon icon="flame" intent="warning" />} py={0} minHeight={18}>
                      <Text>Trending</Text>
                    </Tag>
                  </Flex>
                )}

                <LabelList
                  labels={model.labels}
                  shareLevel={model.shareLevel}
                  priorityLabels={model.collection.discreteFilterValues.labelMultiSelect}
                />
              </Flex>
              {(description || view_description) && (
                <Text ellipsis small muted width="100%">
                  {description || view_description}
                </Text>
              )}

              {model.isParametric && (
                <Box maxWidth={400} width="100%">
                  <GuidedViewForm view={model} textStyle={{ marginBottom: 0 }} />
                </Box>
              )}
            </>
          );
        }
      },
      {
        id: 'shareLevel',
        name: 'shareLevel',
        label: 'Sharing',
        value: 'shareLevel',
        minWidth: 114,
        flexBasis: 80,
        renderer: ({ model }) => (
          <>
            {model.shareLevel === 'Preset' ? (
              <Flex alignItems="center">
                <KentikLogo onlyMark alt="Kentik" style={{ minWidth: 20 }} />
                <Text>Kentik Preset</Text>
              </Flex>
            ) : (
              <Flex alignItems="center" gap="4px">
                <Icon icon={model.shareLevel === 'Shared' ? 'people' : 'eye-open'} color="rgb(92, 112, 128)" />
                <Text>{model.shareLevel === 'Shared' ? 'Shared' : 'Private'}</Text>
              </Flex>
            )}
          </>
        )
      },
      {
        id: 'numSubscribers',
        name: 'numSubscribers',
        value: 'numSubscribers',
        label: 'Subscription',
        minWidth: 100,
        flexBasis: 80,
        renderer: ({ value = 0 }) => {
          const plural = value !== 1 ? 's' : '';
          return <Text>{value ? `${value} Member${plural}` : ''}</Text>;
        }
      },
      {
        id: 'author',
        name: 'author',
        value: 'author',
        label: 'Owner',
        minWidth: 100,
        flexBasis: 80,
        computed: true,
        renderer: ({ model }) => (
          <Text muted small>
            {model.author}
          </Text>
        )
      },
      {
        id: 'lastEditedDate',
        name: 'lastEditedDate',
        value: 'lastEditedDate',
        label: 'Last Edited',
        minWidth: 94,
        flexBasis: 50,
        computed: true,
        renderer: ({ value }) => (value ? formatDate(value) : '')
      },
      {
        id: 'companyLastViewedDate',
        name: 'companyLastViewedDate',
        value: 'companyLastViewedDate',
        label: 'Last Viewed',
        computed: true,
        renderer: ({ value }) => (value ? moment(value).fromNow() : 'N/A')
      }
    ];
  }

  get toolbarActions() {
    const { allCollection } = this.state;
    return [
      {
        id: 'spacer',
        type: 'spacer',
        containerProps: { flex: 1 },
        component: <Flex flex={1} flexGrow={1} />
      },
      {
        id: 'remove',
        type: 'remove',
        component: (
          <RemoveButton
            minimal={false}
            onRemove={this.onRemove}
            popoverProps={{
              confirmBodyContent: 'This will permanently remove selected items. Are you sure you want to continue?'
            }}
            text={`Remove View${allCollection.selected?.length > 1 ? 's' : ''}`}
            small
            showIcon
          />
        )
      }
    ];
  }

  get selected() {
    const { allCollection = {} } = this.state;
    const { selected } = allCollection;
    let selectedArray = [];
    if (selected !== undefined && selected !== null) {
      selectedArray = Array.isArray(selected) ? selected : [selected];
    }
    return selectedArray;
  }

  addNewSavedView = () => {
    const { $savedViews } = this.props;
    this.setState({ showSavedViewDialog: true, selectedModel: $savedViews.collection.forge() });
  };

  addNewDashboard = () => {
    const { $dashboards } = this.props;
    this.setState({
      showDashboardDialog: true,
      selectedModel: $dashboards.collection.forge()
    });
  };

  handleSaveDashboard = (model) => {
    const { history } = this.props;
    history.push(model.navigatePath, { isEditing: true });
  };

  onRemove = () => {
    const { allCollection } = this.state;
    allCollection.remove(
      this.selected.map((item) => item.id),
      true
    );
  };

  onClose = () => {
    this.setState({
      showDashboardDialog: false,
      showSavedViewDialog: false,
      addToDashboardDialogOpen: false,
      shareDialogOpen: false,
      subscribeDialogOpen: false,
      unsubscribeDialogOpen: false,
      guidedDialogOpen: false,
      selectedModel: undefined
    });

    this.loadCollection();
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey
          global
          group="Library"
          combo="shift + F"
          label="Toggle Favorites/Recents"
          onKeyDown={this.handleFavoritesSectionToggle}
        />
      </Hotkeys>
    );
  }

  groupSummaryLookup = ({ groupKey, group }) => (
    <>
      <Text fontWeight="bold" mx={1}>
        {groupKey === 'undefined' || groupKey === 'null' || !groupKey ? 'None' : groupKey}
      </Text>
      <Tag minimal round>
        {group.length}
      </Tag>
    </>
  );

  render() {
    const { $auth, $dashboards, $explorer, $recentlyViewed, loading } = this.props;
    const {
      showDashboardDialog,
      showSavedViewDialog,
      selectedModel,
      allCollection,
      shareDialogOpen,
      subscribeDialogOpen,
      unsubscribeDialogOpen,
      addToDashboardDialogOpen,
      dashboardItem,
      guidedDialogOpen,
      isFavoritesSectionOpen
    } = this.state;

    const contentType =
      selectedModel && selectedModel.get('content_type') === 'dashboard' ? 'dashboards' : 'saved-views';
    const privateShareUrl = selectedModel ? `/v4/library/${contentType}/${selectedModel.get('content_id')}` : '';

    const recentItems =
      $recentlyViewed.collection
        .map((i) => allCollection?.get(i.get('view_id')))
        .filter(Boolean)
        .sort((a, b) => a.lastViewedDate - b.lastViewedDate) || [];

    const favoriteItems = allCollection?.unfiltered?.filter((item) => item.isFavorite) || [];

    const subnavTools = (
      <ButtonGroup minimal style={{ gap: 8 }}>
        {(favoriteItems.length > 0 || recentItems.length > 0) && (
          <Button
            text="Favorites / Recents"
            active={isFavoritesSectionOpen}
            onClick={this.handleFavoritesSectionToggle}
            minimal
          />
        )}
        {this.actions}
      </ButtonGroup>
    );

    return (
      <Page docTitle="Library" subnavTools={subnavTools} overflow="hidden">
        <PageHeading title="Library" />
        <Suspense loading={loading}>
          {isFavoritesSectionOpen &&
            allCollection?.unfiltered?.length > 0 &&
            (favoriteItems.length > 0 || recentItems.length > 0) && (
              <Box mb={1} overflow="hidden">
                <Tabs
                  renderActiveTabPanelOnly
                  flexed
                  height="100%"
                  defaultSelectedTabId={favoriteItems.length ? 'favorites' : 'recent'}
                >
                  <Tab
                    id="favorites"
                    title="Favorites"
                    panel={
                      <LibraryPanel type="favorites" kebabActions={this.getKebabActions()} items={favoriteItems} />
                    }
                  />
                  <Tab
                    id="recent"
                    title="Recent"
                    panel={<LibraryPanel type="recent" kebabActions={this.getKebabActions()} items={recentItems} />}
                  />
                </Tabs>
              </Box>
            )}

          {allCollection && (
            <AdminTable
              filterSidebar={<LibraryFilterSidebar collection={allCollection} />}
              noHighlightSorted
              toolbarActions={$auth.isAdministrator && this.toolbarActions}
              showLabelActions
              tabPrefId="library-table"
              showColumnSelector
              defaultColumns={[
                'isFavorite',
                'name',
                'shareLevel',
                'numSubscribers',
                'author',
                'lastEditedDate',
                'companyLastViewedDate'
              ]}
              labelType={[
                { value: 'dashboard', filter: (model) => model.get('type') === 'dashboard' },
                { value: 'saved_view', filter: (model) => model.get('type') === 'saved-view' }
              ]}
              kebabActions={this.getKebabActions()}
              kebabProps={{
                onFocus: this.loadView,
                onMouseEnter: this.loadView
              }}
              multiSelect
              multiSelectProps={{ verticalAlign: 'center' }}
              rowKeyField="key"
              rowHeight={({ model }) => {
                if (model?.isGroupSummary) {
                  return 40;
                }

                let height = 40;

                if (model?.labels.length > 0) {
                  height += 22;
                }

                if (model?.isParametric) {
                  height += 46;
                }

                if (model.get('description') || model.get('view_description')) {
                  height += 18;
                }

                // Asserting that we don't actually want less than 52, because heights too varied
                // But we definitely don't want it as low as 30, that default is just so things add up nicely
                return Math.max(height, 52);
              }}
              collection={allCollection}
              columns={this.columns}
              tableHeaderControls={this.tableHeaderControls}
              groupSummaryLookup={this.groupSummaryLookup}
            />
          )}
        </Suspense>

        {showDashboardDialog && (
          <DashboardDetailsDialog
            isOpen={showDashboardDialog}
            onClose={this.onClose}
            model={selectedModel}
            collection={$dashboards.collection}
            onSubmit={this.handleSaveDashboard}
          />
        )}
        {showSavedViewDialog && <SavedViewFormDialog model={selectedModel} onClose={this.onClose} />}
        <DashboardPanelFormDialog
          isOpen={addToDashboardDialogOpen}
          onCancel={this.onClose}
          model={dashboardItem}
          dataview={$explorer.dataview}
          navigateOnSave={false}
        />
        <ShareViewDialog
          isOpen={shareDialogOpen}
          model={selectedModel}
          onClose={this.onClose}
          legacyLinkShare
          privateShareUrl={privateShareUrl}
        />
        <SubscribeDialog
          isOpen={subscribeDialogOpen}
          model={selectedModel}
          setModel={(updatedModel) => this.setSelectedModel(updatedModel)}
          onClose={this.onClose}
          subscriptionDialogType="subscribe"
        />
        <SubscribeDialog
          isOpen={unsubscribeDialogOpen}
          model={selectedModel}
          setModel={(updatedModel) => this.setSelectedModel(updatedModel)}
          onClose={this.onClose}
          subscriptionDialogType="unsubscribe"
        />
        {selectedModel?.isParametric && (
          <GuidedExportDialog view={selectedModel} isOpen={guidedDialogOpen} onClose={this.onClose} />
        )}
      </Page>
    );
  }
}

export default Library;
