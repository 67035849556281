import * as React from 'react';

export default class Pager extends React.Component {
  static defaultProps = {
    currentPage: 1,
    pageSize: 10
  };

  get pageCount() {
    const { data, pageSize } = this.props;
    const dataCount = data.length || data.size;

    return Math.ceil(dataCount / pageSize);
  }

  get pageData() {
    const { currentPage, data, pageSize } = this.props;
    const pageStart = (currentPage - 1) * pageSize;
    const pageEnd = pageStart + pageSize;

    return data.slice(pageStart, pageEnd);
  }

  render() {
    const { children, currentPage } = this.props;

    return children({
      pageData: this.pageData,
      pageCount: this.pageCount,
      currentPage
    });
  }
}
