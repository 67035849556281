import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Card, EmptyState, Grid, Icon, Tag, Text } from 'core/components';
import HealthDetailsItemContainer from './HealthDetailsItemContainer';
import HealthItem from './HealthItem';

@observer
export default class HealthDetails extends React.Component {
  get emptyState() {
    return <EmptyState icon="heart-broken" title="Health Data Unavailable" />;
  }

  get healthyState() {
    return (
      <HealthDetailsItemContainer>
        <Card p={1} mb={1}>
          <Grid gridTemplateColumns="45px 1fr" alignItems="center">
            <Tag intent="success" textAlign="center" height="32px">
              <Icon icon="small-tick" />
            </Tag>
            <Text>All health metrics are within normal operating ranges</Text>
          </Grid>
        </Card>
      </HealthDetailsItemContainer>
    );
  }

  render() {
    const { type, health, healthIssues } = this.props;
    const isValidType = type === 'site' || type === 'device' || type === 'interface';
    const hasHealthSummary = !!(health?.data?.length > 0);
    const hasHealthIssues = healthIssues?.size > 0;
    const isEmpty = !isValidType || (!hasHealthSummary && !hasHealthIssues);

    if (isEmpty) {
      // show empty when either there's an invalid type or we don't have a health summary or health issue breakdown to report
      return this.emptyState;
    }

    if (hasHealthIssues) {
      // displays a breakdown of the current health issues (less than healthy items)
      const { byType } = healthIssues;
      const groupTypes = ['interface_availability_down', 'interface_availability_operstatus'];
      const omitTypes = [];

      const groups = groupTypes.map((groupType) => {
        const group = byType[groupType];

        if (group?.length > 1) {
          omitTypes.push(groupType);
          return <HealthItem key={groupType} group={group} />;
        }

        return null;
      });

      return (
        <Box>
          {groups}
          {healthIssues.models
            .filter((healthIssue) => !omitTypes.includes(healthIssue.normalizedCheckName))
            .map((healthIssue) => (
              <HealthItem key={healthIssue.id} healthIssue={healthIssue} />
            ))}
        </Box>
      );
    }

    // when we fall down to here, it means we have a health summary of all healthy items and an empty healthIssues collection
    // in this case we can report that everything is healthy
    return this.healthyState;
  }
}
