import React from 'react';
import { themeGet } from 'styled-system';
import styled, { useTheme, css } from 'styled-components';
import { setLightness, setSaturation } from 'polished';
import classNames from 'classnames';
import { get } from 'lodash';

import { Rect, Path, Text, TextMeasure } from 'app/components/svg';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';

const lineHeight = 1.2;
const titleFontSize = 13;
const subtitleFontSize = 11;

function getColor(color, theme) {
  return get(theme.colors, color, color);
}

export const CloudItemRect = styled(Rect)`
  @keyframes glowing {
    0% {
      stroke: ${themeGet('colors.primary')};
      opacity: 1;
    }
    50% {
      stroke: ${themeGet('colors.primaryBackground')};
      opacity: 0.5;
    }
  }

  &.flashing {
    animation: glowing 750ms linear infinite;
  }

  cursor: pointer;

  &.unselected {
    opacity: 0.5;
  }

  &.hover,
  &:hover {
    opacity: 1;
    fill: ${({ stroke, theme, hoverFill }) =>
      (hoverFill && getColor(hoverFill, theme)) ||
      setSaturation(0.3, setLightness(theme.name === 'light' ? 0.94 : 0.18, getColor(stroke, theme)))};
  }

  &.selected {
    stroke: ${(props) => (props.cloudProvider === 'gcp' ? props.theme.colors.gcp.green : props.theme.colors.primary)};
    stroke-width: 2px;
  }

  &.highlighted {
    stroke: ${(props) => props.theme.colors.primary};
    stroke-width: 2px;

    ${({ theme, backgroundHighlightColor }) => {
      if (backgroundHighlightColor) {
        return css`
          fill: ${getColor(backgroundHighlightColor, theme)};
        `;
      }

      return null;
    }}
  }
`;
CloudItemRect.defaultProps = {
  fill: 'transparent',
  stroke: 'muted',
  strokeWidth: 2,
  rx: 4
};

export const CloudItemIconsRect = styled(Rect)`
  pointer-events: none;

  &.unselected {
    opacity: 0.5;
  }
`;
CloudItemIconsRect.defaultProps = {
  fill: 'transparent',
  stroke: 'muted',
  strokeWidth: 2,
  rx: 0
};

export function CloudItemIcons({ iconsToDisplay, height, width }) {
  const spacing = 6;
  const iconWidth = 26;
  const iconsWidthPositions = (width - spacing * 2) / iconsToDisplay.length;
  const size = Math.min(iconWidth, height) - spacing;
  const textHeight = titleFontSize * lineHeight - spacing / 3;
  const hasIcons = iconsToDisplay.some(({ entity }) => entity);
  const textOffsetY = hasIcons ? height - textHeight + spacing / 3 : height / 2 - lineHeight / 2;

  return iconsToDisplay.map(({ cloudProvider, entity, title, count }, index) => (
    <g key={`${cloudProvider}-${entity}`} letterSpacing="-0.035em" fontSize={titleFontSize} fontWeight={500}>
      <TextMeasure text={title}>
        {({ text, width: textWidth }) => (
          <g
            transform={`translate(${
              spacing + index * iconsWidthPositions + (iconsWidthPositions - Math.max(textWidth, size)) / 2
            } ${spacing / 2})`}
          >
            {entity && (
              <g transform={`translate(${textWidth / 2 - size / 2} 0)`}>
                <CloudIcon cloudProvider={cloudProvider} entity={entity} width={size} height={size} />
              </g>
            )}

            <Text dominantBaseline="central" x={0} y={textOffsetY} width={textWidth} fill="muted">
              {text}
              {count ? `(${count})` : null}
            </Text>
          </g>
        )}
      </TextMeasure>
    </g>
  ));
}

export function CloudItemInner({ title, subtitle = [], icon, width, height, color = 'body', withArrow, ...rest }) {
  if (Object.values(rest).length > 0) {
    console.warn('CloudItemInner: Unused props: ', rest);
  }

  const subtitles = Array.isArray(subtitle) ? subtitle : [subtitle];
  const id = `${title}-${subtitle}`.replace(/\W+/g, '_');

  const iconWidth = icon ? icon.props.width || 20 : 0;
  const iconHeight = icon ? icon.props.height || 20 : 0;

  const textHeight = (titleFontSize + subtitles.length * subtitleFontSize) * lineHeight;
  const textOffsetY = (height - textHeight) / 2;
  const textOffsetX = 6 + (icon ? iconWidth + 6 : 0);

  const titleTextY = textOffsetY + (titleFontSize * lineHeight) / 2;
  const subtitleTextY = textOffsetY + titleFontSize * lineHeight + (subtitleFontSize * lineHeight) / 2;

  const arrowWidth = 7;
  const arrowHeight = 6;
  const arrowMargin = 5;
  const textFadeWidth = withArrow ? 30 : 20;

  const availableTextWidth = width - (withArrow ? arrowWidth - arrowMargin : 0) - textOffsetX;

  return (
    <g pointerEvents="none">
      <defs>
        <linearGradient id={`gradient-${id}`}>
          <stop offset={0} stopColor="#fff" />
          <stop offset={withArrow ? 0.5 : 0.75} stopColor="#000" />
        </linearGradient>
        <mask id={`mask-${id}`}>
          <rect width={width - textFadeWidth} height={height} fill="#fff" />
          <rect x={width - textFadeWidth} width={textFadeWidth} height={height} fill={`url(#gradient-${id})`} />
        </mask>
      </defs>
      {icon && React.cloneElement(icon, { x: title ? 6 : (width - iconWidth) / 2, y: (height - iconHeight) / 3 })}
      <g letterSpacing="-0.035em">
        <g fontSize={titleFontSize} fontWeight={500}>
          <TextMeasure text={title}>
            {({ text, width: textWidth }) => (
              <Text
                mask={textWidth > availableTextWidth ? `url(#mask-${id})` : null}
                x={textOffsetX}
                y={titleTextY}
                dominantBaseline="central"
                fill={color}
              >
                {text}
              </Text>
            )}
          </TextMeasure>
        </g>
        {subtitles.map((st, idx) => (
          <g key={st?.key ?? st} fontSize={subtitleFontSize}>
            <TextMeasure text={st}>
              {({ text, width: textWidth }) => (
                <Text
                  mask={textWidth > availableTextWidth ? `url(#mask-${id})` : null}
                  x={textOffsetX}
                  y={subtitleTextY + idx * subtitleFontSize * lineHeight}
                  dominantBaseline="central"
                  fill={color}
                  fillOpacity={0.7}
                >
                  {text}
                </Text>
              )}
            </TextMeasure>
          </g>
        ))}
        {withArrow && (
          <g transform={`translate(${width - arrowWidth - arrowMargin} ${(height - arrowHeight) / 2})`}>
            <Path d={`M${arrowWidth / 2},${arrowHeight} L0,0 L${arrowWidth},0 Z`} fill={color} fillOpacity={0.7} />
          </g>
        )}
      </g>
    </g>
  );
}

export default function CloudItem({
  item,
  width,
  height,
  className,
  title,
  iconsToDisplay = [],
  subtitle = [],
  icon,
  color,
  withArrow,
  onClick,
  innerProps,
  ...props
}) {
  const theme = useTheme();

  if (!title) {
    console.warn(`CloudItem is missing - title: ${title}, subtitle: ${subtitle}`);
    return null;
  }

  const handleClick = (evt) => {
    evt.stopPropagation();

    if (onClick) {
      onClick(evt);
    }
  };

  const iconsRectHeight = Math.abs((innerProps?.height ?? 0) - height);

  return (
    <>
      <CloudItemRect
        className={classNames('hybrid-map-selectable-node', className)}
        width={width}
        height={height}
        onClick={handleClick}
        theme={theme}
        {...props}
      />
      <CloudItemInner
        width={width}
        height={height}
        title={title}
        subtitle={subtitle}
        icon={icon}
        color={color}
        withArrow={withArrow}
        {...innerProps}
      />
      {iconsToDisplay.length > 0 && (
        <g transform={`translate(0 ${height - iconsRectHeight})`} pointerEvents="none">
          <CloudItemIconsRect
            width={width}
            height={iconsRectHeight}
            className={className}
            // will draw top border of rect only
            // stroke dash array will draw border (top) for length = width, and keep rest 75% blank
            // strokeDasharray={`${width}, 75%`}
            theme={theme}
            {...props}
          />
          <CloudItemIcons height={iconsRectHeight} width={width} iconsToDisplay={iconsToDisplay} />
        </g>
      )}
    </>
  );
}
