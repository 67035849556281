import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { MdHome, MdLibraryBooks } from 'react-icons/md';
import { TiFlowSwitch } from 'react-icons/ti';
import { STATUS_TO_STATES } from 'app/stores/mitigations/mitigationsConstants';
import styled from 'styled-components';
import storeLoader from 'app/stores/storeLoader';
import AnimatedArrow, { animatedArrowStyles } from 'app/components/AnimatedArrow';

import { Box, Card, Flex, Grid, Icon, Link, LinkButton, Spinner, Tag, Text } from 'core/components';
import DetailTabs from 'app/components/detailTabs/DetailTabs';
import Page from 'app/components/page/Page';
import PageHeading from 'app/components/page/PageHeading';
import PageTabs, { PageTab } from 'app/components/page/PageTabs';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import LargeLabeledValue from 'app/components/LargeLabeledValue';
import Views from 'app/views/mkp/Views';
import AggregateViewHeader from 'app/views/core/aggregate/AggregateViewHeader';
import Suspense from 'core/components/Suspense';

const ArrowLink = styled(Link)`
  transition: all 150ms ease-in-out;
  color: ${({ theme }) => theme.colors.body};
  ${animatedArrowStyles};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    .alerting-widget-icon.bp4-icon {
      color: ${({ theme }) => theme.colors.primary} !important;
      svg {
        fill: ${({ theme }) => theme.colors.primary} !important;
      }
    }
  }
`;

@storeLoader('$alerting.standardPolicyCollection')
@inject('$app', '$auth', '$mitigations', '$exports', '$tabs')
@observer
export default class MKPLanding extends Component {
  state = {
    loadingAlerts: true,
    loadingMitigations: true,
    aggregateType: 'p95th_bits_per_sec',
    filters: [],
    lookback_seconds: 86400,
    activeAlerts: 0,
    clearedAlerts: 0,
    ackReqAlerts: 0
  };

  componentDidMount() {
    const { $mitigations, $alerting, $exports } = this.props;

    $exports.getSettings();

    if (this.hasAlerting()) {
      $alerting.getSummaryTotals({ includeSubpolicies: true }).then(({ states = {}, ackStates = {} }) => {
        const { alarm, clear } = states || {};
        const { ack_required } = ackStates || {};
        this.setState({ loadingAlerts: false, activeAlerts: alarm, clearedAlerts: clear, ackReqAlerts: ack_required });
      });
    }

    // How do we check if we should show this?
    $mitigations.activeMitigationCollection
      .setServerFilter({
        states: ['Active', 'Waiting', 'Failed'].flatMap((status) => STATUS_TO_STATES[status]),
        lookback: 86400
      })
      .then(() => {
        this.setState({ loadingMitigations: false });
      });
  }

  hasAlerting(props) {
    const { $auth } = props || this.props;
    return $auth.getActiveUserProperty('userGroup.config.alerts.length') > 0;
  }

  handleFiltersApply = (filters) => {
    this.setState({ filters });
  };

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds });
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType });
  };

  renderNetworkExplorerTab() {
    const { $auth, $tabs } = this.props;
    const { lookback_seconds, aggregateType, filters } = this.state;

    const queryOverrides = {
      aggregateTypes: [aggregateType],
      lookback_seconds
    };

    const { ASNS, IPS, COUNTRIES, REGIONS, CITIES } = $tabs.getTabs();

    // TODO: source from tenant definition
    if ($auth.hasSudo) {
      return (
        <PageTab
          id="ne"
          panel={
            <Box>
              <AggregateViewHeader
                aggregateType={aggregateType}
                filters={filters}
                lookbackSeconds={lookback_seconds}
                onFiltersChange={this.handleFiltersApply}
                onAggregateChange={this.handleAggregationChange}
                onMetricsChange={this.handleAggregationChange}
                onTimeRangeChange={this.handleLookbackChange}
                showButtons={{
                  simple: false,
                  agg: true,
                  metric: true,
                  time: true
                }}
              />

              <Card mb={3}>
                <TabbedChart queryName="mkp.inboundOutboundQuery" overrides={queryOverrides} height={200} />
              </Card>

              <Box>
                <DetailTabs queryOverrides={queryOverrides} defaultTabs={[ASNS, IPS, COUNTRIES, REGIONS, CITIES]} />
              </Box>
            </Box>
          }
          icon={MdHome}
          title={
            <Flex alignItems="center">
              <Text>Traffic Explorer</Text>
              <Tag intent="warning" ml={1}>
                Beta
              </Tag>
            </Flex>
          }
        />
      );
    }

    return null;
  }

  renderMainContent() {
    return (
      <Box>
        <PageHeading title="Explore Your Network" level={1} mb="12px" whiteSpace="nowrap" />
        <PageTabs>
          <PageTab id="library" panel={<Views />} icon={MdLibraryBooks} title="Library" />
          {this.renderNetworkExplorerTab()}
        </PageTabs>
      </Box>
    );
  }

  renderMitigationTotals() {
    const { $mitigations } = this.props;
    const { activeMitigationCollection } = $mitigations;
    const { loadingMitigations } = this.state;

    const countsByStatus = activeMitigationCollection.countsByStatus || {};
    const statuses = ['Active', 'Failed', 'Waiting'];

    return statuses.map((status) => (
      <LargeLabeledValue
        flex={1}
        key={status}
        label={status}
        labelTextProps={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '10px', muted: true }}
        valueElement={
          <Box showSkeleton={loadingMitigations}>
            <Text fontSize={20} fontWeight="black">
              {countsByStatus[status] || '0'}
            </Text>
          </Box>
        }
      />
    ));
  }

  renderAlertingTotals() {
    const { loadingAlerts, activeAlerts, clearedAlerts, ackReqAlerts } = this.state;
    const states = [
      { label: 'Active', count: activeAlerts },
      { label: 'Cleared', count: clearedAlerts },
      { label: 'Ack Req.', count: ackReqAlerts }
    ];

    return states.map(({ label, count }) => (
      <LargeLabeledValue
        flex={1}
        key={label}
        label={label}
        labelTextProps={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '10px', muted: true }}
        valueElement={
          <Box showSkeleton={loadingAlerts}>
            <Text fontSize={20} fontWeight="black">
              {Number(count).toLocaleString()}
            </Text>
          </Box>
        }
      />
    ));
  }

  render() {
    const { $app, loading, $auth } = this.props;

    return (
      <Page docTitle={$app.appName} scrolls hideSubnav>
        <Suspense
          loading={loading}
          fallback={
            <Box pt={3}>
              <Spinner size={24} />
            </Box>
          }
        >
          {this.hasAlerting() && (
            <Grid gridTemplateColumns="1fr minmax(225px, 320px)" gridGap={3}>
              {this.renderMainContent()}
              <Flex flexDirection="column" gap={2} mt={4} mr={2}>
                <Card p={2}>
                  <Flex mb={2} pb="4px" alignItems="flex-end" justifyContent="space-between" borderBottom="thin">
                    <ArrowLink to="/v4/alerting">
                      <Flex alignItems="center">
                        <Icon iconSize={14} mr={1} color="warning" icon="notifications" />
                        <Text fontSize={14} fontWeight="heavy" as="div">
                          Alerting
                        </Text>
                        <AnimatedArrow />
                      </Flex>
                    </ArrowLink>
                    <Text as="div" muted small fontWeight="normal">
                      Last 24 Hours
                    </Text>
                  </Flex>
                  <Flex>{this.renderAlertingTotals()}</Flex>
                  {$auth.isTenantNotificationChannelCreationAllowed && (
                    <Flex borderTop="thin" pt={1} mt={1} justifyContent="flex-end">
                      <LinkButton
                        text="Manage Notifications"
                        icon="cog"
                        // intent="warning"
                        to="/v4/notifications"
                        minimal
                        small
                      />
                    </Flex>
                  )}
                </Card>

                <Card p={2}>
                  <Flex mb={2} pb="4px" alignItems="flex-end" justifyContent="space-between" borderBottom="thin">
                    <ArrowLink to="/v4/mitigations">
                      <Flex alignItems="center">
                        <Icon iconSize={14} mr={1} color="warning" icon={TiFlowSwitch} />
                        <Text fontSize={14} fontWeight="heavy" as="div">
                          Mitigations
                        </Text>
                        <AnimatedArrow />
                      </Flex>
                    </ArrowLink>
                    <Text as="div" muted small fontWeight="normal">
                      Last 24 Hours
                    </Text>
                  </Flex>
                  <Flex>{this.renderMitigationTotals()}</Flex>
                </Card>
              </Flex>
            </Grid>
          )}
          {!this.hasAlerting() && this.renderMainContent()}
        </Suspense>
      </Page>
    );
  }
}
