import React, { Component, useContext } from 'react';
import { inject, MobXProviderContext, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { MdHelpOutline } from 'react-icons/md';
import classNames from 'classnames';
import moment from 'moment';

import { Box, Button, CalloutOutline, Flex, Heading, Icon, SmallCaps, Tag, Text, Tooltip } from 'core/components';
import AppliedFiltersButton from 'app/components/dataviews/tools/AppliedFiltersButton';
import { getQueryTimeInterval, secondsIntervalToText } from 'core/util/dateUtils';

import KentikAiBadge from 'core/components/KentikAiBadge';
import { FPA_FORM_DEFAULTS } from '@kentik/ui-shared/fpa/constants';
import LastUpdatedTag from './tools/LastUpdatedTag';

// alignment seems slightly off.
const viewTagIconStyle = {
  fontSize: 11,
  marginRight: 4,
  position: 'relative',
  top: -1
};

const ViewTag = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <Tag intent="primary" ml={1}>
      <Icon icon="star" style={viewTagIconStyle} />
      <strong>{text}</strong>
    </Tag>
  );
};

const DataSources = ({ dataview }) => {
  const { $devices } = useContext(MobXProviderContext);
  const {
    queryBuckets: { selectedQuery }
  } = dataview;

  if (!selectedQuery) {
    return null;
  }

  const { all_devices, device_name, device_labels, device_sites, device_types } = selectedQuery.get();
  const selected = !all_devices
    ? $devices.getUniqueSelectedDevices({ device_types, device_labels, device_sites, device_name })
    : $devices.activeDeviceSummaries;

  return (
    <Flex alignItems="center">
      <Icon icon="database" iconSize={12} color="muted" />
      <Text small muted ml="4px">
        {selected.length} of {$devices.activeDeviceSummaries.length} data sources
      </Text>
    </Flex>
  );
};

export const DateRange = ({ dataview }) => {
  const {
    queryBuckets: { selectedQuery }
  } = dataview;

  if (!selectedQuery) {
    return null;
  }

  const { lookback_seconds, starting_time, ending_time, time_format } = selectedQuery.get();
  const secondsInterval = getQueryTimeInterval({ lookback_seconds, starting_time, ending_time });
  const intervalText = secondsIntervalToText(secondsInterval, true);
  if (lookback_seconds > 0) {
    let interval = `Last ${intervalText}`;
    if (lookback_seconds === 1) {
      interval = 'This month';
    } else if (lookback_seconds === 2) {
      interval = 'Last month';
    } else if (lookback_seconds === 2592000) {
      interval = 'Last 30 Days';
    }

    return (
      <Text small muted>
        {interval}
      </Text>
    );
  }

  // query times are ALWAYS UTC, so be specific. Adjust to local format based on query time_format only.
  const isTimeZoneLocal = time_format === 'Local';
  const start = (isTimeZoneLocal ? moment.utc(starting_time).local() : moment.utc(starting_time)).format(
    'MMM DD, YYYY HH:mm'
  );
  const end = (isTimeZoneLocal ? moment.utc(ending_time).local() : moment.utc(ending_time)).format(
    'MMM DD, YYYY HH:mm'
  );

  return (
    <Text small muted>
      {start} to {end} ({intervalText})
    </Text>
  );
};

@inject('$explorer', '$auth', '$app')
@observer
class DataViewHeader extends Component {
  handleRunCpdFpa = () => {
    const { dataview } = this.props;
    const explorerQueryModel = dataview.createExplorerQueryModelFromSelectedQuery();

    const fpa = explorerQueryModel.get('fpa');

    dataview.applyToAllQueries({
      use_fpa: true,
      show_overlay: false,
      show_total_overlay: false,
      fpa: {
        ...FPA_FORM_DEFAULTS,
        ...fpa,
        type: 'cpd_fpa'
      }
    });
  };

  render() {
    const {
      $app,
      $auth,
      $explorer,
      category,
      dataview,
      isGenerated,
      isDashboardView,
      isSavedView,
      navigationHistory,
      sourceLink,
      showAppliedFilters,
      showDataSources,
      showDateRange,
      showTitle = true,
      showTitleLink,
      showViewTypeTag,
      showLastUpdated,
      showRunCpdFpaButton,
      showCategory,
      title: titleOverride,
      tools
    } = this.props;

    let title = titleOverride || dataview.title;
    let tagText = '';
    let description = '';
    if (sourceLink) {
      if (sourceLink.type === 'view') {
        tagText = 'Saved View';
        if (!isGenerated) {
          title = sourceLink.model.get('view_name');
        }
      } else if (sourceLink.type === 'dashboard') {
        tagText = 'View Panel';
      }

      if (sourceLink.model && !isGenerated) {
        description = sourceLink.model.panelDescription;
      }
    }

    const savedViewDescription = isSavedView && sourceLink.model.get('view_description');

    let titleTag;

    // I just don't know how else to fix this
    if (dataview.viewType === 'metrics') {
      title = sourceLink.model.get('panel_title');
    }

    if (!$app.isSharedLink && showTitleLink) {
      let link;
      if (dataview.viewType === 'syntheticsTest') {
        link = `/v4/synthetics/tests/${dataview.query.synth_test_id}/results`;
      } else if (dataview.viewType === 'metrics') {
        link = `/v4/nms/explorer/${dataview.hash}`;
      } else {
        const template_id = dataview.queryBuckets.selectedQuery.get('template_id');
        link = template_id ? `/library/${template_id}` : `/v4/core/explorer/${dataview.hash}`;
      }
      titleTag = <Link to={{ pathname: link, state: { navigationHistory } }}>{title}</Link>;
    } else {
      titleTag = <span>{title}</span>;
    }

    return (
      // padding-right and padding-top here prevent Button's box shadow from being chopped off
      <Flex alignItems="flex-start" flexWrap="wrap" flex="1 1 auto" pr="1px" pt="1px" mt="-4px">
        <Box
          flex={1}
          mr={2}
          mt="4px"
          className={classNames({ 'dataview-loading': !dataview.fullyLoaded }) /* used by node/puppeteerRunner */}
        >
          {showTitle && (
            <Heading level={isDashboardView ? 5 : 4} mb="2px" fontWeight={isDashboardView ? undefined : 'heavy'}>
              {showCategory && (
                <SmallCaps color="warning" fontSize={11}>
                  {category}
                </SmallCaps>
              )}
              {titleTag}
              {description && (
                <Tooltip content={<div style={{ maxWidth: 500 }}>{description}</div>}>
                  <Icon
                    icon={MdHelpOutline}
                    color="muted"
                    style={{ marginTop: 2, marginLeft: 4, verticalAlign: 'top', fontSize: 16, cursor: 'pointer' }}
                  />
                </Tooltip>
              )}
              {showViewTypeTag && <ViewTag text={tagText} sourceLink={sourceLink} />}
            </Heading>
          )}
          {showLastUpdated && dataview.lastUpdated && <LastUpdatedTag dataview={dataview} loading={dataview.loading} />}

          <Flex>
            {!$auth.isSharedUser && (
              <>
                {showDateRange && (
                  <Flex pr="12px" alignItems="center">
                    <DateRange dataview={dataview} />
                  </Flex>
                )}
                {showDataSources && (
                  <Flex alignItems="center" px="12px" borderLeft={showDateRange ? 'thin' : undefined}>
                    <DataSources dataview={dataview} />
                  </Flex>
                )}
                {showAppliedFilters &&
                  dataview.appliedFilters &&
                  dataview.appliedFilters.filterGroups &&
                  dataview.appliedFilters.filterGroups.length > 0 && (
                    <Flex
                      alignItems="center"
                      pl="4px"
                      borderLeft={showDateRange || showDataSources ? 'thin' : undefined}
                    >
                      <AppliedFiltersButton dataview={dataview} />
                    </Flex>
                  )}
                {$auth.hasPermission('fpa') && showRunCpdFpaButton && (
                  <Flex alignItems="center" pl="4px" borderLeft="thin">
                    <Tooltip content="Enable automatic detection to identify the most likely causes of traffic fluctuations">
                      <Button minimal onClick={this.handleRunCpdFpa} tracker="probable-cause-button" small>
                        <Flex alignItems="center" gap="6px">
                          <KentikAiBadge small />
                          <Text small color="muted">
                            Probable Cause Analysis
                          </Text>
                        </Flex>
                      </Button>
                    </Tooltip>
                  </Flex>
                )}
                {dataview.isComparisonWorkflow && dataview.timeSelections.length === 1 && (
                  <CalloutOutline intent="primary" fontSize="small" width="auto" px="12px" py="4px" lineHeight="auto">
                    <Flex alignItems="center" gap={1}>
                      <Icon icon="comparison" />
                      <Text small>
                        <Text fontWeight="bold">Compare Traffic:</Text> Select another time range to continue
                      </Text>
                      <Button onClick={() => dataview.toggleComparisonWorkflow()} small>
                        Cancel
                      </Button>
                    </Flex>
                  </CalloutOutline>
                )}
              </>
            )}
          </Flex>

          {savedViewDescription && (
            <Text muted small>
              {savedViewDescription}
            </Text>
          )}
        </Box>
        {$explorer.suppressed && (
          <Box flex={1} pl={2} pt="4px">
            <CalloutOutline intent="primary" p={1}>
              <Text as="div" mb="4px" small>
                Queries are not automatically executed when including or excluding rows. All subsequent changes made
                will not be applied until you confirm below.
              </Text>
              <Button intent="primary" text="Apply Filters" onClick={$explorer.applySuppressedChanges} mr={1} small />
              <Button text="Cancel" onClick={$explorer.cancelSuppressedChanges} small />
            </CalloutOutline>
          </Box>
        )}
        {tools}
      </Flex>
    );
  }
}

export default DataViewHeader;
