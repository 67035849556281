import moment from 'moment';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';

import { RemoveButton } from 'core/form';
import Page from 'app/components/page/Page';
import FavoriteButton from 'app/views/core/FavoriteButton';
import { Table, CELL_TYPES } from 'core/components/table';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import TopLevelBox from 'app/views/hybrid/maps/components/TopLevelBox';
import { Button, CalloutOutline, Box, Heading, Text, Flex, Icon, Suspense } from 'core/components';
import { ReactComponent as ConnectivityIcon } from 'app/assets/icons/cloud-connectivity-checker.svg';

import { convertToReadableId } from './utils';

const ICON_SIZE = 16;

@withRouter
@inject('$auth', '$clouds')
@observer
export default class ConnectivityChecker extends Component {
  // TODO - rename all "Connectivity Checker" components to reflect new ***marketing-approved*** name "Cloud Pathfinder"
  cloud = null;

  state = {
    isLoading: true
  };

  constructor(props) {
    super(props);

    const { match } = props;

    const cloud = match?.params?.cloud ?? 'aws';
    this.cloud = cloud;
  }

  componentDidMount() {
    const { $clouds } = this.props;

    this.setState({ isLoading: true });
    $clouds.connectivityReportCollection.fetchByCloud(this.cloud).then(() => this.setState({ isLoading: false }));
  }

  getConnectivityReportColumns = () => {
    const { $auth } = this.props;
    return [
      {
        label: 'Reachability',
        name: 'results.reachable',
        width: 150,
        renderer: ({ value }) => (
          <Flex alignItems="center">
            <Icon icon={value ? FiCheckCircle : FiXCircle} color={value ? 'success' : 'danger'} />
            <Text color={value ? 'success' : 'danger'} ml={1}>
              {value ? 'Reachable' : 'Not reachable'}
            </Text>
          </Flex>
        )
      },
      {
        label: 'Report Name',
        name: 'name'
      },
      {
        label: 'Source',
        name: 'src',
        renderer: ({ model, value }) => (
          <Flex alignItems="center" overflow="hidden" gap={1}>
            <CloudIcon
              cloudProvider={model.get('cloud')}
              entity={model.get('src_type')}
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
            <Text>{convertToReadableId(value, model.get('cloud'))}</Text>
          </Flex>
        )
      },
      {
        label: 'Destination',
        name: 'dst',
        renderer: ({ model, value }) => (
          <Flex alignItems="center" overflow="hidden" gap={1}>
            <CloudIcon
              cloudProvider={model.get('cloud')}
              entity={model.get('dst_type')}
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
            <Text>{convertToReadableId(value, model.get('cloud'))}</Text>
          </Flex>
        )
      },
      {
        label: 'Last Analysis Date',
        name: 'edate',
        width: 200,
        renderer: ({ value }) => <Text>{moment(value).format(DEFAULT_DATETIME_FORMAT)}</Text>
      },
      {
        label: 'Topology Date',
        name: 'end_time',
        width: 200,
        renderer: ({ value }) => {
          const nowDate = new Date();
          const endTimeDate = new Date(value);
          if (endTimeDate >= nowDate) {
            return <Text>Latest</Text>;
          }
          return <Text>{moment(value).format(DEFAULT_DATETIME_FORMAT)}</Text>;
        }
      },
      {
        type: CELL_TYPES.ACTION,
        width: 200,
        actions: [
          (model) => (
            <Flex key={model.id} gap="10px">
              <Button text="View Report" onClick={() => this.onViewReportClick(model)} />
              {$auth.isAdministrator && (
                <RemoveButton
                  onRemove={() => model.destroy()}
                  popoverProps={model.removalConfirmPopoverProps}
                  key="remove"
                  entityName="Pathfinder Report"
                  model={model}
                  small
                  showIcon
                  hideText
                />
              )}
            </Flex>
          )
        ]
      }
    ];
  };

  onViewReportClick = (model) => {
    const { history } = this.props;
    history.push(`/v4/cloud/pathfinder/${this.cloud}/${model.id}`);
  };

  render() {
    const { $clouds, history, match } = this.props;
    const { isLoading } = this.state;
    const title = `${this.cloud === 'aws' ? 'AWS' : 'Azure'} Pathfinder Reports`;
    return (
      <Page gray scrolls title={title} parentLinks={[{ link: '/v4/cloud/pathfinder', label: 'Cloud Pathfinder' }]}>
        <Flex alignItems="center" gap="4px" mb={1}>
          <FavoriteButton type="page" id={match.url} name="Cloud Pathfinder" />
          <Heading level={1} mr={2} mb={0}>
            {title}
          </Heading>
        </Flex>
        <Suspense loading={isLoading}>
          <CalloutOutline intent="primary" p={2} mb={2}>
            <Flex alignItems="flex-start">
              <Icon iconSize={64} icon={ConnectivityIcon} mr={2} mt="1px" />
              <Box>
                <Text as="div" fontWeight="heavy" mb={1}>
                  Start troubleshooting connectivity issues.
                </Text>

                {this.cloud === 'aws' && (
                  <Text as="div" mb={1}>
                    Monitor and troubleshoot cloud issues. See and drill into any level of detail about traffic to,
                    from, in, and between cloud providers, regions, availability zones, VPCs, subnets, and instances.
                    See traffic breakdown by application, IP address, business attributes, and more. Be alerted to cloud
                    performance issues from synthetic tests.
                  </Text>
                )}

                {this.cloud === 'azure' && (
                  <Text as="div" mb={1}>
                    Monitor and troubleshoot cloud issues. See and drill into any level of detail about traffic to,
                    from, in, and between subnets. <br />
                    See traffic breakdown by application, IP address, business attributes, and more. Be alerted to cloud
                    performance issues from synthetic tests.
                  </Text>
                )}

                <Text as="div" mb={1}>
                  Find out why traffic is getting dropped in your {this.cloud} network. Click <b>Create Report</b> to
                  analyze the path from any source or destination.
                </Text>
                <Button
                  text="Create Report"
                  small
                  intent="primary"
                  onClick={() => history.push(`/v4/cloud/pathfinder/${this.cloud}/create`)}
                />
              </Box>
            </Flex>
          </CalloutOutline>

          {$clouds.connectivityReportCollection.size > 0 && (
            <TopLevelBox
              title="Previous Reports"
              isExpanded
              expandedContent={
                <Table
                  collection={$clouds.connectivityReportCollection}
                  columns={this.getConnectivityReportColumns()}
                />
              }
            />
          )}
        </Suspense>
      </Page>
    );
  }
}
