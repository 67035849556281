import { ReactComponent as DoneIcon } from 'app/assets/icons/progress-status/done.svg';
import { ReactComponent as ErrorIcon } from 'app/assets/icons/progress-status/error.svg';
import { ReactComponent as DisabledIcon } from 'app/assets/icons/progress-status/disabled.svg';
import { ReactComponent as IncompleteIcon } from 'app/assets/icons/progress-status/incomplete.svg';
import { ReactComponent as InProgressIcon } from 'app/assets/icons/progress-status/in-progress.svg';
import { ReactComponent as DoneOptionalIcon } from 'app/assets/icons/progress-status/done-optional.svg';

export const PROGRESS_STATES = {
  INCOMPLETE: 'incomplete',
  IN_PROGRESS: 'in-progress',
  DONE: 'done',
  DONE_OPTIONAL: 'done-optional',
  DISABLED: 'disabled',
  ERROR: 'error'
};

export const PROGRESS_ICONS = {
  [PROGRESS_STATES.INCOMPLETE]: IncompleteIcon,
  [PROGRESS_STATES.IN_PROGRESS]: InProgressIcon,
  [PROGRESS_STATES.DONE]: DoneIcon,
  [PROGRESS_STATES.DONE_OPTIONAL]: DoneOptionalIcon,
  [PROGRESS_STATES.DISABLED]: DisabledIcon,
  [PROGRESS_STATES.ERROR]: ErrorIcon
};

export const getProgressIcon = (state) => PROGRESS_ICONS?.[state] || IncompleteIcon;
