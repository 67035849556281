import React from 'react';
import { inject, observer } from 'mobx-react';
import { getASNValues } from 'app/util/queryResults';
import { Box, EmptyState, Flex, Grid, Spinner, Text } from 'core/components';
import { showErrorToast, showInfoToast } from 'core/components/toast';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { addFilters } from 'app/stores/query/FilterUtils';
import AbstractMap from '../components/AbstractMap';
import TopLevelBox from '../components/TopLevelBox';
import withPopover from '../components/popovers/withPopover';
import KubeBoxLinkGenerator from './KubeBoxLinkGenerator';
import TopKeys from '../components/TopKeys/TopKeys';
import TopServicesIPs from '../components/TopKeys/TopServicesIPs';
import TrafficLinkGenerator from '../components/TrafficLinkGenerator';
import {
  getInfrastructureDimension,
  getInfrastructureFilterGroup,
  getInternetDimension,
  getInternetFilterField,
  getKubeFilterGroup
} from '../components/popovers/queryOptionsHelper';
import { getHealthClass } from '../../utils/health';
import ResourceItem from './ResourceItem';
import ParentItem from './ParentItem';
import { BASE_ROUTE } from '../../kube';
import RelatedClusters from './RelatedClusters';

const resourceTypes = ['services', 'ingresses', 'deployments'];
const srcObjectNameDimension = 'ktsubtype__kappa__STR08';
const dstObjectNameDimension = 'ktsubtype__kappa__STR14';
const srcObjectTypeDimension = 'ktsubtype__kappa__STR10';
const dstObjectTypeDimension = 'ktsubtype__kappa__STR16';
const srcWorkloadDimension = 'ktsubtype__kappa__STR12';
const dstWorkloadDimension = 'ktsubtype__kappa__STR18';

@withPopover
@inject('$explorer', '$hybridMap', '$query')
@observer
export default class NamespaceDetailsMap extends AbstractMap {
  constructor(props) {
    super(props);

    if (process.env.NODE_ENV !== 'production') {
      window.NamespaceDetailsMap = this;
    }

    Object.assign(this.state, {
      loading: true,
      activeInfrastructureTabId: 'services',
      relatedClusters: [],
      boxExpanded: {
        infrastructure: true,
        internet: true
      }
    });

    this.getQueryResultKey = this.getQueryResultKey.bind(this);
  }

  componentDidMount() {
    this.fetchTopology();
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarSettings, drawerIsOpen } = this.props;
    const { activeNode } = this.state;
    const mapSearchChanged = prevProps.sidebarSettings.searchTerm !== sidebarSettings.searchTerm;
    const timeSettingsChanged =
      prevProps.sidebarSettings.timeRange.start !== sidebarSettings.timeRange.start ||
      prevProps.sidebarSettings.timeRange.end !== sidebarSettings.timeRange.end;
    const drawerChanged = prevProps.drawerIsOpen !== drawerIsOpen;
    const activeNodeChanged = prevProps.activeNode !== activeNode;

    if (mapSearchChanged) {
      this.topologyCollection.filter(sidebarSettings.searchTerm);
    }

    if (timeSettingsChanged) {
      this.fetchTopology();
      return;
    }

    if (drawerChanged) {
      // this.getRegionBoxPositions.cache.clear();
    }

    if (activeNodeChanged) {
      if (!activeNode) {
        // this.topologyCollection.resetHighlightedNodes();
      }

      // this.calculateStaticLinks.cache.clear();
    }
    super.componentDidUpdate(prevProps, prevState);
  }

  async fetchTopology() {
    const { sidebarSettings, $hybridMap } = this.props;
    const { topologyCollection } = this;
    this.setState({ loading: true });

    if (this.pendingPromise) {
      this.pendingPromise.cancel();
    }

    this.pendingPromise = makeCancelable(topologyCollection.fetch({ force: true, query: sidebarSettings.timeRange }));

    return this.pendingPromise.promise
      .then(() => Promise.all([this.getLatency(), this.getRelatedClusters()]))
      .then(([latencies, relatedClusters]) => {
        const { cluster, namespace } = this;

        if (latencies.cluster) {
          const latency = latencies.cluster;
          const healthData = { state: latency > $hybridMap.latencyThreshold ? 'CRITICAL' : 'GOOD' };
          Object.assign(cluster, { latency, health: { cssClassName: getHealthClass(healthData), data: healthData } });
        }

        if (latencies.namespace) {
          const latency = latencies.namespace;
          const healthData = { state: latency > $hybridMap.latencyThreshold ? 'CRITICAL' : 'GOOD' };
          Object.assign(namespace, { latency, health: { cssClassName: getHealthClass(healthData), data: healthData } });
        }

        this.topologyCollection.getEntities(resourceTypes).forEach((resource) => {
          if (latencies.resources.has(resource.id)) {
            const latency = latencies.resources.get(resource.id);
            const healthData = { state: latency > $hybridMap.latencyThreshold ? 'CRITICAL' : 'GOOD' };
            Object.assign(resource, {
              latency,
              health: { cssClassName: getHealthClass(healthData), data: healthData }
            });
          }
        });

        this.setState({ loading: false, relatedClusters });
      })
      .catch((err) => {
        this.setState({ loading: false }, () => {
          if (!(err instanceof CanceledError)) {
            const { deviceName } = this;
            showErrorToast(`Unable to load traffic data, device ${deviceName} not found.`);
          } else {
            console.warn('Promise was canceled.');
          }
        });
      })
      .finally(() => {
        this.pendingPromise = null;
        this.setState({ loading: false });
      });
  }

  /**
   * Get latency for each resource
   * @returns {Promise}
   */
  async getLatency() {
    const { namespaceName, $query } = this.props;
    const { deviceName } = this;

    const latencyMetric = 'p98th_ktsubtype__kappa__APPL_LATENCY_MS';

    const aggregateTypes = [latencyMetric];
    const latencies = { cluster: 0, namespace: 0, resources: new Map() };

    return Promise.all([
      $query.runQuery(this.getQuery({ aggregateTypes }, deviceName), true, true).then(({ results }) => {
        if (results.size > 0) {
          latencies.cluster = results.models[0].get(latencyMetric);
        }
      }),
      $query.runQuery(this.getQuery({ aggregateTypes }, deviceName, namespaceName), true, true).then(({ results }) => {
        if (results.size > 0) {
          latencies.namespace = results.models[0].get(latencyMetric);
        }
      }),
      $query
        .runQuery(
          this.getQuery(
            { aggregateTypes, metric: [srcObjectNameDimension, srcObjectTypeDimension, srcWorkloadDimension] },
            deviceName,
            namespaceName
          ),
          true,
          true
        )
        .then(({ results }) => {
          results.each((row) => {
            const name = row.get('kt_k8s_src_pod_name');
            const type = row.get(srcObjectTypeDimension);
            const workload = row.get('kt_k8s_src_load_name');
            const latency = row.get(latencyMetric);
            const key = this.getResourceId({ objectName: name, objectType: type, workload });

            if (key) {
              latencies.resources.set(
                key,
                Math.max(latency, latencies.resources.has(key) ? latencies.resources.get(key) : 0)
              );
            }
          });
        })
    ]).then(() => latencies);
  }

  async getRelatedClusters() {
    const { clusterId, namespaceName, $query } = this.props;
    const { deviceName } = this;

    return $query
      .runQuery(this.getQuery({ metric: ['IP_src', 'IP_dst'] }, deviceName, namespaceName), true, true)
      .then(({ results }) => {
        const clusterNames = new Set();

        results.each((result) => {
          this.getQueryResultKey(result)
            .split(' ---- ')
            .forEach((c) => clusterNames.add(c));
        });

        [clusterId, '---'].forEach((ignoreName) => clusterNames.delete(ignoreName));

        return Array.from(clusterNames);
      });
  }

  shouldTargetLinkRollUp(type) {
    return type !== 'clusters' && type !== 'resources';
  }

  getResourceId({ objectName, objectType, workload }) {
    const { clusterId } = this.props;

    if (objectType === 'pod') {
      // temporarily infer workload name from pod name
      if (workload === '---') {
        workload = objectName.match(/^(.+?)-(\w+-)?\w+$/)?.[1] || '---';
      }

      return workload !== '---' ? `${clusterId}-deployments-${workload}` : '---';
    }

    // TODO ingresses
    return objectName !== '---' ? `${clusterId}-services-${objectName}` : '---';
  }

  getClusterFromIp(cidr) {
    const { topologyCollection } = this;
    const ip = cidr.replace(/\/\d+$/, '');
    return topologyCollection
      .get()
      .find((model) => model.get('entityType') === 'clusters' && model.searchableData.cidrs.includes(ip))?.id;
  }

  getQueryResultKey(result) {
    const { clusterId } = this.props;
    let key = result.get('lookup') || result.get('key');

    // replace ip -> cluster id
    const srcIp = result.get('inet_src_addr');
    const dstIp = result.get('inet_dst_addr');

    if (srcIp) {
      const srcCluster = this.getClusterFromIp(srcIp) || '---';
      key = key.replace(srcIp, srcCluster);
    }

    if (dstIp) {
      const dstCluster = this.getClusterFromIp(dstIp) || '---';
      key = key.replace(dstIp, dstCluster);
    }

    // replace namespace name -> namespace id
    const srcNamespace = result.get('kt_k8s_src_pod_ns');
    const dstNamespace = result.get('kt_k8s_dst_pod_ns');

    if (srcNamespace && srcNamespace !== '---') {
      key = key.replace(srcNamespace, `${clusterId}-${srcNamespace}`);
    }

    if (dstNamespace && dstNamespace !== '---') {
      key = key.replace(dstNamespace, `${clusterId}-${dstNamespace}`);
    }

    // replace object name + object type + workload name -> resource id
    const srcObjectName = result.get('kt_k8s_src_pod_name');
    const dstObjectName = result.get('kt_k8s_dst_pod_name');
    const srcObjectType = result.get(srcObjectTypeDimension);
    const dstObjectType = result.get(dstObjectTypeDimension);
    const srcWorkload = result.get('kt_k8s_src_load_name');
    const dstWorkload = result.get('kt_k8s_dst_load_name');

    if (srcObjectName) {
      const srcResource = this.getResourceId({
        objectName: srcObjectName,
        objectType: srcObjectType,
        workload: srcWorkload
      });
      key = key.replace(`${srcObjectName} ---- ${srcObjectType} ---- ${srcWorkload}`, srcResource);
    }

    if (dstObjectName) {
      const dstResource = this.getResourceId({
        objectName: dstObjectName,
        objectType: dstObjectType,
        workload: dstWorkload
      });
      key = key.replace(`${dstObjectName} ---- ${dstObjectType} ---- ${dstWorkload}`, dstResource);
    }

    return key;
  }

  getNodeLinkQueries({ selectedNode, activeInternetTabId, activeInfrastructureTabId } = this.state) {
    const { relatedClusters } = this.state;
    const queries = [];

    if (selectedNode) {
      const { namespaceName } = this.props;
      const { deviceName } = this;
      const { type: source, subType, value } = selectedNode;
      const entity = this.topologyCollection.getEntity({ entityType: subType || source, entityId: value });

      // kube <-> kube
      if (entity && source === 'resources') {
        const filters = { connector: 'All', filterGroups: [] };

        filters.filterGroups.push(getKubeFilterGroup({ entity }));

        queries.push({
          type: 'resources',
          getKey: this.getQueryResultKey,
          keyTypes: ['resources', 'resources'],
          selectedNode,
          queries: [
            this.getQuery(
              {
                metric: [
                  srcObjectNameDimension,
                  srcObjectTypeDimension,
                  srcWorkloadDimension,
                  dstObjectNameDimension,
                  dstObjectTypeDimension,
                  dstWorkloadDimension
                ],
                filters
              },
              deviceName,
              namespaceName
            )
          ]
        });

        if (relatedClusters.length > 0) {
          queries.push({
            type: 'resourceClusters',
            getKey: this.getQueryResultKey,
            keyTypes: ['src-clusters', 'src-resources', 'dst-resources', 'dst-clusters'],
            allowPairs: [
              ['src-clusters', 'dst-resources'],
              ['src-resources', 'dst-clusters']
            ],
            selectedNode,
            queries: [
              this.getQuery(
                {
                  metric: [
                    'IP_src',
                    srcObjectNameDimension,
                    srcObjectTypeDimension,
                    srcWorkloadDimension,
                    dstObjectNameDimension,
                    dstObjectTypeDimension,
                    dstWorkloadDimension,
                    'IP_dst'
                  ],
                  filters
                },
                deviceName,
                namespaceName
              )
            ]
          });
        }
      }

      // kube <-> internet
      if ((entity && source === 'resources') || source === 'internet') {
        const srcInternetDimension = getInternetDimension({ subType: activeInternetTabId, direction: 'src' });
        const dstInternetDimension = getInternetDimension({ subType: activeInternetTabId, direction: 'dst' });
        const filters = { connector: 'All', filterGroups: [] };

        if (source === 'resources') {
          filters.filterGroups.push(getKubeFilterGroup({ entity }));
        }

        if (source === 'internet') {
          filters.filterGroups.push({
            filters: [
              {
                filterField: getInternetFilterField({ subType: activeInternetTabId }),
                operator: '=',
                filterValue: getASNValues(value).asnList
              }
            ]
          });
        }

        filters.filterGroups.push(getKubeFilterGroup({ internet: true }));

        queries.push({
          type: 'resourcesInternet',
          getKey: this.getQueryResultKey,
          keyTypes: ['src-internet', 'src-resources', 'dst-resources', 'dst-internet'],
          allowPairs: [
            ['src-internet', 'dst-resources'],
            ['src-resources', 'dst-internet']
          ],
          selectedNode,
          queries: [
            this.getQuery(
              {
                metric: [
                  srcInternetDimension,
                  srcObjectNameDimension,
                  srcObjectTypeDimension,
                  srcWorkloadDimension,
                  dstObjectNameDimension,
                  dstObjectTypeDimension,
                  dstWorkloadDimension,
                  dstInternetDimension
                ],
                filters
              },
              deviceName,
              namespaceName
            )
          ]
        });

        if (source === 'internet' && relatedClusters.length > 0) {
          queries.push({
            type: 'clustersInternet',
            getKey: this.getQueryResultKey,
            keyTypes: ['src-clusters', 'src-internet', 'dst-internet', 'dst-clusters'],
            allowPairs: [
              ['src-clusters', 'dst-internet'],
              ['src-internet', 'dst-clusters']
            ],
            selectedNode,
            queries: [
              this.getQuery(
                {
                  metric: ['IP_src', srcInternetDimension, dstInternetDimension, 'IP_dst'],
                  filters
                },
                deviceName
              )
            ]
          });
        }
      }

      // kube <-> infrastructure
      if ((entity && source === 'resources') || source === 'infrastructure') {
        const srcInfrastructureDimension = getInfrastructureDimension({
          subType: activeInfrastructureTabId,
          direction: 'src'
        });
        const dstInfrastructureDimension = getInfrastructureDimension({
          subType: activeInfrastructureTabId,
          direction: 'dst'
        });
        const filters = { connector: 'All', filterGroups: [] };

        if (source === 'resources') {
          filters.filterGroups.push(getKubeFilterGroup({ entity }));
        }

        if (source === 'infrastructure') {
          filters.filterGroups.push(getInfrastructureFilterGroup({ subType: activeInfrastructureTabId, value }));
        }

        filters.filterGroups.push(getKubeFilterGroup({ infrastructure: true }));

        queries.push({
          type: 'resourcesInfrastructure',
          getKey: this.getQueryResultKey,
          keyTypes: ['src-infrastructure', 'src-clusters', 'dst-clusters', 'dst-infrastructure'],
          allowPairs: [
            ['src-infrastructure', 'dst-clusters'],
            ['src-clusters', 'dst-infrastructure']
          ],
          selectedNode,
          queries: [
            this.getQuery(
              {
                metric: [srcInfrastructureDimension, 'IP_src', 'IP_dst', dstInfrastructureDimension],
                filters
              },
              deviceName,
              namespaceName
            )
          ]
        });

        if (source === 'infrastructure' && relatedClusters.length > 0) {
          queries.push({
            type: 'clustersInfrastructure',
            getKey: this.getQueryResultKey,
            keyTypes: ['src-clusters', 'src-infrastructure', 'dst-infrastructure', 'dst-clusters'],
            allowPairs: [
              ['src-clusters', 'dst-infrastructure'],
              ['src-infrastructure', 'dst-clusters']
            ],
            selectedNode,
            queries: [
              this.getQuery(
                {
                  metrics: ['IP_src', srcInfrastructureDimension, dstInfrastructureDimension, 'IP_dst'],
                  filters
                },
                deviceName
              )
            ]
          });
        }
      }
    }

    return queries;
  }

  /**
   * @param {Object} query
   * @param {string?} device_name
   * @param {string?} namespaceName
   * @returns {Object}
   */
  getQuery(query, device_name = null, namespaceName = null) {
    const { $hybridMap } = this.props;

    if (namespaceName) {
      addFilters(query, [
        {
          filterField: 'ktsubtype__kappa__STR09src|dstktsubtype__kappa__STR15',
          operator: '=',
          filterValue: namespaceName
        }
      ]);
    }

    return $hybridMap.getQuery({
      all_devices: false,
      device_types: device_name ? [] : ['kappa'],
      device_name: device_name ? [device_name] : [],
      hostname_lookup: false,
      show_overlay: false,
      show_total_overlay: false,
      aggregateTypes: ['avg_bits_per_sec'],
      viz_type: 'table',
      depth: 5000,
      topx: 5000,
      ...query
    });
  }

  /**
   * @returns {import('app/stores/hybrid/KubeCloudMapCollection').default}
   */
  get topologyCollection() {
    const { $hybridMap } = this.props;
    return $hybridMap.kubeCloudMapCollection;
  }

  get cluster() {
    const { clusterId } = this.props;
    return this.topologyCollection.topology.Entities.clusters?.[clusterId];
  }

  get deviceName() {
    return this.cluster?.device_name || 'unknown';
  }

  get cloudProvider() {
    return this.cluster?.cloudProvider || 'unknown';
  }

  get namespace() {
    const { namespaceId } = this.props;
    return this.topologyCollection.topology.Entities.namespaces?.[namespaceId];
  }

  get relatedClusters() {
    const { relatedClusters } = this.state;
    const { topology } = this.topologyCollection;
    return relatedClusters.map((id) => topology.Entities.clusters?.[id] || { id, name: id });
  }

  handleBoxLinksUpdate = (boxLinks) => {
    const links = this.cleanBoxLinkTraffic(boxLinks);
    this.setState({ boxLinks: links });
  };

  /**
   * @override
   * @borrowed from CloudAwsMap
   */
  handleNodeLinksUpdate(links) {
    const { clusterId } = this.props;
    const { selectedNode } = this.state;
    const processedLinks = links
      .flatMap((l) => l.links)
      .filter((link) => !(link.target.type === 'clusters' && link.target.value === clusterId));

    if (processedLinks.length === 0 && selectedNode) {
      showInfoToast('No connections were found.');
    }

    const nodeLinks = [...this.getNodeLinks(), ...processedLinks];

    this.setState({
      nodeLinks,
      nodeLinksLoading: false
    });
  }

  handleInfrastructureTabChange = (tabId) => {
    const { selectedNode } = this.state;

    this.setState({ activeInfrastructureTabId: tabId });

    if (selectedNode?.type === 'infrastructure') {
      this.handleSelectNode({ value: null });
    }
  };

  handleSelectNode({ type, subType, value, nodeData, event }) {
    const { namespaceName, isEmbedded, openPopover } = this.props;
    const { selectedNode } = this.state;
    const { topologyCollection, deviceName } = this;
    const { nodes, pods } = topologyCollection.topology.Entities;

    if (event) {
      event.stopPropagation();
    }

    super.handleSelectNode({ type, subType, value, nodeData });

    this.setState((prevState) => {
      if (prevState.selectedNode) {
        const isSameNode = isEqual(selectedNode, prevState.selectedNode);
        const { node } = this.getNodePosition(prevState.selectedNode);

        if (node) {
          const { x, y, width, height } = node;

          const config = {
            ...prevState.selectedNode,
            deviceName,
            cloudProvider: this.cloudProvider,
            isKubeDetail: true,
            namespace: namespaceName,
            isEmbedded,
            showHealth: nodeData?.health,
            showTitle: nodeData?.name,
            position: { left: x, top: y, width, height },
            pods: type === 'resources',
            entities: { nodes, pods },
            shortcutMenu: {
              selectedNode: prevState.selectedNode,
              showConnectionsCallback: this.setNodeLinks,
              isShowingConnections: prevState.nodeLinks.length > 0 && isSameNode
            }
          };

          openPopover(config);
        }
      }

      return null;
    });
  }

  handleNodeLinkClick = (config) => {
    const { setSidebarDetails } = this.props;
    const { activeInternetTabId, activeInfrastructureTabId } = this.state;
    const { deviceName, topologyCollection } = this;
    const { source, target } = config;

    if (source.type === 'internet') {
      source.subType = activeInternetTabId;
    } else if (source.type === 'infrastructure') {
      source.subType = activeInfrastructureTabId;
    }

    if (target.type === 'internet') {
      target.subType = activeInternetTabId;
    } else if (target.type === 'infrastructure') {
      target.subType = activeInfrastructureTabId;
    }

    setSidebarDetails({
      ...config,
      deviceName,
      source: {
        ...source,
        nodeData: topologyCollection.getEntity({ entityType: source.type, entityId: source.value })
      },
      target: {
        ...target,
        nodeData: topologyCollection.getEntity({ entityType: target.type, entityId: target.value })
      }
    });
  };

  renderMap() {
    const { $hybridMap } = this.props;
    const { loading, boxExpanded, activeInfrastructureTabId, activeInternetTabId, nodeLinkQueries, nodeLinksLoading } =
      this.state;
    const topBoxHeight = 233;

    if (loading) {
      return <Spinner mt={100} />;
    }

    const { topologyCollection, cluster, deviceName, namespace, relatedClusters } = this;
    const resources = resourceTypes.flatMap((type) =>
      topologyCollection
        .getEntities(type)
        .filter((resource) => resource.clusterId === cluster.id && resource.metadata.namespace === namespace.name)
    );

    if (resources.length === 0) {
      return <EmptyState mt={4} icon="cloud" title="No resources in this namespace found" />;
    }

    const selectedResource = this.getSelectedItem('resources', resources);
    const highlightedResources = this.getHighlightedItems('resources', resources);

    return (
      <Box>
        <Grid
          gridTemplateColumns="1fr auto 1fr"
          gridTemplateAreas="'infrastructure parents internet' '. spinner .' 'kube kube kube'"
          gridGap={5}
          gridRowGap={3}
        >
          <Box gridArea="infrastructure">
            <TopLevelBox
              title="Other Infrastructure"
              boxProps={{
                className: classNames('box-infrastructure', 'link-bottomright', {
                  highlighted: this.isBoxHighlighted('infrastructure')
                }),
                height: topBoxHeight,
                overflow: 'hidden'
              }}
              onExpandToggle={(isExpanded) => this.setBoxExpanded('infrastructure', isExpanded)}
              expandedContent={
                <TopServicesIPs
                  classPrefix="infrastructure"
                  selected={this.getSelected('infrastructure')}
                  highlighted={this.getHighlighted('infrastructure')}
                  onSelect={(value, { event }) =>
                    this.handleSelectNode({ type: 'infrastructure', subType: activeInfrastructureTabId, value, event })
                  }
                  onTabChange={this.handleInfrastructureTabChange}
                  device={deviceName}
                  namespace={namespace.name}
                />
              }
              isExpanded={boxExpanded.infrastructure}
              keepChildrenMounted
            />
          </Box>

          <Box gridArea="parents" alignSelf="center" pt={27} overflow="hidden">
            <ParentItem item={cluster} type="clusters" to={BASE_ROUTE} />
            <Box width={0} height={50} borderLeft="thinLighter" mx="auto" />
            <ParentItem item={namespace} type="namespaces" to={`${BASE_ROUTE}/cluster/${cluster.id}`} />
          </Box>

          <Box gridArea="internet">
            <TopLevelBox
              title="Internet"
              boxProps={{
                className: classNames('box-internet', 'link-bottomleft', {
                  highlighted: this.isBoxHighlighted('internet')
                }),
                height: topBoxHeight,
                overflow: 'hidden'
              }}
              onExpandToggle={(isExpanded) => this.setBoxExpanded('internet', isExpanded)}
              expandedContent={
                <TopKeys
                  classPrefix="internet"
                  selected={this.getSelected('internet')}
                  hovered={this.getHovered('internet')}
                  highlighted={this.getHighlighted('internet')}
                  onSelect={(internet, { event }) =>
                    this.handleSelectNode({
                      type: 'internet',
                      subType: activeInternetTabId,
                      value: internet,
                      nodeData: { subType: 'internet-k8' },
                      event
                    })
                  }
                  onTabChange={this.handleInternetTabChange}
                  showProvidersTab={false}
                  showNextHopNetworksTab={false}
                  isKube
                  device={deviceName}
                  namespace={namespace.name}
                />
              }
              isExpanded={boxExpanded.internet}
              keepChildrenMounted
            />
          </Box>

          <Box gridArea="spinner" justifySelf="center" width={50} height={50}>
            {nodeLinksLoading && <Spinner />}
          </Box>

          <Box gridArea="kube">
            <TopLevelBox
              isExpanded
              boxProps={{ p: 0, className: 'box-kube' }}
              expandedContent={
                <Flex gap={2} alignItems="flex-start" py={4} px={2} width="100%">
                  <RelatedClusters
                    clusters={relatedClusters}
                    handleOnClick={(selectedNode) => this.handleSelectNode(selectedNode)}
                    highlighted={this.getHighlighted('clusters')}
                  />
                  <Flex gap="80px 4px" flex="1 auto" flexWrap="wrap" alignItems="center" justifyContent="center" py={1}>
                    {resources.map((resource) => {
                      const isSelected = selectedResource === resource;
                      const isHighlighted = !isSelected && highlightedResources.includes(resource);
                      const isUnselected =
                        (selectedResource || highlightedResources.length > 0) && !isSelected && !isHighlighted;

                      return (
                        <Box key={`${resource.type}-${resource.id}`} width={130} textAlign="center">
                          <Text
                            as="div"
                            opacity={isUnselected ? 0.3 : 1}
                            small
                            ellipsis
                            title={resource.name}
                            fontSize="13px"
                            fontWeight={500}
                          >
                            {resource.name}
                          </Text>
                          <svg display="block" width={54} height={54} style={{ margin: '0 auto' }}>
                            <g transform="translate(27, 27)">
                              <ResourceItem
                                id={resource.id}
                                label={resource.name}
                                type="resources"
                                subType={resource.type}
                                className={classNames(
                                  'node',
                                  'hybrid-map-selectable-node',
                                  resource.health?.cssClassName,
                                  {
                                    'node-selected': isSelected,
                                    'node-highlighted': isHighlighted,
                                    'node-unselected': isUnselected
                                  }
                                )}
                                onClick={() =>
                                  this.handleSelectNode({
                                    type: 'resources',
                                    subType: resource.type,
                                    value: resource.id,
                                    nodeData: resource
                                  })
                                }
                              />
                            </g>
                          </svg>
                        </Box>
                      );
                    })}
                  </Flex>
                </Flex>
              }
            />
          </Box>
        </Grid>

        <KubeBoxLinkGenerator
          debug
          onBoxLinksUpdate={this.handleBoxLinksUpdate}
          deviceName={deviceName}
          namespace={namespace.name}
          kubeLabel={namespace.name}
          {...$hybridMap.kubeStateAlt}
        />
        <TrafficLinkGenerator inputs={nodeLinkQueries} onLinksUpdate={this.handleNodeLinksUpdate} />
      </Box>
    );
  }
}
