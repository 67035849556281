import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Flex, Tag, Text } from 'core/components';
import { Search } from 'core/components/table';
import SelectServices from './selectServices/SelectServices';
import TestCoverage from './TestCoverage';

@observer
export default class CloudServiceToolbar extends React.Component {
  componentDidUpdate(prevProps) {
    const { selectedServices } = this.props;

    if (prevProps.selectedServices !== selectedServices) {
      this.applyServiceFilter(selectedServices);
    }
  }

  handleSearch = (e) => {
    const { collection } = this.props;

    collection.filter(e.target.value, { customFilter: this.searchFilterMethod });
  };

  searchFilterMethod = (model) => {
    const { collection } = this.props;

    // additional keys to search through Status Code and Avg HTTP Latency in CloudServiceTable
    const additionalKeysForFiltering = [
      'service',
      'serviceTestResults.http_status.value',
      'serviceTestResults.http_latency.value'
    ];

    const modelFilterValues = [
      ...collection.getFilterValues(model),
      ...additionalKeysForFiltering.map((key) => model.get(key)).filter((value) => value !== undefined)
    ];

    return modelFilterValues.some((value) => `${value}`.toLowerCase().includes(collection.filterState.toLowerCase()));
  };

  applyServiceFilter = (selectedServices) => {
    const { collection } = this.props;

    collection.removeDiscreteFilter('service');

    if (selectedServices.length > 0) {
      collection.setDiscreteFilters([
        ...collection.discreteFilters,
        {
          type: 'service',
          fn: (model) => selectedServices.includes(model.get('service'))
        }
      ]);
    }

    collection.filter();
  };

  handleServiceSelectionChange = (selectedServices) => {
    const { onServiceSelectionChange } = this.props;

    if (onServiceSelectionChange) {
      onServiceSelectionChange(selectedServices);
    }
  };

  handleTestsUpdate = () => {
    const { collection, onTestsUpdate } = this.props;

    if (onTestsUpdate) {
      onTestsUpdate(collection.missingTests);
    }
  };

  render() {
    const {
      collection,
      selectedServices,
      onServiceSelectionChange,
      onActionClick,
      onDeployClick,
      onTestsUpdate,
      loading,
      disabled,
      ...boxProps
    } = this.props;
    const { missingTests } = collection;
    const canUpdateTests = missingTests.length > 0;

    return (
      <Flex alignItems="flex-start" justifyContent="space-between" {...boxProps}>
        <Box showSkeleton={loading}>
          <Search
            placeholder="Search by Name, Metric, or Status"
            collection={collection}
            onChange={this.handleSearch}
            inputProps={{ value: collection.filterState, width: 275, disabled }}
          />
        </Box>

        <Flex showSkeleton={loading}>
          <Button mr={1} icon="automatic-updates" onClick={this.handleTestsUpdate} disabled={!canUpdateTests} small>
            <Text small>Update Tests</Text>
            {canUpdateTests && (
              <Tag ml={1} intent="primary" round>
                {missingTests.length}
              </Tag>
            )}
          </Button>

          <TestCoverage mr={1} collection={collection} />

          <SelectServices
            collection={collection}
            selectedServices={selectedServices}
            onServiceSelectionChange={this.handleServiceSelectionChange}
            disabled={disabled}
          />
        </Flex>
      </Flex>
    );
  }
}
