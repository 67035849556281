import React from 'react';
import { PopoverInteractionKind } from '@blueprintjs/core';
import { Popover, Tag } from 'core/components';
import { SearchSuggestions } from './SearchSuggestions';

export function SearchSuggestionsPopover() {
  return (
    <Popover
      position="bottom"
      interactionKind={PopoverInteractionKind.HOVER}
      minimal={false}
      usePortal
      target={
        <Tag round minimal>
          ?
        </Tag>
      }
      content={<SearchSuggestions alignItems="flex-start" bg="rgba(0, 0, 0, 0.55)" p={2} minimal />}
    />
  );
}
