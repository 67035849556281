import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Flex, Select } from 'core/components';
import { InputGroup } from 'core/form';

const groupingOptions = [
  { label: 'None', value: '' },
  { label: 'Alarm Type', value: 'displayName' },
  { label: 'Site', value: 'site_name' },
  { label: 'Device Name', value: 'device_name' },
  { label: 'Device Labels', value: 'displayDeviceLabels' }
];

const valueRenderer = (option) => option.label;

@withRouter
@observer
export default class HealthIssuesSettings extends Component {
  state = {
    grouping: '',
    searchInputValue: ''
  };

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState() {
    const { collection, location } = this.props;
    const { state = {} } = location;
    const settings = {
      grouping: collection.groupBy ?? '',
      searchInputValue: collection.filterState,
      ...state.settings
    };

    this.handleSearchInputChange(settings.searchInputValue);
    this.handleGroupByChange(settings.grouping);
  }

  handleSearchInputChange = (evt) => {
    const { history, location } = this.props;
    const { state } = location;
    const searchInputValue = evt?.target ? evt.target.value : evt;
    const settings = { ...state?.settings, searchInputValue };

    this.setState({ searchInputValue });
    history.replace(location.pathname, { ...state, settings });

    setTimeout(() => {
      const { collection } = this.props;
      collection.filter(searchInputValue.trim());
    }, 0);
  };

  handleGroupByChange = (grouping) => {
    const { history, location } = this.props;
    const { state } = location;
    const settings = { ...state?.settings, grouping };

    this.setState({ grouping });
    history.replace(location.pathname, { ...state, settings });

    setTimeout(() => {
      const { collection } = this.props;
      collection.group(grouping || null);

      if (grouping) {
        collection.sort(grouping, 'asc');
      } else {
        collection.sort('check.state', 'desc');
      }
    }, 0);
  };

  render() {
    const { grouping, searchInputValue } = this.state;

    return (
      <Flex gap={1} alignItems="center" mb={1}>
        <InputGroup
          autoFocus
          leftIcon="search"
          placeholder="Search..."
          value={searchInputValue}
          onChange={this.handleSearchInputChange}
          className="jumbo"
          width={350}
        />
        <Select
          inlineLabel
          label="Group By"
          menuWidth={180}
          onChange={this.handleGroupByChange}
          options={groupingOptions}
          small
          values={grouping}
          valueRenderer={valueRenderer}
        />
      </Flex>
    );
  }
}
