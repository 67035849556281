import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Flex, Text, Link } from 'core/components';
import { ReactComponent as RouterIcon } from 'app/assets/icons/router_icon.svg';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import DeviceLink from 'app/components/links/DeviceLink';
import InterfaceLink from 'app/components/links/InterfaceLink';

function getTitle(config) {
  return config.nodeData?.device_name || config.device_name || config.value;
}

const RouterSummary = (config) => {
  const { logo, nodeData, $auth } = config;

  const iface = Object.values(nodeData?.children || {})[0] ?? nodeData?.connectedInterfaces[0] ?? null;

  const hasConfiguredDevice = +nodeData?.device_id > 0;

  return (
    <Box>
      <Flex p={2} borderBottom="thin" alignItems="center">
        {logo ? React.cloneElement(logo, { width: 32, height: 32 }) : <RouterIcon width={32} height={32} />}
        <Box flex={1} ml={1}>
          <Text as="div" muted>
            Router Details
          </Text>
          <Text muted small>
            {getTitle(config)}
          </Text>
        </Box>
      </Flex>
      {nodeData && (
        <SidebarItem title="Details" icon="more" popoutTitle={RouterSummary.PopoutTitle(config)} excludeFormProps>
          <Box>
            <CloudMetadata
              node={{
                'Device ID': hasConfiguredDevice ? nodeData.device_id : null,
                'Device Name': nodeData.device_name,
                Title: nodeData.title,
                'Interface Description': hasConfiguredDevice
                  ? (nodeData?.interface_description ?? iface?.interface_description)
                  : null,
                'Interface Ip': nodeData?.interface_ip ?? iface?.interface_ip,
                'Interface CIDR': nodeData?.interface_cidr ?? iface?.interface_cidr,
                'Interface Ip Net Mask': nodeData?.interface_ip_netmask ?? iface?.interface_ip_netmask,
                'SNMP Alias': nodeData?.snmp_alias ?? iface?.snmp_alias,
                IPs: nodeData.ips,
                Site: nodeData.site_title
              }}
              keys={[
                'Device ID',
                'Device Name',
                'Title',
                'Interface Description',
                'Interface Ip',
                'Interface CIDR',
                'Interface Ip Net Mask',
                'SNMP Alias',
                'IPs',
                'Site'
              ]}
              renderItem={(key, itemValue) => {
                if (key === 'Device Name' && hasConfiguredDevice) {
                  return <DeviceLink name={itemValue} blank />;
                }

                if (key === 'Interface Description' && hasConfiguredDevice) {
                  return <InterfaceLink device_name={nodeData.device_name} {...iface} blank />;
                }

                return null;
              }}
            />

            {!hasConfiguredDevice && $auth.isAdministrator && (
              <Box mt={1}>
                <Link to="/v4/settings/devices" blank>
                  Configure a device to monitor this gateway in Kentik
                </Link>
              </Box>
            )}
          </Box>
        </SidebarItem>
      )}
    </Box>
  );
};

RouterSummary.PopoutTitle = (config) => {
  const { logo } = config;
  return (
    <Flex alignItems="center">
      {logo || <RouterIcon />}
      <Box ml={1}>{getTitle(config)}</Box>
    </Flex>
  );
};

export default inject('$auth')(observer(RouterSummary));
