import React, { Component } from 'react';
import { formConsumer } from 'core/form';
import { dateTimeFormatter } from 'core/util/dateUtils';
import AdminTable from 'app/components/admin/AdminTable';
import { Flex } from 'core/components';

function renderFlowPackSummary({ value }) {
  const { devices, fps, planId } = value;
  return `Added: Devices: ${devices || 'N/A'} | FPS: ${fps || 'N/A'},  Plan: ${planId || 'N/A'}`;
}

function renderCustomDimensionSummary({ value }) {
  const { customDimensions } = value;
  return `Added: ${customDimensions}`;
}

function renderCloudPakSummary({ value }) {
  const { maxFlowLogVolume } = value;
  return `Added: ${maxFlowLogVolume / 1000000000} (GB)`;
}

function renderFullRetentionSummary({ value }) {
  const { fullRetention, planId } = value;
  return `Added: ${fullRetention} Days, Plan: ${planId || 'N/A'}`;
}

function renderFastRetentionSummary({ value }) {
  const { fastRetention, planId } = value;
  return `Added: ${fastRetention} Days,  Plan: ${planId || 'N/A'}`;
}

const renderMap = {
  flowpak: renderFlowPackSummary,
  customdimension: renderCustomDimensionSummary,
  cloudpak: renderCloudPakSummary,
  fullretention: renderFullRetentionSummary,
  fastretention: renderFastRetentionSummary
};
@formConsumer
export default class CompanyAddOns extends Component {
  getColumns = () => [
    {
      label: 'Type',
      name: 'type'
    },
    {
      label: 'Added',
      name: 'data',
      renderer: ({ model, value }) => renderMap[model.get('type')]({ model, value }) || 'N/A'
    },
    {
      label: 'Created',
      name: 'cdate',
      renderer: dateTimeFormatter
    },
    {
      label: 'Updated',
      name: 'edate',
      renderer: dateTimeFormatter
    }
  ];

  render() {
    const { form } = this.props;
    return (
      <Flex flexDirection="column" flex={1} overflow="auto">
        <AdminTable
          collection={form.model.get('addOns')}
          columns={this.getColumns()}
          containerProps={{ overflow: 'auto' }}
          tableContainerProps={{ overflow: 'auto' }}
          fetchCollectionOnMount={false}
          flexed
        />
      </Flex>
    );
  }
}
