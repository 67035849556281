import * as React from 'react';
import { Box, Button, Flex, Grid, Icon, Text } from 'core/components';
import { ReactComponent as SetupIcon } from 'app/assets/icons/setup_icon.svg';

export default class AgentSummary extends React.Component {
  renderStatus = ({ name, value }) => (
    <Box key={name}>
      <Text muted small>
        {name}
      </Text>
      <Flex>
        <Icon mr="4px" icon={SetupIcon} />
        <Text fontWeight="bold">{value}</Text>
      </Flex>
    </Box>
  );

  render() {
    const { toggleAgentManagement, unmonitoredVpcs, pendingAgents, privateAgents, ...gridProps } = this.props;
    const summaryItems = [
      { name: 'Unmonitored', value: unmonitoredVpcs },
      { name: 'Pending', value: pendingAgents },
      { name: 'Installed', value: privateAgents }
    ];

    return (
      <Grid gridTemplateColumns="repeat(4, auto)" alignItems="flex-start" {...gridProps}>
        {summaryItems.map(this.renderStatus)}
        <Button mt="4px" text="Manage Agents" intent="primary" onClick={toggleAgentManagement} small />
      </Grid>
    );
  }
}
