import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import AdminFilterSidebar from 'app/components/admin/AdminFilterSidebar';
import { Switch } from 'core/form';

@inject('$rbac')
@observer
class RoleSetFilterSidebar extends Component {
  get filterTypeHash() {
    return {
      noSsoMappings: {
        filter: (model, showWithoutMappings) => {
          if (showWithoutMappings) {
            return !model.get('ssoAttributes')?.length;
          }
          return true;
        }
      },
      roles: {
        label: 'Contains Roles',
        placeholder: 'Filter by roles...',
        showCheckboxes: false,
        allowMultiSelect: true,
        filter: (model, values) => {
          const roles = model.get('roles');
          return roles?.length && roles.some((role) => values.includes(role));
        }
      },
      noRoles: {
        filter: (model, showWithoutRoles) => {
          if (showWithoutRoles) {
            return !model.get('roles')?.length;
          }
          return true;
        }
      }
    };
  }

  get roleOptions() {
    const { collection, $rbac } = this.props;
    const optionsSet = new Set();

    collection.get().forEach((model) => {
      const roles = model.get('roles');
      roles.forEach((role) => optionsSet.add(role));
    });
    return [...optionsSet].map((value) => ({ value, label: $rbac.collection.get(value)?.get('role_name') }));
  }

  render() {
    const { filterTypeHash, roleOptions } = this;

    const filterFields = [
      {
        type: 'noSsoMappings',
        name: 'noSsoMappings',
        customField: <Switch switchLabel="Does not have SSO mappings" />,
        ...filterTypeHash.noSsoMappings
      },
      {
        type: 'roles',
        name: 'roles',
        options: roleOptions,
        ...filterTypeHash.roles
      },
      {
        type: 'noRoles',
        name: 'noRoles',
        customField: <Switch switchLabel="Does not contain roles" />,
        ...filterTypeHash.noRoles
      }
    ];

    return <AdminFilterSidebar {...this.props} filterFields={filterFields} />;
  }
}

export default RoleSetFilterSidebar;
