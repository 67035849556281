import React from 'react';
import { Text, Icon, Box, Flex } from 'core/components';
import FavoriteButton from 'app/views/core/FavoriteButton';
import ResultMenuItem from './ResultMenuItem';

function SavedView({ item, selected, query, IconCmp, onItemClick, showFavorite, daysAgo, ...rest }) {
  const { id, path, view_name, view_description, description, name } = item;
  return (
    <ResultMenuItem
      {...rest}
      selected={selected}
      onClick={() => onItemClick({ type: 'savedView', id, path }, 'Search Results')}
    >
      <Flex gridArea="icon" alignItems="center" justifyContent={showFavorite ? 'space-between' : 'center'}>
        <IconCmp iconSize={22} />
        {showFavorite && <FavoriteButton type="saved-view" id={id} name={view_name || name} ml={1} />}
      </Flex>

      <Box gridArea="title">
        <Text className="title">{view_name || name}</Text>
      </Box>

      <Flex gridArea="rightIcon" alignItems="center" justifyContent="flex-end" fontSize={12}>
        <Icon icon={selected ? 'key-enter' : undefined} color="#ffffff" />
        {daysAgo && <Text pl={1}>{daysAgo}</Text>}
      </Flex>

      <Text
        className="description"
        gridArea="description"
        muted
        small
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        style={{ overflow: 'hidden' }}
      >
        {view_description || description || 'No description'}
      </Text>
    </ResultMenuItem>
  );
}

export default SavedView;
