/*
  Represents the status of a GCP target vpn gateway as defined by the 'status' property:
  https://cloud.google.com/compute/docs/reference/rest/v1/targetVpnGateways/list#response-body

  and the status of a VPN tunnel according to the 'status' property:
  https://cloud.google.com/compute/docs/reference/rest/v1/vpnTunnels/list#response-body
*/
import * as React from 'react';
import { Icon, Tag, Tooltip } from 'core/components';

const STATUS_MAP = {
  // target vpn gateway status types
  CREATING: { label: 'Creating', color: 'gcp.blue' },
  READY: { label: 'Ready', color: 'gcp.green' },
  FAILED: { label: 'Failed', color: 'gcp.red' }, // and vpn tunnel
  DELETING: { label: 'Deleting', color: 'gcp.red' },

  // vpn tunnel types
  PROVISIONING: { label: 'Provisioning', color: 'gcp.blue' },
  WAITING_FOR_FULL_CONFIG: { label: 'Waiting for Config', color: 'gcp.blue' },
  FIRST_HANDSHAKE: { label: 'First Handshake', color: 'gcp.blue' },
  ESTABLISHED: { label: 'Established', color: 'gcp.green' },
  NETWORK_ERROR: { label: 'Network Error', color: 'gcp.red' }, // marked as deprecated but it's still in the api docs
  AUTHORIZATION_ERROR: { label: 'Authorization Error', color: 'gcp.red' },
  NEGOTIATION_FAILURE: { label: 'Negotiation Failure', color: 'gcp.red' },
  DEPROVISIONING: { label: 'Deprovisioning', color: 'gcp.yellow' },
  NO_INCOMING_PACKETS: { label: 'No Incoming Packets', color: 'gcp.red' },
  REJECTED: { label: 'Rejected', color: 'gcp.red' },
  ALLOCATING_RESOURCES: { label: 'Allocating Resources', color: 'gcp.blue' },
  STOPPED: { label: 'Stopped', color: 'gcp.red' },
  PEER_IDENTITY_MISMATCH: { label: 'Peer Mismatch', color: 'gcp.red' },
  TS_NARROWING_NOT_ALLOWED: { label: 'Traffic Selector Narrowing Not Allowed', color: 'gcp.red' }
};

export function VPNStatusTag({ status, detailedStatus }) {
  const { label, color } = STATUS_MAP[status] || { label: status, color: null };
  return (
    <Tooltip content={detailedStatus} isDisabled={!detailedStatus}>
      <Tag bg={color}>{label}</Tag>
    </Tooltip>
  );
}

export function VPNStatusBadges({ statusList = [], showCount = false }) {
  const colorCount = statusList.reduce((acc, status) => {
    const config = STATUS_MAP[status];

    if (config) {
      acc[config.color] = (acc[config.color] || 0) + 1;
    }

    return acc;
  }, {});

  return (
    <>
      {Object.entries(colorCount).map(([key, value]) => {
        if (showCount) {
          return (
            <Tag key={key} bg={key} round>
              {value}
            </Tag>
          );
        }

        return <Icon key={key} icon="full-circle" color={key} />;
      })}
    </>
  );
}
