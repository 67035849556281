import * as React from 'react';
import { observer } from 'mobx-react';
import DeployAgentInstructionsDialog from 'app/views/cloudPerformance/deployAgentInstructionsDialog/DeployAgentInstructionsDialog';
import AgentConfigFormDialog from 'app/views/synthetics/agents/AgentConfigFormDialog';
import ManageAgentsDialog from './ManageAgentsDialog';

@observer
export default class AgentManagementDialog extends React.Component {
  static defaultProps = {
    agentIds: [],
    uninstalledVpcs: []
  };

  static getDerivedStateFromProps(props, state) {
    const { model } = props;
    let { showAgentInstructionsDialog, showAgentActivateDialog } = state;

    let selectedAgent = null;
    let agentMetadata = null;

    if (model) {
      if (model.get('vpcId')) {
        // we are either dealing with a vpc with no agent (step 1 of 3)
        // or a vpc that has a pending agent (step 2 of 3)
        showAgentInstructionsDialog = true;
        selectedAgent = model.get('agent');
        agentMetadata = {
          agent_alias: model.get('vpcId'),
          cloud_provider: 'aws',
          cloud_region: model.get('region'),
          cloud_vpc: model.get('vpcId')
        };
      } else {
        // we have an activated agent model that we want to edit
        showAgentActivateDialog = true;
        selectedAgent = model;
      }
    } else {
      // there is no model, close all 'editing' dialogs
      showAgentInstructionsDialog = false;
      showAgentActivateDialog = false;
    }

    return { showAgentInstructionsDialog, showAgentActivateDialog, selectedAgent, agentMetadata };
  }

  state = {
    showAgentInstructionsDialog: false,
    showAgentActivateDialog: false,
    selectedAgent: null,
    agentMetadata: null
  };

  handleAgentFormDialogClose = () => {
    const { onAgentFormDialogClose } = this.props;

    if (onAgentFormDialogClose) {
      onAgentFormDialogClose();
    }

    return null;
  };

  handleCloseActivate = () =>
    this.setState(({ showAgentInstructionsDialog }) => {
      if (showAgentInstructionsDialog) {
        return { showAgentActivateDialog: false };
      }

      return this.handleAgentFormDialogClose();
    });

  handleChallenge = (model) => {
    const { onModelChange } = this.props;

    if (onModelChange) {
      onModelChange({ type: 'challenge', model });
    }

    return null;
  };

  handleSave = () => {
    const { onModelChange } = this.props;
    const { selectedAgent } = this.state;

    if (onModelChange) {
      onModelChange({ type: 'save', model: selectedAgent });
    }

    return null;
  };

  render() {
    const { agentIds, uninstalledVpcs, isAgentManagementOpen, toggleAgentManagement, onModelChange } = this.props;
    const { showAgentInstructionsDialog, showAgentActivateDialog, selectedAgent, agentMetadata } = this.state;

    return (
      <>
        {showAgentInstructionsDialog && (
          <DeployAgentInstructionsDialog
            isOpen={showAgentInstructionsDialog}
            onClose={this.handleAgentFormDialogClose}
            onEnterAgentChallengeCodeClick={() => this.setState({ showAgentActivateDialog: true })}
          />
        )}

        {showAgentActivateDialog && (
          <AgentConfigFormDialog
            challenge={!selectedAgent}
            model={selectedAgent}
            onClose={this.handleCloseActivate}
            agentMetadata={agentMetadata}
            onChallenge={this.handleChallenge}
            onSave={this.handleSave}
          />
        )}

        {isAgentManagementOpen && (
          <ManageAgentsDialog
            agentIds={agentIds}
            uninstalledVpcs={uninstalledVpcs}
            isOpen={isAgentManagementOpen}
            onClose={toggleAgentManagement}
            onModelChange={onModelChange}
          />
        )}
      </>
    );
  }
}
