import React from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';

// provides default styling for arc items, mainly in the form of sizing and positioning

const StyledItemGroup = styled.g.attrs((props) => {
  const { linkLength, point, shapeSize } = props;
  const scale = linkLength ? Math.max(0.5, Math.min(1, linkLength / (shapeSize * 1.5))) : 1;
  return {
    className: classNames({ scaled: scale < 1 }),
    style: {
      transform: `translate(${point[0]}px, ${point[1]}px) scale(${scale})`
    }
  };
})`
  ${(props) => {
    const { clickable, shapeSize } = props;
    const radius = shapeSize / 2;
    const textOffset = -(radius + 10);
    const textAngle = Math.PI / 6;

    return css`
      cursor: ${clickable ? 'pointer' : 'auto'};

      circle {
        r: ${radius}px;

        &.health-indicator {
          cx: 10px;
          cy: -17px;
        }

        &:not(.health-indicator) {
          cx: 0;
          cy: 0;
        }
      }

      rect {
        width: ${shapeSize}px;
        height: ${shapeSize}px;
        transform: translate(${-radius}px, ${-radius}px);
      }

      text {
        font-size: 12px;
        text-anchor: middle;
        transform: translate(0, ${textOffset}px);

        &.topleft {
          text-anchor: end;
          transform: translate(${Math.sin(textAngle) * (textOffset + 5)}px, ${Math.cos(textAngle) * (textOffset + 5)}px)
            rotate(${textAngle / 3}rad);
        }

        &.topright {
          text-anchor: start;
          transform: translate(
              ${-Math.sin(textAngle) * (textOffset + 5)}px,
              ${Math.cos(textAngle) * (textOffset + 5)}px
            )
            rotate(-${textAngle / 3}rad);
        }

        &.bottom {
          transform: translate(0, ${-textOffset + 10}px);
        }
      }

      &.scaled {
        text {
          text-anchor: start;
          transform: translate(
              ${-Math.sin(textAngle) * (textOffset + 5)}px,
              ${Math.cos(textAngle) * (textOffset + 5)}px
            )
            rotate(-${textAngle}rad);

          &.topleft {
            text-anchor: end;
            transform: translate(
                ${Math.sin(textAngle) * (textOffset + 5)}px,
                ${Math.cos(textAngle) * (textOffset + 5)}px
              )
              rotate(${textAngle}rad);
          }

          &.bottom {
            transform: translate(0, ${-textOffset + 10}px) rotate(${textAngle}rad);
          }
        }
      }
    `;
  }}
`;

const ArcLayoutItemGroup = (props) => {
  const { className, ...rest } = props;
  return <StyledItemGroup {...rest} className={classNames('arclayout-item-group', className)} />;
};

ArcLayoutItemGroup.defaultProps = {
  point: { x: 0, y: 0 },
  linkLength: 60,
  shapeSize: 40,
  clickable: true
};

export default ArcLayoutItemGroup;
