import React, { Component } from 'react';
import ArcLayout, { sortArcItems } from './arc/ArcLayout';
import ChordLayout from './chord/ChordLayout';

export default class ChordLayoutWithFallback extends Component {
  static defaultProps = {
    chordThreshold: 12,
    forceArc: false,
    forceChord: false,
    width: 900,
    size: 40,
    paddingX: 150,
    paddingY: 120,
    items: [],
    getLink: () => null,
    setPopoverPosition: () => null
  };

  static getDerivedStateFromProps(props, state) {
    const { items, getLink } = props;

    if (items.length !== state.items.length) {
      return {
        items: ChordLayoutWithFallback.getLayout(props) === 'ARC' ? sortArcItems(items, getLink) : items
      };
    }

    return null;
  }

  static getLayout(props) {
    const { items, chordThreshold, forceArc, forceChord } = { ...this.defaultProps, ...props };
    return forceArc || (!forceChord && items.length < chordThreshold) ? 'ARC' : 'CHORD';
  }

  static getHeight(props, min = 200, max = 800) {
    const { items, paddingY } = { ...this.defaultProps, ...props };
    const factor = Math.min(1, items.length / 80);
    const height = min + Math.sin((factor * Math.PI) / 2) * (max - min);

    if (this.getLayout(props) === 'CHORD') {
      const labelWidth = ChordLayout.getLabelWidth(props);
      return height + labelWidth * 2 + 16;
    }

    return height / 2 + 2 * paddingY;
  }

  state = {
    items: [], // props.items sorted for better arc
    hovered: null
  };

  chordBox = React.createRef();

  get layout() {
    return ChordLayoutWithFallback.getLayout(this.props);
  }

  handleHover = (item) => this.setState({ hovered: item });

  render() {
    const { paddingX, paddingY, width, height, size, onSelect, interSiteTrafficEnabled, ...props } = this.props;
    const { items, hovered } = this.state;
    const { layout } = this;

    if (layout === 'ARC') {
      const availableWidth = width - 2 * paddingX;
      const arcWidth = Math.min(availableWidth, Math.max((items.length - 1) * (size * 5), size));

      return (
        <g transform={`translate(${paddingX + (availableWidth - arcWidth) / 2} ${paddingY})`}>
          <ArcLayout
            {...props}
            width={arcWidth}
            arcHeight={height - 2 * paddingY}
            size={size}
            items={items}
            hovered={hovered}
            onHover={this.handleHover}
            onSelect={(item) => onSelect(item, layout)}
            interSiteTrafficEnabled={interSiteTrafficEnabled}
          />
        </g>
      );
    }

    return (
      <g ref={this.chordBox}>
        <ChordLayout
          {...props}
          width={width}
          height={height}
          items={items}
          hovered={hovered}
          onHover={this.handleHover}
          onSelect={(item) => {
            // set our own explicit popover position to account for chord animation
            const { setPopoverPosition } = this.props;
            const rect = this.chordBox.current.getBoundingClientRect();
            setPopoverPosition({ left: rect.width / 2, top: rect.y });

            onSelect(item, layout);
          }}
          interSiteTrafficEnabled={interSiteTrafficEnabled}
        />
      </g>
    );
  }
}
