import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Flex, LinkButton } from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import { ReactComponent as ObservationDeckIcon } from 'app/assets/observation-deck-logo.svg';
import Deck from 'app/components/decks/Deck';
import SetupGauge from 'app/components/setup/SetupGauge';

/*
 * TODO: consider exposing this as a reusable component to avoid customize/boilerplate
 *
 * Deck itself is very flexible, but if we are going to usually have the same tools, we'll
 * want to avoid this getting replicated for every deck.
 */
@storeLoader('$setup.setupTasks')
@inject('$auth', '$devices', '$syn')
class ObservationDeck extends Component {
  state = {
    isCustomizeDrawerOpen: false
  };

  handleToggleCustomizeDrawer = () => {
    const { isCustomizeDrawerOpen } = this.state;
    this.setState({ isCustomizeDrawerOpen: !isCustomizeDrawerOpen });
  };

  handleCloseCustomizeDrawer = () => {
    this.setState({ isCustomizeDrawerOpen: false });
  };

  get canCustomize() {
    const { $devices, $syn } = this.props;
    return $devices.activeDeviceSummaries.length > 0 || $syn.tests.size > 0;
  }

  render() {
    const { $auth, $setup } = this.props;
    const { isCustomizeDrawerOpen } = this.state;
    const { canCustomize } = this;

    return (
      <Deck
        title="Observation Deck"
        type="home"
        icon={ObservationDeckIcon}
        tools={
          <>
            {$auth.isAdministrator && canCustomize && $setup.setupTasks.size > 0 && (
              <Flex alignItems="center">
                <SetupGauge width={90} showLabel />
                <LinkButton text="View Setup Tasks" to="/v4/setup" />
              </Flex>
            )}
          </>
        }
        isCustomizeDrawerOpen={isCustomizeDrawerOpen}
        onCloseCustomizeDrawer={this.handleCloseCustomizeDrawer}
        onAddWidgets={this.handleToggleCustomizeDrawer}
        canCustomize={canCustomize}
        hideSetupGauge
        showOnboarding
        showLandingPageCallout
      />
    );
  }
}

export default ObservationDeck;
