import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { FiInfo } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { Box, Button, CalloutOutline, Dismissible, Flex, Icon, Text, Tooltip } from 'core/components';
import {
  getEntities,
  checkSecurityGroupRules,
  checkNACLRules,
  securityEntitiesDenyTraffic
} from 'app/views/hybrid/utils/aws';
import DeniedTrafficTable from 'app/views/hybrid/maps/components/popovers/DeniedTrafficTable';
import SecurityGroupsAndNACLsDialog from './SecurityGroupsAndNACLsDialog';

@inject('$hybridMap')
@observer
export default class SecurityGroupsAndNACLs extends React.Component {
  state = {
    dialogTab: null,
    selectedRule: null
  };

  handleOpenDialog = (dialogTab) => this.setState({ dialogTab });

  handleCloseDialog = () => this.setState({ dialogTab: null, selectedRule: null });

  handleRowClick = (selectedRule) => {
    let dialogTab = 'securityGroups';
    const securityGroupsDenyTraffic = securityEntitiesDenyTraffic(this.getSecurityGroups(selectedRule));
    const naclsDenyTraffic = securityEntitiesDenyTraffic(this.getNACLs(selectedRule));

    if (!securityGroupsDenyTraffic && naclsDenyTraffic) {
      dialogTab = 'networkACLs';
    }

    this.setState({ selectedRule, dialogTab });
  };

  // support is shown by having at least one flow log with s3 as the log destination type
  get hasSupportedFlowLogs() {
    const { topology } = this.props;
    const flowLogs = topology && topology.Entities && topology.Entities.FlowLogs;

    if (flowLogs) {
      return Object.values(flowLogs).some((log) => log.LogDestinationType === 's3');
    }

    return false;
  }

  sortSecurityEntities = (securityEntities) =>
    securityEntities.sort((a, b) => {
      if (a.deniesTraffic === b.deniesTraffic) {
        return 0;
      }

      return a.deniesTraffic ? -1 : 1;
    });

  getSecurityGroups(selectedRule) {
    const { topology, vpcId } = this.props;

    const securityGroups = getEntities(topology, 'SecurityGroups', { asList: true })
      .filter((securityGroup) => securityGroup.VpcId === vpcId)
      .map((securityGroup) => {
        const check = checkSecurityGroupRules(topology, securityGroup, selectedRule);
        // if the check passed, show all traffic undecorated, otherwise mark the denied traffic accordingly so it gets the red row colors
        const rules = check.allowed
          ? check.rules.all
          : [].concat(
              check.rules.denied.map((rule) => ({ ...rule, deniesTraffic: true })),
              check.rules.allowed
            );

        return { ...securityGroup, rules, deniesTraffic: !check.allowed };
      });

    return this.sortSecurityEntities(securityGroups);
  }

  getNACLs(selectedRule) {
    const { topology, vpcId, subnetId } = this.props;

    const nacls = getEntities(topology, 'NetworkAcls', { asList: true })
      .filter((nacl) => {
        const matchesVpc = nacl.VpcId === vpcId;

        if (nacl.Associations && subnetId) {
          const subnetIds = nacl.Associations.map((association) => association.SubnetId);
          return matchesVpc && subnetIds.some((id) => id === subnetId);
        }

        return matchesVpc;
      })
      .map((nacl) => {
        const check = checkNACLRules(nacl, selectedRule);
        const rules = [].concat(
          check.rules.denied.map((rule) => ({ ...rule, deniesTraffic: true })),
          check.rules.allowed
        );

        return { ...nacl, rules, deniesTraffic: !check.allowed };
      });

    return this.sortSecurityEntities(nacls);
  }

  render() {
    const { $hybridMap, topology, vpcId, subnetId, entity, entityType, cloudProvider } = this.props;
    const { dialogTab, selectedRule } = this.state;

    return (
      <Box px="4px">
        <Dismissible name="denied-traffic-help-text">
          {({ onDismiss }) => (
            <CalloutOutline display="flex" alignItems="flex-start" intent="primary" p={2} mb={2}>
              <Text small>Click a row to see which Security Groups and/or Network ACL rules rejected the traffic</Text>
              <Button icon={MdClose} minimal onClick={onDismiss} />
            </CalloutOutline>
          )}
        </Dismissible>

        <Flex mb={1}>
          <Text fontWeight="bold">Denied Traffic</Text>
          {!this.hasSupportedFlowLogs && (
            <Tooltip
              content={
                <div>
                  <div>There are no supported flow logs found.</div>
                  <div>
                    {' '}
                    You must have at least one flow log with a log destination type of{' '}
                    <Text fontStyle="italic">s3</Text>
                  </div>
                </div>
              }
            >
              <Icon ml={1} icon={FiInfo} intent="primary" />
            </Tooltip>
          )}
        </Flex>

        <DeniedTrafficTable
          cloudProvider={cloudProvider}
          entity={entity}
          entityType={entityType}
          onRowClick={this.handleRowClick}
          queryTimeOptions={$hybridMap.settingsModel.queryTimeOptions}
        />

        <Flex mt={1} justifyContent="center">
          <Button
            mr={1}
            text="View Security Groups"
            icon="shield"
            onClick={() => this.handleOpenDialog('securityGroups')}
            minimal
            small
          />

          <Button
            text="View Network ACLs"
            icon="lock"
            onClick={() => this.handleOpenDialog('networkACLs')}
            minimal
            small
          />
        </Flex>

        <SecurityGroupsAndNACLsDialog
          isOpen={!!dialogTab}
          onClose={this.handleCloseDialog}
          height="85vh"
          width={700}
          topology={topology}
          securityGroups={this.getSecurityGroups(selectedRule)}
          nacls={this.getNACLs(selectedRule)}
          initialTab={dialogTab}
          vpcId={vpcId}
          subnetId={subnetId}
          selectedRule={selectedRule}
          queryTimeOptions={$hybridMap.settingsModel.queryTimeOptions}
        />
      </Box>
    );
  }
}
