import * as React from 'react';
import styled from 'styled-components';
import { Classes } from '@blueprintjs/core';
import { Box, Button, Flex, Heading, Tabs, Tab } from 'core/components';
import BorderedBox from './BorderedBox';

const Container = styled(Box)`
  .box-tabs > .${Classes.TAB_PANEL} {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
`;

export default class CloudMapBox extends React.Component {
  static defaultProps = {
    containerProps: {},
    isExpanded: true,
    onExpandToggle: null,
    title: null
  };

  handleExpandToggle = (evt) => {
    const { isExpanded, onExpandToggle } = this.props;

    evt.stopPropagation();
    onExpandToggle(!isExpanded);
  };

  get selectedTabId() {
    const { isExpanded } = this.props;

    return isExpanded ? 'expanded' : 'collapsed';
  }

  get titleHeading() {
    const { isExpanded, title } = this.props;

    if (title && isExpanded) {
      return title;
    }

    return <span>&nbsp;</span>;
  }

  get expandButton() {
    const { isExpanded, onExpandToggle } = this.props;

    if (onExpandToggle) {
      const icon = isExpanded ? 'minimize' : 'maximize';

      return (
        <Button position="absolute" right={4} top={4} icon={icon} onClick={this.handleExpandToggle} minimal small />
      );
    }

    return null;
  }

  get boxProps() {
    const { containerProps, title, isExpanded, onExpandToggle, ...boxProps } = this.props;
    const defaultBoxProps = {
      borderStyle: 'dashed',
      ...boxProps
    };

    if (isExpanded) {
      return {
        ...defaultBoxProps,
        borderStyle: 'solid'
      };
    }

    return {
      ...defaultBoxProps,
      // in collapsed state enforce a consistent, smaller size
      minWidth: 'auto',
      minHeight: 'auto',
      width: 150,
      height: 75
    };
  }

  render() {
    const { children, containerProps, title } = this.props;

    return (
      <Container {...containerProps}>
        <Heading level={5}>{this.titleHeading}</Heading>
        <BorderedBox {...this.boxProps}>
          {this.expandButton}
          <Tabs className="box-tabs" selectedTabId={this.selectedTabId} renderActiveTabPanelOnly flexed minimal>
            <Tab id="expanded" panel={children} />
            <Tab
              id="collapsed"
              panel={
                <Flex alignItems="center" justifyContent="center" flex={1}>
                  <Heading level={6} m={0}>
                    {title}
                  </Heading>
                </Flex>
              }
            />
          </Tabs>
        </BorderedBox>
      </Container>
    );
  }
}
