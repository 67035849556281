import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import styled, { css } from 'styled-components';

import { Card, Text, Heading, Flex, Box, Icon } from 'core/components';

const FeatureWrapper = styled(Card)`
  background: ${(props) => props.theme.colors.subnavBackground};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  margin-right: 12px;
  margin-bottom: 8px;
  flex: 1;

  ${(props) =>
    !props.active &&
    css`
      div,
      span {
        filter: grayscale(1);
        opacity: 0.65;
      }
    `}
`;

@inject('$auth')
@observer
class ServiceProviderPlanCard extends Component {
  render() {
    const { $auth, ...rest } = this.props;

    return (
      <Card p={2} {...rest}>
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Flex flex="1 1 auto" mr={3}>
            <Box width={40} mr={2}>
              <Icon icon="heatmap" iconSize={40} color="warning" />
            </Box>

            <Box maxWidth={600} minWidth={350}>
              <Heading level={4} mb={1}>
                Service Provider
              </Heading>

              <Flex>
                <FeatureWrapper active={$auth.hasPermission('ott.enabled')}>
                  <Box>
                    <Text as="div" fontSize="small" fontWeight="bold">
                      OTT Service Tracking
                    </Text>
                    <Text color="muted" fontSize="small">
                      {$auth.hasPermission('ott.enabled') ? 'Enabled' : 'Disabled'}
                    </Text>
                  </Box>
                </FeatureWrapper>
                <FeatureWrapper active={$auth.hasPermission('cdn.enabled')}>
                  <Box>
                    <Text as="div" fontSize="small" fontWeight="bold">
                      CDN Analytics
                    </Text>
                    <Text color="muted" fontSize="small">
                      {$auth.hasPermission('cdn.enabled') ? 'Enabled' : 'Disabled'}
                    </Text>
                  </Box>
                </FeatureWrapper>
                <FeatureWrapper active={$auth.hasPermission('marketIntel.enabled')}>
                  <Box>
                    <Text as="div" fontSize="small" fontWeight="bold">
                      Market Intelligence
                    </Text>
                    <Text color="muted" fontSize="small">
                      {$auth.hasPermission('marketIntel.enabled') ? 'Enabled' : 'Disabled'}
                    </Text>
                  </Box>
                </FeatureWrapper>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Card>
    );
  }
}

export default ServiceProviderPlanCard;
