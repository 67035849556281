import * as React from 'react';
import { MdCompareArrows } from 'react-icons/md';
import { Box, Flex, Heading, Icon, Text } from 'core/components';
import { ReactComponent as KubePodIcon } from 'app/assets/icons/kubernetes/pod.svg';

class K8PodtoPodLinkSummary extends React.Component {
  render() {
    const { source, target } = this.props;
    // console.log(this.props);

    return (
      <>
        <Flex p={2} borderBottom="thin" alignItems="center">
          <Icon icon={KubePodIcon} iconSize={32} mr={1} />
          <Box>
            <Text as="div" muted>
              Pod to Pod Details
            </Text>
            <Heading level={4}>
              {source.id.replace('gke-gcp-kubernetes-default-pool-', '')} <Icon icon={MdCompareArrows} />{' '}
              {target.id.replace('gke-gcp-kubernetes-default-pool-', '')}
            </Heading>
          </Box>
        </Flex>
        <Box p={2} py={1} borderBottom="thin">
          <Flex mb="4px" alignItems="center">
            <Text muted as="div" mr="4px">
              Source:
            </Text>
            <Flex alignItems="center">
              <Icon icon={KubePodIcon} iconSize={16} mr="4px" color="primary" />
              <Text fontWeight="medium">{source.id}</Text>
            </Flex>
          </Flex>
          <Flex mb="4px" alignItems="center">
            <Text muted as="div" mr="4px">
              Destination:
            </Text>
            <Flex alignItems="center">
              <Icon icon={KubePodIcon} iconSize={16} mr="4px" color="primary" />
              <Text fontWeight="medium">{target.id}</Text>
            </Flex>
          </Flex>
        </Box>
      </>
    );
  }
}

K8PodtoPodLinkSummary.PopoutTitle = (props) => <K8PodtoPodLinkSummary {...props} popoutTitle />;

export default K8PodtoPodLinkSummary;
