import React from 'react';
import { inject, observer } from 'mobx-react';
import { uniq, isEqual } from 'lodash';

import { EmptyState, Spinner } from 'core/components';
import { setupLayers } from 'app/views/hybrid/maps/SiteDetailsMap';
import AbstractMap from 'app/views/hybrid/maps/components/AbstractMap';
import SiteDevices from 'app/views/hybrid/maps/components/SiteDevices';
import withPopover from 'app/views/hybrid/maps/components/popovers/withPopover';
import SiteFormDialog from 'app/views/settings/sites/SiteFormDialog';

@withPopover
@inject('$hybridMap')
@observer
class EmbeddedSiteDetailsMap extends AbstractMap {
  constructor(props) {
    super(props);

    Object.assign(this.state, {
      loading: true,
      boxExpanded: {},
      boxLinks: [],
      popoverPosition: null,
      layers: [],
      linksMap: {},
      hasUnassignedDevices: false,
      isSiteDialogOpen: false
    });
  }

  componentDidMount() {
    this.fetchTopology();
  }

  componentDidUpdate(prevProps, prevState) {
    const { site } = this.props;

    if (prevProps.site !== site) {
      this.fetchTopology();
    } else {
      super.componentDidUpdate(prevProps, prevState);
    }
  }

  fetchTopology() {
    const { site, $hybridMap } = this.props;

    this.setState({ loading: true });

    return $hybridMap
      .getTopologyDataForSite(site.id)
      .then((topology) => {
        const { layers, linksMap, hasUnassignedDevices } = setupLayers(site, topology);
        this.setState({ topology, loading: false, layers, linksMap, hasUnassignedDevices });
      })
      .catch((e) => {
        console.error('Error loading topology', e);
        this.setState({ loading: false });
      });
  }

  getSelectedLinksFor(type, selectedNode = this.state.selectedNode) {
    const { topology } = this.state;

    if (selectedNode) {
      const { value } = selectedNode;
      return topology.deviceLinks.filter(({ device1_id, device2_id }) => device1_id === value || device2_id === value);
    }

    return [];
  }

  getHighlighted(type, selectedNode = this.state.selectedNode) {
    const selectedLinks = this.getSelectedLinksFor(type, selectedNode);
    const selectedDevice = this.getSelected('device');

    return uniq(
      selectedLinks
        .flatMap(({ device1_id, device2_id }) => [device1_id, device2_id])
        .filter((id) => id && id !== selectedDevice)
    );
  }

  handleSelectNode({ type, value, force = false, nodeData }) {
    const { isEmbedded, openPopover, site } = this.props;
    const { selectedNode } = this.state;

    super.handleSelectNode({ type, value, force, nodeData });

    this.setState((prevState) => {
      if (prevState.selectedNode) {
        const { points } = this.getNodePosition(prevState.selectedNode);
        const isSameNode = isEqual(selectedNode, prevState.selectedNode);

        if (points.length > 0) {
          const [x, y] = points[0];

          openPopover({
            ...prevState.selectedNode,
            links: this.getSelectedLinksFor('device', prevState.selectedNode),
            position: { left: x, top: y },
            detail: {
              type: 'site',
              model: site
            },
            shortcutMenu: {
              selectedNode: prevState.selectedNode,
              showConnectionsCallback: this.setNodeLinks,
              isShowingConnections: prevState.nodeLinks.length > 0 && isSameNode
            },
            isEmbedded
          });
        }
      }

      return null;
    });
  }

  handleShowSiteDialog = () => {
    this.setState({ isSiteDialogOpen: true });
  };

  handleCloseSiteDialog = () => {
    this.setState({ isSiteDialogOpen: false });
  };

  handleSiteSave = () => {
    const { site } = this.props;
    const { layers, linksMap, hasUnassignedDevices } = setupLayers(site, this.state.topology);
    this.setState({ isSiteDialogOpen: false, layers, linksMap, hasUnassignedDevices });
  };

  renderMap() {
    const { width, site, ...rest } = this.props;
    const { loading, topology, layers, linksMap, isSiteDialogOpen } = this.state;

    if (loading) {
      return <Spinner mt={100} />;
    }

    if (!site || !topology) {
      return <EmptyState icon="map-marker" title="Site not found" />;
    }

    return (
      <>
        <SiteDevices
          {...rest}
          site={site}
          devices={topology.devices}
          links={topology.deviceLinks}
          layers={layers}
          linksMap={linksMap}
          selected={this.getSelected('device')}
          hovered={this.getHovered('device')}
          highlighted={this.getHighlighted('device')}
          getLinkTraffic={this.getLinkTraffic}
          onSelect={(value, data) => this.handleSelectNode({ type: 'device', value, nodeData: data })}
          onShowSiteDialog={this.handleShowSiteDialog}
          onHover={(value) => this.handleHoverNode('device', value)}
          width={width - 4}
        />
        {isSiteDialogOpen && (
          <SiteFormDialog model={site} onSave={this.handleSiteSave} onClose={this.handleCloseSiteDialog} />
        )}
      </>
    );
  }
}

export default EmbeddedSiteDetailsMap;
