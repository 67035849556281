import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { FiInfo } from 'react-icons/fi';

import { Box, CalloutOutline, Flex, Icon, Suspense, Text } from 'core/components';
import { Field, formConsumer, Select, Switch } from 'core/form';

@inject('$auth', '$rbac')
@formConsumer
class UserPermissions extends Component {
  getRoleOptions() {
    const { $auth, model } = this.props;

    const modelUserLevel = model.get('user_level');
    let roles = [
      {
        value: 'Administrator',
        label: 'Administrator'
      },
      {
        value: 'Member',
        label: 'Member'
      }
    ];

    // if authenticated user is superAdmin, let them create more superAdmins
    if ($auth.isSuperAdministrator) {
      roles.unshift({
        value: 'Super Administrator',
        label: 'Super Administrator'
      });

      // else if authenticated user is NOT superAdmin, and they are looking at a superAdmin, they can't de-level.
    } else if (modelUserLevel.toString() === '2') {
      roles = [
        {
          value: 'Super Administrator',
          label: 'Super Administrator'
        }
      ];
    }

    return roles;
  }

  onRoleChange = (field, value) => {
    const { model, onUserLevelChange } = this.props;
    if (model.isNew) {
      onUserLevelChange(value);
    }
  };

  render() {
    const { $auth, model, loading } = this.props;

    return (
      <Suspense loading={loading}>
        {!model.isNew && (
          <CalloutOutline intent="primary" mb={2}>
            <Flex alignItems="center">
              <Icon icon={FiInfo} iconSize={30} mr="8px" color="primary" />
              <Box flex="1 1 auto">
                <Text fontWeight="heavy">Note: </Text>
                <Text>Changes will take effect on user&apos;s next login.</Text>
              </Box>
            </Flex>
          </CalloutOutline>
        )}

        <Field name="role" options={this.getRoleOptions()} mb={2} large onChange={this.onRoleChange}>
          <Select width={180} />
        </Field>

        {$auth.isSuperAdministrator && (
          <>
            <Field name="settings.canCancelRawFlow" mb={2} large>
              <Switch switchLabel="Cancel Queries in Forensic Viewer" />
            </Field>
          </>
        )}
      </Suspense>
    );
  }
}

export default UserPermissions;
