export default {
  message: 'Enter a number',
  fn: (value) => {
    let numbers = value;
    if (!Array.isArray(value)) {
      numbers = value.split(',');
    }

    return numbers.every((num) => parseInt(num.trim()).toString() === num.trim());
  }
};
