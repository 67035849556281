import React from 'react';

import { FlexColumn, Box, Heading, Flex } from 'core/components';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import ViewInExplorerButton from 'app/components/dataviews/tools/ViewInExplorerButton';

import SidebarItem from '../../SidebarItem';
import CloudMetadata from '../../CloudMetadata';

function getPrimaryQuery(vHubName, isIngress = true) {
  return {
    bucket: 'Left +Y Axis',
    all_devices: false,
    aggregateTypes: ['avg_bits_per_sec', 'p95th_bits_per_sec', 'max_bits_per_sec'],
    aggregateThresholds: {
      avg_bits_per_sec: 0,
      p95th_bits_per_sec: 0,
      max_bits_per_sec: 0
    },
    dimension: ['ktsubtype__azure_subnet__STR13', 'ktsubtype__azure_subnet__STR14'],
    customAsGroups: false,
    device_name: [],
    fastData: 'Auto',
    device_types: ['azure_subnet'],
    hostname_lookup: false,
    lookback_seconds: 3600,
    metric: [],
    secondaryTopxSeparate: false,
    secondaryTopxMirrored: true,
    show_overlay: false,
    show_total_overlay: false,
    starting_time: null,
    ending_time: null,
    viz_type: 'stackedArea',
    overlay_day: -7,
    aggregates: [
      {
        value: 'avg_bits_per_sec',
        column: 'f_sum_both_bytes',
        fn: 'average',
        label: 'Bits/s Sampled at Ingress + Egress Average',
        unit: 'bytes',
        group: 'Bits/s Sampled at Ingress + Egress',
        origLabel: 'Average',
        sample_rate: 1,
        raw: true,
        name: 'avg_bits_per_sec'
      },
      {
        value: 'p95th_bits_per_sec',
        column: 'f_sum_both_bytes',
        fn: 'percentile',
        label: 'Bits/s Sampled at Ingress + Egress 95th Percentile',
        rank: 95,
        unit: 'bytes',
        group: 'Bits/s Sampled at Ingress + Egress',
        origLabel: '95th Percentile',
        sample_rate: 1,
        name: 'p95th_bits_per_sec'
      },
      {
        value: 'max_bits_per_sec',
        column: 'f_sum_both_bytes',
        fn: 'max',
        label: 'Bits/s Sampled at Ingress + Egress Max',
        unit: 'bytes',
        group: 'Bits/s Sampled at Ingress + Egress',
        origLabel: 'Max',
        sample_rate: 1,
        name: 'max_bits_per_sec'
      }
    ],
    filters: {
      connector: 'All',
      filterGroups: [
        {
          named: false,
          connector: 'Any',
          not: false,
          filters: [
            isIngress
              ? {
                  filterField: 'ktsubtype__azure_subnet__STR13',
                  operator: '=',
                  filterValue: vHubName
                }
              : {
                  filterField: 'ktsubtype__azure_subnet__STR14',
                  operator: '=',
                  filterValue: vHubName
                }
          ]
        }
      ]
    }
  };
}

const AzureVirtualHubDetails = ({ width, popoutTitle, nodeData }) => (
  <SidebarItem
    excludeFormProps
    title={nodeData?.name}
    dialogProps={{ width }}
    fullscreenProps={{ headingOffset: -16 }}
    icon="inner-join"
    popoutTitle={popoutTitle}
  >
    <FlexColumn>
      <CloudMetadata
        node={{
          ...nodeData,
          ...nodeData.properties
        }}
        keys={[
          'sku',
          'provisioningState',
          'addressPrefix',
          'hubRoutingPreference',
          'virtualRouterIps',
          'virtualRouterAsn',
          'virtualRouterAutoScaleConfiguration.minCapacity'
        ]}
      />

      {['In', 'Out'].map((direction) => {
        const query = getPrimaryQuery(nodeData?.name, direction === 'In');
        return (
          <Box height={225} key={direction}>
            <DataViewWrapper allowCache query={query} viewProps={{ height: 200 }}>
              {({ component, footer }) => (
                <FlexColumn>
                  <Heading level={5} my="10px">
                    {`Primary ${direction} Utilization (bits/s)`}
                  </Heading>
                  <Flex flex={1} overflow="auto">
                    {component}
                    <ViewInExplorerButton query={query} position="absolute" bottom="40px" right="4px" openInNewWindow />
                  </Flex>
                  <Box pt={1}>{footer}</Box>
                </FlexColumn>
              )}
            </DataViewWrapper>
          </Box>
        );
      })}
    </FlexColumn>
  </SidebarItem>
);

export default AzureVirtualHubDetails;
