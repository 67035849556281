import ip from 'app/forms/validations/ip';

function fn(value) {
  if (value) {
    const portStripped = value.substr(0, value.lastIndexOf(':'));

    return ip.fn(value) || ip.fn(portStripped);
  }

  return true;
}

export default {
  message: 'Enter a valid IPv4 or IPv6 address. Port and/or CIDR/netmask is supported. ',
  fn
};
