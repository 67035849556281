import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Button, EmptyState, Flex, Tag, Text } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import { GCP_ENTITY_TYPES } from 'shared/hybrid/constants';
import SearchableTable from 'app/views/hybrid/maps/components/SearchableTable';
import { uriToObject } from '@kentik/ui-shared/util/map';

const { LOAD_BALANCER } = GCP_ENTITY_TYPES;

@observer
export default class BackendPoolsPanel extends React.Component {
  state = {
    isPoppedOut: false
  };

  handlePanelRefresh = (e) => {
    e.stopPropagation();
  };

  handlePopOutChange = (isPoppedOut) => {
    this.setState({ isPoppedOut });
  };

  getBackendData = (backendResources) => {
    const backEndData = [];
    for (const be of backendResources) {
      const {
        nics,
        zone: zoneUri,
        IPForwarding,
        instanceName,
        instanceStatus,
        tags,
        scheduling: { automaticRestart, onHostMaintenance, preemptible, provisioningModel }
      } = be;
      const { zone } = uriToObject(zoneUri);
      for (const { accessConfigs, primaryInternalIp, ipStackType } of nics) {
        for (const { externalIp, natName, natType, networkTier } of accessConfigs) {
          backEndData.push({
            instanceName,
            primaryInternalIp,
            externalIp,
            IPForwarding,
            ipStackType,
            instanceStatus,
            natName,
            natType,
            networkTier,
            automaticRestart,
            onHostMaintenance,
            preemptible,
            provisioningModel,
            tags,
            zone
          });
        }
      }
    }
    return backEndData;
  };

  get columns() {
    const { isPoppedOut } = this.state;

    const primaryInternalIpCol = {
      name: 'primaryInternalIp',
      label: 'Primary Internal Ip',
      width: 110
    };
    const externalIpCol = {
      name: 'externalIp',
      label: 'External Ip',
      width: 110
    };
    const IPForwardingCol = {
      name: 'IPForwarding',
      label: 'IP Forwarding',
      width: 75,
      renderer: ({ value }) => (value ? value.toString() : 'false')
    };
    const ipStackTypeCol = {
      name: 'ipStackType',
      label: 'Ip Stack Type',
      width: 85
    };
    const instanceStatusCol = {
      name: 'instanceStatus',
      label: 'Instance Status',
      width: 75
    };
    const natNameCol = {
      name: 'natName',
      label: 'Nat Name',
      width: 120
    };

    const natTypeCol = {
      name: 'natType',
      label: 'Nat Type',
      width: 150
    };
    const networkTierCol = {
      name: 'networkTier',
      label: 'Network Tier',
      width: 75
    };
    const automaticRestartCol = {
      name: 'automaticRestart',
      label: 'Automatic Restart',
      width: 75
    };
    const onHostMaintenanceCol = {
      name: 'onHostMaintenance',
      label: 'On Host Maintenance',
      width: 110
    };
    const preemptibleCol = {
      name: 'preemptible',
      label: 'Preemptible',
      width: 75,
      renderer: ({ value }) => (value ? value.toString() : 'false')
    };

    const provisioningModelCol = {
      name: 'provisioningModel',
      label: 'Provisioning Model',
      width: 115
    };

    const zoneCol = {
      name: 'zone',
      label: 'Zone',
      width: 150,
      renderer: ({ value }) => (value ? value.toString() : '--')
    };

    const defaultCols = [primaryInternalIpCol, externalIpCol, natNameCol];

    const poppedOutColumns = [
      primaryInternalIpCol,
      externalIpCol,
      IPForwardingCol,
      ipStackTypeCol,
      instanceStatusCol,
      natNameCol,
      natTypeCol,
      networkTierCol,
      automaticRestartCol,
      onHostMaintenanceCol,
      preemptibleCol,
      provisioningModelCol,
      zoneCol
    ];

    return isPoppedOut ? poppedOutColumns : defaultCols;
  }

  groupSummary = ({ groupKey, group }) => {
    // this will probably change once we start getting network endpoint group data
    // i.e. backend resource could be instance or network endpoint
    const label = groupKey === 'undefined' ? 'Ungrouped' : `Instance: ${groupKey}`;

    // at present we don't have stats for load distribution from GCP metadata
    // once healthChecks are gotten, we might be able to show this (?)
    // a la src/app/views/hybrid/maps/components/popovers/azure/loadBalancer/BackendPoolsPanel.js
    return (
      <Flex alignItems="center">
        <Tag mr={1} minimal>
          {group.length}
        </Tag>
        <Box>
          <Text as="div">{label}</Text>
        </Box>
      </Flex>
    );
  };

  render() {
    const { cloudProvider, width, popoutTitle, backendResources } = this.props;
    const backEndData = this.getBackendData(backendResources);

    return (
      <SidebarItem
        excludeFormProps
        title="Backend Pools"
        dialogProps={{ width: width * 2 }}
        popoutTitle={popoutTitle}
        navigationButtons={<Button icon="refresh" color="success" onClick={this.handlePanelRefresh} small minimal />}
        onPopOutChange={this.handlePopOutChange}
        filledIconTag={false}
        icon={<CloudIcon cloudProvider={cloudProvider} entity={LOAD_BALANCER} style={{ marginTop: '3px' }} />}
      >
        <SearchableTable
          height={240}
          loading={false}
          collectionOptions={{ groupBy: 'instanceName' }}
          columns={this.columns}
          data={Object.values(backEndData) || []}
          stickyHeader
          stickyGroups
          groupSummary={this.groupSummary}
          emptyState={
            <EmptyState
              title="No Results"
              icon={<CloudIcon cloudProvider={cloudProvider} entity={LOAD_BALANCER} iconSize={48} />}
            />
          }
        />
      </SidebarItem>
    );
  }
}
