import React, { Component } from 'react';
import { Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import { Button, Checkbox, Flex, FlexColumn, Popover } from 'core/components/index';
import Box from 'core/components/Box';
import { Search } from 'core/components/table';
import styled from 'styled-components';

const MultiSelectWrapper = styled(FlexColumn)`
  .bp4-control {
    display: flex;
    align-items: center;

    &.bp4-checkbox .bp4-control-indicator {
      margin-top: 0px;
    }
  }
`;

@observer
export default class MultiSelectMenu extends Component {
  state = {
    isOpen: false
  };

  componentWillUnmount() {
    const { collection } = this.props;
    if (collection && collection.models.length) {
      collection.clearFilters();
    }
  }

  handleInteraction = (nextOpenState) => {
    this.setState({ isOpen: nextOpenState });
  };

  handleCancel = () => {
    const { collection } = this.props;
    collection.clearSelection();
    collection.clearFilters();
    this.setState({ isOpen: false });
  };

  handleApply = () => {
    const { collection, onApply } = this.props;

    if (onApply) {
      onApply(collection.selected);
    }
    this.handleCancel();
  };

  onSearchChange = (e) => {
    const { collection } = this.props;
    collection.filter(e.target.value);
  };

  renderItem = (model) => {
    const { blackList, itemRenderer, labelKey } = this.props;
    if (blackList.map((item) => item.id).includes(model.id)) {
      return null;
    }

    const label = itemRenderer ? itemRenderer(model) : model.get(labelKey);
    return (
      <Checkbox
        key={model.id}
        checked={model.isSelected}
        label={label}
        onChange={() => model.select({ multi: true })}
        mb={1}
      />
    );
  };

  render() {
    const { collection, buttonText, buttonIcon, buttonProps, popoverProps } = this.props;
    const { isOpen } = this.state;

    return (
      <Popover
        isOpen={isOpen}
        onInteraction={this.handleInteraction}
        {...popoverProps}
        content={
          <MultiSelectWrapper justifyContent="space-between" p={2} maxHeight={450} overflow="hidden">
            <FlexColumn flex={1} overflow="hidden">
              <Search collection={collection} onChange={this.onSearchChange} mb={1} p={1} />
              <Box flex={1} overflow="auto">
                {collection.map((item) => this.renderItem(item))}
              </Box>
            </FlexColumn>
            <Flex justifyContent="space-between" mt={2}>
              <Box>
                <Button text="Cancel" onClick={this.handleCancel} />
              </Box>
              <Box>
                <Button text="Apply" intent={Intent.PRIMARY} onClick={this.handleApply} />
              </Box>
            </Flex>
          </MultiSelectWrapper>
        }
      >
        <Button text={buttonText} icon={buttonIcon} {...buttonProps} />
      </Popover>
    );
  }
}
