import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Select } from 'core/form';
import storeLoader from 'app/stores/storeLoader';
import AdminFilterSidebar from 'app/components/admin/AdminFilterSidebar';
import KentikLogo from 'app/components/KentikLogo';
import { LabelMultiSelect } from 'app/components/labels/LabelMultiSelect';

const typeOptionsMap = {
  savedView: {
    value: 'Saved View',
    matcher: 'Saved View',
    label: 'Saved View',
    icon: 'grouped-bar-chart',
    iconColor: 'rgb(92, 112, 128)'
  },
  stackedArea: {
    value: 'stackedArea',
    matcher: 'Stacked Area Chart',
    label: 'Stacked Area Chart',
    icon: 'timeline-area-chart',
    iconColor: 'rgb(92, 112, 128)',
    style: { marginLeft: 22 }
  },
  stackedAreaHundredPercent: {
    value: 'stackedAreaHundredPercent',
    matcher: '100% Stacked Area Chart',
    label: '100% Stacked Area Chart',
    icon: 'full-stacked-chart',
    iconColor: 'rgb(92, 112, 128)',
    style: { marginLeft: 22 }
  },
  line: {
    value: 'line',
    matcher: 'Line Chart',
    label: 'Line Chart',
    icon: 'timeline-line-chart',
    iconColor: 'rgb(92, 112, 128)',
    style: { marginLeft: 22 }
  },
  stackedBar: {
    value: 'stackedBar',
    matcher: 'Stacked Column Chart',
    label: 'Stacked Column Chart',
    icon: 'stacked-chart',
    iconColor: 'rgb(92, 112, 128)',
    style: { marginLeft: 22 }
  },
  horizon: {
    value: 'horizon',
    matcher: 'Horizon Chart',
    label: 'Horizon Chart',
    icon: 'align-justify',
    iconColor: 'rgb(92, 112, 128)',
    style: { marginLeft: 22 }
  },
  bar: {
    value: 'bar',
    matcher: 'Bar Chart',
    label: 'Bar Chart',
    icon: 'horizontal-bar-chart',
    iconColor: 'rgb(92, 112, 128)',
    style: { marginLeft: 22 }
  },
  pie: {
    value: 'pie',
    matcher: 'Pie Chart',
    label: 'Pie Chart',
    icon: 'doughnut-chart',
    iconColor: 'rgb(92, 112, 128)',
    style: { marginLeft: 22 }
  },
  sankey: {
    value: 'sankey',
    matcher: 'Sankey',
    label: 'Sankey',
    icon: 'flows',
    iconColor: 'rgb(92, 112, 128)',
    style: { marginLeft: 22 }
  },
  sunburst: {
    value: 'sunburst',
    matcher: 'Sunburst',
    label: 'Sunburst',
    icon: 'flash',
    iconColor: 'rgb(92, 112, 128)',
    style: { marginLeft: 22 }
  },
  matrix: {
    value: 'matrix',
    matcher: 'Matrix',
    label: 'Matrix',
    icon: 'heat-grid',
    iconColor: 'rgb(92, 112, 128)',
    style: { marginLeft: 22 }
  },
  gauge: {
    value: 'gauge',
    matcher: 'Gauge',
    label: 'Gauge',
    icon: 'selection',
    iconColor: 'rgb(92, 112, 128)',
    style: { marginLeft: 22 }
  },
  table: {
    value: 'table',
    matcher: 'Table',
    label: 'Table',
    icon: 'th-list',
    iconColor: 'rgb(92, 112, 128)',
    style: { marginLeft: 22 }
  },
  geoHeatMap: {
    value: 'geoHeatMap',
    matcher: 'Geo HeatMap',
    label: 'Geo HeatMap',
    icon: 'globe',
    iconColor: 'rgb(92, 112, 128)',
    style: { marginLeft: 22 }
  },
  dashboard: {
    value: 'Dashboard',
    matcher: 'Dashboard',
    label: 'Dashboard',
    icon: 'new-grid-item',
    iconColor: 'rgb(92, 112, 128)'
  },
  guidedMode: {
    value: 'Guided Mode',
    matcher: 'Guided Mode',
    label: 'Guided Mode',
    style: { marginLeft: 22 }
  }
};
@inject('$labels', '$users')
@storeLoader('$dashboards.collection', '$dashboards.categories', '$savedViews')
@withRouter
@observer
class LibraryFilterSidebar extends Component {
  get defaultValues() {
    return {
      type: [],
      sharing: [],
      subscribed: false,
      owner: []
    };
  }

  getLabelOptions() {
    const { $labels } = this.props;
    const labelSet = new Set(
      $labels
        .getLabels('dashboard')
        .concat($labels.getLabels('saved_view'))
        .map((label) => {
          const { option } = label;
          if (label.isPreset) {
            option.isPreset = true;
            option.bg = '#e05420';
            option.color = '#ffffff';
          }
          return option;
        })
    );
    labelSet.add({ value: '[NONE]', label: 'None', showColor: false });
    return Array.from(labelSet).sort((a, b) => {
      if (a.value === '[NONE]') {
        return -1;
      }

      // push Presets to the bottom
      if (a.isPreset && !b.isPreset) {
        return 1;
      }

      if (!a.isPreset && b.isPreset) {
        return -1;
      }

      return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
    });
  }

  getOwnerOptions() {
    const { $users, collection } = this.props;
    const owners = new Set(collection.unfiltered.map((model) => model.get('user_id')));
    return $users.collection.userSelectOptions.filter(({ value }) => owners.has(value));
  }

  getTypeOptions() {
    const { collection } = this.props;
    const options = [];

    const allViews = collection.get();
    if (allViews.some((view) => view.type === 'Dashboard')) {
      options.push(typeOptionsMap.dashboard);
    }
    if (allViews.some((view) => view.type === 'Dashboard' && view.isParametric)) {
      options.push(typeOptionsMap.guidedMode);
    }

    if (allViews.some((view) => view.type === 'Saved View')) {
      options.push(typeOptionsMap.savedView);
    }

    const savedViewTypes = new Set();
    allViews.forEach((view) => {
      if (view.type === 'Saved View' && view.vizType) {
        savedViewTypes.add(view.vizType);
      }
    });

    Array.from(savedViewTypes)
      .sort()
      .forEach((type) => {
        options.push(typeOptionsMap[type]);
      });

    return options;
  }

  get filterFields() {
    const { $labels } = this.props;

    const filterFields = {
      isFavorite: {
        name: 'isFavorite',
        allowMultiSelect: true,
        showCheckboxes: true,
        filter: (model, value) => (value ? model.isFavorite : true),
        options: [
          {
            value: true,
            matcher: 'Favorite',
            label: 'Favorites',
            icon: 'star',
            iconColor: 'yellow'
          }
        ]
      },

      subscribed: {
        name: 'subscribed',
        allowMultiSelect: true,
        showCheckboxes: true,
        filter: (model, values) => values.includes(model.get('numSubscribers') > 0),
        options: [
          {
            value: true,
            matcher: 'Subscribed',
            label: 'Subscribed',
            icon: 'envelope'
          }
        ]
      },
      isTrending: {
        name: 'isTrending',
        allowMultiSelect: true,
        showCheckboxes: true,
        filter: (model, value) => (value ? model.isTrending : true),
        options: [
          {
            value: true,
            matcher: 'Trending',
            label: 'Trending',
            icon: 'flame',
            iconColor: 'warning'
          }
        ]
      },

      labelMultiSelect: {
        name: 'labelMultiSelect',
        label: 'Labels',
        placeholder: 'Filter by labels...',
        allowMultiSelect: true,
        defaultValue: [],
        filter: (model, values) =>
          $labels.labelMultiSelectModelFilter(model.labels, values) ||
          (values.includes('[NONE]') && model.labels.length === 0),
        options: this.getLabelOptions(),
        customField: <LabelMultiSelect showSelectAll />
      },

      shareLevel: {
        name: 'shareLevel',
        label: 'Sharing',
        allowMultiSelect: true,
        showCheckboxes: true,
        filter: (model, values) => values.includes(model.shareLevel),
        options: [
          {
            value: 'Private',
            matcher: 'Private',
            label: 'Private',
            icon: 'eye-open',
            iconColor: 'rgb(92, 112, 128)'
          },
          {
            value: 'Shared',
            matcher: 'Shared',
            label: 'Shared',
            icon: 'people',
            iconColor: 'rgb(92, 112, 128)'
          },
          {
            value: 'Preset',
            matcher: 'Preset',
            label: 'Kentik Preset',
            icon: <KentikLogo onlyMark alt="Kentik" style={{ width: 18 }} />
          }
        ]
      },

      owner: {
        name: 'user_id',
        label: 'Owner',
        placeholder: 'Filter by owner...',
        allowMultiSelect: true,
        defaultValue: [],
        options: this.getOwnerOptions(),
        // Saved Views stores user_id as an int but its a string elsewhere . . .
        filter: (model, values) => values.includes(model.get('user_id')?.toString()),
        customField: <Select fill multi keepOpen showFilter toggle />
      },

      type: {
        name: 'type',
        label: 'Type',
        placeholder: 'Filter by type...',
        allowMultiSelect: true,
        filter: (model, values) =>
          values.includes(model.type) ||
          (values.includes('Guided Mode') && model.get('parametric')) ||
          values.includes(model.vizType),
        options: this.getTypeOptions()
      }
    };

    return Object.values(filterFields);
  }

  render() {
    const { $dashboards, $savedViews, collection, ...rest } = this.props;

    return (
      <AdminFilterSidebar
        {...rest}
        useDiscreteFilters
        collection={collection}
        filterFields={this.filterFields}
        resetValues={this.defaultValues}
      />
    );
  }
}

export default LibraryFilterSidebar;
