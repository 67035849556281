const deviceFilters = {
  connector: 'All',
  filterGroups: [
    {
      connector: 'All',
      filters: [
        // all devices
        {
          filterField: 'km_device_id',
          operator: '<>',
          filterValue: '0'
        }
      ]
    }
  ]
};

export const DEFAULT_METRICS_EXPLORER_QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/interfaces/counters',
    dimensions: ['device_name', 'name'],
    metrics: [
      {
        name: 'in-utilization',
        type: 'gauge'
      },
      {
        name: 'out-utilization',
        type: 'gauge'
      }
    ],
    range: {
      lookback: 'PT3600S'
    },
    window: {
      size: 0,
      fn: {
        'in-utilization': 'avg',
        'out-utilization': 'avg'
      }
    },
    rollups: {
      'last_in-utilization': {
        metric: 'in-utilization',
        aggregate: 'last'
      },
      'last_out-utilization': {
        metric: 'out-utilization',
        aggregate: 'last'
      }
    },
    sort: [
      {
        name: 'last_in-utilization',
        direction: 'desc'
      },
      {
        name: 'last_out-utilization',
        direction: 'desc'
      }
    ],
    limit: 100,
    includeTimeseries: 20,
    viz: {
      type: 'line',
      rollup: 'avg_in-utilization',
      metric: 'in-utilization'
    }
  }
};

export const ALL_INTERFACES_ERRORS_DISCARDS_QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/interfaces/counters',
    dimensions: ['device_name', 'name', 'ifindex'],
    metrics: [
      {
        name: 'in-errors',
        type: 'gauge'
      },
      {
        name: 'out-errors',
        type: 'gauge'
      },
      {
        name: 'in-discards',
        type: 'gauge'
      },
      {
        name: 'out-discards',
        type: 'gauge'
      }
    ],
    range: {
      lookback: 'PT3600S'
    },
    window: {
      size: 3600,
      fn: {
        'in-errors': 'sum',
        'out-errors': 'sum',
        'in-discards': 'sum',
        'out-discards': 'sum'
      }
    },
    rollups: {
      IfInErrors: {
        metric: 'in-errors',
        aggregate: 'last'
      },
      IfOutErrors: {
        metric: 'out-errors',
        aggregate: 'last'
      },
      IfInDiscards: {
        metric: 'in-discards',
        aggregate: 'last'
      },
      IfOutDiscards: {
        metric: 'out-discards',
        aggregate: 'last'
      }
    },
    sort: [
      {
        name: 'IfInErrors',
        direction: 'desc'
      },
      {
        name: 'IfOutErrors',
        direction: 'desc'
      },
      {
        name: 'IfInDiscards',
        direction: 'desc'
      },
      {
        name: 'IfOutDiscards',
        direction: 'desc'
      }
    ],
    limit: 10000,
    includeTimeseries: 0,
    viz: {
      type: 'line'
    }
  }
};

// Split from utilization query due to issues with metric mask (https://github.com/kentik/recon/issues/1266#issuecomment-2274247043)
export const ALL_INTERFACES_BITRATE_QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/interfaces/counters',
    dimensions: ['device_name', 'name', 'ifindex'],
    metrics: [
      {
        name: 'in-bit-rate',
        type: 'gauge'
      },
      {
        name: 'out-bit-rate',
        type: 'gauge'
      }
    ],
    range: {
      lookback: 'PT20M'
    },
    window: {
      size: 0,
      fn: {
        'in-bit-rate': 'avg',
        'out-bit-rate': 'avg'
      }
    },
    rollups: {
      IfInBitRate: {
        metric: 'in-bit-rate',
        aggregate: 'last'
      },
      IfOutBitRate: {
        metric: 'out-bit-rate',
        aggregate: 'last'
      }
    },
    sort: [
      {
        name: 'IfInBitRate',
        direction: 'desc'
      },
      {
        name: 'IfOutBitRate',
        direction: 'desc'
      }
    ],
    limit: 10000,
    includeTimeseries: 0,
    viz: {
      type: 'table'
    }
  }
};

export const ALL_INTERFACES_UTILIZATION_QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/interfaces/counters',
    dimensions: ['device_name', 'name', 'ifindex'],
    metrics: [
      {
        name: 'in-utilization',
        type: 'gauge'
      },
      {
        name: 'out-utilization',
        type: 'gauge'
      }
    ],
    range: {
      lookback: 'P1D'
    },
    window: {
      size: 3600,
      fn: {
        'in-utilization': 'last',
        'out-utilization': 'last'
      }
    },
    rollups: {
      IfInUtilization: {
        metric: 'in-utilization',
        aggregate: 'last'
      },
      IfOutUtilization: {
        metric: 'out-utilization',
        aggregate: 'last'
      }
    },
    sort: [
      {
        name: 'IfInUtilization',
        direction: 'desc'
      },
      {
        name: 'IfOutUtilization',
        direction: 'desc'
      }
    ],
    limit: 10000,
    includeTimeseries: 10000,
    viz: {
      type: 'line'
    }
  }
};

export const ALL_INTERFACES_FLOW_SNMP_QUERY = {
  aggregateTypes: [
    'avg_ktappprotocol__snmp__INT05', // utilization in
    'avg_ktappprotocol__snmp__INT06', // utilization out
    'avg_ktappprotocol__snmp__INT00', // input errors
    'avg_ktappprotocol__snmp__INT01', // output errors
    'avg_ktappprotocol__snmp__INT64_04', // input discards
    'avg_ktappprotocol__snmp__INT64_05' // output discards
  ],
  fastData: 'Full',
  lookback_seconds: 86400,
  metric: ['ktappprotocol__snmp__i_device_name', 'ktappprotocol__snmp__output_port'],
  minsPolling: 10,
  forceMinsPolling: true,
  reAggInterval: 3600,
  reAggFn: 'avg',
  outsort: 'avg_ktappprotocol__snmp__INT05',
  secondaryOutsort: 'avg_ktappprotocol__snmp__INT06',
  show_overlay: false,
  show_total_overlay: false,
  forceDepth: true,
  depth: 10000,
  topx: 10000,
  viz_type: 'line'
};

export const DEVICE_MEMORY_QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/system/memory',
    dimensions: ['km_device_id'],
    metrics: [{ name: 'utilization', type: 'gauge' }],
    merge: {
      aggregate: 'avg',
      dimensions: ['index']
    },
    range: {
      lookback: 'PT20M'
    },
    rollups: {
      last_memory_util: { aggregate: 'last', metric: 'utilization' }
    },
    filters: deviceFilters,
    sort: [{ name: 'km_device_id', direction: 'asc' }],
    window: {
      size: 0,
      fn: {
        utilization: 'last'
      }
    },
    limit: 1000,
    includeTimeseries: 0
  }
};

export const DEVICE_MEMORY_QUERY_SERIES = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/system/memory',
    dimensions: ['km_device_id'],
    metrics: [{ name: 'utilization', type: 'gauge' }],
    merge: {
      aggregate: 'avg',
      dimensions: ['index']
    },
    range: {
      lookback: 'PT86400S'
    },
    rollups: {
      last_memory_util: { aggregate: 'last', metric: 'utilization' }
    },
    filters: deviceFilters,
    sort: [{ name: 'km_device_id', direction: 'asc' }],
    window: {
      size: 1800,
      fn: {
        utilization: 'last'
      }
    },
    limit: 10000,
    includeTimeseries: 10000,
    viz: {
      metric: 'utilization'
    }
  }
};

export const DEVICE_CPU_QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/system/cpus',
    dimensions: ['km_device_id'],
    metrics: [{ name: 'total/instant', type: 'gauge' }],
    merge: {
      aggregate: 'avg',
      dimensions: ['cpu_index']
    },
    range: {
      lookback: 'PT20M'
    },
    rollups: {
      last_cpu_util: { aggregate: 'last', metric: 'total/instant' }
    },
    filters: deviceFilters,
    sort: [{ name: 'km_device_id', direction: 'asc' }],
    window: {
      size: 0,
      fn: {
        'total/instant': 'last'
      }
    },
    limit: 1000,
    includeTimeseries: 0
  }
};

export const DEVICE_CPU_QUERY_SERIES = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/system/cpus',
    dimensions: ['km_device_id'],
    metrics: [{ name: 'total/instant', type: 'gauge' }],
    merge: {
      aggregate: 'avg',
      dimensions: ['cpu_index']
    },
    range: {
      lookback: 'PT86400S'
    },
    rollups: {
      last_cpu_util: { aggregate: 'last', metric: 'total/instant' }
    },
    filters: deviceFilters,
    sort: [{ name: 'km_device_id', direction: 'asc' }],
    window: {
      size: 1800,
      fn: {
        'total/instant': 'last'
      }
    },
    limit: 10000,
    includeTimeseries: 10000,
    viz: {
      metric: 'total/instant'
    }
  }
};

export const DEVICE_STATUS_QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/system',
    dimensions: ['device_name'],
    metrics: [
      {
        name: 'available',
        type: 'gauge'
      }
    ],
    range: {
      lookback: 'PT15M'
    },
    rollups: {
      last_available: {
        metric: 'available',
        aggregate: 'last'
      }
    },
    sort: [
      {
        name: 'last_available',
        direction: 'desc'
      }
    ],
    limit: 10000,
    includeTimeseries: 0
  }
};

export const DEVICE_TEMPERATURE_QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/components/temperature',
    dimensions: ['km_device_id'],
    metrics: [
      {
        name: 'instant',
        type: 'gauge'
      }
    ],
    range: {
      lookback: 'PT20M'
    },
    merge: {
      dimensions: ['index'],
      aggregate: 'max'
    },
    filters: deviceFilters,
    window: {
      size: 0,
      fn: {
        instant: 'avg'
      }
    },
    rollups: {
      avg_instant: {
        metric: 'instant',
        aggregate: 'avg'
      },
      max_instant: {
        metric: 'instant',
        aggregate: 'max'
      },
      last_instant: {
        metric: 'instant',
        aggregate: 'last'
      }
    },
    sort: [
      {
        name: 'avg_instant',
        direction: 'desc'
      },
      {
        name: 'max_instant',
        direction: 'desc'
      },
      {
        name: 'last_instant',
        direction: 'desc'
      }
    ],
    limit: 1000,
    includeTimeseries: 0,
    viz: {
      type: 'area',
      rollup: '',
      metric: 'instant'
    }
  }
};

export const DEVICE_TEMPERATURE_QUERY_SERIES = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/components/temperature',
    dimensions: ['km_device_id'],
    metrics: [
      {
        name: 'instant',
        type: 'gauge'
      }
    ],
    range: {
      lookback: 'PT86400S'
    },
    merge: {
      dimensions: ['index'],
      aggregate: 'max'
    },
    filters: deviceFilters,
    window: {
      size: 1800,
      fn: {
        instant: 'avg'
      }
    },
    rollups: {
      avg_instant: {
        metric: 'instant',
        aggregate: 'avg'
      },
      max_instant: {
        metric: 'instant',
        aggregate: 'max'
      },
      last_instant: {
        metric: 'instant',
        aggregate: 'last'
      }
    },
    sort: [
      {
        name: 'avg_instant',
        direction: 'desc'
      },
      {
        name: 'max_instant',
        direction: 'desc'
      },
      {
        name: 'last_instant',
        direction: 'desc'
      }
    ],
    limit: 10000,
    includeTimeseries: 10000,
    viz: {
      type: 'area',
      rollup: '',
      metric: 'instant'
    }
  }
};

export const DEVICE_TRAFFIC_QUERY_SERIES = {
  all_devices: true,
  lookback_seconds: 86400,
  aggregateTypes: ['avg_bits_per_sec'],
  show_overlay: false,
  show_total_overlay: false,
  topx: 1000,
  depth: 1000,
  forceDepth: true,
  filters: {
    connector: 'All',
    filterGroups: [
      {
        connector: 'All',
        filters: [
          // all devices
          {
            filterField: 'i_device_id',
            operator: '<>',
            filterValue: '0'
          }
        ]
      }
    ]
  },
  metric: ['i_device_id'],
  minsPolling: 60,
  forceMinsPolling: true,
  reAggInterval: 'auto',
  reAggFn: 'none'
};

export const DEVICE_TRAFFIC_QUERY_BY_IDS = ({ ids, limit = 500 }) => ({
  all_devices: true,
  lookback_seconds: 86400,
  aggregateTypes: ['avg_bits_per_sec'],
  show_overlay: false,
  show_total_overlay: false,
  topx: limit,
  depth: limit,
  forceDepth: true,
  filters: {
    connector: 'All',
    filterGroups: [
      {
        connector: 'all',
        filters: [
          {
            filterField: 'i_device_id',
            operator: '=',
            filterValue: ids.join(',')
          }
        ]
      }
    ]
  },
  metric: ['i_device_id'],
  minsPolling: 60,
  forceMinsPolling: true,
  reAggInterval: 'auto',
  reAggFn: 'none'
});

export const COMPANY_MPS_QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/kentik/nms/usage',
    dimensions: [],
    merge: {
      dimensions: ['device_id', 'agent_id', 'server'],
      aggregate: 'sum'
    },
    metrics: [
      {
        name: 'metricsPerSec',
        type: 'gauge'
      }
    ],
    window: {
      fn: {
        metricsPerSec: 'avg'
      },
      size: 0
    },
    range: {
      lookback: 'PT1H'
    },
    rollups: {
      avg_metricsPerSec: {
        metric: 'metricsPerSec',
        aggregate: 'avg'
      },
      min_metricsPerSec: {
        metric: 'metricsPerSec',
        aggregate: 'min'
      },
      max_metricsPerSec: {
        metric: 'metricsPerSec',
        aggregate: 'max'
      }
    },
    sort: [
      {
        name: 'avg_metricsPerSec',
        direction: 'desc'
      }
    ],
    limit: 100,
    includeTimeseries: 0,
    viz: {
      type: 'line',
      rollup: '',
      metric: 'metricsPerSec'
    }
  }
};

export const COMPANY_MPS_HISTORY_QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/kentik/nms/usage',
    dimensions: [],
    merge: {
      dimensions: ['device_id', 'agent_id', 'server'],
      aggregate: 'sum'
    },
    metrics: [
      {
        name: 'metricsPerSec',
        type: 'gauge'
      }
    ],
    range: {
      lookback: 'P30D'
    },
    rollups: {
      avg_metricsPerSec: {
        metric: 'metricsPerSec',
        aggregate: 'avg'
      },
      min_metricsPerSec: {
        metric: 'metricsPerSec',
        aggregate: 'min'
      },
      max_metricsPerSec: {
        metric: 'metricsPerSec',
        aggregate: 'max'
      }
    },
    sort: [
      {
        name: 'avg_metricsPerSec',
        direction: 'desc'
      }
    ],
    limit: 100,
    includeTimeseries: 10,
    window: {
      size: 3600,
      fn: {
        metricsPerSec: 'avg'
      }
    },
    viz: {
      type: 'line',
      rollup: '',
      metric: 'metricsPerSec'
    }
  }
};

export const DEVICE_BGP_METRICS_QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/protocols/bgp/neighbors',
    dimensions: ['device_name'],
    metrics: [
      {
        name: 'messages/received/TOTAL',
        type: 'gauge'
      },
      {
        name: 'messages/sent/TOTAL',
        type: 'gauge'
      },
      {
        name: 'established-transitions',
        type: 'gauge'
      },
      {
        name: 'messages/received/UPDATE',
        type: 'gauge'
      },
      {
        name: 'messages/sent/UPDATE',
        type: 'gauge'
      }
    ],
    range: {
      lookback: 'PT86400S'
    },
    window: {
      size: 0,
      fn: {
        'messages/received/TOTAL': 'last',
        'messages/sent/TOTAL': 'last',
        'established-transitions': 'last',
        'messages/received/UPDATE': 'last',
        'messages/sent/UPDATE': 'last'
      }
    },
    rollups: {
      'messages-received-total': {
        metric: 'messages/received/TOTAL',
        aggregate: 'last'
      },
      'messages-sent-total': {
        metric: 'messages/sent/TOTAL',
        aggregate: 'last'
      },
      'state-transitions': {
        metric: 'established-transitions',
        aggregate: 'last'
      },
      'messages-received-update': {
        metric: 'messages/received/UPDATE',
        aggregate: 'last'
      },
      'messages-sent-update': {
        metric: 'messages/sent/UPDATE',
        aggregate: 'last'
      }
    },
    sort: [
      {
        name: 'messages-received-total',
        direction: 'desc'
      }
    ],
    limit: 10,
    includeTimeseries: 10,
    viz: {
      type: 'line',
      metric: 'messages/received/TOTAL'
    }
  }
};
