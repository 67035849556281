import React from 'react';
import classNames from 'classnames';
import { Box } from 'core/components';
import CircleGroup from './CircleGroup';

const MinifiedCloudItem = ({ isMuted, icon, isMinified, children, ...rest }) => (
  <Box zIndex="1" {...rest}>
    {isMinified && (
      <CircleGroup
        className={classNames('hybrid-map-selectable-node', {
          muted: isMuted
        })}
      >
        {icon}
      </CircleGroup>
    )}
    {!isMinified && children}
  </Box>
);

MinifiedCloudItem.defaultProps = {
  isMuted: false,
  isMinified: true
};

export default MinifiedCloudItem;
