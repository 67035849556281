import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import AdminFilterSidebar from 'app/components/admin/AdminFilterSidebar';
import { Box, FlexColumn } from 'core/components';
import UserAssignedRolesForm from 'app/views/settings/users/UserAssignedRolesForm';
import { partition } from 'lodash';

// import UserAssignedRolesForm from 'app/views/settings/users/UserAssignedRolesForm';

@inject('$auth', '$rbac')
@observer
class UserRolesFilterSidebar extends Component {
  get filterTypeHash() {
    return {
      type: {
        label: 'Type',
        allowMultiSelect: true,
        showCheckboxes: true,
        filter: (model, values) => values.includes(model.get('group'))
      },
      enabled: {
        label: 'Status',
        allowMultiSelect: true,
        showCheckboxes: true,
        filter: (model, values) => values.includes(model.get('isEnabled'))
      },
      role: {
        label: 'Assigned Roles',
        allowMultiSelect: true
      }
    };
  }

  get typeOptions() {
    const { permissionCollection } = this.props;
    const optionsSet = new Set();

    permissionCollection.get().forEach((model) => {
      if (model.get('isEnabled')) {
        optionsSet.add(model.get('group'));
      }
    });
    return [...optionsSet].map((value) => ({ value, label: value }));
  }

  get roleOptions() {
    const { roleCollection } = this.props;
    return roleCollection.get().map((model) => ({
      label: model.get('role_name'),
      value: model.id
    }));
  }

  get enabledOptions() {
    return [
      { value: true, label: 'Full Access' },
      { value: false, label: 'Restricted' }
    ];
  }

  get roleKmrOptions() {
    const { roleCollection } = this.props;
    return roleCollection
      .filter((role) => !role.get('company_id'), { immutable: true })
      .map((model) => ({
        label: model.get('role_name'),
        value: model.id
      }));
  }

  get roleCustomOptions() {
    const { roleCollection } = this.props;
    return roleCollection
      .filter((role) => !!role.get('company_id'), { immutable: true })
      .map((model) => ({
        label: model.get('role_name'),
        value: model.id
      }));
  }

  get roleSetOptions() {
    const { roleSetCollection, $rbac } = this.props;
    return roleSetCollection.map((model) => ({
      label: model.get('name'),
      value: model.id,
      roles: $rbac.collection
        .filter((role) => model.get('roles').includes(role.id), { immutable: true })
        .map((role) => role.get('role_name'))
    }));
  }

  onFilterChange = (filterValues) => {
    const { roleCollection, onRoleChange } = this.props;

    if (filterValues.roles && filterValues.roles.length) {
      roleCollection.setDiscreteFilters([{ type: 'role', fn: (role) => filterValues.roles.includes(role.id) }]);
    } else {
      roleCollection.resetDiscreteFilters();
    }
    roleCollection.filter();

    onRoleChange();
  };

  handleAddItems = (newItems) => {
    const { roleCollection, roleSetCollection, roleSetRoleCollection, onRoleChange, $rbac } = this.props;

    const [newRoleSets, newRoles] = partition(newItems, (item) => item.isRoleSet);

    const newRoleIds = newRoles
      .map((r) => parseInt(r.id.split('|')[1]))
      .filter((roleId) => !roleCollection.get(roleId));
    const newRoleSetIds = newRoleSets
      .map((rs) => parseInt(rs.id.split('|')[1]))
      .filter((roleSetId) => !roleSetCollection.get(roleSetId));

    roleCollection.add(
      $rbac.collection
        .filter((role) => newRoleIds.includes(role.id), { immutable: true })
        .map((role) => ({ ...role.get() }))
    );

    roleSetCollection.add(
      $rbac.roleSetCollection
        .filter((role) => newRoleSetIds.includes(role.id), { immutable: true })
        .map((role) => ({ ...role.get() }))
    );

    const roleSetRoleIds = Array.from(new Set(newRoleSets.map((rs) => rs.get('roles')).flat()));
    roleSetRoleCollection.add(
      $rbac.collection
        .filter((role) => roleSetRoleIds.includes(role.id), { immutable: true })
        .map((roleModel) => ({ ...roleModel.get() }))
    );

    onRoleChange();
  };

  handleRemoveItems = ({ roles, roleSets }) => {
    const { roleCollection, roleSetCollection, roleSetRoleCollection, onRoleChange, $rbac } = this.props;
    roles.forEach((role) => roleCollection.remove(role));
    roleSets.forEach((roleSet) => roleSetCollection.remove(roleSet));

    // if none of the existing role sets have that role, remove from role set role collection
    const rolesInCollection = Array.from(new Set(roleSetCollection.map((rs) => rs.get('roles')).flat()));
    const rolesToRemove = Array.from(
      new Set(
        $rbac.roleSetCollection
          .filter((rs) => roleSets.includes(rs.id), { immutable: true })
          .map((rs) => rs.get('roles'))
          .flat()
      )
    );

    const remainingRoles = rolesToRemove.filter((role) => !rolesInCollection.includes(role));
    remainingRoles.forEach((role) => roleSetRoleCollection.remove(role));
    onRoleChange();
  };

  render() {
    const { roleCollection, roleSetCollection, permissionCollection, selectedUser, $auth } = this.props;
    const { filterTypeHash, typeOptions, roleOptions, enabledOptions } = this;

    const filterFields = [
      {
        name: 'enabled',
        options: enabledOptions,
        ...filterTypeHash.enabled
      },
      {
        name: 'type',
        options: typeOptions,
        ...filterTypeHash.type
      },
      {
        name: 'roles',
        options: roleOptions,
        ...filterTypeHash.role
      }
    ];

    return (
      <FlexColumn flex={1} overflow="auto">
        <Box mb={2}>
          <AdminFilterSidebar
            {...this.props}
            showClose={false}
            updateHashOnFilterChange={false}
            title="Filters"
            filterFields={filterFields}
            collection={permissionCollection}
            onFilterChange={this.onFilterChange}
          />
        </Box>
        {$auth.hasRbacPermissions(['rbac.role.user::assign_to_role', 'rbac.role.user::remove_from_role']) && (
          <UserAssignedRolesForm
            kmrRoles={this.roleKmrOptions}
            customRoles={this.roleCustomOptions}
            roleSets={this.roleSetOptions}
            roleCollection={roleCollection}
            roleSetCollection={roleSetCollection}
            onAddItems={this.handleAddItems}
            onRemoveItems={this.handleRemoveItems}
            selectedUser={selectedUser}
          />
        )}
      </FlexColumn>
    );
  }
}

export default UserRolesFilterSidebar;
