import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { SizeMe } from 'react-sizeme';
import { AiOutlineMenuFold } from 'react-icons/ai';
import { Redirect } from 'react-router-dom';

import { Box, Button } from 'core/components';
import Page from 'app/components/page/Page';
import withHybridTopoSettings from 'app/views/hybrid/maps/components/settingsToolbar/withHybridTopoSettings';
import { cloudProviderData } from 'app/util/utils';

import { isGoogleCloud } from '@kentik/ui-shared/util/map';
import CloudAwsMap from './maps/CloudAwsMap';
import AzureMap from './maps/azure/AzureMap';
import GCPMap from './maps/gcp/GCPMap';
import OciMap from './maps/oci/OciMap';
import HybridTopoSidebar from './maps/components/HybridTopoSidebar';
import HybridTopoSettingsToolbar from './maps/components/settingsToolbar/HybridTopoSettingsToolbar';
import withSidebarDetails from './maps/components/popovers/withSidebarDetails';

function getMapComponent(cloudProvider) {
  if (cloudProvider === 'aws') {
    return CloudAwsMap;
  }

  if (cloudProvider === 'azure') {
    return AzureMap;
  }

  if (cloudProvider === 'gcp') {
    return GCPMap;
  }

  if (cloudProvider === 'oci') {
    return OciMap;
  }

  return null;
}

@withHybridTopoSettings
@withSidebarDetails
@inject('$hybridMap')
@observer
class CloudProvider extends Component {
  static getDerivedStateFromProps(props) {
    if (!props.sidebarDetailsPanel) {
      return { drawerIsOpen: false };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      drawerIsOpen: true
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarDetailsPanel } = this.props;

    // if we're setting new details and it's not open, open it
    if (!prevState.drawerIsOpen && sidebarDetailsPanel) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ drawerIsOpen: true });
    }
  }

  handleDrawerToggle = () => this.setState(({ drawerIsOpen }) => ({ drawerIsOpen: !drawerIsOpen }));

  // quarantine 'gce' virus from spreading, convert to gcp.
  get cloudProvider() {
    const { match } = this.props;
    const { cloudProvider } = match.params;
    return isGoogleCloud(cloudProvider) ? 'gcp' : cloudProvider;
  }

  get cloudProviderName() {
    return cloudProviderData(this.cloudProvider, 'name');
  }

  render() {
    const { $hybridMap, settingsModel, sidebarSettings, saveSettings, setSidebarDetails, sidebarDetailsPanel } =
      this.props;
    const { drawerIsOpen } = this.state;
    const { cloudProvider, cloudProviderName } = this;
    const MapComponent = getMapComponent(cloudProvider);

    if (!MapComponent) {
      return <Redirect to="/v4/kentik-map" />;
    }

    return (
      <Page
        title={cloudProviderName}
        docTitle={`${cloudProviderName} Map`}
        drawerContents={<HybridTopoSidebar sidebarDetailsPanel={sidebarDetailsPanel} />}
        drawerIsOpen={drawerIsOpen}
        drawerOnClose={(e) => {
          if (e && e.key === 'Escape' && drawerIsOpen) {
            this.handleDrawerToggle();
          }
        }}
        drawerProps={{ extraOffset: 43 }}
        subnavTools={
          <Button
            text="Details"
            icon={AiOutlineMenuFold}
            ml="2px"
            active={drawerIsOpen}
            onClick={this.handleDrawerToggle}
            disabled={!sidebarDetailsPanel}
            minimal
          />
        }
        toolbar={
          <HybridTopoSettingsToolbar
            onSettingsUpdate={saveSettings}
            model={settingsModel}
            collection={$hybridMap[`${cloudProvider}CloudMapCollection`]}
          />
        }
        scrolls
        minHeight="auto"
        canFullScreen
      >
        <SizeMe monitorWidth noPlaceholder>
          {({ size }) => (
            <Box flex={1} mb={1}>
              <MapComponent
                onSettingsUpdate={saveSettings}
                cloudProvider={cloudProvider}
                width={size.width}
                cloudProviderName={cloudProviderName}
                sidebarSettings={sidebarSettings}
                setSidebarDetails={setSidebarDetails}
                drawerIsOpen={drawerIsOpen}
              />
            </Box>
          )}
        </SizeMe>
      </Page>
    );
  }
}

export default CloudProvider;
