import React, { Component } from 'react';
import { Heading, Card, Box, Flex, Button } from 'core/components';
import DashboardSelector from 'app/views/core/dashboards/DashboardSelector';
import { Form, TextArea } from 'core/form';
import { inject } from 'mobx-react';
import CopyToClipboard from 'app/components/CopyToClipboardButton';
import Page from 'app/components/page/Page';
import { FiSave, FiCopy } from 'react-icons/fi';

const fields = {
  dashboard: {
    label: 'Dashboard'
  }
};

@Form({
  fields,
  options: {
    name: 'AssetMigration'
  }
})
@inject('$dashboards')
export default class AssetMigration extends Component {
  state = {
    selectedDashboardJSON: undefined,
    importDashboardJSON: undefined
  };

  onDashboardSelect = (field, dashboardId) => {
    const { $dashboards } = this.props;
    $dashboards.collection
      .get(dashboardId)
      .getSerializedDataForClone()
      .then((dashboardJSON) => {
        this.setState(() => ({
          selectedDashboardJSON: JSON.stringify(dashboardJSON, null, 2)
        }));
      });
  };

  onSubmitImport = () => {
    const { $dashboards } = this.props;
    const { importDashboardJSON } = this.state;
    if (importDashboardJSON) {
      $dashboards.assetMigration(importDashboardJSON);
    }
  };

  render() {
    const { selectedDashboardJSON, importDashboardJSON } = this.state;
    return (
      <Page hideSubnav scrolls>
        <Heading level={2}>Asset Migration</Heading>

        <Card p={2}>
          <Heading level={5}>Export</Heading>
          <Box mt={2} justifyContent="space-between">
            <DashboardSelector large name="dashboard" onChange={this.onDashboardSelect} />
            <Box>
              <TextArea
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                readOnly
                value={selectedDashboardJSON}
                rows={10}
                fill
              />
              <Box mt={2}>
                <CopyToClipboard text={selectedDashboardJSON}>
                  <Button text="Copy to Clipboard" icon={FiCopy} style={{ minWidth: 150 }} />
                </CopyToClipboard>
              </Box>
            </Box>
          </Box>
        </Card>

        <Card mt={3} p={2}>
          <Heading level={5}>Import</Heading>
          <Flex>
            <TextArea
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              onChange={(e) => {
                const _importDashboardJSON = e.target.value;
                this.setState(() => ({ importDashboardJSON: _importDashboardJSON }));
              }}
              value={importDashboardJSON}
              rows={10}
              fill
            />
          </Flex>
          <Box mt={2}>
            <Button
              text="Submit"
              intent="primary"
              icon={FiSave}
              style={{ minWidth: 150 }}
              onClick={this.onSubmitImport}
            />
          </Box>
        </Card>
      </Page>
    );
  }
}
