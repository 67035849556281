import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { Box } from 'core/components';

const CircleGroup = styled(Box)`
  cursor: pointer;

  &.deselected {
    opacity: 0.5;
  }

  &.selected {
    circle {
      stroke: ${themeGet('colors.primary')};
      stroke-width: 2px;
    }
  }

  &.highlighted {
    circle {
      stroke: ${themeGet('colors.primary')};
      stroke-width: 2px;
      stroke-dasharray: 7 3;
    }
  }
`;

export default CircleGroup;
