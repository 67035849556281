import { OCI_ENTITY_TYPES } from 'shared/hybrid/constants';

const connectToVpcPeeringConnection = ({ sourcePoint, targetPoint, ySpacing, regionLeft, linkSpacing }) => {
  const leftX = regionLeft - linkSpacing;
  const topY = targetPoint[1] + ySpacing;
  const bottomY = sourcePoint[1] + ySpacing;
  const connectionPoints = [
    [sourcePoint[0], bottomY],
    [leftX, bottomY],
    [leftX, topY],
    [targetPoint[0], topY]
  ];
  return { sourceAnchor: 'bottom', targetAnchor: 'bottom', connectionPoints };
};

/**
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const VpcPeeringConnectionConnector = ({ targetType, ...rest }) => {
  switch (targetType) {
    case 'VpcPeeringConnections':
    case OCI_ENTITY_TYPES.VIRTUAL_CLOUD_NETWORK:
      return connectToVpcPeeringConnection({ ...rest });
    default:
      return null;
  }
};

export default VpcPeeringConnectionConnector;
