import React, { Component } from 'react';
import ArcLayout from './arc/ArcLayout';
import HoneycombLayout, { getHoneycombWidth } from './honeycomb/HoneycombLayout';

export default class ArcLayoutWithFallback extends Component {
  static defaultProps = {
    honeycombThreshold: 40,
    forceHoneycomb: false,
    width: 900,
    size: 40,
    items: []
  };

  render() {
    const { honeycombThreshold, forceHoneycomb, width, size, items, ...props } = this.props;

    if (forceHoneycomb || items.length >= honeycombThreshold) {
      const honeycombWidth = getHoneycombWidth({ width, size, count: items.length });
      return (
        <g transform={`translate(${(width - honeycombWidth) / 2} 0)`}>
          <HoneycombLayout items={items} width={width} size={size} {...props} />
        </g>
      );
    }

    return (
      <g transform={`translate(${size / 2} 0)`}>
        <ArcLayout items={items} width={width - size} size={size} {...props} />
      </g>
    );
  }
}
