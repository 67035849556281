import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { isEqual } from 'lodash';
import { deepClone } from 'core/util';
import { buildFilterGroup } from 'core/util/filters';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import { interfaceKeyRegex } from 'app/components/dataviews/views/legend/legendUtils';

@inject('$hybridMap')
@observer
export default class LinkFlowTrafficGenerator extends React.Component {
  static getDerivedStateFromProps(props) {
    const { $hybridMap, links = [] } = props;
    const sidebarFilters = $hybridMap.settingsModel.get('sidebarQueryOverrides.filters');
    const hasSidebarFilters = sidebarFilters && sidebarFilters.filterGroups && sidebarFilters.filterGroups.length > 0;
    const interfaces = links
      .filter((link) => link && link.connections)
      .map((link) => link.connections)
      .flat()
      .reduce((acc, connection) => acc.concat(Object.values(connection)), [])
      .filter((inf) => inf.device_name && inf.snmp_id !== undefined);

    return { interfaces: hasSidebarFilters ? interfaces : [] };
  }

  state = {
    interfaces: []
  };

  componentDidUpdate(prevProps, prevState) {
    const { links = [], onFlowTrafficUpdate } = this.props;
    const { interfaces } = this.state;

    if (!isEqual(prevState.interfaces, interfaces) && interfaces.length === 0) {
      const linksWithoutTraffic = links.map(({ traffic, isFiltered, ...link }) => link);

      onFlowTrafficUpdate(linksWithoutTraffic);
    }
  }

  get deviceInterfaceFilterGroup() {
    const { interfaces } = this.state;

    if (interfaces.length > 0) {
      return buildFilterGroup({
        connector: 'Any',
        filterGroups: interfaces.map((inf) =>
          buildFilterGroup({
            connector: 'All',
            filters: [
              {
                filterField: 'i_device_name',
                operator: '=',
                filterValue: inf.device_name
              },

              {
                filterField: 'input_port',
                operator: '=',
                filterValue: inf.snmp_id.toString()
              }
            ]
          })
        )
      });
    }

    return null;
  }

  getResultsMap = (results) =>
    results.reduce((acc, model) => {
      const interfaceId = model.get('input_port');
      const interfaceParts = interfaceKeyRegex.exec(interfaceId);

      if (interfaceParts) {
        const { snmp_id } = interfaceParts.groups;
        const key = `${model.get('i_device_name')}-${snmp_id}`;

        return {
          ...acc,
          [key]: {
            device_name: model.get('i_device_name'),
            site_name: model.get('i_device_site_name'),
            inTraffic: model.get('avg_bits_per_sec'),
            snmp_id
          }
        };
      }

      return acc;
    }, {});

  handleQueryComplete = ({ results, fullyLoaded }) => {
    if (fullyLoaded) {
      const { links = [], onFlowTrafficUpdate } = this.props;
      const resultsMap = this.getResultsMap(results);
      const linksWithTraffic = deepClone(links).map((link) => {
        if (link.connections && link.connections.length > 0) {
          const traffic = link.connections.reduce((acc, connection) => {
            const { interface1, interface2 } = connection;

            if (interface1 && interface2) {
              const fromInterfaceTraffic = resultsMap[`${interface1.device_name}-${interface1.snmp_id}`];
              const toInterfaceTraffic = resultsMap[`${interface2.device_name}-${interface2.snmp_id}`];
              const inTraffic = (fromInterfaceTraffic && fromInterfaceTraffic.inTraffic) || 0;
              const outTraffic = (toInterfaceTraffic && toInterfaceTraffic.inTraffic) || 0;

              return acc.concat({ fromInterface: interface1, toInterface: interface2, inTraffic, outTraffic });
            }

            return acc;
          }, []);

          return { ...link, isFiltered: true, traffic };
        }

        return link;
      });

      onFlowTrafficUpdate(linksWithTraffic);
    }
  };

  render() {
    const { $hybridMap } = this.props;
    const filters = this.deviceInterfaceFilterGroup;

    if (filters) {
      const query = $hybridMap.getQuery({
        topx: 0,
        depth: 500,
        viz_type: 'table',
        show_overlay: false,
        show_total_overlay: false,
        metric: ['InterfaceID_src', 'i_device_site_name'],
        aggregateTypes: ['avg_bits_per_sec'],
        filters
      });

      return <LightweightDataViewWrapper query={query} onQueryComplete={this.handleQueryComplete} />;
    }

    return null;
  }
}
