import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Box, EmptyState, Flex, Grid, Link, Text } from 'core/components';
import { Circle } from 'app/components/svg';
import { cloudProviderData } from 'app/util/utils';
import CircleGroup from './CircleGroup';

@inject('$hybridMap')
@observer
export default class Clouds extends Component {
  static defaultProps = {
    exclude: []
  };

  get clouds() {
    const { exclude, $hybridMap } = this.props;
    return $hybridMap.clouds.filter(({ provider }) => !exclude.includes(provider));
  }

  get isFiltered() {
    const { $hybridMap } = this.props;
    return $hybridMap.clouds.length !== this.clouds.length;
  }

  renderLogo(provider) {
    const logo = cloudProviderData(provider, 'logo');
    const width = 20;
    const height = 20;

    if (logo) {
      return React.cloneElement(logo, { x: width / 2, y: height / 2, width, height });
    }

    return null;
  }

  render() {
    const { selected, highlighted, onSelect } = this.props;
    const { clouds } = this;

    if (this.clouds.length === 0) {
      return (
        <EmptyState
          icon="cloud"
          description={
            <>
              No {this.isFiltered ? 'other' : ''} clouds configured. Visit{' '}
              <Link to="/v4/settings/clouds">cloud settings</Link> to get started.
            </>
          }
        />
      );
    }

    return (
      <Grid gridTemplateColumns="repeat(auto-fit, minmax(110px, 1fr))" flex={1} alignItems="center">
        {clouds.map((config) => {
          const name = cloudProviderData(config.provider, 'name');

          return (
            <CircleGroup
              key={config.provider}
              className={classNames('circle-item-group', 'no-icon-fill', 'hybrid-map-selectable-node', {
                selected: selected === config.provider,
                highlighted: !selected && highlighted.includes(config.provider),
                deselected: selected && selected !== config.provider
              })}
              onClick={() => onSelect(config.provider)}
            >
              <Flex flexDirection="column" alignItems="center" minWidth={110}>
                <Text as="div" whiteSpace="nowrap" mb={1}>
                  {config.name || name}
                </Text>
                <Box>
                  <svg width={40} height={40} viewBox="-1 -1 42 42">
                    <Circle cx={20} cy={20} r={20} className={`cloud-${config.provider}`} />
                    {this.renderLogo(config.provider)}
                  </svg>
                </Box>
              </Flex>
            </CircleGroup>
          );
        })}
      </Grid>
    );
  }
}
