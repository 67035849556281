import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IoIosGitNetwork } from 'react-icons/io';
import { Box, Flex, Grid, Heading, Icon, LinkButton, Text } from 'core/components';
import SiteLink from 'app/components/links/SiteLink';

const SiteSummary = ({ model, $hybridMap }) => (
  <Grid gridTemplateColumns="auto 1fr" p={2} borderBottom="thin">
    <Flex alignItems="center" gridColumn="1/3">
      <Icon icon="map-marker" iconSize={32} color="violet3" mr={1} />
      <Flex flex={1} alignItems="flex-start" justifyContent="space-between">
        <Box>
          <Text as="div" muted>
            Site Details
          </Text>
          <Heading level={3} mb={0}>
            <SiteLink siteId={model.get('id')} blank>
              {model.get('title')}
            </SiteLink>
          </Heading>
        </Box>
        <LinkButton to={`${$hybridMap.baseRoute}/site/${model.id}`} icon={IoIosGitNetwork} text="View Topology" />
      </Flex>
    </Flex>

    <Text muted>Type</Text>
    <Text>Data Center</Text>

    <Text muted>Address</Text>
    <Text>{model.siteLocation}</Text>
  </Grid>
);

SiteSummary.PopoutTitle = ({ model }) => (
  <Flex alignItems="center">
    <Icon icon="map-marker" color="violet3" mr={1} />
    {model.get('title')}
  </Flex>
);

export default inject('$hybridMap')(observer(SiteSummary));
