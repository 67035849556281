import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import getErrorBoundary from 'core/util/getErrorBoundary';
import SharedNavBar from 'app/components/navbar/SharedNavBar';
import PageContentContainer from 'app/components/page/PageContentContainer';

import SharedFooter from './SharedFooter';

const PageContentsErrorBoundaryCmp = getErrorBoundary('SharedPageContents');

@inject('$app')
@observer
class SharedPage extends Component {
  static defaultProps = {
    parentLinks: [],
    scrolls: false,
    flexDirection: 'column'
  };

  componentDidMount() {
    this.updateDocTitle();
  }

  componentDidUpdate(prevProps) {
    const { title, docTitle, parentLinkLabel } = this.props;

    if (title !== prevProps.title || docTitle !== prevProps.docTitle || parentLinkLabel !== prevProps.parentLinkLabel) {
      this.updateDocTitle();
    }
  }

  updateDocTitle() {
    const { $app, title, docTitle } = this.props;
    if (title || docTitle) {
      $app.setPageTitle(docTitle || title);
    }
  }

  render() {
    const { bottomElement, children, flexDirection, scrolls, ...rest } = this.props;

    return (
      <>
        <SharedNavBar />

        <PageContentsErrorBoundaryCmp>
          <PageContentContainer
            className={classNames({ scrolls, showFullScreen: true })}
            py={2}
            px="20px"
            flexDirection={flexDirection}
            overflow={scrolls ? 'hidden scroll' : 'visible'}
            height={scrolls ? 'fit-content' : undefined}
            maxHeight={scrolls ? '100%' : undefined}
            {...rest}
          >
            {children}
          </PageContentContainer>
        </PageContentsErrorBoundaryCmp>

        <SharedFooter />
      </>
    );
  }
}

export default SharedPage;
