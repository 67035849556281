import * as React from 'react';
import { Box, Flex, Heading, Text } from 'core/components';
import { AzureConsoleLink } from 'app/views/hybrid/maps/azure/components';
import { AwsConsoleLink } from 'app/views/hybrid/maps/aws/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';

function Header(props) {
  const { icon, title, subTitle, cloudProvider, nodeData, type, value } = props;

  return (
    <Flex borderBottom="thin" p={2} alignItems="center">
      <CloudIcon {...icon} width={32} height={32} />
      <Box flex={1} ml={1} flexGrow={1}>
        <Text as="div" muted>
          {title}
        </Text>
        <Heading mb={0} level={4}>
          {subTitle}
        </Heading>
      </Box>
      {cloudProvider === 'azure' && <AzureConsoleLink entity={nodeData} hideLabel />}
      {cloudProvider === 'aws' && <AwsConsoleLink type={type} value={value} nodeData={nodeData} />}
    </Flex>
  );
}

export default Header;
