import * as React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { has, isEqual } from 'lodash';
import { getOption } from 'core/form/components/modalSelect/selectHelpers';
import { Box, Button, Card, Flex, Grid, Text } from 'core/components';
import { Form, Field, Select } from 'core/form';
import { PREFIXES, adjustByGreekPrefix, toDecimal } from 'core/util';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import { valueRenderer } from 'app/components/dataviews/views/legend/legendRenderers';
import ResultsTable from 'app/components/resultsTable/ResultsTable';
import QueryResultsCollection from 'app/stores/query/QueryResultsCollection';
import TrafficChart from './TrafficChart';
import { DIMENSION_RENDERER_MAP, getDimensionOptions, searchDimensionOptions } from './dimensionOptionsHelper';

const fields = {
  query: {},

  aggregate: {
    defaultValue: 'avg_bits_per_sec'
  },

  syncChartScales: {
    label: 'Sync Chart Scales',
    defaultValue: true
  },

  dimension: {
    defaultValue: 'Traffic',
    options: []
  }
};

const options = {
  name: 'Traffic Charts Selector'
};

@Form({ fields, options })
@inject('$colors', '$hybridMap')
@observer
export default class TrafficCharts extends React.Component {
  state = {};

  formDisposer;

  static getDerivedStateFromProps(props, state) {
    const { form, queryOptions } = props;

    if (!isEqual(queryOptions, state.queryOptions)) {
      let selectedOverride = null;

      if (queryOptions && queryOptions.length > 0) {
        // if we have an option coming in that matches the currently selected query option, use it
        // otherwise default to the first query option in the list
        const matchedOption = getOption(queryOptions, form.getValue('query'));

        selectedOverride = (matchedOption && matchedOption.query) || queryOptions[0].query;
      }

      return {
        queryOptions,
        queryOverrides: selectedOverride,
        inboundLoading: true,
        outboundLoading: true,
        inboundDataview: null,
        outboundDataview: null,
        tableCollection: new QueryResultsCollection()
      };
    }

    return null;
  }

  componentDidMount() {
    const { form, queryOptions, onFormChange, formValues } = this.props;

    this.formDisposer = reaction(
      () => form.getValues(),
      (values) => {
        if (onFormChange) {
          onFormChange(values);
        }
      }
    );

    if (queryOptions && queryOptions.length > 0) {
      if (formValues) {
        // sync up the selected form values
        form.setValues(formValues);

        const matchedOption = getOption(queryOptions, form.getValue('query'));

        if (matchedOption && matchedOption.query) {
          this.setState({ queryOverrides: matchedOption.query });
        }
      } else {
        // auto-select the first query
        form.setValue('query', queryOptions[0].value);
      }
    }
  }

  componentWillUnmount() {
    if (this.formDisposer) {
      this.formDisposer();
    }
  }

  get inboundResults() {
    const { inboundLoading, inboundDataview } = this.state;

    if (!inboundLoading) {
      const [bucket] = inboundDataview.queryBuckets.activeBuckets;
      return bucket.queryResults;
    }

    return null;
  }

  get outboundResults() {
    const { outboundLoading, outboundDataview } = this.state;

    if (!outboundLoading) {
      const [bucket] = outboundDataview.queryBuckets.activeBuckets;
      return bucket.queryResults;
    }

    return null;
  }

  get aggregateOptions() {
    return [
      { label: 'Avg bits/s', value: 'avg_bits_per_sec', unitLabel: `Avg ${this.prefix}bits/s` },
      { label: 'p95th bits/s', value: 'p95th_bits_per_sec', unitLabel: `p95th ${this.prefix}bits/s` },
      { label: 'Max bits/s', value: 'max_bits_per_sec', unitLabel: `Max ${this.prefix}bits/s` }
    ];
  }

  get selectedAggregate() {
    const { form } = this.props;
    return form.getValue('aggregate');
  }

  get baseQuery() {
    return {
      aggregateTypes: [this.selectedAggregate],
      metric: ['Traffic'],
      show_overlay: true,
      show_total_overlay: true
    };
  }

  getOverridesFromSelectedDimension = (query) => {
    const { form } = this.props;
    const { query_title, renderOptions } = query;
    const dimensionField = form.getField('dimension');
    let selectedDimension = dimensionField.getValue('dimension');
    const dimensionOption = dimensionField.options.find((o) => o.value === selectedDimension);
    let isDirectionalDimension = false;

    if (dimensionOption) {
      const isTotal = selectedDimension === 'Traffic';
      const titleSuffix = !isTotal ? ` By ${dimensionOption.label}` : '';

      // reset to 'Traffic' for directional dimensions
      if (dimensionOption.src && dimensionOption.dst) {
        selectedDimension = ['Traffic'];
      }

      if (
        this.isDoubleChart &&
        renderOptions.dimensions &&
        renderOptions.dimensions.direction &&
        dimensionOption[renderOptions.dimensions.direction]
      ) {
        isDirectionalDimension = true;
        selectedDimension = dimensionOption[renderOptions.dimensions.direction];
      } else {
        selectedDimension =
          dimensionOption.src && dimensionOption.dst
            ? [dimensionOption.src, dimensionOption.dst]
            : [].concat(dimensionOption.metric);
      }

      return {
        metric: selectedDimension,
        query_title: `${query_title}${titleSuffix}`,
        renderOptions: { ...renderOptions, showResultsTable: !isTotal, stripDirection: isDirectionalDimension }
      };
    }

    return {};
  };

  get inboundQuery() {
    const { queryOverrides } = this.state;
    const dimensionOverrides = queryOverrides.inboundQuery
      ? this.getOverridesFromSelectedDimension(queryOverrides.inboundQuery)
      : {};

    return {
      ...this.baseQuery,
      ...queryOverrides.inboundQuery,
      ...dimensionOverrides
    };
  }

  get outboundQuery() {
    const { queryOverrides } = this.state;
    const dimensionOverrides = queryOverrides.outboundQuery
      ? this.getOverridesFromSelectedDimension(queryOverrides.outboundQuery)
      : {};

    return {
      ...this.baseQuery,
      ...queryOverrides.outboundQuery,
      ...dimensionOverrides
    };
  }

  get prefix() {
    if (this.inboundResults && this.outboundResults) {
      const inboundPrefix = this.inboundResults.prefix.bytes;
      const outboundPrefix = this.outboundResults.prefix.bytes;

      if (inboundPrefix && outboundPrefix) {
        const inboundPrefixPosition = PREFIXES.findIndex((p) => p.value === inboundPrefix);
        const outboundPrefixPosition = PREFIXES.findIndex((p) => p.value === outboundPrefix);
        const highestMagnitudePosition = Math.min(inboundPrefixPosition, outboundPrefixPosition);

        return PREFIXES[highestMagnitudePosition].value;
      }
    }

    return '';
  }

  // makes the column labels in the results table overridable
  // we typically want to do this for device/interface queries where we label them "Ingress" and "Egress"
  get valueColumnLabels() {
    const { renderOptions: inboundRenderOptions } = this.inboundQuery;
    const { renderOptions: outboundRenderOptions } = this.outboundQuery;

    return {
      inbound: (inboundRenderOptions && inboundRenderOptions.columnLabel) || 'Inbound',
      outbound: (outboundRenderOptions && outboundRenderOptions.columnLabel) || 'Outbound'
    };
  }

  getValueColumnOverrides = (prefix) => {
    const aggregateOption = this.aggregateOptions.find((o) => o.value === this.selectedAggregate);
    const unitLabel = aggregateOption ? aggregateOption.unitLabel : `${this.prefix}bits/s`;
    const renderer = valueRenderer({
      fix: 2,
      prefix
    });

    return [
      {
        key: this.selectedAggregate,
        label: (
          <>
            <Text as="div">{this.valueColumnLabels.inbound}</Text>
            <Text>{unitLabel}</Text>
          </>
        ),
        renderer,
        width: 100
      },
      {
        key: 'outbound',
        label: (
          <>
            <Text as="div">{this.valueColumnLabels.outbound}</Text>
            <Text>{unitLabel}</Text>
          </>
        ),
        value: 'outbound',
        renderer,
        width: 100
      }
    ];
  };

  syncChartExtremes() {
    const { form } = this.props;
    const { inboundLoading, outboundLoading, inboundDataview, outboundDataview } = this.state;
    const syncChartScales = form.getValue('syncChartScales');

    if (!inboundLoading && !outboundLoading && inboundDataview && outboundDataview) {
      if (has(inboundDataview, 'component.chart.yAxis') && has(outboundDataview, 'component.chart.yAxis')) {
        inboundDataview.component.setExtremes(null, null);
        outboundDataview.component.setExtremes(null, null);

        if (syncChartScales) {
          const { max: inboundMax } = inboundDataview.component.getExtremes();
          const { max: outboundMax } = outboundDataview.component.getExtremes();

          const max = Math.max(inboundMax, outboundMax);

          inboundDataview.component.setExtremes(0, max);
          outboundDataview.component.setExtremes(0, max);
        }
      }
    }
  }

  cleanDirectionalKey(key) {
    const reGCPRegion = /^ktsubtype__gcp_subnet__STR0(4|5)$/;
    const reGCPZone = /^ktsubtype__gcp_subnet__STR0(6|7)$/;
    const awsGatewayID = /^ktsubtype__aws_subnet__STR1(6|7)$/;
    const awsGatewayType = /^ktsubtype__aws_subnet__STR1(8|9)$/;
    const awsPacketAddress = /^ktsubtype__aws_subnet__INET_0(0|1)$/;
    const reVLAN = /^vlan_(in|out)$/;

    if (reGCPRegion.test(key)) {
      return 'ktsubtype__gcp_subnet__STR04src|dstktsubtype__gcp_subnet__STR05';
    }

    if (reGCPZone.test(key)) {
      return 'ktsubtype__gcp_subnet__STR06src|dstktsubtype__gcp_subnet__STR07';
    }

    if (awsGatewayID.test(key)) {
      return 'ktsubtype__aws_subnet__STR16src|dstktsubtype__aws_subnet__STR17';
    }

    if (awsGatewayType.test(key)) {
      return 'ktsubtype__aws_subnet__STR18src|dstktsubtype__aws_subnet__STR19';
    }

    if (awsPacketAddress.test(key)) {
      return 'ktsubtype__aws_subnet__INET_00src|dstktsubtype__aws_subnet__INET_01';
    }

    if (reVLAN.test(key)) {
      return 'vlan_in|out';
    }

    return key.replace(/src|dst/, 'src|dst').replace(/input|output/, 'input|output');
  }

  updateTableData = () => {
    const { $colors } = this.props;
    const { tableCollection } = this.state;

    if (this.inboundResults && this.outboundResults) {
      const inboundData = this.inboundResults.toJS();
      const outboundData = this.outboundResults.toJS();

      const inboundDataByKey = inboundData.reduce(
        (acc, item) => ({
          ...acc,
          [item.key]: item
        }),
        {}
      );

      const mergedDataByKey = outboundData.reduce((acc, item) => {
        const outboundValue = item[this.selectedAggregate];
        const inboundItem = acc[item.key] || {};

        // strip the chosen metric/agg as this is overwritten by inbound
        delete item[this.selectedAggregate];

        return {
          ...acc,
          [item.key]: {
            ...inboundItem,
            ...item,
            isOverlay: !!(inboundItem.isOverlay || item.isOverlay),
            rawData: inboundItem.rawData || item.rawData,
            outbound: outboundValue
          }
        };
      }, inboundDataByKey);

      const results = Object.values(mergedDataByKey).map((record) => {
        if (!this.stripDirection) {
          return record;
        }

        const cleanedRecord = {};
        Object.keys(record).forEach((k) => (cleanedRecord[this.cleanDirectionalKey(k)] = record[k]));
        return cleanedRecord;
      });

      // rewrite the colors so they're consistent between the charts and table
      let colorIndex = 0;
      results.forEach((model) => {
        const { isOverlay, rawData } = model;

        if (!isOverlay && rawData) {
          model.color = $colors.chartColors[colorIndex];
          colorIndex += 1;
        }
      });

      tableCollection.reset();
      tableCollection.set(results);

      // everything's fine
      setTimeout(() => this.syncChartExtremes(), 0);
    }
  };

  get colorPalette() {
    const { $colors, form } = this.props;
    const { tableCollection } = this.state;

    if (form.getValue('query') !== 'total') {
      // I think this is still trouble because the view doesn't re-render, maybe lifecycle issues, but this fixes the biggest issue with totals
      if (this.inboundResults && this.outboundResults) {
        const { overlayColor, primaryOverlayColor } = $colors;
        const colorMap = tableCollection.reduce(
          (acc, model) => ({
            ...acc,
            [model.get('key')]: model.get('color')
          }),
          {}
        );
        const inboundColors = this.inboundResults.nonOverlayRows.map((model) => colorMap[model.get('key')]);
        const outboundColors = this.outboundResults.nonOverlayRows.map((model) => colorMap[model.get('key')]);

        return {
          inbound: {
            dark: {
              overlay: overlayColor,
              primaryOverlay: primaryOverlayColor,
              paletteCustom: inboundColors
            },
            standard: {
              overlay: overlayColor,
              primaryOverlay: primaryOverlayColor,
              paletteCustom: inboundColors
            }
          },

          outbound: {
            dark: {
              overlay: overlayColor,
              primaryOverlay: primaryOverlayColor,
              paletteCustom: outboundColors
            },
            standard: {
              overlay: overlayColor,
              primaryOverlay: primaryOverlayColor,
              paletteCustom: outboundColors
            }
          }
        };
      }

      return null;
    }

    const palette = {
      overlay: $colors.overlayColor,
      primaryOverlay: $colors.primaryOverlayColor,
      paletteCustom: $colors.chartColors
    };

    return {
      inbound: {
        standard: palette,
        dark: palette
      },
      outbound: {
        standard: palette,
        dark: palette
      }
    };
  }

  handleQuerySelectChange = (field, value) => {
    const { form } = this.props;
    const selectedOption = field.options.find((o) => o.value === value);

    if (selectedOption) {
      this.setState(
        {
          queryOverrides: selectedOption.query,
          inboundLoading: true,
          outboundLoading: true,
          inboundDataview: null,
          outboundDataview: null
        },
        () => form.setValue('dimension', 'Traffic')
      );
    }
  };

  handleQueryComplete = ({ type, dataview, fullyLoaded }) => {
    this.setState(
      {
        [`${type}Loading`]: !fullyLoaded,
        [`${type}Dataview`]: dataview
      },
      this.updateTableData
    );
  };

  syncModelData = (model, data) => {
    const inboundModel = this.inboundResults.models.find((m) => m.get('key') === model.get('key'));
    const outboundModel = this.outboundResults.models.find((m) => m.get('key') === model.get('key'));

    if (inboundModel) {
      inboundModel.set(data);
    }

    if (outboundModel) {
      outboundModel.set(data);
    }

    model.set(data);
  };

  setMouseHover = (model, hovered) => {
    this.syncModelData(model, { mouseover: hovered });
  };

  getLastDataPoint = (results) => {
    const totalRow = results.models.find((model) => model.get('key') === 'Total');

    if (totalRow) {
      return toDecimal(adjustByGreekPrefix(totalRow.get(this.selectedAggregate), results.prefix.bytes));
    }

    return 0;
  };

  renderSelector = ({ model }) => {
    const color = model.get('color');
    const toggled = model.get('toggled');
    const rawData = model.get('rawData');

    if (!rawData) {
      return null;
    }

    return (
      <Box>
        <Button
          small
          alignSelf="center"
          onClick={() => {
            if (this.isDoubleChart) {
              this.syncModelData(model, { toggled: !toggled });
            } else {
              model.set({ toggled: !toggled });
            }
          }}
          title={toggled ? 'Click to enable this row' : 'Click to disable this row'}
          minimal
        >
          <Box
            bg={toggled ? 'transparent' : color}
            border={`2px solid ${color}`}
            borderRadius={8}
            height={14}
            width={14}
          />
        </Button>
      </Box>
    );
  };

  get dimensionOverrides() {
    const { tableCollection } = this.state;
    const { nonOverlayRows } = tableCollection;
    let overrides = [];

    if (nonOverlayRows.length > 0) {
      const [firstRecord] = nonOverlayRows;
      const recordKeys = Object.keys(firstRecord.toJS());

      Object.keys(DIMENSION_RENDERER_MAP).forEach((mapKey) => {
        if (recordKeys.indexOf(mapKey) > -1) {
          overrides = overrides.concat(DIMENSION_RENDERER_MAP[mapKey]);
        }
      });
    }

    return overrides;
  }

  get dimensionOptions() {
    const { queryOverrides } = this.state;
    const renderOptions = this.isDoubleChart ? this.inboundQuery.renderOptions : queryOverrides.renderOptions;

    return getDimensionOptions(renderOptions && renderOptions.dimensions, this.isDoubleChart);
  }

  // the majority of cases we'll want to show the results table so enable it by default and hide if explicitly asked for
  get showResultsTable() {
    const { renderOptions: inboundRenderOptions } = this.inboundQuery;
    const { renderOptions: outboundRenderOptions } = this.outboundQuery;

    if (
      (inboundRenderOptions && inboundRenderOptions.showResultsTable === false) ||
      (outboundRenderOptions && outboundRenderOptions.showResultsTable === false)
    ) {
      return false;
    }

    return true;
  }

  // when true, src/dst dimensions will be consolidated into a single non-directional dimension
  get stripDirection() {
    const { renderOptions: inboundRenderOptions } = this.inboundQuery;
    const { renderOptions: outboundRenderOptions } = this.outboundQuery;

    if (
      (inboundRenderOptions && inboundRenderOptions.stripDirection === false) ||
      (outboundRenderOptions && outboundRenderOptions.stripDirection === false)
    ) {
      return false;
    }

    return true;
  }

  getResultsTable = ({ bucket, queryModel }) => {
    const { tableCollection } = this.state;

    if (this.showResultsTable && this.inboundResults && this.outboundResults) {
      return (
        <Card overflow="auto" m="2px">
          <ResultsTable
            queryResultsCollection={tableCollection}
            bucket={bucket}
            queryModel={queryModel}
            showSparklines={false}
            interactive
            onRowMouseOver={(model) => this.setMouseHover(model, true)}
            onRowMouseOut={(model) => this.setMouseHover(model, false)}
            valueOverrides={this.getValueColumnOverrides(this.prefix)}
            selectionRenderer={this.renderSelector}
            dimensionOverrides={this.dimensionOverrides}
            allowSelection
            stickyHeader
            mergeOverlayCells
          />
        </Card>
      );
    }

    return null;
  };

  get noResultsConfig() {
    const { inboundDataview, outboundDataview } = this.state;
    const { renderOptions: inboundRenderOptions = {}, ...inboundQuery } = this.inboundQuery;
    const { renderOptions: outboundRenderOptions = {}, ...outboundQuery } = this.outboundQuery;
    const hasInboundData = this.hasData(inboundDataview);
    const hasOutboundData = this.hasData(outboundDataview);

    return {
      inbound: {
        hasData: hasInboundData,
        renderOptions: inboundRenderOptions,
        query: inboundQuery
      },

      outbound: {
        hasData: hasOutboundData,
        renderOptions: outboundRenderOptions,
        query: outboundQuery
      },
      hasData: hasInboundData || hasOutboundData
    };
  }

  get isDoubleChart() {
    const { queryOverrides } = this.state;
    return queryOverrides.inboundQuery && queryOverrides.outboundQuery;
  }

  get singleChart() {
    if (!this.isDoubleChart) {
      const { queryOverrides } = this.state;
      const dimensionOverrides = this.getOverridesFromSelectedDimension(queryOverrides);
      const { ...queryOverride } = queryOverrides;
      const query = { ...this.baseQuery, ...queryOverride, ...dimensionOverrides };
      const { renderOptions = {} } = dimensionOverrides;
      const showResultsTable = renderOptions.showResultsTable !== false;

      return (
        <LightweightDataViewWrapper query={query}>
          {({ dataview, loading, results, bucket, queryModel }) => {
            const hasData = this.hasData(dataview);

            return (
              <TrafficChart
                type="outbound"
                dataview={dataview}
                loading={loading}
                units={`${results.prefix.bytes || ''}bits/s`}
                lastDataPoint={this.getLastDataPoint(results)}
                showResultsTable={showResultsTable}
                noResultsConfig={{
                  hasData: true, // we don't ever want to show the inbound/outbound empty state for single charts
                  outbound: { hasData, renderOptions }
                }}
                resultsTable={
                  showResultsTable && (
                    <Card overflow="auto" m="2px">
                      <ResultsTable
                        queryResultsCollection={results}
                        bucket={bucket}
                        queryModel={queryModel}
                        showSparklines={false}
                        interactive
                        onRowMouseOver={(model) => model.set({ mouseover: true })}
                        onRowMouseOut={(model) => model.set({ mouseover: false })}
                        selectionRenderer={this.renderSelector}
                        allowSelection
                        stickyHeader
                        mergeOverlayCells
                      />
                    </Card>
                  )
                }
                query={query}
                {...renderOptions}
              />
            );
          }}
        </LightweightDataViewWrapper>
      );
    }

    return null;
  }

  hasData = (dv) =>
    dv && dv.queryBuckets && dv.queryBuckets.activeBuckets.every((bucket) => bucket.queryResults.size > 0);

  get doubleChart() {
    if (this.isDoubleChart) {
      const { renderOptions: inboundRenderOptions, ...inboundQuery } = this.inboundQuery;
      const { renderOptions: outboundRenderOptions, ...outboundQuery } = this.outboundQuery;

      return (
        <>
          <LightweightDataViewWrapper
            query={inboundQuery}
            onQueryComplete={({ dataview, fullyLoaded, results }) =>
              this.handleQueryComplete({ type: 'inbound', dataview, fullyLoaded, results })
            }
          >
            {({ dataview, loading, results }) => {
              this.syncChartExtremes();

              return (
                <TrafficChart
                  type="inbound"
                  dataview={dataview}
                  loading={loading}
                  units={`${results.prefix.bytes || ''}bits/s`}
                  lastDataPoint={this.getLastDataPoint(results)}
                  colors={this.colorPalette && this.colorPalette.inbound}
                  query={inboundQuery}
                  noResultsConfig={this.noResultsConfig}
                  {...inboundRenderOptions}
                />
              );
            }}
          </LightweightDataViewWrapper>
          <LightweightDataViewWrapper
            query={outboundQuery}
            onQueryComplete={({ dataview, fullyLoaded, results }) =>
              this.handleQueryComplete({ type: 'outbound', dataview, fullyLoaded, results })
            }
          >
            {({ dataview, loading, results, bucket, queryModel }) => {
              this.syncChartExtremes();

              return (
                <TrafficChart
                  type="outbound"
                  dataview={dataview}
                  loading={loading}
                  units={`${results.prefix.bytes || ''}bits/s`}
                  lastDataPoint={this.getLastDataPoint(results)}
                  showResultsTable={this.showResultsTable}
                  resultsTable={this.getResultsTable({ bucket, queryModel })}
                  colors={this.colorPalette && this.colorPalette.outbound}
                  query={outboundQuery}
                  noResultsConfig={this.noResultsConfig}
                  canSyncScales
                  {...outboundRenderOptions}
                />
              );
            }}
          </LightweightDataViewWrapper>
        </>
      );
    }

    return null;
  }

  handleDimensionOptionsQuery = (optionsFilter) => searchDimensionOptions(optionsFilter, this.dimensionOptions);

  render() {
    const { queryOptions } = this.props;
    const hasMultipleQueries = queryOptions.length > 1;
    let columnConfig = 'repeat(2, minmax(auto, 280px)) 100px';

    if (!hasMultipleQueries) {
      columnConfig = '1fr 100px';
    }

    return (
      <Flex flexDirection="column" flex={1} minHeight={0}>
        <Grid gridGap={1} gridTemplateColumns={columnConfig} justifyContent="flex-start" alignItems="center">
          {hasMultipleQueries && (
            <Field name="query" options={queryOptions} onChange={this.handleQuerySelectChange}>
              <Select menuWidth={280} fill />
            </Field>
          )}

          <Field
            name="dimension"
            options={this.dimensionOptions}
            onQuery={this.handleDimensionOptionsQuery}
            showError={false}
          >
            <Select menuWidth={280} fill showFilter preventEnterSelection />
          </Field>

          <Field name="aggregate" options={this.aggregateOptions}>
            <Select menuWidth={100} />
          </Field>
        </Grid>
        {this.singleChart}
        {this.doubleChart}
      </Flex>
    );
  }
}
