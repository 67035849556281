export const QUERY_NAMESPACE = 'AWS/PrivateLinkEndpoints';
// The most useful statistics are Average, Maximum, and Minimum.
// See https://docs.aws.amazon.com/vpc/latest/privatelink/privatelink-cloudwatch-metrics.html
export const MEANINGFUL_STATISTICS = ['Average', 'Sum', 'Maximum', 'Minimum'];

/*
  Based on documentation https://docs.aws.amazon.com/vpc/latest/privatelink/privatelink-cloudwatch-metrics.html
*/
export const METRICS = [
  'ActiveConnections',
  'BytesProcessed',
  'NewConnections',
  'PacketsDropped',
  'RstPacketsReceived',
  'RstPacketsSent'
];

export const fields = {
  metricType: {
    fieldProps: {
      flex: 3
    },
    defaultValue: 'ActiveConnections',
    options: [
      // Units: Count
      { label: 'The number of concurrent active connections.', value: 'ActiveConnections' },
      {
        label: 'The number of bytes exchanged between endpoints and endpoint services.',
        value: 'BytesProcessed'
      },
      { label: 'The number of new connections established through the endpoint.', value: 'NewConnections' },
      { label: 'The number of packets dropped by the endpoint.', value: 'PacketsDropped' },
      { label: 'The number of RST packets received by the endpoint.', value: 'RstPacketsReceived' }
    ]
  }
};

export const formValuesToMetricsArray = (formValues) => {
  const { metricType } = formValues;

  return [metricType];
};

export const generateDimensionsByEntity = (nodeData) => {
  const dimensions = [
    {
      Name: 'VPC Id',
      Value: nodeData.VpcId
    },
    {
      Name: 'VPC Endpoint Id',
      Value: nodeData.id
    },
    {
      Name: 'Endpoint Type',
      Value: nodeData.VpcEndpointType
    },

    {
      Name: 'Service Name',
      Value: nodeData.ServiceName
    }
  ];

  if (nodeData.SubnetIds?.lenght > 0) {
    dimensions.push({
      Name: 'Subnet Id',
      Value: nodeData.SubnetIds[0]
    });
  }

  return dimensions;
};
