import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, Text } from 'core/components';
import { ReactComponent as DirectConnectGatewayIcon } from 'app/assets/icons/direct-connect-gateway.svg';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';

function getTitle(config) {
  return config.nodeData?.directConnectGatewayName || config.metadata?.name || config.value;
}

const DirectConnectGatewaySummary = (config) => {
  const { logo, nodeData } = config;

  return (
    <Box>
      <Flex p={2} borderBottom="thin" alignItems="center" gridColumn="1/3">
        {logo ? (
          React.cloneElement(logo, { width: 32, height: 32 })
        ) : (
          <DirectConnectGatewayIcon width={32} height={32} />
        )}
        <Box flex={1} ml={1}>
          <Text as="div" muted>
            Direct Connect Gateway Details
          </Text>
          <Text muted small>
            {getTitle(config)}
          </Text>
        </Box>
      </Flex>
      {nodeData && (
        <SidebarItem title="Details" icon="more" popoutTitle={DirectConnectGatewaySummary.PopoutTitle(config)}>
          <CloudMetadata
            node={{
              ...nodeData,
              State: nodeData.DirectConnectGatewayState
            }}
            keys={['id', 'OwnerId', 'State', 'Tags']}
          />
        </SidebarItem>
      )}
    </Box>
  );
};

DirectConnectGatewaySummary.PopoutTitle = (config) => {
  const { logo } = config;
  return (
    <Flex alignItems="center">
      {logo || <DirectConnectGatewayIcon />}
      <Box ml={1}>{getTitle(config)}</Box>
    </Flex>
  );
};

export default observer(DirectConnectGatewaySummary);
