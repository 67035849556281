import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Box, EmptyState, Flex, FlexColumn, Spinner } from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import ExplorerQueryModel from 'app/stores/query/ExplorerQueryModel';
import QueryModel from 'app/stores/query/QueryModel';
import SharedPage from 'app/components/page/SharedPage';
import NotFound from 'app/views/NotFound';

import SharedLinkHeader from 'app/views/sharedLinks/SharedLinkHeader';
import DashboardItemGrid from './DashboardItemGrid';

@storeLoader('$dashboards')
@inject('$explorer', '$dashboard', '$sharedLinks')
@withRouter
@observer
class DashboardView extends Component {
  static defaultProps = {
    showSidebar: true,
    showHeader: true
  };

  state = {
    dockCollapsed: false
  };

  constructor(props) {
    super(props);

    this.grid = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { $dashboards, $sharedLinks, location, loading } = this.props;
    const { dashboard_id, dashboard, start_time, end_time, parametric_fields } = $sharedLinks.data.metadata;

    if (
      (!loading && prevProps.loading) ||
      this.getDashboardId() !== this.getDashboardId(prevProps) ||
      this.getDashboardSlug() !== this.getDashboardSlug(prevProps)
    ) {
      $dashboards.loadDashboard(
        {
          // old shared dashboards may have dashboard_id
          dashboardId: dashboard_id,
          dashboardModel: dashboard,
          params: encodeURIComponent(
            JSON.stringify({
              starting_time: start_time,
              ending_time: end_time,
              lookback_seconds: 0,
              time_format: 'UTC',
              parametric_fields
            })
          )
        },
        { ...location.state, completedInitialParametricSubmit: true }
      );
    }
  }

  componentWillUnmount() {
    const { $dashboard } = this.props;
    $dashboard.reset();

    if (this.dashboardDisposer) {
      this.dashboardDisposer();
    }
  }

  getDashboardId(props) {
    const { $sharedLinks } = props || this.props;
    return $sharedLinks.data.metadata.dashboard_id;
  }

  getDashboardSlug(props) {
    const { match } = props || this.props;
    return match.params.dashboardSlug;
  }

  get model() {
    const { $dashboard } = this.props;

    if ($dashboard.dashboard) {
      const query = $dashboard.dashboard.get('query');
      const model = ExplorerQueryModel.createFromQueryModel(QueryModel.create(query));

      model.set('parametric_fields', $dashboard.dashboard.get('parametric_fields'));

      return model;
    }

    return null;
  }

  toggleDialog(e, name) {
    e.preventDefault();
    e.stopPropagation();
    this.setState((state) => ({ [name]: !state[name] }));
  }

  handleToggleDockCollapsed = () => {
    const { dockCollapsed } = this.state;
    this.setState({ dockCollapsed: !dockCollapsed });
  };

  checkNeedsScrollToBottom = () => {
    const { $dashboard } = this.props;
    if ($dashboard.needsScrollToBottom) {
      this.grid.current.scrollIntoView(false);
      $dashboard.needsScrollToBottom = false;
    }
  };

  render() {
    const { $dashboard, location, loading } = this.props;
    const { dashboard, isFetching } = $dashboard;

    if (loading || isFetching) {
      return (
        <SharedPage title="Dashboards">
          <Flex pt="100px" flex={1} justifyContent="center">
            <Spinner />
          </Flex>
        </SharedPage>
      );
    }

    if (!dashboard) {
      return (
        <SharedPage title="Dashboards">
          <NotFound />
        </SharedPage>
      );
    }

    const showEmptyView = !$dashboard.isFetching && !$dashboard.dashboard.hasItems;
    const showItemGrid = !$dashboard.isFetching && $dashboard.dashboard.hasItems;
    const dashboardNavigationHistory = location.state && location.state.dashboardNavigationHistory;

    return (
      <SharedPage title={dashboard.get('dash_title')}>
        <FlexColumn flex={1}>
          <SharedLinkHeader isDashboard />
          {$dashboard.isFetching && (
            <Flex flex={1} justifyContent="center" alignItems="center">
              <Spinner />
            </Flex>
          )}
          {showEmptyView && (
            <Box height="calc(100vh - 400px)">
              <EmptyState
                title="No Panels"
                icon="new-grid-item"
                description="This view does not have any panels added yet."
              />
            </Box>
          )}
          <Flex flex={1} ref={this.grid} mx={-3}>
            {showItemGrid && (
              <DashboardItemGrid
                checkScroll={this.checkNeedsScrollToBottom}
                collapsed={this.dockCollapsed}
                navigationHistory={dashboardNavigationHistory}
                onToggleDockCollapsed={this.handleToggleDockCollapsed}
                dockCollapsed={this.dockCollapsed}
              />
            )}
          </Flex>
        </FlexColumn>
      </SharedPage>
    );
  }
}

export default DashboardView;
