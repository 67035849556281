import React, { useState } from 'react';

import { inject } from 'mobx-react';
import { Box, Flex, Heading, Tag, EmptyState } from 'core/components';
import { FaCheck } from 'react-icons/fa';
import { getCustomProperties, selectIdToObj } from 'shared/util/map';
import { GCP_ENTITY_TYPES } from 'shared/hybrid/constants';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import { translateFromUTCForUser } from 'core/util/dateUtils';
import { greekDisplay } from '../cloudUtil';

import SidebarItem from '../SidebarItem';
import { FirewallPolicyRulesTable } from './FirewallRulesComponents';

const { NETWORK, REGION, FIREWALL_POLICY } = GCP_ENTITY_TYPES;

function isInNetwork({ associatedNetworks, selectedNetwork, selectedProject }) {
  for (const { project: policyProject, network: policyNetwork } of associatedNetworks) {
    if (policyProject === selectedProject && policyNetwork === selectedNetwork) {
      return true;
    }
  }
  return false;
}

const FirewallPolicies = ({ policies, isPoppedOut, $hybridMap, userTimezone }) => {
  if (policies.length) {
    return policies.map(({ name, ruleTupleCount, creationTimestamp, region, description, flatRules, associations }) => {
      const rules = flatRules.map((r) => ({
        protocolAndPort: r.port ? `${r.protocol}:${r.port}` : `${r.protocol}`,
        ...r
      }));

      const associationList = associations.map((a) => a.name);
      const node = {
        'Policy Name': name,
        'Tuple Count': ruleTupleCount,
        'Policy Created': translateFromUTCForUser({ timestamp: creationTimestamp, userTimezone }),
        Scope: region ? 'Region' : 'Network',
        description,
        Associations: associationList
      };
      const keys = Object.keys(node);
      return (
        <Box key={`${name}-${creationTimestamp}-${ruleTupleCount}-${region}`}>
          <CloudMetadata node={node} keys={keys} />
          <FirewallPolicyRulesTable
            firewallPolicyRules={rules}
            isPoppedOut={isPoppedOut}
            queryTimeOptions={$hybridMap.settingsModel.queryTimeOptions}
          />
        </Box>
      );
    });
  }
  return null;
};

const FirewallPoliciesPopover = (props) => {
  const [isPoppedOut, setIsPoppedOut] = useState(false);

  const handlePopOutChange = (prevPopped) => {
    setIsPoppedOut(!!prevPopped);
  };

  const { width, popoutTitle, $hybridMap, $auth, ...rest } = props;
  const { nodeData, type } = rest;
  const { gcpCloudMapCollection } = $hybridMap;
  const { userTimezone } = $auth;

  const allFirewallPolicies = gcpCloudMapCollection.getActiveEntities({
    entityType: FIREWALL_POLICY,
    allowUnlinked: true
  });

  let fwPolicies;

  switch (type) {
    case NETWORK: {
      const { network: selectedNetwork, project: selectedProject } = getCustomProperties(nodeData);
      fwPolicies = allFirewallPolicies.filter((fwp) => {
        const { associatedNetworks, scope } = fwp;
        return scope === 'network' && isInNetwork({ associatedNetworks, selectedNetwork, selectedProject });
      });
      break;
    }
    case REGION: {
      const { project: selectedProject, network: selectedNetwork, region: selectedRegion } = selectIdToObj(nodeData);
      fwPolicies = allFirewallPolicies.filter((fwp) => {
        const { associatedNetworks, scope, regionName } = fwp;
        return (
          scope === 'region' &&
          isInNetwork({ associatedNetworks, selectedNetwork, selectedProject }) &&
          regionName === selectedRegion
        );
      });
      break;
    }
    default: {
      fwPolicies = [];
    }
  }

  return (
    <SidebarItem
      excludeFormProps
      title={
        <Flex alignItems="center" gap={1} width="100%">
          <Heading level={5} mb={0}>
            Firewall Policies
          </Heading>
          {fwPolicies.length > 0 ? (
            <Tag
              intent="success"
              icon={FaCheck}
              title={`${fwPolicies.length} active firewall policies`}
              minimal
              round
              flexGrow={1}
            >
              {greekDisplay(fwPolicies.length)}
            </Tag>
          ) : null}
        </Flex>
      }
      dialogProps={{ width: width * 1.5 }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="shield"
      popoutTitle={popoutTitle}
      onPopOutChange={handlePopOutChange}
    >
      {fwPolicies.length > 0 ? (
        <FirewallPolicies
          policies={fwPolicies}
          isPoppedOut={isPoppedOut}
          $hybridMap={$hybridMap}
          userTimezone={userTimezone}
        />
      ) : (
        <Box>
          <EmptyState
            title="No Results"
            icon={<CloudIcon cloudProvider="gcp" entity={FIREWALL_POLICY} iconSize={48} />}
          />
        </Box>
      )}
    </SidebarItem>
  );
};

export default inject('$hybridMap')(FirewallPoliciesPopover);
