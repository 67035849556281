import * as React from 'react';
import { inject, observer } from 'mobx-react';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import { Box, Flex } from 'core/components';
import Legend from 'app/components/dataviews/views/legend/Legend';

@inject('$explorer', '$hybridMap')
@observer
export default class ContainersSummary extends React.Component {
  get containersQuery() {
    const { queryOverrides } = this.props;
    const { value } = this.props;

    // console.log(this.props);

    return {
      all_devices: false,
      device_types: ['kappa'],
      show_overlay: false,
      show_total_overlay: false,
      topx: 8,
      viz_type: 'stackedArea',
      aggregateTypes: ['avg_bits_per_sec'],
      filters: {
        connector: 'All',
        filterGroups: [
          {
            connector: 'All',
            not: false,
            filters: [
              {
                filterField: 'ktsubtype__kappa__STR10',
                operator: '=',
                filterValue: 'pod'
              },

              // @TODO is this correcet?
              {
                filterField: 'ktsubtype__kappa__STR08',
                operator: '=',
                filterValue: value
              }
            ]
          },
          {
            connector: 'All',
            not: true,
            filters: [
              {
                filterField: 'ktsubtype__kappa__STR11',
                operator: '=',
                filterValue: ''
              }
            ]
          }
        ]
      },
      metric: ['ktsubtype__kappa__STR11'],
      ...queryOverrides
    };
  }

  render() {
    return (
      <Flex flexDirection="column" flex={1} overflow="hidden">
        <Box height={480}>
          <DataViewWrapper
            query={this.containersQuery}
            headerProps={{
              shouldArrangeVertically: true
            }}
            fitToHeight
            viewProps={{
              height: 180,
              showNativeLegend: false
            }}
          >
            {({ dataview, component }) => (
              <>
                <Flex flex={1} overflow="auto">
                  {component}
                </Flex>
                <Flex flex={1} minHeight={280}>
                  <Legend dataview={dataview} />
                </Flex>
              </>
            )}
          </DataViewWrapper>
        </Box>
      </Flex>
    );
  }
}
