import { get } from 'lodash';

// if the entity has a 'Name' tag, use that. Otherwise the name will be the id
export function getEntityName(entity) {
  if (!entity) {
    return null;
  }

  if (entity.Tags) {
    const nameTag = entity.Tags.find((tag) => tag.Key === 'Name');

    if (nameTag) {
      return nameTag.Value;
    }
  }

  return entity.id;
}

// given a link with paths, returns counts for total paths and paths with tests
export function getPathCounts(paths) {
  if (paths) {
    const hasTestCount = paths.reduce((acc, path) => {
      const [firstNode] = path;
      const lastNodeInPath = path && path[path.length - 1];

      if (firstNode && firstNode.agent && lastNodeInPath && lastNodeInPath.agents && lastNodeInPath.targetIps) {
        const { agent } = firstNode;
        const { targetIps } = lastNodeInPath;
        const hasTest =
          agent.tests && agent.tests.some((t) => targetIps && targetIps.toString() === get(t, 'config.target.value'));

        if (hasTest) {
          acc += 1;
        }
      }

      return acc;
    }, 0);

    return {
      totalCount: paths.length,
      hasTestCount
    };
  }

  return {
    hasTestCount: 0,
    totalCount: 0
  };
}

export function getTestsFromPaths(paths = []) {
  return paths.reduce((acc, path) => {
    const [firstNode] = path;
    const lastNodeInPath = path && path[path.length - 1];
    const { agent } = firstNode;
    if (agent) {
      let target = null;

      if (lastNodeInPath.type === 'CustomerGateway') {
        target = lastNodeInPath.IpAddress;
      }

      if (lastNodeInPath.children) {
        if (Array.isArray(lastNodeInPath.children)) {
          target = lastNodeInPath.children.join(',');
        } else {
          const childIps = Object.values(lastNodeInPath.children)
            .map((child) => child.interface_ip)
            .filter((ip) => !!ip);

          target = childIps.join(',');
        }
      }

      if (target) {
        const matchedTest = agent.tests.find((test) => test.config.target.value === target);
        if (matchedTest) {
          acc.push(matchedTest);
        }
      }
    }

    return acc;
  }, []);
}

export function getMissingTestPaths(paths) {
  return paths.filter((path) => {
    const [firstNode] = path;
    const lastNodeInPath = path && path[path.length - 1];
    const { agent } = firstNode;
    if (agent) {
      let target;
      if (lastNodeInPath.type === 'CustomerGateway') {
        target = lastNodeInPath.IpAddress;
      } else {
        let { children } = lastNodeInPath;
        if (typeof children === 'object' && children !== null) {
          children = Object.keys(children).map((key) => children[key].interface_ip);
        }
        target = Array.isArray(children) ? children.join(',') : null;
      }
      if (target) {
        return !agent.tests.find((test) => test.config.target.value === target);
      }
    }
    return false;
  });
}

export const baseTestConfig = {
  labels: [],
  rollup_level: 1,
  healthSettings: {
    latencyCritical: 0,
    latencyWarning: 0,
    packetLossCritical: 0,
    packetLossWarning: 0,
    jitterCritical: 0,
    jitterWarning: 0,
    httpLatencyCritical: 0,
    httpLatencyWarning: 0,
    httpValidCodes: [],
    dnsValidCodes: [],
    disabledMetrics: {}
  },
  notifications: {
    channels: []
  },
  activate: {
    times: '3',
    timeWindow: '5',
    timeUnit: 'm',
    gracePeriod: '2'
  },
  protocol: 'icmp',
  port: 443,
  family: 'DUAL',
  ping: {
    period: 60,
    count: 5,
    expiry: 3000,
    dscp: 0
  },
  trace: {
    period: 60,
    expiry: 22500,
    count: 3,
    limit: 30,
    protocol: 'udp',
    port: 33434,
    dscp: 0
  },
  tasks: ['ping', 'traceroute'],
  alerting: {
    trigger_per_agent_dst: false
  }
};
