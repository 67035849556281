import * as React from 'react';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { Box, Button, Card, Flex, Label, Tag } from 'core/components';
import { Table } from 'core/components/table';
import { Form, Field, InputGroup, Select } from 'core/form';
import ViewInExplorerButton from 'app/components/dataviews/tools/ViewInExplorerButton';
import SecurityRulesCollection from 'app/stores/hybrid/SecurityRulesCollection';

@Form({ fields: { groupBy: { label: 'Group By' }, search: {} }, options: { name: 'Security Rules Search Form' } })
@withTheme
@observer
export default class SecurityRulesTable extends React.Component {
  collection = new SecurityRulesCollection();

  componentDidUpdate(prevProps) {
    const { securityGroup, nacl } = this.props;

    if (prevProps.securityGroup !== securityGroup) {
      this.collection.processData({ securityGroup });
    }

    if (prevProps.nacl !== nacl) {
      this.collection.processData({ nacl });
      this.collection.sort('ruleNumber');
    }
  }

  handleGroupByChange = (field) => this.collection.group(field.getValue());

  handleSearchChange = (field) => this.collection.filter(field.getValue());

  handleSearchClear = () => {
    const { form } = this.props;

    form.setValue('search', '');
    this.collection.clearFilters();
  };

  getDetailQuery = (model) => {
    const { vpcId, subnetId, queryTimeOptions } = this.props;
    const direction = model.get('direction');
    const resource = model.get('resource');
    const fromPort = model.get('fromPort');
    const toPort = model.get('toPort');

    const filters = [];
    const isInbound = direction === 'inbound';
    const directionCode = isInbound ? 'dst' : 'src';
    const resourceDirection = isInbound ? 'src' : 'dst';
    const query_title = `${direction === 'inbound' ? 'Inbound' : 'Outbound'} traffic ${
      direction === 'inbound' ? 'to' : 'from'
    } ${vpcId} ${subnetId || ''}`;

    if (resource) {
      filters.push({
        filterField: `inet_${resourceDirection}_addr`,
        operator: 'ILIKE',
        filterValue: resource
      });
    }

    if (fromPort && fromPort !== -1 && !isInbound) {
      filters.push({
        filterField: 'l4_src_port',
        operator: '=',
        filterValue: fromPort.toString()
      });
    }

    if (toPort && toPort !== -1 && isInbound) {
      filters.push({
        filterField: 'l4_dst_port',
        operator: '=',
        filterValue: toPort.toString()
      });
    }

    if (vpcId) {
      filters.push({
        filterField: `kt_aws_${directionCode}_vpc_id`,
        operator: '=',
        filterValue: vpcId
      });
    }

    if (subnetId) {
      filters.push({
        filterField: `kt_aws_${directionCode}_subnet_id`,
        operator: '=',
        filterValue: subnetId
      });
    }

    const query = {
      all_devices: false,
      aggregateTypes: ['agg_total_fps', 'agg_total_bytes'],
      device_types: ['aws_subnet'],
      query_title,
      show_overlay: false,
      show_total_overlay: false,
      filters: {
        connector: 'All',
        filterGroups: [
          {
            name: '',
            named: false,
            connector: 'All',
            not: false,
            autoAdded: '',
            filters,
            saved_filters: [],
            filterGroups: []
          }
        ]
      },
      metric: ['IP_src', 'Port_src', 'IP_dst', 'Port_dst'],
      viz_type: 'table',
      ...queryTimeOptions
    };

    return query;
  };

  get columns() {
    const { nacl, vpcId, subnetId } = this.props;

    const directionColumn = {
      label: 'Direction',
      name: 'direction',
      width: 90,
      renderer: ({ value }) => {
        const icon = value === 'inbound' ? 'arrow-left' : null;
        const rightIcon = value === 'outbound' ? 'arrow-right' : null;
        const label = value === 'inbound' ? 'in' : 'out';
        const title = `${value === 'inbound' ? 'Inbound to' : 'Outbound from'} ${subnetId || vpcId}`;

        return (
          <Tag icon={icon} rightIcon={rightIcon} title={title} small minimal>
            {label}
          </Tag>
        );
      }
    };

    const resourceColumn = {
      label: 'Resource',
      name: 'resource'
    };

    const protocolColumn = {
      label: 'Protocol',
      name: 'protocol',
      renderer: ({ value }) => (value === '-1' ? 'all protocols' : value)
    };

    const portRangeColumn = {
      label: 'Port Range',
      name: 'portRange',
      renderer: ({ model }) => {
        const fromPort = model.get('fromPort');
        const toPort = model.get('toPort');

        if (!fromPort && !toPort) {
          return '--';
        }

        if (fromPort === -1) {
          return 'all icmp types';
        }

        if (fromPort && !toPort) {
          return fromPort;
        }

        return `${fromPort} - ${toPort}`;
      }
    };

    const ipVersionColumn = {
      label: 'IP Version',
      name: 'ipVersion'
    };

    const descriptionColumn = {
      label: 'Description',
      name: 'description'
    };

    const ruleActionColumn = {
      label: 'Rule Action',
      name: 'ruleAction',
      renderer: ({ value }) => {
        const intent = value === 'allow' ? 'success' : 'danger';

        return (
          <Tag intent={intent} small minimal>
            {value}
          </Tag>
        );
      }
    };

    const ruleNumberColumn = {
      label: 'Rule Number',
      name: 'ruleNumber'
    };

    const viewInExplorerButtonColumn = {
      label: null,
      name: 'viewInExplorer',
      width: 50,
      renderer: ({ model }) => (
        // keep the model selected when using the data explorer button
        <div
          onClick={(e) => {
            model.select();
            e.stopPropagation();
          }}
        >
          <ViewInExplorerButton text={null} query={this.getDetailQuery(model)} openInNewWindow />
        </div>
      )
    };

    if (nacl) {
      return [
        directionColumn,
        ruleActionColumn,
        ruleNumberColumn,
        resourceColumn,
        protocolColumn,
        portRangeColumn,
        ipVersionColumn,
        viewInExplorerButtonColumn
      ];
    }

    return [
      directionColumn,
      resourceColumn,
      protocolColumn,
      portRangeColumn,
      ipVersionColumn,
      descriptionColumn,
      viewInExplorerButtonColumn
    ];
  }

  get groupByOptions() {
    const { nacl } = this.props;
    const options = [
      { label: 'None', value: null },
      { label: 'Direction', value: 'direction' },
      { label: 'Protocol', value: 'protocol' },
      { label: 'IP Version', value: 'ipVersion' }
    ];

    if (nacl) {
      options.push({ label: 'Rule Action', value: 'ruleAction' });
    }

    return options;
  }

  groupSummary = ({ groupKey, group }) => {
    const groupKeyLabels = {
      inbound: 'Inbound',
      outbound: 'Outbound',
      '-1': 'all protocols',
      allow: 'Allow',
      deny: 'Deny'
    };

    return `${groupKeyLabels[groupKey] || groupKey} (${group.length})`;
  };

  render() {
    const { form, topology, securityGroup, nacl, theme, ...containerProps } = this.props;

    return (
      <Card {...containerProps}>
        <Flex alignItems="center" borderBottom="thin" p={1} bg="subnavBackground">
          <Label mr={1} small>
            Group By:
          </Label>
          <Field
            options={this.groupByOptions}
            name="groupBy"
            showLabel={false}
            className="no-margin"
            placeholder="None"
            onChange={this.handleGroupByChange}
          >
            <Select menuWidth={100} />
          </Field>

          <Field name="search" className="no-margin" flex={1} onChange={this.handleSearchChange}>
            <InputGroup
              ml={1}
              leftIcon="search"
              rightElement={
                form.getValue('search') !== '' && <Button icon="cross" onClick={this.handleSearchClear} minimal small />
              }
            />
          </Field>
        </Flex>
        <Box height={250} overflow="auto">
          <Table
            collection={this.collection}
            columns={this.columns}
            groupSummaryLookup={this.groupSummary}
            rowTags={({ model }) => {
              const tags = {};

              if (model.get('deniesTraffic')) {
                tags.style = { background: theme.colors.dangerBackground };
              }

              return tags;
            }}
          />
        </Box>
      </Card>
    );
  }
}
