import { isIpV6Valid } from 'core/util/ip';

export default {
  message: 'This field requires one or more comma-separated IPv6 addresses',
  fn: (value) => {
    let ips = value;
    if (!Array.isArray(value)) {
      ips = value.split(',');
    }

    return ips.every((ip) => isIpV6Valid(ip));
  }
};
