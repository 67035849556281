import React from 'react';

import { inject } from 'mobx-react';
import { Flex, Box } from 'core/components';

import SidebarItem from '../SidebarItem';
import CloudMetadata from '../CloudMetadata';

const AzureVpnSitesPopover = ({ cloudProvider, width, popoutTitle, $hybridMap, ...rest }) => {
  const { nodeData } = rest;

  const { azureCloudMapCollection } = $hybridMap;

  const vpnSites =
    azureCloudMapCollection.getEntityProperty(nodeData, 'vpnSites') ??
    azureCloudMapCollection.getEntityRelatedEntities(nodeData, 'vpnSites');

  if (!vpnSites || vpnSites.length === 0) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title="VPN Sites"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="one-to-many"
      popoutTitle={popoutTitle}
    >
      <Flex px="4px" gap={1} flexDirection="column">
        {vpnSites.map((vpnSite) => (
          <SidebarItem
            key={vpnSite.id}
            excludeFormProps
            title={vpnSite?.name}
            dialogProps={{ width }}
            fullscreenProps={{ headingOffset: -16 }}
            icon="inner-join"
            popoutTitle={popoutTitle}
          >
            <Box>
              <CloudMetadata
                node={{
                  ...vpnSite,
                  ...vpnSite.properties,
                  vpnSiteLinks: ' '
                }}
                keys={[
                  'provisioningState',
                  'addressSpace.addressPrefix',
                  'deviceProperties.deviceVendor',
                  'vpnSiteLinks'
                ]}
              />
              {azureCloudMapCollection.getEntityProperty(vpnSite, 'vpnSiteLinks')?.map((vpnSiteLink) => (
                <SidebarItem
                  key={vpnSiteLink.id}
                  excludeFormProps
                  title={vpnSiteLink?.name}
                  dialogProps={{ width }}
                  fullscreenProps={{ headingOffset: -16 }}
                  icon="cloud"
                  popoutTitle={popoutTitle}
                >
                  <Box>
                    <CloudMetadata
                      node={{
                        ...vpnSiteLink,
                        ...vpnSiteLink.properties
                      }}
                      keys={[
                        'provisioningState',
                        'addressSpace',
                        'bgpProperties.asn',
                        'bgpProperties.bgpPeeringAddress',
                        'linkProperties.linkProviderName',
                        'linkProperties.linkSpeedInMbps'
                      ]}
                    />
                  </Box>
                </SidebarItem>
              ))}
            </Box>
          </SidebarItem>
        ))}
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureVpnSitesPopover);
