import * as React from 'react';
import { Box, Card, Flex, Heading, LinkButton, Text, ThemedImage, Tabs, Tab } from 'core/components';
import Splash from 'app/views/splash/Splash';
import InterconnectsImage from 'app/assets/screenshots/perf-monitor-interconnects.png';
import InterconnectsImageDark from 'app/assets/screenshots/perf-monitor-interconnects-dark.png';
import ServicesImage from 'app/assets/screenshots/perf-monitor-services.png';
import ServicesImageDark from 'app/assets/screenshots/perf-monitor-services-dark.png';

const imageGroup = [
  {
    title: 'Interconnects',
    images: { src: InterconnectsImage, darkSrc: InterconnectsImageDark }
  },

  {
    title: 'Services',
    images: { src: ServicesImage, darkSrc: ServicesImageDark }
  }
];

export default function CloudPerformanceMonitorSplash() {
  return (
    <Splash
      title="Cloud Performance Monitor"
      toolbar={<Heading level={1}>Cloud Performance Monitor (Beta)</Heading>}
      scrolls
    >
      <Flex gap={3} pt={2}>
        <Tabs flex={1}>
          {imageGroup.map((group) => (
            <Tab
              key={group.title}
              id={group.title}
              title={group.title}
              panel={
                <Card>
                  <ThemedImage {...group.images} />
                </Card>
              }
            />
          ))}
        </Tabs>

        <Box flex={1} pt="40px">
          <Heading level={3} mb={2}>
            An easy way to check the performance of your Interconnects between your Cloud and on-prem deployments, as
            well as connection performance to AWS cloud services.
          </Heading>

          <Text as="p" mb={2}>
            Cloud Performance Monitor allows you to collect performance data on your links to cloud services and
            Interconnects (such as Direct Connect) using Kentik synthetic agents. Deploy agents in VPCs of interest to
            see end-to-end latency, delay, and jitter.
          </Text>

          <ul style={{ paddingLeft: '12px' }}>
            <li>Identify and address network performance problems for your cloud-based workloads</li>
            <li>See Latency, Delay, and Jitter for your cloud interconnects and cloud services connections</li>
          </ul>

          <LinkButton text="Onboard Your AWS to Get Access" to="/v4/setup/clouds/aws" intent="primary" mt={2} />
        </Box>
      </Flex>
    </Splash>
  );
}
