// determines the vpc ids agent status between uninstalled, pending, and private (installed and activated)
export default function getAgentBreakdown(list = []) {
  return list.reduce(
    (acc, item) => {
      const { virtualNetworkId, agentStatus } = item;

      if (acc.all[virtualNetworkId] || virtualNetworkId === '---') {
        // already been logged
        return acc;
      }

      acc.all[virtualNetworkId] = virtualNetworkId;

      if (agentStatus) {
        if (agentStatus === 'WAIT') {
          acc.pendingAgents.push(virtualNetworkId);
        }

        if (agentStatus === 'OK') {
          acc.privateAgents.push(virtualNetworkId);
        }
      } else {
        acc.unmonitoredVpcs.push(virtualNetworkId);
      }

      return acc;
    },
    { all: {}, pendingAgents: [], privateAgents: [], unmonitoredVpcs: [] }
  );
}
