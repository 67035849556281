import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, withRouter } from 'react-router-dom';

import { Flex, Spinner } from 'core/components';
import NotFound from 'app/views/NotFound';
import { LOGIN } from '@kentik/ui-shared/paths/login';

const getRedirect = (search) => {
  const params = new URLSearchParams(search);
  const redirectUrl = params.get('redirectUrl');
  const q = params.get('q');
  let redirect = '/v4';

  if (redirectUrl) {
    redirect = redirectUrl;

    if (q) {
      redirect += `?q=${q}`;
    }
  }

  return redirect;
};

@inject('$auth', '$sudo')
@withRouter
@observer
class SpoofAutoRedirect extends Component {
  state = { redirect: null, reason: null, loading: true, launchingSession: false };

  static getDerivedStateFromProps(props, prevState) {
    const { $auth, $sudo, location, match } = props;
    const { companyId, userId } = match.params;
    const isAlreadySpoofed = $sudo.isSpoofedAs({ companyId, userId });
    const reason = isAlreadySpoofed ? $sudo.getSpoofData('reason') : location.state?.reason;
    const redirect = getRedirect(location.search);
    const additional_info = location.state?.additional_info;

    const state = {
      loading: true,
      launchingSession: false,
      companyId,
      userId,
      reason,
      redirect,
      additional_info
    };

    // short circuit if the user is already spoofing as the spoof target
    if (isAlreadySpoofed && !prevState.launchingSession) {
      state.loading = false;
      return state;
    }

    // first, check for active spoofing and clear it if necessary
    if ($auth.isDemoUser) {
      $sudo.clearDemoSession();
    } else if ($auth.isSpoofed && !isAlreadySpoofed) {
      $sudo.clearSpoofSession();
    }

    // if there is no reason provided and it is required, short circuit
    if (!reason && $sudo.isSpoofReasonEnabled(companyId)) {
      return state;
    }

    // we need to spoof, and we have a reason, or it is not required
    state.launchingSession = true;
    return state;
  }

  componentDidMount() {
    const { $sudo } = this.props;
    const {
      launchingSession,
      companyId: company,
      userId: target_user_id,
      reason,
      additional_info,
      redirect
    } = this.state;

    if (launchingSession) {
      $sudo.launchSudoSession({ company, target_user_id, reason, additional_info }, { redirect });
    }
  }

  render() {
    const { $auth, location } = this.props;
    const { loading, launchingSession, redirect, reason } = this.state;

    if (!$auth.hasSudo && !$auth.isSpoofed) {
      return <NotFound />;
    }

    if (loading) {
      if (!reason && !launchingSession) {
        return (
          <Redirect
            to={{
              pathname: LOGIN.TWO_FACTOR,
              state: { from: location, requireReason: true }
            }}
          />
        );
      }

      return (
        <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
          <Spinner intent="primary" size={48} mt={-150} />
        </Flex>
      );
    }

    return <Redirect push to={redirect} />;
  }
}

export default SpoofAutoRedirect;
