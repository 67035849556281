import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { AiOutlineMenuFold } from 'react-icons/ai';
import { SizeMe } from 'react-sizeme';
import { Box, Button, LinkButton } from 'core/components';
import Page from 'app/components/page/Page';
import withHybridTopoSettings from 'app/views/hybrid/maps/components/settingsToolbar/withHybridTopoSettings';
import { BiCube } from 'react-icons/bi';
import OverviewMap from './maps/overview';
import HybridTopoSidebar from './maps/components/HybridTopoSidebar';
import HybridTopoSettingsToolbar from './maps/components/settingsToolbar/HybridTopoSettingsToolbar';
import withSidebarDetails from './maps/components/popovers/withSidebarDetails';

@withHybridTopoSettings
@withSidebarDetails
@inject('$auth')
@observer
class HybridOverview extends Component {
  static getDerivedStateFromProps(props) {
    if (!props.sidebarDetailsPanel) {
      return { drawerIsOpen: false };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      drawerIsOpen: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarDetailsPanel } = this.props;

    // if we're setting new details and it's not open, open it
    if (
      !prevState.drawerIsOpen &&
      sidebarDetailsPanel &&
      // sometimes we want to set sidebarDetailsPanel but do not open drawer
      !sidebarDetailsPanel.props?.forceClose &&
      (!prevProps.sidebarDetailsPanel || !!sidebarDetailsPanel.props?.forceOpen)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ drawerIsOpen: true });
    }

    if (prevState.drawerIsOpen && sidebarDetailsPanel?.props?.forceClose) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ drawerIsOpen: false });
    }
  }

  handleDrawerToggle = () => {
    const { sidebarDetailsPanel, setSidebarDetails } = this.props;
    this.setState(({ drawerIsOpen }) => {
      if (drawerIsOpen && sidebarDetailsPanel?.props?.forceOpen) {
        const config = sidebarDetailsPanel.props;
        setSidebarDetails({ ...config, forceOpen: false });
      }
      return { drawerIsOpen: !drawerIsOpen };
    });
  };

  render() {
    const { settingsModel, saveSettings, sidebarSettings, setSidebarDetails, sidebarDetailsPanel, $auth } = this.props;
    const { drawerIsOpen } = this.state;

    return (
      <Page
        docTitle="Kentik Map"
        subnavTools={
          <>
            {$auth.hasPermission('kubeMap.enabled') && (
              <LinkButton icon={BiCube} text="View Kube" mr="2px" to="/v4/cloud/kube" minimal />
            )}
            <LinkButton text="View Logical Map" ml="2px" to="/v4/kentik-map/logical" minimal />
            <Button
              text="Details"
              icon={AiOutlineMenuFold}
              ml="2px"
              active={drawerIsOpen}
              onClick={this.handleDrawerToggle}
              disabled={!sidebarDetailsPanel}
              minimal
            />
          </>
        }
        drawerContents={<HybridTopoSidebar sidebarDetailsPanel={sidebarDetailsPanel} />}
        toolbar={<HybridTopoSettingsToolbar onSettingsUpdate={saveSettings} model={settingsModel} trafficLegend />}
        drawerIsOpen={drawerIsOpen}
        drawerOnClose={(e) => {
          if (e && e.key === 'Escape' && drawerIsOpen) {
            this.handleDrawerToggle();
          }
        }}
        drawerProps={{ extraOffset: 43 }}
        scrolls
        canFullScreen
        minHeight="auto"
        pb={0}
      >
        <SizeMe monitorWidth noPlaceholder>
          {({ size }) => (
            <Box flex={1} mb={1}>
              <OverviewMap
                width={size.width}
                sidebarSettings={sidebarSettings}
                setSidebarDetails={setSidebarDetails}
                drawerIsOpen={drawerIsOpen}
              />
            </Box>
          )}
        </SizeMe>
      </Page>
    );
  }
}

export default HybridOverview;
