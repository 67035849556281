import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { ABSTRACT_MAP_WRAPPER_PADDING, ABSTRACT_MAP_TOO_CLOSE_MODIFIER } from 'app/views/hybrid/utils/cloud/constants';

const { LOCATION, VNET_GATEWAY_CONNECTION } = ENTITY_TYPES.get('azure');
/**
 * Target
 *   ↑
 *    ← ← ← ←
 *            ↑
 *            ↑
 *            ↑
 *    Region →
 */
const connectToAnyConnectionOnPrem = ({ sourcePoint, targetPoint, regionRight, ySpacing, linkSpacing }) => {
  const rightX = regionRight + linkSpacing;
  const topY = targetPoint[1] + ySpacing;
  const connectionPoints = [
    [rightX, sourcePoint[1]],
    [rightX, topY],
    [targetPoint[0], topY]
  ];
  return { sourceAnchor: 'right', targetAnchor: 'bottom', connectionPoints };
};

/**
 * will connect through left anchors
 *        _ _ _ _
 *       |
 *  → →  | region-1
 * ↑     |
 * ↑     |_ _ _ _
 * ↑
 * ↑      _ _ _ _
 * ↑     |
 * ↑     | region-2
 *   ← ← |
 *       |_ _ _ _
 */
const connectRegionToRegionThoughTheLeft = ({ source, targetPoint, sourcePoint, regionOffset }) => {
  const leftAnchorPoint = source.anchors.left;
  const leftSpacing = Math.min(ABSTRACT_MAP_TOO_CLOSE_MODIFIER + 1, ABSTRACT_MAP_WRAPPER_PADDING / regionOffset);

  const leftSourceConnectionPoint = Math.min(
    Math.abs(leftAnchorPoint[0] - leftSpacing * regionOffset),
    leftAnchorPoint[0] - leftSpacing
  );

  const connectionPoints = [
    [leftSourceConnectionPoint, sourcePoint[1]],
    [leftSourceConnectionPoint, targetPoint[1]]
  ];

  return { sourceAnchor: 'left', targetAnchor: 'left', connectionPoints };
};

/**
 * will either connect through left anchors or vertically if too close
 */
const connectRegionToRegion = ({ ...passThroughProps }) => {
  const { targetRegionOffset, sourceRegionOffset, yDirectionModifier } = passThroughProps;

  // check if regions are too close to each other
  if (Math.abs(targetRegionOffset - sourceRegionOffset) <= 1) {
    const isTargetAbove = yDirectionModifier < 0;
    // connect points directly
    return {
      sourceAnchor: isTargetAbove ? 'top' : 'bottom',
      targetAnchor: isTargetAbove ? 'bottom' : 'top',
      connectionPoints: []
    };
  }

  return connectRegionToRegionThoughTheLeft(passThroughProps);
};

/**
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const RegionConnector = ({ targetType, ...rest }) => {
  switch (targetType) {
    case 'region':
    case LOCATION:
      return connectRegionToRegion({ ...rest });
    case 'internet':
    case 'Lags':
    case 'VpnConnections':
    case 'DirectConnections':
    case 'DirectConnectGateways':
    case VNET_GATEWAY_CONNECTION:
      return connectToAnyConnectionOnPrem({ ...rest });
    // /** @TODO other target types */
    default:
      return null;
  }
};

export default RegionConnector;
