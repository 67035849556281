const CLOSE_ENOUGH_CONSTANT = 5;

/**
 * example:
 * Source                    Source
 *   ↓                         ↓
 *    → → → → → →  ← ← ← ← ← ←
 *                ↓
 *                ↓
 *                ↓
 *    ← ← ← ← ← ←   → → → → → →
 *   ↓                         ↓
 *  Target                   Target
 */
export const connectThroughMiddleX = ({
  xSpacing,
  ySpacing,
  nodeWidth,
  sourcePoint,
  targetPoint,
  xDirectionModifier,
  yDirectionModifier,
  sourceYSpacing = ySpacing,
  targetYSpacing = ySpacing,
  sourceNodeHeight,
  targetNodeHeight
}) => {
  const sourceMiddleX = sourcePoint[0] + xSpacing * xDirectionModifier;
  let targetMiddleX = targetPoint[0] - xSpacing * xDirectionModifier;

  if (Math.abs(targetPoint[0] - sourcePoint[0]) <= nodeWidth) {
    targetMiddleX = sourceMiddleX;
  }

  const topY = sourcePoint[1] + (sourceNodeHeight / 2) * yDirectionModifier + (sourceYSpacing / 2) * yDirectionModifier;
  const bottomY =
    targetPoint[1] - (targetNodeHeight / 2) * yDirectionModifier - (targetYSpacing / 2) * yDirectionModifier;
  const targetIsBelow = yDirectionModifier === 1;

  const connectionPoints = [
    [sourcePoint[0], topY],
    [sourceMiddleX, topY]
  ];

  /*
   * This condition is to prevent overlapping
   * Example:
   *        Source
   *          ↓
   *   ← ← ← ←
   *
   *       → → → → →
   *    Target
   */
  if (Math.abs(sourceMiddleX - targetMiddleX) < xSpacing) {
    connectionPoints.push([sourceMiddleX, bottomY]);
  } else {
    connectionPoints.push([targetMiddleX, topY], [targetMiddleX, bottomY]);
  }

  connectionPoints.push([targetPoint[0], bottomY]);

  return {
    sourceAnchor: targetIsBelow ? 'bottom' : 'top',
    targetAnchor: targetIsBelow ? 'top' : 'bottom',
    connectionPoints
  };
};

export const withinYGap = ({ sourcePoint, targetPoint, gridRowsGap, onPremBoxPadding, nodeHeight }) => {
  const diff = Math.abs(sourcePoint[1] - targetPoint[1]);
  // have 2 onPremBoxPadding because we want to add row line hight which is currently the same = 16px
  return diff > 0 && diff <= gridRowsGap * 2 + onPremBoxPadding * 2 + nodeHeight;
};

export const sameXandWithingYGap = ({ sourcePoint, targetPoint, gridRowsGap, onPremBoxPadding, nodeHeight }) =>
  // if vertically aligned and within a gap, connect directly
  Math.abs(sourcePoint[0] - targetPoint[0]) <= CLOSE_ENOUGH_CONSTANT &&
  withinYGap({ sourcePoint, targetPoint, gridRowsGap, onPremBoxPadding, nodeHeight });

export const connectAlignedNodes = (passThroughProps) => {
  const { sourcePoint, targetPoint, yDirectionModifier } = passThroughProps;
  // if source and target are on the same x axis
  if (sameXandWithingYGap({ ...passThroughProps })) {
    const isTargetAbove = targetPoint[1] < sourcePoint[1];
    // connect points directly
    return {
      sourceAnchor: isTargetAbove ? 'top' : 'bottom',
      targetAnchor: isTargetAbove ? 'bottom' : 'top',
      connectionPoints: []
    };
  }

  if (withinYGap({ ...passThroughProps })) {
    const middleY = sourcePoint[1] + (Math.abs(sourcePoint[1] - targetPoint[1]) / 2) * yDirectionModifier;
    const connectionPoints = [
      [sourcePoint[0], middleY],
      [targetPoint[0], middleY]
    ];
    const isTargetAbove = targetPoint[1] < sourcePoint[1];
    // connect points directly
    return {
      sourceAnchor: isTargetAbove ? 'top' : 'bottom',
      targetAnchor: isTargetAbove ? 'bottom' : 'top',
      connectionPoints
    };
  }

  return null;
};

/**
 * example:
 *        Target
 *          ↑
 *  → → → →
 * ↑
 * ↑
 *  ← ← ← ←
 *          ↑
 *        Source
 */
export const connectThroughLeft = ({ ...passThroughProps }) => {
  if (withinYGap({ ...passThroughProps })) {
    return connectThroughMiddleX({ ...passThroughProps });
  }

  const { targetPoint, sourcePoint, regionLeft, linkSpacing, ySpacing } = passThroughProps;

  const leftX = regionLeft - linkSpacing * 1.5;

  const isTargetAbove = targetPoint[1] < sourcePoint[1];
  const bottomY = Math.max(sourcePoint[1], targetPoint[1]) - ySpacing;
  const topY = Math.min(sourcePoint[1], targetPoint[1]) + ySpacing;

  const connectionPoints = [
    [sourcePoint[0], bottomY],
    [leftX, bottomY],
    [leftX, topY],
    [targetPoint[0], topY]
  ];
  return {
    sourceAnchor: isTargetAbove ? 'top' : 'bottom',
    targetAnchor: isTargetAbove ? 'bottom' : 'top',
    connectionPoints
  };
};

export const connectToNetwork = ({
  sourcePoint,
  targetPoint,
  regionLeft,
  linkSpacing,
  nodeHeight,
  targetNodeHeight,
  targetAnchor = 'left'
}) => {
  const [sourceX, sourceY] = sourcePoint; // x,y center of the source node
  const [targetX, targetY] = targetPoint; // x,y center of the target node
  const leftGutterX = regionLeft - linkSpacing; // x position of the gutter to the left of regions (aws), locations (azure), and networks (gcp)
  const sourceBottomY = sourceY + nodeHeight / 2; // y position at the bottom/center of the source
  const targetBottomY = targetY + targetNodeHeight / 2; // y position at the bottom/center of the target

  const connectionPoints = [
    [sourceX, sourceBottomY + linkSpacing], // from the bottom/center of the source, go down by 'linkSpacing' value
    [leftGutterX, sourceBottomY + linkSpacing] // turn left and run down the gutter on the left
  ];

  if (targetAnchor === 'bottom') {
    // route points below the target, then up to the bottom/center
    connectionPoints.push([leftGutterX, targetBottomY + linkSpacing * 2]);
    connectionPoints.push([targetX, targetBottomY + linkSpacing * 2]);
  } else {
    // stop at the y center of the target node. From here it'll anchor to the left of the target
    connectionPoints.push([leftGutterX, targetY]);
  }

  return { sourceAnchor: 'bottom', targetAnchor, connectionPoints };
};
