import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Box, Button, Callout, Container, Card, Flex, Heading, Text, Spinner } from 'core/components';
import { InputGroup } from 'core/form';
import React, { Component, createRef } from 'react';
import { isEmpty } from 'lodash';
import { ENTER, ESC } from 'core/util/keyCodes';
import { computed } from 'mobx';
import PromptToQueryModel from 'app/views/core/journeys/PromptToQueryModel';
import QueryAssistantTipsDialog from 'app/views/core/journeys/QueryAssistantTipsDialog';
import PromptToQueryDialog from './PromptToQueryDialog';
import QueryGrid from './QueryGrid';

@inject('$auth', '$metrics')
@withRouter
@observer
export default class MetricsExplorerNLQueryPrompt extends Component {
  state = {
    nlQuery: '',
    history: [],
    showFullHistory: false,
    hasCompletionError: false,
    loading: false,
    isFeedbackOpen: false,
    isTipsDialogOpen: false,
    historyVisible: false,
    defaultSuggestions: [
      'Which devices have the highest P95 and avg system CPU utilization?',
      'Interface Utilization by device',
      'Interfaces with Admin Status up and Operational Status Down',
      'Device uptime and availability with OS name, version and IP',
      'BGP prefixes sent and received by for the last 24 hours',
      'Top five devices by used system memory P95 for the last 3 hours'
    ]
  };

  containerRef = createRef(null);

  componentDidMount() {
    let history = [];
    const { location } = this.props;
    if (window.localStorage) {
      const storedHistory = window.localStorage[this.getHistoryKey()];
      if (storedHistory) {
        history = storedHistory.split(',');
      }
    }

    const model = location?.state?.model;
    const nlQuery = model ? model.prompt : '';
    this.setState({ history, nlQuery, model: new PromptToQueryModel(model) });

    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location !== prevProps.location && location?.state?.model) {
      const { model } = location.state;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ nlQuery: model.prompt, model: new PromptToQueryModel(model) });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  getHistoryKey() {
    const { $auth } = this.props;
    return `kentik_metrics_recentQueries_${$auth.getActiveUserProperty('id')}`;
  }

  storeQuery(query) {
    const { history } = this.state;

    // dont store duplicates or pure suggested queries
    if (!history.includes(query)) {
      history.unshift(query);
    }
    if (history.includes(query)) {
      history.splice(history.indexOf(query), 1);
      history.unshift(query);
      this.updateLocalHistory();
    }
  }

  updateLocalHistory() {
    const { history } = this.state;
    if (window.localStorage) {
      window.localStorage.setItem(this.getHistoryKey(), history.toString());
    }
  }

  clearHistory() {
    this.setState({ history: [] });
    if (window.localStorage) {
      window.localStorage.removeItem(this.getHistoryKey());
    }
  }

  @computed
  get isReadyToThink() {
    const { nlQuery } = this.state;
    return String(nlQuery).trim().split(' ').join('').length >= 3;
  }

  @computed
  get shortHistory() {
    const { history } = this.state;
    const maxIdx = Math.min(history.length, 5);
    const shortHistory = [];
    for (let i = 0; i < maxIdx; i++) {
      shortHistory.push(history[i]);
    }
    return shortHistory;
  }

  @computed
  get isEmptyHistory() {
    const { history } = this.state;
    return history.length === 0;
  }

  @computed
  get isLongHistory() {
    const { history } = this.state;
    return history.length > 5;
  }

  @computed
  get excessHistory() {
    const { history } = this.state;
    return history.length - 5;
  }

  handleClickOutside = (event) => {
    if (this.containerRef.current && !this.containerRef.current.contains(event.target)) {
      this.handleBlur();
    }
  };

  handleNLQueryChange = (e) => {
    e.preventDefault();
    this.setState({ nlQuery: e.target.value, model: null });
  };

  setNlQuery = (queryText, sendPrompt = false) => {
    document.getElementById('nlQueryInputBox').focus();
    this.setState({ nlQuery: queryText, model: null, hasCompletionError: false }, () => {
      if (sendPrompt) {
        this.sendPrompt();
      }
    });
  };

  parseNLQueryToKMQ = (prompt) => {
    const model = new PromptToQueryModel();

    return model.save({ prompt }, { toast: false }).then(({ is_completion_valid, completion, query }) => {
      this.setState({ loading: false });

      if (is_completion_valid && !isEmpty(completion) && !isEmpty(query)) {
        return { query, model };
      }

      return Promise.reject(new Error());
    });
  };

  onKeyDown = (e) => {
    const key = e.keyCode ? e.keyCode : e.which;
    this.setState({ hasCompletionError: false });
    if (key === ENTER) {
      this.sendPrompt();
    }
    if (key === ESC) {
      this.handleBlur();
    }
  };

  toggleShowFullHistory = () => {
    const { showFullHistory } = this.state;
    this.setState({ showFullHistory: !showFullHistory });
  };

  sendPrompt = () => {
    const { $metrics, history } = this.props;
    const { nlQuery } = this.state;

    if (this.isReadyToThink) {
      this.handleBlur();
      const prompt = String(nlQuery).trim().substring(0, 250);

      if (prompt.length >= 3) {
        this.setState({ loading: true });

        this.parseNLQueryToKMQ(prompt)
          .then(({ query, model }) => {
            this.storeQuery(prompt);

            $metrics.queryToHash(query).then((hash) => {
              history.push(`/v4/nms/explorer/${hash}`, { query, model: model.serialize(), isSidebarOpen: true });
            });
          })
          .catch(() => {
            this.setState({ hasCompletionError: true });
          });
      }
    }
  };

  handleFocus = () => {
    const { slimMode } = this.props;
    if (slimMode) {
      this.setState({ historyVisible: true });
    }
  };

  handleBlur = () => {
    this.setState({ historyVisible: false });
  };

  handleShowTipsDialog = () => {
    this.setState({ isTipsDialogOpen: true });
  };

  handleCloseTipsDialog = () => {
    this.setState({ isTipsDialogOpen: false });
  };

  handleClickRating = (user_rating) => {
    const { model } = this.state;
    return model.save({ user_rating }, { sendProvidedValuesOnly: true, toast: false, optimistic: true });
  };

  handleClickComment = () => {
    const { isFeedbackOpen } = this.state;
    this.setState({ isFeedbackOpen: !isFeedbackOpen });
  };

  handleCloseDialog = () => {
    const { isFeedbackOpen } = this.state;
    this.setState({ isFeedbackOpen: !isFeedbackOpen });
  };

  render() {
    const {
      nlQuery,
      history,
      defaultSuggestions,
      showFullHistory,
      hasCompletionError,
      loading,
      model,
      isFeedbackOpen,
      isTipsDialogOpen,
      historyVisible
    } = this.state;
    const { slimMode, $metrics } = this.props;
    return (
      <Container maxWidth={slimMode ? '100%' : 1250} width="100%">
        <Flex flexDirection="column" alignItems="center" mb={2}>
          {!slimMode ? (
            <>
              <Heading level={1} textAlign="center" mb={2} mt={4} fontWeight={800}>
                Query Assistant
              </Heading>
            </>
          ) : null}
          <Box
            position="relative"
            width="100%"
            height={40}
            zIndex={6}
            ref={this.containerRef}
            maxWidth={slimMode ? '100%' : 800}
          >
            <Card
              position="absolute"
              width="100%"
              maxHeight={600}
              overflow="auto"
              elevation={historyVisible ? 4 : 0}
              p={historyVisible ? 2 : 0}
              border={historyVisible ? 'thick' : 'none'}
              style={!historyVisible ? { boxShadow: 'none' } : null}
            >
              <InputGroup
                id="nlQueryInputBox"
                leftIcon={
                  <span className="bp4-icon" role="img" aria-label="magic">
                    ✨
                  </span>
                }
                value={nlQuery || ''}
                onChange={this.handleNLQueryChange}
                onFocus={this.handleFocus}
                onKeyDown={this.onKeyDown}
                className="jumbo"
                placeholder="Query data effortlessly with natural language"
                fill={slimMode}
                autoComplete="off"
                disabled={$metrics.deviceCollection.size === 0}
                rightElement={
                  <Flex height="100%" alignItems="center" pr="6px">
                    {loading ? (
                      <Spinner size={16} />
                    ) : (
                      <Flex alignItems="center" minimal gap={1}>
                        <Button icon="help" onClick={this.handleShowTipsDialog} small minimal>
                          <Text muted>Tips for building good prompts</Text>
                        </Button>
                        <Button
                          minimal
                          rightIcon="send-message"
                          title="Submit"
                          onClick={() => this.sendPrompt()}
                          disabled={$metrics.deviceCollection.size === 0}
                        />
                        {model && !model?.isNew && (
                          <Button
                            icon="thumbs-up"
                            onClick={() => this.handleClickRating(3)}
                            active={model?.get('user_rating') === 3}
                            title="Happy with result"
                            minimal
                          />
                        )}
                        {model && !model?.isNew && (
                          <Button
                            icon="thumbs-down"
                            onClick={() => this.handleClickRating(1)}
                            active={model?.get('user_rating') === 1}
                            title="Not satisfied with result"
                            minimal
                          />
                        )}

                        {model && !model?.isNew && (
                          <Button title="Provide feedback" icon="comment" minimal onClick={this.handleClickComment} />
                        )}
                      </Flex>
                    )}
                  </Flex>
                }
              />
              <Box display={historyVisible ? 'block' : 'none'} p={2}>
                <Flex gap={2} justifyContent="center">
                  <Flex flexDirection="column" mt={1} gap={1} width="475px">
                    <Heading textAlign="center" level={5} m={0}>
                      Examples
                    </Heading>
                    {Array.from(defaultSuggestions).map((suggestion) => (
                      <Card p={1} interactive key={suggestion} onClick={() => this.setNlQuery(suggestion, true)}>
                        <Text as="div">{suggestion}</Text>
                      </Card>
                    ))}
                  </Flex>
                  <Flex flexDirection="column" mt={1} gap={1} width="475px" alignItems="center">
                    <Heading textAlign="center" level={5} m={0}>
                      Recent
                    </Heading>
                    {!this.isEmptyHistory &&
                      !showFullHistory &&
                      Array.from(this.shortHistory).map((recentQuery) => (
                        <Card
                          p={1}
                          width="100%"
                          interactive
                          key={recentQuery}
                          onClick={() => this.setNlQuery(recentQuery, true)}
                        >
                          <Text as="div">{recentQuery}</Text>
                        </Card>
                      ))}
                    {showFullHistory &&
                      Array.from(history).map((recentQuery) => (
                        <Card
                          p={1}
                          width="100%"
                          interactive
                          key={recentQuery}
                          onClick={() => this.setNlQuery(recentQuery, true)}
                        >
                          <Text as="div">{recentQuery}</Text>
                        </Card>
                      ))}
                    {this.isEmptyHistory && (
                      <Text as="div" muted textAlign="center">
                        Your recent queries will be right here, easily accessible whenever you need them!
                      </Text>
                    )}
                    {!this.isEmptyHistory && (
                      <Flex justifyContent="space-between" width="100%">
                        <Button onClick={() => this.clearHistory()}>Clear History</Button>
                        {this.isLongHistory && !showFullHistory && (
                          <Button onClick={() => this.toggleShowFullHistory()} p={1}>
                            Show {this.excessHistory} More
                          </Button>
                        )}
                        {this.isLongHistory && showFullHistory && (
                          <Button onClick={() => this.toggleShowFullHistory()} p={1}>
                            Hide Full History
                          </Button>
                        )}
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </Box>
            </Card>
          </Box>
          <Box>
            {hasCompletionError ? (
              <Callout intent="warning" as="div" mt={1}>
                We had trouble understanding your query. Please see examples and try again.
              </Callout>
            ) : null}
          </Box>
        </Flex>
        <QueryAssistantTipsDialog isOpen={isTipsDialogOpen} onClose={this.handleCloseTipsDialog} />
        {!slimMode ? <QueryGrid setNlQuery={this.setNlQuery} /> : null}
        {isFeedbackOpen ? <PromptToQueryDialog model={model} onClose={this.handleCloseDialog} /> : null}
      </Container>
    );
  }
}
