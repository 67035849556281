export const QUERY_NAMESPACE = 'AWS/DX';

/*
  Based on aws portal api calls
*/
export const METRICS = [
  'ConnectionBpsEgress',
  'ConnectionPpsEgress',

  'ConnectionBpsIngress',
  'ConnectionPpsIngress',

  'ConnectionErrorCount',
  'ConnectionCRCErrorCount',

  'ConnectionLightLevelTx',
  'ConnectionLightLevelRx',

  'ConnectionState',
  'ConnectionEncryptionState'
];

export const generateDimensionsByEntity = (nodeData) => [
  {
    Name: 'ConnectionId',
    Value: nodeData?.id
  }
];
