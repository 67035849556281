import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Button } from 'core/components';

@observer
export default class HybridTopoSidebar extends React.Component {
  render() {
    const { sidebarDetailsPanel, onPopConfig, ...boxProps } = this.props;
    const panels = Array.isArray(sidebarDetailsPanel) ? sidebarDetailsPanel : [sidebarDetailsPanel];

    return (
      <Box position="relative" width={475} height="100%" bg="inherit" {...boxProps}>
        {panels.length > 1 && (
          <div style={{ position: 'absolute', top: '16px', left: '16px', zIndex: 1 }}>
            <Button minimal icon="chevron-left" onClick={onPopConfig} />
          </div>
        )}
        {panels.map((panel, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index} position="absolute" width="100%" height="100%" bg="inherit" overflow="hidden scroll">
            {panel}
          </Box>
        ))}
      </Box>
    );
  }
}
