import * as React from 'react';
import { inject, observer } from 'mobx-react';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import { EmptyState } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import { AZURE_ENTITY_TYPES } from 'shared/hybrid/constants';
import SearchableTable from 'app/views/hybrid/maps/components/SearchableTable';

@inject('$hybridMap')
@observer
export default class FrontendIPConfigurationsPanel extends React.Component {
  state = {
    loading: true,
    isPoppedOut: false,
    data: null
  };

  componentDidUpdate(prevProps, prevState) {
    const { nodeData } = this.props;
    const { data, isPoppedOut } = this.state;
    const popOutChange = prevState.isPoppedOut !== isPoppedOut;

    if (prevProps.nodeData?.id !== nodeData?.id || (data === null && popOutChange)) {
      // if we have the sidebar open and switch to a new load balancer, fetch for new data
      this.fetchConfigs();
    }
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.cancel();
    }
  }

  fetchConfigs = () => {
    const { $hybridMap, nodeData } = this.props;

    this.setState({ loading: true }, () => {
      this.request = makeCancelable($hybridMap.getAzureFrontendIPConfigurations({ id: nodeData.id }));

      this.request.promise
        .then((response) => {
          this.setState({ loading: false, data: response });
        })
        .catch((e) => {
          if (e instanceof CanceledError === false) {
            this.setState({ loading: false }, () => console.error(e));
          }
        });
    });
  };

  handleExpandChange = (isOpen) => {
    const { data } = this.state;

    if (isOpen && data === null) {
      // fetch for data when expanding for the first time
      this.fetchConfigs();
    }
  };

  get columns() {
    return [
      {
        name: 'frontendName',
        label: 'Name'
      },

      {
        name: 'ipAddress',
        label: 'IP Address',
        renderer: ({ model }) => {
          const publicIP = model.get('publicIPAddress');
          const privateIP = model.get('privateIPAddress');
          const ipName = model.get('ipName');
          const ipAddress = publicIP || privateIP;

          if (ipAddress) {
            return `${ipAddress} ${ipName ? `(${ipName})` : ''}`;
          }

          return '--';
        }
      }
    ];
  }

  handlePopOutChange = (isPoppedOut) => {
    this.setState({ isPoppedOut }, this.handleExpandChange);
  };

  render() {
    const { cloudProvider, width, popoutTitle } = this.props;
    const { loading, data } = this.state;

    return (
      <SidebarItem
        excludeFormProps
        title="Frontend IP Configurations"
        dialogProps={{ width }}
        popoutTitle={popoutTitle}
        onExpandChange={this.handleExpandChange}
        onPopOutChange={this.handlePopOutChange}
        filledIconTag={false}
        icon={
          <CloudIcon
            cloudProvider="azure"
            entity={AZURE_ENTITY_TYPES.PUBLIC_IP_ADDRESSES}
            style={{ marginTop: '3px' }}
          />
        }
      >
        <SearchableTable
          height={240}
          loading={loading}
          columns={this.columns}
          data={data || []}
          stickyHeader
          stickyGroups
          emptyState={
            <EmptyState
              title="No Results"
              icon={
                <CloudIcon
                  cloudProvider={cloudProvider}
                  entity={AZURE_ENTITY_TYPES.PUBLIC_IP_ADDRESSES}
                  iconSize={48}
                />
              }
            />
          }
        />
      </SidebarItem>
    );
  }
}
