/*
  A fallback summary for cloud entities
*/
import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, Text } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import Header from './Header';

function DefaultSummary(config) {
  const { details, cloudProvider, nodeData, type, value } = config;

  // details most likely set in configHelper (src/app/views/hybrid/maps/components/popovers/configHelper.js)
  if (details) {
    const {
      node,
      keys,
      renderItem,
      panelTitle = 'Details',
      collapsedPanelTitle,
      expandedPanelTitle,
      ...headerProps
    } = details;
    const headerPassThroughProps = { ...headerProps, cloudProvider, nodeData, type, value };

    return (
      <Box>
        <Header {...headerPassThroughProps} />
        {node && (
          <SidebarItem
            title={panelTitle}
            collapsedPanelTitle={collapsedPanelTitle}
            expandedPanelTitle={expandedPanelTitle}
            icon="more"
            popoutTitle={DefaultSummary.PopoutTitle(config)}
            openOnMount
          >
            <CloudMetadata node={node} keys={keys} renderItem={renderItem} />
          </SidebarItem>
        )}
      </Box>
    );
  }

  return null;
}

DefaultSummary.PopoutTitle = (config) => {
  const { details } = config;

  if (details) {
    return (
      <Flex alignItems="center">
        <CloudIcon {...details.icon} />
        <Text ml={1}>{details.subTitle}</Text>
      </Flex>
    );
  }

  return null;
};

export default observer(DefaultSummary);
