import onboardingGradientBackground from 'app/assets/onboarding/onboarding-bg.jpg';
import styled from 'styled-components';
import Flex from 'core/components/Flex';
import React from 'react';

const LoginWrapperContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: url(${onboardingGradientBackground});
  background-size: cover;
  position: fixed;
  top: 0;
`;

export const LoginWrapper = ({ children }) => (
  <LoginWrapperContainer>
    <Flex flexDirection="column" height="75vh" width="100vw" justifyContent="center">
      {children}
    </Flex>
  </LoginWrapperContainer>
);
