const fields = {
  company_plan_id: {
    label: 'Account Type'
  },
  company_status: {
    label: 'Company Status'
  },
  company_max_user: {
    label: 'Max Users',
    rules: 'min:0'
  },
  trial_end_date: {
    label: 'Trial End Date'
  },
  platform: {
    defaultValue: 'custom',
    label: 'Platform'
  },
  'settings.excludeDataRights': {
    defaultValue: false,
    label: 'Exclude Data Rights'
  },
  'settings.allowBGPSharing': {
    defaultValue: false,
    label: 'Allow BGP Sharing'
  },
  'settings.tags': {
    defaultValue: [],
    label: 'Tags',
    options: [
      'Adtech',
      'CDN',
      'Cloud',
      'Collaboration',
      'Content Provider',
      'eCommerce',
      'Education',
      'Gaming',
      'SaaS',
      'Security',
      'SP',
      'SP-Eyeball',
      'SP-Transit',
      'US-Content',
      'US-General',
      'US-SP'
    ].map(option => ({ label: option, value: option.toLowerCase() }))
  }
};

const options = { name: 'Company' };
module.exports = { fields, options };
