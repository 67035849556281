import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import storeLoader from 'app/stores/storeLoader';
import { withRouter } from 'react-router-dom';
import NotFound from 'app/views/NotFound';
import SharedPage from 'app/components/page/SharedPage';
import SharedLinkHeader from 'app/views/sharedLinks/SharedLinkHeader';
import TestResults from 'app/views/synthetics/TestResults';

@storeLoader('$syn.agents', '$syn.tests', '$syn.communityPerformanceTests')
@inject('$sharedLinks')
@observer
@withRouter
class SharedTestResults extends Component {
  state = {};

  constructor(props) {
    super(props);

    const { $syn, $sharedLinks } = this.props;
    const { test_id } = $sharedLinks.data.metadata;
    const testModel = $syn.tests.get(test_id) || $syn.communityPerformanceTests.get(test_id);
    const { start_time: startDate, end_time: endDate } = $sharedLinks._sharedLink.metadata;

    testModel?.results.configure({ startDate, endDate, lookbackSeconds: null, usePageComponent: false, shared: true });

    this.state.testModel = testModel;
  }

  render() {
    const { $syn, $sharedLinks, match } = this.props;
    const { testModel } = this.state;
    const { start_time: startDate, end_time: endDate } = $sharedLinks._sharedLink.metadata;

    // avoid race condition
    // urlHash is specific to Link Sharing -- adding the check here prevents a race condition
    // where Link Sharing APIs have yet to verify/validate the provided hash (and have their own 404 workflow)
    // and we don't necessarily want this component to update the browser's title yet
    if ($syn.tests.lastFetched === undefined || $syn.tests.loading || !match.params.urlHash) {
      return <></>;
    }

    if (!startDate || !endDate) {
      return <></>;
    }

    if (!testModel) {
      return <NotFound />;
    }

    return (
      <SharedPage title={testModel.get('display_name')}>
        <SharedLinkHeader isSynthetic />
        <TestResults
          test={testModel}
          startDate={startDate}
          endDate={endDate}
          lookbackSeconds={null}
          allowSettings={false}
          usePageComponent={false}
          shared
        />
      </SharedPage>
    );
  }
}

export default SharedTestResults;
