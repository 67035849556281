import React from 'react';
import { Text, Icon, Box, Flex } from 'core/components';
import ResultMenuItem from './ResultMenuItem';

function CDN({ item, selected, query, IconCmp, onItemClick, ...rest }) {
  const { name, id, path, metadata = {} } = item;
  const { cdn_type } = metadata;

  return (
    <ResultMenuItem
      {...rest}
      selected={selected}
      onClick={() => onItemClick({ type: 'cdn', id, path }, 'Search Results')}
    >
      <Flex gridArea="icon" alignItems="center" justifyContent="center">
        <IconCmp iconSize={22} />
      </Flex>

      <Box gridArea="title">
        <Text className="title">{name}</Text>
      </Box>

      <Flex gridArea="rightIcon" alignItems="center" justifyContent="center">
        <Icon icon={selected ? 'key-enter' : undefined} color="#ffffff" />
      </Flex>

      <Text
        className="description"
        gridArea="description"
        muted
        small
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        style={{ overflow: 'hidden' }}
      >
        {Array.isArray(cdn_type) && cdn_type.join(' ')}
        {' CDN'}
      </Text>
    </ResultMenuItem>
  );
}

export default CDN;
