import { Flex, Icon, Text } from 'core/components';
import React from 'react';

export const KentikAgentOptionRenderer = (option) => {
  const { label, value, selectItem, className, host_ip, status } = option;

  return (
    <Flex
      className={className}
      alignItems="center"
      justifyContent="space-between"
      onClick={() => selectItem(value, option)}
      key={value}
    >
      <Flex flexDirection="column" gap={0}>
        <Text fontWeight="medium">{label}</Text>
        <Text small muted>
          {host_ip}
        </Text>
        <Text small muted>
          Status: {status}
        </Text>
      </Flex>
    </Flex>
  );
};

export const KentikAgentValueRenderer = (option, placeholder) => {
  if (!option) {
    return <Text muted>{placeholder}</Text>;
  }

  const { label, value, selectItem, className, host_ip } = option;

  return (
    <Flex
      className={className}
      alignItems="center"
      justifyContent="space-between"
      onClick={() => selectItem(value, option)}
      key={value}
    >
      <Flex flexDirection="column" gap="2px">
        <Text fontWeight="medium">{label}</Text>
        <Text small muted>
          {host_ip}
        </Text>
      </Flex>
    </Flex>
  );
};

const renderCredentialType = (type) => {
  const isSnmp = type.startsWith('snmp_');
  const isST = type === 'streaming_telemetry';

  return (
    <>
      {isSnmp && `SNMP ${type.replace('snmp_', '')}`}
      {isST && 'Streaming Telemetry'}
      {!isSnmp && !isST && type}
    </>
  );
};

export const CredentialOptionRenderer = (option) => {
  const { label, value, type, className, selectItem } = option;

  return (
    <Flex
      className={className}
      alignItems="center"
      justifyContent="space-between"
      onClick={() => selectItem(value, option)}
      key={value}
    >
      {value === '$NEW' && (
        <Flex gap="4px">
          <Icon name="plus" />
          <Text fontWeight="medium">{label}</Text>
        </Flex>
      )}

      {value !== '$NEW' && (
        <Flex flexDirection="column" gap={0}>
          <Text fontWeight="medium">{label}</Text>
          <Text as="div" small muted style={{ marginTop: -4 }}>
            {renderCredentialType(type)}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export const CredentialValueRenderer = (option, placeholder) => {
  if (!option) {
    return <Text muted>{placeholder || 'Select a value...'}</Text>;
  }

  const { label, type } = option;

  return (
    <Flex flexDirection="column" gap="2px">
      <Text fontWeight="medium">{label}</Text>
      <Text as="div" small muted>
        {renderCredentialType(type)}
      </Text>
    </Flex>
  );
};

export const CredentialMultiValueRenderer = (option, placeholder) => {
  if (!option) {
    return <Text muted>{placeholder || 'Select a value...'}</Text>;
  }

  const { label, type } = option;

  return (
    <Flex flexDirection="column" mr={2}>
      <Text fontWeight="medium">{label}</Text>
      <Text as="div" small muted>
        {renderCredentialType(type)}
      </Text>
    </Flex>
  );
};
