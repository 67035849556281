import { groupBy } from 'lodash';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { SizeMe } from 'react-sizeme';
import { AiOutlineMenuFold } from 'react-icons/ai';

import Page from 'app/components/page/Page';
import PageHeading from 'app/components/page/PageHeading';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { Box, Text, Flex, Icon, Button, LinkButton, Suspense, Spinner, Dialog } from 'core/components';
import withHybridTopoSettings from 'app/views/hybrid/maps/components/settingsToolbar/withHybridTopoSettings';

import { ReactComponent as PodsIcon } from 'app/assets/icons/kubernetes/pod.svg';
import { ReactComponent as NodeIcon } from 'app/assets/icons/kubernetes/node.svg';
import { ReactComponent as ClusterIcon } from 'app/assets/icons/kubernetes/cluster.svg';
import KubeMap from '../maps/kube/KubeMap';
import KubeHealth from '../maps/kube/summary/Health';
import KubeProblems from '../maps/kube/summary/Problems';
import KubeInventoryBlock from '../maps/kube/summary/InventoryBlock';
import HybridTopoSidebar from '../maps/components/HybridTopoSidebar';
import HybridTopoSettingsToolbar from '../maps/components/settingsToolbar/HybridTopoSettingsToolbar';
import withSidebarDetails from '../maps/components/popovers/withSidebarDetails';

export const statLabels = { POD: 'Pods', NODE: 'Nodes', CLUSTER: 'Clusters' };
export const statIcons = { POD: PodsIcon, NODE: NodeIcon, CLUSTER: ClusterIcon };

const { CLUSTER, NODE, POD } = ENTITY_TYPES.get('kube');

@withHybridTopoSettings
@withSidebarDetails
@inject('$hybridMap')
@observer
class Kube extends Component {
  state = {
    drawerIsOpen: true
  };

  static getDerivedStateFromProps(props) {
    if (!props.sidebarDetailsPanel) {
      return { drawerIsOpen: false };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarDetailsPanel } = this.props;

    // if we're setting new details and it's not open, open it
    if (!prevState.drawerIsOpen && sidebarDetailsPanel) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ drawerIsOpen: true });
    }
  }

  handleDrawerToggle = () => this.setState(({ drawerIsOpen }) => ({ drawerIsOpen: !drawerIsOpen }));

  render() {
    const { $hybridMap, settingsModel, sidebarSettings, saveSettings, setSidebarDetails, sidebarDetailsPanel } =
      this.props;
    const { drawerIsOpen } = this.state;

    const { loading: isLatencyLoading, highLatencyItems, kubeInitializationFailed } = $hybridMap.kubeState;
    const { showProblematicPods } = $hybridMap.kubeStateAlt;

    const { Entities } = $hybridMap.kubeCloudMapCollection;
    const hasFetched = Object.keys(Entities ?? {}).length > 0;

    const podsCount = Object.keys(Entities?.[POD] ?? {}).length;
    const nodsCount = Object.keys(Entities?.[NODE] ?? {}).length;
    const clustersCount = Object.keys(Entities?.[CLUSTER] ?? {}).length;

    return (
      <Page
        title="Kentik Kube"
        docTitle="Kentik Kube Map"
        drawerContents={<HybridTopoSidebar sidebarDetailsPanel={sidebarDetailsPanel} />}
        drawerIsOpen={drawerIsOpen}
        drawerOnClose={(e) => {
          if ((e && e.key === 'Escape' && drawerIsOpen) || (e && e.type === 'click')) {
            this.handleDrawerToggle();
          }
        }}
        drawerProps={{ extraOffset: 43, showCloseButton: true }}
        subnavTools={
          <Button
            text="Details"
            icon={AiOutlineMenuFold}
            ml="2px"
            active={drawerIsOpen}
            onClick={this.handleDrawerToggle}
            disabled={!sidebarDetailsPanel}
            minimal
          />
        }
        toolbar={
          <HybridTopoSettingsToolbar
            onSettingsUpdate={saveSettings}
            model={settingsModel}
            collection={$hybridMap.kubeCloudMapCollection}
          />
        }
        scrolls
        minHeight="auto"
      >
        {hasFetched && !kubeInitializationFailed && (
          <Flex alignItems="center" mb={1}>
            <PageHeading title="Kentik Kube" mb={0} mr={4} />

            <Suspense loading={isLatencyLoading} fallback={<Spinner size={20} />}>
              <Box mx={2}>
                <KubeHealth>
                  {() => {
                    const total = highLatencyItems.length;

                    if (total === 0) {
                      return (
                        <Flex gap={1} alignItems="center">
                          <Icon icon="tick-circle" color="success" />
                          <Box>No issues found</Box>
                        </Flex>
                      );
                    }

                    const itemsByType = groupBy(highLatencyItems, 'type');
                    const problemTypes = Object.keys(itemsByType);
                    const firstType = problemTypes[0];

                    return (
                      <LinkButton
                        icon="error"
                        minimal
                        intent="primary"
                        onClick={$hybridMap.toggleProblematicPodsDialog}
                      >
                        {problemTypes.length === 1 ? (
                          <>
                            <Text fontWeight="bold">
                              {itemsByType[firstType].length} {firstType}
                            </Text>{' '}
                            with high latency
                          </>
                        ) : (
                          <>
                            <Text fontWeight="bold">
                              {total} issue{total === 1 ? '' : 's'}
                            </Text>{' '}
                            found
                          </>
                        )}
                      </LinkButton>
                    );
                  }}
                </KubeHealth>
              </Box>

              <Flex justifyContent="space-between" width={240} mx={2}>
                <KubeInventoryBlock label={statLabels.POD} icon={statIcons.POD} count={podsCount} />
                <KubeInventoryBlock label={statLabels.NODE} icon={statIcons.NODE} count={nodsCount} />
                <KubeInventoryBlock label={statLabels.CLUSTER} icon={statIcons.CLUSTER} count={clustersCount} />
              </Flex>
            </Suspense>
          </Flex>
        )}
        {showProblematicPods && (
          <Dialog
            title="Kubernetes Problems"
            isOpen={showProblematicPods}
            onClose={$hybridMap.toggleProblematicPodsDialog}
            width={1100}
            pb={0}
            overflow="hidden"
          >
            <KubeProblems setSidebarDetails={setSidebarDetails} />
          </Dialog>
        )}
        <SizeMe monitorWidth noPlaceholder>
          {({ size }) => (
            <Box flex={1} mb={1}>
              <KubeMap
                width={size.width}
                sidebarSettings={sidebarSettings}
                setSidebarDetails={setSidebarDetails}
                drawerIsOpen={drawerIsOpen}
              />
            </Box>
          )}
        </SizeMe>
      </Page>
    );
  }
}

export default Kube;
