import React from 'react';
import classNames from 'classnames';

import { ReactComponent as RouterIcon } from 'app/assets/icons/router_icon.svg';
import { ReactComponent as SiteIcon } from 'app/assets/icons/map_pin.svg';
import { ReactComponent as SwitchLeafIcon } from 'app/assets/icons/switch_leaf_icon.svg';
import { ReactComponent as InterfaceIcon } from 'app/assets/icons/interface_icon.svg';
import { ReactComponent as KubeNodeIcon } from 'app/assets/icons/kubernetes/node.svg';
import { ReactComponent as KubeNodeHoveredIcon } from 'app/assets/icons/kubernetes/node_hovered.svg';
import { ReactComponent as KubeNodeProblem } from 'app/assets/icons/kubernetes/node_problem.svg';

import { ReactComponent as KubePodIcon } from 'app/assets/icons/kubernetes/pod.svg';
import { ReactComponent as KubePodHoveredIcon } from 'app/assets/icons/kubernetes/pod_hovered.svg';
import { ReactComponent as KubePodIconProblem } from 'app/assets/icons/kubernetes/pod_problem.svg';
import { ReactComponent as CustomerGatewayIcon } from 'app/assets/icons/customer-gateway.svg';

const types = {
  site: SiteIcon,
  router: RouterIcon,
  leaf: SwitchLeafIcon,
  interface: InterfaceIcon,
  customerGateway: CustomerGatewayIcon,
  kubeNode: KubeNodeIcon,
  kubePod: KubePodIcon
};

const hoveredTypes = {
  kubeNode: KubeNodeHoveredIcon,
  kubePod: KubePodHoveredIcon
};

const warningTypes = {
  kubeNode: KubeNodeProblem,
  kubePod: KubePodIconProblem
};

const criticalTypes = {
  kubeNode: KubeNodeProblem,
  kubePod: KubePodIconProblem
};

function ArcLayoutIcon({ icon, type, hovered, health, iconSize, point, fill, x: iconX, y: iconY, className }) {
  const x = iconX ? iconX - iconSize / 2 : point.x - iconSize / 2;
  const y = iconY ? iconY - iconSize / 2 : point.y - iconSize / 2;

  let finalIcon = icon || types[type];

  // for more complicated icons that have internal strokes/fills, we can't just rely on CSS to
  // override colors, like in the case of Kube icons.
  // if `hovered` or `health` is passed in, you can provide a different icon component to render.
  if (hovered) {
    finalIcon = hoveredTypes[type] || finalIcon;
  }

  if (health === 'warning') {
    finalIcon = warningTypes[type] || finalIcon;
  }

  if (health === 'critical') {
    finalIcon = criticalTypes[type] || finalIcon;
  }

  if (!finalIcon) {
    return null;
  }

  return React.createElement(finalIcon, {
    className: classNames('arclayout-item-icon', type, className),
    width: iconSize,
    height: iconSize,
    strokeDasharray: 0,
    fill,
    x,
    y
  });
}

ArcLayoutIcon.defaultProps = {
  iconSize: 36,
  point: { x: 0, y: 0 }
};

export default ArcLayoutIcon;
