import * as React from 'react';
import { observer } from 'mobx-react';
import { Grid, Heading, Icon } from 'core/components';
import { getConnectionKey } from 'app/views/hybrid/utils/links';
import InterfaceMetadataDetails from './InterfaceMetadataDetails';
import InterfaceMetadataCard from './InterfaceMetadataCard';

@observer
export default class InterfaceMetadata extends React.Component {
  static defaultProps = {
    headingOffset: -16
  };

  get selectedConnections() {
    const { connections, selectedKey } = this.props;

    if (selectedKey && connections) {
      const allConnections = [].concat(connections.layer3, connections.layer2);
      const connectionMatch = allConnections.find((c) => selectedKey === getConnectionKey(c));

      if (connectionMatch) {
        return [connectionMatch];
      }
    }

    return [];
  }

  renderMetadata = (connections, { title, showLayer = false } = {}) => {
    const { headingOffset } = this.props;

    if (connections && connections.length > 0) {
      const { headingProps, selectedKey } = this.props;

      return (
        <>
          {title && (
            <Heading pb={1} pt={1} level={5} style={{ position: 'sticky', top: headingOffset }} {...headingProps}>
              {title}
            </Heading>
          )}
          {connections.map((connection) => {
            const key = getConnectionKey(connection);

            return (
              <InterfaceMetadataDetails
                key={key}
                interface1={connection.interface1}
                interface2={connection.interface2}
                layer={connection.layer}
              >
                {({ interface1Cmp, interface2Cmp }) => (
                  <InterfaceMetadataCard p={2} mb={1} m="1px" selected={key === selectedKey}>
                    {showLayer && <Heading level={6}>{`Layer ${connection.layer}`}</Heading>}
                    <Grid
                      gridGap="5px"
                      alignItems="flex-start"
                      gridTemplateColumns="minmax(0, 1fr) auto minmax(0, 1fr)"
                    >
                      {interface1Cmp}
                      <Icon icon="arrows-horizontal" style={{ alignSelf: 'center' }} />
                      {interface2Cmp}
                    </Grid>
                  </InterfaceMetadataCard>
                )}
              </InterfaceMetadataDetails>
            );
          })}
        </>
      );
    }

    return null;
  };

  render() {
    const { connections, selectedKey } = this.props;

    if (connections) {
      const layer3 = (connections.layer3 || []).filter((c) => selectedKey !== getConnectionKey(c));
      const layer2 = (connections.layer2 || []).filter((c) => selectedKey !== getConnectionKey(c));

      return (
        <>
          {this.renderMetadata(this.selectedConnections, { title: 'Selected Connection', showLayer: true })}
          {this.renderMetadata(layer3, { title: 'Layer 3' })}
          {this.renderMetadata(layer2, { title: 'Layer 2' })}
          {this.renderMetadata(connections.unknown)}
        </>
      );
    }

    return null;
  }
}
