import React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { get, isEqual, memoize, merge, uniqBy } from 'lodash';
import { darken, opacify } from 'polished';
import { Box, EmptyState, Flex, MenuItem, Spinner, showInfoToast } from 'core/components';
import CloudMapConnector from 'app/views/hybrid/utils/cloud/connector';

import { MAP_TYPES, ENTITY_TYPES } from 'shared/hybrid/constants';
import { ReactComponent as KubeClusterIcon } from 'app/assets/icons/kubernetes/cluster.svg';
import { getASNValues } from 'app/util/queryResults';
import { getCloudPath } from 'app/views/hybrid/utils/path';
import { idToType, getConsoleUrl } from 'app/views/hybrid/utils/aws';
import { generateLinksForHighlightedNode, rewriteFallbackLinks } from 'app/views/hybrid/utils/map';
import {
  GRID_ROWS_GAP,
  ICON_SIZE,
  INTERNET_WIDTH,
  LINK_SPACING,
  LOCATION_PADDING,
  REGION_MARGIN,
  getTotalWidth
} from 'app/views/hybrid/utils/cloud/constants';
import CloudIcon from './components/CloudIcon';

import AbstractMap from './components/AbstractMap';
import CloudRegionItem from './components/CloudRegionItem';
import TopKeys from './components/TopKeys/TopKeys';
import TopLevelBox from './components/TopLevelBox';
import TrafficLinkGenerator from './components/TrafficLinkGenerator';
import PathTraffic from './components/PathTraffic';
import CloudSubnetDestinations from './components/popovers/CloudSubnetDestinations';
import withPopover from './components/popovers/withPopover';
import {
  getCloudGatewayFilterField,
  getCloudSubnetFilterField,
  getInternetDimension,
  getInternetFilterField
} from './components/popovers/queryOptionsHelper';
import ItemGrid from './components/ItemGrid';
import { AWS } from './cloudDimensionConstants';

const { VPC, SUBNET, CORE_NETWORK_ATTACHMENT, CORE_NETWORK_EDGE } = ENTITY_TYPES.get('aws');

// constant to disable hover effect on connections as they create lagging experience for huge companies
const VPC_COUNT_FOR_HUGE_MAP = 100;

@withRouter
@withPopover
@withTheme
@inject('$auth', '$hybridMap')
@observer
export default class CloudAwsMap extends AbstractMap {
  preventHoverEffects = false;

  constructor(props) {
    super(props);

    Object.assign(this.state, {
      loading: true,
      loadingNodeConnections: false,
      selectedVpcs: [], // [VpcId, ...]
      persistantNodeLinks: [],
      boxExpanded: {
        Routers: false,
        internet: false
      }
    });
  }

  componentDidMount() {
    const { $hybridMap } = this.props;

    this.fetchTopology();

    this.topologyFetcherDisposer = reaction(
      () => $hybridMap.hasAwsAccountsDiff,
      () => this.fetchTopology()
    );
  }

  componentWillUnmount() {
    // execute to avoid memory leak
    this.topologyFetcherDisposer();
  }

  componentDidUpdate(prevProps, prevState) {
    const { $hybridMap, cloudProvider, sidebarSettings, drawerIsOpen } = this.props;
    const { selectedNode } = this.state;
    const mapSearchChanged = prevProps.sidebarSettings.searchTerm !== sidebarSettings.searchTerm;
    const timeSettingsChanged =
      prevProps.sidebarSettings.timeRange.start !== sidebarSettings.timeRange.start ||
      prevProps.sidebarSettings.timeRange.end !== sidebarSettings.timeRange.end;
    const drawerChanged = prevProps.drawerIsOpen !== drawerIsOpen;

    if (mapSearchChanged) {
      $hybridMap.awsCloudMapCollection.filter(sidebarSettings.searchTerm);
      this.handleDrawLinksRequest();
    }

    if (drawerChanged) {
      this.getRegionBoxPositions.cache.clear();
      this.getVpcBoxPositions.cache.clear();
    }

    if (prevProps.cloudProvider !== cloudProvider || timeSettingsChanged) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ loading: true }, () => {
        this.fetchTopology();

        if (selectedNode) {
          // deselect the currently selected node to clean up drawn links etc.
          this.handleSelectNode({ type: selectedNode.type, value: selectedNode.value });
        }
      });
    } else {
      super.componentDidUpdate(prevProps, prevState);
    }
  }

  shouldLinksRedraw(prevProps, prevState) {
    const { sidebarSettings } = this.props;
    const { persistantNodeLinks, selectedVpcs, selectedNode, hoveredNode, activeNode, nodeLinks } = this.state;

    if (super.shouldLinksRedraw(prevProps, prevState)) {
      return true;
    }

    if (hoveredNode?.value !== prevState.hoveredNode?.value && (nodeLinks?.length ?? 0) === 0) {
      return true;
    }

    if (!isEqual(activeNode, prevState.activeNode)) {
      return true;
    }

    if (sidebarSettings.showDefaultNetworks !== prevProps.sidebarSettings.showDefaultNetworks) {
      return true;
    }

    return (
      !isEqual(prevState.persistantNodeLinks, persistantNodeLinks) ||
      !isEqual(prevState.selectedVpcs, selectedVpcs) ||
      selectedNode?.value !== prevState.selectedNode?.value
    );
  }

  setNodeLinks = (node) => {
    this.setState((state) => {
      const nodeLinks = node ? this.getNodeLinks(node) : [];
      const nodeLinkQueries = node ? this.getNodeLinkQueries({ ...state, selectedNode: node }) : [];

      return {
        nodeLinks,
        nodeLinkQueries,
        // keep state from Abstract map just in case
        nodeLinksLoading: nodeLinkQueries.length > 0,
        loadingNodeConnections: nodeLinkQueries.length > 0
      };
    });
  };

  handleNodeLinkClick = (config) => {
    const { cloudProvider, isEmbedded, setSidebarDetails } = this.props;
    const { topology } = this.state;

    if (config.pathLink) {
      // if this is a 'show path to' link, use the subnet/destination cidr as source/target
      config.source = config.pathLink.source;
      config.target = config.pathLink.target;
    }

    if (!isEmbedded) {
      setSidebarDetails({
        type: 'link',
        source: config.source,
        target: config.target,
        links: this.nodeLinks,
        cloudProvider,
        topology
      });
    }
  };

  fetchTopology() {
    const { $hybridMap, sidebarSettings } = this.props;

    // clear the link cache
    this.calculateStaticLinks.cache.clear();

    return $hybridMap.awsCloudMapCollection
      .fetch({ force: true, query: sidebarSettings.timeRange, data: { accountIds: $hybridMap.selectedAwsAccounts } })
      .then(() => {
        const { topology } = $hybridMap.awsCloudMapCollection;

        $hybridMap.awsCloudMapCollection.filter(sidebarSettings.searchTerm);

        const boxExpanded = {
          internet: true
        };

        this.setInitialState({
          topology,
          boxExpanded,
          nodeLinkQueries: this.getNodeLinkQueries(),
          loading: false
        });

        if (Object.keys(topology.Entities[VPC]).length > VPC_COUNT_FOR_HUGE_MAP) {
          this.preventHoverEffects = true;
        }

        if (topology.request && topology.request.isTimeRangeOverridden) {
          showInfoToast("We've pulled the latest metadata instead.", {
            title: 'No Metadata Found for Given Time Range'
          });
        }
      })
      .catch((e) => {
        console.error('Error loading topology', e);
        this.setState({ loading: false });
      });
  }

  setInitialState(state) {
    const { $hybridMap } = this.props;
    const { topology } = $hybridMap.awsCloudMapCollection;
    const { initialSidebarNode } = this;

    if (initialSidebarNode) {
      const { type, value } = initialSidebarNode;
      const nodeData = topology.Entities?.[type]?.[value];

      if (nodeData) {
        let sidebarType = type;

        if (MAP_TYPES.get('aws.vpc_connections').includes(type)) {
          sidebarType = 'gateway';

          if (nodeData.VpcId) {
            state.selectedVpcs = [nodeData.VpcId];
          } else if (nodeData.AccepterVpcInfo) {
            state.selectedVpcs = [nodeData.AccepterVpcInfo.VpcId];
          }
        } else if (type === 'TransitGateways') {
          sidebarType = 'gateway';
        } else if (type === 'Vpcs') {
          sidebarType = 'vpc';
          state.selectedVpcs = [value];
        } else if (type === 'Subnets') {
          sidebarType = 'subnet';
          state.selectedVpcs = [nodeData.VpcId];
        }

        return this.setState(state, () => this.handleShowDetails(sidebarType, nodeData));
      }
    }

    return this.setState(state);
  }

  shouldTargetLinkRollUp(type) {
    return ['cloud', 'internet'].includes(type);
  }

  vpcIsOpen(vpcId) {
    const { selectedVpcs } = this.state;
    return selectedVpcs.includes(vpcId);
  }

  getInternetGatewayForVpc(vpcId) {
    const { $hybridMap } = this.props;
    const { topology } = $hybridMap.awsCloudMapCollection;
    const { Entities } = topology;
    const vpc = Entities.Vpcs[vpcId];

    if (vpc) {
      return vpc.InternetGateways?.[0];
    }

    return null;
  }

  rewriteVpcLink(link, key, vpc) {
    const vpcIsOpen = this.vpcIsOpen(vpc.VpcId);
    const otherKey = key === 'source' ? 'target' : 'source';
    const vpcConnectionTypes = MAP_TYPES.get('aws.vpc_connections');

    let gatewayId;

    if (key === 'source') {
      const { target } = link[0];

      if (vpcConnectionTypes.includes(target.type)) {
        gatewayId = target.value;
      }
    } else {
      const { source } = link[link.length - 1];

      if (vpcConnectionTypes.includes(source.type)) {
        gatewayId = source.value;
      }
    }

    return link.map((segment) => {
      if (vpcIsOpen && segment[key].type === 'Vpcs' && segment[otherKey].type === 'TransitGateways') {
        const transitGatewayAttachment = vpc.TransitGatewayAttachments?.find(
          (attachment) => attachment.TransitGatewayId === segment[otherKey].value
        );

        if (transitGatewayAttachment) {
          segment[key].type = 'TransitGatewayAttachments';
          segment[key].value = transitGatewayAttachment.id;
        }
      }

      if (
        !vpcIsOpen &&
        segment[key].value === gatewayId &&
        (segment[otherKey].type !== 'Vpcs' || segment[otherKey].value === vpc.id)
      ) {
        segment[key].original = `${segment[key].type}-${segment[key].value}`;
        segment[key].type = 'region';
        segment[key].value = vpc.RegionName;
      }

      if (
        !vpcIsOpen &&
        segment[otherKey].value === gatewayId &&
        (segment[key].type !== 'Vpcs' || segment[key].value === vpc.id)
      ) {
        segment[otherKey].original = `${segment[otherKey].type}-${segment[otherKey].value}`;
        segment[otherKey].type = 'region';
        segment[otherKey].value = vpc.RegionName;
      }

      return segment;
    });
  }

  getPath(link) {
    const { $hybridMap } = this.props;
    const { topology } = $hybridMap.awsCloudMapCollection;
    const { Entities } = topology;

    const nodes = link.flatMap((segment, idx) => {
      const { source, target } = segment;
      return idx === 0 ? [source, target] : target;
    });

    return nodes.map((node) => {
      const { type, id } = node;
      const entity = Entities[type]?.[id] || {};
      return { ...entity, type: String(type).replace(/s$/, '') };
    });
  }

  styleLink = (link) => {
    const { theme } = this.props;
    const { nodeLinks } = this.state;
    const { source, target } = link;
    let connectionType = 'square';
    const color = theme.name === 'light' ? theme.colors.primary : darken(0.35, opacify(1, theme.colors.primary));

    if (
      (source.type === 'Vpcs' && target.type === 'region') ||
      (source.type === 'region' && target.type === 'Vpcs') ||
      source.type === 'subnet' ||
      target.type === 'subnet' ||
      target.type === 'box' ||
      (target.type === 'TransitGateways' && target.type === 'TransitGateways') ||
      // keep square if no path selected
      (target.type === 'TransitGateways' && nodeLinks.length !== 0) ||
      // need to keep square connection link to onPremNodes
      (source.type === 'TransitGateways' && !MAP_TYPES.get('aws.on_prem').includes(target.type)) ||
      source.type === CORE_NETWORK_ATTACHMENT ||
      target.type === CORE_NETWORK_ATTACHMENT ||
      source.type === CORE_NETWORK_EDGE ||
      target.type === CORE_NETWORK_EDGE
    ) {
      connectionType = 'curved';
    }

    return { connectionType, color, ...link };
  };

  calculateStaticLinks = memoize(
    () => {
      const { $hybridMap } = this.props;
      const { hoveredNode, activeNode } = this.state;
      const { topology = {} } = $hybridMap.awsCloudMapCollection;
      const { Links = [], Entities, Summary = {} } = topology;

      const linksToCompute =
        Summary?.ConnectionLinks?.length &&
        MAP_TYPES.AWS.MAP_TOP_LEVEL_CONNECTIONS.includes(activeNode?.type ?? hoveredNode?.type)
          ? Summary.ConnectionLinks
          : Links;

      return uniqBy(
        linksToCompute.flatMap((link) => {
          let processedLink = link.map((segment) => {
            const { source, target } = segment;
            return { source: { type: source.type, value: source.id }, target: { type: target.type, value: target.id } };
          });

          const firstSegment = processedLink[0];
          const lastSegment = processedLink[processedLink.length - 1];

          if (firstSegment.source.type === 'Vpcs' && lastSegment.target.type === 'Vpcs') {
            const startVpc = Entities.Vpcs[firstSegment.source.value];
            const endVpc = Entities.Vpcs[lastSegment.target.value];

            if (startVpc && endVpc) {
              const isPeering =
                firstSegment.target.type === 'VpcPeeringConnections' &&
                lastSegment.source.type === 'VpcPeeringConnections';

              const startRegion = startVpc.RegionName;
              const endRegion = endVpc.RegionName;

              if (isPeering && startRegion !== endRegion) {
                const startVpcOpen = this.vpcIsOpen(startVpc.VpcId);
                const endVpcOpen = this.vpcIsOpen(endVpc.VpcId);

                const startNode = startVpcOpen
                  ? { ...firstSegment.target, regionName: startRegion }
                  : { type: 'region', value: startRegion };
                const endNode = endVpcOpen
                  ? { ...lastSegment.source, regionName: endRegion }
                  : { type: 'region', value: endRegion };

                processedLink = [
                  { source: firstSegment.source, target: startNode },
                  { source: startNode, target: endNode },
                  { source: endNode, target: lastSegment.target }
                ];
              }
            }
          }

          if (firstSegment.source.type === 'Vpcs') {
            const vpcId = firstSegment.source.value;
            const vpc = get(Entities, `Vpcs.${vpcId}`);

            if (vpc) {
              processedLink = this.rewriteVpcLink(processedLink, 'source', vpc);
            }
          }

          if (lastSegment.target.type === 'Vpcs') {
            const vpcId = lastSegment.target.value;
            const vpc = get(Entities, `Vpcs.${vpcId}`);

            if (vpc) {
              processedLink = this.rewriteVpcLink(processedLink, 'target', vpc);
            }
          }

          const path = this.getPath(link);

          return processedLink.map((segment) => Object.assign(segment, { paths: [path] }));
        }),
        ({ source, target }) =>
          [source, target]
            .sort((a, b) => String(a.value).localeCompare(b.value))
            .map(({ type, value }) => `${type}-${value}`)
            .join('-')
      );
    },
    () => {
      const { selectedVpcs, hoveredNode, activeNode } = this.state;
      return selectedVpcs.join() + (hoveredNode?.value ?? `${activeNode?.value}` ?? '');
    }
  );

  get nodeLinks() {
    const { $hybridMap } = this.props;
    const { hoveredNode, activeNode, persistantNodeLinks = [] } = this.state;
    const { topology = {} } = $hybridMap.awsCloudMapCollection;
    const { Entities } = topology;

    if (!this.shouldRenderLinksBasedOnState) {
      return [];
    }

    const staticLinks = this.calculateStaticLinks();
    const statePathLinks = super.nodeLinks;

    const nodeLinks = statePathLinks.concat(persistantNodeLinks).flatMap((link) => {
      const { source, target, ...rest } = link;
      if (source.type === 'Vpcs' && target.type === 'Vpcs') {
        const sourceIgw = this.getInternetGatewayForVpc(source.value);
        const targetIgw = this.getInternetGatewayForVpc(target.value);
        if (sourceIgw && targetIgw) {
          const sourceRegion = sourceIgw.RegionName;
          const targetRegion = targetIgw.RegionName;
          const sourceVpcOpen = this.vpcIsOpen(source.value);
          const targetVpcOpen = this.vpcIsOpen(target.value);
          const sourceIgwNode = sourceVpcOpen
            ? { type: 'InternetGateways', value: sourceIgw.id }
            : { type: 'region', value: sourceRegion };
          const targetIgwNode = targetVpcOpen
            ? { type: 'InternetGateways', value: targetIgw.id }
            : { type: 'region', value: targetRegion };
          if (sourceRegion === targetRegion) {
            return [
              { ...rest, source, target: sourceIgwNode },
              { ...rest, source: sourceIgwNode, target }
            ];
          }
          return [
            { ...rest, source, target: sourceIgwNode },
            { ...rest, source: sourceIgwNode, target: targetIgwNode },
            { ...rest, source: targetIgwNode, target }
          ];
        }
        return [];
      }

      if (source.type === 'internet' && target.type === 'subnet') {
        const subnetId = link.target.value;
        const subnet = Entities.Subnets[subnetId];
        if (subnet) {
          if (this.vpcIsOpen(subnet.VpcId)) {
            const igw = this.getInternetGatewayForVpc(subnet.VpcId);
            if (igw) {
              return [
                { source, target: { type: 'InternetGateways', value: igw.id }, ...rest },
                { source: { type: 'gateway', value: igw.id }, target, ...rest }
              ];
            }
          }
          const regionNode = { type: 'region', value: subnet.RegionName };
          return [
            { source, target: regionNode, ...rest },
            { source: regionNode, target: { type: 'Vpcs', value: subnet.VpcId }, ...rest }
          ];
        }
        return [];
      }
      return link;
    });

    const allLinks = nodeLinks.concat(staticLinks).map(this.styleLink);
    const linksMap = {};

    allLinks.forEach((link) => {
      const { source, target } = link;

      const key = [source, target]
        .map(({ type, value }) => `${type}-${value}`)
        .sort()
        .join('-');

      const hadOutboundData = Object.prototype.hasOwnProperty.call(link, 'outbound');

      link.inbound = link.inbound || 0;
      link.outbound = link.outbound || 0;
      link.total = link.total || 0;
      link.paths = link.paths || [];

      link.isArrowLink = true;

      // always draw when outbound is not defined
      link.isForwardPath = !hadOutboundData || link.outbound > 0;
      link.isReversePath = link.inbound > 0;

      if (linksMap[key]) {
        const existingLink = linksMap[key];

        if (existingLink.source.value === source.value) {
          existingLink.inbound += link.inbound;
          existingLink.outbound += link.outbound;
        } else {
          existingLink.inbound += link.outbound;
          existingLink.outbound += link.inbound;
        }

        if (link.source.value === existingLink.target.value) {
          existingLink.isReversePath = true;
        }

        existingLink.total += link.total;
        existingLink.paths = existingLink.paths.concat(link.paths);
      } else {
        linksMap[key] = link;
      }
    });

    let resultedLinks = Object.values(linksMap);

    if (activeNode) {
      resultedLinks = generateLinksForHighlightedNode(resultedLinks, activeNode);
    } else if (hoveredNode) {
      resultedLinks = generateLinksForHighlightedNode(resultedLinks, hoveredNode);
    }

    // if rendering path -> only include path link
    if (statePathLinks.length > 0) {
      resultedLinks = resultedLinks.filter(
        // source and target should belong to the path
        (link) =>
          statePathLinks.some((pathLink) => isEqual(pathLink.source, link.source)) &&
          statePathLinks.some((pathLink) => isEqual(pathLink.target, link.target))
      );
    }

    const styledLinks = resultedLinks
      .reverse() // reverse links to ensure path is drawn last
      .map(this.styleLink);

    return rewriteFallbackLinks({ links: styledLinks, mapDocument: this.map.current });
  }

  get autoAnchorLinks() {
    return false;
  }

  getRegionOffset({ type, value, ...data }) {
    const { $hybridMap } = this.props;
    const { topology } = $hybridMap.awsCloudMapCollection;
    const { Entities, Hierarchy } = topology;
    const { Regions } = Hierarchy;

    if (['box', 'internet', 'cloud'].includes(type)) {
      return -Regions.length;
    }

    let regionName;

    if (type === 'region') {
      regionName = value;
    } else if (data.regionName) {
      regionName = data.regionName;
    } else {
      regionName = Entities[type]?.[value]?.RegionName;
    }

    return regionName ? Regions.findIndex((region) => region.Name === regionName) + 1 : 0;
  }

  getNodePosition({ type, value }) {
    const data = super.getNodePosition({ type, value });
    const { points } = data;

    if (points.length === 0) {
      return { points };
    }

    return { ...data, points };
  }

  get isMinifiedMode() {
    const { activeNode, nodeLinks } = this.state;

    return activeNode === null && nodeLinks.length === 0;
  }

  getVpcBoxPositions = memoize(
    ({ source }) => {
      const vpcRect = this.map.current.querySelector('.vpc-mini-map')?.getBoundingClientRect();
      const vpcLeft = vpcRect?.left ?? 0 - source.svg.rect.left;
      const vpcRight = vpcRect?.right ?? 0 - source.svg.rect.left;
      const vpcTop = vpcRect?.top ?? 0 - source.svg.rect.top;
      const vpcBottom = vpcRect?.bottom ?? 0 - source.svg.rect.top;

      const vpcCx = ((vpcRect?.right ?? 0) - (vpcRect?.left ?? 0)) / 2;
      const vpcCy = ((vpcRect?.vpcTop ?? 0) - (vpcRect?.vpcBottom ?? 0)) / 2;

      return { vpcLeft, vpcRight, vpcCx, vpcCy, vpcTop, vpcBottom };
    },
    // vpc box left and right position wont change
    () => this.map.current.querySelector('.vpc-mini-map')?.className
  );

  getNodeLinkPositions(data) {
    const { $hybridMap } = this.props;
    const { topology } = $hybridMap.awsCloudMapCollection;
    const { Hierarchy } = topology;
    const { Regions } = Hierarchy;
    const { linkData, source, target, path, pathIndex } = data;
    const { points: sourcePoints } = source;
    const { points: targetPoints } = target;

    if (sourcePoints.length === 0 || targetPoints.length === 0) {
      return { source, target };
    }

    const maxOffset = Math.floor((REGION_MARGIN - 2) / LINK_SPACING);
    const sourceRegionOffset = this.getRegionOffset(linkData.source);
    const targetRegionOffset = this.getRegionOffset(linkData.target);
    const regionOffset = Math.min(Math.abs(sourceRegionOffset - targetRegionOffset), maxOffset);
    const gatewayRegionOffset = Math.min(regionOffset + Regions.length, maxOffset);
    const midRegionOffset = Regions.length / 2 - regionOffset;
    const { regionCx, regionLeft, regionRight, regionTop, regionBottom } = this.getRegionBoxPositions({ source });
    const { vpcCx, vpcLeft, vpcRight, vpcTop, vpcBottom } = this.getVpcBoxPositions({ source });

    /**
     * currently moved some of:
     *  - links to/from VpnConnections
     *  - links to/from CustomerGateways
     *  - links to/from DirectConnections
     *  - some links from Regions
     *  - links from TransitGateways to On Prem
     *  - links from VirtualGateways to On Prem
     * @TODO need to move problematic links into aws map connector as we go
     */
    const connector = CloudMapConnector({
      sourceNode: linkData.source,
      targetNode: linkData.target,
      path,
      vpcCx,
      source,
      target,
      vpcTop,
      vpcLeft,
      regionCx,
      vpcRight,
      pathIndex,
      vpcBottom,
      regionTop,
      regionLeft,
      regionRight,
      regionOffset,
      regionBottom,
      midRegionOffset,
      targetRegionOffset,
      sourceRegionOffset,
      gatewayRegionOffset,
      connectionType: linkData.connectionType
    });

    if (connector !== null) {
      const { sourceAnchor, targetAnchor, connectionPoints } = connector;
      this.setLinkAnchor(source, sourceAnchor);
      this.setLinkAnchor(target, targetAnchor);

      return super.getNodeLinkPositions({
        ...data,
        source: { ...source, points: sourcePoints.concat(connectionPoints) },
        target
      });
    }

    // eslint-disable-next-line no-console
    console.warn('NO CONNECTOR FOUND FOR', source, target, linkData);

    return super.getNodeLinkPositions({
      ...data,
      source: { ...source, points: sourcePoints },
      target
    });
  }

  canLinkHighlight(linkData) {
    return linkData.sourcePath && linkData.targetPath;
  }

  makeLinkQuery(overrides) {
    const { cloudProvider, $hybridMap } = this.props;

    const baseQuery = {
      aggregateTypes: ['agg_total_bytes'],
      outsort: 'agg_total_bytes',
      viz_type: 'sankey',
      all_devices: false,
      device_types: [`${cloudProvider}_subnet`],
      show_overlay: false,
      show_total_overlay: false,
      lookback_seconds: 3600,
      topx: 40,
      metric: [],
      filters: {
        connector: 'All',
        filterGroups: []
      }
    };

    return $hybridMap.getQuery(merge(baseQuery, overrides));
  }

  /**
   * See maps/README.md file for explanation of how nodeLinkQueries need to be structured to interact with
   * TrafficLinkGenerator component.
   */
  getNodeLinkQueries({ selectedVpcs, selectedNode } = this.state) {
    const { cloudProvider } = this.props;
    const { topology } = this.state;
    const queryDefs = [];

    if (selectedNode) {
      if (selectedNode.type === 'internet') {
        const { subType, value } = selectedNode;

        queryDefs.push({
          type: 'internet',
          keyTypes: ['internet', 'subnet', 'subnet', 'internet'],
          allowPairs: [
            ['internet', 'subnet'],
            ['subnet', 'internet']
          ],
          selectedNode,
          queries: [
            this.makeLinkQuery({
              metric: [
                getInternetDimension({ subType, direction: 'src' }),
                getCloudSubnetFilterField({ cloudProvider, direction: 'src' }),
                getCloudSubnetFilterField({ cloudProvider, direction: 'dst' }),
                getInternetDimension({ subType, direction: 'dst' })
              ],
              filters: {
                connector: 'All',
                filterGroups: [
                  {
                    connector: 'All',
                    filters: [
                      {
                        filterField: getInternetFilterField({ subType }),
                        operator: '=',
                        filterValue: getASNValues(value).asnList
                      }
                    ]
                  },
                  {
                    connector: 'Any',
                    filters: [
                      { filterField: 'i_trf_profile', operator: '=', filterValue: 'from cloud to outside' },
                      { filterField: 'i_trf_profile', operator: '=', filterValue: 'from outside to cloud' }
                    ]
                  }
                ]
              }
            })
          ]
        });
      } else if (['gateway', 'subnet'].includes(selectedNode.type)) {
        const filters = [];
        let filterField;
        let filterValue = selectedNode.value;

        if (selectedNode.type === 'gateway') {
          filterField = getCloudGatewayFilterField({ cloudProvider });

          if (selectedNode.nodeData?.type === 'TransitGatewayAttachments') {
            filterValue = selectedNode.nodeData?.TransitGatewayId;
            filters.push({
              filterField: AWS.BI.VPC_ID,
              operator: '=',
              filterValue: selectedNode.nodeData?.ResourceId
            });
          }
        } else if (selectedNode.type === 'subnet') {
          filterField = getCloudSubnetFilterField({ cloudProvider });
        }

        const filterGroup = {
          connector: 'All',
          filterGroups: [
            {
              connector: 'All',
              filters: [...filters, { filterField, operator: '=', filterValue }]
            }
          ]
        };

        if (selectedNode.type === 'gateway' && topology && topology.SubnetGatewayNetworkInterfaceMap) {
          // determine if we have an eni associated with this gateway
          const eni = Object.keys(topology.SubnetGatewayNetworkInterfaceMap).find(
            (eniId) => topology.SubnetGatewayNetworkInterfaceMap[eniId].parsedId === selectedNode.value
          );

          if (eni) {
            // adjust the filter group to be or'ed with the initial filter and the found source eni
            filterGroup.connector = 'Any';
            filterGroup.filterGroups.push({
              connector: 'Any',
              filters: [
                {
                  filterField: AWS.SRC.INTERFACE_ID,
                  operator: '=',
                  filterValue: eni
                }
              ]
            });
          }
        }

        queryDefs.push(
          {
            type: 'vpcInternal',
            keyTypes: ['subnet', 'gateway', 'subnet'],
            selectedNode: { ...selectedNode, value: filterValue },
            queries: [
              this.makeLinkQuery({
                metric: [
                  AWS.SRC.SUBNET_ID,
                  AWS.DST.GATEWAY_ID,
                  AWS.DST.SUBNET_ID,
                  AWS.SRC.INTERFACE_ID,
                  AWS.SRC.INTERFACE_TYPE
                ],
                filters: filterGroup
              })
            ]
          },
          {
            type: 'vpcInternetTraffic',
            keyTypes: ['src-internet', 'gateway', 'dst-internet'],
            selectedNode: { type: 'gateway' },
            queries: [
              this.makeLinkQuery({
                metric: ['AS_src', AWS.DST.GATEWAY_ID, 'AS_dst'],
                filters: {
                  connector: 'All',
                  filterGroups: [
                    {
                      connector: 'All',
                      filters: [{ filterField, operator: '=', filterValue }]
                    },
                    {
                      connector: 'Any',
                      filters: [
                        {
                          filterField: AWS.DST.GATEWAY_TYPE,
                          operator: '=',
                          filterValue: 'Internet'
                        },
                        {
                          filterField: AWS.DST.GATEWAY_TYPE,
                          operator: '=',
                          filterValue: 'NAT Gateway'
                        }
                      ]
                    }
                  ]
                }
              })
            ]
          }
        );
      } else if (selectedNode.type === 'VpcEndpoints') {
        const vpcEndpointType = selectedNode.nodeData.VpcEndpointType;
        let filterField;
        let filterValue;
        let metric;
        if (vpcEndpointType === 'Interface') {
          filterField = AWS.BI.INTERFACE_ID;
          [filterValue] = selectedNode.nodeData.NetworkInterfaceIds;
          metric = [AWS.SRC.SUBNET_ID, AWS.DST.INTERFACE_ID, AWS.SRC.INTERFACE_ID, AWS.DST.SUBNET_ID];
        } else {
          filterField = AWS.DST.GATEWAY_ID;
          filterValue = selectedNode.value;
          metric = [AWS.SRC.SUBNET_ID, AWS.DST.GATEWAY_ID];
        }

        const filterGroup = {
          connector: 'All',
          filterGroups: [{ connector: 'All', filters: [{ filterField, operator: '=', filterValue }] }]
        };

        queryDefs.push({
          type: 'vpcInternal',
          keyTypes: ['src-subnet', 'dst-VpcEndpoints', 'src-VpcEndpoints', 'dst-subnet'],
          allowPairs: [
            ['src-VpcEndpoints', 'dst-subnet'],
            ['src-subnet', 'dst-VpcEndpoints']
          ],
          selectedNode,
          queries: [this.makeLinkQuery({ metric, filters: filterGroup })]
        });
      }
    }

    queryDefs.push({
      type: 'igw',
      keyTypes: ['Vpcs', 'Vpcs'],
      queries: [
        this.makeLinkQuery({
          metric: [AWS.SRC.VPC_ID, AWS.DST.VPC_ID],
          filters: {
            connector: 'All',
            filterGroups: [
              {
                connector: 'All',
                filters: [
                  { filterField: AWS.DST.GATEWAY_TYPE, operator: '=', filterValue: 'Internet' },
                  { filterField: 'i_trf_profile', operator: '=', filterValue: 'cloud internal' }
                ]
              }
            ]
          }
        })
      ]
    });

    if (
      selectedNode?.type === 'subnet' ||
      selectedNode?.type === CORE_NETWORK_EDGE ||
      selectedNode?.type === CORE_NETWORK_ATTACHMENT
    ) {
      const subnetId = selectedNode?.nodeData?.SubnetId ?? selectedNode?.value;

      let filters = [
        { filterField: AWS.BI.SUBNET_ID, operator: '=', filterValue: subnetId },
        { filterField: AWS.SRC.SUBNET_ID, operator: '<>', filterValue: '' },
        { filterField: AWS.DST.SUBNET_ID, operator: '<>', filterValue: '' }
      ];

      if (selectedNode?.type === CORE_NETWORK_EDGE) {
        filters = [
          {
            filterField: AWS.BI.CORE_NETWORK_EDGE_LOCATION,
            operator: '=',
            filterValue: selectedNode?.nodeData?.EdgeLocation
          }
        ];
      }

      queryDefs.push({
        type: 'subnetCoreNetwork',
        keyTypes: [
          'srcSubnet',
          'srcCoreNetworkAttachment',
          'dstSubnet',
          'dstCoreNetworkAttachment',
          'coreNetworkId',
          'coreNetworkEdgeLocation',
          'coreNetworkEdgeLocation'
        ],
        allowPairs: [
          ['srcSubnet', 'srcCoreNetworkAttachment'],
          ['dstSubnet', 'dstCoreNetworkAttachment']
        ],
        extraInfo: ['coreNetworkId', 'srcCoreNetworkAttachment', 'dstCoreNetworkAttachment', 'coreNetworkEdgeLocation'],
        // vpcId,
        queries: [
          this.makeLinkQuery({
            metric: [
              AWS.SRC.SUBNET_ID,
              AWS.SRC.CORE_NETWORK_ATTACHMENT,
              AWS.DST.SUBNET_ID,
              AWS.DST.CORE_NETWORK_ATTACHMENT,
              AWS.NON_DIRECTIONAL.CORE_NETWORK_ID,
              AWS.SRC.CORE_NETWORK_EDGE_LOCATION,
              AWS.DST.CORE_NETWORK_EDGE_LOCATION
            ],
            filters: {
              connector: 'All',
              filterGroups: [
                {
                  connector: 'All',
                  filters
                }
              ]
            }
          })
        ]
      });
    }

    const vpcQueries = selectedVpcs.map((vpcId) => ({
      type: 'vpcToVpc',
      keyTypes: ['vpc', 'vpc', 'dstGatewayType'],
      allowPairs: [['vpc', 'vpc']],
      extraInfo: ['dstGatewayType'],
      vpcId,
      queries: [
        this.makeLinkQuery({
          metric: [
            AWS.SRC.VPC_ID,
            AWS.DST.VPC_ID,
            AWS.DST.GATEWAY_TYPE,
            AWS.SRC.CORE_NETWORK_ATTACHMENT,
            AWS.DST.CORE_NETWORK_ATTACHMENT
          ],
          filters: {
            connector: 'All'
          }
        })
      ]
    }));

    return [...queryDefs, ...vpcQueries];
  }

  getSelectedNodeForRegion(regionName) {
    const { selectedNode } = this.state;
    return selectedNode &&
      MAP_TYPES.get('aws.cloud').includes(selectedNode.type) &&
      selectedNode.nodeData.RegionName === regionName
      ? selectedNode
      : null;
  }

  getSelected(type, data = {}) {
    if (type === 'region') {
      const selectedNode = this.getSelectedNodeForRegion(data.RegionName);
      return selectedNode ? selectedNode.value : null;
    }

    return super.getSelected(type);
  }

  getHighlighted(type) {
    const { nodeLinks, selectedNode } = this.state;

    if (type === 'region') {
      if (selectedNode) {
        const highlighted = {};

        nodeLinks.forEach(({ source, target }) => {
          highlighted[source.value] = true;
          highlighted[target.value] = true;
        });

        return Object.keys(highlighted).filter((value) => value && value !== selectedNode.value);
      }

      return [];
    }

    return super.getHighlighted(type);
  }

  getHighlightedVpcs(regionName) {
    const { $hybridMap } = this.props;
    const { selectedNode } = this.state;
    const { topology } = $hybridMap.awsCloudMapCollection;
    const { Entities } = topology;

    return this.nodeLinks
      .flatMap(({ source, target }) =>
        [source, target].map(({ type, value }) => {
          if (type === 'subnet') {
            const subnet = Entities.Subnets[value];

            if (subnet && subnet.RegionName === regionName) {
              return subnet.VpcId;
            }
          }

          return null;
        })
      )
      .filter((value) => value && value !== selectedNode?.value);
  }

  isRegionHighlighted(regionName) {
    const { $hybridMap } = this.props;
    const { nodeLinks, selectedNode } = this.state;
    const { topology } = $hybridMap.awsCloudMapCollection;
    const { Entities } = topology;

    return nodeLinks.some(({ source, target }) =>
      [source, target].some(({ type, value }) => {
        if (type === 'subnet') {
          const subnet = Entities.Subnets[value];
          return subnet?.RegionName === regionName && value !== selectedNode?.value;
        }

        return false;
      })
    );
  }

  getConsoleUrl({ type, value, nodeData }) {
    const regionName = nodeData?.RegionName;

    if (regionName) {
      if (type === 'subnet') {
        return `https://console.aws.amazon.com/vpc/home?region=${regionName}#SubnetDetails:subnetId=${value}`;
      }

      if (type === 'vpc') {
        return `https://console.aws.amazon.com/vpc/home?region=${regionName}#VpcDetails:VpcId=${value}`;
      }

      if (type === 'gateway') {
        if (value.startsWith('igw-')) {
          return `https://console.aws.amazon.com/vpc/home?region=${regionName}#InternetGateway:internetGatewayId=${value}`;
        }

        if (value.startsWith('nat-')) {
          return `https://console.aws.amazon.com/vpc/home?region=${regionName}#NatGatewayDetails:natGatewayId=${value}`;
        }

        if (value.startsWith('tgw-')) {
          return `https://console.aws.amazon.com/vpc/home?region=${regionName}#TransitGateways:sort=transitGatewayId`;
        }

        if (value.startsWith('vgw-')) {
          return `https://console.aws.amazon.com/vpc/home?region=${regionName}#VpnGateways:sort=VpnGatewayId`;
        }

        if (value.startsWith('pcx-')) {
          return `https://console.aws.amazon.com/vpc/home?region=${regionName}#PeeringConnections`;
        }
      }

      return `https://console.aws.amazon.com/vpc/home?region=${regionName}#subnets:`;
    }

    return 'https://console.aws.amazon.com/vpc/home';
  }

  handleSelectNode({ type, value, force = false, nodeData }) {
    const { openPopover, cloudProvider, $hybridMap, history } = this.props;
    const { selectedNode } = this.state;
    const { topology } = $hybridMap.awsCloudMapCollection;

    super.handleSelectNode({ type, value, force, nodeData });

    this.setState((prevState) => {
      if (prevState.selectedNode) {
        const { node } = this.getNodePosition(prevState.selectedNode);

        if (node) {
          const isSameNode = isEqual(selectedNode, prevState.selectedNode);
          const isCloudNode = MAP_TYPES.get('aws.cloud').includes(type);
          const customItems = [];
          const { x, y, width, height } = node;

          if (isCloudNode) {
            customItems.push({
              text: 'Show in AWS Console',
              icon: 'panel-stats',
              action: () => {
                const url = getConsoleUrl({ type, value, nodeData });
                window.open(url);
              }
            });

            if (type === 'subnet') {
              customItems.push(
                <MenuItem
                  key="showPathTo"
                  text={<>Show Path To&hellip;</>}
                  icon="route"
                  popoverProps={{ hoverCloseDelay: 600 }}
                >
                  <li>
                    <CloudSubnetDestinations subnet={nodeData} onSelect={(dest) => this.handleShowPath(value, dest)} />
                  </li>
                </MenuItem>
              );

              customItems.push(
                <MenuItem
                  key="forwardToConnectivityChecker"
                  text="Cloud Pathfinder"
                  icon="offline"
                  bg="warningBackground"
                  onClick={() =>
                    history.push('/v4/cloud/pathfinder/aws/create', {
                      src: value,
                      src_type: SUBNET,
                      dst_type: SUBNET
                    })
                  }
                />
              );
            }

            if (nodeData?.hasEksNode) {
              customItems.unshift(
                <MenuItem
                  key="kubeNode"
                  text="Show Kube Cluster"
                  icon={KubeClusterIcon}
                  onClick={() => history.push(`/v4/cloud/kube/cluster/aws/${nodeData.eksClusterName}`)}
                />
              );
            }
          }

          openPopover({
            ...prevState.selectedNode,
            routeTableSummary: nodeData?.routeTableSummary,
            topology,
            onShowPath: type === 'subnet' ? (route) => this.handleShowPath(value, route.destination) : null,
            cloudProvider,
            position: { left: x, top: y, width, height },
            placement: isCloudNode ? 'bottom' : 'left',
            detail: {
              type: 'cloud',
              cloudProvider
            },
            shortcutMenu: {
              selectedNode: prevState.selectedNode,
              showConnectionsCallback: this.setNodeLinks,
              isShowingConnections: prevState.nodeLinks.length > 0 && isSameNode,
              customItems
            }
          });
        }
      }

      return null;
    });
  }

  getCloudPath(subnetId, destination, selectedVpcs = {}) {
    const { $hybridMap } = this.props;
    const { topology } = this.state;
    const { Entities } = topology;

    // precompute route table for subnet
    $hybridMap.awsCloudMapCollection.computeSubnetRouteTableSummaryForce(subnetId);

    return getCloudPath(Entities, subnetId, destination)
      .map((segment) => {
        if (segment.type === 'VpcPeeringConnections') {
          selectedVpcs[segment.VpcId] = true;
          segment.value += `-${segment.VpcId}`;
        } else if (segment.type === 'Vpcs' && segment.VpcPeeringConnectionId) {
          selectedVpcs[segment.value] = true;
          segment.type = 'VpcPeeringConnections';
          segment.value = `${segment.VpcPeeringConnectionId}-${segment.value}`;
        }

        return segment;
      })
      .filter(({ type }) => type !== 'Vpcs');
  }

  handleShowPath = (subnetId, destination) => {
    const { topology } = this.state;
    const { Entities } = topology;
    const selectedVpcs = {};

    const initialPath = this.getCloudPath(subnetId, destination, selectedVpcs);

    const subnet = Entities.Subnets[subnetId];
    selectedVpcs[subnet.VpcId] = true;

    const nodeLinks = initialPath.flatMap((current, pIdx) => {
      if (pIdx === 0) {
        return [];
      }

      const previous = initialPath[pIdx - 1];
      const prevValues = Array.isArray(previous.value) ? previous.value : [previous.value];
      const currValues = Array.isArray(current.value) ? current.value : [current.value];

      return currValues
        .map((value) => ({ type: current.type, value }))
        .map((target, sIdx) => {
          const source = { type: previous.type, value: prevValues[sIdx % prevValues.length] };

          [source, target].forEach((node) => {
            const { type, value } = node;
            const otherNode = node === source ? target : source;
            const nodeData = Entities[type]?.[value];

            if (MAP_TYPES.get('aws.vpc_connections').includes(type)) {
              if (otherNode.type?.toLowerCase().startsWith('subnet') && type !== 'VpcEndpoints') {
                node.type = 'gateway';
              }
            } else if (type === 'Vpcs') {
              node.type = 'vpc';
            } else if (type === 'Subnets') {
              node.type = 'subnet';
            }

            if (nodeData?.VpcId) {
              selectedVpcs[nodeData.VpcId] = true;
            } else if (nodeData?.AccepterVpcInfo) {
              selectedVpcs[nodeData.AccepterVpcInfo.VpcId] = true;
              selectedVpcs[nodeData.RequesterVpcInfo.VpcId] = true;
            }
          });

          return {
            source,
            target,
            title: (
              <div>
                Path from {subnet.CidrBlock} ({subnet.Name || subnet.id}) to {destination}
                {subnet.id && <PathTraffic subnetId={subnet.id} destinationIp={destination} />}
              </div>
            ),
            pathLink: {
              source: { type: 'subnet', value: subnetId },
              target: { type: 'cidr', value: destination }
            }
          };
        });
    });

    this.setState({ nodeLinks, selectedVpcs: Object.keys(selectedVpcs) });
  };

  handleSelectOnPrem = (value, { event }) => {
    const { topology } = this.state;
    let type = 'Routers';

    if (value.toString().startsWith('cgw-')) {
      type = 'CustomerGateways';
    }

    if (event) {
      event.stopPropagation();
    }

    const nodeData = topology[type]?.find((node) => node.id === value);

    if (type === 'Routers' && value > -1) {
      this.handleSelectNode({ type, value, nodeData });
    } else {
      this.handleShowDetails(type, nodeData);
    }
  };

  handleToggleVpc = (vpcId) =>
    this.setState((state) => {
      const selectedNode = MAP_TYPES.get('aws.cloud').includes(state.selectedNode?.type) ? null : state.selectedNode;
      const selectedVpcs = state.selectedVpcs.includes(vpcId)
        ? state.selectedVpcs.filter((id) => id !== vpcId)
        : [vpcId];
      const nodeLinkQueries = this.getNodeLinkQueries({ ...state, selectedNode, selectedVpcs });
      return {
        selectedNode,
        selectedVpcs,
        vpcTraffic: null,
        nodeLinkQueries,
        nodeLinksLoading: nodeLinkQueries.length > 0
      };
    });

  handleGridItemHoverToggle = ({ type, key, item }) => {
    if (this.preventHoverEffects) {
      console.warn('hover effect is disabled.');
      return;
    }
    if (Object.values) {
      if (type === 'enter') {
        this.handleHoverNode(key, item.id);
      } else {
        this.handleUnhoverNode(key, item.id);
      }
    }
  };

  handleGridItemClick = ({ key, item, isMinified }) => {
    if (isMinified) {
      this.activateNode({ type: key, value: item.id });
    } else {
      this.handleShowDetails(key, item);
    }
  };

  handleShowDetails = (type, nodeData) => {
    const { cloudProvider, setSidebarDetails } = this.props;
    const { topology } = this.state;
    const isDeselectableType = ['region', 'vpc'].includes(type);

    if (setSidebarDetails) {
      this.setState(
        {
          selectedNode: isDeselectableType ? null : { type, value: nodeData.id, nodeData },
          activeNode: { type, value: nodeData.id, nodeData }
        },
        () => {
          if (isDeselectableType) {
            // reset node links
            // this prevents scenarios where we request the same node link queries and the traffic generator doesn't see any change,
            // resulting in a death spinner
            this.setNodeLinks(null);
          }

          const config = { type, value: nodeData.id, cloudProvider, nodeData, links: this.nodeLinks, topology };

          // these types are launched via 'Show Details' links on the map
          // in these cases we want to reset selected node and node links/queries to a blank slate
          let details = { ...config };

          if (type === 'region') {
            details = { ...config, value: nodeData.Name };
          } else if (type === 'vpc') {
            details = {
              ...config,
              vpcs: [{ vpc_id: nodeData.id, agent: nodeData.agent }]
            };
          }

          setSidebarDetails(details);
        }
      );
    }

    // activate node in case its not active
    this.activateNode({ type, value: nodeData.id });
  };

  handleNodeLinksUpdate(links) {
    const { selectedVpcs, selectedNode } = this.state;
    const { $hybridMap } = this.props;
    const { topology } = $hybridMap.awsCloudMapCollection;
    const { Entities } = topology;

    const vpcTraffic = {};
    let persistantNodeLinks = [];
    const selectedVpcIds = [];

    const processedLinks = links.flatMap((l) => {
      const { type } = l;

      if (type === 'vpcInternal') {
        return l.links
          .map((link) => {
            // we will keep all extra links here, and flatten array later
            const generatedLinks = [];

            ['source', 'target'].forEach((key) => {
              if (link[key].type === 'gateway') {
                const inverseKey = key === 'source' ? 'target' : 'source';
                // if tgw attachment link
                if (link[key].value.startsWith('tgw-')) {
                  const vpcId = get(Entities, `Subnets.${link[inverseKey].value}.VpcId`);
                  const tgwAttachment = Object.values(Entities.TransitGatewayAttachments).find(
                    (a) => a.TransitGatewayId === link[key].value && a.ResourceId === vpcId
                  );

                  if (tgwAttachment) {
                    selectedVpcIds.push(vpcId);
                    // generate link from transit gateway to gtw attachment
                    const transitGateway = tgwAttachment.TransitGateway;
                    if (transitGateway) {
                      // push both links because its bidirectional
                      generatedLinks.push(
                        {
                          source: { type: 'TransitGateways', value: transitGateway.id },
                          target: { type: 'TransitGatewayAttachments', value: tgwAttachment.id }
                        },
                        {
                          target: { type: 'TransitGateways', value: transitGateway.id },
                          source: { type: 'TransitGatewayAttachments', value: tgwAttachment.id }
                        }
                      );
                    }
                  }

                  link[key].value = tgwAttachment ? tgwAttachment.id : null;
                }

                if (idToType(link[key].value) === 'NatGateways') {
                  const subnetId = link[inverseKey].value;
                  const natGatewayId = link[key].value;
                  const connectorSubnetId = get(Entities, `NatGateways.${natGatewayId}.SubnetId`);
                  if (connectorSubnetId) {
                    generatedLinks.push({
                      source: { type: 'subnet', value: subnetId },
                      target: { type: 'subnet', value: connectorSubnetId }
                    });

                    link[inverseKey].value = connectorSubnetId;
                  }
                }
              }
            });

            generatedLinks.push({ ...link });

            return generatedLinks;
          })
          .flat()
          .filter(
            (link) =>
              // ensure every link has source and dest
              link.source.value && link.target.value
          )
          .filter((link) =>
            // filter core network attachment links, they will be handles separately
            ['source', 'target'].every((key) => {
              if (!link[key].value.startsWith('attachment-')) {
                return true;
              }

              const attachmentId = link[key].value;
              const coreNetworkEdge = $hybridMap.awsCloudMapCollection.findCoreNetworkEdgeByAttachment(attachmentId);

              // filter if has core network edge
              return !coreNetworkEdge;
            })
          )
          .filter((link) => link.source.value && link.target.value);
      }

      if (type === 'vpcInternetTraffic') {
        return l.links.map((link) => {
          ['source', 'target'].forEach((key) => {
            if (link[key].type === 'gateway') {
              if (link[key].value.startsWith('igw-')) {
                link[key].type = 'InternetGateways';
              } else if (link[key].value.startsWith('nat-')) {
                link[key].type = 'NatGateways';
              }
            }
          });

          return Object.assign({}, link, { connectionType: 'square' });
        });
      }

      if (type === 'igw') {
        persistantNodeLinks = l.links;
        return [];
      }

      if (type === 'vpcToVpc') {
        l.links.forEach((link) => {
          let key;

          const vpcId = link.source.value;
          const { inbound, outbound } = link;

          vpcTraffic[vpcId] = vpcTraffic[vpcId] || {
            igw: { inbound: 0, outbound: 0 },
            tgw: { inbound: 0, outbound: 0 }
          };

          if (link.srcGatewayType === 'Internet' || link.dstGatewayType === 'Internet') {
            key = 'igw';
          } else if (link.srcGatewayType === 'Transit' || link.dstGatewayType === 'Transit') {
            key = 'tgw';
          }

          if (key) {
            vpcTraffic[vpcId][key].inbound += inbound;
            vpcTraffic[vpcId][key].outbound += outbound;
          }
        });
      }

      if (type === 'subnetCoreNetwork') {
        return l.links
          .map((link) => {
            const key = 'tgw';

            const { inbound, outbound, dstCoreNetworkAttachment, srcCoreNetworkAttachment } = link;

            const subnetId = link.source.value.includes('subnet-') ? link.source.value : link.target.value;
            const coreNetworkAttachmentId = link.source.value.includes('attachment-')
              ? link.source.value
              : link.target.value;

            // core network attachment on opposite subnet, we need it for edge to edge connections
            const oppositeCoreNetworkAttachmentId =
              coreNetworkAttachmentId === srcCoreNetworkAttachment
                ? dstCoreNetworkAttachment
                : srcCoreNetworkAttachment;

            const coreNetworkEdge =
              $hybridMap.awsCloudMapCollection.findCoreNetworkEdgeByAttachment(coreNetworkAttachmentId);

            const oppositeCoreNetworkEdge = $hybridMap.awsCloudMapCollection.findCoreNetworkEdgeByAttachment(
              oppositeCoreNetworkAttachmentId
            );

            const vpcId = Entities[SUBNET]?.[subnetId]?.VpcId;
            vpcTraffic[vpcId] = vpcTraffic[vpcId] || {
              igw: { inbound: 0, outbound: 0 },
              tgw: { inbound: 0, outbound: 0 }
            };

            vpcTraffic[vpcId][key].inbound += inbound;
            vpcTraffic[vpcId][key].outbound += outbound;

            const coreNetworkLinks = [
              {
                ...link,
                source: { type: SUBNET, value: subnetId, fallbackNodes: [{ type: VPC, value: vpcId }] },
                target: { type: CORE_NETWORK_ATTACHMENT, value: coreNetworkAttachmentId }
              },
              {
                ...link,
                source: {
                  type: CORE_NETWORK_ATTACHMENT,
                  value: coreNetworkAttachmentId,
                  fallbackNodes: [{ type: VPC, value: vpcId }]
                },
                target: { type: CORE_NETWORK_EDGE, value: coreNetworkEdge?.id }
              },
              {
                ...link,
                source: {
                  type: CORE_NETWORK_EDGE,
                  value: oppositeCoreNetworkEdge?.id
                },
                target: { type: CORE_NETWORK_EDGE, value: coreNetworkEdge?.id }
              }
            ];

            return coreNetworkLinks;
          })
          .flat()
          .filter((link) => link.source.value && link.target.value);
      }

      return l.links;
    });

    const nodeLinks = [...this.getNodeLinks(), ...processedLinks];

    this.setState(({ loadingNodeConnections }) => {
      if (processedLinks.length === 0 && selectedNode && loadingNodeConnections) {
        showInfoToast('No connections were found.');
      }
      return {
        vpcTraffic,
        persistantNodeLinks,
        nodeLinks,
        nodeLinksLoading: false,
        loadingNodeConnections: false,
        // ovveride selected vpcs only if we need to expand them
        selectedVpcs: selectedVpcIds.length ? selectedVpcIds : selectedVpcs
      };
    });
  }

  get highlightedNodes() {
    const { activeNode, nodeLinks } = this.state;

    return (
      ((activeNode || nodeLinks.length > 0) && this.nodeLinks.flatMap((l) => [l.source.value, l.target.value])) || []
    );
  }

  get onPremRoutersToRender() {
    const { $hybridMap, cloudProvider } = this.props;
    const { topology } = $hybridMap.awsCloudMapCollection;
    const { width: rectWidth } = this.svg.current.getBoundingClientRect();
    const items = [
      {
        key: 'Routers',
        group: topology.Routers,
        name: 'Router',
        icon: <CloudIcon cloudProvider={cloudProvider} entity="router" width={ICON_SIZE} height={ICON_SIZE} />,
        getSubtitle: (router) => router?.name ?? router?.device_name ?? router?.id ?? 'Unknown Device'
      },
      {
        key: 'CustomerGateways',
        group: topology.CustomerGateways,
        name: 'Customer Gateways',
        icon: <CloudIcon cloudProvider={cloudProvider} entity="customerGateway" width={ICON_SIZE} height={ICON_SIZE} />,
        getSubtitle: (customerGateway) => customerGateway.Name || customerGateway.id
      }
    ];

    const willFit = items.every(
      ({ group }) =>
        // substract internet box and a gap
        getTotalWidth({ itemCount: group.length }) < rectWidth - INTERNET_WIDTH.toPoints() - GRID_ROWS_GAP.toPoints()
    );

    return items.map((item) => ({ ...item, willFit })).filter((tempGroup) => tempGroup.group.length > 0);
  }

  get onPremTopologyToRender() {
    const { $hybridMap, cloudProvider } = this.props;
    const { topology } = $hybridMap.awsCloudMapCollection;
    const { width: rectWidth } = this.svg.current.getBoundingClientRect();
    const items = [
      {
        key: 'Lags',
        group: topology.Lags,
        name: 'LAGs',
        icon: (
          <CloudIcon cloudProvider={cloudProvider} entity="directConnection" width={ICON_SIZE} height={ICON_SIZE} />
        ),
        getSubtitle: (lag) => lag.LagName || lag.id
      },
      {
        key: 'DirectConnections',
        group: topology.DirectConnections,
        name: 'Direct Connections',
        getSubtitle: (directConnection) => directConnection.ConnectionName || directConnection.id,
        icon: <CloudIcon cloudProvider={cloudProvider} entity="directConnection" width={ICON_SIZE} height={ICON_SIZE} />
      },
      {
        key: 'DirectConnectGateways',
        group: topology.DirectConnectGateways,
        name: 'Direct Connect Gateways',
        getSubtitle: (directConnectGateway) => directConnectGateway.DirectConnectGatewayName || directConnectGateway.id,
        icon: (
          <CloudIcon cloudProvider={cloudProvider} entity="directConnectGateway" width={ICON_SIZE} height={ICON_SIZE} />
        )
      },
      {
        key: 'VpnConnections',
        group: topology.VpnConnections,
        name: 'VPN Connections',
        getTitle: (item) => `VPN Connection (${item?.TransitGatewayId ? 'TGW' : 'VGW'})`,
        getSubtitle: (vpnConnection) => vpnConnection.Name || vpnConnection.id,
        icon: <CloudIcon cloudProvider={cloudProvider} entity="vpnConnection" width={ICON_SIZE} height={ICON_SIZE} />
      }
    ];

    const willFit = items.every(({ group }) => getTotalWidth({ itemCount: group.length }) < rectWidth);

    return items.map((item) => ({ ...item, willFit })).filter((tempGroup) => tempGroup.group.length > 0);
  }

  renderMap() {
    const { cloudProvider, width, $hybridMap, sidebarSettings } = this.props;
    const {
      loading,
      vpcTraffic,
      boxExpanded,
      selectedVpcs,
      nodeLinkQueries,
      nodeLinksLoading,
      loadingNodeConnections,
      selectedNode
    } = this.state;
    const { topology, loading: topologyIsLoading } = $hybridMap.awsCloudMapCollection;

    if (loading || topologyIsLoading) {
      return <Spinner mt={100} />;
    }

    if (!topology) {
      return <EmptyState icon="map-marker" title="No clouds registered for given cloud provider" />;
    }

    const { Hierarchy, Entities, Routers, CustomerGateways } = topology;

    // sort regions alphabetically for display consistency
    const filteredAwsRegions = Hierarchy.Regions.filter((region) => !region.isEmpty && !!region.Name);

    return (
      <Box>
        {(nodeLinksLoading || loadingNodeConnections) && (
          <Flex alignItems="center" justifyContent="center" position="fixed" zIndex="999" top="300px" left="50%">
            <Spinner intent="primary" />
          </Flex>
        )}

        <Flex justifyContent="space-between" gap={`${GRID_ROWS_GAP}px`}>
          <Box mr={1}>
            <TopLevelBox
              title="On Prem"
              boxProps={{
                className: classNames('box-Routers', 'box-CustomerGateways', 'link-bottomright', {
                  highlighted: this.isBoxHighlighted('Routers') || this.isBoxHighlighted('CustomerGateways')
                }),
                minWidth: '20vw',
                minHeight: 150,
                flex: '1 1 auto',
                flexWrap: 'wrap',
                alignItems: Routers.length === 0 && CustomerGateways.length === 0 ? 'center' : 'flex-end'
              }}
              height="100%"
              isExpanded
              expandedContent={
                <ItemGrid
                  items={this.onPremRoutersToRender}
                  highlightedNodes={this.highlightedNodes}
                  onHoverToggle={this.handleGridItemHoverToggle}
                  onClick={this.handleGridItemClick}
                  emptyState={<EmptyState icon="folder-close" description="No Connected Routers" />}
                  selectedNode={selectedNode}
                  showTitle={false}
                />
              }
            />
          </Box>
          <Box ml={1}>
            <TopLevelBox
              title="Internet"
              boxProps={{
                className: classNames('box-internet', 'link-bottomcenter', {
                  highlighted: this.isBoxHighlighted('internet')
                }),
                width: INTERNET_WIDTH,
                height: 233
              }}
              onExpandToggle={(isExpanded) => this.setBoxExpanded('internet', isExpanded)}
              isExpanded={boxExpanded.internet}
              expandedContent={
                <TopKeys
                  classPrefix="internet"
                  selected={this.getSelected('internet')}
                  hovered={this.getHovered('internet')}
                  highlighted={this.getHighlighted('internet')}
                  onSelect={(value) => this.handleSelectNode({ type: 'internet', value })}
                  onTabChange={this.handleInternetTabChange}
                  cloud={cloudProvider}
                />
              }
            />
          </Box>
        </Flex>

        <ItemGrid
          items={this.onPremTopologyToRender}
          highlightedNodes={this.highlightedNodes}
          onHoverToggle={this.handleGridItemHoverToggle}
          onClick={this.handleGridItemClick}
          selectedNode={selectedNode}
        />

        <Flex flexDirection="column" gap={`${GRID_ROWS_GAP}px`}>
          {filteredAwsRegions.map((region) => (
            <Box key={region.Name} className={`box-region region-${region.Name.replace(/\W+/g, '')}`}>
              <TopLevelBox
                boxProps={{
                  className: classNames({
                    highlighted: this.isRegionHighlighted(region.Name)
                  }),
                  p: 0,
                  minWidth: 200,
                  minHeight: 100
                }}
                isExpanded
                expandedContent={
                  <CloudRegionItem
                    region={region}
                    width={width - LOCATION_PADDING}
                    vpcTraffic={vpcTraffic}
                    colorBy={$hybridMap.settingsModel.get('colorBy')}
                    selectedVpcs={selectedVpcs.filter((vpcId) => Entities.Vpcs[vpcId]?.RegionName === region.Name)}
                    selected={this.getSelected('region', { RegionName: region.Name })}
                    highlighted={this.getHighlighted('region')}
                    highlightedVpcs={this.getHighlightedVpcs(region.Name)}
                    sidebarSettings={sidebarSettings}
                    onToggleVpc={this.handleToggleVpc}
                    onShowRegionDetails={() => this.handleShowDetails('region', region)}
                    onShowVpcDetails={(vpc) => this.handleShowDetails('vpc', vpc)}
                    onSelectNode={this.handleSelectNode}
                    onResize={this.handleDrawLinksRequest}
                  />
                }
              />
            </Box>
          ))}
        </Flex>

        <TrafficLinkGenerator
          inputs={nodeLinkQueries}
          onLinksUpdate={this.handleNodeLinksUpdate}
          subnetGatewayNetworkInterfaceMap={topology.SubnetGatewayNetworkInterfaceMap}
        />
      </Box>
    );
  }
}
