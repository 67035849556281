import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Button, Flex, Box, Tag, Text } from 'core/components';
import SelectedValuesPanel from 'core/form/components/modalSelect/SelectedValuesPanel';
import $dictionary from 'app/stores/$dictionary';
import { getUnitsLegacy } from 'app/stores/query/ExplorerQueryModel';
import { stopBubbling } from 'app/util/utils';
import { getNmsMetricOption, getMetricOption, getNmsDimensionOption } from 'app/util/policies';

const aggregateTypeLabelOverrides = {
  p95th_in_bits_per_sec: (
    <span>
      95th Percentile
      <br />
      Ingress
    </span>
  ),
  p95th_out_bits_per_sec: (
    <span>
      95th Percentile
      <br />
      Egress
    </span>
  ),
  p95th_in_pkts_per_sec: (
    <span>
      95th Percentile
      <br />
      Ingress
    </span>
  ),
  p95th_out_pkts_per_sec: (
    <span>
      95th Percentile
      <br />
      Egress
    </span>
  )
};

const aggregateUnitLabelOverrides = {
  repeated_retransmits: (
    <span>
      Repeated
      <br />
      Retransmits/s
    </span>
  ),
  perc_repeated_retransmits: (
    <span>
      % Repeated
      <br />
      Retransmits
    </span>
  ),
  client_latency: (
    <span>
      Client
      <br />
      Latency (ms)
    </span>
  ),
  server_latency: (
    <span>
      Server
      <br />
      Latency (ms)
    </span>
  ),
  appl_latency: (
    <span>
      Application
      <br />
      Latency (ms)
    </span>
  ),
  unique_src_route_prefix: (
    <span>
      Unique Src
      <br />
      Prefixes
    </span>
  ),
  unique_dst_route_prefix: (
    <span>
      Unique Dst
      <br />
      Prefixes
    </span>
  ),
  unique_src_port: (
    <span>
      Unique Src
      <br />
      Ports
    </span>
  ),
  unique_dst_port: (
    <span>
      Unique Dst
      <br />
      Ports
    </span>
  ),
  unique_src_as: (
    <span>
      Unique Src
      <br />
      ASNs
    </span>
  ),
  unique_dst_as: (
    <span>
      Unique Dst
      <br />
      ASNs
    </span>
  ),
  unique_dst_nexthop_asn: (
    <span>
      Unique Dst
      <br />
      Nexthop ASNs
    </span>
  ),
  unique_src_geo: (
    <span>
      Unique Src
      <br />
      Countries
    </span>
  ),
  unique_dst_geo: (
    <span>
      Unique Dst
      <br />
      Countries
    </span>
  ),
  unique_src_geo_region: (
    <span>
      Unique Src
      <br />
      Regions
    </span>
  ),
  unique_dst_geo_region: (
    <span>
      Unique Dst
      <br />
      Regions
    </span>
  ),
  unique_src_geo_city: (
    <span>
      Unique Src
      <br />
      Cities
    </span>
  ),
  unique_dst_geo_city: (
    <span>
      Unique Dst
      <br />
      Cities
    </span>
  ),
  max_sample_rate: (
    <span>
      Max Sampling
      <br />
      Rate
    </span>
  ),
  avg_sample_rate: (
    <span>
      Avg Sampling
      <br />
      Rate
    </span>
  )
};

export const getAggregateTypeLabel = ({ aggregate, useLineBreaks = true }) => {
  const { aggregateLabels } = $dictionary.dictionary;
  const { value, label } = aggregate;

  let result;
  if (useLineBreaks) {
    result = aggregateTypeLabelOverrides[value];
  }

  return result || aggregateLabels[value.startsWith('sum_logsum') ? 'sum_logsum' : value] || label;
};

export const getAggregateUnitLabel = ({ aggregate, prefix = {}, useLineBreaks = true, wrap = true }) => {
  const { units } = $dictionary.dictionary;

  let unit = aggregate.parentUnit || aggregate.unit;
  if (unit === 'kt_intell_order') {
    unit = aggregate.value.replace('sum_logsum_', '');
  }
  const unitPrefix = prefix[unit] || '';

  let unitLabel = aggregate.unit_label;
  if (useLineBreaks) {
    unitLabel = aggregateUnitLabelOverrides[unit] || unitLabel;
  }
  unitLabel = unitLabel || units[unit] || 'Unknown';
  if (unitPrefix) {
    unitLabel = unitLabel.toLowerCase();
  }

  if (wrap) {
    return (
      <span>
        {unitPrefix}
        {unitLabel}
      </span>
    );
  }

  return `${unitPrefix} ${unitLabel}`;
};

export const MetricTag = ({ children, ...rest }) => (
  <Tag outline minimal p="3px 6px 3px 8px" {...rest}>
    {children}
  </Tag>
);

export const metricLabelRenderer = (metric, selectedMeasurement = '', tagProps = {}) => (
  <MetricTag mr="3px" mb="3px" ellipsis key={metric.value} {...tagProps}>
    {getMetricOption(metric, selectedMeasurement).label}
  </MetricTag>
);

export const nmsMetricLabelRenderer = (metric, measurements = [], tagProps = {}) => (
  <MetricTag mr="3px" mb="3px" ellipsis key={metric.value} {...tagProps}>
    {getNmsMetricOption(metric, measurements).label}
  </MetricTag>
);

export const nmsDimensionLabelRenderer = (dimension, measurements = [], tagProps = {}) => (
  <MetricTag mr="3px" mb="3px" ellipsis key={dimension} {...tagProps}>
    {getNmsDimensionOption(dimension, measurements).label}
  </MetricTag>
);

export const simpleMetricLabelRenderer = (option) => (
  <span>
    {getAggregateTypeLabel({ aggregate: option, useLineBreaks: false })}{' '}
    {option.value && getAggregateUnitLabel({ aggregate: option, useLineBreaks: false })}
  </span>
);

@observer
export class MetricRenderer extends Component {
  render() {
    const { option, onClick, onUnselect, field } = this.props;
    if (onUnselect && option) {
      return (
        <Flex>
          <MetricTag
            fill
            round
            fontWeight="bold"
            onClick={onClick}
            onRemove={(e) => {
              stopBubbling(e);
              onUnselect(field, option.value);
            }}
          >
            <Flex justifyContent="space-between" width={1}>
              <Text as="div">{getAggregateUnitLabel({ aggregate: option, useLineBreaks: false })}</Text>
              <Text color="muted" fontWeight="normal" fontSize={10}>
                {getAggregateTypeLabel({ aggregate: option, useLineBreaks: false })}
              </Text>
            </Flex>
          </MetricTag>
        </Flex>
      );
    }

    const dimensionButtonClassName = classNames('pt-small dimension-tag solid', option ? option.className : null);

    return (
      <div className="select-wrapper pt-button-group pt-small">
        <Button className={dimensionButtonClassName}>
          {option && <span className="dimension-tag-group">{option.group} </span>}
          {option ? <strong>{option.label}</strong> : 'Select a metric...'}
          <span className="pt-icon-standard pt-icon-chevron-down pt-align-right" />
        </Button>
      </div>
    );
  }
}

export const MetricRendererSimple = ({ option, onClick, onUnselect, field }) => (
  <Flex>
    <Tag
      outline
      round
      fill
      minimal
      fontWeight="bold"
      p="3px 6px 3px 8px"
      onClick={onClick}
      onRemove={(e) => {
        stopBubbling(e);
        onUnselect(field, option.value);
      }}
    >
      <Flex justifyContent="space-between" width={1}>
        <Text as="div">{option.label}</Text>
      </Flex>
    </Tag>
  </Flex>
);

export const MetricRendererDdos = ({ option, onClick, onUnselect, field }) => {
  const values = field.getValue();
  const primaryMetric = values ? values[0] : '';

  return (
    <Flex>
      <Tag
        outline
        round
        fill
        minimal
        intent={option.value === primaryMetric ? 'primary' : undefined}
        fontWeight="bold"
        p="3px 6px 3px 8px"
        onClick={onClick}
        onRemove={(e) => {
          stopBubbling(e);
          onUnselect(field, option.value);
        }}
      >
        <Text as="div">{option.label}</Text>
      </Tag>
    </Flex>
  );
};

export const MetricsRenderer = (props) => {
  const { values, field, onChange, onEditComplete, onEditBtnClick, onUnselect, readOnly } = props;

  const onUnselectWrapper = (formField, value) => {
    onUnselect(formField, value);
    onEditComplete(false, true, 'unselect');
  };

  const onChangeWrapper = (changes, redrawOnly = false) => {
    if (onChange) {
      onChange(changes);
    }
    onEditComplete(false, redrawOnly, 'change');
  };

  const valueRenderer = (valueProps) => <MetricRenderer {...valueProps} field={field} onUnselect={onUnselectWrapper} />;

  const unitsLegacy = getUnitsLegacy(values);

  return (
    <Box width="100%">
      {!unitsLegacy && (
        <Box color={values.length === 0 && !field.hasError ? 'muted' : undefined} mt={1}>
          {values.length === 0 && <div className="empty-text">No metrics selected</div>}
          {values.length > 0 && (
            <SelectedValuesPanel
              {...props}
              onChange={onChangeWrapper}
              reorderable={!readOnly && values.length > 1}
              valueRenderer={valueRenderer}
              showCard={false}
              allowSelection={false}
            />
          )}
        </Box>
      )}
      <Button icon="edit" mt={1} onClick={onEditBtnClick} small>
        {unitsLegacy ? 'Customize Metrics' : 'Edit Metrics'}
      </Button>
    </Box>
  );
};

export const MetricRendererPolicies = ({ option, onClick, onUnselect, field }) => {
  const values = field.getValue();
  const primaryMetric = values ? values[0] : '';

  return (
    <Flex>
      <Tag
        outline
        round
        fill
        minimal
        intent={option.value === primaryMetric ? 'primary' : undefined}
        fontWeight="bold"
        p="3px 6px 3px 8px"
        onClick={onClick}
        onRemove={(e) => {
          stopBubbling(e);
          onUnselect(field, option.value);
        }}
      >
        <Text as="div">{option.label}</Text>
      </Tag>
    </Flex>
  );
};
