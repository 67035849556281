import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import classNames from 'classnames';
import { get, isEqual } from 'lodash';
import { mix } from 'polished';

import { Box, ButtonLink, Flex, Text, Heading } from 'core/components';
import { getMapClassname } from 'app/views/hybrid/utils/map';
import { formatBytesGreek } from 'core/util';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import getScrollbarWidth from 'core/util/getScrollbarWidth';
import { Grid, Rect, Path, Text as SVGText } from 'app/components/svg';

import CloudIcon from './CloudIcon';

import CloudItem, { CloudItemRect, CloudItemInner } from './CloudItem';
import CloudVpcMiniMap from './CloudVpcMiniMap';
import { getGatewayProps } from './popovers/cloudUtil';

const { VPC, TRANSIT_GATEWAY, CORE_NETWORK_EDGE } = ENTITY_TYPES.get('aws');

const miniItemMargin = 3;
const miniItemSize = 20;

const normalItemMarginX = 12;
const normalItemMarginY = 20;
const normalItemWidth = 170;
const normalItemHeight = 52;

const expandedItemMargin = normalItemMarginX;
const expandedItemWidth = normalItemWidth;
const expandedItemHeight = normalItemHeight + 76;

const connectionItemWidth = 160;
const connectionItemHeight = 38;

function getConnectionProps(item) {
  const { displayName, type, icon, stroke } = getGatewayProps(item);

  return {
    title: displayName ?? type,
    subtitle: item.Name || item.id,
    icon,
    stroke
  };
}

const MiniVpcItem = styled(Rect)`
  cursor: pointer;

  &:hover,
  &.hover {
    stroke: ${(props) => props.theme.colors.primary};
    stroke-width: 2;
  }

  &.highlighted {
    stroke: ${(props) => props.theme.colors.primary};
    stroke-width: 2px;
    stroke-dasharray: 7 3;

    &:hover {
      stroke-dasharray: none;
    }
  }
`;

function NormalVpcItem({ vpc, fill, ...props }) {
  const { width, height } = props;
  const sideWidth = 6;

  return (
    <>
      <CloudItemRect {...props} stroke={fill} strokeWidth={2} />
      <Rect x={1} y={1} width={sideWidth} height={height - 2} fill={fill} pointerEvents="none" />
      <g transform={`translate(${sideWidth}, 0)`}>
        <CloudItemInner
          width={width - sideWidth}
          height={height}
          title={vpc.Name || vpc.id}
          subtitle={
            vpc.Name
              ? [
                  <tspan key={vpc.id} fontStyle="italic">
                    {vpc.id}
                  </tspan>,
                  vpc.CidrBlock
                ]
              : [vpc.CidrBlock, '']
          }
          icon={<CloudIcon cloudProvider="aws" entity="vpc" width={24} height={24} />}
        />
      </g>
    </>
  );
}

function ExpandedVpcItem({ vpc, traffic, fill, selected, className, ...props }) {
  const { width } = props;

  return (
    <>
      <CloudItemRect
        {...props}
        className={classNames(className, 'hybrid-map-selectable-node', { selected })}
        stroke={fill}
        fill="appBackground"
        opacity={selected ? 0.5 : 1}
        pointerEvents={selected ? 'none' : 'auto'}
      />
      <CloudItemInner
        width={width}
        height={normalItemHeight}
        title={vpc.Name || vpc.id}
        subtitle={
          vpc.Name
            ? [
                <tspan key={vpc.id} fontStyle="italic">
                  {vpc.id}
                </tspan>,
                vpc.CidrBlock
              ]
            : [vpc.CidrBlock, '']
        }
        icon={<CloudIcon cloudProvider="aws" entity="vpc" width={17} height={18} />}
      />
      <g transform={`translate(0 ${normalItemHeight})`} pointerEvents="none" fontSize={10} letterSpacing="-0.035em">
        <Path d={`M0,0 H${width}`} stroke="muted" strokeOpacity={0.2} strokeWidth={1} />
        <CloudIcon cloudProvider="aws" entity="internetGateway" x={8} y={8} width={14} height={14} />
        <SVGText x={15} y={32} textAnchor="middle" fill="muted">
          IGW
        </SVGText>
        <CloudIcon cloudProvider="aws" entity="transitGateway" x={8} y={44} width={13} height={13} />
        <SVGText x={15} y={67} textAnchor="middle" fill="muted">
          TGW
        </SVGText>
        <g transform="translate(29 0)" fontSize={11}>
          <g transform="translate(0 11)">
            <Path d="M3,0 L0,6 L6,6 Z" fill="muted" />
            <SVGText x={10} y={2} dominantBaseline="central" fill="muted">
              {formatBytesGreek(get(traffic, 'igw.outbound', 0), 'bps')}
            </SVGText>
          </g>
          <g transform="translate(0 23)">
            <Path d="M3,6 L0,0 L6,0 Z" fill="muted" />
            <SVGText x={10} y={2} dominantBaseline="central" fill="muted">
              {formatBytesGreek(get(traffic, 'igw.inbound', 0), 'bps')}
            </SVGText>
          </g>
          <g transform="translate(0 46)">
            <Path d="M3,0 L0,6 L6,6 Z" fill="muted" />
            <SVGText x={10} y={2} dominantBaseline="central" fill="muted">
              {formatBytesGreek(get(traffic, 'tgw.outbound', 0), 'bps')}
            </SVGText>
          </g>
          <g transform="translate(0 58)">
            <Path d="M3,6 L0,0 L6,0 Z" fill="muted" />
            <SVGText x={10} y={2} dominantBaseline="central" fill="muted">
              {formatBytesGreek(get(traffic, 'tgw.inbound', 0), 'bps')}
            </SVGText>
          </g>
        </g>
      </g>
    </>
  );
}

@withTheme
export default class CloudRegionItem extends Component {
  static defaultProps = {
    width: 500,
    padding: 16,
    selectedVpcs: [],
    colorBy: 'inbound_bits_per_second'
  };

  static getDerivedStateFromProps(props, state) {
    const { colorBy, region } = props;
    const { Vpcs = [] } = region;

    if (colorBy !== state.colorBy) {
      const maxBps = Math.max(1_000_000_000, ...Vpcs.map((vpc) => vpc.traffic?.[colorBy] || 0));
      return { colorBy, maxBps };
    }

    return null;
  }

  state = {
    expanded: false
  };

  shouldComponentUpdate(prevProps, prevState) {
    const { width, selectedVpcs, selected, highlighted, highlightedVpcs, region } = this.props;
    return (
      prevState !== this.state ||
      prevProps.width !== width ||
      prevProps.selectedVpcs !== selectedVpcs ||
      !isEqual(prevProps.selected, selected) ||
      !isEqual(prevProps.highlighted, highlighted) ||
      !isEqual(prevProps.highlightedVpcs, highlightedVpcs) ||
      !isEqual(prevProps.region, region)
    );
  }

  get Vpcs() {
    const { region, sidebarSettings } = this.props;
    const { Vpcs = [] } = region;
    return Vpcs.filter((vpc) => {
      if (sidebarSettings.showDefaultNetworks) {
        return vpc;
      }

      return !vpc.IsDefault;
    });
  }

  get canExpand() {
    const { selectedVpcs } = this.props;
    return selectedVpcs?.length < 1 && this.Vpcs.length > this.getColumns(false) * 9;
  }

  get mini() {
    const { selectedVpcs } = this.props;
    const { expanded } = this.state;
    return !expanded && selectedVpcs?.length < 1 && this.canExpand;
  }

  get svgWidth() {
    const { width } = this.props;
    const { regionConnections } = this;
    return width - (regionConnections.length > 0 ? connectionItemWidth + 30 : 0);
  }

  get availableWidth() {
    const { padding } = this.props;
    return this.svgWidth - getScrollbarWidth() - padding * 2;
  }

  get selectedVpcs() {
    const { selectedVpcs } = this.props;
    return this.Vpcs.filter((vpc) => selectedVpcs.includes(vpc.id));
  }

  get regionConnections() {
    const { region } = this.props;

    return [TRANSIT_GATEWAY, CORE_NETWORK_EDGE]
      .map((entityType) => (region[entityType] ?? []).map((item) => ({ type: entityType, ...item })))
      .flat();
  }

  getColumns(mini = this.mini) {
    const { availableWidth } = this;
    return mini
      ? Math.floor((availableWidth + miniItemMargin) / (miniItemSize + miniItemMargin))
      : Math.floor((availableWidth + normalItemMarginX) / (normalItemWidth + normalItemMarginX));
  }

  getVPCColor(vpc) {
    const { colorBy, maxBps } = this.state;
    const bytes = vpc.traffic?.[colorBy] || 0;
    const pct = bytes / maxBps;
    return mix(1 - pct, '#d5c1e9', '#613e8b');
  }

  getItemClasses(item) {
    const { selected, highlighted } = this.props;
    const isSelected = selected === item.id;
    const isHighlighted = highlighted.includes(item.id);

    return {
      selected: isSelected,
      highlighted: isHighlighted,
      unselected: highlighted.length > 0 && !isSelected && !isHighlighted
    };
  }

  handleToggleExpanded = (evt) => {
    const { onResize } = this.props;
    evt.stopPropagation();
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
    onResize();
  };

  handleShowDetails = (evt) => {
    const { region, onShowRegionDetails } = this.props;
    evt.stopPropagation();
    onShowRegionDetails(region);
  };

  handleSelectNode = (item) => {
    const { onSelectNode } = this.props;
    const value = item.id;
    onSelectNode({ type: item.type === CORE_NETWORK_EDGE ? CORE_NETWORK_EDGE : 'gateway', value, nodeData: item });
  };

  handleToggleVpc = (vpc) => {
    const { onToggleVpc } = this.props;
    onToggleVpc(vpc.id);
  };

  render() {
    const { region, padding, selected, highlighted, highlightedVpcs, vpcTraffic, onShowVpcDetails, onSelectNode } =
      this.props;
    const { Vpcs, svgWidth, availableWidth, canExpand, mini, selectedVpcs, regionConnections } = this;

    return (
      <Box width="100%">
        <Flex alignItems="baseline" justifyContent="space-between" pt={`${padding}px`} px={`${padding}px`}>
          <Flex>
            <Heading level={6} mb={0}>
              {region.Name}
            </Heading>
            {canExpand && (
              <ButtonLink ml={2} color="muted" small onClick={this.handleToggleExpanded}>
                {mini ? 'Expand' : 'Collapse'}
              </ButtonLink>
            )}
          </Flex>
          <Box>
            <ButtonLink small ml={1} onClick={this.handleShowDetails}>
              Show Details
            </ButtonLink>
            <Text small muted ml={1}>
              {Vpcs.length} VPC{Vpcs.length === 1 ? '' : 's'}
            </Text>
          </Box>
        </Flex>
        <Flex justifyContent="space-between">
          <Box>
            {selectedVpcs.map((selectedVpc) => (
              <Box key={selectedVpc.VpcId} mt={`${padding / 2}px`} mx={padding} mb={4}>
                <CloudVpcMiniMap
                  width={availableWidth}
                  padding={padding / 2}
                  vpc={selectedVpc}
                  region={region}
                  selected={selected}
                  highlighted={highlighted}
                  onClose={() => this.handleToggleVpc(selectedVpc)}
                  onShowVpcDetails={onShowVpcDetails}
                  onSelectNode={onSelectNode}
                />
              </Box>
            ))}
            <Box py={`${padding / 2}px`}>
              {mini && (
                <Grid
                  items={Vpcs}
                  width={svgWidth}
                  paddingX={padding}
                  paddingY={padding / 2}
                  itemWidth={miniItemSize}
                  itemHeight={miniItemSize}
                  itemMarginX={miniItemMargin}
                  itemMarginY={miniItemMargin}
                  getItemFill={(vpc) => this.getVPCColor(vpc)}
                  renderItem={(vpc, props) => (
                    <MiniVpcItem
                      className={classNames('hybrid-map-selectable-node', {
                        highlighted: highlightedVpcs.includes(vpc.id)
                      })}
                      {...props}
                    />
                  )}
                  onItemClick={this.handleToggleVpc}
                  renderTooltipContent={(vpc) => (
                    <Box>
                      <Box fontWeight="bold">{vpc.Name || vpc.id}</Box>
                      {vpc.Name && (
                        <Box fontSize="small" fontStyle="italic">
                          {vpc.id}
                        </Box>
                      )}
                      <Box fontSize="small">{vpc.CidrBlock}</Box>
                    </Box>
                  )}
                />
              )}
              {!mini && selectedVpcs.length === 0 && (
                <Grid
                  items={Vpcs}
                  width={svgWidth}
                  maxHeight={9 * (normalItemHeight + normalItemMarginY) + padding}
                  paddingX={padding}
                  paddingY={padding / 2}
                  itemWidth={normalItemWidth}
                  itemHeight={normalItemHeight}
                  itemMarginX={normalItemMarginX}
                  itemMarginY={normalItemMarginY}
                  getItemFill={(vpc) => this.getVPCColor(vpc)}
                  renderItem={(vpc, props) => (
                    <NormalVpcItem
                      vpc={vpc}
                      className={classNames(
                        'hybrid-map-selectable-node',
                        getMapClassname({ type: VPC, value: vpc.id }),
                        {
                          highlighted: highlightedVpcs.includes(vpc.id)
                        }
                      )}
                      {...props}
                    />
                  )}
                  onItemClick={this.handleToggleVpc}
                />
              )}
              {selectedVpcs.length > 0 && (
                <Grid
                  items={Vpcs}
                  width={svgWidth}
                  maxHeight={9 * (normalItemHeight + normalItemMarginY) + padding}
                  paddingX={padding}
                  paddingY={padding / 2}
                  itemWidth={expandedItemWidth}
                  itemHeight={expandedItemHeight}
                  itemMarginX={expandedItemMargin}
                  itemMarginY={expandedItemMargin}
                  getItemFill={(vpc) => this.getVPCColor(vpc)}
                  renderItem={(vpc, props) => {
                    const vpcSelected = selectedVpcs.some((selectedVpc) => selectedVpc.id === vpc.id);

                    return (
                      <ExpandedVpcItem
                        vpc={vpc}
                        selected={vpcSelected}
                        traffic={get(vpcTraffic, vpc.id)}
                        className={classNames('hybrid-map-selectable-node', {
                          highlighted: !vpcSelected && highlightedVpcs.includes(vpc.id)
                        })}
                        {...props}
                      />
                    );
                  }}
                  onItemClick={this.handleToggleVpc}
                />
              )}
            </Box>
          </Box>
          {regionConnections.length > 0 && (
            <Box mt={padding} mr={padding}>
              <Flex flexDirection="column" justifyContent="center" height="100%">
                <svg
                  width={connectionItemWidth + 4}
                  height={regionConnections.length * (connectionItemHeight + normalItemMarginY)}
                >
                  <g transform="translate(2, 2)">
                    {regionConnections.map((connection, index) => (
                      <g
                        key={connection.id}
                        transform={`translate(0, ${(connectionItemHeight + normalItemMarginY) * index})`}
                      >
                        <CloudItem
                          width={connectionItemWidth}
                          height={connectionItemHeight}
                          stroke="#4d27aa"
                          className={classNames(
                            `gateway-${connection.id.replace(/\W+/g, '')}`,
                            `${connection.type}-${connection.id.replace(/\W+/g, '')}`,
                            this.getItemClasses(connection)
                          )}
                          onClick={(evt) => this.handleSelectNode(connection, evt.target)}
                          withArrow
                          {...getConnectionProps(connection)}
                        />
                      </g>
                    ))}
                  </g>
                </svg>
              </Flex>
            </Box>
          )}
        </Flex>
      </Box>
    );
  }
}
