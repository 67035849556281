import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Box, Button } from 'core/components';
import { Field, FormGroup, ControlGroup, InputGroup, Select } from 'core/form';
import { Collection } from 'core/model';

@inject('$auth')
@withRouter
@observer
class SelectManaged extends Component {
  static defaultProps = {
    field: undefined,
    modelCollection: new Collection(),
    managePath: '/v4/'
  };

  state = {
    showCreateField: false,
    newOptionName: '',
    options: [],
    saving: false
  };

  componentDidMount() {
    this.buildSelectOptions().then((options) => {
      this.setState({ options });
    });
  }

  buildSelectOptions() {
    const { $auth, modelCollection } = this.props;
    return modelCollection.fetch({ force: true }).then((models) => {
      const options = models
        .map((model) => ({
          id: model.id,
          value: model.id,
          label: model.name
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      if ($auth.isAdministrator) {
        options.unshift({
          value: 'CREATE_NEW',
          label: <strong className="pt-intent-primary-text">Create New</strong>,
          iconCls: 'plus'
        });
        options.unshift({
          value: 'MANAGE',
          label: <strong className="pt-intent-primary-text">Manage Options</strong>,
          iconCls: 'menu'
        });
      }
      return options;
    });
  }

  handleAddOption = () => {
    const { $auth, modelCollection, field, onChange } = this.props;
    const { newOptionName } = this.state;

    this.setState({ saving: true });

    const newOptionModel = modelCollection.forge();
    newOptionModel
      .save({ name: newOptionName, company_id: $auth.getActiveUserProperty('company_id') })
      .then(() => {
        this.buildSelectOptions().then((options) => {
          const newOptionValues = options.map((option) => option.value);
          const newRules = [{ validOptions: newOptionValues }];
          if (field.rules.length > 1) {
            const oldOptionsIdx = field.rules.reduce((acc, rule, idx) => (rule?.validOptions?.length ? idx : acc), 0);
            field.rules.forEach((rule, idx) => (idx === oldOptionsIdx ? undefined : newRules.push(rule)));
          }
          field.setRules(newRules);
          this.setState({ options, showCreateField: false, newOptionName: '', saving: false });
        });
      })
      .then(() => {
        const newModelId = newOptionModel.get('id');
        field.setValue(newModelId);
        return onChange(newModelId);
      })
      .catch(() => {
        this.setState({ saving: false });
      });
  };

  handleOptionNameChange = (e) => {
    this.setState({ newOptionName: e.target.value });
  };

  handleCancelAdd = () => {
    const { field } = this.props;
    field.setValue(undefined);
    this.setState({ showCreateField: false, newOptionName: '' });
  };

  handleManageList() {
    const { managePath, history } = this.props;
    history.push(managePath);
  }

  handleOptionChange = (field, value) => {
    const { onChange } = this.props;
    if (value === 'CREATE_NEW') {
      return this.setState({ showCreateField: true });
    }
    if (value === 'MANAGE') {
      return this.handleManageList();
    }

    if (onChange) {
      return onChange(value);
    }
    return true;
  };

  render() {
    const { field, small, ...selectProps } = this.props;
    const { showCreateField, options, newOptionName, saving } = this.state;

    return (
      <Box>
        {!showCreateField && (
          <Field field={field} onChange={this.handleOptionChange} options={options} mb={2}>
            <Select small={small} {...selectProps} />
          </Field>
        )}

        {showCreateField && (
          <FormGroup label={!small && 'Add Option'} labelFor="newOption" required p="1px">
            <ControlGroup vertical={false}>
              <InputGroup
                small={small}
                name="newOption"
                onChange={this.handleOptionNameChange}
                placeholder="New option name..."
                value={newOptionName}
                autoFocus
              />
              <Button
                small={small}
                disabled={!newOptionName}
                loading={saving}
                icon="plus"
                intent="primary"
                onClick={this.handleAddOption}
              >
                Add
              </Button>
              <Button small={small} disabled={saving} onClick={this.handleCancelAdd}>
                Cancel
              </Button>
            </ControlGroup>
          </FormGroup>
        )}
      </Box>
    );
  }
}

export default SelectManaged;
