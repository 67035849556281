import * as React from 'react';
import { Box, Flex, Icon, Text } from 'core/components';
import { ReactComponent as SetupIcon } from 'app/assets/icons/setup_icon.svg';

export default class TestCoverage extends React.Component {
  render() {
    const { collection, loading, ...boxProps } = this.props;

    return (
      <Box {...boxProps}>
        <Text muted small>
          Test Coverage
        </Text>
        <Flex alignItems="center">
          <Icon icon={SetupIcon} mr="4px" />
          <Text fontWeight="bold">{Math.round(collection.testCoverage * 100)}%</Text>
        </Flex>
      </Box>
    );
  }
}
