export default {
  message: 'Enter a valid comma-separated list of ASNs (integers)',
  fn: (value) => {
    let numbers = value;
    if (!Array.isArray(value)) {
      numbers = value.split(',');
    }
    // This checks only for 16-bit or 32-bit Public ASNs
    return numbers.every((num) => {
      const integer = parseInt(num.trim());
      return (
        integer.toString() === num.trim() &&
        ((integer > 0 && integer < 64496) || (integer > 131071 && integer < 4200000000))
      );
    });
  }
};
