import { baseFields } from './baseFields';
import { pingFields } from './pingFields';

const matrixOptions = {
  name: 'Agent Matrix Test'
};

const matrixFields = {
  ...baseFields,
  ...pingFields,
  'config.tasks': {
    defaultValue: ['ping', 'traceroute']
  },
  'config.useLocalIp': {
    label: 'Use agent private IPs',
    defaultValue: false
  },
  'config.agents': {
    defaultValue: [],
    rules: 'required|min:2|agentsSupportIPv6|agentsSupportTcp',
    messages: {
      required: 'Two or more agent selections are required.',
      min: 'Two or more agent selections are required.'
    }
  }
};

matrixFields['config.protocol'] = {
  ...matrixFields['config.protocol'],
  rules: ['required', 'in:icmp,tcp', 'agentsSupportTcp']
};

matrixFields['config.trace.protocol'] = {
  ...matrixFields['config.trace.protocol'],
  rules: ['required', 'in:udp,tcp,icmp', 'agentsSupportTcp']
};

export { matrixOptions, matrixFields };
