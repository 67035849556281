import React from 'react';
import { AiOutlineLineChart } from 'react-icons/ai';
import { LinkButton, Link } from 'core/components';
import { pick } from 'lodash';

const queryProps = [
  'all_devices',
  'device_labels',
  'device_name',
  'device_sites',
  'device_types',
  'filters',
  'lookback_seconds',
  'starting_time',
  'ending_time'
];

function getBasePath(type) {
  if (type === 'nms') {
    return '/v4/nms/explorer/saved-view';
  }
  return '/v4/library/saved-views';
}

const SavedViewLink = ({ as, children = 'Open Saved View', id, type, query, urlParams, withIcon, ...rest }) => {
  if (query && !urlParams) {
    urlParams = encodeURIComponent(JSON.stringify(pick(query, queryProps)));
  }

  const isButton = as === 'Button';
  const to = `${getBasePath(type)}/${id}${urlParams ? `/urlParams/${urlParams}` : ''}`;

  if (isButton) {
    return (
      <LinkButton to={to} icon={withIcon ? AiOutlineLineChart : null} {...rest}>
        {children}
      </LinkButton>
    );
  }

  return (
    <Link to={to} as={as} {...rest}>
      {children}
    </Link>
  );
};

export default SavedViewLink;
