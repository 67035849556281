import { HTTP_HEADER_REGEX } from '@kentik/ui-shared/util/regex';

const isMatching = (value) => typeof value === 'string' && (value.trim().length === 0 || HTTP_HEADER_REGEX.test(value));

export default {
  message: 'Enter HTTP header e.g.: "Authorization: 123" (only authorization and x- prefixed headers allowed)',
  fn: (value) => {
    if (Array.isArray(value)) {
      return value.every(isMatching);
    }
    return isMatching(value);
  }
};
