export const range = (minvalue, maxVal) => (value) =>
  (typeof value === 'string' ? value && value.trim() !== '' : true) &&
  !Number.isNaN(Number(value)) &&
  Number(value) >= minvalue &&
  Number(value) <= maxVal;

export const commaSeparatedNumbers =
  ({ maxNumOfItems = 100, minVal = 0, maxVal }) =>
  (value) =>
    value && value.trim().split(',').length <= maxNumOfItems && value.trim().split(',').every(range(minVal, maxVal));

export const arrayOfNumbers =
  ({ maxNumOfItems = 100, minVal = 0, maxVal }) =>
  (value) =>
    value && value.length <= maxNumOfItems && value.every(range(minVal, maxVal));

export const commaSeparatedNumberRanges =
  ({ maxNumOfItems = 100, minVal = 0, maxVal }) =>
  (value) =>
    value &&
    value.trim().split(',').length <= maxNumOfItems &&
    value
      .trim()
      .split(',')
      .every((entry) => entry.split('-').length <= 2) &&
    value
      .trim()
      .split(',')
      .every((entry) => entry.split('-').every(range(minVal, maxVal)));

export const arrayOfNumberRanges =
  ({ maxNumOfItems = 100, minVal = 0, maxVal }) =>
  (value) =>
    value &&
    value.length <= maxNumOfItems &&
    value.every((entry) => {
      const values = entry.split('-');
      return values.length <= 2 && values.every(range(minVal, maxVal));
    });
