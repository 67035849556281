import React from 'react';

import { inject } from 'mobx-react';
import { Button, Flex } from 'core/components';
import DeniedTrafficTable from 'app/views/hybrid/maps/components/popovers/DeniedTrafficTable';

import { AZURE_ENTITY_TYPES } from 'shared/hybrid/constants';

import SidebarItem from '../SidebarItem';
import { SecurityGroupDialog } from './securityGroupComponents';

const { VNET, SUBNET } = AZURE_ENTITY_TYPES;
/**
 * in Azure, subnet can have only 1 security group attached a time.
 */
const AzureSecurityGroupPopover = ({ width, popoutTitle, $hybridMap, ...rest }) => {
  const { cloudProvider, nodeData, type } = rest;

  if (type !== VNET && type !== SUBNET) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title="Network Security Groups"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="shield"
      popoutTitle={popoutTitle}
    >
      <Flex px="4px" gap={1} flexDirection="column">
        <DeniedTrafficTable
          cloudProvider={cloudProvider}
          entity={nodeData}
          entityType={type}
          queryTimeOptions={$hybridMap.settingsModel.queryTimeOptions}
        />

        <SecurityGroupDialog {...rest}>
          <Flex justifyContent="center">
            <Button text="View Security Groups" icon="shield" minimal small />
          </Flex>
        </SecurityGroupDialog>
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureSecurityGroupPopover);
