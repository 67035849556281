import React from 'react';
import { inject, observer } from 'mobx-react';
import { BorderedTab, BorderedTabs, Box, Button, Divider, Flex, FlexColumn, Popover, Tag, Text } from 'core/components';
import PageHeading from 'app/components/page/PageHeading';
import { Field, Form, InputGroup } from 'core/form';
import RoleSetPermissions from 'app/views/rbac/RoleSetPermissions';
import RbacRoleCollection from 'app/stores/rbac/RbacRoleCollection';
import RbacRoleSetUsers from './RoleSetUsers';
import RoleSetRoles from './RoleSetRoles';

const fieldConfigs = {
  name: {
    rules: 'required',
    label: 'Name',
    messages: {
      required: 'You must name the RBAC role set.'
    }
  },
  description: {
    rules: 'required',
    label: 'Description',
    messages: {
      required: 'Please provide a description for this role set.'
    }
  }
};

@Form({ fields: fieldConfigs, options: { name: 'RoleSetDetailsForm', showPristineErrors: false } })
@inject('$rbac', '$auth')
@observer
class RoleSetDetailsForm extends React.Component {
  state = {
    selectedTabId: undefined,
    loading: true
  };

  roleSetRoleCollection = new RbacRoleCollection();

  componentDidMount() {
    const { $rbac, model } = this.props;
    const roleIds = model.get('roles') || [];

    this.roleSetRoleCollection.add(
      $rbac.collection
        .filter((role) => roleIds.includes(role.id), { immutable: true })
        .map((roleModel) => ({ ...roleModel.get() }))
    );

    this.setState({ loading: false });
  }

  handleSave = () => {
    const { form, model, $rbac } = this.props;

    const { valid } = form;
    const { name, description } = form.getValues();

    if (valid) {
      const roles = this.roleSetRoleCollection.map((role) => role.id) || [];

      // update / save new model
      const isNewModel = model.isNew;
      return model
        .save({
          name,
          description,
          roles
        })
        .then((updatedModel) => {
          if (updatedModel) {
            $rbac.redirectToRoleSet(updatedModel.id);
            if (isNewModel) {
              // add to collection if new
              $rbac.roleSetCollection.add(updatedModel);
            }
          }

          return updatedModel;
        });
    }

    return false;
  };

  handleTabChange = (selectedTabId) => {
    this.setState({ selectedTabId });
  };

  handleCancel = () => {
    const { $rbac } = this.props;
    $rbac.navigateToRbacRoleSets();
  };

  renderMappingInfo = () => {
    const { model, $auth } = this.props;
    const ssoAttributes = model.get('ssoAttributes');

    if (ssoAttributes && ssoAttributes.length > 0 && $auth.hasRbacPermissions(['rbac.role::read'])) {
      return (
        <Popover
          minimal
          placement="right-start"
          content={
            <FlexColumn p={1} gap={1} width="fit-content">
              <Box>
                <Text fontWeight="bold">Mapped attribute key</Text>
                <Divider />
              </Box>
              <Text>{ssoAttributes[0].sso_key}</Text>

              <Box>
                <Text fontWeight="bold">Mapped attribute value</Text>
                <Divider />
              </Box>
              {ssoAttributes.map(({ sso_value, id }) => (
                <Text key={id}>{sso_value}</Text>
              ))}
            </FlexColumn>
          }
          interactionKind="hover"
        >
          <Tag large round textAlign="center" intent="primary" style={{ cursor: 'pointer' }} mb={1}>
            {ssoAttributes.length} {ssoAttributes.length > 1 ? 'mappings' : 'mapping'}
          </Tag>
        </Popover>
      );
    }
    return null;
  };

  render() {
    const { model, viewOnly, form } = this.props;
    const { selectedTabId, loading } = this.state;
    const { valid } = form;

    const tabs = [
      {
        id: 'roles',
        title: (
          <Flex alignItems="center">
            <Box mr={1}>Roles</Box>
            <Tag round small minimal textAlign="center">
              {this.roleSetRoleCollection.size}
            </Tag>
          </Flex>
        ),
        icon: 'inherited-group',
        panel: <RoleSetRoles viewOnly={viewOnly} roleSetRoleCollection={this.roleSetRoleCollection} />
      },
      {
        id: 'permissions',
        title: 'Permissions Overview',
        icon: 'shield',
        panel: <RoleSetPermissions roleSetRoleCollection={this.roleSetRoleCollection} />
      }
    ];

    if (!model.isNew) {
      tabs.push({
        id: 'users',
        title: (
          <Flex alignItems="center">
            <Box mr={1}>Users</Box>
            <Tag round small minimal textAlign="center">
              {model.users.size}
            </Tag>
          </Flex>
        ),
        icon: 'people',
        panel: <RbacRoleSetUsers viewOnly={viewOnly} model={model} />
      });
    }

    return (
      <>
        <Flex justifyContent="space-between">
          <Flex alignItems="center" gap={2}>
            <PageHeading title="Manage RBAC Role Set" icon="shield" />
            {this.renderMappingInfo()}
          </Flex>
          <Flex m={10} flex={1} justifyContent="flex-end">
            <Button onClick={this.handleSave} text="Save" intent="primary" disabled={!valid} mr={1} />
            <Button onClick={this.handleCancel} text="Cancel" />
          </Flex>
        </Flex>
        <Box p={1}>
          <Field readOnly={viewOnly} large name="name" placeholder="Role Set Name Lorem Ipsum">
            <InputGroup />
          </Field>

          <Field readOnly={viewOnly} large name="description" placeholder="Role Set Description">
            <InputGroup />
          </Field>
        </Box>
        <Box overflow="hidden" display="flex" flex={1} flexDirection="column">
          {/* only mount children once roleSetCollection is set */}
          {!loading && (
            <BorderedTabs
              selectedTabId={selectedTabId}
              onChange={this.handleTabChange}
              overflow="hidden"
              width="auto"
              tabIndent={16}
              mb="4px"
            >
              {tabs.map((tab) => (
                <BorderedTab key={tab.id} {...tab} />
              ))}
            </BorderedTabs>
          )}
        </Box>
      </>
    );
  }
}

export default RoleSetDetailsForm;
