import React, { Component } from 'react';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import { Box, Collapse, Flex, Icon, Text } from 'core/components';
import HoneycombLayout, { getHoneycombHeight } from '../layouts/honeycomb/HoneycombLayout';

const titles = {
  upstream: 'Upstream Connected Interfaces',
  parallel: 'Parallel Connected Interfaces',
  external: 'External Connected Interfaces',
  unlinked: 'Unknown Connected Interfaces',
  downstream: 'Downstream Connected Interfaces',
  site: 'On-Prem Connected Interfaces'
};

export default class DeviceInterfaces extends Component {
  static defaultProps = {
    width: 900,
    padding: 36,
    highlighted: [],
    isRenderedBySidebar: false
  };

  static getDerivedStateFromProps(props, state) {
    const { topology, selected } = props;
    const { open } = state;
    const { interfaces } = topology;

    // open section of selected interface
    if (selected !== state.selected) {
      if (selected) {
        const openKey = Object.keys(interfaces).find((key) =>
          interfaces[key].find((intf) => intf.snmp_id === selected)
        );
        return { selected, open: { ...open, [openKey]: true } };
      }

      return { selected };
    }

    return null;
  }

  state = {};

  constructor(props) {
    super(props);
    this.state.open = props.open || {
      upstream: true,
      parallel: true,
      external: true,
      unlinked: false,
      downstream: true,
      site: true
    };
  }

  get count() {
    const { topology } = this.props;
    const { interfaces } = topology;
    return Object.values(interfaces).reduce((sum, arr) => sum + arr.length, 0);
  }

  getInterface(snmp_id, interfaceList) {
    if (!snmp_id) {
      return null;
    }

    return interfaceList.find((intf) => intf.snmp_id === snmp_id) || {};
  }

  handleToggleOpen(key) {
    this.setState(({ open }) => ({ open: { ...open, [key]: !open[key] } }));
    setTimeout(this.handleResize, 200);
  }

  handleResize = () => {
    const { onResize } = this.props;

    if (onResize) {
      onResize();
    }
  };

  renderInterfaceGroup(keys) {
    const { topology, width, padding, selected, hovered, highlighted, onSelect, onHover, isRenderedBySidebar } =
      this.props;
    const { open } = this.state;
    const { interfaces } = topology;
    const renderedKeys = keys.filter((key) => interfaces[key].length > 0);
    const totalCount = renderedKeys.reduce((sum, key) => sum + interfaces[key].length, 0);

    const spacing = 60;
    const availableWidth = width - padding - spacing * renderedKeys.length;
    const honeycombOffset = getHoneycombHeight({ count: 1 }) / 2;

    if (renderedKeys.length === 0) {
      return null;
    }

    return (
      <Flex key={keys} mb={3} flexWrap="wrap">
        {renderedKeys.map((key) => {
          const groupWidth = Math.floor(availableWidth * (interfaces[key].length / totalCount));
          const honeycombHeight = getHoneycombHeight({ width: groupWidth, count: interfaces[key].length });

          return (
            <Box key={key} flex={isRenderedBySidebar ? 'auto' : `0 0 ${groupWidth + padding}px`} mr={spacing}>
              <Box
                pl={padding}
                onClick={() => this.handleToggleOpen(key)}
                css={{ cursor: 'pointer', ':hover': { textDecoration: 'underline' } }}
              >
                <Icon icon={open[key] ? FaCaretDown : FaCaretRight} mr="4px" />
                <Text fontWeight="bold">{titles[key]}</Text> ({interfaces[key].length})
              </Box>
              <Collapse isOpen={open[key]}>
                <Box className="interface-group" py={1}>
                  <svg width={groupWidth + padding} height={honeycombHeight}>
                    <g transform={`translate(${padding} ${honeycombOffset})`}>
                      <HoneycombLayout
                        width={groupWidth}
                        keyProp="snmp_id"
                        nameProp="interface_description"
                        classPrefix="interface"
                        className={key}
                        itemType="interface"
                        items={interfaces[key]}
                        selected={this.getInterface(selected, interfaces[key])}
                        hovered={this.getInterface(hovered, interfaces[key])}
                        highlighted={interfaces[key].filter(
                          (intf) => intf.snmp_id !== selected && highlighted.includes(intf.snmp_id)
                        )}
                        onSelect={onSelect}
                        onHover={onHover}
                      />
                    </g>
                  </svg>
                </Box>
              </Collapse>
            </Box>
          );
        })}
      </Flex>
    );
  }

  render() {
    const { width, padding } = this.props;

    return (
      <Box width={width}>
        <Box pl={padding} mb={3}>
          <Text as="div" fontWeight="bold" fontSize={16}>
            Interfaces
          </Text>
          <Text as="div" color="muted">
            {this.count} total
          </Text>
        </Box>
        {this.renderInterfaceGroup(['site'])}
        {this.renderInterfaceGroup(['upstream', 'parallel', 'external'])}
        {this.renderInterfaceGroup(['unlinked'])}
        {this.renderInterfaceGroup(['downstream'])}
      </Box>
    );
  }
}
