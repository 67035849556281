import * as React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { debounce, get } from 'lodash';
import { Classes } from '@blueprintjs/core';
import { Flex, Grid } from 'core/components';
import { Form, Field, Checkbox } from 'core/form';
import { fields, options as formOptions } from 'app/forms/config/hybridTopoSidebar';
import FiltersButton from './FiltersButton';
// import ConnectionsMenu from './ConnectionsMenu';
import ColorByMenu from './ColorByMenu';
import TimeOptions from './TimeOptions';
import MapSearch from './mapSearch/MapSearch';
import HealthIssuesMenu from './HealthIssuesMenu';
import TrafficLegend from './TrafficLegend';
import CloudProvidersFilter from './CloudProvidersFilter';

export const ToolbarWrapper = styled.div`
  margin-top: -1px;
  z-index: 8;
  padding-right: 15px;
  background: ${(props) => get(props.theme.colors, props.bg || 'subnavBackground')};
  border-bottom: ${(props) => props.theme.borders.thin};

  .time-options button {
    width: 100%;
  }

  .map-filter {
    .${`${Classes.ICON}-search`}, .${Classes.INPUT_ACTION} {
      top: 3px;
    }
  }
`;

@Form({ fields, options: formOptions })
@inject('$hybridMap')
@withRouter
@observer
export default class HybridTopoSettingsToolbar extends React.Component {
  componentDidMount() {
    const { form, model } = this.props;
    form.setModel(model);
  }

  handleChange = (options = {}) => {
    const { $hybridMap, form, onSettingsUpdate } = this.props;
    const model = $hybridMap.settingsModel;
    const values = form.getValues();

    model.set(values);
    form.setModel(model);

    onSettingsUpdate(model.sidebarSettingsWithTimeRange, options);
  };

  handleSearchSettingsChange = debounce((options) => {
    this.handleChange(options);
  }, 100);

  get isKubeMap() {
    const { location } = this.props;
    return location.pathname.startsWith('/v4/cloud/kube');
  }

  get isKentikMap() {
    const { location } = this.props;
    return location.pathname.startsWith('/v4/kentik-map') || this.isKubeMap;
  }

  get canShowConnectionsMenu() {
    const { location } = this.props;
    return this.isKentikMap && location.pathname.startsWith('/v4/kentik-map/cloud') === false;
  }

  get isAzurePage() {
    const { location } = this.props;
    return location.pathname.includes('azure');
  }

  get isAwsPage() {
    const { location } = this.props;
    return location.pathname.includes('aws');
  }

  get canShowColorByMenu() {
    const { location } = this.props;
    return location.pathname === '/v4/kentik-map/cloud/aws';
  }

  get canShowHealthIssueMenu() {
    const { location } = this.props;
    return location.pathname === '/v4/kentik-map';
  }

  get interSiteMenuEnabled() {
    const { match } = this.props;
    const validPaths = ['/v4/kentik-map', '/v4/kentik-map/site/:id'];

    return validPaths.includes(match.path);
  }

  render() {
    const { children, collection, model, onSettingsUpdate, trafficLegend, form, ...wrapperProps } = this.props;

    const leftColumns = [
      this.isKentikMap ? 'min-content' : '',
      this.canShowColorByMenu ? 'min-content' : '',
      this.isKentikMap ? '220px' : '',
      collection ? 'auto' : '',
      collection?.hasDefaultNetworks ? 'auto' : ''
    ];

    const rightColumns = [this.canShowHealthIssueMenu ? 'min-content' : '', trafficLegend ? 'min-content' : ''];

    const filterButton = <FiltersButton onChange={this.handleChange} />;
    const colorMenu = <ColorByMenu onChange={this.handleChange} />;
    const timeSelector = <TimeOptions onChange={this.handleChange} className="time-options" />;
    const mapSearch = !this.isKubeMap && (
      <MapSearch
        collection={collection}
        onSearchSettingsChange={this.handleSearchSettingsChange}
        isAzurePage={this.isAzurePage}
        isAwsPage={this.isAwsPage}
      />
    );
    const cloudProvidersFilter = <CloudProvidersFilter onChange={this.handleChange} />;

    if (this.isAwsPage) {
      form.getField('showDefaultNetworks').setLabel('Show Default VPCs and Subnets');
    } else {
      form.getField('showDefaultNetworks').setLabel('Show Default Networks');
    }

    if (typeof children === 'function') {
      return children({
        filterButton,
        // connectionsMenu,
        colorMenu,
        timeSelector,
        mapSearch,
        trafficLegend,
        cloudProvidersFilter
      });
    }

    return (
      <ToolbarWrapper {...wrapperProps}>
        <Flex justifyContent="space-between">
          <Grid px={3} py="4px" gridGap={1} gridTemplateColumns={leftColumns.join(' ')}>
            {this.isKentikMap && filterButton}
            {/* {this.canShowConnectionsMenu && connectionsMenu} */}
            {this.canShowColorByMenu && colorMenu}
            {this.isKentikMap && timeSelector}
            {collection && mapSearch}
            {collection?.hasDefaultNetworks && (
              <Field
                name="showDefaultNetworks"
                showLabel={false}
                m={0}
                justifyContent="center"
                onChange={this.handleChange}
              >
                <Checkbox />
              </Field>
            )}
          </Grid>
          <Grid px={3} py="4px" gridGap="12px" gridTemplateColumns={rightColumns.join(' ')}>
            {this.canShowHealthIssueMenu && <HealthIssuesMenu />}
            {trafficLegend && <TrafficLegend />}
          </Grid>
        </Flex>
      </ToolbarWrapper>
    );
  }
}
