import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Redirect } from 'react-router-dom';
import Recaptcha from 'react-grecaptcha';

import { Box, Button, Callout, Icon, LinkButton } from 'core/components';
import { Field, Form, InputGroup } from 'core/form';
import { fields, options } from 'app/forms/config/login';
import { FiUser } from 'react-icons/fi';
import { LOGIN } from '@kentik/ui-shared/paths/login';

@Form({ fields, options })
@inject('$auth')
@observer
class LoginForm extends Component {
  state = {};

  handleKeyboardSubmit = (e) => {
    const { $auth, form } = this.props;
    if (e && e.keyCode === 13) {
      if ($auth.openConfig && form.dirty && form.valid) {
        form.submit(this.handleSubmitWrapper);
      }
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyboardSubmit);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyboardSubmit);
  }

  componentDidUpdate() {
    const { form, $auth } = this.props;
    const showRecaptcha = $auth.openConfig && $auth.openConfig.recaptchaEnabled && $auth.showLoginRecaptcha;
    // if recaptcha is to be shown, update the form rules to make it required.
    form.getField('recaptcha').setRules(showRecaptcha ? 'required' : []);
  }

  handleSubmitWrapper = (form, data) => {
    const { $auth, handleSubmit } = this.props;
    const showRecaptcha = $auth.openConfig && $auth.openConfig.recaptchaEnabled && $auth.showLoginRecaptcha;

    return handleSubmit(form, data).then(() => {
      // reset recaptcha after submit.
      if (showRecaptcha) {
        window.grecaptcha.reset();
        form.getField('recaptcha').setValue(null);
      }
    });
  };

  recaptchaCallback = (response) => {
    const { form } = this.props;
    form.getField('recaptcha').setValue(response);
  };

  recaptchaExpire = () => {
    const { form } = this.props;
    form.getField('recaptcha').setValue(null);
  };

  render() {
    const { form, authenticating, authenticated, $auth } = this.props;
    const { forgotPassword } = this.state;
    const { openConfig, showLoginRecaptcha } = $auth;
    const showRecaptcha = openConfig && openConfig.recaptchaEnabled && showLoginRecaptcha;

    if (forgotPassword) {
      return <Redirect to={{ pathname: LOGIN.PASSWORD_RECOVERY }} push />;
    }

    return (
      <form>
        {form.error && (
          <Callout title="Login Failed" intent="danger" icon="error" my={2}>
            {form.error}
          </Callout>
        )}
        <Field disabled={authenticated || authenticating} name="user_email" autoFocus showLabel={false} large>
          <InputGroup autoFocus leftIcon={<Icon icon={FiUser} />} autoComplete="username" className="jumbo" />
        </Field>
        <Field disabled={authenticated || authenticating} name="password" showLabel={false} large>
          <InputGroup
            type="password"
            leftIcon={<Icon icon="lock" />}
            autoComplete="current-password"
            className="jumbo"
          />
        </Field>
        {showRecaptcha && (
          <Box mb={1}>
            <Recaptcha
              sitekey={$auth.openConfig.recaptchaSiteKey}
              callback={this.recaptchaCallback}
              expiredCallback={this.recaptchaExpire}
            />
          </Box>
        )}
        <Button
          disabled={!$auth.openConfig || !form.dirty || !form.valid}
          intent="primary"
          large
          fill
          loading={authenticated || authenticating}
          onClick={() => form.submit(this.handleSubmitWrapper)}
          text="Login"
          mb={2}
        />

        <Button minimal onClick={() => this.setState({ forgotPassword: true })} text="Forgot your password?" />
        {openConfig && !openConfig.subtenancy && (
          <LinkButton to={LOGIN.SSO_LOOKUP} minimal>
            Looking for Single Sign-on?
          </LinkButton>
        )}
      </form>
    );
  }
}

export default LoginForm;
