import * as React from 'react';
import { FiEdit } from 'react-icons/fi';
import styled from 'styled-components';
import { darken } from 'polished';
import { Box, Button, ConfirmPopover, EmptyState, Flex, Grid, Icon, Text } from 'core/components';
import { FormComponent, Field, InputGroup } from 'core/form';

const SavedSearchItem = styled(Grid)`
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => darken(0.05, theme.colors.subnavBackground)};
  }

  &:active {
    border: ${({ theme }) => theme.borders.thin};
  }
`;

export default class SavedSearches extends React.Component {
  state = {
    editSearchName: null
  };

  handleSavedSearchEdit = (oldSearchName, newSearchName) => {
    const { onSavedSearchEdit } = this.props;

    onSavedSearchEdit(oldSearchName, newSearchName);
    this.setState({ editSearchName: null });
  };

  get savedSearches() {
    const { savedSearches, onSavedSearchSelect, onSavedSearchDelete } = this.props;
    const { editSearchName } = this.state;

    if (savedSearches && savedSearches.length > 0) {
      return savedSearches.map((search) => {
        const isEditing = search.name === editSearchName;
        let item = (
          <>
            <Text title={search.name} ellipsis small>
              {search.name}
            </Text>

            <Flex alignItems="center">
              <Button
                icon={FiEdit}
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ editSearchName: search.name });
                }}
                minimal
                small
              />

              <ConfirmPopover onConfirm={() => onSavedSearchDelete(search.name)} entityName="saved search">
                <Button icon="trash" intent="danger" minimal small />
              </ConfirmPopover>
            </Flex>
          </>
        );

        if (isEditing) {
          item = (
            <FormComponent
              fields={{ searchName: {} }}
              options={{ name: 'Map Search Name Editor' }}
              values={{ searchName: search.name }}
            >
              {({ form }) => (
                <Field
                  name="searchName"
                  className="no-margin"
                  onEnterKey={(field) => this.handleSavedSearchEdit(search.name, field.getValue())}
                >
                  <InputGroup
                    onClick={(e) => e.stopPropagation()}
                    rightElement={
                      <Button
                        text="Save"
                        intent="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleSavedSearchEdit(search.name, form.getValue('searchName'));
                        }}
                        small
                      />
                    }
                    small
                  />
                </Field>
              )}
            </FormComponent>
          );
        }

        return (
          <SavedSearchItem
            key={search.name}
            pl={1}
            alignItems="center"
            justifyContent="space-between"
            onClick={() => {
              if (!isEditing) {
                this.setState({ editSearchName: null });
              }

              onSavedSearchSelect(search.value);
            }}
            onDoubleClick={() => this.setState({ editSearchName: search.name })}
            gridGap={0}
            gridTemplateColumns="auto 1fr auto"
          >
            <Icon icon="search" iconSize={12} color="primary" pr={1} />
            {item}
          </SavedSearchItem>
        );
      });
    }

    return null;
  }

  get emptyState() {
    return <EmptyState icon="search" title="No Saved Searches" />;
  }

  render() {
    const { savedSearches, onSavedSearchSelect, onSavedSearchEdit, onSavedSearchDelete, ...boxProps } = this.props;

    return (
      <Flex flexDirection="column" {...boxProps}>
        <Text fontWeight="bold" color="muted" m={1} small>
          Saved Searches
        </Text>
        <Box flex={1} overflow="auto">
          {this.savedSearches || this.emptyState}
        </Box>
      </Flex>
    );
  }
}
