import React from 'react';
import { Flex, Icon } from 'core/components';

export default function PackageIcon({ icon, color, width, height, iconSize, borderRadius, ...rest }) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bg={color}
      width={width}
      height={height}
      borderRadius={borderRadius}
      {...rest}
    >
      <Icon icon={icon} iconSize={iconSize} color="#fff" />
    </Flex>
  );
}

PackageIcon.defaultProps = {
  width: 60,
  height: 60,
  iconSize: 30,
  borderRadius: 8,
  color: '#abb8c3',
  icon: 'document'
};
