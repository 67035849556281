import { AZURE_ENTITY_TYPES, OCI_ENTITY_TYPES, AWS_ENTITY_TYPES } from 'shared/hybrid/constants';
import {
  curveUpLeft,
  curveLeftUp,
  curveUpRight,
  curveRightUp,
  curveLeftDown,
  curveDownLeft,
  curveRightDown,
  curveDownRight
} from 'app/views/hybrid/utils/d3/curves';

import { connectThroughMiddleXCurved } from './commonUtilsCurved';

const MAX_ZONE_PADDING = 50;

/**
 *
 *     → → → → → → →
 *    ↑              ↓
 * Source          Target
 */
const connectHorizonallyPositionedSubnets = ({
  ySpacing,
  regionTop,
  regionLeft,
  sourcePoint,
  targetPoint,
  regionRight,
  linkSpacing,
  regionBottom
}) => {
  let connectionPoints = [];

  const regionWidth = regionRight - regionLeft;
  const widthPercentage = Math.abs(sourcePoint[0] - targetPoint[0]) / regionWidth;

  // we want to spread connection lines vertially based on distance between source and target compare to total width of region to prevent overlap
  const bottomY =
    Math.min(sourcePoint[1], targetPoint[1]) -
    Math.max(
      Math.min(Math.abs(sourcePoint[1] - regionBottom), Math.abs(sourcePoint[1] - regionTop), MAX_ZONE_PADDING) *
        widthPercentage,
      ySpacing
    );

  if (sourcePoint[0] < targetPoint[0]) {
    connectionPoints = [
      ...curveUpRight(sourcePoint[0], bottomY, linkSpacing),
      ...curveRightDown(targetPoint[0], bottomY, linkSpacing)
    ];
  } else {
    connectionPoints = [
      ...curveUpLeft(sourcePoint[0], bottomY, linkSpacing),
      ...curveLeftDown(targetPoint[0], bottomY, linkSpacing)
    ];
  }

  return { sourceAnchor: 'top', targetAnchor: 'top', connectionPoints };
};

/**
 *  Target
 *    ↑
 *    ↑
 *    ↑
 *    ↑
 *  Source
 */
const connectVertiallyPositionedSubnets = ({ sourcePoint, targetPoint }) => {
  const isTargetTop = targetPoint[1] < sourcePoint[1];
  return {
    sourceAnchor: isTargetTop ? 'top' : 'bottom',
    targetAnchor: isTargetTop ? 'bottom' : 'top',
    connectionPoints: []
  };
};

/**
 *
 * we gonna assume source is below target on a page
 *     ← ← ←
 *    ↓      ↑
 * Target    ↑
 *          →
 *        ↑
 *    Source
 */
const connectVertiallyTooFarApartSubnets = ({
  sourcePoint,
  targetPoint,
  linkSpacing,
  nodeHeight,
  ySpacing,
  vpcRight
}) => {
  const bottomY = sourcePoint[1] - nodeHeight / 2 + ySpacing / 2;
  const topY = targetPoint[1] - nodeHeight / 2 + ySpacing / 2;

  const connectionPoints = [
    ...curveUpRight(sourcePoint[0], bottomY, linkSpacing),
    ...curveRightUp(vpcRight, bottomY, linkSpacing),
    ...curveUpLeft(vpcRight, topY, linkSpacing),
    ...curveLeftDown(targetPoint[0], topY, linkSpacing)
  ];

  return {
    sourceAnchor: 'top',
    targetAnchor: 'top',
    connectionPoints
  };
};

const connectVertiallyCloseSubnets = ({ sourcePoint, targetPoint, linkSpacing, nodeHeight, ySpacing, xSpacing }) => {
  const isTargetTop = targetPoint[1] < sourcePoint[1];
  const isSourceLeft = sourcePoint[0] < targetPoint[0];
  const rightX = Math.max(sourcePoint[0], targetPoint[0]) - xSpacing + linkSpacing;
  const leftX = Math.min(sourcePoint[0], targetPoint[0]) + xSpacing - linkSpacing;

  const bottomY = Math.max(sourcePoint[1], targetPoint[1]) - nodeHeight / 2 - ySpacing / 2;
  const topY = Math.min(sourcePoint[1], targetPoint[1]) - nodeHeight / 2 - ySpacing / 2;

  let connectionPoints = [];

  // source is on the left
  if (isSourceLeft) {
    if (isTargetTop) {
      /**
       *           → → →
       *          ↑     ↓
       *          ↑   Target
       *          ↑
       *       → →
       *      ↑
       *   Source
       */
      connectionPoints = [
        ...curveUpRight(sourcePoint[0], bottomY, linkSpacing),
        ...curveRightUp(rightX, bottomY, linkSpacing),
        ...curveUpRight(rightX, topY, linkSpacing),
        ...curveRightDown(targetPoint[0], topY, linkSpacing)
      ];
    } else {
      /**
       *     → → →
       *    ↑     ↓
       *  Source  ↓
       *          ↓
       *           →
       *             ↓
       *           Target
       */
      connectionPoints = [
        ...curveUpRight(sourcePoint[0], topY, linkSpacing),
        ...curveRightDown(leftX, topY, linkSpacing),
        ...curveDownRight(leftX, bottomY, linkSpacing),
        ...curveRightDown(targetPoint[0], bottomY, linkSpacing)
      ];
    }
    // source is on the right
  } else if (isTargetTop) {
    /**
     *     ← ←
     *   ↓     ↑
     * Target  ↑
     *         ↑
     *           ←
     *             ↑
     *           Source
     */
    connectionPoints = [
      ...curveUpLeft(sourcePoint[0], bottomY, linkSpacing),
      ...curveLeftUp(leftX, bottomY, linkSpacing),
      ...curveUpLeft(leftX, topY, linkSpacing),
      ...curveLeftDown(targetPoint[0], topY, linkSpacing)
    ];
  } else {
    /**
     *         ← ←
     *        ↓     ↑
     *        ↓   Source
     *        ↓
     *    ← ←
     *   ↓
     * Target
     */
    connectionPoints = [
      ...curveUpLeft(sourcePoint[0], topY, linkSpacing),
      ...curveLeftDown(rightX, topY, linkSpacing),
      ...curveDownLeft(rightX, bottomY, linkSpacing),
      ...curveLeftDown(targetPoint[0], bottomY, linkSpacing)
    ];
  }

  return {
    sourceAnchor: 'top',
    targetAnchor: 'top',
    connectionPoints
  };
};

const connectSubnets = ({ sourcePoint, targetPoint, nodeHeight, linkSpacing, ...rest }) => {
  // too far if we can fit 4 subnets in between
  const isVerticallyTooFar = Math.abs(targetPoint[1] - sourcePoint[1]) > nodeHeight * 4;

  if (isVerticallyTooFar) {
    // connect through region right
    return connectVertiallyTooFarApartSubnets({ sourcePoint, targetPoint, linkSpacing, nodeHeight, ...rest });
  }

  // connect directly
  return connectVertiallyCloseSubnets({ sourcePoint, targetPoint, linkSpacing, nodeHeight, ...rest });
};

// calculate best connection points for a subnet to a subnet
const connectSubnetToSubnet = ({ sourcePoint, targetPoint, xSpacing, ySpacing, ...rest }) => {
  const isPositionedHorizonally = Math.abs(sourcePoint[1] - targetPoint[1]) < ySpacing;
  const isPositionedVertically = Math.abs(sourcePoint[0] - targetPoint[0]) < xSpacing / 2;

  if (isPositionedHorizonally) {
    return connectHorizonallyPositionedSubnets({ sourcePoint, targetPoint, xSpacing, ySpacing, ...rest });
  }

  if (isPositionedVertically) {
    return connectVertiallyPositionedSubnets({
      sourcePoint,
      targetPoint
    });
  }

  // calculate best lines
  return connectSubnets({ sourcePoint, targetPoint, xSpacing, ySpacing, ...rest });
};

const connectToPeering = (props) => {
  const { sourcePoint, targetPoint, ySpacing, nodeHeight } = props;
  const topY = sourcePoint[1] + ySpacing;
  const bottomY = targetPoint[1] - ySpacing;

  const isVerticallyTooFar = Math.abs(targetPoint[1] - sourcePoint[1]) > nodeHeight * 4;
  if (isVerticallyTooFar) {
    return connectThroughMiddleXCurved(props);
  }

  return {
    sourceAnchor: 'bottom',
    targetAnchor: 'top',
    connectionPoints: [
      [sourcePoint[0], topY],
      [targetPoint[0], bottomY]
    ]
  };
};

const connectToVHubConnection = (props) => {
  const { sourcePoint, sourceNodeHeight, targetPoint, regionLeft, linkSpacing, ySpacing } = props;
  const leftX = regionLeft + linkSpacing;
  const isTargetTop = targetPoint[1] < sourcePoint[1];

  const subnetY = sourcePoint[1] + sourceNodeHeight / 2 + ySpacing / 2;
  const connectorY = targetPoint[1] - ySpacing;
  let connectionPoints = [];

  if (isTargetTop) {
    /**
     *  → → → →
     * ↑        ↓
     * ↑   VHubConnection
     * ↑
     * ↑
     * ↑
     * ↑     Subnet
     * ↑       ↓
     *   ← ← ← ←
     */
    connectionPoints = [
      ...curveDownLeft(sourcePoint[0], subnetY, linkSpacing),
      ...curveLeftUp(leftX, subnetY, linkSpacing),
      ...curveUpRight(leftX, connectorY, linkSpacing),
      ...curveRightDown(targetPoint[0], connectorY, linkSpacing)
    ];
  } else {
    /**
     *   Subnet
     *     ↓
     *  ← ←
     * ↓
     * ↓
     * ↓
     * ↓
     *  → → → →
     *          ↓
     *    VHubConnection
     */
    connectionPoints = [
      ...curveDownLeft(sourcePoint[0], subnetY, linkSpacing),
      ...curveLeftDown(leftX, subnetY, linkSpacing),
      ...curveDownRight(leftX, connectorY, linkSpacing),
      ...curveRightDown(targetPoint[0], connectorY, linkSpacing)
    ];
  }

  return {
    sourceAnchor: 'bottom',
    targetAnchor: 'top',
    connectionPoints
  };
};

const connectSubnetToGateway = ({
  xSpacing,
  ySpacing,
  nodeHeight,
  sourcePoint,
  targetPoint,
  linkSpacing,
  sourceNodeWidth,
  sourceNodeHeight
}) => {
  const isVerticallyTooFar = Math.abs(targetPoint[1] - sourcePoint[1]) > nodeHeight * 4;
  const middleX = sourcePoint[0] + sourceNodeWidth / 2 + xSpacing / 2;
  const topY = sourcePoint[1] + sourceNodeHeight / 2 + ySpacing / 2;
  const connectorY = targetPoint[1] - ySpacing;

  if (isVerticallyTooFar) {
    /**
     *   Subnet
     *     ↓
     *      → → →
     *            ↓
     *            ↓
     *            ↓
     *            ↓
     *         Gateway
     */

    return {
      sourceAnchor: 'bottom',
      targetAnchor: 'top',
      connectionPoints: [
        ...curveDownRight(sourcePoint[0], topY, linkSpacing),
        ...curveRightDown(middleX, topY, linkSpacing),
        ...curveDownRight(middleX, connectorY, linkSpacing),
        ...curveRightDown(targetPoint[0], connectorY, linkSpacing)
      ]
    };
  }

  let connectionPoints = [
    ...curveDownLeft(sourcePoint[0], topY, linkSpacing),
    ...curveLeftDown(targetPoint[0], connectorY, linkSpacing)
  ];

  const isGatewayOnRight = sourcePoint[0] < targetPoint[0];
  if (isGatewayOnRight) {
    connectionPoints = [
      ...curveDownRight(sourcePoint[0], topY, linkSpacing),
      ...curveRightDown(targetPoint[0], connectorY, linkSpacing)
    ];
  }
  return {
    sourceAnchor: 'bottom',
    targetAnchor: 'top',
    connectionPoints
  };
};

/**
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const SubnetConnector = ({ targetType, ...rest }) => {
  switch (targetType) {
    case 'subnet':
    case AZURE_ENTITY_TYPES.SUBNET:
      return connectSubnetToSubnet({ ...rest });

    case 'gateway':
    case AWS_ENTITY_TYPES.CORE_NETWORK_ATTACHMENT:
      return connectSubnetToGateway({ ...rest });

    case AZURE_ENTITY_TYPES.FIREWALL:
    case AZURE_ENTITY_TYPES.VNET_PEERING:
    case OCI_ENTITY_TYPES.NAT_GATEWAY:
    case OCI_ENTITY_TYPES.SERVICE_GATEWAY:
    case OCI_ENTITY_TYPES.INTERNET_GATEWAY:
    case OCI_ENTITY_TYPES.LOCAL_PEERING_GATEWAY:
    case OCI_ENTITY_TYPES.DYNAMIC_ROUTING_GATEWAY_ATTACHMENT:
      return connectToPeering({ ...rest });

    case AZURE_ENTITY_TYPES.HUB_VIRTUAL_NETWORK_CONNECTION:
      return connectToVHubConnection({ ...rest });
    default:
      return null;
  }
};

export default SubnetConnector;
