import React from 'react';
import { Redirect } from 'react-router-dom';

function LegacyRedirects() {
  return (
    <>
      <Redirect from="/explorer" to="/v4/core/explorer" />
      <Redirect from="/explorer/preset" to="/v4/core/explorer/preset" />
      <Redirect from="/explorer/urlParams/:params" to="/v4/core/explorer/urlParams/:params" />
      <Redirect from="/explorer/:urlHash" to="/v4/core/explorer/:urlHash" />

      <Redirect from="/dashboards" to="/v4/library" />
      <Redirect from="/dashboards/:dashboardId" to="/v4/library/dashboards/:dashboardId" />
      <Redirect
        from="/dashboards/:dashboardId/urlParams/:params"
        to="/v4/library/dashboards/:dashboardId/urlParams/:params"
      />

      <Redirect from="/savedView" to="/v4/library" />
      <Redirect from="/savedView/:viewId" to="/v4/library/savedView/:viewId" />

      <Redirect from="/alerting" to="/v4/alerting" />
      <Redirect from="/profile" to="/v4/profile" />

      <Redirect from="/analytics" to="/v4/edge/peering/discover-peers" />
      <Redirect from="/analytics/peering" to="/v4/edge/peering/discover-peers" />
      <Redirect from="/analytics/peering/:asn" to="/v4/edge/peering/:asn" />
      <Redirect from="/analytics/capacity" to="/v4/core/capacity" />
      <Redirect from="/analytics/rawFlow/:hash?" to="/v4/core/raw-flow/:hash?" />
      <Redirect from="/analytics/rawFlow/explorer/:hash?" to="/v4/core/raw-flow/:hash?" />
      <Redirect from="/analytics/rawFlow/forensics/:hash?" to="/v4/core/forensic-raw-flow/:hash?" />
      <Redirect from="/analytics/queryEditor" to="/v4/core/query-editor" />
    </>
  );
}

export default LegacyRedirects;
