// returns an array of data suitable for sparkline rendering
// calculated by an averaging reducer
export function getSparklineData(rawDataByTimestamp) {
  const sparklineData = [];

  if (rawDataByTimestamp) {
    const timestamps = Object.keys(rawDataByTimestamp).sort();

    return timestamps.reduce((acc, timestamp) => {
      const timestampedDataset = rawDataByTimestamp[timestamp];

      return acc.concat(timestampedDataset.reduce((tsAcc, value) => tsAcc + value, 0) / timestampedDataset.length);
    }, sparklineData);
  }

  return sparklineData;
}

// rolls up a list of models with raw data organized into 'from' and 'to' directions
// so we can get further aggregated sparklines
function rollupRawData(models) {
  return models.reduce(
    (acc, model) => {
      const rawDataByTimestamp = model.rawData || model.get('rawData');

      if (rawDataByTimestamp) {
        ['from', 'to'].forEach((direction) => {
          const timestamps = Object.keys(rawDataByTimestamp[direction]).sort();

          timestamps.forEach((timestamp) => {
            const timestampedDataset = rawDataByTimestamp[direction][timestamp];

            acc[direction][timestamp] = (acc[direction][timestamp] || []).concat(timestampedDataset);
          });
        });
      }

      return acc;
    },
    { from: {}, to: {} }
  );
}

export function getSparklineDataRollup(models) {
  const { from, to } = rollupRawData(models);

  return {
    from: getSparklineData(from),
    to: getSparklineData(to)
  };
}
