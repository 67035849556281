import * as React from 'react';
import { observer } from 'mobx-react';
import { capitalize } from 'lodash';
import { Box, Flex, Icon, Text } from 'core/components';
import { Form, Field, Select } from 'core/form';
import { getOption } from 'core/form/components/modalSelect/selectHelpers';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import SecurityRulesTable from './SecurityRulesTable';

@Form({ fields: { securityGroup: { label: 'Security Group' } }, options: { name: 'Security Groups' } })
@observer
export default class SecurityGroupsPanel extends React.Component {
  componentDidMount() {
    const { form, securityGroups } = this.props;

    if (securityGroups.length > 0) {
      // auto-select the first option on mount if available
      form.setValue('securityGroup', securityGroups[0].GroupId);
    }
  }

  get options() {
    const { securityGroups } = this.props;

    return securityGroups.map((group) => ({
      label: `${group.GroupName} (${group.GroupId})`,
      value: group.GroupId,
      entity: group
    }));
  }

  get selectedSecurityGroup() {
    const { form } = this.props;
    const field = form.getField('securityGroup');
    const option = getOption(field.options, field.getValue());

    return (option && option.entity) || {};
  }

  get selectedRuleLegend() {
    const { selectedRule } = this.props;

    if (selectedRule && this.selectedSecurityGroup.deniesTraffic) {
      const source = `${selectedRule.sourceIp}:${selectedRule.sourcePort}`;
      const destination = `${selectedRule.destinationIp}:${selectedRule.destinationPort}`;

      return (
        <Flex mt={1}>
          <Box width={14} height={14} bg="dangerBackground" border="thin" mt="4px" mr={1} />
          <Text color="muted" small>
            {selectedRule.sourceProtocol} traffic from {source} to {destination} was evaluated against these rules but
            rejected because it was not explicitly permitted
          </Text>
        </Flex>
      );
    }

    return null;
  }

  get targetLabel() {
    const { vpcId, subnetId } = this.props;
    const targets = [vpcId, subnetId].filter((target) => !!target);

    return targets.join(', ');
  }

  optionFormatter = (option) => {
    if (option) {
      const { entity, label } = option;

      return (
        <Flex>
          {entity.deniesTraffic && (
            <Box pl="4px" pr={1}>
              <Icon icon="dot" color="danger" mx="-7px" />
            </Box>
          )}
          {label}
        </Flex>
      );
    }

    return null;
  };

  get userIdGroupPairsWarning() {
    const { selectedSecurityGroup } = this;

    if (selectedSecurityGroup && selectedSecurityGroup.IpPermissions && selectedSecurityGroup.IpPermissionsEgress) {
      const directions = [];
      const hasInboundPairs = selectedSecurityGroup.IpPermissions.some(
        (permission) => permission.UserIdGroupPairs && permission.UserIdGroupPairs.length > 0
      );
      const hasOutboundPairs = selectedSecurityGroup.IpPermissionsEgress.some(
        (permission) => permission.UserIdGroupPairs && permission.UserIdGroupPairs.length > 0
      );

      if (hasInboundPairs) {
        directions.push('inbound');
      }

      if (hasOutboundPairs) {
        directions.push('outbound');
      }

      if (directions.length > 0) {
        return (
          <Flex alignItems="flex-start" mt={1}>
            <Icon icon={BsFillExclamationTriangleFill} color="warning" mr={1} />
            <Text color="muted" small>
              {capitalize(directions.join(' and '))} rules included one or more unresolvable group pairs. Unable to
              print these rules. Contact <a href="mailto:support@kentik.com">support@kentik.com</a> for more info.
            </Text>
          </Flex>
        );
      }
    }

    return null;
  }

  render() {
    const { topology, vpcId, subnetId, queryTimeOptions } = this.props;
    const { selectedSecurityGroup } = this;

    return (
      <Box>
        <Field
          name="securityGroup"
          options={this.options}
          label="Security Groups"
          showLabel={false}
          className="no-margin"
        >
          <Select
            optionFormatter={this.optionFormatter}
            valueRenderer={this.optionFormatter}
            inlineLabel
            showFilter
            fill
          />
        </Field>

        <CloudMetadata
          node={{
            ...selectedSecurityGroup,
            Name: selectedSecurityGroup.GroupName,
            Description: selectedSecurityGroup.Description,
            'Security Group ID': selectedSecurityGroup.GroupId
          }}
          keys={['Name', 'Description', 'OwnerId', 'Security Group ID', 'VpcId', 'Tags']}
          mb={2}
          maxHeight={200}
          overflow="auto"
          small
        />

        <SecurityRulesTable
          topology={topology}
          securityGroup={selectedSecurityGroup}
          vpcId={vpcId}
          subnetId={subnetId}
          queryTimeOptions={queryTimeOptions}
        />

        {this.selectedRuleLegend}
        {this.userIdGroupPairsWarning}
      </Box>
    );
  }
}
