import React, { Component } from 'react';
import styled from 'styled-components';
import { throttle } from 'lodash';

import { Box, Tooltip } from 'core/components';
import getScrollbarWidth from 'core/util/getScrollbarWidth';

import Rect from './Rect';

export const GridItem = styled(Rect)`
  cursor: pointer;

  &:hover,
  &.hover {
    stroke: ${(props) => props.theme.colors.primary};
    stroke-width: 2;
  }
`;

export default class SVGGrid extends Component {
  static defaultProps = {
    items: [],
    idProp: 'id',
    width: 500,
    maxHeight: null,
    paddingY: 16,
    paddingX: 16,
    defs: null,
    itemWidth: 20,
    itemHeight: 20,
    itemMarginX: 3,
    itemMarginY: 3,
    getItemFill: () => null,
    getItemProps: () => null,
    renderItem: (item, props) => <GridItem {...props} />,
    renderTooltipContent: null,
    onItemHover: null,
    onItemClick: null,
    embed: false
  };

  state = {
    activeId: null,
    tooltipOpen: false,
    tooltipRect: {}
  };

  componentWillUnmount() {
    this.handleHover.cancel();
  }

  get outerWidth() {
    const { width, maxHeight } = this.props;
    const scrollbarWidth = maxHeight && maxHeight !== 'none' ? getScrollbarWidth() : 0;
    return width - scrollbarWidth;
  }

  get innerWidth() {
    const { paddingX } = this.props;
    return this.outerWidth - paddingX * 2;
  }

  get columns() {
    const { itemWidth, itemMarginX } = this.props;
    return Math.floor((this.innerWidth + itemMarginX) / (itemWidth + itemMarginX));
  }

  get height() {
    const { items, paddingY, itemHeight, itemMarginY } = this.props;
    const { columns } = this;
    const itemsLen = typeof items.length !== 'undefined' ? items.length : items.size || 0;
    const rows = Math.ceil(itemsLen / columns);
    const innerHeight = rows * (itemHeight + itemMarginY) - itemMarginY;
    return 2 * paddingY + innerHeight;
  }

  get activeItem() {
    const { items, idProp } = this.props;
    const { activeId } = this.state;
    return items.find((item) => item[idProp] === activeId);
  }

  getPosition(index) {
    const { itemWidth, itemHeight, itemMarginX, itemMarginY } = this.props;
    const { columns } = this;
    const row = Math.floor(index / columns);
    const col = index % columns;

    return {
      x: col * (itemWidth + itemMarginX),
      y: row * (itemHeight + itemMarginY)
    };
  }

  handleHover = throttle((node) => {
    const { idProp, onItemHover, renderTooltipContent, embed } = this.props;

    if (node) {
      const { item, element } = node;

      if (renderTooltipContent || onItemHover) {
        const { width, height } = element.getBBox();

        let ctm = element.getCTM();
        let ownerSvg = element.ownerSVGElement;

        if (embed) {
          while (ownerSvg.ownerSVGElement) {
            ctm = ownerSvg.getCTM().multiply(ctm);
            ownerSvg = ownerSvg.ownerSVGElement;
          }
        }

        const { x: left, y: top } = ownerSvg.createSVGPoint().matrixTransform(ctm);

        if (renderTooltipContent) {
          this.setState({
            tooltipOpen: true,
            activeId: item[idProp],
            tooltipRect: { left, top, width, height }
          });
        }

        if (onItemHover) {
          onItemHover(item, { left, top, width, height }, element);
        }
      }
    } else {
      if (renderTooltipContent) {
        this.setState({ tooltipOpen: false });
      }

      if (onItemHover) {
        onItemHover(null);
      }
    }
  }, 20);

  handleClick = (node) => {
    const { onItemClick } = this.props;
    const { item, element } = node;

    const ctm = element.getCTM();
    const { x: left, y: top } = element.ownerSVGElement.createSVGPoint().matrixTransform(ctm);
    const { width, height } = element.getBBox();

    if (node) {
      if (onItemClick) {
        onItemClick(item, { left, top, width, height });
      }
    }
  };

  renderItem(item) {
    const { itemWidth, itemHeight, getItemFill, getItemProps, renderItem } = this.props;

    return renderItem(item, {
      fill: getItemFill(item) || 'muted',
      rx: 2,
      width: itemWidth,
      height: itemHeight,
      onMouseOver: (evt) => this.handleHover({ item, element: evt.target }),
      onMouseOut: () => this.handleHover(null),
      onClick: (evt) => this.handleClick({ item, element: evt.target }),
      ...getItemProps(item)
    });
  }

  render() {
    const { embed, items, idProp, maxHeight, paddingX, paddingY, renderTooltipContent } = this.props;
    const { tooltipOpen, tooltipRect } = this.state;
    const { activeItem } = this;

    const svg = (
      <svg width={this.outerWidth} height={this.height}>
        <g transform={`translate(${paddingX} ${paddingY})`}>
          {items.map((item, index) => {
            const { x, y } = this.getPosition(index);

            return (
              <g key={item[idProp]} transform={`translate(${x} ${y})`} data-testid="svg-grid-item">
                {this.renderItem(item)}
              </g>
            );
          })}
        </g>
      </svg>
    );

    if (embed) {
      return svg;
    }

    return (
      <Box position="relative" maxHeight={maxHeight || 'none'} overflow="auto" data-testid="svg-grid">
        {svg}
        {renderTooltipContent && (
          <Tooltip
            key={activeItem && activeItem[idProp]}
            isOpen={tooltipOpen}
            boundary="viewport"
            position="bottom"
            content={activeItem ? renderTooltipContent(activeItem) : null}
            target={<div />}
            targetProps={{ style: { pointerEvents: 'none', position: 'absolute', ...tooltipRect } }}
          />
        )}
      </Box>
    );
  }
}
