import React, { Component } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { FiBarChart, FiCalendar, FiShare2, FiTarget, RiBarChartBoxLine, RiDashboardLine } from 'react-icons/fi';
import { Box, Button, Flex, Heading, LinkButton, Select, Tag, Text, Tooltip } from 'core/components';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';
import KentikLogo from 'app/components/KentikLogo';
import { DateRange } from 'app/components/dataviews/DataViewHeader';
import { showSuccessToast } from 'core/components/toast';

const formatDate = (value) => moment.utc(value).format('MMM DD, YYYY HH:mm');

@inject('$sharedLinks', '$explorer', '$dataviews')
@observer
class SharedLinkHeader extends Component {
  handleViewTypeChange = (viewType) => {
    const { $explorer } = this.props;
    $explorer.dataview.queryBuckets.viewType = viewType;
  };

  constructUtms = () => {
    const { isSynthetic /* isDataExplorer, isSavedView, isDashboard */ } = this.props;

    // Maybe one day we have more granular marketing metrics for these values...
    const utm_campaign = isSynthetic ? 'synthetics' : 'generic';
    const utm_content = isSynthetic ? 'testdetail' : 'dataexplorer';

    return `utm_source=portal&utm_campaign=${utm_campaign}&utm_content=${utm_content}`;
  };

  constructTryKentikLink = () => {
    const { isSynthetic } = this.props;
    const utms = this.constructUtms();

    return isSynthetic
      ? `https://www.kentik.com/go/get-started-synthetic-network-monitoring/?${utms}`
      : `https://www.kentik.com/go/get-started?${utms}`;
  };

  renderVizOptions = () => {
    const { $dataviews, $explorer } = this.props;
    const oldViewType = $explorer.dataview.viewType;
    const { selectedQuery } = $explorer.dataview.queryBuckets;

    // specifically NOT matrix or geoHeatMap because they require additional API requests (unlikely to be whitelisted)
    const vizOptions = [
      { value: 'stackedArea', label: 'Stacked Area Chart', icon: 'timeline-area-chart' },
      { value: 'stackedAreaHundredPercent', label: '100% Stacked Area Chart', icon: 'full-stacked-chart' },
      { value: 'line', label: 'Line Chart', icon: 'timeline-line-chart' },
      { value: 'stackedBar', label: 'Stacked Column Chart', icon: 'stacked-chart' },
      { value: 'horizon', label: 'Horizon Chart', icon: 'align-justify' },
      { value: 'bar', label: 'Bar Chart', icon: 'horizontal-bar-chart' },
      { value: 'pie', label: 'Pie Chart', icon: 'doughnut-chart' },
      { value: 'sankey', label: 'Sankey', icon: 'flows' },
      { value: 'sunburst', label: 'Sunburst', icon: 'flash' },
      // {"value":"matrix","label":"Matrix","icon":"heat-grid"},
      { value: 'gauge', label: 'Gauge', icon: 'selection' },
      { value: 'table', label: 'Table', icon: 'th-list' }
      // {"value":"geoHeatMap","label":"Geo HeatMap","icon":"globe"}
    ];

    return vizOptions.filter((option) => $dataviews.isViewTypeCompatible(oldViewType, option.value, selectedQuery));
  };

  render() {
    const { $sharedLinks, $explorer, isDataExplorer, isSavedView, isSynthetic, isDashboard } = this.props;
    const { name, description, user_full_name, cdate, metadata } = $sharedLinks.data;

    return (
      <Flex>
        <Box pr={2}>
          <Flex alignItems="flex-start">
            <Heading level={1} mr={2} mb="6px" lineHeight={1}>
              {name}
            </Heading>

            <Flex alignItems="center" mt="6px">
              {isDataExplorer && (
                <>
                  <Tag icon={FiBarChart} minimal mr="12px">
                    Data Explorer
                  </Tag>
                  <Tag minimal icon={FiCalendar}>
                    <DateRange dataview={$explorer.dataview} />
                  </Tag>
                </>
              )}

              {isSavedView && (
                <>
                  <Tag icon={RiBarChartBoxLine} minimal mr="12px">
                    Saved View
                  </Tag>
                  <Tag minimal icon={FiCalendar}>
                    <DateRange dataview={$explorer.dataview} />
                  </Tag>
                </>
              )}

              {isSynthetic && (
                <>
                  <Tag icon={FiTarget} minimal mr="12px">
                    Synthetics Test
                  </Tag>
                  <Tag icon={FiCalendar} minimal>
                    {formatDate(metadata.start_time * 1000)} - {formatDate(metadata.end_time * 1000)}
                  </Tag>
                </>
              )}

              {isDashboard && (
                <>
                  <Tag icon={RiDashboardLine} minimal mr="12px">
                    Dashboard
                  </Tag>
                  {/* <Tag icon={FiCalendar} minimal>
                    {formatDate(metadata.start_time * 1000)} - {formatDate(metadata.end_time * 1000)}
                  </Tag> */}
                </>
              )}
            </Flex>
          </Flex>

          <Text as="div" muted mb="2px">
            Shared by {user_full_name} on {formatDate(cdate)}
          </Text>

          <Text as="div" muted style={{ whiteSpace: 'pre-line' }}>
            {description}
          </Text>
        </Box>

        <Flex flexDirection="column" flex={1} justifyContent="start">
          <Flex flexDirection="row" justifyContent="flex-end">
            <Tooltip content="Copy public URL">
              <CopyToClipboardButton text={window.location.href} intent="none" copyConfirmationText="Copied">
                <Button
                  mr={2}
                  icon={FiShare2}
                  onClick={() => {
                    showSuccessToast('URL copied to clipboard!');
                  }}
                >
                  Share
                </Button>
              </CopyToClipboardButton>
            </Tooltip>

            <Box>
              <LinkButton
                icon={<KentikLogo onlyMark style={{ width: 16 }} />}
                style={{
                  background:
                    'linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%), #1A1C1E',
                  border: '1px solid #5C7080',
                  color: '#fff'
                }}
                to={this.constructTryKentikLink()}
                blank
              >
                Try Kentik
              </LinkButton>
            </Box>
          </Flex>

          {isDataExplorer && (
            <Flex justifyContent="flex-end">
              <Select
                options={this.renderVizOptions()}
                onChange={this.handleViewTypeChange}
                placeholder="Change visualization..."
                style={{ marginTop: '11px' }}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  }
}

export default SharedLinkHeader;
