import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, Text } from 'core/components';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';

const VPNGatewaySummary = ({ logo, value, nodeData }) => {
  const nodeName = nodeData?.metadata?.name || value;

  return (
    <Box>
      <Flex p={2} borderBottom="thin" alignItems="center">
        {React.cloneElement(logo, { width: 32, height: 32 })}
        <Box flex={1} ml={1}>
          <Text as="div" muted>
            Virtual Private Gateway Details
          </Text>
          <Text muted small>
            {nodeName}
          </Text>
        </Box>
      </Flex>
      {nodeData && (
        <SidebarItem title="Details" icon="more" popoutTitle={VPNGatewaySummary.PopoutTitle({ logo, value, nodeData })}>
          <CloudMetadata node={nodeData} keys={['id', 'OwnerId', 'State', 'Tags']} />
        </SidebarItem>
      )}
    </Box>
  );
};

VPNGatewaySummary.PopoutTitle = ({ logo, value, nodeData }) => {
  const nodeName = nodeData?.metadata?.name || value;

  return (
    <Flex alignItems="center">
      {React.cloneElement(logo)}
      <Box ml={1}>{nodeName}</Box>
    </Flex>
  );
};

export default observer(VPNGatewaySummary);
