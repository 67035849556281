import { ENTITY_TYPES } from 'shared/hybrid/constants';
import {
  curveUpLeft,
  curveLeftUp,
  curveUpRight,
  curveRightUp,
  curveLeftDown,
  curveDownLeft,
  curveRightDown,
  curveDownRight
} from 'app/views/hybrid/utils/d3/curves';

import { connectThroughMiddleXCurved } from '../commonUtilsCurved';

const {
  VNET,
  VIRTUAL_HUB,
  VPN_GATEWAY,
  VNET_PEERING,
  VPN_LINK_CONNECTION,
  EXPRESS_ROUTE_GATEWAY,
  EXPRESS_ROUTE_CONNECTION,
  HUB_VIRTUAL_NETWORK_CONNECTION
} = ENTITY_TYPES.get('azure');

/**
 * example:
 *    HUB/CONNECTION
 *          ↑
 *   → → → →
 * ↑
 * ↑
 * ↑   Virtual Hub
 * ↑         ↓
 *   ← ← ← ←
 */
const connectVirtualHubsOrAnyConnection = ({ sourcePoint, targetPoint, regionLeft, ySpacing, linkSpacing }) => {
  const leftX = regionLeft - linkSpacing * 1.5;

  const bottomY = Math.max(sourcePoint[1], targetPoint[1]) + ySpacing;
  const topY = Math.min(sourcePoint[1], targetPoint[1]) + ySpacing;

  const connectionPoints = [
    [sourcePoint[0], bottomY],
    [leftX, bottomY],
    [leftX, topY],
    [targetPoint[0], topY]
  ];
  return {
    sourceAnchor: 'bottom',
    targetAnchor: 'bottom',
    connectionPoints
  };
};

const connectToVNetConnection = ({
  sourceType,
  maxNodeWidth,
  sourcePoint,
  targetPoint,
  ySpacing,
  xSpacing,
  vpcRight,
  linkSpacing,
  xDirectionModifier
}) => {
  const bottomY = sourcePoint[1] + ySpacing * (sourceType === VIRTUAL_HUB ? -2 : 1);
  const topY = targetPoint[1] - ySpacing;

  const middleX = sourcePoint[0] + xSpacing * xDirectionModifier;

  const isSourceOnTheRight = sourcePoint[0] > targetPoint[0];

  let connectionPoints = [];
  if (isSourceOnTheRight) {
    /**
     *        ← ← ← ← ← ← ←
     *       ↓             ↑
     *    VNET Connection  ↑    VNET Connection
     *                     ↑           ↓
     *                      ← ← ← ← ← ←
     */
    connectionPoints = [
      ...curveDownLeft(sourcePoint[0], bottomY, linkSpacing),
      ...curveLeftUp(middleX, bottomY, linkSpacing),
      ...curveUpLeft(middleX, topY, linkSpacing),
      ...curveLeftDown(targetPoint[0], topY, linkSpacing)
    ];
  } else {
    // if too close -> go around
    const tooClose = Math.abs(sourcePoint[0] - targetPoint[0]) < maxNodeWidth;
    /**
     *                       → → → → → → →  ← ← ← ← ←
     *                     ↑               ↓         ↑
     *    VNET Connection  ↑     VNET Connection     ↑
     *         ↓           ↑                         ↑
     *          → → → → → → → → → → → → → → → → → →  VPCRight
     *                  MiddleX
     */

    connectionPoints = [
      ...curveDownRight(sourcePoint[0], bottomY, linkSpacing),
      ...curveRightUp(tooClose ? vpcRight : middleX, bottomY, linkSpacing),
      ...curveUpRight(tooClose ? vpcRight : middleX, topY, linkSpacing),
      ...curveRightDown(targetPoint[0], topY, linkSpacing)
    ];
  }

  return {
    sourceAnchor: sourceType === VIRTUAL_HUB ? 'top' : 'bottom',
    targetAnchor: 'top',
    connectionPoints
  };
};

const connectToPeering = (props) => {
  const { sourcePoint, targetPoint, vpcRight, linkSpacing, maxNodeHeight, ySpacing } = props;
  const topY = targetPoint[1] + ySpacing;
  const bottomY = sourcePoint[1] - ySpacing * 2;

  const isTooFarApart = Math.abs(sourcePoint[1] - targetPoint[1]) > maxNodeHeight * 10;

  if (!isTooFarApart) {
    return connectThroughMiddleXCurved(props);
  }

  /**
   *   Peering
   *     ↑
   *      ← ← ← ← ← ← ← ← ← ← ← ← ←
   *                                ↑
   *                  → → → →→ → → →
   *                  ↑
   *             VNET Connection
   */

  const connectionPoints = [
    ...curveUpRight(sourcePoint[0], bottomY, linkSpacing),
    ...curveRightUp(vpcRight, bottomY, linkSpacing),
    ...curveUpLeft(vpcRight, topY, linkSpacing),
    ...curveLeftUp(targetPoint[0], topY, linkSpacing)
  ];

  return {
    sourceAnchor: 'top',
    targetAnchor: 'bottom',
    connectionPoints
  };
};

const connectToVNet = ({
  sourcePoint,
  targetPoint,
  regionRight,
  linkSpacing,
  ySpacing,
  targetNodeHeight,
  maxNodeHeight
}) => {
  const connectorY = sourcePoint[1] + ySpacing * 1.5;
  const rightX = regionRight + linkSpacing;
  const vNetY = targetPoint[1] + targetNodeHeight / 2 + ySpacing / 2;

  const isTooFarApart = Math.abs(sourcePoint[1] - targetPoint[1]) > maxNodeHeight * 3;

  // connect directly if in the same region as they are close

  /**
   *   VNET
   *     ↑
   *      ← ← ← ← ← ←
   *                  ↑
   *                  ↑
   *  VNET Connection ↑
   *      ↓           ↑
   *       → → → → → →
   */
  if (isTooFarApart) {
    return {
      sourceAnchor: 'bottom',
      targetAnchor: 'bottom',
      connectionPoints: [
        ...curveDownRight(sourcePoint[0], connectorY, linkSpacing),
        ...curveRightUp(rightX, connectorY, linkSpacing),
        ...curveUpLeft(rightX, vNetY, linkSpacing),
        ...curveLeftUp(targetPoint[0], vNetY, linkSpacing)
      ]
    };
  }

  const isTargetOnRight = targetPoint[0] > sourcePoint[0];

  let connectionPoints = [];
  const middleY = Math.abs(targetPoint[1] - targetNodeHeight / 2 - sourcePoint[1]) / 2;

  /**
   *  VNET Connection
   *      ↓
   *       → → →
   *            ↓
   *           VNET
   */
  if (isTargetOnRight) {
    connectionPoints = [
      ...curveDownRight(sourcePoint[0], sourcePoint[1] + middleY, linkSpacing),
      ...curveRightDown(targetPoint[0], targetPoint[1] - targetNodeHeight / 2 - middleY, linkSpacing)
    ];
  } else {
    connectionPoints = [
      ...curveDownLeft(sourcePoint[0], sourcePoint[1] + middleY, linkSpacing),
      ...curveLeftDown(targetPoint[0], targetPoint[1] - targetNodeHeight / 2 - middleY, linkSpacing)
    ];
  }

  return {
    sourceAnchor: 'bottom',
    targetAnchor: 'top',
    connectionPoints
  };
};

const connectVHubToVHub = ({ sourcePoint, targetPoint, vpcRight, linkSpacing, ySpacing }) => {
  /**
   *    HUB
   *     ↑
   *      ← ← ← ← ← ←
   *                  ↑
   *                  ↑
   *     HUB          ↑
   *      ↓           ↑
   *       → → → → → →
   */

  const topY = Math.min(targetPoint[1], sourcePoint[1]) + ySpacing;
  const bottomY = Math.max(targetPoint[1], sourcePoint[1]) + ySpacing;

  const isTargetOnTop = targetPoint[1] < sourcePoint[1];

  let connectionPoints = [];

  if (isTargetOnTop) {
    connectionPoints = [
      ...curveDownRight(sourcePoint[0], bottomY, linkSpacing),
      ...curveRightUp(vpcRight, bottomY, linkSpacing),
      ...curveUpLeft(vpcRight, topY, linkSpacing),
      ...curveLeftUp(targetPoint[0], topY, linkSpacing)
    ];
  } else {
    connectionPoints = [
      ...curveDownRight(sourcePoint[0], topY, linkSpacing),
      ...curveRightDown(vpcRight, topY, linkSpacing),
      ...curveDownLeft(vpcRight, bottomY, linkSpacing),
      ...curveLeftUp(targetPoint[0], bottomY, linkSpacing)
    ];
  }

  return {
    sourceAnchor: 'bottom',
    targetAnchor: 'bottom',
    connectionPoints
  };
};

const connectVncToVhub = (props) => connectToVNet(props);

/**
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const VirtualHubConnector = (props) => {
  const { sourceType, targetType } = props;
  if (sourceType === VIRTUAL_HUB && targetType === VIRTUAL_HUB) {
    return connectVHubToVHub({ ...props });
  }

  if (sourceType === HUB_VIRTUAL_NETWORK_CONNECTION && targetType === VIRTUAL_HUB) {
    return connectVncToVhub({ ...props });
  }

  switch (targetType) {
    case VIRTUAL_HUB:
    case VPN_LINK_CONNECTION:
    case EXPRESS_ROUTE_CONNECTION:
      return connectVirtualHubsOrAnyConnection({ ...props });
    case VPN_GATEWAY:
    case EXPRESS_ROUTE_GATEWAY:
    case HUB_VIRTUAL_NETWORK_CONNECTION:
      return connectToVNetConnection({ ...props });
    case VNET:
      return connectToVNet({ ...props });
    case VNET_PEERING:
      return connectToPeering({ ...props });
    /** @TODO other target types */
    default:
      return null;
  }
};

export default VirtualHubConnector;
