import * as React from 'react';
import { compact as _compact } from 'lodash';
import { Box, Flex, Heading, EmptyState, Tag } from 'core/components';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { GCP_ENTITY_TYPES } from 'shared/hybrid/constants';
import { getInterconnectAttachmentMetadata } from './utils';

const { INTERCONNECT_ATTACHMENT } = GCP_ENTITY_TYPES;
export default class InterconnectAttachmentPopover extends React.Component {
  get title() {
    const key = 'interconnectAttachmentEntitiesCount';
    const color = this.interconnectAttachmentEntities.length ? 'gcp.blue' : '';
    return (
      <Flex gap={1}>
        <Heading level={5} m={0}>
          Interconnect Attachments
        </Heading>
        <Tag key={key} bg={color} round>
          {this.interconnectAttachmentEntities.length}
        </Tag>
      </Flex>
    );
  }

  get interconnectAttachmentEntities() {
    const { nodeData, data } = this.props;
    return _compact(
      Object.values(data).map((ICA) => {
        if (nodeData.selfLink === ICA.router) {
          return ICA;
        }
        return null;
      })
    );
  }

  get interconnectAttachmentMetadata() {
    const configs = this.interconnectAttachmentEntities.map((ICA) => getInterconnectAttachmentMetadata(ICA));
    return configs.map((config) => (
      <React.Fragment key={`interconnectAttachmentMetadata-${config.node.id}`}>
        <CloudMetadata
          {...config}
          style={
            configs.length > 1
              ? { flexDirection: 'column', borderBottom: '1px solid rgba(16, 22, 26, 0.15)' }
              : { flexDirection: 'column' }
          }
        />
      </React.Fragment>
    ));
  }

  render() {
    const { width, popoutTitle, cloudProvider } = this.props;
    return (
      <SidebarItem
        excludeFormProps
        title={this.title}
        dialogProps={{ width }}
        icon={<CloudIcon cloudProvider="gcp" entity={INTERCONNECT_ATTACHMENT} theme={{ name: 'dark' }} />}
        popoutTitle={popoutTitle}
      >
        {this.interconnectAttachmentEntities.length ? (
          <Box>{this.interconnectAttachmentMetadata}</Box>
        ) : (
          <>
            <EmptyState
              title="No Results"
              icon={<CloudIcon cloudProvider={cloudProvider} entity={INTERCONNECT_ATTACHMENT} iconSize={48} />}
            />
          </>
        )}
      </SidebarItem>
    );
  }
}
