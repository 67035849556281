import React, { Component } from 'react';

import { Box, Button, Flex, ToggleButtonGroup } from 'core/components';
import MenuSelect from 'core/components/MenuSelect';

export default class PresetFilterSelect extends Component {
  static defaultProps = {
    menuLabel: 'Share Level',
    containerProps: {},
    useButtonGroup: false
  };

  state = {
    selectedFilter: undefined
  };

  handleFilterSelect = (selectedFilter) => {
    const { collection } = this.props;
    const selectedPresetFilter = collection.presetFilters.find((presetFilter) => presetFilter.label === selectedFilter);

    this.setState({ selectedFilter });
    collection.setPresetFilter(selectedPresetFilter);
  };

  render() {
    const { selectedFilter } = this.state;
    const { collection, menuLabel, containerProps, useButtonGroup, ...rest } = this.props;

    const options = [
      { label: 'All', value: undefined },
      ...collection.presetFilters.map((filter) => ({ label: filter.label, value: filter.label }))
    ];

    return (
      <Box {...containerProps}>
        {useButtonGroup ? (
          <Flex alignItems="center">
            {menuLabel}
            <ToggleButtonGroup selectedValue={selectedFilter} ml={menuLabel ? 1 : 0}>
              {options.map((option) => (
                <Button
                  key={option.label}
                  text={option.label}
                  value={option.value}
                  onClick={() => this.handleFilterSelect(option.value)}
                  {...rest.buttonProps}
                />
              ))}
            </ToggleButtonGroup>
          </Flex>
        ) : (
          <MenuSelect
            label={menuLabel}
            options={options}
            value={selectedFilter}
            onChange={this.handleFilterSelect}
            {...rest}
          />
        )}
      </Box>
    );
  }
}
