import { capitalize } from 'lodash';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Heading, Grid, Box, Text, Flex, Icon, CalloutOutline, FlexColumn, LinkButton } from 'core/components';
import { getHealthClass } from 'app/views/hybrid/utils/health';
import { ReactComponent as Pod } from 'app/assets/icons/kubernetes/pod_problem.svg';
import { ReactComponent as Node } from 'app/assets/icons/kubernetes/node_problem.svg';
import { ReactComponent as Cluster } from 'app/assets/icons/kubernetes/cluster.svg';
import DialogBody from 'core/components/DialogBody';
import { ENTITY_TYPES } from 'shared/hybrid/constants';

const gridColumns = '36px 3fr minmax(70px,1fr) 1fr';

const { CLUSTER, NODE, POD, RESOURCE } = ENTITY_TYPES.get('kube');

const PodHeaders = () => (
  <Grid flex="1 1 auto" gridTemplateColumns={gridColumns} mb={1}>
    <div />
    <Text color="muted">Name</Text>
    <Text color="muted">Latency</Text>
    <Text color="muted">Actions</Text>
  </Grid>
);

const PodRow = ({ onShowDetails, item }) => {
  const { type = POD } = item;
  let icon = Pod;

  if (type === CLUSTER) {
    icon = Cluster;
  } else if (type === NODE) {
    icon = Node;
  }

  return (
    <Grid flex="1 1 auto" gridTemplateColumns={gridColumns} py={1} alignItems="center" borderTop="thin">
      <FlexColumn gap="2px" alignItems="center" mr="12px">
        <Icon icon={icon} iconSize={24} />
        <Text textTransform="uppercase" fontSize={10} fontWeight="bold">
          {type}
        </Text>
      </FlexColumn>
      <Box>
        <Heading level={6} m={0}>
          {item.name}
        </Heading>
        <Text color="muted">{item.clusterName}</Text>
      </Box>
      <Text color="danger" fontWeight="medium">
        {(item.latency || 0).toFixed(1)}ms
      </Text>
      <Box>
        <LinkButton onClick={(evt) => onShowDetails(evt, item)}>See Details</LinkButton>
        {/* <Heading level={6} m={0}>
          <Link to={`/v4/cloud/kube/cluster/${item.clusterId}/namespace/${item.namespace}`}>{item.namespace}</Link>
        </Heading> */}
      </Box>
    </Grid>
  );
};

@inject('$hybridMap')
@observer
export default class KubeProblems extends Component {
  get topologyCollection() {
    const { $hybridMap } = this.props;
    return $hybridMap.kubeCloudMapCollection;
  }

  handleShowDetails = (evt, item) => {
    const { $hybridMap, setSidebarDetails } = this.props;
    const { name, type, namespace, clusterId, latency } = item;
    const { Entities } = this.topologyCollection;
    const clusterEntity = this.topologyCollection.Entities[CLUSTER]?.[clusterId];

    evt.stopPropagation();
    $hybridMap.toggleProblematicPodsDialog();
    const healthData = { state: latency > $hybridMap.latencyThreshold ? 'CRITICAL' : 'GOOD' };
    const { device_name = 'Unknown', cloudProvider = 'aws' } = clusterEntity;

    setSidebarDetails({
      type: RESOURCE,
      subType: type,
      value: name,
      deviceName: device_name,
      cloudProvider,
      isKubeDetail: true,
      namespace,
      pods: false,
      entities: Entities,
      center: true,
      latency,
      showDetails: true,
      parentNodeData: {
        typeLabel: 'Cluster',
        name: clusterEntity.name
      },
      nodeData: {
        name,
        type,
        typeLabel: capitalize(type.replace(/s$/, '')),
        health: { cssClassName: getHealthClass(healthData), data: healthData },
        ...this.topologyCollection?.Entities?.[type]?.[name]
      }
    });
  };

  render() {
    const { $hybridMap } = this.props;
    const { highLatencyItems, highRetransmitItems } = $hybridMap.kubeState;

    return (
      <DialogBody>
        <CalloutOutline intent="danger" p={2} mb={2}>
          <Flex alignItems="center">
            <Icon icon="error" color="danger" iconSize={36} mr={2} />
            You should investigate these issues immediately because of their high latency or retransmits!
          </Flex>
        </CalloutOutline>

        {highLatencyItems.length > 0 && (
          <>
            <Heading level={3} mb={3}>
              High Latency
            </Heading>

            <PodHeaders />

            {highLatencyItems.map((item) => (
              <PodRow key={item.name} item={item} onShowDetails={this.handleShowDetails} />
            ))}
          </>
        )}

        {highRetransmitItems.length > 0 && (
          <>
            <Heading level={3} mb={3} mt={5}>
              High Retransmits
            </Heading>

            <PodHeaders />

            {highRetransmitItems.map((item) => (
              <PodRow key={item.name} item={item} onShowDetails={this.handleShowDetails} />
            ))}
          </>
        )}
      </DialogBody>
    );
  }
}
