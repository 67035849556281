import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { MdCompareArrows } from 'react-icons/md';
import { formatBytesGreek } from 'core/util';
import { Box, Flex, Grid, Heading, Icon, Link, Text } from 'core/components';
import LinkConnectionSelector from 'app/views/hybrid/maps/components/popovers/LinkConnectionSelector';

@inject('$hybridMap')
@observer
export default class SiteToSiteLinkSummary extends React.Component {
  get capacity() {
    const { connectionSummary } = this.props;

    if (connectionSummary.capacity === 0) {
      return '--';
    }

    return formatBytesGreek(connectionSummary.capacity * 1000000, 'bits/s');
  }

  render() {
    const { $hybridMap, connectionSummary, groupedConnections, linkLabel, onStateChange } = this.props;

    return (
      <Grid gridTemplateColumns="auto 1fr" p={2} borderBottom="thin">
        <Flex alignItems="center" gridColumn="1/3" mb={1}>
          <Icon icon={MdCompareArrows} iconSize={32} color="violet3" mr={1} />
          <Box minWidth={0}>
            <Text as="div" muted>
              Intersite Traffic Details
            </Text>
            <Heading level={4} mb={0}>
              <Link to={`${$hybridMap.baseRoute}/site/${connectionSummary.site1.id}`}>
                {connectionSummary.site1.value}
              </Link>
              <Text mx={1}>--</Text>
              <Link to={`${$hybridMap.baseRoute}/site/${connectionSummary.site2.id}`}>
                {connectionSummary.site2.value}
              </Link>
            </Heading>
          </Box>
        </Flex>

        {linkLabel && (
          <>
            <Text muted>Type</Text>
            <Text>{linkLabel}</Text>
          </>
        )}

        <Text muted>Capacity</Text>
        <Text>{this.capacity}</Text>

        {!$hybridMap.interSiteTrafficEnabled && (
          <Box gridColumn="1/3" mt={1}>
            <LinkConnectionSelector groupedConnections={groupedConnections} onStateChange={onStateChange} />
          </Box>
        )}
      </Grid>
    );
  }
}
