import React, { Component } from 'react';
import { withTheme } from 'styled-components';

import { Box, Flex, Icon, Link, Text } from 'core/components';
import CELL_ACTIONS from 'core/components/table/CELL_ACTIONS';
import FavoriteButton from 'app/views/core/FavoriteButton';
import GuidedViewForm from 'app/views/core/GuidedViewForm';
import KentikLogo from 'app/components/KentikLogo';

@withTheme
class LibraryPanel extends Component {
  maxItems = 50;

  render() {
    const { kebabActions, items, theme, type } = this.props;

    if (!items.length) {
      return (
        <Flex flexDirection="column" alignItems="center" justifyContent="center" gap={2} height="170px">
          {type === 'favorites' && <Icon icon="star-empty" size={32} color="muted" />}
          <Text muted>
            {type === 'favorites' && 'Favorite Dashboards and Saved Views will always appear here!'}
            {type === 'recent' && 'Recently viewed Dashboards and Saved Views will always appear here!'}
          </Text>
        </Flex>
      );
    }

    return (
      <Flex flexDirection="row" overflow="auto" height="100%">
        {items.slice(0, this.maxItems).map((item) => (
          <Flex
            flexDirection="column"
            key={item.id}
            border="thin"
            borderRadius={4}
            width="250px"
            minWidth="250px"
            p={2}
            mr={2}
          >
            <Flex alignItems="center">
              <FavoriteButton model={item} minHeight={0} minWidth={0} mr={1} />
              <Link
                alignItems="flex-start"
                fontWeight="bold"
                ellipsis
                whiteSpace="nowrap"
                overflow="hidden"
                to={
                  item.get('type') === 'dashboard'
                    ? `/v4/library/dashboards/${item.id}`
                    : `/v4/library/saved-views/${item.id}`
                }
              >
                {item.get('type') === 'dashboard' ? item.get('dash_title') : item.get('view_name')}
              </Link>
              <Flex ml="auto">{CELL_ACTIONS.KEBAB(kebabActions)(item)}</Flex>
            </Flex>
            <Text
              muted
              ellipsis
              whiteSpace="nowrap"
              overflow="hidden"
              minHeight="17px"
              ml="26px"
              width="100%"
              alignItems="center"
            >
              {item.shareLevel === 'Preset' ? (
                <Flex alignItems="center">
                  <KentikLogo onlyMark alt="Kentik" style={{ maxWidth: 20 }} />
                  Kentik Preset
                </Flex>
              ) : (
                item.author
              )}
            </Text>
            <Flex
              height="76px"
              alignItems="center"
              justifyContent="center"
              bg={theme.name === 'dark' ? 'primary' : '#e2f1fe'}
              borderRadius={4}
              mt={2}
            >
              {item.isParametric ? (
                <Box minWidth="100%" flex={1} p={2}>
                  <GuidedViewForm view={item} textStyle={{ color: theme.colors.body }} />
                </Box>
              ) : (
                <Icon
                  icon={item.get('type') === 'dashboard' ? 'new-grid-item' : 'grouped-bar-chart'}
                  size={24}
                  color="#9ecff9"
                />
              )}
            </Flex>
          </Flex>
        ))}
      </Flex>
    );
  }
}

export default LibraryPanel;
