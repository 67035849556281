import ip from 'app/forms/validations/ip';

function fn(value) {
  if (value) {
    return value.indexOf('/') === -1 && ip.fn(value);
  }

  return true;
}

export default {
  message: 'Enter a valid IPv4 or IPv6 address. CIDRs are not supported. ',
  fn
};
