import React from 'react';
import { observer } from 'mobx-react';
import { Text, Icon, Box, Flex } from 'core/components';
import CostGroupModel from 'app/stores/cost/CostGroupModel';

import ResultMenuItem from './ResultMenuItem';

function Provider({ item, selected, query, IconCmp, onItemClick, ...rest }) {
  const { id, name, interfaces, provider, costmodel, path } = item;

  const { label } = CostGroupModel.costModelOptions.find((option) => option.value === costmodel);

  return (
    <ResultMenuItem
      {...rest}
      selected={selected}
      onClick={() => onItemClick({ type: 'cost_group', id, path }, 'Search Results')}
    >
      <Flex gridArea="icon" alignItems="center" justifyContent="center">
        <IconCmp iconSize={22} />
      </Flex>

      <Box gridArea="title">
        <Text className="title">{name}</Text>
      </Box>

      <Flex gridArea="rightIcon" alignItems="center" justifyContent="center">
        <Icon icon={selected ? 'key-enter' : undefined} color="#ffffff" />
      </Flex>

      <Text
        className="description"
        gridArea="description"
        muted
        small
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        style={{ overflow: 'hidden' }}
      >
        {provider.name}, {label}, {interfaces.length} Interface
        {interfaces.length !== 1 && 's'}
      </Text>
    </ResultMenuItem>
  );
}

export default observer(Provider);
