import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Highcharts, Card, Popover } from 'core/components';
import Button from 'core/components/Button';
import { PopoverInteractionKind } from '@blueprintjs/core';

@inject('$colors')
@observer
export default class RawFpsChart extends Component {
  chartCallback = (chart) => {
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.chart = chart.container ? chart : null;
  };

  get chartConfig() {
    const { sampledFpsTimeSeries, rawFpsTimeSeries, maxFps } = this.props;
    return {
      chart: {
        type: 'line'
      },
      xAxis: {
        type: 'datetime'
      },
      legend: { enabled: true },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          }
        }
      },
      yAxis: {
        min: 0.001,
        title: {
          enabled: false
        }
      },
      series: [
        {
          name: 'Max FPS',
          data: (rawFpsTimeSeries || []).map((timeSeries) => ({ x: timeSeries[0], y: maxFps })),
          marker: {
            enabled: false
          }
        },
        {
          name: 'Raw',
          data: (rawFpsTimeSeries || []).map((timeSeries) => ({ x: timeSeries[0], y: timeSeries[1] }))
        },
        {
          name: 'Sampled',
          data: (sampledFpsTimeSeries || []).map((timeSeries) => ({ x: timeSeries[0], y: timeSeries[1] }))
        }
      ]
    };
  }

  render() {
    const { $colors } = this.props;

    return (
      <Popover
        content={
          <Card width={600}>
            <Highcharts
              allowChartUpdate
              height={325}
              width={600}
              options={this.chartConfig}
              callback={this.chartCallback}
              colors={$colors.qualitativeColors}
            />
          </Card>
        }
        position="right"
        interactionKind={PopoverInteractionKind.HOVER}
        minimal
        usePortal
        target={<Button small minimal icon="series-search" />}
      />
    );
  }
}
