import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';

import lazyLoad from 'core/util/lazyLoad';
import ProtectedRoute from 'app/components/ProtectedRoute';
import DashboardView from 'app/views/core/dashboards/DashboardView';
import NetworkHealthTestDetail from 'app/views/core/networkHealth/NetworkHealthTestDetail';
import NotFound from 'app/views/NotFound';
import NetworkExplorer from 'app/views/core/NetworkExplorer';
import CloudProvider from 'app/views/core/cloud/CloudProvider';
import CloudDetail from 'app/views/core/cloud/CloudDetail';

import Explorer from './explorer/Explorer';
import Journeys from './journeys/Journeys';

import ApplicationAggregate from './aggregate/ApplicationAggregate';
import ApplicationDetails from './details/ApplicationDetails';
import AsnAggregate from './aggregate/AsnAggregate';
import AsnDetails from './details/AsnDetails';
import ASPathAggregate from './aggregate/AsPathAggregate';
import ASPathDetails from './details/ASPathDetails';
import CityAggregate from './aggregate/CityAggregate';
import CityDetails from './details/CityDetails';
import ConnectivityTypeAggregate from './aggregate/ConnectivityTypeAggregate';
import ConnectivityTypeDetails from './details/ConnectivityTypeDetails';
import CountryAggregate from './aggregate/CountryAggregate';
import CountryDetails from './details/CountryDetails';
import InetFamilyAggregate from './aggregate/InetFamilyAggregate';
import InetFamilyDetails from './details/InetFamilyDetails';
import IPAggregate from './aggregate/IPAggregate';
import IPDetails from './details/IPDetails';
import NetworkBoundaryAggregate from './aggregate/NetworkBoundaryAggregate';
import NetworkBoundaryDetails from './details/NetworkBoundaryDetails';
import NextHopAsnAggregate from './aggregate/NextHopAsnAggregate';
import NextHopAsnDetails from './details/NextHopAsnDetails';
import PacketSizeAggregate from './aggregate/PacketSizeAggregate';
import PacketSizeDetails from './details/PacketSizeDetails';
import ProtocolAggregate from './aggregate/ProtocolAggregate';
import ProtocolDetails from './details/ProtocolDetails';
import ProviderAggregate from './aggregate/ProviderAggregate';
import ProviderDetails from './details/ProviderDetails';
import RegionAggregate from './aggregate/RegionAggregate';
import RegionDetails from './details/RegionDetails';
import RoutePrefixAggregate from './aggregate/RoutePrefixAggregate';
import RoutePrefixDetails from './details/RoutePrefixDetails';
import ServiceAggregate from './aggregate/ServiceAggregate';
import ServiceDetails from './details/ServiceDetails';
import BgpCommunityAggregate from './aggregate/BgpCommunityAggregate';
import BgpCommunityDetails from './details/BgpCommunityDetails';
import Sites from './aggregate/Sites';
import SiteDetails from './details/sites/SiteDetails';
import Interfaces from './aggregate/Interfaces';
import InterfaceDetails from './details/interfaces/InterfaceDetails';
import TCPTraffic from './TCPTraffic';
import Capacity from './capacity';
import CoreNoFlowRoutes from './CoreNoFlowRoutes';

const NetworkHealth = lazyLoad(() => import('./networkHealth/NetworkHealth'));
const NetworkHealthConfig = lazyLoad(() => import('./networkHealth/NetworkHealthConfig'));
const ForensicRawFlow = lazyLoad(() => import('./rawFlow/ForensicRawFlow'));
const RawFlowExplorer = lazyLoad(() => import('./rawFlow/RawFlowExplorer'));

const QueryEditor = lazyLoad(() => import('./queryEditor/QueryEditor'));
const ApiTester = lazyLoad(() => import('../apiTester'));

const BASE_ROUTE = '/v4/core';
export const QUICK_VIEW_ROUTE = `${BASE_ROUTE}/quick-views`;
const IP_BGP = 'IP & BGP Routing';
const NETWORK_TRAFFIC = 'Network & Traffic';
const GEO = 'Geographic';
const APP = 'Application Context';
const CLOUD = 'Cloud';

export const AGGREGATE_VIEWS = [
  { path: `${QUICK_VIEW_ROUTE}/application`, name: 'Applications', component: ApplicationAggregate, group: APP },
  { path: `${QUICK_VIEW_ROUTE}/asn`, name: 'ASNs', component: AsnAggregate, group: IP_BGP },
  { path: `${QUICK_VIEW_ROUTE}/aspath`, name: 'AS Paths', component: ASPathAggregate, group: IP_BGP },
  { path: `${QUICK_VIEW_ROUTE}/bgpCommunity`, name: 'BGP Community', component: BgpCommunityAggregate, group: IP_BGP },
  { path: `${QUICK_VIEW_ROUTE}/country`, name: 'Countries', component: CountryAggregate, group: GEO },
  { path: `${QUICK_VIEW_ROUTE}/region`, name: 'Regions', component: RegionAggregate, group: GEO },
  { path: `${QUICK_VIEW_ROUTE}/city`, name: 'Cities', component: CityAggregate, group: GEO },
  { path: `${QUICK_VIEW_ROUTE}/inet-family`, name: 'INET Family', component: InetFamilyAggregate, group: IP_BGP },
  { path: `${QUICK_VIEW_ROUTE}/ip`, name: 'IP Addresses', component: IPAggregate, group: IP_BGP },
  { path: `${QUICK_VIEW_ROUTE}/nextHopAsn`, name: 'Next-Hop ASNs', component: NextHopAsnAggregate, group: IP_BGP },
  { path: `${QUICK_VIEW_ROUTE}/packet-size`, name: 'Packet Size', component: PacketSizeAggregate, group: IP_BGP },
  { path: `${QUICK_VIEW_ROUTE}/protocol`, name: 'Protocols', component: ProtocolAggregate, group: IP_BGP },
  { path: `${QUICK_VIEW_ROUTE}/route-prefix`, name: 'Route Prefixes', component: RoutePrefixAggregate, group: IP_BGP },
  { path: `${QUICK_VIEW_ROUTE}/service`, name: 'Services', component: ServiceAggregate, group: APP },
  { path: `${QUICK_VIEW_ROUTE}/sites`, name: 'Sites', component: Sites, group: NETWORK_TRAFFIC },
  { path: `${QUICK_VIEW_ROUTE}/devices`, name: 'Devices', group: NETWORK_TRAFFIC, ignoreRoute: true },
  { path: `${QUICK_VIEW_ROUTE}/interfaces`, name: 'Interfaces', component: Interfaces, group: NETWORK_TRAFFIC },
  { path: `${QUICK_VIEW_ROUTE}/provider`, name: 'Providers', component: ProviderAggregate, group: NETWORK_TRAFFIC },
  {
    path: `${QUICK_VIEW_ROUTE}/connectivity-type`,
    name: 'Connectivity Types',
    component: ConnectivityTypeAggregate,
    group: NETWORK_TRAFFIC
  },
  {
    path: `${QUICK_VIEW_ROUTE}/network-boundary`,
    name: 'Network Boundaries',
    component: NetworkBoundaryAggregate,
    group: NETWORK_TRAFFIC
  },

  { path: `${QUICK_VIEW_ROUTE}/cloud/aws`, name: 'Amazon Web Services', component: CloudProvider, group: CLOUD },
  { path: `${QUICK_VIEW_ROUTE}/cloud/gce`, name: 'Google Cloud Platform', component: CloudProvider, group: CLOUD },
  { path: `${QUICK_VIEW_ROUTE}/cloud/azure`, name: 'Microsoft Azure', component: CloudProvider, group: CLOUD }
];

@inject('$app', '$auth', '$devices')
@observer
class CoreRoutes extends Component {
  get baseRoute() {
    const { $app } = this.props;
    return $app.isExport ? '/v4/export/core' : BASE_ROUTE;
  }

  quickViewRoute = `${this.baseRoute}/quick-views`;

  get aggregateViews() {
    const { $app } = this.props;
    return (
      $app.isExport
        ? AGGREGATE_VIEWS.map((view) => ({ ...view, path: view.path.replace('/v4/', '/v4/export/') }))
        : AGGREGATE_VIEWS
    ).filter((view) => !view.ignoreRoute);
  }

  render() {
    const { $auth, $devices } = this.props;
    const hasFlow = $devices.hasReceivedFlow;

    if (!hasFlow) {
      return <CoreNoFlowRoutes />;
    }

    return (
      <Switch>
        <Route exact path={this.baseRoute} component={NetworkExplorer} />
        <Route path={`${this.baseRoute}/capacity`} component={Capacity} />
        <Route exact path="/v4/core/explorer" component={Explorer} />
        <Route exact path="/v4/core/explorer/preset" component={Explorer} />
        <Route exact path="/v4/core/explorer/:urlHash" component={Explorer} />
        <Route exact path="/v4/core/explorer/urlParams/:params" component={Explorer} />
        <Redirect from="/v4/core/explorer/alarm/:id" to="/v4/alerting/:id" />

        <Route exact path="/v4/core/journeys/:journeyId?" component={Journeys} />

        {$auth.isSudoer && <Route path="/v4/core/query-editor" component={QueryEditor} />}
        <ProtectedRoute
          path="/v4/core/forensic-raw-flow/:hash?"
          component={ForensicRawFlow}
          permission="rawFlow.forensicViewer"
        />
        <ProtectedRoute path="/v4/core/raw-flow/:hash?" component={RawFlowExplorer} permission="rawFlow.viewer" />
        <Route exact path="/v4/core/network-availability" component={NetworkHealth} />
        <Route path="/v4/core/network-availability/add/:type" component={NetworkHealthConfig} />
        <Route path="/v4/core/network-availability/:testId" component={NetworkHealthTestDetail} />
        <Route path="/v4/core/api-tester" component={ApiTester} />

        <Route exact path={`${this.quickViewRoute}/cloud/:cloudProvider`} component={CloudProvider} />
        <Route exact path={`${this.quickViewRoute}/cloud/:cloudProvider/:detail/:value`} component={CloudDetail} />

        {this.aggregateViews.map((route) => (
          <Route key={route.path} exact {...route} />
        ))}

        <Route path={`${this.quickViewRoute}/application/:application`} component={ApplicationDetails} />
        <Route path={`${this.quickViewRoute}/asn/:asn/:tab?`} component={AsnDetails} />
        <Route path={`${this.quickViewRoute}/aspath/:asPath`} component={ASPathDetails} />
        <Route path={`${this.quickViewRoute}/city/:city/:region/:country`} component={CityDetails} />
        <Route path={`${this.quickViewRoute}/country/:country`} component={CountryDetails} />
        <Route path={`${this.quickViewRoute}/connectivity-type/:connType`} component={ConnectivityTypeDetails} />
        <Route path={`${this.quickViewRoute}/inet-family/:inetFamily`} component={InetFamilyDetails} />
        <Route path={`${this.quickViewRoute}/ip/:ip_address/:cidr(\\d*)?/:tab?`} component={IPDetails} />
        <Route path={`${this.quickViewRoute}/network-boundary/:netBoundary`} component={NetworkBoundaryDetails} />
        <Route path={`${this.quickViewRoute}/nextHopAsn/:nextHopAsn`} component={NextHopAsnDetails} />
        <Route path={`${this.quickViewRoute}/packet-size/:packetSize`} component={PacketSizeDetails} />
        <Route path={`${this.quickViewRoute}/protocol/:protocol`} component={ProtocolDetails} />
        <Route path={`${this.quickViewRoute}/provider/:provider`} component={ProviderDetails} />
        <Route path={`${this.quickViewRoute}/region/:region/:country`} component={RegionDetails} />
        <Route path={`${this.quickViewRoute}/route-prefix/:prefix/:len`} component={RoutePrefixDetails} />
        <Route path={`${this.quickViewRoute}/service/:service`} component={ServiceDetails} />
        <Route path={`${this.quickViewRoute}/bgpCommunity/:bgpCommunity`} component={BgpCommunityDetails} />
        <Route path={`${this.quickViewRoute}/sites/:siteId/:tab?`} component={SiteDetails} />
        <Redirect
          from={`${this.quickViewRoute}/devices/:deviceName/:tab?`}
          to="/v4/infrastructure/devices/:deviceName/:tab?"
        />
        <Redirect from={`${this.quickViewRoute}/devices`} to="/v4/infrastructure/devices?tab=traffic" />
        <Route path={`${this.quickViewRoute}/interfaces/:deviceInterfaceHash/:tab?`} component={InterfaceDetails} />
        <Route exact path={`${this.quickViewRoute}/tcp-traffic`} component={TCPTraffic} />
        <Route exact path={`${this.quickViewRoute}/tcp-traffic/:dashboardSlug`} component={DashboardView} />
        <Route exact path={`${this.quickViewRoute}/dns-traffic/:dashboardSlug`} component={DashboardView} />

        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}

export default CoreRoutes;
