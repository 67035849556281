import { get } from 'lodash';
import { inject } from 'mobx-react';
import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { Box, ButtonLink, Flex, Text, Heading } from 'core/components';
import { Grid } from 'app/components/svg';
import classNames from 'classnames';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import getScrollbarWidth from 'core/util/getScrollbarWidth';
import { ENTITY_TYPES } from 'shared/hybrid/constants';

import RegionEntity from './RegionEntity';
import RegionExpandedEntityMap from './RegionExpandedEntityMap';

const { VIRTUAL_CLOUD_NETWORK, DYNAMIC_ROUTING_GATEWAY, REGION } = ENTITY_TYPES.get('oci');

const miniItemMargin = 3;
const miniItemSize = 20;

const normalItemMarginX = 12;
const normalItemMarginY = 20;
const normalItemWidth = 170;
const normalItemHeight = 52;

const expandedItemMargin = normalItemMarginX;
const expandedItemWidth = normalItemWidth;
const expandedItemHeight = normalItemHeight + 76;

// const connectionItemWidth = 160;

@withTheme
@inject('$hybridMap')
export default class Region extends Component {
  static defaultProps = {
    width: 500,
    padding: 16,
    selected: false,
    selectedRegions: [],
    highlighted: false,
    highlightedRegions: []
  };

  state = {
    selectedRegionItem: null
  };

  get regionVcns() {
    const { region } = this.props;

    return get(region, VIRTUAL_CLOUD_NETWORK, []);
  }

  get svgWidth() {
    const { width } = this.props;
    return width - (this.regionConnections.length > 0 ? normalItemWidth / 2 : 0);
  }

  get availableWidth() {
    const { padding } = this.props;
    return this.svgWidth - getScrollbarWidth() - padding * 2;
  }

  // how many items will fit
  getColumns(mini = this.mini) {
    const { availableWidth } = this;
    return mini
      ? Math.floor((availableWidth + miniItemMargin) / (miniItemSize + miniItemMargin))
      : Math.floor((availableWidth + normalItemMarginX) / (normalItemWidth + normalItemMarginX));
  }

  get canExpand() {
    return this.regionVcns.length > 0;
  }

  get mini() {
    return this.regionVcns.length > this.getColumns(false);
  }

  get regionEntityItemProps() {
    const { padding } = this.props;
    const { mini, isExpanded } = this;

    // assume normal sizing
    const props = {
      maxHeight: 9 * (normalItemHeight + normalItemMarginY) + padding,
      itemWidth: normalItemWidth,
      itemHeight: normalItemHeight,
      itemMarginX: normalItemMarginX,
      itemMarginY: normalItemMarginY,
      paddingX: 0
    };

    if (mini) {
      // mini mode has condensed boxes with tooltips
      props.itemWidth = miniItemSize;
      props.itemHeight = miniItemSize;
      props.itemMarginX = miniItemMargin;
      props.itemMarginY = miniItemMargin;
      props.renderTooltipContent = (entity) => (
        <Box>
          <Box fontWeight="bold">{entity.displayName || entity.id}</Box>
          {entity.name && (
            <Box fontSize="small" fontStyle="italic">
              {entity.id}
            </Box>
          )}
          {/* @TODO render address(es) */}
          <Box fontSize="small">{entity.cidrBlock}</Box>
        </Box>
      );

      return props;
    }

    if (isExpanded) {
      props.itemWidth = expandedItemWidth;
      props.itemHeight = expandedItemHeight;
      props.itemMarginX = expandedItemMargin;
      props.itemMarginY = expandedItemMargin;
    }

    return props;
  }

  // connection on a right side
  get regionConnections() {
    const { region } = this.props;

    return region[DYNAMIC_ROUTING_GATEWAY] ?? [];
  }

  getRegionItemColor = () => {
    const { theme } = this.props;
    return theme.colors.oci.primary;
  };

  selectRegionItem = (regionItem) => {
    const { selectedRegionItem } = this.state;
    const { onToggleRegionItem } = this.props;
    this.setState({ selectedRegionItem: regionItem });

    if (selectedRegionItem) {
      onToggleRegionItem({ type: selectedRegionItem.type, value: selectedRegionItem.id });
    }
    onToggleRegionItem({ type: regionItem.type, value: regionItem.id });
  };

  unSelectRegionItem = () => {
    const { selectedRegionItem } = this.state;
    const { onToggleRegionItem } = this.props;
    if (selectedRegionItem) {
      onToggleRegionItem({ type: selectedRegionItem.type, value: selectedRegionItem.id });
    }
    this.setState({ selectedRegionItem: null });
  };

  handleShowDetails = (event) => {
    event.stopPropagation();
    const { onShowDetails, region } = this.props;

    onShowDetails({ type: REGION, nodeData: region });
  };

  render() {
    const { theme, region, padding, onShowDetails } = this.props;
    const { regionVcns, svgWidth, mini } = this;
    const { selectedRegionItem } = this.state;

    return (
      <Box width="100%">
        <Flex alignItems="center" justifyContent="space-between" pt={`${padding}px`} px={`${padding}px`}>
          <Flex alignItems="center" gap={1}>
            <CloudIcon cloudProvider="oci" entity="cloudService" stroke={theme.colors.oci.primary} />
            <Heading level={6} mb={0} display="flex" alignItems="center">
              {region.regionName}
            </Heading>
          </Flex>
          <Box>
            <ButtonLink small ml={1} onClick={this.handleShowDetails}>
              Show Details
            </ButtonLink>
            <Text small muted ml={1}>
              {regionVcns?.length} VCN{regionVcns?.length === 1 ? '' : 's'}
            </Text>
          </Box>
        </Flex>

        <Flex justifyContent="space-between" my={`${padding / 2}px`} mb="30px">
          <Flex flexDirection="column" justifyContent="space-between" mx={padding}>
            {selectedRegionItem && (
              <Box mb={4}>
                <RegionExpandedEntityMap
                  padding={padding / 2}
                  width={this.availableWidth}
                  onShowDetails={onShowDetails}
                  onClose={this.unSelectRegionItem}
                  selectedEntity={selectedRegionItem}
                />
              </Box>
            )}

            {/* VCNs section in the bottom */}
            <Box mt="auto">
              <Grid
                items={[...regionVcns]}
                width={svgWidth}
                paddingX={padding}
                paddingY={padding / 2}
                getItemFill={this.getRegionItemColor}
                getItemProps={(regionItem) => ({
                  innerHeight: regionItem.id === selectedRegionItem?.id ? normalItemHeight : null
                })}
                className={classNames(
                  'hybrid-map-selectable-node',
                  // getMapClassname({ type: entityType, value: region.id }),
                  {
                    highlighted: true,
                    selected: false
                  }
                )}
                onItemClick={this.selectRegionItem}
                renderItem={(entity, props) => (
                  <RegionEntity
                    key={entity.id}
                    expanded={entity.id === selectedRegionItem?.id}
                    mini={mini}
                    entity={entity}
                    {...props}
                  />
                )}
                {...this.regionEntityItemProps}
              />
            </Box>
          </Flex>

          {/* DGR on a right */}
          <Flex flexDirection="column" gap="40px" mx="auto" mb="30px">
            {this.regionConnections.map((regionConnection) => (
              <RegionEntity
                key={regionConnection.id}
                strokeWidth={4}
                entity={regionConnection}
                height={normalItemHeight}
                width={normalItemWidth}
                fill={theme.colors.oci.primary}
                onShowDetails={onShowDetails}
                expanded
                withArrow
              />
            ))}
          </Flex>
        </Flex>
      </Box>
    );
  }
}
