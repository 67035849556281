import { SYN_CONSTANTS } from 'shared/synthetics/constants';

import { baseFields } from './baseFields';
import { pingFields } from './pingFields';
import { baseHTTPFields } from './baseHTTPFields';
import { baseBgpFields } from './baseBgpFields';

const urlOptions = {
  name: 'HTTP URL Test'
};

const out = (value) => {
  const val = parseFloat(value, 10);
  const result = typeof val === 'number' && Number.isInteger(val) ? parseInt(value) : value;
  return result;
};

const urlFields = {
  ...baseFields,
  ...pingFields,
  ...baseHTTPFields,
  ...baseBgpFields,

  'config.healthSettings.certExpiryWarning': {
    defaultValue: SYN_CONSTANTS.certExpiryDefaultWarningDays,
    rules: ['integer', 'min:0'],
    transform: {
      out
    }
  },

  'config.healthSettings.certExpiryCritical': {
    defaultValue: SYN_CONSTANTS.certExpiryDefaultCriticalDays,
    rules: ['integer', 'min:0'],
    transform: {
      out
    }
  },

  'config.tasks': {
    defaultValue: ['http', 'ping', 'traceroute']
  },

  'config.target.value': {
    rules: ['required', { urlSingleDomain: { extraTopLevelDomains: ['local'] } }]
  },

  'config.http': {},

  'config.http.ignore_tls_errors': {
    defaultValue: false
  },

  'config.http.method': {
    defaultValue: 'GET',
    options: [
      { label: 'GET', value: 'GET' },
      { label: 'HEAD', value: 'HEAD' },
      { label: 'PATCH', value: 'PATCH' },
      { label: 'POST', value: 'POST' },
      { label: 'PUT', value: 'PUT' }
    ]
  },

  'config.http.params': {
    isComplexArray: true
  },
  'config.http.params[].key': {},
  'config.http.params[].value': {},

  'config.http.headers': {
    isComplexArray: true
  },
  'config.http.headers[].key': {},
  'config.http.headers[].value': {},
  'config.http.headers[].hide': {},

  'config.http.css_selectors': {
    isComplexArray: true,
    rules: 'max:5'
  },
  'config.http.css_selectors[].key': {},
  'config.http.css_selectors[].value': {},

  'config.http.bodyType': {
    defaultValue: 'raw',
    options: [
      { label: 'none', value: 'none' },
      { label: 'x-www-form-urlencoded', value: 'x-www-form-urlencoded' },
      { label: 'raw', value: 'raw' }
    ]
  },

  'config.http.rawType': {
    defaultValue: 'plain',
    options: [
      { label: 'Text', value: 'plain' },
      { label: 'JavaScript', value: 'javascript' },
      { label: 'JSON', value: 'json' },
      { label: 'HTML', value: 'html' },
      { label: 'XML', value: 'xml' }
    ]
  },

  'config.http.formValues': {
    isComplexArray: true
  },
  'config.http.formValues[].key': {},
  'config.http.formValues[].value': {},

  'config.http.body': {
    defaultValue: ''
  },

  'config.period': {
    label: 'Test Frequency',
    defaultValue: 60,
    rules: ['numeric', 'isValidTestFrequency'],
    options: [
      { label: 'Every minute', value: 60 },
      { label: 'Every 2 minutes', value: 120 },
      { label: 'Every 5 minutes', value: 300 },
      { label: 'Every 10 minutes', value: 600 },
      { label: 'Every 15 minutes', value: 900 },
      { label: 'Every 30 minutes', value: 1800 },
      { label: 'Every 60 minutes', value: 3600 }
    ]
  },

  'config.expiry': {
    label: 'HTTP Timeout (ms)',
    defaultValue: SYN_CONSTANTS.httpExpiryDefaultMs,
    rules: ['numeric', `min: ${SYN_CONSTANTS.httpExpiryMinMs}`, `max: ${SYN_CONSTANTS.httpExpiryMaxMs}`]
  }
};

export { urlOptions, urlFields };
