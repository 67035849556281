import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

@inject('$auth')
@observer
export default class MKPDefaultView extends Component {
  render() {
    const { $auth } = this.props;
    const { assets } = $auth.subtenantConfig;
    const lastVistedPage = localStorage.getItem('kentik.lastVisitedPage');

    const redirects = {
      dashboard: (value) => `/v4/library/dashboards/${value}`,
      savedView: (value) => `/v4/library/saved-views/${value}`
    };

    if (lastVistedPage) {
      localStorage.removeItem('kentik.lastVisitedPage');
      return <Redirect to={lastVistedPage} />;
    }

    if (assets?.defaultLandingPageType === 'selectedView' && assets?.defaultLandingPageValue) {
      const [id, type] = assets.defaultLandingPageValue.split('|');

      return <Redirect to={redirects[type](id)} />;
    }

    return <Redirect to="/v4/landing" />;
  }
}
