import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FiMoreVertical } from 'react-icons/fi';

import { Button, Menu, Popover } from 'core/components';

import ChangeViewTypeMenu from 'app/components/dataviews/tools/ChangeViewTypeMenu';
import ExportMenuItem from 'app/components/dataviews/tools/options/ExportMenuItem';

const disallowChangeViewTypes = ['alertScoreboard', 'rawFlow', 'syntheticsTest', 'metrics'];
const disallowExportTypes = ['alertScoreboard', 'rawFlow', 'syntheticsTest', 'metrics'];

@inject('$app', '$auth')
@observer
class DashboardItemViewControls extends Component {
  render() {
    const { item, $app, $auth, onViewTypeChange, buttonProps } = this.props;
    const { dataview } = item;
    const { fullyLoaded, viewType } = dataview;

    if (!fullyLoaded) {
      return null;
    }

    const menuOptions = [];
    if (
      (!$app.isSubtenant || !$auth.hasPermission('subtenancy.disableVizTypeChanges')) &&
      !disallowChangeViewTypes.includes(viewType)
    ) {
      menuOptions.push(
        <ChangeViewTypeMenu
          key="change-view"
          asMenuItem
          dataview={dataview}
          onViewTypeChange={onViewTypeChange}
          showButtonText={false}
        />
      );
    }

    if (!disallowExportTypes.includes(viewType)) {
      menuOptions.push(<ExportMenuItem key="export" dataview={dataview} />);
    }

    if (menuOptions.length === 0) {
      return null;
    }

    return (
      <Popover content={<Menu>{menuOptions}</Menu>} onInteraction={this.handleInteraction} position="bottom-right">
        <Button icon={FiMoreVertical} {...buttonProps} />
      </Popover>
    );
  }
}

export default DashboardItemViewControls;
