import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import classNames from 'classnames';
import { path } from 'd3-path';
import { select, event } from 'd3-selection';
import { arc } from 'd3-shape';
import { transparentize } from 'polished';
import { getMapClassname } from 'app/views/hybrid/utils/map';
import { splitPath, getTrafficLabel, addTrafficLabelArrow } from '../../../utils/links';

const ChartGroup = styled.g`
  transition: transform 600ms;

  .node {
    .node-bg {
      cursor: pointer;
      fill: ${({ theme }) => transparentize(0.85, theme.colors.primary)};
      fill-opacity: 0;
    }

    .node-arc {
      pointer-events: none;
      fill: white;
      fill-opacity: 0.8;
      stroke: lightgray;
      stroke-width: 1px;
    }

    text {
      fill: ${themeGet('colors.body')};
      font-size: 11px;
      pointer-events: none;
    }

    &.healthy,
    &.warning,
    &.critical {
      .node-bg {
        stroke-width: 0px;
        fill-opacity: 0;
      }

      .node-arc {
        stroke-width: 0px;
      }
    }

    ${({ theme }) => css`
      &.healthy {
        .node-bg {
          fill: ${transparentize(0.85, theme.colors.success)};
          stroke: ${theme.colors.success};
        }
        .node-arc,
        text {
          fill: ${theme.colors.success};
        }
      }

      &.warning {
        .node-bg {
          fill: ${transparentize(0.85, theme.colors.warning)};
          stroke: ${theme.colors.warning};
        }
        .node-arc,
        text {
          fill: ${theme.colors.warning};
        }
      }

      &.critical {
        .node-bg {
          fill: ${transparentize(0.85, theme.colors.danger)};
          stroke: ${theme.colors.danger};
        }
        .node-arc,
        text {
          fill: ${theme.colors.danger};
        }
      }
    `}
  }

  .node-unselected {
    .node-arc {
      fill-opacity: 0.15;
    }

    text {
      opacity: 0.3;
    }
  }

  .node-hovered {
    .node-bg {
      stroke: lightgray;
      fill: ${transparentize(0.85, 'lightgray')};
      fill-opacity: 1;
    }

    .node-arc {
      fill-opacity: 1;
    }

    text {
      opacity: 1;
    }

    &.healthy,
    &.warning,
    &.critical {
      .node-bg {
        fill-opacity: 1;
        stroke-width: 1px;
      }
    }
  }

  .node-highlighted,
  .node-selected {
    .node-bg {
      stroke: ${themeGet('colors.primary')};
      stroke-width: 2px;
      fill-opacity: 1;
    }

    .node-arc {
      fill: white;
      stroke: ${themeGet('colors.primary')};
    }

    text {
      fill: ${themeGet('colors.primary')};
      opacity: 1;
      font-weight: 500;
    }

    &.healthy,
    &.warning,
    &.critical {
      .node-bg {
        fill-opacity: 1;
        stroke-width: 2px;
      }
    }
  }

  .node-highlighted {
    .node-bg {
      stroke-dasharray: 7 3;
    }
  }
`;

function normalizeAngle(angle, min = 0) {
  while (angle < min) {
    angle += 2 * Math.PI;
  }

  while (angle >= min + 2 * Math.PI) {
    angle -= 2 * Math.PI;
  }

  return angle;
}

export default class ChordLayout extends Component {
  static defaultProps = {
    classPrefix: 'node',
    nameProp: 'name',
    labelProp: 'label',
    items: [],
    highlighted: [],
    rotateOnSelect: true,
    getLink: () => true
  };

  static getLabelWidth(props) {
    const { items, labelProp, nameProp } = { ...this.defaultProps, ...props };
    const labelLength = items.reduce((length, item) => Math.max(length, (item[labelProp] || item[nameProp]).length), 6);
    const labelWidth = 12 + labelLength * 6.75; // estimate 6.75px per character
    return labelWidth;
  }

  state = {
    rotation: 0,
    hoverIndex: -1,
    selectIndex: -1,
    hoveredLink: null,
    selectedLink: null
  };

  chart = React.createRef();

  nodeGroup = React.createRef();

  ribbonGroup = React.createRef();

  onTransitionEnd = null;

  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate(prevProps, prevState) {
    const { nameProp, items, selected, isPopoverOpen, linkMatrix } = this.props;
    const { rotation } = this.state;

    if (prevProps.selected !== selected) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectIndex: selected ? items.findIndex((item) => item[nameProp] === selected[nameProp]) : -1 });
    }

    if (prevProps.isPopoverOpen && !isPopoverOpen) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedLink: null });
    }

    if (
      prevProps.items.length !== items.length ||
      prevState.rotation !== rotation ||
      prevProps.linkMatrix !== linkMatrix
    ) {
      this.drawChart();
    }

    this.highlightChart();
  }

  drawChart() {
    const { classPrefix, nameProp, labelProp, items, getLink, width, height, interSiteTrafficEnabled } = this.props;
    const { rotation } = this.state;

    const labelWidth = ChordLayout.getLabelWidth(this.props);
    const outerRadius = Math.max(Math.min(width - labelWidth * 2 - 16, height - labelWidth * 2 - 16) / 2, 50);
    const innerRadius = outerRadius - 20;

    const padAngle = (3 * Math.PI) / 4 / items.length;
    const arcAngle = Math.min(Math.PI / 4, (2 * Math.PI - padAngle * items.length) / items.length);
    const bgPadAngle = Math.min(0.06, padAngle / 3); // 2/3 of the padAngle on either side

    const drawMainArc = arc().innerRadius(innerRadius).outerRadius(outerRadius);
    const drawBgArc = arc()
      .innerRadius(innerRadius - 5)
      .outerRadius(outerRadius + labelWidth)
      .cornerRadius(8)
      .startAngle((d) => d.startAngle - bgPadAngle)
      .endAngle((d) => d.endAngle + bgPadAngle);

    const drawChord = (d) => {
      const startAngle = d.startAngle - Math.PI / 2;
      const endAngle = d.endAngle - Math.PI / 2;
      const start = [innerRadius * Math.cos(startAngle), innerRadius * Math.sin(startAngle)];
      const end = [innerRadius * Math.cos(endAngle), innerRadius * Math.sin(endAngle)];
      const context = path();

      context.moveTo(start[0], start[1]);
      context.quadraticCurveTo(0, 0, end[0], end[1]);

      return context.toString();
    };

    const chordGroups = items.map((key, index) => {
      const { id, name, health } = key;
      const angle = normalizeAngle(rotation + (padAngle + arcAngle) * index);
      return {
        id,
        name,
        index,
        health,
        labelRadius: outerRadius + labelWidth,
        angle,
        startAngle: angle - arcAngle / 2,
        endAngle: angle + arcAngle / 2
      };
    });

    const chords = [];
    for (let s = 0; s < chordGroups.length - 1; s += 1) {
      const sourceGroup = chordGroups[s];
      for (let t = s + 1; t < chordGroups.length; t += 1) {
        const targetGroup = chordGroups[t];
        const link = getLink(items[s], items[t]);

        if (link) {
          const { totalTraffic, inTraffic, outTraffic, isFiltered, capacity } = link;
          const pathData = drawChord({ startAngle: sourceGroup.angle, endAngle: targetGroup.angle });
          let trafficData = {};

          if (totalTraffic) {
            const [sourcePath, targetPath] = splitPath(pathData, { at: outTraffic / totalTraffic, minimumPiece: 20 });
            const [sourceLabelPath, targetLabelPath] = splitPath(pathData, {
              margin: 0,
              minimumPiece: 0,
              orientationCheckDirection: 'target'
            });

            trafficData = {
              sourcePath,
              targetPath,
              sourceLabelPath,
              targetLabelPath,
              sourceTraffic: getTrafficLabel(outTraffic, capacity),
              targetTraffic: getTrafficLabel(inTraffic, capacity)
            };
          }

          chords.push({ pathData, source: sourceGroup, target: targetGroup, isFiltered, ...trafficData });
        }
      }
    }

    const groups = select(this.nodeGroup.current)
      .selectAll('g')
      .data(chordGroups)
      .join('g')
      .attr('class', (d) =>
        classNames('node', 'hybrid-map-selectable-node', `node-${d.index}`, d.health && d.health.cssClassName)
      )
      .text('');

    groups
      .append('path')
      .on('click', (d) => this.handleSelect(d))
      .on('mouseenter', (d) => this.setState({ hoverIndex: d.index }))
      .on('mouseleave', () => this.setState({ hoverIndex: -1 }))
      .attr('class', (d) =>
        classNames('node-bg', getMapClassname({ type: classPrefix, value: items[d.index][nameProp] }))
      )
      .attr('d', drawBgArc);

    groups.append('path').attr('class', 'node-arc').attr('d', drawMainArc);

    groups
      .append('text')
      .attr('dy', '.35em')
      .attr(
        'transform',
        (d) =>
          `rotate(${(d.angle * 180) / Math.PI - 90}) ` +
          `translate(${outerRadius + 6}) ` +
          `rotate(${d.angle > Math.PI ? 180 : 0})`
      )
      .attr('text-anchor', (d) => (d.angle > Math.PI ? 'end' : null))
      .text((d) => items[d.index][labelProp] || items[d.index][nameProp]);

    select(this.ribbonGroup.current)
      .selectAll('g')
      .data(chords)
      .join('g')
      .text('')
      .each((d, idx, nodes) => {
        const g = select(nodes[idx]).attr('class', 'hybrid-link');

        if (d.sourcePath && d.targetPath) {
          g.append('path')
            .attr(
              'class',
              classNames('hybrid-link-line', d.sourcePath.textAlign, {
                'animated-traffic': d.isFiltered,
                filtered: d.isFiltered
              })
            )
            .attr('id', `chord-line-source-${d.source.index}-${d.target.index}`)
            .attr('d', d.sourcePath.pathData);

          g.append('path')
            .attr(
              'class',
              classNames('hybrid-link-line', d.targetPath.textAlign, {
                'animated-traffic': d.isFiltered,
                filtered: d.isFiltered
              })
            )
            .attr('id', `chord-line-target-${d.source.index}-${d.target.index}`)
            .attr('d', d.targetPath.pathData);

          g.append('path')
            .attr('class', classNames('hybrid-link-head', { filtered: d.isFiltered }))
            .attr('d', d.sourcePath.arrowHead);

          g.append('path')
            .attr('class', classNames('hybrid-link-head', { filtered: d.isFiltered }))
            .attr('d', d.targetPath.arrowHead);

          // render the source and target labels hugged to their nodes
          g.append('path')
            .attr('fill', 'none')
            .attr('stroke', 'none')
            .attr('d', d.sourceLabelPath.pathData)
            .attr('id', `chord-line-source-label-${d.source.index}-${d.target.index}`);

          g.append('text')
            .attr('class', 'hybrid-link-text')
            .attr('dy', -5)
            .append('textPath')
            .attr('href', `#chord-line-source-label-${d.source.index}-${d.target.index}`)
            .attr('startOffset', d.sourceLabelPath.textAlign === 'left' ? '95%' : '5%')
            .style('text-anchor', d.sourceLabelPath.textAlign === 'left' ? 'end' : 'start')
            .text(addTrafficLabelArrow(d.sourceTraffic, d.sourceLabelPath.textAlign));

          g.append('path')
            .attr('fill', 'none')
            .attr('stroke', 'none')
            .attr('d', d.targetLabelPath.pathData)
            .attr('id', `chord-line-target-label-${d.source.index}-${d.target.index}`);

          g.append('text')
            .attr('class', 'hybrid-link-text')
            .attr('dy', 14)
            .append('textPath')
            .attr('href', `#chord-line-target-label-${d.source.index}-${d.target.index}`)
            .attr('startOffset', d.targetLabelPath.textAlign === 'left' ? '95%' : '5%')
            .style('text-anchor', d.targetLabelPath.textAlign === 'left' ? 'end' : 'start')
            .text(addTrafficLabelArrow(d.targetTraffic, d.targetLabelPath.textAlign));
        } else {
          g.append('path')
            .attr(
              'class',
              classNames('hybrid-link-line', {
                filtered: d.isFiltered,
                'no-display': interSiteTrafficEnabled
              })
            )
            .attr('d', d.pathData);
        }

        g.append('path')
          .attr('d', d.pathData)
          .attr('fill', 'none')
          .attr('stroke', 'transparent')
          .attr('stroke-width', 10)
          .attr(
            'class',
            classNames('hybrid-map-selectable-link', {
              'no-display': !d.sourceTraffic && !d.targetTraffic && interSiteTrafficEnabled
            })
          )
          .style('cursor', 'pointer')
          .on('mouseenter', () => this.setState({ hoveredLink: { source: d.source.index, target: d.target.index } }))
          .on('mouseleave', () => this.setState({ hoveredLink: null }))
          .on('click', () => this.handleLinkClick(d));
      });
  }

  handleLinkClick = (data) => {
    const { onLinkClick } = this.props;
    const pathBox = event.target.getBoundingClientRect();
    const offsetX = event.clientX - pathBox.x;
    const offsetY = event.clientY - pathBox.y;

    onLinkClick({
      source: data.source.name,
      target: data.target.name,
      offset: { left: offsetX, top: offsetY }
    });

    this.setState({ selectedLink: { source: data.source.index, target: data.target.index } });
  };

  highlightChart() {
    const { items, getLink, highlighted } = this.props;
    const { hoverIndex, selectIndex, hoveredLink, selectedLink } = this.state;
    const selected = selectIndex > -1 ? items[selectIndex] : null;

    const isSelected = (d) => selectIndex === d.index;
    const isHovered = (d) => hoverIndex === d.index;
    const isHighlighted = (d) =>
      !isSelected(d) &&
      (highlighted.some((item) => item.index === d.index) ||
        (selected && getLink(selected, items[d.index], { checkExistence: true })));
    const isUnselected = (d) => (selected || highlighted.length > 0) && !isSelected(d) && !isHighlighted(d);

    const isSelectedLink = ({ source, target }) =>
      (selectedLink && selectedLink.source === source.index && selectedLink.target === target.index) ||
      isSelected(source) ||
      isSelected(target);
    const isHoveredLink = ({ source, target }) =>
      (hoveredLink && hoveredLink.source === source.index && hoveredLink.target === target.index) ||
      isHovered(source) ||
      isHovered(target);
    const isHighlightedLink = ({ source, target }) =>
      highlighted.some((item) => item.index === source.index || item.index === target.index);
    const isUnselectedLink = (d) => (selected || highlighted.length > 0) && !isSelectedLink(d) && !isHighlightedLink(d);

    const nodes = select(this.nodeGroup.current).selectAll('.node');
    const ribbons = select(this.ribbonGroup.current).selectAll('.hybrid-link');

    nodes.classed('node-hovered', isHovered);
    ribbons
      .classed('hybrid-link-hovered', isHoveredLink)
      .classed(
        'hybrid-link-showtext',
        (d) => hoveredLink && hoveredLink.source === d.source.index && hoveredLink.target === d.target.index
      )
      .classed('hybrid-link-dimmed', (d) => hoveredLink && !isHoveredLink(d));

    nodes.classed('node-selected', isSelected);
    ribbons.classed('hybrid-link-selected', isSelectedLink);

    nodes.classed('node-highlighted', isHighlighted);

    nodes.classed('node-unselected', isUnselected);
    ribbons.classed('hybrid-link-unselected', isUnselectedLink);

    ribbons.filter('.hybrid-link-unselected').lower();
    ribbons.filter('.hybrid-link-selected').raise();
    ribbons.filter('.hybrid-link-hovered').raise();
  }

  handleSelect = (data) => {
    const { items, onSelect, onAnimationEnd, rotateOnSelect } = this.props;
    const angle = normalizeAngle(data.angle, -Math.PI);

    if (angle === 0 || !rotateOnSelect) {
      onSelect(items[data.index]);
      return;
    }

    this.setState(
      ({ rotation }) => ({ rotation: rotation - angle, selectIndex: data.index }),
      () => {
        const chart = this.chart.current;
        const duration = 300 + (Math.abs(angle) / Math.PI) * 300;

        chart.style.transition = 'none';
        chart.style.transform = `rotate(${angle}rad)`;

        chart.getBoundingClientRect(); // force a repaint to make transition take effect

        chart.style.transition = `transform ${duration}ms`;
        chart.style.transform = 'rotate(0rad)';

        this.onTransitionEnd = () => {
          onSelect(items[data.index]);

          if (onAnimationEnd) {
            onAnimationEnd();
          }
        };
      }
    );
  };

  handleTransitionEnd = () => {
    if (this.onTransitionEnd) {
      this.onTransitionEnd();
      this.onTransitionEnd = null;
    }
  };

  render() {
    const { width, height } = this.props;
    return (
      <g transform={`translate(${width / 2}, ${height / 2})`}>
        <ChartGroup ref={this.chart} onTransitionEnd={this.handleTransitionEnd}>
          <g ref={this.ribbonGroup} />
          <g ref={this.nodeGroup} />
        </ChartGroup>
      </g>
    );
  }
}
