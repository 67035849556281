import * as React from 'react';
import { compact as _compact } from 'lodash';
import { GCP_ENTITY_TYPES } from 'shared/hybrid/constants';
import { Box, Flex, Heading, EmptyState } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import { VPNStatusTag, VPNStatusBadges } from 'app/views/hybrid/maps/gcp/components/VPNStatus';
import { getVpnTunnelMetadata } from './utils';

const { VPN_TUNNEL } = GCP_ENTITY_TYPES;

export default class VpnTunnels extends React.Component {
  get title() {
    return (
      <Flex gap={1}>
        <Heading level={5} m={0}>
          VPN Tunnels
        </Heading>
        <VPNStatusBadges statusList={this.tunnelEntities.map((entity) => entity.status)} showCount />
      </Flex>
    );
  }

  get tunnelEntities() {
    const { nodeData, data } = this.props;
    return _compact(
      Object.values(data).map((tunnel) => {
        if (nodeData.selfLink === tunnel.router) {
          return tunnel;
        }
        return null;
      })
    );
  }

  renderTunnel(tunnel) {
    const { node, keys } = getVpnTunnelMetadata(tunnel);
    return (
      <CloudMetadata
        key={tunnel.name}
        border="thin"
        mb={1}
        p={1}
        node={node}
        keys={keys}
        renderItem={(key, value, allValues) => {
          if (key === 'Status') {
            return <VPNStatusTag status={value} detailedStatus={allValues.detailedStatus} />;
          }

          return null;
        }}
      />
    );
  }

  render() {
    const { width, popoutTitle, cloudProvider } = this.props;

    return (
      <SidebarItem
        excludeFormProps
        title={this.title}
        dialogProps={{ width }}
        icon="exchange"
        popoutTitle={popoutTitle}
      >
        {this.tunnelEntities.length ? (
          <Box>{this.tunnelEntities.map((entity) => this.renderTunnel(entity))}</Box>
        ) : (
          <>
            <EmptyState
              title="No Results"
              icon={<CloudIcon cloudProvider={cloudProvider} entity={VPN_TUNNEL} iconSize={48} />}
            />
          </>
        )}
      </SidebarItem>
    );
  }
}
