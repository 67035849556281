import React from 'react';

import { Box } from 'core/components';

import SidebarItem from '../../SidebarItem';
import CloudMetadata from '../../CloudMetadata';

const AzureVpnSiteLinkDetails = ({ width, popoutTitle, nodeData }) => (
  <SidebarItem
    excludeFormProps
    title={nodeData?.name}
    dialogProps={{ width }}
    fullscreenProps={{ headingOffset: -16 }}
    icon="new-link"
    popoutTitle={popoutTitle}
  >
    <Box>
      <CloudMetadata
        node={{
          ...nodeData,
          ...nodeData.properties
        }}
        keys={[
          'provisioningState',
          'ipAddress',
          'bgpProperties.asn',
          'bgpProperties.bgpPeeringAddress',
          'linkProperties.linkProviderName',
          'linkProperties.linkSpeedInMbps'
        ]}
      />
    </Box>
  </SidebarItem>
);

export default AzureVpnSiteLinkDetails;
