import React from 'react';
import classNames from 'classnames';

const HoneycombItemGroup = (props) => {
  const { className, ...rest } = props;

  return <g {...rest} className={classNames('honeycomb-item-group', className)} />;
};

export default HoneycombItemGroup;
