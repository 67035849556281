import React from 'react';
import { Text, Icon, Box, Flex } from 'core/components';
import ResultMenuItem from './ResultMenuItem';

function IP({ item, selected, IconCmp, onItemClick, ...rest }) {
  const { path, value, details = {} } = item;
  return (
    <ResultMenuItem
      {...rest}
      selected={selected}
      onClick={() => onItemClick({ type: 'ip', id: value, path }, 'Search Results')}
    >
      <Flex gridArea="icon" alignItems="center" justifyContent="center">
        <IconCmp iconSize={22} />
      </Flex>

      <Box gridArea="title">
        <Text className="title">{value}</Text>
      </Box>

      <Flex gridArea="rightIcon" alignItems="center" justifyContent="center">
        <Icon icon={selected ? 'key-enter' : undefined} color="#ffffff" />
      </Flex>

      <Text
        className="description"
        gridArea="description"
        muted
        small
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        style={{ overflow: 'hidden' }}
      >
        {!details.asn.id && 'Internal IP'}
        {details && details.asn && details.asn.id && (
          <>
            <div>ASN: {details.asn.id}</div>
            <div>{details.asn.name}</div>
            {(details.geo.city.name !== '-' || details.geo.region.name !== '-') && (
              <div>
                {details.geo.city.name}
                {details.geo.city.name ? ', ' : ''}
                {details.geo.region.name}
              </div>
            )}
          </>
        )}
      </Text>
    </ResultMenuItem>
  );
}

export default IP;
