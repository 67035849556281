import React from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { withTheme } from 'styled-components';
import { isEqual, merge, uniqBy, memoize } from 'lodash';
import { darken, opacify } from 'polished';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import { getASNValues } from 'app/util/queryResults';
import { Box, EmptyState, Flex, Spinner, showInfoToast, showErrorToast } from 'core/components';
import { GCP_ENTITY_TYPES, MAP_TYPES } from 'shared/hybrid/constants';
import { getCustomProperties, uriToObject, getGCPMapSelectId } from 'shared/util/map';
import {
  getMapClassname,
  generateLinksForHighlightedNode,
  getEntityType,
  rewriteFallbackLinks
} from 'app/views/hybrid/utils/map';
import { GCP } from 'app/views/hybrid/maps/cloudDimensionConstants';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import ItemGrid from 'app/views/hybrid/maps/components/ItemGrid';
import TrafficLinkGenerator from 'app/views/hybrid/maps/components/TrafficLinkGenerator';
import {
  getInternetDimension,
  getInternetFilterField
} from 'app/views/hybrid/maps/components/popovers/queryOptionsHelper';
import CloudMapConnector from 'app/views/hybrid/utils/cloud/connector';
import {
  GRID_ROWS_GAP,
  ICON_SIZE,
  INTERNET_WIDTH,
  LINK_SPACING,
  LOCATION_PADDING,
  ON_PREM_FLEX_GAP,
  REGION_MARGIN,
  getTotalWidth
} from 'app/views/hybrid/utils/cloud/constants';
import Network from './components/Network';
import AbstractMap from '../components/AbstractMap';
import withPopover from '../components/popovers/withPopover';
import CloudMapBox from '../components/CloudMapBox';
import TopKeys from '../components/TopKeys/TopKeys';

const {
  NETWORK,
  SUBNET,
  EXTERNAL_VPN_GATEWAY,
  ROUTER,
  INTERCONNECT,
  INTERCONNECT_ATTACHMENT,
  VPN_GATEWAY,
  VPN_TUNNEL
} = GCP_ENTITY_TYPES;

@withPopover
@withTheme
@inject('$hybridMap')
@observer
export default class GCPMap extends AbstractMap {
  constructor(props) {
    super(props);

    Object.assign(this.state, {
      boxExpanded: {
        internet: true,
        onprem: true
      },
      loading: true,
      selectedRegions: [],
      regionTraffic: {}
    });
  }

  componentDidMount() {
    this.fetchTopology();
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarSettings, drawerIsOpen } = this.props;
    const { activeNode } = this.state;
    const mapSearchChanged = prevProps.sidebarSettings.searchTerm !== sidebarSettings.searchTerm;
    const timeSettingsChanged =
      prevProps.sidebarSettings.timeRange.start !== sidebarSettings.timeRange.start ||
      prevProps.sidebarSettings.timeRange.end !== sidebarSettings.timeRange.end;
    const drawerChanged = prevProps.drawerIsOpen !== drawerIsOpen;
    const activeNodeChanged = prevProps.activeNode !== activeNode;

    if (mapSearchChanged) {
      this.topologyCollection.filter(sidebarSettings.searchTerm);
    }

    if (timeSettingsChanged) {
      this.fetchTopology();
      return;
    }

    if (drawerChanged) {
      this.getRegionBoxPositions.cache.clear();
      this.getNetworkBoxPositions.cache.clear();
    }

    if (activeNodeChanged) {
      if (!activeNode) {
        this.topologyCollection.resetHighlightedNodes();
      }

      this.calculateStaticLinks.cache.clear();
    }

    super.componentDidUpdate(prevProps, prevState);
  }

  /*
    @override
    Helper used in componentDidUpdate
  */
  shouldLinksRedraw(prevProps, prevState) {
    const { sidebarSettings } = this.props;
    const { persistantNodeLinks, selectedRegions, selectedNode, hoveredNode, activeNode, nodeLinks } = this.state;

    if (super.shouldLinksRedraw(prevProps, prevState)) {
      return true;
    }

    if (hoveredNode?.value !== prevState.hoveredNode?.value && (nodeLinks?.length ?? 0) === 0) {
      return true;
    }

    if (!isEqual(activeNode, prevState.activeNode)) {
      return true;
    }

    if (sidebarSettings.showDefaultNetworks !== prevProps.sidebarSettings.showDefaultNetworks) {
      return true;
    }

    return (
      !isEqual(prevState.persistantNodeLinks, persistantNodeLinks) ||
      !isEqual(prevState.selectedRegions, selectedRegions) ||
      selectedNode?.value !== prevState.selectedNode?.value
    );
  }

  /*
    @override
    Determine if the given type should be rolled up to the 'box' level when getting node links
  */
  shouldTargetLinkRollUp(type) {
    return ['onprem', 'internet'].includes(type);
  }

  /*
    @borrowed from CloudAwsMap
    In many cases, this is used as a method for overriding connection types for specific link types
  */
  styleLink = (link) => {
    const { theme } = this.props;
    const { source, target } = link;
    let connectionType = 'square';
    const color = theme.name === 'light' ? theme.colors.primary : darken(0.35, opacify(1, theme.colors.primary));

    if (source.type === SUBNET && target.type === SUBNET) {
      // connections between subnets are curved like the other providers
      connectionType = 'curved';
    }

    return { connectionType, color, ...link };
  };

  /*
    @borrowed from CloudAwsMap
  */
  getNetworkOffset({ type, value }) {
    const { topology } = this.topologyCollection;
    const { Entities, Hierarchy } = topology;
    const { networks } = Hierarchy;
    const entity = Entities[type]?.[value];

    if (['box', 'internet', 'onprem'].includes(type)) {
      return -networks.length;
    }

    let networkName = entity?.network;

    if (type === NETWORK) {
      networkName = value;
    } else {
      // entities associated with a network have a network resource url assigned to them
      // try to find the entity by name
      const item = Object.values(Entities[type]).find((s) => s.name === value);

      if (item) {
        // parse the network name from the network resource url
        networkName = uriToObject(item.network).network;
      }
    }

    if (!networkName) {
      // give a heads up so we can update as conditions arise
      console.warn(`Unhandled type ${type} detected in getNetworkOffset calculation`);
    }

    return networkName ? networks.findIndex((network) => network.name === networkName) + 1 : 0;
  }

  /*
    @borrowed from CloudAwsMap
    Link connection drawing relies on the properties returned here
    From the perspective of the GCP map, this isn't accurate as a 'region' box in other providers is actually a 'network' box here
    However we'll preserve the properties returned here in order to keep the link connector drawing stable
  */
  getRegionBoxPositions = memoize(
    ({ source }) => {
      const regionRect = this.map.current.querySelector('.box-network')?.getBoundingClientRect();
      const regionLeft = regionRect?.left - source.svg.rect.left;
      const regionRight = regionRect?.right - source.svg.rect.left;
      const regionTop = regionRect?.top - source.svg.rect.top;
      const regionBottom = regionRect?.bottom - source.svg.rect.top;

      const regionCx = (regionRect?.right - regionRect?.left) / 2;
      const regionCy = (regionRect?.regionTop - regionRect?.regionBottom) / 2;

      return { regionLeft, regionRight, regionCx, regionCy, regionTop, regionBottom };
    },
    // region box left and right position wont change
    () => this.map.current.querySelector('.box-network')?.className
  );

  /*
    @borrowed from CloudAwsMap
  */
  getPath(link) {
    const { topology } = this.topologyCollection;
    const { Entities } = topology;

    const nodes = link.flatMap((segment, idx) => {
      const { source, target } = segment;
      return idx === 0 ? [source, target] : target;
    });

    return nodes.map((node) => {
      const { type, id } = node;
      const entity = Entities[type]?.[id] || {};
      return { ...entity, type: String(type).replace(/s$/, '') };
    });
  }

  /*
    @borrowed from CloudAwsMap
  */
  calculateStaticLinks = memoize(
    () => {
      const { topology = {} } = this.topologyCollection;
      const { Links = [] } = topology;
      const staticLinks = uniqBy(
        Links.flatMap((link) => {
          const processedLink = link.map((segment) => {
            const { source, target } = segment;
            const sourceSegment = { type: source.type, value: source.id };
            const targetSegment = { type: target.type, value: target.id };

            if (source.fallbackNodes) {
              sourceSegment.fallbackNodes = source.fallbackNodes;
            }

            if (target.fallbackNodes) {
              targetSegment.fallbackNodes = target.fallbackNodes;
            }

            return {
              source: sourceSegment,
              target: targetSegment
            };
          });

          const path = this.getPath(link);

          return processedLink.map((segment) => Object.assign(segment, { paths: [path] }));
        }),
        ({ source, target }) =>
          [source, target]
            .sort((a, b) => String(a.value).localeCompare(b.value))
            .map(({ type, value }) => `${type}-${value}`)
            .join('-')
      );

      return rewriteFallbackLinks({ links: staticLinks, mapDocument: this.map.current });
    },
    () => {
      const { selectedRegions } = this.state;
      return selectedRegions.join();
    }
  );

  /*
    @borrowed from CloudAwsMap
  */
  get shouldRenderLinksBasedOnState() {
    const { hoveredNode, nodeLinks, activeNode } = this.state;

    return !!(hoveredNode || nodeLinks.length > 0 || activeNode);
  }

  /*
    @borrowed from CloudAwsMap.getVpcBoxPositions
    Similar to the getRegionBoxPositions function, this is actually referring to a region
    Rather than translate their values back to region-based coordinates, this will be preserved as-is
    It's much 'clearer' this way. No one hears your screams.
  */
  getNetworkBoxPositions = memoize(
    ({ source }) => {
      const networkRect = this.map.current.querySelector('.region-mini-map')?.getBoundingClientRect();
      const networkLeft = networkRect?.left - source.svg.rect.left;
      const networkRight = networkRect?.right - source.svg.rect.left;
      const networkTop = networkRect?.top - source.svg.rect.top;
      const networkBottom = networkRect?.bottom - source.svg.rect.top;

      const networkCx = (networkRect?.right - networkRect?.left) / 2;
      const networkCy = (networkRect?.networkTop - networkRect?.networkBottom) / 2;

      return { networkLeft, networkRight, networkCx, networkCy, networkTop, networkBottom };
    },
    // network box left and right position wont change
    () => this.map.current.querySelector('.region-mini-map')?.className
  );

  /*
    @override
    @borrowed from CloudAwsMap
  */
  getNodeLinkPositions(data) {
    const { topology } = this.topologyCollection;
    const { Hierarchy } = topology;
    const { networks } = Hierarchy;
    const { linkData, source, target, path, pathIndex } = data;
    const { points: sourcePoints } = source;
    const { points: targetPoints } = target;

    if (sourcePoints.length === 0 || targetPoints.length === 0) {
      return { source, target };
    }

    const maxOffset = Math.floor((REGION_MARGIN - 2) / LINK_SPACING);
    const sourceRegionOffset = this.getNetworkOffset(linkData.source);
    const targetRegionOffset = this.getNetworkOffset(linkData.target);
    const regionOffset = Math.min(Math.abs(sourceRegionOffset - targetRegionOffset), maxOffset);
    const gatewayRegionOffset = Math.min(regionOffset + networks.length, maxOffset);
    const midRegionOffset = networks.length / 2 - regionOffset;
    const { regionCx, regionLeft, regionRight, regionTop, regionBottom } = this.getRegionBoxPositions({ source });
    const { networkCx, networkLeft, networkRight, networkTop, networkBottom } = this.getNetworkBoxPositions({ source });

    /**
     * currently moved some of:
     *  - links to/from VpnConnections
     *  - links to/from CustomerGateways
     *  - links to/from DirectConnections
     *  - some links from Regions
     *  - links from TransitGateways to On Prem
     *  - links from VirtualGateways to On Prem
     */
    const connector = CloudMapConnector({
      sourceNode: linkData.source,
      targetNode: linkData.target,
      path,
      vpcCx: networkCx,
      source,
      target,
      vpcTop: networkTop,
      vpcLeft: networkLeft,
      regionCx,
      vpcRight: networkRight,
      pathIndex,
      vpcBottom: networkBottom,
      regionLeft,
      regionRight,
      regionTop,
      regionBottom,
      regionOffset,
      midRegionOffset,
      targetRegionOffset,
      sourceRegionOffset,
      gatewayRegionOffset,
      connectionType: linkData.connectionType
    });

    if (connector !== null) {
      const { sourceAnchor, targetAnchor, connectionPoints } = connector;
      this.setLinkAnchor(source, sourceAnchor);
      this.setLinkAnchor(target, targetAnchor);

      return super.getNodeLinkPositions({
        ...data,
        source: { ...source, points: sourcePoints.concat(connectionPoints) },
        target
      });
    }

    // eslint-disable-next-line no-console
    console.error('NO CONNECTOR FOUND FOR', source, target);

    return super.getNodeLinkPositions({
      ...data,
      source: { ...source, points: sourcePoints },
      target
    });
  }

  /*
    @override
    @borrowed from CloudAwsMap
  */
  get nodeLinks() {
    const { persistantNodeLinks = [] } = this.state;

    if (!this.shouldRenderLinksBasedOnState) {
      return [];
    }

    const staticLinks = this.calculateStaticLinks();
    const statePathLinks = super.nodeLinks;

    const nodeLinks = statePathLinks.concat(persistantNodeLinks).flatMap((link) => {
      const { source, target } = link;

      if (source.type === 'internet' && target.type === SUBNET) {
        // @TODO implement fallback for when subnet's containing region is collapsed
      }
      return link;
    });

    const allLinks = rewriteFallbackLinks({ links: nodeLinks, mapDocument: this.map.current })
      .concat(staticLinks)
      .map(this.styleLink);
    const linksMap = {};

    allLinks.forEach((link) => {
      const { source, target } = link;

      const key = [source, target]
        .map(({ type, value }) => `${type}-${value}`)
        .sort()
        .join('-');

      const hadOutboundData = Object.prototype.hasOwnProperty.call(link, 'outbound');

      link.inbound = link.inbound || 0;
      link.outbound = link.outbound || 0;
      link.total = link.total || 0;
      link.paths = link.paths || [];

      link.isArrowLink = true;

      // always draw when outbound is not defined
      link.isForwardPath = !hadOutboundData || link.outbound > 0;
      link.isReversePath = link.inbound > 0;

      if (linksMap[key]) {
        const existingLink = linksMap[key];

        if (existingLink.source.value === source.value) {
          existingLink.inbound += link.inbound;
          existingLink.outbound += link.outbound;
        } else {
          existingLink.inbound += link.outbound;
          existingLink.outbound += link.inbound;
        }

        if (link.source.value === existingLink.target.value) {
          existingLink.isReversePath = true;
        }

        existingLink.total += link.total;
        existingLink.paths = existingLink.paths.concat(link.paths);
      } else {
        linksMap[key] = link;
      }
    });

    let resultedLinks = Object.values(linksMap);

    const { hoveredNode, activeNode } = this.state;
    if (activeNode) {
      resultedLinks = generateLinksForHighlightedNode(resultedLinks, activeNode);
    } else if (hoveredNode) {
      resultedLinks = generateLinksForHighlightedNode(resultedLinks, hoveredNode);
    }

    // if rendering path -> only include path link
    if (statePathLinks.length > 0) {
      resultedLinks = resultedLinks.filter((link) =>
        statePathLinks.some(
          (pathLink) => isEqual(pathLink.source, link.source) && isEqual(pathLink.target, link.target)
        )
      );
    }

    return resultedLinks
      .reverse() // reverse links to ensure path is drawn last
      .map(this.styleLink);
  }

  /*
    @override
    @borrowed from CloudAwsMap
  */
  handleNodeLinksUpdate(links) {
    const { selectedNode } = this.state;
    const regionTraffic = {};
    const processedLinks = links.flatMap((l) => {
      const { type } = l;

      if (type === 'regionTraffic') {
        l.links.forEach((link) => {
          // our nodeLinkQuery assigned a source region and simple traffic profile as an allowed pair but the traffic link generator
          // can be unreliable when identifying one as the source and the other as a destination
          // this will break up those values so we can reliably access their values
          // when gcp has better dimension support for gateways, we'll likely change this to better reflect traffic stats and flow directions
          // for now, we're going to report on raw inbound, outbound, and internal traffic for the given region
          const regionAndTrafficProfile = [link.source, link.target].reduce(
            (acc, item) => ({
              ...acc,
              [item.type]: item.value
            }),
            { region: null, simpleTrafficProfile: null }
          );

          // create a select id from the project, network, and region
          // regionAndTrafficProfile has the region and the link has the rest in it
          const selectId = getGCPMapSelectId({ ...link, ...regionAndTrafficProfile });

          // initialize traffic information for the profiles we find interesting
          regionTraffic[selectId] = regionTraffic[selectId] || {
            inbound: 0,
            outbound: 0,
            internal: 0
          };

          // aggregate the traffic stats based on the traffic profile type
          regionTraffic[selectId][regionAndTrafficProfile.simpleTrafficProfile] += link.total;
        });

        return [];
      }

      return l.links;
    });

    if (processedLinks.length === 0 && selectedNode) {
      showInfoToast('No connections were found.');
    }

    const nodeLinks = [...this.getNodeLinks(), ...processedLinks];

    this.setState({
      nodeLinks,
      nodeLinksLoading: false,
      regionTraffic
    });
  }

  /*
    @borrowed from CloudAwsMap
  */
  makeLinkQuery(overrides) {
    const { cloudProvider, $hybridMap } = this.props;

    const baseQuery = {
      aggregateTypes: ['agg_total_bytes'],
      outsort: 'agg_total_bytes',
      viz_type: 'sankey',
      all_devices: false,
      device_types: [`${cloudProvider}_subnet`],
      show_overlay: false,
      show_total_overlay: false,
      lookback_seconds: 3600,
      topx: 40,
      metric: [],
      filters: {
        connector: 'All',
        filterGroups: []
      }
    };

    return $hybridMap.getQuery(merge(baseQuery, overrides));
  }

  /**
   * @override
   * See maps/README.md file for explanation of how nodeLinkQueries need to be structured to interact with
   * TrafficLinkGenerator component.
   */
  getNodeLinkQueries({ selectedNode, selectedRegions } = this.state) {
    const queryDefs = [];

    if (selectedNode) {
      if (selectedNode.type === 'internet') {
        const { subType, value } = selectedNode;

        queryDefs.push({
          type: 'internet',
          keyTypes: ['internet', SUBNET, SUBNET, 'internet'],
          allowPairs: [
            ['internet', SUBNET],
            [SUBNET, 'internet']
          ],
          selectedNode,
          queries: [
            this.makeLinkQuery({
              metric: [
                getInternetDimension({ subType, direction: 'src' }),
                GCP.SRC.SUBNET_NAME,
                GCP.DST.SUBNET_NAME,
                getInternetDimension({ subType, direction: 'dst' }),
                GCP.SRC.VPC_NAME,
                GCP.DST.VPC_NAME,
                GCP.SRC.REGION,
                GCP.DST.REGION
              ],
              filters: {
                connector: 'All',
                filterGroups: [
                  {
                    connector: 'All',
                    filters: [
                      {
                        filterField: getInternetFilterField({ subType }),
                        operator: '=',
                        filterValue: getASNValues(value).asnList
                      }
                    ]
                  },
                  {
                    connector: 'Any',
                    filters: [
                      { filterField: 'i_trf_profile', operator: '=', filterValue: 'from cloud to outside' },
                      { filterField: 'i_trf_profile', operator: '=', filterValue: 'from outside to cloud' }
                    ]
                  }
                ]
              }
            })
          ]
        });
      } else if (selectedNode.type === SUBNET) {
        const filters = [];
        const filterField = GCP.BI.SUBNET_NAME;
        const filterValue = selectedNode.nodeData?.name;

        const filterGroup = {
          connector: 'All',
          filterGroups: [
            {
              connector: 'All',
              filters: [...filters, { filterField, operator: '=', filterValue }]
            }
          ]
        };

        queryDefs.push(
          {
            type: 'vpcInternal',
            keyTypes: [SUBNET, SUBNET],
            selectedNode: { ...selectedNode, value: filterValue },
            queries: [
              this.makeLinkQuery({
                metric: [
                  GCP.SRC.SUBNET_NAME,
                  GCP.DST.SUBNET_NAME,
                  GCP.SRC.VPC_NAME,
                  GCP.DST.VPC_NAME,
                  GCP.SRC.REGION,
                  GCP.DST.REGION
                ],
                filters: filterGroup
              })
            ]
          },
          {
            type: 'vpcInternetTraffic',
            keyTypes: ['src-internet', SUBNET, 'dst-internet'],
            allowPairs: [
              ['src-internet', SUBNET],
              [SUBNET, 'dst-internet']
            ],
            selectedNode: { ...selectedNode, value: filterValue },
            queries: [
              this.makeLinkQuery({
                metric: [
                  'AS_src',
                  GCP.DST.SUBNET_NAME,
                  'AS_dst',
                  GCP.SRC.VPC_NAME,
                  GCP.DST.VPC_NAME,
                  GCP.SRC.REGION,
                  GCP.DST.REGION
                ],
                filters: {
                  connector: 'All',
                  filterGroups: [
                    {
                      connector: 'All',
                      filters: [{ filterField, operator: '=', filterValue }]
                    }
                  ]
                }
              })
            ]
          }
        );
      }
    }

    // this query is responsible for reporting stats in the expanded region box
    // previously only aws supported this on the map and the stats were a bit different
    // since we have gateway dimensions in aws, we're able to determine connections better and determine the type of gateway traffic is flowing through
    // aws would report these stats as inbound/outbound against internet and transit gateways
    // gcp doesn't have this yet so we're opting to game the system here and just report on traffic flowing using simple traffic profiles
    // here, our queries will basically bring back the project, network, and region we need to craft a select id (the unique identifier for a region on the map)
    // along with that will be the simple network profile type (inbound, outbound, internal) and the total traffic
    // when we post-process this, we'll aggregate the traffic stats for a given unique region and traffic profile
    const regionQueries = selectedRegions.map((regionId) => ({
      type: 'regionTraffic',
      keyTypes: ['region', 'simpleTrafficProfile', 'project', 'network'],
      allowPairs: [['region', 'simpleTrafficProfile']],
      extraInfo: ['project', 'network'],
      queries: [
        this.makeLinkQuery({
          metric: [GCP.SRC.REGION, 'simple_trf_prof', GCP.SRC.PROJECT_ID, GCP.SRC.VPC_NAME],
          filters: {
            connector: 'All'
          }
        })
      ],
      regionId
    }));

    return [...queryDefs, ...regionQueries];
  }

  /*
    @borrowed from CloudAwsMap
  */
  handleShowDetails = ({ type, value, nodeData }) => {
    const { activeInternetTabId } = this.state;
    const { topology } = this.topologyCollection;
    const isSelectable = MAP_TYPES.get('gcp.selectable_entities').includes(type);

    const node = { type, subType: activeInternetTabId, value: nodeData?.name || value, nodeData };

    if (type === SUBNET) {
      // subnets use a stronger unique key to identify themselves
      node.value = getCustomProperties(nodeData).key;
    }

    if (isSelectable) {
      this.handleSelectNode(node);
    } else {
      const { cloudProvider, setSidebarDetails } = this.props;

      if (setSidebarDetails) {
        this.setState({ selectedNode: node }, () => {
          setSidebarDetails({
            ...node,
            cloudProvider,
            // static links are memoized
            links: this.calculateStaticLinks(),
            topology
          });

          // reset node links
          // this prevents scenarios where we request the same node link queries and the traffic generator doesn't see any change,
          // resulting in a death spinner
          this.setNodeLinks(null);

          this.activateNode({ type: node.type, value: nodeData.id });
        });
      }
    }
  };

  /*
    @override
  */
  handleSelectNode({ type, value, nodeData = {}, force = false }) {
    const { openPopover, cloudProvider } = this.props;
    const { selectedNode } = this.state;
    const isCloudNode = MAP_TYPES.get('gcp.cloud').includes(type);
    const { topology } = this.topologyCollection;

    super.handleSelectNode({ type, value, force, nodeData });

    this.setState((prevState) => {
      if (prevState.selectedNode) {
        const { node } = this.getNodePosition(prevState.selectedNode);

        if (node) {
          const isSameNode = isEqual(selectedNode, prevState.selectedNode);
          const { x, y, width, height } = node;

          openPopover({
            ...prevState.selectedNode,
            topology,
            cloudProvider,
            position: { left: x, top: y, width, height },
            placement: isCloudNode ? 'bottom' : 'left',
            detail: {
              type: 'cloud',
              cloudProvider
            },
            shortcutMenu: {
              selectedNode: prevState.selectedNode,
              showConnectionsCallback: this.setNodeLinks,
              isShowingConnections: prevState.nodeLinks.length > 0 && isSameNode
            }
          });
        }
      }

      return null;
    });
  }

  /*
    @borrowed from CloudAwsMap
  */
  isNetworkHighlighted(networkName) {
    const { nodeLinks, selectedNode } = this.state;
    const { topology } = this.topologyCollection;
    const { Entities } = topology;

    return nodeLinks.some(({ source, target }) =>
      [source, target].some(({ type, value }) => {
        if (type === SUBNET) {
          return Object.values(Entities[SUBNET]).find((s) => {
            const customProperties = getCustomProperties(s);
            return (
              customProperties.key === value &&
              customProperties.network === networkName &&
              value !== selectedNode?.value
            );
          });
        }

        return false;
      })
    );
  }

  /*
    @override
  */
  getHighlighted(type) {
    const { nodeLinks, selectedNode } = this.state;

    if (type === NETWORK) {
      if (selectedNode) {
        const highlighted = {};

        nodeLinks.forEach(({ source, target }) => {
          highlighted[source.value] = true;
          highlighted[target.value] = true;
        });

        return Object.keys(highlighted).filter((value) => value && value !== selectedNode?.value);
      }

      return [];
    }

    return super.getHighlighted(type);
  }

  /*
    @borrowed from CloudAwsMap.getHighlightedVpcs
  */
  getHighlightedRegions(networkName) {
    const { nodeLinks, selectedNode } = this.state;
    const { topology } = this.topologyCollection;
    const { Entities } = topology;

    return nodeLinks
      .flatMap(({ source, target }) =>
        [source, target].flatMap(({ type, value }) => {
          if (type === SUBNET) {
            return Object.values(Entities[SUBNET]).map((s) => {
              const customProperties = getCustomProperties(s);

              if (customProperties.key === value && customProperties.network === networkName) {
                return customProperties.regionSelectId;
              }

              return null;
            });
          }

          return null;
        })
      )
      .filter((value) => value && value !== selectedNode?.value);
  }

  /*
    @borrowed from CloudAwsMap.handleToggleVpc
  */
  handleToggleRegion = (regionId) =>
    this.setState((state) => {
      const selectedNode = ['internet', SUBNET].includes(state.selectedNode?.type) ? state.selectedNode : null;
      const selectedRegions = state.selectedRegions.includes(regionId)
        ? state.selectedRegions.filter((id) => id !== regionId)
        : [regionId];
      const nodeLinkQueries = this.getNodeLinkQueries({ ...state, selectedNode, selectedRegions });
      return {
        selectedNode,
        selectedRegions,
        regionTraffic: {},
        nodeLinkQueries,
        nodeLinksLoading: nodeLinkQueries.length > 0
      };
    });

  /*
    @borrowed from CloudAwsMap
  */
  handleGridItemHoverToggle = ({ type, key, item }) => {
    if (type === 'enter') {
      this.handleHoverNode(key, item.id);
    } else {
      this.handleUnhoverNode(key, item.id);
    }
  };

  /*
    @borrowed from CloudAwsMap
  */
  handleGridItemClick = ({ key, item }) => {
    this.activateNode({ type: key, value: item.id });
    this.handleShowDetails({ type: getEntityType(item), value: item.name, nodeData: item });
  };

  async fetchTopology() {
    const { sidebarSettings } = this.props;
    this.setState({ loading: true });

    if (this.pendingPromise) {
      this.pendingPromise.cancel();
    }

    this.pendingPromise = makeCancelable(
      this.topologyCollection.fetch({ force: true, query: sidebarSettings.timeRange }).then((res) => {
        const { cached, isHistoricData } = res;
        if (!cached && !isHistoricData && sidebarSettings.timeRange) {
          const { start, end } = sidebarSettings.timeRange;
          showInfoToast(
            `Unable to locate historic GCP data between ${start} and ${end}. Showing current data instead.`,
            { timeout: 10000 }
          );
        }
      })
    );

    return this.pendingPromise.promise
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false }, () => {
          if (!(err instanceof CanceledError)) {
            console.error('Error loading topology', err);
            showErrorToast('Error occurred attempting to load topology');
          } else {
            console.warn('Promise was canceled.');
          }
        });
      })
      .finally(() => {
        this.pendingPromise = null;
        this.setState({ loading: false });
      });
  }

  get topologyCollection() {
    const { $hybridMap } = this.props;
    return $hybridMap.gcpCloudMapCollection;
  }

  get highlightedNodes() {
    return this.nodeLinks.flatMap((l) => [l.source.value, l.target.value]);
  }

  get onPremRoutersToRender() {
    const { cloudProvider } = this.props;
    const { topology } = this.topologyCollection;
    const { width: rectWidth } = this.svg.current.getBoundingClientRect();

    const items = [
      {
        key: 'on-prem-entities',
        getItemType: (item) => getEntityType(item),
        getItemIcon: (item) => (
          <CloudIcon cloudProvider={cloudProvider} entity={getEntityType(item)} width={ICON_SIZE} height={ICON_SIZE} />
        ),
        group: [...topology[ROUTER], ...topology[EXTERNAL_VPN_GATEWAY]],
        getTitle: (item) => {
          const entityType = getEntityType(item);

          if (entityType === EXTERNAL_VPN_GATEWAY) {
            return 'External VPN Gateway';
          }

          return 'Router';
        },
        getSubtitle: (entity) => entity?.name ?? entity?.device_name ?? entity?.id ?? 'Unknown Device'
      }
    ];
    const willFit = items.every(
      ({ group }) =>
        getTotalWidth({ itemCount: group.length, gap: ON_PREM_FLEX_GAP }) < rectWidth - INTERNET_WIDTH.toPoints()
    );

    return items.map((item) => ({ ...item, willFit })).filter((tempGroup) => tempGroup.group.length > 0);
  }

  get onPremTopologyToRender() {
    const { cloudProvider } = this.props;
    const { topology } = this.topologyCollection;
    const { width: rectWidth } = this.svg.current.getBoundingClientRect();

    const items = [
      {
        key: INTERCONNECT,
        group: topology[INTERCONNECT],
        name: 'Interconnects',
        getTitle: () => 'Interconnect',
        getSubtitle: (interconnect) => interconnect.name,
        icon: <CloudIcon cloudProvider={cloudProvider} entity={INTERCONNECT} width={ICON_SIZE} height={ICON_SIZE} />
      },
      {
        key: INTERCONNECT_ATTACHMENT,
        group: topology[INTERCONNECT_ATTACHMENT],
        name: 'Interconnect Attachments',
        getTitle: () => 'Interconnect Attachment',
        getSubtitle: (vlanAtt) => vlanAtt.name,
        icon: (
          <CloudIcon
            cloudProvider={cloudProvider}
            entity={INTERCONNECT_ATTACHMENT}
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        )
      },
      {
        key: VPN_TUNNEL,
        group: topology[VPN_TUNNEL],
        name: 'VPN Tunnels',
        getTitle: () => 'VPN Tunnels',
        getSubtitle: (vpnT) => vpnT.name,
        icon: <CloudIcon cloudProvider={cloudProvider} entity={VPN_TUNNEL} width={ICON_SIZE} height={ICON_SIZE} />
      },
      {
        key: VPN_GATEWAY,
        group: topology[VPN_GATEWAY],
        name: 'VPN Gateways',
        getTitle: () => 'VPN Gateway',
        getSubtitle: (vpnGW) => vpnGW.name,
        icon: <CloudIcon cloudProvider={cloudProvider} entity={VPN_GATEWAY} width={ICON_SIZE} height={ICON_SIZE} />
      }
    ];

    const willFit = items.every(({ group }) => getTotalWidth({ itemCount: group?.length, width: 200 }) < rectWidth);

    return items.map((item) => ({ ...item, willFit })).filter((tempGroup) => tempGroup?.group?.length > 0);
  }

  renderNetworkBoxes({ network, width, selectedRegions }) {
    const { sidebarSettings } = this.props;
    const { regionTraffic } = this.state;

    if (!sidebarSettings.showDefaultNetworks && network.autoCreateSubnetworks) {
      return null;
    }

    return (
      <Box
        key={network.id}
        className={`box-network ${getMapClassname({ type: NETWORK, value: getCustomProperties(network).id })}`}
      >
        <CloudMapBox
          p={0}
          minWidth={200}
          minHeight={100}
          className={classNames({
            highlighted: this.isNetworkHighlighted(network.name)
          })}
          isExpanded
        >
          <Network
            network={network}
            width={width - LOCATION_PADDING}
            selectedRegions={selectedRegions}
            regionTraffic={regionTraffic}
            highlighted={this.getHighlighted(NETWORK)}
            highlightedRegions={this.getHighlightedRegions(network.name)}
            onToggleRegion={this.handleToggleRegion}
            onShowDetails={this.handleShowDetails}
            onResize={this.handleDrawLinksRequest}
          />
        </CloudMapBox>
      </Box>
    );
  }

  renderMap() {
    const { cloudProvider, width } = this.props;
    const { boxExpanded, loading, selectedRegions, nodeLinkQueries, nodeLinksLoading, selectedNode } = this.state;
    const { topology } = this.topologyCollection;

    if (loading) {
      return <Spinner mt={100} />;
    }

    if (this.topologyCollection.isEmpty()) {
      return <EmptyState mt={4} icon="cloud" title="No Topology Found" />;
    }

    return (
      <Box>
        {nodeLinksLoading && (
          <Flex alignItems="center" justifyContent="center" position="fixed" zIndex="999" top="300px" left="50%">
            <Spinner intent="primary" />
          </Flex>
        )}
        {/* ON PREM DEVICES */}
        <Flex justifyContent="space-between" gap={`${GRID_ROWS_GAP}px`}>
          <Box mr={1}>
            <CloudMapBox
              title="On Prem"
              className={classNames('box-onprem', {
                highlighted: this.isBoxHighlighted('onprem')
              })}
              minWidth="20vw"
              minHeight={150}
              flex="1 1 auto"
              flexWrap="wrap"
              onExpandToggle={(isExpanded) => this.setBoxExpanded('onprem', isExpanded)}
              isExpanded={boxExpanded.onprem}
            >
              <ItemGrid
                items={this.onPremRoutersToRender}
                itemStroke="gcp.green"
                highlightedNodes={this.highlightedNodes}
                onHoverToggle={this.handleGridItemHoverToggle}
                onClick={this.handleGridItemClick}
                emptyState={<EmptyState icon="folder-close" description="No On Prem Resources" />}
                showTitle={false}
                selectedNode={selectedNode}
              />
            </CloudMapBox>
          </Box>

          {/* INTERNET BOX */}
          <Box ml={1}>
            <CloudMapBox
              title="Internet"
              className={classNames('box-internet', 'link-bottomcenter', {
                highlighted: this.isBoxHighlighted('internet')
              })}
              width={INTERNET_WIDTH}
              height={233}
              onExpandToggle={(isExpanded) => this.setBoxExpanded('internet', isExpanded)}
              isExpanded={boxExpanded.internet}
            >
              <TopKeys
                classPrefix="internet"
                selected={this.getSelected('internet')}
                hovered={this.getHovered('internet')}
                highlighted={this.getHighlighted('internet')}
                onSelect={(value) => this.handleShowDetails({ type: 'internet', value })}
                onTabChange={this.handleInternetTabChange}
                cloud={cloudProvider}
              />
            </CloudMapBox>
          </Box>
        </Flex>

        {/* INTERCONNECTS & INTERCONNECT ATTACHMENTS */}
        <ItemGrid
          items={this.onPremTopologyToRender}
          itemWidth={200}
          itemStroke="primary"
          highlightedNodes={this.highlightedNodes}
          onHoverToggle={this.handleGridItemHoverToggle}
          onClick={this.handleGridItemClick}
          selectedNode={selectedNode}
        />

        {/* NETWORKS BOXES */}
        <Flex flexDirection="column" gap={`${GRID_ROWS_GAP}px`}>
          {topology.Hierarchy?.networks?.map((network) => this.renderNetworkBoxes({ network, width, selectedRegions }))}
        </Flex>

        <TrafficLinkGenerator
          cloudProvider={cloudProvider}
          inputs={nodeLinkQueries}
          onLinksUpdate={this.handleNodeLinksUpdate}
        />
      </Box>
    );
  }
}
