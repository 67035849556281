import Validator from 'validatorjs';

import { range, arrayOfNumbers, arrayOfNumberRanges } from './validationHelpers';

/**
 * This is an initial port of tagValidations.js for modified for hippo both in value type (arrays vs comma delim strings)
 * and max values. TODO: Validations have not been updated at this point, although it looks like they should probably
 * get some attention in the near future.
 * @type {{delimiters: {maxNumOfItems: number, minVal: number, maxVal: number}, message: string}}
 */
const portDetails = {
  delimiters: {
    maxNumOfItems: 500,
    minVal: 0,
    maxVal: 65535
  },
  message: 'Up to 500 comma separated list of port numbers between 0 to 65535'
};

const vlanDetails = {
  delimiters: {
    maxNumOfItems: 500,
    minVal: 0,
    maxVal: 4095
  },
  message: 'Up to 500 comma separated list of integers and ranges of integers between 0 to 4095'
};

const protocolDetails = {
  delimiters: {
    maxNumOfItems: 256,
    minVal: 0,
    maxVal: 255
  },
  message: 'Up to 256 comma separated list of protocol numbers between 0 to 255'
};

const asnDetails = {
  delimiters: {
    maxNumOfItems: 500,
    minVal: 0,
    maxVal: 4294967295
  },
  message: 'Up to 500 comma separated list of ASN numbers between 1 and 2^32-1=4294967295'
};

const criteriaFields = [
  'addr',
  'asn',
  'bgp_aspath',
  'bgp_community',
  'country',
  'device_name',
  'device_type',
  'device_subtype',
  'interface_name',
  'lasthop_as_name',
  'mac',
  'nexthop',
  'nexthop_as_name',
  'nexthop_asn',
  'port',
  'protocol',
  'site',
  'tcp_flags',
  'vlans'
];

export const atLeastOneTagPopulatorCriteria = {
  message: 'Must include at least one matching criteria in addition to value.',
  fn: function atLeastOneCriteria() {
    return criteriaFields.some((field) => {
      let rules = { [field]: 'required' };

      // tcp_flag 0 is valid allowed value (and default from backend) but considered "not set", so value must
      // be greater than 0 to trigger as a set criteria.
      if (field === 'tcp_flags') {
        rules = { [field]: 'min:1' };
      }
      const validation = new Validator(this.validator.input, rules);
      return validation.passes();
    });
  }
};

export const portArrayValidator = {
  message: portDetails.message,
  fn: arrayOfNumberRanges(portDetails.delimiters)
};
export const vlanArrayValidator = {
  message: vlanDetails.message,
  fn: arrayOfNumberRanges(vlanDetails.delimiters)
};
export const protocolArrayValidator = {
  message: protocolDetails.message,
  fn: arrayOfNumbers(protocolDetails.delimiters)
};
export const asnArrayValidator = { message: asnDetails.message, fn: arrayOfNumberRanges(asnDetails.delimiters) };

export const nextHopAsnArrayValidator = Object.assign({}, asnArrayValidator, {
  message: 'Up to 500 comma separated list of Nexthop ASN numbers between 1 and 2^32-1=4294967295'
}); // same delimiters as asnDetails

export const tcpFlagsValidator = { message: 'TCP flag mask between 0 to 255', fn: range(0, 255) };

export const countryArrayValidator = {
  message: 'Up to 200 comma separated list of 2 digit Country codes',
  fn: (value) => value && value.length <= 200 && value.every((code) => code.length === 2)
};

export const macArrayValidator = {
  message: 'Up to 500 comma separated list of MAC addresses',
  fn: (value) =>
    value && value.length <= 500 && value.every((mac) => /^([0-9A-Fa-f]{2}[:-]){5,7}([0-9A-Fa-f]{2})$/gi.test(mac))
};

export const bgpCommunityArrayValidator = {
  message: `Comma separated list of numbers or regular expression representing bgp community
  (i.e. 2096:2212) Permitted characters []*:_^$.0123456789()+?,space-`,
  fn: (value) =>
    value && value.length <= 100 && value.every((community) => /^\s*[-[\]*:_^$.0-9()+?|\s]+\s*$/i.test(community))
};

export const bgpAspathArrayValidator = {
  message: `Comma separated list of numbers or regular expression representing bgp aspath.
   Example "^3737 1212, _7801_, 2906$" would look for any of those 3 combinations in the AS path.
   Permitted characters []*:_^$.0123456789()+?,space-`,
  fn: (value) => value && value.length <= 100 && value.every((asPath) => /^\s*[-[\]*:_^$.0-9()+?\s]+\s*$/i.test(asPath))
};

export const interfaceNameArrayValidator = {
  message: 'Comma separated list of interface names (no pipe or double quote allow)',
  fn: (value) => value && value.length <= 5000 // && /^[ -!#-{}-~]+(\s*,\s*[ -!#-{}-~]*)*$/i.test(value)
  // match all printable ascii characters but double quote and pipe "|
};
