import $dictionary from 'app/stores/$dictionary';
import { isIpV4Valid, isIpV6Valid, toIp } from 'core/util/ip';

/*
  Given a cloud providers and region id, returns the display name for the region.

  in: { cloudProvider: 'azure', regionId: 'centralus' }
  out: 'Central US'
*/
export function getRegionDisplayName({ cloudProvider, regionId }) {
  const region = $dictionary.get(`cloudMetadata.${cloudProvider}.regions.${regionId}.name`, regionId);

  if (typeof region === 'string') {
    return region;
  }

  return regionId;
}

function getIpV4(filterState) {
  const containsDot = filterState.includes('.');

  if (containsDot || !Number.isNaN(Number.parseInt(filterState, 10))) {
    const inputAddress = filterState.replace(/(\/.*|\.)$/, '');
    const groups = inputAddress.split('.');
    const mask = filterState.match(/\/(\d+)$/)?.[1] || groups.length * 8;

    if (groups.length <= 4) {
      for (let i = 0; i < 4; i += 1) {
        groups[i] = groups[i] || '0';
      }

      return isIpV4Valid(`${groups.join('.')}/${mask}`, { returnAddress: true });
    }
  }

  return null;
}

function getIpV6(filterState) {
  const containsColon = filterState.includes(':');

  if (containsColon || !Number.isNaN(Number.parseInt(filterState, 16))) {
    let inputAddress = filterState.replace(/(\/.*|:)$/, '');
    let mask = filterState.match(/\/(\d+)$/)?.[1] || 128;
    const groups = inputAddress.split(':');

    if (groups.length < 8 && !filterState.includes('::')) {
      inputAddress += '::';
      mask = groups.length * 16;
    }

    return isIpV6Valid(`${inputAddress}/${mask}`, { returnAddress: true });
  }

  return null;
}

export function filterStateIpFilter(filterState, originalModels, ipModelAttribute) {
  const ip4 = getIpV4(filterState);
  const ip6 = getIpV6(filterState);

  const specificMatches = []; // [{ model, ip }]
  let highestSpecificity = 0;
  const higherSpecificityMatches = [];

  originalModels.forEach((model) => {
    let ips = model.get(ipModelAttribute) ?? [];

    if (!Array.isArray(ips)) {
      ips = [ips];
    }
    ips.forEach((ip) => {
      const destinationIp = toIp(ip);
      const recordMatches = (inputIp) => {
        const inputInDestination = inputIp.isInSubnet(destinationIp);
        const destinationInInput = destinationIp.isInSubnet(inputIp);
        if (inputInDestination) {
          // record specificMatches which is where input is subset of destination
          if (!specificMatches.some((specificMatchModel) => specificMatchModel.id === model.id)) {
            specificMatches.push({ model, ip: destinationIp });
          }
          highestSpecificity = Math.max(highestSpecificity, destinationIp.subnetMask);
        } else if (destinationInInput) {
          // record matches where destination is subset of input.
          // This represents matches that might be possible as user further specifies input
          if (!higherSpecificityMatches.some((specificMatchModel) => specificMatchModel.id === model.id)) {
            higherSpecificityMatches.push(model);
          }
        }
      };

      if (destinationIp) {
        if (ip4 && destinationIp.v4) {
          recordMatches(ip4);
        }
        if (ip6 && !destinationIp.v4) {
          recordMatches(ip6);
        }
      }
    });
  });
  const mostSpecificMatches = specificMatches.reduce((arr, { model, ip }) => {
    // filter out specificMatches that have lower specificity than the highestSpecificity, as defined
    // by subnetMask value
    if (ip.subnetMask >= highestSpecificity) {
      arr.push(model);
    }
    return arr;
  }, []);

  return { mostSpecificMatches, higherSpecificityMatches };
}
