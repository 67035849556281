import * as React from 'react';
import { Box, Flex, Grid, Icon, Text } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import CircleIconWrapper from 'app/views/hybrid/maps/components/CircleIconWrapper';
import Node from './Node';

export default class NodeGroup extends React.Component {
  iconMap = {
    vpc: {
      fullSize: <CloudIcon cloudProvider="aws" entity="vpc" width={24} height={24} />,
      mini: <CloudIcon cloudProvider="aws" entity="vpc" width={24} height={24} />
    },

    directConnection: {
      fullSize: <CloudIcon cloudProvider="aws" entity="directConnection" width={24} height={24} />,
      mini: <CloudIcon cloudProvider="aws" entity="directConnection" width={20} height={20} />
    },

    directConnectGateway: {
      fullSize: <CloudIcon cloudProvider="aws" entity="directConnectGateway" width={22} height={22} x="1px" y="1px" />,
      mini: <CloudIcon cloudProvider="aws" entity="directConnectGateway" width={16} height={16} />
    },

    transitGateway: {
      fullSize: <CloudIcon cloudProvider="aws" entity="transitGateway" width={24} height={24} />,
      mini: <CloudIcon cloudProvider="aws" entity="transitGateway" width={16} height={16} />
    },

    internetGateway: {
      fullSize: <CloudIcon cloudProvider="aws" entity="internetGateway" width={24} height={24} />,
      mini: <CloudIcon cloudProvider="aws" entity="internetGateway" width={16} height={16} />
    },

    router: {
      fullSize: (
        <CircleIconWrapper size={24}>
          <CloudIcon cloudProvider="aws" entity="router" width={20} height={20} x="2px" y="2px" />
        </CircleIconWrapper>
      ),
      mini: <CloudIcon cloudProvider="aws" entity="router" width={16} height={16} />
    },

    vpnGateway: {
      fullSize: <CloudIcon cloudProvider="aws" entity="virtualGateway" width={26} height={26} />,
      mini: <CloudIcon cloudProvider="aws" entity="virtualGateway" width={20} height={20} />
    },

    customerGateway: {
      fullSize: <CloudIcon cloudProvider="aws" entity="customerGateway" width={26} height={26} />,
      mini: <CloudIcon cloudProvider="aws" entity="customerGateway" width={16} height={16} />
    },

    vpnConnection: {
      fullSize: <CloudIcon cloudProvider="aws" entity="vpnConnection" width={24} height={24} />,
      mini: <CloudIcon cloudProvider="aws" entity="vpnConnection" width={20} height={20} />
    }
  };

  getContent = (node) => {
    const contentMap = {
      Vpc: {
        iconConfig: this.iconMap.vpc,
        primaryContent: node.CidrBlock,
        secondaryContent: node.metadata.name,
        miniContent: node.CidrBlock
      },

      DirectConnection: {
        iconConfig: this.iconMap.directConnection,
        primaryContent: 'Direct Connect',
        secondaryContent: node.ConnectionName || node.metadata.name,
        miniContent: node.metadata.name
      },

      TransitGatewayVpcAttachment: {
        iconConfig: this.iconMap.transitGateway,
        primaryContent: 'Transit Gateway',
        secondaryContent: node.metadata.name,
        miniContent: node.ConnectionName || node.metadata.name
      },

      TransitGateway: {
        iconConfig: this.iconMap.transitGateway,
        primaryContent: 'Transit Gateway',
        secondaryContent: node.metadata.name,
        miniContent: node.metadata.name
      },

      directConnectGatewayAssociation: {
        iconConfig: this.iconMap.internetGateway,
        primaryContent: 'Direct Connect Gateway',
        secondaryContent: node.metadata.name,
        miniContent: node.metadata.name
      },

      DirectConnectGateway: {
        iconConfig: this.iconMap.directConnectGateway,
        primaryContent: 'Direct Connect Gateway',
        secondaryContent: node.DirectConnectGatewayName || node.metadata.name,
        miniContent: node.DirectConnectGatewayName || node.metadata.name
      },

      VpnGateway: {
        iconConfig: this.iconMap.vpnGateway,
        primaryContent: 'Virtual Private Gateway',
        secondaryContent: node.metadata.name,
        miniContent: node.metadata.name
      },

      VpnConnection: {
        iconConfig: this.iconMap.vpnConnection,
        primaryContent: 'VPN Connection',
        secondaryContent: node.metadata.name,
        miniContent: node.metadata.name
      },

      CustomerGateway: {
        iconConfig: this.iconMap.customerGateway,
        primaryContent: 'Customer Gateway',
        secondaryContent: node.metadata.name,
        miniContent: node.metadata.name
      },

      VirtualInterface: {
        iconConfig: this.iconMap.router,
        primaryContent: 'Customer Router',
        secondaryContent: (
          <>
            {node.metadata.name}
            <br />
            {`VLAN ${node.vlan}`}
          </>
        ),
        miniContent: (
          <Box>
            <Text as="div" small>
              {node.metadata.name}
            </Text>
            <Text muted small>{`VLAN ${node.vlan}`}</Text>
          </Box>
        )
      },

      Router: {
        iconConfig: this.iconMap.router,
        primaryContent: 'Router',
        secondaryContent: node.device_name,
        miniContent: node.device_name
      }
    };

    return contentMap[node && node.type] || null;
  };

  renderNode = (node) => {
    const { onNodeClick, onMouseEnter, onMouseLeave, mini, pathType } = this.props;
    const nodeProps = {
      key: node.id,
      onNodeClick,
      onMouseEnter,
      onMouseLeave,
      mini,
      syntheticAgentInstalled: !!node.syntheticAgentInstalled,
      pathType
    };

    if (React.isValidElement(node)) {
      return node;
    }

    const content = this.getContent(node);

    if (content) {
      const { iconConfig, miniContent, ...contentProps } = content;

      if (mini) {
        return (
          <Flex key={node.id} alignItems="center">
            {iconConfig.mini}
            <Text ml="4px" small>
              {miniContent}
            </Text>
          </Flex>
        );
      }

      return <Node node={node} icon={iconConfig.fullSize} {...contentProps} {...nodeProps} />;
    }

    return <Node key={node.id} node={node} primaryContent="Unknown" secondaryContent={node.type} {...nodeProps} />;
  };

  render() {
    const { nodes, mini, gridProps } = this.props;
    let groupNodes = nodes;
    let columnConfig = 'minmax(auto, 170px)';

    if (mini) {
      // in mini-mode, we will show an arrow-delimited representation of the entire link path
      groupNodes = nodes.reduce((acc, node, i, all) => {
        acc.push(node);

        if (i < all.length - 1) {
          acc.push(<Icon key={`${node.id}-icon`} icon="arrow-right" />);
        }

        return acc;
      }, []);
      columnConfig = mini ? `repeat(${groupNodes.length}, max-content)` : 'max-content';
    }

    return (
      <Grid alignItems="center" gridTemplateColumns={columnConfig} gridRowGap="16px" {...gridProps}>
        {groupNodes.map((node) => this.renderNode(node))}
      </Grid>
    );
  }
}
