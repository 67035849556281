/*
  A searchable table used for displaying data in the kentik map sidebar
*/
import * as React from 'react';
import { observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import { isEqual } from 'lodash';
import { Collection } from 'core/model';
import { Card, Flex, Tag, Text } from 'core/components';
import { Table, Search } from 'core/components/table';

@observer
export default class SearchableTable extends React.Component {
  static defaultProps = {
    searchPlaceholder: 'Search...',
    collectionOptions: {},
    loading: true,
    columns: {},
    data: [],
    filterKeys: [],
    groupSummary: null
  };

  constructor(props) {
    super(props);

    const { collectionOptions, data } = props;

    this.collection = new Collection(data, collectionOptions);
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (!isEqual(prevProps.data, data)) {
      this.collection.processData(data);
    }
  }

  customSearchFilter = (model) => {
    const { filterKeys = [] } = this.props;
    const filterValues = filterKeys.map((key) => model.get(key)).filter((value) => value !== undefined);

    return filterValues.some((value) => `${value}`.toLowerCase().includes(this.collection.filterState.toLowerCase()));
  };

  // unless overridden, performs simple search against the collection
  handleSearch = (e) => {
    const { filterKeys } = this.props;
    const searchOptions = {};

    if (filterKeys?.length) {
      searchOptions.customFilter = this.customSearchFilter;
    }

    // fallback to an empty string for when we click 'X' in the search input to clear
    this.collection.filter(e?.target?.value || '', searchOptions);
  };

  // automatically installs simple grouping when defined by the collection
  groupSummary = ({ groupKey, group }) => {
    if (this.collection.groupBy) {
      const { groupSummary } = this.props;

      if (groupSummary) {
        return groupSummary({ groupKey, group, collection: this.collection });
      }

      const label = groupKey === 'undefined' ? 'Ungrouped' : groupKey;

      return (
        <Flex alignItems="center">
          <Tag mr={1} minimal>
            {group.length}
          </Tag>
          <Text> {label}</Text>
        </Flex>
      );
    }

    return null;
  };

  render() {
    const {
      searchPlaceholder,
      loading,
      columns,
      collectionOptions,
      data,
      stickyHeader,
      stickyGroups,
      emptyState,
      ...containerProps
    } = this.props;

    return (
      <Card display="flex" flexDirection="column" maxHeight={250} overflow="hidden" {...containerProps}>
        <Search
          placeholder={searchPlaceholder}
          collection={this.collection}
          onChange={this.handleSearch}
          inputProps={{
            value: this.collection.filterState,
            disabled: loading,
            clearable: true
          }}
          css={{
            [`.${Classes.INPUT}:focus`]: { boxShadow: 'none' }
          }}
        />
        <Table
          loading={loading}
          collection={this.collection}
          columns={columns}
          selectOnRowClick={false}
          groupSummaryLookup={this.groupSummary}
          style={{ overflow: 'auto' }}
          stickyHeader={stickyHeader}
          stickyGroups={stickyGroups}
          emptyState={emptyState}
        />
      </Card>
    );
  }
}
