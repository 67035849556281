import { privateIpOptions } from 'app/util/constants';
import { isIpValid } from 'core/util/ip';

const privateSubnets = privateIpOptions.reduce((list, opt) => {
  list.push(...opt.value.split(',').map((subnet) => isIpValid(subnet, { returnAddress: true })));
  return list;
}, []);

function isPrivate(address) {
  return privateSubnets.reduce((res, privateSubnet) => res || address.isInSubnet(privateSubnet), false);
}

export const isIpPublic = function isIpPublic(value) {
  if (value) {
    const noPort = value.substr(0, value.lastIndexOf(':'));

    const validIp = isIpValid(value, { returnAddress: true });
    const validNoPort = isIpValid(noPort, { returnAddress: true });
    return (validIp && !isPrivate(validIp)) || (validNoPort && !isPrivate(validNoPort));
  }

  return true;
};

export default {
  message: 'The provided IP address must be publicly accessible.',
  fn: isIpPublic
};
