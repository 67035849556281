import React, { Component } from 'react';
import { formConsumer } from 'core/form';
import { dateTimeFormatter } from 'core/util/dateUtils';
import AdminTable from 'app/components/admin/AdminTable';
import { Flex } from 'core/components';

@formConsumer
export default class CompanyDevices extends Component {
  getColumns = () => [
    {
      label: 'ID',
      name: 'id',
      width: 80
    },
    {
      label: 'Name',
      name: 'device_name',
      flexBasis: 200
    },
    {
      label: 'Status',
      name: 'device_status'
    },
    {
      label: 'Type',
      name: 'device_subtype'
    },
    {
      label: 'Plan',
      name: 'plan_id',
      renderer: ({ model }) => model.get('plan').get('name')
    },
    {
      label: 'Flow Type',
      name: 'device_flow_type'
    },
    {
      label: 'Sample Rate',
      name: 'device_sample_rate'
    },
    {
      label: 'BGP',
      name: 'device_bgp_type'
    },
    {
      label: 'Tee',
      name: 'device_tee'
    },
    {
      label: 'Created',
      name: 'cdate',
      renderer: dateTimeFormatter
    },
    {
      label: 'Updated',
      name: 'edate',
      renderer: dateTimeFormatter
    }
  ];

  render() {
    const { form } = this.props;
    return (
      <Flex flexDirection="column" flex={1} overflow="auto">
        <AdminTable
          collection={form.model.get('devices')}
          columns={this.getColumns()}
          containerProps={{ overflow: 'auto' }}
          tableContainerProps={{ overflow: 'auto' }}
          fetchCollectionOnMount={false}
          flexed
        />
      </Flex>
    );
  }
}
