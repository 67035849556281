import * as React from 'react';
import { observer } from 'mobx-react';
import { parseDimensionIp } from 'core/util/ip';
import { Box, Button, Card, EmptyState, Flex, Tag, Text } from 'core/components';
import { Form, Field, InputGroup } from 'core/form';
import { Table } from 'core/components/table';
import { AZURE_ENTITY_TYPES } from 'shared/hybrid/constants';
import { uriToObject } from 'shared/util/map';
import { buildFilterGroup } from 'core/util/filters';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import ViewInExplorerButton from 'app/components/dataviews/tools/ViewInExplorerButton';
import DeniedTrafficCollection from 'app/stores/hybrid/DeniedTrafficCollection';
import { getDimensionFieldName, getDimensionFilter } from 'app/views/hybrid/maps/cloudDimensionConstants';

/*
  Supports listing denied traffic flows in the form of ip/port/protocol
  Supported providers: aws, azure
  Supported aws entities: vpc, subnet
  Supported azure entities: vnet, subnet, firewall

  The queries and filters that generate this table are largely created by a series of getters
  so it should be easier to add support for other like-minded entities in other providers in the future
*/

@Form({ fields: { search: {} }, options: { name: 'Denied Traffic Search Form' } })
@observer
export default class DeniedTrafficTable extends React.Component {
  collection = new DeniedTrafficCollection();

  /*
    Returns the identifying property of a virtual network entity that will be used for matching values in filters
    This can oftentimes be provider-specific, ie aws vpc id vs azure vnet id (name)
  */
  get virtualNetworkId() {
    const { cloudProvider, entity, entityType } = this.props;

    if (cloudProvider === 'aws' && (entityType === 'vpc' || entityType === 'subnet')) {
      return entity.VpcId;
    }

    if (cloudProvider === 'azure') {
      if (entityType === AZURE_ENTITY_TYPES.VNET) {
        return entity.name;
      }

      if (entityType === AZURE_ENTITY_TYPES.SUBNET) {
        const { virtualNetwork } = uriToObject(entity.id);

        if (virtualNetwork) {
          return virtualNetwork;
        }
      }
    }

    return null;
  }

  /*
    The virtual network field type we use to look up dimension names and generate their filters
  */
  get virtualNetworkFieldType() {
    const { cloudProvider } = this.props;

    if (cloudProvider === 'aws') {
      return 'vpc_id';
    }

    if (cloudProvider === 'azure') {
      return 'vnet_id';
    }

    return null;
  }

  /*
    Returns the identifying property of a subnet entity that will be used for matching values in filters
    This can oftentimes be provider-specific, ie aws subnet id vs azure subnet name
  */
  get subnetId() {
    const { cloudProvider, entity, entityType } = this.props;

    if (cloudProvider === 'aws' && entityType === 'subnet') {
      return entity.id;
    }

    if (cloudProvider === 'azure' && entityType === AZURE_ENTITY_TYPES.SUBNET) {
      return entity.name;
    }

    return null;
  }

  /*
    The subnet field type we use to look up dimension names and generate their filters
  */
  get subnetFieldType() {
    const { cloudProvider } = this.props;

    if (cloudProvider === 'aws') {
      return 'subnet_id';
    }

    if (cloudProvider === 'azure') {
      return 'subnet_name';
    }

    return null;
  }

  /*
    Returns the identifying property of a firewall entity that will be used for matching values in filters
    Azure is currently the only provider firewall we support
  */
  get firewallId() {
    const { cloudProvider, entity, entityType } = this.props;

    if (cloudProvider === 'azure' && entityType === AZURE_ENTITY_TYPES.FIREWALL) {
      return entity.name;
    }

    return null;
  }

  /*
    The firewall field type we use to look up dimension names and generate their filters
  */
  get firewallIdFieldType() {
    const { cloudProvider } = this.props;

    if (cloudProvider === 'azure') {
      return 'logging_resource_name';
    }

    return null;
  }

  /*
    The firewall action field type we use to look up dimension names and generate their filters
    In the case of azure, this is used for determining the direction of the denied flow
  */
  get firewallActionFieldType() {
    // aws and azure both support this dimension
    // azure supports direction, aws does not
    return 'firewall_action';
  }

  /*
    How we determine a denied flow
    With support currently only for aws and azure, they share a dimension name but just differ on the value for a rejection
  */
  get firewallRejectionFilter() {
    const { cloudProvider } = this.props;

    return getDimensionFilter({
      cloudProvider,
      type: 'firewall_action',
      filterValue: cloudProvider === 'aws' ? 'REJECT' : 'Denied'
    });
  }

  /*
    Returns a list of filter groups for denied traffic
    This is currently used to accommodate Azure firewalls where we can possibly match
    by either an uppercase or user-defined value
  */
  get entityMatchFilterGroups() {
    const { cloudProvider } = this.props;
    const { firewallId } = this;
    const filterGroups = [];

    if (firewallId) {
      filterGroups.push(
        buildFilterGroup({
          connector: 'Any',
          filters: [
            getDimensionFilter({
              cloudProvider,
              type: this.firewallIdFieldType,
              filterValue: firewallId.toUpperCase()
            }),

            getDimensionFilter({
              cloudProvider,
              type: this.firewallIdFieldType,
              filterValue: firewallId
            })
          ]
        })
      );
    }

    return filterGroups;
  }

  /*
    Returns a list of additional filter matches for denied traffic
    This will include any of virtual network|subnet|firewall
  */
  get entityMatchFilters() {
    const { cloudProvider } = this.props;
    const { virtualNetworkId, subnetId } = this;
    const filters = [];

    if (virtualNetworkId) {
      filters.push(
        getDimensionFilter({
          cloudProvider,
          type: this.virtualNetworkFieldType,
          filterValue: virtualNetworkId
        })
      );
    }

    if (subnetId) {
      filters.push(
        getDimensionFilter({
          cloudProvider,
          type: this.subnetFieldType,
          filterValue: subnetId
        })
      );
    }

    return filters;
  }

  /*
    Returns a list of additional dimensions to participate in the denied traffic query
    This will include any of virtual network|subnet|firewall
  */
  get entityDimensions() {
    const { cloudProvider } = this.props;
    const { subnetFieldType, virtualNetworkFieldType, firewallActionFieldType } = this;
    const filters = [];

    if (this.virtualNetworkId || this.subnetId) {
      filters.push(
        getDimensionFieldName({
          cloudProvider,
          direction: 'src',
          type: virtualNetworkFieldType
        }),

        getDimensionFieldName({
          cloudProvider,
          direction: 'dst',
          type: virtualNetworkFieldType
        }),

        getDimensionFieldName({
          cloudProvider,
          direction: 'src',
          type: subnetFieldType
        }),

        getDimensionFieldName({
          cloudProvider,
          direction: 'dst',
          type: subnetFieldType
        })
      );
    } else if (this.firewallId) {
      filters.push(
        getDimensionFieldName({
          cloudProvider,
          direction: 'src',
          type: firewallActionFieldType
        }),

        getDimensionFieldName({
          cloudProvider,
          direction: 'dst',
          type: firewallActionFieldType
        })
      );
    }

    return filters;
  }

  handleSearchChange = (field) => this.collection.filter(field.getValue());

  handleSearchClear = () => {
    const { form } = this.props;

    form.setValue('search', '');
    this.collection.clearFilters();
  };

  handleRowClick = (model) => {
    const { onRowClick } = this.props;

    if (onRowClick) {
      const { port: sourcePort, protocol: sourceProtocol } = model.sourcePortAndProtocol;
      const { port: destinationPort, protocol: destinationProtocol } = model.destinationPortAndProtocol;

      onRowClick({
        direction: model.get('direction'),
        sourceIp: model.sourceIp,
        sourcePort,
        sourceProtocol,
        destinationIp: model.destinationIp,
        destinationPort,
        destinationProtocol
      });
    }
  };

  /*
    Adds the direction properties to the results
    This is done by comparing the entity (virtual network|subnet|firewall) against the denied traffic results
  */
  handleQueryComplete = ({ results, fullyLoaded }) => {
    const { cloudProvider } = this.props;
    const {
      virtualNetworkFieldType,
      virtualNetworkId,
      subnetFieldType,
      subnetId,
      firewallId,
      firewallActionFieldType
    } = this;

    if (fullyLoaded) {
      const data = results.reduce((acc, model) => {
        let direction = null;

        if (subnetId) {
          const srcSubnetId = model.get(
            getDimensionFieldName({ cloudProvider, direction: 'src', type: subnetFieldType })
          );
          const dstSubnetId = model.get(
            getDimensionFieldName({ cloudProvider, direction: 'dst', type: subnetFieldType })
          );

          if (srcSubnetId === subnetId) {
            direction = 'outbound';
          }

          if (dstSubnetId === subnetId) {
            direction = 'inbound';
          }
        } else if (virtualNetworkId) {
          const srcVirtualNetworkId = model.get(
            getDimensionFieldName({ cloudProvider, direction: 'src', type: virtualNetworkFieldType })
          );
          const dstVirtualNetworkId = model.get(
            getDimensionFieldName({ cloudProvider, direction: 'dst', type: virtualNetworkFieldType })
          );

          if (srcVirtualNetworkId === virtualNetworkId) {
            direction = 'outbound';
          }

          if (dstVirtualNetworkId === virtualNetworkId) {
            direction = 'inbound';
          }
        } else if (firewallId) {
          const srcFirewallAction = model.get(
            getDimensionFieldName({ cloudProvider, direction: 'src', type: firewallActionFieldType })
          );
          const dstFirewallAction = model.get(
            getDimensionFieldName({ cloudProvider, direction: 'dst', type: firewallActionFieldType })
          );

          if (srcFirewallAction === 'Denied') {
            direction = 'outbound';
          }

          if (dstFirewallAction === 'Denied') {
            direction = 'inbound';
          }
        }

        if (direction) {
          acc.push({ ...model.get(), direction });
        }

        return acc;
      }, []);

      this.collection.set(data);
    }
  };

  get deniedTrafficQuery() {
    const { queryTimeOptions, cloudProvider } = this.props;

    const query = {
      all_devices: false,
      aggregateTypes: ['agg_total_fps'],
      device_types: [`${cloudProvider}_subnet`],
      show_overlay: false,
      show_total_overlay: false,
      filters: {
        connector: 'All',
        filterGroups: [
          ...this.entityMatchFilterGroups,
          {
            name: '',
            named: false,
            connector: 'All',
            not: false,
            autoAdded: '',
            filters: [this.firewallRejectionFilter, ...this.entityMatchFilters],
            saved_filters: [],
            filterGroups: []
          }
        ]
      },
      metric: ['IP_src', 'src_proto_port', 'IP_dst', 'dst_proto_port', ...this.entityDimensions],
      viz_type: 'table',
      ...queryTimeOptions
    };

    return query;
  }

  getDetailQuery(model) {
    const { cloudProvider, queryTimeOptions } = this.props;
    const { virtualNetworkId, subnetId, firewallId } = this;
    const direction = model.get('direction');
    const srcIP = model.get('inet_src_addr');
    const { port: srcPort, protocol: srcProtocol } = model.sourcePortAndProtocol;
    const dstIP = model.get('inet_dst_addr');
    const { port: dstPort, protocol: dstProtocol } = model.destinationPortAndProtocol;
    const filters = [this.firewallRejectionFilter];
    const filterGroups = [];
    const directionCode = direction === 'inbound' ? 'dst' : 'src';
    const query_title = `Denied ${direction} traffic ${
      direction === 'inbound' ? 'to' : 'from'
    } ${virtualNetworkId} ${subnetId || ''}`;

    if (srcIP) {
      filters.push({
        filterField: 'inet_src_addr',
        operator: 'ILIKE',
        filterValue: parseDimensionIp(srcIP)
      });
    }

    if (srcPort) {
      filters.push({
        filterField: 'l4_src_port',
        operator: '=',
        filterValue: srcPort.toString()
      });
    }

    if (srcProtocol) {
      filters.push({
        filterField: 'i_protocol_name',
        operator: '=',
        filterValue: srcProtocol
      });
    }

    if (dstIP) {
      filters.push({
        filterField: 'inet_dst_addr',
        operator: 'ILIKE',
        filterValue: parseDimensionIp(dstIP)
      });
    }

    if (dstPort) {
      filters.push({
        filterField: 'l4_dst_port',
        operator: '=',
        filterValue: dstPort.toString()
      });
    }

    if (dstProtocol) {
      filters.push({
        filterField: 'i_protocol_name',
        operator: '=',
        filterValue: dstProtocol
      });
    }

    if (virtualNetworkId) {
      filters.push(
        getDimensionFilter({
          cloudProvider,
          type: this.virtualNetworkFieldType,
          direction: directionCode,
          filterValue: virtualNetworkId
        })
      );
    }

    if (subnetId) {
      filters.push(
        getDimensionFilter({
          cloudProvider,
          type: this.subnetFieldType,
          direction: directionCode,
          filterValue: subnetId
        })
      );
    }

    if (firewallId) {
      // Azure firewalls are the only entity currently using this filter group
      // so we'll re-use it here until the next entity we want to support comes along
      filterGroups.push(...this.entityMatchFilterGroups);
    }

    const query = {
      all_devices: false,
      aggregateTypes: ['agg_total_fps', 'agg_total_bytes'],
      device_types: [`${cloudProvider}_subnet`],
      query_title,
      show_overlay: false,
      show_total_overlay: false,
      filters: {
        connector: 'All',
        filterGroups: [
          ...filterGroups,
          {
            name: '',
            named: false,
            connector: 'All',
            not: false,
            autoAdded: '',
            filters,
            saved_filters: [],
            filterGroups: []
          }
        ]
      },
      metric: ['IP_src', 'Port_src', 'IP_dst', 'Port_dst'],
      viz_type: 'table',
      ...queryTimeOptions
    };

    return query;
  }

  get columns() {
    const { virtualNetworkId, subnetId } = this;

    return [
      {
        label: 'Direction',
        name: 'direction',
        width: 60,
        renderer: ({ value }) => {
          const icon = value === 'inbound' ? 'arrow-left' : null;
          const rightIcon = value === 'outbound' ? 'arrow-right' : null;
          const label = value === 'inbound' ? 'in' : 'out';
          const title = `${value === 'inbound' ? 'Inbound to' : 'Outbound from'} ${subnetId || virtualNetworkId}`;

          return (
            <Tag icon={icon} rightIcon={rightIcon} title={title} small minimal>
              {label}
            </Tag>
          );
        }
      },

      {
        label: 'Source',
        name: 'source',
        computed: true
      },

      {
        label: 'Destination',
        name: 'destination',
        computed: true
      },

      {
        label: 'Total Flows',
        name: 'agg_total_fps',
        align: 'right',
        width: 60
      },

      {
        label: null,
        name: 'viewInExplorer',
        width: 50,
        renderer: ({ model }) => (
          // keep the model selected when using the data explorer button
          <div
            onClick={(e) => {
              model.select();
              e.stopPropagation();
            }}
          >
            <ViewInExplorerButton
              text={null}
              query={this.getDetailQuery(model)}
              title="View in Data Explorer"
              openInNewWindow
            />
          </div>
        )
      }
    ];
  }

  render() {
    const { form, cloudProvider } = this.props;
    const isSearching = form.getValue('search') !== '';

    if (cloudProvider === 'aws' || cloudProvider === 'azure') {
      return (
        <LightweightDataViewWrapper query={this.deniedTrafficQuery} onQueryComplete={this.handleQueryComplete}>
          {({ loading }) => (
            <Card display="flex" flexDirection="column" height={310} showSkeleton={loading}>
              <Box borderBottom="thin" p={1} bg="subnavBackground">
                <Field
                  name="search"
                  className="no-margin"
                  flex={1}
                  onChange={this.handleSearchChange}
                  disabled={this.collection.unfilteredSize === 0}
                >
                  <InputGroup
                    ml={1}
                    leftIcon="search"
                    rightElement={isSearching && <Button icon="cross" onClick={this.handleSearchClear} minimal small />}
                  />
                </Field>
              </Box>
              <Box overflow="auto">
                <Table
                  loading={loading}
                  collection={this.collection}
                  columns={this.columns}
                  onRowClick={this.handleRowClick}
                  emptyState={
                    <EmptyState
                      title="No Results"
                      icon="shield"
                      description={
                        <Flex flexDirection="column" alignItems="center">
                          <Text as="div">{isSearching ? '' : 'Please try a different time range'}</Text>
                          <ViewInExplorerButton
                            query={this.deniedTrafficQuery}
                            text="View in Data Explorer"
                            openInNewWindow
                          />
                        </Flex>
                      }
                    />
                  }
                />
              </Box>
            </Card>
          )}
        </LightweightDataViewWrapper>
      );
    }

    return null;
  }
}
