import * as React from 'react';
import { Box, Icon, Text } from 'core/components';
import { getIfOperStatusLabel } from 'app/views/hybrid/utils/health';

// 1 = up, 2 = down, 7 = lowerLayerDown

const iconMap = {
  1: 'tick-circle',
  2: 'error',
  7: 'error'
};

const intentMap = {
  1: 'success',
  2: 'danger',
  7: 'danger'
};

export default class OperStatus extends React.Component {
  get icon() {
    const { check } = this.props;
    const iconName = iconMap[check.value] || null;
    const intent = intentMap[check.value] || null;

    if (iconName && intent) {
      return <Icon icon={iconName} intent={intent} mr="4px" />;
    }

    return null;
  }

  render() {
    const { check } = this.props;
    let label = 'n/a';

    if (check && check.value) {
      label = getIfOperStatusLabel(check.value);

      return (
        <Box>
          {this.icon}
          <Text>{label}</Text>
        </Box>
      );
    }

    return <Text>{label}</Text>;
  }
}
