import * as React from 'react';
import { inject } from 'mobx-react';
import Splash from 'app/views/splash/Splash';
import { CLOUD_PROVIDERS } from 'app/util/constants';
import ListImage from 'app/assets/screenshots/connectivity-checker-list.png';
import ListImageDark from 'app/assets/screenshots/connectivity-checker-list-dark.png';
import DetailImage from 'app/assets/screenshots/connectivity-checker-detail.png';
import DetailImageDark from 'app/assets/screenshots/connectivity-checker-detail-dark.png';
import { Box, Card, Flex, Heading, Link, LinkButton, Text, ThemedImage, Tabs, Tab } from 'core/components';

const imageGroup = [
  {
    title: 'Report List',
    images: { src: ListImage, darkSrc: ListImageDark }
  },

  {
    title: 'Report Detail',
    images: { src: DetailImage, darkSrc: DetailImageDark }
  }
];

const { AWS, AZURE } = CLOUD_PROVIDERS;
// TODO - rename all "Connectivity Checker" components to reflect new ***marketing-approved*** name "Cloud Pathfinder"
function ConnectivityCheckerSplash({ $hybridMap }) {
  return (
    <Splash title="Cloud Pathfinder" toolbar={<Heading level={1}>Cloud Pathfinder For AWS and Azure</Heading>} scrolls>
      <Flex gap={3} pt={2}>
        <Tabs flex={1}>
          {imageGroup.map((group) => (
            <Tab
              key={group.title}
              id={group.title}
              title={group.title}
              panel={
                <Card>
                  <ThemedImage {...group.images} />
                </Card>
              }
            />
          ))}
        </Tabs>

        <Box flex={1} pt="40px">
          <Heading level={3} mb={2}>
            Use Cloud Pathfinder to analyze network connectivity within your cloud environment
          </Heading>

          <Text as="p" mb={2}>
            Analyze network connectivity between two points within your AWS or Azure cloud environment, including both
            forward and return paths between instances, subnets, network interfaces, or IP addresses.
          </Text>

          <Text as="p" mb={2}>
            To start, choose a source and destination to analyze: Cloud Pathfinder&apos;s connectivity report will help
            you understand the network flow between them. The report also includes a visualization of the flow path,
            highlighting any potential connectivity issues, such as security groups or network ACLs. Cloud Pathfinder
            provides you with suggestions to resolve connectivity issues, including links to the relevant AWS console or
            Azure portal for adjusting gateway routing or security policies.
          </Text>

          <Text as="p" mb={2}>
            Note: to use Cloud Pathfinder, you must have metadata enabled in your AWS or Azure cloud exports.
          </Text>

          <Text as="p" mb={2}>
            <Link to="/v4/settings/clouds" blank>
              View and edit your cloud exports
            </Link>
          </Text>

          <Flex gap={1} justifyContent="space-between" mt={2}>
            {!$hybridMap.hasCloud('aws') && (
              <LinkButton
                text="Onboard Your AWS to Get Access"
                to="/v4/setup/clouds/aws"
                intent="primary"
                icon={AWS.logo}
              />
            )}
            {!$hybridMap.hasCloud('azure') && (
              <LinkButton
                text="Onboard Your Azure to Get Access"
                to="/v4/setup/clouds/azure"
                intent="primary"
                icon={AZURE.logo}
              />
            )}
          </Flex>

          <Flex gap={1} justifyContent="space-between" mt={2}>
            {$hybridMap.hasCloud('aws') && (
              <LinkButton text="Show AWS Pathfinder Report" to="/v4/cloud/pathfinder/aws" icon={AWS.logo} />
            )}
            {$hybridMap.hasCloud('azure') && (
              <LinkButton text="Show Azure Pathfinder Report" to="/v4/cloud/pathfinder/azure" icon={AZURE.logo} />
            )}
          </Flex>
        </Box>
      </Flex>
    </Splash>
  );
}

export default inject('$hybridMap')(ConnectivityCheckerSplash);
