import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { get } from 'lodash';
import { Classes } from '@blueprintjs/core';
import { AiOutlineAreaChart } from 'react-icons/ai';
import { MdDashboard } from 'react-icons/md';

import { Box, Button, Callout, Card, Flex, Icon, Heading, Suspense, Text } from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import { FormComponent } from 'core/form';
import DashboardLink from 'app/components/links/DashboardLink';
import SavedViewLink from 'app/components/links/SavedViewLink';
import PackageIcon from 'app/views/mkpConfig/components/PackageIcon';
import ApplyParametricOptions from 'app/views/core/dashboards/ApplyParametricOptions';
import Collection from 'core/model/Collection';

const parametricFields = {
  parametric_fields: {
    isComplexArray: true
  },
  'parametric_fields[].type': {},
  'parametric_fields[].label': {},
  'parametric_fields[].question': {},
  'parametric_fields[].value': {
    rules: 'required'
  }
};

function LibraryLinkButton({ view, children }) {
  const ButtonComponent = view.type === 'Dashboard' ? DashboardLink : SavedViewLink;

  if (view.get('parametric')) {
    return (
      <Card
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        textDecoration="none"
        width={300}
        mt={2}
        mr={2}
        p={0}
      >
        {children}
      </Card>
    );
  }

  return (
    <ButtonComponent
      as="a"
      id={view.id}
      type={view.get('view_type')}
      className={`${Classes.CARD} ${Classes.INTERACTIVE}`}
      display="flex"
      textDecoration="none"
      width={300}
      mt={2}
      mr={2}
      p={0}
      style={{
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}
    >
      {children}
    </ButtonComponent>
  );
}

@storeLoader('$dashboards', '$savedViews')
@inject('$recentlyViewed', '$auth')
@observer
export default class Views extends Component {
  get tenantPackage() {
    const { $auth } = this.props;
    const tenantPackages = $auth.getActiveUserProperty('userGroup.template');

    return tenantPackages && tenantPackages.length > 0 && tenantPackages[0];
  }

  handleParametricSubmit = (dashboard, parametricFieldValues) => {
    const { $dashboards } = this.props;
    dashboard.set(parametricFieldValues);
    $dashboards.navigateToDashboard(dashboard, true, null, null);
  };

  getFormValuesFromDashboard = (model) => {
    const values = model.get();
    const [parametric_field] = values.parametric_fields;
    parametric_field.value = undefined;

    return values;
  };

  renderViewCard(view) {
    const { $recentlyViewed } = this.props;
    const isDashboard = view.type === 'Dashboard';

    const tenantViewType = isDashboard ? 'dashboard' : 'savedView';
    const { tenantPackage } = this;
    const isPackage = get(tenantPackage, 'config.assets.reports', []).find(
      (packageView) => packageView.id === view.id && packageView.type === tenantViewType
    );
    const lastViewedDate = $recentlyViewed.getLastViewedDate(view);

    return (
      <LibraryLinkButton key={view.id} view={view}>
        <Box flex={1} p="12px">
          <Heading level={6} mb={0}>
            <Flex alignItems="center">
              <Icon icon={isDashboard ? MdDashboard : AiOutlineAreaChart} m="0 4px 0 0 !important" color="muted" />
              {view.get(isDashboard ? 'dash_title' : 'view_name')}
            </Flex>
          </Heading>

          {view.description && (
            <Text
              as="div"
              muted
              small
              style={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitLineClamp: '4',
                WebkitBoxOrient: 'vertical',
                marginTop: 4
              }}
            >
              {view.description}
            </Text>
          )}
          {lastViewedDate && (
            <Text as="div" mt={2} small muted>
              Last Opened: {moment(lastViewedDate).fromNow()}
            </Text>
          )}
        </Box>
        {view.get('parametric') && (
          <FormComponent
            fields={parametricFields}
            options={{ name: `${view.id} Parametric`, showPristineErrors: false }}
            values={this.getFormValuesFromDashboard(view)}
          >
            <ApplyParametricOptions dashboard={view} handleSubmit={this.handleParametricSubmit}>
              {({ buttonProps, inputField, question }) => (
                <Callout icon={null} ml="12px" mb={2} width={276}>
                  <Text as="h6" fontSize={12} mt={0} mb="12px" textTransform="uppercase" muted>
                    <Box>
                      <Text>{question}</Text>
                    </Box>
                  </Text>

                  <Flex alignItems="center">
                    <Box flex={1}>{React.cloneElement(inputField, { mb: 0 })}</Box>

                    <Button small {...buttonProps} text="Go" ml={1} />
                  </Flex>
                </Callout>
              )}
            </ApplyParametricOptions>
          </FormComponent>
        )}
        {isPackage && (
          <Flex
            p="3px 6px"
            width="100%"
            justifyContent="center"
            bg={get(tenantPackage, 'config.color', '#abb8c3')}
            borderRadius="0 0 3px 3px"
            alignItems="center"
          >
            <PackageIcon
              iconSize={12}
              width={12}
              height={12}
              icon={get(tenantPackage, 'config.icon')}
              color={get(tenantPackage, 'config.color')}
            />
            <Text as="div" ml={1} color="#ffffff" small ellipsis>
              {tenantPackage.name}
            </Text>
          </Flex>
        )}
      </LibraryLinkButton>
    );
  }

  render() {
    const { loading, $dashboards, $savedViews } = this.props;

    const dashboardAndViewCollection = new Collection(
      [...$dashboards.collection.get(), ...$savedViews.collection.get()],
      {
        groupBy: {
          groupByFn: (model) => {
            if (model.labels && model.labels.length > 0) {
              const allLabels = {};
              model.labels.forEach((label) => {
                allLabels[label.get('name')] = label.get('id');
              });
              return Object.keys(allLabels);
            }
            return ['Unlabeled'];
          }
        }
      }
    );

    const groupedViewsbyLabel = dashboardAndViewCollection.groupedData;
    const labels = Object.keys(groupedViewsbyLabel).sort((a, b) => a.localeCompare(b));

    return (
      <Suspense loading={loading}>
        {labels.map((label) => (
          <Box key={label} mb={3}>
            <Box borderBottom="tabs">
              <Text textTransform="uppercase" fontWeight="bold" fontSize={12}>
                {label}
              </Text>
            </Box>
            <Flex flexWrap="wrap">{groupedViewsbyLabel[label].map((view) => this.renderViewCard(view))}</Flex>
          </Box>
        ))}
      </Suspense>
    );
  }
}
