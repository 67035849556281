import React, { Component } from 'react';

import { Card, Callout, Grid, Icon, Text } from 'core/components';
import { ReactComponent as CPUIcon } from 'app/assets/icons/cpu_icon.svg';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import { getHealthStateFromValue } from 'app/views/hybrid/utils/health';
import DeviceMetricsDetailCharts from 'app/views/hybrid/maps/components/deviceMetrics/DeviceMetricsDetailCharts';

export default class CPUChart extends Component {
  get query() {
    const { deviceName } = this.props;

    return {
      all_devices: false,
      device_name: [deviceName],
      fastData: 'Full',
      metric: ['ktappprotocol__snmp_device_metrics__STR01'], // component
      aggregateTypes: ['avg_ktappprotocol__snmp_device_metrics__INT64_00'], // utilization
      lookback_seconds: 86400,
      show_overlay: false,
      show_total_overlay: false
    };
  }

  get loadingState() {
    return <Card height={100} mb={1} showSkeleton />;
  }

  get emptyState() {
    return (
      <Callout>
        <Icon icon={CPUIcon} />
        <Text ml={1}>CPU Data Unavailable</Text>
      </Callout>
    );
  }

  getResults = (results) =>
    results.getRawDataRows().map((model) => {
      const value = model.get('avg_ktappprotocol__snmp_device_metrics__INT64_00');
      const healthState = getHealthStateFromValue({ type: 'cpu', value });
      let color = 'primary';
      let headingProps = {};

      if (healthState.state !== 'GOOD') {
        color = healthState.color;
        headingProps = { color: healthState.color, fontWeight: 'bold' };
      }

      return {
        key: model.get('key'),
        value,
        lastDatapoint: model.get('f_avg_int64_00__k_last'),
        rawData: model.get('rawData').f_avg_int64_00.flow,
        color,
        headingProps
      };
    });

  render() {
    return (
      <LightweightDataViewWrapper query={this.query}>
        {({ loading, results }) => {
          if (loading) {
            return this.loadingState;
          }

          const data = this.getResults(results);

          if (data.length > 0) {
            return (
              <Grid gridTemplateColumns="repeat(auto-fit, minmax(350px, 1fr))" gridGap={4} gridRowGap={2}>
                <DeviceMetricsDetailCharts data={data} title="Average CPU Utilization" icon={CPUIcon} />
              </Grid>
            );
          }

          return this.emptyState;
        }}
      </LightweightDataViewWrapper>
    );
  }
}
