import { easeLinear } from 'd3';

const DEFAULT_TICKS = 100;
const DEFAULT_TICK_DURATION = 10;

export const linearTransitionAlongThePath = ({
  d3Arrow,
  pathSvg,
  startPoint,
  tick = 0,
  isReverse = false,
  totalTicks = DEFAULT_TICKS,
  tickDuration = DEFAULT_TICK_DURATION
}) => {
  const currentTick = () => {
    if (isReverse) {
      return tick === 0 ? totalTicks : tick;
    }

    return tick === totalTicks ? 0 : tick;
  };

  const nextTick = () => {
    const diff = isReverse ? -1 : 1;

    return currentTick() + diff;
  };

  d3Arrow
    .transition()
    .duration(tickDuration)
    .ease(easeLinear)
    .attr('transform', () => {
      tick = currentTick();

      const length = pathSvg.getTotalLength();
      const prevPoint = pathSvg.getPointAtLength((length / totalTicks) * tick);
      const nextPoint = pathSvg.getPointAtLength((length / totalTicks) * nextTick());
      const angle = (Math.atan2(nextPoint.y - prevPoint.y, nextPoint.x - prevPoint.x) * 180) / Math.PI; // angle for the arrow
      return `translate(${nextPoint.x},${nextPoint.y})rotate(${angle})`;
    })
    .on('end', () => {
      tick = nextTick();
      linearTransitionAlongThePath({ d3Arrow, pathSvg, startPoint, tick, isReverse });
    });
};
