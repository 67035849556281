import React, { useState, useEffect } from 'react';
import { Callout } from 'core/components';

const TimedCallout = ({ timeout, ...rest }) => {
  const [showCallout, setShowCallout] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setShowCallout(false), timeout);
    return () => clearTimeout(timer);
  }, []);

  if (!showCallout) {
    return null;
  }

  return <Callout {...rest} />;
};

export default TimedCallout;
