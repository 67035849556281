import { interpolateRgb, piecewise } from 'd3-interpolate';
import { scaleOrdinal, scalePow, scaleQuantize } from 'd3-scale';
import { select } from 'd3-selection';
import { capitalize } from 'lodash';
import colorLegend from './colorLegend';

export const trafficColorScale = piecewise(interpolateRgb.gamma(2.2), [
  '#445b77',
  '#545e90',
  '#795a9e',
  '#a74e9c',
  '#d23988',
  '#f12564',
  '#ff3333'
]);

export const utilizationColorScale = scaleQuantize().range([
  '#CFB4F9',
  '#C7A3FF',
  '#B789FF',
  '#A76FFF',
  '#935FF3',
  '#7336F3',
  '#5723E5',
  '#421DC9',
  '#3016AC',
  '#140A73'
]);

export const healthColorScale = scaleOrdinal()
  .domain(['down', 'critical', 'degraded', 'warning', 'healthy'])
  .range(['#EB483F', '#EB483F', '#F3AB3E', '#F3AB3E', '#82D577']);

export function getTrafficColor(bps, maxBps = 1_000_000_000) {
  const trafficScale = scalePow()
    .exponent(1 / 5)
    .domain([1, maxBps])
    .clamp(true)
    .range([0, 1]);

  return trafficColorScale(trafficScale(bps));
}

export function renderLegend(canvas, n = 512) {
  const context = canvas.getContext('2d');

  canvas.width = n;
  canvas.height = 1;
  canvas.style.width = '100%';
  canvas.style.height = '100%';
  canvas.style.display = 'block';
  canvas.style.imageRendering = 'pixelated';

  for (let i = 0; i < n; ++i) {
    context.fillStyle = trafficColorScale(i / (n - 1));
    context.fillRect(i, 0, 1, 1);
  }
}

export function renderUtilizationLegend(options = {}) {
  return colorLegend(utilizationColorScale, { tickFormat: '.0%', ...options });
}

export function renderHealthLegend(options = {}) {
  const node = colorLegend(healthColorScale, { tickSize: 2, tickFormat: (value) => capitalize(value), ...options });
  const svg = select(node);
  const segmentWidth = svg.attr('width') / healthColorScale.domain().length;
  const margin = segmentWidth * 0.2;

  // add line underneath colors that denote line colors
  [0, 2].forEach((idx) => {
    svg
      .append('line')
      .attr('y1', svg.attr('height'))
      .attr('y2', svg.attr('height'))
      .attr('x1', segmentWidth * idx + margin)
      .attr('x2', segmentWidth * (idx + 1) - margin)
      .attr('stroke', healthColorScale.range()[idx])
      .attr('stroke-dasharray', '7 3');
  });

  svg.selectAll('.tick > line').remove();

  return node;
}
