import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from 'core/components';
import { getConsoleUrlForResource } from 'app/views/hybrid/utils/azure';

const AzureConsoleLink = ({ entity, hideLabel, ...rest }) => {
  const azureResourceUrl = getConsoleUrlForResource(entity);

  if (!azureResourceUrl) {
    return null;
  }

  return (
    <LinkButton
      key="clear"
      icon="panel-stats"
      to={azureResourceUrl}
      title="Open in Azure Console"
      ml="auto"
      blank
      minimal
      small
      {...rest}
    >
      {!hideLabel && 'Azure Console'}
    </LinkButton>
  );
};

AzureConsoleLink.propTypes = {
  hideLabel: PropTypes.bool,
  entity: PropTypes.object.isRequired
};

AzureConsoleLink.defaultProps = {
  hideLabel: false
};

export default React.memo(AzureConsoleLink);
