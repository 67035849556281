import React, { Component } from 'react';
import { observer } from 'mobx-react';
import storeLoader from 'app/stores/storeLoader';
import { withRouter } from 'react-router-dom';
import Page from 'app/components/page/Page';
import CompanyAdminDetailsForm from 'app/views/admin/CompanyAdminDetailsForm';
import { Suspense } from 'core/components';

@storeLoader('$sudo.companyAdminCollection')
@withRouter
@observer
export default class CompanyAdminDetails extends Component {
  state = {
    selectedCompanyModel: undefined
  };

  componentDidUpdate() {
    const { match, $sudo } = this.props;
    const { selectedCompanyModel } = this.state;

    if ($sudo.companyAdminCollection.hasFetched && selectedCompanyModel?.id !== match.params.id) {
      const model = $sudo.companyAdminCollection.get(match.params.id);
      if (model) {
        model.fetch().then(() => this.setSelectedCompanyModel(model));
      }
    }
  }

  setSelectedCompanyModel = (selectedCompanyModel) => {
    this.setState({ selectedCompanyModel });
  };

  render() {
    const { loading } = this.props;
    const { selectedCompanyModel } = this.state;

    let title = 'Manage Company';
    if (selectedCompanyModel) {
      title = selectedCompanyModel.get('company_name_full');
    }

    return (
      <Page title={title} height="100%" scrolls>
        <Suspense loading={loading}>
          {selectedCompanyModel && <CompanyAdminDetailsForm model={selectedCompanyModel} title={title} />}
        </Suspense>
      </Page>
    );
  }
}
