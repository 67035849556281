import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { line, curveBasis } from 'd3-shape';
import classNames from 'classnames';
import { get } from 'lodash';
import { withTheme } from 'styled-components';

import { Circle, Path, Text } from 'app/components/svg';
import ArcLayout from '../layouts/arc/ArcLayout';
import ArcLayoutItemGroup from '../layouts/arc/ArcLayoutItemGroup';
import HoneycombLayout, { getHoneycombPoint, getHoneycombWidth } from '../layouts/honeycomb/HoneycombLayout';
import { splitPath, getTrafficLabel, addTrafficLabelArrow } from '../../utils/links';
import { getLayerIndex } from '../../utils/siteArchitecture';
import ArcLayoutIcon from '../layouts/arc/ArcLayoutIcon';

const honeycombLayoutThreshold = 50;

@withTheme
@inject('$auth')
export default class SiteDevices extends Component {
  static defaultProps = {
    devices: {},
    links: [],
    highlighted: [],
    arcHeight: 100,
    paddingX: 100,
    paddingY: 60,
    linksMap: {},
    layers: [],
    hideLayerNames: false,
    hideDeviceNames: false,
    getLinkTraffic: () => null
  };

  state = {
    hoveredLink: null,
    selectedLink: null
  };

  componentDidUpdate(prevProps) {
    const { isPopoverOpen } = this.props;

    if (prevProps.isPopoverOpen && !isPopoverOpen) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedLink: null });
    }
  }

  get maxHeight() {
    const { arcHeight } = this.props;
    return arcHeight;
  }

  isHoneycombLayout(layerIndex, subLayerIndex) {
    const { layers } = this.props;
    const subLayer = layers[layerIndex].subLayers[subLayerIndex];
    return subLayer.unassigned || (subLayer.devices || []).length >= honeycombLayoutThreshold;
  }

  getMaxWidthAt(layerIndex, subLayerIndex) {
    const { layers, paddingX, width } = this.props;
    const layer = layers[layerIndex];
    const numLayerDevices = layer.subLayers.reduce((num, { devices }) => num + devices.length, 0);
    const numSubLayerDevices = layer.subLayers[subLayerIndex].devices.length;
    return width * (numSubLayerDevices / numLayerDevices) - paddingX * 2;
  }

  getArcWidthAt(layerIndex, subLayerIndex) {
    const { layers, paddingX } = this.props;
    const maxWidth = this.getMaxWidthAt(layerIndex, subLayerIndex);
    const numSubLayerDevices = layers[layerIndex].subLayers[subLayerIndex].devices.length;

    if (this.isHoneycombLayout(layerIndex, subLayerIndex)) {
      return getHoneycombWidth({ width: maxWidth, count: numSubLayerDevices });
    }

    return Math.min(maxWidth - paddingX * 2, (numSubLayerDevices - 1) * 200);
  }

  getArcHeightAt(layerIndex, subLayerIndex) {
    const { layers, linksMap } = this.props;
    const numDevices = layers[layerIndex].subLayers[subLayerIndex].devices.length;

    if (layerIndex === layers.length - 1) {
      if (numDevices <= 2) {
        // preserve the flat layout
        return 1;
      }

      // this is a flat layout however if there are links between assets we'll want to adjust the height based on links so they render apart from one another

      // the links map defines links on each side of a connecting link
      // this flattens out the hash and divides by 2 to get the number of complete links (both sides)
      const numberOfLinks =
        Object.values(linksMap).reduce((acc, linkMap) => (acc += Object.keys(linkMap).length), 0) / 2;

      // the calculated link height, defaulting to 1 (flat) if there are no links
      const linksHeight = Math.max(numberOfLinks * 15, 1);

      return Math.min(this.maxHeight, linksHeight);
    }

    if (numDevices === 1) {
      return 15;
    }

    return Math.min(this.maxHeight, (numDevices - 1) * 15);
  }

  getLayerOffsetAt(layerIndex, subLayerIndex) {
    const { paddingX, paddingY } = this.props;

    let offsetX = 0;
    for (let i = subLayerIndex - 1; i >= 0; i -= 1) {
      offsetX += this.getMaxWidthAt(layerIndex, i) + paddingX * 2;
    }

    return [offsetX, paddingY / 2 + paddingY * (layerIndex + 1) + (this.maxHeight + paddingY) * layerIndex];
  }

  getArcOffsetAt(layerIndex, subLayerIndex) {
    const { paddingX } = this.props;

    const [offsetX, offsetY] = this.getLayerOffsetAt(layerIndex, subLayerIndex);
    const maxWidth = this.getMaxWidthAt(layerIndex, subLayerIndex);

    const width = this.getArcWidthAt(layerIndex, subLayerIndex);
    const height = this.getArcHeightAt(layerIndex, subLayerIndex);

    return [paddingX + offsetX + (maxWidth - width) / 2, offsetY + (this.maxHeight - height) / 2];
  }

  getIndexesForDevice(deviceId) {
    const { layers } = this.props;
    return getLayerIndex(layers, deviceId);
  }

  getDevicePoint(deviceId) {
    const { layers } = this.props;
    const { layerIndex, subLayerIndex, deviceIndex } = this.getIndexesForDevice(deviceId);

    if (layerIndex === -1) {
      return null;
    }

    const offset = this.getArcOffsetAt(layerIndex, subLayerIndex);
    const numDevices = layers[layerIndex].subLayers[subLayerIndex].devices.length;
    let point;

    if (this.isHoneycombLayout(layerIndex, subLayerIndex)) {
      const width = this.getMaxWidthAt(layerIndex, subLayerIndex);
      point = getHoneycombPoint({ width, index: deviceIndex, count: numDevices });
    } else {
      const width = this.getArcWidthAt(layerIndex, subLayerIndex);
      const arcHeight = this.getArcHeightAt(layerIndex, subLayerIndex);
      const t = numDevices <= 1 ? 0.5 : deviceIndex / (numDevices - 1);
      point = ArcLayout.getPointOnArc({ arcHeight, width }, t);
    }

    return { raw: point, offset, point: [offset[0] + point[0], offset[1] + point[1]] };
  }

  getLink(source, target, options) {
    const { linksMap, getLinkTraffic, hovered, selected, theme } = this.props;
    const { hoveredLink, selectedLink } = this.state;
    const { checkExistence } = options || {};
    const link = get(linksMap, `${source}.${target}`);

    if (link) {
      if (checkExistence) {
        return true;
      }

      const { isFiltered } = link;
      const isSelectedLink = selectedLink && selectedLink.source === source && selectedLink.target === target;
      const isHoveredLink = hoveredLink && hoveredLink.source === source && hoveredLink.target === target;
      const isSelected = isSelectedLink || source === selected || target === selected;
      const isHovered = isHoveredLink || source === hovered || target === hovered;
      let color = theme.name === 'dark' ? 'rgb(255,255,255,0.10)' : 'gray5';
      let opacity = 1;

      if ((hovered || hoveredLink) && !isHovered) {
        opacity = 0.5;

        if (isSelected) {
          opacity = 0.2;
        }
      }

      if (isHovered) {
        color = theme.name === 'dark' ? '#ffffff' : 'gray1';
      }

      if (isSelected) {
        color = 'primary';
      }

      const traffic = getLinkTraffic(link);
      const trafficData = {};

      if (traffic) {
        if (traffic.fromInterfaces[0].device_id === source) {
          trafficData.inTraffic = traffic.inTraffic;
          trafficData.outTraffic = traffic.outTraffic;
        } else {
          trafficData.inTraffic = traffic.outTraffic;
          trafficData.outTraffic = traffic.inTraffic;
        }

        if (link.snmp_speed) {
          trafficData.capacity = link.snmp_speed * 1000000;
        }

        trafficData.totalTraffic = trafficData.inTraffic + trafficData.outTraffic;
      }

      if (isFiltered) {
        color = 'hybrid.filtered';

        if (trafficData.totalTraffic === 0 && !isHovered) {
          opacity = 0.2;
        }
      }

      return { color, opacity, isSelected, isHovered, isFiltered, isSelectedLink, isHoveredLink, ...trafficData };
    }

    return null;
  }

  renderSeparators() {
    const { $auth, layers, onShowSiteDialog, paddingX, paddingY, width, hideLayerNames } = this.props;
    const drawLine = line();

    return (
      <g>
        {layers.map(({ subLayers }, layerIndex) =>
          subLayers.map(({ names, unassigned }, subLayerIndex) => {
            const [offsetX, offsetY] = this.getLayerOffsetAt(layerIndex, subLayerIndex);
            const key = names.join('-');

            const x = offsetX + paddingX / 2;
            const y = offsetY - paddingY;

            return (
              <g key={key}>
                <Path
                  id={`${key}-separator-path`}
                  className={classNames({ 'hybrid-section-divider': layerIndex > 0 && subLayerIndex === 0 })}
                  opacity={0.5}
                  d={drawLine([
                    [x, y],
                    [width - x, y]
                  ])}
                />
                {!hideLayerNames &&
                  names.map((name, nameIndex) => (
                    <Text key={name} dy={40} fontSize={14} fontWeight="bold" css={{ textTransform: 'uppercase' }}>
                      <textPath
                        href={`#${key}-separator-path`}
                        startOffset={nameIndex === 0 ? '0%' : '100%'}
                        textAnchor={nameIndex === 0 ? 'start' : 'end'}
                      >
                        {name}
                      </textPath>
                    </Text>
                  ))}
                {unassigned && $auth.isAdministrator && (
                  <Text x={x} y={y + 17} dy={40} fontSize={12} color="muted">
                    {/* eslint-disable-next-line */}
                    <a href="#" role="button" tabIndex={0} onClick={onShowSiteDialog}>
                      Configure Site
                    </a>
                    {layerIndex === 0 ? ' to setup architecture' : ' to organize these devices'}
                  </Text>
                )}
              </g>
            );
          })
        )}
      </g>
    );
  }

  renderDeviceLinks() {
    const { links } = this.props;
    const drawLink = line().curve(curveBasis);

    const sortLink = ({ isHovered, isSelected }) => {
      if (isHovered) {
        return 2;
      }

      if (isSelected) {
        return 1;
      }

      return 0;
    };

    return (
      <g>
        {links
          .map(
            ({
              device1_id,
              device2_id,
              connections,
              bundled_connections,
              layer3_connections,
              layer2_connections,
              linkLabel,
              isFiltered
            }) => ({
              device1_id,
              device2_id,
              connections,
              bundled_connections,
              layer3_connections,
              layer2_connections,
              linkLabel,
              isFiltered,
              ...this.getLink(device1_id, device2_id)
            })
          )
          .sort((a, b) => sortLink(a) - sortLink(b))
          .map((link) => {
            const {
              device1_id,
              device2_id,
              connections,
              isHovered,
              isSelected,
              isHoveredLink,
              isSelectedLink,
              color,
              opacity,
              inTraffic,
              outTraffic,
              totalTraffic,
              linkLabel,
              isFiltered,
              capacity
            } = link;

            const { layerIndex: layer1Index, subLayerIndex: subLayer1Index } = this.getIndexesForDevice(device1_id);
            const { layerIndex: layer2Index, subLayerIndex: subLayer2Index } = this.getIndexesForDevice(device2_id);

            // connected devices on the same subLayer are handled by the layout
            if (
              layer1Index === -1 ||
              layer2Index === -1 ||
              (layer1Index === layer2Index && subLayer1Index === subLayer2Index)
            ) {
              return null;
            }

            const device1Honeycomb = this.isHoneycombLayout(layer1Index, subLayer1Index);
            const device2Honeycomb = this.isHoneycombLayout(layer2Index, subLayer2Index);

            // honeycomb links only show on hover/select
            if ((device1Honeycomb || device2Honeycomb) && !isHovered && !isSelected) {
              return null;
            }

            const position1 = this.getDevicePoint(device1_id);
            const position2 = this.getDevicePoint(device2_id);

            if (!position1 || !position2) {
              return null;
            }

            let points = [];

            if (layer1Index === layer2Index) {
              points = [
                position1.point,
                [
                  (position1.point[0] + position2.point[0]) / 2,
                  Math.max(position1.point[1], position2.point[1]) + this.maxHeight * 0.75
                ],
                position2.point
              ];
            } else {
              points.push(position1.point);

              if (position1.point[1] < position2.point[1]) {
                points.push([position1.point[0], position1.point[1] + 40]);

                if (device2Honeycomb) {
                  points.push([position2.point[0], position2.point[1] - position2.raw[1] - 80]);
                } else {
                  points.push([position2.point[0], position2.point[1] - 40]);
                }
              } else {
                if (device1Honeycomb) {
                  points.push([position1.point[0], position1.point[1] - position1.raw[1] - 80]);
                } else {
                  points.push([position1.point[0], position1.point[1] - 40]);
                }

                points.push([position2.point[0], position2.point[1] + 40]);
              }

              points.push(position2.point);
            }

            const hasValidPoints = points
              .reduce((acc, coordinate) => acc.concat(coordinate), [])
              .every((axis) => !Number.isNaN(axis));

            if (hasValidPoints) {
              let trafficData = {};
              const pathData = drawLink(points);

              if (totalTraffic) {
                const [sourcePath, targetPath] = splitPath(pathData, { at: outTraffic / totalTraffic });
                const [trafficPath] = splitPath(pathData, { at: 1, margin: 0, minimumPiece: 0 });

                trafficData = {
                  sourcePath,
                  targetPath,
                  trafficPath,
                  sourceTraffic: getTrafficLabel(outTraffic, capacity),
                  targetTraffic: getTrafficLabel(inTraffic, capacity),
                  canRenderDirection:
                    sourcePath && sourcePath.canRenderDirection && targetPath && targetPath.canRenderDirection
                };
              }

              return (
                <g key={`${device1_id}-${device2_id}`}>
                  {trafficData.sourcePath && trafficData.targetPath ? (
                    <>
                      <Path
                        fill="none"
                        stroke={color}
                        strokeWidth={2}
                        opacity={opacity}
                        d={trafficData.sourcePath.pathData}
                        className={classNames(trafficData.sourcePath.textAlign, {
                          'animated-traffic': isFiltered
                        })}
                        id={`device-source-path-${device1_id}-${device2_id}`}
                      />
                      <Path
                        fill="none"
                        stroke={color}
                        strokeWidth={2}
                        opacity={opacity}
                        d={trafficData.targetPath.pathData}
                        className={classNames(trafficData.targetPath.textAlign, {
                          'animated-traffic': isFiltered
                        })}
                        id={`device-target-path-${device1_id}-${device2_id}`}
                      />
                      <Path fill={color} opacity={opacity} d={trafficData.sourcePath.arrowHead} />
                      <Path fill={color} opacity={opacity} d={trafficData.targetPath.arrowHead} />
                      <g opacity={isHoveredLink || isSelectedLink ? 1 : 0} pointerEvents="none">
                        {trafficData.canRenderDirection && (
                          <>
                            <Text dy={-5} fill="muted" fontSize={12}>
                              <textPath
                                href={`#device-source-path-${device1_id}-${device2_id}`}
                                startOffset={trafficData.sourcePath.textAlign === 'left' ? '0%' : '100%'}
                                textAnchor={trafficData.sourcePath.textAlign === 'left' ? 'start' : 'end'}
                              >
                                {trafficData.sourceTraffic}
                              </textPath>
                            </Text>
                            <Text dy={-5} fill="muted" fontSize={12}>
                              <textPath
                                href={`#device-target-path-${device1_id}-${device2_id}`}
                                startOffset={trafficData.targetPath.textAlign === 'left' ? '0%' : '100%'}
                                textAnchor={trafficData.targetPath.textAlign === 'left' ? 'start' : 'end'}
                              >
                                {trafficData.targetTraffic}
                              </textPath>
                            </Text>
                          </>
                        )}
                        {!trafficData.canRenderDirection && (
                          <>
                            <Path
                              fill="none"
                              stroke="none"
                              strokeWidth={2}
                              d={trafficData.trafficPath.pathData}
                              id={`device-traffic-path-${device1_id}-${device2_id}`}
                            />
                            <Text dy={-5} fill="muted" fontSize={12}>
                              <textPath
                                href={`#device-traffic-path-${device1_id}-${device2_id}`}
                                startOffset="50%"
                                textAnchor="middle"
                              >
                                {addTrafficLabelArrow(trafficData.sourceTraffic, trafficData.sourcePath.textAlign)}
                              </textPath>
                            </Text>
                            <Text dy={14} fill="muted" fontSize={12}>
                              <textPath
                                href={`#device-traffic-path-${device1_id}-${device2_id}`}
                                startOffset="50%"
                                textAnchor="middle"
                              >
                                {addTrafficLabelArrow(trafficData.targetTraffic, trafficData.targetPath.textAlign)}
                              </textPath>
                            </Text>
                          </>
                        )}
                      </g>
                    </>
                  ) : (
                    <Path fill="none" stroke={color} strokeWidth={2} opacity={opacity} d={pathData} />
                  )}
                  <Path
                    d={pathData}
                    fill="none"
                    stroke="transparent"
                    strokeWidth={10}
                    className="hybrid-map-selectable-link"
                    onMouseEnter={(e) => {
                      e.persist();
                      this.setState({ hoveredLink: { source: device1_id, target: device2_id } });
                    }}
                    onMouseLeave={(e) => {
                      e.persist();
                      this.setState({ hoveredLink: null });
                    }}
                    onClick={(e) => {
                      e.persist();
                      const { onLinkClick } = this.props;

                      if (onLinkClick) {
                        const pathBox = e.target.getBoundingClientRect();
                        const offsetX = e.clientX - pathBox.x;
                        const offsetY = e.clientY - pathBox.y;

                        onLinkClick({
                          connections,
                          link,
                          linkLabel,
                          offset: {
                            left: offsetX,
                            top: offsetY
                          }
                        });

                        this.setState({ selectedLink: { source: device1_id, target: device2_id } });
                      }
                    }}
                    css={{ cursor: 'pointer' }}
                  />
                </g>
              );
            }

            return null;
          })}
      </g>
    );
  }

  handleHoverDevice = (device) => {
    const { onHover } = this.props;

    if (onHover) {
      onHover(device ? device.id : null);
    }
  };

  handleSelectDevice = (device) => {
    const { onSelect } = this.props;

    if (onSelect) {
      if (device) {
        onSelect(device.id, { health: device.health });
      } else {
        onSelect(null);
      }
    }
  };

  handleArcLinkClick = ({ source, target, offset }) => {
    const { links, onLinkClick } = this.props;
    const link = links.find(
      (item) =>
        (item.device1_id === source && item.device2_id === target) ||
        (item.device1_id === target && item.device2_id === source)
    );

    if (link && onLinkClick) {
      onLinkClick({
        connections: link.connections,
        linkLabel: link.linkLabel,
        link,
        offset
      });
    }
  };

  getArcItemRenderer = ({ item, props, itemIndex, layers, layerIndex, deviceIds }) => {
    const { hideDeviceNames } = this.props;
    return (
      <ArcLayoutItemGroup {...props} key={item.id}>
        <Circle
          className={classNames(
            `layer-${layerIndex}`,
            `device-${item.id}`,
            `device-${item.name.replace(/\W/, '')}`,
            item.health && item.health.cssClassName
          )}
        />
        <ArcLayoutIcon point={item.point} type="router" iconSize={20} />
        {!hideDeviceNames && (
          <Text
            fill="body"
            className={classNames({
              topleft: layerIndex < layers.length - 1 && itemIndex < (deviceIds.length - 1) / 2,
              topright: layerIndex < layers.length - 1 && itemIndex >= (deviceIds.length - 1) / 2,
              bottom: layerIndex === layers.length - 1
            })}
          >
            {item.name}
          </Text>
        )}
        {item.health && <circle className={classNames('health-indicator', item.health.cssClassName)} />}
      </ArcLayoutItemGroup>
    );
  };

  render() {
    const { devices, layers, paddingY, width, hovered, selected, highlighted, isPopoverOpen } = this.props;
    const height = paddingY + (this.maxHeight + paddingY * 2) * layers.length;

    return (
      <svg width={width} height={height}>
        {this.renderSeparators()}
        {this.renderDeviceLinks()}
        {layers.map(({ subLayers }, layerIndex) =>
          subLayers.map(({ names, devices: deviceIds }, subLayerIndex) => {
            const key = names.join('-');

            return (
              <g key={key} transform={`translate(${this.getArcOffsetAt(layerIndex, subLayerIndex).join(' ')})`}>
                {this.isHoneycombLayout(layerIndex, subLayerIndex) ? (
                  <HoneycombLayout
                    className={`layer-${layerIndex}`}
                    width={this.getMaxWidthAt(layerIndex, subLayerIndex)}
                    items={deviceIds.map((id) => devices[id])}
                    hovered={devices[hovered]}
                    selected={devices[selected]}
                    highlighted={highlighted.map((id) => devices[id])}
                    getLink={(a, b, options) => this.getLink(a.id, b.id, options)}
                    onHover={this.handleHoverDevice}
                    onSelect={this.handleSelectDevice}
                  />
                ) : (
                  <ArcLayout
                    width={this.getArcWidthAt(layerIndex, subLayerIndex)}
                    arcHeight={this.getArcHeightAt(layerIndex, subLayerIndex)}
                    strokeWidth={2}
                    items={deviceIds.filter((id) => devices[id]).map((id) => devices[id])}
                    hovered={devices[hovered]}
                    selected={devices[selected]}
                    highlighted={highlighted.map((id) => devices[id])}
                    itemRenderer={(item, props, itemIndex) =>
                      this.getArcItemRenderer({ item, props, itemIndex, layers, layerIndex, deviceIds })
                    }
                    getLink={(a, b, options) => this.getLink(a.id, b.id, options)}
                    onHover={this.handleHoverDevice}
                    onSelect={this.handleSelectDevice}
                    onLinkClick={this.handleArcLinkClick}
                    isPopoverOpen={isPopoverOpen}
                  />
                )}
              </g>
            );
          })
        )}
      </svg>
    );
  }
}
