import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import AdminFilterSidebar from 'app/components/admin/AdminFilterSidebar';

@inject('$notifications')
@observer
export default class NotificationsFilterSidebar extends Component {
  get filterFields() {
    const { $notifications } = this.props;
    return [
      {
        name: 'status',
        label: 'Status',
        allowMultiSelect: true,
        showCheckboxes: true,
        options: [
          { label: 'Enabled', value: 'enabled' },
          { label: 'Disabled', value: 'disabled' }
        ],
        filter: (model, values) => {
          const status = model.get('status');
          return values.includes(status);
        }
      },
      {
        name: 'channelType',
        options: $notifications.supportedChannels.channelOptions,
        label: 'Type',
        allowMultiSelect: true,
        showCheckboxes: true,
        filter: (model, values) => {
          const type = model.get('channelType');
          return values.includes(type);
        }
      }
    ];
  }

  render() {
    return <AdminFilterSidebar {...this.props} filterFields={this.filterFields} />;
  }
}
