import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Heading, Icon, Text } from 'core/components';
import { ReactComponent as CheckboxIcon } from 'app/assets/onboarding/onboarding-checkbox.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'app/assets/onboarding/onboarding-checkbox-checked.svg';

const Checkbox = styled(Box)`
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
  max-width: 750px;
  display: flex;
  align-items: flex-start;
  padding: 16px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.12);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  &:focus {
    outline: 1px dotted #ffffff;
    outline-offset: 4px;
  }
`;

class OnboardingCheckbox extends Component {
  state = {
    checked: undefined,
    checkedInitial: undefined
  };

  static getDerivedStateFromProps(props, state) {
    const { checked } = props;

    if (checked !== state.checkedInitial) {
      return { checked, checkedInitial: checked };
    }

    return null;
  }

  handleCheck = () => {
    const { onChange } = this.props;
    const { checked } = this.state;

    this.setState({ checked: !checked }, () => onChange(!checked));
  };

  render() {
    const { title, description, icon, children, showChildren, checked: checkedProp, onChange, ...rest } = this.props;
    const { checked } = this.state;

    return (
      <Checkbox onClick={this.handleCheck} tabIndex={0} {...rest}>
        <Icon icon={icon} iconSize={48} />
        <Box flex={1} mx={2}>
          <Heading level={4} color="#ffffff" mb="4px" fontWeight="heavy">
            {title}
          </Heading>
          <Text color="white.7">{description}</Text>
          {showChildren && children}
        </Box>
        <Icon icon={checked ? CheckboxCheckedIcon : CheckboxIcon} iconSize={20} />
      </Checkbox>
    );
  }
}

export default OnboardingCheckbox;
