import * as React from 'react';
import { inject, observer } from 'mobx-react';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import { Box, Button, EmptyState } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import SearchableTable from 'app/views/hybrid/maps/components/SearchableTable';

@inject('$hybridMap')
@observer
export default class VNetGatewayBGPPeerStatus extends React.Component {
  state = {
    loading: true,
    isPoppedOut: false,
    data: null
  };

  componentDidUpdate(prevProps, prevState) {
    const { nodeData } = this.props;
    const { data, isPoppedOut } = this.state;
    const popOutChanged = prevState.isPoppedOut !== isPoppedOut;

    if (prevProps.nodeData?.id !== nodeData?.id || (data === null && popOutChanged)) {
      // if we have the sidebar open and switch to a new load balancer or change the time range, fetch for new data
      this.fetchStatus();
    }
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.cancel();
    }
  }

  fetchStatus = () => {
    const { $hybridMap, nodeData } = this.props;

    this.setState({ loading: true }, () => {
      this.request = makeCancelable($hybridMap.getVNetGatewayBGPPeerStatus({ id: nodeData.id }));

      this.request.promise
        .then((response) => {
          this.setState({ loading: false, data: response });
        })
        .catch((e) => {
          if (e instanceof CanceledError === false) {
            this.setState({ loading: false }, () => console.error(e));
          }
        });
    });
  };

  handleExpandChange = (isOpenOrPoppedOut) => {
    const { data } = this.state;

    if (isOpenOrPoppedOut && data === null) {
      // fetch for data when expanding for the first time
      this.fetchStatus();
    }
  };

  handlePopOutChange = (isPoppedOut) => {
    this.setState({ isPoppedOut }, this.handleExpandChange);
  };

  handlePanelRefresh = (e) => {
    e.stopPropagation();
    this.fetchStatus();
  };

  get emptyState() {
    const { cloudProvider } = this.props;
    return (
      <EmptyState
        title="No Results Found"
        icon={<CloudIcon cloudProvider={cloudProvider} entity="bgpPeer" iconSize={48} />}
      />
    );
  }

  get columns() {
    const { isPoppedOut } = this.state;
    const minimizedColumns = [
      {
        name: 'neighbor',
        label: 'Peer Address'
      },

      {
        name: 'localAddress',
        label: 'Local Address'
      },

      {
        name: 'asn',
        label: 'Asn'
      },

      {
        name: 'state',
        label: 'Status'
      },

      {
        name: 'routesReceived',
        label: 'Routes Received'
      }
    ];

    if (isPoppedOut) {
      return minimizedColumns.concat(
        {
          name: 'connectedDuration',
          label: 'Connected Duration'
        },

        {
          name: 'messagesSent',
          label: 'Messages Sent'
        },

        {
          name: 'messagesReceived',
          label: 'Messages Received'
        }
      );
    }

    return minimizedColumns;
  }

  render() {
    const { cloudProvider, popoutTitle } = this.props;
    const { loading, data } = this.state;

    return (
      <SidebarItem
        excludeFormProps
        title="BGP Peer Status"
        dialogProps={{ width: 1300 }}
        popoutTitle={popoutTitle}
        filledIconTag={false}
        icon={<CloudIcon cloudProvider={cloudProvider} entity="bgpPeer" style={{ marginTop: '3px' }} />}
        navigationButtons={<Button icon="refresh" color="success" onClick={this.handlePanelRefresh} small minimal />}
        onExpandChange={this.handleExpandChange}
        onPopOutChange={this.handlePopOutChange}
      >
        <Box>
          {this.popOutHeader}
          <SearchableTable
            collectionOptions={{ sortState: { field: 'name', direction: 'asc' } }}
            height={240}
            loading={loading}
            columns={this.columns}
            data={data || []}
            emptyState={this.emptyState}
            stickyHeader
          />
        </Box>
      </SidebarItem>
    );
  }
}
