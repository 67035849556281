import { Address4, Address6 } from 'ip-address';

export function isIpV4Valid(ip, options = {}) {
  const { returnAddress = false } = options;
  let ipAddress;
  try {
    ipAddress = new Address4(ip.trim());
    const isCorrect = ipAddress.isCorrect();
    return returnAddress ? isCorrect && ipAddress : isCorrect;
  } catch (e) {
    return returnAddress ? null : false;
  }
}
export function isIpV6Valid(ip, options = {}) {
  const { returnAddress = false } = options;
  let ipAddress;
  try {
    ipAddress = new Address6(ip.trim());
    // ip-address v6 is very strict with their check for correctness
    const isCorrect = !!ipAddress;
    return returnAddress ? isCorrect && ipAddress : isCorrect;
  } catch (e) {
    return returnAddress ? null : false;
  }
}

export function isIpValid(ip, options = {}) {
  const { returnAddress = false } = options;
  return isIpV4Valid(ip, { returnAddress }) || isIpV6Valid(ip, { returnAddress });
}

export function toIp(ipAddr) {
  if (ipAddr instanceof Address4 || ipAddr instanceof Address6) {
    return ipAddr;
  }

  return isIpValid(ipAddr, { returnAddress: true });
}

export function isInSubnet(ipAddr, subnet) {
  return toIp(ipAddr)?.isInSubnet(toIp(subnet));
}

export const toCIDRNotation = (ipAddr, netmaskAddr) => {
  const ip = isIpValid(ipAddr, { returnAddress: true });

  if (!ip) {
    return '';
  }

  const mask = isIpValid(netmaskAddr, { returnAddress: true });

  const cidr = mask ? `${mask.binaryZeroPad()}0`.indexOf('0') : ip.subnetMask;

  return `${ip.correctForm()}/${cidr}`;
};

export function parseDimensionIp(ip) {
  if (typeof ip === 'string') {
    return ip.split(' ')[0];
  }

  return ip;
}
