const moment = require('moment');

/**
 * @param {object} q kmetrics query
 * @returns {number} query duration in seconds
 */
function getQueryDuration(q) {
  if ('lookback' in q.range) {
    return moment.duration(q.range.lookback).asSeconds();
  }

  return moment.utc(q.range.end).diff(moment.utc(q.range.start), 'seconds');
}

/**
 * @param {number} duration duration in seconds
 * @returns {number} window size in seconds
 */
function getBucketSeconds(duration) {
  const buckets = [
    60,
    5 * 60,
    10 * 60,
    20 * 60,
    60 * 60,
    2 * 60 * 60,
    3 * 60 * 60,
    4 * 60 * 60,
    6 * 60 * 60,
    8 * 60 * 60,
    12 * 60 * 60,
    24 * 60 * 60
  ];

  const fallback = buckets[buckets.length - 1];

  // Try to return a bucket size that will generate up to 288 buckets, using nice round durations
  // 288 gives us 5 minute intervals at 1 day
  return buckets.find((bucket) => duration / bucket <= 288) || fallback;
}

/**
 * @param {object} q kmetrics query
 * @returns {number} window size in seconds
 */
function getQueryBucketSeconds(q) {
  if (q.window && q.window.size) {
    return q.window.size - (q.window.size % 60) || 1;
  }

  const duration = getQueryDuration(q);
  return getBucketSeconds(duration);
}

module.exports = { getQueryDuration, getBucketSeconds, getQueryBucketSeconds };
