import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Intent } from '@blueprintjs/core';
import Button from 'core/components/Button';

const NewButton = (props) => {
  const { collection, disabled, entityName, history, icon, onClick, rootUrl, showIcon, style, text, intent, ...rest } =
    props;

  const defaultOnClick = () => {
    collection.forge();
    if (rootUrl) {
      history.push(`${rootUrl}/new`);
    }
  };

  return (
    <Button
      disabled={disabled}
      icon={showIcon ? 'plus' : icon}
      intent={intent || Intent.PRIMARY}
      onClick={onClick || defaultOnClick}
      style={style}
      text={text || `Add ${entityName}`}
      {...rest}
    />
  );
};

export default withRouter(observer(NewButton));
