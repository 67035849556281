import { get } from 'lodash';
/**
 * requiredIfMultiple rules should be specified as follows:
 *
 * [{ requiredIfMultiple: ['anotherField', val1, 'anotherField2', val2, ...] }]
 *
 * There must be key/value pairs and these will be AND'd.
 * If you want OR's, use multiple required_if.
 */
function requiredIfMultiple(value, req) {
  if (value) {
    return true;
  }

  let required = true;
  for (let i = 0; i < req.length; i += 2) {
    if (get(this.validator.input, req[i]) !== req[i + 1]) {
      required = false;
      break;
    }
  }

  return !required || value;
}

export default {
  message: ':attribute is required',
  fn: requiredIfMultiple
};
