import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { AiOutlineLineChart, AiOutlineTable } from 'react-icons/ai';
import { FiBarChart2, FiClock, FiDatabase, FiFilter } from 'react-icons/fi';
import { someFilters } from '@kentik/ui-shared/util/filters';
import { Flex, Button, Icon, Text, FlexColumn } from 'core/components';
import { Checkbox, Select, Field, FormGroup, formConsumer, InputGroup, Switch } from 'core/form';
import { getFilterCount } from 'core/util/filters';
import SavedViewOptions from 'app/views/core/explorer/sidebar/SavedViewOptions';
import SidebarSection from 'app/views/core/explorer/sidebar/SidebarSection';
import TimeOptions from 'app/views/core/explorer/sidebar/TimeOptions';
import SidebarFilterOptions from 'app/views/core/explorer/sidebar/FilterOptions';
import {
  aggFuncsOptions,
  mergeAggFuncsOptions,
  metricOperatorOptions,
  limitCountOptions,
  rollupAggFuncsOptions,
  transformationOptions,
  windowSizeOptions
} from './utils/sidebarOptions';

export const MetricOrDimensionOptionRenderer = (option) => {
  const { label, value, selectItem, className, unit } = option;

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      onClick={() => selectItem(value, option)}
      key={value}
      className={className}
    >
      <Text>{label}</Text>
      <Text small muted>
        {unit}
      </Text>
    </Flex>
  );
};

@formConsumer
@withRouter
@inject('$app', '$auth', '$metrics', '$savedViews')
@observer
class MetricsExplorerSidebar extends Component {
  static defaultProps = {
    // array of rollup functions to allow, overrides defaults
    rollupAggFuncsOptionsOverride: undefined,
    // when running in integrated mode, we're not doing a page / sidebar format and layout but rather
    // just using the innards of the explorer as a query builder / form, basically
    integratedMode: false,
    // some integratedMode situations only care about specific parts of the form
    showVizOptions: true,
    showTimeOptions: true,
    showMetricFilterOptions: true,
    showDimensionGrouping: true,
    showMergeDimensions: true,
    showRollupOptions: true,
    // toggle allowing multiple aggregate types over the same metrics / dimensions
    allowMultiAggregate: true,
    isSingleMetric: false,
    onChange: undefined,
    width: 575
  };

  state = {
    isEditingFilters: false,
    isEditingMetricFilters: false
  };

  componentDidMount() {
    const { form } = this.props;

    form.onChange = this.handleFormChange;

    this.checkSingleMetric();
  }

  checkSingleMetric = () => {
    const { form, isSingleMetric } = this.props;

    if (isSingleMetric) {
      const metricField = form.getField('selectedMetrics');
      metricField.transform = {
        in: (value) => {
          if (value) {
            if (Array.isArray(value)) {
              return value.join();
            }
            return value;
          }
          return '';
        },
        out: (value) => {
          if (value) {
            if (Array.isArray(value)) {
              return value;
            }
            return [value];
          }
          return [];
        }
      };
    }
  };

  handleFormChange = ({ form, formValues }) => {
    const { onChange } = this.props;
    const { isEditingFilters } = this.state;

    if (!isEditingFilters && onChange) {
      onChange({ form, formValues });
    }
  };

  handleEditFilters = () => {
    const { form } = this.props;
    const { isEditingFilters } = this.state;
    this.setState({ isEditingFilters: !isEditingFilters }, () => {
      this.handleFormChange({ form, formValues: form.getValues() });
    });
  };

  handleEditMetricFilters = () => {
    const { form } = this.props;
    const { isEditingMetricFilters } = this.state;
    this.setState({ isEditingMetricFilters: !isEditingMetricFilters }, () => {
      this.handleFormChange({ form, formValues: form.getValues() });
    });
  };

  handleSelectedMeasurementChange = () => {
    const { form } = this.props;

    form.getField('selectedMetrics').setToDefault({ validate: false, pristine: false });
    form.getField('selectedDimensions').setToDefault({ validate: false, pristine: false });
    form.getField('selectedMergeDimensions').setToDefault({ validate: false, pristine: false });
    form.getField('filters').setToDefault({ validate: false, pristine: false });
    form.getField('rollupFilters').setToDefault({ validate: false, pristine: false });
    form.getField('selectedVisualizationRollup').setToDefault({ validate: false, pristine: false });
    form.getField('selectedVisualizationMetric').setToDefault({ validate: false, pristine: false });

    form.validate();
  };

  handleMetricsChange = () => {
    this.updateVizRollupMetric();
  };

  handleVizChange = () => {
    this.updateVizRollupMetric();
  };

  handleSave = (values) => {
    const { $metrics, history, savedViewModel, query } = this.props;

    $metrics.queryToHash(query, { persist: true }).then((saved_query_id) => {
      savedViewModel.save({ ...values, saved_query_id }).then(() => {
        history.push(savedViewModel.navigatePath);
      });
    });
  };

  updateVizRollupMetric = () => {
    const { form } = this.props;
    const rollupField = form.getField('selectedVisualizationRollup');
    const metricField = form.getField('selectedVisualizationMetric');

    if (this.vizRollupOptions.length > 0) {
      if (!rollupField.getValue() || !this.vizRollupOptions.some((opt) => opt.value === rollupField.getValue())) {
        rollupField.setValue(this.vizRollupOptions[0].value);
      }
    } else {
      rollupField.reset();
    }

    if (this.vizMetricOptions.length > 0) {
      if (!metricField.getValue() || !this.vizMetricOptions.some((opt) => opt.value === metricField.getValue())) {
        metricField.setValue(this.vizMetricOptions[0].value);
      }
    } else {
      metricField.reset();
    }
  };

  handleMergeDimensionsChange = (field, value) => {
    const { form } = this.props;

    // when you select a merge dimension, it needs to be removed from selectedDimensions
    // and added to selectedMergeDimensions
    const selectedDimensions = form.getValue('selectedDimensions');
    const newSelectedDimensions = selectedDimensions.filter((dimension) => !value.includes(dimension));
    form.setValue('selectedDimensions', newSelectedDimensions);
  };

  metricLabel = (metric) => {
    const { form, $metrics } = this.props;
    const measurement = form.getValue('selectedMeasurement');
    const fullSelectedMeasurement = $metrics.measurementModelById(measurement);
    const meta = fullSelectedMeasurement?.get(`storage.Metrics.${metric}`);
    return meta?.Label ? meta.Label : metric;
  };

  get vizRollupOptions() {
    const { form } = this.props;
    const selectedMetrics = form.getValue('selectedMetrics');
    if (form && selectedMetrics) {
      const vizRollupOptions = [];
      if (!selectedMetrics) {
        return [];
      }
      const selectedRollupsAggFunc = form.getValue('selectedRollupsAggFunc');
      selectedMetrics.forEach((metric) => {
        selectedRollupsAggFunc.forEach((aggFunc) => {
          vizRollupOptions.push({
            label: `(${aggFunc}) ${this.metricLabel(metric)}`,
            value: `${aggFunc}_${metric}`
          });
        });
      });
      return vizRollupOptions;
    }
    return [];
  }

  @computed
  get availableDimensionFilterOptions() {
    const { form, $metrics } = this.props;
    const selectedMeasurement = form.getValue('selectedMeasurement');
    const filters = form.getValue('filters');
    const filterOptions = $metrics.getAvailableDimensionOptions(selectedMeasurement);

    if (someFilters(filters, ({ filterField }) => filterField === 'km_device_id')) {
      filterOptions.push({ label: 'Device ID', value: 'km_device_id' });
    }

    filterOptions.push(
      { label: 'Device Labels', value: 'i_device_label' },
      { label: 'Interface Group', value: 'i_output_interface_group' }
    );

    return filterOptions.sort((a, b) => a.label.localeCompare(b.label));
  }

  @computed
  get availableMetricOptions() {
    const { form, $metrics } = this.props;
    const selectedMeasurement = form.getValue('selectedMeasurement');
    return $metrics.getAvailableMetricOptions(selectedMeasurement);
  }

  @computed
  get metricFilterOptions() {
    const { form, $metrics } = this.props;
    const metrics = form.getValue('selectedMetrics');
    const rollups = form.getValue('selectedRollupsAggFunc');
    const measurement = form.getValue('selectedMeasurement');
    return $metrics.metricFilterOptions(measurement, rollups, metrics);
  }

  @computed
  get availableDimensionOptions() {
    const { form, $metrics } = this.props;
    const selectedMeasurement = form.getValue('selectedMeasurement');
    return $metrics.getAvailableDimensionOptions(selectedMeasurement);
  }

  get dimensionOptions() {
    // the available dimensions get passed in as a part of the measurement
    // but we need to filter out any dimensions that are chosen for Merge Dimensions
    const { form } = this.props;

    return this.availableDimensionOptions.filter((dimension) => {
      const selectedMergeDimensions = form.getValue('selectedMergeDimensions');
      return !selectedMergeDimensions.includes(dimension.value);
    });
  }

  get vizMetricOptions() {
    const { form } = this.props;
    const selectedMetrics = form.getValue('selectedMetrics');
    if (form && selectedMetrics) {
      return selectedMetrics.map((metric) => ({ label: this.metricLabel(metric), value: metric }));
    }
    return [];
  }

  get vizIsRollup() {
    const { form } = this.props;
    const selectedVisualization = form.getValue('selectedVisualization');
    return !(selectedVisualization === 'line' || selectedVisualization === 'area');
  }

  render() {
    const {
      $app,
      $auth,
      isLoading,
      integratedMode,
      showVizOptions,
      showTimeOptions,
      showMetricFilterOptions,
      showMergeSeriesOptions,
      showRollupOptions,
      showDimensionGrouping,
      allowMultiAggregate,
      rollupAggFuncsOptionsOverride,
      $metrics,
      form,
      onForceRunQuery,
      onControlledByViewChange,
      isSingleMetric,
      savedViewModel,
      width
    } = this.props;

    if (isLoading) {
      return null;
    }

    const { isEditingFilters, isEditingMetricFilters } = this.state;
    const selectedMeasurement = form.getValue('selectedMeasurement');
    const selectedVisualization = form.getValue('selectedVisualization');
    const enableTimeSeries = selectedVisualization !== 'table';

    const dimensionFilterCount = getFilterCount(form.getValue('filters.filterGroups'));
    const metricFilterCount = getFilterCount(form.getValue('rollupFilters.filterGroups'));
    const filterCount = dimensionFilterCount + metricFilterCount;

    // this is copied from Data Explorer
    const maxDateRange = $app.isSubtenant && $auth.getActiveUserProperty('userGroup.config.max_date_range');
    const isTimeLocked = onControlledByViewChange ? form.getValue('time_locked') : false;

    const sharedSectionProps = {
      defaultIsOpen: true,
      showSectionWarnings: false
    };

    return (
      <FlexColumn width={width} height="100%">
        {savedViewModel && !$app.isSubtenant && (
          <SidebarSection label="Saved View" icon={AiOutlineLineChart} {...sharedSectionProps}>
            <SavedViewOptions onSave={this.handleSave} onRevert={null} savedView={savedViewModel} />
          </SidebarSection>
        )}

        {!$app.isSubtenant && (
          <SidebarSection
            fieldGroup="measurement"
            label="Measurement"
            sectionName="me.measurement"
            iconProps={{ icon: FiDatabase }}
            {...sharedSectionProps}
          >
            <Field
              name="selectedMeasurement"
              options={$metrics.availableMeasurementOptions}
              showLabel={false}
              beforeOnChange={this.handleSelectedMeasurementChange}
              large
            >
              <Select fill showFilter />
            </Field>

            {selectedMeasurement && (
              <>
                <Field
                  name="selectedMetrics"
                  options={this.availableMetricOptions}
                  onChange={this.handleMetricsChange}
                  large
                >
                  <Select
                    multi={!isSingleMetric}
                    showFilter
                    filterPlaceholder="Filters metrics..."
                    optionRenderer={MetricOrDimensionOptionRenderer}
                    menuWidth={300}
                    width="100%"
                    className="helix"
                    valueTagProps={{
                      intent: 'success',
                      className: 'bp4-large',
                      style: { fontSize: 13, fontWeight: 500 }
                    }}
                  />
                </Field>

                {showDimensionGrouping && (
                  <Field name="selectedDimensions" options={this.dimensionOptions} large>
                    <Select
                      multi
                      showFilter
                      filterPlaceholder="Filters dimensions..."
                      optionRenderer={MetricOrDimensionOptionRenderer}
                      menuWidth={300}
                      width="100%"
                      className="helix"
                      valueTagProps={{
                        intent: 'primary',
                        className: 'bp4-large',
                        style: { fontSize: 13, fontWeight: 500 }
                      }}
                      showSelectAll
                    />
                  </Field>
                )}

                {showMergeSeriesOptions && (
                  <Field
                    name="selectedMergeDimensions"
                    options={this.availableDimensionOptions}
                    large
                    onChange={this.handleMergeDimensionsChange}
                    mb={0}
                  >
                    <Select
                      multi
                      showFilter
                      filterPlaceholder="Filters dimensions..."
                      optionRenderer={MetricOrDimensionOptionRenderer}
                      menuWidth={300}
                      width="100%"
                      className="helix"
                      valueTagProps={{
                        intent: 'primary',
                        className: 'bp4-large',
                        style: { fontSize: 13, fontWeight: 500 }
                      }}
                    />
                  </Field>
                )}

                {form.getValue('selectedMergeDimensions').length !== 0 && (
                  <Flex ml={2} gap={1} mt="12px">
                    <Icon icon="nest" color="muted" iconSize={20} style={{ position: 'relative', top: -5 }} />
                    <Field name="selectedMergeAggregation" options={mergeAggFuncsOptions} mb={0} large>
                      <Select width={145} menuWidth={145} />
                    </Field>
                  </Flex>
                )}
              </>
            )}
          </SidebarSection>
        )}

        {/* When running in integrated mode, time controls and visualization are irrelevant to the context */}
        {showVizOptions && selectedMeasurement && !$app.isSubtenant && (
          <SidebarSection
            fieldGroup="visualization"
            label="Visualization Options"
            sectionName="me.visualization"
            iconProps={{ icon: FiBarChart2 }}
            {...sharedSectionProps}
          >
            <Flex flexDirection="row" flexWrap="wrap" alignItems="center" gap={2} width="100%" mb={2}>
              <Field
                name="selectedVisualization"
                options={$metrics.vizTypeOptions()}
                onChange={this.handleVizChange}
                showLabel
                label="Visualization"
                large
                fill
                flex={1}
                m={0}
              >
                <Select fill />
              </Field>
              {this.vizIsRollup ? (
                <Field
                  name="selectedVisualizationRollup"
                  options={this.vizRollupOptions}
                  disabled={!enableTimeSeries}
                  showLabel
                  label="Metric"
                  large
                  fill
                  flex={1}
                  m={0}
                >
                  <Select fill />
                </Field>
              ) : (
                <Field
                  name="selectedVisualizationMetric"
                  options={this.vizMetricOptions}
                  disabled={!enableTimeSeries}
                  showLabel
                  label="Metric"
                  large
                  fill
                  flex={1}
                  m={0}
                >
                  <Select fill />
                </Field>
              )}
            </Flex>

            <Flex flexDirection="row" flexWrap="wrap" alignItems="center" gap={2} width="100%">
              <Field
                name="selectedAggFunc"
                options={aggFuncsOptions}
                disabled={!enableTimeSeries}
                large
                fill
                m={0}
                flex={1}
              >
                <Select fill menuWidth={200} />
              </Field>
              <Field
                name="selectedWindowSize"
                options={windowSizeOptions}
                disabled={!enableTimeSeries}
                large
                fill
                m={0}
                flex={1}
              >
                <Select fill menuWidth={200} />
              </Field>
              <Field
                name="selectedLimitCount"
                options={limitCountOptions}
                disabled={!enableTimeSeries}
                large
                fill
                m={0}
                flex={1}
              >
                <Select fill menuWidth={40} />
              </Field>
              <Field
                name="selectedTransformation"
                options={transformationOptions}
                disabled={!enableTimeSeries}
                large
                fill
                m={0}
                flex={1}
              >
                <Select fill menuWidth={250} />
              </Field>
            </Flex>
          </SidebarSection>
        )}

        {selectedMeasurement && (
          <>
            {/* When running in integrated mode, time controls are irrelevant to the context */}
            {showTimeOptions && (
              <SidebarSection
                fieldGroup="time"
                label="Time"
                sectionName="me.time"
                iconProps={{ icon: FiClock }}
                {...sharedSectionProps}
              >
                {onControlledByViewChange && (
                  <Field name="time_locked" showLabel={false} onChange={onControlledByViewChange} large>
                    <Switch switchLabel="Controlled by Dashboard" />
                  </Field>
                )}
                <TimeOptions
                  disabled={onControlledByViewChange && !isTimeLocked}
                  showAwsTimezoneSelector={false}
                  periodOverPeriodDisabled
                  maxDateRange={maxDateRange}
                  large
                />
              </SidebarSection>
            )}

            {!$app.isSubtenant && (
              <SidebarSection
                fieldGroup="filtering"
                sectionName="me.filtering"
                label="Filters"
                showSectionWarnings={false}
                iconProps={{ icon: FiFilter }}
                labelTag={filterCount > 0 ? filterCount : 'No Filters'}
                labelTagIntent={filterCount > 0 ? 'primary' : undefined}
              >
                <FormGroup label="Dimension Filters" mb={2}>
                  <SidebarFilterOptions
                    readOnly={false}
                    isOpen={isEditingFilters}
                    onClose={this.handleEditFilters}
                    allowNestedFilters={false}
                    allowSavedFilters={false}
                    showEmptyText={false}
                    showSaveFiltersButton={false}
                    useNmsDimensions={this.availableDimensionFilterOptions || this.availableDimensionOptions}
                    dontCloseOnRemove
                  />
                  <Button
                    onClick={this.handleEditFilters}
                    text={dimensionFilterCount > 0 ? 'Edit Dimension Filters' : 'Add Dimension Filters'}
                    icon={dimensionFilterCount > 0 ? 'edit' : 'plus'}
                    mt={dimensionFilterCount > 0 ? 1 : 0}
                  />
                </FormGroup>
                {showMetricFilterOptions && (
                  <FormGroup label="Metric Filters" mb={0}>
                    <SidebarFilterOptions
                      fieldName="rollupFilters"
                      readOnly={false}
                      isOpen={isEditingMetricFilters}
                      onClose={this.handleEditMetricFilters}
                      operatorOptionsOverride={metricOperatorOptions}
                      allowNestedFilters={false}
                      allowSavedFilters={false}
                      showEmptyText={false}
                      showSaveFiltersButton={false}
                      useNmsDimensions={this.metricFilterOptions}
                      dontCloseOnRemove
                    />
                    <Button
                      onClick={this.handleEditMetricFilters}
                      text={metricFilterCount > 0 ? 'Edit Metric Filters' : 'Add Metric Filters'}
                      icon={metricFilterCount > 0 ? 'edit' : 'plus'}
                      mt={metricFilterCount > 0 ? 1 : 0}
                    />
                  </FormGroup>
                )}
              </SidebarSection>
            )}

            {showRollupOptions && !$app.isSubtenant && (
              <SidebarSection
                fieldGroup="rollups"
                label="Table Options"
                sectionName="me.rollups"
                iconProps={{ icon: AiOutlineTable }}
                showSectionWarnings={false}
              >
                <Flex flexDirection="row" flexWrap="wrap" alignItems="center" gap="16px" width="100%">
                  <Field
                    name="selectedRollupsAggFunc"
                    options={rollupAggFuncsOptionsOverride || rollupAggFuncsOptions}
                    large
                    fill
                    m={0}
                    flex={2}
                  >
                    <Select fill multi={allowMultiAggregate} />
                  </Field>
                  <Field name="selectedRollupsLimit" options={limitCountOptions} large fill m={0} flex={1}>
                    <Select fill />
                  </Field>
                </Flex>
              </SidebarSection>
            )}

            {!integratedMode && (
              <Flex alignItems="center" flexDirection="column" p="12px" gap="12px">
                <Button text="Run Query" fill large intent="primary" onClick={onForceRunQuery} disabled={!form.valid} />
                {($auth.hasSudo || $auth.isSpoofed) && !$app.isSubtenant && (
                  <Flex flexDirection="column" alignItems="flex-start" width="100%" mt={1}>
                    <Field name="streamingUpdate" component={Checkbox} large showLabel={false} />
                    <Field name="update_frequency">
                      <InputGroup />
                    </Field>
                  </Flex>
                )}
              </Flex>
            )}
          </>
        )}
      </FlexColumn>
    );
  }
}

export default MetricsExplorerSidebar;
