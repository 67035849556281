import React from 'react';

import { inject } from 'mobx-react';
import { Flex, Text } from 'core/components';

import SidebarItem from '../../SidebarItem';
import CloudMetadata from '../../CloudMetadata';
import { AzurePeeringDetails } from '.';

const AzureExpressRouteConnectionDetails = ({ width, popoutTitle, $hybridMap, nodeData }) => {
  const { azureCloudMapCollection } = $hybridMap;

  const expressRouteCircuitPeering = azureCloudMapCollection.getEntityProperty(nodeData, 'expressRouteCircuitPeering');

  if (!expressRouteCircuitPeering) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title={nodeData?.name}
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="asterisk"
      popoutTitle={popoutTitle}
    >
      <Flex flexDirection="column" gap={1}>
        <CloudMetadata
          node={{
            ...nodeData,
            ...nodeData.properties
          }}
          keys={['provisioningState']}
        />
        {expressRouteCircuitPeering && (
          <Flex flexDirection="column" gap={1}>
            <Text as="div">Express Route Circuit Peering</Text>
            <AzurePeeringDetails nodeData={expressRouteCircuitPeering} width={width} popoutTitle={popoutTitle} />
          </Flex>
        )}
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureExpressRouteConnectionDetails);
