import React, { Component } from 'react';

import { Card, Callout, Flex, Grid, Icon, Text } from 'core/components';
import { formatBytesGreek } from 'core/util';
import { ReactComponent as MemoryIcon } from 'app/assets/icons/memory_icon.svg';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import { getHealthStateFromValue } from 'app/views/hybrid/utils/health';
import DeviceMetricsDetailCharts from 'app/views/hybrid/maps/components/deviceMetrics/DeviceMetricsDetailCharts';

export default class MemoryChart extends Component {
  static defaultProps = {
    title: 'Average Memory Utilization'
  };

  get query() {
    const { deviceName } = this.props;

    return {
      all_devices: false,
      device_name: [deviceName],
      fastData: 'Full',
      metric: ['ktappprotocol__snmp_device_metrics__STR01'], // component
      aggregateTypes: [
        'avg_ktappprotocol__snmp_device_metrics__INT64_04', // utilization
        'avg_ktappprotocol__snmp_device_metrics__INT64_02', // used
        'avg_ktappprotocol__snmp_device_metrics__INT64_01' // total
      ],
      lookback_seconds: 86400,
      show_overlay: false,
      show_total_overlay: false
    };
  }

  get loadingState() {
    return <Card height={100} mb={1} showSkeleton />;
  }

  get emptyState() {
    return (
      <Callout>
        <Icon icon={MemoryIcon} />
        <Text ml={1}>Memory Data Unavailable</Text>
      </Callout>
    );
  }

  getResults = (results) =>
    results.getRawDataRows().map((model) => {
      const value = model.get('avg_ktappprotocol__snmp_device_metrics__INT64_04');
      const healthState = getHealthStateFromValue({ type: 'memory', value });
      let color = 'indigo3';
      let headingProps = {};

      if (healthState.state !== 'GOOD') {
        color = healthState.color;
        headingProps = { color: healthState.color, fontWeight: 'bold' };
      }

      return {
        key: model.get('key'),
        usedValue: model.get('avg_ktappprotocol__snmp_device_metrics__INT64_02'),
        totalValue: model.get('avg_ktappprotocol__snmp_device_metrics__INT64_01'),
        value,
        usedLastDatapoint: model.get('f_avg_int64_02__k_last'),
        totalLastDatapoint: model.get('f_avg_int64_01__k_last'),
        valueLastDatapoint: model.get('f_avg_int64_04__k_last'),
        rawData: model.get('rawData').f_avg_int64_04.flow,
        color,
        headingProps
      };
    });

  render() {
    const { title } = this.props;

    return (
      <LightweightDataViewWrapper query={this.query}>
        {({ loading, results }) => {
          if (loading) {
            return this.loadingState;
          }

          const data = this.getResults(results);

          if (data.length > 0) {
            return (
              <Grid gridTemplateColumns="repeat(auto-fit, minmax(350px, 1fr))" gridGap={4} gridRowGap={2}>
                <DeviceMetricsDetailCharts
                  data={data}
                  title={title}
                  icon={MemoryIcon}
                  renderFooter={({ usedValue, totalValue }) => (
                    <Flex justifyContent="flex-end" mt={1}>
                      <Text fontWeight="bold" mr={2}>
                        Used:{' '}
                        <Text fontWeight="normal" muted>
                          {formatBytesGreek(usedValue)}
                        </Text>
                      </Text>
                      <Text fontWeight="bold">
                        Total:{' '}
                        <Text fontWeight="normal" muted>
                          {formatBytesGreek(totalValue)}
                        </Text>
                      </Text>
                    </Flex>
                  )}
                />
              </Grid>
            );
          }

          return this.emptyState;
        }}
      </LightweightDataViewWrapper>
    );
  }
}
