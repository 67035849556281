import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { FiPlusCircle, FiEdit } from 'react-icons/fi';

import storeLoader from 'app/stores/storeLoader';
import { Box, Button, Flex, Text } from 'core/components';
import { CELL_TYPES } from 'core/components/table';
import RemoveButton from 'core/form/components/RemoveButton';
import AdminTable from 'app/components/admin/AdminTable';
import Page from 'app/components/page/Page';
import PageHeading from 'app/components/page/PageHeading';
import NotificationsFilterSidebar from 'app/views/mkp/components/NotificationsFilterSidebar';
import NotificationFormDialog from 'app/views/settings/notifications/NotificationsFormDialog';

@storeLoader('$notifications', '$notifications.supportedChannels')
@observer
export default class Notifications extends Component {
  state = {
    isDialogOpen: false,
    selectedModel: undefined
  };

  handleAddClick = () => {
    const { $notifications } = this.props;
    this.setState(() => ({
      isDialogOpen: true,
      selectedModel: $notifications.collection.forge(undefined, { select: false })
    }));
  };

  handleEditClick = (selectedModel) => {
    this.setState(() => ({
      isDialogOpen: true,
      selectedModel
    }));
  };

  handleDialogClose = () => {
    this.setState(() => ({
      isDialogOpen: false,
      selectedModel: undefined
    }));
  };

  getEditButton = (model) => (
    <Button key="edit" icon={FiEdit} title="Edit" minimal small onClick={() => this.handleEditClick(model)} />
  );

  getDeleteButton = (model) => {
    const defaultConfirmation = 'Are you sure you want to remove this notification channel?';
    return (
      <RemoveButton
        key="remove"
        model={model}
        showIcon
        hideText
        small
        popoverProps={{
          confirmBodyContent: defaultConfirmation
        }}
      />
    );
  };

  getColumns = () => {
    const { $notifications } = this.props;

    const columns = [
      {
        name: 'name',
        label: 'Name',
        renderer: ({ model, value }) => {
          const color = model.isActiveUserChannel ? 'primary' : undefined;
          return <Text color={color}>{value}</Text>;
        }
      },
      {
        name: 'channelType',
        label: 'Type',
        flexBasis: 60,
        renderer: ({ value }) => {
          const channel = $notifications.supportedChannels.get(value);

          return <Text>{channel ? channel.get('channelDisplayName') : ''}</Text>;
        }
      },
      {
        name: 'status',
        label: 'Status',
        width: 80,
        ellipsis: false,
        renderer: ({ value }) => (
          <Text color={value === 'enabled' ? 'success' : 'danger'} textTransform="capitalize">
            {value}
          </Text>
        )
      },
      {
        type: CELL_TYPES.ACTION,
        width: 72,
        actions: [(model) => this.getEditButton(model), (model) => this.getDeleteButton(model)]
      }
    ];

    return columns;
  };

  render() {
    const { $notifications, loading } = this.props;
    const { isDialogOpen, selectedModel } = this.state;

    return (
      <Page title="Notification Channels" parentLinks={[{ link: '/v4/alerting', label: 'Alerting' }]} overflow="hidden">
        <Flex>
          <PageHeading title="Notification Channels" />

          <Flex flex={1} justifyContent="flex-end">
            <Box>
              <Button
                intent="primary"
                icon={FiPlusCircle}
                text="Add Notification Channel"
                onClick={this.handleAddClick}
              />
            </Box>
          </Flex>
        </Flex>

        <AdminTable
          filterSidebar={<NotificationsFilterSidebar collection={$notifications.collection} />}
          collection={$notifications.collection}
          loading={loading}
          selectOnRowClick={false}
          columns={this.getColumns()}
          fetchCollectionOnMount={false}
        />

        {isDialogOpen && (
          <NotificationFormDialog model={selectedModel} onClose={this.handleDialogClose} showUsedBy={false} />
        )}
      </Page>
    );
  }
}
