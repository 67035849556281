import React from 'react';

import { get } from 'lodash';
import { inject } from 'mobx-react';
import { Flex, Text } from 'core/components';

import SidebarItem from '../../SidebarItem';
import CloudMetadata from '../../CloudMetadata';
import AzureVirtualHubDetails from './VirtualHubDetails';

const AzureFirewallDetails = ({ width, popoutTitle, $hybridMap, nodeData }) => {
  const { azureCloudMapCollection } = $hybridMap;

  const virtualHub = azureCloudMapCollection.getEntityProperty(nodeData, 'virtualHub');

  const publicIpAddresses = get(nodeData, 'properties.hubIPAddresses.publicIPs.addresses', []).map(
    ({ address }) => address
  );
  const privateIpAdress = get(nodeData, 'properties.hubIPAddresses.privateIPAddress');

  return (
    <SidebarItem
      excludeFormProps
      title={nodeData?.name}
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="shield"
      popoutTitle={popoutTitle}
    >
      <Flex flexDirection="column" gap={1}>
        <CloudMetadata
          node={{
            ...nodeData,
            ...nodeData.properties,
            PublicIpAddress: publicIpAddresses,
            PrivateIpAdress: privateIpAdress
          }}
          keys={['provisioningState', 'sku.name', 'sku.tier', 'PublicIpAddress', 'PrivateIpAdress']}
        />

        {virtualHub && (
          <Flex flexDirection="column" gap={1}>
            <Text as="div">Virtual Hub:</Text>
            <AzureVirtualHubDetails nodeData={virtualHub} width={width} popoutTitle={popoutTitle} />
          </Flex>
        )}
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureFirewallDetails);
