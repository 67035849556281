/**
 * A simple, margin auto maxWidth centered container
 */

import React from 'react';
import Box from './Box';

function Container(props) {
  return <Box {...props} />;
}

Container.defaultProps = {
  mx: 'auto',
  maxWidth: 1250
};

export default Container;
