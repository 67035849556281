import { nStdDeviations } from './utils';

export const PREFIXES = [
  { value: 'P', magnitude: 10 ** 15 },
  { value: 'T', magnitude: 10 ** 12 },
  { value: 'G', magnitude: 10 ** 9 },
  { value: 'M', magnitude: 10 ** 6 },
  { value: 'K', magnitude: 10 ** 3 }
];

export function greekPrefix(data, scaleMax = 5) {
  const twoStdDeviations = nStdDeviations(data, 2);
  const prefix = PREFIXES.find((pf) => twoStdDeviations / pf.magnitude >= scaleMax);
  return prefix ? prefix.value : '';
}

export function adjustByGreekPrefix(value, prefix) {
  const prefixFound = PREFIXES.find((pf) => pf.value === prefix);

  return prefixFound ? value / prefixFound.magnitude : value;
}

export function adjustPrefixLevel(prefix, magnitude, dir = 'down') {
  let factor = PREFIXES.findIndex((pfx) => pfx.magnitude <= Math.abs(magnitude));
  const prefixIdx = PREFIXES.findIndex((pfx) => pfx.value === prefix);
  let newPrefix = prefix;
  let newIdx;

  if (factor > -1) {
    factor = PREFIXES.length - factor;
    newIdx = Math.min(Math.max(prefixIdx + factor * (dir === 'up' ? -1 : 1), 0), PREFIXES.length - 1);
    newPrefix = PREFIXES[newIdx].value;
  }

  return newPrefix;
}

export function getPrefixMagnitude(prefix) {
  const match = PREFIXES.find((option) => option.value === prefix);
  return match?.magnitude || 1;
}

export function formatBytesGreek(value, suffix = 'B', scaleMax) {
  const prefix = greekPrefix(Array.isArray(value) ? value : [value], scaleMax);
  const total = Array.isArray(value) ? value.reduce((sum, row) => sum + parseFloat(row), 0) : parseFloat(value);
  return `${adjustByGreekPrefix(total, prefix).toFixed()} ${prefix}${suffix}`;
}

export function toDecimal(value, maxNumberOfDecimals = 2) {
  const decimals = 10 ** maxNumberOfDecimals;

  if (Number.isNaN(value)) {
    return 0;
  }

  return Math.round(value * decimals) / decimals;
}
