import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Intent } from '@blueprintjs/core';

import { Button, Box, Heading, showErrorToast, showSuccessToast } from 'core/components';
import Page from 'app/components/page/Page';

@inject('$auth')
class Expired extends Component {
  static defaultProps = {
    message: 'Your trial has expired',
    title: 'Expired Trial'
  };

  handleRequest = () => {
    const { $auth } = this.props;

    $auth.requestAssistance().then(
      () => {
        showSuccessToast('Your request has been received. Kentik will be in touch shortly.', {
          timeout: 0
        });
      },
      () => {
        showErrorToast(
          'Your request could not be processed at this time due to technical issues. Please try again later.',
          {
            timeout: 0
          }
        );
      }
    );
  };

  render() {
    const { message, title } = this.props;

    return (
      <Page title={title} hideSubnav locked>
        <Heading level={2}>{message}</Heading>
        <Box py={2}>Please contact Kentik to restore access via the button below:</Box>
        <Box>
          <Button text="Request Assistance" fontWeight="bold" intent={Intent.PRIMARY} onClick={this.handleRequest} />
        </Box>
      </Page>
    );
  }
}

export default Expired;
