import React, { Component } from 'react';
import { FiInfo } from 'react-icons/fi';

import { Box, Button, CalloutOutline, Flex, Icon, Suspense, Text } from 'core/components';
import { formConsumer } from 'core/form';
import FilterOptionsDialog from 'app/components/filters/FilterOptionsDialog';
import FilterOptions from 'app/views/core/explorer/sidebar/FilterOptions';

@formConsumer
class UserPermissions extends Component {
  state = {
    editingFilters: false
  };

  handleToggleFiltersEditing = () => {
    const { editingFilters } = this.state;
    this.setState({ editingFilters: !editingFilters });
  };

  handleCloseFilterDialog = () => {
    this.setState({ editingFilters: false });
  };

  render() {
    const { form, loading } = this.props;
    const { editingFilters } = this.state;
    const filterGroupValues = form.getValue('filters.filterGroups');

    return (
      <Suspense loading={loading}>
        <CalloutOutline intent="primary" mb={2}>
          <Flex alignItems="center">
            <Icon icon={FiInfo} iconSize={30} mr="8px" color="primary" />
            <Box flex="1 1 auto">
              <Text fontWeight="heavy">Note: </Text>
              <Text>Changes can take up to 20 minutes to take effect.</Text>
            </Box>
          </Flex>
        </CalloutOutline>

        <FilterOptions />

        <Button icon="multi-select" onClick={this.handleToggleFiltersEditing} maxWidth={200}>
          {filterGroupValues.length > 0 ? 'Edit Filters' : 'Add Filters'}
        </Button>

        <FilterOptionsDialog
          isOpen={editingFilters}
          showSaveFiltersButton={false}
          onClose={this.handleCloseFilterDialog}
        />
      </Suspense>
    );
  }
}

export default UserPermissions;
