import * as React from 'react';
import {
  overlayRenderer,
  asnRenderer,
  providerRenderer,
  interfaceRenderer,
  ipRenderer,
  cloudDetailRenderer,
  connectivityTypeRenderer,
  portRenderer,
  routePrefixRenderer,
  valueOrNameRenderer,
  asPathRenderer,
  bgpCommunityRenderer,
  countryRenderer,
  cdnRenderer,
  serviceRenderer,
  kappaRenderer
} from 'app/components/dataviews/views/legend/legendRenderers';
import RegionLink from 'app/components/links/RegionLink';
import CityLink from 'app/components/links/CityLink';

const DIMENSION_GROUPS = {
  applicationContextAndSecurity: 'Application Context & Security',
  kappaApplicationContextAndSecurity: 'Application Context & Security',
  geolocation: 'Geolocation',
  ipAndBgpRouting: 'IP & BGP Routing',
  kappaIpAndBgpRouting: 'IP & BGP Routing',
  networkAndTrafficTopology: 'Network & Traffic Topology',
  cloud: 'Cloud',
  kappa: 'Process-Aware Telemetry Agent'
};

export const ALL_GROUPS_EXCLUDING_CLOUD = {
  include: { groups: ['applicationContextAndSecurity', 'geolocation', 'ipAndBgpRouting', 'networkAndTrafficTopology'] },
  exclude: { options: ['ipBoth', 'interfaceBoth'] }
};

export const ALL_KAPPA = {
  include: { groups: ['kappa', 'applicationContextAndSecurity', 'ipAndBgpRouting'] },
  exclude: { options: ['ipBoth', 'siteByIP'] }
};

export const ALL_GROUPS_CLOUD = {
  include: {
    groups: ['applicationContextAndSecurity', 'geolocation', 'ipAndBgpRouting', 'networkAndTrafficTopology', 'cloud']
  },
  exclude: { options: ['ip', 'interfaceBoth'] }
};

const DIMENSION_MAP = {
  kappa: [
    { label: 'Pod Name', value: 'objectName', src: 'kt_k8s_src_pod_name', dst: 'kt_k8s_dst_pod_name' },
    { label: 'Namespace', value: 'namespace', src: 'kt_k8s_src_pod_ns', dst: 'kt_k8s_dst_pod_ns' },
    { label: 'Node', value: 'node', src: 'ktsubtype__kappa__STR06', dst: 'ktsubtype__kappa__STR07' },

    { label: 'Process PID', value: 'process_pid', src: 'ktsubtype__kappa__INT01', dst: 'ktsubtype__kappa__INT02' },
    { label: 'Process Name', value: 'process_name', src: 'ktsubtype__kappa__STR00', dst: 'ktsubtype__kappa__STR03' },
    { label: 'Process Cmdline', value: 'process_cmd', src: 'ktsubtype__kappa__STR01', dst: 'ktsubtype__kappa__STR04' },
    { label: 'Container ID', value: 'container_id', src: 'ktsubtype__kappa__STR02', dst: 'ktsubtype__kappa__STR05' },
    {
      label: 'Container Name',
      value: 'container_name',
      src: 'ktsubtype__kappa__STR11',
      dst: 'ktsubtype__kappa__STR17'
    },
    { label: 'Workload Name', value: 'workload', src: 'ktsubtype__kappa__STR12', dst: 'ktsubtype__kappa__STR18' },
    { label: 'Labels', value: 'labels', src: 'ktsubtype__kappa__STR20', dst: 'ktsubtype__kappa__STR21' }
  ],

  applicationContextAndSecurity: [
    { label: 'Public Cloud Provider', value: 'publicCloud', src: 'src_cloud', dst: 'dst_cloud' },
    {
      label: 'Public Cloud Service',
      value: 'publicCloudService',
      src: 'src_cloud_service',
      dst: 'dst_cloud_service'
    },
    { label: 'CDN', value: 'cdn', src: 'src_cdn', dst: 'dst_cdn' },
    { label: 'Service (Port+Proto)', value: 'servicePortProto', src: 'src_proto_port', dst: 'dst_proto_port' },
    { label: 'Botnet C&C', value: 'botnet', src: 'src_threat_bnetcc', dst: 'dst_threat_bnetcc' },
    { label: 'Threat-list Host', value: 'threatListHost', src: 'src_threat_host', dst: 'dst_threat_host' },
    { label: 'Application', value: 'application', metric: 'application' },
    { label: 'TCP Flags', value: 'tcp_flags', metric: 'tcp_flags' }
  ],

  geolocation: [
    { label: 'Custom Geo', value: 'customGeo', src: 'kt_src_market', dst: 'kt_dst_market' },
    { label: 'Country', value: 'country', src: 'Geography_src', dst: 'Geography_dst' },
    { label: 'Region', value: 'region', src: 'src_geo_region', dst: 'dst_geo_region' },
    { label: 'City', value: 'city', src: 'src_geo_city', dst: 'dst_geo_city' },
    { label: 'Site Country', value: 'i_device_site_country', metric: 'i_device_site_country' }
  ],

  ipAndBgpRouting: [
    { label: 'IP', value: 'ip', src: 'IP_src', dst: 'IP_dst' },
    { label: 'IP (both)', value: 'ipBoth', metric: ['IP_src', 'IP_dst'] },
    { label: 'Port Number', value: 'portNumber', src: 'Port_src', dst: 'Port_dst' },
    { label: 'Route Prefix/LEN', value: 'routePrefix', src: 'src_route_prefix_len', dst: 'dst_route_prefix_len' },
    { label: 'AS Number', value: 'asn', src: 'AS_src', dst: 'AS_dst' },
    { label: 'Next Hop IP/CIDR', value: 'nextHopIP', src: 'src_nexthop_ip', dst: 'dst_nexthop_ip' },
    { label: 'Next Hop AS Number', value: 'nextHopASN', src: 'src_nexthop_asn', dst: 'dst_nexthop_asn' },
    { label: '2nd Hop AS Number', value: '2ndHopASN', src: 'src_second_asn', dst: 'dst_second_asn' },
    { label: '3rd Hop AS Number', value: '3rdHopASN', src: 'src_third_asn', dst: 'dst_third_asn' },
    { label: 'AS Path', value: 'asPath', src: 'src_bgp_aspath', dst: 'dst_bgp_aspath' },
    { label: 'BGP Community', value: 'bgpCommunity', src: 'src_bgp_community', dst: 'dst_bgp_community' },
    { label: 'VRF Name', value: 'vrfName', src: 'i_input_vrf', dst: 'i_output_vrf' },
    {
      label: 'VRF Route Distinguisher',
      value: 'vrfRouteDistinguisher',
      src: 'i_input_vrf_rd',
      dst: 'i_output_vrf_rd'
    },
    { label: 'VRF Route Target', value: 'vrfRouteTarget', src: 'i_input_vrf_rt', dst: 'i_output_vrf_rt' },
    {
      label: 'VRF Extended Route Distinguisher',
      value: 'vrfExtendedRouteDistinguisher',
      src: 'input_vrf',
      dst: 'output_vrf'
    },
    { label: 'Packet Size', value: 'sampledpktsize', metric: 'sampledpktsize' },
    { label: 'Protocol', value: 'Proto', metric: 'Proto' },
    { label: 'INET Family', value: 'inet_family', metric: 'inet_family' },
    { label: 'DSCP', value: 'dscp', metric: 'dscp' },
    { label: 'ToS', value: 'TOS', metric: 'TOS' },
    { label: 'Site by IP', value: 'siteByIP', src: 'kt_src_site_title', dst: 'kt_dst_site_title' }
  ],

  networkAndTrafficTopology: [
    { label: 'Interface', value: 'interface', src: 'InterfaceID_src', dst: 'InterfaceID_dst' },
    { label: 'Interface (both)', value: 'interfaceBoth', metric: ['InterfaceID_src', 'InterfaceID_dst'] },
    {
      label: 'Connectivity Type',
      value: 'connectivityType',
      src: 'i_src_connect_type_name',
      dst: 'i_dst_connect_type_name'
    },
    {
      label: 'Provider',
      value: 'provider',
      src: 'i_src_provider_classification',
      dst: 'i_dst_provider_classification'
    },
    { label: 'VLAN', value: 'vlan', src: 'VLAN_src', dst: 'VLAN_dst' },
    { label: 'MAC Address', value: 'macAddress', src: 'src_eth_mac', dst: 'dst_eth_mac' },
    { label: 'Ultimate Exit Interface', value: 'bgp_ult_exit_interface', metric: 'bgp_ult_exit_interface' },
    {
      label: 'Ultimate Exit Connectivity Type',
      value: 'i_ult_exit_connect_type_name',
      metric: 'i_ult_exit_connect_type_name'
    },
    {
      label: 'Ultimate Exit Network Boundary',
      value: 'i_ult_exit_network_bndry_name',
      metric: 'i_ult_exit_network_bndry_name'
    },
    {
      label: 'Ultimate Exit Provider',
      value: 'i_ult_provider_classification',
      metric: 'i_ult_provider_classification'
    },
    { label: 'Traffic Profile', value: 'i_trf_profile', metric: 'i_trf_profile' },
    { label: 'Ultimate Exit Site', value: 'i_ult_exit_site', metric: 'i_ult_exit_site' },
    { label: 'Ultimate Exit Site Market', value: 'i_ult_exit_site_market', metric: 'i_ult_exit_site_market' },
    { label: 'Ultimate Exit Device', value: 'i_ult_exit_device_name', metric: 'i_ult_exit_device_name' },
    { label: 'Device', value: 'i_device_id', metric: 'i_device_id' },
    { label: 'Site', value: 'i_device_site_name', metric: 'i_device_site_name' },
    { label: 'Site Market', value: 'i_site_market', metric: 'i_site_market' }
  ],

  cloud: [
    {
      label: 'Instance Name',
      fullLabel: 'AWS Instance Name',
      value: 'awsInstanceName',
      metric: ['kt_aws_src_vm_name', 'kt_aws_dst_vm_name']
    },
    {
      label: 'Instance Name',
      fullLabel: 'Azure Instance Name',
      value: 'azureInstanceName',
      metric: ['kt_az_src_inst_name', 'kt_az_dst_inst_name']
    },
    {
      label: 'Instance Name',
      fullLabel: 'GCP Instance Name',
      value: 'gcpInstanceName',
      metric: ['ktsubtype__gcp_subnet__STR02', 'ktsubtype__gcp_subnet__STR03']
    },
    {
      label: 'Region',
      fullLabel: 'AWS Region',
      value: 'awsRegion',
      src: 'kt_aws_src_region',
      dst: 'kt_aws_dst_region'
    },
    {
      label: 'Region',
      fullLabel: 'Azure Region',
      value: 'azureRegion',
      src: 'kt_az_src_region',
      dst: 'kt_az_dst_region'
    },
    {
      label: 'Region',
      fullLabel: 'GCP Region',
      value: 'gcpRegion',
      src: 'ktsubtype__gcp_subnet__STR04',
      dst: 'ktsubtype__gcp_subnet__STR05'
    },
    {
      label: 'Zone Name',
      fullLabel: 'AWS Zone Name',
      value: 'awsZone',
      src: 'kt_aws_src_zone',
      dst: 'kt_aws_dst_zone'
    },
    {
      label: 'Zone ID',
      fullLabel: 'AWS Zone ID',
      value: 'awsZoneID',
      src: 'kt_aws_src_zone_id',
      dst: 'kt_aws_dst_zone_id'
    },
    { label: 'Zone', fullLabel: 'Azure Zone', value: 'azureZone', src: 'kt_az_src_zone', dst: 'kt_az_dst_zone' },
    {
      label: 'Zone',
      fullLabel: 'GCP Zone',
      value: 'gcpZone',
      src: 'ktsubtype__gcp_subnet__STR06',
      dst: 'ktsubtype__gcp_subnet__STR07'
    },

    { label: 'VPC', fullLabel: 'AWS VPC', value: 'awsVPCVNet', src: 'kt_aws_src_vpc_id', dst: 'kt_aws_dst_vpc_id' },

    { label: 'VNet', fullLabel: 'Azure VNet', value: 'azureVPCVNet', src: 'kt_az_src_vnet', dst: 'kt_az_dst_vnet' },
    {
      label: 'Instance Tags',
      fullLabel: 'AWS Instance Tags',
      value: 'awsInstanceTags',
      src: 'kt_aws_src_vm_tags',
      dst: 'kt_aws_dst_vm_tags'
    },
    {
      label: 'Interface ID',
      fullLabel: 'AWS Interface ID',
      value: 'awsInterfaceID',
      metric: 'ktsubtype__aws_subnet__STR03'
    },
    {
      label: 'Gateway ID',
      fullLabel: 'AWS Gateway ID',
      value: 'awsGatewayID',
      src: 'ktsubtype__aws_subnet__STR16',
      dst: 'ktsubtype__aws_subnet__STR17'
    },
    {
      label: 'Gateway Type',
      fullLabel: 'AWS Gateway Type',
      value: 'awsGatewayType',
      src: 'ktsubtype__aws_subnet__STR18',
      dst: 'ktsubtype__aws_subnet__STR19'
    },
    {
      label: 'Account ID',
      fullLabel: 'AWS Account ID',
      value: 'awsAccountID',
      src: 'kt_aws_src_acc_id',
      dst: 'kt_aws_dst_acc_id'
    },
    {
      label: 'Subnet',
      fullLabel: 'AWS Subnet',
      value: 'awsSubnet',
      src: 'kt_aws_src_subnet_id',
      dst: 'kt_aws_dst_subnet_id'
    },
    {
      label: 'Security Group',
      fullLabel: 'AWS Security Group',
      value: 'awsSecurityGroup',
      src: 'kt_aws_src_sg',
      dst: 'kt_aws_dst_sg'
    },
    {
      label: 'Packet Address',
      fullLabel: 'AWS Packet Address',
      value: 'awsPacketAddress',
      src: 'ktsubtype__aws_subnet__INET_00',
      dst: 'ktsubtype__aws_subnet__INET_01'
    },
    {
      label: 'Public DNS Name',
      fullLabel: 'AWS Public DNS Name',
      value: 'awsPublicDNSName',
      src: 'kt_aws_src_pub_dns',
      dst: 'kt_aws_dst_pub_dns'
    },
    {
      label: 'Private DNS Name',
      fullLabel: 'AWS Private DNS Name',
      value: 'awsPrivateDNSName',
      src: 'kt_aws_src_priv_dns',
      dst: 'kt_aws_dst_priv_dns'
    },
    {
      label: 'Firewall Action',
      fullLabel: 'AWS Firewall Action',
      value: 'awsFirewallAction',
      metric: 'kt_aws_action'
    }
  ]
};

export const regionRenderer =
  (showOverlayTag) =>
  ({ value, model, name, column }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    const key = (column && column.name) || name;
    const isDst = model.has(key) ? key.includes('dst') : model.has('dst_geo_region');

    // check for direction-stripped country key found in double traffic charts
    const country = model.get('Geography_src|dst') || (isDst ? model.get('Geography_dst') : model.get('Geography_src'));

    if (value && /[^-]/.test(value)) {
      return <RegionLink region={value} country={country} />;
    }
    return value;
  };

export const cityRenderer =
  (showOverlayTag) =>
  ({ value, model, name, column }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    const key = (column && column.name) || name;
    const isDst = model.has(key) ? key.includes('dst') : model.has('dst_geo_city');

    // check for direction-stripped country and region keys found in double traffic charts
    const country = model.get('Geography_src|dst') || (isDst ? model.get('Geography_dst') : model.get('Geography_src'));
    const region =
      model.get('src|dst_geo_region') || (isDst ? model.get('dst_geo_region') : model.get('src_geo_region'));

    if (value && /[^-]/.test(value)) {
      return <CityLink city={value} country={country} region={region} />;
    }

    return value;
  };

export const DIMENSION_RENDERER_MAP = {
  'i_src|dst_as_name': [
    { key: 'i_src_as_name', hidden: true },
    { key: 'i_dst_as_name', hidden: true },
    {
      key: 'i_src|dst_as_name',
      label: 'AS Number',
      renderer: asnRenderer()
    }
  ],
  'inet_src|dst_addr': [
    { key: 'inet_src_addr', hidden: true },
    { key: 'inet_dst_addr', hidden: true },
    {
      key: 'inet_src|dst_addr',
      label: 'IP/CIDR',
      renderer: ipRenderer()
    }
  ],
  'input|output_port': [
    { key: 'input_port', hidden: true },
    { key: 'output_port', hidden: true },
    {
      key: 'input|output_port',
      label: 'Interface',
      renderer: interfaceRenderer()
    }
  ],
  'i_src|dst_provider_classification': [
    { key: 'i_src_provider_classification', hidden: true },
    { key: 'i_dst_provider_classification', hidden: true },
    {
      key: 'i_src|dst_provider_classification',
      label: 'Provider',
      renderer: providerRenderer()
    }
  ],
  'i_src|dst_nexthop_as_name': [
    { key: 'i_src_nexthop_as_name', hidden: true },
    { key: 'i_dst_nexthop_as_name', hidden: true },
    {
      key: 'i_src|dst_nexthop_as_name',
      label: 'Next-Hop Network',
      renderer: asnRenderer()
    }
  ],
  'ktsubtype__gcp_subnet__STR04src|dstktsubtype__gcp_subnet__STR05': [
    { key: 'ktsubtype__gcp_subnet__STR04', hidden: true },
    { key: 'ktsubtype__gcp_subnet__STR05', hidden: true },
    {
      key: 'ktsubtype__gcp_subnet__STR04src|dstktsubtype__gcp_subnet__STR05',
      name: 'ktsubtype__gcp_subnet__STR04',
      label: 'GCP Region',
      renderer: cloudDetailRenderer()
    }
  ],
  'kt_aws_src|dst_region': [
    { key: 'kt_aws_src_region', hidden: true },
    { key: 'kt_aws_dst_region', hidden: true },
    {
      key: 'kt_aws_src|dst_region',
      name: 'kt_aws_src_region',
      label: 'AWS Region',
      renderer: cloudDetailRenderer()
    }
  ],
  'kt_az_src|dst_region': [
    { key: 'kt_az_src_region', hidden: true },
    { key: 'kt_az_dst_region', hidden: true },
    {
      key: 'kt_az_src|dst_region',
      name: 'kt_az_src_region',
      label: 'Azure Region',
      renderer: cloudDetailRenderer()
    }
  ],
  'ktsubtype__gcp_subnet__STR06src|dstktsubtype__gcp_subnet__STR07': [
    { key: 'ktsubtype__gcp_subnet__STR06', hidden: true },
    { key: 'ktsubtype__gcp_subnet__STR07', hidden: true },
    {
      key: 'ktsubtype__gcp_subnet__STR06src|dstktsubtype__gcp_subnet__STR07',
      name: 'ktsubtype__gcp_subnet__STR06',
      label: 'GCP Zone',
      renderer: cloudDetailRenderer()
    }
  ],
  'kt_aws_src|dst_zone': [
    { key: 'kt_aws_src_zone', hidden: true },
    { key: 'kt_aws_dst_zone', hidden: true },
    {
      key: 'kt_aws_src|dst_zone',
      name: 'kt_aws_src_zone',
      label: 'AWS Zone Name',
      renderer: cloudDetailRenderer()
    }
  ],
  'kt_aws_src|dst_zone_id': [
    { key: 'kt_aws_src_zone_id', hidden: true },
    { key: 'kt_aws_dst_zone_id', hidden: true },
    {
      key: 'kt_aws_src|dst_zone_id',
      name: 'kt_aws_src_zone_id',
      label: 'AWS Zone Name',
      renderer: cloudDetailRenderer()
    }
  ],
  'kt_az_src|dst_zone': [
    { key: 'kt_az_src_zone', hidden: true },
    { key: 'kt_az_dst_zone', hidden: true },
    {
      key: 'kt_az_src|dst_zone',
      name: 'kt_az_src_zone',
      label: 'Azure Zone',
      renderer: cloudDetailRenderer()
    }
  ],
  'kt_aws_src|dst_vpc_id': [
    { key: 'kt_aws_src_vpc_id', hidden: true },
    { key: 'kt_aws_dst_vpc_id', hidden: true },
    {
      key: 'kt_aws_src|dst_vpc_id',
      name: 'kt_aws_src_vpc_id',
      label: 'AWS VPC ID',
      renderer: cloudDetailRenderer()
    }
  ],
  'kt_az_src|dst_vnet': [
    { key: 'kt_az_src_vnet', hidden: true },
    { key: 'kt_az_dst_vnet', hidden: true },
    {
      key: 'kt_az_src|dst_vnet',
      name: 'kt_az_src_vnet',
      label: 'Azure VNet',
      renderer: cloudDetailRenderer()
    }
  ],
  'src|dst_cloud': [
    { key: 'src_cloud', hidden: true },
    { key: 'dst_cloud', hidden: true },
    {
      key: 'src|dst_cloud',
      name: 'src_cloud',
      label: 'Public Cloud Provider',
      renderer: cloudDetailRenderer()
    }
  ],
  'i_src|dst_connect_type_name': [
    { key: 'i_src_connect_type_name', hidden: true },
    { key: 'i_dst_connect_type_name', hidden: true },
    {
      key: 'i_src|dst_connect_type_name',
      label: 'Connectivity Type',
      renderer: connectivityTypeRenderer()
    }
  ],
  'l4_src|dst_port': [
    { key: 'l4_src_port', hidden: true },
    { key: 'l4_dst_port', hidden: true },
    {
      key: 'l4_src|dst_port',
      label: 'Port Number',
      renderer: portRenderer()
    }
  ],
  'src|dst_route_prefix_len': [
    { key: 'src_route_prefix_len', hidden: true },
    { key: 'dst_route_prefix_len', hidden: true },
    {
      key: 'src|dst_route_prefix_len',
      label: 'Route Prefix/LEN',
      renderer: routePrefixRenderer()
    }
  ],
  'inet_src|dst_next_hop': [
    { key: 'inet_src_next_hop', hidden: true },
    { key: 'inet_dst_next_hop', hidden: true },
    {
      key: 'inet_src|dst_next_hop',
      label: 'Next Hop IP/CIDR',
      renderer: ipRenderer()
    }
  ],
  'vlan_in|out': [
    { key: 'vlan_in', hidden: true },
    { key: 'vlan_out', hidden: true },
    {
      key: 'vlan_in|out',
      label: 'VLAN',
      renderer: valueOrNameRenderer()
    }
  ],
  'src|dst_eth_mac': [
    { key: 'src_eth_mac', hidden: true },
    { key: 'dst_eth_mac', hidden: true },
    {
      key: 'src|dst_eth_mac',
      label: 'MAC Address',
      renderer: valueOrNameRenderer()
    }
  ],
  'i_src|dst_second_asn_name': [
    { key: 'i_src_second_asn_name', hidden: true },
    { key: 'i_dst_second_asn_name', hidden: true },
    {
      key: 'i_src|dst_second_asn_name',
      label: '2nd Hop AS Number',
      renderer: asnRenderer()
    }
  ],
  'i_src|dst_third_asn_name': [
    { key: 'i_src_third_asn_name', hidden: true },
    { key: 'i_dst_third_asn_name', hidden: true },
    {
      key: 'i_src|dst_third_asn_name',
      label: '3rd Hop AS Number',
      renderer: asnRenderer()
    }
  ],
  'src|dst_bgp_aspath': [
    { key: 'src_bgp_aspath', hidden: true },
    { key: 'dst_bgp_aspath', hidden: true },
    {
      key: 'src|dst_bgp_aspath',
      label: 'AS Path',
      renderer: asPathRenderer()
    }
  ],
  'src|dst_bgp_community': [
    { key: 'src_bgp_community', hidden: true },
    { key: 'dst_bgp_community', hidden: true },
    {
      key: 'src|dst_bgp_community',
      label: 'BGP Community',
      renderer: bgpCommunityRenderer()
    }
  ],
  'i_input|output_vrf': [
    { key: 'i_input_vrf', hidden: true },
    { key: 'i_output_vrf', hidden: true },
    {
      key: 'i_input|output_vrf',
      label: 'VRF Name',
      renderer: valueOrNameRenderer()
    }
  ],
  'i_input|output_vrf_rd': [
    { key: 'i_input_vrf_rd', hidden: true },
    { key: 'i_output_vrf_rd', hidden: true },
    {
      key: 'i_input|output_vrf_rd',
      label: 'VRF Route Distinguisher',
      renderer: valueOrNameRenderer()
    }
  ],
  'i_input|output_vrf_rt': [
    { key: 'i_input_vrf_rt', hidden: true },
    { key: 'i_output_vrf_rt', hidden: true },
    {
      key: 'i_input|output_vrf_rt',
      label: 'VRF Route Target',
      renderer: valueOrNameRenderer()
    }
  ],
  'input|output_vrf': [
    { key: 'input|output_vrf', hidden: true },
    { key: 'input|output_vrf', hidden: true },
    {
      key: 'input|output_vrf',
      label: 'VRF Extended Route Distinguisher',
      renderer: valueOrNameRenderer()
    }
  ],
  'kt_src|dst_market': [
    { key: 'kt_src_market', hidden: true },
    { key: 'kt_dst_market', hidden: true },
    {
      key: 'kt_src|dst_market',
      label: 'Custom Geo',
      renderer: valueOrNameRenderer()
    }
  ],
  'Geography_src|dst': [
    { key: 'Geography_src', hidden: true },
    { key: 'Geography_dst', hidden: true },
    {
      key: 'Geography_src|dst',
      label: 'Country',
      renderer: countryRenderer()
    }
  ],
  'src|dst_geo_region': [
    { key: 'src_geo_region', hidden: true },
    { key: 'dst_geo_region', hidden: true },
    {
      key: 'src|dst_geo_region',
      label: 'Region',
      renderer: regionRenderer()
    }
  ],
  'src|dst_geo_city': [
    { key: 'src_geo_city', hidden: true },
    { key: 'dst_geo_city', hidden: true },
    {
      key: 'src|dst_geo_city',
      label: 'City',
      renderer: cityRenderer()
    }
  ],
  'src|dst_cloud_service': [
    { key: 'src_cloud_service', hidden: true },
    { key: 'dst_cloud_service', hidden: true },
    {
      key: 'src|dst_cloud_service',
      label: 'Public Cloud Service',
      renderer: valueOrNameRenderer()
    }
  ],
  'src|dst_cdn': [
    { key: 'src_cdn', hidden: true },
    { key: 'dst_cdn', hidden: true },
    {
      key: 'src|dst_cdn',
      label: 'CDN',
      renderer: cdnRenderer()
    }
  ],
  'src|dst_threat_bnetcc': [
    { key: 'src_threat_bnetcc', hidden: true },
    { key: 'dst_threat_bnetcc', hidden: true },
    {
      key: 'src|dst_threat_bnetcc',
      label: 'Botnet C&C',
      renderer: valueOrNameRenderer()
    }
  ],
  'src|dst_threat_host': [
    { key: 'src_threat_host', hidden: true },
    { key: 'dst_threat_host', hidden: true },
    {
      key: 'src|dst_threat_host',
      label: 'Threat-list Host',
      renderer: valueOrNameRenderer()
    }
  ],
  'i_l4_src|dst_port_name': [
    { key: 'i_l4_src_port_name', hidden: true },
    { key: 'i_l4_dst_port_name', hidden: true },
    {
      key: 'i_l4_src|dst_port_name',
      label: 'Service (Port+Proto)',
      renderer: serviceRenderer()
    }
  ],
  'kt_aws_src|dst_vm_tags': [
    { key: 'kt_aws_src_vm_tags', hidden: true },
    { key: 'kt_aws_dst_vm_tags', hidden: true },
    {
      key: 'kt_aws_src|dst_vm_tags',
      label: 'AWS Instance Tags',
      renderer: valueOrNameRenderer()
    }
  ],
  'ktsubtype__aws_subnet__STR16src|dstktsubtype__aws_subnet__STR17': [
    { key: 'ktsubtype__aws_subnet__STR16', hidden: true },
    { key: 'ktsubtype__aws_subnet__STR17', hidden: true },
    {
      key: 'ktsubtype__aws_subnet__STR16src|dstktsubtype__aws_subnet__STR17',
      label: 'AWS Gateway ID',
      renderer: valueOrNameRenderer()
    }
  ],
  'ktsubtype__aws_subnet__STR18src|dstktsubtype__aws_subnet__STR19': [
    { key: 'ktsubtype__aws_subnet__STR18', hidden: true },
    { key: 'ktsubtype__aws_subnet__STR19', hidden: true },
    {
      key: 'ktsubtype__aws_subnet__STR18src|dstktsubtype__aws_subnet__STR19',
      label: 'AWS Gateway Type',
      renderer: valueOrNameRenderer()
    }
  ],
  'kt_aws_src|dst_acc_id': [
    { key: 'kt_aws_src_acc_id', hidden: true },
    { key: 'kt_aws_dst_acc_id', hidden: true },
    {
      key: 'kt_aws_src|dst_acc_id',
      label: 'AWS Account ID',
      renderer: valueOrNameRenderer()
    }
  ],
  'kt_aws_src|dst_subnet_id': [
    { key: 'kt_aws_src_subnet_id', hidden: true },
    { key: 'kt_aws_dst_subnet_id', hidden: true },
    {
      key: 'kt_aws_src|dst_subnet_id',
      name: 'kt_aws_src_subnet_id',
      label: 'AWS Subnet',
      renderer: cloudDetailRenderer()
    }
  ],
  'kt_aws_src|dst_sg': [
    { key: 'kt_aws_src_sg', hidden: true },
    { key: 'kt_aws_dst_sg', hidden: true },
    {
      key: 'kt_aws_src|dst_sg',
      name: 'kt_aws_src_sg',
      label: 'AWS Security Group',
      renderer: cloudDetailRenderer()
    }
  ],
  'ktsubtype__aws_subnet__INET_00src|dstktsubtype__aws_subnet__INET_01': [
    { key: 'ktsubtype__aws_subnet__INET_00', hidden: true },
    { key: 'ktsubtype__aws_subnet__INET_01', hidden: true },
    {
      key: 'ktsubtype__aws_subnet__INET_00src|dstktsubtype__aws_subnet__INET_01',
      label: 'AWS Packet Address',
      renderer: valueOrNameRenderer()
    }
  ],
  'kt_aws_src|dst_pub_dns': [
    { key: 'kt_aws_src_pub_dns', hidden: true },
    { key: 'kt_aws_dst_pub_dns', hidden: true },
    {
      key: 'kt_aws_src|dst_pub_dns',
      name: 'kt_aws_src_pub_dns',
      label: 'AWS Public DNS Name',
      renderer: cloudDetailRenderer()
    }
  ],
  'kt_aws_src|dst_priv_dns': [
    { key: 'kt_aws_src_priv_dns', hidden: true },
    { key: 'kt_aws_dst_priv_dns', hidden: true },
    {
      key: 'kt_aws_src|dst_priv_dns',
      name: 'kt_aws_src_priv_dns',
      label: 'AWS Private DNS Name',
      renderer: cloudDetailRenderer()
    }
  ],
  'kt_src|dst_site_title': [
    { key: 'kt_src_site_title', hidden: true },
    { key: 'kt_dst_site_title', hidden: true },
    {
      key: 'kt_src|dst_site_title',
      label: 'Site by IP',
      renderer: valueOrNameRenderer()
    }
  ],
  'kt_k8s_src|dst_pod_name': [
    { key: 'kt_k8s_src_pod_name', hidden: true },
    { key: 'kt_k8s_dst_pod_name', hidden: true },
    { key: 'kt_k8s_src|dst_pod_name', label: 'Pod Name', renderer: kappaRenderer() }
  ],
  'kt_k8s_src|dst_pod_ns': [
    { key: 'kt_k8s_src_pod_ns', hidden: true },
    { key: 'kt_k8s_dst_pod_ns', hidden: true },
    { key: 'kt_k8s_src|dst_pod_ns', label: 'Namespace', renderer: kappaRenderer() }
  ]
};

function generateGroupHeaderOption(groupName) {
  if (groupName) {
    return {
      label: DIMENSION_GROUPS[groupName],
      value: groupName,
      groupHeaderProps: { top: -4, fontWeight: 'bold', mt: '4px' }
    };
  }

  return null;
}

function generateGroupSubHeaderOption(groupName, subGroupName) {
  const subGroupNameMap = {
    nonDirectionalOther: { label: 'Non-Directional / Other', bg: 'selectGroupHeader.successBackground' },
    source: { label: 'Source', bg: 'selectGroupHeader.warningBackground' },
    destination: { label: 'Destination', bg: 'selectGroupHeader.primaryBackground' }
  };

  if (subGroupNameMap[subGroupName]) {
    return {
      label: subGroupNameMap[subGroupName].label,
      value: `${groupName}-${subGroupName}`,
      groupHeaderProps: { top: 19, bg: subGroupNameMap[subGroupName].bg, mb: '4px' }
    };
  }

  return null;
}

// adjust margins for group headers depending on their neighbors
function adjustGroupHeaderSpacing(options) {
  return options.map((o, i, all) => {
    const { groupHeaderProps } = o;

    if (groupHeaderProps) {
      const previousOption = all[i - 1];
      const nextOption = all[i + 1];
      const margins = {};

      if (previousOption && !previousOption.groupHeaderProps) {
        margins.mt = '4px';
      }

      if (nextOption && !nextOption.groupHeaderProps) {
        margins.mb = '4px';
      }

      return {
        ...o,
        groupHeaderProps: { ...groupHeaderProps, ...margins }
      };
    }

    return o;
  });
}

function classifyGroupOptions(groupName, groupOptions, isDoubleChart) {
  const groups = groupOptions.reduce(
    (acc, option) => {
      const optionSupportDirection = !!option.src && !!option.dst;

      if (isDoubleChart) {
        acc.byNonDirectionalOther = acc.byNonDirectionalOther.concat(option);
        return acc;
      }

      if (optionSupportDirection) {
        acc.bySrc = acc.bySrc.concat({
          label: option.label,
          value: `${option.value}-source`,
          metric: option.src
        });

        acc.byDst = acc.byDst.concat({
          label: option.label,
          value: `${option.value}-destination`,
          metric: option.dst
        });
      } else {
        acc.byNonDirectionalOther = acc.byNonDirectionalOther.concat({
          label: option.label,
          value: `${option.value}-nonDirectionalOther`,
          metric: option.metric
        });
      }

      return acc;
    },
    {
      byNonDirectionalOther: [generateGroupSubHeaderOption(groupName, 'nonDirectionalOther')],
      bySrc: [generateGroupSubHeaderOption(groupName, 'source')],
      byDst: [generateGroupSubHeaderOption(groupName, 'destination')]
    }
  );

  if (!isDoubleChart) {
    return [].concat(groups.byNonDirectionalOther, groups.bySrc, groups.byDst);
  }

  return groups.byNonDirectionalOther;
}

function filterDimensionOptions(dimensionRenderOptions, isDoubleChart) {
  const { include = {}, exclude = {}, cloudProvider } = dimensionRenderOptions;
  const groups = Object.keys(DIMENSION_MAP);

  return groups.reduce((acc, groupName) => {
    const { groups: includeGroups = [], options: includeOptions = [] } = include;
    const { groups: excludeGroups = [], options: excludeOptions = [] } = exclude;
    let options = [];

    if (includeGroups.includes(groupName) && !excludeGroups.includes(groupName)) {
      // add the whole group in
      options = options.concat(DIMENSION_MAP[groupName]);
    }

    // filter at the options level
    options = options.filter(
      (o) => (includeOptions.length === 0 || includeOptions.includes(o.value)) && !excludeOptions.includes(o.value)
    );

    if (options.length > 0) {
      // add the group name to the option values to assist with fuzzy search
      let groupOptions = options.map((o) => ({ ...o, value: `${o.value}-${groupName}` }));

      if (groupName === 'cloud') {
        const providers = [].concat(cloudProvider);

        if (cloudProvider) {
          groupOptions = groupOptions.filter((o) => providers.some((provider) => o.value.startsWith(provider)));
        }

        if (providers.length > 1) {
          // with more than 1 provider type in the list, we want full labels to tell them apart
          groupOptions = groupOptions.map((o) => ({
            ...o,
            label: o.fullLabel || o.label
          }));
        }
      }

      const classifiedOptions = classifyGroupOptions(groupName, groupOptions, isDoubleChart);

      // if there are options for this group, prepend a group heading option
      return acc.concat(generateGroupHeaderOption(groupName), classifiedOptions);
    }

    return acc;
  }, []);
}

export function getDimensionOptions(dimensionRenderOptions, isDoubleChart) {
  let options = [{ label: 'Total', value: 'Traffic', metric: 'Traffic' }];

  if (dimensionRenderOptions) {
    const dimOptions = filterDimensionOptions(dimensionRenderOptions, isDoubleChart);
    options = options.concat(adjustGroupHeaderSpacing(dimOptions));
  }

  return options;
}

export function searchDimensionOptions(optionsFilter, options) {
  const filter = optionsFilter && optionsFilter.toLowerCase();
  const filteredOptions = options.filter(
    ({ label, value }) =>
      (typeof label === 'string' && label.toLowerCase().includes(filter)) ||
      (typeof value === 'string' && value.toLowerCase().includes(filter))
  );

  const groupedOptions = filteredOptions
    .filter(({ groupHeaderProps }) => !groupHeaderProps)
    .reduce((acc, option) => {
      const [, groupName, subGroupName] = option.value.split('-');
      const groupHeaderOption = generateGroupHeaderOption(groupName);
      const groupSubHeaderOption = generateGroupSubHeaderOption(groupName, subGroupName);

      // add the header if needed
      if (groupHeaderOption && !acc.find((o) => o.value === groupHeaderOption.value)) {
        acc.push(groupHeaderOption);
      }

      // add the sub-header if needed
      if (groupSubHeaderOption && !acc.find((o) => o.value === groupSubHeaderOption.value)) {
        acc.push(groupSubHeaderOption);
      }

      acc.push(option);

      return acc;
    }, []);

  return Promise.resolve(adjustGroupHeaderSpacing(groupedOptions));
}
