import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { formatBytesGreek, toDecimal } from 'core/util';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import SearchableTable from 'app/views/hybrid/maps/components/SearchableTable';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import { PopoverInteractionKind, PopoverPosition } from '@blueprintjs/core';
import { Flex, Button, EmptyState, Heading, Popover, Sparkline, Card, Box, Icon, Text } from 'core/components';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import { getDateRangeDisplay } from 'core/util/dateUtils';
import { reaction } from 'mobx';

@inject('$hybridMap', '$dictionary', '$metrics')
@observer
export default class MetricsPanel extends React.Component {
  state = {
    loading: true,
    isPoppedOut: false,
    data: null
  };

  componentDidMount() {
    const { $hybridMap } = this.props;

    this.timeRangeDisposer = reaction(
      () => $hybridMap.sidebarSettings.timeRange,
      () => this.fetchMetrics()
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { nodeData } = this.props;
    const { data, isPoppedOut } = this.state;
    const popOutChanged = prevState.isPoppedOut !== isPoppedOut;

    if (prevProps.nodeData?.id !== nodeData?.id || (data === null && popOutChanged)) {
      // if we have the sidebar open and switch to a new load balancer or change the time range, fetch for new data
      this.fetchMetrics();
    }
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.cancel();
    }

    if (this.timeRangeDisposer) {
      this.timeRangeDisposer();
    }
  }

  fetchMetrics = () => {
    const { $hybridMap, nodeData, entityType } = this.props;
    const { compartmentId, tenancyId, region } = nodeData;

    this.setState({ loading: true }, () => {
      const postData = {
        metricDataParams: { compartmentId, tenancyId, region }
      };
      this.request = makeCancelable($hybridMap.getOciMetrics({ entityType, id: nodeData.id, postData }));

      this.request.promise
        .then((response) => {
          this.setState({ loading: false, data: response });
        })
        .catch((e) => {
          if (e instanceof CanceledError === false) {
            this.setState({ loading: false }, () => console.error(e));
          }
        });
    });
  };

  handleExpandChange = (isOpenOrPoppedOut) => {
    const { data } = this.state;

    if (isOpenOrPoppedOut && data === null) {
      // fetch for data when expanding for the first time
      this.fetchMetrics();
    }
  };

  handlePopOutChange = (isPoppedOut) => {
    this.setState({ isPoppedOut }, this.handleExpandChange);
  };

  handlePanelRefresh = (e) => {
    e.stopPropagation();
    this.fetchMetrics();
  };

  get title() {
    const { $hybridMap, $dictionary } = this.props;
    const { isPoppedOut } = this.state;
    // under the hood we support children being able to report their metrics as part of their parent
    // this would be shown as a group summary where we can display the unique time intervals for each metric there
    // since we're not using this feature just yet, just take the first interval and use that as it will be what was used for all the metrics returned

    const lookBackSeconds = $hybridMap.sidebarSettings?.sidebarQueryOverrides?.lookback_seconds ?? '';

    return (
      <Flex alignItems="end">
        <Heading level={5} mb={0} mr={1}>
          Metrics
        </Heading>
        {!isPoppedOut && (
          <Text color="muted" small>
            ({$dictionary.get(`showLast.${lookBackSeconds}`, 'UTC')})
          </Text>
        )}
      </Flex>
    );
  }

  get popOutHeader() {
    const { $hybridMap } = this.props;
    const { data, isPoppedOut } = this.state;
    const displayInterval = data?.metrics?.[0]?.interval?.display;
    const { start, end } = $hybridMap.sidebarSettings.timeRange;

    if (isPoppedOut) {
      return (
        <Text as="div" fontWeight="bold" mb={1}>{`${getDateRangeDisplay(start, end)} ${
          displayInterval ? `(${displayInterval})` : ''
        }`}</Text>
      );
    }

    return null;
  }

  get emptyState() {
    const { cloudProvider } = this.props;

    return (
      <EmptyState
        title="No Metrics Found"
        icon={<CloudIcon cloudProvider={cloudProvider} entity="metrics" iconSize={48} />}
      />
    );
  }

  renderMetric = ({ value, model }) => {
    const metricsName = model.get('name')?.toLowerCase();

    // make sure we display values less than 1
    const fixedValue = toDecimal(value);

    if (metricsName.includes('bytes')) {
      return formatBytesGreek(fixedValue, 'B');
    }

    return formatBytesGreek(fixedValue, '');
  };

  get columns() {
    const { isPoppedOut } = this.state;
    return [
      {
        name: 'name',
        label: 'Metric',
        renderer: ({ value, model }) => {
          const sparklineData = model.get('sparklineData', []);
          const hasData = sparklineData.length > 0;
          // TODO: as of 11.13.24, Kentik does not support historical NMS Metrics collection for OCI
          // if we do end up supporting in the future, we should implement a link to Metrics Explorer a la other cloud provider metrics panels
          return (
            <Popover
              usePortal
              disabled={!hasData || isPoppedOut}
              minimal={false}
              interactionKind={PopoverInteractionKind.HOVER}
              position={PopoverPosition.LEFT}
              modifiers={{
                flip: { enabled: false },
                preventOverflow: { enabled: true, boundariesElement: 'window' }
              }}
              content={
                <Card p={1}>
                  <Box width={150} height={50}>
                    <Sparkline data={sparklineData} width={150} height={50} strokeWidth={0.5} color="primary" />
                  </Box>
                </Card>
              }
            >
              <Flex alignItems="end">
                <>
                  <Icon icon="timeline-line-chart" color={hasData ? 'primary' : 'muted'} mr={1} />
                  <Text>{value}</Text>
                </>
              </Flex>
            </Popover>
          );
        }
      },

      ...(isPoppedOut
        ? [
            {
              name: 'sparklineData',
              label: 'Trend',
              flexBasis: 125,
              renderer: ({ value }) => (
                <Box width={250} height={20}>
                  <Sparkline data={value} width={250} height={20} strokeWidth={0.5} color="primary" />
                </Box>
              )
            }
          ]
        : []),

      {
        name: 'average',
        label: 'Avg',
        width: 85,
        renderer: this.renderMetric
      },

      {
        name: 'sum',
        label: 'Sum',
        width: 85,
        renderer: this.renderMetric
      }
    ];
  }

  render() {
    const { cloudProvider, width, popoutTitle } = this.props;
    const { loading, data } = this.state;

    return (
      <SidebarItem
        excludeFormProps
        title={this.title}
        dialogProps={{ width }}
        popoutTitle={popoutTitle}
        useIconTag={false}
        icon={<CloudIcon cloudProvider={cloudProvider} entity="metrics" iconSize={24} style={{ marginRight: 8 }} />}
        navigationButtons={
          <Flex gap={1}>
            <Button icon="refresh" color="success" onClick={this.handlePanelRefresh} small minimal />
          </Flex>
        }
        onExpandChange={this.handleExpandChange}
        onPopOutChange={this.handlePopOutChange}
      >
        <Box>
          {this.popOutHeader}
          <SearchableTable
            collectionOptions={{ sortState: { field: 'name', direction: 'asc' } }}
            height={240}
            loading={loading}
            columns={this.columns}
            filterKeys={['name']}
            data={data?.data || []}
            emptyState={this.emptyState}
            stickyHeader
            stickyGroups
          />
        </Box>
      </SidebarItem>
    );
  }
}
