import React, { Component } from 'react';
import { Box, Button, Flex, Tag, Text } from 'core/components';
import PageHeading from 'app/components/page/PageHeading';
import { ReactComponent as OfficeIcon } from 'app/assets/icons/office.svg';
import { FormComponent, SubmitButton } from 'core/form';
import CompanyOverview from 'app/views/admin/CompanyOverview';
import CompanyPlans from 'app/views/admin/CompanyPlans';
import CompanyPermissions from 'app/views/admin/CompanyPermissions';
import PageTabs, { PageTab } from 'app/components/page/PageTabs';
import CompanyUsers from 'app/views/admin/CompanyUsers';
import CompanyDevices from 'app/views/admin/CompanyDevices';
import CompanyNotes from 'app/views/admin/CompanyNotes';
import CompanyAddOns from 'app/views/admin/CompanyAddOns';
import { withRouter } from 'react-router-dom';
import { fields as companyFields, options } from './companyDetails';
import { fields as permissionFields } from './companyPemissionsFormConfig';

@withRouter
export default class CompanyAdminDetailsForm extends Component {
  handleCancel = () => {
    const { history } = this.props;
    history.push('/v4/admin');
  };

  onSubmit = (form, values) => form.model.save(values).then(() => form.model.fetch());

  render() {
    const { title, model } = this.props;
    const tabs = [
      <PageTab key="overview" id="overview" title="Overview" panel={<CompanyOverview />} />,
      <PageTab
        key="plans"
        id="plans"
        title={
          <Text>
            Plans <Tag minimal>{model.get('plans').unfilteredSize}</Tag>
          </Text>
        }
        panel={<CompanyPlans />}
      />,
      <PageTab key="permissions" id="permissions" title="Permissions" panel={<CompanyPermissions />} />,
      <PageTab
        key="users"
        id="users"
        title={
          <Text>
            Users <Tag minimal>{model.get('users').unfilteredSize}</Tag>
          </Text>
        }
        panel={<CompanyUsers />}
      />,
      <PageTab
        key="devices"
        id="devices"
        title={
          <Text>
            Devices <Tag minimal>{model.get('devices').unfilteredSize}</Tag>
          </Text>
        }
        panel={<CompanyDevices />}
      />,
      <PageTab
        key="notes"
        id="notes"
        title={
          <Text>
            Notes <Tag minimal>{model.get('notes').unfilteredSize}</Tag>
          </Text>
        }
        panel={<CompanyNotes />}
      />,
      <PageTab
        key="addons"
        id="addons"
        title={
          <Text>
            AddOns <Tag minimal>{model.get('addOns').unfilteredSize}</Tag>
          </Text>
        }
        panel={<CompanyAddOns />}
      />
    ];

    const fields = {
      ...companyFields,
      ...permissionFields
    };
    return (
      <FormComponent fields={fields} options={options} model={model}>
        <Flex justifyContent="space-between">
          <PageHeading hideFavorite title={title} icon={OfficeIcon} mb={2} />
          <Flex justifyContent="flex-end">
            <Box>
              <SubmitButton onSubmit={this.onSubmit} text="Save" mr={2} intent="primary" />
            </Box>
            <Box>
              <Button onClick={this.handleCancel} text="Cancel" />
            </Box>
          </Flex>
        </Flex>
        <PageTabs defaultSelectedTabId="overview" useUrlPathParam>
          {tabs}
        </PageTabs>
      </FormComponent>
    );
  }
}
