import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, Icon, Heading, Text, CalloutOutline } from 'core/components';
import { ReactComponent as KubeNodeIcon } from 'app/assets/icons/kubernetes/node.svg';
import { ReactComponent as KubeNodeIconProblem } from 'app/assets/icons/kubernetes/node_problem.svg';

@observer
class K8NodeSummary extends React.Component {
  render() {
    const { id } = this.props;
    const { nodeData } = this.props;
    const { health, cluster_name, cluster_id, latency } = nodeData;
    const hasProblem = health?.data.state === 'CRITICAL' || health?.data.state === 'WARNING';

    return (
      <>
        <Flex alignItems="center" borderBottom="thin" p={2} mb={1}>
          <Icon icon={hasProblem ? KubeNodeIconProblem : KubeNodeIcon} iconSize={40} color="primary" mr={1} />
          <Box>
            <Text as="div" muted>
              Node Details
            </Text>
            <Heading level={3} mb={0}>
              {id}
            </Heading>
          </Box>
        </Flex>

        {hasProblem && (
          <Box px={2}>
            <CalloutOutline intent="danger">This node is experiencing some high latency</CalloutOutline>
          </Box>
        )}

        <Box p={2} py={1} borderBottom="thin">
          <Flex mb="4px" alignItems="center">
            <Text muted as="div" mr="4px">
              Name:
            </Text>
            <Text fontWeight="medium">{id}</Text>
          </Flex>
          {/* <Flex mb="4px" alignItems="center">
            <Text muted as="div" mr="4px">
              Node IP:
            </Text>
            <Flex alignItems="center">
              <Text fontWeight="medium">---</Text>
            </Flex>
          </Flex> */}
          {cluster_id > 0 && (
            <Flex mb="4px" alignItems="center">
              <Text muted as="div" mr="4px">
                Cluster ID:
              </Text>
              <Flex alignItems="center">
                <Text fontWeight="medium">{cluster_id}</Text>
              </Flex>
            </Flex>
          )}
          <Flex mb="4px" alignItems="center">
            <Text muted as="div" mr="4px">
              Cluster Name:
            </Text>
            <Flex alignItems="center">
              <Text fontWeight="medium">{cluster_name}</Text>
            </Flex>
          </Flex>
          {latency && (
            <Flex mb="4px" alignItems="center">
              <Text muted as="div" mr="4px">
                Latency:
              </Text>
              <Flex alignItems="center">
                <Text fontWeight="medium">{Math.round(latency)}ms</Text>
              </Flex>
            </Flex>
          )}
        </Box>
      </>
    );
  }
}

K8NodeSummary.PopoutTitle = ({ value }) => `Kubernetes Node - ${value}`;

export default K8NodeSummary;
