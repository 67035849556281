import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { isEqual } from 'lodash';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import { ALL_KAPPA } from 'app/views/hybrid/maps/components/trafficCharts/dimensionOptionsHelper';
import { getKubeFilterGroup } from '../components/popovers/queryOptionsHelper';

@inject('$hybridMap')
@observer
export default class KubeBoxLinkGenerator extends React.Component {
  static defaultProps = {
    boxes: {
      infrastructureKubeLink: {
        orientation: 'vertical',
        from: 'infrastructure',
        to: 'kube',
        loading: true
      },

      internetKubeLink: {
        orientation: 'vertical',
        from: 'internet',
        to: 'kube',
        loading: true
      }
    },
    kubeLabel: 'Kubernetes',
    deviceName: null
  };

  constructor(props) {
    super(props);
    const { boxes } = props;

    this.state = boxes;
  }

  componentDidMount() {
    this.updateBoxLinks();
  }

  componentDidUpdate(prevProps, prevState) {
    const { infrastructureKubeLink, internetKubeLink } = this.state;

    if (
      !isEqual(prevState.infrastructureKubeLink, infrastructureKubeLink) ||
      !isEqual(prevState.internetKubeLink, internetKubeLink)
    ) {
      this.updateBoxLinks();
    }
  }

  updateBoxLinks() {
    const { onBoxLinksUpdate } = this.props;
    const { infrastructureKubeLink, internetKubeLink } = this.state;
    const boxLinks = [infrastructureKubeLink, internetKubeLink];
    onBoxLinksUpdate(boxLinks);
  }

  generateBitrateBreakdown(query, results) {
    let infrastructureToKube = 0;
    let kubeToInfrastructure = 0;

    let internetToKube = 0;
    let kubeToInternet = 0;

    for (let i = 0; i < results.size; i += 1) {
      const { key, avg_bits_per_sec } = results.at(i).get();

      if (key.includes('to Internet')) {
        kubeToInternet += avg_bits_per_sec;
      }

      if (key.includes('From Internet')) {
        internetToKube += avg_bits_per_sec;
      }

      if (key.includes('From Other Infrastructure')) {
        infrastructureToKube += avg_bits_per_sec;
      }

      if (key.includes('to Other Infrastructure')) {
        kubeToInfrastructure += avg_bits_per_sec;
      }
    }

    return { infrastructureToKube, internetToKube, kubeToInfrastructure, kubeToInternet };
  }

  handleQueryComplete = ({ query, results, fullyLoaded }) => {
    const { deviceName, namespace, kubeLabel } = this.props;
    const entity = { namespace };

    if (fullyLoaded) {
      const { infrastructureToKube, kubeToInfrastructure, internetToKube, kubeToInternet } =
        this.generateBitrateBreakdown(query, results);

      const baseQuery = {
        all_devices: false,
        device_name: deviceName ? [deviceName] : [],
        device_types: deviceName ? [] : ['kappa']
      };

      this.setState(({ infrastructureKubeLink, internetKubeLink }) => ({
        infrastructureKubeLink: {
          ...infrastructureKubeLink,
          loading: false,
          bytesIn: kubeToInfrastructure,
          bytesOut: infrastructureToKube,
          queryOptions: [
            {
              label: 'Traffic Between On Prem and Kubernetes',
              value: 'boxLinkTraffic',
              query: {
                inboundQuery: {
                  ...baseQuery,
                  query_title: `Traffic From On Prem to ${kubeLabel}`,
                  filters: {
                    connector: 'All',
                    filterGroups: [getKubeFilterGroup({ entity, direction: 'dst', infrastructure: true })]
                  },
                  renderOptions: {
                    source: 'Other Infrastructure',
                    target: kubeLabel,
                    dimensions: ALL_KAPPA
                  }
                },
                outboundQuery: {
                  ...baseQuery,
                  query_title: `Traffic From ${kubeLabel} to On Prem`,
                  filters: {
                    connector: 'All',
                    filterGroups: [getKubeFilterGroup({ entity, direction: 'src', infrastructure: true })]
                  },
                  renderOptions: {
                    source: kubeLabel,
                    target: 'Other Infrastructure',
                    dimensions: ALL_KAPPA
                  }
                }
              }
            }
          ]
        },
        internetKubeLink: {
          ...internetKubeLink,
          loading: false,
          bytesIn: kubeToInternet,
          bytesOut: internetToKube,
          queryOptions: [
            {
              label: 'Traffic Between Internet and Kubernetes',
              value: 'boxLinkTraffic',
              query: {
                inboundQuery: {
                  ...baseQuery,
                  query_title: `Traffic From Internet to ${kubeLabel}`,
                  filters: {
                    connector: 'All',
                    filterGroups: [getKubeFilterGroup({ entity, direction: 'dst', internet: true })]
                  },
                  renderOptions: {
                    source: 'Internet',
                    target: kubeLabel,
                    dimensions: ALL_KAPPA
                  }
                },
                outboundQuery: {
                  ...baseQuery,
                  query_title: `Traffic From ${kubeLabel} to Internet`,
                  filters: {
                    connector: 'All',
                    filterGroups: [getKubeFilterGroup({ entity, direction: 'src', internet: true })]
                  },
                  renderOptions: {
                    source: kubeLabel,
                    target: 'Internet',
                    dimensions: ALL_KAPPA
                  }
                }
              }
            }
          ]
        }
      }));
    }
  };

  get query() {
    const { deviceName, namespace, $hybridMap } = this.props;
    const entity = { namespace };

    const query = $hybridMap.getQuery({
      all_devices: false,
      device_name: deviceName ? [deviceName] : [],
      device_types: deviceName ? [] : ['kappa'],
      aggregateTypes: ['avg_bits_per_sec', 'p95th_bits_per_sec', 'max_bits_per_sec'],
      filterDimensionsEnabled: true,
      filterDimensionName: 'Total',
      filterDimensionOther: false,
      filterDimensionSort: false,
      filterDimensions: {
        connector: 'All',
        filterGroups: [
          {
            name: 'From Kube to Internet',
            named: true,
            connector: 'All',
            not: false,
            filterGroups: [getKubeFilterGroup({ entity, direction: 'src', internet: true })]
          },
          {
            name: 'From Kube to Other Infrastructure',
            named: true,
            connector: 'All',
            not: false,
            filterGroups: [getKubeFilterGroup({ entity, direction: 'src', infrastructure: true })]
          },
          {
            name: 'From Internet to Kube',
            named: true,
            connector: 'All',
            not: false,
            filterGroups: [getKubeFilterGroup({ entity, direction: 'dst', internet: true })]
          },
          {
            name: 'From Other Infrastructure to Kube',
            named: true,
            connector: 'All',
            not: false,
            filterGroups: [getKubeFilterGroup({ entity, direction: 'dst', infrastructure: true })]
          }
        ]
      },
      show_overlay: false,
      show_total_overlay: false,
      topx: 250,
      depth: 250,
      viz_type: 'table'
    });

    return query;
  }

  render() {
    return <LightweightDataViewWrapper query={this.query} onQueryComplete={this.handleQueryComplete} />;
  }
}
