import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, Icon, Text } from 'core/components';
import { Form, Field, Select } from 'core/form';
import { getOption } from 'core/form/components/modalSelect/selectHelpers';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SecurityRulesTable from './SecurityRulesTable';

@Form({ fields: { nacl: { label: 'Network ACL' } }, options: { name: 'Network ACLs' } })
@observer
export default class NetworkACLsPanel extends React.Component {
  componentDidMount() {
    const { form, nacls } = this.props;

    if (nacls.length > 0) {
      // auto-select the first option on mount if available
      form.setValue('nacl', nacls[0].NetworkAclId);
    }
  }

  get options() {
    const { nacls } = this.props;

    return nacls.map((nacl) => ({
      label: `${nacl.NetworkAclId}`,
      value: nacl.NetworkAclId,
      entity: nacl
    }));
  }

  get selectedNACL() {
    const { form } = this.props;
    const field = form.getField('nacl');
    const option = getOption(field.options, field.getValue());

    return (option && option.entity) || {};
  }

  get selectedRuleLegend() {
    const { selectedRule } = this.props;

    if (selectedRule && this.selectedNACL.deniesTraffic) {
      const source = `${selectedRule.sourceIp}:${selectedRule.sourcePort}`;
      const destination = `${selectedRule.destinationIp}:${selectedRule.destinationPort}`;

      return (
        <Flex alignItems="center" mt={1}>
          <Box width={14} height={14} bg="dangerBackground" border="thin" mr={1} />
          <Text color="muted" small>
            Denies {selectedRule.sourceProtocol} traffic from {source} to {destination}
          </Text>
        </Flex>
      );
    }

    return null;
  }

  get targetLabel() {
    const { vpcId, subnetId } = this.props;
    const targets = [vpcId, subnetId].filter((target) => !!target);

    return targets.join(', ');
  }

  optionFormatter = (option) => {
    if (option) {
      const { entity, label } = option;

      return (
        <Flex>
          {entity.deniesTraffic && (
            <Box pl="4px" pr={1}>
              <Icon icon="dot" color="danger" mx="-7px" />
            </Box>
          )}
          {label}
        </Flex>
      );
    }

    return null;
  };

  render() {
    const { vpcId, subnetId, queryTimeOptions } = this.props;
    const { selectedNACL } = this;

    return (
      <Box>
        <Field name="nacl" options={this.options} label="Network ACLs" showLabel={false} className="no-margin">
          <Select
            optionFormatter={this.optionFormatter}
            valueRenderer={this.optionFormatter}
            inlineLabel
            showFilter
            fill
          />
        </Field>

        <CloudMetadata
          node={{
            ...selectedNACL
          }}
          keys={['OwnerId', 'VpcId', 'Tags']}
          mb={2}
          maxHeight={200}
          overflow="auto"
          small
        />

        <SecurityRulesTable nacl={selectedNACL} vpcId={vpcId} subnetId={subnetId} queryTimeOptions={queryTimeOptions} />

        {this.selectedRuleLegend}
      </Box>
    );
  }
}
