import { AZURE_ENTITY_TYPES, AWS_ENTITY_TYPES } from 'shared/hybrid/constants';
import {
  curveLeftUp,
  curveUpRight,
  curveUpLeft,
  curveRightUp,
  curveDownLeft,
  curveLeftDown,
  curveDownRight,
  curveRightDown
} from 'app/views/hybrid/utils/d3/curves';
import { connectAlignedNodes } from './commonUtils';

/**
 * pinLeft = false:
 * Target
 *   ↑
 *    ← ← ← ← ← ← ←
 *                  ↑
 *             → → →
 *            ↑
 *    Transit Gateway
 *
 * pinLeft = true:
 * Target
 *   ↑
 *   ↑
 *   ↑
 *   → → →→ → →
 *            ↑
 *    Transit Gateway
 */
const connectToAnyConnectionOnPrem = ({
  sourcePoint,
  targetPoint,
  regionLeft,
  regionRight,
  ySpacing,
  sourceNodeHeight,
  targetNodeHeight,
  linkSpacing,
  routeLeft = false
}) => {
  let sideX = regionRight + linkSpacing;

  if (routeLeft === true) {
    sideX = regionLeft - linkSpacing;
  }

  const topY = targetPoint[1] + targetNodeHeight / 2 + ySpacing / 2;
  const bottomY = sourcePoint[1] - sourceNodeHeight / 2 - ySpacing / 2;
  const connectionPoints = [
    [sourcePoint[0], bottomY],
    [sideX, bottomY],
    [sideX, topY],
    [targetPoint[0], topY]
  ];
  return { sourceAnchor: 'top', targetAnchor: 'bottom', connectionPoints };
};

/**
 * Internet Box
 *       ↑
 *        ← ← ← ← ←
 *                 ↑
 *                 ↑
 *                 ↑
 *    Gateway      ↑
 *      ↓          ↑
 *       → → → → →
 */
const connectGatewayToInternetBox = ({
  sourcePoint,
  targetPoint,
  regionRight,
  ySpacing,
  sourceNodeHeight,
  targetNodeHeight,
  linkSpacing
}) => {
  const rightX = regionRight + linkSpacing;
  const topY = targetPoint[1] + targetNodeHeight / 2 + ySpacing / 2;
  const bottomY = sourcePoint[1] + sourceNodeHeight + linkSpacing * 4;
  const connectionPoints = [
    [sourcePoint[0], bottomY],
    [rightX, bottomY],
    [rightX, topY],
    [targetPoint[0], topY]
  ];
  return { sourceAnchor: 'bottom', targetAnchor: 'bottom', connectionPoints };
};

export const connectToSubnet = (props) => {
  const { regionLeft, sourcePoint, targetPoint, ySpacing, targetNodeHeight, linkSpacing, directConnectHeight } = props;

  const leftX = regionLeft + linkSpacing;
  // we have to consider targetNodeHeight, becuase of new Icons section.
  const topY = targetPoint[1] - targetNodeHeight / 2 - ySpacing / 2;
  const bottomY = sourcePoint[1] - ySpacing;
  const isTargetLeft = targetPoint[0] < sourcePoint[0];

  /**
   *  → → → →
   * ↑       ↓
   * ↑     Subnet
   * ↑
   * ↑
   * ↑
   * ↑
   *  ← ← ← ← ← ← ←
   *                ↑
   *         TGW Attachment
   */
  // only go through the left when there is a box between nodes
  if (Math.abs(bottomY - topY) > directConnectHeight * 2) {
    const connectionPoints = [
      ...curveUpLeft(sourcePoint[0], bottomY, linkSpacing),
      ...curveLeftUp(leftX, bottomY, linkSpacing),
      ...curveUpRight(leftX, topY, linkSpacing),
      ...curveRightUp(targetPoint[0], topY, linkSpacing)
    ];

    return { sourceAnchor: 'top', targetAnchor: 'top', connectionPoints };
  }

  const middleY = sourcePoint[1] - Math.abs(sourcePoint[1] - targetPoint[1]) / 2;
  /**
   *      Subnet
   *        ↑
   *        ↑
   *           ← ← ←
   *                ↑
   *                ↑
   *            Attachment
   */
  if (isTargetLeft) {
    const connectionPoints = [
      ...curveUpLeft(sourcePoint[0], middleY, linkSpacing),
      ...curveLeftUp(targetPoint[0], middleY, linkSpacing)
    ];

    return { sourceAnchor: 'top', targetAnchor: 'bottom', connectionPoints };
  }

  /**
   *            Subnet
   *              ↑
   *              ↑
   *         → → →
   *       ↑
   *       ↑
   *  Attachment
   */
  const connectionPoints = [
    ...curveUpRight(sourcePoint[0], middleY, linkSpacing),
    ...curveRightUp(targetPoint[0], middleY, linkSpacing)
  ];

  return { sourceAnchor: 'top', targetAnchor: 'bottom', connectionPoints };
};

/**
 *       Transit Gateway
 *             ↑
 *             ↓
 *        → ← →
 *       ↑
 *       ↓
 *       ↑
 *        ← → ←
 *             ↑
 *             ↓
 *       Transit Gateway
 */
const connectToTransitGateway = ({ sourcePoint, targetPoint, directConnectWidth, ySpacing, linkSpacing }) => {
  const isSourceOnTop = sourcePoint[1] < targetPoint[1];
  const middleX = sourcePoint[0] - directConnectWidth / 2 - linkSpacing;
  const topY = Math.min(targetPoint[1], sourcePoint[1]) + ySpacing;
  const bottomY = Math.max(targetPoint[1], sourcePoint[1]) - ySpacing;

  if (isSourceOnTop) {
    const connectionPoints = [
      ...curveDownLeft(sourcePoint[0], topY, linkSpacing),
      ...curveLeftDown(middleX, topY, linkSpacing),
      ...curveDownRight(middleX, bottomY, linkSpacing),
      ...curveRightDown(targetPoint[0], bottomY, linkSpacing)
    ];

    return { sourceAnchor: 'bottom', targetAnchor: 'top', connectionPoints };
  }

  const connectionPoints = [
    ...curveUpLeft(sourcePoint[0], bottomY, linkSpacing),
    ...curveLeftUp(middleX, bottomY, linkSpacing * 3),
    ...curveUpRight(middleX, topY, linkSpacing * 3),
    ...curveRightUp(targetPoint[0], topY, linkSpacing)
  ];

  return { sourceAnchor: 'top', targetAnchor: 'bottom', connectionPoints };
};

/**
 * Will connect transit gateway to any attachment. E.g. TransitGatewaysAttachment, VPC Attachment, etc...
 */
const connectToAnyAttachment = (props) => {
  const {
    path,
    ySpacing,
    pathIndex,
    sourceNode,
    targetNode,
    targetPoint,
    sourcePoint,
    linkSpacing,
    directConnectWidth,
    directConnectHeight
  } = props;

  const isForwardPath =
    path.find(
      (pathNode) =>
        (pathNode.source.value === sourceNode.value || pathNode.target.value === sourceNode.value) &&
        (pathNode.source.value === targetNode.value || pathNode.target.value === targetNode.value)
    )?.source.value === sourceNode.value;

  const targetY = targetPoint[1] + directConnectHeight;
  const sourceY = sourcePoint[1] + ySpacing;
  const revertedSourceY = sourcePoint[1] - ySpacing;
  const middleX = sourcePoint[0] - directConnectWidth / 2 - linkSpacing;

  const alignedAlongX = Math.abs(sourcePoint[1] - targetPoint[1]) < ySpacing;
  const isTransitGatewayOnTop = sourcePoint[1] < targetPoint[1];

  const tgwIsSourceInAPath = path.find((pathNode) => pathNode.source.value === sourceNode.value);
  const tgwIsTargetInAPath = path.find((pathNode) => pathNode.target.value === sourceNode.value);

  const pathGoingThroughTgw = tgwIsSourceInAPath && tgwIsTargetInAPath;

  /**
   * example:
   *               ← ← ← ← ←
   *              ↓           ↑
   * Attachment   ↓    Transit Gateway
   *   ↑          ↓
   *    ← ← ← ← ←
   */
  if (alignedAlongX || isTransitGatewayOnTop || pathGoingThroughTgw) {
    if (!isForwardPath) {
      const sourceLeftX = middleX - directConnectWidth / 2;

      if (tgwIsSourceInAPath) {
        const maxY = Math.max(sourcePoint[1], targetPoint[1]) + directConnectHeight / 2 + ySpacing;

        return {
          sourceAnchor: 'bottom',
          targetAnchor: 'bottom',
          connectionPoints: [
            ...curveDownLeft(sourcePoint[0], maxY, linkSpacing),
            ...curveLeftUp(targetPoint[0], maxY, linkSpacing)
          ]
        };
      }

      const topY = sourceY - directConnectHeight - ySpacing;

      return {
        sourceAnchor: tgwIsSourceInAPath ? 'bottom' : 'top',
        targetAnchor: 'bottom',
        connectionPoints: [
          ...curveUpLeft(sourcePoint[0], topY, linkSpacing),
          ...curveLeftDown(sourceLeftX, topY, linkSpacing),
          ...curveDownLeft(sourceLeftX, targetY, linkSpacing),
          ...curveLeftUp(targetPoint[0], targetY, linkSpacing)
        ]
      };
    }

    const connectionPoints = [
      ...curveDownLeft(sourcePoint[0], targetY, linkSpacing),
      ...curveLeftUp(targetPoint[0], targetY, linkSpacing)
    ];
    return { sourceAnchor: 'bottom', targetAnchor: 'bottom', connectionPoints };
  }

  // if path goes transit gateway, we need to make sure that anchors are set based on prev and next node positions
  const nextLink = path[pathIndex + 1];
  const sourceNodeId = sourceNode.value;
  const nextLinkTargetNodeId = nextLink?.target?.value ?? null;

  if (sourceNodeId === nextLinkTargetNodeId && revertedSourceY > targetY) {
    /**
     * example:
     * Attachment
     *    ↑
     *    ← ← ← ← ← ← ←
     *                  ↑
     *                    ← ← ← ←
     *                            ↑
     *         <WE ARE HERE>Transit Gateway</WE ARE HERE>
     *                            ↑
     *                    → → → →
     *                   ↑
     *                   ↑
     *                   ↑
     *  Attachment       ↑
     *      ↓            ↑
     *       → → → → → →
     */

    const connectionPoints = [
      ...curveUpLeft(sourcePoint[0], revertedSourceY, linkSpacing),
      ...curveLeftUp(middleX, revertedSourceY, linkSpacing),
      ...curveUpLeft(middleX, targetY, linkSpacing),
      ...curveLeftUp(targetPoint[0], targetY, linkSpacing)
    ];
    return { sourceAnchor: 'top', targetAnchor: 'bottom', connectionPoints };
  }

  /**
   * example:
   * Attachment
   *    ↑
   *    ← ← ← ← ← ← ←
   *                  ↑   Transit Gateway
   *                  ↑         ↓
   *                    ← ← ← ←
   */
  const connectionPoints = [
    ...curveDownLeft(sourcePoint[0], sourceY, linkSpacing),
    ...curveLeftUp(middleX, sourceY, linkSpacing),
    ...curveUpLeft(middleX, targetY, linkSpacing),
    ...curveLeftUp(targetPoint[0], targetY, linkSpacing)
  ];
  return { sourceAnchor: 'bottom', targetAnchor: 'bottom', connectionPoints };
};

/**
 *       Transit Gateway
 *             ↑
 *             ↓
 *        → ← →
 *       ↑
 *       ↓
 *       ↑
 *        ← → ←
 *             ↑
 *             ↓
 *       Transit Gateway
 */
const connectTransitGatewayToTransitGateway = ({
  sourcePoint,
  targetPoint,
  directConnectWidth,
  ySpacing,
  linkSpacing
}) => {
  const isSourceOnTop = sourcePoint[1] < targetPoint[1];
  const leftX = sourcePoint[0] - directConnectWidth / 2 - linkSpacing;

  const topY = Math.min(targetPoint[1], sourcePoint[1]) + ySpacing;
  const bottomY = Math.max(targetPoint[1], sourcePoint[1]) - ySpacing;

  if (isSourceOnTop) {
    const connectionPoints = [
      ...curveDownLeft(sourcePoint[0], topY, linkSpacing),
      ...curveLeftDown(leftX, topY + linkSpacing, linkSpacing),
      ...curveDownRight(leftX, bottomY - linkSpacing, linkSpacing),
      ...curveRightDown(targetPoint[0], bottomY, linkSpacing)
    ];

    return { sourceAnchor: 'bottom', targetAnchor: 'top', connectionPoints };
  }

  const connectionPoints = [
    ...curveUpLeft(sourcePoint[0], bottomY, linkSpacing),
    ...curveLeftUp(leftX, bottomY, linkSpacing * 3),
    ...curveUpRight(leftX, topY, linkSpacing * 3),
    ...curveRightUp(targetPoint[0], topY, linkSpacing)
  ];

  return { sourceAnchor: 'top', targetAnchor: 'bottom', connectionPoints };
};
/**
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const GatewayConnector = ({ sourceType, targetType, ...rest }) => {
  const alignedConnection = connectAlignedNodes({ ...rest });

  const transitGatewayTypes = [AWS_ENTITY_TYPES.TRANSIT_GATEWAY, AWS_ENTITY_TYPES.CORE_NETWORK_EDGE];
  if (transitGatewayTypes.includes(sourceType) && transitGatewayTypes.includes(targetType)) {
    return connectTransitGatewayToTransitGateway({ ...rest });
  }

  switch (targetType) {
    case AWS_ENTITY_TYPES.TRANSIT_GATEWAY:
    case AWS_ENTITY_TYPES.CORE_NETWORK_EDGE:
      return connectToTransitGateway({ ...rest });

    // transit gateway attachment
    case 'subnet':
    case AZURE_ENTITY_TYPES.SUBNET:
      return alignedConnection !== null ? alignedConnection : connectToSubnet({ ...rest });

    case AWS_ENTITY_TYPES.VPC:
    case AWS_ENTITY_TYPES.VPC_PEERING_CONNECTION:
    case AWS_ENTITY_TYPES.CORE_NETWORK_ATTACHMENT:
    case AWS_ENTITY_TYPES.TRANSIT_GATEWAY_ATTACHMENT:
      return connectToAnyAttachment({ ...rest });

    case 'Lags':
    case AWS_ENTITY_TYPES.VPN_CONNECTION:
    case AWS_ENTITY_TYPES.DIRECT_CONNECTION:
    case AWS_ENTITY_TYPES.DIRECT_CONNECTION_GATEWAY:
      return connectToAnyConnectionOnPrem({ ...rest });

    case 'internet':
      return connectGatewayToInternetBox({ ...rest });
    // /** @TODO other target types */
    default:
      return null;
  }
};

export default GatewayConnector;
