import React from 'react';
import { Text, Icon, Box, Flex } from 'core/components';
import OttIcon from 'app/views/serviceProvider/ott/OttIcon';
import ResultMenuItem from './ResultMenuItem';

function OttService({ item, selected, query, onItemClick, ...rest }) {
  const { id, path, name, type, provider } = item;
  return (
    <ResultMenuItem
      {...rest}
      selected={selected}
      onClick={() => onItemClick({ type: 'ott_service', id, path }, 'Search Results')}
    >
      <Flex gridArea="icon" alignItems="center" justifyContent="center">
        <OttIcon type="type" value={type.name} iconSize={20} />
      </Flex>

      <Box gridArea="title">
        <Text className="title">{name}</Text>
      </Box>

      <Flex gridArea="rightIcon" alignItems="center" justifyContent="center">
        <Icon icon={selected ? 'key-enter' : undefined} color="#ffffff" />
      </Flex>

      <Text
        className="description"
        gridArea="description"
        muted
        small
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        style={{ overflow: 'hidden' }}
      >
        Type: {type.name}, Provider: {provider.name}
      </Text>
    </ResultMenuItem>
  );
}

export default OttService;
