import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Callout, Link, Box, Spinner } from 'core/components';
import Page from 'app/components/page/Page';
import PageHeading from 'app/components/page/PageHeading';

@inject('$users')
@observer
export default class VerifyEmail extends Component {
  state = {
    reqComplete: false
  };

  componentDidMount() {
    const { $users } = this.props;
    const token = this.getSearchParam('token');
    $users.updateUserEmail(token).then((res) => {
      if (res.success) {
        this.setState({ reqComplete: true, success: true });
      } else {
        this.setState({ reqComplete: true, success: false, msg: res.msg });
      }
    });
  }

  getSearchParam(name) {
    const { location } = this.props;
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  render() {
    const { reqComplete, success, msg } = this.state;
    return (
      <Page title="Email Verification" hideSubnav locked>
        <PageHeading title="Email Verification" />
        {!reqComplete && (
          <Callout p={2} flexDirection="column" alignItems="center" justifyContent="center">
            <Box>
              <Spinner />
            </Box>
            <Box>
              <p>Verifying email address update...</p>
            </Box>
          </Callout>
        )}
        {reqComplete && success && (
          <Callout intent="success" alignItems="center" justifyItems="center" p={2} title="Success">
            <Box>
              <p>Your email address has been updated.</p>
              <Link to="/">Return to Kentik</Link>
            </Box>
          </Callout>
        )}
        {reqComplete && !success && (
          <Callout intent="danger" alignItems="center" justifyItems="center" p={2} title="Unable to update email">
            <Box>
              <p>{msg}</p>
              <Link to="/">Return to Kentik</Link>
            </Box>
          </Callout>
        )}
      </Page>
    );
  }
}
