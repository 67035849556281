import React from 'react';
import $app from 'app/stores/$app';
import KentikLogo from 'app/components/KentikLogo';

const PoweredByKentik = ({ style, blackText }) => (
  <div style={{ display: 'inline-block', ...style }}>
    <span style={{ fontWeight: 500 }}>Powered by</span>
    <a href="https://www.kentik.com" target="_blank" rel="noopener noreferrer">
      <KentikLogo
        blackText={blackText || !$app.darkThemeEnabled}
        style={{ width: 70, verticalAlign: 'bottom', left: 8 }}
      />
    </a>
  </div>
);

export default PoweredByKentik;
