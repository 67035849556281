import React from 'react';
import { inject, observer } from 'mobx-react';
import { Box, EmptyState, Flex, Grid, MenuItem, Spinner } from 'core/components';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { showErrorToast, showInfoToast } from 'core/components/toast';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import HoneycombLayout, {
  getHoneycombHeight,
  getHoneycombWidth
} from 'app/views/hybrid/maps/layouts/honeycomb/HoneycombLayout';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { IoIosGitNetwork } from 'react-icons/io';
import { getASNValues } from 'app/util/queryResults';
import { addFilters } from 'app/stores/query/FilterUtils';
import CircleLayout, { SPACING } from '../layouts/CircleLayout';
import AbstractMap from '../components/AbstractMap';
import withPopover from '../components/popovers/withPopover';
import { getHealthClass } from '../../utils/health';
import { BASE_ROUTE } from '../../kube';

import KubeBoxLinkGenerator from './KubeBoxLinkGenerator';
import TopLevelBox from '../components/TopLevelBox';
import TopKeys from '../components/TopKeys/TopKeys';
import TopServicesIPs from '../components/TopKeys/TopServicesIPs';
import TrafficLinkGenerator from '../components/TrafficLinkGenerator';
import {
  getInfrastructureDimension,
  getInfrastructureFilterGroup,
  getInternetDimension,
  getInternetFilterField,
  getKubeFilterGroup
} from '../components/popovers/queryOptionsHelper';
import ResourceItem from './ResourceItem';
import ParentItem from './ParentItem';
import RelatedClusters from './RelatedClusters';

const srcNamespaceDimension = 'ktsubtype__kappa__STR09';
const dstNamespaceDimension = 'ktsubtype__kappa__STR15';

const { CLUSTER } = ENTITY_TYPES.get('kube');
const HONEYCOMB_LAYOUT_THRESHOLD = 20;

@withPopover
@inject('$explorer', '$hybridMap', '$query')
@observer
class ClusterDetailsMap extends AbstractMap {
  constructor(props) {
    super(props);

    if (process.env.NODE_ENV !== 'production') {
      window.ClusterDetailsMap = this;
    }

    Object.assign(this.state, {
      loading: true,
      activeInfrastructureTabId: 'services',
      boxExpanded: {
        infrastructure: true,
        internet: true
      },
      relatedClusters: [],
      cloudProvider: null
    });

    this.getQueryResultKey = this.getQueryResultKey.bind(this);
  }

  componentDidMount() {
    this.fetchTopology();
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarSettings, drawerIsOpen } = this.props;
    const { activeNode } = this.state;
    const mapSearchChanged = prevProps.sidebarSettings.searchTerm !== sidebarSettings.searchTerm;
    const timeSettingsChanged =
      prevProps.sidebarSettings.timeRange.start !== sidebarSettings.timeRange.start ||
      prevProps.sidebarSettings.timeRange.end !== sidebarSettings.timeRange.end;
    const drawerChanged = prevProps.drawerIsOpen !== drawerIsOpen;
    const activeNodeChanged = prevProps.activeNode !== activeNode;

    if (mapSearchChanged) {
      this.topologyCollection.filter(sidebarSettings.searchTerm);
    }

    if (timeSettingsChanged) {
      this.fetchTopology();
      return;
    }

    if (drawerChanged) {
      // this.getRegionBoxPositions.cache.clear();
    }

    if (activeNodeChanged) {
      if (!activeNode) {
        // this.topologyCollection.resetHighlightedNodes();
      }

      // this.calculateStaticLinks.cache.clear();
    }
    super.componentDidUpdate(prevProps, prevState);
  }

  /**
   * @returns {import('app/stores/hybrid/KubeCloudMapCollection').default}
   */
  get topologyCollection() {
    const { $hybridMap } = this.props;
    return $hybridMap.kubeCloudMapCollection;
  }

  get cluster() {
    const { clusterId, clusterName, cloud } = this.props;

    if (clusterId) {
      return this.topologyCollection.topology.Entities.clusters?.[clusterId];
    }

    return Object.values(this.topologyCollection.topology.Entities.clusters).find(
      (topologyCluster) =>
        topologyCluster.name === clusterName && (cloud ? topologyCluster.cloud_provider === cloud : true)
    );
  }

  get deviceName() {
    return this.cluster?.device_name || 'unknown';
  }

  get relatedClusters() {
    const { relatedClusters } = this.state;
    const { topology } = this.topologyCollection;
    return relatedClusters.map((id) => topology.Entities.clusters?.[id] || { id, name: id });
  }

  /**
   * Get latencies for all namespaces in selected cluster
   * @returns {Promise}
   */
  async getLatency() {
    const { $query } = this.props;
    const { deviceName } = this;

    const latencyMetric = 'p98th_ktsubtype__kappa__APPL_LATENCY_MS';
    const aggregateTypes = [latencyMetric];

    const latencies = { cluster: 0, objects: new Map() };

    return Promise.all([
      $query.runQuery(this.getQuery({ aggregateTypes }, deviceName), true, true).then(({ results }) => {
        if (results.size > 0) {
          latencies.cluster = results.models[0].get(latencyMetric);
        }
      }),
      $query
        .runQuery(
          this.getQuery(
            {
              metric: [srcNamespaceDimension],
              aggregateTypes
            },
            deviceName
          ),
          true,
          true
        )
        .then(({ results }) => {
          results.each((row) => {
            const namespaceName = row.get('kt_k8s_src_pod_ns');
            const latency = row.get(latencyMetric);
            latencies.objects.set(namespaceName, latency);
          });
        })
    ]).then(() => latencies);
  }

  async getRelatedClusters() {
    const { clusterId, $query } = this.props;
    const { deviceName } = this;

    return $query
      .runQuery(this.getQuery({ metric: ['IP_src', 'IP_dst'] }, deviceName), true, true)
      .then(({ results }) => {
        const clusterNames = new Set();

        results.each((result) => {
          this.getQueryResultKey(result)
            .split(' ---- ')
            .forEach((c) => clusterNames.add(c));
        });

        [clusterId, '---'].forEach((ignoreName) => clusterNames.delete(ignoreName));

        return Array.from(clusterNames);
      });
  }

  // Defines if links should be rolled up into one link
  shouldTargetLinkRollUp(type) {
    return type !== 'clusters' && type !== 'namespaces';
  }

  getClusterFromIp(cidr) {
    const { topologyCollection } = this;
    const ip = cidr.replace(/\/\d+$/, '');
    return topologyCollection
      .get()
      .find((model) => model.get('entityType') === 'clusters' && model.searchableData.cidrs.includes(ip))?.id;
  }

  getQueryResultKey(result) {
    const { clusterId } = this.props;
    let key = result.get('lookup') || result.get('key');

    // replace ip -> cluster id
    const srcIp = result.get('inet_src_addr');
    const dstIp = result.get('inet_dst_addr');

    if (srcIp) {
      const srcCluster = this.getClusterFromIp(srcIp) || '---';
      key = key.replace(srcIp, srcCluster);
    }

    if (dstIp) {
      const dstCluster = this.getClusterFromIp(dstIp) || '---';
      key = key.replace(dstIp, dstCluster);
    }

    // replace namespace name -> namespace id
    const srcNamespace = result.get('kt_k8s_src_pod_ns');
    const dstNamespace = result.get('kt_k8s_dst_pod_ns');

    if (srcNamespace && srcNamespace !== '---') {
      key = key.replace(srcNamespace, `${clusterId}-${srcNamespace}`);
    }

    if (dstNamespace && dstNamespace !== '---') {
      key = key.replace(dstNamespace, `${clusterId}-${dstNamespace}`);
    }

    return key;
  }

  get relatedClustersEntities() {
    const { relatedClusters } = this.state;

    return relatedClusters.map((clusterName) => {
      const entity = this.topologyCollection.getEntity({ entityType: CLUSTER, entityId: clusterName });
      return entity;
    });
  }

  get relatedClustersDevices() {
    return this.relatedClustersEntities
      .map((clusterEntity) => clusterEntity?.device_name || null)
      .filter((device) => device);
  }

  getNodeLinkQueries({ selectedNode, activeInternetTabId, activeInfrastructureTabId } = this.state) {
    const { relatedClusters } = this.state;
    const queries = [];

    if (selectedNode) {
      const { deviceName } = this;
      const { type: source, value } = selectedNode;
      const entity = this.topologyCollection.getEntity({ entityType: source, entityId: value });
      const { name } = entity;

      // related cluster <-> namespaces
      if (name && source === CLUSTER) {
        const clusterDeviceName = entity?.device_name || null;
        queries.push({
          type: 'relatedClusters',
          getKey: this.getQueryResultKey,
          keyTypes: ['src-clusters', 'src-namespaces', 'dst-namespaces', 'dst-clusters'],
          allowPairs: [
            ['src-clusters', 'dst-namespaces'],
            ['src-namespaces', 'dst-clusters']
          ],
          selectedNode,
          queries: [
            this.getQuery(
              {
                metric: ['IP_src', srcNamespaceDimension, dstNamespaceDimension, 'IP_dst'],
                aggregateTypes: ['avg_bits_per_sec', 'avg_in_bits_per_sec', 'avg_out_bits_per_sec']
              },
              clusterDeviceName
            )
          ]
        });

        // clusters <-> internet
        queries.push({
          type: 'relatedClustersInternet',
          getKey: this.getQueryResultKey,
          keyTypes: ['src-internet', 'src-clusters', 'dst-clusters', 'dst-internet'],
          allowPairs: [
            ['src-internet', 'dst-clusters'],
            ['src-clusters', 'dst-internet']
          ],
          selectedNode,
          queries: [
            this.getQuery(
              {
                metric: [
                  getInternetDimension({ subType: activeInternetTabId, direction: 'src' }),
                  'IP_src',
                  'IP_dst',
                  getInternetDimension({ subType: activeInternetTabId, direction: 'dst' })
                ],
                aggregateTypes: ['avg_bits_per_sec', 'avg_in_bits_per_sec', 'avg_out_bits_per_sec'],
                filters: { connector: 'All', filterGroups: [getKubeFilterGroup({ internet: true })] }
              },
              clusterDeviceName
            )
          ]
        });

        // clusters <-> infrastructure
        queries.push({
          type: 'relatedClustersInfrastructure',
          getKey: this.getQueryResultKey,
          keyTypes: ['src-infrastructure', 'src-clusters', 'dst-clusters', 'dst-infrastructure'],
          allowPairs: [
            ['src-infrastructure', 'dst-clusters'],
            ['src-clusters', 'dst-infrastructure']
          ],
          selectedNode,
          queries: [
            this.getQuery(
              {
                metric: [
                  getInfrastructureDimension({ subType: activeInfrastructureTabId, direction: 'src' }),
                  'IP_src',
                  'IP_dst',
                  getInfrastructureDimension({ subType: activeInfrastructureTabId, direction: 'dst' })
                ],
                aggregateTypes: ['avg_bits_per_sec', 'avg_in_bits_per_sec', 'avg_out_bits_per_sec'],
                filters: { connector: 'All', filterGroups: [getKubeFilterGroup({ infrastructure: true })] }
              },
              clusterDeviceName
            )
          ]
        });
      }

      if (name && source === 'namespaces') {
        // namespaces <-> namespaces
        queries.push({
          type: 'namespaces',
          getKey: this.getQueryResultKey,
          keyTypes: ['namespaces', 'namespaces'],
          selectedNode,
          queries: [this.getQuery({ metric: [srcNamespaceDimension, dstNamespaceDimension] }, deviceName, name)]
        });

        if (relatedClusters.length > 0) {
          queries.push({
            type: 'namespaceClusters',
            getKey: this.getQueryResultKey,
            keyTypes: ['src-clusters', 'src-namespaces', 'dst-namespaces', 'dst-clusters'],
            allowPairs: [
              ['src-clusters', 'dst-namespaces'],
              ['src-namespaces', 'dst-clusters']
            ],
            selectedNode,
            queries: [
              this.getQuery(
                { metric: ['IP_src', srcNamespaceDimension, dstNamespaceDimension, 'IP_dst'] },
                deviceName,
                name
              )
            ]
          });
        }
      }

      // namespaces <-> internet
      if ((name && source === 'namespaces') || source === 'internet') {
        const srcInternetDimension = getInternetDimension({ subType: activeInternetTabId, direction: 'src' });
        const dstInternetDimension = getInternetDimension({ subType: activeInternetTabId, direction: 'dst' });
        const filters = { connector: 'All', filterGroups: [] };

        if (source === 'internet') {
          filters.filterGroups.push({
            filters: [
              {
                filterField: getInternetFilterField({ subType: activeInternetTabId }),
                operator: '=',
                filterValue: getASNValues(value).asnList
              }
            ]
          });
        }

        filters.filterGroups.push(getKubeFilterGroup({ internet: true }));

        queries.push({
          type: 'namespacesInternet',
          getKey: this.getQueryResultKey,
          keyTypes: ['src-internet', 'src-namespaces', 'dst-namespaces', 'dst-internet'],
          allowPairs: [
            ['src-internet', 'dst-namespaces'],
            ['src-namespaces', 'dst-internet']
          ],
          selectedNode,
          queries: [
            this.getQuery(
              {
                metric: [srcInternetDimension, srcNamespaceDimension, dstNamespaceDimension, dstInternetDimension],
                filters
              },
              deviceName,
              source === 'namespaces' ? name : null
            )
          ]
        });

        if (source === 'internet' && relatedClusters.length > 0) {
          const devicesToQuery = [deviceName, ...this.relatedClustersDevices];
          queries.push({
            type: 'clustersInternet',
            getKey: this.getQueryResultKey,
            keyTypes: ['src-clusters', 'src-internet', 'dst-internet', 'dst-clusters'],
            allowPairs: [
              ['src-clusters', 'dst-internet'],
              ['src-internet', 'dst-clusters']
            ],
            selectedNode,
            queries: [
              this.getQuery(
                {
                  metric: ['IP_src', srcInternetDimension, dstInternetDimension, 'IP_dst'],
                  filters
                },
                devicesToQuery
              )
            ]
          });
        }
      }

      // namespaces <-> infrastructure
      if ((name && source === 'namespaces') || source === 'infrastructure') {
        const srcInfrastructureDimension = getInfrastructureDimension({
          subType: activeInfrastructureTabId,
          direction: 'src'
        });
        const dstInfrastructureDimension = getInfrastructureDimension({
          subType: activeInfrastructureTabId,
          direction: 'dst'
        });
        const filters = { connector: 'All', filterGroups: [] };

        if (source === 'infrastructure') {
          filters.filterGroups.push(getInfrastructureFilterGroup({ subType: activeInfrastructureTabId, value }));
        }

        filters.filterGroups.push(getKubeFilterGroup({ infrastructure: true }));

        queries.push({
          type: 'namespacesInfrastructure',
          getKey: this.getQueryResultKey,
          keyTypes: ['src-infrastructure', 'src-namespaces', 'dst-namespaces', 'dst-infrastructure'],
          allowPairs: [
            ['src-infrastructure', 'dst-namespaces'],
            ['src-namespaces', 'dst-infrastructure']
          ],
          selectedNode,
          queries: [
            this.getQuery(
              {
                metric: [
                  srcInfrastructureDimension,
                  srcNamespaceDimension,
                  dstNamespaceDimension,
                  dstInfrastructureDimension
                ],
                filters
              },
              deviceName,
              source === 'namespaces' ? name : null
            )
          ]
        });

        if (source === 'infrastructure' && relatedClusters.length > 0) {
          const devicesToQuery = [deviceName, ...this.relatedClustersDevices];
          queries.push({
            type: 'clustersInfrastructure',
            getKey: this.getQueryResultKey,
            keyTypes: ['src-clusters', 'src-infrastructure', 'dst-infrastructure', 'dst-clusters'],
            allowPairs: [
              ['src-clusters', 'dst-infrastructure'],
              ['src-infrastructure', 'dst-clusters']
            ],
            selectedNode,
            queries: [
              this.getQuery(
                {
                  metrics: ['IP_src', srcInfrastructureDimension, dstInfrastructureDimension, 'IP_dst'],
                  filters
                },
                devicesToQuery
              )
            ]
          });
        }
      }
    }

    return queries;
  }

  /**
   * @param {Object} query
   * @param {string | null} [device_name]
   * @param {string | null} [namespace]
   * @returns {Object}
   */
  getQuery(query, device_name = null, namespace = null) {
    const { $hybridMap } = this.props;

    if (namespace) {
      addFilters(query, [
        { filterField: 'ktsubtype__kappa__STR09src|dstktsubtype__kappa__STR15', operator: '=', filterValue: namespace }
      ]);
    }

    // ensure its defaulted to empty array in case if device_name is not passed
    const deviceNameToQuery = (Array.isArray(device_name) ? device_name : [device_name]).filter((name) => name);

    return $hybridMap.getQuery({
      all_devices: false,
      device_types: device_name ? [] : ['kappa'],
      device_name: deviceNameToQuery,
      hostname_lookup: false,
      show_overlay: false,
      show_total_overlay: false,
      aggregateTypes: ['avg_bits_per_sec'],
      viz_type: 'table',
      depth: 5000,
      topx: 5000,
      ...query
    });
  }

  async fetchTopology() {
    const { sidebarSettings, $hybridMap } = this.props;
    const { topologyCollection } = this;

    this.setState({ loading: true });

    if (this.pendingPromise) {
      this.pendingPromise.cancel();
    }

    this.pendingPromise = makeCancelable(topologyCollection.fetch({ force: true, query: sidebarSettings.timeRange }));

    return this.pendingPromise.promise
      .then(() => Promise.all([this.getLatency(), this.getRelatedClusters()]))
      .then(([latencies, relatedClusters]) => {
        const { cluster } = this;
        // assign cluster latency if > 0
        if (latencies.cluster) {
          const latency = latencies.cluster;
          const healthData = { state: latency > $hybridMap.latencyThreshold ? 'CRITICAL' : 'GOOD' };
          Object.assign(cluster, { latency, health: { cssClassName: getHealthClass(healthData), data: healthData } });
        }

        // get all namespaces associated with the cluster
        this.topologyCollection
          .getEntities('namespaces')
          .filter((namespace) => namespace.clusterId === cluster.id && latencies.objects.has(namespace.name))
          .forEach((namespace) => {
            // assign the latency and health from query results to each namespace object
            const latency = latencies.objects.get(namespace.name);
            const healthData = { state: latency > $hybridMap.latencyThreshold ? 'CRITICAL' : 'GOOD' };

            Object.assign(namespace, {
              latency,
              health: { cssClassName: getHealthClass(healthData), data: healthData }
            });
          });

        const entity = this.topologyCollection.getEntity({ entityType: 'clusters', entityId: cluster.id });
        const { cloudProvider } = entity;

        this.setState({ loading: false, relatedClusters, cloudProvider });
      })
      .catch((err) => {
        this.setState({ loading: false }, () => {
          if (!(err instanceof CanceledError)) {
            const { deviceName } = this;
            showErrorToast(`Unable to load traffic data, device ${deviceName} not found.`);
          } else {
            console.warn('Promise was canceled.');
          }
        });
      })
      .finally(() => {
        this.pendingPromise = null;
        this.setState({ loading: false });
      });
  }

  handleNamespaceSelect = (namespace) => {
    if (namespace) {
      this.handleSelectNode({ type: 'namespaces', value: namespace.id, nodeData: namespace });
    } else {
      this.handleSelectNode({ value: null });
    }
  };

  handleInfrastructureTabChange = (tabId) => {
    const { selectedNode } = this.state;

    this.setState({ activeInfrastructureTabId: tabId });

    if (selectedNode?.type === 'infrastructure') {
      this.handleSelectNode({ value: null });
    }
  };

  handleSelectNode({ type, subType, value, nodeData, model, event }) {
    const { isEmbedded, openPopover } = this.props;
    const { selectedNode, cloudProvider } = this.state;
    const { deviceName } = this;

    if (event) {
      event.stopPropagation();
    }

    super.handleSelectNode({ type, subType, value, nodeData, event });

    this.setState((prevState) => {
      if (prevState.selectedNode) {
        const isSameNode = isEqual(selectedNode, prevState.selectedNode);
        const { node } = this.getNodePosition(prevState.selectedNode);

        if (node) {
          const { x, y, width, height } = node;

          const config = {
            ...prevState.selectedNode,
            deviceName,
            cloudProvider,
            isKubeDetail: true,
            isEmbedded,
            showHealth: nodeData?.health,
            showTitle: nodeData?.name,
            showHealthCallout: type === 'namespaces',
            position: { left: x, top: y, width, height },
            model,
            shortcutMenu: {
              selectedNode: prevState.selectedNode,
              showConnectionsCallback: this.setNodeLinks,
              isShowingConnections: prevState.nodeLinks.length > 0 && isSameNode,
              customItems: this.getCustomMenuItems(nodeData)
            }
          };

          openPopover(config);
        }
      }

      return null;
    });
  }

  handleNodeLinkClick = (config) => {
    const { setSidebarDetails } = this.props;
    const { activeInternetTabId, activeInfrastructureTabId } = this.state;
    const { deviceName, topologyCollection } = this;
    const { source, target } = config;

    if (source.type === 'internet') {
      source.subType = activeInternetTabId;
    } else if (source.type === 'infrastructure') {
      source.subType = activeInfrastructureTabId;
    }

    if (target.type === 'internet') {
      target.subType = activeInternetTabId;
    } else if (target.type === 'infrastructure') {
      target.subType = activeInfrastructureTabId;
    }

    setSidebarDetails({
      ...config,
      deviceName,
      source: {
        ...source,
        nodeData: topologyCollection.getEntity({ entityType: source.type, entityId: source.value })
      },
      target: {
        ...target,
        nodeData: topologyCollection.getEntity({ entityType: target.type, entityId: target.value })
      }
    });
  };

  hasResourcesToDisplay(clusterId, namespaceName) {
    const resourceTypes = ['services', 'ingresses', 'deployments'];

    const resourcesToDisplay = resourceTypes.flatMap((type) =>
      this.topologyCollection
        .getEntities(type)
        .filter((resource) => resource.clusterId === clusterId && resource.metadata.namespace === namespaceName)
    );

    return resourcesToDisplay.length > 0;
  }

  getCustomMenuItems(nodeData) {
    const { history } = this.props;
    const { cluster } = this;

    if (nodeData?.type !== 'namespaces') {
      return null;
    }
    const mapType = nodeData.type.replace(/s$/, '');

    return [
      <MenuItem
        disabled={!this.hasResourcesToDisplay(cluster.id, nodeData.name)}
        key="view-topology"
        text="View Topology"
        onClick={() => history.push(`${BASE_ROUTE}/cluster/${cluster.id}/${mapType}/${nodeData.name}`)}
        icon={IoIosGitNetwork}
      />
    ];
  }

  handleBoxLinksUpdate = (boxLinks) => {
    const links = this.cleanBoxLinkTraffic(boxLinks);
    this.setState({ boxLinks: links });
  };

  /**
   * @override
   * @borrowed from CloudAwsMap
   */
  handleNodeLinksUpdate(links) {
    const { cluster } = this;
    const { selectedNode } = this.state;
    const processedLinks = links
      .flatMap((l) => l.links)
      .filter((link) => !(link.target.type === 'clusters' && link.target.value === cluster.id));

    if (processedLinks.length === 0 && selectedNode) {
      showInfoToast('No connections were found.');
    }

    const nodeLinks = [...this.getNodeLinks(), ...processedLinks];

    this.setState({
      nodeLinks,
      nodeLinksLoading: false
    });
  }

  get namespaces() {
    const { cluster } = this;
    return (
      this.topologyCollection.getEntities('namespaces').filter((namespace) => namespace.clusterId === cluster.id) || []
    );
  }

  get isHoneycombLayout() {
    return this.namespaces.length > HONEYCOMB_LAYOUT_THRESHOLD;
  }

  get NamespacesChartComponent() {
    return this.isHoneycombLayout ? HoneycombLayout : CircleLayout;
  }

  get chartWidth() {
    const { width } = this.props;
    if (this.isHoneycombLayout) {
      return getHoneycombWidth({ width, count: this.namespaces.length });
    }

    // do not exceed screen width
    return Math.min(width, Math.max(585, (this.namespaces.length / 2) * 50 + 200));
  }

  get chartHeight() {
    const { width } = this.props;
    if (this.isHoneycombLayout) {
      return getHoneycombHeight({ width, count: this.namespaces.length }) + 100;
    }

    return this.chartWidth;
  }

  renderMap() {
    const { $hybridMap } = this.props;
    const { loading, boxExpanded, activeInfrastructureTabId, nodeLinkQueries, nodeLinksLoading } = this.state;
    const topBoxHeight = 233;

    if (loading) {
      return <Spinner mt={100} />;
    }

    const { cluster, deviceName, relatedClusters } = this;

    if (!cluster) {
      return <EmptyState icon="search" title="Cluster not found" />;
    }

    const namespaces =
      this.topologyCollection.getEntities('namespaces').filter((namespace) => namespace.clusterId === cluster?.id) ||
      [];

    // null checking
    if (!namespaces || namespaces.length === 0) {
      return <EmptyState icon="search" title="Namespaces not found" />;
    }

    const { NamespacesChartComponent } = this;

    return (
      <Box>
        <Grid
          gridTemplateColumns="1fr auto 1fr"
          gridTemplateAreas="'infrastructure parents internet' '. spinner .' 'kube kube kube'"
          gridGap={5}
          gridRowGap={3}
        >
          <Box gridArea="infrastructure">
            <TopLevelBox
              title="Other Infrastructure"
              boxProps={{
                className: classNames('box-infrastructure', 'link-bottomright', {
                  highlighted: this.isBoxHighlighted('infrastructure')
                }),
                height: topBoxHeight,
                overflow: 'hidden'
              }}
              onExpandToggle={(isExpanded) => this.setBoxExpanded('infrastructure', isExpanded)}
              expandedContent={
                <TopServicesIPs
                  classPrefix="infrastructure"
                  selected={this.getSelected('infrastructure')}
                  highlighted={this.getHighlighted('infrastructure')}
                  onSelect={(value, { event }) =>
                    this.handleSelectNode({ type: 'infrastructure', subType: activeInfrastructureTabId, value, event })
                  }
                  onTabChange={this.handleInfrastructureTabChange}
                  device={deviceName}
                />
              }
              isExpanded={boxExpanded.infrastructure}
              keepChildrenMounted
            />
          </Box>
          <Box gridArea="parents" alignSelf="center" pt={27} overflow="hidden">
            <ParentItem item={cluster} type="clusters" to={BASE_ROUTE} />
          </Box>

          <Box gridArea="internet">
            <TopLevelBox
              title="Internet"
              boxProps={{
                className: classNames('box-internet', 'link-bottomleft', {
                  highlighted: this.isBoxHighlighted('internet')
                }),
                height: topBoxHeight,
                overflow: 'hidden'
              }}
              onExpandToggle={(isExpanded) => this.setBoxExpanded('internet', isExpanded)}
              expandedContent={
                <TopKeys
                  classPrefix="internet"
                  selected={this.getSelected('internet')}
                  hovered={this.getHovered('internet')}
                  highlighted={this.getHighlighted('internet')}
                  onSelect={(internet, { event }) =>
                    this.handleSelectNode({
                      type: 'internet',
                      value: internet,
                      nodeData: { subType: 'internet-k8' },
                      event
                    })
                  }
                  onTabChange={this.handleInternetTabChange}
                  showProvidersTab={false}
                  showNextHopNetworksTab={false}
                  isKube
                  device={deviceName}
                />
              }
              isExpanded={boxExpanded.internet}
              keepChildrenMounted
            />
          </Box>

          <Box gridArea="spinner" justifySelf="center" width={50} height={50}>
            {nodeLinksLoading && <Spinner />}
          </Box>

          <Box gridArea="kube">
            <TopLevelBox
              isExpanded
              boxProps={{ p: 0, className: 'box-kube' }}
              expandedContent={
                <Flex
                  gap={2}
                  alignItems="flex-start"
                  justifyContent="center"
                  py={4}
                  px={2}
                  width="100%"
                  flexDirection={this.isHoneycombLayout ? 'column' : 'row'}
                >
                  <RelatedClusters
                    clusters={relatedClusters}
                    highlighted={this.getHighlighted('clusters')}
                    handleOnClick={this.handleSelectNode}
                  />
                  <Flex flexWrap="wrap" alignItems="center" justifyContent="center" width="100%" p={2}>
                    <svg width={this.chartWidth} height={this.chartHeight} display="block">
                      <g transform={`translate(0, ${this.isHoneycombLayout ? 50 : 0})`}>
                        <NamespacesChartComponent
                          titleStyles={{ fontSize: '13px', fontWeight: 500 }}
                          width={this.chartWidth}
                          spacing={SPACING.Equal}
                          items={namespaces}
                          renderItem={(props) => (
                            <ResourceItem
                              type="namespaces"
                              empty={!this.hasResourcesToDisplay(props.item.clusterId, props.item.name)}
                              titleStyles={{ fontSize: '13px', fontWeight: 500 }}
                              {...props}
                            />
                          )}
                          selected={this.getSelected('namespaces')}
                          highlighted={this.getHighlighted('namespaces')}
                          onSelect={this.handleNamespaceSelect}
                        />
                      </g>
                    </svg>
                  </Flex>
                </Flex>
              }
            />
          </Box>
        </Grid>

        <KubeBoxLinkGenerator
          debug
          onBoxLinksUpdate={this.handleBoxLinksUpdate}
          {...$hybridMap.kubeStateAlt}
          kubeLabel={cluster.name}
          deviceName={deviceName}
        />
        <TrafficLinkGenerator debug inputs={nodeLinkQueries} onLinksUpdate={this.handleNodeLinksUpdate} />
      </Box>
    );
  }
}

export default ClusterDetailsMap;
