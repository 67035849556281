import * as React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { PopoverInteractionKind, PopoverPosition } from '@blueprintjs/core';
import { formatBytesGreek, toDecimal } from 'core/util';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import { getDateRangeDisplay } from 'core/util/dateUtils';
import { Box, Button, Card, Heading, Icon, Flex, Popover, Sparkline, Tag, Text, EmptyState } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import MetricsExplorerButton from 'app/views/metrics/MetricsExplorerButton';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import SearchableTable from 'app/views/hybrid/maps/components/SearchableTable';
import { AZURE_ENTITY_TYPES } from 'shared/hybrid/constants';
import { getCustomProperties } from 'shared/util/map';

@inject('$hybridMap', '$metrics')
@observer
export default class MetricsPanel extends React.Component {
  state = {
    loading: true,
    isPoppedOut: false,
    data: null
  };

  componentDidMount() {
    const { $hybridMap } = this.props;

    this.timeRangeDisposer = reaction(
      () => $hybridMap.sidebarSettings.timeRange,
      () => this.fetchMetrics()
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { nodeData } = this.props;
    const { data, isPoppedOut } = this.state;
    const popOutChanged = prevState.isPoppedOut !== isPoppedOut;

    if (prevProps.nodeData?.id !== nodeData?.id || (data === null && popOutChanged)) {
      // if we have the sidebar open and switch to a new load balancer or change the time range, fetch for new data
      this.fetchMetrics();
    }
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.cancel();
    }

    if (this.timeRangeDisposer) {
      this.timeRangeDisposer();
    }
  }

  fetchMetrics = () => {
    const { $hybridMap, nodeData } = this.props;

    this.setState({ loading: true }, () => {
      this.request = makeCancelable($hybridMap.getAzureMetrics({ id: nodeData.id }));

      this.request.promise
        .then((response) => {
          this.setState({ loading: false, data: response });
        })
        .catch((e) => {
          if (e instanceof CanceledError === false) {
            this.setState({ loading: false }, () => console.error(e));
          }
        });
    });
  };

  handleExpandChange = (isOpenOrPoppedOut) => {
    const { data } = this.state;

    if (isOpenOrPoppedOut && data === null) {
      // fetch for data when expanding for the first time
      this.fetchMetrics();
    }
  };

  handlePopOutChange = (isPoppedOut) => {
    this.setState({ isPoppedOut }, this.handleExpandChange);
  };

  handlePanelRefresh = (e) => {
    e.stopPropagation();
    this.fetchMetrics();
  };

  get title() {
    const { data, isPoppedOut } = this.state;
    // under the hood we support children being able to report their metrics as part of their parent
    // this would be shown as a group summary where we can display the unique time intervals for each metric there
    // since we're not using this feature just yet, just take the first interval and use that as it will be what was used for all the metrics returned
    const displayInterval = data?.metrics?.[0]?.interval?.display;

    return (
      <Flex alignItems="end">
        <Heading level={5} mb={0} mr={1}>
          Metrics
        </Heading>
        {!isPoppedOut && displayInterval && (
          <Text color="muted" small>
            ({displayInterval})
          </Text>
        )}
      </Flex>
    );
  }

  get popOutHeader() {
    const { $hybridMap } = this.props;
    const { data, isPoppedOut } = this.state;
    const displayInterval = data?.metrics?.[0]?.interval?.display;
    const { start, end } = $hybridMap.sidebarSettings.timeRange;

    if (isPoppedOut) {
      return (
        <Text as="div" fontWeight="bold" mb={1}>{`${getDateRangeDisplay(start, end)} ${
          displayInterval ? `(${displayInterval})` : ''
        }`}</Text>
      );
    }

    return null;
  }

  get isSupported() {
    const { type, nodeData } = this.props;

    if (type === AZURE_ENTITY_TYPES.LOAD_BALANCER && nodeData?.sku?.name === 'Basic') {
      return false;
    }

    return true;
  }

  get emptyState() {
    const { cloudProvider } = this.props;

    return (
      <EmptyState
        title="No Metrics Found"
        description={this.isSupported ? null : 'Basic SKUs Are Not Supported'}
        icon={<CloudIcon cloudProvider={cloudProvider} entity="metrics" iconSize={48} />}
      />
    );
  }

  getEntityContextForQuery = () => {
    const { $metrics, nodeData } = this.props;
    const { measurementModelByName, decorateFilterObj } = $metrics;

    const entityDimensionName = 'resourceName';
    const { subscriptionId } = getCustomProperties(nodeData);
    const { type: nodeDataEntityType, name: entityName } = nodeData;

    // argh! inconsistencies abound!
    let type = nodeDataEntityType;
    if (nodeDataEntityType === 'vnetGateways') {
      type = 'Microsoft.Network/virtualNetworkGateways';
    }

    const measurement = `/cloud/azure/${type}`;

    // get the model used for metrics NMS metrics explorer
    const measurementModel = measurementModelByName(measurement);

    if (!measurementModel) {
      // we can't query without a valid model
      return {};
    }

    const selectedFilters = [decorateFilterObj({ filterField: entityDimensionName, filterValue: entityName })];
    const selectedDimensions = [entityDimensionName];
    if (subscriptionId) {
      selectedFilters.push(decorateFilterObj({ filterField: 'subscriptionID', filterValue: subscriptionId }));
      selectedDimensions.push('subscriptionID');
    }

    return { measurementModel, selectedDimensions, selectedFilters };
  };

  getMetricsExplorerQuery = ({ metricName, measurementModel, selectedDimensions = {}, selectedFilters = {} }) => {
    const { $metrics, $hybridMap } = this.props;
    const { getCloudTopologyMetricExplorerLinkQuery } = $metrics;

    if (!metricName) {
      // can't query w/o metricName
      return {};
    }
    const storage = measurementModel?.get('storage');

    if (storage) {
      const { Metrics } = storage;
      if (!Metrics?.[metricName]) {
        // Metrics is array of metric explorer supported metrics for this measurement/entity-type
        // if metricName ain't there, we can't query on it
        // so just return empty object
        return {};
      }
    } else {
      // likewise if we don't have measurementModel storage, the query won't work
      return {};
    }

    const queryParams = {
      selectedMeasurement: measurementModel.get('id'),
      metricName,
      selectedDimensions,
      selectedFilters,
      selectedVisualizationMetric: metricName,
      starting_time: $hybridMap.sidebarSettings.timeRange.start,
      ending_time: $hybridMap.sidebarSettings.timeRange.end
    };

    return getCloudTopologyMetricExplorerLinkQuery(queryParams);
  };

  get columns() {
    const { isPoppedOut } = this.state;
    const { topology } = this.props;

    const { measurementModel, selectedDimensions = [], selectedFilters = [] } = this.getEntityContextForQuery();

    let hasExporterWithEnabledMetrics = topology?.KentikExportersSummary?.EnableMetricsCollection ?? false;

    if (!measurementModel) {
      hasExporterWithEnabledMetrics = false;
    }

    return [
      {
        name: 'name',
        label: 'Metric',
        renderer: ({ value, model }) => {
          const sparklineData = model.get('sparklineData', []);
          const hasData = sparklineData.length > 0;
          const metricName = model.get('metricName') ?? value;
          const metricsExplorerQuery = hasExporterWithEnabledMetrics
            ? this.getMetricsExplorerQuery({ metricName, measurementModel, selectedDimensions, selectedFilters })
            : {};

          return (
            <Popover
              usePortal
              disabled={!hasData || isPoppedOut}
              minimal={false}
              interactionKind={PopoverInteractionKind.HOVER}
              position={PopoverPosition.LEFT}
              modifiers={{
                flip: { enabled: false },
                preventOverflow: { enabled: true, boundariesElement: 'window' }
              }}
              content={
                <Card p={1}>
                  <Box width={150} height={50}>
                    <Sparkline data={sparklineData} width={150} height={50} strokeWidth={0.5} color="primary" />
                  </Box>
                </Card>
              }
            >
              <Flex alignItems="end">
                {
                  // if query is legit, it'll have a kmetrics prop
                  // if we're missing data necessary for the query, metricsExplorerQuery will be empty object
                  // so don't show the link
                  hasExporterWithEnabledMetrics && metricsExplorerQuery?.kmetrics ? (
                    <MetricsExplorerButton
                      openInNewWindow
                      text={value}
                      query={metricsExplorerQuery}
                      iconSize={20}
                      ml={-1}
                      mr={1}
                    />
                  ) : (
                    <>
                      <Icon icon="timeline-line-chart" color={hasData ? 'primary' : 'muted'} mr={1} />
                      <Text>{value}</Text>
                    </>
                  )
                }
              </Flex>
            </Popover>
          );
        }
      },

      ...(isPoppedOut
        ? [
            {
              name: 'sparklineData',
              label: 'Trend',
              flexBasis: 125,
              renderer: ({ value }) => (
                <Box width={250} height={20}>
                  <Sparkline data={value} width={250} height={20} strokeWidth={0.5} color="primary" />
                </Box>
              )
            }
          ]
        : []),

      {
        name: 'average',
        label: 'Avg',
        width: 85,
        renderer: this.renderMetric
      },

      {
        name: 'sum',
        label: 'Sum',
        width: 85,
        renderer: this.renderMetric
      }
    ];
  }

  groupSummary = ({ groupKey, group }) => {
    const label = groupKey === 'undefined' ? 'Ungrouped' : groupKey;
    const interval = group?.[0]?.get('interval.display', null);

    return (
      <Flex alignItems="center">
        <Tag mr={1} minimal>
          {group.length}
        </Tag>
        <Box>
          <Text as="div"> {label}</Text>
          <Text muted>{interval}</Text>
        </Box>
      </Flex>
    );
  };

  renderMetric = ({ value, model, column }) => {
    if (!model.get('supportsTotalAggregation') && column.name === 'sum') {
      return '--';
    }

    const unit = model.get('unit');

    // make sure we display values less than 1
    const fixedValue = toDecimal(value);

    if (['Count', 'CountPerSecond'].includes(unit)) {
      // counts won't get the default 'B' suffix
      return `${formatBytesGreek(fixedValue, '')}${unit === 'CountPerSecond' ? '/s' : ''}`;
    }

    if (['Bytes', 'BytesPerSecond'].includes(unit)) {
      return `${formatBytesGreek(fixedValue)}${unit === 'BytesPerSecond' ? '/s' : ''}`;
    }

    if (unit === 'Percent') {
      return `${formatBytesGreek(fixedValue, '')}%`;
    }

    if (unit === 'BitsPerSecond') {
      return `${formatBytesGreek(fixedValue, 'b')}/s`;
    }

    if (unit === 'MilliSeconds') {
      return `${formatBytesGreek(fixedValue, '')} ms`;
    }

    // log to the Chrome console as a heads up that we've encountered a metric with a unit we haven't implemented before
    // it doesn't necessarily mean it needs any special treatment, but we want to take a look and do so if needed
    console.warn(`Azure metric ${model.get('name')} unit type ${unit} is not implemented in the MetricsPanel`);

    return value;
  };

  render() {
    const { cloudProvider, width, popoutTitle } = this.props;
    const { loading, data } = this.state;

    return (
      <SidebarItem
        excludeFormProps
        title={this.title}
        dialogProps={{ width }}
        popoutTitle={popoutTitle}
        filledIconTag={false}
        icon={<CloudIcon cloudProvider={cloudProvider} entity="metrics" style={{ marginTop: '3px' }} />}
        navigationButtons={
          <Flex gap={1}>
            <Button icon="refresh" color="success" onClick={this.handlePanelRefresh} small minimal />
          </Flex>
        }
        onExpandChange={this.handleExpandChange}
        onPopOutChange={this.handlePopOutChange}
      >
        <Box>
          {this.popOutHeader}
          <SearchableTable
            collectionOptions={{ sortState: { field: 'name', direction: 'asc' } }}
            height={240}
            loading={loading}
            columns={this.columns}
            filterKeys={['name']}
            data={data?.metrics || []}
            emptyState={this.emptyState}
            groupSummary={this.groupSummary}
            stickyHeader
            stickyGroups
          />
        </Box>
      </SidebarItem>
    );
  }
}
