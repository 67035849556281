import { AZURE_ENTITY_TYPES } from 'shared/hybrid/constants';

export function getConsoleUrl({ id, type }) {
  if (!id) {
    return null;
  }

  const initialUrl = `https://portal.azure.com/#@/resource${id}`;

  switch (type) {
    case AZURE_ENTITY_TYPES.ROUTE_TABLE:
      return `${initialUrl}/overview`;
    default:
      return initialUrl;
  }
}

export function getConsoleUrlForResource(resource) {
  if (!resource?.id) {
    return null;
  }
  const parsedType = resource?.type?.split('/').pop() ?? '';

  return getConsoleUrl({
    id: resource.id,
    type: parsedType
  });
}
