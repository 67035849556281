/*
  A collection of panels to be rendered in the map sidebar
  for cloud providers of type Gcp, and entities of type load balancer
*/
import * as React from 'react';
import { Box, EmptyState, Flex, Heading, Tag } from 'core/components';
import { FaCheck } from 'react-icons/fa';

import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';

import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import { GCP_ENTITY_TYPES } from 'shared/hybrid/constants';
import { selectIdToObj, getCustomProperties } from '@kentik/ui-shared/util/map';
import BackendPoolsPanel from './LoadBalancerComponents/BackendPoolsPanel';
import { greekDisplay } from '../cloudUtil';

const { NETWORK, REGION, LOAD_BALANCER } = GCP_ENTITY_TYPES;

const LoadBalancerData = ({ scopedLoadBalancers, cloudProvider, width, popoutTitle }) =>
  scopedLoadBalancers.map((slb) => {
    const {
      backendResources,
      frontEndIP,
      forwardingRuleDesc,
      instanceGroupCount,
      loadBalancerName,
      loadBalancingScheme,
      networkEndpointGroupCount,
      networkTier,
      portRange,
      protocol,
      scope,
      targetProxy,
      targetPoolDesc,
      type: lbType
    } = slb;

    const key = `${loadBalancerName}-${frontEndIP}-${scope}`;

    const lbNode = {
      'Load Balancer Name': loadBalancerName,
      'Load Balancer Type': lbType,
      'Front End IP': frontEndIP,
      Protocol: protocol,
      'Port Range': portRange,
      Scope: scope,
      'Load Balancing Scheme': loadBalancingScheme,
      'Network Tier': networkTier,
      'Forwarding Rule Description': forwardingRuleDesc
    };

    const appLbNode = {
      ...lbNode,
      'Target Proxy': targetProxy,
      'Instance Group Count': instanceGroupCount?.toString(),
      'Network Endpoint Group Count': networkEndpointGroupCount?.toString()
    };

    const networkLbNode = {
      ...lbNode,
      'Target Pool Description': targetPoolDesc
    };

    const node = lbType?.toLowerCase() === 'application' ? appLbNode : networkLbNode;
    const keys = Object.keys(node);

    return (
      <React.Fragment key={key}>
        <Box>
          <CloudMetadata node={node} keys={keys} />
          <BackendPoolsPanel
            cloudProvider={cloudProvider}
            width={width}
            popoutTitle={popoutTitle}
            backendResources={backendResources}
          />
        </Box>
      </React.Fragment>
    );
  });

export default function GcpLoadBalancers(props) {
  let scopedLoadBalancers;

  const {
    nodeData,
    topology: { Entities },
    width,
    popoutTitle,
    type,
    cloudProvider
  } = props;

  const allLoadBalancers = Entities[LOAD_BALANCER] ?? {};

  switch (type) {
    case NETWORK: {
      scopedLoadBalancers = Object.values(allLoadBalancers).filter(({ scope, project: lbProject }) => {
        const { project: nodeDataProject } = getCustomProperties(nodeData);
        return lbProject === nodeDataProject && scope === 'global';
      });
      break;
    }
    case REGION: {
      const { selectId } = nodeData;
      const { project, region } = selectIdToObj({ selectId });
      scopedLoadBalancers = Object.values(allLoadBalancers).filter(
        ({ scope, project: lbProject, region: lbRegion }) =>
          scope === 'region' && project === lbProject && region === lbRegion
      );
      break;
    }
    default: {
      scopedLoadBalancers = [];
    }
  }

  return (
    <SidebarItem
      excludeFormProps
      title={
        <Flex alignItems="center" gap={1} width="100%">
          <Heading level={5} mb={0}>
            Load Balancers
          </Heading>
          {scopedLoadBalancers.length > 0 && (
            <Tag
              intent="success"
              icon={FaCheck}
              title={`${scopedLoadBalancers.length} active routes`}
              minimal
              round
              flexGrow={1}
            >
              {greekDisplay(scopedLoadBalancers.length)}
            </Tag>
          )}
        </Flex>
      }
      dialogProps={{ width }}
      popoutTitle={popoutTitle}
      filledIconTag={false}
      icon={<CloudIcon cloudProvider="gcp" entity={GCP_ENTITY_TYPES.LOAD_BALANCER} style={{ marginTop: '3px' }} />}
    >
      {scopedLoadBalancers.length ? (
        <LoadBalancerData
          scopedLoadBalancers={scopedLoadBalancers}
          cloudProvider={cloudProvider}
          width={width}
          popoutTitle={popoutTitle}
        />
      ) : (
        <>
          <EmptyState
            title="No Results"
            icon={<CloudIcon cloudProvider={cloudProvider} entity={LOAD_BALANCER} iconSize={48} />}
          />
        </>
      )}
    </SidebarItem>
  );
}
