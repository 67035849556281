import React from 'react';
import { observer } from 'mobx-react';
import { Text, Icon, Box, Flex } from 'core/components';
import ResultMenuItem from './ResultMenuItem';

function JourneyResult({ item, selected, IconCmp, onItemClick, ...rest }) {
  const { name, description } = item;

  return (
    <ResultMenuItem {...rest} selected={selected} onClick={() => onItemClick(item, 'Search Results')}>
      <Flex gridArea="icon" alignItems="center" justifyContent="center">
        <IconCmp iconSize={22} />
      </Flex>

      <Box gridArea="title">
        <Text className="title">{name}</Text>
      </Box>

      <Flex gridArea="rightIcon" alignItems="center" justifyContent="center">
        <Icon icon={selected ? 'key-enter' : undefined} color="#ffffff" />
      </Flex>

      <Text
        className="description"
        gridArea="description"
        muted
        small
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        style={{ overflow: 'hidden' }}
      >
        {description}
      </Text>
    </ResultMenuItem>
  );
}

export default observer(JourneyResult);
