import { connectAlignedNodes, connectThroughMiddleX } from './commonUtils';

/**
 * example:
 * CustomerGateway       CustomerGateway
 *   ↓                         ↓
 *    → → → → → →  ← ← ← ← ← ←
 *                ↓
 *                ↓
 *                ↓
 *    ← ← ← ← ← ←   → → → → → →
 *   ↓                         ↓
 *  Target                   Target
 */
const connectoToOnPremGateway = ({ ...rest }) => connectThroughMiddleX({ ...rest });

/**
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const OnPremGatewayConnector = ({ targetType, ...rest }) => {
  const alignedConnection = connectAlignedNodes({ ...rest });
  switch (targetType) {
    case 'Lags':
    case 'VpnConnections':
    case 'DirectConnections':
    case 'DirectConnectGateways':
      return alignedConnection !== null ? alignedConnection : connectoToOnPremGateway({ ...rest });
    /** @TODO other target */
    default:
      return null;
  }
};

export default OnPremGatewayConnector;
