import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Page from 'app/components/page/Page';
import { isEmpty } from 'lodash';

import RbacRoleDetailsForm from './RbacRoleDetailsFormV2';

@inject('$auth', '$rbac')
@observer
class RbacRoleDetails extends Component {
  state = {
    selectedRoleModel: {}
  };

  setModelFromRoleId = (roleId) => {
    const { $rbac } = this.props;

    $rbac.setSelectedRbacRoleUsersCollection(roleId);
    $rbac.getRoleModel({ roleId, force: true }).then((model) => {
      if (!isEmpty(model) && model.get('id')) {
        this.setState({ selectedRoleModel: model });
      }
    });
  };

  componentDidMount() {
    const roleId = this.getRoleId();
    if (roleId) {
      this.setModelFromRoleId(roleId);
    }
  }

  componentDidUpdate() {
    const { selectedRoleModel } = this.state;
    if (isEmpty(selectedRoleModel)) {
      const roleId = this.getRoleId();
      if (roleId) {
        this.setModelFromRoleId(roleId);
      }
    }
  }

  componentWillUnmount() {
    const { $rbac } = this.props;

    $rbac.setSelectedRbacRoleUsersCollection();
  }

  getRoleId(props) {
    const { match } = props || this.props;
    return match.params.roleid;
  }

  render() {
    const { $auth } = this.props;
    const { selectedRoleModel } = this.state;

    const viewOnly =
      !$auth.hasRbacPermissions(['rbac.role::update']) ||
      (!isEmpty(selectedRoleModel) && selectedRoleModel.get('company_id') === null);

    return (
      <Page
        title="Manage RBAC Role"
        parentLinks={[{ link: '/v4/settings/rbac/roles', label: 'RBAC Management' }]}
        height="100%"
        flex={1}
      >
        <RbacRoleDetailsForm model={selectedRoleModel} viewOnly={viewOnly} />
      </Page>
    );
  }
}

export default RbacRoleDetails;
