import React from 'react';
import SidebarItem from '../SidebarItem';
import SecurityGroupsAndNACLs from './securityGroupsAndNACLs/SecurityGroupsAndNACLs';

const AwsSecurityGroupNACLPopover = ({
  cloudProvider,
  width,
  popoutTitle,
  type,
  subType,
  nodeData,
  value,
  topology
}) => {
  if (topology && (type === 'vpc' || type === 'subnet' || subType === 'Vpc')) {
    const vpcId = type === 'vpc' || subType === 'Vpc' ? value : nodeData.VpcId;
    const subnetId = type === 'subnet' ? value : null;

    return (
      <SidebarItem
        title="Security Groups &amp; Network ACLs"
        dialogProps={{ width }}
        fullscreenProps={{ headingOffset: -16 }}
        icon="shield"
        popoutTitle={popoutTitle}
      >
        <SecurityGroupsAndNACLs
          topology={topology}
          vpcId={vpcId}
          subnetId={subnetId}
          cloudProvider={cloudProvider}
          entity={nodeData}
          entityType={type}
        />
      </SidebarItem>
    );
  }

  return null;
};

export default AwsSecurityGroupNACLPopover;
