/**
 *        InternetBox
 *             ↓
 *             ↓
 *              → → →
 *                    ↓
 *                    ↓
 *                    ↓
 *                    ↓
 *                    ↓
 *     Gateway        ↓
 *        ↑           ↓
 *          ← ← ← ← ←
 *
 */
const connectGatewayToInternetBox = ({
  sourcePoint,
  targetPoint,
  targetNodeHeight,
  ySpacing,
  linkSpacing,
  regionRight
}) => {
  // randomly spread multiple links
  const randomize = Math.floor(Math.random() * (3 - 1) + 1);
  const topY = sourcePoint[1] + ySpacing;
  const bottomY = targetPoint[1] + targetNodeHeight + (ySpacing / 2) * randomize;
  const rightX = regionRight + linkSpacing;
  const connectionPoints = [
    [sourcePoint[0], topY],
    [rightX, topY],
    [rightX, bottomY],
    [targetPoint[0], bottomY]
  ];
  return { sourceAnchor: 'bottom', targetAnchor: 'bottom', connectionPoints };
};

/**
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const InternetBoxConnector = ({ targetType, ...rest }) => {
  switch (targetType) {
    case 'region':
      // the RegionConnector already draws internet <-> region links
      return null;
    default:
      return connectGatewayToInternetBox({ ...rest });
  }
};

export default InternetBoxConnector;
