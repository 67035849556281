import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Page from 'app/components/page/Page';
import RoleSetDetailsForm from 'app/views/rbac/RoleSetDetailsForm';
import { Flex, Spinner, Suspense } from 'core/components';
import RbacRoleSetModel from 'app/stores/rbac/RbacRoleSetModel';

@inject('$auth', '$rbac')
@observer
class RoleSetDetails extends Component {
  state = {
    selectedRoleSetModel: new RbacRoleSetModel(),
    loading: true
  };

  componentDidMount() {
    const { $rbac } = this.props;
    const roleSetId = this.getRoleSetId();
    if (roleSetId) {
      return $rbac.getRoleSetModel({ roleSetId, force: true }).then((model) => {
        if (model) {
          this.setState({ selectedRoleSetModel: model });
        }
        this.setState({ loading: false });
      });
    }
    return this.setState({ loading: false });
  }

  getRoleSetId() {
    const { match } = this.props;
    return match.params.rolesetid;
  }

  render() {
    const { $auth } = this.props;
    const { selectedRoleSetModel, loading } = this.state;
    const isLoading = loading || selectedRoleSetModel.requestStatus === 'fetching';

    const viewOnly = !$auth.hasRbacPermissions(['rbac.role::update']);

    return (
      <Page
        title="Manage RBAC Role Set"
        parentLinks={[
          {
            link: '/v4/settings/rbac/role-sets',
            label: 'RBAC Management'
          }
        ]}
        height="100%"
        flex={1}
      >
        <Suspense
          fallback={
            <Flex flexDirection="column" pt={1} justifyContent="center" alignItems="center">
              <Spinner size={40} />
            </Flex>
          }
          loading={isLoading}
        >
          <RoleSetDetailsForm model={selectedRoleSetModel} viewOnly={viewOnly} />
        </Suspense>
      </Page>
    );
  }
}

export default RoleSetDetails;
