import moment from 'moment';
import { pick, get } from 'lodash';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';

import Page from 'app/components/page/Page';
import { getHashForObject } from 'app/stores/query/urlHash';
import { FormComponent, Field, InputGroup, Select, LookbackDateRange, SubmitButton } from 'core/form';
import { Box, Flex, Label, Button, Container, Spinner, Suspense, Text } from 'core/components';
import withHybridTopoSettings from 'app/views/hybrid/maps/components/settingsToolbar/withHybridTopoSettings';

import { convertToReadableId } from './utils';
import { SourceFieldType as AwsSourceFieldType, TargetFieldType as AwsTargetFieldType } from './aws/constants';
import { SourceFieldType as AzureSourceFieldType, TargetFieldType as AzureTargetFieldType } from './azure/constants';

const options = {
  name: 'Pathfinder Report'
};

@withHybridTopoSettings
@inject('$clouds', '$hybridMap')
@withRouter
@withTheme
@observer
export default class CreateConnectivityReport extends Component {
  cloud = null;

  state = {
    selectedTime: {
      lookbackSeconds: 21600,
      startDate: null,
      endDate: null
    },
    cloudMapCollection: null,
    nameUpdatedManually: false,
    connectivityReportModel: null,
    loadingMessage: 'Initializing...'
  };

  componentDidMount() {
    const { location, match, $clouds, $hybridMap } = this.props;

    const cloud = match?.params?.cloud ?? 'aws';

    const { src_type, dst_type, src, dst } = location.state || {};

    const connectivityReportModel = $clouds.connectivityReportCollection.forge({
      src,
      dst,
      cloud,
      src_type,
      dst_type,
      name: src && dst && `Connectivity between ${src} and ${dst}`
    });

    this.cloud = cloud;
    let cloudMapCollection;
    if (cloud === 'aws') {
      cloudMapCollection = $hybridMap.awsCloudMapCollection;
      this.setState({ loadingMessage: 'Loading AWS Topology...' });
    }

    if (cloud === 'azure') {
      cloudMapCollection = $hybridMap.azureCloudMapCollection;
      this.setState({ loadingMessage: 'Loading Azure Topology...' });
    }

    if (!cloudMapCollection) {
      throw new Error(`Unable to create pathfinder report for cloud ${cloud}`);
    }
    if (!cloudMapCollection) {
      throw new Error(`Unable to create pathfinder report for cloud ${cloud}`);
    }

    cloudMapCollection
      .fetchConnectivityCheckerTopology()
      .then(() => this.setState({ connectivityReportModel, cloudMapCollection, loadingMessage: '' }));
  }

  get fields() {
    const initialFields = {
      name: {
        label: 'Name',
        rules: 'required',
        placeholder: 'Will be autogenerated based on source and destination if left blank.',
        defaultValue: ''
      },

      src: {
        label: 'Source'
      },
      dst: {
        label: 'Destination'
      },
      dst_port: {
        label: 'Port',
        rules: 'port',
        type: 'number',
        defaultValue: '80'
      },
      protocol: {
        label: 'Protocol',
        defaultValue: 'tcp',
        options: [
          { value: 'tcp', label: 'TCP' },
          { value: 'udp', label: 'UDP' }
        ]
      }
    };

    // default to AWS cloud
    initialFields.src_type = AwsSourceFieldType;
    initialFields.dst_type = AwsTargetFieldType;

    if (this.cloud === 'azure') {
      initialFields.src_type = AzureSourceFieldType;
      initialFields.dst_type = AzureTargetFieldType;
    }

    return initialFields;
  }

  handleCreateReport = (form) => {
    const { history } = this.props;
    const { selectedTime } = this.state;
    const { startDate, endDate, lookbackSeconds } = selectedTime;

    let start;
    let end = new Date();
    if (!endDate) {
      const bufferInSeconds = Math.abs(lookbackSeconds);
      start = moment().subtract(bufferInSeconds, 'seconds').toDate();
    } else {
      start = new Date(startDate * 1000);
      end = new Date(endDate * 1000);
    }

    const reportValues = {
      ...form.getValues(),
      cloud: this.cloud,
      start_time: start.toISOString(),
      end_time: end.toISOString()
    };

    // default to -1 to match buildReport execution for all reports on empty
    if (!reportValues.dst_port) {
      reportValues.dst_port = -1;
    }

    getHashForObject(reportValues).then((hash) => {
      history.push(`/v4/cloud/pathfinder/${this.cloud}/${hash}`);
    });
  };

  handleOnTypeChange = (field, _value, _previousValue, form) => {
    const { $clouds } = this.props;
    const resetPropName = field.name.replace('_type', '');
    const connectivityReportModel = $clouds.connectivityReportCollection.forge({
      ...form.getValues(),
      [resetPropName]: null
    });

    this.setState({ connectivityReportModel });
  };

  renderLabel({ id, name, Name, ...rest }, propsToRenderArr) {
    const labelProps = propsToRenderArr.filter((prop) => prop !== 'id');
    const idToRender = convertToReadableId(id, this.cloud);
    return (
      <Flex flexDirection="column" gap={1}>
        <Text as="div" fontWeight="bold">
          {idToRender}
        </Text>
        {labelProps.map((key) => {
          const keyArr = key.split('.');
          const formattedKey = keyArr[keyArr.length - 1];
          const value = get(rest, key);
          if (!value) {
            return null;
          }
          return (
            <Flex gap={1} key={`${id}-${formattedKey}`} muted>
              <Text as="div">{formattedKey}:</Text>
              <Text as="div">{value}</Text>
            </Flex>
          );
        })}
      </Flex>
    );
  }

  // we need handle to generate name field
  handleOnValueChange = (field, _value, _previousValue, form) => {
    const { $clouds } = this.props;
    const { nameUpdatedManually } = this.state;
    const { src, dst } = form.getValues();

    const sourceId = convertToReadableId(src, this.cloud);
    const destinationId = convertToReadableId(dst, this.cloud);

    if (!nameUpdatedManually) {
      const connectivityReportModel = $clouds.connectivityReportCollection.forge({
        ...form.getValues(),
        name: `Connectivity between ${sourceId} and ${destinationId}`
      });

      this.setState({ connectivityReportModel });
    }
  };

  async handleOnDropdownSearch(searchText, entityType) {
    const { cloudMapCollection } = this.state;
    const { options: fieldOptions } = this.cloud === 'aws' ? AwsSourceFieldType : AzureSourceFieldType;
    const { labelKeys } = fieldOptions.find(({ value }) => value.toLowerCase() === entityType.toLowerCase());
    const entities = cloudMapCollection?.getEntitiesByType(entityType) ?? [];

    const initialFieldOptions = entities.map((entity) => ({
      entity: pick(entity, labelKeys),
      labelKeys,
      value: entity.id,
      label: this.renderLabel(entity, labelKeys)
    }));

    if (!searchText) {
      return initialFieldOptions;
    }

    // get only options that inclues search text in one of label keys
    return initialFieldOptions.filter(({ entity }) =>
      labelKeys.some((key) => entity[key]?.toLowerCase()?.includes(searchText.toLowerCase()))
    );
  }

  handleDateRangeChange = (selectedTime) => {
    this.setState({ selectedTime });
  };

  render() {
    const { theme, history } = this.props;
    const { loadingMessage, connectivityReportModel, selectedTime } = this.state;

    return (
      <Page
        title="Create Report"
        parentLinks={[{ link: `/v4/cloud/pathfinder/${this.cloud}`, label: 'Cloud Pathfinder' }]}
        gray
      >
        <Suspense
          loading={loadingMessage.length}
          fallback={
            <Flex width="100%" flexDirection="column" justifyContent="center" alignItems="center" gap={1}>
              <Spinner size={24} />
              <Text>{loadingMessage}</Text>
            </Flex>
          }
        >
          <Container>
            <FormComponent fields={this.fields} options={options} model={connectivityReportModel}>
              {({ form }) => (
                <Flex flexDirection="column" gap={1}>
                  <Box>
                    <Field name="name" width={600} onChange={() => this.setState({ nameUpdatedManually: true })}>
                      <InputGroup />
                    </Field>
                  </Box>
                  <Flex gap={2}>
                    <Field name="src_type" onChange={this.handleOnTypeChange}>
                      <Select />
                    </Field>
                    <Box flex={3}>
                      <Field
                        name="src"
                        onQuery={(search) => this.handleOnDropdownSearch(search, form.getValue('src_type'))}
                        onChange={this.handleOnValueChange}
                      >
                        <Select menuWidth="100%" showFilter autoComplete exactMatch clearable={false} />
                      </Field>
                    </Box>
                  </Flex>

                  <Flex gap={2}>
                    <Field name="dst_type" onChange={this.handleOnTypeChange}>
                      <Select />
                    </Field>
                    <Box flex={3}>
                      <Field
                        name="dst"
                        onQuery={(search) => this.handleOnDropdownSearch(search, form.getValue('dst_type'))}
                        onChange={this.handleOnValueChange}
                      >
                        <Select menuWidth="100%" showFilter autoComplete exactMatch clearable={false} />
                      </Field>
                    </Box>
                  </Flex>

                  <Flex justifyContent="space-between">
                    <Box>
                      <Label fontWeight={600} fontSize="12px">
                        Time Range
                      </Label>
                      <LookbackDateRange
                        minimal
                        onChange={this.handleDateRangeChange}
                        lookbackSeconds={3600}
                        allowSingleDayRange
                        {...selectedTime}
                      />
                    </Box>

                    <Box width="30%" alignSelf="end">
                      <Field name="protocol">
                        <Select menuWidth="100%" showFilter autoComplete exactMatch clearable={false} />
                      </Field>
                    </Box>

                    <Box width="30%" alignSelf="end">
                      <Field name="dst_port">
                        <InputGroup />
                      </Field>
                    </Box>
                  </Flex>

                  <Flex justifyContent="space-between">
                    <Button text="Cancel" onClick={() => history.goBack()} bg={theme.colors.dangerBackground} />
                    <SubmitButton intent="primary" onSubmit={this.handleCreateReport} text="Run" />
                  </Flex>
                </Flex>
              )}
            </FormComponent>
          </Container>
        </Suspense>
      </Page>
    );
  }
}
