import { curveUpRight, curveRightUp, curveDownRight, curveRightDown } from 'app/views/hybrid/utils/d3/curves';
import { connectToSubnet } from 'app/views/hybrid/utils/cloud/GatewayConnector';

const connectToTransitGateway = ({
  path,
  targetNode,
  pathIndex,
  sourcePoint,
  targetPoint,
  ySpacing,
  directConnectWidth,
  linkSpacing
}) => {
  const targetY = targetPoint[1] - ySpacing;
  const reverseTargetY = targetPoint[1] + ySpacing;
  const sourceY = sourcePoint[1] + ySpacing;
  const middleX = targetPoint[0] - directConnectWidth / 2 - linkSpacing;

  const alignedAlongX = Math.abs(sourcePoint[1] - targetPoint[1]) < ySpacing;
  const isAttachmentBelow = targetPoint[1] < sourcePoint[1];

  /**
   * example:
   *                  → → → → →
   *                 ↑          ↓
   * Attachment      ↑   Transit Gateway
   *    ↓            ↑
   *     → → → → → →
   */
  if (alignedAlongX || isAttachmentBelow) {
    // if path goes transit gateway, we need to make sure that anchors are set based on prev and next node positions
    const prevLink = path[pathIndex - 1];
    const targetNodeId = targetNode.value;
    const prevLinkSourceNodeId = prevLink?.source?.value ?? null;

    // exception X(, if going through transit gateway -> need to reverse anchors
    if (targetNodeId === prevLinkSourceNodeId && isAttachmentBelow) {
      /**
       * example:
       * Attachment
       *    ↑
       *    ← ← ← ← ← ← ←
       *                  ↑
       *                    ← ← ← ←
       *                            ↑
       *                     Transit Gateway
       *                            ↑
       *                    → → → →
       *                   ↑
       *                   ↑
       * <WE ARE HERE>     ↑
       *  Attachment       ↑
       *      ↓            ↑
       *        → → → → → →
       */

      const connectionPoints = [
        ...curveDownRight(sourcePoint[0], sourceY, linkSpacing),
        ...curveRightUp(middleX, sourceY, linkSpacing),
        ...curveUpRight(middleX, reverseTargetY, linkSpacing),
        ...curveRightUp(targetPoint[0], reverseTargetY, linkSpacing)
      ];
      return { sourceAnchor: 'bottom', targetAnchor: 'bottom', connectionPoints };
    }

    const connectionPoints = [
      ...curveDownRight(sourcePoint[0], sourceY, linkSpacing),
      ...curveRightUp(middleX, sourceY, linkSpacing),
      ...curveUpRight(middleX, targetY, linkSpacing),
      ...curveRightDown(targetPoint[0], targetY, linkSpacing)
    ];
    return { sourceAnchor: 'bottom', targetAnchor: 'top', connectionPoints };
  }

  /**
   * example:
   * Attachment
   *     ↓
   *       → → → → → → →
   *                    ↓
   *              Transit Gateway
   */
  const connectionPoints = [
    ...curveDownRight(sourcePoint[0], sourceY, linkSpacing),
    ...curveRightDown(targetPoint[0], sourceY, linkSpacing)
  ];
  return { sourceAnchor: 'bottom', targetAnchor: 'top', connectionPoints };
};

/**
 *
 *  @returns {
 *  sourceAnchor: left | right | top | bottom
 *  targetAnchor: left | right | top | bottom
 *  connectionPoints: [ [x, y], [x, y], ... ]
 * }
 */
const TransitGatewayAttachmentConnector = ({ targetType, ...rest }) => {
  switch (targetType) {
    case 'TransitGateways':
      return connectToTransitGateway({ ...rest });
    default:
      return connectToSubnet({ ...rest });
  }
};

export default TransitGatewayAttachmentConnector;
