import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import NotFound from 'app/views/NotFound';
import HybridOverview from './HybridOverview';
import MultiCloudHybridOverview from './MultiCloudHybridOverview';
import DeviceDetails from './DeviceDetails';
import SiteDetails from './SiteDetails';
import CloudProvider from './CloudProvider';
import HealthIssues from './HealthIssues';

@inject('$hybridMap', '$devices')
@observer
class TopologyRoutes extends Component {
  render() {
    const { $hybridMap, $devices } = this.props;
    const BASE_ROUTE = $hybridMap.baseRoute;
    const supportedClouds = `(${Object.keys($hybridMap.providerMap).join('|')})`;

    return (
      <Switch>
        {$devices.nonCloudDeviceCount === 0 &&
          $devices.cloudDeviceCount > 0 &&
          $devices.cloudProviders.length === 1 && (
            <Route
              exact
              path={BASE_ROUTE}
              render={() => {
                const [cloudProvider] = $devices.cloudProviders;
                return <Redirect to={`${BASE_ROUTE}/cloud/${cloudProvider}`} />;
              }}
            />
          )}
        <Route exact path={BASE_ROUTE} component={HybridOverview} />

        <Route exact path={`${BASE_ROUTE}/multiCloud`} component={MultiCloudHybridOverview} />
        <Route exact path={`${BASE_ROUTE}/device/:id`} component={DeviceDetails} />
        <Route exact path={`${BASE_ROUTE}/site/:id`} component={SiteDetails} />
        <Redirect from={`${BASE_ROUTE}/cloud/gce`} to={`${BASE_ROUTE}/cloud/gcp`} />
        <Route exact path={`${BASE_ROUTE}/cloud/:cloudProvider${supportedClouds}`} component={CloudProvider} />
        <Route exact path={`${BASE_ROUTE}/health`} component={HealthIssues} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default TopologyRoutes;
