import React from 'react';
import { get } from 'lodash';
import { ENTITY_TYPES } from 'shared/hybrid/constants';

import { Flex } from 'core/components';

import SidebarItem from '../SidebarItem';
import CloudMetadata from '../CloudMetadata';

const { DIRECT_CONNECTION, VIRTUAL_INTERFACE } = ENTITY_TYPES.get('aws');

const VirtualInterface = ({ nodeData, topology, width, popoutTitle }) => {
  const directConnection = get(topology.Entities[DIRECT_CONNECTION], nodeData.ConnectionId);

  const interfaceMetadata = {
    'Account ID': nodeData.OwnerAccount,
    Type: nodeData.VirtualInterfaceType,
    'Connection ID': nodeData.ConnectionId,
    'Connection Name': directConnection?.ConnectionName,
    'Amazon Router peer IP': nodeData?.AmazonAddress,
    'Amazon side ASN': nodeData?.AmazonSideAsn,
    'Customer Router IP': nodeData?.CustomerAddress,
    'Customer Side ASN': nodeData?.Asn,
    'BGP Status': nodeData?.BgpPeers?.map(({ BgpPeerId, BgpStatus }) => `${BgpPeerId} - ${BgpStatus}`).join(',')
  };

  return (
    <SidebarItem
      excludeFormProps
      title={nodeData?.VirtualInterfaceName ?? nodeData?.id}
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="compass"
      popoutTitle={popoutTitle}
    >
      <CloudMetadata node={interfaceMetadata} keys={Object.keys(interfaceMetadata)} />
    </SidebarItem>
  );
};

const AwsVirtualInterfacesPopover = ({ width, popoutTitle, nodeData, topology }) => {
  const virtualInterfaces = Object.values(topology?.Entities[VIRTUAL_INTERFACE] ?? {}).filter(
    (virtualInterface) =>
      virtualInterface?.DirectConnectGatewayId === nodeData?.id || virtualInterface?.ConnectionId === nodeData?.id
  );

  if (virtualInterfaces.length === 0) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title="Virtual Interface Attachments"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="compass"
      popoutTitle={popoutTitle}
    >
      <Flex gap={1} flexDirection="column">
        {virtualInterfaces.map((virtualInterface) => (
          <VirtualInterface
            key={virtualInterface.id}
            width={width}
            topology={topology}
            nodeData={virtualInterface}
            popoutTitle={popoutTitle}
          />
        ))}
      </Flex>
    </SidebarItem>
  );
};

export default AwsVirtualInterfacesPopover;
