import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { EmptyState, Tabs, Tab } from 'core/components';
import TopKeysListing from './TopKeysListing';

@inject('$hybridMap')
@observer
class TopServicesIPs extends Component {
  static defaultProps = {
    defaultTab: 'services'
  };

  render() {
    const {
      $hybridMap,
      defaultTab,
      classPrefix,
      selected,
      highlighted,
      layout,
      onSelect,
      onTabChange,
      device,
      namespace
    } = this.props;

    return (
      <Tabs defaultSelectedTabId={defaultTab} onChange={onTabChange} flexed mt={-1} renderActiveTabPanelOnly>
        <Tab
          id="services"
          title="Services"
          className="legend-tab"
          panel={
            <TopKeysListing
              name="Services"
              classPrefix={classPrefix}
              selected={selected}
              highlighted={highlighted}
              onSelect={onSelect}
              layout={layout}
              query={$hybridMap.getServicesQuery({ isKube: true, device, namespace })}
              emptyState={<EmptyState icon="folder-close" description="No Services" />}
            />
          }
        />
        <Tab
          id="ips"
          title="IPs"
          className="legend-tab"
          panel={
            <TopKeysListing
              name="IPs"
              classPrefix={classPrefix}
              selected={selected}
              highlighted={highlighted}
              onSelect={onSelect}
              layout={layout}
              query={$hybridMap.getIPsQuery({ isKube: true, device, namespace })}
              emptyState={<EmptyState icon="folder-close" description="No IPs" />}
            />
          }
        />
      </Tabs>
    );
  }
}

export default TopServicesIPs;
