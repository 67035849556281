import { maxBy } from 'lodash';

export const healthStates = ['GOOD', 'WARNING', 'CRITICAL'];
export const healthClasses = ['healthy', 'warning', 'critical'];
export const linkHealthClasses = ['degraded', 'down'];

// allows us to obtain health styling/messaging information based on thresholds we currently do not receive back from the health api
export function getHealthStateFromValue({ type, value }) {
  const healthStateMap = {
    cpu: [
      {
        value: 90,
        color: 'danger',
        intent: 'danger',
        name: 'Critical',
        state: 'CRITICAL',
        description:
          "The percentage of non-idle CPU cycles for this device's most-heavily-loaded component exceeds the critical threshold of 90%"
      },
      {
        value: 80,
        color: 'warning',
        intent: 'warning',
        name: 'Warning',
        state: 'WARNING',
        description:
          "The percentage of non-idle CPU cycles for this device's most-heavily-loaded component exceeds the warning threshold of 80%"
      },
      {
        value: 0,
        color: 'success',
        intent: 'success',
        name: 'Good',
        state: 'GOOD',
        description: "The percentage of non-idle CPU cycles for this device's most-heavily-loaded component is normal"
      }
    ],
    memory: [
      {
        value: 90,
        color: 'danger',
        intent: 'danger',
        name: 'Critical',
        state: 'CRITICAL',
        description:
          "The percentage of memory in use for this device's most-heavily-loaded component exceeds the critical threshold of 90%"
      },
      {
        value: 75,
        color: 'warning',
        intent: 'warning',
        name: 'Warning',
        state: 'WARNING',
        description:
          "The percentage of memory in use for this device's most-heavily-loaded component exceeds the warning threshold of 75%"
      },
      {
        value: 0,
        color: 'success',
        intent: 'success',
        name: 'Good',
        state: 'GOOD',
        description: "The percentage of memory in use for this device's most-heavily-loaded component is normal"
      }
    ],
    interfacePerformanceIn: [
      {
        value: 90,
        color: 'danger',
        intent: 'danger',
        name: 'Critical',
        state: 'CRITICAL',
        description:
          'The percentage of interface capacity occupied by egress traffic (5 minute average) exceeds the critical threshold of 90%'
      },
      {
        value: 80,
        color: 'warning',
        intent: 'warning',
        name: 'Warning',
        state: 'WARNING',
        description:
          'The percentage of interface capacity occupied by egress traffic (5 minute average) exceeds the warning threshold of 80%'
      },
      {
        value: 0,
        color: 'success',
        intent: 'success',
        name: 'Good',
        state: 'GOOD',
        description: 'The percentage of interface capacity occupied by egress traffic (5 minute average) is normal'
      }
    ],
    interfacePerformanceOut: [
      {
        value: 90,
        color: 'danger',
        intent: 'danger',
        name: 'Critical',
        state: 'CRITICAL',
        description:
          'The percentage of interface capacity occupied by egress traffic (5 minute average) exceeds the critical threshold of 90%'
      },
      {
        value: 80,
        color: 'warning',
        intent: 'warning',
        name: 'Warning',
        state: 'WARNING',
        description:
          'The percentage of interface capacity occupied by egress traffic (5 minute average) exceeds the warning threshold of 80%'
      },
      {
        value: 0,
        color: 'success',
        intent: 'success',
        name: 'Good',
        state: 'GOOD',
        description: 'The percentage of interface capacity occupied by egress traffic (5 minute average) is normal'
      }
    ]
  };

  const thresholds = healthStateMap[type];

  if (thresholds) {
    const found = thresholds.find((threshold) => value >= threshold.value);

    if (found) {
      return found;
    }
  }

  return { color: 'body', intent: 'none', name: null };
}

export function getHealthStateFromStatus(status) {
  const stateMap = {
    GOOD: { color: 'success', intent: 'success', name: 'Good' },
    WARNING: { color: 'warning', intent: 'warning', name: 'Warning' },
    CRITICAL: { color: 'danger', intent: 'danger', name: 'Critical' }
  };

  return stateMap[status] || { color: 'body', intent: 'none', name: null };
}

export function getHealthStateFromHealthData(health) {
  const colorTextMap = {
    healthy: { color: 'success', intent: 'success', text: 'Healthy' },
    warning: { color: 'warning', intent: 'warning', text: 'Warning' },
    critical: { color: 'danger', intent: 'danger', text: 'Critical' }
  };

  return colorTextMap[health && health.cssClassName] || { color: 'body', intent: 'none', text: 'Unavailable' };
}

export function getMaxFromQueryResults({ queryResults, valueKey, rawDataKey }) {
  const lastDatapointKey = `${rawDataKey}__k_last`;

  return queryResults.getRawDataRows().reduce(
    (acc, model) => {
      const nextValue = model.get(valueKey);
      const nextLastDatapoint = model.get(lastDatapointKey);
      const rawData = nextValue > acc.value ? model.get('rawData')[rawDataKey].flow.map((d) => d[1]) : acc.rawData;

      return {
        value: Math.max(acc.value, nextValue),
        lastDatapoint: Math.max(acc.lastDatapoint, nextLastDatapoint),
        rawData
      };
    },
    {
      rawData: [],
      value: 0,
      lastDatapoint: 0
    }
  );
}

export function getHealthCheck(health, checkName) {
  const checks = health?.data?.checks || health?.checks;

  if (checkName && checks?.length > 0) {
    return checks.find((check) => check.check_name === checkName);
  }

  return undefined;
}

export function getHealthState(state) {
  const stateMap = {
    GOOD: { color: 'success', intent: 'success', name: 'Healthy' },
    WARNING: { color: 'warning', intent: 'warning', name: 'Warning' },
    CRITICAL: { color: 'danger', intent: 'danger', name: 'Critical' }
  };

  return stateMap[state] || { color: 'body', intent: 'none', name: state };
}

export function mergeHealthStates(...states) {
  return maxBy(states, (state) => {
    let idx = healthStates.indexOf(state);

    if (idx < 0) {
      idx = healthClasses.indexOf(state);
    }

    if (idx < 0) {
      idx = linkHealthClasses.indexOf(state);

      if (idx > -1) {
        idx += healthClasses.length;
      }
    }

    return idx;
  });
}

/**
 * when connections states are merged:
 *  - all `down` is `down`
 *  - only some `down` is `degraded`
 *  - otherwise find highest state in `mergeHealthStates`
 * @param  {...string} states
 * @returns {string}
 */
export function mergeConnectionHealthStates(...states) {
  const numDown = states.filter((state) => state === 'down').length;
  const numDegraded = states.filter((state) => state === 'degraded').length;

  if (numDown === states.length) {
    return 'down';
  }

  if (numDown > 0 || numDegraded > 0) {
    return 'degraded';
  }

  return mergeHealthStates(...states);
}

export function getHealthClass(itemHealth, checkOperStatus = false) {
  if (checkOperStatus) {
    const check = getHealthCheck(itemHealth, 'interface_availability_operstatus');

    if (check?.value > 1) {
      return 'down';
    }
  }

  const state = itemHealth?.overall_state || itemHealth?.state;

  if (state) {
    return healthClasses[healthStates.indexOf(state)];
  }

  return null;
}

// we only want to display details on unhealthy states
export function getHealthIssues(data) {
  if (data && data.length > 0) {
    return data.filter((check) => check.state !== 'GOOD');
  }

  return [];
}

function flattenDetails(details) {
  const checkNames = Object.keys(details);

  return checkNames.reduce((acc, checkName) => {
    const config = details[checkName];
    const values = Object.values(config);

    acc[checkName] = values.flat();

    return acc;
  }, {});
}

function mergeDetailsReferenceData(details, referenceData, nodeId) {
  if (details) {
    const flattenedDetails = flattenDetails(details);
    const newDetails = Object.keys(flattenedDetails).reduce((acc, checkName) => {
      const detailsWithData = flattenedDetails[checkName]
        .map((id) => {
          const reference = nodeId ? referenceData[nodeId][id] : referenceData[id];

          if (reference && reference.checks) {
            const matchedCheck = reference.checks.find((c) => c.check_name === checkName);

            if (matchedCheck) {
              return {
                ...matchedCheck,
                id: nodeId || id
              };
            }
          }

          return null;
        })
        .filter((c) => !!c);

      if (detailsWithData.length > 0) {
        acc[checkName] = detailsWithData;
      }

      return acc;
    }, {});

    if (newDetails && Object.keys(newDetails).length > 0) {
      return newDetails;
    }
  }

  return null;
}

export function mergeRollupCheckDetails(checks, { rollup, referenceData, nodeId }) {
  if (checks && checks.length > 0) {
    return checks.map((check) => {
      if (check.check_name === rollup) {
        return {
          ...check,
          id: nodeId,
          details: mergeDetailsReferenceData(check.details, referenceData, nodeId)
        };
      }

      return {
        ...check,
        id: nodeId
      };
    });
  }

  return checks;
}

export function getHealthRollupCount(rollup) {
  if (rollup && rollup.details) {
    const checkNames = Object.keys(rollup.details);

    const count = checkNames.reduce((acc, checkName) => {
      const checks = Object.values(rollup.details[checkName]).flat();

      if (checkName === 'device_rollup') {
        // a site rollup could have device rollups underneath
        return acc + checks.reduce((deviceAcc, deviceRollup) => deviceAcc + getHealthRollupCount(deviceRollup), 0);
      }

      return acc + checks.length;
    }, 0);

    if (typeof count === 'number' && !Number.isNaN(count)) {
      return count;
    }

    console.warn('health badge count is invalid');
  }

  return 0;
}

export function getIfOperStatusLabel(checkValue) {
  const labelMap = {
    1: 'Up',
    2: 'Down',
    3: 'Testing',
    4: 'Unknown',
    5: 'Dormant Interface',
    6: 'Not Present',
    7: 'Lower Layer Down'
  };

  return labelMap[checkValue] || null;
}
