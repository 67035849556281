import * as React from 'react';
import { observer } from 'mobx-react';
import { Position } from '@blueprintjs/core';
import { getFilterCount } from 'core/util/filters';
import { Box, Button, Flex, Tag, Popover, Icon, Text, Tooltip } from 'core/components';
import { formConsumer } from 'core/form';
import SidebarSectionWarnings from 'app/views/core/explorer/sidebar/SidebarSectionWarnings';
import FilterOptionsDialog from 'app/components/filters/FilterOptionsDialog';
import FilterOptionsDisplay from 'app/components/filters/FilterOptionsDisplay';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';

@formConsumer
@observer
export default class FiltersButton extends React.Component {
  state = {
    isEditingFilters: false,
    isPopoverOpen: false
  };

  handleToggleEditFilters = (isOpen) => {
    this.setState({
      isEditingFilters: isOpen
    });
  };

  handleFilterGroupUpdate = () => {
    const { form, onChange } = this.props;

    if (onChange) {
      onChange(form.getValues());
    }
  };

  get filterControls() {
    const { form } = this.props;
    const filterGroups = form.getValue('sidebarQueryOverrides.filters.filterGroups');
    const filterCount = getFilterCount(filterGroups);
    const isDirty = form.isGroupDirty('filtering');
    const sectionHasErrors = form.invalidGroups.filtering;
    const sectionIncomplete = form.incompleteGroups.filtering;

    return (
      <Box p={2}>
        <Box maxHeight={400} p="2px" overflow="auto">
          {filterCount === 0 && (
            <Flex flexDirection="column" alignItems="center">
              <Icon icon="filter" iconSize={20} color="muted" mb={1} />
              <Text as="div" fontWeight="bold" muted small>
                No filtering criteria have been applied
              </Text>
            </Flex>
          )}

          {filterCount > 0 && <FilterOptionsDisplay fieldName="sidebarQueryOverrides.filters" />}
        </Box>

        <Flex justifyContent="flex-end" mt={2}>
          <SidebarSectionWarnings fieldGroup="filtering" isSectionDirty={isDirty}>
            {({ label, intent }) => (
              <Tooltip content={label} intent={intent} disabled={intent === 'none'} autoFocus={false}>
                <Button
                  text="Apply"
                  disabled={!isDirty || sectionHasErrors || sectionIncomplete}
                  mr={1}
                  icon={intent === 'warning' && <Icon icon={BsFillExclamationTriangleFill} intent={intent} />}
                  onClick={this.handleFilterGroupUpdate}
                  width={85}
                  small
                />
              </Tooltip>
            )}
          </SidebarSectionWarnings>
          <Button onClick={() => this.handleToggleEditFilters(true)} text="Edit Filters" icon="edit" small />
        </Flex>
      </Box>
    );
  }

  render() {
    const { form } = this.props;
    const { isPopoverOpen, isEditingFilters } = this.state;
    const filterGroups = form.getValue('sidebarQueryOverrides.filters.filterGroups');
    const filterCount = getFilterCount(filterGroups);

    return (
      <>
        <Popover
          isOpen={isPopoverOpen && !isEditingFilters}
          content={this.filterControls}
          position={Position.BOTTOM}
          minimal={false}
          onClose={() => this.setState({ isPopoverOpen: false })}
          canEscapeKeyClose
        >
          <Button
            rightIcon="caret-down"
            height="35px"
            active={isPopoverOpen}
            onClick={() => this.setState((prevState) => ({ isPopoverOpen: !prevState.isPopoverOpen }))}
            small
          >
            <Flex alignItems="center">
              <Icon icon="filters" color="hybrid.filtered" />
              Filters
              <Tag ml="4px" bg="hybrid.filtered" color="white.10" round small>
                {filterCount}
              </Tag>
            </Flex>
          </Button>
        </Popover>

        {isEditingFilters && (
          <FilterOptionsDialog
            fieldName="sidebarQueryOverrides.filters"
            showSaveFiltersButton
            onClose={() => {
              this.setState({ isPopoverOpen: true }, () => this.handleToggleEditFilters(false));
            }}
            isOpen={isEditingFilters}
            allowNestedFilters
          />
        )}
      </>
    );
  }
}
