import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, withRouter } from 'react-router-dom';
import { Intent } from '@blueprintjs/core';

import { Box, Button, Callout, Card, Flex, Heading, Icon, Select, showErrorToast, Text } from 'core/components';
import { InputGroup } from 'core/form';

import { GoShield } from 'react-icons/go';
import KentikLogo from 'app/components/KentikLogo';
import { getReasonOptions } from 'app/util/sudo/getReasonOptions';

@inject('$auth', '$sudo', '$store')
@observer
class VerifyTwoFactor extends Component {
  state = { reason: '', additional_info: '', redirectToReferrer: false, token: '', validatingToken: false };

  componentDidMount() {
    const { location } = this.props;
    const reason = location?.state?.reason;
    const additional_info = location?.state?.additional_info;
    if (reason) {
      this.setState({ reason });
    }

    if (additional_info) {
      this.setState({ additional_info });
    }
  }

  shouldShowReason = () => {
    const { $sudo, location } = this.props;
    const requireReason = location?.state?.requireReason && $sudo.isSpoofReasonEnabled(location?.state?.target_cid);
    return requireReason;
  };

  handleKeyboardSubmit = (e) => {
    const requireReason = this.shouldShowReason();
    const { reason, additional_info, token, validatingToken } = this.state;
    if (e && e.key === 'Enter') {
      e.stopPropagation();
      if (
        token &&
        !validatingToken &&
        (!requireReason || (reason && reason !== 'other') || (reason === 'other' && additional_info))
      ) {
        this.handleSubmit();
      }
    }
  };

  handleSubmit = (options = {}) => {
    const { $auth } = this.props;
    const { token } = this.state;

    this.setState({ validatingToken: true });

    const verifyParams = { token };
    if ($auth.getActiveUserProperty('allowTotpSkip') && options.skipValidation) {
      verifyParams.force = true;
    }

    // Make call to verify 2fa token.
    return $auth.verifyTotp(verifyParams).then((twoFactorRequired) => {
      if (twoFactorRequired) {
        if ($auth.twoFactorDelay) {
          showErrorToast(`Too many failed tries; please wait ${$auth.twoFactorDelay} seconds before retrying`);
        } else {
          showErrorToast('Invalid Token');
        }
        this.setState({ redirectToReferrer: false, token: '', validatingToken: false });
      } else {
        this.setState({ redirectToReferrer: true });
      }
    });
  };

  handleSkipValidation = () => {
    this.handleSubmit({ skipValidation: true });
  };

  handleTokenChange = (e) => this.setState({ token: e.target.value });

  handleReasonChange = (reason) => this.setState({ reason });

  handleAdditionalInfo = (e) => this.setState({ additional_info: e.target.value });

  render() {
    const { $auth, location, $store } = this.props;
    const { from } = location.state || { from: { pathname: '/' } };
    const requireReason = this.shouldShowReason();
    const { reason, additional_info, redirectToReferrer, token, validatingToken } = this.state;
    const { subtenancy } = $auth.openConfig;

    if (redirectToReferrer) {
      return (
        <Redirect
          to={{
            pathname: from.pathname,
            search: from.search,
            state: { reason, additional_info, verified2fa: true }
          }}
        />
      );
    }

    return (
      <>
        {subtenancy && subtenancy.config && subtenancy.config.icon && (
          <Box textAlign="center" p={3}>
            <img
              alt={subtenancy.subdomain}
              src={`/api/ui/icons/${subtenancy.company_id}`}
              style={{ width: 180, height: 'auto' }}
            />
          </Box>
        )}
        <Card maxWidth={375} minHeight={235} mx="auto" p={3} elevation={4}>
          {(!subtenancy || !subtenancy.config || !subtenancy.config.icon) && (
            <Box textAlign="center" mb={4}>
              <a href={window.location.origin}>
                <KentikLogo blackText style={{ width: 200, height: 30 }} />
              </a>
            </Box>
          )}

          <Box pb={1}>
            <Heading level={4} fontWeight="heavy">
              Two-factor Authentication
            </Heading>
            <Text muted>
              Enter the one-time token from your authentication app or physical key. If you are having issues signing
              in, please contact your administrator.
            </Text>
          </Box>

          {!!$auth.twoFactorDelay && (
            <Callout intent={Intent.DANGER} mb={2} pb={0}>
              <p>You have tried an invalid token too many times. Please wait a few moments to try again.</p>
            </Callout>
          )}

          {requireReason && (
            <Box pb={2}>
              <Select
                buttonStyle={{ icon: 'high-priority' }}
                options={getReasonOptions}
                onChange={this.handleReasonChange}
                height={40}
                values={reason}
                fill
                showFilter
                onKeyPress={this.handleKeyboardSubmit}
              />
              <Flex alignItems="center" gap={1} mt="12px">
                <Icon icon="arrow-right" iconSize={10} />
                <InputGroup
                  placeholder="Additional information about SUDO reason"
                  fill
                  value={additional_info}
                  onChange={this.handleAdditionalInfo}
                />
              </Flex>
            </Box>
          )}

          <Box pb={2}>
            <InputGroup
              onKeyPress={this.handleKeyboardSubmit}
              autoFocus={!requireReason}
              large
              fill
              disabled={!!$auth.twoFactorDelay}
              leftIcon={<Icon icon={GoShield} />}
              onChange={this.handleTokenChange}
              placeholder="One-Time Token"
              value={token}
              className="jumbo"
            />
          </Box>

          <Flex justifyContent="space-between">
            <Button text="Cancel" disabled={validatingToken} onClick={$store.logout} mr={1} large />
            <Box>
              {$auth.getActiveUserProperty('allowTotpSkip') && !requireReason && (
                <Button text="Skip" onClick={this.handleSkipValidation} mr={1} large />
              )}
              <Button
                text="Verify"
                disabled={
                  !token ||
                  !!$auth.twoFactorDelay ||
                  (requireReason && !reason) ||
                  (requireReason && reason === 'other' && !additional_info)
                }
                intent={Intent.PRIMARY}
                loading={validatingToken}
                onClick={() => this.handleSubmit()}
                large
              />
            </Box>
          </Flex>
        </Card>
      </>
    );
  }
}

export default withRouter(VerifyTwoFactor);
