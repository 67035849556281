import React from 'react';

import { isObject } from 'lodash';
import { inject } from 'mobx-react';
import { Flex } from 'core/components';

import SidebarItem from '../SidebarItem';
import { AzurePeeringDetails } from './components';

/**
 * in Azure, subnet can have only 1 security group attached a time.
 */
const AzureExpressRoutePeeringPopover = ({ width, popoutTitle, $hybridMap, ...rest }) => {
  const { nodeData } = rest;

  const { azureCloudMapCollection } = $hybridMap;

  let peerings =
    azureCloudMapCollection.getEntityProperty(nodeData, 'peerings') ??
    azureCloudMapCollection.getEntityProperty(nodeData, 'expressRouteCircuitPeering') ??
    [];

  if (!Array.isArray(peerings) && isObject(peerings)) {
    peerings = [peerings];
  }

  if (peerings.length === 0) {
    return null;
  }

  return (
    <SidebarItem
      excludeFormProps
      title="Peerings"
      dialogProps={{ width }}
      fullscreenProps={{ headingOffset: -16 }}
      icon="chat"
      popoutTitle={popoutTitle}
    >
      <Flex px="4px" gap={1} flexDirection="column">
        {peerings.map((peering) => (
          <AzurePeeringDetails key={peering.id} nodeData={peering} width={width} popoutTitle={popoutTitle} />
        ))}
      </Flex>
    </SidebarItem>
  );
};

export default inject('$hybridMap')(AzureExpressRoutePeeringPopover);
