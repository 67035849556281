import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { SizeMe } from 'react-sizeme';
import { AiOutlineMenuFold } from 'react-icons/ai';

import { Box, Button } from 'core/components';
import Page from 'app/components/page/Page';
import withHybridTopoSettings from 'app/views/hybrid/maps/components/settingsToolbar/withHybridTopoSettings';

import NamespaceDetailsMap from '../maps/kube/NamespaceDetailsMap';
import HybridTopoSidebar from '../maps/components/HybridTopoSidebar';
import HybridTopoSettingsToolbar from '../maps/components/settingsToolbar/HybridTopoSettingsToolbar';
import withSidebarDetails from '../maps/components/popovers/withSidebarDetails';
import { BASE_ROUTE } from '.';

@withHybridTopoSettings
@withSidebarDetails
@inject('$hybridMap')
@observer
export default class NamespaceDetails extends Component {
  static getDerivedStateFromProps(props) {
    if (!props.sidebarDetailsPanel) {
      return { drawerIsOpen: false };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      drawerIsOpen: true
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarDetailsPanel } = this.props;

    // if we're setting new details and it's not open, open it
    if (!prevState.drawerIsOpen && sidebarDetailsPanel) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ drawerIsOpen: true });
    }
  }

  handleDrawerToggle = () => this.setState(({ drawerIsOpen }) => ({ drawerIsOpen: !drawerIsOpen }));

  render() {
    const {
      $hybridMap,
      match,
      settingsModel,
      sidebarSettings,
      saveSettings,
      onPopSidebarConfig,
      setSidebarDetails,
      sidebarDetailsPanel
    } = this.props;
    const { drawerIsOpen } = this.state;
    const { clusterId, namespaceName } = match.params;
    const clusterName = clusterId.replace(/^\w+-/, '');
    const namespaceId = `${clusterId}-${namespaceName}`;

    return (
      <Page
        title={`Namespace: ${namespaceName}`}
        docTitle={`Namespace Map: ${namespaceName}`}
        parentLinks={[
          { link: BASE_ROUTE, label: 'Kentik Kube' },
          { link: `${BASE_ROUTE}/cluster/${clusterId}`, label: `Cluster: ${clusterName}` }
        ]}
        drawerContents={
          <HybridTopoSidebar sidebarDetailsPanel={sidebarDetailsPanel} onPopConfig={onPopSidebarConfig} />
        }
        drawerIsOpen={drawerIsOpen}
        drawerOnClose={(e) => {
          if ((e && e.key === 'Escape' && drawerIsOpen) || (e && e.type === 'click')) {
            this.handleDrawerToggle();
          }
        }}
        drawerProps={{ extraOffset: 43, showCloseButton: true }}
        subnavTools={
          <Button
            text="Details"
            icon={AiOutlineMenuFold}
            ml="2px"
            active={drawerIsOpen}
            onClick={this.handleDrawerToggle}
            disabled={!sidebarDetailsPanel}
            minimal
          />
        }
        toolbar={
          <HybridTopoSettingsToolbar
            onSettingsUpdate={saveSettings}
            model={settingsModel}
            collection={$hybridMap.kubeCloudMapCollection}
          />
        }
        scrolls
        minHeight="auto"
      >
        <SizeMe monitorWidth noPlaceholder>
          {({ size }) => (
            <Box flex={1} mb={1}>
              <NamespaceDetailsMap
                width={size.width}
                clusterId={clusterId}
                clusterName={clusterName}
                namespaceId={namespaceId}
                namespaceName={namespaceName}
                sidebarSettings={sidebarSettings}
                setSidebarDetails={setSidebarDetails}
                drawerIsOpen={drawerIsOpen}
              />
            </Box>
          )}
        </SizeMe>
      </Page>
    );
  }
}
