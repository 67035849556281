import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import { Button, Flex, Popover, Icon } from 'core/components';
import { formConsumer, Field, InputGroup } from 'core/form';
import MapSearchPanel from './MapSearchPanel';
import MapSearchAwsSubscriptionsPanel from './AwsAccountsPanel';
import MapSearchAzureSubscriptionsPanel from './AzureSubscriptionsPanel';

@inject('$clouds', '$hybridMap')
@formConsumer
@observer
export default class MapSearch extends React.Component {
  state = {
    isOpen: false
  };

  handleChange = (options) => {
    const { onSearchSettingsChange } = this.props;

    onSearchSettingsChange(options);
  };

  handleAzureSubscriptionsChange = ({ azureSubscriptionIds = [], ...rest }) => {
    const { onSearchSettingsChange, form } = this.props;
    form.model.set('azureSubscriptionIds', azureSubscriptionIds);
    onSearchSettingsChange({ ...rest });
  };

  handleAwsAccountsChange = ({ awsAccountIds = [], selectedGroup = '', ...rest }) => {
    const { onSearchSettingsChange, form } = this.props;

    form.model.set('awsAccountIds', awsAccountIds);
    form.model.set('awsSelectedAccountGroup', selectedGroup);
    onSearchSettingsChange({ ...rest });
  };

  handleSearchTermClear = () => {
    const { form } = this.props;

    form.setValue('searchTerm', '');
    this.handleChange({ persist: false });
  };

  handleSavedSearchAdd = (savedSearch) => {
    const { form } = this.props;
    const savedSearches = form.getValue('savedSearches');

    form.setValue('savedSearches', [savedSearch, ...savedSearches]);
    this.handleChange({ persist: true });
  };

  handleSavedSearchEdit = (oldSearchName, newSearchName) => {
    const { form } = this.props;
    const savedSearches = form.getValue('savedSearches');
    const item = savedSearches.find((savedSearch) => savedSearch.name === oldSearchName);

    if (item) {
      item.name = newSearchName;
    }

    form.setValue('savedSearches', savedSearches);
    this.handleChange({ persist: true });
  };

  handleSavedSearchDelete = (savedSearchName) => {
    const { form } = this.props;
    const savedSearches = form.getValue('savedSearches');

    form.setValue(
      'savedSearches',
      savedSearches.filter((savedSearch) => savedSearch.name !== savedSearchName)
    );

    this.handleChange({ persist: true });
  };

  handleInteraction = (nextIsOpen, e) => {
    let isClickingInput = true;

    if (e) {
      // keep the menu open when interacting with the input whether it be focusing, editing, or clearing
      isClickingInput = !!e.target.closest(`.${Classes.FORM_GROUP}`);
      if (nextIsOpen === false && isClickingInput === true) {
        return;
      }

      this.setState({ isOpen: nextIsOpen });
    }
  };

  render() {
    const { form, collection, isAzurePage, isAwsPage } = this.props;
    const { isOpen } = this.state;

    return (
      <Flex alignItems="center">
        <Popover
          autoFocus={false}
          enforceFocus={false}
          isOpen={isOpen}
          onInteraction={this.handleInteraction}
          placement="bottom-start"
          content={
            <MapSearchPanel
              collection={collection}
              onChange={() => this.handleChange({ persist: false })}
              onSavedSearchAdd={this.handleSavedSearchAdd}
              onSavedSearchEdit={this.handleSavedSearchEdit}
              onSavedSearchDelete={this.handleSavedSearchDelete}
              width={550}
              minHeight={250}
              maxHeight={400}
            />
          }
        >
          <Field
            name="searchTerm"
            onChange={() => this.handleChange({ persist: false })}
            className="no-margin"
            placeholder="Search by Account ID, Entity ID, CIDR, and Tags"
            pr={1}
          >
            <InputGroup
              height={35}
              width={300}
              leftIcon={<Icon icon="search" style={{ marginTop: '10px' }} />}
              rightElement={
                <Flex alignItems="center" top="8px" right="2px" position="relative">
                  {form.getValue('searchTerm') !== '' && (
                    <Button icon="cross" onClick={this.handleSearchTermClear} minimal small />
                  )}
                </Flex>
              }
            />
          </Field>
        </Popover>

        {isAzurePage && <MapSearchAzureSubscriptionsPanel onChange={this.handleAzureSubscriptionsChange} />}
        {isAwsPage && <MapSearchAwsSubscriptionsPanel onChange={this.handleAwsAccountsChange} />}
      </Flex>
    );
  }
}
