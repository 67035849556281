import * as React from 'react';
import { Box, CalloutOutline, Flex, Heading, Icon } from 'core/components';
import ViewInExplorerButton from 'app/components/dataviews/tools/ViewInExplorerButton';
import { buildFilterGroup } from 'core/util/filters';
import $sites from 'app/stores/site/$sites';

function getSiteModel(title) {
  const model = $sites.collection.models.find((m) => m.get('title') === title);

  if (model) {
    return model;
  }

  return null;
}

function getQueryTitleSuffix(queryType) {
  return `By ${queryType === 'ip' ? 'IP' : 'Ultimate Exit'}`;
}

// adds in query properties necessary for full features of popover queries to work
function toPopoverQuery(query) {
  return {
    ...query,
    topx: 8,
    show_overlay: true,
    show_total_overlay: true,
    viz_type: 'stackedArea'
  };
}

// returns site by ip and site by ultimate exit traffic for the top-level map
export function getTopLevelQuery(queryType = 'ip', { source, target, isPopoverQuery = false } = {}) {
  const queryTitleSuffix = getQueryTitleSuffix(queryType);

  // default to ip query
  const query = {
    topx: 0,
    depth: 15000,
    forceDepth: true,
    viz_type: 'table',
    show_overlay: false,
    show_total_overlay: false,
    metric: ['kt_src_site_title', 'kt_dst_site_title'],
    aggregateTypes: ['avg_bits_per_sec'],
    query_title: `Inter-Site Traffic ${queryTitleSuffix}`,
    filters: buildFilterGroup({
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          connector: 'Any',
          not: true,
          filters: [
            {
              filterField: 'kt_src_site_title',
              operator: '=',
              filterValue: ''
            },
            {
              filterField: 'kt_dst_site_title',
              operator: '=',
              filterValue: ''
            }
          ]
        }),

        buildFilterGroup({
          connector: 'Any',
          filters: [
            {
              filterField: 'i_src_network_bndry_name',
              operator: '=',
              filterValue: 'external'
            },
            {
              filterField: 'i_src_connect_type_name',
              operator: '=',
              filterValue: 'backbone'
            },
            {
              filterField: 'i_src_connect_type_name',
              operator: '=',
              filterValue: 'datacenter_interconnect'
            }
          ]
        })
      ]
    })
  };

  if (queryType === 'ultimateExit') {
    query.metric = ['i_device_site_name', 'i_ult_exit_site'];
    query.filters = {
      connector: 'All',
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'All',
          not: false,
          autoAdded: '',
          filters: [
            {
              filterField: 'i_device_site_name',
              operator: 'misc_flags_not_set',
              filterValue: ''
            }
          ],
          saved_filters: [],
          filterGroups: []
        },
        {
          name: '',
          named: false,
          connector: 'All',
          not: true,
          autoAdded: '',
          filters: [
            {
              filterField: 'i_ult_exit_site',
              operator: '=',
              filterValue: ''
            }
          ],
          saved_filters: [],
          filterGroups: []
        }
      ]
    };
  }

  if (source && target) {
    query.query_title = `Inter-Site Traffic ${queryTitleSuffix} From ${source} To ${target}`;

    if (queryType === 'ip') {
      query.filters.filterGroups.push(
        buildFilterGroup({
          connector: 'All',
          filters: [
            {
              filterField: 'kt_src_site_title',
              operator: '=',
              filterValue: source
            },

            {
              filterField: 'kt_dst_site_title',
              operator: '=',
              filterValue: target
            }
          ]
        })
      );
    }

    if (queryType === 'ultimateExit') {
      query.filters.filterGroups.push(
        buildFilterGroup({
          connector: 'All',
          filters: [
            {
              filterField: 'i_device_site_name',
              operator: '=',
              filterValue: source
            },

            {
              filterField: 'i_ult_exit_site',
              operator: '=',
              filterValue: target
            }
          ]
        })
      );
    }
  }

  if (isPopoverQuery === true) {
    return toPopoverQuery(query);
  }

  return query;
}

// directional context is the detail site where the device lives
export function getDeviceSiteNodeLinkQuery(
  deviceModel,
  siteName,
  { direction = 'inbound', type = 'ip', isPopoverQuery = false } = {}
) {
  const deviceName = deviceModel.get('device_name');
  const siteFilterField = direction === 'inbound' ? 'kt_src_site_title' : 'kt_dst_site_title';
  const dimDirection = direction === 'inbound' ? 'src' : 'dst';
  const queryTitleSuffix = getQueryTitleSuffix(type);

  // assumes 'by ip'
  const query = {
    all_devices: false,
    device_name: [deviceName],
    topx: 0,
    depth: 500,
    viz_type: 'table',
    show_overlay: false,
    show_total_overlay: false,
    metric: ['kt_dst_site_title'],
    aggregateTypes: ['avg_bits_per_sec'],
    fastData: 'Fast',
    query_title: `From "Other Sites" To ${deviceName} In Site ${siteName} ${queryTitleSuffix}`,
    filters: buildFilterGroup({
      connector: 'All',
      filterGroups: [
        // any external boundary or backbone connectivity type or datacenter interconnect connectivity type
        buildFilterGroup({
          connector: 'Any',
          filters: [
            {
              filterField: `i_${dimDirection}_network_bndry_name`,
              operator: '=',
              filterValue: 'external'
            },
            {
              filterField: `i_${dimDirection}_connect_type_name`,
              operator: '=',
              filterValue: 'backbone'
            },
            {
              filterField: `i_${dimDirection}_connect_type_name`,
              operator: '=',
              filterValue: 'datacenter_interconnect'
            }
          ]
        }),

        // exclude any blank src|dst site by ips
        buildFilterGroup({
          connector: 'Any',
          not: true,
          filters: [
            {
              filterField: 'kt_src_site_title',
              operator: '=',
              filterValue: ''
            },
            {
              filterField: 'kt_dst_site_title',
              operator: '=',
              filterValue: ''
            }
          ]
        }),

        // exclude any traffic to itself
        buildFilterGroup({
          connector: 'All',
          not: true,
          filters: [
            {
              filterField: 'kt_src_site_title',
              operator: '=',
              filterValue: siteName
            },
            {
              filterField: 'kt_dst_site_title',
              operator: '=',
              filterValue: siteName
            }
          ]
        }),

        // determined by in|outbound direction
        // inbound query will exclude site by ip
        // outbound query will exclude destination site by ip
        buildFilterGroup({
          connector: 'All',
          not: true,
          filters: [
            {
              filterField: siteFilterField,
              operator: '=',
              filterValue: siteName
            }
          ]
        })
      ]
    })
  };

  if (direction === 'outbound') {
    query.query_title = `From ${deviceName} In Site ${siteName} To "Other Sites" ${queryTitleSuffix}`;
  }

  if (type === 'ultimateExit') {
    query.device_name = [];

    if (direction === 'inbound') {
      query.all_devices = true;
      query.metric = 'i_device_site_name';
      query.filters = buildFilterGroup({
        connector: 'All',
        filterGroups: [
          buildFilterGroup({
            connector: 'All',
            filters: [
              {
                filterField: 'i_ult_exit_device_name',
                operator: '=',
                filterValue: deviceName
              }
            ]
          }),

          buildFilterGroup({
            connector: 'Any',
            not: true,
            filters: [
              {
                filterField: 'i_device_site_name',
                operator: '=',
                filterValue: siteName
              },

              {
                filterField: 'i_device_site_name',
                operator: '=',
                filterValue: ''
              }
            ]
          })
        ]
      });
    }

    if (direction === 'outbound') {
      const siteModel = getSiteModel(siteName);

      if (siteModel) {
        query.device_sites = [siteModel.id];
        query.metric = ['i_ult_exit_site'];
        query.filters = buildFilterGroup({
          connector: 'All',
          filterGroups: [
            buildFilterGroup({
              connector: 'All',
              filters: [
                {
                  filterField: 'i_device_id',
                  operator: '=',
                  filterValue: deviceModel.id
                }
              ]
            }),

            buildFilterGroup({
              connector: 'Any',
              not: true,
              filters: [
                {
                  filterField: 'i_ult_exit_site',
                  operator: '=',
                  filterValue: siteName
                },
                {
                  filterField: 'i_ult_exit_site',
                  operator: '=',
                  filterValue: ''
                }
              ]
            })
          ]
        });
      }
    }
  }

  if (isPopoverQuery === true) {
    return toPopoverQuery(query);
  }

  return query;
}

// directional context is the detail site
export function getSiteDeviceNodeLinkQuery(
  selectedSiteModel,
  detailSiteModel,
  { direction = 'inbound', type = 'ip', isPopoverQuery = false } = {}
) {
  const dimDirection = direction === 'inbound' ? 'src' : 'dst';
  const queryTitleSuffix = getQueryTitleSuffix(type);
  const detailSiteName = detailSiteModel.get('title');
  const selectedSiteName = selectedSiteModel.get('title');

  // assuming 'by ip'
  const query = {
    all_devices: false,
    device_sites: [detailSiteModel.id],
    topx: 0,
    depth: 500,
    viz_type: 'table',
    show_overlay: false,
    show_total_overlay: false,
    metric: ['device_id'],
    aggregateTypes: ['avg_bits_per_sec'],
    fastData: 'Fast',
    query_title: `From ${selectedSiteName} To Devices In ${detailSiteName} ${queryTitleSuffix}`,
    filters: buildFilterGroup({
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          connector: 'Any',
          filters: [
            {
              filterField: `i_${dimDirection}_network_bndry_name`,
              operator: '=',
              filterValue: 'external'
            },

            {
              filterField: `i_${dimDirection}_connect_type_name`,
              operator: '=',
              filterValue: 'backbone'
            },

            {
              filterField: `i_${dimDirection}_connect_type_name`,
              operator: '=',
              filterValue: 'datacenter_interconnect'
            }
          ]
        }),

        buildFilterGroup({
          connector: 'All',
          filters: [
            {
              filterField: 'kt_dst_site_title',
              operator: '=',
              filterValue: detailSiteName
            },
            {
              filterField: 'kt_src_site_title',
              operator: '=',
              filterValue: selectedSiteName
            }
          ]
        })
      ]
    })
  };

  if (direction === 'outbound') {
    query.query_title = `From Devices In ${detailSiteName} To ${selectedSiteName} ${queryTitleSuffix}`;
  }

  if (type === 'ultimateExit') {
    if (direction === 'inbound') {
      query.device_sites = [selectedSiteModel.id];
      query.metric = ['ult_exit_device_id'];
      query.filters = buildFilterGroup({
        connector: 'All',
        filterGroups: [
          buildFilterGroup({
            connector: 'All',
            filters: [
              {
                filterField: 'i_device_site_name',
                operator: '=',
                filterValue: selectedSiteName
              },

              {
                filterField: 'i_ult_exit_site',
                operator: '=',
                filterValue: detailSiteName
              }
            ]
          }),

          buildFilterGroup({
            connector: 'Any',
            not: true,
            filters: [
              {
                filterField: 'ult_exit_device_id',
                operator: '=',
                filterValue: '0'
              }
            ]
          })
        ]
      });
    }

    if (direction === 'outbound') {
      query.device_sites = [detailSiteModel.id];
      query.metric = ['device_id'];
      query.filters = buildFilterGroup({
        connector: 'All',
        filterGroups: [
          buildFilterGroup({
            connector: 'All',
            filters: [
              {
                filterField: 'i_device_site_name',
                operator: '=',
                filterValue: detailSiteName
              },

              {
                filterField: 'i_ult_exit_site',
                operator: '=',
                filterValue: selectedSiteName
              }
            ]
          })
        ]
      });
    }
  }

  if (isPopoverQuery === true) {
    return toPopoverQuery(query);
  }

  return query;
}

export function getOtherSitesQuery(detailSiteName) {
  return {
    topx: 0,
    depth: 500,
    viz_type: 'table',
    show_overlay: false,
    show_total_overlay: false,
    metric: ['i_device_site_name'],
    aggregateTypes: ['avg_bits_per_sec'],
    query_title: 'Discover Other Sites',
    filters: buildFilterGroup({
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          connector: 'All',
          not: true,
          filters: [
            {
              filterField: 'i_device_site_name',
              operator: '=',
              filterValue: ''
            }
          ]
        }),

        buildFilterGroup({
          connector: 'Any',
          filters: [
            {
              filterField: 'i_src|dst_network_bndry_name',
              operator: '=',
              filterValue: 'external'
            },
            {
              filterField: 'i_src|dst_connect_type_name',
              operator: '=',
              filterValue: 'backbone'
            },
            {
              filterField: 'i_src|dst_connect_type_name',
              operator: '=',
              filterValue: 'datacenter_interconnect'
            }
          ]
        }),

        buildFilterGroup({
          connector: 'Any',
          not: true,
          filters: [
            {
              filterField: 'i_device_site_name',
              operator: '=',
              filterValue: detailSiteName
            }
          ]
        })
      ]
    })
  };
}

export function debugPanel(queries) {
  const debugQueries = [].concat(queries);

  return (
    <CalloutOutline p={2} mt={1} intent="primary">
      <Flex mb={1}>
        <Icon icon="series-search" mr={1} color="primary" />
        <Heading level={5} color="primary">
          Inter-Site Traffic Queries
        </Heading>
      </Flex>
      {debugQueries
        .filter((q) => q.query_title)
        .map((q) => (
          <Box key={q.query_title}>
            <ViewInExplorerButton query={q} text={q.query_title} minimal={false} icon={null} mb={1} openInNewWindow />
          </Box>
        ))}
    </CalloutOutline>
  );
}
