import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Box, EmptyState, Flex, Grid, Heading, Spinner, Text } from 'core/components';
import { toCIDRNotation } from 'core/util/ip';
import DeviceLink from 'app/components/links/DeviceLink';
import InterfaceLink from 'app/components/links/InterfaceLink';
import IpLink from 'app/components/links/IpLink';
import OperStatus from 'app/views/hybrid/maps/components/OperStatus';
import { getHealthCheck } from 'app/views/hybrid/utils/health';

@inject('$interfaces')
@observer
export default class InterfaceMetadataDetails extends React.Component {
  state = {
    interface1Model: null,
    interface2Model: null,
    loading: true
  };

  componentDidMount() {
    const { $interfaces, interface1, interface2 } = this.props;

    Promise.all([$interfaces.fetchInterface({ ...interface1 }), $interfaces.fetchInterface({ ...interface2 })])
      .then(([interface1Model, interface2Model]) => {
        this.setState({ interface1Model, interface2Model, loading: false });
      })
      .catch((e) => {
        console.error('Error loading interface', e);
        this.setState({ loading: false });
      });
  }

  renderInterfaceSummary(model, interfaceProps) {
    if (!model) {
      return <EmptyState icon="inbox" title="Unknown Device(s)" color="muted" />;
    }

    let operStatusHealthCheck = null;

    if (interfaceProps && interfaceProps.health) {
      operStatusHealthCheck = getHealthCheck(interfaceProps.health, 'interface_availability_operstatus');
    }

    return (
      <Grid gridTemplateColumns="auto 1fr" fontSize={12} gridGap="5px">
        <Box gridColumn="1/3">
          <Heading level={5}>
            <InterfaceLink
              device_name={model.get('device').get('device_name')}
              interface_description={model.get('interface_description') || 'No Description'}
              snmp_id={model.get('snmp_id')}
            />
          </Heading>
          <Text color="muted">{model.get('snmp_alias')}</Text>
        </Box>
        {operStatusHealthCheck && (
          <>
            <Text color="muted" whiteSpace="nowrap">
              OperStatus
            </Text>
            <OperStatus check={operStatusHealthCheck} />
          </>
        )}
        <Text color="muted" whiteSpace="nowrap">
          Device
        </Text>
        <DeviceLink name={model.get('device').get('device_name')} ellipsis />
        <Text color="muted" whiteSpace="nowrap">
          IP Address
        </Text>
        {model.get('interface_ip') ? (
          <IpLink ip_address={toCIDRNotation(model.get('interface_ip'), model.get('interface_ip_netmask'))} ellipsis />
        ) : (
          <Text>None</Text>
        )}
        <Text color="muted" whiteSpace="nowrap">
          Capacity
        </Text>
        <Text>{model.capacityLabel}</Text>
        <Text color="muted" whiteSpace="nowrap">
          Network Boundary
        </Text>
        <Text>{model.networkBoundary}</Text>
        <Text color="muted" whiteSpace="nowrap">
          Connectivity Type
        </Text>
        <Text ellipsis>{model.connectivityType}</Text>
      </Grid>
    );
  }

  render() {
    const { children, interface1, interface2 } = this.props;
    const { interface1Model, interface2Model, loading } = this.state;

    if (loading) {
      return (
        <Flex alignItems="center" justifyContent="center" flex={1} minHeight={150}>
          <Spinner />
        </Flex>
      );
    }

    return children({
      interface1Cmp: this.renderInterfaceSummary(interface1Model, interface1),
      interface2Cmp: this.renderInterfaceSummary(interface2Model, interface2)
    });
  }
}
