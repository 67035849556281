import * as React from 'react';
import { Box, Flex, Text } from 'core/components';

export default class ConnectionTypeSummary extends React.Component {
  render() {
    const { collection, serviceKey } = this.props;
    const { connectionType, cloudProvider } = collection.rollup[serviceKey];

    return (
      <Box>
        {['public', 'private'].map((type) => {
          const connectionTypeCount = connectionType[type];

          if (connectionTypeCount > 0) {
            return (
              <Flex key={type} alignItems="center">
                <Text fontWeight="bold" small>
                  {type} ({connectionTypeCount}{' '}
                  {`${cloudProvider === 'azure' ? 'VNET' : 'VPC'}${connectionTypeCount === 1 ? '' : 's'}`})
                </Text>
              </Flex>
            );
          }

          return null;
        })}
      </Box>
    );
  }
}
