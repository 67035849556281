import { get } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import { FiCopy } from 'react-icons/fi';
import { Box, Button, Flex, Grid, Text } from 'core/components';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';

const Label = styled(Text)`
  color: ${(props) => props.theme.colors.muted};
`;

// overrides selected keys to use as labels
const labelMap = {
  id: 'ID',
  name: 'Name',
  type: 'Type',
  VpcId: 'VPC ID',
  CidrBlock: 'CIDR',
  ipCidrRange: 'CIDR',
  OwnerId: 'Account ID',
  tenantId: 'Tenant ID',
  SubnetId: 'Subnet ID',
  PublicIp: 'Public IP',
  SubnetIds: 'Subnet ID',
  PrivateIp: 'Private IP',
  location: 'Location',
  resourceGroup: 'Resource Group',
  ShortServiceName: 'Service Name',
  subscriptionId: 'Subscription ID',
  PrivateIpAddress: 'Private IP',
  PublicIpAddress: 'Public IP',
  AttachmentId: 'Attachment ID',
  TransitGatewayRouteTableId: 'Route Table ID',
  NetworkInterfaceId: 'Network Interface ID',
  description: 'Description',
  selfLink: 'Resource URL',
  project: 'Project',
  region: 'Region',
  SamplingRate: 'Sampling Rate',
  SamplingEnabled: 'Sampling Enabled',
  compartmentId: 'Compartment id',
  tenancyId: 'Tenancy id'
};

// predefined sorted keys we'll use if keys aren't sent in with the node
const keySort = ['id', 'tenantId', 'subscriptionId', 'resourceGroup'];

// keys that aren't useful for copying
const keysWithCopyDisabled = ['State'];

export default class CloudMetadata extends React.Component {
  static defaultProps = {
    node: {},
    keys: [],
    small: false
  };

  get sortedKeys() {
    const { keys, node } = this.props;
    let sortedKeys = keys || [];

    if (sortedKeys.length === 0) {
      // we want to interrogate the node's keys and sort them
      sortedKeys = Object.keys(node).sort((a, b) => {
        // find the index position of each value in the predefined key sort
        const aIndex = keySort.indexOf(a);
        const bIndex = keySort.indexOf(b);
        // if we don't find it in the key sort, drop down to the bottom
        const aCompare = aIndex > -1 ? aIndex : Number.MAX_VALUE;
        const bCompare = bIndex > -1 ? bIndex : Number.MAX_VALUE;

        return aCompare - bCompare;
      });
    }

    return sortedKeys;
  }

  getCopyButton = (key, value) => {
    const copyIsDisabled = keysWithCopyDisabled.indexOf(key) > -1;

    if (copyIsDisabled) {
      return null;
    }

    return (
      <CopyToClipboardButton text={value} copyConfirmationText={null} intent="none" ml="4px" minimal small>
        <Button icon={FiCopy} />
      </CopyToClipboardButton>
    );
  };

  renderItem = (key) => {
    const { node, small, renderItem } = this.props;
    const value = node && get(node, key);

    // split . to allow using path as a key: e.g: NatGatewayAddresses.0.PrivateIp
    const labelKey = key.split('.').slice(-1);
    const label = labelMap[labelKey] || labelKey;

    if (label && value) {
      let valueComponent = <Text small={small}>{value}</Text>;
      const valueIsArray = Array.isArray(value);

      if (valueIsArray) {
        if (value.length === 0) {
          return null;
        }

        valueComponent = (
          <Box>
            {value.map((item) => (
              <Box key={item}>
                {item} {this.getCopyButton(key, item)}
              </Box>
            ))}
          </Box>
        );
      }

      if (key === 'Tags') {
        valueComponent = this.renderTags(value);
      }

      if (renderItem) {
        valueComponent = renderItem(key, value, node) || valueComponent;
      }

      if (valueComponent) {
        return (
          <React.Fragment key={key}>
            <Label small={small}>{label}</Label>
            <Flex alignItems="flex-start" minHeight={24}>
              <Box overflow="hidden" whiteSpace="nowrap" style={{ textOverflow: 'ellipsis' }}>
                {valueComponent}
              </Box>
              {key !== 'Tags' && !valueIsArray && this.getCopyButton(key, value)}
            </Flex>
          </React.Fragment>
        );
      }
    }

    return null;
  };

  getTagList = (tags) => {
    if (tags) {
      if (Array.isArray(tags)) {
        // should already be in { Key, Value } format (AWS style)
        return tags;
      }

      // assuming this is in Azure format { [KEY]: [VALUE] }
      return Object.keys(tags).reduce((acc, Key) => acc.concat({ Key, Value: tags[Key] }), []);
    }

    return [];
  };

  renderTags = (tags) => {
    const tagList = this.getTagList(tags);

    if (tagList.length > 0) {
      return tagList.map(({ Key, Value }) => (
        <Box key={Key}>
          {Key}={Value} {this.getCopyButton('Tags', `${Key}=${Value}`)}
        </Box>
      ));
    }

    return null;
  };

  render() {
    const { onFormChange, ...gridProps } = this.props;
    const { sortedKeys } = this;

    if (sortedKeys && sortedKeys.length > 0) {
      return (
        <Grid gridTemplateColumns="auto minmax(0, 1fr)" gridRowGap="4px" gridColumnGap={3} {...gridProps}>
          {sortedKeys.map((key) => this.renderItem(key))}
        </Grid>
      );
    }

    return null;
  }
}
