import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, Heading, Text } from 'core/components';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import { getVpcEndpointProps } from 'app/views/hybrid/maps/components/popovers/cloudUtil';

function getTitle(props) {
  return props.nodeData?.ServiceName || props.value;
}

const VpcEndpointSummary = (props) => {
  const { logo, nodeData } = props;
  const { type = 'VPC Endpoint', icon = logo } = nodeData ? getVpcEndpointProps(nodeData) : {};

  return (
    <Box>
      <Flex borderBottom="thin" p={2} alignItems="center">
        {React.cloneElement(icon, { width: 32, height: 32 })}
        <Box flex={1} ml={1}>
          <Text as="div" muted>
            {type} Details
          </Text>
          <Heading mb={0} level={4}>
            {getTitle(props)}
          </Heading>
        </Box>
      </Flex>
      {nodeData && (
        <SidebarItem title="Details" icon="more" popoutTitle={VpcEndpointSummary.PopoutTitle(props)}>
          <CloudMetadata
            node={nodeData}
            keys={['id', 'OwnerId', 'ShortServiceName', 'SubnetIds', 'NetworkInterfaceIds']}
          />
        </SidebarItem>
      )}
    </Box>
  );
};

VpcEndpointSummary.PopoutTitle = (props) => {
  const { logo, nodeData } = props;
  const { type = 'VPC Endpoint', icon = logo } = nodeData ? getVpcEndpointProps(nodeData) : {};

  return (
    <Flex alignItems="center">
      {React.cloneElement(icon)}
      <Box ml={1}>
        {getTitle(props)} {type}
      </Box>
    </Flex>
  );
};

export default observer(VpcEndpointSummary);
