import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { get, uniq, uniqBy, isEqual } from 'lodash';
import classNames from 'classnames';
import { Box, EmptyState, Flex, Spinner, Grid, Icon } from 'core/components';
import DeviceSubtypeIcon from 'app/components/device/DeviceSubtypeIcon';
import DeviceLink from 'app/components/links/DeviceLink';
import SiteLink from 'app/components/links/SiteLink';
import AbstractMap from './components/AbstractMap';
import Clouds from './components/Clouds';
import TopKeys from './components/TopKeys/TopKeys';
import TopLevelBox from './components/TopLevelBox';
import NodeLinkGenerator from './components/NodeLinkGenerator';
import ArcLayoutWithFallback from './layouts/ArcLayoutWithFallback';
import DeviceInterfaces from './components/DeviceInterfaces';
import DeviceMetrics from './components/deviceMetrics/DeviceMetrics';
import HealthTag from './components/HealthTag';
import withPopover from './components/popovers/withPopover';
import { getHealthClass } from '../utils/health';
import { getLayerIndex } from '../utils/siteArchitecture';

export function getDeviceLinks(deviceLinks, thisDevice, otherDevices) {
  return deviceLinks
    .map((link) => {
      const { snmp_speed, linkLabel, bundled_connections, layer2_connections, layer3_connections } = link;
      const thisSide = link.device1_id === thisDevice ? 1 : 2;
      const otherSide = link.device1_id === thisDevice ? 2 : 1;
      const device = link[`device${otherSide}_id`];

      if (!otherDevices.includes(device)) {
        return null;
      }

      const connections = link.connections.map((connection) => {
        const thisInterface = connection[`interface${thisSide}`];
        const otherInterface = connection[`interface${otherSide}`];
        return { thisInterface, otherInterface };
      });

      return {
        device,
        connections,
        snmp_speed,
        linkLabel,
        bundled_connections,
        layer3_connections,
        layer2_connections
      };
    })
    .filter((link) => link !== null);
}

export function concatLayerDevices(layer) {
  return layer
    ? layer.subLayers.reduce((arr, { devices }) => arr.concat(devices), []).map((device) => Number(device))
    : [];
}

function getInterfaceHealth(intf, health) {
  const { device_id, snmp_id } = intf;
  const itemHealth = get(health, `interfaces.${device_id}.${snmp_id}`);

  if (itemHealth) {
    return {
      cssClassName: getHealthClass(itemHealth),
      data: itemHealth
    };
  }

  return null;
}

export function getInterfaces(topology) {
  const {
    upstreamLinks = [],
    parallelLinks = [],
    downstreamLinks = [],
    externalLinks = [],
    unlinkedInterfaces = [],
    siteLinks = [],
    health
  } = topology;

  const upstream = upstreamLinks
    .flatMap((link) => link.connections.map(({ thisInterface }) => thisInterface))
    .filter(({ snmp_id }) => snmp_id !== undefined);
  const parallel = parallelLinks
    .flatMap((link) => link.connections.map(({ thisInterface }) => thisInterface))
    .filter(({ snmp_id }) => snmp_id !== undefined);
  const downstream = downstreamLinks
    .flatMap((link) => link.connections.map(({ thisInterface }) => thisInterface))
    .filter(({ snmp_id }) => snmp_id !== undefined);
  const external = externalLinks
    .flatMap((link) => link.connections.map(({ interface1 }) => interface1))
    .filter(({ snmp_id }) => snmp_id !== undefined);
  const site = siteLinks
    .flatMap((link) => link.connections.map(({ interface1 }) => interface1))
    .filter(({ snmp_id }) => snmp_id !== undefined);

  const allInterfaces = {
    upstream: uniqBy(upstream, 'snmp_id'),
    parallel: uniqBy(parallel, 'snmp_id'),
    downstream: uniqBy(downstream, 'snmp_id'),
    external: uniqBy(external, 'snmp_id'),
    site: uniqBy(site, 'snmp_id'),
    unlinked: unlinkedInterfaces
  };

  Object.values(allInterfaces).forEach((interfaces) => {
    interfaces.forEach((intf) => (intf.health = getInterfaceHealth(intf, health)));
  });

  return allInterfaces;
}

function OtherDevices({ items, width, ...props }) {
  const height = 150;
  const arcHeight = items.length > 2 ? 50 : 1;
  const padding = Math.max(40, width - (items.length - 1) * 100);

  return (
    <svg width={width} height={height}>
      <g transform={`translate(${padding / 2}, ${(height - arcHeight) / 2})`}>
        <ArcLayoutWithFallback
          items={items}
          itemType="router"
          width={width - padding}
          arcHeight={arcHeight}
          getLink={() => null}
          {...props}
        />
      </g>
    </svg>
  );
}

@withPopover
@withRouter
@inject('$hybridMap', '$devices', '$sites')
@observer
export default class DeviceDetailsMap extends AbstractMap {
  static defaultProps = {
    ...super.defaultProps,
    isRenderedBySidebar: false
  };

  constructor(props) {
    super(props);

    const { $hybridMap } = this.props;

    Object.assign(this.state, {
      loading: true,
      boxExpanded: {
        cloud: $hybridMap.clouds.length > 0,
        internet: true
      }
    });
  }

  componentDidMount() {
    this.fetchTopology();
  }

  componentDidUpdate(prevProps, prevState) {
    const { id, sidebarSettings } = this.props;
    const { selectedNode } = this.state;

    if (prevProps.id !== id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ loading: true }, () => {
        this.fetchTopology();

        if (selectedNode) {
          // deselect the currently selected node to clean up drawn links etc.
          this.handleSelectNode({ type: selectedNode.type, value: selectedNode.value });
        }
      });
    } else if (prevProps.sidebarSettings.connectionType !== sidebarSettings.connectionType) {
      // reset node selections
      this.handleSelectNode({ value: null });

      // load the topology using the new layers config
      this.fetchTopology();
    } else {
      super.componentDidUpdate(prevProps, prevState);
    }
  }

  fetchTopology() {
    const { id, $hybridMap, $devices, $sites } = this.props;
    const deviceSummary = $devices.deviceSummariesById[id];

    if (!deviceSummary) {
      this.setState({ loading: false });
      return;
    }

    const { device_name: name } = deviceSummary;

    Promise.all([
      $hybridMap.getTopologyDataForDevice(id),
      $hybridMap.getHealthCollection('device', id),
      $devices.fetchDevice(name),
      $sites.collection.fetch()
    ])
      .then(([topology, healthCollection, device]) => {
        const { deviceLinks } = topology;

        const site = $sites.collection.get(device.get('site.id'));
        const layers = site.get('metadata.architecture') || [];
        const { layerIndex, subLayerIndex } = getLayerIndex(layers, id);

        if (layerIndex > -1) {
          topology.upstreamLinks = getDeviceLinks(deviceLinks, id, concatLayerDevices(layers[layerIndex - 1]));
          topology.parallelLinks = getDeviceLinks(deviceLinks, id, concatLayerDevices(layers[layerIndex]));
          topology.downstreamLinks = getDeviceLinks(deviceLinks, id, concatLayerDevices(layers[layerIndex + 1]));
        } else {
          topology.upstreamLinks = [];
          topology.parallelLinks = [];
          topology.downstreamLinks = [];
        }

        topology.upstreamDevices = uniq(topology.upstreamLinks.map((link) => link.device));
        topology.downstreamDevices = uniq(topology.downstreamLinks.map((link) => link.device));
        topology.parallelDevices = uniq(topology.parallelLinks.map((link) => link.device));

        topology.interfaces = getInterfaces(topology);

        this.setState({ topology, healthCollection, device, site, layerIndex, subLayerIndex, loading: false }, () =>
          this.onTopologyReady(topology)
        );
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  getInterface(snmp_id) {
    const { topology } = this.state;
    const { interfaces } = topology;
    let foundInterface;

    Object.values(interfaces).forEach((interfaceList) => {
      foundInterface = foundInterface || interfaceList.find((intf) => intf.snmp_id === snmp_id);
    });

    return foundInterface;
  }

  shouldTargetLinkRollUp(type) {
    return ['cloud', 'internet'].includes(type);
  }

  getNodePosition({ type, value }) {
    const { points, mask, svg, node } = super.getNodePosition({ type, value });

    if (points.length === 0) {
      return { points };
    }

    if (type === 'upstream' || type === 'parallel') {
      points.push([node.cx, node.cy + 100]);
    } else if (type === 'downstream') {
      points.push([node.cx, node.cy - 100]);
    } else if (type === 'interface') {
      const groupRect = node.element.closest('.interface-group').getBoundingClientRect();
      const groupTop = groupRect.top - svg.rect.top + 3;
      const groupBottom = groupRect.bottom - svg.rect.top;
      const groupLeft = groupRect.left - svg.rect.left + 12;

      if (node.element.classList.contains('downstream')) {
        points.push([node.cx, node.cy + 20], [node.cx, groupBottom + 100]);
      } else {
        const boxRect = node.element.closest('.box-interface').getBoundingClientRect();
        const boxTop = boxRect.top - svg.rect.top;

        points.push(
          [node.cx, node.cy - 20],
          [node.cx, groupTop],
          [node.cx - 20, groupTop],
          [groupLeft + 20, groupTop],
          [groupLeft, groupTop],
          [groupLeft, groupTop - 20],
          [groupLeft, boxTop],
          [groupLeft, boxTop - 50]
        );
      }
    }

    return { points, mask };
  }

  getLinksFor(type) {
    const { topology } = this.state;

    if (type === 'upstream') {
      return topology.upstreamLinks;
    }

    if (type === 'parallel') {
      return topology.parallelLinks;
    }

    if (type === 'downstream') {
      return topology.downstreamLinks;
    }

    if (type === 'interface') {
      return [...topology.upstreamLinks, ...topology.parallelLinks, ...topology.downstreamLinks];
    }

    return [];
  }

  getSelectedLinksFor(type, selectedNode = this.state.selectedNode) {
    if (selectedNode) {
      const { type: selectedType, value } = selectedNode;

      if (selectedType === 'interface') {
        const links = this.getLinksFor(type);
        return links.filter(({ connections }) =>
          connections.some(({ thisInterface }) => thisInterface.snmp_id === value)
        );
      }

      if (type === 'interface') {
        const links = this.getLinksFor(selectedType);
        return links.filter(({ device }) => device === value);
      }
    }

    return [];
  }

  getSelectedNodesFor(type, selectedNode = this.state.selectedNode) {
    const selectedLinks = this.getSelectedLinksFor(type, selectedNode);

    if (type === 'interface') {
      return uniq(
        selectedLinks.flatMap(({ connections }) => connections.map(({ thisInterface }) => thisInterface.snmp_id))
      );
    }

    return uniq(selectedLinks.map(({ device }) => device));
  }

  getNodeLinks(selectedNode = this.state.selectedNode) {
    if (selectedNode) {
      const { type, value } = selectedNode;

      if (type === 'interface') {
        return [
          ...this.getSelectedNodesFor('upstream', selectedNode).map((device) => ({
            source: { type, value },
            target: { type: 'upstream', value: device }
          })),
          ...this.getSelectedNodesFor('parallel', selectedNode).map((device) => ({
            source: { type, value },
            target: { type: 'parallel', value: device }
          })),
          ...this.getSelectedNodesFor('downstream', selectedNode).map((device) => ({
            source: { type, value },
            target: { type: 'downstream', value: device }
          }))
        ];
      }

      return this.getSelectedNodesFor('interface', selectedNode).map((intf) => ({
        source: { type, value },
        target: { type: 'interface', value: intf }
      }));
    }

    return [];
  }

  getNodeLinkQueries({ selectedNode, activeInternetTabId } = this.state) {
    const { id, $hybridMap } = this.props;

    if (selectedNode) {
      const { type: source, value: sourceValue } = selectedNode;
      let types = [];

      if (source === 'cloud' || source === 'internet') {
        types = ['interface'];
      } else if (source === 'interface') {
        types = ['cloud', 'internet'];
      }

      return types.flatMap((target) => {
        const type = target === 'internet' ? activeInternetTabId : target;
        const [inboundQuery, outboundQuery] = $hybridMap.getNodeLinkQuery(
          { selectedNode: { ...selectedNode, value: sourceValue }, type },
          { device: id }
        );

        if (inboundQuery && outboundQuery) {
          return [
            { direction: 'inbound', source, sourceValue, target, query: inboundQuery },
            { direction: 'outbound', source, sourceValue, target, query: outboundQuery }
          ];
        }

        return [];
      });
    }

    return [];
  }

  getNodeData({ type, value }, data) {
    const { topology, healthCollection } = this.state;

    if (type === 'upstream' || type === 'downstream' || type === 'parallel') {
      return {
        health: topology.devices[value]?.health,
        healthIssues: healthCollection.getIssues({ device_id: value }, { doLookups: true })
      };
    }

    if (type === 'interface') {
      return {
        health: getInterfaceHealth(data, topology.health),
        healthIssues: healthCollection.getIssues({ snmp_id: value }, { doLookups: true })
      };
    }

    return null;
  }

  getSidebarConfig({ selectedNode } = this.state) {
    const { device, topology } = this.state;
    const { type, value } = selectedNode;
    let data = null;

    if (type === 'interface') {
      data = this.getInterface(value);
    } else if (type === 'upstream' || type === 'downstream' || type === 'parallel') {
      data = topology.devices[value];
    }

    return {
      ...selectedNode,
      value: data || value,
      nodeData: this.getNodeData(selectedNode, data),
      links: this.getSelectedLinksFor('interface', selectedNode),
      detail: {
        type: 'device',
        model: device
      }
    };
  }

  handleSelectNode({ type, value, force = false, nodeData }) {
    const { openPopover } = this.props;
    const { selectedNode } = this.state;

    super.handleSelectNode({ type, value, force, nodeData });

    this.setState((prevState) => {
      if (prevState.selectedNode) {
        const { points } = this.getNodePosition(prevState.selectedNode);
        const isSameNode = isEqual(selectedNode, prevState.selectedNode);
        const config = {
          ...this.getSidebarConfig(prevState),
          shortcutMenu: {
            selectedNode: prevState.selectedNode,
            showConnectionsCallback: this.setNodeLinks,
            isShowingConnections: prevState.nodeLinks.length > 0 && isSameNode
          }
        };

        if (points.length > 0) {
          // use the node position getter if available
          const [x, y] = points[0];
          config.position = { left: x, top: y };
        }

        openPopover(config);
      }

      return null;
    });
  }

  renderMap() {
    const { width, isRenderedBySidebar } = this.props;
    const {
      boxExpanded,
      loading,
      layerIndex,
      topology,
      device,
      site,
      nodeLinkQueries,
      nodeLinksLoading,
      activeInternetTabId
    } = this.state;
    const deviceHealth = get(topology, `devices[${this.props.id}].health`);

    if (loading) {
      return <Spinner mt={100} />;
    }

    if (!device || !topology) {
      return <EmptyState icon={<Icon icon="database" />} title="Device not found" mt={100} />;
    }

    const { upstreamDevices, parallelDevices, downstreamDevices, devices } = topology;

    const upstreamWidth = parallelDevices.length > 0 ? width / 2 - 20 : width - 4;
    const parallelWidth = upstreamDevices.length > 0 ? width / 2 - 20 : width - 4;
    const downstreamWidth = width - 4;

    return (
      <Box>
        {nodeLinksLoading && (
          <Box key="linksLoading" position="absolute" top="280px" width="100%">
            <Spinner intent="primary" />
          </Box>
        )}

        {!isRenderedBySidebar && layerIndex === 0 && (
          <>
            <Flex justifyContent="space-around" mb={5}>
              <TopLevelBox
                boxProps={{
                  className: classNames('box-cloud', 'link-bottomright', {
                    highlighted: this.isBoxHighlighted('cloud')
                  }),
                  minWidth: 200,
                  maxWidth: 400,
                  minHeight: 150
                }}
                title="Clouds"
                isExpanded={boxExpanded.cloud}
                onExpandToggle={(isExpanded) => this.setBoxExpanded('cloud', isExpanded)}
                expandedContent={
                  <Clouds
                    selected={this.getSelected('cloud')}
                    highlighted={this.getHighlighted('cloud')}
                    onSelect={(value) => this.handleSelectNode({ type: 'cloud', value })}
                  />
                }
              />
              <TopLevelBox
                boxProps={{
                  className: classNames('box-internet', 'link-bottomleft', {
                    highlighted: this.isBoxHighlighted('internet')
                  }),
                  width: 500,
                  height: 235
                }}
                title="Internet"
                isExpanded={boxExpanded.internet}
                onExpandToggle={(isExpanded) => this.setBoxExpanded('internet', isExpanded)}
                expandedContent={
                  <TopKeys
                    classPrefix="internet"
                    selected={this.getSelected('internet')}
                    hovered={this.getHovered('internet')}
                    highlighted={this.getHighlighted('internet')}
                    onSelect={(value) => this.handleSelectNode({ type: 'internet', value })}
                    onTabChange={this.handleInternetTabChange}
                    defaultTab={activeInternetTabId}
                    device={device.get('device_name')}
                  />
                }
              />
            </Flex>

            <Flex justifyContent="space-between" flexWrap="wrap">
              {upstreamDevices.length > 0 && (
                <Box mb={5}>
                  <TopLevelBox
                    boxProps={{
                      className: classNames('box-upstream', 'link-bottomcenter', {
                        highlighted: this.isBoxHighlighted('upstream')
                      }),
                      p: 0
                    }}
                    title="Upstream Connected Devices"
                    isExpanded
                    expandedContent={
                      <OtherDevices
                        items={upstreamDevices.map((id) => devices[id])}
                        width={upstreamWidth}
                        classPrefix="upstream"
                        selected={devices[this.getSelected('upstream')]}
                        hovered={devices[this.getHovered('upstream')]}
                        highlighted={this.getHighlighted('upstream').map((id) => devices[id])}
                        onSelect={(item, data) =>
                          this.handleSelectNode({ type: 'upstream', value: item?.id, nodeData: data })
                        }
                        onHover={(item) => this.handleHoverNode('upstream', item?.id)}
                      />
                    }
                  />
                </Box>
              )}
              {parallelDevices.length > 0 && (
                <Box mb={5}>
                  <TopLevelBox
                    boxProps={{
                      className: classNames('box-parallel', 'link-bottomcenter', {
                        highlighted: this.isBoxHighlighted('parallel')
                      }),
                      p: 0
                    }}
                    title="Parallel Connected Devices"
                    isExpanded
                    expandedContent={
                      <OtherDevices
                        items={parallelDevices.map((id) => devices[id])}
                        width={parallelWidth}
                        classPrefix="parallel"
                        selected={devices[this.getSelected('parallel')]}
                        hovered={devices[this.getHovered('parallel')]}
                        highlighted={this.getHighlighted('parallel').map((id) => devices[id])}
                        onSelect={(item, data) =>
                          this.handleSelectNode({ type: 'parallel', value: item?.id, nodeData: data })
                        }
                        onHover={(item) => this.handleHoverNode('parallel', item?.id)}
                      />
                    }
                  />
                </Box>
              )}
            </Flex>
          </>
        )}

        <TopLevelBox
          boxProps={{
            className: classNames('box-interface', { highlighted: this.isBoxHighlighted('interface') }),
            p: 0
          }}
          title={
            !isRenderedBySidebar && (
              <>
                <DeviceSubtypeIcon type={device.get('device_subtype')} />{' '}
                <DeviceLink name={device.get('device_name')} />
              </>
            )
          }
          isExpanded
          expandedContent={
            <Flex p={2} width={1} flexDirection={isRenderedBySidebar ? 'column' : 'row'} gap="10px">
              <Box
                pr={2}
                flex="1"
                pb={2}
                borderRight={isRenderedBySidebar ? 'none' : 'thin'}
                borderBottom={isRenderedBySidebar ? 'thin' : 'none'}
              >
                <Grid gridTemplateColumns="auto auto">
                  <Box color="muted">Status</Box>
                  <HealthTag health={deviceHealth} />

                  <Box color="muted">IP Address</Box>
                  <Box>{device.sendingIpsDisplay}</Box>

                  <Box color="muted">Site</Box>
                  <Box>
                    <SiteLink siteId={site.id}>{site.get('title')}</SiteLink>
                  </Box>

                  {device.hasVendor && (
                    <>
                      <Box color="muted">Model</Box>
                      <Box>
                        {device.get('device_vendor_type')} {device.get('device_model_type')}
                      </Box>
                    </>
                  )}

                  <Box color="muted">Sample Rate</Box>
                  <Box>1:{device.get('device_sample_rate')}</Box>

                  <Box color="muted">Machine Type</Box>
                  <Box>{device.deviceType}</Box>

                  <Box color="muted">Device ID</Box>
                  <Box>{device.id}</Box>
                </Grid>

                <Box borderTop="thin" mt={1} pt={1}>
                  <DeviceMetrics device={device} />
                </Box>
              </Box>
              {!isRenderedBySidebar && (
                <Box className="subBox-interface">
                  <DeviceInterfaces
                    width={isRenderedBySidebar ? width : width - 300}
                    isRenderedBySidebar={isRenderedBySidebar}
                    device={device}
                    topology={topology}
                    selected={this.getSelected('interface')}
                    hovered={this.getHovered('interface')}
                    highlighted={this.getHighlighted('interface')}
                    onSelect={(item, data) =>
                      this.handleSelectNode({ type: 'interface', value: item?.snmp_id, nodeData: data })
                    }
                    onHover={(item) => this.handleHoverNode('interface', item?.snmp_id)}
                    onResize={this.handleDrawLinksRequest}
                  />
                </Box>
              )}
            </Flex>
          }
        />

        {!isRenderedBySidebar && downstreamDevices.length > 0 && (
          <Box mt={5}>
            <TopLevelBox
              boxProps={{
                className: classNames('box-downstream', 'link-topcenter', {
                  highlighted: this.isBoxHighlighted('downstream')
                }),
                p: 0
              }}
              title="Downstream Connected Devices"
              isExpanded
              expandedContent={
                <OtherDevices
                  items={downstreamDevices.map((id) => devices[id])}
                  width={downstreamWidth}
                  classPrefix="downstream"
                  itemType="leaf"
                  selected={devices[this.getSelected('downstream')]}
                  hovered={devices[this.getHovered('downstream')]}
                  highlighted={this.getHighlighted('downstream').map((id) => devices[id])}
                  onSelect={(item, data) =>
                    this.handleSelectNode({ type: 'downstream', value: item?.id, nodeData: data })
                  }
                  onHover={(item) => this.handleHoverNode('downstream', item?.id)}
                />
              }
            />
          </Box>
        )}

        <NodeLinkGenerator queries={nodeLinkQueries} onLinksUpdate={this.handleNodeLinksUpdate} />
      </Box>
    );
  }
}
