import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Box, Flex, InfoBox, LinkButton, Link, Text, Tag, Heading, Lookup } from 'core/components';
import InterfaceLink from 'app/components/links/InterfaceLink';
import IpLink from 'app/components/links/IpLink';
import InterfaceKvsMetadata from 'app/views/settings/interfaces/InterfaceKvsMetadata';
import InterfaceFlowStatus from 'app/views/settings/interfaces/InterfaceFlowStatus';
import InterfaceBoundaryASNs from 'app/components/InterfaceBoundaryASNs';

const Overridden = ({ isOverridden, ...rest }) =>
  !isOverridden ? (
    <></>
  ) : (
    <Text muted small {...rest}>
      (Overridden)
    </Text>
  );

export const InterfaceNameAndDescription = (props) => {
  const { interface_description, snmp_alias, ellipsis = true, small } = props;
  return (
    <>
      <Text small={small}>{interface_description}</Text>
      <InterfaceDescription snmp_alias={snmp_alias} ellipsis={ellipsis} small={small} />
    </>
  );
};

export const InterfaceNameSnmpIdAndDescription = (props) => {
  const { interface_description, snmp_alias, snmp_id, device_name, link, ellipsis = true, small } = props;
  return (
    <>
      {link && (
        <InterfaceLink snmp_id={snmp_id} device_name={device_name} interface_description={interface_description} />
      )}
      {!link && (
        <>
          <Text small={small}>{interface_description}</Text>
          <Text ml="4px" muted>
            ({snmp_id})
          </Text>
        </>
      )}
      <InterfaceDescription snmp_alias={snmp_alias} ellipsis={ellipsis} small={small} />
    </>
  );
};

export const InterfaceNameDeviceNameAndDescription = (props) => {
  const { interface_description, snmp_alias, snmp_id, device_name, link, ellipsis = true, small } = props;
  return (
    <>
      <Text muted small={small}>
        {device_name}
      </Text>{' '}
      {link && (
        <InterfaceLink snmp_id={snmp_id} device_name={device_name} interface_description={interface_description} />
      )}
      {!link && <Text small={small}>{interface_description}</Text>}
      <InterfaceDescription snmp_alias={snmp_alias} ellipsis={ellipsis} small={small} />
    </>
  );
};

export const InterfaceNameDeviceNameSnmpIdAndDescription = (props) => {
  const { interface_description, snmp_alias, snmp_id, device_name, link, ellipsis = true, small } = props;
  return (
    <>
      <Text muted small={small}>
        {device_name}
      </Text>{' '}
      {link && (
        <InterfaceLink snmp_id={snmp_id} device_name={device_name} interface_description={interface_description} />
      )}
      {!link && (
        <>
          <Text small={small}>{interface_description}</Text>
          <Text ml="4px" muted>
            ({snmp_id})
          </Text>
        </>
      )}
      <InterfaceDescription snmp_alias={snmp_alias} ellipsis={ellipsis} small={small} />
    </>
  );
};

export const InterfaceNameDeviceNameSnmpIdAndDescriptionLink = (props) => {
  const { snmp_id, interface_description, snmp_alias, device_name, ellipsis = true } = props;
  return (
    <>
      <InterfaceLink interface_description={interface_description} device_name={device_name} snmp_id={snmp_id} />{' '}
      {interface_description && interface_description !== '---' && <Text muted>({snmp_id})</Text>}
      <InterfaceDescription snmp_alias={snmp_alias} ellipsis={ellipsis} />
    </>
  );
};

export const InterfaceMetadataTags = inject('$dictionary')((props) => {
  const { $dictionary, network_boundary, connectivity_type, snmp_speed } = props;
  const networkBoundary = $dictionary.get(`interfaceClassification.networkBoundaryTypes.${network_boundary}`);
  const connectivityType = $dictionary.get(`interfaceClassification.connectivityTypes.${connectivity_type}`);

  let snmpSpeed = parseInt(snmp_speed) || 0;

  snmpSpeed = snmpSpeed >= 1000 ? `${snmpSpeed / 1000}Gbits/s` : `${snmpSpeed}Mbits/s`;

  return (
    <Flex>
      <Tag small minimal mr={1}>
        {snmpSpeed}
      </Tag>
      {connectivityType && (
        <Tag small minimal mr={1}>
          {connectivityType}
        </Tag>
      )}
      {networkBoundary && (
        <Tag small minimal mr={1}>
          {networkBoundary}
        </Tag>
      )}
    </Flex>
  );
});

export const InterfaceDescription = (props) => {
  const { snmp_alias, ellipsis = true, small } = props;

  if (!snmp_alias) {
    return null;
  }

  return (
    <Text as="div" muted ellipsis={ellipsis} title={snmp_alias} small={small}>
      {snmp_alias}
    </Text>
  );
};

export const InterfaceMetadata = inject('$dictionary')((props) => {
  const { $dictionary, interfaceMetadata = {} } = props;
  const { connectivityTypes, networkBoundaryTypes, provider } = interfaceMetadata;
  const ctDisplay = $dictionary.get(`interfaceClassification.connectivityTypes.${connectivityTypes}`);
  const nbDisplay = $dictionary.get(`interfaceClassification.networkBoundaryTypes.${networkBoundaryTypes}`);

  return (
    <>
      {!!networkBoundaryTypes && (
        <Tag minimal small mr={1}>
          {nbDisplay || networkBoundaryTypes}
          {/* <NetworkBoundaryLink value={networkBoundaryTypes} /> */}
        </Tag>
      )}
      {!!connectivityTypes && (
        <Tag minimal small mr={1}>
          {ctDisplay || connectivityTypes}
          {/* <ConnectivityTypeLink value={connectivityTypes} /> */}
        </Tag>
      )}
      {!!provider && (
        <Tag minimal small>
          {provider}
          {/* <ProviderLink provider={provider} /> */}
        </Tag>
      )}
    </>
  );
});

const FieldOverrideRenderer = ({ model, field, overrideField }) => {
  const hasOverridden = model.overriddenFields.includes(overrideField || field);
  const value = model.get(field);

  return (
    <>
      {value}
      <Overridden isOverridden={hasOverridden} />
    </>
  );
};

@inject('$cost', '$devices', '$dictionary')
@observer
export class InterfaceDrawer extends Component {
  render() {
    const { model, $cost, $devices, $dictionary, ...rest } = this.props;
    const deviceSummary = $devices.activeDeviceSummariesById[model.get('device_id')];
    const hasOverridden = model.overriddenFields.includes('interface_ip_netmask');
    const ipNetmask = model.get('interface_ip_netmask') || model.get('initial_interface_ip_netmask');
    const costGroup = $cost.getCostGroupForInterface(model.get());

    return (
      <Box p={2} width={360} {...rest}>
        <Heading level={5} mb="4px" fontWeight="heavy">
          <FieldOverrideRenderer model={model} field="interface_description" />
        </Heading>
        <Heading level={6} mb="4px">
          <FieldOverrideRenderer model={model} field="snmp_alias" />
        </Heading>
        <Flex mb="4px">
          {model.get('network_boundary') && (
            <Tag mr="4px">
              {$dictionary.get(`interfaceClassification.networkBoundaryTypes.${model.get('network_boundary')}`)}
            </Tag>
          )}
          {model.get('connectivity_type') && (
            <Tag mr="4px">
              {$dictionary.get(`interfaceClassification.connectivityTypes.${model.get('connectivity_type')}`)}
            </Tag>
          )}
          {model.get('provider') && <Tag>{model.get('provider')}</Tag>}
        </Flex>
        <Text as="div" muted mb="4px" small>
          SNMP ID (ifindex): <FieldOverrideRenderer model={model} field="snmp_id" />
        </Text>
        {deviceSummary && (
          <LinkButton
            small
            text="View in Network Explorer"
            to={`/v4/core/quick-views/interfaces/${deviceSummary.device_name}-${model.get('snmp_id')}`}
            mb={2}
          />
        )}

        <Flex>
          <InfoBox heading="Traffic" mr={2}>
            <Text as={Box} mb="1px" muted small>
              Flow
            </Text>
            <Text as={Box} muted small>
              SNMP
            </Text>
          </InfoBox>
          <InfoBox heading="In (Mbps)" mr={2}>
            <InterfaceFlowStatus model={model} status={model.flowInbound} />
          </InfoBox>
          <InfoBox heading="Out (Mbps)">
            <InterfaceFlowStatus model={model} status={model.flowOutbound} />
          </InfoBox>
        </Flex>

        <InfoBox heading="IP Address">
          <Text small>{model.get('interface_ip') ? <IpLink ip_address={model.get('interface_ip')} /> : 'N/A'}</Text>
          <Overridden isOverridden={model.overriddenFields.includes('interface_ip')} />
        </InfoBox>
        {model.get('secondary_ips') && (
          <InfoBox heading="Secondary IPs">
            <Text small>
              {Array.isArray(model.get('secondary_ips')) &&
                model.get('secondary_ips').length > 0 &&
                model.get('secondary_ips').map((ip) => (
                  <Box key={ip.address}>
                    <IpLink ip_address={ip.address} key={ip.address} />
                  </Box>
                ))}
              {model.get('secondary_ips').length === 0 && <Text muted>N/A</Text>}
            </Text>
          </InfoBox>
        )}
        <InfoBox
          heading="Netmask"
          value={
            <Flex>
              <Text small>{ipNetmask || 'None'}</Text>
              <Overridden isOverridden={hasOverridden} ml="4px" />
            </Flex>
          }
        />
        <InfoBox heading="Boundary ASNs">
          <Text small>
            <InterfaceBoundaryASNs model={model} />
          </Text>
        </InfoBox>
        {model.get('vrf_id') && (
          <InfoBox heading="VRF">
            <Text small>
              <Text mr="4px">{model.get('vrf').name}</Text>
              <Text muted>({model.get('vrf').route_distinguisher})</Text>
            </Text>
          </InfoBox>
        )}

        {$cost.canViewCosts && (
          <InfoBox heading="Cost Group">
            {!costGroup && (
              <Text small muted>
                N/A
              </Text>
            )}
            {costGroup && (
              <Link small to={`/v4/edge/costs/configure/providers/${costGroup.get('provider_id')}/${costGroup.id}`}>
                {costGroup.get('name')}
              </Link>
            )}
          </InfoBox>
        )}

        {model.get('ix_id') && (
          <InfoBox
            heading="PeeringDB IX Mapping"
            value={
              <Text small>
                <Lookup lookup="ix_id" value={model.get('ix_id')} />
              </Text>
            }
          />
        )}

        <InfoBox heading="Metadata" mb={0}>
          <InterfaceKvsMetadata model={model} />
        </InfoBox>
      </Box>
    );
  }
}
