import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Callout, Dialog } from 'core/components';
import { formatDateTime } from 'core/util/dateUtils';

@inject('$messages', '$auth')
@observer
class MessagesDialog extends Component {
  render() {
    const { $auth, $messages } = this.props;
    const { dismissMessages, messages } = $messages;
    const { userTimezone } = $auth;

    return (
      <Dialog
        isOpen={messages.length > 0}
        title="System Messages"
        canEscapeKeyClose={false}
        canClickOutside={false}
        isCloseButtonShown={false}
        top={150}
      >
        <Dialog.Body p={0} overflow="hidden" display="flex">
          {messages.map(({ id, message, cdate }) => (
            <Callout intent="warning" key={id} mb={1}>
              <h5>
                {formatDateTime(cdate)} ({userTimezone})
              </h5>
              <p style={{ marginBottom: 0 }}>{message}</p>
            </Callout>
          ))}
        </Dialog.Body>

        <Dialog.Footer>
          <Button intent="primary" onClick={dismissMessages} text="Close" />
        </Dialog.Footer>
      </Dialog>
    );
  }
}

export default MessagesDialog;
