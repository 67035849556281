import moment from 'moment';

function afterDateValidation(value, req) {
  const val1 = moment(value);
  const val2 = moment(req);

  return val1.isAfter(val2);
}

export default {
  message: ':attribute must be within retention period',
  fn: afterDateValidation
};
