import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, ButtonGroup, Flex, Label } from 'core/components';
import { formConsumer } from 'core/form/';

@formConsumer
@observer
export default class CloudProvidersFilter extends React.Component {
  handleChange = ({ isSelected, option }) => {
    const { onChange } = this.props;
    let nextSelections = this.field.getValue();

    if (isSelected) {
      // de-select the option
      nextSelections = nextSelections.filter((value) => value !== option.value);
    } else {
      // select the option
      nextSelections.push(option.value);
    }

    // set new values
    this.field.setValue(nextSelections);

    if (nextSelections.length > 0 && onChange) {
      onChange();
    }
  };

  get field() {
    const { form } = this.props;
    return form.getField('cloudProviders');
  }

  get options() {
    return this.field.options;
  }

  render() {
    const selectedValues = this.field.getValue();

    return (
      <Flex alignItems="center">
        <Label mr={1}>Show:</Label>
        <ButtonGroup>
          {this.options.map((option) => {
            const isSelected = selectedValues.includes(option.value);

            return (
              <Button
                key={option.value}
                text={option.label}
                active={isSelected}
                onClick={() => this.handleChange({ isSelected, option })}
              />
            );
          })}
        </ButtonGroup>
      </Flex>
    );
  }
}
