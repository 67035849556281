import { uniqBy } from 'lodash';
import { toIp } from 'core/util/ip';
import React, { Component } from 'react';
import { Classes } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { MdArrowDownward } from 'react-icons/md';
import { uriToObject, getCustomProperties } from 'shared/util/map';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { ICON_SIZE } from 'app/views/hybrid/utils/cloud/constants';

import { FaCheck } from 'react-icons/fa';

import { Box, Flex, Icon, Text } from 'core/components';
import { VirtualizedTable, Search } from 'core/components/table';

import { AzureRouteDestinationCollection } from 'app/stores/hybrid/azure';

const {
  VNET,
  SUBNET,
  ROUTER,
  FIREWALL,
  VPN_SITE,
  VIRTUAL_HUB,
  VPN_GATEWAY,
  ROUTE_TABLE,
  VNET_PEERING,
  VNET_GATEWAY,
  VPN_CONNECTIONS,
  VPN_LINK_CONNECTION,
  EXPRESS_ROUTE_GATEWAY,
  EXPRESS_ROUTE_CIRCUIT,
  EXPRESS_ROUTE_CONNECTION,
  HUB_VIRTUAL_NETWORK_CONNECTION
} = ENTITY_TYPES.get('azure');

@inject('$hybridMap')
@observer
class SubnetDestinations extends Component {
  // will keep here list of id visited when computing possible destinations to prevent infinite loop
  visitedIds = [];

  subnetRoutes = [];

  constructor(props) {
    super(props);

    this.visitedIds = [];

    const { subnet, $hybridMap } = this.props;
    const { azureCloudMapCollection } = $hybridMap;

    const subnetEntity = subnet.nodeData;

    const subnetRouteTable = azureCloudMapCollection.getEntityProperty(subnetEntity, ROUTE_TABLE);
    this.subnetRoutes = azureCloudMapCollection.getEntityProperty(subnetRouteTable, 'routes') ?? [];

    const vnetId = getCustomProperties(subnetEntity)?.vnetId;

    const vnet = azureCloudMapCollection.findEntityInSummary(VNET, vnetId);

    const destinations = uniqBy(this.extractVNetDestination(vnet), 'destination').filter(
      (route) => route.routeTarget?.id !== vnetId
    );

    this.collection = new AzureRouteDestinationCollection(destinations);
  }

  extractVNetDestinationFromVirtualNetworkPeering = (vnetPeering, vnet) => {
    const { $hybridMap, subnet } = this.props;
    const { azureCloudMapCollection } = $hybridMap;

    const remoteCidr = vnetPeering?.ips?.[0];

    const destinationVnetId = vnetPeering?.remoteVirtualNetwork;

    if (!destinationVnetId) {
      return [];
    }

    const destinationVnetFromSummary = azureCloudMapCollection.findEntityInSummary(VNET, destinationVnetId);
    const subscriptionId = destinationVnetFromSummary?.subscriptionId;
    const subscriptionName = azureCloudMapCollection.Summary?.[subscriptionId]?.name ?? '';
    const subscriptionSummary = azureCloudMapCollection.Summary[subscriptionId];

    let currentPeeringPath = [
      { id: vnetPeering.id, type: VNET_PEERING, vnetId: vnet.id, subscriptionId: vnet.subscriptionId }
    ];

    if (vnetPeering.matchingRoute?.properties?.nextHopType === 'VirtualAppliance') {
      const nextHopApplianceId = Object.keys(subscriptionSummary?.[FIREWALL] ?? {}).find((firewallId) => {
        const firewall = subscriptionSummary[FIREWALL][firewallId];
        return firewall.ips.some((ip) => ip === vnetPeering.matchingRoute.properties.nextHopIpAddress);
      });

      if (nextHopApplianceId) {
        const firewall = subscriptionSummary[FIREWALL][nextHopApplianceId];
        const isSameVnet = (firewall?.subnets ?? []).some((subnetId) => {
          const initialSubnetId = subnet.nodeData.id;

          const initialVnetId = initialSubnetId.split('/subnets/')[0];
          const vnetId = subnetId.split('/subnets/')[0];

          return initialVnetId.toLowerCase() === vnetId.toLowerCase();
        });

        if (isSameVnet) {
          currentPeeringPath = [
            {
              id: nextHopApplianceId,
              type: FIREWALL,
              vnetId: vnet.id,
              subscriptionId: vnet.subscriptionId
            },
            { id: vnetPeering.id, type: VNET_PEERING, vnetId: vnet.id, subscriptionId: vnet.subscriptionId }
          ];
        } else {
          currentPeeringPath.push({
            id: nextHopApplianceId,
            type: FIREWALL,
            subscriptionId: vnet.subscriptionId
          });
        }
      }
    }

    // it can be a peering to VHUB.
    if (!destinationVnetFromSummary) {
      const summaryHubs = azureCloudMapCollection.getSummaryEntities(VIRTUAL_HUB);
      // most likely its a peering to vhub
      const remoteVHub = Object.values(summaryHubs).find((vHub) => vHub.ips.includes(remoteCidr));

      if (!remoteVHub) {
        const uriObject = uriToObject(destinationVnetId);
        return [
          {
            state: false,
            destination: remoteCidr,
            routeTarget: {
              id: destinationVnetId,
              name: uriObject?.virtualNetwork ?? destinationVnetId
            },
            routeTargetType: VNET,
            subscription: {
              id: uriObject.subscription,
              name: 'Unknown'
            },
            destinationResourceFound: false,
            path: [
              { id: vnetPeering.id, type: VNET_PEERING },
              { id: destinationVnetId, type: VNET, vnetId: destinationVnetId }
            ]
          }
        ];
      }

      // find vnc that connects to current vnet
      const hubVirtualNetworkConnection = Object.values(remoteVHub[HUB_VIRTUAL_NETWORK_CONNECTION]).find(
        (vncConnection) => vncConnection?.remoteVirtualNetwork?.toLowerCase() === vnet?.id?.toLowerCase()
      );

      const neighborHubs = (remoteVHub.neighborHubs ?? []).map((neighborHubId) =>
        azureCloudMapCollection.findEntityInSummary(VIRTUAL_HUB, neighborHubId)
      );

      const remoteHubDestinations = this.extractDestinationsFromVHub(remoteVHub)
        .flat()
        .filter((destination) => destination)
        .map((destination) => {
          const { path } = destination;

          const totalPath = [
            ...currentPeeringPath,
            { id: hubVirtualNetworkConnection.id, type: HUB_VIRTUAL_NETWORK_CONNECTION, vHubId: remoteVHub.id },
            ...path
          ];
          const resultedPath = uniqBy(totalPath, 'id');
          return {
            ...destination,
            // subnet -> VNET_PEERING -> VNC -> ....
            path: resultedPath
          };
        });

      const remoteHubNeighborsDestinations = neighborHubs.map((neighborHub) =>
        this.extractDestinationsFromVHub(neighborHub)
          .flat()
          .filter((destination) => destination)
          .map((destination) => {
            const { path, nextHopGateways = [] } = destination;

            const totalPath = [
              ...currentPeeringPath,
              { id: hubVirtualNetworkConnection.id, type: HUB_VIRTUAL_NETWORK_CONNECTION, vHubId: remoteVHub.id },
              { id: remoteVHub.id, type: VIRTUAL_HUB },
              { id: neighborHub.id, type: VIRTUAL_HUB },
              ...path
            ];
            const resultedPath = uniqBy(totalPath, 'id');

            return {
              ...destination,
              // subnet -> VHUB -> NEIGHBOR_VHUB -> ....
              path: resultedPath,
              nextHopGateways: [{ id: remoteVHub.id, type: VIRTUAL_HUB, ip: remoteCidr }, ...nextHopGateways]
            };
          })
      );

      return [...remoteHubDestinations, ...remoteHubNeighborsDestinations].flat();
    }

    const nextHopIpAddress = 'Unknown Test';
    const nextHopGatewaysEntity = azureCloudMapCollection.getEntityProperty(
      vnetPeering,
      'remoteGateways',
      VNET_GATEWAY
    );

    const remoteVnetDestinations = (this.extractVNetDestination(destinationVnetFromSummary, false) ?? []).map(
      (remoteVnetDestination) => {
        const totalPath = [...currentPeeringPath, ...remoteVnetDestination.path];
        const resultedPath = uniqBy(totalPath, 'id');

        return {
          ...remoteVnetDestination,
          path: resultedPath
        };
      }
    );

    const nextHopGateway = nextHopGatewaysEntity?.[0] ?? null;
    const nextHopGateways = nextHopGateway ? [{ id: nextHopGateway.id, type: VNET_GATEWAY, ip: nextHopIpAddress }] : [];

    const currentVnetDestinations = vnetPeering?.ips.map((remoteIp) => ({
      state: true,
      destination: remoteIp,
      routeTarget: destinationVnetFromSummary,
      routeTargetType: VNET,
      subscription: {
        id: subscriptionId,
        name: subscriptionName
      },
      propagated: !!nextHopIpAddress,
      nextHopGateways,
      path: [...currentPeeringPath, { id: destinationVnetId, type: VNET, subscriptionId, vnetId: destinationVnetId }]
    }));

    return [...currentVnetDestinations, ...remoteVnetDestinations];
  };

  extractDestinationsFromVHub = (vHub) => {
    const { subscriptionId, id, ips } = vHub;
    const { $hybridMap } = this.props;
    const { azureCloudMapCollection } = $hybridMap;

    const hubSubscriptionName = azureCloudMapCollection.Summary?.[subscriptionId]?.name ?? '';

    const vncConnectionsDestinations = Object.keys(vHub[HUB_VIRTUAL_NETWORK_CONNECTION] ?? {}).map((vncId) => {
      const vncInSummary = vHub[HUB_VIRTUAL_NETWORK_CONNECTION][vncId];
      const { remoteVirtualNetwork } = vncInSummary;

      const destinationVnet = azureCloudMapCollection.findEntityInSummary(VNET, remoteVirtualNetwork);
      const destinationSubscriptionId = destinationVnet?.subscriptionId;
      const destinationSubscriptionName = azureCloudMapCollection.Summary?.[destinationSubscriptionId]?.name ?? '';

      const path = [
        { id: vncId, type: HUB_VIRTUAL_NETWORK_CONNECTION, subscriptionId },
        {
          id: remoteVirtualNetwork,
          type: VNET,
          subscriptionId: destinationSubscriptionId,
          vnetId: remoteVirtualNetwork
        }
      ];

      return {
        state: true,
        destination: (destinationVnet?.ips ?? []).join(','),
        subscription: {
          id: destinationSubscriptionId,
          name: destinationSubscriptionName
        },
        nextHopGateways: [{ id, type: VIRTUAL_HUB, ip: ips[0] }],
        routeTarget: destinationVnet,
        routeTargetType: VNET,
        propagated: false,
        path
      };
    });

    const expressRouteGateway = vHub[EXPRESS_ROUTE_GATEWAY] ?? null;
    const expressRouteGatewaysDestinations = (expressRouteGateway?.[EXPRESS_ROUTE_CONNECTION] ?? [])
      .map((connection) => {
        const router = connection?.[EXPRESS_ROUTE_CIRCUIT]?.[ROUTER] ?? null;

        return {
          path: [
            { id: expressRouteGateway.id, type: EXPRESS_ROUTE_GATEWAY, subscriptionId },
            { id: connection.id, type: EXPRESS_ROUTE_CONNECTION },
            { id: connection?.[EXPRESS_ROUTE_CIRCUIT]?.id, type: EXPRESS_ROUTE_CIRCUIT },
            { id: router?.id, type: ROUTER }
          ],
          subscription: {
            id: subscriptionId,
            name: hubSubscriptionName
          },
          nextHopGateways: [{ id: expressRouteGateway.id, type: EXPRESS_ROUTE_GATEWAY }],
          routeTarget: router,
          routeTargetType: ROUTER,
          destination: router?.ip,
          propagated: true
        };
      })
      .flat();

    const vpnGateway = vHub[VPN_GATEWAY] ?? null;
    const vpnGatewayDestinations = (vpnGateway?.[VPN_CONNECTIONS] ?? [])
      .map((vpnConnection) =>
        (vpnConnection?.[VPN_LINK_CONNECTION] ?? []).map((vpnLinkConnection) => {
          const vpnSite = vpnLinkConnection?.[VPN_SITE];

          return {
            path: [
              { id: vpnGateway.id, type: VPN_GATEWAY, subscriptionId },
              { id: vpnLinkConnection?.id, type: VPN_LINK_CONNECTION },
              { id: vpnSite?.id, type: VPN_SITE }
            ],
            nextHopGateways: [{ id: expressRouteGateway.id, type: EXPRESS_ROUTE_GATEWAY }],
            routeTarget: vpnSite,
            routeTargetType: VPN_SITE,
            destination: vpnSite?.ip,
            propagated: true
          };
        })
      )
      .flat();

    return [...vncConnectionsDestinations, ...expressRouteGatewaysDestinations, ...vpnGatewayDestinations];
  };

  /**
   * VNET Destinations consist of:
   * 1. VNET Peerings
   * 2. Connected VNET Hubs destinations
   */
  extractVNetDestination = (vnet, includeSourceSubnet = true) => {
    if (this.visitedIds.length > 1000) {
      console.warn('preventing infinite loop while generation virtual network peering destination');
      return [];
    }

    const { subnet } = this.props;
    const subnetEntity = subnet.nodeData;
    const { subscriptionId } = subnetEntity?.kentik || {};

    // prevent infinite loop
    if (this.visitedIds.includes(vnet.id)) {
      return [];
    }

    // mark as visited
    this.visitedIds.push(vnet.id);
    let virtualNetworkPeerings = vnet?.[VNET_PEERING] ?? [];

    if (this.subnetRoutes.length > 0) {
      // get only peering that match subnet routes
      virtualNetworkPeerings = virtualNetworkPeerings.filter((virtualNetworkPeering) =>
        virtualNetworkPeering.ips.some((peeringDestIp) => {
          const destIp = toIp(peeringDestIp);

          return this.subnetRoutes.some((subnetRoute) => {
            const routeAddressPrefix = subnetRoute.properties.addressPrefix;
            const routeAddressIp = toIp(routeAddressPrefix);

            const isMatchingRoute = destIp.isInSubnet(routeAddressIp);
            if (isMatchingRoute) {
              virtualNetworkPeering.matchingRoute = subnetRoute;
            }

            return isMatchingRoute;
          });
        })
      );
    }

    return [
      // extract from peerings
      ...virtualNetworkPeerings
        .map((vnetPeering) =>
          this.extractVNetDestinationFromVirtualNetworkPeering(vnetPeering, vnet).map((destination) => {
            const { path, ...rest } = destination;

            const newPath = includeSourceSubnet
              ? [{ id: subnetEntity.id, type: SUBNET, subscriptionId }, ...path]
              : [...path];

            return {
              ...rest,
              path: newPath
            };
          })
        )
        .flat(3)
    ].filter((route) => route?.destination);
  };

  get columns() {
    return [
      {
        label: 'State',
        name: 'state',
        width: 50,
        renderer: ({ value, model }) => {
          const foundDestination = model.get('destinationResourceFound') ?? true;
          if (!foundDestination) {
            return <Icon icon="issue" intent="warning" />;
          }

          return <Icon icon={value ? FaCheck : 'error'} intent="success" />;
        }
      },

      {
        label: 'Destination',
        name: 'destination',
        width: 320,
        renderer: ({ value, model }) => (
          <Flex gap="1">
            {model.get('nextHopIpAddress') && (
              <>
                <Box>{model.get('nextHopIpAddress')}</Box>
                <Box>
                  <Icon icon="arrow-right" intent="info" />
                </Box>
              </>
            )}
            <Box>{value}</Box>
          </Flex>
        )
      },

      {
        label: 'Route Target',
        name: 'routeTarget',
        width: 280,
        renderer: ({ value, model }) => {
          const nextHopGateways = model.get('nextHopGateways');
          const destinationResourceFound = model.get('destinationResourceFound') ?? true;

          return (
            <Flex flexDirection="column" gap="1">
              <Flex flexDirection="column">
                <Flex alignItems="center" gap="1">
                  <CloudIcon
                    cloudProvider="azure"
                    entity={model.get('routeTargetType')}
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                  />
                  <Text>{value?.name ?? value?.device_name ?? value?.id?.split('/')?.pop()}</Text>
                </Flex>

                {destinationResourceFound === false && (
                  <Text as="div" color="warning" textAlign="center">
                    Unknown Destination
                  </Text>
                )}
              </Flex>

              {nextHopGateways?.length > 0 && (
                <Flex flexDirection="column">
                  <Text fontWeight="bold" textAlign="center">
                    Next Hop Resource:
                  </Text>
                  <Flex flexDirection="column">
                    {nextHopGateways.map((nextHopGateway, index) => (
                      <React.Fragment key={nextHopGateway.id}>
                        <Flex gap="1" alignItems="center">
                          <CloudIcon
                            cloudProvider="azure"
                            entity={nextHopGateway.type}
                            width={ICON_SIZE * 0.5}
                            height={ICON_SIZE * 0.5}
                          />
                          <Text>{nextHopGateway?.id?.split('/')?.pop()}</Text>
                        </Flex>
                        {index < nextHopGateways.length - 1 && (
                          <Box alignSelf="center">
                            <Icon icon={MdArrowDownward} />
                          </Box>
                        )}
                      </React.Fragment>
                    ))}
                  </Flex>
                </Flex>
              )}
            </Flex>
          );
        }
      },
      {
        label: 'Destination Subscription',
        name: 'subscription',
        width: 300,
        renderer: ({ value }) => (
          <Flex flexDirection="column">
            <Text as="div" fontWeight="bold">
              {value?.name}
            </Text>
            <Text small>{value?.id}</Text>
          </Flex>
        )
      },
      {
        label: 'Propagated',
        name: 'propagated',
        width: 100,
        renderer: ({ value }) => <Box>{value ? 'Yes' : 'No'}</Box>
      }
    ];
  }

  rowHeight = ({ model }) => {
    const nextHopGateways = model.get('nextHopGateways');
    const subscription = model.get('subscription');
    if (subscription) {
      return 100;
    }

    return 40 + nextHopGateways.length * 50;
  };

  handleRowClick = (model) => {
    const { onSelect } = this.props;
    onSelect(model.get('path'));
  };

  handleSearch = (e) => this.collection.filter(e.target.value);

  render() {
    return (
      <>
        <Search
          p="4px"
          collection={this.collection}
          onChange={this.handleSearch}
          placeholder="Search..."
          inputProps={{ value: this.collection.filterState }}
          autoFocus
        />
        <VirtualizedTable
          style={{ height: 300 }}
          collection={this.collection}
          columns={this.columns}
          rowHeight={this.rowHeight}
          rowClass={Classes.POPOVER_DISMISS}
          onRowClick={this.handleRowClick}
          flexed
        />
      </>
    );
  }
}

export default SubnetDestinations;
