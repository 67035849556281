import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Flex, Link, Text } from 'core/components';
import DeviceLink from 'app/components/links/DeviceLink';
import InterfaceLink from 'app/components/links/InterfaceLink';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';
import { getRelatedGatewayIdsAndIps } from 'app/views/hybrid/maps/components/popovers/cloudUtil';

function getTitle(config) {
  return config.nodeData?.Name || config.nodeData?.metadata?.name || config.value;
}

function getIcon(config) {
  const { cloudProvider } = config;

  return <CloudIcon cloudProvider={cloudProvider} entity="customerGateway" width={32} height={32} />;
}

const CustomerGatewaySummary = (config) => {
  const { nodeData, topology, $auth, links, value } = config;
  const { ips } = getRelatedGatewayIdsAndIps({ links, topology, target: value });

  let device;
  let iface;

  if (nodeData?.router) {
    device = topology.Entities.Routers[nodeData.router.device_id];
    iface = device.children[nodeData.router.snmp_id];
  }

  return (
    <Box>
      <Flex p={2} borderBottom="thin" alignItems="center">
        {getIcon(config)}
        <Box flex={1} ml={1}>
          <Text as="div" muted>
            Customer Gateway Details
          </Text>
          <Text muted small>
            {getTitle(config)}
          </Text>
        </Box>
      </Flex>
      {nodeData && (
        <SidebarItem
          title="Details"
          icon="more"
          popoutTitle={CustomerGatewaySummary.PopoutTitle(config)}
          excludeFormProps
        >
          <Box>
            {iface && (
              <Box fontWeight="bold" mb={1}>
                AWS Metadata
              </Box>
            )}
            <CloudMetadata
              node={{
                ...nodeData,
                Destinations: ips
              }}
              keys={['id', 'OwnerId', 'State', 'Tags', 'Destinations']}
            />
            {iface && (
              <Box mt={1}>
                <Box fontWeight="bold" mb={1}>
                  Kentik Metadata
                </Box>
                <CloudMetadata
                  node={{
                    'IP Address': iface.interface_ip,
                    Device: device.device_name,
                    Interface: `${iface.interface_description} ${iface.snmp_id ? `(${iface.snmp_id})` : ''}`
                  }}
                  keys={['IP Address', 'Device', 'Interface']}
                  renderItem={(key, itemValue) => {
                    if (key === 'Device') {
                      return <DeviceLink name={itemValue} blank />;
                    }

                    if (key === 'Interface') {
                      return <InterfaceLink device_name={device.device_name} {...iface} blank />;
                    }

                    return null;
                  }}
                />
              </Box>
            )}
            {!device && $auth.isAdministrator && (
              <Box mt={1}>
                <Link to="/v4/settings/devices" blank>
                  Configure a device to monitor this gateway in Kentik
                </Link>
              </Box>
            )}
          </Box>
        </SidebarItem>
      )}
    </Box>
  );
};

CustomerGatewaySummary.PopoutTitle = (config) => (
  <Flex alignItems="center">
    {getIcon(config)}
    <Box ml={1}>{getTitle(config)}</Box>
  </Flex>
);

export default inject('$auth')(observer(CustomerGatewaySummary));
