/*
  this utility is responsible for generating all the 'View in Data Explorer' queries for cloud performance services
*/
import { buildFilterGroup } from 'core/util/filters';
import { getDimensionFieldName } from 'app/views/hybrid/maps/cloudDimensionConstants';

function getNetworkInterfaceIds({
  cloudProvider,
  services = [],
  region,
  virtualNetworkId,
  networkInterfaceMap = {},
  matchBy
}) {
  if (cloudProvider === 'aws' || cloudProvider === 'azure') {
    const networkInterfaces = networkInterfaceMap?.[cloudProvider]?.networkInterfaces || {};
    return Object.keys(networkInterfaces || {}).reduce((acc, networkInterfaceId) => {
      const item = networkInterfaces[networkInterfaceId];
      const matches =
        matchBy &&
        matchBy
          .map((matchType) => {
            const matchers = {
              service: services.includes(item.service),
              region: item.region === region,
              virtualNetworkId: item.virtualNetworkId === virtualNetworkId
            };

            return matchers[matchType];
          })
          .every((matchResult) => !!matchResult);

      if (!matchBy || matches) {
        return acc.concat(networkInterfaceId);
      }

      return acc;
    }, []);
  }

  return [];
}

function getQueryTitle({
  cloudProvider = 'aws',
  services = [],
  region,
  virtualNetworkId,
  networkInterfaceMap = {},
  matchBy
}) {
  const networkInterfaceIds = getNetworkInterfaceIds({
    cloudProvider,
    services,
    region,
    virtualNetworkId,
    networkInterfaceMap,
    matchBy
  });
  let serviceList = 'AWS';
  const whereString = [];
  let networkInterfaceString = '';

  if (services.length > 0) {
    const topServices = services.slice(0, 3);
    const restServices = services.slice(3);
    serviceList = `${topServices.join(', ')} ${restServices.length > 0 ? `(and ${restServices.length} more)` : ''}`;
  }

  if (region) {
    whereString.push(`in ${region} region`);
  }

  if (virtualNetworkId) {
    whereString.push(`${virtualNetworkId}`);
  }

  if (networkInterfaceIds.length > 0) {
    const networkInterfaceCount = networkInterfaceIds.length;

    networkInterfaceString = `including ${networkInterfaceCount} network interface${
      networkInterfaceCount === 1 ? '' : 's'
    } of private traffic`;
  }

  return `Top ${serviceList} service traffic ${whereString.join(' and ')} ${networkInterfaceString}`;
}

export default function getCloudServicesQuery({
  cloudProvider = 'aws',
  services = [],
  filterableServices = [],
  region,
  virtualNetworkId,
  networkInterfaceMap = {},
  matchBy,
  timeOptions
}) {
  const networkInterfaceIds = getNetworkInterfaceIds({
    cloudProvider,
    services,
    region,
    virtualNetworkId,
    networkInterfaceMap,
    matchBy
  });

  // matches public cloud services
  const filters = buildFilterGroup({
    connector: 'Any',
    filterGroups: [
      buildFilterGroup({
        connector: 'All',
        filters: [
          {
            filterField: 'src|dst_cloud',
            operator: '=',
            filterValue: cloudProvider
          }
        ]
      })
    ]
  });

  if (filterableServices.length > 0) {
    filters.filterGroups[0].filterGroups.push(
      buildFilterGroup({
        connector: 'Any',
        filters: filterableServices.map((serviceName) => ({
          filterField: 'src|dst_cloud_service',
          operator: '=',
          filterValue: serviceName
        }))
      })
    );
  }

  if (region) {
    filters.filterGroups[0].filters.push({
      filterField: getDimensionFieldName({ cloudProvider, direction: 'bi', type: 'region' }),
      operator: '=',
      filterValue: region
    });
  }

  if (virtualNetworkId) {
    filters.filterGroups[0].filters.push({
      filterField: getDimensionFieldName({ cloudProvider, direction: 'bi', type: 'virtual_network_id' }),
      operator: '=',
      filterValue: virtualNetworkId
    });
  }

  if (cloudProvider === 'aws' && networkInterfaceIds.length > 0) {
    // matches privately connected cloud services via network interface
    filters.filterGroups.push(
      buildFilterGroup({
        connector: 'Any',
        filters: networkInterfaceIds.map((id) => ({
          filterField: getDimensionFieldName({ cloudProvider, direction: 'bi', type: 'interface_id' }),
          operator: '=',
          filterValue: id
        }))
      })
    );
  }

  return {
    all_devices: false,
    device_types: [`${cloudProvider}_subnet`],
    query_title: getQueryTitle({ cloudProvider, services, region, virtualNetworkId, networkInterfaceMap, matchBy }),
    aggregateTypes: ['avg_bits_per_sec'],
    show_overlay: false,
    show_total_overlay: true,
    metric: [
      'src_cloud_service',
      'dst_cloud_service',
      getDimensionFieldName({ cloudProvider, direction: 'src', type: 'interface_id' }),
      getDimensionFieldName({ cloudProvider, direction: 'dst', type: 'interface_id' }),
      getDimensionFieldName({ cloudProvider, direction: 'src', type: 'region' }),
      getDimensionFieldName({ cloudProvider, direction: 'dst', type: 'region' }),
      getDimensionFieldName({ cloudProvider, direction: 'src', type: 'virtual_network_id' }),
      getDimensionFieldName({ cloudProvider, direction: 'dst', type: 'virtual_network_id' })
    ],
    topx: 6000,
    depth: 6000,
    forceDepth: true,
    fastData: 'Fast',
    viz_type: 'table',
    filters,
    ...timeOptions
  };
}
