import React from 'react';
import { inject } from 'mobx-react';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';

import SidebarItem from '../SidebarItem';
import DrgRouteDistribution from './components/DrgRouteDistribution';

const DrgRouteTablePopover = ({ width, popoutTitle, $hybridMap, ...rest }) => {
  const { nodeData } = rest;
  const { ociCloudMapCollection } = $hybridMap;

  const { drgRouteTableId } = nodeData;

  if (!drgRouteTableId) {
    return null;
  }

  const drgRouteTable = ociCloudMapCollection.findEntityById(drgRouteTableId);

  const drgRouteDistribution = ociCloudMapCollection.findEntityById(drgRouteTable.importDrgRouteDistributionId);

  const cloudMetadataDetails = {
    node: {
      ...drgRouteTable,
      Name: drgRouteTable.displayName,
      Status: drgRouteTable.lifecycleState,
      'Created At': new Date(drgRouteTable.timeCreated).toLocaleString()
    },
    keys: ['id', 'Name', 'region', 'Status', 'Created At', 'compartmentId', 'tenancyId']
  };

  return (
    <>
      <SidebarItem title="DRG Route Table" icon="route" {...rest}>
        <CloudMetadata {...cloudMetadataDetails} />
      </SidebarItem>
      <DrgRouteDistribution nodeData={drgRouteDistribution} width={width} popoutTitle={popoutTitle} />
    </>
  );
};

export default inject('$hybridMap')(DrgRouteTablePopover);
