import React, { Component } from 'react';
import { isEqual, uniqueId } from 'lodash';
import bytesToBitrate from 'core/util/bytesToBitrate';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import ViewInExplorerButton from 'app/components/dataviews/tools/ViewInExplorerButton';
import { interfaceKeyRegex } from 'app/components/dataviews/views/legend/legendUtils';
import { isGoogleCloud } from 'shared/util/map';
import { AWS, AZURE, GCP } from '../cloudDimensionConstants';

const { SUBNET } = ENTITY_TYPES.get('azure');
const emptyResults = [undefined, '', '---', 'Unassigned'];

/*
  Using a result row, find the matching dimension key for a given value
  A match is defined as having a key with a ktsubtype prefix and a purely matched value
  This is used to compose a 'subnetKey' string so we'll fallback to an empty string if there's no match
*/
function getDimensionKeyByValue({ result, value }) {
  const matchingEntry = Object.entries(result?.get() || {}).find(([k, v]) => k.startsWith('kt') && v === value);

  if (matchingEntry) {
    return matchingEntry[0];
  }

  return '';
}

function getValue(type, value, selectedNode) {
  const strippedType = type.replace(/(src|dst)-/, '');

  if (strippedType === 'interface') {
    const interfaceParts = interfaceKeyRegex.exec(value);

    if (interfaceParts) {
      return Number(interfaceParts.groups.snmp_id);
    }
  }

  if (strippedType === 'gateway') {
    if (value === 'local') {
      return '';
    }
  }

  if (strippedType === 'VpcEndpoints') {
    const vpcEndpointType = selectedNode.nodeData.VpcEndpointType;
    if (vpcEndpointType === 'Interface' && value === selectedNode.nodeData.NetworkInterfaceIds[0]) {
      return selectedNode.value;
    }
  }

  return value;
}

function getTraffic(query, result, direction) {
  const dirPart = direction.replace(/(in|out)bound/, '$1');

  if (result.has(`avg_${dirPart}_bits_per_sec`)) {
    return result.get(`avg_${dirPart}_bits_per_sec`);
  }

  if (result.has(`agg_total_${dirPart}_bytes`)) {
    return bytesToBitrate(result.get(`agg_total_${dirPart}_bytes`), query);
  }

  if (result.has('avg_bits_per_sec')) {
    return result.get('avg_bits_per_sec');
  }

  if (result.has('agg_total_bytes')) {
    return bytesToBitrate(result.get('agg_total_bytes'), query);
  }

  return 0;
}

function nodesMatch(node, selectedNode) {
  return (
    selectedNode &&
    (!selectedNode.type || node.type.replace(/(src|dst)-/, '') === selectedNode.type) &&
    (!selectedNode.value || node.value === selectedNode.value)
  );
}

/*
  With azure and gcp queries, the value we get back for subnet cannot be relied upon as unique.
  For instance, we could get a value back of 'default' which is often repeated across a number of vnets.
  In order to establish any reliable form of uniqueness, we add in a value named 'subnetKey' to the link segment.
  This value is expected to match a custom property we put on the subnet entity named 'key'. This is our own, internal way of uniquely identifying a subnet

  args:

  direction: SRC|DST
  link: a link fragment of the form { type, value }
  cloudProvider: azure|aws|gcp
  result: QueryResultsModel
*/
function decorateLinkSegment({ link, cloudProvider, result }) {
  if (cloudProvider === 'azure' || isGoogleCloud(cloudProvider)) {
    // find the dimension key for this link value
    const valueDimensionKey = getDimensionKeyByValue({ result, value: link.value });

    // for azure and gcp below, use the link value's dimension key to get the vpc name for this link's subnet direction
    // this will craft a subnet key composed of <vpc>-<subnet>

    if (cloudProvider === 'azure' && link.type === SUBNET) {
      return { ...link, subnetKey: `${result.get(AZURE.SIBLING?.[valueDimensionKey]?.VNET_ID)}-${link.value}` };
    }

    if (isGoogleCloud(cloudProvider) && link.type === SUBNET) {
      return {
        ...link,
        value: `${result.get(GCP.SIBLING?.[valueDimensionKey]?.VPC_NAME)}-${result.get(
          GCP.SIBLING?.[valueDimensionKey]?.REGION
        )}-${link.value}`
      };
    }
  }

  return link;
}

export default class TrafficLinkGenerator extends Component {
  static defaultProps = {
    debug: false,
    inputs: [],
    onLinksUpdate: () => null,
    subnetGatewayNetworkInterfaceMap: {}
  };

  static getDerivedStateFromProps(props, state) {
    const { inputs } = props;
    const queriesCount = inputs.reduce((count, { queries }) => count + queries.length, 0);

    if (queriesCount !== state.queriesCount || !isEqual(inputs, state.inputs)) {
      return { queriesId: uniqueId(), queriesCount, inputs, outputs: [] };
    }

    return null;
  }

  state = {
    queriesId: 0,
    queriesCount: 0,
    inputs: [],
    outputs: []
  };

  processResults(outputs) {
    const { cloudProvider, subnetGatewayNetworkInterfaceMap } = this.props;

    return outputs.map(({ results, ...data }) => {
      const { getKey, keyTypes, allowPairs = [], extraInfo = [], selectedNode } = data;
      const linksMap = {};

      /*
       * keyTypes = ['gateway', 'subnet', 'subnet', 'gateway']
       * list of types to match to each dimension
       *
       * allowPairs = [['gateway', 'subnet'], ['subnet', 'subnet'], ['subnet', 'gateway']]
       * source/target pairs that are allowed from the results
       * all pairs allowed if allowPairs is not specified
       *
       * selectedNode = { type: 'subnet', value: 'subnet-c34e2ea9' }
       * if provided, source/target pair must contain this node
       * this node will be made the source
       */

      results.forEach(({ resultsCollection, query }) => {
        resultsCollection.each((result) => {
          // first attempt to use the lookup which is essential in the case of an ASN group, fallback to the standard key for others
          const key = getKey ? getKey(result) : result.get('lookup') || result.get('key');
          const values = key?.split(' ---- ') ?? [];

          for (let s = 0; s < keyTypes.length - 1; s += 1) {
            const srcType = keyTypes[s];
            const srcValue = getValue(srcType, values[s], selectedNode);

            if (!emptyResults.includes(srcValue)) {
              for (let d = s + 1; d < keyTypes.length; d += 1) {
                const dstType = keyTypes[d];
                const dstValue = getValue(dstType, values[d], selectedNode);

                const sameKey = srcType === dstType && srcValue === dstValue;
                const pairAllowed =
                  allowPairs.length === 0 || allowPairs.some((p) => srcType === p[0] && dstType === p[1]);
                const srcIsSelected = nodesMatch({ type: srcType, value: srcValue }, selectedNode);
                const dstIsSelected = nodesMatch({ type: dstType, value: dstValue }, selectedNode);
                const hasSelectedNode = !selectedNode || srcIsSelected || dstIsSelected;
                const destinationGatewayId = result.get(AWS.DST.GATEWAY_ID);
                const destinationSubnetId = result.get(AWS.DST.SUBNET_ID);
                const networkInterfaceMapEntry = subnetGatewayNetworkInterfaceMap[result.get(AWS.SRC.INTERFACE_ID)];
                const matchesSubnet =
                  networkInterfaceMapEntry && destinationSubnetId === networkInterfaceMapEntry.subnetId;
                const hasMissingTrafficEntry =
                  networkInterfaceMapEntry && destinationGatewayId === 'local' && matchesSubnet;

                if (
                  hasMissingTrafficEntry ||
                  (!emptyResults.includes(dstValue) && !sameKey && pairAllowed && hasSelectedNode)
                ) {
                  let source;
                  let target;
                  let direction;

                  if (hasMissingTrafficEntry) {
                    // represents the best effort to fill in missing traffic from a transit/nat gateway to a subnet
                    source = { type: 'gateway', value: networkInterfaceMapEntry.gatewayId };
                    target = { type: 'subnet', value: destinationSubnetId };
                    direction = 'outbound';
                  } else if (srcIsSelected || (!dstIsSelected && String(srcValue).localeCompare(dstValue) > 0)) {
                    source = decorateLinkSegment({
                      direction: 'SRC',
                      link: { type: srcType.replace('src-', ''), value: srcValue },
                      cloudProvider,
                      result
                    });
                    target = decorateLinkSegment({
                      direction: 'DST',
                      link: { type: dstType.replace('dst-', ''), value: dstValue },
                      cloudProvider,
                      result
                    });
                    direction = 'outbound';
                  } else {
                    source = decorateLinkSegment({
                      direction: 'DST',
                      link: { type: dstType.replace('dst-', ''), value: dstValue },
                      cloudProvider,
                      result
                    });
                    target = decorateLinkSegment({
                      direction: 'SRC',
                      link: { type: srcType.replace('src-', ''), value: srcValue },
                      cloudProvider,
                      result
                    });
                    direction = 'inbound';
                  }

                  const linkKey = `${source.type}-${source.value}-${target.type}-${target.value}`;

                  linksMap[linkKey] = linksMap[linkKey] || {
                    source,
                    target,
                    inbound: 0,
                    outbound: 0
                  };
                  linksMap[linkKey][direction] += getTraffic(query, result, direction);

                  linksMap[linkKey].query = query;

                  if (extraInfo.length > 0) {
                    extraInfo.forEach((infoKey) => {
                      const idx = keyTypes.indexOf(infoKey);

                      if (idx > -1) {
                        // override empty additional results to ensure value (then can be '---')
                        if (emptyResults.includes(linksMap[linkKey][infoKey])) {
                          linksMap[linkKey][infoKey] = values[idx];
                        }
                      }
                    });
                  }
                }
              }
            }
          }
        });
      });

      const links = Object.values(linksMap);

      links.forEach((link) => {
        link.total = link.inbound + link.outbound;
      });

      return {
        ...data,
        // do not show links with 0 traffic on both sides
        links: links.filter((link) => Math.round(link.inbound) > 0 || Math.round(link.outbound) > 0)
      };
    });
  }

  handleQueryComplete = (inputData, resultData, inputIndex, queryIndex) => {
    const { onLinksUpdate } = this.props;

    this.setState(({ queriesCount, outputs }) => {
      outputs[inputIndex] = outputs[inputIndex] || { ...inputData, results: [] };
      outputs[inputIndex].results[queryIndex] = resultData;

      const resultsCount = outputs.reduce(
        (count, output) => count + (output ? output.results.filter(Boolean).length : 0),
        0
      );

      if (resultsCount === queriesCount) {
        const links = this.processResults(outputs);
        onLinksUpdate(links);
      }

      return { outputs };
    });
  };

  render() {
    const { debug } = this.props;
    const { queriesId, inputs } = this.state;

    return inputs.map(({ queries, ...inputData }, inputIndex) =>
      queries.map((query, queryIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={`${queriesId}-${inputIndex}-${queryIndex}`}>
          <LightweightDataViewWrapper
            query={query}
            onQueryComplete={({ results: resultsCollection, queryModel }) =>
              this.handleQueryComplete(inputData, { resultsCollection, query, queryModel }, inputIndex, queryIndex)
            }
          />
          {debug && <ViewInExplorerButton key={inputData.type} text={inputData.type} query={query} openInNewWindow />}
        </React.Fragment>
      ))
    );
  }
}
