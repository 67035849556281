import { generateFilterFields, generateTimeFields } from './helpers';

const filterFields = generateFilterFields('sidebarQueryOverrides.filters');
const timeFields = generateTimeFields('sidebarQueryOverrides');

const options = {
  name: 'Hybrid Topo Sidebar',
  groups: {
    filtering: Object.keys(filterFields),
    time: Object.keys(timeFields)
  }
};

const fields = {
  showInterSiteTraffic: {
    label: 'Show Inter-Site Traffic',
    defaultValue: false
  },

  showDefaultNetworks: {
    defaultValue: false
  },

  interSiteTraffic: {
    label: 'Inter-Site Traffic'
  },

  connectionType: {
    label: 'Draw Connections Using:',
    defaultValue: 'all',
    options: [
      { label: 'All Layers', value: 'all' },
      { label: 'Layer 3', value: 'layer3' },
      { label: 'Layer 2', value: 'layer2' }
    ]
  },

  trafficType: {
    label: 'Traffic Type',
    defaultValue: 'interfaces',
    options: [
      { label: 'Connected Interfaces', value: 'interfaces' },
      { label: 'Site IP', value: 'ip' },
      { label: 'Ultimate Exit', value: 'ultimateExit' }
    ]
  },

  colorBy: {
    label: 'Color by',
    defaultValue: 'inbound_bits_per_second',
    options: [
      { label: 'Inbound bits/s', value: 'inbound_bits_per_second' },
      { label: 'Outbound bits/s', value: 'outbound_bits_per_second' },
      { label: 'Total bits/s', value: 'total_bits_per_second' }
    ]
  },

  ...filterFields,
  ...timeFields,

  searchTerm: { defaultValue: '' },
  savedSearches: { defaultValue: [] },

  cloudProviders: {
    rules: 'required',
    defaultValue: ['aws', 'azure'],
    options: [
      { label: 'AWS', value: 'aws' },
      { label: 'Azure', value: 'azure' }
    ]
  }
};

export { fields, options };
