import * as React from 'react';
import { observer } from 'mobx-react';
import { FiCopy, FiMoreVertical } from 'react-icons/fi';
import { FaCheck } from 'react-icons/fa';
import { Box, Button, Card, DropdownMenu, Flex, Icon, Menu, MenuItem, Tag, Text } from 'core/components';
import { VirtualizedTable, Search, CELL_TYPES } from 'core/components/table';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';
import RouteTableSummaryCollection from 'app/stores/hybrid/RouteTableSummaryCollection';
import { ReactComponent as VPCIcon } from 'app/assets/icons/vpc.svg';
import { ReactComponent as TransitGatewayIcon } from 'app/assets/icons/transit-gateway.svg';
import { ReactComponent as VirtualGatewayIcon } from 'app/assets/icons/vpn-gateway.svg';
import { ReactComponent as DirectConnectGatewayIcon } from 'app/assets/icons/direct-connect-gateway.svg';
import { ReactComponent as RouterIcon } from 'app/assets/icons/router_icon.svg';
import { ReactComponent as VpcPeeringIcon } from 'app/assets/icons/vpc-peering.svg';

@observer
export default class RouteTable extends React.Component {
  constructor(props) {
    super(props);
    const { routeTableSummary } = props;
    const emptyRouteTables = [];

    // need to account for route tables with no routes
    routeTableSummary.routeTables.forEach((routeTableId) => {
      const { totalActiveRoutes, totalBlackholeRoutes, routeTableName } = routeTableSummary.totalsByTable[routeTableId];
      if (totalActiveRoutes === 0 && totalBlackholeRoutes === 0) {
        emptyRouteTables.push({ routeTableId, routeTableName });
      }
    });

    this.routesCollection = new RouteTableSummaryCollection([...routeTableSummary.routes, ...emptyRouteTables], {
      groupBy: 'routeTableId'
    });
  }

  getIcon = (resourceType) => {
    const iconMap = {
      vpc: <Icon icon={VPCIcon} mr="4px" />,
      vpn: <Icon icon={VirtualGatewayIcon} mr="4px" />,
      'direct-connect-gateway': <Icon icon={DirectConnectGatewayIcon} mr="4px" />,
      connect: <Icon icon={RouterIcon} mr="4px" />,
      peering: <Icon icon={VpcPeeringIcon} mr="4px" />,
      'tgw-peering': <Icon icon={TransitGatewayIcon} mr="4px" />
    };

    return iconMap[resourceType] || null;
  };

  get showMenu() {
    const { onShowPath } = this.props;
    return !!onShowPath;
  }

  get columns() {
    const { onShowPath } = this.props;

    const columns = [
      {
        label: 'State',
        name: 'state',
        width: 50,
        renderer: ({ value }) => {
          if (!value) {
            return null;
          }
          if (value === 'blackhole') {
            return <Icon icon="error" color="danger" />;
          }

          return <Icon icon={FaCheck} intent="success" />;
        }
      },

      {
        label: 'Destination',
        name: 'destination',
        width: 145,
        renderer: ({ value }) => {
          if (!value) {
            return <Text>No Routes Found</Text>;
          }

          const destinations = [].concat(value);
          const destinationsCopyValue = destinations.join(', ');

          return (
            <Flex alignItems="center">
              <Box>
                {destinations.map((destination) => (
                  <Text key={destination} as="div">
                    {destination}
                  </Text>
                ))}
              </Box>
              <CopyToClipboardButton
                text={destinationsCopyValue}
                copyConfirmationText={null}
                intent="none"
                ml="4px"
                minimal
                small
              >
                <Button icon={FiCopy} />
              </CopyToClipboardButton>
            </Flex>
          );
        }
      },

      {
        label: 'Route Target',
        name: 'routeTarget',
        renderer: ({ value, model }) => {
          if (!value) {
            return <Text>-</Text>;
          }

          if (model.isTransitGatewayRouteTable) {
            const { attachment, nextHopResource, resourceType } = value;

            return (
              <>
                <Box mb={1}>
                  <Text fontWeight="bold">Attachment:</Text>
                  <Flex alignItems="center">
                    <Text as="div" title={attachment.id} ellipsis>
                      {attachment.name}
                    </Text>
                    <CopyToClipboardButton
                      text={attachment.copyValue}
                      copyConfirmationText={null}
                      intent="none"
                      minimal
                      small
                    >
                      <Button icon={FiCopy} />
                    </CopyToClipboardButton>
                  </Flex>
                </Box>

                <Box>
                  <Text fontWeight="bold">Next Hop Resource:</Text>
                  <Flex alignItems="center">
                    {this.getIcon(resourceType)}
                    {(nextHopResource && (
                      <>
                        <Text as="div" title={nextHopResource.id} ellipsis>
                          {nextHopResource.name}
                        </Text>
                        <CopyToClipboardButton
                          text={nextHopResource.copyValue}
                          copyConfirmationText={null}
                          intent="none"
                          minimal
                          small
                        >
                          <Button icon={FiCopy} />
                        </CopyToClipboardButton>
                      </>
                    )) || (
                      <Text fontStyle="italic" muted>
                        n/a
                      </Text>
                    )}
                  </Flex>
                </Box>
              </>
            );
          }

          return (
            <Flex alignItems="center">
              <Text>{value}</Text>
              <CopyToClipboardButton text={value} copyConfirmationText={null} intent="none" ml="4px" minimal small>
                <Button icon={FiCopy} />
              </CopyToClipboardButton>
            </Flex>
          );
        }
      }
    ];

    if (this.showMenu) {
      columns.push({
        name: 'options',
        type: CELL_TYPES.ACTION,
        actions: [
          (model) => (
            <DropdownMenu
              key="routeTableOptions"
              content={
                <Menu>
                  <MenuItem icon="route" text="Show Path" onClick={() => onShowPath(model.get())} />
                </Menu>
              }
            >
              <Button icon={FiMoreVertical} minimal color="muted" small />
            </DropdownMenu>
          )
        ],
        width: 45
      });
    }

    return columns;
  }

  groupSummary = ({ group }) => {
    const { isPoppedOut } = this.props;
    const maxWidth = isPoppedOut ? 'auto' : 345;

    let routeTableId = null;
    let routeTableName = null;
    let label = 'n/a';
    let labelCopyValue = null;
    let associationLabel = null;

    const { totalActiveRoutes, totalBlackholeRoutes } = group.reduce(
      (acc, model) => {
        const routeState = model.get('state');

        if (routeState === 'active') {
          acc.totalActiveRoutes += 1;
        } else if (routeState === 'blackhole') {
          acc.totalBlackholeRoutes += 1;
        }

        return acc;
      },
      {
        totalActiveRoutes: 0,
        totalBlackholeRoutes: 0
      }
    );

    if (group.length > 0) {
      let routeTable = group[0].get('routeTable');

      routeTableId = routeTable?.id || group[0].get('routeTableId');
      routeTableName = routeTable?.id || group[0].get('routeTableName');

      label = routeTableId;
      labelCopyValue = routeTable?.copyValue || routeTableId;
      associationLabel = routeTable?.associationLabel;

      // if route table has no routes, "routeTable" will be undefined, but group will still have table id
      if (!routeTable && routeTableId) {
        routeTable = { routeTableId, routeTableName };
      }
    }

    return (
      <Flex alignItems="center">
        <Tag mr={1} intent="success" icon={FaCheck} title={`${totalActiveRoutes} active`} minimal round>
          {totalActiveRoutes}
        </Tag>
        {totalBlackholeRoutes > 0 && (
          <Tag mr={1} intent="danger" icon="error" title={`${totalBlackholeRoutes} blackhole`} minimal round>
            {totalBlackholeRoutes}
          </Tag>
        )}
        <Box maxWidth={maxWidth}>
          <Flex alignItems="center">
            <Text fontWeight="bold" mr={1} ellipsis>
              Table: {label} {associationLabel}
            </Text>

            {labelCopyValue && (
              <CopyToClipboardButton text={labelCopyValue} copyConfirmationText={null} intent="none" minimal small>
                <Button icon={FiCopy} />
              </CopyToClipboardButton>
            )}
          </Flex>
          {routeTableName && <Text ellipsis>{routeTableName}</Text>}
        </Box>
      </Flex>
    );
  };

  rowHeight = ({ model }) => {
    if (model) {
      if (model.isGroupSummary) {
        return 52;
      }

      if (model.isTransitGatewayRouteTable) {
        return 100;
      }

      const numberOfLines = [].concat(model.get('destination')).length;
      return numberOfLines * 15 + 24;
    }

    return 39;
  };

  handleSearch = (e) => this.routesCollection.filter(e.target.value);

  render() {
    return (
      <Flex flexDirection="column" flex={1}>
        <Card height="100%">
          <Search
            p="4px"
            collection={this.routesCollection}
            onChange={this.handleSearch}
            placeholder="Search Route Table..."
            inputProps={{ value: this.routesCollection.filterState }}
            autoFocus
          />
          <VirtualizedTable
            style={{ height: 300 }}
            collection={this.routesCollection}
            columns={this.columns}
            groupSummaryLookup={this.groupSummary}
            rowHeight={this.rowHeight}
            selectOnRowClick={false}
            isCollapsed
            flexed
          />
        </Card>
      </Flex>
    );
  }
}
