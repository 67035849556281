import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { getCustomProperties, uriToObject } from 'shared/util/map';
import { Box, Flex, Heading, Link, Text } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import CloudMetadata from 'app/views/hybrid/maps/components/popovers/CloudMetadata';
import SidebarItem from 'app/views/hybrid/maps/components/popovers/SidebarItem';

function getTitle(config) {
  // try the common 'name' properties first before falling back to the default value
  return config.nodeData?.Name || config.nodeData?.name || config.value;
}

function getMetadataValues({ cloudProvider, nodeData }) {
  const customProperties = getCustomProperties(nodeData);

  if (cloudProvider === 'azure') {
    return {
      node: {
        Name: nodeData.name,
        CIDRs: nodeData.properties?.addressPrefix,
        State: nodeData.properties.provisioningState || 'n/a',
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        resourceGroup: customProperties.uri?.resourceGroup,
        Tags: nodeData.tags
      }
    };
  }

  if (cloudProvider === 'gcp') {
    const { network } = uriToObject(nodeData.network);

    return {
      node: {
        ...nodeData,
        ...customProperties,
        Gateway: nodeData.gatewayAddress,
        Network: network,
        Purpose: nodeData.purpose,
        SamplingEnabled: nodeData.logConfig?.enable?.toString() || 'false',
        SamplingRate: nodeData.logConfig?.flowSampling || 0,
        'Secondary CIDRs': (nodeData.secondaryIpRanges || []).map(
          ({ ipCidrRange, rangeName }) => `${ipCidrRange} (${rangeName})`
        )
      },
      keys: [
        'selfLink',
        'project',
        'Network',
        'region',
        'id',
        'name',
        'description',
        'Purpose',
        'ipCidrRange',
        'SamplingEnabled',
        'SamplingRate',
        'Gateway',
        'Secondary CIDRs'
      ]
    };
  }

  if (cloudProvider === 'oci') {
    return {
      node: {
        Name: nodeData.displayName,
        CIDRs: nodeData.cidrBlock,
        virtualRouterIp: nodeData.virtualRouterIp,
        State: nodeData.lifecycleState,
        CompartmentId: nodeData.compartmentId,
        TenancyId: customProperties.tenancyId
      }
    };
  }

  return {
    node: nodeData,
    keys: ['id', 'CidrBlock', 'OwnerId', 'State', 'VpcId', 'Tags', 'SamplingEnabled', 'SamplingRate']
  };
}

const SubnetSummary = (config) => {
  const { value, cloudProvider, nodeData } = config;
  const { node, keys } = getMetadataValues({ cloudProvider, nodeData });
  const subnetRoute = cloudProvider === 'aws' ? 'subnet_id' : 'subnet_name';
  const subnetEntity = ENTITY_TYPES.get(cloudProvider)?.SUBNET || 'subnet';

  return (
    <Box>
      <Flex borderBottom="thin" p={2} alignItems="center">
        <CloudIcon cloudProvider={cloudProvider} entity={subnetEntity} width={32} height={32} />
        <Box flex={1} ml={1}>
          <Text as="div" muted>
            Cloud Subnet Details
          </Text>
          <Heading mb={0} level={4}>
            <Link to={`/v4/core/quick-views/cloud/${cloudProvider}/${subnetRoute}/${value}`} blank>
              {getTitle(config)}
            </Link>
          </Heading>
        </Box>
      </Flex>
      {nodeData && (
        <SidebarItem title="Details" icon="more" popoutTitle={SubnetSummary.PopoutTitle(config)} openOnMount>
          <CloudMetadata node={node} keys={keys} />
        </SidebarItem>
      )}
    </Box>
  );
};

SubnetSummary.PopoutTitle = (config) => {
  const { cloudProvider } = config;
  const subnetEntity = ENTITY_TYPES.get(cloudProvider)?.SUBNET || 'subnet';

  return (
    <Flex alignItems="center">
      <CloudIcon cloudProvider={cloudProvider} entity={subnetEntity} width={17} height={17} />
      <Box ml={1}>{getTitle(config)} Subnet</Box>
    </Flex>
  );
};

export default inject('$hybridMap')(observer(SubnetSummary));
