import { baseFields } from './baseFields';

const dnsOptions = {
  name: 'DNS Server Monitor'
};

const dnsFields = {
  ...baseFields,
  'config.tasks': {
    defaultValue: ['dns']
  },

  'config.target.value': {
    defaultValue: '',
    rules: 'required|hostname'
  },

  'config.servers': {
    rules: ['required', 'commaSeparatedIPsNoCIDR', 'agentsSupportIPv6'],
    messages: {
      required: 'One or more DNS servers are required.'
    },
    placeholder: 'Enter a comma-separated list of IP addresses, e.g. 10.2.1.23, 2001:450:2002:236::2a6, 1.1.1.1'
  },

  'config.agents': {
    defaultValue: [],
    rules: ['required', 'agentsSupportIPv6'],
    messages: {
      required: 'One or more agent selections is required.'
    }
  },

  'config.type': {
    defaultValue: 'A',
    label: 'DNS Record Type',
    rules: 'required',
    options: [
      { label: 'A', value: 'A' },
      { label: 'AAAA', value: 'AAAA' },
      { label: 'CAA', value: 'CAA' },
      { label: 'CNAME', value: 'CNAME' },
      { label: 'MX', value: 'MX' },
      // TODO: #13121 add back NAPTR and SRV once hostname validation is updated
      // { label: 'NAPTR', value: 'NAPTR' },
      { label: 'NS', value: 'NS' },
      { label: 'PTR', value: 'PTR' },
      { label: 'SOA', value: 'SOA' },
      // { label: 'SRV', value: 'SRV' },
      { label: 'TXT', value: 'TXT' }
    ]
  },

  'config.port': {
    defaultValue: 53,
    label: 'Target Port',
    rules: ['numeric', 'MinPingPortValidator', 'max:65535'],
    transform: {
      out: (value) => parseInt(value)
    }
  },

  'config.period': {
    label: 'DNS Lookup Frequency',
    defaultValue: 60,
    rules: ['required', 'numeric', 'min:60', 'max:3600'],
    options: [
      { label: 'Every minute', value: 60 },
      { label: 'Every 2 minutes', value: 120 },
      { label: 'Every 5 minutes', value: 300 },
      { label: 'Every 10 minutes', value: 600 },
      { label: 'Every 15 minutes', value: 900 },
      { label: 'Every 30 minutes', value: 1800 },
      { label: 'Every 60 minutes', value: 3600 }
    ]
  },

  'config.healthSettings.dnsValidIps': {
    label: 'Allowed DNS Results',
    rules: ['commaSeparatedIPsNoCIDR', 'agentsSupportIPv6'],
    helpText: 'Enter a comma-separated list of IP mappings to be considered "healthy"'
  }
};

export { dnsOptions, dnsFields };
