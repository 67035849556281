import * as React from 'react';
import { observer } from 'mobx-react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Box } from 'core/components';
import ServiceListItem from './ServiceListItem';

class ServiceList extends React.Component {
  render() {
    const { serviceModels, allServicesSelected, onItemClick, sortable, ...boxProps } = this.props;
    const ListItem = sortable ? SortableElement(ServiceListItem) : ServiceListItem;

    return (
      <Box {...boxProps}>
        {serviceModels.map((model, index) => {
          const serviceKey = model.get('value');
          const listItemProps = sortable ? { index } : {};

          return (
            <ListItem
              {...listItemProps}
              key={serviceKey}
              zIndex={130}
              serviceKey={serviceKey}
              model={model}
              allServicesSelected={allServicesSelected}
              onItemClick={onItemClick}
              sortable={sortable}
            />
          );
        })}
      </Box>
    );
  }
}

export default SortableContainer(observer(ServiceList));
