import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { IoCloudCircleSharp } from 'react-icons/io5';
import Deck from 'app/components/decks/Deck';
import { Collection } from 'core/model';
import { PresetFilterSelect } from '../../../core/components/table';

class CloudFilter extends Collection {
  availableFilters = [];

  get presetFilters() {
    return this.availableFilters;
  }
}

/*
 * TODO: consider exposing this as a reusable component to avoid customize/boilerplate
 *
 * Deck itself is very flexible, but if we are going to usually have the same tools, we'll
 * want to avoid this getting replicated for every deck.
 */
@inject('$devices', '$setup')
@observer
class CloudDeck extends Component {
  constructor(props) {
    super(props);
    const { $devices } = props;
    this.cloudFilter = new CloudFilter();
    if ($devices.hasAWSDevice) {
      this.cloudFilter.availableFilters.push({ label: 'AWS', type: 'aws' });
    }
    if ($devices.hasAzureDevice) {
      this.cloudFilter.availableFilters.push({ label: 'Azure', type: 'azure' });
    }
    if ($devices.hasGCEDevice) {
      this.cloudFilter.availableFilters.push({ label: 'GCP', type: 'gcp' });
    }

    if ($devices.hasOCIDevice) {
      this.cloudFilter.availableFilters.push({ label: 'OCI', type: 'oci' });
    }
  }

  render() {
    return (
      <Deck
        title="Cloud"
        type={this.cloudFilter.activePresetFilter?.type || 'cloud'}
        icon={IoCloudCircleSharp}
        iconSize={32}
        canCustomize={false}
        defaultDeckConfig={this.defaultDeckConfig}
        tools={
          <PresetFilterSelect
            menuLabel="Show:"
            collection={this.cloudFilter}
            useButtonGroup
            containerProps={{ mr: 1 }}
            buttonProps={{ pl: '12px', pr: '12px' }}
          />
        }
      />
    );
  }
}

export default CloudDeck;
