import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import classNames from 'classnames';

const StyledCircle = styled.circle`
  fill: ${({ fill }) => themeGet(`colors.${fill}`, fill)};
  stroke: ${({ stroke }) => themeGet(`colors.${stroke}`, stroke)};
`;

const Circle = (props) => {
  const { className, ...rest } = props;

  return <StyledCircle {...rest} className={classNames('hybrid-circle', className)} />;
};

export default Circle;
