import { ENTITY_KEY_SUBNETS } from 'app/stores/hybrid/AzureCloudMapCollection';

const sourceTypeOptions = [
  {
    label: ENTITY_KEY_SUBNETS,
    value: ENTITY_KEY_SUBNETS,
    labelKeys: ['id', 'name', 'properties.addressPrefix']
  }
];

export const SourceFieldType = {
  label: 'Source type',
  options: sourceTypeOptions,
  defaultValue: ENTITY_KEY_SUBNETS
};

export const TargetFieldType = {
  label: 'Target type',
  options: sourceTypeOptions,
  defaultValue: ENTITY_KEY_SUBNETS
};
