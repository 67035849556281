import React from 'react';
import { inject, observer } from 'mobx-react';
import { Text, Icon, Box, Flex } from 'core/components';
import ResultMenuItem from './ResultMenuItem';

function Provider({ $cost, item, selected, query, IconCmp, onItemClick, ...rest }) {
  const { id, name, type, path, costGroups } = item;
  return (
    <ResultMenuItem
      {...rest}
      selected={selected}
      onClick={() => onItemClick({ type: 'provider', id, path }, 'Search Results')}
    >
      <Flex gridArea="icon" alignItems="center" justifyContent="center">
        <IconCmp iconSize={22} />
      </Flex>

      <Box gridArea="title">
        <Text className="title">{name}</Text>
      </Box>

      <Flex gridArea="rightIcon" alignItems="center" justifyContent="center">
        <Icon icon={selected ? 'key-enter' : undefined} color="#ffffff" />
      </Flex>

      <Text
        className="description"
        gridArea="description"
        muted
        small
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        style={{ overflow: 'hidden' }}
      >
        {Array.isArray(costGroups) && $cost.getConnectivityLabel(type)}, {costGroups.length} Cost Group
        {costGroups.length !== 1 && 's'}
      </Text>
    </ResultMenuItem>
  );
}

export default inject('$cost')(observer(Provider));
